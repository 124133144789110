import { TaskState, TaskActionTypes, RECEIVE_TASKS, RECEIVE_TASK } from './taskTypes';

const initialState: TaskState = {
  tasks: [],
  task: null,
};

function taskReducer(
  state: TaskState = initialState, action: TaskActionTypes
): TaskState {
  switch (action.type) {
    case RECEIVE_TASKS:
      return { ...state, tasks: action.tasks };
    case RECEIVE_TASK:
      return { ...state, task: action.task };
    default:
      return state;
  }
}

export default taskReducer;
