export const EPOD_STATUS = {
  to_be_acknowledged: 'to_be_acknowledged',
  partially_complete: 'partially_complete',
  declined: 'declined',
  complete: 'complete',
} as const;

export const EPOD_STATUS_TEXT = {
  to_be_acknowledged: 'To be acknowledged',
  partially_complete: 'Partially Complete',
  declined: 'Declined',
  complete: 'Complete',
} as const;
