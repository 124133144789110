import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: '0.5rem',
  colors: {
    main: 'rgba(255, 255, 255, 1)',
    base_white: 'hsla(0, 0%, 100%, 1)',
    primary_25: 'rgba(242, 243, 251, 1)',
    primary_50: 'rgba(230, 232, 248, 1)',
    primary_200: 'rgba(153, 163, 224, 1)',
    primary_300: 'rgba(102, 116, 209, 1)',
    primary_500: 'rgba(0, 24, 178, 1)',
    primary_600: 'rgba(0, 23, 162, 1)',
    primary_700: 'rgba(0, 22, 145, 1)',
    primary_800: 'rgba(0, 20, 129, 1)',
    primary_900: 'rgba(0, 19, 112, 1)',
    gray_25: 'rgba(249, 250, 252, 1)',
    gray_50: 'rgba(245, 247, 249, 1)',
    gray_100: 'rgba(238, 238, 238, 1)',
    gray_200: 'rgba(222, 222, 222, 1)',
    gray_300: 'rgba(170, 171, 179, 1)',
    gray_400: 'rgba(116, 119, 136, 1)',
    gray_500: 'rgba(94, 97, 115, 1)',
    gray_600: 'rgba(89, 92, 109, 1)',
    gray_700: 'rgba(53, 57, 75, 1)',
    gray_900: 'rgba(13, 17, 40, 1)',
    error_25: 'rgba(253, 243, 247, 1)',
    error_50: 'rgba(251, 232, 238, 1)',
    error_100: 'hsla(340, 71%, 89%, 1)',
    error_200: 'rgba(240, 162, 188, 1)',
    error_300: 'rgba(232, 115, 154, 1)',
    error_500: 'rgba(217, 22, 87, 1)',
    error_600: 'rgba(193, 25, 82, 1)',
    error_700: 'rgba(170, 28, 78, 1)',
    error_800: 'rgba(146, 31, 73, 1)',
    warning_25: 'rgba(255, 252, 242, 1)',
    warning_50: 'rgba(255, 249, 230, 1)',
    warning_200: 'rgba(255, 231, 153, 1)',
    warning_300: 'rgba(255, 220, 102, 1)',
    warning_500: 'rgba(255, 196, 0, 1)',
    warning_600: 'rgba(216, 166, 0, 1)',
    warning_700: 'rgba(177, 136, 0, 1)',
    warning_800: 'rgba(137, 105, 0, 1)',
    blue_100: 'rgba(204, 209, 240, 1)',
    pink_50: 'rgba(253, 242, 250, 1)',
    pink_700: 'rgba(193, 21, 116, 1)',
    success_50: 'rgba(232, 250, 251, 1)',
    success_300: 'rgba(113, 225, 229, 1)',
    success_500: 'rgba(18, 205, 212, 1)',
    success_600: 'rgba(14, 173, 180, 1)',
    success_700: 'rgba(9, 141, 147, 1)',
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    display_xs: '1.5rem',
    display_sm: '1.875rem',
    display_md: '2.75rem',
    display_lg: '3rem',
    display_xl: '3.75rem',
    display_2xl: '4.5rem',
  },
  iconSizes: {
    xs: '0.45rem',
    sm: '0.575rem',
    md: '0.75rem',
    lg: '1.125rem',
    xl: '1.5rem',
  },
  boxShadows: {
    xs: '0px 1px 2px 0px rgba(204, 209, 240, 0.05)',
    sm: '0px 1px 2px 0px rgba(204, 209, 240, 0.06), 0px 1px 3px 0px rgba(204, 209, 240, 1)',
  },
  fontWeights: {
    semibold: 600,
    normal: 400,
  },
};

export default theme;
