import * as React from 'react';
import styled from 'styled-components';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  faFileDownload,
  faFileAlt,
  faEnvelope,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TaskClient from 'httpClients/taskClient';
import { CenterModal, StyledButton, Typography, Alert } from 'components';
import { Epod } from 'models/task/epod';
import EpodDocument from 'components/pdf/epodDocument';
import { format } from 'date-fns';
import COLOR, { transparent } from 'constants/color';
import { EPOD_STATUS } from 'constants/epodStatus';
import whatsAppLogo from 'assets/images/whatsapp@2x.png';
import telegramLogo from 'assets/images/telegram@2x.png';
import { shareSocialMedia, shareEmail } from 'utils/httpHelper';

interface DividerProps {
  isHidden?: boolean;
  marginTopBottom?: string;
  marginBottom?: string;
}

export const Divider = styled.hr<DividerProps>`
  margin: 0;
  height: 1px;
  width: 100%;
  border: none;
  background-color: ${(props): string => props.theme.colors.gray_200};
  visibility: ${(props): string => props.isHidden && 'hidden'};
  margin: ${(props): string =>
    props.marginTopBottom && `${props.marginTopBottom} 0 ${props.marginTopBottom} 0`};
  margin-bottom: ${(props): string => props.marginBottom && props.marginBottom};
`;

const TemplateContainer = styled.div`
  position: relative;
`;

const TemplatePreview = styled.div`
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLOR.midLightGrey};
  padding: 0.5rem;
  font-size: ${(props): string => props.theme.fontSizes.sm};
  color: ${(props) => props.theme.colors.gray_600};
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;

  overflow-y: auto;
  max-height: 15rem;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

const ActionButtonText = styled.div`
  font-size: 14px;
  margin-left: 0.375rem;
`;

const WhatsappImg = styled.img`
  width: 1.2rem;
`;

const TelegramImg = styled.img`
  width: 1.2rem;
`;

const Toast = styled.div`
  background-color: ${COLOR.white};
  border-radius: 0.5rem;
  box-shadow: 0px 4px 16px ${transparent('shadow', 0.8)};
  padding: 1rem;
  cursor: pointer;
`;

const ToastBackground = styled.div`
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface SharePodReceiptModalProps {
  isShowBackButton: boolean;
  handleBackButtonModal?: () => void;
  handleCloseModal: () => void;
  taskId: string;
}

export default function SharePodReceiptModal(props: SharePodReceiptModalProps) {
  const { handleCloseModal, isShowBackButton, taskId } = props;
  const [epod, setEpod] = React.useState<Epod | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEpodAvailable, setEpodAvailable] = React.useState(true);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);
  const [showCopySuccess, setShowCopySuccess] = React.useState(false);

  const shareContentRef = React.useRef<HTMLDivElement>(null);

  const fetchTask = async (id: string): Promise<void> => {
    const client = new TaskClient();
    setIsLoading(true);
    try {
      const response = await client.getEpodByTaskId(id);
      setEpod(response);
    } catch (error) {
      setEpodAvailable(false);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const filename = (epodCreatedAt: Date, taskId: string) => {
    const id = taskId.split('-')[0];
    const dateEpodCreatedFormatted = format(new Date(epodCreatedAt), 'ddMMyy');
    return `EPOD-${dateEpodCreatedFormatted}-${id}`;
  };

  const copyTemplate = (): void => {
    navigator.clipboard.writeText(shareContentRef.current.innerText);
    setShowCopySuccess(true);
    setTimeout(() => {
      setShowCopySuccess(false);
    }, 3000);
  };

  const renderEndorsementText = (epod: Epod | null) => {
    if (!epod) {
      return null;
    }

    const isEndorsed = epod.status !== 'to_be_acknowledged' && epod.endorse !== null;
    return (
      <>
        {isEndorsed ? (
          <Typography color="gray_700" as="p" size="sm">
            Share the endorsed ePOD with the ship captain using the provided template.{' '}
            <a href={`/epod/${epod.task.id}`} target='_blank' rel="noreferrer">View endorsement</a>
          </Typography>
        ) : (
          <Typography color="gray_700" as="p" size="sm">
            Need the ship master’s endorsement? Get your ePOD endorsed from the ship
            captain using the provided template.
          </Typography>
        )}
      </>
    );
  };

  const renderEndorsementMessage = (epod: Epod | null) => {
    if (!epod) {
      return null;
    }
    const { task } = epod;
    const orderId = task.tracking_id
      ? task.tracking_id.toLowerCase()
      : task.id.split('-')[0];
    const shareUrl = `${window.location.origin}/epod/${task.id}`;
    let endorsementMessage: React.ReactNode = null;
    switch (epod.status) {
      case EPOD_STATUS.partially_complete:
        endorsementMessage = (
          <>
            Dear Master / Chief,
            <br />
            <br />
            The receipt of goods for Order {orderId} is partially complete - some goods
            received and accepted, but not all
            <br />
            <br />
            <a href={shareUrl} target="_blank" rel="noreferrer">{shareUrl}</a>
            <br />
            <br />
            Best Regards,
            <br />
            {task.org_name}
          </>
        );
        break;
      case EPOD_STATUS.declined:
        endorsementMessage = (
          <>
            Dear Master / Chief,
            <br />
            <br />
            The receipt of goods for Order {orderId} is declined - no goods
            received and accepted
            <br />
            <br />
            <a href={shareUrl} target="_blank" rel="noreferrer">{shareUrl}</a>
            <br />
            <br />
            Best Regards,
            <br />
            {task.org_name}
          </>
        );
        break;
      case EPOD_STATUS.complete:
        endorsementMessage = (
          <>
            Dear Master / Chief,
            <br />
            <br />
            The receipt of goods for Order {orderId} is complete - all goods received and
            accepted
            <br />
            <br />
            <a href={shareUrl} target="_blank" rel="noreferrer">{shareUrl}</a>
            <br />
            <br />
            Best Regards,
            <br />
            {task.org_name}
          </>
        );
        break;
      case EPOD_STATUS.to_be_acknowledged:
        endorsementMessage = (
          <>
            Dear Master / Chief,
            <br />
            <br />
            Your order {orderId} has been delivered. Please acknowledge the receipt of
            goods within 7 days to be eligible for GST refund.
            <br />
            <br />
            <a href={shareUrl} target="_blank" rel="noreferrer">{shareUrl}</a>
            <br />
            <br />
            Best Regards,
            <br />
            {task.org_name}
          </>
        );
        break;
    }

    return (
      <>
        <TemplateContainer>
          <TemplatePreview ref={shareContentRef}>{endorsementMessage}</TemplatePreview>
          {showCopySuccess ? (
            <ToastBackground
              onClick={(): void => {
                setShowCopySuccess(false);
              }}
            >
              <Toast>Copied!</Toast>
            </ToastBackground>
          ) : (
            false
          )}
        </TemplateContainer>
      </>
    );
  };

  const renderShareButtons = (epod: Epod | null) => {
    if (!epod) {
      return null;
    }
    const shareUrl = `${window.location.origin}/${epod.task.tracking_id}`;
    const orderId = epod.task.tracking_id;
    return (
      <>
        <ActionButtonContainer>
          <ActionButton
            onClick={(): void =>
              shareSocialMedia('telegram', shareContentRef.current.innerText, shareUrl)
            }
          >
            <TelegramImg src={telegramLogo} />
            <ActionButtonText>Open Telegram</ActionButtonText>
          </ActionButton>
          <ActionButton
            onClick={(): void =>
              shareSocialMedia('whatsapp', shareContentRef.current.innerText, shareUrl)
            }
          >
            <WhatsappImg src={whatsAppLogo} />
            <ActionButtonText>Open WhatsApp</ActionButtonText>
          </ActionButton>
          <ActionButton
            onClick={() => {
              shareEmail(
                shareContentRef.current.innerText,
                `${orderId} Proof of Delivery e-Signature`
              );
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '2px' }} />
            <ActionButtonText>Open Email</ActionButtonText>
          </ActionButton>
        </ActionButtonContainer>
        <Divider marginTopBottom="1.5rem" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <StyledButton
            buttonStyle="encourage"
            buttonType="neutral"
            fontWeight={400}
            size="sm"
            onClick={copyTemplate}
            icon={<FontAwesomeIcon icon={faCopy} />}
          >
            <ActionButtonText>Copy Text</ActionButtonText>
          </StyledButton>
        </div>
      </>
    );
  };

  React.useEffect(() => {
    fetchTask(taskId);
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CenterModal
      position={isMobile ? 'bottom' : 'top'}
      title={<PODModalTitle>Share Proof of Delivery Receipt</PODModalTitle>}
      width="small"
      isShowBackButton={isShowBackButton}
      closeButtonOnClick={() => handleCloseModal()}
      backButtonOnClick={() => {
        if (typeof props.handleBackButtonModal === 'function') {
          props.handleBackButtonModal();
        }
      }}
    >
      <Typography
        color="gray_600"
        as="p"
        customStyle={{
          marginBottom: '1.35rem',
        }}
      >
        View or download your proof of delivery receipt.
      </Typography>

      {!isEpodAvailable ? (
        <EpodUnavailable status="info">
          ePOD is unavailable for this order.
        </EpodUnavailable>
      ) : (
        false
      )}

      {epod && isEpodAvailable ? (
        <PDFDownloadLink
          document={<EpodDocument data={epod} />}
          fileName={filename(epod.created_at, taskId)}
        >
          {() => (
            <>
              <StyledButton
                isLoading={isLoading}
                disabled={isLoading}
                style={{ marginRight: '1rem', minWidth: '126.42px' }}
                size={isMobile ? 'lg' : 'md'}
                buttonStyle="encourage"
                buttonType="neutral"
                fontWeight={600}
                icon={<FontAwesomeIcon icon={faFileDownload} />}
                isFullWidth={isMobile}
              >
                Download
              </StyledButton>
            </>
          )}
        </PDFDownloadLink>
      ) : (
        <StyledButton
          disabled={true}
          style={{ marginRight: '1rem' }}
          size={isMobile ? 'lg' : 'md'}
          buttonStyle="encourage"
          buttonType="neutral"
          fontWeight={600}
          icon={<FontAwesomeIcon icon={faFileDownload} />}
          isFullWidth={isMobile}
        >
          Download
        </StyledButton>
      )}

      {!isMobile && (
        <StyledButton
          disabled={!isEpodAvailable}
          size="md"
          buttonStyle="encourage"
          buttonType="neutral"
          fontWeight={600}
          icon={<FontAwesomeIcon icon={faFileAlt} />}
          onClick={() => {
            if (isEpodAvailable) {
              window.open(`/epod/${taskId}/pdf`, '_blank');
            }
          }}
        >
          View Receipt
        </StyledButton>
      )}

      <Divider marginTopBottom="1.5rem" />
      <div
        style={{
          minHeight: '34px',
        }}
      >
        {renderEndorsementText(epod)}
        {renderEndorsementMessage(epod)}
        {renderShareButtons(epod)}
      </div>
    </CenterModal>
  );
}

const PODModalTitle = styled.p`
  font-size: 1.7rem;
  margin-bottom: 1rem;
  margin-top: 0;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
    margin-top: 0.75rem;
  }
`;

const EpodUnavailable = styled(Alert)`
  margin-bottom: 1.5rem;
`;
