import * as React from 'react';
import { Typography, StyledButton } from 'components';
import COLOR, { transparent } from 'constants/color';
import { VEHICLE_PREFERENCE_INFO } from 'constants/vehiclePreference';
import styled from 'styled-components';
import VehicleModal from '../modal/vehicleModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';

interface BaseProps {
  form: {
    broadcast_preference: keyof typeof BROADCAST_PREFERENCES;
    vehicle_preference?: string;
  };
  disabled?: boolean;
  onFormChange: (fieldName: string, value: string | boolean) => void;
  displayHeader?: React.ReactNode;
  totalWeight?: number;
  vehiclesExceedingDimensions?: string[];
}

type Props = BaseProps &
  (
    | { enableTotalWeightValidation?: false; totalWeight?: number }
    | { enableTotalWeightValidation: true; totalWeight: number }
  );

const VehicleForm = ({
  enableTotalWeightValidation,
  totalWeight,
  vehiclesExceedingDimensions = [],
  ...props
}: Props): JSX.Element => {
  const [showVehicleModal, setVehicleModal] = React.useState(false);
  const modalDisplayColumn =
    props.form.broadcast_preference === BROADCAST_PREFERENCES.marine ? 1 : 2;
  const vehicleList = React.useMemo(() => {
    const list = { ...VEHICLE_PREFERENCE_INFO };
    if (
      props.form.broadcast_preference !== BROADCAST_PREFERENCES.marine &&
      enableTotalWeightValidation
    ) {
      Object.entries(list).forEach(([key, value]) => {
        list[key] = {
          ...value,
          disabled: totalWeight > value.max_weight,
        };
      });
    }
    return list;
  }, [props.form.broadcast_preference, enableTotalWeightValidation, totalWeight]);

  return (
    <ItemVehicleRequirementsContainer>
      {props.displayHeader ? (
        props.displayHeader
      ) : (
        <>
          <Title>Vehicle Requirements</Title>
          <TitleDescription>
            Select the most appropriate vehicle to fit all your items.
          </TitleDescription>
        </>
      )}
      <div style={{ marginTop: '1rem' }}>
        {props.form.vehicle_preference ? (
          <>
            <Typography
              as="div"
              customStyle={{
                backgroundColor: transparent('neutral', 0.2),
                borderRadius: 15,
                padding: '1rem',
              }}
            >
              <RowSpaceBetween>
                <Typography
                  as="div"
                  color="primary_600"
                  weight="semibold"
                  size="sm"
                  customStyle={{ display: 'flex', flexDirection: 'column', gap: 4 }}
                >
                  <Typography
                    as="span"
                    color="gray_700"
                    weight="semibold"
                    size="md"
                    customStyle={{ marginRight: 'auto' }}
                  >
                    {VEHICLE_PREFERENCE_INFO[props.form.vehicle_preference].description}
                  </Typography>
                  <Typography
                    as="p"
                    color="gray_600"
                    weight="normal"
                    size="sm"
                    customStyle={{ marginRight: 'auto' }}
                  >
                    {VEHICLE_PREFERENCE_INFO[props.form.vehicle_preference].dimension}
                  </Typography>
                  <Typography
                    as="p"
                    color="gray_600"
                    weight="normal"
                    size="sm"
                    customStyle={{ marginRight: 'auto' }}
                  >
                    {VEHICLE_PREFERENCE_INFO[props.form.vehicle_preference].capacity}
                  </Typography>
                </Typography>
                <ButtonIcon
                  disabled={props.disabled}
                  buttonStyle="encourage"
                  buttonType="neutral"
                  icon={<FontAwesomeIcon icon={faPencilAlt} />}
                  onClick={(): void => {
                    setVehicleModal(true);
                  }}
                  type="button"
                />
              </RowSpaceBetween>
            </Typography>
            {props.form.vehicle_preference === 'motorcycle' &&
              !vehicleList['motorcycle'].disabled &&
              vehiclesExceedingDimensions.includes('motorcycle') && (
                <Typography
                  as="div"
                  color="warning_600"
                  size="sm"
                  customStyle={{ display: 'inline-flex', gap: 10, margin: '0.5rem' }}
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <Typography as="span" color="warning_600" size="sm">
                    Dimensions Exceeded
                  </Typography>
                </Typography>
              )}
          </>
        ) : (
          <SelectVehicleButton
            buttonStyle="encourage"
            buttonType="neutral"
            onClick={(): void => {
              setVehicleModal(true);
            }}
            disabled={props.disabled}
            type="button"
          >
            <SelectVehicleText>Select a vehicle...</SelectVehicleText>
          </SelectVehicleButton>
        )}
      </div>
      {showVehicleModal ? (
        <VehicleModal
          vehiclesPreferenceInfo={vehicleList}
          vehiclesExceedingDimensions={vehiclesExceedingDimensions}
          displayColumnTabletAndDesktop={modalDisplayColumn}
          broadcastPreference={props.form.broadcast_preference}
          closeModal={(): void => {
            setVehicleModal(false);
          }}
          onVehicleClick={(vehicle: string): void => {
            props.onFormChange('vehicle_preference', vehicle);
          }}
          selectedVehicle={props.form.vehicle_preference}
          disabled={props.disabled}
        />
      ) : (
        false
      )}
    </ItemVehicleRequirementsContainer>
  );
};

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  color: ${COLOR.darkGray};
`;

const TitleDescription = styled.div`
  color: ${COLOR.midDarkGrey};
  margin-bottom: 1rem;
`;

const ItemVehicleRequirementsContainer = styled.section`
  width: 57%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectVehicleText = styled.div`
  width: 100%;
  text-align: center;
`;

const SelectVehicleButton = styled(StyledButton)`
  display: flex;
  flex-direction: row;
  height: fit-content;
  padding: 1rem;
  align-items: center;
  width: 100%;
`;

const ButtonIcon = styled(StyledButton)`
  padding: 8px;
  width: 40px;
  height: 40px;
`;

export default VehicleForm;
