import * as React from 'react';
import RunsheetRTBClient from 'httpClients/runsheetRTBClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import {
  Table, StyledButton, TableCellRenderEvent,
  SysAdminPagination, Dropdown, DatePicker,
} from 'components';
import { Link } from 'react-router-dom';
import * as H from 'history';
import * as moment from 'moment';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward } from '@fortawesome/free-solid-svg-icons';

import DateHelper from 'utils/dateHelper';
import COLOR from 'constants/color';
import JOB_STATUS from 'constants/jobStatus';
import { RunsheetReadyToBroadcast } from 'models/runsheetReadyToBroadcast';

interface StateProps {
  models: RunsheetReadyToBroadcast[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type RunsheetReadyToBroadcastIndexProps = StateProps & HistoryProps;

class RunsheetReadyToBroadcastIndex extends SysAdminPagination<RunsheetReadyToBroadcast, RunsheetReadyToBroadcastIndexProps> {
  constructor(props: RunsheetReadyToBroadcastIndexProps) {
    super(props);

    this.state = {
      ...this.state,
      basePath: '/sys/runsheet-ready-to-broadcasts',
      pluralModelName: 'broadcasts',
      selectedTab: 'runsheets',
      filters: this.getDefaultFilter(),
    };
  }

  getDefaultFilter = (): Record<string, string> => {
    const currentDate = new Date();

    return ({
      page: '1',
      order: 'desc',
      runsheet_date: DateHelper.formatDate(currentDate),
    });
  }

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });
    const filters = { ...this.state.filters };
    const runsheetReadyToBroadcastDate = DateHelper.formatString(this.state.filters.runsheet_date);

    const startDate = new Date(runsheetReadyToBroadcastDate).setHours(0, 0, 0, 0);
    const endDate = new Date(runsheetReadyToBroadcastDate).setHours(23, 59, 59, 999);

    filters.range_start_created_at = moment(startDate).utc().format();
    filters.range_end_created_at = moment(endDate).utc().format();
    delete filters.runsheet_date;

    const client = new RunsheetRTBClient();
    await client.getRunsheetReadyToBroadcasts(new URLSearchParams(filters));

    this.setState({ isFetching: false });
  }

  renderFilter = (): React.ReactNode => (
    <FilterContainer>
      <Dropdown
        containerStyle={css`
          margin: 0 0.5rem;
        `}
        label="Sort by"
        options={[
          {
            value: 'desc',
            name: 'Newest First',
          }, {
            value: 'asc',
            name: 'Oldest First',
          },
        ]}
        onChange={(value: string): void => { this.onFilterChange('order', value); }}
        value={this.state.filters.order}
      />
      <DatePicker
        label='Date Created'
        enablePreviousDate
        containerStyle={css`
                margin-left: 0.375rem;
                background-color: ${COLOR.white}
              `}
        value={DateHelper.formatString(this.state.filters.runsheet_date)}
        onChange={(v: Date): void => { this.onFilterChange('runsheet_date', DateHelper.formatDate(v)); }}
      />
    </FilterContainer>
  )

  renderActionPanel = (): React.ReactNode => (
    <TopButtonContainer>
      <Button
        buttonStyle="encourage"
        buttonType="primary"
        size="sm"
        icon={<FontAwesomeIcon icon={faBackward} />}
        fontWeight={400}
        onClick={(): void => { this.props.history.push('/sys/runsheets'); }}
      >
        Back to Runsheet
      </Button>
    </TopButtonContainer>
  )

  renderCell = (e: TableCellRenderEvent<{ createdAt: string; id: string; runsheetID: string; totalDrivers: number; status: string; updatedAt: string }>): React.ReactNode => {
    if (e.key === 'runsheetID') {
      return (
        <Link to={'/sys/runsheets/' + e.value.toString()}>
          <strong>{e.value.toString()}</strong>
        </Link>
      );
    }

    return <>{e.value}</>;
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      id: 'ID',
      runsheetID: 'Runsheet ID',
      totalDrivers: 'Driver Broadcasted',
      status: 'Status',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    };

    const data = models ? models.map(runsheetReadyToBroadcast => ({
      id: runsheetReadyToBroadcast.id,
      runsheetID: runsheetReadyToBroadcast.runsheet_id,
      totalDrivers: (runsheetReadyToBroadcast.drivers).length,
      status: JOB_STATUS[runsheetReadyToBroadcast.status],
      createdAt: moment(runsheetReadyToBroadcast.created_at).format('DD MMM YYYY HH:mm:ss A'),
      updatedAt: moment(runsheetReadyToBroadcast.updated_at).format('DD MMM YYYY HH:mm:ss A'),
    })) : [];

    return (
      <>
        <Table
          columns={columns}
          data={data}
          cellRenderer={this.renderCell}
          rowOnNavigationButtonClick={(id: string): void => { this.props.history.push('/sys/runsheet-ready-to-broadcasts/' + id); }}
        />
      </>
    );
  }
}

const Button = styled(StyledButton)`
  margin-right: 0.5rem;
  flex: none;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
`;

const TopButtonContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

const mapStateToProps = (state: RootState): StateProps => ({
  models: state.runsheetReadyToBroadcast.runsheetReadyToBroadcasts,
});

export default connect(mapStateToProps)(RunsheetReadyToBroadcastIndex);
