import * as React from 'react';
import styled from 'styled-components';
import COLOR from 'constants/color';
import merchantLogo from 'assets/images/merchant_logo.svg';
import merchantSmallLogo from 'assets/images/merchant_small.png';
import loginIllustration from 'assets/images/login_illus.svg';
import pattern from 'assets/images/bg_pattern.png';

interface BeforeLoginContainerProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
}

const BeforeLoginContainer = (props: BeforeLoginContainerProps): JSX.Element => (
  <Container>
    <IllustrationContainer>
      <LoginIllustration src={loginIllustration} />
    </IllustrationContainer>
    <RightContainer>
      <DetailContainer>
        <LogoContainer>
          <Logo src={merchantLogo} />
        </LogoContainer>
        <SmallLogo src={merchantSmallLogo}></SmallLogo>
        <Title>
          {props.title}
        </Title>
        {props.children}
      </DetailContainer>
    </RightContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const SmallLogo = styled.img`
  width: 3rem;
  height: 3rem;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const IllustrationContainer = styled.div`
  align-items: center;
  background-color: ${COLOR.white};
  background-image: url(${pattern});
  display: flex;
  flex-direction: column;
  flex: 6;
  justify-content: center;
  margin-top: -3%;

  @media screen and (max-width: 960px) {
    display:none;
  }
`;

const RightContainer = styled.div`
  flex: 4;
`;

const DetailContainer = styled.div`
  margin: 3rem 5.75rem;

  @media screen and (max-width: 960px) {
    margin: 2rem;
  }
`;

const LoginIllustration = styled.img`
  width: 62%;
`;

const Title = styled.div`
  color: ${COLOR.blue};
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.875rem;
`;

const LogoContainer = styled.div`
  display: none;
  
  @media screen and (min-width: 768px) {
    display: flex;
    height: 100%;
    margin-left: -0.8rem;
  }
`;

const Logo = styled.img`
  width: 8.875rem;
`;

export default BeforeLoginContainer;