import * as React from 'react';
import styled, {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
  css,
} from 'styled-components';
import { StyledButton, TextInput } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { isValueEmptyOrZero, formatToTwoDecimalNumber } from 'utils/formatter';

const maxDigitAfterDecimalPoint = 2;

interface CounterInputButtonProps {
  value: number | string;
  incrementValue: number;
  decrementValue: number;
  maxLength?: number;
  onValueChange: (value: string | number) => void;
  isMinusButtonDisabled?: boolean;
  isPlusButtonDisabled?: boolean;
  isAllFieldsDisabled?: boolean;
  isDiscourageMinusButton?: boolean;
  isFloatNumberAllowed?: boolean;
  isZeroNumberAllowed?: boolean;
  defaultEmptyValue?: number | string;
}

export default function CounterInputButton({
  value,
  incrementValue,
  decrementValue,
  maxLength = 10,
  onValueChange,
  isMinusButtonDisabled,
  isPlusButtonDisabled,
  isAllFieldsDisabled,
  isDiscourageMinusButton,
  isFloatNumberAllowed = false,
  isZeroNumberAllowed = false,
  defaultEmptyValue = '',
}: CounterInputButtonProps): JSX.Element {
  const regexValidation = isFloatNumberAllowed ? /^-?(?:\d*\.\d*|\d+)$/ : /^-?\d+$/;
  const handleTextChange = (
    value: string,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let inputValue = value;

    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
    }

    if (regexValidation.test(inputValue) || inputValue === '') {
      if (
        inputValue !== '' &&
        Math.abs(Number(value) % 1) !== 0 &&
        value.toString().split('.')[1].length > maxDigitAfterDecimalPoint
      ) {
        e.stopPropagation();
        return;
      }
      const updatedValue =
        inputValue.endsWith('.') || inputValue === '' ? inputValue : Number(inputValue);
      onValueChange(updatedValue);
    } else {
      e.preventDefault();
    }
  };
  const minusButtonDisabled =
    isMinusButtonDisabled || value === null || value === undefined;

  return (
    <CounterInputButtonContainer>
      <ButtonIcon
        buttonStyle={isDiscourageMinusButton ? 'discourage' : 'encourage'}
        buttonType="neutral"
        icon={<FontAwesomeIcon icon={faMinus} />}
        onClick={(): void => {
          if (value === undefined || value === null) {
            return;
          }
          const updatedValue = formatToTwoDecimalNumber(
            Number(value) - decrementValue,
            maxDigitAfterDecimalPoint
          );
          onValueChange(updatedValue);
        }}
        disabled={minusButtonDisabled || isAllFieldsDisabled}
        isAllFieldsDisabled={isAllFieldsDisabled}
        type="button"
      />

      <TextInput
        autoComplete="none"
        containerStyle={css`
          width: 63px !important;
        `}
        inputStyle={css`
          text-align: center;
          padding: 0.75rem;
        `}
        onTextChange={handleTextChange}
        type="text"
        hideNativeInputNumberButton={true}
        width="small"
        placeholder="1"
        value={value ?? ''}
        inputMode="numeric"
        maxLength={maxLength}
        onBlur={(e): void => {
          if (
            !isZeroNumberAllowed &&
            e.target.value === '0' &&
            defaultEmptyValue === ''
          ) {
            onValueChange('');
          } else if (defaultEmptyValue && isValueEmptyOrZero(e.target.value)) {
            onValueChange(defaultEmptyValue);
          }
        }}
        disabled={isAllFieldsDisabled}
      />
      <ButtonIcon
        buttonStyle="encourage"
        buttonType="neutral"
        icon={<FontAwesomeIcon icon={faPlus} />}
        onClick={(): void => {
          if (isNaN(Number(value))) {
            onValueChange(1);
          } else {
            const updatedValue = formatToTwoDecimalNumber(
              Number(value) + incrementValue,
              maxDigitAfterDecimalPoint
            );
            onValueChange(updatedValue);
          }
        }}
        disabled={isPlusButtonDisabled || isAllFieldsDisabled}
        isAllFieldsDisabled={isAllFieldsDisabled}
        type="button"
      />
    </CounterInputButtonContainer>
  );
}

const CounterInputButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 6px;
`;

const ButtonIcon = styled(StyledButton)<{ isAllFieldsDisabled: boolean }>`
  padding: 8px;
  width: 40px;
  height: 40px;

  ${({ isAllFieldsDisabled }): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    isAllFieldsDisabled &&
    css`
      :disabled {
        background-color: ${(props): string => props.theme.colors.gray_200};
        border-color: rgba(170, 171, 180, 1);
        color: rgba(170, 171, 179, 1);
      }
    `}
`;
