import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { getBearerToken, saveBearerToken, saveUser } from 'utils/userHelper';
import configureStore from 'reduxActions/store';
import {
  receiveSigninSuccess,
  receiveUsers,
  receiveUser,
} from 'reduxActions/auth/authActions';
import * as HttpHelper from 'utils/httpHelper';
import {
  User,
  SigninForm,
  PartnerSigninForm,
  PartnerSigninResponse,
  SigninResponse,
  ForgotPasswordForm,
  ResetPasswordForm,
  UserUpdateForm,
  UserInvitationForm,
  SysUserInvitationForm,
  UserAcceptInvitationForm,
  UserInvitationResponse,
  ForgotPasswordResponse,
  ResetPasswordResponse,
  GetUserListResponse,
  GetUserResponse,
  RequestVerificationForm,
  RequestVerificationResponse,
  VerifyRegistrationUserForm,
  CompleteSelfRegistrationForm,
  UpdatePasswordForm,
  DeleteAccountPayload,
} from 'models/auth';
import { PER_PAGE } from 'constants/paginationMeta';
import { logoutUser } from 'utils/userHelper';
import { SysAddOrgDriverForm } from 'src/models/auth/userInvitationForm';
import { GetInvitationLinkResponse } from 'src/models/auth/userInvitationResponse';

class AuthClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.AUTHENTICATION_SERVICE_PROTOCOL;
    this.host = process.env.AUTHENTICATION_SERVICE_HOST;
    this.port = process.env.AUTHENTICATION_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(
    method: Method,
    url: string,
    body: T | null = null,
    includeBearerToken = true,
    customBearerToken: string = null
  ): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${customBearerToken || bearerToken}`;
    }

    return options;
  }

  signInUrl(): string {
    return `${this.getBaseUrl()}/signin`;
  }

  async signIn(form: SigninForm): Promise<void> {
    const request = this.makeRequest('POST', this.signInUrl(), form, false);

    try {
      const response: AxiosResponse<PartnerSigninResponse> = await axios(request);
      const roles = response.data.user.roles;
      if (
        roles.includes('SysAdmin') ||
        roles.includes('OrgAdmin') ||
        roles.includes('OrgTransporter')
      ) {
        saveBearerToken(response.data.bearer_token);
        saveUser(JSON.stringify(response.data.user));

        if (process.env.SEGMENT_KEY) {
          window.analytics.identify(
            response.data.user.id,
            {
              email: response.data.user.email,
              name: response.data.user.name,
            },
            {
              Intercom: {
                user_hash: response.data.user.user_hash,
              },
            }
          );
        }

        const store = configureStore();
        store.dispatch(receiveSigninSuccess(response.data));
      } else {
        throw Error('Your account is not allowed to access the web app');
      }
    } catch (error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async partnershipSignIn(form: PartnerSigninForm): Promise<PartnerSigninResponse> {
    const request = this.makeRequest('POST', this.signInUrl(), form, false);

    try {
      const response: AxiosResponse<PartnerSigninResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async saveUserInfo(): Promise<void> {
    const request = this.makeRequest('GET', this.getBaseUrl());

    try {
      const response: AxiosResponse<User> = await axios(request);

      const token = getBearerToken();
      const userObj: SigninResponse = {
        user: response.data,
        bearer_token: token,
      };

      saveUser(JSON.stringify(response.data));

      if (process.env.SEGMENT_KEY) {
        window.analytics.identify(
          response.data.id,
          {
            email: response.data.email,
            name: response.data.name,
          },
          {
            Intercom: {
              user_hash: userObj.user.user_hash,
            },
          }
        );
      }

      const store = configureStore();
      store.dispatch(receiveSigninSuccess(userObj));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  operatorInvitationUrl(): string {
    return `${this.getBaseUrl()}/invitation`;
  }

  async inviteOperator(form: UserInvitationForm): Promise<UserInvitationResponse> {
    const request = this.makeRequest('POST', this.operatorInvitationUrl(), form);
    try {
      const response: AxiosResponse<UserInvitationResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status && error.response.status === 500) {
          throw 'failed to send invitation(s), please try again later';
        } else {
          throw error.response.data;
        }
      } else {
        throw error.message;
      }
    }
  }

  sysInviteOperatorUrl(): string {
    return `${this.getBaseUrl()}/sys/invitation`;
  }

  async sysInviteOperator(form: SysUserInvitationForm): Promise<UserInvitationResponse> {
    const request = this.makeRequest('POST', this.sysInviteOperatorUrl(), form);
    try {
      const response: AxiosResponse<UserInvitationResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error.message;
      }
    }
  }

  getInvitationLinkUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/invitation-url?${params}`;
  }

  async getInvitationLink(email: string): Promise<GetInvitationLinkResponse> {
    const params = new URLSearchParams();
    params.append('email', email);
    const request = this.makeRequest('GET', this.getInvitationLinkUrl(params));
    try {
      const response: AxiosResponse<GetInvitationLinkResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error.message;
      }
    }
  }

  sysGetInvitationLinkUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/invitation-url?${params}`;
  }

  async sysGetInvitationLink(email: string): Promise<GetInvitationLinkResponse> {
    const params = new URLSearchParams();
    params.append('email', email);
    const request = this.makeRequest('GET', this.sysGetInvitationLinkUrl(params));
    try {
      const response: AxiosResponse<GetInvitationLinkResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error.message;
      }
    }
  }

  sysAddOrgDriverUrl(): string {
    return `${this.getBaseUrl()}/signup`;
  }

  async sysAddOrgDriver(form: SysAddOrgDriverForm): Promise<UserInvitationResponse> {
    const request = this.makeRequest('POST', this.sysAddOrgDriverUrl(), form);
    try {
      const response: AxiosResponse<UserInvitationResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error.message;
      }
    }
  }

  async AcceptInvitation(
    form: UserAcceptInvitationForm
  ): Promise<UserInvitationResponse> {
    const request = this.makeRequest('PUT', this.operatorInvitationUrl(), form, false);
    try {
      const response: AxiosResponse<UserInvitationResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error.message;
      }
    }
  }

  forgotPasswordUrl(): string {
    return `${this.getBaseUrl()}/password`;
  }

  async forgotPassword(form: ForgotPasswordForm): Promise<ForgotPasswordResponse> {
    const request = this.makeRequest('POST', this.forgotPasswordUrl(), form, false);

    try {
      const response: AxiosResponse<ForgotPasswordResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  resetPasswordUrl(): string {
    return `${this.getBaseUrl()}/password`;
  }

  async resetPassword(form: ResetPasswordForm): Promise<ResetPasswordResponse> {
    const request = this.makeRequest('PUT', this.resetPasswordUrl(), form, false);

    try {
      const response: AxiosResponse<ResetPasswordResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.errors &&
          error.response.data.errors.reset_password_token
          ? 'Reset password token is invalid'
          : error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  sysGetUsersUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/users?${params}`;
  }

  getUsersUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/users?${params}`;
  }

  async sysGetUsers(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', this.sysGetUsersUrl(params));

    try {
      const response: AxiosResponse<GetUserListResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveUsers(response.data));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async getUsers(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', this.getUsersUrl(params));

    try {
      const response: AxiosResponse<GetUserListResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveUsers(response.data));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  sysGetUserUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/users/${id}`;
  }

  async sysGetUser(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.sysGetUserUrl(id));

    try {
      const response: AxiosResponse<GetUserResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveUser(response.data.user));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  getUserUrl(id: string): string {
    return `${this.getBaseUrl()}/users/${id}`;
  }

  async getUser(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.getUserUrl(id));

    try {
      const response: AxiosResponse<GetUserResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveUser(response.data.user));
    } catch (error) {
      if (error.response) {
        // check for deleted user
        if (error.response.status === 404) {
          logoutUser();
        }
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  updateUserUrl(): string {
    return `${this.getBaseUrl()}/users`;
  }

  async updateUser(form: UserUpdateForm): Promise<void> {
    const request = this.makeRequest('PUT', this.updateUserUrl(), form);

    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        if (error.response.status && error.response.status === 422) {
          throw 'Failed to update user, please fill in all the required fields';
        } else {
          throw error.response.data.message || error.response.data.error;
        }
      } else {
        throw error.message;
      }
    }
  }

  requestVerificationCodeUrl(): string {
    return `${this.getBaseUrl()}/verification-code`;
  }

  async requestVerificationCode(
    form: RequestVerificationForm
  ): Promise<RequestVerificationResponse> {
    const request = this.makeRequest(
      'POST',
      this.requestVerificationCodeUrl(),
      form,
      false
    );
    try {
      const response: AxiosResponse<RequestVerificationResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  verifyRegistrationUserUrl(): string {
    return `${this.getBaseUrl()}/verification-code`;
  }

  async verifyRegistrationUser(
    form: VerifyRegistrationUserForm,
    bearerToken: string
  ): Promise<void> {
    const request = this.makeRequest(
      'PUT',
      this.verifyRegistrationUserUrl(),
      form,
      true,
      bearerToken
    );
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  completeSelfRegistrationUrl(): string {
    return `${this.getBaseUrl()}/users/complete-self-registration`;
  }

  async completeSelfRegistration(form: CompleteSelfRegistrationForm): Promise<void> {
    const tempToken = localStorage.getItem('tempBearerToken');

    const request = this.makeRequest(
      'PUT',
      this.completeSelfRegistrationUrl(),
      { user: form },
      true,
      tempToken
    );

    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  sysConfirmUserUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/users/${id}/confirm`;
  }

  async sysConfirmUser(id: string): Promise<void> {
    const request = this.makeRequest('PUT', this.sysConfirmUserUrl(id));
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  updatePasswordUrl(): string {
    return `${this.getBaseUrl()}/profile`;
  }

  async updatePassword(form: UpdatePasswordForm): Promise<void> {
    const request = this.makeRequest('PUT', this.updatePasswordUrl(), form);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  sysDeleteAccountUrl(): string {
    return `${this.getBaseUrl()}/sys/users/delete`;
  }

  async sysDeleteAccount(payload: DeleteAccountPayload): Promise<void> {
    const request = this.makeRequest('DELETE', this.sysDeleteAccountUrl(), payload);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw (
          error.response.data.message ||
          error.response.data.error ||
          'Please try again in a few minutes.'
        );
      } else {
        throw error.message;
      }
    }
  }
}

export default AuthClient;
