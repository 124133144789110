import * as React from 'react';
import { connect } from 'react-redux';
import {
  CenterModal,
  MainContainer,
  StyledButton,
  TextInput,
  Searchbox,
  Checkbox,
  Alert,
  Breadcrumb,
  Typography,
  TotalDeliveryWeight,
} from 'components';
import TaskClient from 'httpClients/taskClient';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import * as H from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faCheck,
  faSearch,
  faExclamationCircle,
  faTimes,
  faPlus,
  faCheckCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
  Address,
  TasksAPIResponse,
  TaskCreationGroup,
  MerchantCreateTasksForm,
  MerchantCreateTaskForm,
  TimeWindow,
  AttachmentForm,
  Task,
} from 'models/task';
import COLOR from 'constants/color';
import { RootState } from 'reduxActions/store';
import { CurrentUser } from 'models/auth';
import { DefaultPriceForm, Organization, Squad } from 'models/organization';
import { formatError, generateUuid } from 'utils/formatter';
import {
  ONE_SGD,
  ALLOWED_MIN_PRICE,
  DEFAULT_SQUAD_PRICE,
  DEFAULT_MIN_PRICE,
  DEFAULT_MAX_PRICE,
} from 'constants/priceDetails';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import { ALLOWED_FILE_TYPE } from 'constants/attachmenfFile';
import { GeoData, GeoDistanceRequest, SavedGeoData } from 'models/geoService';
import GeoServiceClient from 'httpClients/geoServiceClient';
import configureStore from 'reduxActions/store';
import { receiveGeoDatas } from 'reduxActions/geoService/geoServiceActions';
import { receiveVesselAllSuccess } from 'reduxActions/vessel/vesselActions';
import PriceRetrievalClient from 'httpClients/priceRetrievalClient';
import { RecommendationPayload, RecommendationResponse } from 'models/priceRetrieval';
import { RouteComponentProps } from 'react-router-dom';
import * as moment from 'moment';
import TaskImportClient from 'httpClients/taskImportClient';
import { computeChecksumMd5 } from 'utils/computeChecksum';
import { fetchCurrentUser } from 'utils/userHelper';
import VesselClient from 'httpClients/vesselClient';
import AddVesselModal from '../sys/vessels/modal/addVesselModal';
import { Vessel } from 'models/vessel';
import { MerchantBaseForm, MerchantDeliveryForm, FileWithData } from 'models/task';
import { FileData } from 'models/taskImport';
import { AddressContactData } from '../sys/task/modal/addressContactModal';
import VehicleForm from '../sys/task/partialForm/vehicleForm';
import { getServicePricing, isItemExceedingVehicleDimensions } from 'utils/taskHelper';
import { DEFAULT_FTL_VEHICLE, DEFAULT_SERVICE_TYPE } from 'constants/serviceType';
import {
  ActionButtonContainer,
  OrderCard,
  OrderTitle,
  OrderIcon,
  Separator,
  AdditionalServiceContent,
  TitleDescription,
  RedText,
  Button,
  ErrorIcon,
  InlineErrorMessage,
  ActionContainer,
  ConfirmOrderModalButtons,
  ConfirmOrderModalText,
  ErrorModalText,
  AdditionalServiceCard,
  DateAndTimeContainer,
  DatePickerWrapper,
  EnterManualEmptyPlaceholderButton,
  EnterManualButton,
  ImoText,
  ImoInfoAlert,
  EmptyVesselPlaceholder,
  VesselCardRow,
  inlineTextInputStyle,
  Calendar,
  BottomMargin,
  RemoveCargoDetailsIcon,
  RemoveCargoDetailsSubTitle,
  DeliveryLocationPanel,
} from './styles';
import PickupForm from './cards/pickupForm';
import DeliveryForm from './cards/deliveryForm';
import GeneralInfoForm from './partialForm/generalInfoForm';
import PriceForm from './cards/priceForm';
import FilesUpload from './cards/filesUpload';
import DateHelper from 'utils/dateHelper';
import CargoDetails from './cards/cargoDetails';
import { VEHICLE_PREFERENCE_INFO } from 'constants/vehiclePreference';

interface StateProps {
  currentUser: CurrentUser;
  organization: Organization;
  squads: Squad[];
  geoDatas: GeoData[];
  savedGeoDatas: SavedGeoData[];
  vessels: Vessel[];
}

interface OrderNewProps<S = H.LocationState> extends StateProps {
  history: H.History<S>;
}

interface LocationState {
  vehiclePreference: string;
  broadcastPreference: string;
  minPrice: number;
  maxPrice: number;
  increment: number;
  rememberMySetting: boolean;
}

interface OrderDeclaration {
  correctAndComplete: boolean;
}

interface OrderNewState {
  baseForm: MerchantBaseForm;
  deliveryForms: MerchantDeliveryForm[];
  isSaving: boolean;
  isFetchingPrice: boolean;
  isFetchingVessel: boolean;
  orderDeclaration: OrderDeclaration;
  rememberMySetting: boolean;
  error: string | null;
  isSuccessful: boolean;
  showRecommendPriceSuccess: boolean;
  showRecommendPriceInfo: boolean;
  showRecommendPriceError: boolean;
  showSwitchViewModal: boolean;
  showConfirmOrderModal: boolean;
  showSwitchServiceWarningModal: string;
  showPickupAddVesselModal: boolean;
  showDeliveryAddVesselModal: boolean | number;
  hasCargoNet: boolean;
  hasOfficerContact: boolean;
  isFetchSavedAddress: boolean;
  showRemoveModal: {
    deliveryFormIndex: number | boolean;
    itemIndex: number;
    actionType: 'cargo_details' | 'items' | '';
  };
  showRemoveSuccessModal: string;
  totalWeight: number;
}

const CURRENT_DATE = new Date();

type Props = RouteComponentProps<object, object, LocationState> & OrderNewProps;

class OrderNew extends React.Component<Props, OrderNewState> {
  searchPickupTimeout: number | null | NodeJS.Timeout;
  searchDeliveryTimeout: number | null | NodeJS.Timeout;
  searchVesselTimeout: number | null | NodeJS.Timeout;
  defaultDeliveryRef: MerchantDeliveryForm;

  constructor(props: Props) {
    super(props);
    this.state = {
      baseForm: this.getDefaultBaseForm(),
      deliveryForms: [this.getDefaultDeliveryForm()],
      isSaving: false,
      isFetchingPrice: false,
      isFetchingVessel: false,
      orderDeclaration: {
        correctAndComplete: false,
      },
      rememberMySetting: props.location.state?.rememberMySetting || false,
      error: null,
      isSuccessful: false,
      showRecommendPriceSuccess: false,
      showRecommendPriceInfo: false,
      showRecommendPriceError: false,
      showSwitchViewModal: false,
      showConfirmOrderModal: false,
      showSwitchServiceWarningModal: '',
      showPickupAddVesselModal: false,
      showDeliveryAddVesselModal: false,
      hasCargoNet: false,
      hasOfficerContact: false,
      isFetchSavedAddress: false,
      showRemoveModal: {
        deliveryFormIndex: false,
        itemIndex: 0,
        actionType: '',
      },
      showRemoveSuccessModal: '',
      totalWeight: 0,
    };
    // Initialize the ref object
    this.defaultDeliveryRef = null;
  }

  async componentDidMount(): Promise<void> {
    // fetching the current user
    fetchCurrentUser();
    const client = new OrganizationManagementClient();
    await client.getOrganization();
    try {
      await client.getSquads(new URLSearchParams());
    } catch (error) {
      // continue to if no squads
    }
  }

  async componentDidUpdate(
    prevProps: OrderNewProps,
    prevState: OrderNewState
  ): Promise<void> {
    const { organization, squads } = this.props;
    if (prevProps.organization !== organization || prevProps.squads !== squads) {
      this.setState({
        baseForm: this.getDefaultBaseForm(),
      });
    }

    if (
      (!prevState.baseForm ||
        !prevState.baseForm.from_address ||
        prevState.baseForm.from_address.street_address === '') &&
      !this.state.isFetchSavedAddress
    ) {
      try {
        const geoClient = new GeoServiceClient();
        await geoClient.getSavedAddress(new URLSearchParams());
        this.getDefaultPickupSavedAddress();
        this.getDefaultDeliverySavedAddress();
        this.setState({ isFetchSavedAddress: true });
      } catch (error) {
        // continue to if no squads
      }
    }

    if (
      this.state.baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine &&
      prevState.totalWeight !== this.state.totalWeight &&
      this.state.baseForm.vehicle_preference in VEHICLE_PREFERENCE_INFO &&
      this.state.totalWeight >
        VEHICLE_PREFERENCE_INFO[this.state.baseForm.vehicle_preference].max_weight
    ) {
      this.setState((prevState) => ({
        ...prevState,
        baseForm: {
          ...prevState.baseForm,
          vehicle_preference: '',
        },
      }));
    }
  }

  getDefaultPickupSavedAddress(): MerchantBaseForm {
    const { savedGeoDatas } = this.props;
    let baseForm: MerchantBaseForm = this.state.baseForm;
    savedGeoDatas.map((savedAddress) => {
      if (savedAddress.is_default_pickup) {
        baseForm = {
          ...this.state.baseForm,
          from_address: {
            ...this.state.baseForm.from_address,
            name_address: savedAddress.name_address,
            building_name: savedAddress.building_name,
            street_address: savedAddress.street_address,
            latitude: savedAddress.lat || 0,
            longitude: savedAddress.lng || 0,
            zip_code: savedAddress.zip_code || '',
            unit_number: savedAddress.unit_number,
          },
          from_contact: {
            ...this.state.baseForm.to_contact,
            name: savedAddress.contact_name,
            phone: savedAddress.contact_phone,
            email: savedAddress.contact_email,
          },
        };
        this.setState({
          baseForm,
          showRecommendPriceSuccess: false,
          showRecommendPriceInfo: false,
        });
      }
    });
    return Object.assign({}, baseForm);
  }

  getDefaultDeliverySavedAddress(): void {
    const { savedGeoDatas } = this.props;
    const i = 0; // only first delivery address will be updated

    savedGeoDatas.map((savedAddress) => {
      if (savedAddress.is_default_delivery) {
        this.setState((prevState) => {
          const updatedDeliveryForms = [...prevState.deliveryForms];
          updatedDeliveryForms[i] = {
            ...prevState.deliveryForms[i],
            to_address: {
              ...prevState.deliveryForms[i].to_address,
              name_address: savedAddress.name_address,
              building_name: savedAddress.building_name,
              street_address: savedAddress.street_address,
              latitude: savedAddress.lat || 0,
              longitude: savedAddress.lng || 0,
              zip_code: savedAddress.zip_code || '',
              unit_number: savedAddress.unit_number,
            },
            to_contact: {
              ...updatedDeliveryForms[i].to_contact,
              name: savedAddress.contact_name,
              phone: savedAddress.contact_phone,
              email: savedAddress.contact_email,
            },
            distance_in_meters: 0,
          };
          this.defaultDeliveryRef = updatedDeliveryForms[i];
          return {
            deliveryForms: updatedDeliveryForms,
            showRecommendPriceSuccess: false,
            showRecommendPriceInfo: false,
          };
        });
      }
    });
  }

  getDefaultBaseForm(): MerchantBaseForm {
    const { organization: org, location } = this.props;
    let increment = ONE_SGD;
    if (location.state?.increment) {
      increment = location.state?.increment;
    } else {
      if (org?.price_table && org?.price_table.length > 1) {
        increment = org?.price_table[1].price - org?.price_table[0].price;
        if (increment <= ONE_SGD) {
          increment = ONE_SGD;
        } else if (increment <= ONE_SGD * 2) {
          increment = ONE_SGD * 2;
        } else if (increment <= ONE_SGD * 3) {
          increment = ONE_SGD * 3;
        }
      }
    }
    const propsService = location.state?.broadcastPreference;
    const propsVehiclePreference = location.state?.vehiclePreference || '';
    const squadsLength = this.props.squads.length;
    let defaultBroadcastPreference = org?.broadcast_preference || '';
    // changed to standard if default broadcast preference is squad but have no squad
    if (
      defaultBroadcastPreference === BROADCAST_PREFERENCES.squad &&
      squadsLength === 0
    ) {
      defaultBroadcastPreference = BROADCAST_PREFERENCES.all;
    }
    if (defaultBroadcastPreference === '') {
      defaultBroadcastPreference =
        squadsLength > 0 ? BROADCAST_PREFERENCES.squad : BROADCAST_PREFERENCES.all;
    }
    if (squadsLength > 0 && propsService) {
      defaultBroadcastPreference = propsService;
    }
    let defaultMinPrice = location.state?.minPrice || org?.min_price || DEFAULT_MIN_PRICE;
    let defaultMaxPrice = location.state?.maxPrice || org?.max_price || DEFAULT_MAX_PRICE;
    if (defaultBroadcastPreference === BROADCAST_PREFERENCES.squad) {
      defaultMinPrice = org?.squad_price || DEFAULT_SQUAD_PRICE;
      defaultMaxPrice = defaultMinPrice;
    }

    // set increment to 0 and retreive price for marine
    const servicePricing = getServicePricing(DEFAULT_SERVICE_TYPE, DEFAULT_FTL_VEHICLE);
    const priceValue = Math.floor(servicePricing.price * 100) * 1000;

    defaultMinPrice = priceValue;
    defaultMaxPrice = priceValue;

    const fromAddress = this.state?.baseForm?.from_address?.street_address
      ? this.state.baseForm.from_address
      : {
          building_name: '',
          name_address: '',
          street_address: '',
          city: 'Singapore',
          country: 'Singapore',
          state: 'Singapore',
          zip_code: '',
        };

    const fromContact = this.state?.baseForm?.from_contact
      ? this.state.baseForm.from_contact
      : {};

    return {
      from_address: { ...fromAddress },
      from_time_window: this.getDefaultPickupTimeWindow(),
      from_contact: { ...fromContact },
      min_price: defaultMinPrice,
      max_price: defaultMaxPrice,
      increment,
      broadcast_preference: BROADCAST_PREFERENCES.marine,
      vehicle_preference: propsVehiclePreference,
      service_type: DEFAULT_SERVICE_TYPE,
      pickup_note_to_driver: '',
      cargo_net_quantity: 0,
      officer_contact: {
        name: '',
        phone: '',
      },
      from_location_type: '',
    };
  }

  getDefaultDeliveryForm(): MerchantDeliveryForm {
    return {
      task_creation_group: { id: generateUuid(), index: 0 },
      attachment_files: [],
      to_address: {
        building_name: '',
        name_address: '',
        street_address: '',
        city: 'Singapore',
        country: 'Singapore',
        state: 'Singapore',
        zip_code: '',
      },
      tracking_id: '',
      to_time_window: this.getDefaultDeliveryTimeWindow(),
      distance_in_meters: 0,
      to_contact: {},
      items: [
        {
          name: '',
          quantity: undefined,
          has_hazard_mat: false,
          dimension: {
            length: null,
            width: null,
            height: null,
          },
        },
      ],
      delivery_note_to_driver: '',
      cargo_details: [
        {
          id: '',
          name: '',
          quantity: 1,
          quantity_unit: '',
          remarks: '',
          has_hazard_mat: false,
        },
      ],
      to_location_type: '',
    };
  }

  getDefaultPickupTimeWindow(): TimeWindow {
    return {
      start_timezone: 'Asia/Singapore',
      start_time_utc: CURRENT_DATE,
      end_timezone: 'Asia/Singapore',
      end_time_utc: this.addHoursToDateTime(1, CURRENT_DATE),
    };
  }

  getDefaultDeliveryTimeWindow(): TimeWindow {
    return {
      start_timezone: 'Asia/Singapore',
      start_time_utc: this.addHoursToDateTime(1, CURRENT_DATE),
      end_timezone: 'Asia/Singapore',
      end_time_utc: this.addHoursToDateTime(2, CURRENT_DATE),
    };
  }

  allowCreation = (): boolean => {
    return this.state.orderDeclaration.correctAndComplete && this.allowConfirmOrder();
  };

  addHoursToDateTime(hours: number, dateTime: Date): Date {
    return new Date(dateTime.getTime() + hours * 60 * 60 * 1000);
  }

  allowConfirmOrder = (): boolean => {
    const { baseForm, deliveryForms } = this.state;
    const { from_time_window: fromTimeWindow } = baseForm;

    let allowedMinPrice = ALLOWED_MIN_PRICE;
    if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      allowedMinPrice = 0;
    }

    let isPriceIncrementFilled = (baseForm.increment || 0) !== 0;
    if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      isPriceIncrementFilled = true;
    }

    const isBaseFormFulfilled =
      baseForm.min_price >= allowedMinPrice &&
      baseForm.max_price >= baseForm.min_price &&
      baseForm.from_address.street_address.trim().length > 0 &&
      baseForm.vehicle_preference !== '' &&
      DateHelper.isRangeValid(fromTimeWindow);

    let areDeliveryFormsFulfilled = true;
    for (let i = 0; i < deliveryForms.length; i++) {
      const { to_time_window: toTimeWindow } = deliveryForms[i];

      let checkCargoDetailsRequiredFields = true;
      let deliveryServiceType = true;
      let deliveryVehiclePreference = true;
      let locationType = true;
      if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
        // always use zero index for cargo details, due to the same cargo details is used for all delivery details
        checkCargoDetailsRequiredFields = !this.areCargoDetailsRequiredFieldsEmpty(0);
        deliveryServiceType = !!deliveryForms[i].service_type;
        deliveryVehiclePreference = !!deliveryForms[i].vehicle_preference;
        locationType =
          baseForm.from_location_type !== '' && deliveryForms[i].to_location_type !== '';
      }

      const isFormFulfilled =
        deliveryForms[i].to_address.street_address.trim().length > 0 &&
        DateHelper.isRangeValid(toTimeWindow) &&
        DateHelper.compareDateTime(
          baseForm.from_time_window.start_time_utc,
          deliveryForms[i].to_time_window.start_time_utc
        ) <= 0 &&
        DateHelper.compareDateTime(
          baseForm.from_time_window.end_time_utc,
          deliveryForms[i].to_time_window.end_time_utc
        ) <= 0 &&
        DateHelper.compareDateTime(baseForm.from_time_window.end_time_utc, new Date()) >
          0 &&
        checkCargoDetailsRequiredFields &&
        deliveryServiceType &&
        deliveryVehiclePreference &&
        locationType;

      if (!isFormFulfilled) {
        areDeliveryFormsFulfilled = false;
        break;
      }
    }

    return (
      isBaseFormFulfilled &&
      areDeliveryFormsFulfilled &&
      isPriceIncrementFilled &&
      !this.state.isSaving
    );
  };

  getPresignedUrl = async (id: string, fileName: string): Promise<string> => {
    const client = new TaskClient();
    return await client.getSignedAttachmentLink(id, fileName);
  };

  onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    deliveryFormIndex: number
  ): Promise<void> => {
    const file = e.target.files[0];
    let blobURL = null;
    if (file.type.includes('image') || file.type.includes('pdf')) {
      blobURL = URL.createObjectURL(file);
    }
    if (file) {
      const newFileData: FileData = {
        file_name: file.name,
        file_size: file.size,
        s3_upload_bucket: '',
        s3_upload_key: '',
        url: '',
        checksum: await computeChecksumMd5(file),
        checksum_method: 'MD5',
      };
      const fileWithData: FileWithData = {
        file: file,
        fileData: newFileData,
        prviewUrl: blobURL,
      };
      if (file.size > 20971520) {
        fileWithData.fileError = 'File size should not be more than 20MB';
      }
      if (!ALLOWED_FILE_TYPE.includes(file.type)) {
        fileWithData.fileError = 'Only accepts png, jpg, pdf, doc, docx, xlsx, csv.';
      }
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          ['attachment_files']:
            updatedDeliveryForms[deliveryFormIndex].attachment_files.concat(fileWithData),
        };
        return {
          deliveryForms: updatedDeliveryForms,
        };
      });
    }
  };

  uploadToS3 = async (filesWithData: FileWithData[]): Promise<void> => {
    try {
      const client = new TaskImportClient();
      for (const fileWithData of filesWithData) {
        await client.uploadToS3(fileWithData.fileData.url, fileWithData.file);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  updateBaseForm = (fieldName: string, value: string | boolean | Date): void => {
    if (typeof value === 'string') {
      switch (fieldName) {
        case 'broadcast_preference': {
          const org = this.props.organization;
          const minPrice = org?.min_price || DEFAULT_MIN_PRICE;
          const maxPrice = org?.max_price || DEFAULT_MAX_PRICE;
          let minMaxPrice = {
            min_price: minPrice,
            max_price: maxPrice,
          };
          let vehiclePreference = '';
          if (value === BROADCAST_PREFERENCES.squad) {
            minMaxPrice = {
              min_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
              max_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
            };
          } else if (value === BROADCAST_PREFERENCES.marine) {
            const servicePricing = getServicePricing(
              DEFAULT_SERVICE_TYPE,
              DEFAULT_FTL_VEHICLE
            );
            const priceValue = Math.floor(servicePricing.price * 100) * 1000;
            minMaxPrice = {
              min_price: priceValue,
              max_price: priceValue,
            };
            vehiclePreference = DEFAULT_FTL_VEHICLE;
          } else {
            vehiclePreference = '';
          }
          this.setState(
            (prevState) => ({
              baseForm: {
                ...prevState.baseForm,
                [fieldName]: value,
                ...minMaxPrice,
                service_type: DEFAULT_SERVICE_TYPE,
                vehicle_preference: vehiclePreference,
              },
              showRecommendPriceInfo: false,
              showRecommendPriceSuccess: false,
            }),
            () => {
              if (value === BROADCAST_PREFERENCES.all) {
                this.getDefaultPickupSavedAddress();
              }
            }
          );
          return;
        }
        case 'max_price':
        case 'min_price': {
          const priceValue = Math.floor(parseFloat(value) * 100) * 1000;
          let minMaxPrice = {
            [fieldName]: priceValue,
          };
          if (this.state.baseForm.broadcast_preference === BROADCAST_PREFERENCES.squad) {
            minMaxPrice = {
              [fieldName]: priceValue,
              max_price: priceValue,
            };
          }
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              ...minMaxPrice,
            },
          }));
          return;
        }
        case 'increment': {
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              increment: parseInt(value),
            },
          }));
          return;
        }
        case 'cargo_net_quantity': {
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              cargo_net_quantity: parseInt(value),
            },
          }));
          return;
        }
        case 'service_type': {
          let vehiclePreference = '';
          if (value === 'ltl') {
            vehiclePreference = 'ltl';
          }
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              service_type: value,
              vehicle_preference: vehiclePreference,
            },
          }));
          return;
        }
        case 'from_location_type': {
          let prevBaseForm = this.state.baseForm;
          if (value === 'sea') {
            prevBaseForm = {
              ...this.state.baseForm,
              from_address: {
                building_name: '',
                name_address: '',
                street_address: '',
                city: 'Singapore',
                country: 'Singapore',
                state: 'Singapore',
                zip_code: '',
              },
              from_contact: {},
            };
          }
          this.setState(
            (prevState) => ({
              ...prevState,
              baseForm: {
                ...prevState.baseForm,
                ...prevBaseForm,
                from_location_type: value,
              },
              fromLocationSelected: false,
            }),
            () => {
              if (value === 'land') {
                this.getDefaultPickupSavedAddress();
              }
            }
          );
          return;
        }
      }
    }
    this.setState((prevState) => {
      if (fieldName.indexOf('.') !== -1) {
        const fields: string[] = fieldName.split('.');
        return {
          baseForm: {
            ...prevState.baseForm,
            [fields[0]]: {
              ...prevState.baseForm[fields[0]],
              [fields[1]]: value,
            },
          },
        };
      } else {
        return {
          baseForm: {
            ...prevState.baseForm,
            [fieldName]: value,
          },
        };
      }
    });
  };

  updateDeliveryForm = (
    fieldName: string,
    value: string | Date | boolean | number,
    deliveryFormIndex: number
  ): void => {
    this.setState(
      (prevState) => {
        let shouldRecalculateTotalWeight = false;
        const updatedDeliveryForms = [...prevState.deliveryForms];
        if (fieldName.indexOf('.') !== -1) {
          const fields: string[] = fieldName.split('.');
          if (fields[0].indexOf('[') >= 0) {
            const arrayInfo: string[] = fields[0].split('[');
            const itemIdx = arrayInfo[1].slice(0, -1);
            const newArray = [
              ...prevState.deliveryForms[deliveryFormIndex][arrayInfo[0]],
            ];
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              [arrayInfo[0]]: newArray,
            };

            if (
              arrayInfo[0] === 'items' &&
              value !== '' &&
              (fields[1] === 'weight' || fields[1] === 'quantity')
            ) {
              shouldRecalculateTotalWeight = true;
            }

            if (
              (arrayInfo[0] === 'items' || arrayInfo[0] === 'cargo_details') &&
              value === '' &&
              (fields[1] === 'weight' || fields[1] === 'volume')
            ) {
              delete newArray[parseInt(itemIdx)][fields[1]];
              if (fields[1] === 'weight') {
                newArray[parseInt(itemIdx)]['weight_unit'] = '';
              }
            } else {
              if (fields.length === 2) {
                newArray[parseInt(itemIdx)][fields[1]] = value;
                if (fields[1] === 'weight' && value !== '') {
                  // set to 1 if quantity is not set
                  if (!newArray[parseInt(itemIdx)]['quantity']) {
                    newArray[parseInt(itemIdx)]['quantity'] = 1;
                  }
                  newArray[parseInt(itemIdx)]['weight_unit'] = 'kg';
                }
              } else if (fields.length === 3) {
                newArray[parseInt(itemIdx)][fields[1]] = {
                  ...newArray[parseInt(itemIdx)][fields[1]],
                  [fields[2]]: value,
                };
              }
            }
          } else if (fields[0] in prevState.deliveryForms[deliveryFormIndex]) {
            let fieldValue = value;
            if (fields.length === 3) {
              fieldValue = {
                ...updatedDeliveryForms[deliveryFormIndex][fields[0]][fields[1]],
                [fields[2]]: value || null,
              };
            }
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              [fields[0]]: {
                ...updatedDeliveryForms[deliveryFormIndex][fields[0]],
                [fields[1]]: fieldValue,
              },
            };
          }
        } else {
          if (fieldName === 'to_location_type' && value === 'sea') {
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              to_address: this.getDefaultDeliveryForm().to_address,
              to_contact: {
                ...updatedDeliveryForms[deliveryFormIndex].to_contact,
                ...this.getDefaultDeliveryForm().to_contact,
              },
              to_location_type: value,
            };
          } else {
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              [fieldName]: value,
            };
          }
        }
        return {
          deliveryForms: updatedDeliveryForms,
          totalWeight: shouldRecalculateTotalWeight
            ? this.calculateTotalItemsWeight(updatedDeliveryForms)
            : prevState.totalWeight,
        };
      },
      () => {
        if (value === 'land' && deliveryFormIndex === 0) {
          this.getDefaultDeliverySavedAddress();
        }
      }
    );
  };

  handleRememberPrice = async (form: DefaultPriceForm): Promise<void> => {
    const client = new OrganizationManagementClient();
    await client.updateDefaultPrice(form);
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (this.allowCreation()) {
      this.setState({ error: null, isSaving: true });
      const { baseForm, deliveryForms } = this.state;

      try {
        const client = new TaskClient();
        const tasksForm: MerchantCreateTasksForm = {
          tasks: deliveryForms.map(
            (
              deliveryForm: MerchantDeliveryForm,
              deliveryIndex: number
            ): MerchantCreateTaskForm => {
              const prefixID =
                (this.props.organization && this.props.organization.prefix_id) ||
                'GOTSURGE-';
              const minPrice = baseForm.min_price;
              const maxPrice = baseForm.max_price;

              const form: MerchantCreateTaskForm = {
                broadcast_preference: baseForm.broadcast_preference,
                vehicle_preference: baseForm.vehicle_preference,
                service_type: baseForm.service_type,
                from_address: baseForm.from_address,
                from_contact: baseForm.from_contact,
                from_time_window: baseForm.from_time_window,
                pickup_note_to_driver: baseForm.pickup_note_to_driver,
                to_address: deliveryForm.to_address,
                to_contact: deliveryForm.to_contact,
                to_time_window: deliveryForm.to_time_window,
                delivery_note_to_driver: deliveryForm.delivery_note_to_driver,
                tracking_id:
                  deliveryForm.tracking_id !== ''
                    ? prefixID + deliveryForm.tracking_id
                    : '',
                min_price: minPrice,
                max_price: maxPrice,
                increment: baseForm.increment,
                currency: 'SGD',
                distance_in_meters: deliveryForm.distance_in_meters,
                client_time_utc: new Date(),
                client_timezone: 'Asia/Singapore',
                items: deliveryForm.items,
                group_tag: '',
                cargo_net_quantity: baseForm.cargo_net_quantity,
                officer_contact: baseForm.officer_contact,
                org_name: this.props.organization.business_name,
                task_creation_group: {
                  id: deliveryForm.task_creation_group.id,
                  index: deliveryIndex,
                },
                is_qa: this.props.organization.is_qa,
              };

              for (let i = form.items.length - 1; i >= 0; i--) {
                if (form.items[i].name.trim().length === 0) {
                  form.items.splice(i, 1);
                } else {
                  // add default value 1 when quantity is not filled or negative
                  if (!form.items[i].quantity || form.items[i].quantity <= 0) {
                    form.items[i].quantity = 1;
                  }
                }
              }
              return form;
            }
          ),
        };

        const tasksResponse: TasksAPIResponse = await client.createTasks(tasksForm);

        const taskHash = Object.create(null);
        const taskResponse = tasksResponse.tasks.reduce(
          (taskReponse: Task[], currentValue: Task) => {
            const newArrayKey = currentValue.task_creation_group.index;
            if (!taskHash[newArrayKey]) {
              taskHash[newArrayKey] = currentValue;
              taskReponse.push(taskHash[newArrayKey]);
            }
            return taskReponse;
          },
          []
        );

        await Promise.all(
          deliveryForms.map(
            async (deliveryForm: MerchantDeliveryForm, deliveryIndex: number) => {
              if (taskResponse[deliveryIndex]) {
                if (
                  deliveryForm.attachment_files.length > 0 &&
                  baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine
                ) {
                  const taskAttachment: AttachmentForm[] = [];
                  for (let i = 0; i < deliveryForm.attachment_files.length; i++) {
                    if (!deliveryForm.attachment_files[i].fileError) {
                      deliveryForm.attachment_files[i].fileData.url =
                        await this.getPresignedUrl(
                          taskResponse[deliveryIndex].id,
                          deliveryForm.attachment_files[i].file.name
                        );
                      taskAttachment.push({
                        name: deliveryForm.attachment_files[i].file.name,
                        url: deliveryForm.attachment_files[i].fileData.url,
                      });
                    }
                  }
                  await this.uploadToS3(
                    deliveryForm.attachment_files.filter(
                      (attachment) => attachment.fileData.url !== ''
                    )
                  );
                  await client.addAttachment(taskResponse[deliveryIndex].id, {
                    attachments: taskAttachment,
                    version_rev: taskResponse[deliveryIndex].version_rev,
                  });
                }
              }
            }
          )
        );
        await client.broadcastTask();

        if (this.state.rememberMySetting) {
          const org = this.props.organization;
          const orgForm: DefaultPriceForm = {
            min_price: baseForm.min_price,
            max_price: baseForm.max_price,
            squad_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
            version_rev: org?.version_rev,
            increment: baseForm.increment,
          };
          if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.squad) {
            orgForm.squad_price = baseForm.min_price;
            orgForm.min_price = org?.min_price;
            orgForm.max_price = org?.max_price;
          }
          this.handleRememberPrice(orgForm);
        }

        this.setState({ showConfirmOrderModal: false, isSuccessful: true });
      } catch (e) {
        this.setState({
          error: e !== undefined ? formatError(e) : 'Network Error',
          showConfirmOrderModal: false,
          isSuccessful: false,
        });
      }
    } else if (this.state.showConfirmOrderModal) {
      this.setState({
        error: 'There was an error processing your order.',
        showConfirmOrderModal: false,
        isSuccessful: false,
      });
    }
  };

  handleSubmitMarineOrders = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (this.allowCreation()) {
      this.setState({ error: null, isSaving: true });
      const { baseForm, deliveryForms } = this.state;
      try {
        const client = new TaskClient();

        // vessel info
        const firstDeliveryContact = deliveryForms[0].to_contact;
        const { etb_time_window, etu_time_window, vessel_name, vessel_imo, vessel_id } =
          firstDeliveryContact;
        const vesselInfo = {
          ...(etb_time_window && { etb_time_window }),
          ...(etu_time_window && { etu_time_window }),
          ...(vessel_name && { vessel_name }),
          ...(vessel_imo && { vessel_imo }),
          ...(vessel_id && { vessel_id }),
        };

        if (vesselInfo.etb_time_window) {
          vesselInfo.etb_time_window.start_timezone = 'Asia/Singapore';
          vesselInfo.etb_time_window.end_timezone = 'Asia/Singapore';
        }

        if (vesselInfo.etu_time_window) {
          vesselInfo.etu_time_window.start_timezone = 'Asia/Singapore';
          vesselInfo.etu_time_window.end_timezone = 'Asia/Singapore';
        }

        const taskCreationGroup: TaskCreationGroup = {
          id: generateUuid(),
          index: 0,
        };

        const isDeliveryMoreThanOne = deliveryForms.length > 1;

        const tasksForm: MerchantCreateTasksForm = {
          tasks: deliveryForms.map(
            (deliveryForm, deliveryIndex): MerchantCreateTaskForm => {
              const prefixID =
                (this.props.organization && this.props.organization.prefix_id) ||
                'GOTSURGE-';

              let form: MerchantCreateTaskForm = {
                broadcast_preference: baseForm.broadcast_preference,
                vehicle_preference: deliveryForm.vehicle_preference,
                service_type: deliveryForm.service_type,
                // pickup detail
                from_address: baseForm.from_address,
                from_contact: baseForm.from_contact,
                from_time_window: baseForm.from_time_window,
                pickup_note_to_driver: baseForm.pickup_note_to_driver,
                // delivery detail
                to_address: deliveryForm.to_address,
                to_contact: {
                  ...vesselInfo,
                  ...deliveryForm.to_contact,
                },
                to_time_window: deliveryForm.to_time_window,
                delivery_note_to_driver: deliveryForm.delivery_note_to_driver,
                tracking_id:
                  deliveryForm.tracking_id !== ''
                    ? prefixID + deliveryForm.tracking_id
                    : '',
                min_price: deliveryForm.min_price,
                max_price: deliveryForm.max_price,
                increment: 0,
                currency: 'SGD',
                distance_in_meters: deliveryForm.distance_in_meters,
                client_time_utc: new Date(),
                client_timezone: 'Asia/Singapore',
                items: [],
                group_tag: '',
                cargo_net_quantity: baseForm.cargo_net_quantity,
                officer_contact: baseForm.officer_contact,
                org_name: this.props.organization.business_name,
                ...(isDeliveryMoreThanOne && {
                  task_creation_group: {
                    ...taskCreationGroup,
                    index: deliveryIndex,
                  },
                }),
                is_qa: this.props.organization.is_qa,
                cargo_details: deliveryForms[0].cargo_details,
                from_location_type: baseForm.from_location_type,
                to_location_type: deliveryForms[deliveryIndex].to_location_type,
              };

              if (deliveryIndex > 0) {
                const { to_contact: contact } = form;
                const { name, phone, vehicle_name, company_name, berth_no } =
                  deliveryForms[deliveryIndex - 1].to_contact;
                const fromContact = {
                  name,
                  phone,
                  vehicle_name,
                  company_name,
                  berth_no,
                };
                form = {
                  ...form,
                  // Pickup detail
                  from_address: deliveryForms[deliveryIndex - 1].to_address,
                  from_contact: fromContact,
                  from_time_window: deliveryForms[deliveryIndex - 1].to_time_window,
                  pickup_note_to_driver:
                    deliveryForms[deliveryIndex - 1].delivery_note_to_driver,
                  to_contact: {
                    // copy vessel name, ETA, ETD
                    ...vesselInfo,
                    ...contact,
                  },
                  from_location_type: deliveryForms[deliveryIndex - 1].to_location_type,
                };
              }

              return form;
            }
          ),
        };

        const tasksResponse: TasksAPIResponse = await client.createTasks(tasksForm);

        const taskHash = Object.create(null);
        const taskResponse = tasksResponse.tasks.reduce(
          (taskReponse: Task[], currentValue: Task) => {
            const newArrayKey = currentValue.task_creation_group.index;
            if (!taskHash[newArrayKey]) {
              taskHash[newArrayKey] = currentValue;
              taskReponse.push(taskHash[newArrayKey]);
            }
            return taskReponse;
          },
          []
        );

        // Upload attachments
        if (taskResponse.length > 0 && deliveryForms[0].attachment_files.length) {
          const taskAttachments: AttachmentForm[] = [];
          for (let i = 0; i < deliveryForms[0].attachment_files.length; i++) {
            // get presigned url with identifier of the task group
            if (!deliveryForms[0].attachment_files[i].fileError) {
              const identifier = isDeliveryMoreThanOne
                ? `gs-${taskResponse[0].task_creation_group.id}`
                : taskResponse[0].id;
              deliveryForms[0].attachment_files[i].fileData.url =
                await this.getPresignedUrl(
                  identifier,
                  deliveryForms[0].attachment_files[i].file.name
                );
              taskAttachments.push({
                name: deliveryForms[0].attachment_files[i].file.name,
                url: deliveryForms[0].attachment_files[i].fileData.url,
              });
            }
          }
          // upload
          await this.uploadToS3(
            deliveryForms[0].attachment_files.filter(
              (attachment) => attachment.fileData.url !== ''
            )
          );

          // update task with uploaded attachments
          await Promise.all(
            taskResponse.map(async (task: Task) => {
              if (taskAttachments.length > 0) {
                await client.addAttachment(task.id, {
                  attachments: taskAttachments,
                  version_rev: task.version_rev,
                });
              }
            })
          );
        }

        await client.broadcastTask();
        this.setState({ showConfirmOrderModal: false, isSuccessful: true });
      } catch (e) {
        this.setState({
          error: e !== undefined ? formatError(e) : 'Network Error',
          showConfirmOrderModal: false,
          isSuccessful: false,
        });
      }
    } else if (this.state.showConfirmOrderModal) {
      this.setState({
        error: 'There was an error processing your order.',
        showConfirmOrderModal: false,
        isSuccessful: false,
      });
    }
  };

  areCargoDetailsRequiredFieldsEmpty = (deliveryFormIndex: number): boolean => {
    const cargoDetails = this.state.deliveryForms[deliveryFormIndex].cargo_details;

    return cargoDetails.reduce((acc, detail) => {
      const isNameEmpty = detail.name.trim() === '';
      const isQuantityEmpty = isNaN(detail.quantity) || detail.quantity <= 0;
      return acc || isNameEmpty || isQuantityEmpty;
    }, false);
  };

  isLocationValid = (geoData: GeoData): boolean => {
    return (
      geoData.street_address !== '' &&
      geoData.lat !== 0 &&
      geoData.lng !== 0 &&
      geoData.lat !== undefined &&
      geoData.lng !== undefined
    );
  };

  isAddressLocationValid = (address: Address): boolean =>
    this.isLocationValid({
      street_address: address.street_address,
      zip_code: address.zip_code,
      lat: address.latitude,
      lng: address.longitude,
    });

  recommendPrice = async (): Promise<void> => {
    const { baseForm, deliveryForms } = this.state;
    const isPickupLocationValid = this.isAddressLocationValid(baseForm.from_address);
    let areDeliveryLocationsValid = true;
    for (let i = 0; i < deliveryForms.length; i++) {
      if (!this.isAddressLocationValid(deliveryForms[i].to_address)) {
        areDeliveryLocationsValid = false;
        break;
      }
    }
    if (!isPickupLocationValid || !areDeliveryLocationsValid) {
      this.setState({ showRecommendPriceInfo: true });
      return;
    }

    this.setState({ showRecommendPriceSuccess: false, isFetchingPrice: true });
    const stops = [
      {
        location: {
          lat: baseForm.from_address.latitude,
          lng: baseForm.from_address.longitude,
        },
        distance_in_meters: 0,
        addresses: {
          en_SG: {
            display_string: baseForm.from_address.street_address,
            market: 'SG_SIN',
          },
        },
      },
    ];
    deliveryForms.forEach((deliveryForm) => {
      stops.push({
        location: {
          lat: deliveryForm.to_address.latitude,
          lng: deliveryForm.to_address.longitude,
        },
        distance_in_meters: deliveryForm.distance_in_meters,
        addresses: {
          en_SG: {
            display_string: deliveryForm.to_address.street_address,
            market: 'SG_SIN',
          },
        },
      });
    });
    const payload: RecommendationPayload = {
      service_type: baseForm.vehicle_preference,
      stops: stops,
    };
    try {
      const client = new PriceRetrievalClient();
      const resp: RecommendationResponse = await client.recommendation(payload);
      const averageFee = resp.average_fee;
      const recommendedPrice =
        averageFee < ALLOWED_MIN_PRICE ? ALLOWED_MIN_PRICE : averageFee;

      if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.all) {
        // price range
        const twoSGD = ONE_SGD * 2;
        let minPrice = recommendedPrice - twoSGD;
        let maxPrice = recommendedPrice + twoSGD;

        if (recommendedPrice - twoSGD < twoSGD) {
          // if below 2 SGD price range will be 2 SGD -> 4 SGD
          minPrice = twoSGD;
          maxPrice = twoSGD * 2;
        }
        this.updateBaseForm('min_price', Math.round(minPrice / ONE_SGD).toString());
        this.updateBaseForm('max_price', Math.round(maxPrice / ONE_SGD).toString());
      } else {
        // single price
        this.updateBaseForm(
          'min_price',
          Math.round(recommendedPrice / ONE_SGD).toString()
        );
      }
      this.setState({
        showRecommendPriceSuccess: true,
        isFetchingPrice: false,
      });
    } catch (e) {
      this.setState({ showRecommendPriceError: true });
      setTimeout(() => {
        this.setState({
          showRecommendPriceError: false,
          isFetchingPrice: false,
        });
      }, 5000);
    }
  };

  fetchAddresses = async (searchKey: string): Promise<void> => {
    this.clearAddresses();
    if (searchKey.length >= 3) {
      const client = new GeoServiceClient();
      await client.getGeoCode(searchKey);
    }
  };

  clearAddresses = (): void => {
    const store = configureStore();
    store.dispatch(receiveGeoDatas([]));
  };

  searchPickupAddress = (): void => {
    clearTimeout(this.searchPickupTimeout);

    this.searchPickupTimeout = setTimeout(() => {
      this.fetchAddresses(this.state.baseForm.from_address.street_address);
    }, 700);
  };

  selectPickupAddress = (streetAddress: string, geoData: GeoData): void => {
    this.setState((prevState) => ({
      baseForm: {
        ...prevState.baseForm,
        from_address: {
          ...prevState.baseForm.from_address,
          street_address: streetAddress,
          building_name: geoData.building_name,
          latitude: geoData.lat,
          longitude: geoData.lng,
          zip_code: geoData.zip_code,
        },
      },
      showRecommendPriceSuccess: false,
      showRecommendPriceInfo: false,
    }));
  };

  clearPickupAddress = (): void => {
    this.setState((prevState) => ({
      baseForm: {
        ...prevState.baseForm,
        from_address: {
          ...prevState.baseForm.from_address,
          latitude: 0,
          longitude: 0,
          zip_code: '',
        },
      },
      showRecommendPriceSuccess: false,
      showRecommendPriceInfo: false,
    }));
  };

  getDistance = async (geoData: GeoData): Promise<number> => {
    const { baseForm } = this.state;
    if (
      baseForm.from_address.latitude == undefined ||
      baseForm.from_address.longitude == undefined
    ) {
      // if base address is not valid, set distance to 0
      return 0;
    }
    try {
      const geoClient = new GeoServiceClient();
      const geoDistanceRequest: GeoDistanceRequest = {
        from_address: {
          street_address: baseForm.from_address.street_address,
          zip_code: baseForm.from_address.zip_code,
          lat: baseForm.from_address.latitude,
          lng: baseForm.from_address.longitude,
        },
        to_address: {
          street_address: geoData.street_address,
          zip_code: geoData.zip_code,
          lat: geoData.lat,
          lng: geoData.lng,
        },
      };

      const geoResponse = await geoClient.geoDistance(geoDistanceRequest);
      return geoResponse.distance_in_meters;
    } catch (e) {
      // e
    }
  };

  selectDeliveryAddress = async (
    i: number,
    streetAddress: string,
    geoData: GeoData
  ): Promise<void> => {
    const distance = (await this.getDistance(geoData)) || 0;
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[i] = {
        ...prevState.deliveryForms[i],
        to_address: {
          ...prevState.deliveryForms[i].to_address,
          street_address: streetAddress,
          building_name: geoData.building_name,
          latitude: geoData.lat,
          longitude: geoData.lng,
          zip_code: geoData.zip_code,
        },
        distance_in_meters: distance,
      };
      return {
        deliveryForms: updatedDeliveryForms,
        showRecommendPriceSuccess: false,
        showRecommendPriceInfo: false,
      };
    });
  };

  clearDeliveryAddress = (i: number): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[i] = {
        ...prevState.deliveryForms[i],
        to_address: {
          ...prevState.deliveryForms[i].to_address,
          street_address: '',
          latitude: 0,
          longitude: 0,
          zip_code: '',
        },
        distance_in_meters: 0,
      };
      return {
        deliveryForms: updatedDeliveryForms,
        showRecommendPriceSuccess: false,
        showRecommendPriceInfo: false,
      };
    });
  };

  searchDeliveryAddress = (i: number): void => {
    clearTimeout(this.searchDeliveryTimeout);

    this.searchDeliveryTimeout = setTimeout(() => {
      this.fetchAddresses(this.state.deliveryForms[i].to_address.street_address);
    }, 700);
  };

  onSelectPickupAddress = (geoData: GeoData): void => {
    this.selectPickupAddress(geoData.street_address, geoData);
  };

  onSelectDeliveryAddress = (i: number, geoData: GeoData): void => {
    this.selectDeliveryAddress(i, geoData.street_address, geoData);
  };

  fetchVessels = async (searchKey: string): Promise<void> => {
    this.clearVessels();
    if (searchKey && searchKey.length >= 3) {
      this.setState({ isFetchingVessel: true });
      const client = new VesselClient();
      await client.getVessels(
        new URLSearchParams({
          term: searchKey.toUpperCase(),
          is_party_search: 'true',
        })
      );
      this.setState({ isFetchingVessel: false });
    }
  };

  chooseSavedAddress = async (
    type: 'pickup' | 'delivery',
    index: number,
    data: SavedGeoData
  ): Promise<void> => {
    if (type === 'pickup') {
      this.setState((prevState) => ({
        baseForm: {
          ...prevState.baseForm,
          from_address: {
            ...prevState.baseForm.from_address,
            name_address: data.name_address,
            building_name: data.building_name,
            street_address: data.street_address,
            latitude: data.lat || 0,
            longitude: data.lng || 0,
            zip_code: data.zip_code || '',
            unit_number: data.unit_number,
          },
          from_contact: {
            ...prevState.baseForm.to_contact,
            name: data.contact_name,
            phone: data.contact_phone,
            email: data.contact_email,
          },
        },
      }));
    } else {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        updatedDeliveryForms[index] = {
          ...updatedDeliveryForms[index],
          to_address: {
            ...updatedDeliveryForms[index].to_address,
            name_address: data.name_address,
            building_name: data.building_name,
            street_address: data.street_address,
            latitude: data.lat || 0,
            longitude: data.lng || 0,
            zip_code: data.zip_code || '',
            unit_number: data.unit_number,
          },
          to_contact: {
            ...updatedDeliveryForms[index].to_contact,
            name: data.contact_name,
            phone: data.contact_phone,
            email: data.contact_email,
          },
        };
        return {
          deliveryForms: updatedDeliveryForms,
        };
      });
    }
  };

  clearVessels = (): void => {
    const store = configureStore();
    store.dispatch(receiveVesselAllSuccess([]));
  };

  searchPickupVessels = (): void => {
    clearTimeout(this.searchVesselTimeout);

    this.searchVesselTimeout = setTimeout(() => {
      this.fetchVessels(this.state.baseForm.from_contact.vessel_name);
    }, 700);
  };

  clearPickupVessels = (clearName: boolean): void => {
    this.setState((prevState) => {
      const fromContact = prevState.baseForm.from_contact;
      delete fromContact.vessel_id;
      delete fromContact.vessel_imo;
      if (clearName) {
        fromContact.vessel_name = '';
      }

      return {
        baseForm: {
          ...prevState.baseForm,
          from_contact: fromContact,
        },
      };
    });
  };

  selectPickupVessels = (id: string, vessel: Vessel): void => {
    this.setState((prevState) => ({
      baseForm: {
        ...prevState.baseForm,
        from_contact: {
          ...prevState.baseForm.from_contact,
          vessel_name: vessel.name,
          vessel_id: id,
          vessel_imo: vessel.imo,
        },
      },
    }));
  };

  searchDeliveryVessels = (index: number): void => {
    clearTimeout(this.searchVesselTimeout);

    this.searchVesselTimeout = setTimeout(() => {
      this.fetchVessels(this.state.deliveryForms[index].to_contact.vessel_name);
    }, 700);
  };

  selectDeliveryVessels = (index: number, id: string, vessel: Vessel): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[index] = {
        ...updatedDeliveryForms[index],
        to_contact: {
          ...updatedDeliveryForms[index].to_contact,
          vessel_id: id,
          vessel_imo: vessel.imo,
          vessel_name: vessel.name,
        },
      };
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  clearDeliveryVessels = (index: number, clearName: boolean): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      const toContact = updatedDeliveryForms[index].to_contact;
      delete toContact.vessel_id;
      delete toContact.vessel_imo;
      if (clearName) {
        toContact.vessel_name = '';
      }
      updatedDeliveryForms[index] = {
        ...updatedDeliveryForms[index],
        to_contact: toContact,
      };
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  shouldShowWarningDataWillBeLost = (): boolean => {
    const { baseForm, deliveryForms } = this.state;
    const deliveryForm = deliveryForms[0];

    // get the base form value to compare to the current state
    const defaultPickup = this.getDefaultBaseForm();
    const defaultPickupSavedAddress = this.getDefaultPickupSavedAddress();
    const defaultDelivery = this.getDefaultDeliveryForm();
    const defaultDeliverySavedAddress = this.defaultDeliveryRef;

    // get saved address
    const pickupDefault = this.props.savedGeoDatas.filter(
      (savedAddress) => savedAddress.is_default_pickup
    );
    const deliveryDefault = this.props.savedGeoDatas.filter(
      (savedAddress) => savedAddress.is_default_delivery
    );

    // use saved address if exist, if no then use default base form
    const defaultPickupForm =
      pickupDefault.length === 0 ? defaultPickup : defaultPickupSavedAddress;
    const defaultDeliveryForm =
      deliveryDefault.length === 0 ? defaultDelivery : defaultDeliverySavedAddress;

    // compare the changes
    const isPickupAddressChanged =
      JSON.stringify(defaultPickupForm.from_address) !==
      JSON.stringify(baseForm.from_address);
    const isPickupContactChanged =
      JSON.stringify(defaultPickupForm.from_contact) !==
      JSON.stringify(baseForm.from_contact);
    const isDeliveryAddressChanged =
      JSON.stringify(defaultDeliveryForm.to_address) !==
      JSON.stringify(deliveryForm.to_address);
    const isDeliveryContactChanged =
      JSON.stringify(defaultDeliveryForm.to_contact) !==
      JSON.stringify(deliveryForm.to_contact);

    const isDeliveryMoreThanOne = deliveryForms.length > 1;

    const result =
      isPickupAddressChanged ||
      isPickupContactChanged ||
      isDeliveryAddressChanged ||
      isDeliveryContactChanged ||
      isDeliveryMoreThanOne;

    return result;
  };

  renderGeneralInfoForm = (): React.ReactNode => {
    const { baseForm, deliveryForms } = this.state;
    const i = 0;
    return (
      <GeneralInfoForm
        form={{
          delivery_date: this.state.baseForm.delivery_date,
          broadcast_preference: this.state.baseForm.broadcast_preference,
        }}
        onFormChange={(fieldName: string, value: string | Date): void => {
          if (
            fieldName === 'broadcast_preference' &&
            typeof value === 'string' &&
            this.state.baseForm.broadcast_preference !== value &&
            this.shouldShowWarningDataWillBeLost()
          ) {
            this.setState({ showSwitchServiceWarningModal: value });
          } else {
            this.updateBaseForm(fieldName, value);
          }
        }}
        hasNoSquad={this.props.squads.length === 0}
        vesselForm={
          <>
            {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
              <>
                <OrderTitle>Mother Vessel</OrderTitle>
                <VesselCardRow>
                  <Searchbox
                    key={`to_contact_vessel_name${i}`}
                    inputName="to_contact_vessel_name"
                    trailingIcon={
                      this.state.deliveryForms[i].to_contact.vessel_name
                        ? faTimes
                        : faSearch
                    }
                    containerStyle={inlineTextInputStyle}
                    fieldName="Vessel Name"
                    bottomLabel={this.renderDeliveryVesselBottomLabel(i)}
                    trailingIconOnClick={(): void => {
                      this.clearDeliveryVessels(i, true);
                      this.searchDeliveryVessels(i);
                    }}
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm('to_contact.vessel_name', value, i);
                      this.searchDeliveryVessels(i);
                      this.clearDeliveryVessels(i, false);
                    }}
                    handleSetFocus={(focused: boolean): void => {
                      if (focused) {
                        this.clearVessels();
                        this.searchDeliveryVessels(i);
                      } else {
                        const toContact = this.state.deliveryForms[i].to_contact;
                        if (toContact.vessel_name && !toContact.vessel_imo) {
                          this.updateDeliveryForm('to_contact.vessel_name', '', i);
                        }
                      }
                    }}
                    handleSelectChange={(value: string, vessel: Vessel): void => {
                      this.selectDeliveryVessels(i, value, vessel);
                    }}
                    placeholder="Search vessel name or IMO"
                    width="large"
                    value={this.state.deliveryForms[i].to_contact.vessel_name}
                    options={(this.props.vessels || []).map((vessel) => ({
                      value: vessel.id,
                      text: vessel.name,
                      additionalText: `IMO ${vessel.imo}`,
                      additionalValue: {
                        id: vessel.id,
                        imo: vessel.imo,
                        name: vessel.name,
                      },
                    }))}
                    emptyPlaceholder={this.renderDeliveryVesselEmptyPlaceholder(i)}
                  />
                  <DateAndTimeContainer alignItems="flex-start">
                    {/* Formerly Estimated Time of Berthing (ETB) */}
                    <DatePickerWrapper
                      marginRight="10px"
                      width="13.5rem"
                      minDate={moment().toDate()}
                      dateFormat="dd MMM, hh:mm a"
                      onChange={(value: Date): void => {
                        this.updateDeliveryForm(
                          'to_contact.etb_time_window.start_time_utc',
                          value,
                          i
                        );
                        this.updateDeliveryForm(
                          'to_contact.etb_time_window.end_time_utc',
                          moment(value).add(1, 'hour').toDate(),
                          i
                        );
                      }}
                      onSelect={(value: Date): void => {
                        this.updateDeliveryForm(
                          'to_contact.etb_time_window.start_time_utc',
                          value,
                          i
                        );
                        this.updateDeliveryForm(
                          'to_contact.etb_time_window.end_time_utc',
                          moment(value).add(1, 'hour').toDate(),
                          i
                        );
                      }}
                      selected={
                        deliveryForms[i].to_contact?.etb_time_window?.start_time_utc ??
                        null
                      }
                      showTimeSelect
                      placeholderText="10 Jan, 10:00 AM"
                      customInput={
                        <TextInput
                          autoComplete="none"
                          inputName="name"
                          containerStyle={inlineTextInputStyle}
                          fieldName="Est. Time of Arrival (ETA)"
                          width="full"
                          noWrapLabel
                        />
                      }
                      calendarContainer={Calendar}
                    />
                    {/* Formerly Estimated Time of Unberthing (ETU) */}
                    <DatePickerWrapper
                      width="13.5rem"
                      minDate={moment().toDate()}
                      dateFormat="dd MMM, hh:mm a"
                      onChange={(value: Date): void => {
                        this.updateDeliveryForm(
                          'to_contact.etu_time_window.start_time_utc',
                          value,
                          i
                        );
                        this.updateDeliveryForm(
                          'to_contact.etu_time_window.end_time_utc',
                          moment(value).add(1, 'hour').toDate(),
                          i
                        );
                      }}
                      onSelect={(value: Date): void => {
                        this.updateDeliveryForm(
                          'to_contact.etu_time_window.start_time_utc',
                          value,
                          i
                        );
                        this.updateDeliveryForm(
                          'to_contact.etu_time_window.end_time_utc',
                          moment(value).add(1, 'hour').toDate(),
                          i
                        );
                      }}
                      selected={
                        deliveryForms[i].to_contact?.etu_time_window?.start_time_utc ??
                        null
                      }
                      showTimeSelect
                      placeholderText="10 Jan, 11:30 AM"
                      customInput={
                        <TextInput
                          autoComplete="none"
                          inputName="name"
                          containerStyle={inlineTextInputStyle}
                          fieldName="Est. Time of Departure (ETD)"
                          width="full"
                          noWrapLabel
                        />
                      }
                      calendarContainer={Calendar}
                    />
                  </DateAndTimeContainer>
                  {/* <TextInput
                    autoComplete="none"
                    containerStyle={inlineTextInputStyle}
                    fieldName="Lighter Company Name"
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm(
                        'to_contact.lighter_company_name',
                        value,
                        i
                      );
                    }}
                    placeholder="Aquaholic Pte Ltd"
                    width="medium"
                    value={this.state.deliveryForms[i].to_contact.lighter_company_name}
                  />
                  <TextInput
                    autoComplete="none"
                    containerStyle={inlineTextInputStyle}
                    fieldName="Lighter Boat Name"
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm('to_contact.lighter_boat_name', value, i);
                    }}
                    placeholder="Seas the Day"
                    width="medium"
                    value={this.state.deliveryForms[i].to_contact.lighter_boat_name}
                  /> */}
                </VesselCardRow>
              </>
            )}
          </>
        }
      />
    );
  };

  renderAddVesselModal = (): React.ReactNode => {
    const { showDeliveryAddVesselModal: index } = this.state;
    return (
      <>
        {this.state.showPickupAddVesselModal ? (
          <AddVesselModal
            closeModal={(): void => {
              this.setState({ showPickupAddVesselModal: false });
            }}
            addVesselSuccess={(vessel: Vessel): void => {
              this.setState((prevState) => ({
                baseForm: {
                  ...prevState.baseForm,
                  from_contact: {
                    ...prevState.baseForm.from_contact,
                    vessel_name: vessel.name.toUpperCase(),
                    vessel_imo: vessel.imo,
                  },
                },
              }));
            }}
          />
        ) : (
          false
        )}
        {typeof index === 'number' && (
          <AddVesselModal
            closeModal={(): void => {
              this.setState({ showDeliveryAddVesselModal: false });
            }}
            addVesselSuccess={(vessel: Vessel): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[index] = {
                  ...updatedDeliveryForms[index],
                  to_contact: {
                    ...updatedDeliveryForms[index].to_contact,
                    vessel_id: vessel.id,
                    vessel_imo: vessel.imo,
                    vessel_name: vessel.name.toUpperCase(),
                  },
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
          />
        )}
      </>
    );
  };

  renderServiceAndVehicleSelect = (): React.ReactNode => {
    return (
      <OrderCard>
        {this.renderVehicleForm(
          this.state.baseForm.broadcast_preference as keyof typeof BROADCAST_PREFERENCES
        )}
      </OrderCard>
    );
  };

  onFileRemove = (deliveryFormIndex: number, index: number): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[deliveryFormIndex] = {
        ...updatedDeliveryForms[deliveryFormIndex],
        ['attachment_files']: updatedDeliveryForms[
          deliveryFormIndex
        ].attachment_files.filter((_, i) => i !== index),
      };
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  removeCargoDetails = (deliveryFormIndex: number, cargoDetailsIndex: number): void => {
    if (typeof deliveryFormIndex === 'number') {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        const updatedItems = [
          ...prevState.deliveryForms[deliveryFormIndex].cargo_details,
        ];
        if (
          cargoDetailsIndex === 0 &&
          this.state.deliveryForms[deliveryFormIndex].cargo_details.length === 1
        ) {
          updatedItems[cargoDetailsIndex] = {
            id: '',
            name: '',
            quantity: 1,
            quantity_unit: '',
            remarks: '',
            has_hazard_mat: false,
          };
        } else {
          updatedItems.splice(cargoDetailsIndex, 1);
        }
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          cargo_details: updatedItems,
        };
        return {
          deliveryForms: updatedDeliveryForms,
        };
      });
    }
  };

  removeItem = () => {
    const { deliveryFormIndex, itemIndex, actionType } = this.state.showRemoveModal;
    if (typeof deliveryFormIndex === 'number' && actionType === 'items') {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        const updatedItems = [...prevState.deliveryForms[deliveryFormIndex].items];
        if (
          itemIndex === 0 &&
          this.state.deliveryForms[deliveryFormIndex].items.length === 1
        ) {
          updatedItems[itemIndex] = {
            name: '',
            quantity: undefined,
            has_hazard_mat: false,
            dimension: {
              length: null,
              width: null,
              height: null,
            },
          };
        } else {
          updatedItems.splice(itemIndex, 1);
        }
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          items: updatedItems,
        };
        return {
          ...prevState,
          deliveryForms: updatedDeliveryForms,
          showRemoveModal: {
            deliveryFormIndex: false,
            itemIndex: 0,
            actionType: '',
          },
          showRemoveSuccessModal: 'Item details removed',
        };
      });
    }
  };

  getPopupRemovalPreference = () => {
    return {
      items: {
        title: 'Remove item details?',
        content:
          'Are you sure you want to remove item details? This action cannot be undone.',
        action: this.removeItem,
      },
      cargo_details: {
        title: 'Remove cargo details?',
        content:
          'Are you sure you want to remove cargo details? This action cannot be undone.',
        action: () => {},
      },
    };
  };

  renderRemoveCargoDetailsOrItemsModal = (): React.ReactNode => {
    const { actionType } = this.state.showRemoveModal;
    if (actionType === '') {
      return null;
    }
    const popupPreference = this.getPopupRemovalPreference();
    return (
      <CenterModal
        title={<RemoveCargoDetailsIcon icon={faTrash} color={COLOR.red} />}
        leftButtonText="Cancel"
        leftButtonOnClick={(): void => {
          this.setState({
            showRemoveModal: {
              deliveryFormIndex: false,
              itemIndex: 0,
              actionType: '',
            },
          });
        }}
        rightButtonOnClick={(): void => popupPreference[actionType].action()}
        rightButtonText="Remove"
        rightButtonStyle="discourage"
        rightButtonType="primary"
        width="small"
        position="bottom"
      >
        <RemoveCargoDetailsSubTitle>
          {popupPreference[actionType].title}
        </RemoveCargoDetailsSubTitle>

        <Typography as="div" color="gray_600" size="sm">
          {popupPreference[actionType].content}
        </Typography>
      </CenterModal>
    );
  };

  renderRemoveSuccessModal = (): React.ReactNode => (
    <>
      {this.state.showRemoveSuccessModal ? (
        <CenterModal
          title={
            <RemoveCargoDetailsIcon
              icon={faCheckCircle}
              color={COLOR.cyan}
              fontSize="1.3"
            />
          }
          fullBottomButtonText="OK"
          fullBottomButtonOnClick={(): void => {
            this.setState({
              showRemoveSuccessModal: '',
            });
          }}
          width="small"
          position="bottom"
        >
          <RemoveCargoDetailsSubTitle>
            {this.state.showRemoveSuccessModal}
          </RemoveCargoDetailsSubTitle>
        </CenterModal>
      ) : (
        false
      )}
    </>
  );

  renderFilesUpload = (deliveryFormIndex: number = 0): React.ReactNode => {
    const { deliveryForms } = this.state;
    return (
      <FilesUpload
        deliveryForm={deliveryForms[deliveryFormIndex]}
        deliveryFormIndex={deliveryFormIndex}
        onFileChange={async (e): Promise<void> => {
          await this.onFileChange(e, deliveryFormIndex);
        }}
        onFileRemove={(attachmentIndex: number) => {
          this.onFileRemove(deliveryFormIndex, attachmentIndex);
        }}
      />
    );
  };

  renderDeliveryVesselEmptyPlaceholder = (index: number): React.ReactNode => {
    const { deliveryForms, showDeliveryAddVesselModal } = this.state;
    return (
      <>
        {deliveryForms[index].to_contact &&
        deliveryForms[index].to_contact.vessel_name &&
        deliveryForms[index].to_contact.vessel_name.length >= 3 &&
        !showDeliveryAddVesselModal ? (
          <EmptyVesselPlaceholder>
            {this.state.isFetchingVessel ? (
              <div>Loading...</div>
            ) : (
              <>
                <div>Vessel name not found.</div>
                <EnterManualEmptyPlaceholderButton
                  onClick={(e: React.MouseEvent): void => {
                    e.stopPropagation();
                    this.setState({ showDeliveryAddVesselModal: index });
                  }}
                >
                  Enter manually
                </EnterManualEmptyPlaceholderButton>
              </>
            )}
          </EmptyVesselPlaceholder>
        ) : (
          false
        )}
      </>
    );
  };

  renderSwitchServiceWarningModal = (): React.ReactNode => (
    <CenterModal
      title="Switch Service Type?"
      leftButtonText="No"
      leftButtonOnClick={(): void => {
        this.setState({ showSwitchServiceWarningModal: '' });
      }}
      rightButtonOnClick={(): void => {
        const updatedBaseForm = {
          ...this.getDefaultBaseForm(),
          broadcast_preference: this.state.showSwitchServiceWarningModal,
        };
        this.setState(
          (prevState) => ({
            ...prevState,
            baseForm: updatedBaseForm,
            deliveryForms: [this.getDefaultDeliveryForm()],
            showSwitchServiceWarningModal: '',
          }),
          () => {
            this.getDefaultPickupSavedAddress();
            this.getDefaultDeliverySavedAddress();
          }
        );
      }}
      rightButtonText="Yes"
      width="small"
    >
      <TitleDescription>Some details will be lost.</TitleDescription>
    </CenterModal>
  );

  renderPickupVesselBottomLabel = (): React.ReactNode => {
    const fromContact = this.state.baseForm.from_contact;

    return (
      <>
        {fromContact.vessel_imo ? (
          <>
            <ImoText>{`IMO ${fromContact.vessel_imo}`}</ImoText>
            <ImoInfoAlert status="info">
              Important: Please ensure that this information is accurate.
            </ImoInfoAlert>
          </>
        ) : (
          false
        )}
        <EnterManualButton
          onClick={(): void => {
            this.setState({ showPickupAddVesselModal: true });
          }}
        >
          Enter manually
        </EnterManualButton>
      </>
    );
  };

  renderDeliveryVesselBottomLabel = (index: number): React.ReactNode => {
    const toContact =
      this.state.deliveryForms[index] && this.state.deliveryForms[index].to_contact;

    return (
      <>
        {toContact.vessel_imo ? (
          <>
            <ImoText>{`IMO ${toContact.vessel_imo}`}</ImoText>
            <ImoInfoAlert status="info">
              Important: Please ensure that this information is accurate.
            </ImoInfoAlert>
          </>
        ) : (
          false
        )}
        <EnterManualButton
          onClick={(): void => {
            this.setState({ showDeliveryAddVesselModal: index });
          }}
        >
          Enter manually
        </EnterManualButton>
      </>
    );
  };

  renderCargoDetails = (deliveryFormIndex: number = 0): React.ReactNode => {
    const { deliveryForms } = this.state;
    return (
      <CargoDetails
        deliveryForm={deliveryForms[deliveryFormIndex]}
        updateDeliveryForm={(
          fieldName: string,
          value: string | Date | boolean | number
        ) => {
          this.updateDeliveryForm(fieldName, value, deliveryFormIndex);
        }}
        onRemoveCargoDetails={(cargoDetailsIndex: number) => {
          this.removeCargoDetails(deliveryFormIndex, cargoDetailsIndex);
        }}
        onAddItemClick={(): void => {
          this.setState((prevState) => {
            const updatedDeliveryForms = [...prevState.deliveryForms];
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              cargo_details: updatedDeliveryForms[deliveryFormIndex].cargo_details.concat(
                {
                  id: '',
                  name: '',
                  quantity: 1,
                  quantity_unit: '',
                  remarks: '',
                  has_hazard_mat: false,
                }
              ),
            };
            return {
              deliveryForms: updatedDeliveryForms,
            };
          });
        }}
      />
    );
  };

  renderOrderForm = (): React.ReactNode => {
    return (
      <OrderCard>
        <PickupForm
          baseForm={this.state.baseForm}
          chooseSavedAddress={this.chooseSavedAddress}
          savedGeoDatas={this.props.savedGeoDatas}
          updateBaseForm={this.updateBaseForm}
          onSelectAddress={(data: AddressContactData): void => {
            this.setState((prevState) => ({
              ...prevState,
              baseForm: {
                ...prevState.baseForm,
                from_address: data.address,
                from_contact: data.contact,
              },
              fromLocationSelected: true,
            }));
          }}
        />
        {this.state.deliveryForms.map((_, i) => (
          <DeliveryForm
            page="new"
            key={i}
            i={i}
            baseForm={this.state.baseForm}
            deliveryForm={this.state.deliveryForms[i]}
            updateBaseForm={this.updateBaseForm}
            updateDeliveryForm={this.updateDeliveryForm}
            deliveryFormsLength={this.state.deliveryForms.length}
            organization={this.props.organization}
            geoDatas={this.props.geoDatas}
            savedGeoDatas={this.props.savedGeoDatas}
            addItemClick={(): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[i] = {
                  ...updatedDeliveryForms[i],
                  items: updatedDeliveryForms[i].items.concat({
                    name: '',
                    quantity: undefined,
                    has_hazard_mat: false,
                    dimension: {
                      length: null,
                      width: null,
                      height: null,
                    },
                  }),
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
            removeItemClick={(j: number): void => {
              this.setState({
                showRemoveModal: {
                  deliveryFormIndex: i,
                  itemIndex: j,
                  actionType: 'items',
                },
              });
            }}
            chooseSavedAddress={(
              type: 'pickup' | 'delivery',
              i: number,
              v: SavedGeoData
            ) => this.chooseSavedAddress(type, i, v)}
            marineVehicleFormChange={(
              fieldName: string,
              value: string | boolean
            ): void => {
              // handle pricing based on vehicle
              if (typeof value === 'string') {
                const serviceType = value === 'boat' ? 'boat' : 'truck';
                const servicePricing = getServicePricing(serviceType, value);
                const priceValue =
                  Math.floor(parseFloat(servicePricing.price.toString()) * 100) * 1000;
                this.setState((prevState) => {
                  const updatedDeliveryForms = [...prevState.deliveryForms];
                  updatedDeliveryForms[i] = {
                    ...updatedDeliveryForms[i],
                    [fieldName]: value,
                    min_price: priceValue,
                    max_price: priceValue,
                    service_type: serviceType,
                  };
                  return {
                    deliveryForms: updatedDeliveryForms,
                    baseForm: { ...prevState.baseForm, vehicle_preference: value },
                  };
                });
              }
            }}
            onSelectAddress={(data: AddressContactData): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[i] = {
                  ...updatedDeliveryForms[i],
                  to_address: data.address,
                  to_contact: data.contact,
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                  // toLocationSelected: [...this.state.toLocationSelected, i],
                };
              });
            }}
            onRemoveDeliveryDetails={(): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms.splice(i, 1);
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
          />
        ))}
        <Separator />
        <DeliveryLocationPanel>
          <StyledButton
            buttonType="neutral"
            buttonStyle="encourage"
            onClick={(): void => {
              this.setState((prevState) => {
                return {
                  deliveryForms: prevState.deliveryForms.concat(
                    this.getDefaultDeliveryForm()
                  ),
                };
              });
            }}
            icon={<FontAwesomeIcon icon={faPlus} />}
            disabled={this.state.deliveryForms.length >= 20}
          >
            Add Delivery Location
          </StyledButton>
          {this.state.baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine ? (
            <TotalDeliveryWeight
              totalDeliveries={this.state.deliveryForms.length}
              totalWeight={this.getTotalItemsWeight()}
            />
          ) : (
            false
          )}
        </DeliveryLocationPanel>
      </OrderCard>
    );
  };

  isMotorcycleExceedingDimensions = (): string[] => {
    const { deliveryForms } = this.state;
    const items = deliveryForms.flatMap((deliveryForm) => deliveryForm.items);
    const result = isItemExceedingVehicleDimensions(items, ['motorcycle']);
    const vehiclesExceed = Object.keys(result).filter(
      (key) => result[key as keyof typeof result]
    );
    return vehiclesExceed;
  };

  getTotalItemsWeight = (): number => {
    return parseFloat(this.state.totalWeight.toFixed(2));
  };

  calculateTotalItemsWeight = (deliveryForms: MerchantDeliveryForm[]): number => {
    const items = deliveryForms.flatMap((deliveryForm) => deliveryForm.items);
    return items.reduce((total, item) => {
      const weight = item.weight ? parseFloat(item.weight.toString()) : 0;
      const quantity = item.quantity || 0;
      return total + weight * quantity;
    }, 0);
  };

  renderVehicleForm = (
    broadcastPreference: keyof typeof BROADCAST_PREFERENCES
  ): React.ReactNode => {
    const { baseForm } = this.state;
    return (
      <VehicleForm
        enableTotalWeightValidation
        totalWeight={this.getTotalItemsWeight()}
        displayHeader={
          broadcastPreference === 'marine' ? (
            <>
              <Typography
                weight="normal"
                color="gray_700"
                as="h4"
                size="sm"
                customStyle={{ marginBottom: '0.2rem', letterSpacing: '0.32px' }}
              >
                Vehicle Requirements <RedText>*</RedText>
              </Typography>
              <Typography weight="normal" color="gray_400" as="p" size="xs">
                Select the most appropriate vehicle to fit your cargo.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                weight="semibold"
                color="gray_700"
                as="h2"
                customStyle={{ marginBottom: '0.2rem', letterSpacing: '0.32px' }}
              >
                Vehicle Requirements <RedText>*</RedText>
              </Typography>
              <Typography weight="normal" color="gray_400" as="p">
                Select the most appropriate vehicle to fit all your items.
              </Typography>
            </>
          )
        }
        form={{
          broadcast_preference:
            baseForm.broadcast_preference as keyof typeof BROADCAST_PREFERENCES,
          vehicle_preference: baseForm.vehicle_preference,
        }}
        onFormChange={(fieldName: string, value: string | boolean): void => {
          this.updateBaseForm(fieldName, value);
        }}
        vehiclesExceedingDimensions={this.isMotorcycleExceedingDimensions()}
      />
    );
  };

  renderPriceForm = (): React.ReactNode => {
    const {
      baseForm,
      isFetchingPrice,
      showRecommendPriceError,
      showRecommendPriceInfo,
      showRecommendPriceSuccess,
    } = this.state;

    return (
      <PriceForm
        baseForm={baseForm}
        isFetchingPrice={isFetchingPrice}
        onCheckboxRemember={(): void => {
          this.setState((prevState) => ({
            rememberMySetting: !prevState.rememberMySetting,
          }));
        }}
        rememberMySetting={this.state.rememberMySetting}
        onRecommendPrice={this.recommendPrice}
        showRecommendPriceError={showRecommendPriceError}
        showRecommendPriceInfo={showRecommendPriceInfo}
        showRecommendPriceSuccess={showRecommendPriceSuccess}
        updateBaseForm={this.updateBaseForm}
      />
    );
  };

  renderAdditionalService = (): React.ReactNode => {
    const { baseForm, hasCargoNet, hasOfficerContact } = this.state;

    return (
      <AdditionalServiceCard>
        <OrderTitle>Any Additional Service / Requirements?</OrderTitle>
        <Checkbox
          onClick={(): void => {
            this.setState((prevState) => ({
              hasCargoNet: !prevState.hasCargoNet,
            }));
          }}
          selected={this.state.hasCargoNet}
        >
          Do you need cargo net?
        </Checkbox>
        {hasCargoNet && (
          <AdditionalServiceContent>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="No. of Cargo Nets"
              placeholder="4"
              onTextChange={(value: string): void => {
                this.updateBaseForm('cargo_net_quantity', value);
              }}
              type="number"
              width="medium"
              min={1}
              value={baseForm.cargo_net_quantity}
            />
          </AdditionalServiceContent>
        )}
        <Checkbox
          onClick={(): void => {
            this.setState((prevState) => ({
              hasOfficerContact: !prevState.hasOfficerContact,
            }));
          }}
          selected={this.state.hasOfficerContact}
        >
          Is there an additional Boarding Officer coming along?
        </Checkbox>
        {hasOfficerContact && (
          <AdditionalServiceContent>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Name"
              placeholder="Tony"
              onTextChange={(value: string): void => {
                this.updateBaseForm('officer_contact.name', value);
              }}
              type="text"
              width="medium"
              value={baseForm.officer_contact.name}
            />
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Contact No."
              placeholder="8100 8989"
              onTextChange={(value: string): void => {
                this.updateBaseForm('officer_contact.phone', value);
              }}
              type="text"
              width="medium"
              value={baseForm.officer_contact.phone}
            />
          </AdditionalServiceContent>
        )}
      </AdditionalServiceCard>
    );
  };

  renderConfirmOrderModal = (): React.ReactNode => {
    return (
      <>
        {this.state.showConfirmOrderModal && (
          <CenterModal title="Confirm Order?">
            <Checkbox
              onClick={(): void => {
                this.setState((prevState) => ({
                  orderDeclaration: {
                    ...prevState.orderDeclaration,
                    correctAndComplete: !prevState.orderDeclaration.correctAndComplete,
                  },
                }));
              }}
              selected={this.state.orderDeclaration.correctAndComplete}
            >
              <ConfirmOrderModalText>
                I confirm that the description and specifics of my delivery details are
                correct and complete.
              </ConfirmOrderModalText>
            </Checkbox>
            <ConfirmOrderModalButtons>
              <StyledButton
                buttonStyle="encourage"
                buttonType="neutral"
                onClick={(): void => {
                  this.setState({ showConfirmOrderModal: false });
                }}
              >
                Back
              </StyledButton>
              <StyledButton
                type="submit"
                buttonType="neutral"
                buttonStyle="encourage"
                disabled={!this.allowCreation()}
              >
                Confirm
              </StyledButton>
            </ConfirmOrderModalButtons>
          </CenterModal>
        )}
      </>
    );
  };

  renderErrorModal = (): React.ReactNode => {
    return (
      <>
        {this.state.error && (
          <CenterModal
            title="Unable to place orders"
            rightButtonText="OK"
            rightButtonOnClick={(): void => {
              this.setState({ error: null, isSaving: false });
            }}
          >
            <Alert status="error">
              {this.state.error !== 'Network Error'
                ? this.state.error
                : 'There seems to be an error with the network.'}
            </Alert>
            <ErrorModalText>
              {this.state.error !== 'Network Error'
                ? 'Please check that your order information is correct, then try again.'
                : 'Please wait a few seconds then try again. If the problem continues, let us know.'}
            </ErrorModalText>
          </CenterModal>
        )}
      </>
    );
  };

  renderOrderPlacedModal = (): React.ReactNode => {
    return (
      <>
        {this.state.isSuccessful && (
          <CenterModal
            title="Order Confirmed"
            rightButtonText="OK"
            rightButtonOnClick={(): void => {
              this.props.history.push('/active-orders');
            }}
          >
            <Alert status="success">You have successfully placed your order.</Alert>
          </CenterModal>
        )}
      </>
    );
  };

  renderActionButtons(): React.ReactNode {
    return (
      <ActionContainer>
        <ActionButtonContainer>
          <Button
            type="button"
            buttonStyle="encourage"
            buttonType="neutral"
            onClick={(): void => {
              this.props.history.push('/active-orders');
            }}
          >
            <OrderIcon color={COLOR.darkGray} icon={faChevronLeft} />
            Back
          </Button>
          <Button
            type="button"
            buttonType="primary"
            buttonStyle="encourage"
            disabled={!this.allowConfirmOrder()}
            onClick={(): void => {
              this.allowConfirmOrder()
                ? this.setState({ showConfirmOrderModal: true })
                : this.setState({
                    error: 'There was an error processing your order.',
                  });
            }}
          >
            <OrderIcon color={COLOR.white} icon={faCheck} />
            Place Order
          </Button>
        </ActionButtonContainer>
      </ActionContainer>
    );
  }

  renderSwitchViewWarningModal = (): React.ReactNode => (
    <CenterModal
      title="Switch view?"
      leftButtonText="Cancel"
      leftButtonOnClick={(): void => {
        this.setState({ showSwitchViewModal: false });
      }}
      rightButtonText="OK"
      rightButtonOnClick={(): void => {
        this.props.history.push('/orders/imports/new', {
          vehiclePreference: this.state.baseForm.vehicle_preference,
          broadcastPreference: this.state.baseForm.broadcast_preference,
          minPrice: this.state.baseForm.min_price,
          maxPrice: this.state.baseForm.max_price,
          increment: this.state.baseForm.increment,
          rememberMySetting: this.state.rememberMySetting,
        });
      }}
    >
      The added order details will be lost.
    </CenterModal>
  );

  renderPickupAddressNotFound = (): React.ReactNode => (
    <div>
      {(this.state.baseForm.from_address.street_address ||
        this.state.baseForm.from_address.building_name) &&
      !this.isAddressLocationValid(this.state.baseForm.from_address) ? (
        <InlineErrorMessage>
          <ErrorIcon icon={faExclamationCircle} />
          Address not found
        </InlineErrorMessage>
      ) : (
        false
      )}
    </div>
  );

  render(): React.ReactNode {
    const { baseForm } = this.state;
    const isBroadcastPreferenceMarine =
      baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine;
    let handleFormSubmit = this.handleSubmit;
    if (isBroadcastPreferenceMarine) {
      handleFormSubmit = this.handleSubmitMarineOrders;
    }
    return (
      <MainContainer selected="active-orders">
        <Breadcrumb
          items={[
            {
              text: 'Orders',
              onClick: (): void => {
                this.props.history.push('/active-orders');
              },
            },
            { text: 'Fill Form' },
          ]}
        />
        <form
          onSubmit={handleFormSubmit}
          onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>): void => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          {this.renderGeneralInfoForm()}
          {this.renderOrderForm()}
          {(baseForm.broadcast_preference === BROADCAST_PREFERENCES.all ||
            baseForm.broadcast_preference === BROADCAST_PREFERENCES.squad) && (
            <>
              {this.renderServiceAndVehicleSelect()}
              {this.renderPriceForm()}
            </>
          )}
          {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
            <>
              <OrderCard>
                {this.renderFilesUpload()}
                {this.renderCargoDetails()}
              </OrderCard>
              {this.renderAdditionalService()}
            </>
          )}
          {this.renderActionButtons()}
          {this.renderErrorModal()}
          <BottomMargin />
          {this.renderConfirmOrderModal()}
          {this.renderOrderPlacedModal()}
        </form>
        {this.state.showSwitchViewModal && this.renderSwitchViewWarningModal()}
        {typeof this.state.showSwitchServiceWarningModal === 'string' &&
          this.state.showSwitchServiceWarningModal !== '' &&
          this.renderSwitchServiceWarningModal()}
        {this.renderAddVesselModal()}
        {typeof this.state.showRemoveModal.deliveryFormIndex === 'number' &&
          this.renderRemoveCargoDetailsOrItemsModal()}
        {this.renderRemoveSuccessModal()}
      </MainContainer>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    currentUser: state.auth.currentUser,
    organization: state.organization.organization,
    squads: state.organization.squads,
    geoDatas: state.geoService.geoDatas,
    savedGeoDatas: state.geoService.savedGeoDatas,
    vessels: state.vessel.vessels,
  };
}

export default connect(mapStateToProps)(OrderNew);
