import * as React from 'react';
import { StyledButton, BeforeLoginContainer, Alert } from 'components';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import AuthClient from 'httpClients/authClient';
import * as H from 'history';
import COLOR, { transparent } from 'constants/color';
import gmailLogo from 'assets/images/gmail.png';
import outlookLogo from 'assets/images/outlook.png';

interface VerifyCodeProps<S = H.LocationState> {
  history: H.History<S>;
}

interface LocationState {
  registerEmail: string;
  registerToken: string;
}

interface VerifyCodeState {
  form: {
    verifyCode: string[];
    registerEmail: string;
    registerToken: string;
    resendTimeoutSec: number;
  };
  countdown: number;
  focusInput: number | null;
  error: string | null;
  success: string | null;
}

type Props = RouteComponentProps<object, object, LocationState> & VerifyCodeProps;

const numOtp = 6;

class VerifyCode extends React.Component<Props, VerifyCodeState> {
  timer: ReturnType<typeof setInterval> | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        verifyCode: [],
        registerEmail: '',
        registerToken: '',
        resendTimeoutSec: 0,
      },
      countdown: 0,
      focusInput: null,
      error: null,
      success: null,
    };
    this.timer = null;
  }

  componentDidMount(): void {
    this.checkBearerToken();
    this.createCountdown();
  }

  componentWillUnmount(): void {
    this.removeCountdown();
  }

  createCountdown(showSuccess = false): void {
    this.setState({ countdown: 60 });
    this.timer = setInterval(() => {
      const { countdown } = this.state;
      this.setState(prevState => ({
        countdown: prevState.countdown - 1,
        success: showSuccess ? 'New code sent' : null,
      }));
      if (countdown <= 0) {
        this.removeCountdown();
      }
    }, 1000);
  }

  removeCountdown(): void {
    if (this.timer) {
      this.setState({
        countdown: 0,
        success: null,
      });
      clearInterval(this.timer);
    }
  }

  checkBearerToken(): void {
    const registerEmail = this.props.location.state && this.props.location.state.registerEmail;
    const registerToken = this.props.location.state && this.props.location.state.registerToken;

    if ((!registerEmail) || (registerEmail === '') || (!registerToken) || (registerToken === '')) {
      this.props.history.push('/register');
    }

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        registerEmail,
        registerToken,
      },
    }));
  }

  replaceMessage = (prevMessage: string): string => {
    let newMessage = prevMessage;
    if (prevMessage === 'Security code is incorrect') {
      newMessage = 'Invalid code. Try again.';
    }
    return newMessage;
  }

  async resendNewCode(): Promise<void> {
    const { form } = this.state;

    this.setState({ error: null });
    try {
      const client = new AuthClient();
      const response = await client.requestVerificationCode({
        user: {
          email: form.registerEmail,
        },
      });
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          registerEmail: form.registerEmail,
          registerToken: response.bearer_token,
        },
      }));
      this.createCountdown(true);
    } catch (e) {
      this.setState({ error: e });
    }
  }

  showCountdownMinute(): string {
    const { countdown } = this.state;
    const newMinutes = Math.floor(countdown / 60);
    const newSeconds = countdown % 60;

    return newMinutes.toString() + ':' + newSeconds.toString().padStart(2, '0');
  }

  updateOtpByIndex(index: number, replacement: string): void {
    const { verifyCode } = this.state.form;

    verifyCode[index] = replacement;
    if (replacement.trim().length === 1) {
      this.setState({ focusInput: index + 1, error: null });
    }
  }

  async handleVerifyCode(): Promise<void> {
    const { form } = this.state;
    this.setState({ error: null });

    try {
      const client = new AuthClient();
      await client.verifyRegistrationUser({
        user: {
          paranoid_verification_code: form.verifyCode.join(''),
        },
      }, form.registerToken);
      localStorage.setItem('tempBearerToken', form.registerToken);
      this.props.history.push('/register/set-name-password');
    } catch (e) {
      this.setState({ error: this.replaceMessage(e) });
    }
  }

  handlePaste = (e: React.ClipboardEvent<HTMLDivElement>): void => {
    const pasteText = e.clipboardData.getData('Text');
    const newVerifyCode = Array<string>();

    for (let i = 0; i < numOtp; i++) {
      newVerifyCode[i] = pasteText.charAt(i);
    }
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        verifyCode: newVerifyCode,
      },
    }));
  }

  render(): React.ReactNode {
    const { error, form, countdown, success, focusInput } = this.state;
    const otp = Array.from(Array(numOtp).keys());
    const pattern = new RegExp(/\s/);
    const verifyCode = form.verifyCode.join('');

    return (
      <BeforeLoginContainer title="Check your email">
        <LabelInfo>
          {'We\'ve sent a 6-character code to'} <b>{form.registerEmail}</b>. The code expires shortly, so please enter it soon.
        </LabelInfo>
        <OtpInputContainer onPaste={this.handlePaste}>
          {
            otp.map(index => (
              <OtpInput
                key={index}
                maxLength={1}
                onChange={(e): void => { this.updateOtpByIndex(index, e.target.value); }}
                onBlur={(): void => { this.setState({ focusInput: null }); }}
                onFocus={(e): void => {
                  this.updateOtpByIndex(index, '');
                  e.target.select();
                }}
                ref={(input): void => { index === focusInput && input && input.focus(); }}
                value={form.verifyCode[index] ? form.verifyCode[index] : ''}
                onKeyUp={(e): void => {
                  if (e.key === 'Backspace' || e.key === 'ArrowLeft') {
                    e.preventDefault();
                    this.setState({ focusInput: index - 1 });
                  } else if (e.key === 'ArrowRight') {
                    e.preventDefault();
                    this.setState({ focusInput: index + 1 });
                  }
                }}
              />
            ))
          }
        </OtpInputContainer>
        {error && (
          <UserMessage status='error'>{error}</UserMessage>
        )}
        <ResendNewCode
          onClick={(): void => { this.resendNewCode(); }}
          disabled={countdown > 0}
        >
          Resend New Code {countdown > 0 ? `(${this.showCountdownMinute()})` : false}
        </ResendNewCode>
        {success && (
          <UserMessage status='success'>{success}</UserMessage>
        )}
        <VerifyButton
          buttonStyle="encourage"
          buttonType="primary"
          size="lg"
          isFullWidth={true}
          onClick={(): void => { this.handleVerifyCode(); }}
          disabled={verifyCode.length < 6 || pattern.test(verifyCode)}
        >
          Verify
        </VerifyButton>
        <MailsContainer>
          <MailContainer
            onClick={(): void => { window.open('https://mail.google.com/'); }}
          >
            <img src={gmailLogo} />
            <MailText>
              Open Gmail
            </MailText>
          </MailContainer>
          <MailContainer
            onClick={(): void => { window.open('https://outlook.office365.com/mail'); }}
          >
            <img src={outlookLogo} />
            <MailText>
              Open Outlook
            </MailText>
          </MailContainer>
        </MailsContainer>
        <CenterText>
          {'Can\'t find your code? Check your spam folder!'}
        </CenterText>
      </BeforeLoginContainer>
    );
  }
}

const ResendNewCode = styled.button<{ disabled: boolean }>`
  color: ${(props): string => props.disabled ? COLOR.grey : COLOR.blue}!important;
  cursor: ${(props): string => props.disabled ? 'default' : 'pointer'};
  border: none;
  background: ${transparent('black', 0)};
  color: ${COLOR.blue};
  padding: 0.2rem;
  font-size: 1rem;
  text-decoration: underline;
`;

const VerifyButton = styled(StyledButton)`
  margin-top: 1.875rem;
`;

const LabelInfo = styled.div`
  margin-bottom: 1.875rem;
`;

const CenterText = styled.div`
  margin-top: 1.875rem;
  text-align: center;
`;

const OtpInput = styled.input`
  border-color: ${(props): string => (props.value === undefined
    || props.value.toString().trim() === '')
    ? COLOR.red : COLOR.neutral};
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0px 4px 16px #455B633A;
  width: 2.8125rem;
  box-sizing: border-box;
  font-size: 1rem;
  height: 3.25rem;
  color: ${COLOR.black};
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
  text-align: center;

  &:focus {
    outline: none;
    border-color: ${COLOR.blue};
  }
`;

const OtpInputContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.6875rem;
`;

const UserMessage = styled(Alert)`
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
`;

const Div = styled.div`
  margin-top: 1.875rem;
`;

const MailsContainer = styled(Div)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const MailContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-right: 1rem;
`;

const MailText = styled.div`
  margin-left: 0.375rem;
`;

export default VerifyCode;