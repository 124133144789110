import { RunsheetGenerationJob } from 'models/runsheetGeneration';

export interface RunsheetGenerationJobHash {
  [index: string]: RunsheetGenerationJob;
}

export interface RunsheetGenerationState {
  runsheetGenerationJobHash: RunsheetGenerationJobHash;
  runsheetGenerationIds: string[];
  runsheetGenerationJob: RunsheetGenerationJob;
}

export const RECEIVE_RUNSHEET_GENERATION_JOBS = 'RECEIVE_RUNSHEET_GENERATION_JOBS';
export const RECEIVE_RUNSHEET_GENERATION_JOB_DETAIL = 'RECEIVE_RUNSHEET_GENERATION_JOB_DETAIL';

export interface ReceiveRunsheetGenerationJobsAction {
  type: typeof RECEIVE_RUNSHEET_GENERATION_JOBS;
  runsheetGenerationJobs: RunsheetGenerationJob[];
}

export interface ReceiveRunsheetGenerationJobDetailAction {
  type: typeof RECEIVE_RUNSHEET_GENERATION_JOB_DETAIL;
  runsheetGenerationJob: RunsheetGenerationJob;
}

export type RunsheetGenerationActionTypes = ReceiveRunsheetGenerationJobsAction | ReceiveRunsheetGenerationJobDetailAction;