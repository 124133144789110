import * as React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { useParams, useHistory } from 'react-router-dom';
import TaskClient from 'httpClients/taskClient';
import { Epod } from 'models/task/epod';
import EpodDocument from 'components/pdf/epodDocument';
import { TASK_STATUS } from 'constants/taskStatus';

const EpodViewPdf = () => {
  const history = useHistory();
  const { taskId } = useParams<{ taskId: string }>();
  const [epod, setEpod] = React.useState<Epod | null>(null);
  const fetchTask = async (id: string): Promise<void> => {
    const client = new TaskClient();
    try {
      const response = await client.getEpodByTaskId(id);
      setEpod(response);
    } catch (error) {
      // generate epod if epod not found but task status is complete
      const errorMessage = (error as Error).toString().toLowerCase();
      if (errorMessage === 'epod not found') {
        const epod = await generateEpod(id);
        if (epod) {
          setEpod(epod);
        } else {
          history.push('/');
        }
      } else {
        history.push('/');
      }
    }
  };

  const generateEpod = async (id: string): Promise<Epod | null> => {
    const client = new TaskClient();
    const task = await client.sysAdminGetTask(id);
    if (task && task.current_status === TASK_STATUS.COMPLETE) {
      return await client.generateEpod(id);
    }
    return null;
  };

  React.useEffect(() => {
    fetchTask(taskId);
  }, []);

  if (epod) {
    return (
      <>
        <PDFViewer
          style={{
            minHeight: '100vh',
          }}
        >
          <EpodDocument data={epod} />
        </PDFViewer>
      </>
    );
  }
  return null;
};

export default EpodViewPdf;
