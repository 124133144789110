import { Runsheet } from 'models/runsheet';

export interface RunsheetState {
  runsheets: Runsheet[];
  runsheet: Runsheet | null;
}

export const RECEIVE_RUNSHEETS = 'RECEIVE_RUNSHEETS';
export const RECEIVE_RUNSHEET = 'RECEIVE_RUNSHEET';

interface ReceiveRunsheetsAction {
  type: typeof RECEIVE_RUNSHEETS;
  runsheets: Runsheet[];
}

interface ReceiveRunsheetAction {
  type: typeof RECEIVE_RUNSHEET;
  runsheet: Runsheet;
}

export type RunsheetActionTypes = ReceiveRunsheetsAction | ReceiveRunsheetAction;
