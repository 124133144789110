const ROLES = {
  OrgAdmin: 'OrgAdmin',
  OrgTransporter: 'OrgTransporter',
};

interface RoleName {
  [key: string]: string;
}

export const ROLE_NAME: RoleName = {
  'OrgAdmin': 'Merchant',
  'OrgTransporter': 'Transporter',
};

interface RoleDescription {
  [key: string]: string;
}

export const ROLE_DESCRIPTION: RoleDescription = {
  'OrgAdmin': 'As a Merchant, I want to transport my goods',
  'OrgTransporter': 'As a Transporter, I want get to requests to transport goods',
};

export const ROLE_TYPE: RoleDescription = {
  'OrgAdmin': 'Merchant',
  'OrgTransporter': 'Transporter',
  'SysAdmin': 'System Admin',
};

export default ROLES;