import { TaskExport } from 'models/taskExport';

export interface TaskExportHash {
  [index: string]: TaskExport;
}

export interface TaskExportState {
  taskExportHash: TaskExportHash;
}

export const RECEIVE_TASK_EXPORT_JOBS = 'RECEIVE_TASK_EXPORT_JOBS';
export const RECEIVE_TASK_EXPORT_JOB = 'RECEIVE_TASK_EXPORT_JOB';

interface ReceiveJobs {
  type: typeof RECEIVE_TASK_EXPORT_JOBS;
  jobs: TaskExport[];
}

interface ReceiveJob {
  type: typeof RECEIVE_TASK_EXPORT_JOB;
  job: TaskExport;
}

export type PlanningActionTypes = ReceiveJobs | ReceiveJob;
