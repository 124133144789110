import * as React from 'react';
import { Typography } from 'components';
import { Card, Divider } from '../styles';
import { Task } from 'models/task';

interface Props {
  task: Task;
}

const MarineInfoCard = ({ task }: Props): JSX.Element => (
  <Card>
    <Typography as="h2" size="lg" color="gray_900" customStyle={{ lineHeight: '24px' }}>
      Additional Service / Requirements
    </Typography>
    <Divider />

    <div id="cargo-net">
      <Typography
        as="h4"
        size="sm"
        color="gray_900"
        weight="semibold"
        customStyle={{
          marginBottom: '0.25rem',
        }}
      >
        Cargo Net
      </Typography>
      <Typography as="p" color="gray_600">
        {task?.cargo_net_quantity ? task?.cargo_net_quantity : '-'}
      </Typography>
    </div>

    <div id="boarding-officer">
      <Typography
        as="h4"
        size="sm"
        color="gray_900"
        weight="semibold"
        customStyle={{
          marginBottom: '0.35rem',
        }}
      >
        Boarding Officer Name
      </Typography>

      <Typography
        as="p"
        color="gray_600"
        customStyle={{
          marginBottom: '0.35rem',
        }}
      >
        Name: {task && task.officer_contact && task.officer_contact.name}
      </Typography>
      <Typography as="p" color="gray_600">
        Contact: {task && task.officer_contact && task.officer_contact.phone}
      </Typography>
    </div>
  </Card>
);

export default MarineInfoCard;
