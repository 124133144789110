import * as React from 'react';
import styled from 'styled-components';
import { Button, TextField, SysAdminMainContainer } from 'components';
import RunsheetClient from 'httpClients/runsheetClient';
import * as H from 'history';

interface RunsheetNewProps {
  history: H.History;
}

interface RunsheetNewState {
  form: {
    name: string;
  };
  error: string | null;
}

class RunsheetNew extends React.Component<RunsheetNewProps, RunsheetNewState> {
  constructor(props: RunsheetNewProps) {
    super(props);

    this.state = {
      form: {
        name: '',
      },
      error: '',
    };
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    this.setState({ error: null });
    try {
      const client = new RunsheetClient();
      await client.createRunsheet(this.state.form.name);
      this.props.history.push('/sys/runsheets');
    } catch (e) {
      this.setState({ error: e });
    }
  }

  render(): React.ReactNode {
    const { form, error } = this.state;

    return (
      <SysAdminMainContainer selected="runsheets">
        <Container>
          <Header>Create Runsheet</Header>
          {error && (
           <ErrorMessage>{error}</ErrorMessage>
          )}
          <form onSubmit={this.handleSubmit}>
            <>
              <label>Name</label>
              <TextField
                type="text"
                name="name"
                value={form.name}
                onChange={(e): void => this.handleChange('name', e.target.value)}
              />
            </>
            <>
              <Button type="submit">Create</Button>
            </>
          </form>
        </Container>
      </SysAdminMainContainer>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 400px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 10px 0;
`;

const Header = styled.h1`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`;

export default RunsheetNew;
