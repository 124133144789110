import * as React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';
import { CargoDetail, Item } from 'models/task/item';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import { CARGO_TYPES_DESCRIPTION } from 'constants/cargo';
import { User } from 'models/auth';
import { formatNumberWithSpaces } from 'utils/formatter';

export const CARGO_DETAILS_COLUMNS_HEADER_MERCHANT = [
  { name: 'No', width: '7.5%' },
  { name: 'Description', width: '70%' },
  { name: 'Qty / Unit', width: '18%' },
  { name: 'HAZMAT', width: '10%' },
];

export const CARGO_DETAILS_COLUMNS_HEADER = [
  { name: 'No', width: '4%' },
  { name: 'Description', width: '20%' },
  { name: 'Qty / Unit', width: '12%' },
  { name: 'SKU', width: '12%' },
  { name: 'Weight / Unit', width: '12%' },
  { name: 'Dimensions (cm)', width: '20%' },
  { name: 'Volume (cm³)', width: '15%' },
  { name: 'HAZMAT', width: '5%' },
];

export const ITEMS_COLUMNS_HEADER = [
  { name: 'No', width: '4%' },
  { name: 'Description', width: '20%' },
  { name: 'Qty / Unit', width: '12%' },
  { name: 'SKU', width: '12%' },
  { name: 'Weight / Unit', width: '12%' },
  { name: 'Dimensions (cm)', width: '20%' },
  { name: 'Volume (cm³)', width: '15%' },
  { name: 'HAZMAT', width: '5%' },
];

export const ITEMS_COLUMNS_HEADER_MERCHANT = [
  { name: 'No', width: '7.5%' },
  { name: 'Description', width: '37.5%' },
  { name: 'Qty / Unit', width: '15%' },
  { name: 'Weight / Unit', width: '15%' },
  { name: 'Dimensions (cm)', width: '20%' },
  { name: 'HAZMAT', width: '5%' },
];

const endsWithNumber = (str: string): boolean => {
  return !isNaN(parseInt(str.charAt(str.length - 1)));
};

interface SuperScriptTextProps {
  volume: number;
  volumeUnit: string;
  width: string;
}
const SuperScriptText = ({ volume, volumeUnit, width }: SuperScriptTextProps) => {
  if (!volume) {
    return <Text style={[styles.textMedium, { width: width }]}>-</Text>;
  }

  let volumeNumber = volumeUnit;
  let superScript = '';
  if (endsWithNumber(volumeUnit)) {
    volumeNumber = volumeUnit.substring(0, volumeUnit.length - 1);
    superScript = volumeUnit.charAt(volumeUnit.length - 1);
  }
  return (
    <View
      style={{
        width: width,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {superScript ? (
        <>
          <Text style={[styles.textMedium]}>
            {volume} {volumeNumber}
          </Text>
          {superScript ? <Text style={[{ fontSize: 6 }]}>{superScript}</Text> : ''}
        </>
      ) : (
        <Text style={[styles.textMedium, { position: 'relative' }]}>
          {volume} {volumeUnit}
        </Text>
      )}
    </View>
  );
};

interface ItemRowCargoDetailProps extends CargoDetail {
  number: number;
  columnHeaders: { name: string; width: string }[];
  taskCreatorRole: 'SysAdmin' | 'Merchant';
}

const ItemRowCargoDetail = ({
  number,
  name,
  remarks,
  volume,
  volume_unit,
  weight,
  weight_unit,
  quantity,
  quantity_unit,
  sku,
  has_hazard_mat,
  columnHeaders,
  taskCreatorRole,
  dimension,
}: ItemRowCargoDetailProps) => {
  const renderCargoDetailsOps = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 10,
          borderBottomWidth: 1,
          borderBottomColor: '#DEDEDE',
          marginTop: 10,
        }}
      >
        <Text break style={[styles.textMedium, { width: columnHeaders[0].width }]}>
          {number}
        </Text>
        <Text
          break
          style={[styles.textMedium, { width: columnHeaders[1].width, paddingRight: 10 }]}
        >
          {name === 'others' ? `Others: ${remarks}` : `${CARGO_TYPES_DESCRIPTION[name]}`}
        </Text>
        <Text break style={[styles.textMedium, { width: columnHeaders[2].width }]}>
          {quantity ? `${quantity} ${quantity_unit}` : '-'}
        </Text>
        <Text
          break
          style={[styles.textMedium, { width: columnHeaders[3].width, paddingRight: 10 }]}
        >
          {sku ? sku.split('-').join(' ') : '-'}
        </Text>
        <Text break style={[styles.textMedium, { width: columnHeaders[4].width }]}>
          {weight ? `${weight} ${weight_unit}` : '-'}
        </Text>
        <Text break style={[styles.textMedium, { width: columnHeaders[5].width }]}>
          {dimension &&
          (dimension.length > 0 || dimension.width > 0 || dimension.height > 0) ? (
            <>
              {dimension.length > 0 ? dimension.length : '-'} x{' '}
              {dimension.width > 0 ? dimension.width : '-'} x{' '}
              {dimension.height > 0 ? dimension.height : '-'} cm
            </>
          ) : (
            '-'
          )}
        </Text>
        <SuperScriptText
          volume={volume}
          volumeUnit={volume_unit}
          width={columnHeaders[6].width}
        />
        <Text break style={[styles.textMedium, { width: columnHeaders[7].width }]}>
          {has_hazard_mat ? 'Yes' : 'No'}
        </Text>
      </View>
    );
  };

  const renderCargoDetailsMerchant = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 10,
          borderBottomWidth: 1,
          borderBottomColor: '#DEDEDE',
          marginTop: 10,
        }}
      >
        <Text break style={[styles.textMedium, { width: columnHeaders[0].width }]}>
          {number}
        </Text>
        <Text
          break
          style={[styles.textMedium, { width: columnHeaders[1].width, paddingRight: 10 }]}
        >
          {name === 'others' ? `Others: ${remarks}` : `${CARGO_TYPES_DESCRIPTION[name]}`}
        </Text>
        <Text break style={[styles.textMedium, { width: columnHeaders[2].width }]}>
          {quantity ? `${quantity} ${quantity_unit}` : '-'}
        </Text>
        <Text break style={[styles.textMedium, { width: columnHeaders[3].width }]}>
          {has_hazard_mat ? 'Yes' : 'No'}
        </Text>
      </View>
    );
  };

  return taskCreatorRole === 'SysAdmin'
    ? renderCargoDetailsOps()
    : renderCargoDetailsMerchant();
};

interface ItemRowProps extends Item {
  number: number;
  columnHeaders: { name: string; width: string }[];
  taskCreatorRole: 'SysAdmin' | 'Merchant';
}

const ItemRow = ({
  number,
  name,
  volume,
  volume_unit,
  weight,
  weight_unit,
  quantity,
  quantity_unit,
  sku,
  has_hazard_mat,
  columnHeaders,
  taskCreatorRole,
  dimension,
}: ItemRowProps) => {
  const renderItemOps = () => (
    <View
      style={{
        flexDirection: 'row',
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#DEDEDE',
        marginTop: 10,
      }}
    >
      <Text break style={[styles.textMedium, { width: columnHeaders[0].width }]}>
        {number}
      </Text>
      <Text
        break
        style={[styles.textMedium, { width: columnHeaders[1].width, paddingRight: 10 }]}
      >
        {name ?? '-'}
      </Text>
      <Text break style={[styles.textMedium, { width: columnHeaders[2].width }]}>
        {quantity ? `${quantity} ${quantity_unit}` : '-'}
      </Text>
      <Text wrap break style={[styles.textMedium, { width: columnHeaders[3].width }]}>
        {sku ? sku : '-'}
      </Text>
      <Text break style={[styles.textMedium, { width: columnHeaders[4].width }]}>
        {weight ? `${weight} ${weight_unit}` : '-'}
      </Text>
      <Text break style={[styles.textMedium, { width: columnHeaders[5].width }]}>
        {dimension &&
        (dimension.length > 0 || dimension.width > 0 || dimension.height > 0) ? (
          <>
            {dimension.length > 0 ? dimension.length : '-'} x{' '}
            {dimension.width > 0 ? dimension.width : '-'} x{' '}
            {dimension.height > 0 ? dimension.height : '-'} cm
          </>
        ) : (
          '-'
        )}
      </Text>
      <SuperScriptText
        volume={volume}
        volumeUnit={volume_unit}
        width={columnHeaders[6].width}
      />
      <Text break style={[styles.textMedium, { width: columnHeaders[7].width }]}>
        {has_hazard_mat ? 'Yes' : 'No'}
      </Text>
    </View>
  );

  const renderItemMerchant = () => (
    <View
      style={{
        flexDirection: 'row',
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#DEDEDE',
        marginTop: 10,
      }}
    >
      <Text break style={[styles.textMedium, { width: columnHeaders[0].width }]}>
        {number}
      </Text>
      <Text
        break
        style={[styles.textMedium, { width: columnHeaders[1].width, paddingRight: 10 }]}
      >
        {name ?? '-'}
      </Text>
      <Text break style={[styles.textMedium, { width: columnHeaders[2].width }]}>
        {quantity ? `${quantity} ${quantity_unit}` : '-'}
      </Text>
      <Text break style={[styles.textMedium, { width: columnHeaders[3].width }]}>
        {weight ? `${formatNumberWithSpaces(weight)} ${weight_unit}` : '-'}
      </Text>
      <Text break style={[styles.textMedium, { width: columnHeaders[4].width }]}>
        {dimension &&
        (dimension.length > 0 || dimension.width > 0 || dimension.height > 0) ? (
          <>
            {dimension.length > 0 ? dimension.length : '-'} x{' '}
            {dimension.width > 0 ? dimension.width : '-'} x{' '}
            {dimension.height > 0 ? dimension.height : '-'} cm
          </>
        ) : (
          '-'
        )}
      </Text>
      <Text break style={[styles.textMedium, { width: columnHeaders[5].width }]}>
        {has_hazard_mat ? 'Yes' : 'No'}
      </Text>
    </View>
  );

  return taskCreatorRole === 'SysAdmin' ? renderItemOps() : renderItemMerchant();
};

interface ItemsTableProps {
  broadcastPreference: string;
  cargoDetails: CargoDetail[];
  items: Item[];
  user: User;
}
const ItemsTable = ({
  broadcastPreference,
  cargoDetails,
  items,
  user,
}: ItemsTableProps) => {
  const taskCreatorRole = user.roles.includes('SysAdmin') ? 'SysAdmin' : 'Merchant';
  let columnHeaders: { name: string; width: string }[] = [];
  if (
    broadcastPreference === BROADCAST_PREFERENCES.marine &&
    taskCreatorRole === 'Merchant'
  ) {
    columnHeaders = CARGO_DETAILS_COLUMNS_HEADER_MERCHANT;
  } else if (
    broadcastPreference === BROADCAST_PREFERENCES.marine &&
    taskCreatorRole === 'SysAdmin'
  ) {
    columnHeaders = CARGO_DETAILS_COLUMNS_HEADER;
  } else if (taskCreatorRole === 'Merchant') {
    columnHeaders = ITEMS_COLUMNS_HEADER_MERCHANT;
  } else {
    columnHeaders = ITEMS_COLUMNS_HEADER;
  }
  const isMarine = broadcastPreference === BROADCAST_PREFERENCES.marine;
  const isItemsExist = isMarine ? !!cargoDetails.length : !!items.length;

  return (
    <View
      style={{
        flexDirection: 'column',
        marginBottom: 16,
      }}
    >
      {/* Table Column */}
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          paddingBottom: 10,
          borderBottomWidth: isItemsExist ? 1 : 0,
          borderBottomColor: '#DEDEDE',
        }}
      >
        {isItemsExist &&
          columnHeaders.map((header, index) => (
            <Text key={index} style={[styles.textSmallBold, { width: header.width }]}>
              {header.name}
            </Text>
          ))}
      </View>
      {broadcastPreference === BROADCAST_PREFERENCES.marine ? (
        <>
          {cargoDetails.map((cargoDetail, index) => (
            <ItemRowCargoDetail
              key={cargoDetail.id}
              {...cargoDetail}
              number={index + 1}
              columnHeaders={columnHeaders}
              taskCreatorRole={taskCreatorRole}
            />
          ))}
        </>
      ) : (
        <>
          {isItemsExist ? (
            <>
              {items.map((item, index) => (
                <ItemRow
                  key={item.id}
                  {...item}
                  number={index + 1}
                  columnHeaders={columnHeaders}
                  taskCreatorRole={taskCreatorRole}
                />
              ))}
            </>
          ) : (
            <>
              <Text style={[styles.textMedium, { marginTop: -20, marginBottom: 10 }]}>
                No item details
              </Text>
            </>
          )}
        </>
      )}
    </View>
  );
};

export default ItemsTable;
