import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Button, SysAdminMainContainer } from 'components';
import styled from 'styled-components';
import TaskClient from 'httpClients/taskClient';
import { Item, AddLineItemsForm } from 'models/task';
import * as H from 'history';

interface SysAdminAddLineItemProps {
  history: H.History;
}

interface SysAdminAddLineItemState {
  items: Item[];
  error: string | null;
}

interface LocationState {
  from: string;
}

type Props = RouteComponentProps<{ id: string }, object, LocationState> & SysAdminAddLineItemProps;
class SysAdminAddLineItems extends React.Component<Props, SysAdminAddLineItemState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      items: [{}],
      error: null,
    };
  }

  onAddItemClick = (): void => {
    this.setState(prevState => ({
      items: [...prevState.items, {}],
    }));
  }

  onRemoveItemClick = (i: number): void => {
    const items = [...this.state.items];
    items.splice(i, 1);
    this.setState({ items });
  }

  handleChange = (i: number, name: string, value: string, type: string): void => {
    let formattedValue: string | number = value;

    if (type === 'number') {
      if (name === 'quantity') {
        formattedValue = parseInt(value);//quantity type is int in api
      } else {
        formattedValue = parseFloat(value);//weight and volume are float
      }
    }

    const newArr = [...this.state.items];
    newArr[i] = {
      ...newArr[i],
      [name]: formattedValue,
    };

    this.setState({
      items: newArr,
    });
  }

  checkNameValidation = (): boolean => {
    const checkEmptyName = this.state.items.find(item => (
      item.name === undefined || item.name === ''
    ));
    if (checkEmptyName !== undefined) {
      this.setState({
        error: 'Name should not be empty.',
      });
      return false;
    } else {
      return true;
    }
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const { id } = this.props.match.params;

    if (this.checkNameValidation()) {
      try {
        const client = new TaskClient();

        const task = await client.sysAdminGetTask(id);

        const addLineItemsForm: AddLineItemsForm = {
          items: this.state.items,
          version_rev: task.version_rev,
        };

        await client.addLineItem(id, addLineItemsForm);
        this.props.history.push(`/sys/tasks/${id}`);
      } catch (e) {
        this.setState({ error: e });
      }
    }
  }

  renderItemForm = (item: Item, i: number): React.ReactNode => {
    const itemsFields = [
      {
        label: 'Name',
        type: 'text',
        name: 'name',
        value: item.name,
        required: '*',
      },
      {
        label: 'Sku',
        type: 'text',
        name: 'sku',
        value: item.sku,
        required: '',
      },
      {
        label: 'Quantity',
        type: 'number',
        name: 'quantity',
        value: item.quantity,
        required: '',
      },
      {
        label: 'Quantity Unit',
        type: 'text',
        name: 'quantity_unit',
        value: item.quantity_unit,
        required: '',
      },
      {
        label: 'Volume',
        type: 'number',
        name: 'volume',
        value: item.volume,
        required: '',
      },
      {
        label: 'Volume Unit',
        type: 'text',
        name: 'volume_unit',
        value: item.volume_unit,
        required: '',
      },
      {
        label: 'Weight',
        type: 'number',
        name: 'weight',
        value: item.weight,
        required: '',
      },
      {
        label: 'Weight Unit',
        type: 'text',
        name: 'weight_unit',
        value: item.weight_unit,
        required: '',
      },
    ];
    return (
      <Section key={i}>
        {
          itemsFields.map((field) => (
            <div key={field.name}>
              <Label>{field.label}<Required>{field.required}</Required></Label>
              <TextField
                name={field.name}
                type={field.type}
                value={field.value}
                onChange={(e): void => this.handleChange(i, e.target.name, e.target.value, e.target.type)}
              />
            </div>
          ))
        }
        <Button
          type='button'
          onClick={(): void => this.onRemoveItemClick(i)}
        >
          Remove this Item
        </Button>
      </Section>
    );
  }

  render(): React.ReactNode {
    const { id } = this.props.match.params;

    return (
      <SysAdminMainContainer selected="tasks">
        <Header>
          <Title>Add Line Item</Title>
          <Link to={`/sys/tasks/${id}`}>
            <Button>Back To Order Detail</Button>
          </Link>
        </Header>
        <Container>
          {
            this.state.error !== null
              ? <ErrorMessage>{this.state.error}</ErrorMessage>
              : false
          }
          <form onSubmit={this.handleSubmit}>
            {this.state.items.map((item, i) => this.renderItemForm(item, i))}
            <Button
              type='button'
              onClick={this.onAddItemClick}
            >
              Add More Item
            </Button>
            <Button type='submit'>Submit</Button>
          </form>
        </Container>
      </SysAdminMainContainer>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 40rem;
`;

const TextField = styled.input`
  width: 20.5rem;
  padding: 0.25rem;
  margin: 0.5rem;
`;

const Label = styled.label`
  margin: 0.5rem;
  width: 16rem;
  float: left;
`;

const Section = styled.div`
  border-style: ridge;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
`;

const Required = styled.span`
  color: red;
`;

export default SysAdminAddLineItems;
