import { v4 as uuidv4 } from 'uuid';

function replaceAll(str: string, a: string, b: string): string {
  return str.split(a).join(b);
}

export function generateCorrelationId(): string {
  return uuidv4();
}

export function saveBlob(blob: Blob, fileName: string): void {
  const objectURL = URL.createObjectURL(new Blob([blob]));
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.cssText = 'display: none';
  a.href = objectURL;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(objectURL);
}

export function shareSocialMedia(
  platform: 'telegram' | 'whatsapp',
  shareContent: string,
  shareUrl = ''
): void {
  const environment = process.env.ENVIRONMENT;
  let gotsurgeUrl = 'https://app.gotsurge.co/';
  if (environment !== 'production' && environment !== '') {
    gotsurgeUrl = `https://${environment}.gotsurge.co/`;
  } else {
    gotsurgeUrl = `${window.location.origin}/`;
  }
  const utmSource =
    platform === 'telegram' ? '?utm_source=telegram' : '?utm_source=whatsapp';
  let encodedUrl = replaceAll(
    encodeURI(
      shareUrl ? shareContent.replace(shareUrl, shareUrl + utmSource) : shareContent
    ),
    '&',
    '%26'
  );
  encodedUrl = replaceAll(encodedUrl, '#', '%23');

  let url = '';
  if (platform === 'telegram') {
    url = `https://t.me/share/url?url=${encodeURI(gotsurgeUrl)}&text=%0A${encodedUrl}`;
  } else if (platform === 'whatsapp') {
    url = `https://wa.me/?text=${encodedUrl}`;
  }

  window.open(url);
}

export function shareEmail(
  body: string,
  subject: string,
  email: string = '',
): void {
  const encodedBody = encodeURIComponent(body);
  window.open(`mailto:${email}?subject=${subject}&body=${encodedBody}`);
}