import * as React from 'react';
import {
  Card,
  CardWrapper,
  ColumnItem,
  Divider,
  BulletPoint,
  Stack,
  StyledLink,
  SuperScript,
} from '../styles';
import { Task } from 'models/task';
import { formatNumberWithSpaces, formatPrice } from 'utils/formatter';
import { Typography } from 'components';

import BROADCAST_PREFERENCES, {
  BROADCAST_PREFERENCE_DESCRIPTION,
} from 'constants/broadcastPreference';
import { VEHICLE_PREFERENCE_DESCRIPTION } from 'constants/vehiclePreference';
import { Organization } from 'models/organization';
import { CargoDetail, Item } from 'models/task/item';
import { CARGO_TYPES_DESCRIPTION } from '../../../constants/cargo';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import DateHelper from 'utils/dateHelper';
import { StatusType } from 'models/task/status';
import { isItemExceedingVehicleDimensions } from 'utils/taskHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface Props {
  task: Task;
  organization?: Organization;
  ops?: boolean;
}

const endsWithNumber = (str: string): boolean => {
  return !isNaN(parseInt(str.charAt(str.length - 1)));
};

const renderSuperscriptText = (
  volume: number,
  volumeUnit: string,
  lineHeight?: string
): React.ReactNode => {
  if (!volume) {
    return '-';
  }

  let volumeNumber = volumeUnit;
  let superScript = '';
  if (endsWithNumber(volumeUnit)) {
    volumeNumber = volumeUnit.substring(0, volumeUnit.length - 1);
    superScript = volumeUnit.charAt(volumeUnit.length - 1);
  }

  const volumeText = formatNumberWithSpaces(volume);

  return (
    <>
      {superScript ? (
        <SuperScript lineHeight={lineHeight}>
          {volumeText} {volumeNumber}
          {superScript ? <sup>{superScript}</sup> : ''}
        </SuperScript>
      ) : (
        <>
          {volumeText} {volumeUnit}
        </>
      )}
    </>
  );
};

const renderCargoDetails = (
  cargoDetails: CargoDetail[],
  totalLifts: number,
  isOps: boolean
): JSX.Element => {
  return (
    <div>
      <Typography
        as="h4"
        size="sm"
        color="gray_900"
        customStyle={{
          marginBottom: '0.25rem',
        }}
      >
        Cargo Details
      </Typography>
      <Typography
        as="p"
        size="sm"
        color="gray_600"
        customStyle={{
          marginBottom: '0.35rem',
        }}
      >
        Total Cargo: {totalLifts} Lifts
      </Typography>

      {cargoDetails.map((item, i) => (
        <Stack key={item.name + i} gap="2px" direction="column">
          <Stack gap="0px">
            <ColumnItem>
              <Typography
                as="p"
                color="gray_600"
                customStyle={{
                  marginBottom: '0.25rem',
                  marginRight: '0.5rem',
                }}
              >
                {i + 1}.
              </Typography>
            </ColumnItem>
            <ColumnItem>
              <Typography
                as="p"
                color="gray_600"
                customStyle={{
                  marginBottom: '0.25rem',
                }}
              >
                {CARGO_TYPES_DESCRIPTION[item.name]}
                {item.remarks ? `: ${item.remarks}` : null} x{' '}
                {item.quantity ? item.quantity : '-'}{' '}
                {item.quantity_unit ? `${item.quantity_unit} ` : null}
                {item.weight ? `| ${item.weight} ${item.weight_unit}` : null}{' '}
                {item.sku ? `| ${item.sku}` : null}
              </Typography>

              {item.dimension &&
                (item.dimension.length > 0 ||
                  item.dimension.width > 0 ||
                  item.dimension.height > 0) && (
                  <>
                    <Typography
                      as="p"
                      size="sm"
                      color="gray_600"
                      customStyle={{
                        marginBottom: '0.25rem',
                      }}
                    >
                      {item.dimension.length > 0 ? item.dimension.length : '-'} x{' '}
                      {item.dimension.width > 0 ? item.dimension.width : '-'} x{' '}
                      {item.dimension.height > 0 ? item.dimension.height : '-'} cm
                      {item.volume && isOps ? (
                        <>
                          {' | '}
                          {renderSuperscriptText(item.volume, item.volume_unit, '0')}
                        </>
                      ) : null}
                    </Typography>
                  </>
                )}
              <Typography
                as="p"
                size="sm"
                color="gray_600"
                customStyle={{
                  marginBottom: '0.25rem',
                  lineHeight: '1.25',
                }}
              >
                HAZMAT: {item.has_hazard_mat ? 'Yes' : 'No'}
              </Typography>
            </ColumnItem>
          </Stack>
        </Stack>
      ))}
    </div>
  );
};

const renderItems = (
  items: Item[],
  totalItemWeight: number,
  totalWeightUnit: string,
  isOps?: boolean
): JSX.Element => {
  return (
    <div style={{ width: '100%' }}>
      <Stack direction="column" gap="0">
        <Typography
          as="h4"
          size="sm"
          color="gray_900"
          customStyle={{
            marginBottom: '0.35rem',
          }}
        >
          Item Details
        </Typography>

        {totalWeightUnit === 'kg' &&
          typeof totalItemWeight === 'number' &&
          totalItemWeight > 0 && (
            <Typography
              as="span"
              size="sm"
              color="gray_600"
              customStyle={{
                marginBottom: '0.35rem',
              }}
            >
              Total Weight: {totalItemWeight} kg
            </Typography>
          )}

        {items.length
          ? items.map((item, i) => (
              <Stack key={item.name + i} gap="0.125rem" direction="column">
                <Stack gap="0px">
                  <ColumnItem
                    style={{
                      flexBasis: '18px',
                    }}
                  >
                    <Typography
                      as="p"
                      color="gray_600"
                      customStyle={{
                        marginBottom: '0.25rem',
                      }}
                    >
                      {i + 1}.
                    </Typography>
                  </ColumnItem>
                  <ColumnItem
                    style={{
                      flexBasis: 'auto',
                    }}
                  >
                    <Typography
                      as="p"
                      color="gray_600"
                      customStyle={{
                        marginBottom: '0.25rem',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {item.name ? item.name : '-'} x{' '}
                      {item.quantity ? item.quantity : '-'}{' '}
                      {item.quantity_unit ? `${item.quantity_unit} ` : null}
                      {item.weight ? `| ${item.weight} ${item.weight_unit}` : null}{' '}
                      {item.sku ? `| ${item.sku}` : null}
                      {item.dimension &&
                        (item.dimension.length > 0 ||
                          item.dimension.width > 0 ||
                          item.dimension.height > 0) && (
                          <>
                            <br />
                            <Typography as="span" size="sm" color="gray_600">
                              {item.dimension.length > 0 ? item.dimension.length : '-'} x{' '}
                              {item.dimension.width > 0 ? item.dimension.width : '-'} x{' '}
                              {item.dimension.height > 0 ? item.dimension.height : '-'} cm
                              {item.volume && isOps ? (
                                <>
                                  {' | '}
                                  {renderSuperscriptText(
                                    item.volume,
                                    item.volume_unit,
                                    '0'
                                  )}
                                </>
                              ) : null}
                            </Typography>
                          </>
                        )}
                    </Typography>
                    <Typography
                      as="p"
                      size="sm"
                      color="gray_600"
                      customStyle={{
                        marginBottom: '0.25rem',
                        lineHeight: '1.25',
                      }}
                    >
                      HAZMAT: {item.has_hazard_mat ? 'Yes' : 'No'}
                    </Typography>
                  </ColumnItem>
                </Stack>
              </Stack>
            ))
          : '-'}
      </Stack>
    </div>
  );
};

const renderPrice = (
  currency: string,
  price: number,
  taskStatus: StatusType
): JSX.Element => {
  if (price <= 0) {
    return null;
  }
  if (taskStatus !== 'complete') {
    return null;
  }
  return (
    <div>
      <Typography
        as="h4"
        size="sm"
        color="gray_900"
        customStyle={{
          marginBottom: '0.35rem',
        }}
      >
        Price
      </Typography>
      <Typography
        as="p"
        color="gray_600"
        customStyle={{
          marginBottom: '0.25rem',
        }}
      >
        {formatPrice(currency, price)}
      </Typography>
    </div>
  );
};

const renderFTLInfo = (
  serviceType: string,
  vehicleDescription: string,
  totalLifts: number
): JSX.Element => (
  <>
    {vehicleDescription} <BulletPoint icon={faCircle} /> {totalLifts}{' '}
    {totalLifts > 1 ? 'Lifts' : 'Lift'}
  </>
);

const renderLTLInfo = (serviceType: string, totalLifts: number): JSX.Element => {
  return (
    <>
      {serviceType} <BulletPoint icon={faCircle} /> {totalLifts} {'Lifts'}
    </>
  );
};

const renderServiceDetail = (
  serviceType: string,
  vehicleDescription: string,
  totalVehicle: number,
  totalLifts: number
): JSX.Element => {
  serviceType = serviceType.toUpperCase();
  switch (serviceType) {
    case 'FTL':
      return renderFTLInfo(serviceType, vehicleDescription, totalLifts);
    case 'LTL':
      return renderLTLInfo(serviceType, totalLifts);
    default:
      return <>{vehicleDescription}</>;
  }
};

const IdentityInfoCard = ({ task, ops }: Props): JSX.Element => {
  const isMarine = task.broadcast_preference === BROADCAST_PREFERENCES.marine;
  const vehicleDescription = VEHICLE_PREFERENCE_DESCRIPTION[task.vehicle_preference];
  const isSelectedVehicleMotorcycle = task.vehicle_preference === 'motorcycle';
  let showWarningExceedDimensions = false;
  if (!isMarine && isSelectedVehicleMotorcycle) {
    const { motorcycle } = isItemExceedingVehicleDimensions(task.items, ['motorcycle']);
    showWarningExceedDimensions = motorcycle;
  }

  let serviceDetailContent: string | React.ReactNode = vehicleDescription;
  let itemsOrCargoDetailContent = null;
  let priceContent = null;

  if (isMarine) {
    const totalLifts = task.item_summary.total_item_quantity;
    itemsOrCargoDetailContent = renderCargoDetails(
      task.cargo_details ?? [],
      totalLifts,
      ops
    );
    priceContent = renderPrice(
      task.currency,
      task.price,
      task.current_status as StatusType
    );
    serviceDetailContent = renderServiceDetail(
      task.service_type,
      vehicleDescription,
      1, // temporary hardcode for totalVehicle FTL
      totalLifts
    );
  } else {
    itemsOrCargoDetailContent = renderItems(
      task.items,
      task.item_summary.total_item_weight,
      task.item_summary.total_weight_unit,
      ops
    );
  }
  const userRoles = (task.user && task?.user.roles) || [];
  let createdIn = '';
  if (userRoles.length > 0) {
    createdIn = userRoles.includes('SysAdmin') ? 'Ops' : 'Merchant';
  }

  return (
    <>
      <Stack direction="column">
        <CardWrapper>
          <Card>
            <div>
              <Typography
                as="p"
                size="xs"
                customStyle={{
                  marginBottom: '0.5rem',
                }}
              >
                Updated at {DateHelper.formatDateSimpleMonthTime(task.updated_at)}
              </Typography>
              <Typography as="p" size="xs">
                Created at {DateHelper.formatDateSimpleMonthTime(task.created_at)}
              </Typography>
            </div>
            <Divider />
            {ops && (
              <>
                <div>
                  <Typography
                    as="h4"
                    size="sm"
                    color="gray_900"
                    customStyle={{
                      marginBottom: '0.35rem',
                    }}
                  >
                    Organisation
                  </Typography>
                  <Typography as="p" color="gray_600">
                    {task.org_name}
                  </Typography>
                  <Typography as="p" color="gray_600">
                    {createdIn}
                  </Typography>
                </div>
                <Divider />
                <div>
                  <Typography
                    as="h4"
                    size="sm"
                    color="gray_900"
                    customStyle={{
                      marginBottom: '0.35rem',
                    }}
                  >
                    Runsheet ID
                  </Typography>

                  <Typography as="p" color="primary_600">
                    {task.runsheet?.id ? (
                      <StyledLink to={`/sys/runsheets/${task.runsheet.id}`}>
                        {task.runsheet.id.split('-')[0]}
                      </StyledLink>
                    ) : (
                      '-'
                    )}
                  </Typography>
                </div>
                <Divider />
              </>
            )}

            <div>
              <Typography
                as="h4"
                size="sm"
                color="gray_900"
                customStyle={{
                  marginBottom: '0.35rem',
                }}
              >
                Service
              </Typography>
              <Typography
                as="p"
                color="gray_600"
                customStyle={{
                  marginBottom: '0.25rem',
                  display: 'inline-flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {serviceDetailContent}{' '}
                {showWarningExceedDimensions && (
                  <Typography
                    as="span"
                    color="warning_600"
                    customStyle={{ display: 'inline-block', marginLeft: '0.3rem' }}
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </Typography>
                )}
              </Typography>
              <Typography
                as="p"
                color="gray_600"
                customStyle={{
                  marginBottom: '0.25rem',
                }}
              >
                {BROADCAST_PREFERENCE_DESCRIPTION[task.broadcast_preference] &&
                  BROADCAST_PREFERENCE_DESCRIPTION[task.broadcast_preference].label}
              </Typography>
            </div>
            {itemsOrCargoDetailContent}
            {priceContent}
          </Card>
        </CardWrapper>
      </Stack>
    </>
  );
};

export default IdentityInfoCard;
