import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import theme from 'theme';
import Routes from 'routes';
import configureStore from 'reduxActions/store';
import { getBearerToken, getUser } from 'utils/userHelper';
import { SigninResponse } from 'models/auth';
import { receiveSigninSuccess } from 'reduxActions/auth/authActions';
import GlobalStyle from './globalStyle';
import { ProductFruits } from 'react-product-fruits';

// Declare global types
declare global {
  interface Window {
    analytics: any; // eslint-disable-line
  }
}

const store = configureStore();

const history = createBrowserHistory();

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  release: 'gotsurge-frontend@' + process.env.npm_package_version,
  environment: process.env.ENVIRONMENT,
});

if (process.env.SEGMENT_KEY) {
  /* eslint-disable */
  // Segment code
  function segmentFunc() {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize) {
      if (analytics.invoked) {
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.');
      } else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
        ];
        analytics.factory = function (e: any) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key: any, e: any) {
          var t = document.createElement('script');
          t.type = 'text/javascript';
          t.async = !0;
          t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
          var n = document.getElementsByTagName('script')[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics._writeKey = process.env.SEGMENT_KEY;
        analytics.SNIPPET_VERSION = '4.13.2';

        // The source write key
        analytics.load(process.env.SEGMENT_KEY);
        analytics.page();

        let prevPath = '/';

        history.listen((location) => {
          const currentPath = `${location.pathname}${location.search}`;
          if (prevPath !== currentPath) {
            prevPath = currentPath;
            window.analytics.page();
          }
        });
      }
    }
  }

  segmentFunc();
  /* eslint-enable */
}

interface AppState {
  userLoaded: boolean;
}

class App extends React.Component<unknown, AppState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      userLoaded: false,
    };
  }

  componentDidMount(): void {
    const token = getBearerToken();
    const user = getUser();

    // if user is login
    if (user) {
      const userObj: SigninResponse = {
        user,
        bearer_token: token,
      };

      store.dispatch(receiveSigninSuccess(userObj));

      if (process.env.SEGMENT_KEY) {
        window.analytics.identify(
          userObj.user.id,
          {
            email: userObj.user.email,
            name: userObj.user.name,
          },
          {
            Intercom: {
              user_hash: userObj.user.user_hash,
            },
          }
        );
      }
    }

    this.setState({
      userLoaded: true,
    });
  }

  renderProductFruits(): React.ReactNode {
    let data: React.ReactNode;
    const user = getUser();
    const productFruitsKey = process.env.PRODUCT_FRUITS_KEY;
    if (user && productFruitsKey) {
      const role = user.roles.includes('SysAdmin') ? 'SysAdmin' : 'Merchant';
      const fullName = user.name.split(' ');
      const userInfo = {
        username: user.id,
        email: user.email,
        firstname: fullName[0],
        lastname: fullName.length > 1 ? fullName.slice(1).join(' ') : '',
        signUpAt: user.confirmed_at?.toString(),
        role: role,
        props: { phoneNumber: user.phone_number, orgId: user.org_id },
      };
      data = (
        <ProductFruits
          workspaceCode={productFruitsKey}
          language="en"
          user={userInfo}
          debug={process.env.PRODUCT_FRUITS_KEY !== 'production'}
        />
      );
      return data;
    }
  }

  render(): React.ReactNode {
    return (
      <Provider store={store}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          {this.state.userLoaded && this.renderProductFruits()}
          {this.state.userLoaded && <Routes history={history} />}
        </ThemeProvider>
      </Provider>
    );
  }
}

// ReactDOM.render(<App/>, document.getElementById('gotsurge'));
const root = ReactDOM.createRoot(document.getElementById('gotsurge'));
root.render(<App />);
