import { Role } from 'models/role';

export interface RoleState {
  roles: Role[];
}

export const RECEIVE_ROLES = 'RECEIVE_ROLES';

export interface ReceiveRolesAction {
  type: typeof RECEIVE_ROLES;
  roles: Role[];
}

export type RoleActionTypes = ReceiveRolesAction;