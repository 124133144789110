import * as React from 'react';
import { connect } from 'react-redux';
import driverProfileClient from 'httpClients/driverProfileClient';
import AuthClient from 'httpClients/authClient';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import {
  Breadcrumb,
  StatusLabel,
  FieldView,
  CenterModal,
  Alert,
  SysAdminMainContainer,
  DropdownMenu,
  Separator,
} from 'components';
import { DriverProfile } from 'models/driverProfile';
import { User } from 'models/auth';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  faPencilAlt,
  faCheck,
  faHammer,
  faEnvelopeOpen,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { RootState } from 'reduxActions/store';
import STATUS, { VEHICLE_STATUS_COLOR } from 'constants/driverProfileStatus';
import COLOR from 'constants/color';
import DateHelper from 'utils/dateHelper';
import { capitalizeEachWord } from 'utils/formatter';
import DriverInfoCard from './cards/driverInfoCard';
import { VEHICLE_PREFERENCE_DESCRIPTION } from 'constants/vehiclePreference';
import DriverPerformanceCard from './cards/driverPerformanceCard';
import {
  ActionButtonsContainer,
  ActionButton,
  CustomCard,
  Container,
  Heading,
  InfoCard,
  Icon,
  Image,
  MainColumn,
  PersonalInfoColumn,
  Row,
  Title,
  VehicleInfoTitle,
  AlertMessage,
  AlertDescription,
  DropdownButton,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Organization } from 'models/organization';

interface StateProps {
  organization: Organization;
  driverProfile: DriverProfile;
  user: User;
}

interface LocationState {
  from: string;
}

interface DetailState {
  verifySuccess: boolean;
  confirmSuccess: boolean;
  error: string | null;
}

type Props = RouteComponentProps<{ id: string }, object, LocationState> & StateProps;

class DriverDetail extends React.Component<Props, DetailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      verifySuccess: false,
      confirmSuccess: false,
      error: null,
    };
  }

  async componentDidMount(): Promise<void> {
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    const { id } = this.props.match.params;
    this.fetchUser(id);
    await this.fetchDriverProfile(id);
    if (this.props.driverProfile.org_id !== '') {
      this.fetchOrgManagementForDriver(this.props.driverProfile.org_id);
    }
  }

  fetchOrgManagementForDriver = async (id: string): Promise<void> => {
    const client = new OrganizationManagementClient();
    await client.sysAdminGetOrganization(id);
  };

  fetchDriverProfile = async (id: string): Promise<void> => {
    const client = new driverProfileClient();
    await client.getDriverProfile(id);
  };

  fetchUser = async (id: string): Promise<void> => {
    const authClient = new AuthClient();
    await authClient.sysGetUser(id);
  };

  verifyDriver = async (): Promise<void> => {
    const { driverProfile } = this.props;

    const client = new driverProfileClient();
    await client.verifyDriver(driverProfile.id, driverProfile.version_rev);
    this.setState({ verifySuccess: true });
  };

  confirmUser = async (): Promise<void> => {
    const { id } = this.props.match.params;

    const authClient = new AuthClient();
    await authClient.sysConfirmUser(id);
    this.fetchData();
    this.setState({ confirmSuccess: true });
  };

  toggleIsQA = async (): Promise<void> => {
    const { driverProfile } = this.props;
    this.setState({ error: null });
    try {
      const client = new driverProfileClient();
      await client.toggleIsQA(driverProfile.id, {
        version_rev: driverProfile.version_rev,
        is_qa: !driverProfile.is_qa,
      });

      this.fetchDriverProfile(driverProfile.id);
    } catch (e) {
      let errorMessage = '';
      if (e.code === 'version-conflict') {
        errorMessage = 'There are changes to this profile.';
      } else {
        errorMessage = e.message ? e.message : 'Unknown error';
        if (e.trace_id) {
          errorMessage += ` (trace_id: ${e.trace_id}`;
        }
      }
      this.setState({ error: errorMessage });
    }
  };

  rightButtonText = (): React.ReactNode => {
    return (
      <>
        <Icon icon={faCheck} color={COLOR.black} />
        OK
      </>
    );
  };

  renderHeading = (): React.ReactNode => {
    const { driverProfile, user } = this.props;
    const orderListQueryParams =
      (this.props.location.state && this.props.location.state.from) || '';
    let activeDriver = true;
    if (driverProfile && driverProfile.deleted_at !== null) {
      activeDriver = false;
    }

    return (
      <Heading>
        <Title>Driver Profile</Title>
        <ActionButtonsContainer>
          <ActionButton
            buttonStyle="encourage"
            buttonType="neutral"
            onClick={(): void => {
              this.props.history.push(`/sys/drivers?${orderListQueryParams}`);
            }}
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
          >
            Back
          </ActionButton>
          <DropdownMenu>
            {driverProfile && !activeDriver && (
              <DropdownButton
                disabled={true}
                size="xl"
                buttonStyle="encourage"
                buttonType="neutral"
                fontWeight={400}
                icon={
                  <FontAwesomeIcon icon={faPencilAlt} style={{ color: COLOR.grey }} />
                }
              >
                Edit
              </DropdownButton>
            )}
            {driverProfile && activeDriver && (
              <>
                <DropdownButton
                  onClick={(): void => {
                    this.props.history.push(
                      `/sys/drivers/${driverProfile && driverProfile.id}/squads`
                    );
                  }}
                  size="xl"
                  buttonStyle="encourage"
                  buttonType="neutral"
                  fontWeight={400}
                >
                  Squads
                </DropdownButton>
                <Separator />
                <DropdownButton
                  onClick={(): void => {
                    this.props.history.push(
                      `/sys/drivers/${driverProfile && driverProfile.id}/edit`
                    );
                  }}
                  size="xl"
                  buttonStyle="encourage"
                  buttonType="neutral"
                  fontWeight={400}
                  icon={<FontAwesomeIcon icon={faPencilAlt} />}
                >
                  Edit
                </DropdownButton>
                <Separator />
                <DropdownButton
                  onClick={this.toggleIsQA}
                  size="xl"
                  buttonStyle="encourage"
                  buttonType="neutral"
                  fontWeight={400}
                  icon={<FontAwesomeIcon icon={faHammer} />}
                >
                  {driverProfile && driverProfile.is_qa ? 'Set as not QA' : 'Set as QA'}
                </DropdownButton>
                {driverProfile && !driverProfile.driver_verified_at && (
                  <>
                    <Separator />
                    <DropdownButton
                      onClick={this.verifyDriver}
                      size="xl"
                      buttonStyle="encourage"
                      buttonType="neutral"
                      fontWeight={400}
                      icon={
                        <FontAwesomeIcon icon={faCheck} style={{ color: COLOR.blue }} />
                      }
                    >
                      Verify
                    </DropdownButton>
                  </>
                )}
                {user && !user.confirmed_at && (
                  <>
                    <Separator />
                    <DropdownButton
                      onClick={this.confirmUser}
                      size="xl"
                      buttonStyle="encourage"
                      buttonType="neutral"
                      fontWeight={400}
                      icon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
                    >
                      Confirm
                    </DropdownButton>
                  </>
                )}
              </>
            )}
          </DropdownMenu>
        </ActionButtonsContainer>
      </Heading>
    );
  };

  renderPersonalInfo(): React.ReactNode {
    const { driverProfile, organization } = this.props;

    return (
      <CustomCard>
        <Title>Personal Info</Title>
        <Row>
          <PersonalInfoColumn>
            <FieldView fieldName="Profile Photo">
              <Image
                src={
                  driverProfile &&
                  driverProfile.display_picture &&
                  driverProfile.display_picture.url
                    ? driverProfile.display_picture.url
                    : null
                }
                alt={
                  driverProfile &&
                  driverProfile.display_picture &&
                  driverProfile.display_picture.name
                }
              />
            </FieldView>
            {organization && organization.business_name !== 'FreelanceDriver' && (
              <FieldView fieldName="Company">
                <Link
                  style={{ textDecoration: 'None' }}
                  to={`/sys/organizations/${organization.id.toString()}/company-details`}
                >
                  {organization.business_name}
                </Link>
              </FieldView>
            )}
            <FieldView fieldName="Display Name">
              {driverProfile && driverProfile.name}
            </FieldView>
            <FieldView fieldName="Availability">
              {driverProfile && driverProfile.is_available ? 'Available' : '-'}
            </FieldView>
          </PersonalInfoColumn>
          <PersonalInfoColumn>
            <FieldView fieldName="Account Type">
              {driverProfile &&
                driverProfile.type &&
                capitalizeEachWord(driverProfile.type)}
            </FieldView>
            <FieldView fieldName="Full Name as per NRIC / FIN">
              {driverProfile && (driverProfile.full_name || driverProfile.name)}
            </FieldView>
            <FieldView fieldName="Mobile Number">
              {driverProfile && driverProfile.phone_number}
            </FieldView>
            <FieldView fieldName="Email">
              {driverProfile && driverProfile.email}
            </FieldView>
            <FieldView fieldName="Last 4 characters of NRIC / FIN">
              {driverProfile && driverProfile.partial_id_chars}
            </FieldView>
          </PersonalInfoColumn>
        </Row>
      </CustomCard>
    );
  }

  renderVehicleInfo(): React.ReactNode {
    const { driverProfile } = this.props;
    const vehicleStatus =
      driverProfile &&
      driverProfile.vehicle_plate_number_verified_at &&
      this.isDateOlderThanToday(driverProfile.vehicle_plate_number_verified_at)
        ? 'verified'
        : 'unverified';

    return (
      <InfoCard>
        <VehicleInfoTitle>
          <Title>Vehicle Info</Title>
          <div>
            <StatusLabel iconColor={VEHICLE_STATUS_COLOR[vehicleStatus]}>
              {STATUS[vehicleStatus]}
            </StatusLabel>
            {/*
            {vehicleStatus === 'verified' ? false : (
              <StyledButton
                width="small"
                buttonType="neutral"
                buttonStyle="encourage"
              >
                Verify
              </StyledButton>
            )}
            */}
          </div>
        </VehicleInfoTitle>
        <FieldView fieldName="Vehicle Plate No">
          {driverProfile && driverProfile.vehicle_plate_number}
        </FieldView>
        <FieldView fieldName="Vehicle Type">
          {driverProfile &&
            driverProfile.vehicle_type &&
            VEHICLE_PREFERENCE_DESCRIPTION[driverProfile.vehicle_type]}
        </FieldView>
      </InfoCard>
    );
  }

  renderPaymentInfo(): React.ReactNode {
    return (
      <InfoCard>
        <Title>Payment Info</Title>
        <FieldView fieldName="Bank Name" />
        <FieldView fieldName="Bank Account No" />
      </InfoCard>
    );
  }

  renderDriverActivity(): React.ReactNode {
    return (
      <CustomCard>
        <Title>Driver Activity</Title>
        <div>-</div>
      </CustomCard>
    );
  }

  renderDriverPerformance(): React.ReactNode {
    return (
      <CustomCard>
        <Title>Performance</Title>
        <FieldView fieldName="Fulfilment rate" />
        <FieldView fieldName="Total Successful Deliveries" />
        <FieldView fieldName="Merchant Satisfaction" />
      </CustomCard>
    );
  }

  isDateOlderThanToday(fromDate: Date): boolean {
    const toDayDate = new Date();
    const newFromDate = new Date(fromDate);

    return DateHelper.formatDate(newFromDate) < DateHelper.formatDate(toDayDate);
  }

  render(): React.ReactNode {
    const driverProfileList =
      (this.props.location.state && this.props.driverProfile.id) || '';
    const { verifySuccess, confirmSuccess, error } = this.state;

    return (
      <SysAdminMainContainer selected="drivers">
        <Breadcrumb
          items={[
            {
              text: 'Drivers',
              onClick: (): void => {
                this.props.history.push(`/sys/drivers?${driverProfileList}`);
              },
            },
            { text: this.props.driverProfile && this.props.driverProfile.id },
          ]}
        />
        {this.renderHeading()}
        <Container>
          <MainColumn flex="2">
            {this.renderPersonalInfo()}
            <Row>
              {this.renderVehicleInfo()}
              {this.renderPaymentInfo()}
            </Row>
            {this.renderDriverActivity()}
          </MainColumn>
          <MainColumn flex="1">
            <DriverInfoCard driverProfile={this.props.driverProfile} />
            <DriverPerformanceCard />
          </MainColumn>
          {verifySuccess ? (
            <CenterModal
              rightButtonText={this.rightButtonText()}
              rightButtonOnClick={(): void => {
                this.setState({ verifySuccess: false });
              }}
              title="Driver-Partner verification"
            >
              <Alert status="success">
                <AlertMessage>Driver-Partner verified</AlertMessage>
              </Alert>
              <AlertDescription>
                The Driver-Partner is now able to receive and complete orders.
              </AlertDescription>
            </CenterModal>
          ) : (
            false
          )}
          {confirmSuccess ? (
            <CenterModal
              rightButtonText={this.rightButtonText()}
              rightButtonOnClick={(): void => {
                this.setState({ confirmSuccess: false });
              }}
              title="User has been confirmed"
            >
              <Alert status="success">
                <AlertMessage>Driver-Partner confirmed</AlertMessage>
              </Alert>
            </CenterModal>
          ) : (
            false
          )}
          {error ? (
            <CenterModal
              rightButtonText="Refresh"
              rightButtonOnClick={(): void => {
                window.location.reload();
              }}
            >
              <Alert status="error">
                <AlertMessage>{error}</AlertMessage>
              </Alert>
            </CenterModal>
          ) : (
            false
          )}
        </Container>
      </SysAdminMainContainer>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: Props): StateProps {
  const { id } = ownProps.match.params;

  return {
    organization: state.organization.organization,
    driverProfile: state.driverProfile.profile,
    user: state.auth.users.find((user) => user.id === id) || null,
  };
}

export default connect(mapStateToProps)(DriverDetail);
