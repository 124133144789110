import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import { getBearerToken } from 'utils/userHelper';
import {
  GetDriverProfileResponse,
  GetDriverProfilesWithSquadResponse,
  MetaDriverProfiles,
  DriverProfileEditForm,
  DriverProfile,
  ToggleIsQAForm,
} from 'models/driverProfile';
import {
  receiveDriverProfiles,
  receiveDriverProfile,
  receiveDriverProfilesWithSquad,
} from 'reduxActions/driverProfile/driverProfileActions';
import { PER_PAGE } from 'constants/paginationMeta';

class DriverProfileClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.DRIVER_PROFILE_SERVICE_PROTOCOL;
    this.host = process.env.DRIVER_PROFILE_SERVICE_HOST;
    this.port = process.env.DRIVER_PROFILE_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(
    method: Method,
    url: string,
    body: T | null = null,
    includeBearerToken = true
  ): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  getDriverProfilesUrl = (params = new URLSearchParams()): string =>
    `${this.getBaseUrl()}/sys/driver-profile?${params}`;

  driverProfileUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/driver-profile/${id}`;
  }

  async getDriverProfiles(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', this.getDriverProfilesUrl(params));

    try {
      const response: AxiosResponse<MetaDriverProfiles> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveDriverProfiles(response.data));
    } catch (error) {
      throw error.message;
    }
  }

  async getDriverProfile(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.driverProfileUrl(id));
    try {
      const response: AxiosResponse<GetDriverProfileResponse> = await axios(request);
      if (
        response &&
        response.data &&
        response.data.driver_profile &&
        response.data.driver_profile.deleted_at !== null
      ) {
        const deletedAccount = '[Deleted Account]';
        response.data.driver_profile = {
          id: deletedAccount,
          name: deletedAccount,
          is_available: false,
          org_id: deletedAccount,
          driver_verified_at: null,
          last_suspended_until: null,
          last_active_at: null,
          full_name: deletedAccount,
          type: deletedAccount,
          email: deletedAccount,
          partial_id_chars: deletedAccount,
          vehicle_type: deletedAccount,
          vehicle_plate_number: deletedAccount,
          vehicle_plate_number_proof: null,
          vehicle_plate_number_verified_at: null,
          phone_number: deletedAccount,
          is_qa: null,
          group_tag: deletedAccount,
          display_picture: null,
          latitude: null,
          longitude: null,
          version_seq: null,
          version_rev: null,
          created_at: null,
          updated_at: null,
          deleted_at: response.data.driver_profile.deleted_at,
        };
      }
      const store = configureStore();
      store.dispatch(receiveDriverProfile(response.data.driver_profile));
    } catch (error) {
      throw error.message;
    }
  }

  async updateDriverProfile(
    id: string,
    form: DriverProfileEditForm
  ): Promise<DriverProfile> {
    const request = this.makeRequest('PUT', this.driverProfileUrl(id), form);
    try {
      const response: AxiosResponse<DriverProfile> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  verifyDriverUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/driver-profile/${id}/verify`;
  }

  async verifyDriver(id: string, versionRev: string): Promise<void> {
    const request = this.makeRequest('PUT', this.verifyDriverUrl(id), {
      version_rev: versionRev,
    });
    try {
      const response: AxiosResponse<{ driver_profile: DriverProfile }> = await axios(
        request
      );
      const store = configureStore();
      store.dispatch(receiveDriverProfile(response.data.driver_profile));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  toggleIsQAUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/driver-profile/${id}/is-qa`;
  }

  async toggleIsQA(id: string, form: ToggleIsQAForm): Promise<void> {
    const request = this.makeRequest('PUT', this.toggleIsQAUrl(id), form);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error.message;
      }
    }
  }

  getDriverProfilesWithSquadUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/driver-profile-with-squad?${params}`;
  }

  async getDriverProfilesWithSquad(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', this.getDriverProfilesWithSquadUrl(params));
    try {
      const response: AxiosResponse<GetDriverProfilesWithSquadResponse> = await axios(
        request
      );
      const store = configureStore();
      store.dispatch(receiveDriverProfilesWithSquad(response.data.driver_profiles));
    } catch (error) {
      throw error.message;
    }
  }
}

export default DriverProfileClient;
