import * as React from 'react';
import * as moment from 'moment';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import COLOR, { transparent } from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Calendar from 'react-calendar';
import './datePicker.css';

interface DatePickerProps {
  containerStyle: FlattenSimpleInterpolation;
  value: Date;
  enablePreviousDate?: boolean;
  label?: string;
  onChange: (v: Date) => void;
}

const DatePicker = (props: DatePickerProps): JSX.Element => {
  const calRef = React.useRef(null);
  const [showDropdown, setDropdown] = React.useState(false);
  const formattedDate = moment(props.value).format('DD MMM YYYY');
  const displayDate = moment(props.value).isSame(new Date(), 'day') ? 'Today (' + formattedDate + ')' : formattedDate;

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (showDropdown && calRef.current && !calRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return (): void => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [calRef, showDropdown]);

  return (
    <Container
      customStyle={props.containerStyle}
    >
      <DateDisplay
        onClick={(): void => { setDropdown(!showDropdown); }}
      >
        {props.label && `${props.label} : `}
        {displayDate}
        <CalendarIcon icon={faCalendarAlt} />
      </DateDisplay>
      {showDropdown && (
        <Calendar
          tileDisabled={({ date }: { date: Date }): boolean => props.enablePreviousDate ? false : moment(new Date()).isAfter(date, 'day')}
          onChange={(v: Date): void => {
            props.onChange(v);
            setDropdown(false);
          }}
          value={props.value}
          className="gs-react-calendar"
          inputRef={calRef}
        />
      )}
    </Container>
  );
};

export default DatePicker;

const Container = styled.div<{ customStyle: FlattenSimpleInterpolation }>`
  ${(props): string => `${props.customStyle}`}
`;

const DateDisplay = styled.div`
  cursor: pointer;
  background-color: ${COLOR.white};
  box-shadow: ${transparent('grey', 0.2)} 0px 0px 0px 2px;
  border-radius: 5px;
  display: inline-block;
  padding: 0.5rem;
`;

const CalendarIcon = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`;
