import * as React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

interface SelectFileButtonProps {
  buttonText?: string;
  buttonStyle?: FlattenSimpleInterpolation;
  children?: React.ReactNode;
  disabled?: boolean;
  label?: string;
  acceptedFormat?: string;
  labelStyle?: FlattenSimpleInterpolation;
  onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

interface LabelProps {
  labelStyle?: FlattenSimpleInterpolation;
  paddingBottom?: string;
  paddingLeft?: string;
}

interface ButtonProps {
  buttonStyle?: FlattenSimpleInterpolation;
  marginLeft?: string;
}

class SelectFileButton extends React.Component<SelectFileButtonProps> {
  input: HTMLInputElement;

  constructor(props: SelectFileButtonProps) {
    super(props);
    this.input = null;
  }

  selectFile = (): void => {
    this.input.click();
  }

  render(): React.ReactNode {
    const { label, labelStyle, children, disabled, onFileChange,
      buttonText, buttonStyle, required, acceptedFormat } = this.props;

    return (
      <SelectFileContainer>
        {label &&
          <Label labelStyle={labelStyle}>
            {label} {required && <RedSpan>*</RedSpan>}
          </Label>
        }
        {children}
        <Button
          disabled={disabled}
          onClick={this.selectFile}
          buttonStyle={buttonStyle}
          type="button"
        >
          <input
            accept={acceptedFormat ? acceptedFormat : 'text/csv, .csv'}
            hidden
            onChange={onFileChange}
            ref={(node): void => { this.input = node; }}
            type="file"
            value={''}
          />
          <Icon
            icon={faUpload}
          />
          <label>{buttonText}</label>
        </Button>
      </SelectFileContainer>
    );
  }
}

const Icon = styled(FontAwesomeIcon)`
  color: ${COLOR.grey};
  margin-right: 0.25rem;
`;

const Button = styled.button<ButtonProps>`
  background: ${(props): string => props.disabled ? COLOR.neutral : COLOR.white};
  padding: 0.25rem 0.75rem;
  margin-left: ${(props): string => props.marginLeft ? props.marginLeft : '0.5rem'};
  border: 1px solid ${COLOR.grey};
  border-radius: 20px;
  display: inline-block;
  font-size: 0.75rem;
  cursor: ${(props): string => props.disabled ? 'default' : 'pointer'};
  > * {
    cursor: ${(props): string => props.disabled ? 'default' : 'pointer'};
  }
  &:hover {
    background-color: ${(props): string => props.disabled ? COLOR.neutral : COLOR.whiteGrey};
  }
  ${(props): FlattenSimpleInterpolation => props.buttonStyle};
`;

const RedSpan = styled.span`
  color: ${COLOR.red};
`;

const Label = styled.div<LabelProps>`
  color: ${COLOR.black};
  padding-left: ${(props): string => props.paddingLeft ? props.paddingLeft : '0.5rem'};
  padding-bottom: 0.5rem;
  padding-bottom: ${(props): string => props.paddingBottom ? props.paddingBottom : '0.5rem'};
  font-size: 0.8175rem;
  font-weight: 500;
  font-size: 16px;
  ${(props): FlattenSimpleInterpolation => props.labelStyle};
`;

const SelectFileContainer = styled.div`
  margin-top: 1.5rem;
`;

export default SelectFileButton;