import { RunsheetReadyToBroadcast } from 'models/runsheetReadyToBroadcast';

export interface RunsheetReadyToBroadcastState {
  runsheetReadyToBroadcasts: RunsheetReadyToBroadcast[];
  runsheetReadyToBroadcast: RunsheetReadyToBroadcast | null;
}

export const RECEIVE_RUNSHEET_READY_TO_BROADCASTS = 'RECEIVE_RUNSHEET_READY_TO_BROADCASTS';
export const RECEIVE_RUNSHEET_READY_TO_BROADCAST = 'RECEIVE_RUNSHEET_READY_TO_BROADCAST';

interface ReceiveRunsheetReadyToBroadcastsAction {
  type: typeof RECEIVE_RUNSHEET_READY_TO_BROADCASTS;
  runsheetReadyToBroadcasts: RunsheetReadyToBroadcast[];
}

interface ReceiveRunsheetReadyToBroadcastAction {
  type: typeof RECEIVE_RUNSHEET_READY_TO_BROADCAST;
  runsheetReadyToBroadcast: RunsheetReadyToBroadcast;
}

export type RunsheetReadyToBroadcastActionTypes = ReceiveRunsheetReadyToBroadcastsAction | ReceiveRunsheetReadyToBroadcastAction;
