import * as React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import * as H from 'history';
import { LoginRoute, SysAdminRoute, MerchantRoute } from 'components';

// import Dashboard from 'pages/dashboard';
// import TaskAddItems from 'pages/task/lineItem/addLineItems';
// import TaskEditItems from 'pages/task/lineItem/editLineItems';
// import TaskEdit from 'pages/task/edit';
// import TaskImportJobDetail from 'pages/taskImport/detail';
// import TaskImportIndex from 'pages/taskImport/index';
import Login from 'pages/auth/login';
import RequestCode from 'pages/auth/selfSignup/requestCode';
import VerifyCode from 'pages/auth/selfSignup/verifyCode';
import SetUserPass from 'pages/auth/selfSignup/setUserPass';
import SamlAuth from 'pages/auth/saml/samlAuth';
import SamlNoAccess from 'pages/auth/saml/samlNoAccess';
import OperatorInvitationExpired from 'pages/auth/operatorInvitationExpired';
import UserInvitation from 'pages/auth/userInvitation';
import UserAcceptInvitation from 'pages/auth/userAcceptInvitation';
import ForgotPassword from 'pages/auth/forgotPassword';
import ResetPassword from 'pages/auth/resetPassword';
import ConfirmEmail from 'pages/auth/confirmEmail';
import SetOrganization from 'pages/auth/selfSignup/setOrganization';
import OrderNew from 'pages/order/new';
import OrderEdit from 'pages/order/edit';
import ActiveOrderList from 'pages/order/activeOrderList';
import PastOrderList from 'pages/order/pastOrderList';
import OrderDetail from 'pages/order/orderDetail';
import NotFound from './pages/notFound';
// import NewTaskImport from 'pages/taskImport/new';
import DefaultPriceIndex from 'pages/setting/company/defaultPrice/index';
import DefaultPriceEdit from 'pages/setting/company/defaultPrice/edit';
import TeamIndex from 'pages/setting/company/team/index';
import SavedAddressIndex from 'pages/setting/company/savedAddress/index';
import SavedAddressDetail from 'pages/setting/company/savedAddress/detail';
import SavedAddressEdit from 'pages/setting/company/savedAddress/edit';
import Setting from 'pages/setting/index';
import CompanyDetailsIndex from 'pages/setting/company/companyDetails/index';
import CompanyDetailsEdit from 'pages/setting/company/companyDetails/edit';
import WalletIndex from 'pages/setting/company/wallet/index';
import ProfileIndex from 'pages/setting/personalProfile/profile/index';
import ProfileEdit from 'pages/setting/personalProfile/profile/edit';
import ChangePasswordIndex from 'pages/setting/personalProfile/changePassword/index';
import ChangePasswordEdit from 'pages/setting/personalProfile/changePassword/edit';
import SquadIndex from 'pages/setting/company/squad/index';

import SysAdminDashboard from 'pages/sys/dashboard';
import OrganizationIndex from 'pages/sys/organization/index';
import OrganizationNew from 'pages/sys/organization/new';
import SysAdminConfigIndex from 'pages/sys/config/index';
import SysAdminConfigTransferCustomGroupIndex from 'pages/sys/config/runsheetReadyToTransfer/customGroup/index';
import SysAdminConfigTransferCustomGroupDetail from 'pages/sys/config/runsheetReadyToTransfer/customGroup/edit';
import SysAdminConfigTransferCustomGroupNew from 'pages/sys/config/runsheetReadyToTransfer/customGroup/new';
import SysAdminCompanyDetailsIndex from 'pages/sys/organization/companyDetails/index';
import SysAdminCompanyDetailsEdit from 'pages/sys/organization/companyDetails/edit';
import SysAdminDefaultPriceIndex from 'pages/sys/organization/defaultPrice/index';
import SysAdminDefaultPriceEdit from 'pages/sys/organization/defaultPrice/edit';
import SysAdminTeamIndex from 'pages/sys/organization/team/index';
import SysAdminSquadIndex from 'pages/sys/organization/squad/index';
import SysAdminOrgDriverIndex from 'pages/sys/organization/orgDriver/index';
import SysAdminOrgDriverDetail from 'pages/sys/organization/orgDriver/detail';
import SysAdminOrgDriverEdit from 'pages/sys/organization/orgDriver/edit';
import SysAdminSavedAddressIndex from 'pages/sys/organization/savedAddress/index';
import SysAdminSavedAddressDetail from 'pages/sys/organization/savedAddress/detail';
import SysAdminSavedAddressEdit from 'pages/sys/organization/savedAddress/edit';
import PendingOrganizationIndex from 'pages/sys/organization/pending';
import SysAdminUserInvitation from './pages/sys/userInvitation';
import SysAdminTaskNew from 'pages/sys/task/new';
import SysAdminTaskIndex from 'pages/sys/task';
import SysAdminTaskDetail from 'pages/sys/task/detail';
import SysAdminTaskAddItems from 'pages/sys/task/lineItem/addLineItems';
import SysAdminTaskEditItems from 'pages/sys/task/lineItem/editLineItems';
import SysAdminTaskEdit from 'pages/sys/task/edit';
import RunsheetIndex from 'pages/sys/runsheet';
import RunsheetNew from 'pages/sys/runsheet/new';
import RunsheetEdit from 'pages/sys/runsheet/edit';
import RunsheetDetail from 'pages/sys/runsheet/detail';
import RunsheetAddTask from 'pages/sys/runsheet/addTask';
import RunsheetReadyToBroadcastIndex from 'pages/sys/runsheetReadyToBroadcast';
import RunsheetReadyToBroadcastDetail from 'pages/sys/runsheetReadyToBroadcast/detail';
import RunsheetReadyToTransferIndex from 'pages/sys/runsheetReadyToTransfer';
import RunsheetReadyToTransferDetail from 'pages/sys/runsheetReadyToTransfer/detail';
import RunsheetGenerationIndex from 'pages/sys/runsheetGeneration';
import RunsheetGenerationNew from 'pages/sys/runsheetGeneration/new';
import RunsheetGenerationDetail from 'pages/sys/runsheetGeneration/detail';
import RunsheetGenerationEdit from 'pages/sys/runsheetGeneration/edit';
import RunsheetGenerationTaskAdd from 'pages/sys/runsheetGeneration/addTask';
import TaskExportIndex from 'pages/sys/taskExport/index';
import TaskExportDetail from 'pages/sys/taskExport/detail';
import TaskExportNew from 'pages/sys/taskExport/new';
import DriverIndex from 'pages/sys/driver';
import DriverDetail from 'pages/sys/driver/detail';
import DriverEdit from 'pages/sys/driver/edit';
import SquadList from 'pages/sys/driver/squadList';
import AddDriverSquad from 'pages/sys/driver/addDriverSquad';
import UserIndex from 'pages/sys/user';
import UserRoleAdd from 'pages/sys/user/addRole';
import SysAdminNewTaskImport from 'pages/sys/taskImport/new';
import SysAdminTaskImportJobDetail from 'pages/sys/taskImport/detail';
import SysAdminTaskImportIndex from 'pages/sys/taskImport/index';
import SysContractPricingIndex from 'pages/sys/contractPricing/index';
import SysContractPricingDetail from 'pages/sys/contractPricing/detail';
import DriverPartnerRedirect from 'pages/deepLink/driverPartnerRedirect';
import TransportPartnerRedirect from 'pages/deepLink/transportPartnerRedirect';
import PartnerAuth from 'pages/auth/oauth2/auth';
import SysVesselsIndex from 'pages/sys/vessels/index';
import SysVesselsDetailsIndex from 'pages/sys/vessels/vesselDetails/index';
import SysVesselsDetailsEdit from 'pages/sys/vessels/vesselDetails/edit';
import TrackingIDRedirect from './pages/deepLink/trackingIDRedirect';
import EpodViewPdf from './pages/epod/pdf/epod';
import EpodReceipt from './pages/epod/receipt/receipt';
import SysMarineLocationIndex from 'pages/sys/marineLocation/index';
import SysMarineLocationDetail from 'pages/sys/marineLocation/detail';
import SysMarineLocationEdit from 'pages/sys/marineLocation/edit';
import ReceiveSession from 'pages/deepLink/receiveSession';

interface RouteProps {
  history: H.History;
}

const Routes = (props: RouteProps): JSX.Element => (
  <Router history={props.history}>
    <Switch>
      {/* <MerchantRoute component={Dashboard} path="/" exact/> */}
      {/* Temporary redirect / to /active-orders */}
      <MerchantRoute
        component={(): JSX.Element => <Redirect to="/active-orders" />}
        path="/"
        exact
      />
      <MerchantRoute component={OrderNew} path="/orders/new" exact />
      <MerchantRoute component={ActiveOrderList} path="/active-orders" exact />
      <MerchantRoute component={PastOrderList} path="/past-orders" exact />
      <MerchantRoute component={OrderDetail} path="/active-orders/:id" exact />
      <MerchantRoute component={OrderDetail} path="/past-orders/:id" exact />
      <MerchantRoute component={OrderDetail} path="/orders/:id" exact />
      <MerchantRoute component={OrderEdit} path="/orders/:id/edit" exact/>
      {/* <MerchantRoute component={TaskAddItems} path="/orders/:id/items" exact/> */}
      {/* <MerchantRoute component={NewTaskImport} path="/orders/imports/new" exact/> */}
      {/* <MerchantRoute component={TaskEditItems} path="/orders/:id/items/:itemId" exact/> */}
      <MerchantRoute component={UserInvitation} path="/invitation" exact />
      {/* <MerchantRoute component={TaskImportJobDetail} path="/imports/:id" exact/> */}
      {/* <MerchantRoute component={TaskImportIndex} path="/imports" exact/> */}
      <MerchantRoute
        component={DefaultPriceIndex}
        path="/setting/company/default-price"
        exact
      />
      <MerchantRoute
        component={DefaultPriceEdit}
        path="/setting/company/default-price/edit"
        exact
      />
      <MerchantRoute component={TeamIndex} path="/setting/company/team" exact />
      <MerchantRoute
        component={SavedAddressIndex}
        path="/setting/company/saved-address"
        exact
      />
      <MerchantRoute
        component={SavedAddressDetail}
        path="/setting/company/saved-address/:id"
        exact
      />
      <MerchantRoute
        component={SavedAddressEdit}
        path="/setting/company/saved-address/:id/edit"
        exact
      />
      <MerchantRoute component={Setting} path="/setting" exact />
      <MerchantRoute
        component={CompanyDetailsIndex}
        path="/setting/company/company-details"
        exact
      />
      <MerchantRoute
        component={CompanyDetailsEdit}
        path="/setting/company/company-details/edit"
        exact
      />
      <MerchantRoute component={WalletIndex} path="/setting/company/wallet" exact />
      <MerchantRoute
        component={ProfileIndex}
        path="/setting/personal-profile/profile"
        exact
      />
      <MerchantRoute
        component={ProfileEdit}
        path="/setting/personal-profile/profile/edit"
        exact
      />
      <MerchantRoute
        component={ChangePasswordIndex}
        path="/setting/personal-profile/change-password"
        exact
      />
      <MerchantRoute
        component={ChangePasswordEdit}
        path="/setting/personal-profile/change-password/edit"
        exact
      />
      <MerchantRoute component={SquadIndex} path="/setting/company/squad" exact />

      <SysAdminRoute component={SysAdminDashboard} path="/sys" exact />
      <SysAdminRoute
        component={SysAdminCompanyDetailsIndex}
        path="/sys/organizations/:id/company-details"
        exact
      />
      <SysAdminRoute
        component={SysAdminCompanyDetailsEdit}
        path="/sys/organizations/:id/company-details/edit"
        exact
      />
      <SysAdminRoute
        component={SysAdminDefaultPriceIndex}
        path="/sys/organizations/:id/default-price"
        exact
      />
      <SysAdminRoute
        component={SysAdminDefaultPriceEdit}
        path="/sys/organizations/:id/default-price/edit"
        exact
      />
      <SysAdminRoute
        component={SysAdminTeamIndex}
        path="/sys/organizations/:id/team"
        exact
      />
      <SysAdminRoute
        component={SysAdminSquadIndex}
        path="/sys/organizations/:id/squad"
        exact
      />
      <SysAdminRoute
        component={SysAdminOrgDriverIndex}
        path="/sys/organizations/:id/driver"
        exact
      />
      <SysAdminRoute
        component={SysAdminOrgDriverDetail}
        path="/sys/organizations/:id/driver/:driverId"
        exact
      />
      <SysAdminRoute
        component={SysAdminOrgDriverEdit}
        path="/sys/organizations/:id/driver/:driverId/edit"
        exact
      />
      <SysAdminRoute
        component={SysAdminSavedAddressIndex}
        path="/sys/organizations/:id/saved-address"
        exact
      />
      <SysAdminRoute
        component={SysAdminSavedAddressDetail}
        path="/sys/organizations/:id/saved-address/:addressId"
        exact
      />
      <SysAdminRoute
        component={SysAdminSavedAddressEdit}
        path="/sys/organizations/:id/saved-address/:addressId/edit"
        exact
      />
      <SysAdminRoute component={OrganizationIndex} path="/sys/organizations" exact />
      <SysAdminRoute component={OrganizationNew} path="/sys/organizations/new" exact />
      <SysAdminRoute
        component={PendingOrganizationIndex}
        path="/sys/organizations/pending"
        exact
      />
      <SysAdminRoute component={SysAdminTaskIndex} path="/sys/tasks" exact />
      <SysAdminRoute component={SysAdminTaskNew} path="/sys/tasks/new" exact />
      <SysAdminRoute component={TaskExportIndex} path="/sys/tasks/export" exact />
      <SysAdminRoute component={TaskExportNew} path="/sys/tasks/export/new" exact />
      <SysAdminRoute component={TaskExportDetail} path="/sys/tasks/export/:id" exact />
      <SysAdminRoute component={SysAdminTaskDetail} path="/sys/tasks/:id" exact />
      <SysAdminRoute component={SysAdminTaskEdit} path="/sys/tasks/:id/edit" exact />
      <SysAdminRoute component={SysAdminTaskAddItems} path="/sys/tasks/:id/items" exact />
      <SysAdminRoute
        component={SysAdminNewTaskImport}
        path="/sys/tasks/imports/new"
        exact
      />
      <SysAdminRoute
        component={SysAdminTaskImportJobDetail}
        path="/sys/imports/:id"
        exact
      />
      <SysAdminRoute component={SysAdminTaskImportIndex} path="/sys/imports" exact />
      <SysAdminRoute
        component={SysAdminTaskEditItems}
        path="/sys/tasks/:id/items/:itemId"
        exact
      />
      <SysAdminRoute component={UserIndex} path="/sys/users" exact />
      <SysAdminRoute component={UserRoleAdd} path="/sys/users/:id/role" exact />
      <SysAdminRoute component={RunsheetIndex} path="/sys/runsheets" exact />
      <SysAdminRoute component={RunsheetNew} path="/sys/runsheets/new" exact />
      <SysAdminRoute component={RunsheetEdit} path="/sys/runsheets/:id/edit" exact />
      <SysAdminRoute component={RunsheetDetail} path="/sys/runsheets/:id" exact />
      <SysAdminRoute
        component={RunsheetAddTask}
        path="/sys/runsheets/:id/add-task"
        exact
      />
      <SysAdminRoute
        component={RunsheetGenerationIndex}
        path="/sys/runsheet-generations"
        exact
      />
      <SysAdminRoute
        component={RunsheetGenerationNew}
        path="/sys/runsheet-generations/new"
        exact
      />
      <SysAdminRoute
        component={RunsheetGenerationDetail}
        path="/sys/runsheet-generations/:id"
        exact
      />
      <SysAdminRoute
        component={RunsheetGenerationEdit}
        path="/sys/runsheet-generations/:id/edit"
        exact
      />
      <SysAdminRoute
        component={RunsheetGenerationTaskAdd}
        path="/sys/runsheet-generations/:id/add-task"
        exact
      />
      <SysAdminRoute
        component={RunsheetReadyToBroadcastIndex}
        path="/sys/runsheet-ready-to-broadcasts"
        exact
      />
      <SysAdminRoute
        component={RunsheetReadyToBroadcastDetail}
        path="/sys/runsheet-ready-to-broadcasts/:id"
        exact
      />
      <SysAdminRoute
        component={RunsheetReadyToTransferIndex}
        path="/sys/runsheet-ready-to-transfers"
        exact
      />
      <SysAdminRoute
        component={RunsheetReadyToTransferDetail}
        path="/sys/runsheet-ready-to-transfers/:id"
        exact
      />
      <SysAdminRoute component={SysAdminUserInvitation} path="/sys/invitation" exact />
      <SysAdminRoute component={DriverIndex} path="/sys/drivers" exact />
      <SysAdminRoute component={DriverDetail} path="/sys/drivers/:id" exact />
      <SysAdminRoute component={DriverEdit} path="/sys/drivers/:id/edit" exact />
      <SysAdminRoute component={SquadList} path="/sys/drivers/:id/squads" exact />
      <SysAdminRoute component={AddDriverSquad} path="/sys/drivers/:id/add-squad" exact />
      <SysAdminRoute
        component={SysContractPricingIndex}
        path="/sys/contract-pricings"
        exact
      />
      <SysAdminRoute
        component={SysContractPricingDetail}
        path="/sys/contract-pricings/:id"
        exact
      />
      <SysAdminRoute component={SysAdminConfigIndex} path="/sys/config" exact />
      <SysAdminRoute
        component={SysAdminConfigTransferCustomGroupIndex}
        path="/sys/config/runsheet-ready-to-transfer/custom-group"
        exact
      />
      <SysAdminRoute
        component={SysAdminConfigTransferCustomGroupNew}
        path="/sys/config/runsheet-ready-to-transfer/custom-group/new"
        exact
      />
      <SysAdminRoute
        component={SysAdminConfigTransferCustomGroupDetail}
        path="/sys/config/runsheet-ready-to-transfer/custom-group/:id"
        exact
      />
      <SysAdminRoute component={SysVesselsIndex} path="/sys/vessels" exact />
      <SysAdminRoute
        component={SysVesselsDetailsIndex}
        path="/sys/vessels/:id/details"
        exact
      />
      <SysAdminRoute
        component={SysVesselsDetailsEdit}
        path="/sys/vessels/:id/details/edit"
        exact
      />
      <SysAdminRoute
        component={SysMarineLocationIndex}
        path="/sys/marine-locations"
        exact
      />
      <SysAdminRoute
        component={SysMarineLocationDetail}
        path="/sys/marine-locations/:id/details"
        exact
      />
      <SysAdminRoute
        component={SysMarineLocationEdit}
        path="/sys/marine-locations/:id/details/edit"
        exact
      />

      <LoginRoute component={Login} path="/login" exact />
      <LoginRoute component={RequestCode} path="/register" exact />
      <LoginRoute component={VerifyCode} path="/register/verify-code" exact />
      <LoginRoute component={SetUserPass} path="/register/set-name-password" exact />
      <LoginRoute
        component={OperatorInvitationExpired}
        path="/users/invitation/expired-token"
        exact
      />
      <LoginRoute
        component={UserAcceptInvitation}
        path="/users/invitation/accept"
        exact
      />
      <LoginRoute component={ForgotPassword} path="/forgot-password" exact />
      <LoginRoute component={ResetPassword} path="/reset-password" exact />
      <LoginRoute component={ConfirmEmail} path="/confirm-email" exact />
      <LoginRoute component={SamlAuth} path="/users/saml/authenticate" exact />
      <LoginRoute component={SamlNoAccess} path="/users/saml/no-access" exact />
      <LoginRoute component={SetOrganization} path="/register/set-organization" exact />
      <Route path="/driver-partner" component={DriverPartnerRedirect} />
      <Route path="/receive-session" component={ReceiveSession} />
      <Route path="/transport-partner" component={TransportPartnerRedirect} />
      <Route path="/oauth2/auth" component={PartnerAuth} />
      <Route path="/epod/:taskId/pdf" component={EpodViewPdf} />
      <Route path="/epod/:taskId" component={EpodReceipt} />
      <Route path="/:id" component={TrackingIDRedirect} exact />
      <Route path="*" component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
