import { GetRolesResponse } from 'models/role';
import {
  RECEIVE_ROLES,
  ReceiveRolesAction,
} from './roleTypes';

export function receiveRoles(response: GetRolesResponse): ReceiveRolesAction {
  return ({
    type: RECEIVE_ROLES,
    roles: response.roles,
  });
}