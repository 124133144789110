import * as React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
type Status = 'error' | 'success' | 'primary' | 'info' | 'warning';
import theme from 'theme';

const statusColors: {
  [key: string]: {
    background: string;
    color: string;
    border: string;
  };
} = {
  success: {
    background: theme.colors.success_50,
    color: theme.colors.success_500,
    border: theme.colors.success_300,
  },
  primary: {
    background: theme.colors.primary_25,
    color: theme.colors.primary_700,
    border: theme.colors.primary_300,
  },
  error: {
    background: theme.colors.error_25,
    color: theme.colors.error_700,
    border: theme.colors.error_300,
  },
  info: {
    background: theme.colors.gray_25,
    color: theme.colors.gray_600,
    border: theme.colors.gray_300,
  },
  warning: {
    background: theme.colors.warning_25,
    color: theme.colors.warning_700,
    border: theme.colors.warning_300,
  },
};

const statusIcons = {
  success: faCheckCircle,
  primary: faInfoCircle,
  error: faExclamationCircle,
  info: faInfoCircle,
  warning: faExclamationTriangle,
};

interface AlertProps {
  display?: 'row' | 'column';
  status?: Status;
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  children?: React.ReactNode;
  noBackground?: boolean;
  containerStyle?: FlattenSimpleInterpolation;
  onButtonClick?: () => void;
  buttonText?: string;
}

type Props = AlertProps & React.HTMLAttributes<HTMLDivElement>;

const Alert = ({
  children,
  status = 'info',
  noBackground,
  title,
  subTitle,
  containerStyle,
  onButtonClick,
  buttonText,
  ...alertProps
}: Props): JSX.Element => {
  return (
    <AlertStyle
      role="alert"
      status={status}
      noBackground={noBackground}
      {...alertProps}
      borderColor={statusColors[status].border}
      containerStyle={containerStyle}
    >
      <IconContainer status={status}>
        <AlertInfoIcon
          noBackground={noBackground}
          color={statusColors[status].color}
          icon={statusIcons[status]}
        />
      </IconContainer>
      <BodyContainer
        color={statusColors[status].color}
        status={status}
        noBackground={noBackground}
      >
        {title ? (
          <TitleContainer color={statusColors[status].color}>{title}</TitleContainer>
        ) : (
          false
        )}
        {subTitle ? (
          <SubTitleContainer color={statusColors[status].color}>
            {subTitle}
          </SubTitleContainer>
        ) : (
          false
        )}
        {children ? children : false}
        {onButtonClick ? (
          <ViewButton color={statusColors[status].color} onClick={onButtonClick}>
            <ViewButtonText>{buttonText}</ViewButtonText>
            <ViewButtonIcon
              noBackground={noBackground}
              color={statusColors[status].color}
              icon={faArrowRight}
            />
          </ViewButton>
        ) : (
          false
        )}
      </BodyContainer>
    </AlertStyle>
  );
};

const AlertStyle = styled.div<AlertProps & { borderColor: string }>`
  display: flex;
  align-items: flex-start;
  border-radius: 0.75rem;
  ${(props): string =>
    props.noBackground ? '' : `border: 1px solid ${[props.borderColor]}};`}
  ${(props): string => (props.noBackground ? '' : 'padding: 1rem;')}
  position: relative;
  flex-direction: ${(props): string => (props.display ? props.display : 'column')};
  background: ${(props): string =>
    props.noBackground ? 'none' : statusColors[props.status].background};
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
`;

const IconContainer = styled.span<AlertProps>`
  color: ${(props): string => statusColors[props.status].color};
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const AlertInfoIcon = styled(FontAwesomeIcon)<{ color: string; noBackground: boolean }>`
  color: ${(props): string => props.color};
  font-size: 1rem;
  margin-right: ${(props): string => (props.noBackground ? '0.3rem' : '0.75rem')};
`;

const BodyContainer = styled.div<{
  color: string;
  status: Status;
  noBackground?: boolean;
}>`
  ${(props): string => (props.noBackground ? `color: ${props.color};` : '')}
  font-size: 0.875rem;
`;

const TitleContainer = styled.div<{ color: string }>`
  color: ${(props): string => props.color};
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  font-weight: 600;
`;

const SubTitleContainer = styled.div<{ color: string }>`
  color: ${(props): string => props.color};
  font-size: 0.875rem;
  font-weight: 400;
`;

const ViewButton = styled.button<{ color: string }>`
  display: flex;
  flex-direction: row;
  padding: 0;
  color: ${(props): string => props.color};
  background-color: unset;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 0.75rem;
`;

const ViewButtonText = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  text-decoration: underline;
`;

const ViewButtonIcon = styled(FontAwesomeIcon)<{ color: string; noBackground: boolean }>`
  color: ${(props): string => props.color};
  font-size: 1rem;
  margin-left: 0.5rem;
`;

export default Alert;
