import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import * as HttpHelper from 'utils/httpHelper';
import configureStore from 'reduxActions/store';
import { receiveRoles } from 'reduxActions/role/roleActions';
import { GetRolesResponse, UpdateOrganizationRolePayload } from 'models/role';
import { getBearerToken } from 'utils/userHelper';

class RoleClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.ROLE_MANAGEMENT_SERVICE_PROTOCOL;
    this.host = process.env.ROLE_MANAGEMENT_SERVICE_HOST;
    this.port = process.env.ROLE_MANAGEMENT_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  getRolesUrl(orgId: string): string {
    return `${this.getBaseUrl()}/sys/role/${orgId}`;
  }

  async getRoles(orgId: string): Promise<void> {
    const request = this.makeRequest('GET', this.getRolesUrl(orgId));

    try {
      const response: AxiosResponse<GetRolesResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRoles(response.data));
    } catch(error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  addRoleUrl(userId: string, roleId: string): string {
    return `${this.getBaseUrl()}/sys/user/${userId}/role/${roleId}`;
  }

  async addRoleToUser(userId: string, roleId: string): Promise<void> {
    const request = this.makeRequest('POST', this.addRoleUrl(userId, roleId));

    try {
      await axios(request);
    } catch(error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  updateOrganizationRoleUrl(orgId: string): string {
    return `${this.getBaseUrl()}/sys/organization/${orgId}/role`;
  }

  async updateOrganizationRole(orgId: string, payload: UpdateOrganizationRolePayload): Promise<void> {
    const request = this.makeRequest('PUT', this.updateOrganizationRoleUrl(orgId), payload);

    try {
      await axios(request);
    } catch(error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }
}

export default RoleClient;