import * as React from 'react';
import { MainContainer, Breadcrumb, Heading, PageTitle } from 'components';
import * as H from 'history';
import SettingSidebarMenu from './sidebarMenu';

interface CompanyDetailsProps {
  history: H.History;
}

class Settings extends React.Component<CompanyDetailsProps> {
  constructor(props: CompanyDetailsProps) {
    super(props);
  }

  componentDidMount(): void {
    this.props.history.push('/setting/personal-profile/profile');
  }

  render(): React.ReactNode {
    return (
      <MainContainer selected="setting">
        <Breadcrumb
          items={[
            { text: 'Setting', onClick: (): void => { this.props.history.push('/setting'); } },
          ]}
        />
        <Heading>
          <PageTitle>Setting</PageTitle>
        </Heading>
        <SettingSidebarMenu />
      </MainContainer >
    );
  }
}

export default Settings;