import { RunsheetActionTypes, RECEIVE_RUNSHEETS, RECEIVE_RUNSHEET } from './runsheetTypes';
import { Runsheet } from 'models/runsheet';

export function receiveRunsheetAllSuccess(runsheets: Runsheet[]): RunsheetActionTypes {
  return {
    type: RECEIVE_RUNSHEETS,
    runsheets,
  };
}

export function receiveRunsheet(runsheet: Runsheet): RunsheetActionTypes {
  return {
    type: RECEIVE_RUNSHEET,
    runsheet,
  };
}