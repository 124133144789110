import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import * as HttpHelper from 'utils/httpHelper';
import { MetaPendingOrganizations, SysSignUpPayload } from 'models/organization';
import { getBearerToken } from 'utils/userHelper';
import configureStore from 'reduxActions/store';
import { receivePendingOrganizations } from 'reduxActions/organization/organizationActions';
import { PER_PAGE } from 'constants/paginationMeta';

class OrganizationSignUpClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.ORGANIZATION_SIGN_UP_SERVICE_PROTOCOL;
    this.host = process.env.ORGANIZATION_SIGN_UP_SERVICE_HOST;
    this.port = process.env.ORGANIZATION_SIGN_UP_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true, customBearerToken: string = null): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${customBearerToken || bearerToken}`;
    }

    return options;
  }

  getSysPendingOrganizationUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/pending-organization?${params}`;
  }

  sysApproveUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/pending-organization/${id}/approve`;
  }

  signUpUrl(): string {
    return `${this.getBaseUrl()}/sign-up`;
  }

  sysSignUpUrl(): string {
    return `${this.getBaseUrl()}/sys/sign-up`;
  }

  async sysGetPendingOrganizations(params = new URLSearchParams()): Promise<void> {
    params.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.getSysPendingOrganizationUrl(params));

    try {
      const response: AxiosResponse<MetaPendingOrganizations> = await axios(request);
      const store = configureStore();
      store.dispatch(receivePendingOrganizations(response.data.pending_organizations));
    } catch (error) {
      throw error.message;
    }
  }

  async sysApprove(id: string, versionRev: string): Promise<void> {
    const request = this.makeRequest('PUT', this.sysApproveUrl(id), { version_rev: versionRev });

    try {
      await axios(request);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        alert(error.response.data.message);
      }
      throw error.message;
    }
  }

  async signUp(businessName: string): Promise<void> {
    const tempToken = localStorage.getItem('tempBearerToken');

    const request = this.makeRequest('POST', this.signUpUrl(), {
      business_name: businessName,
     }, true, tempToken);

    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        const data = error.response.data;
        if (data.code === 'invalid-request' && data.details.business_name) {
          throw data.details.business_name.message;
        } else if (data.code === 'business-name-taken') {
          throw 'This name is already in use. If you are part of this organisation, ask an admin to invite you. Otherwise use another name.';
        }
        throw data.message;
      } else {
        throw error.message;
      }
    }
  }

  async sysSignUp(payload: SysSignUpPayload): Promise<void> {
    const request = this.makeRequest('POST', this.sysSignUpUrl(), payload);

    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        const data = error.response.data;
        if (data.code === 'invalid-request' && data.details.business_name) {
          throw data.details.business_name.message;
        } else if (data.code === 'business-name-taken') {
          throw 'This name is already in use. Use another name.';
        } else if (data.code === 'invalid-request' && data.details.role_name) {
          throw data.details.role_name.message;
        }
        throw data.message;
      } else {
        throw error.message;
      }
    }
  }
}

export default OrganizationSignUpClient;
