import * as React from 'react';
import styled from 'styled-components';
import * as H from 'history';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import COLOR, { transparent } from 'constants/color';
import { MetaNotification, Notification } from 'models/notification';
import NotificationPusherClient from 'httpClients/notificationPusherClient';
import * as moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faTimes, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import PusherHelper from 'utils/pusherHelper';
import notificationEmptyState from 'assets/images/notification_empty_state.png';
import TaskExportClient from 'httpClients/taskExportClient';

interface StateProps {
  metaNotification: MetaNotification;
}

interface NotificationPanelProps {
  history: H.History;
  clicked?: boolean;
}

interface NotificationPanelState {
  error: string | null;
  showNotificationPanel: boolean;
  newNotification: boolean;
  retryClicked: string[];
}

type Props = NotificationPanelProps & StateProps;

class NotificationPanel extends React.Component<Props, NotificationPanelState> {
  panelRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      showNotificationPanel: false,
      newNotification: false,
      retryClicked: [],
    };

    this.panelRef = React.createRef();
  }

  async componentDidMount(): Promise<void> {
    document.addEventListener('mousedown', this.handleClickOutside);
    moment.updateLocale('en', {
      relativeTime: {
        past: '%s',
        s: '1 s',
        ss: '%d s',
        m: '1 m',
        mm: '%d m',
        h: '1 h',
        hh: '%d h',
        d: '1 d',
        dd: '%d d',
        w: '1 w',
        ww: '%d w',
        M: '30 d',
      },
    });
    moment.locale('en');

    PusherHelper.bindChannel({
      type: 'EXPORT_NOTIFICATION',
      callback: () => this.setState({ newNotification: true }),
    });
    await this.fetchNotification();
  }

  componentDidUpdate(prevProps: Props): void {
    // check for unread notifications, if any, set newNotification to true
    const { metaNotification, clicked } = this.props;
    if (prevProps.metaNotification !== metaNotification) {
      const notifications = metaNotification && metaNotification.notifications || [];
      const newNotification = notifications.find(data => data.is_read === false) ? true : false;
      this.setState({ newNotification });
    }
    if (prevProps.clicked !== clicked) {
      this.showNotificationPanel(!this.state.showNotificationPanel);
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
    PusherHelper.unsubscribe();
  }

  handleClickOutside = (event: MouseEvent): void => {
    const { showNotificationPanel } = this.state;
    if (showNotificationPanel && this.panelRef.current && !this.panelRef.current.contains(event.target as Node)) {
      this.showNotificationPanel(false);
    }
  }

  fetchNotification = async (): Promise<void> => {
    const endDate = new Date();
    const startDate = new Date().setDate(endDate.getDate() - 30);
    const client = new NotificationPusherClient();
    try {
      await client.getNotifications(new URLSearchParams({
        range_start_created_at: moment(startDate).utc().format(),
        range_end_created_at: moment(endDate).utc().format(),
      }));
    } catch (error) {
      this.setState({ error });
    }
  }

  readNotification = async (): Promise<void> => {
    const client = new NotificationPusherClient();
    try {
      await client.readNotification();
    } catch (error) {
      this.setState({ error });
    }
  }

  downloadExport = async (id: string): Promise<void> => {
    const client = new NotificationPusherClient();
    try {
      const data = await client.getNotification(id);
      if (data.event_data && data.event_data.download_url) {
        window.location.replace(data.event_data.download_url);
      }
    } catch (error) {
      this.setState({ error });
    }
  }

  retryExport = async (exportId: string, notificationDetail: Notification): Promise<void> => {
    if (notificationDetail.event_data.has_retried) {
      // if true, then ignore the request
      return;
    }

    this.setState({ retryClicked: [...this.state.retryClicked, exportId] });
    this.showNotificationPanel(false);

    const exportClient = new TaskExportClient();
    try {
      const newJob = await exportClient.retryCreateJob(exportId);
      await exportClient.startJob(newJob.id, newJob.version_rev);
    } catch (error) {
      this.setState({ error });
      return;
    }

    const npClient = new NotificationPusherClient();
    try {
      notificationDetail.event_data.has_retried = true;
      await npClient.updateNotification(notificationDetail);
    } catch (error) {
      this.setState({ error });
    }
  }

  showNotificationPanel = async (showNotificationPanel: boolean): Promise<void> => {
    if (showNotificationPanel === true) {
      await this.fetchNotification();
      const notifications = this.props.metaNotification && this.props.metaNotification.notifications || [];
      // set unread notification as read
      if (notifications.find(data => data.is_read === false)) {
        await this.readNotification();
      }
    } else {
      this.setState({ newNotification: false });
    }
    this.setState({ showNotificationPanel });
  }

  renderExportNotification = (notification: Notification, isMultiple: boolean): React.ReactNode => {
    const { retryClicked } = this.state;
    if (notification.event_data && notification.event_data.error && notification.event_data.job_id) {
      return (
        <ActionButton
          color={retryClicked.includes(notification.id) || notification.event_data.has_retried ? COLOR.darkGray : COLOR.blue}
          onClick={
            (): Promise<void> => this.retryExport(notification.event_data.job_id, notification)
          }
          isMultiple={isMultiple}
        >
          Retry
        </ActionButton>
      );
    }

    return (
      <ActionButton
        color={COLOR.blue}
        onClick={(): Promise<void> => this.downloadExport(notification.id)}
        isMultiple={isMultiple}
      >
        Download
      </ActionButton>
    );
  };

  render(): React.ReactNode {
    const notifications = this.props.metaNotification && this.props.metaNotification.notifications || [];
    const { error, newNotification, showNotificationPanel } = this.state;

    return (
      <NotificationContainer
        ref={this.panelRef}
        onClick={(): Promise<void> => this.showNotificationPanel(!showNotificationPanel)}
        tabIndex={1}
      >
        <ButtonIcon unread={newNotification ? true : false} icon={faBell} />
        <NotificationButtonText>Notifications</NotificationButtonText>
        {showNotificationPanel ? (
          <NotificationPanelContainer
            onClick={(e: React.MouseEvent): void => e.stopPropagation()}
          >
            <Container>
              <NotificationPanelHeader>
                Notifications
                <HeaderCloseIcon
                  onClick={(): Promise<void> => this.showNotificationPanel(false)}
                >
                  <CloseIcon icon={faTimes} />
                </HeaderCloseIcon>
              </NotificationPanelHeader>
              {error ? (
                <NotificationPanelHeader>
                  <InlineErrorMessage >
                    <ErrorIcon icon={faExclamationCircle} />
                    {error}
                  </InlineErrorMessage>
                  <div onClick={(): void => this.setState({ error: null })}  >
                    <CloseIcon icon={faTimes} />
                  </div>
                </NotificationPanelHeader>
              ) : false}
              {notifications.length === 0 ?
                (
                  <EmptyNotification>
                    <NotificationEmptyImage src={notificationEmptyState} />
                    <div>
                      No notifications in the net, all is well!
                    </div>
                  </EmptyNotification>
                ) : (
                  <ScrollContainer>
                    {(notifications).map(notification => (
                      <NotificationRow
                        key={notification.id}
                        isMultiple={notifications.length > 1}
                      >
                        <NotificationText>
                          {notification.event_data && notification.event_data.title}.
                          <TimeFromNow>
                            {moment(notification.created_at).fromNow(false)}
                          </TimeFromNow>
                        </NotificationText>
                        {notification.event_type === 'EXPORT_NOTIFICATION' && this.renderExportNotification(notification, notifications.length > 1)}
                        {notifications.length > 1 ? (
                          <CustomSeparator />
                        ) : false}
                      </NotificationRow>
                    ))}
                    {notifications.length > 1 ? (
                      <FooterText>
                        {'That\'s all your notifications from the last 30 days.'}
                      </FooterText>
                    ) : false}
                  </ScrollContainer>
                )}
            </Container>
          </NotificationPanelContainer>
        ) : false}
      </NotificationContainer>
    );
  }
}

const ButtonIcon = styled(FontAwesomeIcon) <{ unread: boolean }>`
  color: ${(props): string => props.unread ? COLOR.red : COLOR.black};
  font-size: 1rem;
  height: 1rem;
  width: 1rem;
  padding: 0.5rem;
  margin-right: 15px;
  border-radius: 20px;

  :hover{
    cursor: pointer;
  }

  @media (max-width: 769px) {
    margin-right: 0;
    margin-left: 2px;
  }
`;

const NotificationContainer = styled.div`
  display: block;
  
  @media (max-width: 769px) {
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
    margin-bottom: 15px;
  }
`;

const NotificationButtonText = styled.span`
  margin-left: 15px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const NotificationPanelContainer = styled.div`
  height: 0;
  position: relative;
  width: 0;
`;

const Container = styled.div`
  background-color: ${COLOR.white};
  border: 1px solid ${transparent('shadow', 0.26)};
  box-shadow: 0px 4px 16px ${transparent('shadow', 0.5)};
  border-radius: 0.5rem;
  min-width: 15rem;
  padding: 1rem;
  position: absolute;
  top: 0.4rem;
  right: -1.875rem;
  z-index: 15;

  @media (max-width: 769px) {
    position: fixed;
    width: 90%;
    right: 0;
    top: 1rem;
    right: -10%;
  }
`;

const NotificationPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const HeaderCloseIcon = styled.div`
  display: flex;
`;

const NotificationText = styled.div`
  margin-bottom: 1rem;
`;

const ScrollContainer = styled.div`
  max-height: 24rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const ActionButton = styled.div<{ color: string; isMultiple?: boolean }>`
  color: ${(props): string => props.color};
  margin-bottom: ${(props): string => props.isMultiple ? '1rem' : '0rem'};
  cursor: pointer;
  font-size: 1rem;
  width: 5rem;
`;

const TimeFromNow = styled.a`
  color: ${COLOR.darkGray};
  margin-left: 0.3rem;
  font-size: 0.8rem;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.darkGray};
  font-size: 0.75rem;

  :hover{
    cursor: pointer;
  }
`;

const InlineErrorMessage = styled.div`
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  margin-right: 0.35rem;
`;

const NotificationRow = styled.div<{ isMultiple?: boolean }>`
 margin-bottom: ${(props): string => props.isMultiple ? '1rem' : '0rem'};
`;

const FooterText = styled.div`
  margin-top: 1rem;
  text-align: center;
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
`;

const EmptyNotification = styled.div`
  text-align: center;
  color: ${COLOR.darkGray};
`;

const NotificationEmptyImage = styled.img`
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 4.723rem;
  width: 6.362rem;
`;

const CustomSeparator = styled.hr`
  margin-bottom: 0rem;
  border-top: 0.1rem solid ${COLOR.neutral};
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  margin-top: 0rem;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  metaNotification: state.notification.metaNotification,
});

export default withRouter(connect(mapStateToProps)(NotificationPanel));
