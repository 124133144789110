import * as React from 'react';
import { Checkbox } from 'components';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import COLOR from 'constants/color';
import {
  CategoryAndVehicleType,
  VEHICLE_PREFERENCE_BY_CATEGORY,
  VehicleCategory,
  VEHICLE_PREFERENCE_INFO,
  VEHICLE_CATEGORY_INFO,
} from 'constants/vehiclePreference';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  value: CategoryAndVehicleType[];
  onChange?: (fleets: CategoryAndVehicleType[]) => void;
  containerStyle?: FlattenSimpleInterpolation;
  fieldName?: string | React.ReactNode;
  isRequired?: boolean;
  disabled?: boolean;
}

export const FleetTypesCheckbox = (props: Props): JSX.Element => {
  const { value, onChange } = props;
  const fleets = props.value;

  // Helper function to check if any of the children are checked
  const isAnyChildChecked = (category: VehicleCategory): boolean => {
    return VEHICLE_PREFERENCE_BY_CATEGORY[category].some((type) =>
      value.includes(type as CategoryAndVehicleType)
    );
  };

  // Helper function to check if all children are checked
  const areAllChildrenChecked = (category: VehicleCategory): boolean => {
    return VEHICLE_PREFERENCE_BY_CATEGORY[category].every((type) =>
      value.includes(type as CategoryAndVehicleType)
    );
  };

  // Helper function to determine if there is a partial check on the parent checkbox
  const isPartialCheck = (category: VehicleCategory): boolean => {
    const allChecked = areAllChildrenChecked(category);
    const anyChecked = isAnyChildChecked(category);
    return anyChecked && !allChecked;
  };

  // Handle parent checkbox click
  const handleParentCheckboxClick = (category: VehicleCategory) => {
    const allTypes = VEHICLE_PREFERENCE_BY_CATEGORY[category];
    const isAllChecked = areAllChildrenChecked(category);

    if (isAllChecked) {
      // Uncheck all children and parent
      onChange &&
        onChange(
          value.filter((type) => !allTypes.includes(type as string)) // Remove all children types
        );
    } else {
      // Check all children and parent
      onChange &&
        onChange(
          [...value, ...(allTypes as CategoryAndVehicleType[])] // Add all children types
        );
    }
  };

  // Handle child checkbox click
  const handleChildCheckboxClick = (type: CategoryAndVehicleType) => {
    let newFleets: CategoryAndVehicleType[] = [];
    if (value.includes(type)) {
      newFleets = value.filter((f) => f !== type); // Remove from array if already checked
    } else {
      newFleets = [...value, type]; // Add to array if not already checked
    }
    // Check if we need to update the parent checkbox
    const parentCategory = Object.entries(VEHICLE_PREFERENCE_BY_CATEGORY).find(
      ([, types]) => types.includes(type as string)
    )?.[0] as VehicleCategory;
    // Update the parent checkbox state based on child checkboxes
    if (parentCategory) {
      if (areAllChildrenChecked(parentCategory)) {
        // If all children are checked, check the parent
        onChange && onChange(newFleets);
      } else if (isAnyChildChecked(parentCategory)) {
        // If only some children are checked (partial check), update the state
        onChange && onChange(newFleets);
      } else {
        // If no children are checked, just update the state
        onChange && onChange(newFleets);
      }
    } else {
      // If there's no parent category (edge case), just update the state
      onChange && onChange(newFleets);
    }
  };

  return (
    <Container containerStyle={props.containerStyle}>
      {props.fieldName && (
        <InputLabel>
          {props.fieldName} {props.isRequired && <RequiredSpan>*</RequiredSpan>}
        </InputLabel>
      )}
      <VehicleInformationText className="info-vehicle">
        Select at least 1 vehicle.
        <Icon color={COLOR.blue} fontSize={'1rem'} icon={faInfoCircle} />
      </VehicleInformationText>
      <CheckboxContainer>
        {Object.entries(VEHICLE_PREFERENCE_BY_CATEGORY).map(([category, types]) => {
          // Ensure that category is a VehicleCategory
          const fleetCategory = category as VehicleCategory;

          return (
            <CheckboxParent key={category}>
              <Checkbox
                disabled={props.disabled}
                onClick={() => handleParentCheckboxClick(fleetCategory)}
                selected={
                  areAllChildrenChecked(fleetCategory) || isPartialCheck(fleetCategory)
                }
                isPartialCheck={isPartialCheck(fleetCategory)}
              >
                {VEHICLE_CATEGORY_INFO[fleetCategory].description}
              </Checkbox>
              {types.map((type) => (
                <CheckboxChild key={type}>
                  <Checkbox
                    disabled={props.disabled}
                    onClick={() =>
                      handleChildCheckboxClick(type as CategoryAndVehicleType)
                    }
                    selected={fleets.includes(type as CategoryAndVehicleType)}
                  >
                    {VEHICLE_PREFERENCE_INFO[type] &&
                      VEHICLE_PREFERENCE_INFO[type].description}
                  </Checkbox>
                </CheckboxChild>
              ))}
            </CheckboxParent>
          );
        })}
      </CheckboxContainer>
    </Container>
  );
};

const Container = styled.div<{ containerStyle: FlattenSimpleInterpolation }>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const InputLabel = styled.label`
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.32px;
  margin-bottom: 0.5rem;
`;

const RequiredSpan = styled.span`
  color: ${COLOR.red};
`;

const CheckboxParent = styled.div`
  font-size: 1rem;
`;

const CheckboxChild = styled.div`
  margin-left: 1.5rem;
  margin-top: 0.5rem;
`;

const VehicleInformationText = styled.div`
  color: ${(props): string => props.theme.colors.gray_600};
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
`;

const Icon = styled(FontAwesomeIcon)<{ color: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.375rem;
`;
