import * as React from 'react';
import {
  Alert,
  Breadcrumb,
  Card,
  Dropdown,
  Heading,
  Separator,
  PageTitle,
  MainContainer,
  StyledButton,
  TextInput,
  CenterModal,
} from 'components';
import styled, { css } from 'styled-components';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { Organization, Squad } from 'models/organization';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps } from 'react-router-dom';
import SettingSidebarMenu from '../../sidebarMenu';
import { formatDuration } from 'utils/formatter';
import BROADCAST_PREFERENCES, {
  BROADCAST_PREFERENCE_DESCRIPTION,
  BROADCAST_MINUTES,
} from 'constants/broadcastPreference';
import {
  ONE_SGD,
  ALLOWED_MIN_SGD,
  ALLOWED_MIN_PRICE,
  DEFAULT_SQUAD_PRICE,
  DEFAULT_MIN_PRICE,
  DEFAULT_MAX_PRICE,
} from 'constants/priceDetails';

interface DefaultPriceProps {
  organization: Organization;
  squads: Squad[];
}

interface Form {
  min_price: number;
  max_price: number;
  increment: number;
  squad_price: number;
  broadcast_preference: string;
  version_rev: string;
}

type Props = RouteComponentProps & DefaultPriceProps;

const inlineDropdownStyle = css`
  display: inline-block;
  margin-left: 0;
  margin-top: 0.25rem;
`;

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

const saveDefaultPrice = async (
  form: Form,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  const client = new OrganizationManagementClient();
  const updatedOrg = await client.updateDefaultService(form);
  form.version_rev = updatedOrg.version_rev;
  await client.updateDefaultPrice(form);
  setSuccess(true);
};

const priceValid = (form: Form): boolean => {
  return (
    form.min_price >= ALLOWED_MIN_PRICE &&
    form.max_price >= form.min_price &&
    form.squad_price >= ALLOWED_MIN_PRICE
  );
};

const renderHeading = (
  props: Props,
  form: Form,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  isFormChanged: boolean,
  showCancelChanges: React.Dispatch<React.SetStateAction<boolean>>
): JSX.Element => {
  return (
    <Heading>
      <PageTitle>Service & Price</PageTitle>
      <EditButtonContainer>
        <Button
          buttonStyle="encourage"
          buttonType="neutral"
          onClick={(): void => {
            if (isFormChanged) {
              showCancelChanges(true);
            } else {
              props.history.push('/setting/company/default-price');
            }
          }}
        >
          <Icon icon={faTimes} />
          Cancel
        </Button>
        <StyledButton
          buttonStyle="encourage"
          buttonType="primary"
          disabled={!priceValid(form)}
          onClick={(): void => {
            saveDefaultPrice({ ...form }, setSuccess);
          }}
        >
          <Icon icon={faCheck} color={COLOR.white} />
          Save
        </StyledButton>
      </EditButtonContainer>
    </Heading>
  );
};

const getDefaultForm = (props: Props): Form => {
  const org = props.organization;
  const squads = props.squads;
  let increment = ONE_SGD;
  if (org?.price_table && org?.price_table.length > 1) {
    increment = org?.price_table[1].price - org?.price_table[0].price;
    if (increment <= ONE_SGD) {
      increment = ONE_SGD;
    } else if (increment <= ONE_SGD * 2) {
      increment = ONE_SGD * 2;
    } else if (increment <= ONE_SGD * 3) {
      increment = ONE_SGD * 3;
    }
  }
  const squadsLength = squads.length;
  let defaultBroadcastPreference = org?.broadcast_preference || '';
  // changed to standard if default broadcast preference is squad but have no squad
  if (defaultBroadcastPreference === BROADCAST_PREFERENCES.squad && squadsLength === 0) {
    defaultBroadcastPreference = BROADCAST_PREFERENCES.all;
  }
  if (defaultBroadcastPreference === '') {
    defaultBroadcastPreference =
      squadsLength > 0 ? BROADCAST_PREFERENCES.squad : BROADCAST_PREFERENCES.all;
  }

  return {
    min_price: org?.min_price || DEFAULT_MIN_PRICE,
    max_price: org?.max_price || DEFAULT_MAX_PRICE,
    increment: increment,
    squad_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
    broadcast_preference: defaultBroadcastPreference,
    version_rev: org?.version_rev,
  };
};

const renderMaxAcceptanceTime = (
  minPrice: number,
  maxPrice: number,
  increment: number
): React.ReactNode => {
  if (minPrice < maxPrice) {
    const minutes =
      (Math.ceil((maxPrice - minPrice) / increment) + 1) * BROADCAST_MINUTES;

    return (
      <article>
        Max order acceptance time: <b>{formatDuration(minutes * 60)}</b>
      </article>
    );
  }

  return false;
};

const DefaultPriceEdit = (props: Props): JSX.Element => {
  React.useEffect(() => {
    (async function (): Promise<void> {
      const client = new OrganizationManagementClient();
      await client.getOrganization();
      await client.getSquads(new URLSearchParams());
    })();
  }, []);

  const defaultForm = getDefaultForm(props);
  const [form, setForm] = React.useState(defaultForm);
  const [cancelChanges, showCancelChanges] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const isFormChanged = JSON.stringify(defaultForm) !== JSON.stringify(form);

  React.useEffect(() => {
    setForm(defaultForm);
  }, [props.organization, props.squads]);

  const handleChange = (fieldName: string, value: string): void => {
    switch (fieldName) {
      case 'increment': {
        setForm((prevState) => ({
          ...prevState,
          [fieldName]: parseInt(value),
        }));
        break;
      }
      case 'squad_price':
      case 'min_price':
      case 'max_price': {
        setForm((prevState) => ({
          ...prevState,
          [fieldName]: Math.floor(parseFloat(value) * 100) * 1000,
        }));
        break;
      }
      default: {
        setForm((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
        break;
      }
    }
  };

  const renderSquadPrice = (form: Form): JSX.Element => {
    return (
      <>
        <ServiceTitle>
          {BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.squad].label}
          {form.broadcast_preference == BROADCAST_PREFERENCES.squad && (
            <DefaultServiceBadge>Default</DefaultServiceBadge>
          )}
        </ServiceTitle>
        <Description>Broadcast your Orders to your Squad</Description>
        <ButtonContainer>
          <StyledButton
            buttonStyle="encourage"
            buttonType="neutral"
            disabled={
              form.broadcast_preference === BROADCAST_PREFERENCES.squad ||
              props.squads.length == 0
            }
            onClick={(): void => {
              handleChange('broadcast_preference', BROADCAST_PREFERENCES.squad);
            }}
          >
            Set as default
          </StyledButton>
        </ButtonContainer>
        <PriceContainer>
          <ContentTitle>Set your price</ContentTitle>
          <Description>
            Alert drivers at a fixed price per location until a driver is found.
          </Description>
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Price (SGD)"
              isRequired
              placeholder="5.00"
              onTextChange={(value: string): void => {
                handleChange('squad_price', value);
              }}
              type="number"
              width="medium"
              value={form.squad_price / ONE_SGD}
            />
          </div>
          {form.squad_price < ALLOWED_MIN_PRICE && (
            <ErrorAlert status="error">
              Enter a minimum price from SGD {ALLOWED_MIN_SGD}.00.
            </ErrorAlert>
          )}
        </PriceContainer>
      </>
    );
  };

  const renderAllDriversPrice = (form: Form): JSX.Element => {
    return (
      <>
        <ServiceTitle>
          {BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.all].label}
          {form.broadcast_preference == BROADCAST_PREFERENCES.all && (
            <DefaultServiceBadge>Default</DefaultServiceBadge>
          )}
        </ServiceTitle>
        <Description>Broadcast your Orders to all GotSurge Driver-Partners</Description>
        <ButtonContainer>
          <StyledButton
            buttonStyle="encourage"
            buttonType="neutral"
            disabled={form.broadcast_preference === BROADCAST_PREFERENCES.all}
            onClick={(): void => {
              handleChange('broadcast_preference', BROADCAST_PREFERENCES.all);
            }}
          >
            Set as default
          </StyledButton>
        </ButtonContainer>
        <PriceContainer>
          <ContentTitle>Set your price</ContentTitle>
          <Description>
            Alert drivers starting from a base price per location that increases to your
            max budget until a driver is found.
          </Description>
          <PriceInfo>
            <CardRow>
              <TextInput
                fieldName="Minimum Price (SGD)"
                isRequired
                type="number"
                width="medium"
                onTextChange={(value): void => handleChange('min_price', value)}
                value={form.min_price / ONE_SGD}
              />
              <Arrow icon={faArrowRight} />
              <TextInput
                fieldName="Maximum Price (SGD)"
                isRequired
                type="number"
                width="medium"
                onTextChange={(value): void => handleChange('max_price', value)}
                value={form.max_price / ONE_SGD}
              />
            </CardRow>
          </PriceInfo>
          <AlertInfo>
            {form.max_price < form.min_price && (
              <Alert status="error">
                Set a Maximum Price that is higher than the Minimum Price. Alternatively,
                set a lower Minimum Price.
              </Alert>
            )}
            {form.max_price === form.min_price && (
              <Alert status="info">
                There will be no increment, your orders will be broadcasted at this same
                price throughout.
              </Alert>
            )}
          </AlertInfo>
          <div>
            <div>Price increment</div>
            <Dropdown
              containerStyle={inlineDropdownStyle}
              disabled={form.max_price <= form.min_price}
              onChange={(value: string): void => handleChange('increment', value)}
              width="medium"
              height="large"
              options={[ONE_SGD, ONE_SGD * 2, ONE_SGD * 3].map((value: number) => ({
                name: `SGD ${(value / ONE_SGD).toFixed(2)}`,
                value: value.toString(),
              }))}
              value={form.increment.toString()}
            />
            <DropdownDescription>every {BROADCAST_MINUTES} minutes</DropdownDescription>
          </div>
          <SmallDescription>
            {renderMaxAcceptanceTime(form.min_price, form.max_price, form.increment)}
          </SmallDescription>
          {form.min_price < ALLOWED_MIN_PRICE && (
            <ErrorAlert status="error">
              Enter a minimum price from SGD {ALLOWED_MIN_SGD}.00.
            </ErrorAlert>
          )}
        </PriceContainer>
      </>
    );
  };

  const renderMarinePrice = (): JSX.Element => {
    return (
      <>
        <ServiceTitle>
          {BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.marine].label}
          {form.broadcast_preference == BROADCAST_PREFERENCES.marine && (
            <DefaultServiceBadge>Default</DefaultServiceBadge>
          )}
        </ServiceTitle>
        <Description>Book for a Transporter to and from Marine ports</Description>
        <ButtonContainer>
          <StyledButton
            buttonStyle="encourage"
            buttonType="neutral"
            disabled={form.broadcast_preference === BROADCAST_PREFERENCES.marine}
            onClick={(): void => {
              handleChange('broadcast_preference', BROADCAST_PREFERENCES.marine);
            }}
          >
            Set as default
          </StyledButton>
        </ButtonContainer>
        <PriceContainer>
          <ContentTitle>Set your price</ContentTitle>
          <Description>Note: Default price is not applicable for marine.</Description>
        </PriceContainer>
      </>
    );
  };

  return (
    <MainContainer selected="setting">
      <Breadcrumb
        items={[
          {
            text: 'Setting',
            onClick: (): void => {
              props.history.push('/setting');
            },
          },
          { text: 'Service & Price' },
        ]}
      />
      <Heading>
        <PageTitle>Setting</PageTitle>
      </Heading>
      <SettingSidebarMenu selected="/setting/company/default-price">
        {renderHeading(props, form, setSuccess, isFormChanged, showCancelChanges)}
        <CustomSeparator />
        <Container>
          <CustomCard>
            <CustomCardDescription>Set your preferred service.</CustomCardDescription>
            {props.organization && props.squads && renderSquadPrice(form)}
            {props.organization && props.squads && renderAllDriversPrice(form)}
            {props.organization && props.squads && renderMarinePrice()}
            <CustomSeparator />
          </CustomCard>
          {success ? (
            <CenterModal
              rightButtonText="OK"
              rightButtonOnClick={(): void => {
                props.history.push('/setting/company/default-price');
              }}
              title="Service and Price"
            >
              <Alert status="success">
                <AlertMessage>Saved</AlertMessage>
              </Alert>
            </CenterModal>
          ) : (
            false
          )}
          {cancelChanges ? (
            <CenterModal
              leftButtonOnClick={(): void => showCancelChanges(false)}
              leftButtonText="No"
              rightButtonText="Yes"
              rightButtonOnClick={(): void => {
                props.history.push('/setting/company/default-price');
              }}
              title="Cancel changes?"
            >
              <CustomCardDescription>
                You made some changes. Are you sure to cancel?
              </CustomCardDescription>
            </CenterModal>
          ) : (
            false
          )}
        </Container>
      </SettingSidebarMenu>
    </MainContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
`;

const CustomCard = styled(Card)`
  flex: 1;
  margin: 0rem;
  padding-left: 0 !important;
`;

const CardRow = styled.div`
  display: flex;

  & > * {
    margin-right: 1rem;
  }
`;

const Arrow = styled(FontAwesomeIcon)`
  align-self: center;
  margin-top: 1.5rem;
`;

const Icon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;
`;

const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Button = styled(StyledButton)`
  margin-right: 1rem;
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
`;

const ServiceTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 600;
  margin-right: auto;
  margin-top: 1.5rem;
  margin-bottom: 0.375rem;
  color: ${COLOR.darkGray};
`;

const ContentTitle = styled.div`
  font-size: 1rem;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 0.375rem;
  color: ${COLOR.darkGray};

  @media (max-width: 700px) {
    font-size: 1rem;
  }
`;

const PriceInfo = styled.div`
  margin-bottom: 1rem;
`;

const AlertInfo = styled.div`
  margin-bottom: 1rem;
`;

const CustomCardDescription = styled.div`
  margin-bottom: 1.5rem;
  font-size: 1rem;
`;

const Description = styled.div`
  margin-bottom: 1rem;
  font-size: 0.8125rem;
  color: #747788;

  @media (max-width: 700px) {
    font-size: 0.875rem;
  }
`;

const SmallDescription = styled.div`
  margin-top: 0.313rem;
  font-size: 0.75rem;
`;

const DefaultServiceBadge = styled.div`
  font-size: 0.75rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.3rem;
  border-radius: 6px;
  color: ${COLOR.black};
  background-color: ${COLOR.yellow};
`;

const DropdownDescription = styled.span`
  margin-left: 0.5rem;
`;

const PriceContainer = styled.div`
  padding-left: 1rem;
`;

export const AlertMessage = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

const EditButtonContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

function mapStateToProps(state: RootState): DefaultPriceProps {
  return {
    organization: state.organization.organization,
    squads: state.organization.squads,
  };
}

export default connect(mapStateToProps)(DefaultPriceEdit);
