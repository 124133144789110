import * as React from 'react';
import styled from 'styled-components';

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
   const [visible, setVisible] = React.useState(false);
   const containerRef = React.useRef<HTMLDivElement>(null);

   const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <TooltipContainer ref={containerRef} onClick={handleClick}>
      {children}
      <TooltipText visible={visible}>{text}</TooltipText>
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const TooltipText = styled.div<{ visible: boolean }>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  position: absolute;
  top: 120%; /* Position the tooltip below the element */
  left: 6rem;
  transform: translateX(-50%);
  background-color: ${(props): string => props.theme.colors.base_white};
  color: ${(props): string => props.theme.colors.gray_600};
  text-align: center;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  z-index: 100;
  font-size: 14px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`;

export default Tooltip;
