import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import ContractPricingClient from 'httpClients/contractPricingClient';
import { ContractPricing } from 'models/contractPricing';
import { RootState } from 'reduxActions/store';
import styled from 'styled-components';
import { Button, SysAdminMainContainer, Message, Table } from 'components';
import DateHelper from 'utils/dateHelper';

interface StateProps {
  contractPricing: ContractPricing;
}

interface SysContractPricingDetailState {
  error: string | null;
}

type Props = RouteComponentProps<{id: string}> & StateProps;

class SysContractPricingDetail extends React.Component<Props, SysContractPricingDetailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidMount(): void {
    this.fetchContractPricing();
  }

  fetchContractPricing = async (): Promise<void> => {
    const { id } = this.props.match.params;

    try {
      const client = new ContractPricingClient();
      await client.sysGetContractPricing(id);
      this.setState({ error: null });
    } catch (error) {
      this.setState({ error });
    }
  }

  toggleActiveStatus = async (): Promise<void> => {
    const { id } = this.props.match.params;
    const { contractPricing } = this.props;

    try {
      const client = new ContractPricingClient();
      if (contractPricing.is_active) {
        await client.sysDeactivateContractPricing(id, contractPricing.version_rev);
      } else {
        await client.sysActivateContractPricing(id, contractPricing.version_rev);
      }
      this.fetchContractPricing();
      this.setState({ error: null });
    } catch (error) {
      this.setState({ error });
    }
  }

  render(): React.ReactNode {
    const { error } = this.state;
    const { contractPricing } = this.props;

    const columns = {
      code: 'Code',
      price: 'Price',
      time_delta: 'Time Delta',
    };
    const data = contractPricing && contractPricing.price_table.map(table => ({
      code: table.code,
      price: table.price,
      time_delta: table.time_delta,
    }));

    return (
      <SysAdminMainContainer selected="contract-pricings">
        <div>
          <h2>Contract Pricing Detail</h2>
          <Link to="/sys/contract-pricings">
            <Button>Back</Button>
          </Link>
        </div>
        {error && (
          <Message className="error">{error}</Message>
        )}
        {contractPricing && (
          <React.Fragment>
            <div>
              <Label>ID</Label>
              <span>: {contractPricing.id}</span>
            </div>
            <div>
              <Label>Org ID</Label>
              <span>: {contractPricing.org_id}</span>
            </div>
            <div>
              <Label>Currency</Label>
              <span>: {contractPricing.currency}</span>
            </div>
            <div>
              <Label>Is Active</Label>
              <span>: {contractPricing.is_active ? 'Active' : 'Inactive'}</span>
              <div>
                <Button onClick={this.toggleActiveStatus}>
                  {contractPricing.is_active ? 'Deactivate' : 'Activate'}
                </Button>
              </div>
            </div>
            <div>
              <Label>Is Valid From</Label>
              <span>: {DateHelper.formatDateTime(contractPricing.is_valid_from)}</span>
            </div>
            <div>
              <Label>Is Valid To</Label>
              <span>: {DateHelper.formatDateTime(contractPricing.is_valid_to)}</span>
            </div>
            {contractPricing.price_table && (
              <div>
                <h3>Price Table</h3>
                <Table
                  columns={columns}
                  data={data}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </SysAdminMainContainer>
    );
  }
}

const Label = styled.span`
  display: inline-block;
  width: 8rem;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  contractPricing: state.contractPricing.contractPricing,
});

export default connect(mapStateToProps)(SysContractPricingDetail);
