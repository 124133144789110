import * as React from 'react';
import styled from 'styled-components';
import StyledButton, { ButtonProps } from './styledButton';
import COLOR from 'constants/color';

interface ButtonWithIconProps {
  icon: React.ReactNode;
  mainText: string | React.ReactNode;
  description: string | React.ReactNode;
  disabled?: boolean;
  warningMode?: boolean;
}

type Props = ButtonProps & ButtonWithIconProps;

const ButtonWithIcon = (props: Props): JSX.Element => {
  return (
    <Button {...props}>
      {props.icon}
      <TextContainer disabled={props.disabled}>
        <span>{props.mainText}</span>
        <Description>{props.description}</Description>
      </TextContainer>
    </Button>
  );
};

interface ButtonExtendedProps {
  warningMode?: boolean;
}
const Button = styled(StyledButton)<
  React.ComponentProps<typeof StyledButton> & ButtonExtendedProps
>`
  display: flex;
  flex-direction: row;
  height: fit-content;
  padding: 1rem;
  align-items: center;
  width: 100%;
  height: 100%;
  ${(props): string => (props.disabled ? 'opacity: 0.5;' : '')};

  &:hover {
    border-color: ${(props): string =>
      props.warningMode ? props.theme.colors.warning_600 : COLOR.blue};
  }

  ${(props) => props.warningMode && `border-color: ${props.theme.colors.warning_600};`}

  ${(props) =>
    props.isActive &&
    props.warningMode &&
    `border-color: ${props.theme.colors.warning_600};
    background-color: ${props.theme.colors.warning_25};
  `}

  @media (max-width: 768px) {
    padding: 1rem 0.75rem;
  }
`;

const TextContainer = styled.div<{ disabled?: boolean }>`
  color: ${(props): string => (props.disabled ? COLOR.midDarkGrey : COLOR.black)};
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 8px;
  /* margin-right: 0.5rem; */
`;

const Description = styled.span`
  color: ${COLOR.midDarkGrey};
  font-size: 0.75rem;
`;

export default ButtonWithIcon;
