import * as React from 'react';
import { CenterModal, Searchbox, RadioCard } from 'components';
import COLOR from 'constants/color';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import BROADCAST_PREFERENCES, {
  BROADCAST_PREFERENCE_DESCRIPTION,
} from 'constants/broadcastPreference';
import Checkbox from 'components/input/checkbox';
import { Organization } from 'models/organization';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
  form: {
    is_qa: boolean;
    broadcast_preference?: string;
  };
  disabled?: boolean;
  disableFullEdit?: boolean;
  orgId: string;
  hasNoSquad?: boolean;
  organizations: Organization[];
  onFormChange: (fieldName: string, value: string | boolean) => void;
  vesselForm?: React.ReactNode;
}

const GeneralInfoForm = (props: Props): JSX.Element => {
  const broadcastPreference = props.form && props.form.broadcast_preference;
  const orgId = props.orgId || '';
  const disableFullEdit = props.disableFullEdit || false;
  const editLocked = props.disabled || false;
  const [showNoSquadModal, setNoSquadModal] = React.useState(false);
  const [searchOrg, setSearchOrg] = React.useState('');
  const history = useHistory();
  const findOrgById = (orgId: string): Organization => {
    return props.organizations.find((org) => org.id === orgId);
  };
  const selectedOrg = findOrgById(orgId);
  const isTestOrg = (selectedOrg && selectedOrg.is_qa) || false;
  const filteredOptions = props.organizations.filter((option) =>
    option.business_name.toLowerCase().includes(searchOrg.toLowerCase())
  );
  const clearSelectedOrg = (): void => {
    props.onFormChange('org_id', '');
    props.onFormChange('is_qa', false);
    setSearchOrg('');
  };
  const renderTestLabel = (): JSX.Element =>
    orgId &&
    selectedOrg &&
    selectedOrg.is_qa && (
      <DisplayValueContainer>
        <DisplayValue>
          <OrgNameHidden>
            {searchOrg ? searchOrg : selectedOrg.business_name}
          </OrgNameHidden>
          <IsTestLabel title={selectedOrg.business_name}>Test</IsTestLabel>
        </DisplayValue>
      </DisplayValueContainer>
    );

  return (
    <Card>
      <Title>
        Organisation ID <RedText>*</RedText>
      </Title>
      <SearchContainer>
        {(disableFullEdit && (
          <Searchbox
            trailingIcon={faSearch}
            isRequired
            placeholder="Wonderland Pte Ltd"
            width="auto"
            disabled
            value={selectedOrg && selectedOrg.business_name}
            bottomLabel={renderTestLabel()}
            onTextChange={(): void => {
              return;
            }}
            inputStyle={css`
              padding-right: 4.8rem !important;
            `}
          />
        )) || (
          <Searchbox
            trailingIcon={searchOrg ? faTimes : faSearch}
            isRequired
            placeholder="Wonderland Pte Ltd"
            width="auto"
            value={searchOrg}
            bottomLabel={renderTestLabel()}
            handleSelectChange={(value: string): void => {
              props.onFormChange('org_id', value || '');
              const org = findOrgById(value);
              if (org) {
                setSearchOrg(org.business_name);
                props.onFormChange('is_qa', org.is_qa);
              }
            }}
            handleSetFocus={(focused: boolean): void => {
              if (selectedOrg && !focused) {
                setSearchOrg(selectedOrg.business_name);
              }
            }}
            onTextChange={(v): void => {
              setSearchOrg(v);
              if (v === '') {
                clearSelectedOrg();
              }
            }}
            options={filteredOptions.map((org) => {
              return {
                value: org.id,
                text: (
                  <Row title={org.business_name}>
                    <OrgName>{org.business_name}</OrgName>
                    {org.is_qa && <IsTestLabel>Test</IsTestLabel>}
                  </Row>
                ),
              };
            })}
            trailingIconOnClick={(): void => {
              clearSelectedOrg();
            }}
            inputStyle={css`
              padding-right: 4.8rem !important;
            `}
          />
        )}
      </SearchContainer>
      <IsTestCheckbox>
        <Checkbox
          onClick={(): void => {
            props.onFormChange('is_qa', !props.form.is_qa);
          }}
          selected={props.form.is_qa}
          disabled={editLocked || isTestOrg}
        >
          Test Order
        </Checkbox>
      </IsTestCheckbox>
      {isTestOrg ? (
        <TestOrgLabel>Test organisations can only create test orders.</TestOrgLabel>
      ) : (
        false
      )}
      <BroadcastPreferencesContainer>
        <RadioCard
          radioStyle={css`
            width: 20.5rem;
          `}
          buttonContainerStyle={css`
            @media only screen and (min-width:768px) and (max-width: 1148px)  {
              flex-direction: column;
            }
          `}
          label="Select a Service"
          isRequired
          options={[
            {
              value: BROADCAST_PREFERENCES.marine,
              label: BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.marine].label,
              description:
                BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.marine]
                  .description,
            },
            {
              value: BROADCAST_PREFERENCES.all,
              label: BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.all].label,
              description:
                BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.all].description,
            },
            {
              value: BROADCAST_PREFERENCES.squad,
              label: BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.squad].label,
              description:
                BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.squad].description,
              disabled:
                !props.orgId ||
                (disableFullEdit && broadcastPreference !== BROADCAST_PREFERENCES.squad),
            },
          ]}
          disabled={disableFullEdit}
          broadcast_preference={broadcastPreference}
          onChange={(value: string) => {
            if (value === BROADCAST_PREFERENCES.squad && props.hasNoSquad) {
              setNoSquadModal(true);
              return;
            }
            props.onFormChange('broadcast_preference', value);
          }}
        />
      </BroadcastPreferencesContainer>
      {props.vesselForm ? (
        <VesselFormContainer>{props.vesselForm}</VesselFormContainer>
      ) : (
        false
      )}
      {showNoSquadModal && (
        <CenterModal
          leftButtonOnClick={(): void => {
            setNoSquadModal(false);
          }}
          leftButtonText="Back"
          rightButtonStyle="encourage"
          rightButtonType="secondary"
          rightButtonOnClick={(): void => {
            history.push(`/sys/organizations/${props.orgId}/squad`);
          }}
          rightButtonText="Invite member"
          title="You have no squad member yet"
        >
          Add your squad member or select another service.
        </CenterModal>
      )}
    </Card>
  );
};

const IsTestCheckbox = styled.div`
  width: fit-content;
`;

const Card = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${(props): string => props.theme.colors.gray_200};
  background-color: ${(props): string => props.theme.colors.base_white};
  box-shadow: 0px 2px 6px 0px ${(props): string => props.theme.colors.gray_200},
    0px 1px 1px 2px rgba(222, 222, 222, 0.06);
  padding: 1.875rem 2.125rem;
  margin-bottom: 1.75rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  color: ${COLOR.darkGray};
`;

const RedText = styled.span`
  color: ${COLOR.red};
`;

const BroadcastPreferencesContainer = styled.section`
  margin-top: 1rem;
`;

const VesselFormContainer = styled.section`
  margin-top: 1rem;
`;

const SearchContainer = styled.div`
  margin: 0.5rem 0;
  width: 25rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const OrgName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.3rem;
`;

const OrgNameHidden = styled(OrgName)`
  visibility: hidden;
`;

const IsTestLabel = styled.span`
  border-radius: 1rem;
  background-color: ${(props): string => props.theme.colors.pink_50};
  color: ${(props): string => props.theme.colors.pink_700};
  padding: 0.125rem 0.5rem;
`;

const TestOrgLabel = styled.div`
  font-size: 0.8rem;
  margin-left: 1.7rem;
  color: ${COLOR.midDarkGrey};
`;

const DisplayValueContainer = styled.div`
  position: relative;
`;

const DisplayValue = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -1.85rem;
  width: calc(90% - 0.3rem);
  margin-left: 0.8rem;
  margin-right: 1.5rem;
  pointer-events: none;
`;

export default GeneralInfoForm;
