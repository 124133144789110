import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import { getBearerToken } from 'utils/userHelper';
import {
  GeoDataContact,
  GeoDistanceRequest,
  GeoDistanceResponse,
  GetGeoCodeResponse,
  MetaGeoDistanceResponse,
  MetaSavedGeoResponse,
  SavedGeoData,
  SysCreateGeoData,
} from 'models/geoService';
import {
  receiveGeoDatas,
  receiveSavedGeoDatas,
  receiveSavedGeoData,
  receiveMarineLocationDatas,
  receiveMarineLocationDetail,
} from 'reduxActions/geoService/geoServiceActions';
import { PER_PAGE } from 'constants/paginationMeta';
import { MetaMarineLocationResponse } from 'src/models/geoService/geoService';
import {
  MarineLocation,
  MarineLocationCreate,
  MarineLocationUpdate,
  MarineLocationCreateResponse,
  MarineLocationUpdateResponse,
} from 'models/marineLocation';

class GeoServiceClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.GEO_SERVICE_PROTOCOL;
    this.host = process.env.GEO_SERVICE_HOST;
    this.port = process.env.GEO_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(
    method: Method,
    url: string,
    body: T | null = null,
    includeBearerToken = true
  ): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  getGeoCodeUrl(searchVal: string): string {
    return `${this.getBaseUrl()}/geocode?street_address=${searchVal}&country=Singapore`;
  }

  async getGeoCode(searchVal: string): Promise<void> {
    const request = this.makeRequest('GET', this.getGeoCodeUrl(searchVal));
    const store = configureStore();

    try {
      const response: AxiosResponse<GetGeoCodeResponse> = await axios(request);
      store.dispatch(receiveGeoDatas(response.data.geo_data));
    } catch (error) {
      store.dispatch(receiveGeoDatas([]));
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  geoDistanceUrl(): string {
    return `${this.getBaseUrl()}/distance`;
  }

  async geoDistance(
    geoDistanceRequest: GeoDistanceRequest
  ): Promise<GeoDistanceResponse> {
    const request = this.makeRequest('POST', this.geoDistanceUrl(), geoDistanceRequest);

    try {
      const response: AxiosResponse<MetaGeoDistanceResponse> = await axios(request);
      return response.data.distance_data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  savedAddressUrl(): string {
    return `${this.getBaseUrl()}/saved-address`;
  }

  getSavedAddressUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/saved-address?${params}`;
  }

  savedAddressDetailUrl(id: string): string {
    return `${this.getBaseUrl()}/saved-address/${id}`;
  }

  savedAddressDetailByNameUrl(name: string): string {
    return `${this.getBaseUrl()}/saved-address-name/${name}`;
  }

  sysSavedAddressDetailByNameUrl(
    name: string,
    orgID: string,
    params = new URLSearchParams()
  ): string {
    return `${this.getBaseUrl()}/sys/organization/${orgID}/saved-address-name/${name}?${params}`;
  }

  sysSavedAddressUrl(): string {
    return `${this.getBaseUrl()}/sys/saved-address`;
  }

  sysSavedAddressDetailUrl(id: string, params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/saved-address/${id}?${params}`;
  }

  getSysSavedAddressUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/saved-address?${params}`;
  }

  sysSavedAddressDelte(orgID: string, id: string): string {
    return `${this.getBaseUrl()}/sys/organization/${orgID}/saved-address/${id}`;
  }

  async createSavedAddress(form: GeoDataContact): Promise<GeoDataContact> {
    const request = this.makeRequest('POST', this.savedAddressUrl(), form);

    try {
      const response: AxiosResponse<GeoDataContact> = await axios(request);

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    }
  }

  async updateSavedAddress(form: SavedGeoData): Promise<SavedGeoData> {
    const request = this.makeRequest('PUT', this.savedAddressDetailUrl(form.id), form);

    try {
      const response: AxiosResponse<SavedGeoData> = await axios(request);

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async getSavedAddress(queryParams = new URLSearchParams()): Promise<void> {
    queryParams.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.getSavedAddressUrl(queryParams));
    const store = configureStore();

    try {
      const response: AxiosResponse<MetaSavedGeoResponse> = await axios(request);
      store.dispatch(receiveSavedGeoDatas(response.data.geo_datas));
    } catch (error) {
      store.dispatch(receiveSavedGeoDatas([]));
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  async getSavedAddressDetail(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.savedAddressDetailUrl(id));

    try {
      const response: AxiosResponse<SavedGeoData> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveSavedGeoData(response.data));
    } catch (error) {
      throw error.message;
    }
  }

  async getSavedAddressDetailByName(name: string): Promise<SavedGeoData> {
    const request = this.makeRequest('GET', this.savedAddressDetailByNameUrl(name));

    try {
      const response: AxiosResponse<SavedGeoData> = await axios(request);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  async deleteSavedAddress(id: string): Promise<void> {
    const request = this.makeRequest('DELETE', this.savedAddressDetailUrl(id));

    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    }
  }

  // SysAdmin
  async getSysSavedAddress(queryParams = new URLSearchParams()): Promise<void> {
    queryParams.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.getSysSavedAddressUrl(queryParams));
    const store = configureStore();

    try {
      const response: AxiosResponse<MetaSavedGeoResponse> = await axios(request);
      store.dispatch(receiveSavedGeoDatas(response.data.geo_datas));
    } catch (error) {
      store.dispatch(receiveSavedGeoDatas([]));
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  async getSysSavedAddressDetail(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.sysSavedAddressDetailUrl(id));

    try {
      const response: AxiosResponse<SavedGeoData> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveSavedGeoData(response.data));
    } catch (error) {
      throw error.message;
    }
  }

  async getSysSavedAddressDetailByName(
    name: string,
    orgID: string,
    queryParams = new URLSearchParams()
  ): Promise<SavedGeoData> {
    const request = this.makeRequest(
      'GET',
      this.sysSavedAddressDetailByNameUrl(name, orgID, queryParams)
    );

    try {
      const response: AxiosResponse<SavedGeoData> = await axios(request);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  async sysCreateSavedAddress(form: SysCreateGeoData): Promise<SysCreateGeoData> {
    const request = this.makeRequest('POST', this.sysSavedAddressUrl(), form);

    try {
      const response: AxiosResponse<SysCreateGeoData> = await axios(request);

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    }
  }

  async sysUpdateSavedAddress(form: SavedGeoData): Promise<SavedGeoData> {
    const request = this.makeRequest('PUT', this.sysSavedAddressDetailUrl(form.id), form);

    try {
      const response: AxiosResponse<SavedGeoData> = await axios(request);

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysDeleteSavedAddress(orgID: string, id: string): Promise<void> {
    const request = this.makeRequest('DELETE', this.sysSavedAddressDelte(orgID, id));

    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    }
  }

  sysMarineLocationUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/marine-location?${params}`;
  }

  sysMarineLocationDetailUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/marine-location/${id}`;
  }

  marineLocationUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/marine-location?${params}`;
  }

  async getSysMarineLocation(queryParams = new URLSearchParams()): Promise<void> {
    queryParams.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.sysMarineLocationUrl(queryParams));
    const store = configureStore();

    try {
      const response: AxiosResponse<MetaMarineLocationResponse> = await axios(request);
      store.dispatch(receiveMarineLocationDatas(response.data.marine_locations));
    } catch (error) {
      store.dispatch(receiveMarineLocationDatas([]));
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  async deleteSysMarineLocation(id: string): Promise<void> {
    const request = this.makeRequest('DELETE', this.sysMarineLocationDetailUrl(id));

    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    }
  }

  async postSysMarineLocation(
    form: MarineLocationCreate
  ): Promise<MarineLocationCreateResponse> {
    const request = this.makeRequest('POST', this.sysMarineLocationUrl(), form);

    try {
      const response: AxiosResponse<MarineLocationCreateResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          throw new Error('name address already exists');
        } else {
          throw error.response.data.message;
        }
      } else {
        throw error.message;
      }
    }
  }

  async getSysMarineLocationDetail(id: string): Promise<MarineLocation> {
    const request = this.makeRequest('GET', this.sysMarineLocationDetailUrl(id));
    try {
      const response: AxiosResponse<MarineLocation> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveMarineLocationDetail(response.data));
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  async updateSysMarineLocation(
    form: MarineLocationUpdate
  ): Promise<MarineLocationUpdateResponse> {
    const request = this.makeRequest(
      'PUT',
      this.sysMarineLocationDetailUrl(form.id),
      form
    );

    try {
      const response: AxiosResponse<MarineLocationUpdateResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          throw new Error('name address already exists');
        } else {
          throw error.response.data.message;
        }
      } else {
        throw error.message;
      }
    }
  }

  async getMarineLocation(queryParams = new URLSearchParams()): Promise<void> {
    queryParams.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.marineLocationUrl(queryParams));
    const store = configureStore();

    try {
      const response: AxiosResponse<MetaMarineLocationResponse> = await axios(request);
      store.dispatch(receiveMarineLocationDatas(response.data.marine_locations));
    } catch (error) {
      store.dispatch(receiveMarineLocationDatas([]));
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

}

export default GeoServiceClient;
