import * as React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

type ButtonType =
  | 'primary'
  | 'neutral'
  | 'secondary' // TODO: Delete this type
  | 'tertiary'
  | 'link'
  | 'secondary-gray' // TODO: Delete this type
  | 'tertiary-gray'; // TODO: Delete this type

interface StyledButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: ButtonType;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  isFullWidth?: boolean;
  fontWeight?: 400 | 600 | 700;
  isLoading?: boolean;
  icon?: React.ReactNode;
  buttonStyle: 'encourage' | 'discourage' | 'caution';
}

const LinkButtonStyle = css`
  border: none;
  background-color: transparent;
  box-shadow: none;
  text-decoration-line: underline;
  padding: 0;
`;

const encourageStyle = (buttonType: ButtonType): ReturnType<typeof css> => {
  switch (buttonType) {
    case 'primary':
      return css`
        color: ${(props): string => props.theme.colors.base_white};
        border: 1px solid ${(props): string => props.theme.colors.primary_600};
        background-color: ${(props): string => props.theme.colors.primary_600};
        box-shadow: 0px 1px 2px 0px rgba(204, 209, 240, 0.05);
        &:hover {
          background-color: ${(props): string => props.theme.colors.primary_700};
          border: 1px solid ${(props): string => props.theme.colors.primary_700};
        }
        &:focus {
          background-color: ${(props): string => props.theme.colors.primary_900};
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
        &:disabled {
          border: 1px solid ${(props): string => props.theme.colors.primary_200};
          background-color: ${(props): string => props.theme.colors.primary_200};
          box-shadow: ${(props): string => props.theme.boxShadows.xs};
        }
      `;
    case 'neutral':
      return css`
        color: ${(props): string => props.theme.colors.gray_700};
        border: 1px solid ${(props): string => props.theme.colors.gray_300};
        background-color: ${(props): string => props.theme.colors.base_white};
        box-shadow: ${(props): string => props.theme.boxShadows.xs};
        &:hover {
          border: 1px solid ${(props): string => props.theme.colors.gray_300};
          background-color: ${(props): string => props.theme.colors.gray_50};
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px #f2f4f7,
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        &:disabled {
          color: ${(props): string => props.theme.colors.gray_300};
          border: 1px solid ${(props): string => props.theme.colors.gray_200};
        }
      `;
    case 'link':
      return css`
        ${LinkButtonStyle}
        color: ${(props): string => props.theme.colors.primary_700};

        &:hover {
          color: ${(props): string => props.theme.colors.primary_800};
        }

        &:disabled {
          color: ${(props): string => props.theme.colors.gray_300};
        }
      `;
  }
};

const discourageStyle = (buttonType: ButtonType): ReturnType<typeof css> => {
  switch (buttonType) {
    case 'primary':
      return css`
        color: ${(props): string => props.theme.colors.base_white};
        border: 1px solid ${(props): string => props.theme.colors.error_600};
        background-color: ${(props): string => props.theme.colors.error_600};
        box-shadow: ${(props): string => props.theme.boxShadows.xs};
        &:hover {
          background-color: ${(props): string => props.theme.colors.error_700};
          border: 1px solid ${(props): string => props.theme.colors.error_700};
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px #fee4e2,
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        &:disabled {
          border: 1px solid ${(props): string => props.theme.colors.error_200};
          background-color: ${(props): string => props.theme.colors.error_200};
          box-shadow: ${(props): string => props.theme.boxShadows.xs};
        }
      `;
    case 'neutral':
      return css`
        color: ${(props): string => props.theme.colors.error_700};
        border: 1px solid ${(props): string => props.theme.colors.error_300};
        background-color: ${(props): string => props.theme.colors.base_white};
        box-shadow: ${(props): string => props.theme.boxShadows.xs};
        &:hover {
          background-color: ${(props): string => props.theme.colors.error_50};
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px #fee4e2,
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        &:disabled {
          color: ${(props): string => props.theme.colors.error_300};
          border: 1px solid ${(props): string => props.theme.colors.error_200};
          background-color: ${(props): string => props.theme.colors.base_white};
          box-shadow: ${(props): string => props.theme.boxShadows.xs};
        }
      `;
    case 'link':
      return css`
        ${LinkButtonStyle}
        color: ${(props): string => props.theme.colors.error_700};

        &:hover {
          color: ${(props): string => props.theme.colors.error_800};
        }

        &:disabled {
          color: ${(props): string => props.theme.colors.error_300};
        }
      `;
  }
};

const cautionStyle = (buttonType: ButtonType): ReturnType<typeof css> => {
  switch (buttonType) {
    case 'primary':
      return css`
        color: ${(props): string => props.theme.colors.base_white};
        border: 1px solid ${(props): string => props.theme.colors.warning_600};
        background-color: ${(props): string => props.theme.colors.warning_600};
        box-shadow: ${(props): string => props.theme.boxShadows.xs};
        &:hover {
          background-color: ${(props): string => props.theme.colors.warning_700};
          border: 1px solid ${(props): string => props.theme.colors.warning_700};
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px #fff3cc,
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        &:disabled {
          border: 1px solid ${(props): string => props.theme.colors.warning_200};
          background-color: ${(props): string => props.theme.colors.warning_200};
          box-shadow: ${(props): string => props.theme.boxShadows.xs};
        }
      `;
    case 'neutral':
      return css`
        color: ${(props): string => props.theme.colors.warning_700};
        border: 1px solid ${(props): string => props.theme.colors.warning_300};
        background-color: ${(props): string => props.theme.colors.base_white};
        box-shadow: ${(props): string => props.theme.boxShadows.xs};
        &:hover {
          border: 1px solid ${(props): string => props.theme.colors.warning_300};
          background-color: ${(props): string => props.theme.colors.warning_50};
        }
        &:focus {
          box-shadow: 0px 0px 0px 4px #fff3cc,
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        &:disabled {
          color: ${(props): string => props.theme.colors.warning_300};
          border: 1px solid ${(props): string => props.theme.colors.warning_200};
          background-color: ${(props): string => props.theme.colors.base_white};
          box-shadow: ${(props): string => props.theme.boxShadows.xs};
        }
      `;
    case 'link':
      return css`
        ${LinkButtonStyle}
        color: ${(props): string => props.theme.colors.warning_700};

        &:hover {
          color: ${(props): string => props.theme.colors.warning_800};
        }

        &:disabled {
          color: ${(props): string => props.theme.colors.warning_300};
        }
      `;
  }
};

const LoadingIcon = <FontAwesomeIcon icon={faCircleNotch} spin />;

const StyledButton = styled.button<StyledButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: ${(props): string => props.theme.colors.base_white};
  font-weight: ${({ fontWeight }): number => (fontWeight ? fontWeight : 600)};
  cursor: pointer;
  outline: none;
  gap: 0.5rem;
  ${({ isFullWidth }): FlattenSimpleInterpolation =>
    isFullWidth &&
    css`
      width: 100%;
    `}
  ${(props): FlattenSimpleInterpolation => {
    switch (props.size) {
      case 'xs':
        return css`
          padding: 4px 8px;
          font-size: ${props.theme.fontSizes.xs};
        `;
      case 'sm':
        return css`
          padding: 8px 14px;
          font-size: ${props.theme.fontSizes.sm};
        `;
      case 'md':
        return css`
          padding: 10px 16px;
          font-size: ${props.theme.fontSizes.sm};
        `;
      case 'lg':
        return css`
          padding: 10px 18px;
          font-size: ${props.theme.fontSizes.md};
        `;
      case 'xl':
        return css`
          padding: 12px 20px;
          font-size: ${props.theme.fontSizes.md};
        `;
      case '2xl':
        return css`
          padding: 16px 28px;
          font-size: ${props.theme.fontSizes.lg};
        `;
      default:
        return css`
          padding: 4px 8px;
        `;
    }
  }}

  ${(props): ReturnType<typeof css> => {
    switch (props.buttonStyle) {
      case 'encourage':
        return encourageStyle(props.buttonType);
      case 'discourage':
        return discourageStyle(props.buttonType);
      case 'caution':
        return cautionStyle(props.buttonType);
    }
  }}

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Button = React.forwardRef<HTMLButtonElement, StyledButtonProps>(
  ({ icon = null, size = 'sm', ...props }, ref) => {
    const { children, isLoading, ...buttonProps } = props;

    return (
      <StyledButton {...buttonProps} size={size} ref={ref}>
        {isLoading ? LoadingIcon : icon}
        {children}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';
