import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { RootState } from 'reduxActions/store';
import { CurrentUser } from 'models/auth';
import { connect } from 'react-redux';

interface SysAdminRouteProps {
  currentUser: CurrentUser;
}

type Props = SysAdminRouteProps & RouteProps;

const SysAdminRoute = (props: Props): JSX.Element => {
  const { component: Component, currentUser, ...rest } = props;

  const isSysAdmin = currentUser && currentUser.user.roles.includes('SysAdmin')
    && currentUser.user.org_id === 'VersaFleet';
  const isMerchant = currentUser && !currentUser.user.roles.includes('SysAdmin');

  let redirectPath: string;
  if (!currentUser) {
    redirectPath = '/login';
  } else if (isMerchant) {
    redirectPath = '/active-orders';
  }

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode =>
        isSysAdmin ?
          <Component {...props} />
        : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

function mapStateToProps(state: RootState): SysAdminRouteProps {
  return {
    currentUser: state.auth.currentUser,
  };
}

export default connect(mapStateToProps)(SysAdminRoute);
