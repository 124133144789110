import * as React from 'react';
import styled from 'styled-components';
import { Address } from 'models/task';
import { capitalizeAll, formatAddress } from 'utils/formatter';

interface AddressViewProps {
  address: Address;
  noWrap?: boolean;
  berthNo?: string;
}

const AddressView = (props: AddressViewProps): JSX.Element => {
  const buildingName =
    props.address.building_name !== props.address.street_address
      ? props.address.building_name
      : '';
  const headingTitle =
    props.address.name_address !== undefined && props.address.name_address !== ''
      ? buildingName !== ''
        ? props.noWrap
          ? props.address.name_address
          : `${props.address.name_address}, ${buildingName}`
        : props.address.name_address
      : buildingName;

  if (props.noWrap) {
    return (
      <AddressViewContainer>
        <Heading noWrap>
          {headingTitle !== ''
            ? capitalizeAll(headingTitle)
            : capitalizeAll(formatAddress(props.address))}
        </Heading>
      </AddressViewContainer>
    );
  }

  // All the address information will be capitalize to following onemap
  return (
    <AddressViewContainer>
      <Heading>{capitalizeAll(headingTitle)}</Heading>
      <Subtitle>{capitalizeAll(formatAddress(props.address))}{props.berthNo && `, ${props.berthNo}`}</Subtitle>
    </AddressViewContainer>
  );
};

const AddressViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const TextBase = styled.div<{ noWrap?: boolean }>`
  line-height: 1.25rem;
  ${({ noWrap }): string | undefined =>
    noWrap &&
    `
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}
`;

const Heading = styled(TextBase)`
  font-weight: 600;
  color: ${(props): string => props.theme.colors.gray_700};
  font-size: ${(props): string => props.theme.fontSizes.sm};
`;

const Subtitle = styled(TextBase)`
  color: ${(props): string => props.theme.colors.gray_600};
  font-size: ${(props): string => props.theme.fontSizes.md};
`;

export default AddressView;
