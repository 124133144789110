import { Address } from 'models/task';
import { v4 as uuidv4 } from 'uuid';

export function generateUuid(): string {
  return uuidv4();
}

export function capitalizeEachWord(data: string): string {
  if (data.trim().length === 0) {
    return '';
  }

  const pieces = data.split(' ');
  for (let i = 0; i < pieces.length; i += 1) {
    const j = pieces[i].charAt(0).toUpperCase();
    pieces[i] = j + pieces[i].substr(1);
  }
  return pieces.join(' ');
}

export function capitalizeAll(data: string): string {
  return data.toUpperCase();
}

export function removeUnderscore(str: string): string {
  return (str || '').replace(/_/g, ' ');
}

export function isStartsWithNumber(str: string): boolean {
  return /^\d/.test(str);
}

export function formatError<T extends string | Record<string, unknown> | unknown[]>(
  e: T,
  includeKeyProperty = true
): string {
  if (typeof e === 'object') {
    const error: string[] = [];
    for (const property in e) {
      if (includeKeyProperty === true) {
        error.push(`${property}: ${e[property]}`);
      } else {
        error.push(String(e[property]));
      }
    }
    return error.join(', ');
  } else if (Array.isArray(e)) {
    return e.join(', ');
  } else {
    return String(e);
  }
}

export function formatAddress(address: Address, includeUnitNumber = true): string {
  const addressArray: string[] = [];

  if (address.street_address) {
    addressArray.push(address.street_address);
  }
  if (address.unit_number && includeUnitNumber) {
    addressArray.push(address.unit_number);
  }
  if (address.country) {
    // put country same as onemap style
    addressArray.push(capitalizeAll(address.country));
  }
  if (address.zip_code) {
    addressArray.push(address.zip_code);
  }

  return addressArray.join(', ');
}

export function formatPrice(currency: string, price: number): string {
  return `${currency.toUpperCase()} ${(price / (100 * 1000)).toFixed(2)}`;
}

export function formatDuration(seconds: number): string {
  let hours = 0;
  let minutes = 0;
  if (isFinite(seconds) && seconds !== 0) {
    hours = Math.floor(seconds / 3600);
    minutes = Math.floor((seconds % 3600) / 60);
  }
  return `${hours} ${hours > 1 ? 'hours' : 'hour'} ${minutes} ${
    minutes > 1 ? 'minutes' : 'minute'
  }`;
}

export function groupArrayKeyByValue(
  arrayData: { [key: string]: string }[],
  arrayKey: string,
  arrayValue: string
): Array<{ [key: string]: string[] | string }> {
  const hash = Object.create(null);
  const result = arrayData.reduce(
    (accumulator: Array<{ [key: string]: string[] | string }>, currentValue) => {
      const newArrayKey = currentValue[arrayValue];
      const newArrayValue = currentValue[arrayKey];
      if (!hash[newArrayKey]) {
        hash[newArrayKey] = { [arrayValue]: newArrayKey, [arrayKey]: [] };
        accumulator.push(hash[newArrayKey]);
      }
      hash[newArrayKey][arrayKey].push(newArrayValue);
      return accumulator;
    },
    []
  );
  return result;
}

export function formatHourTo24Format(hour: number, period: string): number {
  if (period === 'am' && hour === 12) {
    return hour - 12;
  } else if (period === 'pm' && hour < 12) {
    return hour + 12;
  }
  return hour;
}

export function removeNonDigits(input: string): string {
  return input.replace(/\D/g, '');
}

export function isValueEmptyOrZero(value: string | number): boolean {
  return value === '' || value === 0 || value === '0';
}

export function formatToTwoDecimalNumber(
  value: number,
  maxDecimalPlaces: number
): number {
  if (
    Number.isFinite(value) &&
    Math.abs(value % 1) !== 0 &&
    value.toString().split('.')[1].length > maxDecimalPlaces
  ) {
    return parseFloat(value.toFixed(maxDecimalPlaces));
  } else {
    return value;
  }
}

export function formatNumberWithSpaces(input: number | string): string {
  const number = typeof input === 'string' ? parseFloat(input) : input;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
