import * as React from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faCheck } from '@fortawesome/free-solid-svg-icons';
import COLOR, { transparent } from 'constants/color';
import Input, { HEIGHTS, WIDTHS, StyledInputProps } from './basicTextInput';
import { Typography } from '../typography/typography';
import { isStartsWithNumber } from 'utils/formatter';

interface Option {
  value: string;
  title?: string | React.ReactNode;
  leftIcon?: IconDefinition;
  text: string | React.ReactNode;
  additionalText?: string | React.ReactNode;
  additionalValue?: object | string;
}

interface SearchboxProps extends StyledInputProps {
  containerStyle?: FlattenSimpleInterpolation;
  fieldName?: string | React.ReactNode;
  trailingIcon?: IconDefinition;
  trailingIconOnClick?: () => void;
  isRequired?: boolean;
  extraLabel?: React.ReactNode;
  bottomLabel?: React.ReactNode;
  onTextChange?: (value: string) => void;
  width?: keyof typeof WIDTHS;
  height?: keyof typeof HEIGHTS;
  inputName?: string;
  options?: Option[];
  expandableOptions?: Option[];
  expandableOptionsTitle?: string;
  expandableOptionsMaxLength?: number;
  handleSelectChange?: (value: string, additionalValue?: object | string) => void;
  handleSetFocus?: (focused: boolean) => void;
  disabled?: boolean;
  emptyPlaceholder?: React.ReactNode;
  hideDropdown?: boolean;
}

interface ContainerProps {
  containerStyle?: FlattenSimpleInterpolation;
  width?: keyof typeof WIDTHS;
}

interface SearchboxState {
  focusedIndex: number | null;
  showDropdown: boolean;
  isShowMore: boolean;
}

const inputWithTrailingIcon = css`
  padding-right: 2.5rem;
`;

class Searchbox extends React.Component<SearchboxProps, SearchboxState> {
  dropdownRef: React.RefObject<HTMLDivElement>;
  dropdownListRef: React.RefObject<HTMLDivElement>[];
  constructor(props: SearchboxProps) {
    super(props);
    this.state = {
      focusedIndex: -1,
      showDropdown: false,
      isShowMore: false,
    };
    this.dropdownRef = React.createRef();
    const combinedOptions = [
      ...(this.props.options || []),
      ...(this.props.expandableOptions || []),
    ];
    this.dropdownListRef = combinedOptions.map(() => React.createRef<HTMLDivElement>());
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent): void => {
    const { showDropdown } = this.state;
    if (
      showDropdown &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target as Node)
    ) {
      this.showDropdown(false);
    }
  };

  showDropdown = async (showDropdown: boolean): Promise<void> => {
    !this.props.hideDropdown && this.setState({ showDropdown });
    if (this.props.handleSetFocus) {
      this.props.handleSetFocus(showDropdown);
    }
  };

  handleSelectChange = (value: string, additionalValue?: object | string): void => {
    if (this.props.handleSelectChange) {
      this.props.handleSelectChange(value, additionalValue);
    }
    this.setState({ showDropdown: false, isShowMore: false });
  };

  render(): React.ReactNode {
    const {
      fieldName,
      isRequired,
      extraLabel,
      bottomLabel,
      onTextChange,
      disabled,
      trailingIcon,
      trailingIconOnClick,
      emptyPlaceholder,
      inputStyle,
      containerStyle,
      inputName,
      width,
      options,
      expandableOptions,
      expandableOptionsMaxLength,
      expandableOptionsTitle,
      ...inputProps
    } = this.props;
    const { focusedIndex, showDropdown, isShowMore } = this.state;
    const sortAlphaNumeric = (data: Option[]): Option[] => {
      const startsWithNumberList = data.filter((opt: Option) => {
        return isStartsWithNumber(typeof opt.title === 'string' && opt.title);
      });
      const startsWithAlphaList = data.filter((opt: Option) => {
        return !isStartsWithNumber(typeof opt.title === 'string' && opt.title);
      });
      const sortOptions = (opts: Option[]): void => {
        opts.sort((optA, optB) => {
          const titleA = typeof optA.title === 'string' ? optA.title.toLowerCase() : '';
          const titleB = typeof optB.title === 'string' ? optB.title.toLowerCase() : '';

          return titleA.localeCompare(titleB, 'en', { numeric: true });
        });
      };
      sortOptions(startsWithNumberList);
      sortOptions(startsWithAlphaList);

      return [...startsWithAlphaList, ...startsWithNumberList];
    };

    const optionsList = sortAlphaNumeric(options || []);
    let expandableOptionsList: Option[] = sortAlphaNumeric(expandableOptions || []);
    let canShowMore = false;
    // limit expand-able options
    if (expandableOptionsList.length && expandableOptionsMaxLength) {
      if (expandableOptionsList.length > expandableOptionsMaxLength) {
        canShowMore = true;
      }
      if (!isShowMore) {
        expandableOptionsList = expandableOptionsList.filter(
          (_, i) => i < expandableOptionsMaxLength
        );
      }
    }

    const combinedOptions = [...expandableOptionsList, ...optionsList];
    const optionsLength = (combinedOptions || []).length;
    this.dropdownListRef = combinedOptions.map(() => React.createRef<HTMLDivElement>());
    const selectOptions: React.ReactNode[] = [];
    (combinedOptions || []).forEach((option, i) => {
      selectOptions.push(
        <div key={i}>
          {i === 0 && expandableOptionsTitle && expandableOptionsList.length > 0 ? (
            <Typography
              as="div"
              size="md"
              color="gray_900"
              customStyle={{
                paddingLeft: '1rem',
                paddingTop: '0.875rem',
                paddingBottom: '0.5rem',
                borderBottom: `2px solid ${COLOR.midLightGrey}`,
              }}
            >
              {expandableOptionsTitle}
            </Typography>
          ) : (
            false
          )}
          <OptionContainer
            key={option.value + Math.random()}
            ref={this.dropdownListRef[i]}
            focused={focusedIndex === i}
            onMouseDown={(): void => {
              this.handleSelectChange(option.value, option.additionalValue);
            }}
            onMouseEnter={(): void => {
              this.setState({ focusedIndex: i });
            }}
          >
            <SelectOptionContainer>
              <SelectOption selected={option.value === this.props.value} focused={focusedIndex === i}>
                {option.title ? (
                  <SelectOptionContentWithIcon>
                    {option.leftIcon ? <CheckIcon icon={option.leftIcon} /> : false}
                    <SelectOptionContent>
                      <Title>{option.title}</Title>
                      <TextValue>{option.text}</TextValue>
                    </SelectOptionContent>
                  </SelectOptionContentWithIcon>
                ) : (
                  option.text
                )}
                {option.additionalText ? (
                  <AdditionalText>{option.additionalText}</AdditionalText>
                ) : (
                  false
                )}
              </SelectOption>
              {focusedIndex === i ? <CheckIcon icon={faCheck} /> : false}
            </SelectOptionContainer>
          </OptionContainer>
          {i === expandableOptionsList.length - 1 ? (
            <>
              {canShowMore ? (
                <>
                  {!isShowMore ? (
                    <ShowMoreButton
                      onClick={(): void => {
                        this.setState({ isShowMore: true });
                      }}
                    >
                      More (+
                      {(expandableOptions || []).length - expandableOptionsMaxLength})
                    </ShowMoreButton>
                  ) : (
                    <ShowMoreButton
                      onClick={(): void => {
                        this.setState({ isShowMore: false });
                      }}
                    >
                      Hide
                    </ShowMoreButton>
                  )}
                </>
              ) : (
                false
              )}
              <Separator />
            </>
          ) : (
            false
          )}
        </div>
      );
    });

    return (
      <Container containerStyle={containerStyle} width={width} ref={this.dropdownRef}>
        <LabelContainer>
          {fieldName && (
            <InputLabel>
              {fieldName} {isRequired && <RequiredSpan>*</RequiredSpan>}
            </InputLabel>
          )}
          {extraLabel}
        </LabelContainer>
        <Input
          autoComplete="none"
          name={inputName}
          inputStyle={[inputStyle, trailingIcon ? inputWithTrailingIcon : '']}
          isRequired={isRequired}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            onTextChange(e.target.value);
            this.setState({ focusedIndex: -1, isShowMore: false });
          }}
          {...inputProps}
          onFocus={(): void => {
            this.showDropdown(true);
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
              e.preventDefault();
              const focusedIndexValue =
                e.key === 'ArrowDown' ? focusedIndex + 1 : focusedIndex - 1;
              if (focusedIndexValue >= 0 && focusedIndexValue < optionsLength) {
                this.setState({ focusedIndex: focusedIndexValue });
                const offsetTop =
                  this.dropdownListRef &&
                  this.dropdownListRef[focusedIndexValue] &&
                  this.dropdownListRef[focusedIndexValue].current.offsetTop;
                const dropdownRefid = document.getElementById('dropdownRefid');
                dropdownRefid &&
                  dropdownRefid.scrollTo({ top: offsetTop, behavior: 'smooth' });
              }
            } else if (e.key === 'Enter') {
              const selectedValue =
                (combinedOptions &&
                  combinedOptions[focusedIndex] &&
                  combinedOptions[focusedIndex].value) ||
                '';
              const selectedAdditionalValue =
                (combinedOptions &&
                  combinedOptions[focusedIndex] &&
                  combinedOptions[focusedIndex].additionalValue) ||
                '';
              if (selectedValue) {
                this.handleSelectChange(selectedValue, selectedAdditionalValue);
              }
            } else if (e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
              this.showDropdown(true);
            }
          }}
        />
        {trailingIcon && (
          <IconContainer>
            <Icon
              icon={trailingIcon}
              onClick={trailingIconOnClick}
              style={{ position: 'absolute' }}
            />
          </IconContainer>
        )}
        {showDropdown ? (
          <SelectOptionsContainer>
            {(combinedOptions || []).length > 0 ? (
              <SelectOptions id="dropdownRefid">{selectOptions}</SelectOptions>
            ) : emptyPlaceholder ? (
              emptyPlaceholder
            ) : (
              <SelectOptions>
                <OptionContainer>
                  <SelectOption>No data found ...</SelectOption>
                </OptionContainer>
              </SelectOptions>
            )}
          </SelectOptionsContainer>
        ) : (
          false
        )}
        {bottomLabel}
      </Container>
    );
  }
}
const Container = styled.div<ContainerProps>`
  margin: 0;
  text-overflow: ellipsis;
  width: ${(props): string => (props.width ? WIDTHS[props.width] : WIDTHS.full)};
  ${(props): FlattenSimpleInterpolation => props.containerStyle};

  @media (max-device-width: 768px), (max-width: 768px) {
    width: 100%;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InputLabel = styled.label`
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.32px;
  margin-bottom: 0.25rem;

  @media (max-device-width: 700px), (max-width: 700px) {
    font-size: 0.875rem;
  }
`;

const RequiredSpan = styled.span`
  color: ${COLOR.red};
`;

const IconContainer = styled.div`
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)<{ onClick: () => void }>`
  position: absolute;
  top: -1.75rem;
  right: 1rem;
  ${(props): string => (props.onClick ? 'cursor: pointer;' : '')}
`;

const SelectOptionsContainer = styled.div`
  position: relative;
  top: 0.2rem;
  width: 100%;
`;

const SelectOptions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLOR.blue};
  color: ${COLOR.black};
  background-color: ${COLOR.white};
  outline: none;
  overflow-y: auto;
  max-height: 15rem;
  z-index: 10000;
`;

const SelectOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-right: 1rem;
`;

const SelectOption = styled.div<{ selected?: boolean; focused?: boolean }>`
  cursor: ${(props): string => (props.selected ? 'default' : 'pointer')};
  padding: 0.8rem 1rem 0.8rem 0;
  margin-left: 1rem;
  margin-right: 1rem;
  width: ${(props): string => (props.focused ? '80%' : '90%')};
`;

const OptionContainer = styled.div<{ selected?: boolean; focused?: boolean }>`
  background-color: ${(props): string => props.focused && transparent('blue', 0.1)};
  cursor: ${(props): string => (props.selected ? 'default' : 'pointer')};

  :hover {
    background-color: ${(props): string => props.focused && transparent('blue', 0.1)};
  }

  :last-child {
    border-bottom: 0px;
  }
`;

const Separator = styled.div`
  border-bottom: 2px solid ${COLOR.midLightGrey};
`;

const ShowMoreButton = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${COLOR.blue};
  cursor: pointer;
  text-decoration: underline;
  padding-top: 0.3rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  width: fit-content;
`;

const AdditionalText = styled.div`
  color: ${COLOR.midDarkGrey};
  font-size: 0.75rem;
`;

const TextValue = styled.div`
  color: ${COLOR.darkGray};
  font-size: 0.75rem;
  font-weight: 400;
`;

const SelectOptionContentWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
`;

const SelectOptionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${COLOR.black};
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.5rem;
  font-weight: 600;
`;

export default Searchbox;
