import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import { receiveRunsheetAllSuccess, receiveRunsheet } from 'reduxActions/runsheet/runsheetActions';
import { Runsheet, AddTaskResponse, GetRunsheetsResponse, EditRunsheetPayload } from 'models/runsheet';
import { AddTaskToRunsheetRequest } from 'models/task';
import { getBearerToken } from 'utils/userHelper';
import { PER_PAGE } from 'constants/paginationMeta';
import { StopBroadcastRunsheetPayload } from 'models/runsheet/stopBroadcastRunsheetPayload';
import { SysTransporterAssignPayload } from 'models/runsheet/sysTransporterAssignPayload';
import { SysTransporterAssignResponse } from 'models/runsheet/sysTransporterAssignResponse';

class RunsheetClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.RUNSHEET_MANAGEMENT_SERVICE_PROTOCOL;
    this.host = process.env.RUNSHEET_MANAGEMENT_SERVICE_HOST;
    this.port = process.env.RUNSHEET_MANAGEMENT_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  runsheetUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/runsheet?${params}`;
  }

  runsheetWithIdUrl(id: string): string {
    return `${this.getBaseUrl()}/runsheet/${id}`;
  }

  stopBroadcastRunsheetWithIdUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/runsheet/${id}/stop-broadcast`;
  }

  addTaskToRunsheetUrl(id: string): string {
    return `${this.getBaseUrl()}/runsheet/${id}/task`;
  }

  deleteTaskFromRunsheetUrl(id: string, taskId: string): string {
    return `${this.getBaseUrl()}/runsheet/${id}/task/${taskId}`;
  }

  sysTransporterAssignUrl(runsheetId: string): string {
    return `${this.getBaseUrl()}/sys/runsheet/${runsheetId}/transporter/assign`;
  }

  async getRunsheets(params = new URLSearchParams()): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', `${this.runsheetUrl(params)}`);

    try {
      const response: AxiosResponse<GetRunsheetsResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheetAllSuccess(response.data.runsheets));
    } catch (error) {
      throw error.message;
    }
  }

  async createRunsheet(name: string): Promise<void> {
    const request = this.makeRequest('POST', this.runsheetUrl(), { name });

    try {
      await axios(request);
    } catch (error) {
      throw error.message;
    }
  }

  async editRunsheet(payload: EditRunsheetPayload, id: string): Promise<void> {
    const request = this.makeRequest('PUT', this.runsheetWithIdUrl(id), payload);
    try {
      await axios(request);
    } catch (error) {
      throw error.message;
    }
  }

  async stopBroadcastRunsheet(payload: StopBroadcastRunsheetPayload, id: string): Promise<void> {
    const request = this.makeRequest('PUT', this.stopBroadcastRunsheetWithIdUrl(id), payload);
    try {
      await axios(request);
    } catch (error) {
      throw error.message;
    }
  }

  async getRunsheet(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.runsheetWithIdUrl(id));

    try {
      const response: AxiosResponse<Runsheet> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheet(response.data));
    } catch (error) {
      throw error.message;
    }
  }

  async getRunsheetData(id: string): Promise<Runsheet> {
    const request = this.makeRequest('GET', this.runsheetWithIdUrl(id));

    try {
      const response: AxiosResponse<Runsheet> = await axios(request);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  async addTaskToRunsheet(id: string, addTaskToRunsheetRequest: AddTaskToRunsheetRequest): Promise<AddTaskResponse> {
    const request = this.makeRequest('POST', this.addTaskToRunsheetUrl(id), addTaskToRunsheetRequest);

    try {
      const response: AxiosResponse<AddTaskResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  async deleteRunsheet(id: string): Promise<void> {
    const request = this.makeRequest('DELETE', this.runsheetWithIdUrl(id));
    try {
      await axios(request);
    } catch (error) {
      throw error.message;
    }
  }

  async deleteTaskFromRunsheet(id: string, taskId: string, versionRev: string): Promise<void> {
    const request = this.makeRequest('DELETE', this.deleteTaskFromRunsheetUrl(id, taskId), {
      version_rev: versionRev,
    });

    try {
      await axios(request);
    } catch (error) {
      throw error.message;
    }
  }

  async sysTransporterAssign(runsheetId: string, payload: SysTransporterAssignPayload): Promise<SysTransporterAssignResponse> {
    const request = this.makeRequest('PUT', this.sysTransporterAssignUrl(runsheetId), payload);

    try {
      const response: AxiosResponse<SysTransporterAssignResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error.message;
    }
  }
}

export default RunsheetClient;
