import { PartnerAuthorization } from 'models/partnership';

export interface PartnershipState {
  partnerAuthorization: PartnerAuthorization;
}

export const RECEIVE_PARTNER_AUTHORIZATION = 'RECEIVE_PARTNER_AUTHORIZATION';

export interface ReceivePartnerAuthorization {
  type: typeof RECEIVE_PARTNER_AUTHORIZATION;
  partnerAuthorization: PartnerAuthorization;
}

export type PartnershipActionTypes = ReceivePartnerAuthorization