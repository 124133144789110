import {
  AuthState,
  AuthActionTypes,
  SIGNIN_SUCCESS,
  SIGNOUT_SUCCESS,
  RECEIVE_USERS,
  RECEIVE_USER,
  SET_AFTER_LOGIN_PAGE,
} from './authTypes';

const initialState: AuthState = {
  currentUser: null,
  users: [],
  promptMessage: '',
  afterLoginPage: '',
};

function authReducer(state: AuthState = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case SIGNIN_SUCCESS:
      return { ...state, currentUser: action.payload, promptMessage: '' };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        promptMessage: action.promptMessage,
        afterLoginPage: action.afterLoginPage,
      };
    case RECEIVE_USERS:
      return { ...state, users: action.users };
    case RECEIVE_USER: {
      const users = [...state.users];

      const index = state.users.findIndex(user => user.id === action.user.id);

      if (index !== -1) {
        users[index] = action.user;
      } else {
        users.push(action.user);
      }

      return { ...state, users };
    }
    case SET_AFTER_LOGIN_PAGE: {
      return {
        ...state,
        afterLoginPage: action.afterLoginPage,
      };
    }
    default:
      return state;
  }
}

export default authReducer;
