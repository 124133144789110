import * as React from 'react';
import { Text } from '@react-pdf/renderer';
import { styles } from './styles';

interface FooterTextProps {
  text: string;
}

const FooterText = ({ text }: FooterTextProps) => (
  <Text fixed style={styles.footerText}>
    {text}
  </Text>
);

export default FooterText;
