import { Plan } from 'models/plan';

export interface PlanHash {
  [index: string]: Plan;
}

export interface PlanState {
  plan: Plan | null;
  plans: Plan[];
}

export const RECEIVE_PLANS = 'RECEIVE_PLANS';
export const RECEIVE_PLAN = 'RECEIVE_PLAN';

interface ReceivePlans {
  type: typeof RECEIVE_PLANS;
  plans: Plan[];
}

interface ReceivePlan {
  type: typeof RECEIVE_PLAN;
  plan: Plan;
}

export type PlanActionTypes = ReceivePlans | ReceivePlan;