import * as React from 'react';
import RunsheetRTTClient from 'httpClients/runsheetRTTClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  TableCellRenderEvent,
  SysAdminPagination,
  Dropdown,
  DatePicker,
} from 'components';
import styled, { css } from 'styled-components';
import { RunsheetReadyToTransfer } from 'models/runsheetReadyToTransfer';
import { Link } from 'react-router-dom';
import * as H from 'history';
import * as moment from 'moment';
import DateHelper from 'utils/dateHelper';
import COLOR from 'constants/color';
import JOB_STATUS from 'constants/jobStatus';

interface StateProps {
  models: RunsheetReadyToTransfer[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type RunsheetReadyToTransferIndexProps = StateProps & HistoryProps;

class RunsheetReadyToTransferIndex extends SysAdminPagination<RunsheetReadyToTransfer, RunsheetReadyToTransferIndexProps> {
  constructor(props: RunsheetReadyToTransferIndexProps) {
    super(props);

    this.state = {
      ...this.state,
      basePath: '/sys/runsheet-ready-to-transfers',
      pluralModelName: 'Transfers',
      selectedTab: 'runsheetReadyToTransfers',
      filters: this.getDefaultFilter(),
    };
  }

  getDefaultFilter = (): Record<string, string> => {
    const currentDate = new Date();

    return ({
      page: '1',
      order: 'desc',
      runsheet_date: DateHelper.formatDate(currentDate),
    });
  }

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });
    const filters = { ...this.state.filters };
    const runsheetReadyToTransferDate = DateHelper.formatString(this.state.filters.runsheet_date);

    const startDate = new Date(runsheetReadyToTransferDate).setHours(0, 0, 0, 0);
    const endDate = new Date(runsheetReadyToTransferDate).setHours(23, 59, 59, 999);

    filters.range_start_created_at = moment(startDate).utc().format();
    filters.range_end_created_at = moment(endDate).utc().format();
    delete filters.runsheet_date;

    const client = new RunsheetRTTClient();
    await client.getRunsheetReadyToTransfers(new URLSearchParams(filters));

    this.setState({ isFetching: false });
  }

  renderFilter = (): React.ReactNode => (
    <FilterContainer>
      <Dropdown
        containerStyle={css`
          margin: 0 0.5rem;
        `}
        label="Sort by"
        options={[
          {
            value: 'desc',
            name: 'Newest First',
          }, {
            value: 'asc',
            name: 'Oldest First',
          },
        ]}
        onChange={(value: string): void => { this.onFilterChange('order', value); }}
        value={this.state.filters.order}
      />
      <DatePicker
        label='Date Created'
        enablePreviousDate
        containerStyle={css`
                margin-left: 0.375rem;
                background-color: ${COLOR.white}
              `}
        value={DateHelper.formatString(this.state.filters.runsheet_date)}
        onChange={(v: Date): void => { this.onFilterChange('runsheet_date', DateHelper.formatDate(v)); }}
      />
    </FilterContainer>
  )

  renderActionPanel = (): React.ReactNode => (
    <>
      <Link to="/sys/runsheets">
        <Button>Back to Runsheet</Button>
      </Link>
      <Link to="/sys/runsheet-ready-to-broadcasts">
        <Button>Broadcasts</Button>
      </Link>
    </>
  )

  renderCell = (e: TableCellRenderEvent<{ createdAt: string; id: string; runsheetID: string; OrgID: string; status: string; updatedAt: string }>): React.ReactNode => {
    if (e.key === 'runsheetID') {
      return (
        <Link to={'/sys/runsheets/' + e.value.toString()}>
          <strong>{e.value.toString()}</strong>
        </Link>
      );
    } else if (e.key === 'OrgID') {
      return (
        <Link to={'/sys/organizations/' + e.value.toString() + '/company-details'}>
          <strong>{e.value.toString()}</strong>
        </Link>
      );
    }

    return <>{e.value}</>;
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      id: 'ID',
      runsheetID: 'Runsheet ID',
      OrgID: 'Organization ID',
      status: 'Status',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    };

    const data = models ? models.map(runsheetReadyToTransfer => ({
      id: runsheetReadyToTransfer.id,
      runsheetID: runsheetReadyToTransfer.runsheet_id,
      OrgID: runsheetReadyToTransfer.org_id,
      status: JOB_STATUS[runsheetReadyToTransfer.status],
      createdAt: moment(runsheetReadyToTransfer.created_at).format('DD MMM YYYY HH:mm:ss A'),
      updatedAt: moment(runsheetReadyToTransfer.updated_at).format('DD MMM YYYY HH:mm:ss A'),
    })) : [];

    return (
      <>
        <Table
          columns={columns}
          data={data}
          cellRenderer={this.renderCell}
          rowOnNavigationButtonClick={(id: string): void => { this.props.history.push('/sys/runsheet-ready-to-transfers/' + id); }}
        />
      </>
    );
  }
}

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  models: state.runsheetReadyToTransfer.runsheetReadyToTransfers,
});

export default connect(mapStateToProps)(RunsheetReadyToTransferIndex);
