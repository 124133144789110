import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import { getBearerToken } from 'utils/userHelper';
import {
  MetaNotification, Notification, ReadNotificationResponse,
  UpdateNotificationResponse, PushNotificationPayload,
  PushNotificationResponse, PushEventsPayload,
  PushEventsResponse,
} from 'models/notification';
import { receiveMetaNotification } from 'reduxActions/notification/notificationActions';

class PartnershipClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.NOTIFICATION_PUSHER_SERVICE_PROTOCOL;
    this.host = process.env.NOTIFICATION_PUSHER_SERVICE_HOST;
    this.port = process.env.NOTIFICATION_PUSHER_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  getNotificationsUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/notification?${params}`;
  }

  detailNotificationUrl(id: string): string {
    return `${this.getBaseUrl()}/notification/${id}`;
  }

  readNotificationUrl(): string {
    return `${this.getBaseUrl()}/read-notification`;
  }

  pushNotificationUrl(): string {
    return `${this.getBaseUrl()}/push-notification`;
  }

  pushEventsUrl(): string {
    return `${this.getBaseUrl()}/notification`;
  }

  async getNotifications(queryParams = new URLSearchParams()): Promise<void> {
    const request = this.makeRequest('GET', this.getNotificationsUrl(queryParams));

    try {
      const response: AxiosResponse<MetaNotification> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveMetaNotification(response.data));
    } catch (error) {
      throw error.message;
    }
  }

  async getNotification(id: string): Promise<Notification> {
    const request = this.makeRequest('GET', this.detailNotificationUrl(id));

    try {
      const response: AxiosResponse<Notification> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data.message || error.response.data.code;
      }
      throw error.message;
    }
  }

  async readNotification(): Promise<ReadNotificationResponse> {
    const request = this.makeRequest('PUT', this.readNotificationUrl());

    try {
      const response: AxiosResponse<ReadNotificationResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data.message || error.response.data.code;
      }
      throw error.message;
    }
  }

  async updateNotification(form: Notification): Promise<UpdateNotificationResponse> {
    const request = this.makeRequest('PUT', this.detailNotificationUrl(form.id), form);

    try {
      const response: AxiosResponse<UpdateNotificationResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data.message || error.response.data.code;
      }
      throw error.message;
    }
  }

  async pushNotification(payload: PushNotificationPayload): Promise<PushNotificationResponse> {
    const request = this.makeRequest('POST', this.pushNotificationUrl(), payload);

    try {
      const response: AxiosResponse<PushNotificationResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data.message || error.response.data.code;
      }
      throw error.message;
    }
  }

  async pushEvents(payload: PushEventsPayload): Promise<PushEventsResponse> {
    const request = this.makeRequest('POST', this.pushEventsUrl(), payload);

    try {
      const response: AxiosResponse<PushEventsResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data.message || error.response.data.code;
      }
      throw error.message;
    }
  }
}

export default PartnershipClient;
