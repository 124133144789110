import * as React from 'react';
import styled from 'styled-components';
import COLOR, { transparent } from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

interface Option {
  text: string;
  value: string;
}

interface SearchBarProps{
  label: string;
  onChange?: (value: string) => void;
  onTextChange?: (value: string) => void;
  options?: Option[];
  placeholder?: string;
  value?: string;
  required?: boolean;
}

interface SearchBarState{
  isFocus: boolean;
  selectedText: string;
  text: string;
}

interface InputContainerProps {
  isEmpty?: boolean;
}

class SearchBar extends React.Component<SearchBarProps, SearchBarState> {
  constructor(props: SearchBarProps) {
    super(props);
    this.state = {
      isFocus: false,
      text: '',
      selectedText: '',
    };
  }

  onBlur = (): void => {
    this.setState({
      isFocus: false,
    });
  }

  onFocus = (): void => {
    this.setState({
      isFocus: true,
    });
  }

  onTextChange = (event:  React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ text: event.target.value });
    this.props.onTextChange && this.props.onTextChange(event.target.value);
  }

  onAutoCompleteItemPress = (text: string): void => {
    const { options, onChange } = this.props;

    const matchedOption = options.find(option => option.text === text);

    if (matchedOption) {
      onChange(matchedOption.value);
      this.setState({ text: matchedOption.text });
    } else {
      onChange(null);
      this.setState({ text: '' });
    }

    this.onBlur();
  };

  onIconClick = (): void => {
    if (this.state.text !== '') {
      this.props.onChange(null);
      this.setState({
        text: '',
      });
    }
  };

  render(): React.ReactNode {
    const { label, required, placeholder, options} = this.props;
    const { text, isFocus } = this.state;

    const filteredOptions = options
      .filter(option => option.text.toLowerCase().includes(this.state.text.toLowerCase()));

    return (
      <>
        {label &&
          <Label>
            {label} {required && <RedSpan>*</RedSpan>}
          </Label>
        }
        <InputContainer isEmpty={this.state.text === ''}>
          <Input
            onChange={this.onTextChange}
            placeholder={placeholder}
            onFocus={this.onFocus}
            value={this.state.text}
          />
          <span onClick={this.onIconClick}>
            <Icon
              icon={this.state.text === '' ? faSearch : faTimes}
            />
          </span>
          {isFocus && options.length > 0 && text !== '' &&
            <AutoCompleteContainer>
              {filteredOptions.map(data =>
                <AutoCompleteItem
                  key={data.value}
                  onClick={(): void => this.onAutoCompleteItemPress(data.text)}
                >
                  {data.text}
                </AutoCompleteItem>
              )}
              {filteredOptions.length === 0 && <AutoCompleteItem>No item available</AutoCompleteItem>}
            </AutoCompleteContainer>
          }
        </InputContainer>
      </>
    );
  }
}

const InputContainer = styled.div<InputContainerProps>`
  width: 15rem;
  padding: 0.25rem;
  border: 0.1rem solid  ${(props): string => props.isEmpty ? COLOR.red : COLOR.grey};
  border-radius: 5px;
  flex-direction: row;
  margin: 0.25rem 0.5rem 1rem 0.5rem;

  @media (max-width: 769px) {
    width: 100%;
    margin: 0;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${COLOR.grey};
  padding-right: 0.25rem;
  padding-left: 0.25rem;
`;

const Input = styled.input`
  background-color: ${COLOR.white};
  width: 85%;
  height: 1rem;
  border: none;
  font-size: 0.75rem;
  padding-left: 0.4rem;
`;

const RedSpan = styled.span`
  color: ${COLOR.red};
`;

const AutoCompleteContainer = styled.div`
  position: absolute;
  background-color: ${COLOR.white};
  min-width: 14rem;
  box-shadow: 0px 8px 16px 0px ${transparent('black', 0.2)};
  padding: 0.5rem;
  z-index: 99;
`;

const AutoCompleteItem = styled.div`
  &:hover {
    background-color: ${COLOR.blue};
    color: ${COLOR.white};
  }
  padding: 0.5rem;
  font-size: 0.75rem;
  color: ${COLOR.black};
`;

const Label = styled.div`
  color: ${COLOR.black};
  padding-left: 0.5rem;
  font-size: 0.8175rem;
  font-weight: 500;
`;

export default SearchBar;