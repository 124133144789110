import { GeoData, SavedGeoData } from 'models/geoService';
import { MarineLocation } from 'models/marineLocation';
import {
  GeoServiceActionTypes,
  RECEIVE_GEODATAS,
  RECEIVE_SAVED_GEODATAS,
  RECEIVE_SAVED_GEODATA,
  RECEIVE_MARINE_LOCATION_DATAS,
  RECEIVE_MARINE_LOCATION_DETAIL,
} from './geoServiceTypes';

export function receiveGeoDatas(geoDatas: GeoData[]): GeoServiceActionTypes {
  return {
    type: RECEIVE_GEODATAS,
    geoDatas,
  };
}

export function receiveSavedGeoDatas(
  savedGeoDatas: SavedGeoData[]
): GeoServiceActionTypes {
  return {
    type: RECEIVE_SAVED_GEODATAS,
    savedGeoDatas,
  };
}

export function receiveSavedGeoData(savedGeoData: SavedGeoData): GeoServiceActionTypes {
  return {
    type: RECEIVE_SAVED_GEODATA,
    savedGeoData,
  };
}

export function receiveMarineLocationDatas(
  marineLocationDatas: MarineLocation[]
): GeoServiceActionTypes {
  return {
    type: RECEIVE_MARINE_LOCATION_DATAS,
    marineLocationDatas,
  };
}

export function receiveMarineLocationDetail(
  marineLocationDetail: MarineLocation
): GeoServiceActionTypes {
  return {
    type: RECEIVE_MARINE_LOCATION_DETAIL,
    marineLocationDetail,
  };
}
