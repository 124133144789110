import * as React from 'react';
import { Description, Stack } from '../styles';
import { Task } from 'models/task';
import { Typography } from 'components';

import DateHelper from 'utils/dateHelper';
import STATUS from 'constants/taskStatus';
import TranslationHelper from 'utils/translationHelper';
import styled from 'styled-components';
import COLOR from 'constants/color';
import StatusBadge from 'components/statusBadge';

interface TableProps {
  size?: 'big' | 'small';
}

interface Props {
  task: Task;
  ops?: boolean;
}

interface Description {
  [key: string]: string;
}

const DESCRIPTION: Description = {
  created: 'Order is created.',
  updated: 'Order has been edited.',
  pending_broadcast: 'Order has been received and will be processed shortly.',
  broadcasting: 'Order is being broadcasted to drivers and is pending acceptance.',
  accepted: 'Order has been confirmed and pickup will be as scheduled.',
  pending_pickup: 'Driver is on the way to the pickup location.',
  pickup_arriving:
    'Driver is nearby pickup location. Order is about to be picked by Driver.',
  pending_delivery: 'Order is picked up by Driver and on the way to delivery location.',
  delivery_arriving:
    'Driver is nearby delivery location. Order is about to be delivered by Driver.',
  complete: 'Order is released from Driver. Delivery is complete.',
  unsuccessful_delivery: 'Order cannot be delivered by Driver due to #{reason}.',
  unsuccessful_pickup: 'Order cannot be collected by Driver due to #{reason}.',
  cancelled: 'Order is cancelled due to #{reason}.',
  expired: 'The order broadcast has expired.',
};

function renderActivityEntry(
  history: {
    set_at_utc?: Date;
    status: string;
    custom_info?: { question: string; answer: string }[];
    reason?: string;
    driver_location?: {
      altitude: number;
      latitude: number;
      longitude: number;
      speed: number;
    } | null;
  },
  index: number
): React.ReactNode {
  const reasonInfo =
    history.custom_info && history.custom_info.find((info) => info.question === 'Reason');

  return (
    <Stack
      key={index}
      direction="column"
      gap="0.35rem"
      style={{
        marginBottom: '1rem',
      }}
    >
      <Typography as="span" size="xs" color="gray_400">
        {history.set_at_utc && DateHelper.formatDateSimpleMonthTime(history.set_at_utc)}
        {history.driver_location && ' | '}
        {history.driver_location && (
          <CustomLink
            target="_blank"
            href={`https://www.google.com/search?q=${history.driver_location.latitude},${history.driver_location.longitude}`}
          >
            Locate Driver
          </CustomLink>
        )}
      </Typography>
      <Typography as="span" size="xs">
        <StatusBadge status={history.status}>{STATUS[history.status]}</StatusBadge>
      </Typography>
      <Typography as="p" color="gray_600">
        {history.status === 'cancelled' &&
          TranslationHelper.replaceTag(DESCRIPTION[history.status], {
            reason: history.reason,
          })}
        {(history.status === 'unsuccessful_pickup' ||
          history.status === 'unsuccessful_delivery') &&
          TranslationHelper.replaceTag(DESCRIPTION[history.status], {
            reason: reasonInfo && reasonInfo.answer,
          })}
        {history.status !== 'cancelled' &&
          history.status !== 'unsuccessful_pickup' &&
          history.status !== 'unsuccessful_delivery' &&
          DESCRIPTION[history.status]}
      </Typography>
    </Stack>
  );
}

const OrderActivityCard = ({ task }: Props): JSX.Element => {
  return (
    <>
      <Typography
        as="h3"
        size="lg"
        color="gray_900"
        weight="semibold"
        customStyle={{
          marginBottom: '1rem',
        }}
      >
        Order Activity
      </Typography>
      <ActivityTable>
        <tbody>
          {task &&
            task.statuses
              .slice()
              .reverse()
              .map((history, index) => renderActivityEntry(history, index + 1))}
          {task &&
            renderActivityEntry({ set_at_utc: task.created_at, status: 'created' }, 0)}
        </tbody>
      </ActivityTable>
    </>
  );
};

export const ActivityTable = styled.table`
  border-collapse: collapse;
`;

export const ActivityTableRow = styled.tr`
  vertical-align: top;
`;

export const ActivityTableEntry = styled.th<TableProps>`
  text-align: left;
  font-weight: 500;
  padding-right: ${(props): string => (props.size === 'big' ? '3.125rem' : '2rem')};
  padding-bottom: 1rem;
  color: ${COLOR.black};
  font-size: ${(props): string => (props.size === 'big' ? '1rem' : '0.875rem')};
`;

export const CustomLink = styled.a`
  font-size: 0.75rem;
  text-decoration: underline;
  color: ${COLOR.blue};
  cursor: pointer;
`;

export default OrderActivityCard;
