import * as React from 'react';
import { css } from 'styled-components';
import { RadioButton } from 'components';

interface Props {
  value: string;
  onChange: (v: string) => void;
  disabled?: boolean;
}

export const LocationTypeForm = (props: Props): JSX.Element => {
  return (
    <>
      <RadioButton
        title="Type of location"
        containerStyle={css`
          margin-bottom: 1rem;
        `}
        onChange={(v: string) => {
          !props.disabled && props.onChange(v);
        }}
        options={[
          {
            text: 'Land',
            value: 'land',
          },
          {
            text: 'Sea',
            value: 'sea',
          },
        ]}
        value={props.value}
        disabled={props.disabled}
      />
    </>
  );
};
