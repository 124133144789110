import { createGlobalStyle } from 'styled-components';
import COLOR from 'constants/color';

export default createGlobalStyle`
  * {
    font-family: 'Lexend', sans-serif;
  }
  
  html {
    height: 100%;
  }

  body {
    margin: 0;
    color: ${COLOR.black};
    background-color: ${COLOR.white};
    height: 100%;
  }

  div#gotsurge {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;
