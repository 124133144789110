import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import COLOR from 'constants/color';
import TaskClient from 'httpClients/taskClient';
import { Task } from 'models/task';
import { CurrentUser } from 'models/auth';
import NotFound from 'pages/notFound';
import { logoutUser } from 'utils/userHelper';
import TransportPartnerRedirect from './transportPartnerRedirect';
import { TASK_STATUS } from 'constants/taskStatus';

interface StateProps {
  currentUser: CurrentUser;
  task: Task;
}

interface LocationState {
  from: string;
}

interface RouteMatchParams {
  id: string;
}

type Props = RouteComponentProps<RouteMatchParams, object, LocationState> & StateProps;

interface TrackingState {
  isNotFound: boolean;
  isNotAuthorized: boolean;
  finalUrl: string;
}

class TrackingIDRedirect extends React.Component<Props, TrackingState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isNotFound: false,
      isNotAuthorized: false,
      finalUrl: '',
    };
  }

  componentDidMount(): void {
    this.fetchTaskByTrackingID();
    this.redirectToOrderDetail();
  }

  componentDidUpdate(prevProps: Props): void {
    const { task } = this.props;
    if (prevProps.task !== task) {
      this.redirectToOrderDetail();
    }
  }

  async fetchTaskByTrackingID(): Promise<void> {
    const { id } = this.props.match.params;
    try {
      const client = new TaskClient();
      await client.getTaskByTrackingID(id);
    } catch (error) {
      if (error.includes('401')) {
        this.setState({ isNotAuthorized: true });
      } else {
        this.setState({ isNotFound: true });
      }
    }
  }

  redirectToOrderDetail(): void {
    const { task, currentUser } = this.props;
    const orderID = task && task.id;
    if (orderID === null) {
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    let queryString = '';
    let hasUtmMedium = false;

    // Iterate through all the query parameters
    for (const param of queryParams.entries()) {
      // Append the query parameter to the queryString
      queryString += `${param[0]}=${param[1]}&`;

      if (param[0] === 'utm_medium') {
        hasUtmMedium = true;
      }
    }

    // Set default value to share if didn't exist
    if (!hasUtmMedium) {
      queryString += 'utm_medium=share&';
    }

    // Remove the trailing '&' from the queryString
    queryString = queryString.slice(0, -1);

    // Create the final URL with the orderID and the query parameters
    const isSysAdmin =
      currentUser &&
      currentUser.user.roles.includes('SysAdmin') &&
      currentUser.user.org_id === 'VersaFleet';
    const isTransporter =
      currentUser &&
      currentUser.user.roles.includes('OrgTransporter');

    let finalURL = `/orders/${orderID}?${queryString}`;
    if (isSysAdmin) {
      finalURL = `sys/tasks/${orderID}?${queryString}`;
    }

    if (!isTransporter) {
      // Redirect to the final URL
      window.location.replace(finalURL);
    }

    const runsheetID = task && task.runsheet && task.runsheet.id;
    if (task && task.current_status === TASK_STATUS.BROADCASTING) {
      this.setState({ finalUrl: `/runsheets/${runsheetID}?${queryString}` });
    } else {
      this.setState({ finalUrl: `/orders/${orderID}?${queryString}` });
    }
  }

  reLogin = (): void => {
    logoutUser('Re-login to see this page.', this.props.location.pathname);
    // Redirect to the login page
    this.props.history.push('/login');
  };

  render(): React.ReactNode {
    const { currentUser } = this.props;
    const { isNotFound, isNotAuthorized, finalUrl } = this.state;

    if (isNotAuthorized) {
      this.reLogin();
    }

    if (isNotFound) {
      return <NotFound />;
    }

    const isTransporter =
      currentUser &&
      currentUser.user.roles.includes('OrgTransporter');

    if (!finalUrl || finalUrl === '') {
      return (
        <CenteredContainer>
          <IconContainer>
            <Icon icon={faCircleNotch} className="fa-spin" />
          </IconContainer>
        </CenteredContainer>
      );
    }

    return (
      <>
        {isTransporter ? (
          <TransportPartnerRedirect finalUrl={finalUrl} />
        ) : false
        }
      </>
    );
  }
}

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const IconContainer = styled.div`
  margin-right: 0.5rem;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${COLOR.midDarkGrey};
  font-size: 2rem;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.auth.currentUser,
  task: state.task.task,
});

export default connect(mapStateToProps)(TrackingIDRedirect);