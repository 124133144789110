import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { StyledButton } from 'components';
import COLOR from 'constants/color';
import { Link } from 'react-router-dom';

interface IconProps {
  color: string;
}

interface InfoTableProps {
  size?: 'big' | 'small';
}

interface ColumnProps {
  columnStyle?: FlattenSimpleInterpolation;
  flex: string;
}

export const Card = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${COLOR.neutral};
  padding: 1rem;
  background-color: ${COLOR.white};
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

export const CustomCard = styled(Card)`
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: ${COLOR.white};
  margin-right: 0;
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  };
`;

export const CardRow = styled.div`
  display: flex;

  & > * {
    margin-right: 1rem;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
`;

export const Icon = styled(FontAwesomeIcon) <IconProps>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.375rem;
`;

export const InfoTable = styled.table`
  border-collapse: collapse;
`;

export const InfoTableRow = styled.tr`
  vertical-align: top;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const InfoTableEntry = styled.th<InfoTableProps>`
  text-align: left;
  font-weight: 500;
  padding-right: ${(props): string => props.size === 'big' ? '3.125rem' : '2rem'};
  padding-bottom: 1rem;
  color: ${COLOR.darkGray};
`;

export const Description = styled.div`
  font-size: 0.875rem;
`;

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props): string => props.flex};

  ${(props): FlattenSimpleInterpolation => props.columnStyle}
`;

export const Container = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const CardContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  color: ${COLOR.darkGray};
`;

export const CancelButton = styled(StyledButton)`
  margin-left: 0.5rem;
`;

export const Prompt = styled.p`
  margin-bottom: 1.5rem;
`;

export const Content = styled.div<{ contentStyle?: FlattenSimpleInterpolation }>`
  flex: 2;
  padding: 1.5rem;
  padding-right: 0rem;

  & > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  ${(props): FlattenSimpleInterpolation => props.contentStyle};
`;

export const Separator = styled.hr`
  margin-bottom: 0rem;
  border-top: 0.1rem solid ${COLOR.neutral};
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  margin-top: 0rem;
`;

export const StyledLink = styled(Link)`
  color: ${COLOR.blue};
  cursor: pointer;
  text-decoration: none;
`;

export const LogInfoDetail = styled.span`
  padding-right: 1.5rem;
`;

export const LogInfoBox = styled.div`
  padding: 2px;
  background-color: rgba(238,238,238,1);
  border-radius: 5px;
  padding: 0.375rem 0.5rem;
`;

export const DetailLog = styled.pre`
  font-size: 0.75rem;
  max-width: 520px;
  width: 100%;
  overflow: scroll;
`;

export const ActionButton = styled(StyledButton)`
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
`;

export const ActionButtonsContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  height: 2.2rem;
`;