import {
  SIGNIN_SUCCESS,
  SIGNOUT_SUCCESS,
  RECEIVE_USERS,
  RECEIVE_USER,
  SET_AFTER_LOGIN_PAGE,
  SigninSuccessAction,
  SignoutSuccessAction,
  ReceiveUsersAction,
  ReceiveUserAction,
  SetAfterLoginPageAction,
} from './authTypes';
import { SigninResponse, GetUserListResponse, UserWithTimestamp } from 'models/auth';

export function receiveSigninSuccess(data: SigninResponse): SigninSuccessAction {
  return {
    type: SIGNIN_SUCCESS,
    payload: {
      bearerToken: data.bearer_token,
      user: data.user,
    },
  };
}

export function signoutSuccess(promptMessage = '', afterLoginPage = ''): SignoutSuccessAction {
  return {
    type: SIGNOUT_SUCCESS,
    promptMessage,
    afterLoginPage,
  };
}

export function receiveUsers(data: GetUserListResponse): ReceiveUsersAction {
  return {
    type: RECEIVE_USERS,
    users: data.users,
  };
}

export function receiveUser(data: UserWithTimestamp): ReceiveUserAction {
  return {
    type: RECEIVE_USER,
    user: data,
  };
}

export function setAfterLoginPage(afterLoginPage: string): SetAfterLoginPageAction {
  return {
    type: SET_AFTER_LOGIN_PAGE,
    afterLoginPage,
  };
}