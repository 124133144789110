import * as React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';
import Divider from './divider';
import { Driver } from 'models/driver';
import { Status } from 'models/task';
import { format } from 'date-fns';

interface CompletionInfoProps {
  status: Status;
  driver: Driver;
  country: string;
}

const CompletionInfo = ({ driver, country, status }: CompletionInfoProps) => (
  <View
    break
    style={{
      flexDirection: 'column',
      marginBottom: 16,
      width: '100%',
    }}
  >
    <Text style={[styles.titleSmall, { marginBottom: 8 }]}>
      Completed on {format(new Date(status?.set_at_utc), 'dd MMM yyyy, HH:mm')}
    </Text>
    <Divider />
    <Text style={[styles.textMedium, { marginTop: 6, marginBottom: 12 }]}>
      {driver?.name ?? '-'}
    </Text>
    <Text style={styles.titleSmall}>Location</Text>
    <Text style={[styles.textMedium]}>{country ?? 'Singapore'}</Text>

    {status.status === 'complete' && status.remarks && (
      <>
        <Text style={[styles.titleSmall, { marginTop: 12 }]}>Remarks</Text>
        <Text style={styles.textMedium}>{status.remarks}</Text>
      </>
    )}
  </View>
);

export default CompletionInfo;
