import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

import {
  faCheckCircle,
  faTimesCircle,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

interface VerticalTimelineProps {
  type: 'pickup' | 'delivery';
  currentStatus: string;
  hasNextLocation: boolean;
  style?: React.CSSProperties;
}

const timelineColorstyles = {
  pickupTypeToPickup: css`
    color: ${(props): string => props.theme.colors.warning_600};
    background-color: ${(props): string => props.theme.colors.gray_200};
  `,
  pickupTypeFailPickup: css`
    color: ${(props): string => props.theme.colors.error_600};
    background-color: ${(props): string => props.theme.colors.gray_200};
  `,
  pickupTypeToDeliver: css`
    color: ${(props): string => props.theme.colors.success_600};
    background-color: ${(props): string => props.theme.colors.success_600};
  `,
  deliverTypeToDeliver: css`
    color: ${(props): string => props.theme.colors.warning_600};
  `,
  deliveryTypeFailDeliver: css`
    color: ${(props): string => props.theme.colors.error_600};
  `,
  deliveryTypeComplete: css`
    color: ${(props): string => props.theme.colors.success_600};
  `,
  defaultColor: css`
    color: ${(props): string => props.theme.colors.gray_200};
    background-color: ${(props): string => props.theme.colors.gray_200};
  `,
};

const HorizontalTimeline = (props: VerticalTimelineProps): JSX.Element => {
  const { type, currentStatus, hasNextLocation } = props;
  let timelineColor = timelineColorstyles.defaultColor;
  let timelineIcon = faMapMarkerAlt;
  if (['pending_pickup', 'pickup_arriving'].includes(currentStatus)) {
    if (type === 'pickup') {
      timelineColor = timelineColorstyles.pickupTypeToPickup;
      timelineIcon = faMapMarkerAlt;
    }
  } else if (['pending_delivery', 'delivery_arriving'].includes(currentStatus)) {
    if (type === 'delivery') {
      timelineColor = timelineColorstyles.deliverTypeToDeliver;
      timelineIcon = faMapMarkerAlt;
    } else if (type === 'pickup') {
      timelineColor = timelineColorstyles.pickupTypeToDeliver;
      timelineIcon = faCheckCircle;
    }
  } else if (currentStatus === 'unsuccessful_pickup') {
    if (type === 'delivery') {
      timelineColor = timelineColorstyles.defaultColor;
      timelineIcon = faMapMarkerAlt;
    } else if (type === 'pickup') {
      timelineColor = timelineColorstyles.pickupTypeFailPickup;
      timelineIcon = faTimesCircle;
    }
  } else if (currentStatus === 'unsuccessful_delivery') {
    if (type === 'delivery') {
      timelineColor = timelineColorstyles.deliveryTypeFailDeliver;
      timelineIcon = faTimesCircle;
    } else if (type === 'pickup') {
      timelineColor = timelineColorstyles.pickupTypeToDeliver;
      timelineIcon = faCheckCircle;
    }
  } else if (currentStatus === 'complete') {
    if (type === 'delivery') {
      timelineColor = timelineColorstyles.deliveryTypeComplete;
      timelineIcon = faCheckCircle;
    } else if (type === 'pickup') {
      timelineColor = timelineColorstyles.pickupTypeToDeliver;
      timelineIcon = faCheckCircle;
    }
  }
  return (
    <HorizontalTimelineContainer
      $timelineColor={timelineColor}
      hasNextLocation={hasNextLocation}
      style={props.style}
    >
      <Icon $timelineColor={timelineColor} icon={timelineIcon} fixedWidth={true} />
    </HorizontalTimelineContainer>
  );
};

interface AddressLineProps {
  $timelineColor: FlattenInterpolation<ThemeProps<DefaultTheme>>;
}

interface HorizontalTimelineContainerProps extends AddressLineProps {
  hasNextLocation?: boolean;
}

const HorizontalTimelineContainer = styled.div<HorizontalTimelineContainerProps>`
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: nowrap;

  ${({
    hasNextLocation,
    $timelineColor,
  }): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    hasNextLocation &&
    css`
      &::after {
        content: '';
        margin-top: 0.25rem;
        width: 91%;
        height: 0.125rem;
        border-radius: 3rem;
        transition: all 0.35s;
        align-self: center;
        margin-left: 0.25rem;
        ${$timelineColor}
      }
    `}
`;

const Icon = styled(FontAwesomeIcon)<AddressLineProps>`
  ${({ $timelineColor }): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    $timelineColor}
  text-align: center;
  font-size: 22px; // cannot using rem unit due to incosistent size
  background-color: transparent;
`;

export default HorizontalTimeline;
