import * as React from 'react';
import { RootState } from 'reduxActions/store';
import * as H from 'history';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { Table, Button, TableCellRenderEvent, SysAdminPagination, Dropdown } from 'components';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { Link } from 'react-router-dom';
import { ContractPricingBasic } from 'models/contractPricing';
import ContractPricingClient from 'httpClients/contractPricingClient';
import { Organization } from 'models/organization';
import configureStore from 'reduxActions/store';
import { receiveOrganizations } from 'reduxActions/organization/organizationActions';

interface StateProps {
  models: ContractPricingBasic[];
  organizations: Organization[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type SysContractPricingIndexProps = HistoryProps & StateProps;

class SysContractPricingIndex extends SysAdminPagination<ContractPricingBasic, SysContractPricingIndexProps> {
  constructor(props: SysContractPricingIndexProps) {
    super(props);

    this.state = {
      ...this.state,
      filters: this.getDefaultFilter(),
      basePath: '/sys/contract-pricings',
      pluralModelName: 'contract-pricings',
      selectedTab: 'contract-pricings',
    };

    this.rules = [ ...this.rules, {
      name: 'org_id',
      validate: (param: string): boolean => {
        const { organizations } = this.props;
        const orgIds = organizations.map(data => data.id);

        return organizations.length === 0 || orgIds.includes(param);
      },
    }];
  }

  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
    order: 'desc',
  })

  componentDidMount(): void {
    super.componentDidMount();
    this.fetchOrganizations();
  }

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new ContractPricingClient();
    await client.sysGetContractPricings(new URLSearchParams(this.state.filters));

    this.setState({ isFetching: false });
  }

  fetchOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(new URLSearchParams({ page: pageNum.toString() }));
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch(e) {
      //
    }
  }

  renderFilter = (): React.ReactNode => (
    <>
      <Dropdown
        label="Sort by"
        options={[
          {
            value: 'desc',
            name: 'Newest First',
          }, {
            value: 'asc',
            name: 'Oldest First',
          },
        ]}
        onChange={(value: string): void => { this.onFilterChange('order', value); }}
        value={this.state.filters.order}
      />
      <Dropdown
        label="Organization"
        options={this.props.organizations.map(org => (
          {
            value: org.id,
            name: org.business_name,
          }
        ))}
        includeAll
        onChange={(value: string): void => {
          const isDelete = value === '';
          this.onFilterChange('org_id', value, isDelete);
        }}
        value={this.state.filters.org_id || ''}
        withSearch
        searchPlaceholder="Find Organisation..."
        sorted
      />
    </>
  )

  renderCell = (e: TableCellRenderEvent<{ action: string }>): React.ReactNode => {
    if (e.key === 'action') {
      return (
        <div>
          <Link to={`/sys/contract-pricings/${e.value.toString()}`}>
            <Button>Detail</Button>
          </Link>
        </div>
      );
    }

    return <>{e.value}</>;
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      id: 'ID',
      currency: 'Currency',
      is_active: 'Is Active',
      total_price_tables: 'Total Price Tables',
      is_valid_from: 'Valid From',
      is_valid_to: 'Valid To',
      action: 'Actions',
    };

    const data = models.map(contractPricing => ({
      id: contractPricing.id,
      currency: contractPricing.currency,
      is_active: contractPricing.is_active,
      total_price_tables: contractPricing.total_price_tables,
      is_valid_from: moment(contractPricing.is_valid_from).format('DD MMM YYYY, hh:mm a'),
      is_valid_to: moment(contractPricing.is_valid_to).format('DD MMM YYYY, hh:mm a'),
      action: contractPricing.id,
    }));

    return (
      <Table
        columns={columns}
        data={data}
        cellRenderer={this.renderCell}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  models: state.contractPricing.contractPricings,
  organizations: state.organization.organizations,
});

export default connect(mapStateToProps)(SysContractPricingIndex);