import * as React from 'react';
import { format } from 'date-fns';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';
import { Address, Contact, TimeWindow } from 'models/task';
import { formatAddress } from 'utils/formatter';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';

interface DeliveryDetailProps {
  broadcastPreference: string;
  toAddress: Address;
  toTimeWindow: TimeWindow;
  toContact: Contact;
}

const DeliveryDetail = ({
  broadcastPreference,
  toAddress,
  toTimeWindow,
  toContact,
}: DeliveryDetailProps) => {
  const isMarine = broadcastPreference === BROADCAST_PREFERENCES.marine;
  const isBuildingNameExist = !!toAddress.building_name;
  return (
    <View
      style={{
        flexDirection: 'column',
        marginBottom: 16,
      }}
    >
      <Text style={styles.titleSmall}>Delivery Address, Delivery Date & Time</Text>
      {isBuildingNameExist && (
        <Text style={[styles.textMedium, styles.spaceSmallBottom]}>
          {toAddress.building_name}
        </Text>
      )}
      <Text style={[styles.textMedium, { maxWidth: 220, lineHeight: 1.5 }]}>
        {formatAddress(toAddress, true)}
      </Text>
      <Text style={[styles.textMedium, styles.spaceSmallBottom, { marginTop: 0.3 }]}>
        {format(new Date(toTimeWindow.start_time_utc), 'dd MMM yyyy, HH:mm')}
      </Text>
      {isMarine && (
        <Text style={[styles.textMedium, { maxWidth: 220, lineHeight: 1.5 }]}>
          Berth No. : {toContact.berth_no ?? '-'}
        </Text>
      )}
    </View>
  );
};

export default DeliveryDetail;
