import * as React from 'react';
import * as H from 'history';
import {
  Separator as BasicSeparator,
  Heading,
  PaginationState,
  Breadcrumb,
  PageTitle,
  FieldView,
  Separator,
  StyledButton,
  SysAdminMainContainer,
} from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import COLOR from 'constants/color';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import configureStore, { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import SettingSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import { GeoData, SavedGeoData } from 'models/geoService';
import GeoServiceClient from 'httpClients/geoServiceClient';
import { Address, Contact } from 'models/task';
import { receiveGeoDatas } from 'reduxActions/geoService/geoServiceActions';
import { formatAddress } from 'utils/formatter';
import { Organization } from 'models/organization';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';

interface SavedAddressDetailProps {
  models: SavedGeoData;
  geoDatas: GeoData[];
  organization: Organization;
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface SavedAddressState extends PaginationState<SavedGeoData> {
  form: SavedGeoData;
  contact: Contact;
}

type Props = RouteComponentProps<{ id: string; addressId: string }> &
  SavedAddressDetailProps &
  HistoryProps;

class SysAdminSavedAddressDetail extends React.Component<Props, SavedAddressState> {
  searchPickupTimeout: number | null | NodeJS.Timeout;
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      form: this.getDefaultForm(),
    };
  }

  getDefaultForm(): SavedGeoData {
    const savedAddress = this.props.models;
    if (savedAddress) {
      return {
        id: savedAddress && savedAddress.id,
        name_address: savedAddress && savedAddress.name_address,
        building_name: savedAddress && savedAddress.building_name,
        unit_number: savedAddress && savedAddress.unit_number,
        street_address: savedAddress && savedAddress.street_address,
        city: savedAddress && savedAddress.city,
        state: savedAddress && savedAddress.state,
        country: savedAddress && savedAddress.country,
        zip_code: savedAddress && savedAddress.zip_code,
        lat: savedAddress && savedAddress.lat,
        lng: savedAddress && savedAddress.lng,
        source: savedAddress && savedAddress.source,
        source_reference: savedAddress && savedAddress.source_reference,
        contact_name: savedAddress && savedAddress.contact_name,
        contact_phone: savedAddress && savedAddress.contact_phone,
        contact_email: savedAddress && savedAddress.contact_email,
        is_default_pickup: savedAddress && savedAddress.is_default_pickup,
        is_default_delivery: savedAddress && savedAddress.is_default_delivery,
      };
    }
  }

  componentDidMount(): void {
    this.fetchData();
  }

  componentDidUpdate(prevProps: SavedAddressDetailProps): void {
    if (prevProps.models !== this.props.models) {
      this.setState({
        form: this.getDefaultForm(),
      });
    }
  }

  defaultFilter = (): Record<string, string> => ({
    page: '1',
  });

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const orgClient = new OrganizationManagementClient();
    await orgClient.sysAdminGetOrganization(this.props.match.params.id);

    const savedAddressClient = new GeoServiceClient();
    await savedAddressClient.getSysSavedAddressDetail(this.props.match.params.addressId);

    this.setState({ isFetching: false });
  };

  onSelectAddress = (geoData: GeoData): void => {
    this.selectAddress(geoData.street_address, geoData);
  };

  selectAddress = (streetAddress: string, geoData: GeoData): void => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        street_address: streetAddress,
        city: geoData.city,
        state: geoData.state,
        country: geoData.country,
        zip_code: geoData.zip_code,
        lat: geoData.lat,
        lng: geoData.lng,
        source: geoData.source,
        source_reference: geoData.source_reference,
      },
    }));
  };

  fetchAddresses = async (searchKey: string): Promise<void> => {
    this.clearAddresses();
    if (searchKey && searchKey.length >= 3) {
      const client = new GeoServiceClient();
      await client.getGeoCode(searchKey);
    }
  };

  isLocationValid = (geoData: GeoData): boolean => {
    return (
      geoData.street_address !== '' &&
      geoData.lat !== 0 &&
      geoData.lng !== 0 &&
      geoData.lat !== undefined &&
      geoData.lng !== undefined
    );
  };

  isAddressLocationValid = (address: Address): boolean =>
    this.isLocationValid({
      street_address: address.street_address,
      zip_code: address.zip_code,
      lat: address.latitude,
      lng: address.longitude,
    });

  clearAddresses = (): void => {
    const store = configureStore();
    store.dispatch(receiveGeoDatas([]));
  };

  clearAddress = (): void => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        id: '',
        name_address: '',
        building_name: '',
        street_address: '',
        city: '',
        state: '',
        country: '',
        zip_code: '',
        lat: 0,
        lng: 0,
      },
    }));
  };

  searchAddress = (): void => {
    clearTimeout(this.searchPickupTimeout);

    this.searchPickupTimeout = setTimeout(() => {
      this.fetchAddresses(this.state.form.building_name);
    }, 700);
  };

  renderContent = (): React.ReactNode => {
    const { form } = this.state;

    return (
      <>
        <PersonalInfoColumn>
          <Header>Address Details</Header>
          <FieldView fieldName="Name of Address">{form && form.name_address}</FieldView>
          {form.is_default_pickup && <DefaultSavedAddress>Pickup</DefaultSavedAddress>}
          {form.is_default_delivery && (
            <DefaultSavedAddress>Delivery</DefaultSavedAddress>
          )}
          <FieldView fieldName="Postal Code / Street Address">
            {form && formatAddress(form, false)}
          </FieldView>
          <FieldView fieldName="Unit No.">{form && form.unit_number}</FieldView>
        </PersonalInfoColumn>
        <Separator />
        <PersonalInfoColumn>
          <Header>Contact Details</Header>
          <FieldView fieldName="Name">{form && form.contact_name}</FieldView>
          <FieldView fieldName="Contact No.">{form && form.contact_phone}</FieldView>
          <FieldView fieldName="Email">{form && form.contact_email}</FieldView>
        </PersonalInfoColumn>
      </>
    );
  };

  render(): React.ReactNode {
    const { form } = this.state;
    const { id, addressId } = this.props.match.params;
    return (
      <SysAdminMainContainer selected="setting">
        <Breadcrumb
          items={[
            {
              text: 'Organizations',
              onClick: (): void => {
                this.props.history.push('/sys/organizations');
              },
            },
            {
              text: id,
              onClick: (): void => {
                this.props.history.push('/sys/organizations/' + id + '/company-details');
              },
            },
            { text: 'Saved Addresses' },
          ]}
        />
        <Heading>
          <PageTitle>Setting</PageTitle>
        </Heading>
        <SettingSidebarMenu selected={`/sys/organizations/${id}/saved-address`}>
          <Heading>
            <PageTitle>{form && form.name_address}</PageTitle>
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={(): void => {
                this.props.history.push(
                  `/sys/organizations/${id}/saved-address/${addressId}/edit`
                );
              }}
            >
              <Icon icon={faEdit} color={COLOR.black} />
              Edit
            </StyledButton>
          </Heading>
          <CustomSeparator />
          {this.renderContent()}
        </SettingSidebarMenu>
      </SysAdminMainContainer>
    );
  }
}

const CustomSeparator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
`;

const Icon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;
`;

const Header = styled.div`
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
`;

export const CustomCard = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${COLOR.neutral};
  padding: 1rem;
  background-color: ${COLOR.white};
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PersonalInfoColumn = styled(Column)`
  flex: 1;
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const DefaultSavedAddress = styled.span`
  background-color: ${COLOR.whiteGrey};
  border-radius: 0.375rem;
  width: fit-content;
  padding: 0.2rem 0.25rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
`;

function mapStateToProps(state: RootState): SavedAddressDetailProps {
  return {
    models: state.geoService.savedGeoData,
    geoDatas: state.geoService.geoDatas,
    organization: state.organization.organization,
  };
}

export default connect(mapStateToProps)(SysAdminSavedAddressDetail);
