import * as React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { FontAwesomeIcon, Props } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import Input, { HEIGHTS, WIDTHS, StyledInputProps } from './basicTextInput';

interface TextInputProps extends StyledInputProps {
  containerStyle?: FlattenSimpleInterpolation;
  iconStyle?: React.CSSProperties;
  fieldName?: string | React.ReactNode;
  fieldDescription?: string | React.ReactNode;
  icon?: IconDefinition;
  iconOnClick?: () => void;
  isRequired?: boolean;
  leftLabel?: React.ReactNode;
  extraLabel?: React.ReactNode;
  onTextChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: keyof typeof WIDTHS;
  height?: keyof typeof HEIGHTS;
  inputName?: string;
  noWrapLabel?: boolean;
}

interface ContainerProps {
  containerStyle?: FlattenSimpleInterpolation;
  width?: keyof typeof WIDTHS;
}

interface IconProps extends Props {
  iconStyle?: React.CSSProperties;
  onClick?: () => void;
}

// TODO: Update the component to be more general and follow XD design
const TextInput = (props: TextInputProps): JSX.Element => {
  const {
    fieldName,
    fieldDescription,
    isRequired,
    leftLabel,
    extraLabel,
    onTextChange,
    onBlur,
    inputStyle,
    containerStyle,
    iconStyle,
    inputName,
    width,
    noWrapLabel,
    ...inputProps
  } = props;

  // disallowed character for number
  const disallowedNumberChars = ['e', 'E'];

  return (
    <Container containerStyle={containerStyle} width={width}>
      <LabelContainer>
        {fieldName && (
          <InputLabel noWrapLabel={noWrapLabel}>
            {fieldName} {isRequired && <RequiredSpan>*</RequiredSpan>}
            {fieldDescription && (
              <InputDescLabel>{fieldDescription}</InputDescLabel>
            )}
          </InputLabel>
        )}
      </LabelContainer>
      <InputContainer>
        {leftLabel && <InputLeftLabel>{leftLabel}</InputLeftLabel>}
        <Input
          autoComplete={props.autoComplete ? props.autoComplete : 'none'}
          name={inputName}
          inputStyle={inputStyle}
          isRequired={isRequired}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (
              props.type === 'number' &&
              disallowedNumberChars.includes(e.key)
            ) {
              e.preventDefault();
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            onTextChange ? onTextChange(e.target.value, e) : undefined;
          }}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
            onBlur ? onBlur(e) : undefined;
          }}
          {...inputProps}
        />
      </InputContainer>
      {props.icon && (
        <IconContainer>
          <Icon
            iconStyle={iconStyle}
            icon={props.icon}
            onClick={props.iconOnClick}
          />
        </IconContainer>
      )}
      {extraLabel}
    </Container>
  );
};

const Container = styled.div<ContainerProps>`
  margin: 0;
  width: ${(props): string =>
    props.width ? WIDTHS[props.width] : WIDTHS.full};
  ${(props): FlattenSimpleInterpolation => props.containerStyle};

  @media (max-device-width: 700px), (max-width: 700px) {
    width: 100%;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: flex;
  line-height: 3rem;
`;

const InputLeftLabel = styled.label`
  font-size: 0.875rem;
  margin-right: 5px;
  margin-bottom: 0.25rem;
  white-space: nowrap;
`;

const InputLabel = styled.label<{ noWrapLabel?: boolean }>`
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.32px;
  margin-bottom: 0.25rem;
  white-space: ${(props): string => (props.noWrapLabel ? 'nowrap' : 'wrap')};

  @media (max-device-width: 700px), (max-width: 700px) {
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
`;

const InputDescLabel = styled.label`
  font-size: 0.8125rem;
  color: ${COLOR.midDarkGrey};
  line-height: 1.25rem;
  display: block;
`;

const RequiredSpan = styled.span`
  color: ${COLOR.red};
`;

const IconContainer = styled.div`
  position: relative;
`;

const Icon = (props: IconProps): JSX.Element => {
  const { icon, iconStyle, onClick } = props;
  return (
    <span onClick={onClick}>
      <FontAwesomeIcon
        icon={icon}
        style={{
          position: 'absolute',
          top: '-1.75rem',
          right: '1rem',
          ...iconStyle,
        }}
      />
    </span>
  );
};

export default TextInput;
