import * as React from 'react';
import * as H from 'history';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { SysAdminPagination, Table } from 'components';
import { RootState } from 'reduxActions/store';
import DriverProfileClient from 'httpClients/driverProfileClient';
import { DriverProfile } from 'models/driverProfile';

interface StateProps {
  models: DriverProfile[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type SysAdminDriverIndexProps = HistoryProps & StateProps;

class SysAdminDriverIndex extends SysAdminPagination<DriverProfile, SysAdminDriverIndexProps> {
  constructor(props: SysAdminDriverIndexProps) {
    super(props);
    this.state = {
      ...this.state,
      basePath: '/sys/drivers',
      pluralModelName: 'drivers',
      selectedTab: 'drivers',
    };
  }

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new DriverProfileClient();
    await client.getDriverProfiles(new URLSearchParams(this.state.filters));

    this.setState({ isFetching: false });
  }

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      id: 'Driver ID',
      name: 'Name',
      isAvailable: 'Availability',
      status: 'Status',
      type: 'Type',
      lastActive: 'Last Active',
    };

    const data = models.map(profile => {
      let status = 'Unverified';

      if (profile.deleted_at && profile.deleted_at != null) {
        status = 'Deleted';
      } else if (profile.last_suspended_until && new Date(profile.last_suspended_until) > new Date()) {
        status = 'Suspended';
      } else if (profile.driver_verified_at && new Date(profile.driver_verified_at) < new Date()) {
        status = 'Verified';
      }

      return ({
        id: profile.id,
        name: profile.name,
        isAvailable: profile.is_available ? 'Available' : 'Not Available',
        status,
        type: profile.org_id !== 'FreelanceDriver' ? 'Professional' : 'Freelance',
        lastActive: moment(profile.last_active_at).format('DD MMM YYYY, hh:mm a'),
      });
    });

    return (
      <Table
        columns={columns}
        data={data}
        rowOnClick={(id: string): void => { this.props.history.push('/sys/drivers/' + id); }}
      />
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    models: state.driverProfile.profiles,
  };
}

export default connect(mapStateToProps)(SysAdminDriverIndex);