import {
  RECEIVE_DRIVER_PROFILES, RECEIVE_DRIVER_PROFILE, RECEIVE_DRIVER_PROFILES_WITH_SQUAD,
  ReceiveDriverProfiles, ReceiveDriverProfile, ReceiveDriverProfileWithSquad,
} from './driverProfileTypes';
import { MetaDriverProfiles, DriverProfile, DriverProfileWithSquad } from 'models/driverProfile';

export function receiveDriverProfile(profile: DriverProfile): ReceiveDriverProfile {
  return {
    type: RECEIVE_DRIVER_PROFILE,
    profile: profile,
  };
}

export function receiveDriverProfilesWithSquad(profiles: DriverProfileWithSquad[]): ReceiveDriverProfileWithSquad {
  return {
    type: RECEIVE_DRIVER_PROFILES_WITH_SQUAD,
    profiles: profiles,
  };
}

export function receiveDriverProfiles(meta: MetaDriverProfiles): ReceiveDriverProfiles {
  return {
    type: RECEIVE_DRIVER_PROFILES,
    profiles: meta.driver_profiles,
  };
}