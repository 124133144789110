import * as React from 'react';
import {
  MainContainer,
  Breadcrumb,
  Heading,
  PageTitle,
  Separator,
  StyledButton,
  TextInput,
  Alert,
} from 'components';
import { RouteComponentProps } from 'react-router-dom';
import { User, UserUpdateForm } from 'models/auth';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import AuthClient from 'httpClients/authClient';
import COLOR from 'constants/color';
import SettingSidebarMenu from '../../sidebarMenu';
import { faUser } from '@fortawesome/free-solid-svg-icons';

interface ProfileProps {
  user: User;
}

type Props = RouteComponentProps & ProfileProps;

const getDefaultForm = (user: User): UserUpdateForm => {
  return {
    user: {
      name: user && user.name ? user.name : '',
      phone_number: user && user.phone_number ? user.phone_number : '',
    },
  };
};

const ProfileEdit = (props: Props): JSX.Element => {
  React.useEffect(() => {
    (async function (): Promise<void> {
      const client = new AuthClient();
      await client.getUser(props.user.id);
    })();
  }, []);

  const { user } = props;
  const [form, setForm] = React.useState(getDefaultForm(user));
  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    setForm(getDefaultForm(user));
  }, [user]);


  const isPhoneNumberValid = (): boolean => {
    const pattern = new RegExp(/^([+\d]*)?\d$/);

    return pattern.test(form.user.phone_number);
  };

  const handleChange = (fieldName: string, value: string): void => {
    setForm(prevState => ({
      user: {
        ...prevState.user,
        [fieldName]: value,
      },
    }));
    setError('');
  };

  const save = async (form: UserUpdateForm): Promise<void> => {
    setError('');
    try {
      setFetching(true);
      const client = new AuthClient();
      await client.updateUser(form);
      await client.saveUserInfo();
      props.history.push('/setting/personal-profile/profile');
    } catch (e) {
      setError(e);
    }
    setFetching(false);
  };

  return (
    <MainContainer selected="setting">
      <Breadcrumb
        items={[
          { text: 'Setting', onClick: (): void => { props.history.push('/setting'); } },
          { text: 'Profile' },
        ]}
      />
      <Heading>
        <PageTitle>Setting</PageTitle>
      </Heading>
      <SettingSidebarMenu selected="/setting/personal-profile/profile">
        <Heading>
          <PageTitle>
            Profile
          </PageTitle>
          {props.user && (
            <EditButtonContainer>
              <Button
                buttonStyle="encourage"
                buttonType="neutral"
                onClick={(): void => { props.history.push('/setting/personal-profile/profile'); }}
                disabled={fetching}
                icon={<FontAwesomeIcon icon={faTimes} />}
              >
                Cancel
              </Button>
              <StyledButton
                buttonStyle="encourage"
                buttonType="primary"
                disabled={
                  (form.user.name.length < 2) ||
                  (form.user.name.trim() === '') ||
                  fetching ||
                  !isPhoneNumberValid()
                }
                onClick={(): void => { save({ ...form }); }}
                icon={<FontAwesomeIcon icon={faCheck} />}
              >
                Save
              </StyledButton>
            </EditButtonContainer>
          )}
        </Heading>
        <CustomSeparator />
        {props.user && (
          <TextInputContainer>
            <ProfileIconContainer>
              <ProfileIcon
                icon={faUser}
              />
            </ProfileIconContainer>
            <CustomTextInput
              fieldName="Name"
              isRequired
              name="name"
              onTextChange={(value: string): void => handleChange('name', value)}
              type="text"
              width="auto"
              value={form && form.user && form.user.name}
              required
            />
            <CustomTextInput
              fieldName="Email"
              isRequired
              name="Email"
              onTextChange={(value: string): void => handleChange('email', value)}
              type="text"
              width="auto"
              value={props && props.user && props.user.email}
              required
              disabled
            />
            <CustomTextInput
              fieldName="Phone"
              placeholder="80008000"
              isRequired
              name="Phone"
              onTextChange={(value: string): void => handleChange('phone_number', value)}
              type="text"
              width="auto"
              value={form && form.user && form.user.phone_number}
              required
            />
            {isPhoneNumberValid() ? false : (
              <InlineErrorMessage>
                <Icon icon={faExclamationCircle} />
                Mobile number must be xxxxxxxx
              </InlineErrorMessage>
            )}
            {error ? (
              <ErrorMessage status='error'>
                <AlertMessage>{error}</AlertMessage>
              </ErrorMessage>
            ) : false}
          </TextInputContainer>
        )}
      </SettingSidebarMenu>
    </MainContainer>
  );
};

const ProfileIcon = styled(FontAwesomeIcon)`
  background-color: ${COLOR.cyan};
  color: ${COLOR.white};
  height: 4.25rem;
  width: 4.25rem !important;
  padding: 1.5rem;
  border-radius: 50%;
`;

const ProfileIconContainer = styled.div`
  height: 6.25rem;
  width: 6.25rem;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem !important;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.5rem;
`;

const EditButtonContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
`;

const CustomTextInput = styled(TextInput)`
  color: ${(props): string => props.disabled ? COLOR.midDarkGrey : COLOR.black};
  background-color: ${(props): string => props.disabled ? COLOR.whiteGrey : COLOR.white};
`;

const TextInputContainer = styled.div`
  flex: 1;
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  };

  @media only screen and (min-width: 768px) and (min-device-width: 768px){
    width: 100%;
  }

  @media only screen and (min-width: 992px){
    width: 50%;
  }

  @media only screen and (min-width: 1200px) {
    width: 30%;
  }
`;

const ErrorMessage = styled(Alert)`
  margin-top: 1rem;
`;

const AlertMessage = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

const InlineErrorMessage = styled.div`
  margin-top: -1.3rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const Button = styled(StyledButton)`
  margin-right: 1rem;
`;

function mapStateToProps(state: RootState): ProfileProps {
  return {
    user: state.auth.currentUser.user,
  };
}

export default connect(mapStateToProps)(ProfileEdit);