import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Component } from 'react';
import * as H from 'history';
import { CurrentUser } from 'models/auth';
import PartnershipClient from 'httpClients/partnershipClient';

interface ReceiveSessionProps<S = H.LocationState> {
  history: H.History<S>;
}

interface StateProps {
  promptMessage: string;
  afterLoginPage: string;
  currentUser: CurrentUser;
}

type Props = ReceiveSessionProps & StateProps & RouteComponentProps;

function ssoFrontendBaseURL() {
  const protocol = process.env.SSO_PLATFORM_PARTNERS_PROTOCOL;
  const host = process.env.SSO_PLATFORM_PARTNERS_HOST;
  const port = process.env.SSO_PLATFORM_PARTNERS_PORT || null;
  const environment = process.env.ENVIRONMENT;

  if (environment === 'local') {
    return `${protocol}://${host}:${port}`;
  }
  return `${protocol}://${host}`;
}

class ReceiveSession extends Component<Props> {
  componentDidMount() {
    window.addEventListener('message', this.handlePostMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handlePostMessage);
  }

  handlePostMessage = async (event: MessageEvent) => {
    const sessionID = event.data['session_id'];
    if (!sessionID) {
      return; // If no session_id, don't process
    }

    const ssoFrontend = ssoFrontendBaseURL();
    if (event.origin !== ssoFrontend) {
      console.error('invalid origin!', event.origin, ssoFrontend);
      return;
    }

    const partnershipClient = new PartnershipClient();
    try {
      await partnershipClient.exchangeToken({
        session_id: sessionID,
      });
      // Notify parent window to redirect
      window.parent.postMessage('redirectParent', event.origin);
    } catch (error) {
      console.error('Error during token exchange', error);
    }
  };

  render() {
    return (
      <div>
        <h1>Session Receiver</h1>
        <p>Exchanging for token...</p>
      </div>
    );
  }
}

export default withRouter(ReceiveSession);
