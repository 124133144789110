import { Planning, PlanningStatus, PlanningResult } from 'models/planning';

export interface PlanningHash {
  [index: string]: Planning;
}

export interface PlanningState {
  planningHash: PlanningHash;
  planningIds: string[];
}

export const RECEIVE_PLANNINGS = 'RECEIVE_PLANNINGS';
export const RECEIVE_PLANNING = 'RECEIVE_PLANNING';
export const RECEIVE_PLANNING_STATUS = 'RECEIVE_PLANNING_STATUS';
export const RECEIVE_PLANNING_RESULT = 'RECEIVE_PLANNING_RESULT';

export interface ReceivePlannings {
  type: typeof RECEIVE_PLANNINGS;
  plannings: Planning[];
}

export interface ReceivePlanning {
  type: typeof RECEIVE_PLANNING;
  planning: Planning;
}

export interface ReceivePlanningStatus {
  type: typeof RECEIVE_PLANNING_STATUS;
  planningStatus: PlanningStatus;
}

export interface ReceivePlanningResult {
  type: typeof RECEIVE_PLANNING_RESULT;
  planningResult: PlanningResult;
}

export type PlanningActionTypes =
  | ReceivePlannings
  | ReceivePlanning
  | ReceivePlanningStatus
  | ReceivePlanningResult;
