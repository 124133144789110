import { OrgDriver } from 'models/organization';

export interface OrgDriverState {
  orgDrivers: OrgDriver[];
  orgDriver: OrgDriver;
}

export const RECEIVE_ORGANIZATION_DRIVER = 'RECEIVE_ORGANIZATION_DRIVER';
export const RECEIVE_ORGANIZATION_DRIVERS = 'RECEIVE_ORGANIZATION_DRIVERS';

export interface ReceiveOrgDriverAction {
  type: typeof RECEIVE_ORGANIZATION_DRIVER;
  orgDriver: OrgDriver;
}

export interface ReceiveOrgDriversAction {
  type: typeof RECEIVE_ORGANIZATION_DRIVERS;
  orgDrivers: OrgDriver[];
}

export type OrgDriverActionTypes = ReceiveOrgDriverAction
  | ReceiveOrgDriversAction;
