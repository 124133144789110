import { RunsheetReadyToBroadcastActionTypes, RECEIVE_RUNSHEET_READY_TO_BROADCASTS, RECEIVE_RUNSHEET_READY_TO_BROADCAST } from './runsheetReadyToBroadcastTypes';
import { RunsheetReadyToBroadcast } from 'models/runsheetReadyToBroadcast';

export function receiveRunsheetReadyToBroadcastAllSuccess(runsheetReadyToBroadcasts: RunsheetReadyToBroadcast[]): RunsheetReadyToBroadcastActionTypes {
  return {
    type: RECEIVE_RUNSHEET_READY_TO_BROADCASTS,
    runsheetReadyToBroadcasts,
  };
}

export function receiveRunsheetReadyToBroadcast(runsheetReadyToBroadcast: RunsheetReadyToBroadcast): RunsheetReadyToBroadcastActionTypes {
  return {
    type: RECEIVE_RUNSHEET_READY_TO_BROADCAST,
    runsheetReadyToBroadcast,
  };
}