import { RECEIVE_TASKS, RECEIVE_TASK, TaskActionTypes } from './taskTypes';
import { Task } from 'models/task';

export function receiveTaskAllSuccess(tasks: Task[]): TaskActionTypes {
  return {
    type: RECEIVE_TASKS,
    tasks,
  };
}

export function receiveTaskSuccess(task: Task): TaskActionTypes {
  return {
    type: RECEIVE_TASK,
    task,
  };
}
