import {
  combineReducers, createStore, applyMiddleware, Store,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './auth/authReducer';
import runsheetReducer from './runsheet/runsheetReducer';
import runsheetGenerationReducer from './runsheetGeneration/runsheetGenerationReducer';
import runsheetReadyToBroadcastReducer from './runsheetReadyToBroadcast/runsheetReadyToBroadcastReducer';
import runsheetReadyToTransferReducer from './runsheetReadyToTransfer/runsheetReadyToTransferReducer';
import priceAdjustmentReducer from './priceAdjustment/priceAdjustmentReducer';
import planReducer from './plan/planReducer';
import planningReducer from './planning/planningReducer';
import taskReducer from './task/taskReducer';
import taskExportReducer from './taskExport/taskExportReducer';
import driverProfileReducer from './driverProfile/driverProfileReducer';
import roleReducer from './role/roleReducer';
import organizationReducer from './organization/organizationReducer';
import orgDriverReducer from './orgDriver/orgDriverReducer';
import taskImportReducer from './taskImport/taskImportReducer';
import broadcastRecordReducer from './broadcastRecord/broadcastRecordReducer';
import contractPricingReducer from './contractPricing/contractPricingReducer';
import geoServiceReducer from './geoService/geoServiceReducer';
import partnershipReducer from './partnership/partnershipReducer';
import notificationReducer from './notification/notificationReducer';
import vesselReducer from './vessel/vesselReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  broadcastRecord: broadcastRecordReducer,
  runsheet: runsheetReducer,
  contractPricing: contractPricingReducer,
  runsheetGeneration: runsheetGenerationReducer,
  runsheetReadyToBroadcast: runsheetReadyToBroadcastReducer,
  runsheetReadyToTransfer: runsheetReadyToTransferReducer,
  priceAdjustment: priceAdjustmentReducer,
  plan: planReducer,
  planning: planningReducer,
  task: taskReducer,
  taskExport: taskExportReducer,
  driverProfile: driverProfileReducer,
  role: roleReducer,
  organization: organizationReducer,
  orgDriver: orgDriverReducer,
  taskImport: taskImportReducer,
  geoService: geoServiceReducer,
  partnership: partnershipReducer,
  notification: notificationReducer,
  vessel: vesselReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

let store: Store;

export default function configureStore(): Store {
  let enhancer = applyMiddleware(thunkMiddleware);
  if (process.env.NODE_ENV === 'development') {
    enhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
  }

  if (!store) {
    store = createStore(rootReducer, enhancer);
  }

  return store;
}
