import * as React from 'react';
import * as H from 'history';
import styled from 'styled-components';
import {
  Separator,
  Pagination,
  PageTitle,
  MainContainer,
  PaginationState,
  Breadcrumb,
  Heading,
  StyledButton,
  Table,
  TableCellRenderEvent,
  CenterModal,
} from 'components';
import SettingSidebarMenu from '../../sidebarMenu';
import squadLogo from 'assets/images/surge_squad_logo.png';
import emptyPlaceholder from 'assets/images/team_empty_state.png';
import COLOR from 'constants/color';
import {
  faShare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import ShareModal from './modal/shareModal';
import { Organization, Squad } from 'models/organization';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { fetchCurrentUser } from 'utils/userHelper';

interface SquadProps {
  organization: Organization;
  models: Squad[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface SquadState extends PaginationState<Squad> {
  showShareModal: boolean;
  showRemoveMembersModal: boolean;
  selectedMemberId: string;
}

type Props = SquadProps & HistoryProps;

class SquadIndex extends Pagination<Squad, Props, SquadState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      basePath: '/setting/company/squad',
      showShareModal: false,
      showRemoveMembersModal: false,
      selectedMemberId: '',
      renderContainer: false,
      renderSortBy: false,
      filters: this.defaultFilter(),
      pluralModelName: 'users',
    };
    this.rules = [{ name: 'page' }];
  }

  componentDidMount(): void {
    // fetching the current user
    fetchCurrentUser();
    this.fetchData();
  }

  defaultFilter = (): Record<string, string> => ({
    page: '1',
  })

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new OrganizationManagementClient();
    await client.getOrganization();
    await client.getSquads(new URLSearchParams(this.state.filters));

    this.setState({ isFetching: false });
  }

  confirmRemoveMember = (selectedMemberId: string): void => {
    this.setState({
      showRemoveMembersModal: true,
      selectedMemberId,
    });
  }

  closeRemoveMemberModal = (): void => {
    this.setState({
      showRemoveMembersModal: false,
      selectedMemberId: '',
    });
  }

  renderCell = (e: TableCellRenderEvent<{ id: string; name: string; email: string; action: string }>): React.ReactNode => {
    if (e.key === 'action') {
      return (
        <div>
          <RemoveButton
            buttonStyle="discourage"
            buttonType="neutral"
            onClick={(): void => { this.confirmRemoveMember(e.value.toString()); }}
          >
            <TrashIcon icon={faTrash} />
            Remove
          </RemoveButton>
        </div>
      );
    }

    return <>{e.value}</>;
  };

  deleteMembers = async (): Promise<void> => {
    const { selectedMemberId } = this.state;
    const client = new OrganizationManagementClient();
    try {
      await client.removeSquad(selectedMemberId);
      await client.getSquads(new URLSearchParams(this.state.filters));
    } catch (e) {
      // e
    }

    this.setState({
      showRemoveMembersModal: false,
      selectedMemberId: '',
    });
  }

  renderContent = (): JSX.Element => {
    const { models } = this.props;
    const { showRemoveMembersModal } = this.state;

    const columns = {
      'name': 'Name',
      'email': 'Email',
      'action': '',
    };

    const data = models.map(item => ({
      id: item.driver.id,
      name: item.driver.name,
      email: item.driver.email,
      action: item.id,
    }));

    return (
      <>
        {this.renderPage(
          <Table
            columns={columns}
            data={data}
            cellRenderer={this.renderCell}
            hideNavigationButton
          />
        )}
        {
          showRemoveMembersModal ? (
            <CenterModal
              leftButtonOnClick={this.closeRemoveMemberModal}
              leftButtonText="Cancel"
              rightButtonText="Remove"
              rightButtonOnClick={this.deleteMembers}
              rightButtonStyle="discourage"
              rightButtonType="secondary"
              title="Remove driver?"
            >
              <ModalTextContainer>
                <ModalText>
                  The driver details will be lost.
                </ModalText>
              </ModalTextContainer>
            </CenterModal>
          ) : false
        }
      </>
    );
  }

  showShareModal = (shareModal: boolean): void => {
    this.setState({ showShareModal: shareModal });
  }

  renderEmptyState = (): React.ReactNode => (
    <>
      {this.renderPage(
        <EmptyContainer>
          <Placeholder src={emptyPlaceholder} />
          <Subtitle>You look lonely here.</Subtitle>
          <Description>Invite Squad members</Description>
        </EmptyContainer>
      )}
    </>
  )

  renderPage = (content: JSX.Element): React.ReactNode => {
    const { organization } = this.props;
    return (
      <MainContainer selected="squad">
        <Breadcrumb
          items={[
            { text: 'Setting', onClick: (): void => { this.props.history.push('/setting'); } },
            { text: 'Squad' },
          ]}
        />
        <Heading>
          <PageTitle>Setting</PageTitle>
        </Heading>
        <SettingSidebarMenu selected="/setting/company/squad">
          <Heading>
            <PageTitle>
              Squad
            </PageTitle>
          </Heading>
          <CustomSeparator />
          <InfoContainer>
            <InfoImg src={squadLogo} />
            <InfoDescription>
              Invite unlimited drivers as members of your Squad. <br />
              Your squad members’ commission fee will be waived for completing your orders.
            </InfoDescription>
          </InfoContainer>
          <InfoLabel>
            Your Squad Code: <b>{organization && organization.squad_code}</b>
            <ShareButton
              buttonStyle="encourage"
              buttonType="primary"
              onClick={(): void => { this.showShareModal(true); }}
            >
              <Icon icon={faShare} color={COLOR.white} />
              Share
            </ShareButton>
          </InfoLabel>
          <CustomSeparator />
          <DriverSquadLabel>
            <DriverSquadLabelTitle>
              Members
            </DriverSquadLabelTitle>
            {this.renderFilterAndPagination()}
          </DriverSquadLabel>
          {content}
          {this.state.showShareModal && (
            <ShareModal
              closeModal={(): void => { this.showShareModal(false); }}
              squadCode={organization && organization.squad_code}
            />
          )}
        </SettingSidebarMenu>
      </MainContainer>
    );
  };
}

const ModalTextContainer = styled.div`
  margin-bottom: 2rem;
`;

const ModalText = styled.div`
  line-height: 1.25rem;
  margin-bottom: 1rem;
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
`;

const InfoContainer = styled.div`
  margin-top: 1.5rem;
  background-color: ${COLOR.midWhiteGrey};
  display: flex;
  border-radius: 13px;
  align-items: center;
`;

const InfoImg = styled.img`
  width: 3.875rem;
  padding-left: 1.4rem;
  padding-top: 1.08rem;
  padding-bottom: 0.89rem;
`;

const InfoDescription = styled.div`
  color: ${COLOR.darkGray};
  padding-left: 1.78rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const DriverSquadLabel = styled.div`
  font-size: 0.875rem;
  margin-top: 1.5rem;
  margin-bottom: 0.3rem;
`;

const DriverSquadLabelTitle = styled.div`
  float: left;
  font-size: 1rem;
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  & > * {
    margin: 0.5rem;
  }
`;

const Placeholder = styled.img`
  width: 13.5rem;
`;

const Subtitle = styled.div`
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const InfoLabel = styled.div`
  font-size: 1rem;
  margin-top: 1.5rem;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;
`;

const ShareButton = styled(StyledButton)`
  margin-top: 0.3rem;
  margin-left: 0.75rem;
`;

const RemoveButton = styled(StyledButton)`
  margin-right: 0.5rem;
  border-width: 0;
  visibility: hidden;
  tr:hover & {
    visibility: visible;
    background-color: ${COLOR.white};
  }
`;

const TrashIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`;

function mapStateToProps(state: RootState): SquadProps {
  return {
    organization: state.organization.organization,
    models: state.organization.squads,
  };
}

export default connect(mapStateToProps)(SquadIndex);