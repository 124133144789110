import * as React from 'react';
import styled, {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
  css,
} from 'styled-components';

type AllowedTagNames = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'div' | 'span' | 'p' | 'a';

interface TypographyProps {
  as: AllowedTagNames;
  children?: React.ReactNode;
  customStyle?: React.CSSProperties;
  size?: keyof DefaultTheme['fontSizes'];
  color?: keyof DefaultTheme['colors'];
  weight?: keyof DefaultTheme['fontWeights'];
  smSize?: keyof DefaultTheme['fontSizes'];
  mdSize?: keyof DefaultTheme['fontSizes'];
  lgSize?: keyof DefaultTheme['fontSizes'];
  xlSize?: keyof DefaultTheme['fontSizes'];
}

type HTMLTagDefaultFontSize = Partial<
  { [key in AllowedTagNames]: FlattenInterpolation<ThemeProps<DefaultTheme>> }
>;

const defaultFontSizeTag: HTMLTagDefaultFontSize = {
  h1: css`
    font-size: ${(props): string => props.theme.fontSizes.display_sm}; // '1.875rem'
  `,
  h2: css`
    font-size: ${(props): string => props.theme.fontSizes.display_xs}; // '1.5rem'
  `,
  h3: css`
    font-size: ${(props): string => props.theme.fontSizes.xl}; // '1.25rem'
  `,
  h4: css`
    font-size: ${(props): string => props.theme.fontSizes.lg}; // '1.125rem'
  `,
  h5: css`
    font-size: ${(props): string => props.theme.fontSizes.md}; // '1rem'
  `,
  p: css`
    font-size: ${(props): string => props.theme.fontSizes.md}; // '1rem'
  `,
};

const Text = styled.p<TypographyProps>`
  margin: 0;
  font-weight: ${(props): number =>
    props.weight && props.theme.fontWeights[props.weight]};
  color: ${(props): string =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.gray_600};
  ${({ as }): FlattenInterpolation<ThemeProps<DefaultTheme>> => defaultFontSizeTag[as]}
  font-size: ${(props): string => props.size && props.theme.fontSizes[props.size]};

  @media (min-width: 640px) {
    font-size: ${(props): string => props.smSize && props.theme.fontSizes[props.smSize]};
  }

  @media (min-width: 768px) {
    font-size: ${(props): string => props.mdSize && props.theme.fontSizes[props.mdSize]};
  }

  @media (min-width: 1024px) {
    font-size: ${(props): string => props.lgSize && props.theme.fontSizes[props.lgSize]};
  }

  @media (min-width: 1280px) {
    font-size: ${(props): string => props.xlSize && props.theme.fontSizes[props.xlSize]};
  }
`;

export const Typography = ({
  as,
  customStyle,
  children,
  ...props
}: TypographyProps): JSX.Element => {
  const allowedTag = ['h1', 'h2', 'h3', 'h4', 'h5', 'div', 'span', 'p', 'a'];
  const tagName = allowedTag.includes(as) ? as : 'div';
  return (
    <Text as={tagName} style={customStyle} {...props}>
      {children}
    </Text>
  );
};
