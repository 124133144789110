import * as React from 'react';
import {
  Separator as BasicSeparator,
  PageTitle,
  SysAdminMainContainer,
  Breadcrumb,
  Heading,
  StyledButton,
  FleetTypesLabel,
} from 'components';
import { Organization } from 'models/organization';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import RoleClient from 'httpClients/roleClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import styled, { css } from 'styled-components';
import defaultUserCompanyLogo from 'assets/images/default_user_company_logo.png';
import { RootState } from 'reduxActions/store';
import { Role } from 'models/role';
import { connect } from 'react-redux';
import SettingSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import ROLES, { ROLE_DESCRIPTION } from 'constants/role';

interface CompanyDetailsProps {
  organization: Organization;
  roles: Role[];
}

type Props = RouteComponentProps<{ id: string }> & CompanyDetailsProps;

const SysAdminCompanyDetailsIndex = (props: Props): JSX.Element => {
  React.useEffect(() => {
    (async function (): Promise<void> {
      const client = new OrganizationManagementClient();
      await client.sysAdminGetOrganization(props.match.params.id);
      const roleClient = new RoleClient();
      await roleClient.getRoles(props.match.params.id);
    })();
  }, []);

  const { organization, roles } = props;
  const isAdminRoles = roles.find(role => role.role_name === ROLES.OrgAdmin);
  const isTransporterRoles = roles.find(role => role.role_name === ROLES.OrgTransporter);

  return (
    <SysAdminMainContainer selected="organizations">
      <Breadcrumb
        items={[
          { text: 'Organizations', onClick: (): void => { props.history.push('/sys/organizations'); } },
          {
            text: props.match.params.id,
            onClick: (): void => {
              props.history.push('/sys/organizations/' + props.match.params.id + '/company-details');
            },
          },
          { text: 'Company Details' },
        ]}
      />
      <Heading>
        <PageTitle>Organisations Info</PageTitle>
      </Heading>
      <SettingSidebarMenu
        selected={`/sys/organizations/${props.match.params.id}/company-details`}
      >
        <Heading>
          <PageTitle>
            Company Details
          </PageTitle>
          {organization && (
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={(): void => {
                props.history.push(`/sys/organizations/${props.match.params.id}/company-details/edit`);
              }}
            >
              <Icon icon={faEdit} color={COLOR.black} />
              Edit
            </StyledButton>
          )}
        </Heading>
        <Separator />
        {organization && (
          <FormContainer>
            <CompanyImageContainer>
              <CompanyImage src={defaultUserCompanyLogo} />
            </CompanyImageContainer>
            <FormInput>
              <FormHeading>
                Company Name
              </FormHeading>
              <Content>
                {organization.business_name}
              </Content>
            </FormInput>
            <FormInput>
              <FormHeading>
                Test?
              </FormHeading>
              <Content>
                {organization.is_qa ? 'Yes' : 'No'}
              </Content>
            </FormInput>
            {(isAdminRoles || isTransporterRoles) ? (
              <>
                <FormInput>
                  <FormHeading>
                    What do you primarily want use GotSurge for?
                  </FormHeading>
                  <Content>
                    <Ul>
                      {roles.find(role => role.role_name === ROLES.OrgAdmin) ? (
                        <Li>
                          {ROLE_DESCRIPTION[ROLES.OrgAdmin]}
                        </Li>
                      ) : false}
                      {roles.find(role => role.role_name === ROLES.OrgTransporter) ? (
                        <Li>
                          {ROLE_DESCRIPTION[ROLES.OrgTransporter]}
                        </Li>
                      ) : false}
                    </Ul>
                  </Content>
                </FormInput>
                {roles.find(role => role.role_name === ROLES.OrgTransporter) ? (
                  <FleetTypesLabel
                    containerStyle={css`
                        margin-top: 0.5rem;
                        margin-left: 1rem;
                      `}
                    fieldName="Types of fleet"
                    value={organization.fleet_types}
                  />
                ) : false}
              </>
            ) : false}
            <FormInput>
              <FormHeading>
                Reference ID Prefix
              </FormHeading>
              <Content>
                {(organization.prefix_id || 'GOTSURGE-') + '[Your ID]'}
              </Content>
            </FormInput>
          </FormContainer>
        )}
      </SettingSidebarMenu>
    </SysAdminMainContainer>
  );
};

const Separator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyImageContainer = styled.div`
  display: flex;
  margin-top: 1.875rem;
`;

const CompanyImage = styled.img`
  width: 5.625rem;
  height: 5.625rem;
`;

const FormInput = styled.div`
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

const Content = styled.div`
  margin-top: 0.25rem;
  color: ${COLOR.darkGray};
  margin-left: 1rem;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.5rem;
`;

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style-position: inside;
`;

const Li = styled.li`
  padding: 0;
`;

function mapStateToProps(state: RootState): CompanyDetailsProps {
  return {
    organization: state.organization.organization,
    roles: state.role.roles,
  };
}

export default connect(mapStateToProps)(SysAdminCompanyDetailsIndex);