import * as React from 'react';
import TaskClient from 'httpClients/taskClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import {
  Table,
  StyledButton,
  Dropdown,
  SysAdminPagination,
  PaginationState,
  DropdownCheckbox,
  CenterModal,
  Radio,
  TableCellRenderEvent,
  OrderCard,
} from 'components';
import styled, { css } from 'styled-components';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { Task } from 'models/task';
import STATUS, { TASK_STATUS } from 'constants/taskStatus';
import COLOR from 'constants/color';
import * as H from 'history';
import { Organization } from 'models/organization';
import UpdatePriceModal from './modal/updatePriceModal';
import UpdateAdminFeeModal from './modal/updateAdminFeeModal';
import ExportOrdersModal from './modal/exportOrdersModal';
import configureStore from 'reduxActions/store';
import { receiveOrganizations } from 'reduxActions/organization/organizationActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faUpload,
  faDownload,
  faUserAstronaut,
  faCheckCircle,
  faPodcast,
} from '@fortawesome/free-solid-svg-icons';
import { getUser } from 'utils/userHelper';
import PusherHelper from 'utils/pusherHelper';
import emptyPlaceholder from 'assets/images/active_order_empty_state.png';
import { formatPrice } from 'utils/formatter';
import BROADCAST_PREFERENCES, {
  BROADCAST_PREFERENCE_DESCRIPTION,
} from 'constants/broadcastPreference';
import Checkbox from 'components/input/checkbox';

const PUSHER_APP_KEY = process.env.PUSHER_APP_KEY;
const PUSHER_APP_CLUSTER = process.env.PUSHER_APP_CLUSTER;

const broadcastOptions = [
  {
    text: 'Active Orders',
    value: '',
  },
  {
    text: 'Test Orders Only',
    value: 'qa',
  },
];

interface StateProps {
  models: Task[];
  organizations: Organization[];
}

interface LocationParams {
  pathname: string;
  state: {
    from: string;
  };
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface SysAdminTaskIndexState extends PaginationState<Task> {
  showGroupOrdersButton: boolean;
  showUpdatePriceModal: boolean;
  showUpdateAdminFeeModal: boolean;
  showExportOrdersModal: boolean;
  showDialogBroadcastNowModal: boolean;
  showSuccessBroadcastNowModal: boolean;
  broadcastOption: string;
}

type SysAdminTaskIndexProps = HistoryProps & StateProps;

class SysAdminTaskIndex extends SysAdminPagination<
  Task,
  SysAdminTaskIndexProps,
  SysAdminTaskIndexState
> {
  constructor(props: SysAdminTaskIndexProps) {
    super(props);

    this.state = {
      ...this.state,
      basePath: '/sys/tasks',
      multipleSelectButtons: [
        {
          onClick: (): void => {
            this.setState({ showUpdatePriceModal: true });
          },
          text: 'Update Price',
        },
        {
          onClick: (): void => {
            this.setState({ showUpdateAdminFeeModal: true });
          },
          text: 'Update Admin Fee',
        },
      ],
      pluralModelName: 'orders',
      selectedTab: 'tasks',
      showGroupOrdersButton: false,
      showUpdatePriceModal: false,
      showUpdateAdminFeeModal: false,
      showExportOrdersModal: false,
      showDialogBroadcastNowModal: false,
      showSuccessBroadcastNowModal: false,
      broadcastOption: broadcastOptions[0].value,
      isContentNoBorder: true,
    };

    this.rules = [
      ...this.rules,
      {
        name: 'org_id',
        validate: (param: string): boolean => {
          const { organizations } = this.props;
          const orgIds = organizations.map((data) => data.id);

          return organizations.length === 0 || orgIds.includes(param);
        },
      },
    ];
  }

  componentDidMount(): void {
    super.componentDidMount();
    this.fetchUnassignedTasks();
    this.fetchOrganizations();

    if (PUSHER_APP_KEY && PUSHER_APP_CLUSTER) {
      const { id } = getUser();
      PusherHelper.init(PUSHER_APP_KEY, PUSHER_APP_CLUSTER);
      PusherHelper.subscribe([`EXPORT_NOTIFICATION_${id}`]);
    }
  }

  getLocationParams = (pathname: string): LocationParams => {
    return {
      pathname,
      state: {
        from: new URLSearchParams(this.state.filters).toString(),
      },
    };
  };

  renderDriver = (task: Task): React.ReactNode => {
    const orgTransporter = task.org_transporter && task.org_transporter.name;
    const orgTransporterDriver =
      task.org_transporter &&
      task.org_transporter.org_driver &&
      task.org_transporter.org_driver.name;
    let driver: React.ReactNode = orgTransporter
      ? orgTransporterDriver
      : task.driver && task.driver.name;
    if (task.is_squad) {
      driver = (
        <>
          <CircleIconContainer>
            <SquadIcon icon={faUserAstronaut} />
          </CircleIconContainer>
          {task.driver && task.driver.name}
        </>
      );
    }
    if (orgTransporter) {
      driver = (
        <div>
          {orgTransporter && <div>{orgTransporter}</div>}
          <div>{driver}</div>
        </div>
      );
    }
    return driver;
  };

  renderPrice = (task: Task): React.ReactNode => {
    let price: React.ReactNode;
    if (task.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      price = 'N/A';
    } else if (task.current_status === TASK_STATUS.BROADCASTING) {
      price = `Broadcasting at ${formatPrice(task.currency, task.price)}`;
    } else if (task.current_status === TASK_STATUS.EXPIRED) {
      price = `Last Broadcasted at ${formatPrice(task.currency, task.price)}`;
    } else if (
      task.current_status != TASK_STATUS.PENDING_BROADCAST &&
      task.current_status != TASK_STATUS.CREATED &&
      task.current_status != TASK_STATUS.CANCELLED
    ) {
      price = (
        <>
          <CheckIcon icon={faCheckCircle} />
          {formatPrice(task.currency, task.price)}
        </>
      );
    }
    return price;
  };

  getTaskFilters = (): URLSearchParams => {
    const { statuses } = this.state.filters;
    const defaultStatus = !statuses ? [] : statuses.split(',');
    const taskFilters = new URLSearchParams(this.state.filters);

    defaultStatus.forEach((status) => {
      taskFilters.append('current_status', status);
    });

    // removing unnecessary query parameters to the backend
    taskFilters.delete('statuses');

    return taskFilters;
  };

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });
    const filters = this.getTaskFilters();

    const client = new TaskClient();
    await client.sysAdminGetTasks(new URLSearchParams(filters));

    this.setState({ isFetching: false });
  };

  fetchUnassignedTasks = async (): Promise<void> => {
    const queryParams = new URLSearchParams(this.state.filters);
    queryParams.append('is_future_pickup_end_time', 'true');

    const client = new TaskClient();
    const unassignedTasks = await client.sysAdminGetNoRunsheetTasks(queryParams);

    if (unassignedTasks.tasks.length > 0) {
      this.setState({ showGroupOrdersButton: true });
    }
  };

  fetchOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(
          new URLSearchParams({ page: pageNum.toString() })
        );
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch (e) {
      //
    }
  };

  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
    order: 'desc',
    is_qa: 'false',
  });

  changeSelectedStatus = (status: string): void => {
    const newFilters: Record<string, string> = { ...this.state.filters };
    // convert statuses into string array
    const statuses = !newFilters.statuses ? [] : newFilters.statuses.split(',');
    const statusIndex = statuses.indexOf(status);
    // check if status exist in the array statuses
    if (statusIndex > -1) {
      statuses.splice(statusIndex, 1);
    } else {
      statuses.push(status);
    }
    // convert statuses back into string
    newFilters.statuses = statuses.join(',');
    if (statuses.length > 0) {
      this.onFilterChange('statuses', newFilters.statuses);
    } else {
      this.onFilterChange('statuses', '', true);
    }
  };

  clearSelectedStatus = (): void => {
    this.onFilterChange('statuses', '', true);
  };

  broadcastNow = async (): Promise<void> => {
    const client = new TaskClient();
    const isQA = this.state.broadcastOption == 'qa' ? true : false;
    this.setState({
      showDialogBroadcastNowModal: false,
      showGroupOrdersButton: false,
    });

    await client.sysAdminBroadcastTask(new URLSearchParams({ is_qa: isQA.toString() }));

    this.setState({ showSuccessBroadcastNowModal: true });
    setTimeout(() => {
      this.fetchData();
      this.fetchUnassignedTasks();
    }, 5000);
  };

  renderDialogBroadcastNowModal(): React.ReactNode {
    const { broadcastOption } = this.state;
    return (
      <CenterModal
        leftButtonOnClick={(): void => {
          this.setState({ showDialogBroadcastNowModal: false });
        }}
        leftButtonText="Cancel"
        rightButtonOnClick={this.broadcastNow}
        rightButtonText="Ok"
        title="Broadcast Orders"
      >
        <Prompt>Set which orders to group:</Prompt>
        <div>
          <Radio
            options={broadcastOptions}
            value={broadcastOption}
            onChange={(v: string): void => {
              this.setState({ broadcastOption: v });
            }}
          />
        </div>
      </CenterModal>
    );
  }

  renderSuccessBroadcastNowModal(): React.ReactNode {
    return (
      <CenterModal
        rightButtonOnClick={(): void => {
          this.setState({ showSuccessBroadcastNowModal: false });
        }}
        rightButtonText="Ok"
        title="Successful"
      >
        <Prompt>
          If there are many orders, it may take a while for the orders to be mapped into
          the Runsheet.
        </Prompt>
      </CenterModal>
    );
  }

  renderActionPanel = (): React.ReactNode => (
    <TopButtonContainer>
      <Button
        buttonStyle="encourage"
        buttonType="primary"
        size="sm"
        icon={<FontAwesomeIcon icon={faFileAlt} />}
        fontWeight={400}
        onClick={(): void => {
          this.props.history.push('/sys/tasks/new');
        }}
      >
        Create Order
      </Button>

      <Button
        buttonStyle="encourage"
        buttonType="primary"
        size="sm"
        icon={<FontAwesomeIcon icon={faUpload} />}
        fontWeight={400}
        onClick={(): void => {
          this.props.history.push('/sys/tasks/imports/new');
        }}
      >
        Import Orders
      </Button>

      <Button
        buttonStyle="encourage"
        buttonType="primary"
        size="sm"
        icon={<FontAwesomeIcon icon={faDownload} />}
        fontWeight={400}
        onClick={(): void => {
          this.setState({ showExportOrdersModal: true });
        }}
      >
        Export Orders
      </Button>

      <Button
        buttonStyle="encourage"
        buttonType="primary"
        size="sm"
        icon={<FontAwesomeIcon icon={faPodcast} />}
        fontWeight={400}
        disabled={!this.state.showGroupOrdersButton}
        onClick={(): void => {
          this.setState({ showDialogBroadcastNowModal: true });
        }}
      >
        Broadcast Orders
      </Button>
    </TopButtonContainer>
  );

  renderEmptyState = (): React.ReactNode => (
    <EmptyContainer>
      <Placeholder src={emptyPlaceholder} />
      <Subtitle>No orders yet</Subtitle>
      <Description>Place orders with the options below</Description>
      <ButtonContainer>
        <Button
          buttonStyle="encourage"
          buttonType="primary"
          size="sm"
          icon={<FontAwesomeIcon icon={faFileAlt} />}
          onClick={(): void => {
            this.props.history.push('/sys/tasks/new');
          }}
        >
          Create Order
        </Button>
        <div>or</div>
        <Button
          buttonStyle="encourage"
          buttonType="primary"
          size="sm"
          icon={<FontAwesomeIcon icon={faUpload} />}
          onClick={(): void => {
            this.props.history.push('/sys/tasks/imports/new');
          }}
        >
          Import Orders
        </Button>
      </ButtonContainer>
    </EmptyContainer>
  );

  renderFilter = (): React.ReactNode => {
    const serviceList = [];
    for (const service in BROADCAST_PREFERENCES) {
      serviceList.push({
        value: service,
        name: BROADCAST_PREFERENCE_DESCRIPTION[service].label,
      });
    }
    const { statuses } = this.state.filters;
    const selectedStatus = !statuses ? [] : statuses.split(',');
    const defaultStatus = [];
    for (const key in STATUS) {
      if (key !== TASK_STATUS.CREATED && key !== TASK_STATUS.UPDATED) {
        defaultStatus.push({
          value: key,
          name: STATUS[key],
        });
      }
    }
    const organizationList = this.props.organizations.map((org) => ({
      value: org.id,
      name: org.business_name,
    }));
    return (
      <FilterContainer>
        <Checkbox
          onClick={(e): void => {
            e.stopPropagation();
            const isQA = (this.state.filters.is_qa || '') === 'true';
            this.onFilterChange('is_qa', (!isQA).toString());
          }}
          selected={(this.state.filters.is_qa || '') === 'true'}
        >
          Test Orders
        </Checkbox>
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem;
          `}
          label="Service"
          options={serviceList}
          includeAll
          onChange={(value: string): void => {
            const isDelete = value === '';
            this.onFilterChange('broadcast_preference', value, isDelete);
          }}
          value={this.state.filters.broadcast_preference || ''}
        />
        <DropdownCheckbox
          label="Status"
          onChangeSelected={this.changeSelectedStatus}
          onClearSelected={this.clearSelectedStatus}
          options={defaultStatus}
          selectedValues={selectedStatus}
        />
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem;
          `}
          label="Organization"
          options={organizationList}
          includeAll
          onChange={(value: string): void => {
            const isDelete = value === '';
            this.onFilterChange('org_id', value, isDelete);
          }}
          value={this.state.filters.org_id || ''}
          withSearch
          searchPlaceholder="Find Organisation..."
          sorted
        />
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem;
          `}
          label="Sort by"
          options={[
            {
              value: 'desc',
              name: 'Newest First',
            },
            {
              value: 'asc',
              name: 'Oldest First',
            },
          ]}
          onChange={(value: string): void => {
            this.onFilterChange('order', value);
          }}
          value={this.state.filters.order}
        />
      </FilterContainer>
    );
  };

  renderCell = (e: TableCellRenderEvent<Task>): React.ReactNode => {
    return (
      <OrderCard
        row={e.row}
        task={e.data}
        isOps={true}
        onToggleCheckbox={(): void => this.toggleSelect(e.row)}
        selected={Object.keys(this.state.selected)}
      />
    );
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      orderCard: '',
    };

    const data = models.map((task) => {
      return {
        ...task,
      };
    });
    return (
      <>
        <TableContainer addMarginBottom={Object.values(this.state.selected).length !== 0}>
          <Table
            columns={columns}
            data={data}
            multipleSelect={false}
            onToggleCheckbox={this.toggleSelect}
            onToggleCheckboxAll={this.toggleSelectAll}
            rowOnClick={(id: string): void => {
              this.props.history.push('/sys/tasks/' + id);
            }}
            selected={this.state.selected}
            cellRenderer={this.renderCell}
            isRenderColumnHeader={false}
            hideNavigationButton={true}
            isRowHasBorderBottom={false}
            containerPaddingRight="0.125rem"
          />
        </TableContainer>
        {this.state.showDialogBroadcastNowModal && this.renderDialogBroadcastNowModal()}
        {this.state.showSuccessBroadcastNowModal && this.renderSuccessBroadcastNowModal()}
        {this.state.showUpdatePriceModal ? (
          <UpdatePriceModal
            closeModal={(): void => {
              this.setState({ showUpdatePriceModal: false });
            }}
            orders={Object.values(this.state.selected)}
          />
        ) : (
          false
        )}
        {this.state.showUpdateAdminFeeModal ? (
          <UpdateAdminFeeModal
            closeModal={(): void => {
              this.setState({ showUpdateAdminFeeModal: false });
            }}
            orders={Object.values(this.state.selected)}
          />
        ) : (
          false
        )}
        {this.state.showExportOrdersModal ? (
          <ExportOrdersModal
            closeModal={(): void => {
              this.setState({ showExportOrdersModal: false });
            }}
          />
        ) : (
          false
        )}
      </>
    );
  };
}

const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
`;

// This is a temporary solution to fix bulk update box covering the orders
// should be updated with the latest design when available
const TableContainer = styled.div<{ addMarginBottom: boolean }>`
  margin-bottom: ${(props): string => (props.addMarginBottom ? '10rem' : '0')};
`;

const TopButtonContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;

  & > * {
    margin: 0.5rem;
  }
`;

const Placeholder = styled.img`
  width: 13.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    margin-right: 1rem;
  }
`;

const Subtitle = styled.div`
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const Button = styled(StyledButton)`
  margin-right: 0.5rem;
  flex: none;
`;

const Prompt = styled.p`
  margin-bottom: 1.5rem;
`;

export const CircleIconContainer = styled.span`
  display: inline-flex;
  justify-content: center;
  margin-right: 0.3rem;
  background-color: ${COLOR.cyan};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-radius: 20px;
`;

export const SquadIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  color: ${COLOR.darkGray};
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${COLOR.cyan};
  margin-right: 0.3rem;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  models: state.task.tasks,
  organizations: state.organization.organizations,
});

export default connect(mapStateToProps)(SysAdminTaskIndex);
