import * as React from 'react';
import * as H from 'history';

import TaskClient from 'httpClients/taskClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import {
  Pagination,
  StyledButton,
  Table,
  TableCellRenderEvent,
  OrderCard,
} from 'components';
import styled from 'styled-components';
import { Task } from 'models/task';
import emptyPlaceholder from 'assets/images/active_order_empty_state.png';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faUserAstronaut,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from 'utils/formatter';
import { SquadIcon, SquadIconContainer } from './styles';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import { ActiveOrderStatusStrings, TASK_STATUS } from 'constants/taskStatus';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { Organization } from 'models/organization';

interface StateProps {
  models: Task[];
  organization: Organization;
}

interface LocationParams {
  pathname: string;
  state: {
    from: string;
  };
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type ActiveOrderListProps = HistoryProps & StateProps;

class ActiveOrderList extends Pagination<Task, ActiveOrderListProps> {
  constructor(props: ActiveOrderListProps) {
    super(props);

    this.state = {
      ...this.state,
      basePath: '/active-orders',
      breadcrumb: [{ text: 'Active Orders' }],
      pluralModelName: 'active orders',
      selectedTab: 'active-orders',
      isContentNoBorder: true,
    };
  }

  getActiveOrderFilters = (): URLSearchParams => {
    const activeOrderFilters = new URLSearchParams(this.state.filters);
    ActiveOrderStatusStrings.map((status) => {
      activeOrderFilters.append('current_status', status);
    });
    const isQA = (this.props.organization && this.props.organization.is_qa) || false;
    if (!isQA) {
      activeOrderFilters.append('is_qa', 'false');
    }

    return activeOrderFilters;
  };

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const orgClient = new OrganizationManagementClient();
    await orgClient.getOrganization();
    const client = new TaskClient();
    await client.getTasks(new URLSearchParams(this.getActiveOrderFilters()));

    this.setState({ isFetching: false });
  };

  getLocationParams = (pathname: string): LocationParams => {
    return {
      pathname,
      state: {
        from: new URLSearchParams(this.state.filters).toString(),
      },
    };
  };

  renderPrice = (task: Task): React.ReactNode => {
    let price: React.ReactNode;
    if (task.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      price = 'N/A';
    } else if (task.current_status === TASK_STATUS.BROADCASTING) {
      price = `Broadcasting at ${formatPrice(task.currency, task.price)}`;
    } else if (
      task.current_status != TASK_STATUS.PENDING_BROADCAST &&
      task.current_status != TASK_STATUS.CREATED
    ) {
      price = (
        <>
          <CheckIcon icon={faCheckCircle} />
          {formatPrice(task.currency, task.price)}
        </>
      );
    }
    return price;
  };

  renderDriver = (task: Task): React.ReactNode => {
    let driver: React.ReactNode = task.driver ? task.driver.name : '';
    if (task.is_squad) {
      driver = (
        <>
          <SquadIconContainer>
            <SquadIcon icon={faUserAstronaut} />
          </SquadIconContainer>
          {task.driver && task.driver.name}
        </>
      );
    }
    return driver;
  };

  renderActionPanel = (): React.ReactNode => (
    <>
      <Button
        buttonStyle="encourage"
        buttonType="primary"
        onClick={(): void => {
          this.props.history.push('/orders/new');
        }}
        fontWeight={400}
        icon={<FontAwesomeIcon icon={faFileAlt} />}
      >
        Fill Form
      </Button>
    </>
  );

  renderEmptyState = (): React.ReactNode => (
    <EmptyContainer>
      <Placeholder src={emptyPlaceholder} />
      <Subtitle>No active orders yet</Subtitle>
      <Description>Place orders with the options below</Description>
      <ButtonContainer>
        <StyledButton
          buttonStyle="encourage"
          buttonType="primary"
          onClick={(): void => {
            this.props.history.push('/orders/new');
          }}
          icon={<FontAwesomeIcon icon={faFileAlt} />}
        >
          Fill Form
        </StyledButton>
      </ButtonContainer>
    </EmptyContainer>
  );

  renderCell = (e: TableCellRenderEvent<Task>): React.ReactNode => {
    return <OrderCard row={e.row} task={e.data} isOps={false} isCheckboxEnable={false} />;
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      orderCard: '',
    };

    const data = models.map((task) => {
      return {
        ...task,
      };
    });

    return (
      <Table
        columns={columns}
        data={data}
        rowOnClick={(id: string): void => {
          this.props.history.push('/orders/' + id);
        }}
        cellRenderer={this.renderCell}
        isRenderColumnHeader={false}
        hideNavigationButton={true}
        isRowHasBorderBottom={false}
        containerPaddingRight="0.125rem"
      />
    );
  };
}

const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${COLOR.cyan};
  margin-right: 0.3rem;
`;

const Placeholder = styled.img`
  width: 13.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    margin-right: 0rem;
  }

  @media (max-width: 960px) {
    text-align: center;
    display: block;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;

  & > * {
    margin: 0.5rem;
  }
`;

const Subtitle = styled.div`
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const Button = styled(StyledButton)`
  margin-right: 0.5rem;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  models: state.task.tasks,
  organization: state.organization.organization,
});

export default connect(mapStateToProps)(ActiveOrderList);
