import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import {
  SysAdminMainContainer,
  Breadcrumb,
  StyledButton,
  DashedLine,
  Searchbox,
  Checkbox,
  TextInput,
  Alert,
  Separator as BasicSeparator,
  CenterModal,
  SelectFileButton,
  FileInformationCard,
  Dropdown,
  CounterInputButton,
  Typography,
  LocationTypeForm,
  DisplayField,
  SavedAddressButton,
  InputDimensions,
  TotalDeliveryWeight,
} from 'components';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import DatePicker from 'react-datepicker';
//TODO: to put rules in css loader in future
import 'react-datepicker/dist/react-datepicker.css';
import TaskClient from 'httpClients/taskClient';
import * as H from 'history';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import configureStore from 'reduxActions/store';
import {
  receiveGeoDatas,
  receiveSavedGeoDatas,
} from 'reduxActions/geoService/geoServiceActions';
import { receiveVesselAllSuccess } from 'reduxActions/vessel/vesselActions';
import {
  receiveOrganizations,
  receiveOrganization,
  receiveSquadDrivers,
} from 'reduxActions/organization/organizationActions';
import BROADCAST_PREFERENCES, { BROADCAST_MINUTES } from 'constants/broadcastPreference';
import { Organization, SquadDriver } from 'models/organization';
import { GeoData, GeoDistanceRequest, SavedGeoData } from 'models/geoService';
import { Vessel } from 'models/vessel';
import GeneralInfoForm from './partialForm/generalInfoForm';
import VehicleForm from './partialForm/vehicleForm';
import { RouteComponentProps } from 'react-router-dom';
import {
  Address,
  Contact,
  OfficerContact,
  TaskForm,
  TimeWindow,
  TaskAPIResponse,
  AttachmentForm,
  TaskCreationGroup,
  Task,
} from 'models/task';
import {
  ONE_SGD,
  ALLOWED_MIN_SGD,
  ALLOWED_MIN_PRICE,
  DEFAULT_SQUAD_PRICE,
  DEFAULT_MIN_PRICE,
  DEFAULT_MAX_PRICE,
} from 'constants/priceDetails';
import COLOR, { transparent } from 'constants/color';
import { ALLOWED_FILE_TYPE } from 'constants/attachmenfFile';
import { Link } from 'react-router-dom';
import {
  VEHICLE_PREFERENCE_DESCRIPTION,
  VEHICLE_PREFERENCE_INFO,
} from 'constants/vehiclePreference';
import {
  faArrowRight,
  faUpload,
  faCircle,
  faChevronLeft,
  faCheck,
  faBinoculars,
  faSearch,
  faTimes,
  faExclamationCircle,
  faCircleNotch,
  faPlus,
  faTrash,
  faInfoCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import GeoServiceClient from 'httpClients/geoServiceClient';
import { RecommendationPayload, RecommendationResponse } from 'models/priceRetrieval';
import PriceRetrievalClient from 'httpClients/priceRetrievalClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as moment from 'moment';
import LocateMapModal from '../../order/modal/locateMapModal';
import { FileData } from 'models/taskImport';
import { Item } from 'models/task';
import DateHelper from 'utils/dateHelper';
import { formatDuration, formatError, generateUuid } from 'utils/formatter';
import TaskImportClient from 'httpClients/taskImportClient';
import { computeChecksumMd5 } from 'utils/computeChecksum';
import ShareOrderDetailModal from './modal/shareOrderDetailModal';
import VesselClient from 'httpClients/vesselClient';
import AddVesselModal from '../vessels/modal/addVesselModal';
import { CargoDetail } from 'models/task/item';
import { CARGO_TYPES } from '../../../constants/cargo';
import AddressContactModal, {
  AddressContactData,
  ShowSection,
} from './modal/addressContactModal';
import { getServicePricing, isItemExceedingVehicleDimensions } from 'utils/taskHelper';
import { DEFAULT_FTL_VEHICLE, DEFAULT_SERVICE_TYPE } from 'constants/serviceType';

interface SysAdminTaskCreateProps {
  organizations: Organization[];
  organization: Organization;
  squadDrivers: SquadDriver[];
  geoDatas: GeoData[];
  savedGeoDatas: SavedGeoData[];
  vessels: Vessel[];
}

interface OrderNewProps<S = H.LocationState> extends SysAdminTaskCreateProps {
  history: H.History<S>;
}

interface LocationState {
  vehiclePreference: string;
  broadcastPreference: string;
  minPrice: number;
  maxPrice: number;
  increment: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DeliveryForm extends Record<string, any> {
  to_address: Address;
  delivery_note_to_driver: string;
  distance_in_meters: number;
  to_contact: Contact;
  to_time_window: TimeWindow;
  tracking_id?: string;
  invoice_number?: string;
  group_tag: string;
  items: Item[];
  cargo_details: CargoDetail[];
  attachment_files: FileWithData[];
  task_creation_group?: TaskCreationGroup;
  to_location_type: string;
}

interface OrderDeclaration {
  correctAndComplete: boolean;
}

interface SysAdminTaskCreateState {
  baseForm: BaseForm;
  deliveryForms: DeliveryForm[];
  isSaving: boolean;
  isFetchingPrice: boolean;
  isFetchingVessel: boolean;
  orderDeclaration: OrderDeclaration;
  error: string | null;
  isSuccessful: boolean;
  showPickupLocationModal: boolean;
  showDeliveryLocationModal: boolean | number;
  showRecommendPriceSuccess: boolean;
  showRecommendPriceInfo: boolean;
  showRecommendPriceError: boolean;
  showSwitchViewModal: boolean;
  showConfirmOrderModal: boolean;
  showRemoveDeliveryDetailsModal: boolean | number;
  showRemoveModal: {
    deliveryFormIndex: number | boolean;
    itemIndex: number;
    actionType: 'cargo_details' | 'items' | '';
  };
  showRemoveSuccessModal: string;
  showPickupAddVesselModal: boolean;
  showDeliveryAddVesselModal: boolean | number;
  showAddressContactModal: boolean | number;
  hasCargoNet: boolean;
  hasOfficerContact: boolean;
  submittedTasks: TaskAPIResponse[] | [];
  showOnly: ShowSection;
  showSwitchServiceWarningModal: string;
  fromLocationSelected: boolean;
  toLocationSelected: number[];
  totalWeight: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface BaseForm extends Record<string, any> {
  org_id: string;
  is_qa: boolean;
  broadcast_preference: string;
  vehicle_preference: string;
  service_type: string;
  from_address: Address;
  from_contact: Contact;
  from_time_window: TimeWindow;
  pickup_note_to_driver: string;
  max_price: number;
  min_price: number;
  increment: number;
  admin_fee: number;
  cargo_net_quantity: number;
  officer_contact: OfficerContact;
  from_location_type: string;
}

interface FileWithData {
  file: File;
  fileData: FileData;
  fileError?: string;
  prviewUrl?: string;
}

interface RemoveCargoDetailsIconProps {
  color?: string;
  fontSize?: string;
}

const attachmentLabelStyle = css`
  padding-bottom: 0;
  padding-left: 0;
  letter-spacing: 0.32px;
  font-weight: 600;
  font-size: ${(props): string => props.theme.fontSizes.md};
`;

const noMarginLeft = css`
  margin-left: 0;
`;

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

const inlineItemTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`;

const Calendar = styled.div`
  position: inherit;
`;

const RECOMMENDATION_ERROR =
  'Price recommendation is unavailable at the moment. Please try again in a few minutes.';
const CURRENT_DATE = new Date();

type Props = RouteComponentProps<object, object, LocationState> & OrderNewProps;
class SysAdminTaskNew extends React.Component<Props, SysAdminTaskCreateState> {
  searchPickupTimeout: number | null | NodeJS.Timeout;
  searchDeliveryTimeout: number | null | NodeJS.Timeout;
  searchVesselTimeout: number | null | NodeJS.Timeout;
  defaultDeliveryRef: DeliveryForm;
  constructor(props: Props) {
    super(props);
    this.state = {
      baseForm: this.getDefaultBaseForm(),
      deliveryForms: [this.getDefaultDeliveryForm()],
      isSaving: false,
      isFetchingPrice: false,
      isFetchingVessel: false,
      orderDeclaration: {
        correctAndComplete: false,
      },
      error: null,
      isSuccessful: false,
      showPickupLocationModal: false,
      showDeliveryLocationModal: false,
      showRecommendPriceSuccess: false,
      showRecommendPriceInfo: false,
      showRecommendPriceError: false,
      showSwitchViewModal: false,
      showConfirmOrderModal: false,
      showRemoveDeliveryDetailsModal: false,
      showRemoveModal: {
        deliveryFormIndex: false,
        itemIndex: 0,
        actionType: '',
      },
      showRemoveSuccessModal: '',
      showPickupAddVesselModal: false,
      showDeliveryAddVesselModal: false,
      showAddressContactModal: false,
      hasCargoNet: false,
      hasOfficerContact: false,
      submittedTasks: [],
      showOnly: false,
      showSwitchServiceWarningModal: '',
      fromLocationSelected: false,
      toLocationSelected: [],
      totalWeight: 0,
    };
    // Initialize the ref object
    this.defaultDeliveryRef = null;
  }

  async componentDidMount(): Promise<void> {
    const store = configureStore();
    store.dispatch(receiveOrganization(null));
    store.dispatch(receiveSquadDrivers([]));
    store.dispatch(receiveSavedGeoDatas([]));

    await this.fetchOrganizations();
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<SysAdminTaskCreateState>
  ): Promise<void> {
    if (prevState.baseForm.org_id !== this.state.baseForm.org_id) {
      if (this.state.baseForm.org_id !== '') {
        // if organization selected, get the organization data and squad
        const client = new OrganizationManagementClient();
        client.sysAdminGetOrganization(this.state.baseForm.org_id);
        client.sysAdminGetSquadDrivers(this.state.baseForm.org_id, new URLSearchParams());
        this.fetchSavedGeoData();
      } else {
        // if no organization selected, reset organization data and squad
        const store = configureStore();
        store.dispatch(receiveOrganization(null));
        store.dispatch(receiveSquadDrivers([]));
        store.dispatch(receiveSavedGeoDatas([]));
      }
    }
    if (
      prevProps.squadDrivers !== this.props.squadDrivers ||
      (this.state.baseForm &&
        prevState.baseForm &&
        prevState.baseForm.org_id !== this.state.baseForm.org_id)
    ) {
      this.getDefaultPreferenceAndPrice();
    }

    if (
      this.state.baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine &&
      prevState.totalWeight !== this.state.totalWeight &&
      this.state.baseForm.vehicle_preference in VEHICLE_PREFERENCE_INFO &&
      this.state.totalWeight >
        VEHICLE_PREFERENCE_INFO[this.state.baseForm.vehicle_preference].max_weight
    ) {
      this.setState((prevState) => ({
        ...prevState,
        baseForm: {
          ...prevState.baseForm,
          vehicle_preference: '',
        },
      }));
    }
  }

  fetchSavedGeoData = async (): Promise<void> => {
    const geoClient = new GeoServiceClient();
    await geoClient.getSysSavedAddress(
      new URLSearchParams({ org_id: this.state.baseForm.org_id })
    );

    this.props.savedGeoDatas.map((savedAddress) => {
      if (savedAddress.is_default_pickup) {
        this.setState((prevState) => ({
          baseForm: {
            ...prevState.baseForm,
            from_address: {
              ...prevState.baseForm.from_address,
              name_address: savedAddress.name_address,
              building_name: savedAddress.building_name,
              street_address: savedAddress.street_address,
              latitude: savedAddress.lat || 0,
              longitude: savedAddress.lng || 0,
              zip_code: savedAddress.zip_code || '',
              unit_number: savedAddress.unit_number,
            },
            from_contact: {
              ...prevState.baseForm.to_contact,
              name: savedAddress.contact_name,
              phone: savedAddress.contact_phone,
              email: savedAddress.contact_email,
            },
          },
          showRecommendPriceSuccess: false,
          showRecommendPriceInfo: false,
        }));
      } else if (savedAddress.is_default_delivery) {
        this.setState((prevState) => {
          const i = 0; // only first delivery address will be updated
          const updatedDeliveryForms = [...prevState.deliveryForms];
          updatedDeliveryForms[i] = {
            ...prevState.deliveryForms[i],
            to_address: {
              ...prevState.deliveryForms[i].to_address,
              name_address: savedAddress.name_address,
              building_name: savedAddress.building_name,
              street_address: savedAddress.street_address,
              latitude: savedAddress.lat || 0,
              longitude: savedAddress.lng || 0,
              zip_code: savedAddress.zip_code || '',
              unit_number: savedAddress.unit_number,
            },
            to_contact: {
              ...prevState.baseForm.to_contact,
              name: savedAddress.contact_name,
              phone: savedAddress.contact_phone,
              email: savedAddress.contact_email,
            },
            distance_in_meters: 0,
          };
          this.defaultDeliveryRef = updatedDeliveryForms[i];
          return {
            deliveryForms: updatedDeliveryForms,
            showRecommendPriceSuccess: false,
            showRecommendPriceInfo: false,
          };
        });
      }
    });
  };

  fetchOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(
          new URLSearchParams({ page: pageNum.toString() })
        );
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      // sort organization names
      organizations.sort((a, b) => a.business_name.localeCompare(b.business_name));

      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch (e) {
      //
    }
  };

  getDefaultPreferenceAndPrice(): void {
    const { organization: org, location } = this.props;
    let increment = ONE_SGD;
    if (location.state?.increment) {
      increment = location.state?.increment;
    } else {
      if (org?.price_table && org?.price_table.length > 1) {
        increment = org?.price_table[1].price - org?.price_table[0].price;
        if (increment <= ONE_SGD) {
          increment = ONE_SGD;
        } else if (increment <= ONE_SGD * 2) {
          increment = ONE_SGD * 2;
        } else if (increment <= ONE_SGD * 3) {
          increment = ONE_SGD * 3;
        }
      }
    }
    const propsService = location.state?.broadcastPreference;
    const squadsLength = this.props.squadDrivers.length;
    let defaultBroadcastPreference = org?.broadcast_preference || '';
    // changed to standard if default broadcast preference is squad but have no squad
    if (
      defaultBroadcastPreference === BROADCAST_PREFERENCES.squad &&
      squadsLength === 0
    ) {
      defaultBroadcastPreference = BROADCAST_PREFERENCES.all;
    }
    if (defaultBroadcastPreference === '') {
      // defaultBroadcastPreference =
      //   squadsLength > 0 ? BROADCAST_PREFERENCES.squad : BROADCAST_PREFERENCES.all;
      defaultBroadcastPreference = BROADCAST_PREFERENCES.marine;
    }
    if (squadsLength > 0 && propsService) {
      defaultBroadcastPreference = propsService;
    }
    let defaultMinPrice = location.state?.minPrice || org?.min_price || DEFAULT_MIN_PRICE;
    let defaultMaxPrice = location.state?.maxPrice || org?.max_price || DEFAULT_MAX_PRICE;
    if (defaultBroadcastPreference === BROADCAST_PREFERENCES.squad) {
      defaultMinPrice = org?.squad_price || DEFAULT_SQUAD_PRICE;
      defaultMaxPrice = defaultMinPrice;
    }

    this.setState((prevState) => ({
      baseForm: {
        ...prevState.baseForm,
        // broadcast_preference: defaultBroadcastPreference,
        min_price: defaultMinPrice,
        max_price: defaultMaxPrice,
        increment,
      },
    }));
  }

  getDefaultBaseForm(): BaseForm {
    return {
      org_id: '',
      is_qa: false,
      from_address: {
        street_address: '',
        city: 'Singapore',
        country: 'Singapore',
        state: 'Singapore',
        zip_code: '',
        building_name: '',
        name_address: '',
      },
      from_time_window: this.getDefaultPickupTimeWindow(),
      from_contact: {},
      min_price: DEFAULT_MIN_PRICE,
      max_price: DEFAULT_MAX_PRICE,
      increment: ONE_SGD,
      admin_fee: 0,
      broadcast_preference: BROADCAST_PREFERENCES.marine,
      vehicle_preference: this.props.location.state?.vehiclePreference || '',
      service_type: DEFAULT_SERVICE_TYPE,
      pickup_note_to_driver: '',
      cargo_net_quantity: 0,
      officer_contact: {
        name: '',
        phone: '',
      },
      from_location_type: '',
    };
  }

  getDefaultDeliveryForm(): DeliveryForm {
    return {
      attachment_files: [],
      to_address: {
        street_address: '',
        city: 'Singapore',
        country: 'Singapore',
        state: 'Singapore',
        zip_code: '',
        building_name: '',
        name_address: '',
      },
      to_time_window: this.getDefaultDeliveryTimeWindow(),
      distance_in_meters: 0,
      to_contact: {},
      tracking_id: '',
      invoice_number: '',
      group_tag: '',
      items: [
        {
          name: '',
          quantity: undefined,
          has_hazard_mat: false,
          dimension: {
            length: null,
            width: null,
            height: null,
          },
        },
      ],
      cargo_details: [
        {
          id: '',
          name: '',
          quantity: 1,
          quantity_unit: '',
          remarks: '',
          has_hazard_mat: false,
          sku: '',
          volume_unit: '',
          weight_unit: '',
          dimension: {
            length: null,
            width: null,
            height: null,
          },
        },
      ],
      delivery_note_to_driver: '',
      to_location_type: '',
    };
  }

  getDefaultPickupTimeWindow(): TimeWindow {
    return {
      start_timezone: 'Asia/Singapore',
      start_time_utc: CURRENT_DATE,
      end_timezone: 'Asia/Singapore',
      end_time_utc: this.addHoursToDateTime(1, CURRENT_DATE),
    };
  }

  getDefaultDeliveryTimeWindow(): TimeWindow {
    return {
      start_timezone: 'Asia/Singapore',
      start_time_utc: this.addHoursToDateTime(1, CURRENT_DATE),
      end_timezone: 'Asia/Singapore',
      end_time_utc: this.addHoursToDateTime(2, CURRENT_DATE),
    };
  }

  getPresignedUrl = async (id: string, fileName: string): Promise<string> => {
    const client = new TaskClient();
    return await client.getSignedAttachmentLink(id, fileName);
  };

  onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    deliveryFormIndex: number
  ): Promise<void> => {
    const file = e.target.files[0];
    let blobURL = null;
    if (file.type.includes('image') || file.type.includes('pdf')) {
      blobURL = URL.createObjectURL(file);
    }
    if (file) {
      const newFileData: FileData = {
        file_name: file.name,
        file_size: file.size,
        s3_upload_bucket: '',
        s3_upload_key: '',
        url: '',
        checksum: await computeChecksumMd5(file),
        checksum_method: 'MD5',
      };
      const fileWithData: FileWithData = {
        file: file,
        fileData: newFileData,
        prviewUrl: blobURL,
      };
      if (file.size > 20971520) {
        fileWithData.fileError = 'File size should not be more than 20MB';
      }
      if (!ALLOWED_FILE_TYPE.includes(file.type)) {
        fileWithData.fileError = 'Only accepts png, jpg, pdf, doc, docx, xlsx, csv.';
      }
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          ['attachment_files']:
            updatedDeliveryForms[deliveryFormIndex].attachment_files.concat(fileWithData),
        };
        return {
          deliveryForms: updatedDeliveryForms,
        };
      });
    }
  };

  uploadToS3 = async (filesWithData: FileWithData[]): Promise<void> => {
    try {
      const client = new TaskImportClient();
      for (const fileWithData of filesWithData) {
        await client.uploadToS3(fileWithData.fileData.url, fileWithData.file);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  updateBaseForm = (fieldName: string, value: string | boolean | Date): void => {
    if (typeof value === 'string') {
      switch (fieldName) {
        case 'broadcast_preference': {
          const org = this.props.organization;
          const minPrice = org?.min_price || DEFAULT_MIN_PRICE;
          const maxPrice = org?.max_price || DEFAULT_MAX_PRICE;
          // let vehiclePreference = '';
          let minMaxPrice = {
            min_price: minPrice,
            max_price: maxPrice,
          };
          if (value === BROADCAST_PREFERENCES.squad) {
            minMaxPrice = {
              min_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
              max_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
            };
          } else if (value === BROADCAST_PREFERENCES.marine) {
            // const servicePricing = getServicePricing(
            //   DEFAULT_SERVICE_TYPE,
            //   DEFAULT_FTL_VEHICLE
            // );
            // const priceValue = Math.floor(servicePricing.price * 100) * 1000;
            // vehiclePreference = DEFAULT_FTL_VEHICLE;
            // minMaxPrice = {
            //   min_price: priceValue,
            //   max_price: priceValue,
            // };
          }
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              [fieldName]: value,
              ...minMaxPrice,
              service_type: DEFAULT_SERVICE_TYPE,
              // vehicle_preference: vehiclePreference,
            },
            showRecommendPriceInfo: false,
            showRecommendPriceSuccess: false,
          }));
          return;
        }
        case 'max_price':
        case 'min_price': {
          const priceValue = Math.floor(parseFloat(value) * 100) * 1000;
          let minMaxPrice = {
            [fieldName]: priceValue,
          };
          if (this.state.baseForm.broadcast_preference === BROADCAST_PREFERENCES.squad) {
            minMaxPrice = {
              [fieldName]: priceValue,
              max_price: priceValue,
            };
          }
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              ...minMaxPrice,
            },
          }));
          return;
        }
        case 'increment': {
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              increment: Math.floor(parseInt(value) * 100) * 1000,
            },
          }));
          return;
        }
        case 'admin_fee': {
          const priceValue = Math.floor(parseFloat(value) * 100) * 1000;
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              [fieldName]: priceValue,
            },
          }));
          return;
        }
        case 'cargo_net_quantity': {
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              cargo_net_quantity: parseInt(value),
            },
          }));
          return;
        }
        case 'service_type': {
          let vehiclePreference = DEFAULT_FTL_VEHICLE;
          if (value === 'ltl') {
            vehiclePreference = 'ltl';
          }
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              increment: 0,
              service_type: value,
              vehicle_preference: vehiclePreference,
            },
          }));
          return;
        }
        case 'from_location_type': {
          let prevBaseForm = this.state.baseForm;
          if (value === 'sea') {
            prevBaseForm = {
              ...this.state.baseForm,
              from_address: {
                building_name: '',
                name_address: '',
                street_address: '',
                city: 'Singapore',
                country: 'Singapore',
                state: 'Singapore',
                zip_code: '',
              },
              from_contact: {},
            };
          }
          this.setState(
            (prevState) => ({
              ...prevState,
              baseForm: {
                ...prevState.baseForm,
                ...prevBaseForm,
                from_location_type: value,
              },
              fromLocationSelected: false,
            }),
            () => {
              if (value === 'land') {
                this.getDefaultPickupSavedAddress();
              }
              this.setState({
                showAddressContactModal: true,
                showOnly: value === 'land' ? 'address_details' : 'sea_address',
              });
            }
          );
          return;
        }
        case 'org_id': {
          if (value === '') {
            this.clearAddresses();
          }
          this.setDefaultBroadcastPreference();
        }
      }
    }
    this.setState((prevState) => {
      if (fieldName.indexOf('.') !== -1) {
        const fields: string[] = fieldName.split('.');
        return {
          baseForm: {
            ...prevState.baseForm,
            [fields[0]]: {
              ...prevState.baseForm[fields[0]],
              [fields[1]]: value,
            },
          },
        };
      } else {
        return {
          baseForm: {
            ...prevState.baseForm,
            [fieldName]: value,
          },
        };
      }
    });
  };

  getDefaultPickupSavedAddress(): BaseForm {
    const { savedGeoDatas } = this.props;
    let baseForm: BaseForm = this.state.baseForm;
    savedGeoDatas.map((savedAddress) => {
      if (savedAddress.is_default_pickup) {
        baseForm = {
          ...this.state.baseForm,
          from_address: {
            ...this.state.baseForm.from_address,
            name_address: savedAddress.name_address,
            building_name: savedAddress.building_name,
            street_address: savedAddress.street_address,
            latitude: savedAddress.lat || 0,
            longitude: savedAddress.lng || 0,
            zip_code: savedAddress.zip_code || '',
            unit_number: savedAddress.unit_number,
          },
          from_contact: {
            ...this.state.baseForm.to_contact,
            name: savedAddress.contact_name,
            phone: savedAddress.contact_phone,
            email: savedAddress.contact_email,
          },
        };
        this.setState({
          baseForm,
          showRecommendPriceSuccess: false,
          showRecommendPriceInfo: false,
        });
      }
    });
    return baseForm;
  }

  getDefaultDeliverySavedAddress = () => {
    const { savedGeoDatas } = this.props;
    const i = 0; // only first delivery address will be updated

    const defaultDeliveryAddress = savedGeoDatas.find(
      (savedAddress) => savedAddress.is_default_delivery === true
    );
    if (defaultDeliveryAddress) {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        updatedDeliveryForms[i] = {
          ...prevState.deliveryForms[i],
          to_address: {
            ...prevState.deliveryForms[i].to_address,
            name_address: defaultDeliveryAddress.name_address,
            building_name: defaultDeliveryAddress.building_name,
            street_address: defaultDeliveryAddress.street_address,
            latitude: defaultDeliveryAddress.lat || 0,
            longitude: defaultDeliveryAddress.lng || 0,
            zip_code: defaultDeliveryAddress.zip_code || '',
            unit_number: defaultDeliveryAddress.unit_number,
          },
          to_contact: {
            ...updatedDeliveryForms[i].to_contact,
            name: defaultDeliveryAddress.contact_name,
            phone: defaultDeliveryAddress.contact_phone,
            email: defaultDeliveryAddress.contact_email,
          },
          distance_in_meters: 0,
        };
        this.defaultDeliveryRef = updatedDeliveryForms[i];
        return {
          deliveryForms: updatedDeliveryForms,
          showRecommendPriceSuccess: false,
          showRecommendPriceInfo: false,
        };
      });
    }
  };

  updateDeliveryForm = (
    fieldName: string,
    value: string | Date | boolean | number,
    deliveryFormIndex: number
  ): void => {
    this.setState(
      (prevState) => {
        let shouldRecalculateTotalWeight = false;
        const updatedDeliveryForms = [...prevState.deliveryForms];
        if (fieldName.indexOf('.') !== -1) {
          const fields: string[] = fieldName.split('.');
          if (fields[0].indexOf('[') >= 0) {
            const arrayInfo: string[] = fields[0].split('[');
            const itemIdx = arrayInfo[1].slice(0, -1);
            const newArray = [
              ...prevState.deliveryForms[deliveryFormIndex][arrayInfo[0]],
            ];

            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              [arrayInfo[0]]: newArray,
            };

            if (
              arrayInfo[0] === 'items' &&
              value !== '' &&
              (fields[1] === 'weight' || fields[1] === 'quantity')
            ) {
              shouldRecalculateTotalWeight = true;
            }

            if (
              (arrayInfo[0] === 'items' || arrayInfo[0] === 'cargo_details') &&
              value === '' &&
              (fields[1] === 'weight' || fields[1] === 'volume')
            ) {
              delete newArray[parseInt(itemIdx)][fields[1]];
              if (fields[1] === 'weight') {
                newArray[parseInt(itemIdx)]['weight_unit'] = '';
              }
            } else {
              if (fields.length === 2) {
                newArray[parseInt(itemIdx)][fields[1]] = value;
                if (fields[1] === 'weight' && value !== '') {
                  // set to 1 if quantity is not set
                  if (!newArray[parseInt(itemIdx)]['quantity']) {
                    newArray[parseInt(itemIdx)]['quantity'] = 1;
                  }
                  newArray[parseInt(itemIdx)]['weight_unit'] = 'kg';
                }
              } else if (fields.length === 3) {
                newArray[parseInt(itemIdx)][fields[1]] = {
                  ...newArray[parseInt(itemIdx)][fields[1]],
                  [fields[2]]: value,
                };
              }
            }
          } else if (fields[0] in prevState.deliveryForms[deliveryFormIndex]) {
            let fieldValue = value;
            if (fields.length === 3) {
              fieldValue = {
                ...updatedDeliveryForms[deliveryFormIndex][fields[0]][fields[1]],
                [fields[2]]: value || null,
              };
            }
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              [fields[0]]: {
                ...updatedDeliveryForms[deliveryFormIndex][fields[0]],
                [fields[1]]: fieldValue,
              },
            };
          }
        } else {
          if (fieldName === 'to_location_type' && value === 'sea') {
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              to_address: this.getDefaultDeliveryForm().to_address,
              to_contact: {
                ...updatedDeliveryForms[deliveryFormIndex].to_contact,
                ...this.getDefaultDeliveryForm().to_contact,
              },
              to_location_type: value,
            };
          } else {
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              [fieldName]: value,
            };
          }
        }
        // clear the Type of location if changed
        let toLocationSelected = this.state.toLocationSelected;
        if (fieldName === 'to_location_type') {
          toLocationSelected = this.state.toLocationSelected.filter(
            (number) => number !== deliveryFormIndex
          );
        }
        return {
          deliveryForms: updatedDeliveryForms,
          toLocationSelected,
          totalWeight: shouldRecalculateTotalWeight
            ? this.calculateTotalItemsWeight(updatedDeliveryForms)
            : prevState.totalWeight,
        };
      },
      () => {
        if (value === 'land' && deliveryFormIndex === 0) {
          this.getDefaultDeliverySavedAddress();
        }
        if (fieldName === 'to_location_type') {
          this.setState({
            showAddressContactModal: deliveryFormIndex,
            showOnly: value === 'land' ? 'address_details' : 'sea_address',
          });
        }
      }
    );
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (this.allowCreation()) {
      this.setState({ error: null, isSaving: true });
      const { baseForm, deliveryForms } = this.state;
      const prefixID =
        (this.props.organization && this.props.organization.prefix_id) || 'GOTSURGE-';

      try {
        const client = new TaskClient();
        const submittedTasks = await Promise.all(
          deliveryForms.map(async (deliveryForm: DeliveryForm, deliveryIndex: number) => {
            let minPrice = baseForm.min_price;
            let maxPrice = baseForm.max_price;
            if (
              baseForm.service_type === 'ltl' &&
              baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine
            ) {
              const selectedService = getServicePricing(baseForm.service_type, 'ltl');
              const priceValue = Math.floor(selectedService.price * 100) * 1000;
              minPrice = priceValue * this.totalCargoLifts(deliveryIndex);
              maxPrice = priceValue * this.totalCargoLifts(deliveryIndex);
            }
            const form: TaskForm = {
              org_id: baseForm.org_id,
              is_qa: baseForm.is_qa,
              broadcast_preference: baseForm.broadcast_preference,
              vehicle_preference: baseForm.vehicle_preference,
              service_type: baseForm.service_type,
              from_address: baseForm.from_address,
              from_contact: baseForm.from_contact,
              from_time_window: baseForm.from_time_window,
              pickup_note_to_driver: baseForm.pickup_note_to_driver,
              to_address: deliveryForm.to_address,
              to_contact: deliveryForm.to_contact,
              to_time_window: deliveryForm.to_time_window,
              delivery_note_to_driver: deliveryForm.delivery_note_to_driver,
              min_price: minPrice,
              max_price: maxPrice,
              increment: baseForm.increment,
              admin_fee: baseForm.admin_fee,
              currency: 'SGD',
              distance_in_meters: deliveryForm.distance_in_meters,
              client_time_utc: new Date(),
              client_timezone: 'Asia/Singapore',
              items: deliveryForm.items,
              tracking_id:
                deliveryForm.tracking_id !== ''
                  ? prefixID + deliveryForm.tracking_id
                  : '',
              invoice_number: deliveryForm.invoice_number,
              group_tag: deliveryForm.group_tag,
              org_name: this.props.organization.business_name,
            };
            if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
              form.from_contact.vessel_name = baseForm.from_contact.vessel_name;
              form.from_contact.lighter_boat_name =
                baseForm.from_contact.lighter_boat_name;
              form.from_contact.lighter_company_name =
                baseForm.from_contact.lighter_company_name;

              form.to_contact.vessel_name = deliveryForms[0].to_contact.vessel_name;
              form.to_contact.lighter_boat_name =
                deliveryForms[0].to_contact.lighter_boat_name;
              form.to_contact.lighter_company_name =
                deliveryForms[0].to_contact.lighter_company_name;

              form.cargo_net_quantity = baseForm.cargo_net_quantity;
              form.officer_contact = baseForm.officer_contact;
              form.cargo_details = deliveryForm.cargo_details;
              form.increment = 0;

              if (
                form.to_contact.etb_time_window &&
                form.to_contact.etb_time_window.start_time_utc &&
                form.to_contact.etb_time_window.end_time_utc
              ) {
                form.to_contact.etb_time_window.start_timezone = 'Asia/Singapore';
                form.to_contact.etb_time_window.end_timezone = 'Asia/Singapore';
              } else {
                form.to_contact.etb_time_window = null;
              }

              if (
                form.to_contact.etu_time_window &&
                form.to_contact.etu_time_window.start_time_utc &&
                form.to_contact.etu_time_window.end_time_utc
              ) {
                form.to_contact.etu_time_window.start_timezone = 'Asia/Singapore';
                form.to_contact.etu_time_window.end_timezone = 'Asia/Singapore';
              } else {
                form.to_contact.etu_time_window = null;
              }
            }
            let totalItemCount = 0;
            for (let i = form.items.length - 1; i >= 0; i--) {
              if (form.items[i].name.trim().length === 0) {
                form.items.splice(i, 1);
              } else {
                // add default value 1 when quantity is not filled or negative
                if (!form.items[i].quantity || form.items[i].quantity <= 0) {
                  form.items[i].quantity = 1;
                }
                if (form.items[i].quantity >= 1) {
                  totalItemCount++;
                }
              }
            }
            form.total_item_count = totalItemCount;

            const task: TaskAPIResponse = await client.sysAdminCreate(form);
            if (
              deliveryForm.attachment_files.length > 0 &&
              baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine
            ) {
              const taskAttachment: AttachmentForm[] = [];
              for (let i = 0; i < deliveryForm.attachment_files.length; i++) {
                if (!deliveryForm.attachment_files[i].fileError) {
                  deliveryForm.attachment_files[i].fileData.url =
                    await this.getPresignedUrl(
                      task.id,
                      deliveryForm.attachment_files[i].file.name
                    );
                  taskAttachment.push({
                    name: deliveryForm.attachment_files[i].file.name,
                    url: deliveryForm.attachment_files[i].fileData.url,
                  });
                }
              }
              await this.uploadToS3(
                deliveryForm.attachment_files.filter(
                  (attachment) => attachment.fileData.url !== ''
                )
              );
              await client.addAttachment(task.id, {
                attachments: taskAttachment,
                version_rev: task.version_rev,
              });
            }
            return task;
          })
        );
        this.setState({
          showConfirmOrderModal: false,
          isSuccessful: true,
          submittedTasks: submittedTasks,
        });
        this.broadcastAfterSubmit(baseForm.is_qa);
      } catch (e) {
        this.setState({
          error: formatError(e),
          isSaving: false,
          showConfirmOrderModal: false,
          isSuccessful: false,
        });
      }
    }
  };

  handleSubmitMarineOrders = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (this.allowCreation()) {
      this.setState({ error: null, isSaving: true });
      const { baseForm, deliveryForms } = this.state;
      const prefixID =
        (this.props.organization && this.props.organization.prefix_id) || 'GOTSURGE-';

      // vessel info
      const firstDeliveryContact = deliveryForms[0].to_contact;
      const { etb_time_window, etu_time_window, vessel_name, vessel_imo, vessel_id } =
        firstDeliveryContact;
      const vesselInfo = {
        ...(etb_time_window && { etb_time_window }),
        ...(etu_time_window && { etu_time_window }),
        ...(vessel_name && { vessel_name }),
        ...(vessel_imo && { vessel_imo }),
        ...(vessel_id && { vessel_id }),
      };

      if (vesselInfo.etb_time_window) {
        vesselInfo.etb_time_window.start_timezone = 'Asia/Singapore';
        vesselInfo.etb_time_window.end_timezone = 'Asia/Singapore';
      }

      if (vesselInfo.etu_time_window) {
        vesselInfo.etu_time_window.start_timezone = 'Asia/Singapore';
        vesselInfo.etu_time_window.end_timezone = 'Asia/Singapore';
      }

      const taskCreationGroup: TaskCreationGroup = {
        id: generateUuid(),
        index: 0,
      };

      const isDeliveryMoreThanOne = deliveryForms.length > 1;

      const marineForms: TaskForm[] = deliveryForms.map((deliveryForm, deliveryIndex) => {
        let form: TaskForm = {
          org_id: baseForm.org_id,
          org_name: this.props.organization.business_name,
          is_qa: baseForm.is_qa,
          broadcast_preference: baseForm.broadcast_preference,
          vehicle_preference: deliveryForm.vehicle_preference,
          service_type: deliveryForm.service_type,
          min_price: deliveryForm.min_price,
          max_price: deliveryForm.max_price,
          price: deliveryForm.min_price,
          increment: 0,
          admin_fee: baseForm.admin_fee,
          currency: 'SGD',
          client_time_utc: new Date(),
          client_timezone: 'Asia/Singapore',
          tracking_id:
            deliveryForm.tracking_id !== '' ? prefixID + deliveryForm.tracking_id : '',
          invoice_number: deliveryForm.invoice_number,
          group_tag: deliveryForm.group_tag,
          items: [],

          // Cargo details & attachment will be shared for all delivery detail
          cargo_details: deliveryForms[0].cargo_details,
          // attachments: deliveryForms[0].attachment_files,
          // Pickup detail
          from_address: baseForm.from_address,
          from_contact: baseForm.from_contact,
          from_time_window: baseForm.from_time_window,
          pickup_note_to_driver: baseForm.pickup_note_to_driver,
          // Delivery detail
          to_address: deliveryForm.to_address,
          to_contact: {
            ...vesselInfo,
            ...deliveryForm.to_contact,
          },
          to_time_window: deliveryForm.to_time_window,
          delivery_note_to_driver: deliveryForm.delivery_note_to_driver,
          cargo_net_quantity: baseForm.cargo_net_quantity,
          officer_contact: baseForm.officer_contact,
          from_location_type: baseForm.from_location_type,
          to_location_type: deliveryForm.to_location_type,
          ...(isDeliveryMoreThanOne && {
            task_creation_group: {
              ...taskCreationGroup,
              index: deliveryIndex,
            },
          }),
        };

        /* when created, the pickup location info should follow the data above it, see the example below
          Task 1: [Pickup] Loc A → [Delivery] Loc 1 (Truck) (where pickup time got from collect from)
          Task 2: [Pickup] Loc 1 → [Delivery] Loc 2 (Truck) (where pickup time got from delivery start time to Loc 1)
          Task 3: [Pickup] Loc 2 → [Delivery] Loc 3 (Boat) (where pickup time got from delivery start time to Loc 2)
        */
        if (deliveryIndex > 0) {
          const { to_contact: contact } = form;
          const { name, phone, email, vehicle_name, company_name, berth_no } =
            deliveryForms[deliveryIndex - 1].to_contact;
          const fromContact = {
            name,
            phone,
            email,
            vehicle_name,
            company_name,
            berth_no,
          };
          form = {
            ...form,
            // Pickup detail
            from_address: deliveryForms[deliveryIndex - 1].to_address,
            from_contact: fromContact,
            from_time_window: deliveryForms[deliveryIndex - 1].to_time_window,
            pickup_note_to_driver:
              deliveryForms[deliveryIndex - 1].delivery_note_to_driver,
            to_contact: {
              // copy vessel name, ETA, ETD
              ...vesselInfo,
              ...contact,
            },
          };
        }

        return form;
      });

      try {
        const client = new TaskClient();
        const marineTasks = await Promise.all(
          marineForms.map((form) => client.sysAdminCreate(form))
        );

        const taskHash = Object.create(null);
        const taskResponse = marineTasks.reduce(
          (taskReponse: Task[], currentValue: Task) => {
            const newArrayKey = currentValue.task_creation_group.index;
            if (!taskHash[newArrayKey]) {
              taskHash[newArrayKey] = currentValue;
              taskReponse.push(taskHash[newArrayKey]);
            }
            return taskReponse;
          },
          []
        );

        // Upload attachments
        if (taskResponse.length > 0 && deliveryForms[0].attachment_files.length) {
          const taskAttachments: AttachmentForm[] = [];
          for (let i = 0; i < deliveryForms[0].attachment_files.length; i++) {
            // get presigned url with identifier of the task group
            if (!deliveryForms[0].attachment_files[i].fileError) {
              const identifier = isDeliveryMoreThanOne
                ? `gs-${taskResponse[0].task_creation_group.id}`
                : taskResponse[0].id;
              deliveryForms[0].attachment_files[i].fileData.url =
                await this.getPresignedUrl(
                  identifier,
                  deliveryForms[0].attachment_files[i].file.name
                );
              taskAttachments.push({
                name: deliveryForms[0].attachment_files[i].file.name,
                url: deliveryForms[0].attachment_files[i].fileData.url,
              });
            }
          }
          // upload
          await this.uploadToS3(
            deliveryForms[0].attachment_files.filter(
              (attachment) => attachment.fileData.url !== ''
            )
          );

          // update task with uploaded attachments
          await Promise.all(
            taskResponse.map(async (task: Task) => {
              if (taskAttachments.length > 0) {
                await client.addAttachment(task.id, {
                  attachments: taskAttachments,
                  version_rev: task.version_rev,
                });
              }
            })
          );
        }
        this.setState({
          showConfirmOrderModal: false,
          isSuccessful: true,
          submittedTasks: marineTasks,
        });
        this.broadcastAfterSubmit(baseForm.is_qa);
      } catch (e) {
        this.setState({
          error: formatError(e),
          isSaving: false,
          showConfirmOrderModal: false,
          isSuccessful: false,
        });
      }
    }
  };

  broadcastAfterSubmit = async (isQA: boolean): Promise<void> => {
    const taskClient = new TaskClient();
    await taskClient.sysAdminBroadcastTask(
      new URLSearchParams({ is_qa: isQA.toString() })
    );
  };

  areCargoDetailsRequiredFieldsEmpty = (deliveryFormIndex: number): boolean => {
    const cargoDetails = this.state.deliveryForms[deliveryFormIndex].cargo_details;

    return cargoDetails.reduce((acc, detail) => {
      const isNameEmpty = detail.name.trim() === '';
      const isQuantityEmpty = isNaN(detail.quantity) || detail.quantity <= 0;
      return acc || isNameEmpty || isQuantityEmpty;
    }, false);
  };

  totalCargoLifts = (deliveryFormIndex: number): number => {
    const cargoDetails = this.state.deliveryForms[deliveryFormIndex].cargo_details;

    return cargoDetails.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);
  };

  allowConfirmOrder = (): boolean => {
    const { baseForm, deliveryForms } = this.state;
    const { from_time_window: fromTimeWindow } = baseForm;

    let allowedMinPrice = ALLOWED_MIN_PRICE;
    if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      allowedMinPrice = 0;
    }

    let isPriceIncrementFilled = (baseForm.increment || 0) !== 0;
    if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      isPriceIncrementFilled = true;
    }

    let isBaseFormFulfilled =
      baseForm.min_price >= allowedMinPrice &&
      baseForm.max_price >= baseForm.min_price &&
      baseForm.from_address.street_address.trim().length > 0 &&
      this.isRangeValid(fromTimeWindow);

    if (
      isBaseFormFulfilled &&
      baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine
    ) {
      isBaseFormFulfilled = baseForm.vehicle_preference !== '';
    }

    let areDeliveryFormsFulfilled = true;
    for (let i = 0; i < deliveryForms.length; i++) {
      const { to_time_window: toTimeWindow } = deliveryForms[i];

      let checkCargoDetailsRequiredFields = true;
      let deliveryServiceType = true;
      let deliveryVehiclePreference = true;
      if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
        // always use zero index for cargo details, due to the same cargo details is used for all delivery details
        checkCargoDetailsRequiredFields = !this.areCargoDetailsRequiredFieldsEmpty(0);
        deliveryServiceType = !!deliveryForms[i].service_type;
        deliveryVehiclePreference = !!deliveryForms[i].vehicle_preference;
      }

      const isFormFulfilled =
        deliveryForms[i].to_address.street_address.trim().length > 0 &&
        this.isRangeValid(toTimeWindow) &&
        this.compareDateTime(
          baseForm.from_time_window.start_time_utc,
          deliveryForms[i].to_time_window.start_time_utc
        ) <= 0 &&
        this.compareDateTime(
          baseForm.from_time_window.end_time_utc,
          deliveryForms[i].to_time_window.end_time_utc
        ) <= 0 &&
        checkCargoDetailsRequiredFields &&
        deliveryServiceType &&
        deliveryVehiclePreference;

      if (!isFormFulfilled) {
        areDeliveryFormsFulfilled = false;
        break;
      }
    }

    const isOrganizationSelected = this.state.baseForm.org_id.length !== 0;

    const allowPlaceOrder =
      isBaseFormFulfilled &&
      areDeliveryFormsFulfilled &&
      !this.state.isSaving &&
      isOrganizationSelected &&
      isPriceIncrementFilled;

    return allowPlaceOrder;
  };

  setDefaultBroadcastPreference = (): void => {
    this.updateBaseForm(
      'broadcast_preference',
      this.getDefaultBaseForm().broadcast_preference
    );
  };

  allowCreation = (): boolean => {
    return this.state.orderDeclaration.correctAndComplete && this.allowConfirmOrder();
  };

  addHoursToDateTime(hours: number, dateTime: Date): Date {
    return new Date(dateTime.getTime() + hours * 60 * 60 * 1000);
  }

  isFormChanged(): boolean {
    const baseFormChanged =
      JSON.stringify(this.state.baseForm) !== JSON.stringify(this.getDefaultBaseForm());
    const deliveryFormChanged =
      JSON.stringify(this.state.deliveryForms[0]) !==
      JSON.stringify(this.getDefaultDeliveryForm());

    return baseFormChanged || deliveryFormChanged;
  }

  isRangeValid = (timeWindow: TimeWindow): boolean => {
    let isRangeValid = true;
    const startDate = moment(timeWindow.start_time_utc);
    const endDate = moment(timeWindow.end_time_utc);
    if (startDate !== endDate) {
      isRangeValid = endDate.isSameOrAfter(startDate);
    }

    return isRangeValid;
  };

  isLocationValid = (geoData: GeoData): boolean => {
    return (
      geoData.street_address !== '' &&
      geoData.lat !== 0 &&
      geoData.lng !== 0 &&
      geoData.lat !== undefined &&
      geoData.lng !== undefined
    );
  };

  isAddressLocationValid = (address: Address): boolean =>
    this.isLocationValid({
      street_address: address.street_address,
      zip_code: address.zip_code,
      lat: address.latitude,
      lng: address.longitude,
    });

  canRecommendPrice = (): boolean => {
    return (
      this.state.showRecommendPriceInfo ||
      this.state.baseForm.vehicle_preference === '' ||
      this.state.isFetchingPrice
    );
  };

  recommendPrice = async (): Promise<void> => {
    const { baseForm, deliveryForms } = this.state;
    const isPickupLocationValid = this.isAddressLocationValid(baseForm.from_address);
    let areDeliveryLocationsValid = true;
    for (let i = 0; i < deliveryForms.length; i++) {
      if (!this.isAddressLocationValid(deliveryForms[i].to_address)) {
        areDeliveryLocationsValid = false;
        break;
      }
    }
    if (!isPickupLocationValid || !areDeliveryLocationsValid) {
      this.setState({ showRecommendPriceInfo: true });
      return;
    }

    this.setState({ showRecommendPriceSuccess: false, isFetchingPrice: true });
    const stops = [
      {
        location: {
          lat: baseForm.from_address.latitude,
          lng: baseForm.from_address.longitude,
        },
        distance_in_meters: 0,
        addresses: {
          en_SG: {
            display_string: baseForm.from_address.street_address,
            market: 'SG_SIN',
          },
        },
      },
    ];
    deliveryForms.forEach((deliveryForm) => {
      stops.push({
        location: {
          lat: deliveryForm.to_address.latitude,
          lng: deliveryForm.to_address.longitude,
        },
        distance_in_meters: deliveryForm.distance_in_meters,
        addresses: {
          en_SG: {
            display_string: deliveryForm.to_address.street_address,
            market: 'SG_SIN',
          },
        },
      });
    });
    const payload: RecommendationPayload = {
      service_type: baseForm.vehicle_preference,
      stops: stops,
    };
    try {
      const client = new PriceRetrievalClient();
      const resp: RecommendationResponse = await client.recommendation(payload);
      const averageFee = resp.average_fee;
      const recommendedPrice =
        averageFee < ALLOWED_MIN_PRICE ? ALLOWED_MIN_PRICE : averageFee;

      if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.all) {
        // price range
        const twoSGD = ONE_SGD * 2;
        let minPrice = recommendedPrice - twoSGD;
        let maxPrice = recommendedPrice + twoSGD;

        if (recommendedPrice - twoSGD < twoSGD) {
          // if below 2 SGD price range will be 2 SGD -> 4 SGD
          minPrice = twoSGD;
          maxPrice = twoSGD * 2;
        }
        this.updateBaseForm('min_price', Math.round(minPrice / ONE_SGD).toString());
        this.updateBaseForm('max_price', Math.round(maxPrice / ONE_SGD).toString());
      } else {
        // single price
        this.updateBaseForm(
          'min_price',
          Math.round(recommendedPrice / ONE_SGD).toString()
        );
      }
      this.setState({
        showRecommendPriceSuccess: true,
        isFetchingPrice: false,
      });
    } catch (e) {
      this.setState({ showRecommendPriceError: true });
      setTimeout(() => {
        this.setState({
          showRecommendPriceError: false,
          isFetchingPrice: false,
        });
      }, 5000);
    }
  };

  fetchAddresses = async (searchKey: string): Promise<void> => {
    this.clearAddresses();
    if (searchKey && searchKey.length >= 3) {
      const client = new GeoServiceClient();
      await client.getGeoCode(searchKey);
    }
  };

  clearAddresses = (): void => {
    const store = configureStore();
    store.dispatch(receiveGeoDatas([]));
    const defaultBaseForm = this.getDefaultBaseForm();
    const deliveryForms = this.getDefaultDeliveryForm();

    this.setState((prevState) => {
      const updatedDeliveryForms = prevState.deliveryForms.map((prevDeliveryForms) => ({
        ...prevDeliveryForms,
        to_address: deliveryForms.to_address,
        to_contact: deliveryForms.to_contact,
      }));
      return {
        ...prevState,
        baseForm: {
          ...prevState.baseForm,
          from_address: defaultBaseForm.from_address,
          from_contact: defaultBaseForm.from_contact,
        },
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  searchPickupAddress = (): void => {
    clearTimeout(this.searchPickupTimeout);

    this.searchPickupTimeout = setTimeout(() => {
      this.fetchAddresses(this.state.baseForm.from_address.street_address);
    }, 700);
  };

  selectPickupAddress = (streetAddress: string, geoData: GeoData): void => {
    this.setState((prevState) => ({
      baseForm: {
        ...prevState.baseForm,
        from_address: {
          ...prevState.baseForm.from_address,
          street_address: streetAddress,
          building_name: geoData.building_name,
          latitude: geoData.lat,
          longitude: geoData.lng,
          zip_code: geoData.zip_code,
        },
      },
      showRecommendPriceSuccess: false,
      showRecommendPriceInfo: false,
    }));
  };

  clearPickupAddress = (): void => {
    this.setState((prevState) => ({
      baseForm: {
        ...prevState.baseForm,
        from_address: {
          ...prevState.baseForm.from_address,
          latitude: 0,
          longitude: 0,
          zip_code: '',
        },
      },
      showRecommendPriceSuccess: false,
      showRecommendPriceInfo: false,
    }));
  };

  getDistance = async (geoData: GeoData): Promise<number> => {
    const { baseForm } = this.state;
    if (
      baseForm.from_address.latitude == undefined ||
      baseForm.from_address.longitude == undefined
    ) {
      // if base address is not valid, set distance to 0
      return 0;
    }
    try {
      const geoClient = new GeoServiceClient();
      const geoDistanceRequest: GeoDistanceRequest = {
        from_address: {
          street_address: baseForm.from_address.street_address,
          zip_code: baseForm.from_address.zip_code,
          lat: baseForm.from_address.latitude,
          lng: baseForm.from_address.longitude,
        },
        to_address: {
          street_address: geoData.street_address,
          zip_code: geoData.zip_code,
          lat: geoData.lat,
          lng: geoData.lng,
        },
      };

      const geoResponse = await geoClient.geoDistance(geoDistanceRequest);
      return geoResponse.distance_in_meters;
    } catch (e) {
      // e
    }
  };

  renderSwitchServiceWarningModal = (): React.ReactNode => (
    <CenterModal
      title="Switch Service Type?"
      leftButtonText="No"
      leftButtonOnClick={(): void => {
        this.setState({ showSwitchServiceWarningModal: '' });
      }}
      rightButtonOnClick={(): void => {
        const updatedBaseForm = {
          ...this.getDefaultBaseForm(),
          broadcast_preference: this.state.showSwitchServiceWarningModal,
          org_id: this.state.baseForm.org_id,
        };
        this.setState((prevState) => ({
          ...prevState,
          baseForm: updatedBaseForm,
          deliveryForms: [this.getDefaultDeliveryForm()],
          showSwitchServiceWarningModal: '',
        }));
        this.fetchSavedGeoData();
      }}
      rightButtonText="Yes"
      width="small"
    >
      <TitleDescription>Some details will be lost.</TitleDescription>
    </CenterModal>
  );

  selectDeliveryAddress = async (
    i: number,
    streetAddress: string,
    geoData: GeoData
  ): Promise<void> => {
    const distance = (await this.getDistance(geoData)) || 0;
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[i] = {
        ...prevState.deliveryForms[i],
        to_address: {
          ...prevState.deliveryForms[i].to_address,
          street_address: streetAddress,
          building_name: geoData.building_name,
          latitude: geoData.lat,
          longitude: geoData.lng,
          zip_code: geoData.zip_code,
        },
        distance_in_meters: distance,
      };
      return {
        deliveryForms: updatedDeliveryForms,
        showRecommendPriceSuccess: false,
        showRecommendPriceInfo: false,
      };
    });
  };

  clearDeliveryAddress = (i: number): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[i] = {
        ...prevState.deliveryForms[i],
        to_address: {
          ...prevState.deliveryForms[i].to_address,
          street_address: '',
          latitude: 0,
          longitude: 0,
          zip_code: '',
        },
        distance_in_meters: 0,
      };
      return {
        deliveryForms: updatedDeliveryForms,
        showRecommendPriceSuccess: false,
        showRecommendPriceInfo: false,
      };
    });
  };

  searchDeliveryAddress = (i: number): void => {
    clearTimeout(this.searchDeliveryTimeout);

    this.searchDeliveryTimeout = setTimeout(() => {
      this.fetchAddresses(this.state.deliveryForms[i].to_address.street_address);
    }, 700);
  };

  onSelectPickupAddress = (geoData: GeoData): void => {
    this.selectPickupAddress(geoData.street_address, geoData);
  };

  onSelectDeliveryAddress = (i: number, geoData: GeoData): void => {
    this.selectDeliveryAddress(i, geoData.street_address, geoData);
  };

  fetchVessels = async (searchKey: string): Promise<void> => {
    this.clearVessels();
    if (searchKey && searchKey.length >= 3) {
      this.setState({ isFetchingVessel: true });
      const client = new VesselClient();
      await client.getVessels(
        new URLSearchParams({
          term: searchKey.toUpperCase(),
          is_party_search: 'true',
        })
      );
      this.setState({ isFetchingVessel: false });
    }
  };

  chooseSavedAddress = async (
    type: 'pickup' | 'delivery',
    index: number,
    data: SavedGeoData
  ): Promise<void> => {
    if (type === 'pickup') {
      this.setState((prevState) => ({
        baseForm: {
          ...prevState.baseForm,
          from_address: {
            ...prevState.baseForm.from_address,
            name_address: data.name_address,
            building_name: data.building_name,
            street_address: data.street_address,
            latitude: data.lat || 0,
            longitude: data.lng || 0,
            zip_code: data.zip_code || '',
            unit_number: data.unit_number,
          },
          from_contact: {
            ...prevState.baseForm.to_contact,
            name: data.contact_name,
            phone: data.contact_phone,
            email: data.contact_email,
          },
        },
      }));
    } else {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        updatedDeliveryForms[index] = {
          ...updatedDeliveryForms[index],
          to_address: {
            ...updatedDeliveryForms[index].to_address,
            name_address: data.name_address,
            building_name: data.building_name,
            street_address: data.street_address,
            latitude: data.lat || 0,
            longitude: data.lng || 0,
            zip_code: data.zip_code || '',
            unit_number: data.unit_number,
          },
          to_contact: {
            ...updatedDeliveryForms[index].to_contact,
            name: data.contact_name,
            phone: data.contact_phone,
            email: data.contact_email,
          },
        };
        return {
          deliveryForms: updatedDeliveryForms,
        };
      });
    }
  };

  clearVessels = (): void => {
    const store = configureStore();
    store.dispatch(receiveVesselAllSuccess([]));
  };

  searchPickupVessels = (): void => {
    clearTimeout(this.searchVesselTimeout);

    this.searchVesselTimeout = setTimeout(() => {
      this.fetchVessels(this.state.baseForm.from_contact.vessel_name);
    }, 700);
  };

  clearPickupVessels = (clearName: boolean): void => {
    this.setState((prevState) => {
      const fromContact = prevState.baseForm.from_contact;
      delete fromContact.vessel_id;
      delete fromContact.vessel_imo;
      if (clearName) {
        fromContact.vessel_name = '';
      }

      return {
        baseForm: {
          ...prevState.baseForm,
          from_contact: fromContact,
        },
      };
    });
  };

  selectPickupVessels = (id: string, vessel: Vessel): void => {
    this.setState((prevState) => ({
      baseForm: {
        ...prevState.baseForm,
        from_contact: {
          ...prevState.baseForm.from_contact,
          vessel_name: vessel.name,
          vessel_id: id,
          vessel_imo: vessel.imo,
        },
      },
    }));
  };

  searchDeliveryVessels = (index: number): void => {
    clearTimeout(this.searchVesselTimeout);

    this.searchVesselTimeout = setTimeout(() => {
      this.fetchVessels(this.state.deliveryForms[index].to_contact.vessel_name);
    }, 700);
  };

  selectDeliveryVessels = (index: number, id: string, vessel: Vessel): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[index] = {
        ...updatedDeliveryForms[index],
        to_contact: {
          ...updatedDeliveryForms[index].to_contact,
          vessel_id: id,
          vessel_imo: vessel.imo,
          vessel_name: vessel.name,
        },
      };
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  clearDeliveryVessels = (index: number, clearName: boolean): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      const toContact = updatedDeliveryForms[index].to_contact;
      delete toContact.vessel_id;
      delete toContact.vessel_imo;
      if (clearName) {
        toContact.vessel_name = '';
      }
      updatedDeliveryForms[index] = {
        ...updatedDeliveryForms[index],
        to_contact: toContact,
      };
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  compareDateTime = (timeRange1: Date, timeRange2: Date): number => {
    const timeWindow1 = timeRange1.getTime();
    const timeWindow2 = timeRange2.getTime();
    if (timeWindow1 < timeWindow2) {
      return -1;
    } else if (timeWindow1 === timeWindow2) {
      return 0;
    }
    return 1;
  };

  shouldShowWarningDataWillBeLost = (): boolean => {
    const { baseForm, deliveryForms } = this.state;
    const deliveryForm = deliveryForms[0];

    // get the base form value to compare to the current state
    const defaultPickup = this.getDefaultBaseForm();
    const defaultPickupSavedAddress = this.getDefaultPickupSavedAddress();
    const defaultDelivery = this.getDefaultDeliveryForm();
    const defaultDeliverySavedAddress = this.defaultDeliveryRef;

    // get saved address
    const pickupDefault = this.props.savedGeoDatas.filter(
      (savedAddress) => savedAddress.is_default_pickup
    );
    const deliveryDefault = this.props.savedGeoDatas.filter(
      (savedAddress) => savedAddress.is_default_delivery
    );

    // use saved address if exist, if no then use default base form
    const defaultPickupForm =
      pickupDefault.length === 0 ? defaultPickup : defaultPickupSavedAddress;
    const defaultDeliveryForm =
      deliveryDefault.length === 0 ? defaultDelivery : defaultDeliverySavedAddress;

    // compare the changes
    const isPickupAddressChanged =
      JSON.stringify(defaultPickupForm.from_address) !==
      JSON.stringify(baseForm.from_address);
    const isPickupContactChanged =
      JSON.stringify(defaultPickupForm.from_contact) !==
      JSON.stringify(baseForm.from_contact);
    const isDeliveryAddressChanged =
      JSON.stringify(defaultDeliveryForm.to_address) !==
      JSON.stringify(deliveryForm.to_address);
    const isDeliveryContactChanged =
      JSON.stringify(defaultDeliveryForm.to_contact) !==
      JSON.stringify(deliveryForm.to_contact);

    const isDeliveryMoreThanOne = deliveryForms.length > 1;

    const result =
      isPickupAddressChanged ||
      isPickupContactChanged ||
      isDeliveryAddressChanged ||
      isDeliveryContactChanged ||
      isDeliveryMoreThanOne;

    return result;
  };

  getTotalItemsWeight = (): number => {
    return parseFloat(this.state.totalWeight.toFixed(2));
  };

  calculateTotalItemsWeight = (deliveryForms: DeliveryForm[]): number => {
    const items = deliveryForms.flatMap((deliveryForm) => deliveryForm.items);
    return items.reduce((total, item) => {
      const weight = item.weight ? parseFloat(item.weight.toString()) : 0;
      const quantity = item.quantity || 0;
      return total + weight * quantity;
    }, 0);
  };

  renderGeneralInfoForm = (): React.ReactNode => {
    const { baseForm, deliveryForms } = this.state;
    const i = 0;
    return (
      <GeneralInfoFormContainer>
        <GeneralInfoForm
          form={{
            is_qa: baseForm.is_qa,
            broadcast_preference: baseForm.broadcast_preference,
          }}
          orgId={baseForm.org_id}
          onFormChange={(fieldName: string, value: string | boolean): void => {
            if (
              fieldName === 'broadcast_preference' &&
              typeof value === 'string' &&
              this.state.baseForm.broadcast_preference !== value &&
              this.shouldShowWarningDataWillBeLost()
            ) {
              this.setState({ showSwitchServiceWarningModal: value });
            } else {
              this.updateBaseForm(fieldName, value);
            }
          }}
          hasNoSquad={this.props.squadDrivers.length === 0}
          organizations={this.props.organizations}
          vesselForm={
            <>
              {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
                <>
                  <Title>Mother Vessel</Title>
                  <VesselCardRow>
                    <Searchbox
                      key={`to_contact_vessel_name${i}`}
                      inputName="to_contact_vessel_name"
                      trailingIcon={
                        deliveryForms[i].to_contact?.vessel_name ? faTimes : faSearch
                      }
                      containerStyle={inlineTextInputStyle}
                      fieldName="Vessel Name"
                      bottomLabel={this.renderDeliveryVesselBottomLabel(i)}
                      trailingIconOnClick={(): void => {
                        this.clearDeliveryVessels(i, true);
                        this.searchDeliveryVessels(i);
                      }}
                      onTextChange={(value: string): void => {
                        this.updateDeliveryForm('to_contact.vessel_name', value, i);
                        this.searchDeliveryVessels(i);
                        this.clearDeliveryVessels(i, false);
                      }}
                      handleSetFocus={(focused: boolean): void => {
                        if (focused) {
                          this.clearVessels();
                          this.searchDeliveryVessels(i);
                        } else {
                          const toContact = deliveryForms[i].to_contact;
                          if (toContact.vessel_name && !toContact.vessel_imo) {
                            this.updateDeliveryForm('to_contact.vessel_name', '', i);
                          }
                        }
                      }}
                      handleSelectChange={(value: string, vessel: Vessel): void => {
                        this.selectDeliveryVessels(i, value, vessel);
                      }}
                      placeholder="Search vessel name or IMO"
                      width="large"
                      value={deliveryForms[i].to_contact?.vessel_name}
                      options={(this.props.vessels || []).map((vessel) => ({
                        value: vessel.id,
                        text: vessel.name,
                        additionalText: `IMO ${vessel.imo}`,
                        additionalValue: {
                          id: vessel.id,
                          imo: vessel.imo,
                          name: vessel.name,
                        },
                      }))}
                      emptyPlaceholder={this.renderDeliveryVesselEmptyPlaceholder(i)}
                    />
                    <DateAndTimeContainer alignItems="flex-start">
                      {/* Formerly Estimated Time of Berthing (ETB) */}
                      <DatePickerWrapper
                        marginRight="10px"
                        width="13.5rem"
                        minDate={moment().toDate()}
                        dateFormat="dd MMM, hh:mm a"
                        onChange={(value: Date): void => {
                          this.updateDeliveryForm(
                            'to_contact.etb_time_window.start_time_utc',
                            value,
                            i
                          );
                          this.updateDeliveryForm(
                            'to_contact.etb_time_window.end_time_utc',
                            moment(value).add(1, 'hour').toDate(),
                            i
                          );
                        }}
                        onSelect={(value: Date): void => {
                          this.updateDeliveryForm(
                            'to_contact.etb_time_window.start_time_utc',
                            value,
                            i
                          );
                          this.updateDeliveryForm(
                            'to_contact.etb_time_window.end_time_utc',
                            moment(value).add(1, 'hour').toDate(),
                            i
                          );
                        }}
                        selected={
                          deliveryForms[i].to_contact?.etb_time_window?.start_time_utc ??
                          null
                        }
                        showTimeSelect
                        placeholderText="10 Jan, 10:00 AM"
                        customInput={
                          <TextInput
                            autoComplete="none"
                            inputName="name"
                            containerStyle={inlineTextInputStyle}
                            fieldName="Est. Time of Arrival (ETA)"
                            width="full"
                            noWrapLabel
                          />
                        }
                        calendarContainer={Calendar}
                      />
                      {/* Formerly Estimated Time of Unberthing (ETU) */}
                      <DatePickerWrapper
                        width="13.5rem"
                        minDate={moment().toDate()}
                        dateFormat="dd MMM, hh:mm a"
                        onChange={(value: Date): void => {
                          this.updateDeliveryForm(
                            'to_contact.etu_time_window.start_time_utc',
                            value,
                            i
                          );
                          this.updateDeliveryForm(
                            'to_contact.etu_time_window.end_time_utc',
                            moment(value).add(1, 'hour').toDate(),
                            i
                          );
                        }}
                        onSelect={(value: Date): void => {
                          this.updateDeliveryForm(
                            'to_contact.etu_time_window.start_time_utc',
                            value,
                            i
                          );
                          this.updateDeliveryForm(
                            'to_contact.etu_time_window.end_time_utc',
                            moment(value).add(1, 'hour').toDate(),
                            i
                          );
                        }}
                        selected={
                          deliveryForms[i].to_contact?.etu_time_window?.start_time_utc ??
                          null
                        }
                        showTimeSelect
                        placeholderText="10 Jan, 11:30 AM"
                        customInput={
                          <TextInput
                            autoComplete="none"
                            inputName="name"
                            containerStyle={inlineTextInputStyle}
                            fieldName="Est. Time of Departure (ETD)"
                            width="full"
                            noWrapLabel
                          />
                        }
                        calendarContainer={Calendar}
                      />
                    </DateAndTimeContainer>
                    {/* <TextInput
                      autoComplete="none"
                      containerStyle={inlineTextInputStyle}
                      fieldName="Lighter Company Name"
                      onTextChange={(value: string): void => {
                        this.updateDeliveryForm(
                          'to_contact.lighter_company_name',
                          value,
                          i
                        );
                      }}
                      placeholder="Aquaholic Pte Ltd"
                      width="medium"
                      value={deliveryForms[i].to_contact?.lighter_company_name}
                    />
                    <TextInput
                      autoComplete="none"
                      containerStyle={inlineTextInputStyle}
                      fieldName="Lighter Boat Name"
                      onTextChange={(value: string): void => {
                        this.updateDeliveryForm('to_contact.lighter_boat_name', value, i);
                      }}
                      placeholder="Seas the Day"
                      width="medium"
                      value={deliveryForms[i].to_contact?.lighter_boat_name}
                    /> */}
                  </VesselCardRow>
                </>
              )}
            </>
          }
        />
      </GeneralInfoFormContainer>
    );
  };

  isMotorcycleExceedingDimensions = (): string[] => {
    const { deliveryForms } = this.state;
    const items = deliveryForms.flatMap((deliveryForm) => deliveryForm.items);
    const result = isItemExceedingVehicleDimensions(items, ['motorcycle']);
    const vehiclesExceed = Object.keys(result).filter(
      (key) => result[key as keyof typeof result]
    );
    return vehiclesExceed;
  };

  renderVehicleForm = (
    broadcastPreference: keyof typeof BROADCAST_PREFERENCES
  ): React.ReactNode => {
    const { baseForm } = this.state;
    return (
      <VehicleForm
        enableTotalWeightValidation={
          this.state.baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine
        }
        totalWeight={this.getTotalItemsWeight()}
        displayHeader={
          broadcastPreference === 'marine' ? (
            <>
              <Typography
                weight="normal"
                color="gray_700"
                as="h4"
                size="sm"
                customStyle={{ marginBottom: '0.2rem', letterSpacing: '0.32px' }}
              >
                Vehicle Requirements <RedText>*</RedText>
              </Typography>
              <Typography weight="normal" color="gray_400" as="p" size="xs">
                Select the most appropriate vehicle to fit your cargo.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                weight="semibold"
                color="gray_700"
                as="h2"
                customStyle={{ marginBottom: '0.2rem', letterSpacing: '0.32px' }}
              >
                Vehicle Requirements <RedText>*</RedText>
              </Typography>
              <Typography weight="normal" color="gray_400" as="p">
                Select the most appropriate vehicle to fit all your items.
              </Typography>
            </>
          )
        }
        form={{
          broadcast_preference:
            baseForm.broadcast_preference as keyof typeof BROADCAST_PREFERENCES,
          vehicle_preference: baseForm.vehicle_preference,
        }}
        onFormChange={(fieldName: string, value: string | boolean): void => {
          this.updateBaseForm(fieldName, value);
        }}
        vehiclesExceedingDimensions={this.isMotorcycleExceedingDimensions()}
      />
    );
  };

  renderMarineVehicleForm = (i: number): React.ReactNode => {
    const { baseForm, deliveryForms } = this.state;
    return (
      <VehicleForm
        displayHeader={
          <>
            <Typography
              weight="normal"
              color="gray_700"
              as="h4"
              size="sm"
              customStyle={{ marginBottom: '0.2rem', letterSpacing: '0.32px' }}
            >
              Vehicle Requirements <RedText>*</RedText>
            </Typography>
            <Typography weight="normal" color="gray_400" as="p" size="xs">
              Select the most appropriate vehicle to fit your cargo.
            </Typography>
          </>
        }
        form={{
          broadcast_preference:
            baseForm.broadcast_preference as keyof typeof BROADCAST_PREFERENCES,
          vehicle_preference: deliveryForms[i].vehicle_preference,
        }}
        onFormChange={(fieldName: string, value: string | boolean): void => {
          // handle pricing based on vehicle
          if (typeof value === 'string') {
            const serviceType = value === 'boat' ? 'boat' : 'truck';
            const servicePricing = getServicePricing(serviceType, value);
            const priceValue =
              Math.floor(parseFloat(servicePricing.price.toString()) * 100) * 1000;
            this.setState((prevState) => {
              const updatedDeliveryForms = [...prevState.deliveryForms];
              updatedDeliveryForms[i] = {
                ...updatedDeliveryForms[i],
                [fieldName]: value,
                min_price: priceValue,
                max_price: priceValue,
                service_type: serviceType,
              };
              return { deliveryForms: updatedDeliveryForms };
            });
          }
        }}
        disabled={
          baseForm.from_location_type === '' ||
          deliveryForms[i].to_location_type === '' ||
          !(typeof this.state.toLocationSelected[i] === 'number')
        }
      />
    );
  };

  renderPriceForm = (): React.ReactNode => {
    const { baseForm } = this.state;
    const priceContent: React.ReactNode =
      baseForm.broadcast_preference === BROADCAST_PREFERENCES.all
        ? this.renderMinMaxPrice()
        : this.renderSinglePrice();

    return (
      <PriceInfoCard>
        <Title>
          Set your price <RedText>*</RedText>
        </Title>
        {priceContent}
        {baseForm.min_price < ALLOWED_MIN_PRICE || this.state.error !== null ? (
          <ErrorAlert status="error">
            {this.state.error !== null
              ? this.state.error
              : `Enter a minimum price from SGD ${ALLOWED_MIN_SGD}.00.`}
          </ErrorAlert>
        ) : (
          false
        )}
        <PriceContent>
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Admin Fee SGD"
              placeholder="1.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('admin_fee', value);
              }}
              type="number"
              width="medium"
              min={0}
              value={baseForm.admin_fee / ONE_SGD || ''}
              step="any"
            />
          </div>
        </PriceContent>
      </PriceInfoCard>
    );
  };

  renderAdditionalService = (): React.ReactNode => {
    const { baseForm, hasCargoNet, hasOfficerContact } = this.state;

    return (
      <AdditionalServiceCard>
        <Title>Any Additional Service / Requirements?</Title>
        <Checkbox
          onClick={(): void => {
            this.setState((prevState) => ({
              hasCargoNet: !prevState.hasCargoNet,
            }));
          }}
          selected={this.state.hasCargoNet}
        >
          Do you need cargo net?
        </Checkbox>
        {hasCargoNet && (
          <AdditionalServiceContent>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="No. of Cargo Nets"
              placeholder="4"
              onTextChange={(value: string): void => {
                this.updateBaseForm('cargo_net_quantity', value);
              }}
              type="number"
              width="medium"
              min={1}
              value={baseForm.cargo_net_quantity}
            />
          </AdditionalServiceContent>
        )}
        <Checkbox
          onClick={(): void => {
            this.setState((prevState) => ({
              hasOfficerContact: !prevState.hasOfficerContact,
            }));
          }}
          selected={this.state.hasOfficerContact}
        >
          Is there an additional Boarding Officer coming along?
        </Checkbox>
        {hasOfficerContact && (
          <AdditionalServiceContent>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Name"
              placeholder="Tony"
              onTextChange={(value: string): void => {
                this.updateBaseForm('officer_contact.name', value);
              }}
              type="text"
              width="medium"
              value={baseForm.officer_contact.name}
            />
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Contact No."
              placeholder="8100 8989"
              onTextChange={(value: string): void => {
                this.updateBaseForm('officer_contact.phone', value);
              }}
              type="text"
              width="medium"
              value={baseForm.officer_contact.phone}
            />
          </AdditionalServiceContent>
        )}
      </AdditionalServiceCard>
    );
  };

  renderLocateMapModal = (): React.ReactNode => {
    const {
      baseForm,
      deliveryForms,
      showPickupLocationModal,
      showDeliveryLocationModal: deliveryModalIdx,
    } = this.state;

    return (
      <>
        {showPickupLocationModal && (
          <LocateMapModal
            defaultAddress={{
              building_name: baseForm.from_address.building_name
                ? baseForm.from_address.building_name
                : '',
              street_address: baseForm.from_address.street_address
                ? baseForm.from_address.street_address
                : '',
              lat: baseForm.from_address.latitude ? baseForm.from_address.latitude : 0,
              lng: baseForm.from_address.longitude ? baseForm.from_address.longitude : 0,
              zip_code: baseForm.from_address.zip_code
                ? baseForm.from_address.zip_code
                : '',
            }}
            closeModal={(): void => {
              this.setState({
                showPickupLocationModal: false,
                showAddressContactModal: true,
              });
            }}
            onSelectAddress={this.onSelectPickupAddress}
          />
        )}
        {typeof deliveryModalIdx === 'number' && (
          <LocateMapModal
            defaultAddress={{
              building_name: deliveryForms[deliveryModalIdx].to_address.building_name
                ? deliveryForms[deliveryModalIdx].to_address.building_name
                : '',
              street_address: deliveryForms[deliveryModalIdx].to_address.street_address
                ? deliveryForms[deliveryModalIdx].to_address.street_address
                : '',
              lat: deliveryForms[deliveryModalIdx].to_address.latitude
                ? deliveryForms[deliveryModalIdx].to_address.latitude
                : 0,
              lng: deliveryForms[deliveryModalIdx].to_address.longitude
                ? deliveryForms[deliveryModalIdx].to_address.longitude
                : 0,
              zip_code: deliveryForms[deliveryModalIdx].to_address.zip_code
                ? deliveryForms[deliveryModalIdx].to_address.zip_code
                : '',
            }}
            closeModal={(): void => {
              this.setState({ showDeliveryLocationModal: false });
            }}
            onSelectAddress={(geoData: GeoData): void => {
              this.onSelectDeliveryAddress(deliveryModalIdx, geoData);
            }}
          />
        )}
      </>
    );
  };

  renderAddVesselModal = (): React.ReactNode => {
    const { showDeliveryAddVesselModal: index } = this.state;
    return (
      <>
        {this.state.showPickupAddVesselModal ? (
          <AddVesselModal
            hasCheckboxSaveVessel
            closeModal={(): void => {
              this.setState({ showPickupAddVesselModal: false });
            }}
            addVesselSuccess={(vessel: Vessel): void => {
              this.setState((prevState) => ({
                baseForm: {
                  ...prevState.baseForm,
                  from_contact: {
                    ...prevState.baseForm.from_contact,
                    vessel_name: vessel.name.toUpperCase(),
                    vessel_imo: vessel.imo,
                  },
                },
              }));
            }}
          />
        ) : (
          false
        )}
        {typeof index === 'number' && (
          <AddVesselModal
            hasCheckboxSaveVessel
            closeModal={(): void => {
              this.setState({ showDeliveryAddVesselModal: false });
            }}
            addVesselSuccess={(vessel: Vessel): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[index] = {
                  ...updatedDeliveryForms[index],
                  to_contact: {
                    ...updatedDeliveryForms[index].to_contact,
                    vessel_id: vessel.id,
                    vessel_imo: vessel.imo,
                    vessel_name: vessel.name.toUpperCase(),
                  },
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
          />
        )}
      </>
    );
  };

  renderRemoveCargoDetailsOrItemsModal = (): React.ReactNode => {
    const { actionType } = this.state.showRemoveModal;
    if (actionType === '') {
      return null;
    }
    const popupPreference = this.getPopupRemovalPreference();
    return (
      <CenterModal
        title={<RemoveCargoDetailsIcon icon={faTrash} color={COLOR.red} />}
        leftButtonText="Cancel"
        leftButtonOnClick={(): void => {
          this.setState({
            showRemoveModal: {
              deliveryFormIndex: false,
              itemIndex: 0,
              actionType: '',
            },
          });
        }}
        rightButtonOnClick={(): void => popupPreference[actionType].action()}
        rightButtonText="Remove"
        rightButtonStyle="discourage"
        rightButtonType="primary"
        width="small"
        position="bottom"
      >
        <RemoveCargoDetailsSubTitle>
          {popupPreference[actionType].title}
        </RemoveCargoDetailsSubTitle>

        <Typography as="div" color="gray_600" size="sm">
          {popupPreference[actionType].content}
        </Typography>
      </CenterModal>
    );
  };

  renderRemoveSuccessModal = (): React.ReactNode => (
    <>
      {this.state.showRemoveSuccessModal ? (
        <CenterModal
          title={
            <RemoveCargoDetailsIcon
              icon={faCheckCircle}
              color={COLOR.cyan}
              fontSize="1.3"
            />
          }
          fullBottomButtonText="OK"
          fullBottomButtonOnClick={(): void => {
            this.setState({
              showRemoveSuccessModal: '',
            });
          }}
          width="small"
          position="bottom"
        >
          <RemoveCargoDetailsSubTitle>
            {this.state.showRemoveSuccessModal}
          </RemoveCargoDetailsSubTitle>
        </CenterModal>
      ) : (
        false
      )}
    </>
  );

  renderRemoveDeliveryDetailsWarningModal = (): React.ReactNode => (
    <CenterModal
      title="Remove Delivery Details?"
      leftButtonText="Back"
      leftButtonOnClick={(): void => {
        this.setState({ showRemoveDeliveryDetailsModal: false });
      }}
      rightButtonOnClick={(): void => {
        this.setState((prevState) => {
          const updatedDeliveryForms = [...prevState.deliveryForms];
          if (typeof this.state.showRemoveDeliveryDetailsModal === 'number') {
            updatedDeliveryForms.splice(this.state.showRemoveDeliveryDetailsModal, 1);
          }
          return {
            deliveryForms: updatedDeliveryForms,
            showRemoveDeliveryDetailsModal: false,
          };
        });
      }}
      rightButtonText="Remove"
    >
      The delivery details will be lost.
    </CenterModal>
  );

  renderAddressContactModal = (): React.ReactNode => {
    const {
      showAddressContactModal: index,
      baseForm: { broadcast_preference },
      showOnly,
    } = this.state;
    return (
      <>
        {this.state.showAddressContactModal ? (
          <AddressContactModal
            broadcastPreferences={broadcast_preference}
            title="Collect From"
            type="ops"
            defaultForm={{
              address: this.state.baseForm.from_address,
              contact: this.state.baseForm.from_contact,
              org_id: this.state.baseForm.org_id,
            }}
            closeModal={(): void => this.setState({ showAddressContactModal: false })}
            onConfirm={(data: AddressContactData): void => {
              this.setState((prevState) => ({
                ...prevState,
                baseForm: {
                  ...prevState.baseForm,
                  from_address: data.address,
                  from_contact: data.contact,
                },
                fromLocationSelected: true,
              }));
            }}
            showOnly={showOnly}
          />
        ) : (
          false
        )}
        {typeof index === 'number' && (
          <AddressContactModal
            broadcastPreferences={broadcast_preference}
            title="Send To"
            type="ops"
            defaultForm={{
              address: this.state.deliveryForms[index].to_address,
              contact: this.state.deliveryForms[index].to_contact,
              org_id: this.state.baseForm.org_id,
            }}
            closeModal={(): void => this.setState({ showAddressContactModal: false })}
            onConfirm={(data: AddressContactData): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[index] = {
                  ...updatedDeliveryForms[index],
                  to_address: data.address,
                  to_contact: data.contact,
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                  toLocationSelected: [...this.state.toLocationSelected, index],
                };
              });
            }}
            showOnly={showOnly}
          />
        )}
      </>
    );
  };

  renderReferenceID = (prefixID: string, trackingID: string): React.ReactNode => {
    const isReferenceIDFilled = trackingID.length >= 1 ? true : false;
    const hasMinLength = prefixID.length + trackingID.length >= 11 ? true : false;

    return (
      <>
        {isReferenceIDFilled && !hasMinLength && (
          <FormErrorAlert status="error" noBackground>
            Reference ID, including the prefix, must have at least 11 characters.
          </FormErrorAlert>
        )}
      </>
    );
  };

  renderTimeRangeError = (timeWindow: TimeWindow): React.ReactNode => {
    const isRangeInvalid =
      Object.values(timeWindow).every((v) => v !== '') && !this.isRangeValid(timeWindow);

    return (
      <>
        {isRangeInvalid && (
          <FormErrorAlert status="error" noBackground>
            The start time must be earlier than the end time
          </FormErrorAlert>
        )}
      </>
    );
  };

  renderPickupVesselBottomLabel = (): React.ReactNode => {
    const { from_contact } = this.state.baseForm;

    return (
      <>
        {from_contact.vessel_imo ? (
          <>
            <ImoText>{`IMO ${from_contact.vessel_imo}`}</ImoText>
            <ImoInfoAlert status="info">
              Important: Please ensure that this information is accurate.
            </ImoInfoAlert>
          </>
        ) : (
          false
        )}
        <EnterManualButton
          onClick={(): void => {
            this.setState({ showPickupAddVesselModal: true });
          }}
        >
          Enter manually
        </EnterManualButton>
      </>
    );
  };

  renderDeliveryVesselBottomLabel = (index: number): React.ReactNode => {
    const { to_contact } = this.state.deliveryForms[index];

    return (
      <>
        {to_contact.vessel_imo ? (
          <>
            <ImoText>{`IMO ${to_contact.vessel_imo}`}</ImoText>
            <ImoInfoAlert status="info">
              Important: Please ensure that this information is accurate.
            </ImoInfoAlert>
          </>
        ) : (
          false
        )}
        <EnterManualButton
          onClick={(): void => {
            this.setState({ showDeliveryAddVesselModal: index });
          }}
        >
          Enter manually
        </EnterManualButton>
      </>
    );
  };

  renderPickupVesselEmptyPlaceholder = (): React.ReactNode => {
    const { baseForm, showPickupAddVesselModal } = this.state;
    return (
      <>
        {baseForm.from_contact &&
        baseForm.from_contact.vessel_name &&
        baseForm.from_contact.vessel_name.length >= 3 &&
        !showPickupAddVesselModal ? (
          <EmptyVesselPlaceholder>
            {this.state.isFetchingVessel ? (
              <div>Loading...</div>
            ) : (
              <>
                <div>Vessel name not found.</div>
                <EnterManualEmptyPlaceholderButton
                  onClick={(e: React.MouseEvent): void => {
                    e.stopPropagation();
                    this.setState({ showPickupAddVesselModal: true });
                  }}
                >
                  Enter manually
                </EnterManualEmptyPlaceholderButton>
              </>
            )}
          </EmptyVesselPlaceholder>
        ) : (
          false
        )}
      </>
    );
  };

  renderPickupAddressNotFound = (): React.ReactNode => (
    <div>
      {(this.state.baseForm.from_address.street_address ||
        this.state.baseForm.from_address.building_name) &&
      !this.isAddressLocationValid(this.state.baseForm.from_address) ? (
        <InlineErrorMessage>
          <ErrorIcon icon={faExclamationCircle} />
          Address not found
        </InlineErrorMessage>
      ) : (
        false
      )}
    </div>
  );

  renderDeliveryAddressNotFound = (i: number): React.ReactNode => (
    <>
      {(this.state.deliveryForms[i].to_address.street_address ||
        this.state.deliveryForms[i].to_address.building_name) &&
      !this.isAddressLocationValid(this.state.deliveryForms[i].to_address) ? (
        <InlineErrorMessage>
          <ErrorIcon icon={faExclamationCircle} />
          Address not found
        </InlineErrorMessage>
      ) : (
        false
      )}
    </>
  );

  renderPickupLocation = (): React.ReactNode => {
    const { baseForm } = this.state;
    const { savedGeoDatas } = this.props;
    const isBroadcastPreferenceMarine =
      baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const latLngText =
      baseForm.from_address.latitude || baseForm.from_address.longitude
        ? `${baseForm.from_address.latitude}, ${baseForm.from_address.longitude}`
        : '';
    const locationTitle =
      baseForm.from_address.name_address || baseForm.from_address.building_name;
    const locationText =
      baseForm.from_location_type === 'land'
        ? [
            baseForm.from_address.street_address,
            baseForm.from_address.unit_number && baseForm.from_address.unit_number,
            baseForm.from_contact.berth_no && baseForm.from_contact.berth_no,
          ]
            .filter(Boolean)
            .join(', ')
        : latLngText;
    const isFromContactEmpty = Object.entries(baseForm.from_contact)
      .filter(([key]) => key !== 'berth_no')
      .every(([, value]) => value === '');

    return (
      <>
        {isBroadcastPreferenceMarine ? (
          <>
            <LocationTypeForm
              value={this.state.fromLocationSelected && baseForm.from_location_type}
              onChange={(v) => this.updateBaseForm('from_location_type', v)}
            />
            {baseForm.from_location_type && this.state.fromLocationSelected && (
              <>
                <DisplayField
                  label="Location"
                  width="half"
                  title={locationTitle}
                  text={locationText}
                  onButtonClick={() => {
                    this.setState({
                      showAddressContactModal: true,
                      showOnly:
                        baseForm.from_location_type === 'land'
                          ? 'address_details'
                          : 'sea_address',
                    });
                  }}
                />
                {this.renderPickupAddressNotFound()}
                {baseForm.from_location_type === 'land' && (
                  <>
                    <SavedAddressButton
                      savedGeoDatas={savedGeoDatas}
                      onChange={(v: SavedGeoData) =>
                        this.chooseSavedAddress('pickup', 0, v)
                      }
                    />
                  </>
                )}
                {isFromContactEmpty ? (
                  <div>
                    <Typography
                      as="p"
                      color="gray_900"
                      size="sm"
                      customStyle={{ marginTop: '1rem', marginBottom: '0.25rem' }}
                    >
                      Contact Details
                    </Typography>
                    <SelectContactButton
                      buttonStyle="encourage"
                      buttonType="neutral"
                      onClick={(): void => {
                        this.setState({
                          showAddressContactModal: true,
                          showOnly: 'contact_details',
                        });
                      }}
                      disabled={false}
                    >
                      <SelectContactText>Enter contact details...</SelectContactText>
                    </SelectContactButton>
                  </div>
                ) : (
                  <>
                    <DisplayField
                      label="Contact Details"
                      width="half"
                      title={[
                        baseForm.from_contact.company_name,
                        baseForm.from_contact.vehicle_name,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                      text={[
                        baseForm.from_contact.name,
                        baseForm.from_contact.phone,
                        baseForm.from_contact.email,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                      onButtonClick={() => {
                        this.setState({
                          showAddressContactModal: true,
                          showOnly: 'contact_details',
                        });
                      }}
                      containerStyle={css`
                        margin-top: 0.5rem;
                      `}
                    />
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Searchbox
              hideDropdown
              readOnly
              inputName="street-address"
              trailingIcon={faSearch}
              containerStyle={inlineTextInputStyle}
              fieldName="Postal Code or Street Address"
              isRequired
              handleSetFocus={(focused: boolean): void => {
                if (focused) {
                  this.setState({ showAddressContactModal: true, showOnly: false });
                }
              }}
              placeholder="Postal Code / Street Address"
              width="half"
              value={
                this.state.baseForm.from_address.name_address ||
                this.state.baseForm.from_address.building_name
              }
              bottomLabel={
                baseForm.from_address.street_address && (
                  <SubTitleContainer>
                    <SubTitle>
                      {[
                        baseForm.from_address.street_address,
                        baseForm.from_address.unit_number &&
                          baseForm.from_address.unit_number,
                        baseForm.from_contact.berth_no && baseForm.from_contact.berth_no,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </SubTitle>
                    {Object.values(baseForm.from_contact).some((value) => value) && (
                      <SubTitle>
                        Contact:{' '}
                        {[
                          baseForm.from_contact.company_name,
                          baseForm.from_contact.vehicle_name,
                          baseForm.from_contact.name,
                          baseForm.from_contact.phone,
                        ]
                          .filter(Boolean)
                          .join(', ')}
                      </SubTitle>
                    )}
                  </SubTitleContainer>
                )
              }
            />
            {this.renderPickupAddressNotFound()}
            <SavedAddressButton
              savedGeoDatas={savedGeoDatas}
              onChange={(v: SavedGeoData) => this.chooseSavedAddress('pickup', 0, v)}
            />
          </>
        )}
      </>
    );
  };

  renderPickupForm = (): React.ReactNode => {
    const { baseForm } = this.state;
    const isMarine = baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const instructionToDriver = isMarine
      ? 'E.g. Meet at the loading bay'
      : 'E.g. Meet at the lobby';

    return (
      <>
        <CardRow>
          <CardTitle>
            <TitleDescription>Fill in Pickup & Delivery Details</TitleDescription>
          </CardTitle>
          <StyledButton
            type="button"
            buttonType="neutral"
            buttonStyle="encourage"
            onClick={(): void => {
              if (this.isFormChanged()) {
                this.setState({ showSwitchViewModal: true });
              } else {
                this.props.history.push('/sys/tasks/imports/new', {
                  orgId: this.state.baseForm.org_id,
                  vehiclePreference: this.state.baseForm.vehicle_preference,
                  broadcastPreference: this.state.baseForm.broadcast_preference,
                });
              }
            }}
          >
            <MobileResponsiveIcon color={COLOR.darkGray} icon={faUpload} />
            <IconText>Import Form</IconText>
          </StyledButton>
        </CardRow>
        <Separator />
        <CardRow>
          <CircleIcon color={COLOR.blue} icon={faCircle} />
          <AlphabetContainer>A.</AlphabetContainer>
          <Heading>
            <Title>Collect From</Title>
          </Heading>
        </CardRow>
        <CardRow>
          {baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine && (
            <DashedLine />
          )}
          {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
            <DashedLine />
          )}
          <Content>
            <div>{this.renderPickupLocation()}</div>
            <div>
              <DateAndTimeContainer>
                <DatePickerWrapper
                  minDate={moment().toDate()}
                  dateFormat="dd MMM, HH:mm"
                  timeFormat="HH:mm"
                  onChange={(value: Date): void => {
                    if (value instanceof Date) {
                      this.updateBaseForm('from_time_window.start_time_utc', value);
                      this.updateBaseForm(
                        'from_time_window.end_time_utc',
                        moment(value).add(1, 'hour').toDate()
                      );
                    }
                  }}
                  onSelect={(value: Date): void =>
                    this.updateBaseForm('from_time_window.start_time_utc', value)
                  }
                  selected={this.state.baseForm.from_time_window.start_time_utc}
                  showTimeSelect
                  customInput={
                    <TextInput
                      autoComplete="none"
                      inputName="name"
                      containerStyle={inlineTextInputStyle}
                      isRequired
                      fieldName="Pickup Date & Time"
                      width="full"
                    />
                  }
                  calendarContainer={Calendar}
                />
                <TextInput
                  autoComplete="none"
                  containerStyle={inlineTextInputStyle}
                  fieldName={this.renderInstructionsLabel()}
                  onTextChange={(value: string): void => {
                    this.updateBaseForm('pickup_note_to_driver', value);
                  }}
                  placeholder={instructionToDriver}
                  width="half"
                  value={this.state.baseForm.pickup_note_to_driver}
                />
              </DateAndTimeContainer>
              {this.renderTimeRangeError(this.state.baseForm.from_time_window)}
            </div>
          </Content>
        </CardRow>
        <CardRow>
          <DashedLine />
          <AddressSeparator />
        </CardRow>
      </>
    );
  };

  renderDeliveryLocation = (i: number): React.ReactNode => {
    const { deliveryForms, baseForm } = this.state;
    const { savedGeoDatas } = this.props;
    const isBroadcastPreferenceMarine =
      baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const deliveryForm = deliveryForms[i];
    const latLngText =
      deliveryForm.to_address.latitude || deliveryForm.to_address.longitude
        ? `${deliveryForm.to_address.latitude}, ${deliveryForm.to_address.longitude}`
        : '';
    const locationText =
      deliveryForm.to_location_type === 'land'
        ? [
            deliveryForm.to_address.street_address,
            deliveryForm.to_address.unit_number && deliveryForm.to_address.unit_number,
            deliveryForm.to_contact.berth_no && deliveryForm.to_contact.berth_no,
          ]
            .filter(Boolean)
            .join(', ')
        : latLngText;

    const isToContactEmpty = Object.entries(deliveryForm.to_contact)
      .filter(([key]) => key !== 'berth_no')
      .every(([, value]) => value === '');
    const isToLocationSelected = this.state.toLocationSelected.includes(i);

    return (
      <>
        {isBroadcastPreferenceMarine ? (
          <>
            <LocationTypeForm
              value={isToLocationSelected && deliveryForm.to_location_type}
              onChange={(v) => this.updateDeliveryForm('to_location_type', v, i)}
            />
            {isToLocationSelected && deliveryForm.to_location_type ? (
              <>
                <DisplayField
                  label="Location"
                  width="half"
                  title={
                    deliveryForm.to_address.name_address ||
                    deliveryForm.to_address.building_name
                  }
                  text={locationText}
                  onButtonClick={() => {
                    this.setState({
                      showAddressContactModal: i,
                      showOnly:
                        deliveryForm.to_location_type === 'land'
                          ? 'address_details'
                          : 'sea_address',
                    });
                  }}
                />
                {this.renderDeliveryAddressNotFound(i)}
                {deliveryForm.to_location_type === 'land' && (
                  <>
                    {i === 0 ? (
                      <SavedAddressButton
                        savedGeoDatas={savedGeoDatas}
                        onChange={(v: SavedGeoData) =>
                          this.chooseSavedAddress('delivery', i, v)
                        }
                      />
                    ) : (
                      false
                    )}
                  </>
                )}
                {isToContactEmpty ? (
                  <div>
                    <Typography
                      as="p"
                      color="gray_900"
                      size="sm"
                      customStyle={{ marginTop: '1rem', marginBottom: '0.25rem' }}
                    >
                      Contact Details
                    </Typography>
                    <SelectContactButton
                      buttonStyle="encourage"
                      buttonType="neutral"
                      onClick={(): void => {
                        this.setState({
                          showAddressContactModal: i,
                          showOnly: 'contact_details',
                        });
                      }}
                      disabled={false}
                    >
                      <SelectContactText>Enter contact details...</SelectContactText>
                    </SelectContactButton>
                  </div>
                ) : (
                  <DisplayField
                    label="Contact Details"
                    width="half"
                    title={[
                      deliveryForm.to_contact.company_name,
                      deliveryForm.to_contact.vehicle_name,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                    text={[
                      deliveryForm.to_contact.name,
                      deliveryForm.to_contact.phone,
                      deliveryForm.to_contact.email,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                    onButtonClick={() => {
                      this.setState({
                        showAddressContactModal: i,
                        showOnly: 'contact_details',
                      });
                    }}
                    containerStyle={css`
                      margin-top: 0.5rem;
                    `}
                  />
                )}
              </>
            ) : (
              false
            )}
          </>
        ) : (
          <>
            <Searchbox
              hideDropdown
              readOnly
              key={`to_address.street_address${i}`}
              trailingIcon={faSearch}
              containerStyle={inlineTextInputStyle}
              fieldName="Postal Code or Street Address"
              isRequired
              onTextChange={(value: string): void => {
                this.updateDeliveryForm('to_address.building_name', value, i);
                this.searchDeliveryAddress(i);
                if (value === '') {
                  this.clearDeliveryAddress(i);
                }
              }}
              handleSetFocus={(focused: boolean): void => {
                if (focused) {
                  this.setState({ showAddressContactModal: i });
                }
              }}
              placeholder="Postal Code / Street Address"
              width="half"
              value={
                deliveryForms[i].to_address.name_address ||
                deliveryForms[i].to_address.building_name
              }
              bottomLabel={
                deliveryForms[i].to_address && (
                  <SubTitleContainer>
                    <SubTitle>
                      {deliveryForms[i].to_address.street_address}
                      {deliveryForms[i].to_address.unit_number &&
                        `, ${deliveryForms[i].to_address.unit_number}`}
                      {deliveryForms[i].to_contact.berth_no &&
                        `, ${deliveryForms[i].to_contact.berth_no}`}
                    </SubTitle>
                    {Object.values(deliveryForms[i].to_contact).some(
                      (value) => value
                    ) && (
                      <SubTitle>
                        Contact:{' '}
                        {[
                          deliveryForms[i].to_contact.company_name,
                          deliveryForms[i].to_contact.vehicle_name,
                          deliveryForms[i].to_contact.name,
                          deliveryForms[i].to_contact.phone,
                          deliveryForms[i].to_contact.email,
                        ]
                          .filter(Boolean)
                          .join(', ')}
                      </SubTitle>
                    )}
                  </SubTitleContainer>
                )
              }
            />
            {this.renderDeliveryAddressNotFound(i)}
            <SavedAddressButton
              savedGeoDatas={savedGeoDatas}
              onChange={(v: SavedGeoData) => this.chooseSavedAddress('delivery', i, v)}
            />
          </>
        )}
      </>
    );
  };

  renderOrderForm = (): React.ReactNode => {
    return (
      <Card>
        {this.renderPickupForm()}
        {this.state.deliveryForms.map((_, i) => this.renderDeliveryForm(i))}
        <Separator />
        <DeliveryLocationPanel>
          <StyledButton
            buttonType="neutral"
            buttonStyle="encourage"
            onClick={(): void => {
              this.setState((prevState) => {
                return {
                  deliveryForms: prevState.deliveryForms.concat(
                    this.getDefaultDeliveryForm()
                  ),
                };
              });
            }}
            icon={<FontAwesomeIcon icon={faPlus} />}
            disabled={this.state.deliveryForms.length >= 20}
          >
            Add Delivery Location
          </StyledButton>
          {this.state.baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine ? (
            <TotalDeliveryWeight
              totalDeliveries={this.state.deliveryForms.length}
              totalWeight={this.getTotalItemsWeight()}
            />
          ) : (
            false
          )}
        </DeliveryLocationPanel>
      </Card>
    );
  };

  renderServiceAndVehicleSelect = (): React.ReactNode => {
    return (
      <Card>
        {this.renderVehicleForm(
          this.state.baseForm.broadcast_preference as keyof typeof BROADCAST_PREFERENCES
        )}
      </Card>
    );
  };

  renderUploadAttachmentDescription = (): React.ReactNode => {
    return (
      <LabelDescription>
        <Span>Attach documents e.g. DSA/DO/Permits/Packing Lists/Invoice etc.</Span>
        <br />
        <Span>
          Upload files up to 20MB in pdf (recommended), jpg, jpeg, png, docx, xlsx or csv.
        </Span>
      </LabelDescription>
    );
  };

  onFileRemove = (deliveryFormIndex: number, index: number): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[deliveryFormIndex] = {
        ...updatedDeliveryForms[deliveryFormIndex],
        ['attachment_files']: updatedDeliveryForms[
          deliveryFormIndex
        ].attachment_files.filter((_, i) => i !== index),
      };
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  removeCargoDetails = (): void => {
    const { deliveryFormIndex, itemIndex, actionType } = this.state.showRemoveModal;
    if (typeof deliveryFormIndex === 'number' && actionType === 'cargo_details') {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        const updatedItems = [
          ...prevState.deliveryForms[deliveryFormIndex].cargo_details,
        ];
        if (
          itemIndex === 0 &&
          this.state.deliveryForms[deliveryFormIndex].cargo_details.length === 1
        ) {
          updatedItems[itemIndex] = {
            id: '',
            name: '',
            quantity: 1,
            quantity_unit: '',
            remarks: '',
            has_hazard_mat: false,
            sku: '',
            volume_unit: '',
            weight_unit: '',
          };
        } else {
          updatedItems.splice(itemIndex, 1);
        }
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          cargo_details: updatedItems,
        };
        return {
          deliveryForms: updatedDeliveryForms,
          showRemoveModal: {
            deliveryFormIndex: false,
            itemIndex: 0,
            actionType: '',
          },
          showRemoveSuccessModal: 'Cargo details removed',
        };
      });
    }
  };

  removeItem = () => {
    const { deliveryFormIndex, itemIndex, actionType } = this.state.showRemoveModal;
    if (typeof deliveryFormIndex === 'number' && actionType === 'items') {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        const updatedItems = [...prevState.deliveryForms[deliveryFormIndex].items];
        if (
          itemIndex === 0 &&
          this.state.deliveryForms[deliveryFormIndex].items.length === 1
        ) {
          updatedItems[itemIndex] = {
            name: '',
            quantity: undefined,
            has_hazard_mat: false,
            dimension: {
              length: null,
              width: null,
              height: null,
            },
          };
        } else {
          updatedItems.splice(itemIndex, 1);
        }
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          items: updatedItems,
        };
        return {
          deliveryForms: updatedDeliveryForms,
          showRemoveModal: {
            deliveryFormIndex: false,
            itemIndex: 0,
            actionType: '',
          },
          showRemoveSuccessModal: 'Item details removed',
        };
      });
    }
  };

  getPopupRemovalPreference = () => {
    return {
      items: {
        title: 'Remove item details?',
        content:
          'Are you sure you want to remove item details? This action cannot be undone.',
        action: this.removeItem,
      },
      cargo_details: {
        title: 'Remove cargo details?',
        content:
          'Are you sure you want to remove cargo details? This action cannot be undone.',
        action: this.removeCargoDetails,
      },
    };
  };

  renderFiles = (deliveryFormIndex: number): React.ReactNode => {
    return (
      <SectionContainer>
        {this.state.deliveryForms[deliveryFormIndex].attachment_files.map(
          (fileWithData, attachmentIndex) => (
            <FileSectionContainer key={`${deliveryFormIndex}'-'${attachmentIndex}`}>
              <FileInformationCard
                key={`${deliveryFormIndex}'-'${attachmentIndex}`}
                name={fileWithData.file.name}
                size={fileWithData.file.size}
                src={fileWithData.prviewUrl}
                fileType={fileWithData.file.type}
                onDeleteFile={(): void => {
                  this.onFileRemove(deliveryFormIndex, attachmentIndex);
                }}
              />
              {fileWithData && fileWithData.fileError && (
                <AttachmentFormErrorAlert status="error" noBackground>
                  {fileWithData.fileError}
                </AttachmentFormErrorAlert>
              )}
            </FileSectionContainer>
          )
        )}
      </SectionContainer>
    );
  };

  renderFilesUpload = (deliveryFormIndex: number = 0): React.ReactNode => {
    return (
      <div style={{ marginTop: '-1.5rem', marginBottom: '2rem' }}>
        <SelectFileButton
          buttonText="Upload"
          disabled={
            this.state.deliveryForms[deliveryFormIndex].attachment_files.length >= 5
          }
          buttonStyle={noMarginLeft}
          label="Attachments"
          labelStyle={attachmentLabelStyle as FlattenSimpleInterpolation}
          onFileChange={async (e): Promise<void> => {
            await this.onFileChange(e, deliveryFormIndex);
          }}
          acceptedFormat={'text/csv, .png, .jpg, .pdf, .doc, .docx, .xlsx, .csv'}
        >
          {this.renderUploadAttachmentDescription()}
        </SelectFileButton>
        {this.renderFiles(deliveryFormIndex)}
      </div>
    );
  };

  renderDeliveryVesselEmptyPlaceholder = (index: number): React.ReactNode => {
    const { deliveryForms, showDeliveryAddVesselModal } = this.state;
    return (
      <>
        {deliveryForms[index].to_contact &&
        deliveryForms[index].to_contact.vessel_name &&
        deliveryForms[index].to_contact.vessel_name.length >= 3 &&
        !showDeliveryAddVesselModal ? (
          <EmptyVesselPlaceholder>
            {this.state.isFetchingVessel ? (
              <div>Loading...</div>
            ) : (
              <>
                <div>Vessel name not found.</div>
                <EnterManualEmptyPlaceholderButton
                  onClick={(e: React.MouseEvent): void => {
                    e.stopPropagation();
                    this.setState({ showDeliveryAddVesselModal: index });
                  }}
                >
                  Enter manually
                </EnterManualEmptyPlaceholderButton>
              </>
            )}
          </EmptyVesselPlaceholder>
        ) : (
          false
        )}
      </>
    );
  };

  renderCargoDetails = (i: number = 0): React.ReactNode => {
    return (
      <div id="cargo-details">
        <CargoDetailsContainer>
          <CargoDetailsItemTitle>
            Cargo Details
            <ItemDescription>
              Add cargo information and the amount of each cargo.
              <br />
              Please indicate the cargo includes dangerous goods to prevent delays and
              ensure safety.{' '}
              <CustomLinkButton
                target="_blank"
                href="https://help.gotsurge.co/en/article/undeclared-dangerous-goods"
              >
                See more info
              </CustomLinkButton>
            </ItemDescription>
          </CargoDetailsItemTitle>

          {this.state.deliveryForms[i].cargo_details.map((item, k) => {
            const cargoDescription =
              this.state.deliveryForms[i].cargo_details[k].name || '';
            const isMinQty = this.state.deliveryForms[i].cargo_details[k].quantity <= 1;
            const canRemoveCargoByQty = isMinQty && cargoDescription !== '';
            const canRemoveCargo = cargoDescription !== '' || k !== 0;
            return (
              <span key={k}>
                <ItemDetailsContainer>
                  <OrderNumber>{k + 1}.</OrderNumber>

                  <DescriptionWrapper>
                    <ItemFieldName>
                      Description <RedText>*</RedText>
                    </ItemFieldName>

                    <Dropdown
                      width="full"
                      height="large"
                      label="Select Cargo"
                      modalTitle="Select Cargo Type"
                      isItemTitleBold={true}
                      isShowingModalListOnMobile={true}
                      withoutDefinedLabel={true}
                      options={CARGO_TYPES}
                      onChange={(value: string): void => {
                        this.updateDeliveryForm(`cargo_details[${k}].name`, value, i);
                      }}
                      value={this.state.deliveryForms[i].cargo_details[k].name}
                    />

                    {this.state.deliveryForms[i].cargo_details[k].name === 'others' && (
                      <>
                        <TextInput
                          containerStyle={css`
                            margin-top: 8px;
                          `}
                          width="full"
                          height="large"
                          autoComplete="none"
                          onTextChange={(value: string): void => {
                            this.updateDeliveryForm(
                              `cargo_details[${k}].remarks`,
                              value,
                              i
                            );
                          }}
                          type="text"
                          placeholder="Enter cargo type"
                          value={item.remarks || ''}
                        />
                      </>
                    )}
                  </DescriptionWrapper>

                  <QuantityWrapper>
                    <ItemFieldName>
                      Qty <RedText>*</RedText>
                    </ItemFieldName>

                    <CounterInputButton
                      value={item.quantity}
                      incrementValue={1}
                      decrementValue={1}
                      defaultEmptyValue={1}
                      onValueChange={(val): void => {
                        if (canRemoveCargoByQty && val !== '' && Number(val) < 1) {
                          this.setState({
                            showRemoveModal: {
                              deliveryFormIndex: i,
                              itemIndex: k,
                              actionType: 'cargo_details',
                            },
                          });
                        } else {
                          this.updateDeliveryForm(`cargo_details[${k}].quantity`, val, i);
                        }
                      }}
                      isDiscourageMinusButton={canRemoveCargoByQty}
                      isMinusButtonDisabled={isMinQty && !canRemoveCargoByQty}
                    />
                  </QuantityWrapper>

                  <QuantityUnitWrapper>
                    <ItemFieldName>Unit</ItemFieldName>
                    <TextInput
                      containerStyle={css`
                        margin-top: 8px;
                      `}
                      width="full"
                      height="large"
                      autoComplete="none"
                      placeholder="Pallet"
                      onTextChange={(value: string): void => {
                        this.updateDeliveryForm(
                          `cargo_details[${k}].quantity_unit`,
                          value,
                          i
                        );
                      }}
                      type="text"
                      value={item.quantity_unit || ''}
                    />
                  </QuantityUnitWrapper>

                  <SkuWrapper>
                    <ItemFieldName>SKU</ItemFieldName>
                    <TextInput
                      containerStyle={css`
                        margin-top: 8px;
                      `}
                      width="full"
                      height="large"
                      autoComplete="none"
                      placeholder="A123456ABC"
                      onTextChange={(value: string): void => {
                        this.updateDeliveryForm(`cargo_details[${k}].sku`, value, i);
                      }}
                      type="text"
                      value={item.sku || ''}
                    />
                  </SkuWrapper>

                  <WeightWrapper>
                    <ItemFieldName>Weight (kg)</ItemFieldName>

                    <CounterInputButton
                      value={item.weight}
                      incrementValue={1}
                      decrementValue={1}
                      isZeroNumberAllowed={true}
                      isFloatNumberAllowed={true}
                      onValueChange={(value: string): void => {
                        this.updateDeliveryForm(`cargo_details[${k}].weight`, value, i);
                      }}
                      isMinusButtonDisabled={
                        this.state.deliveryForms[i].cargo_details[k].weight <= 1
                      }
                    />
                  </WeightWrapper>

                  <WeightUnitWrapper>
                    <ItemFieldName>Unit</ItemFieldName>
                    <TextInput
                      containerStyle={css`
                        margin-top: 8px;
                      `}
                      placeholder="kg"
                      width="full"
                      height="large"
                      autoComplete="none"
                      type="text"
                      value={item.weight_unit || ''}
                    />
                  </WeightUnitWrapper>

                  <DimensionWrapper>
                    <InputDimensions
                      inputLabel={
                        <ItemFieldName>
                          Dimensions (L x W x H) per item (cm)
                        </ItemFieldName>
                      }
                      isFloatNumberAllowed={true}
                      lengthValue={item.dimension.length}
                      widthValue={item.dimension.width}
                      heightValue={item.dimension.height}
                      onLengthChange={(value) =>
                        this.updateDeliveryForm(
                          `cargo_details[${k}].dimension.length`,
                          value,
                          i
                        )
                      }
                      onWidthChange={(value) =>
                        this.updateDeliveryForm(
                          `cargo_details[${k}].dimension.width`,
                          value,
                          i
                        )
                      }
                      onHeightChange={(value) =>
                        this.updateDeliveryForm(
                          `cargo_details[${k}].dimension.height`,
                          value,
                          i
                        )
                      }
                    />
                  </DimensionWrapper>

                  <CargoHazardousMaterial>
                    <CheckBoxWrapper index={k}>
                      <Checkbox
                        onClick={(): void => {
                          this.updateDeliveryForm(
                            `cargo_details[${k}].has_hazard_mat`,
                            !this.state.deliveryForms[i].cargo_details[k].has_hazard_mat,
                            i
                          );
                        }}
                        selected={
                          this.state.deliveryForms[i].cargo_details[k].has_hazard_mat
                        }
                      >
                        <span style={{ fontSize: '14px' }}>Hazardous Material</span>
                      </Checkbox>
                    </CheckBoxWrapper>
                  </CargoHazardousMaterial>

                  {canRemoveCargo ? (
                    <RemoveItemWrapper className="remove-cargo-item">
                      <RemoveItemButton
                        size="xs"
                        buttonType="link"
                        buttonStyle="discourage"
                        fontWeight={400}
                        icon={<TrashIcon icon={faTrash} />}
                        onClick={(): void => {
                          this.setState({
                            showRemoveModal: {
                              deliveryFormIndex: i,
                              itemIndex: k,
                              actionType: 'cargo_details',
                            },
                          });
                        }}
                      >
                        Remove
                      </RemoveItemButton>
                    </RemoveItemWrapper>
                  ) : (
                    false
                  )}
                </ItemDetailsContainer>
              </span>
            );
          })}
          <Button
            buttonType="neutral"
            buttonStyle="encourage"
            disabled={this.areCargoDetailsRequiredFieldsEmpty(i)}
            onClick={(): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[i] = {
                  ...updatedDeliveryForms[i],
                  cargo_details: updatedDeliveryForms[i].cargo_details.concat({
                    id: '',
                    name: '',
                    quantity: 1,
                    quantity_unit: '',
                    remarks: '',
                    has_hazard_mat: false,
                    sku: '',
                    volume_unit: '',
                    weight_unit: '',
                    dimension: {
                      length: null,
                      width: null,
                      height: null,
                    },
                  }),
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
            icon={<FontAwesomeIcon icon={faPlus} />}
          >
            Add Item
          </Button>
          <TotalCargoContainer>
            <TotalCargo>Total Cargo</TotalCargo>
            <Lifts>
              {this.totalCargoLifts(i)} Lifts <LiftInfoIcon icon={faInfoCircle} />
            </Lifts>
          </TotalCargoContainer>
        </CargoDetailsContainer>
      </div>
    );
  };

  renderDeliveryForm = (i: number): React.ReactNode => {
    const { baseForm, deliveryForms } = this.state;
    const { organization } = this.props;
    const showDashedLine = i !== this.state.deliveryForms.length - 1;
    const prefixID = (organization && organization.prefix_id) || 'GOTSURGE-';
    const isMarine = baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const instructionToDriver = isMarine
      ? 'E.g. Meet at the loading bay'
      : 'E.g. Meet at the lobby';
    return (
      <section>
        <CardRow>
          <CircleIcon color={COLOR.yellow} icon={faCircle} />
          <AlphabetContainer>{i + 1}.</AlphabetContainer>
          <Heading>
            <Title>Send To</Title>
            <TitleDescription>Enter the customers details</TitleDescription>
          </Heading>
          {deliveryForms.length > 1 && (
            <RemoveDeliveryLocationButton
              buttonType="neutral"
              buttonStyle="discourage"
              onClick={(): void => {
                this.setState({ showRemoveDeliveryDetailsModal: i });
              }}
            >
              <TrashIcon icon={faTrash} />
              Remove
            </RemoveDeliveryLocationButton>
          )}
        </CardRow>
        <CardRow>
          {showDashedLine && <DashedLine />}
          <Content>
            <div>{this.renderDeliveryLocation(i)}</div>
            <div>
              <DateAndTimeContainer>
                <DatePickerWrapper
                  minDate={moment().toDate()}
                  dateFormat="dd MMM, HH:mm"
                  timeFormat="HH:mm"
                  onChange={(value: Date): void => {
                    if (value instanceof Date) {
                      this.updateDeliveryForm('to_time_window.start_time_utc', value, i);
                      this.updateDeliveryForm(
                        'to_time_window.end_time_utc',
                        moment(value).add(1, 'hour').toDate(),
                        i
                      );
                    }
                  }}
                  onSelect={(value: Date): void =>
                    this.updateDeliveryForm('to_time_window.start_time_utc', value, i)
                  }
                  selected={this.state.deliveryForms[i].to_time_window.start_time_utc}
                  showTimeSelect
                  customInput={
                    <TextInput
                      autoComplete="none"
                      inputName="name"
                      containerStyle={inlineTextInputStyle}
                      isRequired
                      fieldName="Delivery Date & Time"
                      width="full"
                    />
                  }
                  calendarContainer={Calendar}
                />
                <TextInput
                  autoComplete="none"
                  containerStyle={inlineTextInputStyle}
                  fieldName={this.renderInstructionsLabel()}
                  onTextChange={(value: string): void => {
                    this.updateDeliveryForm('delivery_note_to_driver', value, i);
                  }}
                  placeholder={instructionToDriver}
                  width="half"
                  value={this.state.deliveryForms[i].delivery_note_to_driver}
                />
              </DateAndTimeContainer>
              {this.renderTimeRangeError(this.state.deliveryForms[i].to_time_window)}
              {this.state.deliveryForms[i].to_time_window.start_time_utc &&
                this.compareDateTime(
                  this.state.baseForm.from_time_window.start_time_utc,
                  this.state.deliveryForms[i].to_time_window.start_time_utc
                ) > 0 && (
                  <FormErrorAlert status="error" noBackground display="row">
                    The delivery time must be later than pickup time
                  </FormErrorAlert>
                )}
            </div>
            {/* {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
              <div>
                <SelectFileButton
                  buttonText="Upload"
                  disabled={this.state.deliveryForms[i].attachment_files.length >= 5}
                  buttonStyle={noMarginLeft}
                  label="Attachments"
                  labelStyle={attachmentLabelStyle}
                  onFileChange={async (e): Promise<void> => {
                    await this.onFileChange(e, i);
                  }}
                  acceptedFormat={'text/csv, .png, .jpg, .pdf, .doc, .docx, .xlsx, .csv'}
                >
                  {this.renderUploadAttachmentDescription()}
                </SelectFileButton>
                {this.renderFiles(i)}
              </div>
            )} */}
            {this.state.baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine ? (
              <>
                <div>
                  <ItemTitle>Additional Info</ItemTitle>

                  <TextInput
                    autoComplete="none"
                    inputName="tracking-id"
                    containerStyle={inlineTextInputStyle}
                    fieldName="Reference ID"
                    leftLabel={prefixID}
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm('tracking_id', value, i);
                    }}
                    placeholder="4297"
                    width="medium"
                    value={this.state.deliveryForms[i].tracking_id}
                  />
                  <TextInput
                    autoComplete="none"
                    inputName="invoice-number"
                    containerStyle={inlineTextInputStyle}
                    fieldName="Invoice No."
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm('invoice_number', value, i);
                    }}
                    placeholder="GOT934298"
                    width="small"
                    value={this.state.deliveryForms[i].invoice_number}
                  />
                  <TextInput
                    autoComplete="none"
                    inputName="group-tag"
                    containerStyle={inlineTextInputStyle}
                    fieldName="Group Tag"
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm('group_tag', value, i);
                    }}
                    width="small"
                    value={this.state.deliveryForms[i].group_tag}
                  />
                  {this.renderReferenceID(
                    prefixID,
                    this.state.deliveryForms[i].tracking_id
                  )}
                  {organization && (
                    <Link to={`/sys/organizations/${organization.id}/company-details`}>
                      <LinkToSettingButton>Go to Setting</LinkToSettingButton>
                    </Link>
                  )}
                </div>

                <div id="item-summary">
                  <ItemTitle>
                    Item Details
                    <ItemDescription>
                      Add item information and the amount of each item.
                    </ItemDescription>
                  </ItemTitle>

                  {this.state.deliveryForms[i].items.map((item, j) => {
                    const itemDescription =
                      this.state.deliveryForms[i].items[j].name || '';
                    const isMinQty = this.state.deliveryForms[i].items[j].quantity <= 1;
                    const canRemoveItemByQty = isMinQty && itemDescription !== '';
                    const canRemoveItem = itemDescription !== '' || j !== 0;
                    return (
                      <span key={j}>
                        <ItemDetailsContainer>
                          <OrderNumber>{j + 1}.</OrderNumber>

                          <DescriptionWrapper>
                            <ItemFieldName>Description</ItemFieldName>
                            <TextInput
                              autoComplete="none"
                              containerStyle={
                                j === 0 ? inlineTextInputStyle : inlineItemTextInputStyle
                              }
                              onTextChange={(value: string): void => {
                                this.updateDeliveryForm(`items[${j}].name`, value, i);
                              }}
                              width="full"
                              placeholder="E.g Parcel, Box"
                              value={item.name}
                            />
                          </DescriptionWrapper>

                          <QuantityWrapper>
                            <ItemFieldName>Qty</ItemFieldName>

                            <CounterInputButton
                              value={item.quantity}
                              incrementValue={1}
                              decrementValue={1}
                              defaultEmptyValue={item.weight ? 1 : ''}
                              onValueChange={(val): void => {
                                if (canRemoveItemByQty && val !== '' && Number(val) < 1) {
                                  this.setState({
                                    showRemoveModal: {
                                      deliveryFormIndex: i,
                                      itemIndex: j,
                                      actionType: 'items',
                                    },
                                  });
                                } else {
                                  this.updateDeliveryForm(`items[${j}].quantity`, val, i);
                                }
                              }}
                              isDiscourageMinusButton={canRemoveItemByQty}
                              isMinusButtonDisabled={isMinQty && !canRemoveItemByQty}
                            />
                          </QuantityWrapper>

                          <QuantityUnitWrapper>
                            <ItemFieldName>Unit</ItemFieldName>
                            <TextInput
                              containerStyle={css`
                                margin-top: 8px;
                              `}
                              width="full"
                              height="large"
                              autoComplete="none"
                              placeholder="Pallet"
                              onTextChange={(value: string): void => {
                                this.updateDeliveryForm(
                                  `items[${j}].quantity_unit`,
                                  value,
                                  i
                                );
                              }}
                              type="text"
                              value={item.quantity_unit || ''}
                            />
                          </QuantityUnitWrapper>

                          <SkuWrapper>
                            <ItemFieldName>SKU</ItemFieldName>
                            <TextInput
                              containerStyle={css`
                                margin-top: 8px;
                              `}
                              width="full"
                              height="large"
                              autoComplete="none"
                              placeholder="A123456ABC"
                              onTextChange={(value: string): void => {
                                this.updateDeliveryForm(`items[${j}].sku`, value, i);
                              }}
                              type="text"
                              value={item.sku || ''}
                            />
                          </SkuWrapper>
                          <WeightWrapper>
                            <ItemFieldName>Weight (kg)</ItemFieldName>

                            <CounterInputButton
                              value={item.weight}
                              incrementValue={1}
                              decrementValue={1}
                              isZeroNumberAllowed={true}
                              isFloatNumberAllowed={true}
                              onValueChange={(value: string): void => {
                                this.updateDeliveryForm(`items[${j}].weight`, value, i);
                              }}
                              isMinusButtonDisabled={
                                this.state.deliveryForms[i].items[j].weight <= 1
                              }
                            />
                          </WeightWrapper>
                          <WeightUnitWrapper>
                            <ItemFieldName>Unit</ItemFieldName>
                            <TextInput
                              containerStyle={css`
                                margin-top: 8px;
                              `}
                              placeholder="kg"
                              width="full"
                              height="large"
                              autoComplete="none"
                              type="text"
                              value={item.weight_unit || ''}
                            />
                          </WeightUnitWrapper>
                          <DimensionWrapper>
                            <InputDimensions
                              inputLabel={
                                <ItemFieldName>
                                  Dimensions (L x W x H) per item (cm)
                                </ItemFieldName>
                              }
                              isFloatNumberAllowed={true}
                              lengthValue={item.dimension.length}
                              widthValue={item.dimension.width}
                              heightValue={item.dimension.height}
                              onLengthChange={(value) =>
                                this.updateDeliveryForm(
                                  `items[${j}].dimension.length`,
                                  value,
                                  i
                                )
                              }
                              onWidthChange={(value) =>
                                this.updateDeliveryForm(
                                  `items[${j}].dimension.width`,
                                  value,
                                  i
                                )
                              }
                              onHeightChange={(value) =>
                                this.updateDeliveryForm(
                                  `items[${j}].dimension.height`,
                                  value,
                                  i
                                )
                              }
                            />
                          </DimensionWrapper>

                          <CargoHazardousMaterial>
                            <CheckBoxWrapper index={j}>
                              <Checkbox
                                onClick={(): void => {
                                  this.updateDeliveryForm(
                                    `items[${j}].has_hazard_mat`,
                                    !this.state.deliveryForms[i].items[j].has_hazard_mat,
                                    i
                                  );
                                }}
                                selected={
                                  this.state.deliveryForms[i].items[j].has_hazard_mat
                                }
                              >
                                <span style={{ fontSize: '14px' }}>
                                  Hazardous Material
                                </span>
                              </Checkbox>
                            </CheckBoxWrapper>
                          </CargoHazardousMaterial>
                          {canRemoveItem ? (
                            <RemoveItemWrapper className="remove-item">
                              <RemoveItemButton
                                size="xs"
                                buttonType="link"
                                buttonStyle="discourage"
                                fontWeight={400}
                                icon={<TrashIcon icon={faTrash} />}
                                onClick={(): void => {
                                  this.setState({
                                    showRemoveModal: {
                                      deliveryFormIndex: i,
                                      itemIndex: j,
                                      actionType: 'items',
                                    },
                                  });
                                }}
                              >
                                Remove
                              </RemoveItemButton>
                            </RemoveItemWrapper>
                          ) : (
                            false
                          )}
                        </ItemDetailsContainer>
                      </span>
                    );
                  })}
                  <Button
                    buttonType="neutral"
                    buttonStyle="encourage"
                    onClick={(): void => {
                      this.setState((prevState) => {
                        const updatedDeliveryForms = [...prevState.deliveryForms];
                        updatedDeliveryForms[i] = {
                          ...updatedDeliveryForms[i],
                          items: updatedDeliveryForms[i].items.concat({
                            name: '',
                            quantity: undefined,
                            has_hazard_mat: false,
                            dimension: {
                              length: null,
                              width: null,
                              height: null,
                            },
                          }),
                        };
                        return {
                          deliveryForms: updatedDeliveryForms,
                        };
                      });
                    }}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                  >
                    Add Item
                  </Button>
                </div>
              </>
            ) : null}
            {this.state.baseForm.broadcast_preference ===
              BROADCAST_PREFERENCES.marine && (
              <>
                {this.renderMarineVehicleForm(i)}
                <div style={{ marginTop: '0.75rem' }}>
                  <TextInput
                    autoComplete="none"
                    inputName="tracking-id"
                    containerStyle={inlineTextInputStyle}
                    fieldName="Reference ID"
                    leftLabel={prefixID}
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm('tracking_id', value, i);
                    }}
                    placeholder="4297"
                    width="medium"
                    value={this.state.deliveryForms[i].tracking_id}
                  />
                  <TextInput
                    autoComplete="none"
                    inputName="invoice-number"
                    containerStyle={inlineTextInputStyle}
                    fieldName="Invoice No."
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm('invoice_number', value, i);
                    }}
                    placeholder="GOT934298"
                    width="small"
                    value={this.state.deliveryForms[i].invoice_number}
                  />
                  <TextInput
                    autoComplete="none"
                    inputName="group-tag"
                    containerStyle={inlineTextInputStyle}
                    fieldName="Group Tag"
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm('group_tag', value, i);
                    }}
                    width="small"
                    value={this.state.deliveryForms[i].group_tag}
                  />
                  {this.renderReferenceID(
                    prefixID,
                    this.state.deliveryForms[i].tracking_id
                  )}
                  {organization && (
                    <Link to={`/sys/organizations/${organization.id}/company-details`}>
                      <LinkToSettingButton>Go to Setting</LinkToSettingButton>
                    </Link>
                  )}
                </div>
              </>
            )}
          </Content>
        </CardRow>
        {showDashedLine && (
          <CardRow>
            <DashedLine />
            <AddressSeparator />
          </CardRow>
        )}
      </section>
    );
  };

  renderMaxAcceptanceTime = (
    minPrice: number,
    maxPrice: number,
    increment: number
  ): React.ReactNode => {
    if (minPrice < maxPrice) {
      const minutes =
        (Math.ceil((maxPrice - minPrice) / increment) + 1) * BROADCAST_MINUTES;

      return <article>Max order acceptance time: {formatDuration(minutes * 60)}</article>;
    }

    return false;
  };

  renderRecommendPrice = (): React.ReactNode => {
    return (
      <>
        <RecommendPriceContainer>
          <RecommendPriceText>
            <>Not sure what price to set?</>
            <RecommendPriceHelp>
              To get a price recommendation, addresses must be validated
            </RecommendPriceHelp>
          </RecommendPriceText>
          <RecommendPriceButton
            type="button"
            buttonType="neutral"
            buttonStyle="encourage"
            disabled={this.canRecommendPrice()}
            onClick={this.recommendPrice}
          >
            <Icon
              color={this.canRecommendPrice() ? COLOR.grey : COLOR.blue}
              icon={
                this.canRecommendPrice() && this.state.baseForm.vehicle_preference !== ''
                  ? faCircleNotch
                  : faBinoculars
              }
            />
            Recommend Price
          </RecommendPriceButton>
        </RecommendPriceContainer>
        {this.state.showRecommendPriceSuccess ? (
          <RecommendPriceAlert status="success">
            <TextBold>
              We recommend prices based on current market conditions to increase
              likelihood of order acceptance. You can change the suggested prices if they
              do not fit your budget.
            </TextBold>
          </RecommendPriceAlert>
        ) : (
          false
        )}
        {this.state.showRecommendPriceInfo ? (
          <RecommendPriceAlert status="info">
            <TextBold>
              Valid addresses is required to get a price recommendation. Please fill in
              the correct address to get a price recommendation
            </TextBold>
          </RecommendPriceAlert>
        ) : (
          false
        )}
        {this.state.showRecommendPriceError ? (
          <ErrorAlert status="error">
            <TextBold>{RECOMMENDATION_ERROR}</TextBold>
          </ErrorAlert>
        ) : (
          false
        )}
      </>
    );
  };

  renderInstructionsLabel = (): React.ReactNode => {
    return (
      <InstructionsLabel>
        <>Instructions to Driver</>
        <RecommendedBadge>Recommended</RecommendedBadge>
      </InstructionsLabel>
    );
  };

  renderMinMaxPrice(): React.ReactNode {
    const { baseForm } = this.state;
    return (
      <>
        <div>
          Alert drivers starting from a base price per location that increases to your max
          budget until a driver is found.
        </div>
        <PriceContent>
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Minimum Price SGD"
              isRequired
              placeholder="5.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('min_price', value);
              }}
              type="number"
              width="medium"
              value={baseForm.min_price / ONE_SGD || ''}
            />
            <Icon icon={faArrowRight} />
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Maximum Price SGD"
              isRequired
              placeholder="15.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('max_price', value);
              }}
              type="number"
              width="medium"
              value={baseForm.max_price / ONE_SGD || ''}
            />
          </div>
          {baseForm.max_price < baseForm.min_price && (
            <Alert status="error">
              Set a Maximum Price that is higher than the Minimum Price. Alternatively,
              set a lower Minimum Price.
            </Alert>
          )}
          {baseForm.max_price === baseForm.min_price && (
            <Alert status="info">
              There will be no increment, your orders will be broadcasted at this same
              price throughout.
            </Alert>
          )}
          {this.renderRecommendPrice()}
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Price Increment SGD"
              isRequired
              placeholder="1.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('increment', value);
              }}
              type="number"
              width="medium"
              value={baseForm.increment / ONE_SGD || ''}
              min={1}
              step="any"
              max={baseForm.max_price / ONE_SGD}
            />
            <span>every {BROADCAST_MINUTES} minutes</span>
          </div>
          {this.renderMaxAcceptanceTime(
            baseForm.min_price,
            baseForm.max_price,
            baseForm.increment
          )}
        </PriceContent>
      </>
    );
  }

  renderSinglePrice(): React.ReactNode {
    return (
      <>
        <div>Alert drivers at a fixed price per location until a driver is found.</div>
        <PriceContent>
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Price SGD"
              isRequired
              placeholder="5.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('min_price', value);
              }}
              type="number"
              width="medium"
              value={this.state.baseForm.min_price / ONE_SGD || ''}
              min={1}
            />
          </div>
        </PriceContent>
        {this.renderRecommendPrice()}
      </>
    );
  }

  renderConfirmOrderModal = (): React.ReactNode => {
    return (
      <>
        {this.state.showConfirmOrderModal && (
          <CenterModal title="Confirm Order?">
            <Checkbox
              onClick={(): void => {
                this.setState((prevState) => ({
                  orderDeclaration: {
                    ...prevState.orderDeclaration,
                    correctAndComplete: !prevState.orderDeclaration.correctAndComplete,
                  },
                }));
              }}
              selected={this.state.orderDeclaration.correctAndComplete}
            >
              <ConfirmOrderModalText>
                I confirm that the description and specifics of my delivery details are
                correct and complete.
              </ConfirmOrderModalText>
            </Checkbox>
            <ConfirmOrderModalButtons>
              <StyledButton
                buttonStyle="encourage"
                buttonType="neutral"
                onClick={(): void => {
                  this.setState({ showConfirmOrderModal: false });
                }}
              >
                Back
              </StyledButton>
              <StyledButton
                type="submit"
                buttonType="neutral"
                buttonStyle="encourage"
                disabled={!this.allowCreation()}
              >
                Confirm
              </StyledButton>
            </ConfirmOrderModalButtons>
          </CenterModal>
        )}
      </>
    );
  };

  renderOrderPlacedModal = (): React.ReactNode => {
    const { isSuccessful, submittedTasks, baseForm } = this.state;
    const { organization } = this.props;
    return (
      <>
        {isSuccessful && (
          <>
            {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine &&
            submittedTasks.length > 0 ? (
              <ShareOrderDetailModal
                title="Order Confirmed"
                closeModal={(): void =>
                  this.props.history.push(
                    `/sys/tasks?is_qa=${baseForm.is_qa ? 'true' : 'false'}`
                  )
                }
                header={
                  <>
                    <Alert status="success">
                      You have successfully placed your order.
                    </Alert>
                    <ShareText>
                      Share the order details using the provided template to transporters.
                    </ShareText>
                  </>
                }
              >
                Hi, {submittedTasks.length} new order
                {submittedTasks.length > 1 && 's'} from{' '}
                {organization && organization.business_name}
                <br />
                <br />
                {(submittedTasks || []).map((task: TaskAPIResponse, i: number) => {
                  let vehicleInfo = `1 x ${
                    VEHICLE_PREFERENCE_DESCRIPTION[task.vehicle_preference]
                  }`;
                  if (task.vehicle_preference === 'ltl') {
                    const cargoLifts = task.cargo_details.reduce((acc, item) => {
                      return acc + item.quantity;
                    }, 0);
                    vehicleInfo = `${cargoLifts} Lifts`;
                  }
                  return (
                    <>
                      {submittedTasks.length > 1 && `${i + 1}) `}
                      {task && task.vehicle_preference && (
                        <>
                          {vehicleInfo}
                          <br />
                          <br />
                        </>
                      )}
                      Vessel:{' '}
                      {(task && task.to_contact && task.to_contact.vessel_name) || 'TBD'}
                      <br />
                      Pickup:{' '}
                      {task &&
                        task.from_time_window &&
                        DateHelper.formatSimpleDateTime(
                          task.from_time_window.start_time_utc
                        )}{' '}
                      ({task.from_address.street_address})<br />
                      Delivery:{' '}
                      {task &&
                        task.to_time_window &&
                        DateHelper.formatSimpleDateTime(
                          task.to_time_window.start_time_utc
                        )}{' '}
                      ({task.to_address.street_address})<br />
                      Lighter Company:{' '}
                      {(task &&
                        task.to_contact &&
                        task &&
                        task.to_contact.lighter_company_name) ||
                        'TBD'}
                      <br />
                      Lighter Boat:{' '}
                      {(task &&
                        task.to_contact &&
                        task &&
                        task.to_contact.lighter_boat_name) ||
                        'TBD'}
                      <br />
                      <br />
                    </>
                  );
                })}
                Able to support?
              </ShareOrderDetailModal>
            ) : (
              <CenterModal
                title="Order Confirmed"
                rightButtonText="OK"
                rightButtonOnClick={(): void => {
                  this.props.history.push(
                    `/sys/tasks?is_qa=${baseForm.is_qa ? 'true' : 'false'}`
                  );
                }}
              >
                <Alert status="success">You have successfully placed your order.</Alert>
              </CenterModal>
            )}
          </>
        )}
      </>
    );
  };

  renderSwitchViewWarningModal = (): React.ReactNode => (
    <CenterModal
      title="Switch view?"
      leftButtonText="Cancel"
      leftButtonOnClick={(): void => {
        this.setState({ showSwitchViewModal: false });
      }}
      rightButtonText="OK"
      rightButtonOnClick={(): void => {
        this.props.history.push('/sys/tasks/imports/new', {
          orgId: this.state.baseForm.org_id,
          vehiclePreference: this.state.baseForm.vehicle_preference,
          broadcastPreference: this.state.baseForm.broadcast_preference,
        });
      }}
    >
      The added order details will be lost.
    </CenterModal>
  );

  renderActionButtons(): React.ReactNode {
    return (
      <ActionContainer>
        <ActionButtonContainer>
          <Button
            type="button"
            buttonType="neutral"
            buttonStyle="encourage"
            fontWeight={400}
            onClick={(): void => {
              this.props.history.push('/sys/tasks');
            }}
          >
            <Icon color={COLOR.darkGray} icon={faChevronLeft} />
            Back
          </Button>
          <Button
            type="button"
            buttonType="primary"
            buttonStyle="encourage"
            disabled={!this.allowConfirmOrder()}
            onClick={(): void => {
              this.setState({ showConfirmOrderModal: true });
            }}
          >
            <Icon color={COLOR.white} icon={faCheck} />
            Place Order
          </Button>
        </ActionButtonContainer>
      </ActionContainer>
    );
  }

  render(): React.ReactNode {
    const { baseForm } = this.state;
    const isBroadcastPreferenceMarine =
      baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine;
    let handleFormSubmit = this.handleSubmit;
    if (isBroadcastPreferenceMarine) {
      handleFormSubmit = this.handleSubmitMarineOrders;
    }
    return (
      <SysAdminMainContainer selected="tasks">
        <Breadcrumb
          items={[
            {
              text: 'Orders',
              onClick: (): void => {
                this.props.history.push('/sys/tasks');
              },
            },
            { text: 'Fill Form' },
          ]}
        />
        <form
          onSubmit={handleFormSubmit}
          onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>): void => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          {this.renderGeneralInfoForm()}
          {this.renderOrderForm()}
          {(baseForm.broadcast_preference === BROADCAST_PREFERENCES.all ||
            baseForm.broadcast_preference === BROADCAST_PREFERENCES.squad) && (
            <>
              {this.renderServiceAndVehicleSelect()}
              {this.renderPriceForm()}
            </>
          )}
          {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
            <>
              <Card>
                {this.renderFilesUpload()}
                {this.renderCargoDetails()}
              </Card>
              {this.renderAdditionalService()}
            </>
          )}
          {this.renderLocateMapModal()}
          {this.renderConfirmOrderModal()}
          {this.renderOrderPlacedModal()}
          {this.renderActionButtons()}
        </form>
        {this.state.showSwitchViewModal && this.renderSwitchViewWarningModal()}
        {typeof this.state.showRemoveDeliveryDetailsModal === 'number' &&
          this.renderRemoveDeliveryDetailsWarningModal()}
        {typeof this.state.showRemoveModal.deliveryFormIndex === 'number' &&
          this.renderRemoveCargoDetailsOrItemsModal()}
        {this.renderRemoveSuccessModal()}
        {this.renderAddVesselModal()}
        {this.renderAddressContactModal()}
        {typeof this.state.showSwitchServiceWarningModal === 'string' &&
          this.state.showSwitchServiceWarningModal !== '' &&
          this.renderSwitchServiceWarningModal()}
      </SysAdminMainContainer>
    );
  }
}

const DateAndTimeContainer = styled.div<{ alignItems?: string }>`
  display: flex;
  margin-bottom: 1px;
  align-items: ${(props): string => props.alignItems ?? 'flex-end'};
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DatePickerWrapper = styled(({ className, ...props }) => (
  <DatePicker {...props} wrapperClassName={className} />
))<{ width?: string; marginRight?: string }>`
  display: flex;
  align-items: flex-end;
  margin-right: ${(props): string => props.marginRight || '0px'};
  width: ${(props): string => props.width || '11rem'};
  /* @media (max-width: 768px) {
    width: 100%;
  } */
`;

const Card = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${(props): string => props.theme.colors.gray_200};
  background-color: ${(props): string => props.theme.colors.base_white};
  box-shadow: 0px 2px 6px 0px ${(props): string => props.theme.colors.gray_200},
    0px 1px 1px 2px rgba(222, 222, 222, 0.06);
  padding: 1.875rem 2.125rem;
  /* padding: 1rem; */
  margin-bottom: 1.75rem;

  @media screen and (max-width: 600px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`;

const PriceInfoCard = styled(Card)`
  margin-bottom: 6rem;
`;

const Title = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const MobileResponsiveIcon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const Icon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;
`;

const TrashIcon = styled(FontAwesomeIcon)`
  margin-right: 0rem;
`;

const RemoveCargoDetailsIcon = styled(FontAwesomeIcon)<RemoveCargoDetailsIconProps>`
  color: ${(props): string => props.color};
  font-size: ${(props): string => (props.fontSize ? props.fontSize : '1rem')};
`;

const RemoveCargoDetailsSubTitle = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const RemoveItemButton = styled(StyledButton)`
  border-width: 0;
  background-color: ${COLOR.white};

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 8px;
  }
`;

const RemoveDeliveryLocationButton = styled(StyledButton)`
  border-width: 0;
  visibility: hidden;
  section:hover & {
    visibility: visible;
    background-color: ${COLOR.white};
  }

  @media (max-width: 768px) {
    visibility: visible;
    background-color: ${COLOR.white};
  }
`;

const CircleIcon = styled(FontAwesomeIcon)<{ color: string }>`
  color: ${(props): string => props.color};
  font-size: 0.5rem;
  margin-right: 0.5rem;
  align-self: center;
`;

const Separator = styled(BasicSeparator)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const AddressSeparator = styled(Separator)`
  margin-left: 1.5rem;
  width: 100%;
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const AlphabetContainer = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 1.5rem;
  margin-right: 0.75rem;
  color: ${COLOR.darkGray};

  @media (max-width: 769px) {
    margin-left: 0.75rem;
  }
`;

const Content = styled.div`
  flex: 1;
  margin-left: 3rem;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-right: 0rem;
  display: flex;
  flex-direction: column;
  width: 90%;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media (max-width: 769px) {
    margin-left: 0;
  }
`;

const PriceContent = styled(Content)`
  margin-left: 0;

  @media (max-width: 769px) {
    padding-left: 0;
  }
`;

const AdditionalServiceContent = styled(Content)`
  padding-top: 0;
  padding-bottom: 1rem;
  margin-left: 0;
  padding-left: 1.7rem;
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

const TitleDescription = styled.div`
  color: ${COLOR.midDarkGrey};
`;

const Heading = styled.div`
  margin-right: auto;
`;

const ItemTitle = styled.div`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const CargoDetailsItemTitle = styled(ItemTitle)`
  @media (max-width: 768px) {
    margin-left: -1.5rem;
  }
`;

const ItemDescription = styled.p`
  color: ${(props): string => props.theme.colors.gray_400};
  font-size: ${(props): string => props.theme.fontSizes.xs};
  margin-top: 0.45rem;
  font-weight: 400;
`;

const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const FormErrorAlert = styled(Alert)`
  margin-top: 0.5rem;
`;

const AttachmentFormErrorAlert = styled(Alert)`
  margin-top: 0.5rem;
  max-width: 14rem;
`;

const RedText = styled.span`
  color: ${COLOR.red};
`;

const Button = styled(StyledButton)`
  margin-right: 1rem;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const IconText = styled.span`
  @media (max-width: 769px) {
    display: none;
  }
`;

const InstructionsLabel = styled.div`
  display: flex;
  align-items: end;
`;

const RecommendedBadge = styled.div`
  font-weight: 600;
  font-size: 0.8rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.3rem;
  border-radius: 6px;
  color: ${COLOR.black};
  background-color: ${COLOR.lightGrey};
`;

const LinkToSettingButton = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
  width: 7rem;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  margin-right: 0.35rem;
`;

const InlineErrorMessage = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const RecommendPriceContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RecommendPriceText = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`;

const RecommendPriceAlert = styled(Alert)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const RecommendPriceHelp = styled.div`
  color: ${COLOR.midDarkGrey};
  font-size: 0.875rem;
`;

const RecommendPriceButton = styled(Button)`
  margin-left: 1rem;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const TextBold = styled.div`
  font-weight: 600;
`;

const ActionButtonContainer = styled.div`
  padding: 1rem 3rem;

  @media (max-width: 768px) {
    padding: 1rem 1rem 1rem 2rem;
    display: flex;
    justify-content: space-between;
  }
`;

const ActionContainer = styled.section`
  background-color: ${COLOR.whiteGrey};
  box-shadow: 0px 8px 18px ${transparent('shadow', 80)};
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 14;

  @media (max-width: 768px) {
    padding-bottom: 3rem;
    height: 1.5rem;
  }
`;

const GeneralInfoFormContainer = styled.div`
  margin-top: 1rem;
`;

const ConfirmOrderModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

const ConfirmOrderModalText = styled.div`
  margin-left: 0.5rem;
`;

const AdditionalServiceCard = styled(Card)`
  margin-bottom: 6rem;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const LabelDescription = styled.div`
  margin-bottom: 0.5rem;
`;

const Span = styled.span`
  font-size: 0.8rem;
  color: gray;
`;

const FileSectionContainer = styled.div`
  flex-direction: column;
`;

const SectionContainer = styled.div`
  margin-bottom: 1rem;
  flex-wrap: wrap;
  display: flex;
`;

const ShareText = styled.div`
  margin-top: 1.5rem;
`;

const EnterManualEmptyPlaceholderButton = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
  width: 7rem;
  margin-top: 0.1rem;
`;

const EnterManualButton = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
  width: 7rem;
  margin-top: 0.5rem;
`;

const ImoText = styled.div`
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
`;

const ImoInfoAlert = styled(Alert)`
  max-width: 24rem;
`;

const EmptyVesselPlaceholder = styled.div`
  background-color: ${COLOR.white};
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLOR.blue};
  color: ${COLOR.black};
  outline: none;
  overflow-y: auto;
  max-height: 15rem;
  padding: 0.8rem;
  z-index: 1;
`;

const VesselCardRow = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const ItemFieldName = styled.p`
  font-size: 0.875rem;
  margin: 0 0 6px 0;
`;

const CheckBoxWrapper = styled.div<{ index: number }>`
  white-space: nowrap;
  /* @media (min-width: 768px) {
    margin-left: 1.5rem;
  } */
`;

const ItemDetailsContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 18px;
  grid-column-gap: 16px;
  margin-bottom: 1.5rem;
  max-width: 530px;

  @media (min-width: 590px) and (max-width: 785px) {
    grid-template-columns: 35% 1fr;
  }

  @media (min-width: 786px) {
    padding-left: 23px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const OrderNumber = styled.span`
  position: absolute;
  left: -24px;
  top: 32px;

  @media (min-width: 786px) {
    left: 0;
  }
`;

const DescriptionWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: end;

  @media (min-width: 786px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const QuantityWrapper = styled.div`
  @media (min-width: 786px) {
    grid-column: 1;
  }
`;

const SkuWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: end;
  max-width: 50%;

  @media (min-width: 786px) {
    grid-column-start: 3;
    grid-column-end: 4;
    max-width: 100%;
  }
`;

const DimensionWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: end;
  @media (min-width: 786px) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const CargoHazardousMaterial = styled.div`
  @media (min-width: 786px) {
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 2;
  }
`;

const RemoveItemWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: end;
  @media (min-width: 786px) {
    position: absolute;
    top: 2rem;
    right: -16rem;
  }
`;

const QuantityUnitWrapper = styled.div``;

const WeightWrapper = styled.div``;

const WeightUnitWrapper = styled.div`
  visibility: hidden;
`;

const TotalCargoContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 680px;
  align-items: center;
`;

const TotalCargo = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
`;

const Lifts = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

const LiftInfoIcon = styled(FontAwesomeIcon)`
  color: ${(props): string => props.theme.colors.primary_600};
`;

const SubTitle = styled.div`
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const SubTitleContainer = styled.div`
  display: flex;
  margin-top: 0.2rem;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 0;
`;

const CustomLinkButton = styled.a`
  display: inline-block;
`;

const CargoDetailsContainer = styled.div`
  @media screen and (max-width: 768px) {
    padding-left: 1.5rem;
  }
`;

const SelectContactButton = styled(StyledButton)`
  display: flex;
  flex-direction: row;
  height: fit-content;
  padding: 1rem;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

const SelectContactText = styled.div`
  width: 100%;
  text-align: center;
`;

const DeliveryLocationPanel = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const mapStateToProps = (state: RootState): SysAdminTaskCreateProps => ({
  organizations: state.organization.organizations,
  organization: state.organization.organization,
  squadDrivers: state.organization.squadDrivers,
  geoDatas: state.geoService.geoDatas,
  savedGeoDatas: state.geoService.savedGeoDatas,
  vessels: state.vessel.vessels,
});

export default connect(mapStateToProps)(SysAdminTaskNew);
