import * as React from 'react';
import { format } from 'date-fns';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import { Epod } from 'models/task/epod';
import { styles } from 'components/pdf/epod/styles';
import HeaderTitle from 'components/pdf/epod/headerTitle';
import ItemsTable from 'components/pdf/epod/tableItems';
import OrderInfo from 'components/pdf/epod/orderInfo';
import VesselDetail from 'components/pdf/epod/vesselDetail';
import DeliveryDetail from 'components/pdf/epod/deliveryDetail';
import CareOf from 'components/pdf/epod/careOf';
import Divider from 'components/pdf/epod/divider';
import DeliveryNote from 'components/pdf/epod/deliveryNote';
import ReleasedTo from 'components/pdf/epod/releasedTo';
import CompletionInfo from 'components/pdf/epod/completionInfo';
import EndorseSection from 'components/pdf/epod/endorseSection';
import AttachmentSection from 'components/pdf/epod/attachmentSection';
import FooterText from 'components/pdf/epod/footerText';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import { Attachment } from 'models/task';

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

interface ItemsSectionProps {
  children?: React.ReactNode;
  broadcastPreference: string;
}
const ItemsSection = ({ children, broadcastPreference }: ItemsSectionProps) => {
  const title =
    broadcastPreference === BROADCAST_PREFERENCES.marine
      ? 'Cargo Details'
      : 'Item Details';
  return (
    <View
      style={{
        flexDirection: 'column',
      }}
    >
      <Text style={[styles.titleSmall, { marginVertical: 20 }]}>{title}</Text>
      {children}
    </View>
  );
};

interface EpodDocumentProps {
  data: Epod;
}
const EpodDocument = ({ data }: EpodDocumentProps) => {
  const { task } = data;
  const taskId = task.id.split('-')[0];
  const dateEpodCreatedFormatted = format(new Date(data.created_at), 'ddMMyy');
  const documentTitle = `EPOD-${dateEpodCreatedFormatted}-${taskId}`;
  const broadcastPreference = data.task.broadcast_preference;
  const status = task.statuses.find((status) => status.status === 'complete');
  const deliveryAttachments: Attachment[] = [];
  if (task && task?.statuses) {
    task.statuses.forEach((history) => {
      if (history.status === 'unsuccessful_delivery') {
        if ((history.attachments || []).length > 0) {
          deliveryAttachments.push(...history.attachments);
        }
        return;
      }

      if (history.status === 'complete') {
        if (history.attachments) {
          if (history.attachments.length > 0) {
            deliveryAttachments.push(...history.attachments);
          }
        }
      }
    });
  }

  if (task?.attachments?.length > 0) {
    deliveryAttachments.push(...task.attachments);
  }

  return (
    <Document title={documentTitle} creator="GotSurge">
      <Page size="A4" style={styles.page}>
        <HeaderTitle title="Proof of Delivery Receipt" />
        <OrderInfo
          orgName={task.org_name}
          orderId={task.id}
          trackingId={task.tracking_id}
        />
        {broadcastPreference === BROADCAST_PREFERENCES.marine && (
          <VesselDetail toContact={task.to_contact} />
        )}
        <DeliveryDetail
          broadcastPreference={broadcastPreference}
          toAddress={task.to_address}
          toTimeWindow={task.to_time_window}
          toContact={task.to_contact}
        />
        <CareOf toContact={task.to_contact} />
        <DeliveryNote deliveryNote={task.delivery_note_to_driver ?? ''} />
        <Divider />
        <ItemsSection broadcastPreference={broadcastPreference}>
          <ItemsTable
            user={task.user}
            broadcastPreference={broadcastPreference}
            cargoDetails={task.cargo_details ?? []}
            items={task.items ?? []}
          />
        </ItemsSection>
        <AttachmentSection attachments={deliveryAttachments} />
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View style={{ width: '32%', marginRight: 18 }}>
            <ReleasedTo status={status} toContact={task.to_contact} />
          </View>
          <View style={{ width: '32%', marginRight: 18 }}>
            <CompletionInfo
              status={status}
              driver={task.driver}
              country={task.to_address.country}
            />
          </View>
          <View style={{ width: '32%' }}>
            <EndorseSection
              epodCreatedAt={data.created_at}
              epodStatus={data.status}
              receiver={data.receiver}
              endorse={data.endorse}
              toContact={task.to_contact}
              epodRemarks={data.remarks}
            />
          </View>
        </View>
        <FooterText text="This is a computer generated notice. No signature is required." />
      </Page>
    </Document>
  );
};

export default EpodDocument;
