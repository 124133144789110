import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
    color: '#35394B',
    paddingTop: 35,
    paddingBottom: 45,
    paddingHorizontal: 35,
  },
  titleLarge: {
    fontSize: 16,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 700,
    marginBottom: 12,
  },
  titleSmall: {
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 700,
    marginBottom: 6,
  },
  textMedium: {
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  textMediumBold: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 700,
  },
  textSmall: {
    fontSize: 8,
    fontFamily: 'Helvetica',
  },
  textSmallBold: {
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 700,
  },
  spaceSmallBottom: {
    marginBottom: 4,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: '#DEDEDE',
    borderBottomStyle: 'solid',
  },
  footerText: {
    fontSize: 8,
    fontFamily: 'Helvetica',
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    marginLeft: 35,
  },
});
