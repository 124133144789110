import * as React from 'react';
import RunsheetClient from 'httpClients/runsheetClient';
import { RouteComponentProps } from 'react-router-dom';
import {
  Card,
  CenterModal,
  SysAdminMainContainer,
  Breadcrumb,
  FieldView,
  StatusLabel,
  DropdownMenu,
  Searchbox,
  Separator,
  AddressView,
} from 'components';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import { css } from 'styled-components';
import { Runsheet, OrgTransporter } from 'models/runsheet';
import { Organization, OrgDriver } from 'models/organization';
import * as H from 'history';
import * as moment from 'moment';
import BROADCAST_PREFERENCES, {
  BROADCAST_PREFERENCE_DESCRIPTION,
} from 'constants/broadcastPreference';
import RunsheetRTBClient from 'httpClients/runsheetRTBClient';
import {
  Title,
  Header,
  DropdownButton,
  Icon,
  Column,
  CardContainer,
  CardRow,
  CustomCard,
  Row,
  TimestampField,
  OrderField,
  Prompt,
  RemoveButton,
  AddOrderButton,
  StyledLink,
  RunsheetId,
  FieldInfo,
  ModalTextContainer,
  ModalText,
  ErrorMessage,
  CustomLink,
  OrderHeading,
  OrderText,
  inlineTextInputStyle,
  Label,
  LabelButton,
  Field,
  ActionButtonsContainer,
  AlertMessage,
  ActionButton,
  DropdownButtonIcon,
} from './styles';
import {
  faPen,
  faTrash,
  faPodcast,
  faArrowLeft,
  faPlusCircle,
  faStopCircle,
  faThumbsUp,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import { STATUS_DESCRIPTION, STATUS_COLOR } from 'constants/runsheetStatus';
import {
  STATUS_DESCRIPTION as TASK_STATUS_DESCRIPTION,
  STATUS_COLOR as TASK_STATUS_COLOR,
} from 'constants/taskStatus';
import { VEHICLE_PREFERENCE_DESCRIPTION } from 'constants/vehiclePreference';
import { formatPrice, capitalizeEachWord } from 'utils/formatter';
import driverProfileClient from 'httpClients/driverProfileClient';
import { DriverProfile } from 'models/driverProfile';
import { RunsheetReadyToBroadcast } from 'models/runsheetReadyToBroadcast';
import { RunsheetReadyToTransfer } from 'models/runsheetReadyToTransfer';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import OrganizationDriverClient from 'httpClients/organizationDriverClient';
import AddDriverModal from '../organization/orgDriver/modal/addDriverModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface RunsheetDetailState {
  error: null | string;
  showStopBroadcastingModal: boolean;
  showAcceptOrderModal: boolean;
  showAddOrgDriverModal: boolean;
  showOrderAcceptedModal: boolean;
  isStartClicked: boolean;
  isStopClicked: boolean;
  confirmDeleteRunsheet: {
    id: string;
    name: string;
  };
  acceptOrderModal: {
    orgTransporter: OrgTransporter;
    organizations: Organization[];
    orgDrivers: OrgDriver[];
  };
}

interface LocationState {
  from: string;
}

interface StateProps {
  runsheet: Runsheet;
  driverProfile: DriverProfile;
  broadcastLogs: RunsheetReadyToBroadcast[];
  transferLogs: RunsheetReadyToTransfer[];
}

interface RunsheetDetailProps {
  history: H.History;
}

type Props = RouteComponentProps<{ id: string }, object, LocationState> &
  RunsheetDetailProps &
  StateProps;

class RunsheetDetail extends React.Component<Props, RunsheetDetailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      showStopBroadcastingModal: false,
      showAcceptOrderModal: false,
      showAddOrgDriverModal: false,
      showOrderAcceptedModal: false,
      isStartClicked: false,
      isStopClicked: false,
      confirmDeleteRunsheet: {
        id: '',
        name: '',
      },
      acceptOrderModal: {
        orgTransporter: null,
        organizations: [],
        orgDrivers: [],
      },
    };
  }

  async componentDidMount(): Promise<void> {
    this.fetchData();
  }

  componentDidUpdate(prevProps: StateProps, prevState: RunsheetDetailState): void {
    if (prevProps.runsheet !== this.props.runsheet) {
      this.setState((prevState) => ({
        acceptOrderModal: {
          ...prevState.acceptOrderModal,
          orgTransporter: this.getDefaultTransporter(),
        },
      }));
    }
    if (prevState.showAcceptOrderModal !== this.state.showAcceptOrderModal) {
      if (
        this.state.showAcceptOrderModal &&
        this.state.acceptOrderModal.orgTransporter &&
        this.state.acceptOrderModal.orgTransporter.id
      ) {
        this.fetchOrganizationDrivers(this.state.acceptOrderModal.orgTransporter.id);
      }
    }
  }

  fetchData = async (): Promise<void> => {
    const { id } = this.props.match.params;
    await this.fetchRunsheet(id);
    await this.fetchOrganizations();
    this.fetchJobs();
    this.fetchDriverProfile();
  };

  fetchRunsheet = async (id: string): Promise<void> => {
    this.setState({ error: null });
    try {
      const client = new RunsheetClient();
      await client.getRunsheet(id);
    } catch (e) {
      this.setState({ error: e });
    }
  };

  fetchDriverProfile = async (): Promise<void> => {
    const { runsheet } = this.props;
    if (runsheet && runsheet.driver && runsheet.driver.id) {
      const { id } = runsheet.driver;
      const client = new driverProfileClient();
      try {
        await client.getDriverProfile(id);
      } catch (e) {
        this.setState({ error: 'driver-profile-error' });
      }
    }
  };

  fetchJobs = async (): Promise<void> => {
    const { runsheet } = this.props;
    if (runsheet && runsheet.id && runsheet.broadcast_preference) {
      const { id } = runsheet;
      const client = new RunsheetRTBClient();
      await client.getRunsheetRTBByRunsheetId(id, new URLSearchParams());
    }
  };

  fetchOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(
          new URLSearchParams({ page: pageNum.toString() })
        );
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      this.setState((prevState) => ({
        acceptOrderModal: {
          ...prevState.acceptOrderModal,
          organizations,
        },
      }));
    } catch (e) {
      //
    }
  };

  fetchOrganizationDrivers = async (orgId: string): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const orgDrivers: OrgDriver[] = [];
    const client = new OrganizationDriverClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationDriverList(
          orgId,
          new URLSearchParams({ page: pageNum.toString() })
        );
        pageNum += 1;
        if (results.org_drivers.length === 0) {
          allFetched = true;
        } else {
          orgDrivers.push(
            ...results.org_drivers.filter((driver) => driver.driver_id !== '')
          );
        }
      }
      this.setState((prevState) => ({
        acceptOrderModal: {
          ...prevState.acceptOrderModal,
          orgDrivers,
        },
      }));
    } catch (e) {
      //
    }
  };

  getDefaultTransporter = (): OrgTransporter => {
    const { runsheet } = this.props;
    let orgDriver = null;
    if (runsheet && runsheet.org_transporter && runsheet.org_transporter.org_driver) {
      orgDriver = {
        id: runsheet.org_transporter.org_driver.id,
        name: runsheet.org_transporter.org_driver.name,
        org_id: runsheet.org_transporter.org_driver.org_id,
        email: runsheet.org_transporter.org_driver.email,
        full_name: runsheet.org_transporter.org_driver.full_name,
        partial_id_chars: runsheet.org_transporter.org_driver.partial_id_chars,
        phone_number: runsheet.org_transporter.org_driver.phone_number,
        driver_id: runsheet.org_transporter.org_driver.driver_id,
      };
    }
    return {
      id: runsheet && runsheet.org_transporter && runsheet.org_transporter.id,
      name: runsheet && runsheet.org_transporter && runsheet.org_transporter.name,
      org_driver: orgDriver,
    };
  };

  onDeleteClick = async (id: string): Promise<void> => {
    const { match, runsheet } = this.props;
    const client = new RunsheetClient();
    await client.deleteTaskFromRunsheet(match.params.id, id, runsheet.version_rev);
    this.fetchRunsheet(match.params.id);
  };

  showDeleteRunsheetModal = (id: string, name: string): void => {
    this.setState({
      confirmDeleteRunsheet: {
        id,
        name,
      },
    });
  };

  closeDeleteRunsheetModal = (): void => {
    this.setState({
      confirmDeleteRunsheet: {
        id: '',
        name: '',
      },
    });
  };

  renderStopBroadcastingModal(): React.ReactNode {
    return (
      <CenterModal
        leftButtonOnClick={(): void => {
          this.setState({ showStopBroadcastingModal: false });
        }}
        leftButtonText="Cancel"
        rightButtonOnClick={this.handleStopBroadcast}
        rightButtonText="Stop!"
        title="Stop Broadcasting"
      >
        <Prompt>Are you sure to Stop Broadcasting this Runsheet?</Prompt>
      </CenterModal>
    );
  }

  handleStopBroadcast = async (): Promise<void> => {
    const { id } = this.props.match.params;
    const { runsheet } = this.props;

    this.setState({ error: null, isStopClicked: true });
    try {
      const client = new RunsheetClient();
      await client.stopBroadcastRunsheet(
        {
          version_rev: runsheet.version_rev,
        },
        id
      );
      this.setState({ showStopBroadcastingModal: false });
      setTimeout(() => {
        this.fetchRunsheet(id);
        this.setState({ isStopClicked: false });
      }, 500);
    } catch (e) {
      this.setState({ error: e });
    }
  };

  handleStartBroadcast = async (id: string, versionRev: string): Promise<void> => {
    this.setState({ isStartClicked: true });
    try {
      const client = new RunsheetRTBClient();
      await client.startBroadcast(id, {
        version_rev: versionRev,
      });
      setTimeout(() => {
        this.fetchRunsheet(id);
        this.setState({ isStartClicked: false });
        // todo: check if the status is same, then add error message to reload by them self
      }, 2000);
    } catch (e) {
      this.setState({ error: e });
    }
  };

  deleteRunsheet = async (): Promise<void> => {
    const { runsheet } = this.props;
    const runsheetId = runsheet && runsheet.id;

    const client = new RunsheetClient();
    await client.deleteRunsheet(runsheetId);
    this.closeDeleteRunsheetModal();
    this.goBack();
  };

  assignTransporter = async (): Promise<void> => {
    const { runsheet } = this.props;
    const runsheetId = runsheet && runsheet.id;

    try {
      const client = new RunsheetClient();
      const assignedRunsheet = await client.sysTransporterAssign(runsheetId, {
        version_rev: runsheet.version_rev,
        task_ids: runsheet.tasks.map((task) => task.id),
        org_transporter: this.state.acceptOrderModal.orgTransporter,
      });
      // send notification to assigned driver
      const rtbClient = new RunsheetRTBClient();
      await rtbClient.startBroadcast(runsheetId, {
        version_rev: assignedRunsheet.runsheet.version_rev,
      });
      this.fetchData();
      this.setState({ showAcceptOrderModal: false, showOrderAcceptedModal: true });
    } catch (e) {
      this.setState({ error: e });
    }
  };

  customFormatDateTime = (date: Date): string => {
    return moment(date).format('DD MMM YYYY, LT');
  };

  canModify = (): boolean => {
    const { runsheet } = this.props;

    return (
      runsheet && runsheet !== null && runsheet.current_status === 'pending_broadcast'
    );
  };

  showStartBroadcast = (): boolean => {
    return this.canModify();
  };

  canStartBroadcast = (): boolean => {
    const { runsheet } = this.props;
    const taskAvailable = runsheet && (runsheet.tasks || []).length > 0;

    return this.showStartBroadcast() && taskAvailable && !this.state.isStartClicked;
  };

  showStopBroadcast = (): boolean => {
    const { runsheet } = this.props;

    return runsheet && runsheet !== null && runsheet.current_status === 'broadcasting';
  };

  canStopBroadcast = (): boolean => {
    return this.showStopBroadcast() && !this.state.isStopClicked;
  };

  goBack = (): void => {
    const { history } = this.props;
    const { location } = history;

    if (
      location &&
      location.state &&
      location.state.from &&
      location.state.from === '/sys/runsheets'
    ) {
      history.goBack();
    } else {
      history.push('/sys/runsheets');
    }
  };

  renderAcceptOrderModal = (): React.ReactNode => {
    const { runsheet } = this.props;
    const { organizations, orgTransporter, orgDrivers } = this.state.acceptOrderModal;
    const searchDriver =
      orgTransporter &&
      orgTransporter.org_driver &&
      orgTransporter.org_driver.name &&
      orgTransporter.org_driver.name.toLowerCase();
    const searchTransporter =
      orgTransporter && orgTransporter.name && orgTransporter.name.toLowerCase();
    const filteredOrganizations = searchTransporter
      ? organizations.filter((org) =>
          org.business_name.toLowerCase().includes(searchTransporter)
        )
      : organizations;
    filteredOrganizations.sort((a, b) => a.business_name.localeCompare(b.business_name));
    const filteredOrgDrivers = searchDriver
      ? orgDrivers.filter((driver) => driver.name.toLowerCase().includes(searchDriver))
      : orgDrivers;

    return (
      this.state.showAcceptOrderModal && (
        <CenterModal
          leftButtonOnClick={(): void => {
            this.setState({ showAcceptOrderModal: false });
          }}
          leftButtonText="Cancel"
          rightButtonOnClick={(): void => {
            this.assignTransporter();
          }}
          rightButtonText="Update"
          rightButtonStyle="encourage"
          rightButtonDisabled={
            (orgTransporter && !orgTransporter.id) ||
            (orgTransporter &&
              orgTransporter.org_driver &&
              orgTransporter.org_driver.id === '' &&
              orgTransporter.org_driver.name !== '')
          }
          title={
            runsheet && runsheet.org_transporter && runsheet.org_transporter.id
              ? 'Reassign Order'
              : 'Accept Order'
          }
          width="small"
        >
          <Row>
            <Field>
              <Searchbox
                trailingIcon={faSearch}
                containerStyle={inlineTextInputStyle}
                fieldName="Transporter"
                isRequired
                onTextChange={(value: string): void => {
                  this.setState((prevState) => ({
                    acceptOrderModal: {
                      ...prevState.acceptOrderModal,
                      orgDrivers: [],
                      orgTransporter: {
                        ...prevState.acceptOrderModal.orgTransporter,
                        id: '',
                        name: value,
                        org_driver: null,
                      },
                    },
                  }));
                }}
                handleSelectChange={(value: string, org: Organization): void => {
                  this.setState((prevState) => ({
                    acceptOrderModal: {
                      ...prevState.acceptOrderModal,
                      orgTransporter: {
                        ...prevState.acceptOrderModal.orgTransporter,
                        id: value,
                        name: org.business_name,
                        org_driver: null,
                      },
                    },
                  }));
                  this.fetchOrganizationDrivers(value);
                }}
                placeholder="Search Transporter"
                width="full"
                value={orgTransporter.name || ''}
                options={(filteredOrganizations || []).map((organization) => ({
                  value: organization.id,
                  text: organization.business_name,
                  additionalValue: {
                    business_name: organization.business_name,
                  },
                }))}
              />
            </Field>
            <Field>
              <Searchbox
                trailingIcon={faSearch}
                containerStyle={inlineTextInputStyle}
                fieldName="Driver"
                onTextChange={(value: string): void => {
                  const driver =
                    value === ''
                      ? null
                      : {
                          id: '',
                          name: value,
                          org_id: '',
                          email: '',
                          full_name: '',
                          partial_id_chars: '',
                          phone_number: '',
                          driver_id: '',
                        };
                  this.setState((prevState) => ({
                    acceptOrderModal: {
                      ...prevState.acceptOrderModal,
                      orgTransporter: {
                        ...prevState.acceptOrderModal.orgTransporter,
                        org_driver: driver,
                      },
                    },
                  }));
                }}
                handleSelectChange={(value: string, driver: OrgDriver): void => {
                  this.setState((prevState) => ({
                    acceptOrderModal: {
                      ...prevState.acceptOrderModal,
                      orgTransporter: {
                        ...prevState.acceptOrderModal.orgTransporter,
                        org_driver: {
                          id: value,
                          name: driver.name,
                          org_id: driver.org_id,
                          email: driver.email,
                          full_name: driver.full_name,
                          partial_id_chars: driver.partial_id_chars,
                          phone_number: driver.phone_number,
                          driver_id: driver.driver_id,
                        },
                      },
                    },
                  }));
                }}
                placeholder="Search Driver"
                width="full"
                value={
                  (orgTransporter &&
                    orgTransporter.org_driver &&
                    orgTransporter.org_driver.name) ||
                  ''
                }
                options={(filteredOrgDrivers || []).map((driver) => ({
                  value: driver.id,
                  text: driver.name,
                  additionalValue: {
                    name: driver.name,
                    org_id: driver.org_id,
                    email: driver.email,
                    full_name: driver.full_name,
                    partial_id_chars: driver.partial_id_chars,
                    phone_number: driver.phone_number,
                    driver_id: driver.driver_id,
                  },
                }))}
              />
            </Field>
            <Label>
              Driver not found?
              <LabelButton
                disabled={!this.state.acceptOrderModal.orgTransporter.id}
                onClick={(): void => {
                  if (this.state.acceptOrderModal.orgTransporter.id) {
                    this.setState({
                      showAcceptOrderModal: false,
                      showAddOrgDriverModal: true,
                    });
                  }
                }}
              >
                Add Driver
              </LabelButton>
            </Label>
          </Row>
        </CenterModal>
      )
    );
  };

  renderAddOrgDriverModal = (): React.ReactNode => {
    return (
      this.state.showAddOrgDriverModal && (
        <AddDriverModal
          orgId={this.state.acceptOrderModal.orgTransporter.id}
          closeModal={(): void => {
            this.setState({ showAcceptOrderModal: true, showAddOrgDriverModal: false });
          }}
          addDriverSuccess={(): void => {
            this.setState((prevState) => ({
              ...prevState,
              showAddOrgDriverModal: false,
              showAcceptOrderModal: true,
              acceptOrderModal: {
                ...prevState.acceptOrderModal,
                orgTransporter: {
                  ...prevState.acceptOrderModal.orgTransporter,
                  org_driver: null,
                },
              },
            }));
          }}
        />
      )
    );
  };

  renderOrderAcceptedModal = (): React.ReactNode => {
    return (
      this.state.showOrderAcceptedModal && (
        <CenterModal
          rightButtonOnClick={(): void => {
            this.setState({
              showOrderAcceptedModal: false,
            });
          }}
          rightButtonText="OK"
          title="Updated"
        >
          <AlertMessage status="success">Order accepted!</AlertMessage>
        </CenterModal>
      )
    );
  };

  renderHeading = (): React.ReactNode => {
    const { runsheet } = this.props;
    const { id } = this.props.match.params;
    const canAccept =
      runsheet &&
      runsheet.broadcast_preference === BROADCAST_PREFERENCES.marine &&
      (runsheet.current_status === 'broadcasting' ||
        runsheet.current_status === 'accepted');

    return (
      <Header>
        <Title>Runsheet Details</Title>
        <ActionButtonsContainer>
          <ActionButton
            onClick={(): void => {
              this.goBack();
            }}
            buttonStyle="encourage"
            buttonType="neutral"
            icon={<FontAwesomeIcon icon={faArrowLeft} style={{ color: COLOR.black }} />}
          >
            Back
          </ActionButton>
          <DropdownMenu>
            <DropdownButton
              buttonStyle="encourage"
              buttonType="neutral"
              disabled={!runsheet}
              onClick={(): void => {
                this.props.history.push({
                  pathname: `/sys/runsheets/${id}/edit`,
                  state: { from: this.props.location.pathname },
                });
              }}
            >
              <DropdownButtonIcon icon={faPen} fixedWidth />
              Edit Runsheet Name
            </DropdownButton>
            <Separator />
            <DropdownButton
              buttonStyle="encourage"
              buttonType="neutral"
              disabled={!this.canModify()}
              onClick={(): void => {
                this.showDeleteRunsheetModal(id, runsheet.name);
              }}
            >
              <DropdownButtonIcon icon={faTrash} fixedWidth />
              Delete Runsheet
            </DropdownButton>
            <Separator />
            {this.showStartBroadcast() ? (
              <>
                <DropdownButton
                  disabled={!this.canStartBroadcast()}
                  onClick={(): void => {
                    this.handleStartBroadcast(id, runsheet.version_rev);
                  }}
                  buttonStyle="encourage"
                  buttonType="neutral"
                >
                  <DropdownButtonIcon icon={faPodcast} fixedWidth />
                  Start Broadcast
                </DropdownButton>
                <Separator />
              </>
            ) : (
              false
            )}
            {this.showStopBroadcast() ? (
              <>
                <DropdownButton
                  disabled={!this.canStopBroadcast()}
                  onClick={(): void => {
                    this.setState({ showStopBroadcastingModal: true });
                  }}
                  buttonStyle="encourage"
                  buttonType="neutral"
                >
                  <DropdownButtonIcon icon={faStopCircle} fixedWidth />
                  Stop Broadcast
                </DropdownButton>
                <Separator />
              </>
            ) : (
              false
            )}
            <DropdownButton
              disabled={!canAccept}
              onClick={(): void => {
                this.setState((prevState) => ({
                  showAcceptOrderModal: true,
                  acceptOrderModal: {
                    ...prevState.acceptOrderModal,
                    orgTransporter: this.getDefaultTransporter(),
                  },
                }));
              }}
              buttonStyle="encourage"
              buttonType="neutral"
            >
              <DropdownButtonIcon icon={faThumbsUp} fixedWidth />
              {runsheet && runsheet.org_transporter && runsheet.org_transporter.id
                ? 'Reassign Order'
                : 'Accept Order'}
            </DropdownButton>
          </DropdownMenu>
        </ActionButtonsContainer>
      </Header>
    );
  };

  renderOrders = (): React.ReactNode => {
    const { runsheet } = this.props;
    const { id } = this.props.match.params;

    return (
      <Card>
        <CardRow>
          <Title>Orders</Title>
        </CardRow>
        {runsheet.tasks !== null
          ? runsheet.tasks.map((task, index) => {
              return (
                <Column
                  flex="1"
                  key={index}
                  columnStyle={css`
                    margin-bottom: 1rem;
                  `}
                >
                  <OrderField>
                    <div>
                      <OrderHeading>ID</OrderHeading>
                      <OrderText>
                        <StyledLink to={`/sys/tasks/${task.id}`}>{task.id}</StyledLink>
                      </OrderText>
                    </div>
                    <RemoveButton
                      disabled={!this.canModify()}
                      buttonStyle="discourage"
                      buttonType="neutral"
                      onClick={(): void => {
                        this.onDeleteClick(task.id);
                      }}
                    >
                      <Icon
                        disabled={!this.canModify()}
                        icon={faTrash}
                        color={COLOR.red}
                      />
                      Remove
                    </RemoveButton>
                  </OrderField>
                  <OrderField>
                    <div>
                      <OrderHeading>Status</OrderHeading>
                      <OrderText>
                        <StatusLabel
                          iconColor={
                            task.current_status
                              ? TASK_STATUS_COLOR[task.current_status]
                              : ''
                          }
                        >
                          {task.current_status &&
                            TASK_STATUS_DESCRIPTION[task.current_status]}
                        </StatusLabel>
                      </OrderText>
                    </div>
                  </OrderField>
                  {task.org_transporter && (
                    <OrderField>
                      <div>
                        <OrderHeading>Transporter and Driver</OrderHeading>
                        <OrderText>
                          <StyledLink
                            to={`/sys/organizations/${task.org_transporter.id}/company-details`}
                          >
                            {task.org_transporter.name}
                          </StyledLink>
                          {task.org_transporter.org_driver ? (
                            <div>
                              <StyledLink
                                to={`/sys/organizations/${task.org_transporter.id}/driver/${task.org_transporter.org_driver.id}`}
                              >
                                {task.org_transporter.org_driver.name}
                              </StyledLink>
                            </div>
                          ) : (
                            false
                          )}
                        </OrderText>
                      </div>
                    </OrderField>
                  )}
                  {!task.org_transporter && task.driver && (
                    <OrderField>
                      <div>
                        <OrderHeading>Driver</OrderHeading>
                        <OrderText>
                          <StyledLink to={`/sys/drivers/${task.driver.id}`}>
                            {task.driver.name}
                          </StyledLink>
                        </OrderText>
                      </div>
                    </OrderField>
                  )}
                  <OrderField>
                    <div>
                      <OrderHeading>Collect From</OrderHeading>
                      <AddressView address={task.from_address} />
                    </div>
                  </OrderField>
                  <OrderField>
                    <div>
                      <OrderHeading>Send To</OrderHeading>
                      <AddressView address={task.to_address} />
                    </div>
                  </OrderField>
                </Column>
              );
            })
          : null}
        <CardRow>
          <AddOrderButton>
            <ActionButton
              disabled={!this.canModify()}
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={(): void => {
                this.props.history.push({
                  pathname: `/sys/runsheets/${id}/add-task`,
                  state: { from: this.props.location.pathname },
                });
              }}
              icon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              Add Order
            </ActionButton>
          </AddOrderButton>
        </CardRow>
      </Card>
    );
  };

  renderRunsheetInfo = (): React.ReactNode => {
    const { runsheet } = this.props;

    return (
      <CustomCard>
        <TimestampField>
          <div>
            {runsheet.start_broadcast_at &&
              `Updated at ${this.customFormatDateTime(runsheet.start_broadcast_at)}`}
          </div>
          <div>
            {runsheet.created_at &&
              `Created at ${this.customFormatDateTime(runsheet.created_at)}`}
          </div>
        </TimestampField>
        <FieldView fieldName="Runsheet Name & ID">
          <>{runsheet.name}</>
          <RunsheetId>{runsheet.id}</RunsheetId>
        </FieldView>
        <FieldView fieldName="Status">
          <StatusLabel
            iconColor={
              runsheet.current_status ? STATUS_COLOR[runsheet.current_status] : ''
            }
          >
            {runsheet.current_status && STATUS_DESCRIPTION[runsheet.current_status]}
          </StatusLabel>
          {
            <>
              {this.props.broadcastLogs &&
              this.props.broadcastLogs[0] &&
              this.props.broadcastLogs[0].id ? (
                <StyledLink
                  to={`/sys/runsheet-ready-to-broadcasts/${this.props.broadcastLogs[0].id}`}
                >
                  View broadcast log
                </StyledLink>
              ) : (
                false
              )}
            </>
          }
        </FieldView>
      </CustomCard>
    );
  };

  renderBroadcastInfo = (): React.ReactNode => {
    const { runsheet, driverProfile } = this.props;
    const { error } = this.state;
    const firstTaskPrice =
      runsheet && runsheet.tasks && runsheet.tasks[0] && runsheet.tasks[0].driver_price;
    const isPriceSame = (runsheet.tasks || []).every((task) => {
      return task.driver_price === firstTaskPrice;
    });
    const firstDriver =
      runsheet &&
      runsheet.tasks &&
      runsheet.tasks[0] &&
      runsheet.tasks[0].driver &&
      runsheet.tasks[0].driver.id;
    const hasMultipleDriver = !(runsheet.tasks || []).every((task) => {
      if (task.driver !== null) {
        return task.driver && task.driver.id === firstDriver;
      } else {
        return true;
      }
    });
    const isMarine =
      runsheet &&
      runsheet.broadcast_preference &&
      runsheet.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const currentStatus = runsheet && runsheet.current_status;
    const canShowPrice =
      currentStatus !== 'pending_broadcast' && currentStatus !== 'cancelled';

    return (
      <CustomCard>
        <FieldView fieldName="Service">
          {(runsheet.broadcast_preference &&
            BROADCAST_PREFERENCE_DESCRIPTION[runsheet.broadcast_preference].label) ||
            BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.all].label}
        </FieldView>
        <FieldView fieldName="Item and Vehicle Requirement">
          {runsheet.vehicle_preference &&
            VEHICLE_PREFERENCE_DESCRIPTION[runsheet.vehicle_preference]}
        </FieldView>
        {runsheet.org_transporter ? (
          <>
            <FieldView fieldName="Transporter and Driver">
              <StyledLink
                to={`/sys/organizations/${runsheet.org_transporter.id}/company-details`}
              >
                {runsheet.org_transporter.name}
              </StyledLink>
              {runsheet.org_transporter.org_driver ? (
                <div>
                  <StyledLink
                    to={`/sys/organizations/${runsheet.org_transporter.id}/driver/${runsheet.org_transporter.org_driver.id}`}
                  >
                    {runsheet.org_transporter.org_driver.name}
                  </StyledLink>
                </div>
              ) : (
                false
              )}
            </FieldView>
          </>
        ) : (
          <FieldView fieldName="Driver">
            {runsheet.driver && runsheet.driver.id && runsheet.driver.name && (
              <>
                {hasMultipleDriver ? (
                  'Multiple'
                ) : (
                  <>
                    <StyledLink to={`/sys/drivers/${runsheet.driver.id}`}>
                      {runsheet.driver.name}
                    </StyledLink>
                    {error !== 'driver-profile-error' &&
                    driverProfile &&
                    driverProfile.id ? (
                      <>
                        <FieldInfo>{capitalizeEachWord(driverProfile.type)}</FieldInfo>
                        {driverProfile.latitude && driverProfile.longitude ? (
                          <CustomLink
                            target="_blank"
                            href={`https://maps.google.com/?q=${driverProfile.latitude},${driverProfile.longitude}`}
                          >
                            Locate Driver
                          </CustomLink>
                        ) : (
                          false
                        )}
                      </>
                    ) : (
                      false
                    )}
                  </>
                )}
              </>
            )}
          </FieldView>
        )}
        <FieldView fieldName="Total Orders">{runsheet.total_tasks}</FieldView>
        {!isMarine ? (
          <>
            <FieldView fieldName="Price per Order">
              {runsheet &&
              canShowPrice &&
              runsheet.currency &&
              runsheet.tasks &&
              runsheet.tasks[0] ? (
                <>
                  {!isPriceSame ? (
                    'Varies'
                  ) : (
                    <>
                      {runsheet.tasks[0].driver_price
                        ? formatPrice(runsheet.currency, runsheet.tasks[0].driver_price)
                        : false}
                      {runsheet.tasks[0].driver_price_accepted ? (
                        <FieldInfo>
                          {`Driver: ${formatPrice(
                            runsheet.currency,
                            runsheet.tasks[0].driver_price_accepted
                          )}`}
                        </FieldInfo>
                      ) : (
                        false
                      )}
                    </>
                  )}
                </>
              ) : (
                false
              )}
            </FieldView>
            <FieldView fieldName="Total Price">
              {runsheet && canShowPrice && runsheet.currency ? (
                <>
                  {runsheet.total_price
                    ? formatPrice(runsheet.currency, runsheet.total_price)
                    : false}
                  {runsheet.total_driver_price ? (
                    <FieldInfo>
                      {`Driver: ${formatPrice(
                        runsheet.currency,
                        runsheet.total_driver_price
                      )}`}
                    </FieldInfo>
                  ) : (
                    false
                  )}
                </>
              ) : (
                false
              )}
            </FieldView>
          </>
        ) : (
          false
        )}
      </CustomCard>
    );
  };

  renderConfirmDeleteRunsheetModal = (): React.ReactNode => {
    return (
      <CenterModal
        leftButtonOnClick={this.closeDeleteRunsheetModal}
        leftButtonText="Cancel"
        rightButtonText="Delete"
        rightButtonOnClick={this.deleteRunsheet}
        rightButtonStyle="discourage"
        rightButtonType="secondary"
        title={`Delete "${this.state.confirmDeleteRunsheet.name}"`}
      >
        <ModalTextContainer>
          <ModalText>This field could be broadcasted to drivers.</ModalText>
          <ModalText>
            By deleting this runsheet, you will update or remove the broadcast from the
            Driver-Partner app.
          </ModalText>
          <ModalText>This action cannot be undone.</ModalText>
        </ModalTextContainer>
      </CenterModal>
    );
  };

  render(): React.ReactNode {
    const { runsheet } = this.props;
    const { id } = this.props.match.params;
    const { error } = this.state;

    return (
      <SysAdminMainContainer selected="runsheets">
        <Breadcrumb
          items={[
            {
              text: 'Runsheet',
              onClick: (): void => {
                this.props.history.push('/sys/runsheets');
              },
            },
            { text: id.split('-')[0] },
          ]}
        />
        {this.renderHeading()}
        {error && error !== 'driver-profile-error' && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
        {runsheet ? (
          <CardContainer>
            <Column flex="2">{runsheet !== null ? this.renderOrders() : false}</Column>
            <Column flex="1">
              {runsheet !== null ? this.renderRunsheetInfo() : false}
              {runsheet !== null ? this.renderBroadcastInfo() : false}
            </Column>
          </CardContainer>
        ) : (
          false
        )}
        {this.state.confirmDeleteRunsheet.id !== ''
          ? this.renderConfirmDeleteRunsheetModal()
          : false}
        {this.state.showStopBroadcastingModal
          ? this.renderStopBroadcastingModal()
          : false}
        {this.renderAcceptOrderModal()}
        {this.renderAddOrgDriverModal()}
        {this.renderOrderAcceptedModal()}
      </SysAdminMainContainer>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  runsheet: state.runsheet.runsheet,
  driverProfile: state.driverProfile.profile,
  broadcastLogs: state.runsheetReadyToBroadcast.runsheetReadyToBroadcasts,
  transferLogs: state.runsheetReadyToTransfer.runsheetReadyToTransfers,
});

export default connect(mapStateToProps)(RunsheetDetail);
