import { MetaNotification } from 'models/notification';

export interface NotificationState {
  metaNotification: MetaNotification;
}

export const RECEIVE_META_NOTIFICATION = 'RECEIVE_META_NOTIFICATION';

interface ReceiveMetaNotification {
  type: typeof RECEIVE_META_NOTIFICATION;
  metaNotification: MetaNotification;
}

export type NotificationActionTypes = ReceiveMetaNotification;