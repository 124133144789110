import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import { TaskExportHash } from 'reduxActions/taskExport/taskExportTypes';
import TaskExportClient from 'httpClients/taskExportClient';
import { Button, SysAdminMainContainer, Table, TableCellRenderEvent } from 'components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface StateProps {
  jobs: TaskExportHash;
}

type Props = StateProps;

class TaskExportIndex extends React.Component<Props> {
  componentDidMount(): void {
    this.fetchTaskExportJobs();
  }

  fetchTaskExportJobs = async (): Promise<void> => {
    const client = new TaskExportClient();
    await client.getJobs();
  }

  renderTableHeader = (): React.ReactNode => (
    <>
      <Title>Task Export Jobs</Title>
      <Link to="/sys/tasks/export/new">
        <Button>Create Task Export</Button>
      </Link>
    </>
  )

  renderCell = (e: TableCellRenderEvent<{ action: string }>): React.ReactNode => {
    if (e.key === 'action') {
      return (
        <>
          <Link to={`/sys/tasks/export/${e.value.toString()}`}>
            <Button>Detail</Button>
          </Link>
        </>
      );
    }

    return <>{e.value}</>;
  }

  render(): React.ReactNode {
    const { jobs } = this.props;

    const columns = {
      id: 'ID',
      orgId: 'Org ID',
      orgName: 'Org Name',
      name: 'Name',
      status: 'Status',
      action: 'Actions',
    };

    const data = Object.values(jobs).map(job => ({
      id: job.id,
      orgId: job.org_id,
      orgName: job.org_name,
      name: job.name,
      status: job.status,
      action: job.id,
    }));

    return (
      <SysAdminMainContainer selected="tasks">
        <Table
          columns={columns}
          data={data}
          cellRenderer={this.renderCell}
          renderHeader={this.renderTableHeader}
        />
      </SysAdminMainContainer>
    );
  }
}

const Title = styled.div`
  font-size: 1.5rem;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  jobs: state.taskExport.taskExportHash,
});

export default connect(mapStateToProps)(TaskExportIndex);
