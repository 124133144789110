import * as React from 'react';
import RunsheetGenerationClient from 'httpClients/runsheetGenerationClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import {
  Table, Button, TableCellRenderEvent,
  SysAdminPagination, Dropdown,
} from 'components';
import { Link } from 'react-router-dom';
import * as H from 'history';
import { RunsheetGenerationJob } from 'models/runsheetGeneration/runsheetGenerationJob';
import { RunsheetGenerationJobHash } from 'reduxActions/runsheetGeneration/runsheetGenerationTypes';

interface RunsheetGenerationIndexProps {
  models: RunsheetGenerationJob[];
  jobsHash: RunsheetGenerationJobHash;
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type Props = HistoryProps & RunsheetGenerationIndexProps;

class RunsheetGenerationIndex extends SysAdminPagination<RunsheetGenerationJob, Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,
      basePath: '/sys/runsheet-generations',
      pluralModelName: 'runsheet generations',
      selectedTab: 'runsheet-generations',
    };
  }

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new RunsheetGenerationClient();
    await client.getJobs(new URLSearchParams(this.state.filters));

    this.setState({ isFetching: false });
  }

  onStartClick = async (id: string): Promise<void> => {
    const client = new RunsheetGenerationClient();
    await client.startJob(id, this.props.jobsHash[id].version_rev);
  }

  renderFilter = (): React.ReactNode => (
    <>
      <Dropdown
        label="Sort by"
        options={[
          {
            value: 'desc',
            name: 'Newest First',
          }, {
            value: 'asc',
            name: 'Oldest First',
          },
        ]}
        onChange={(value: string): void => { this.onFilterChange('order', value); }}
        value={this.state.filters.order}
      />
    </>
  )

  renderActionPanel = (): React.ReactNode => (
    <>
      <Link to="/sys/runsheet-generations/new">
        <Button>Create Runsheet Generation Job</Button>
      </Link>
    </>
  )

  renderCell = (e: TableCellRenderEvent<{ action: string }>): React.ReactNode => {
    if (e.key === 'action') {
      return (
        <>
          <Link to={`/sys/runsheet-generations/${e.value.toString()}`}><Button>Detail</Button></Link>
          <Link to={`/sys/runsheet-generations/${e.value.toString()}/edit`}>
            <Button>Edit</Button>
          </Link>
          <Button
            type='button'
            onClick={(): Promise<void> => this.onStartClick(e.value.toString())}
          >
            Start
          </Button>
        </>
      );
    }

    return <>{e.value}</>;
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      id: 'ID',
      name: 'Name',
      action: 'Action',
    };

    const data = models.map(job => ({
      id: job.id,
      name: job.name,
      action: job.id,
    }));

    return (
      <Table
        columns={columns}
        data={data}
        cellRenderer={this.renderCell}
      />
    );
  }
}

const mapStateToProps = (state: RootState): RunsheetGenerationIndexProps => ({
  models: state.runsheetGeneration.runsheetGenerationIds
    .map(id => state.runsheetGeneration.runsheetGenerationJobHash[id]),
  jobsHash: state.runsheetGeneration.runsheetGenerationJobHash,
});

export default connect(mapStateToProps)(RunsheetGenerationIndex);
