import * as React from 'react';
import { Alert, BeforeLoginContainer } from 'components';
import styled from 'styled-components';

function OperatorInvitationExpired(): JSX.Element {
  return (
    <BeforeLoginContainer title="Set your name and password">
      <MessageContainer status='error'>
        {'This invitation has expired. Contact your admin to invite you again.'}
      </MessageContainer>
    </BeforeLoginContainer>
  );
}

const MessageContainer = styled(Alert)`
  margin-top: 1rem;
`;

export default OperatorInvitationExpired;