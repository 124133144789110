export const MerchantRegisteredPath = [
  {
    path: '/active-orders',
    text: 'Active Orders',
  },
  {
    path: '/orders/new',
    text: 'Fill Form',
  },
  {
    path: '/orders/imports/new',
    text: 'Import Form',
  },
  {
    path: '/past-orders',
    text: 'Past Orders',
  },
  {
    path: '/setting/personal-profile/profile',
    text: 'Profile',
  },
  {
    path: '/setting/personal-profile/profile/edit',
    text: 'Edit Profile',
  },
  {
    path: '/setting/personal-profile/change-password',
    text: 'Password',
  },
  {
    path: '/setting/company/company-details',
    text: 'Company Details',
  },
  {
    path: '/setting/company/company-details/edit',
    text: 'Edit Company Details',
  },
  {
    path: '/setting/company/default-price',
    text: 'Service & Price',
  },
  {
    path: '/setting/company/team',
    text: 'Team',
  },
  {
    path: '/setting/company/squad',
    text: 'Squad',
  },
];

export const OpsRegisteredPath = [
  {
    path: '/sys/tasks',
    text: 'Orders',
  },
  {
    path: '/sys/tasks/new',
    text: 'Fill Form',
  },
  {
    path: '/sys/tasks/:value/edit',
    text: 'Edit Orders',
  },
  {
    path: '/sys/runsheets',
    text: 'Runsheets',
  },
  {
    path: '/sys/runsheets/new',
    text: 'Create Runsheet',
  },
  {
    path: '/sys/runsheets/:value/edit',
    text: 'Edit Runsheets',
  },
  {
    path: '/sys/runsheet-ready-to-broadcasts',
    text: 'Broadcasting History',
  },
  {
    path: '/sys/drivers',
    text: 'Drivers',
  },
  {
    path: '/sys/drivers/:value/edit',
    text: 'Edit Drivers',
  },
  {
    path: '/sys/drivers/:value/squads',
    text: 'Driver Squads',
  },
  {
    path: '/sys/users',
    text: 'Users',
  },
  {
    path: '/sys/users/:value/edit',
    text: 'Edit Users',
  },
  {
    path: '/sys/organizations',
    text: 'Organizations',
  },
  {
    path: '/sys/organizations/new',
    text: 'Create Organisation',
  },
  {
    path: '/sys/organizations/pending',
    text: 'Pending Organizations',
  },
  {
    path: '/sys/organizations/:value/company-details',
    text: 'Company Details',
  },
  {
    path: '/sys/organizations/:value/company-details/edit',
    text: 'Edit Company Details',
  },
  {
    path: '/sys/organizations/:value/default-price',
    text: 'Company Default Price',
  },
  {
    path: '/sys/organizations/:value/default-price/edit',
    text: 'Edit Company Default Price',
  },
  {
    path: '/sys/organizations/:value/team',
    text: 'Company Teams',
  },
  {
    path: '/sys/organizations/:value/squad',
    text: 'Company Squads',
  },
  {
    path: '/sys/organizations/:value/driver',
    text: 'Company Drivers',
  },
  {
    path: '/sys/vessels',
    text: 'Vessels',
  },
  {
    path: '/sys/vessels/:value/details',
    text: 'Vessel',
  },
  {
    path: '/sys/vessels/:value/details/edit',
    text: 'Edit Vessel',
  },
];