import motorcycleIcon from 'assets/images/motorcycle.svg';
import carIcon from 'assets/images/car.svg';
import mpvIcon from 'assets/images/mpv.svg';
import van17dmIcon from 'assets/images/van_17dm.svg';
import van24dmIcon from 'assets/images/van_24dm.svg';
import lorry10ftIcon from 'assets/images/lorry_10ft.svg';
import lorry14ftIcon from 'assets/images/lorry_14ft.svg';
import lorry24ftIcon from 'assets/images/lorry_24ft.svg';
import lorry40ftIcon from 'assets/images/lorry_40ft.svg';
import boatIcon from 'assets/images/boat.svg';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';

export const VEHICLE_PREFERENCE = {
  motorcycle: 'motorcycle',
  car: 'car',
  mpv: 'mpv',
  van_1_7m: 'van_1.7m',
  van_2_4m: 'van_2.4m',
  lorry_10ft: 'lorry_10ft',
  lorry_14ft: 'lorry_14ft',
  lorry_24ft: 'lorry_24ft',
  lorry_40ft: 'lorry_40ft',
  ltl: 'ltl',
  boat: 'boat',
};

interface VehicleCategoryInfo {
  [key: string]: {
    description: string;
  };
}

interface VehiclePreferenceDescription {
  [key: string]: string;
}

// TODO: remove this and use VEHICLE_PREFERENCE_INFO instead
export const VEHICLE_PREFERENCE_DESCRIPTION: VehiclePreferenceDescription = {
  motorcycle: 'Motorcycle',
  car: 'Car',
  mpv: 'MPV',
  'van_1.7m': '1.7m Van',
  'van_2.4m': '2.4m Van',
  lorry_10ft: '10ft Truck',
  lorry_14ft: '14ft Truck',
  lorry_24ft: '24ft Truck',
  lorry_40ft: '40ft Truck',
  ltl: 'ltl',
  boat: 'Boat',
};

export interface VehiclePreferenceInfo {
  [key: string]: {
    description: string;
    capacity: string;
    dimension: string;
    icon: string;
    isShown: boolean;
    broadcastAvailability: (keyof typeof BROADCAST_PREFERENCES)[];
    max_weight: number;
    disabled: boolean;
  };
}

export const VEHICLE_PREFERENCE_INFO: VehiclePreferenceInfo = {
  motorcycle: {
    description: 'Motorcycle',
    capacity: 'Small parcels | 8kg',
    dimension: '40 x 30 x 30 cm',
    icon: motorcycleIcon,
    isShown: true,
    broadcastAvailability: ['all', 'squad'],
    max_weight: 8,
    disabled: false,
  },
  car: {
    description: 'Car',
    capacity: 'Parcels | 20kg',
    dimension: '70 x 50 x 50 cm',
    icon: carIcon,
    isShown: true,
    broadcastAvailability: ['all', 'squad'],
    max_weight: 20,
    disabled: false,
  },
  mpv: {
    description: 'MPV',
    capacity: 'Parcels | 50kg',
    dimension: '110 x 80 x 50 cm',
    icon: mpvIcon,
    isShown: true,
    broadcastAvailability: ['all', 'squad'],
    max_weight: 50,
    disabled: false,
  },
  'van_1.7m': {
    description: '1.7m Van',
    capacity: 'Cartons | 400kg',
    dimension: '160 x 120 x 100 cm',
    icon: van17dmIcon,
    isShown: true,
    broadcastAvailability: ['all', 'squad'],
    max_weight: 400,
    disabled: false,
  },
  'van_2.4m': {
    description: '2.4m Van',
    capacity: 'Cartons | 800kg',
    dimension: '230 x 120 x 120 cm',
    icon: van24dmIcon,
    isShown: true,
    broadcastAvailability: ['all', 'squad'],
    max_weight: 800,
    disabled: false,
  },
  lorry_10ft: {
    description: '10ft Truck',
    capacity: '≤ 2 pallets | 1 tonne',
    dimension: '290 x 140 x 170 cm',
    icon: lorry10ftIcon,
    isShown: true,
    broadcastAvailability: ['all', 'squad'],
    max_weight: 1000,
    disabled: false,
  },
  lorry_14ft: {
    description: '14ft Truck',
    capacity: '≤ 3 pallets | 2 tonnes',
    dimension: '420 x 170 x 190 cm',
    icon: lorry14ftIcon,
    isShown: true,
    broadcastAvailability: ['all', 'squad', 'marine'],
    max_weight: 2000,
    disabled: false,
  },
  lorry_24ft: {
    description: '24ft Truck',
    capacity: '≤ 12 pallets | 5 tonnes',
    dimension: '750 × 230 × 230 cm',
    icon: lorry24ftIcon,
    isShown: true,
    broadcastAvailability: ['all', 'squad', 'marine'],
    max_weight: 5000,
    disabled: false,
  },
  lorry_40ft: {
    description: '40ft Truck',
    capacity: '≤ 21 pallets | 16 tonnes',
    dimension: '1200 x 170 x 250 cm',
    icon: lorry40ftIcon,
    isShown: true,
    broadcastAvailability: ['all', 'squad', 'marine'],
    max_weight: 16000,
    disabled: false,
  },
  boat: {
    description: 'Boat',
    capacity: '≤ 20 pallets | 16 tonnes',
    dimension: '',
    icon: boatIcon,
    isShown: true,
    broadcastAvailability: ['marine'],
    max_weight: 16000,
    disabled: false,
  },
};

export const VEHICLE_PREFERENCE_FTL_CAPACITY: Record<string, { capacity: string }> = {
  lorry_10ft: {
    capacity: '≤ 2 pallets | 1 tonne',
  },
  lorry_14ft: {
    capacity: '≤ 3 pallets or 7 Liferafts | 2 tonnes',
  },
  lorry_24ft: {
    capacity: '≤ 12 pallets or 12 Liferafts | 5 tonnes',
  },
  lorry_40ft: {
    capacity: '≤ 21 pallets | 16 tonnes',
  },
  boat: {
    capacity: '≤ 20 pallets | 16 tonnes',
  },
};

export const VEHICLE_DIMENSION = {
  motorcycle: {
    length: 40,
    width: 30,
    height: 30,
  },
  car: {
    length: 70,
    width: 50,
    height: 50,
  },
  mpv: {
    length: 110,
    width: 80,
    height: 50,
  },
  'van_1.7m': {
    length: 160,
    width: 120,
    height: 100,
  },
  'van_2.4m': {
    length: 230,
    width: 120,
    height: 120,
  },
  lorry_10ft: {
    length: 290,
    width: 140,
    height: 170,
  },
  lorry_14ft: {
    length: 420,
    width: 170,
    height: 190,
  },
  lorry_24ft: {
    length: 750,
    width: 230,
    height: 230,
  },
  lorry_40ft: {
    length: 1200,
    width: 170,
    height: 250,
  },
};

const VEHICLE_CATEGORY = {
  land: 'land',
  sea: 'sea',
};

export const VEHICLE_CATEGORY_INFO: VehicleCategoryInfo = {
  land: {
    description: 'Land (e.g. trucks)',
  },
  sea: {
    description: 'Sea (e.g. lighter boats)',
  },
};

export type VehicleCategory = keyof typeof VEHICLE_CATEGORY;

export const VEHICLE_PREFERENCE_BY_CATEGORY: Record<VehicleCategory, string[]> = {
  land: [
    VEHICLE_PREFERENCE.motorcycle,
    VEHICLE_PREFERENCE.car,
    VEHICLE_PREFERENCE.mpv,
    VEHICLE_PREFERENCE.van_1_7m,
    VEHICLE_PREFERENCE.van_2_4m,
    VEHICLE_PREFERENCE.lorry_10ft,
    VEHICLE_PREFERENCE.lorry_14ft,
    VEHICLE_PREFERENCE.lorry_24ft,
    VEHICLE_PREFERENCE.lorry_40ft,
  ],
  sea: [VEHICLE_PREFERENCE.boat],
};

export type VehicleType = keyof typeof VEHICLE_PREFERENCE_INFO;

export type CategoryAndVehicleType = VehicleCategory | VehicleType;

export default VEHICLE_PREFERENCE;
