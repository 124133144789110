import * as React from 'react';
import styled from 'styled-components';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';

import COLOR, { transparent } from 'constants/color';
import Menu from '../../navbar/Navbar';
import { CurrentUser } from 'models/auth';
import { OpsRegisteredPath } from 'constants/registeredPath';

interface SysAdminHeaderProps {
  dispatch: Dispatch;
  selected: string;
  updateMargin: (height: number) => void;
}

interface StateProps {
  currentUser: CurrentUser;
}

type Props = SysAdminHeaderProps & StateProps;

class SysAdminHeader extends React.Component<Props> {
  render(): React.ReactNode {
    const sysAdminList = [
      {
        name: 'tasks',
        path: '/sys/tasks',
        text: 'Orders',
      },
      {
        name: 'runsheets',
        path: '/sys/runsheets',
        text: 'Runsheets',
      },
      {
        name: 'drivers',
        path: '/sys/drivers',
        text: 'Drivers',
      },
      {
        name: 'users',
        path: '/sys/users',
        text: 'Users',
      },
      {
        name: 'organizations',
        path: '/sys/organizations',
        text: 'Organizations',
      },
      {
        name: 'vessels',
        path: '/sys/vessels',
        text: 'Vessels',
      },
      {
        name: 'marine-locations',
        path: '/sys/marine-locations',
        text: 'Marine Locations',
      },
    ];

    return (
      <MainContainer
        ref={(ref: HTMLDivElement): void => {
          if (ref != null) {
            this.props.updateMargin(ref.offsetHeight);
          }
        }}
      >
        <ButtonContainer>
          <Menu
            user={this.props.currentUser.user}
            navList={sysAdminList}
            role={'sysAdmin'}
            registeredPath={OpsRegisteredPath}
            selected={this.props.selected}
          />
        </ButtonContainer>
      </MainContainer>
    );
  }
}

const MainContainer = styled.div`
  box-shadow: 0px 4px 6px ${transparent('black', 0.05)};
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  z-index: 9999;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: ${COLOR.white};
`;

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps)(SysAdminHeader);
