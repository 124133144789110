import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'components';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { formatNumberWithSpaces } from 'utils/formatter';

interface TotalDeliveryWeightProps {
  totalDeliveries: number;
  totalWeight: number;
}

const TotalDeliveryWeight: React.FC<TotalDeliveryWeightProps> = ({
  totalDeliveries,
  totalWeight,
}) => {
  return (
    <TotalDeliveryInfo>
      <Tooltip text="Total Delivery Locations">
        <MarkerIcon icon={faMapMarkerAlt} />
      </Tooltip>
      {totalDeliveries}
      <div>{`| Total Weight: ${formatNumberWithSpaces(totalWeight)}`} kg</div>
    </TotalDeliveryInfo>
  );
};

const MarkerIcon = styled(FontAwesomeIcon)`
  color: ${(props): string => props.theme.colors.gray_300};
  font-size: 1.1rem;
`;

const TotalDeliveryInfo = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
`;

export default TotalDeliveryWeight;
