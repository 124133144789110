import { Vessel } from 'models/vessel';

export interface VesselState {
  vessels: Vessel[];
  vessel: Vessel;
}

export const RECEIVE_VESSELS = 'RECEIVE_VESSELS';
export const RECEIVE_VESSEL = 'RECEIVE_VESSEL';

interface ReceiveVesselsAction {
  type: typeof RECEIVE_VESSELS;
  vessels: Vessel[];
}

interface ReceiveVesselAction {
  type: typeof RECEIVE_VESSEL;
  vessel: Vessel;
}

export type VesselActionTypes = ReceiveVesselsAction | ReceiveVesselAction;
