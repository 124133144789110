import { User } from 'models/auth/user';
import configureStore from 'reduxActions/store';
import { signoutSuccess, setAfterLoginPage } from 'reduxActions/auth/authActions';
import AuthClient from 'httpClients/authClient';

export function saveUser(user: string): void {
  localStorage.setItem('user', user);
}

export function getUser(): User {
  return JSON.parse(localStorage.getItem('user'));
}

export function removeUser(): void {
  localStorage.removeItem('user');
}

export function getBearerToken(): string {
  return localStorage.getItem('bearerToken');
}

export function saveBearerToken(token: string): void {
  localStorage.setItem('bearerToken', token);
}

export function removeBearerToken(): void {
  localStorage.removeItem('bearerToken');
}

export function logoutUser(promptMessage = '', afterLoginPage = ''): void {
  if (process.env.SEGMENT_KEY) {
    window.analytics.reset();
  }
  // Remove localStorage value
  removeBearerToken();
  removeUser();
  // Dispatch event
  const store = configureStore();
  // promptMessage is for displaying the alert prompt in the login page
  // afterLoginPage is set if user should be redirected to specified page after login
  store.dispatch(signoutSuccess(promptMessage));
  store.dispatch(setAfterLoginPage(afterLoginPage));
}

export async function fetchCurrentUser(): Promise<void> {
  const currentUser = getUser();
  const client = new AuthClient();
  await client.getUser(currentUser.id);
}

export async function checkAuthorization(statusCode: number): Promise<void> {
  const currentPath = window.location.pathname;
  const currentQueryParams = window.location.search;
  if (statusCode === 401 && currentPath !== '/login') {
    logoutUser('Re-login to see this page.', currentPath + currentQueryParams);
  }
}
