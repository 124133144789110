import * as SparkMD5 from 'spark-md5';

export function computeChecksumMd5(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152; // Read in chunks of 2MB (2 * 1024 * 1024)
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    let cursor = 0;

    fileReader.onerror = function(): void {
      reject('MD5 computation failed - error reading the file');
    };

    function processChunk(chunkStart: number): void {
      const chunkEnd = Math.min(file.size, chunkStart + chunkSize);
      fileReader.readAsArrayBuffer(file.slice(chunkStart, chunkEnd));
    }

    fileReader.onload = function(e: ProgressEvent<FileReader>): void {
      const resultArrayBuffer = e.target.result as ArrayBuffer;
      spark.append(resultArrayBuffer);
      cursor += chunkSize;

      if (cursor < file.size) {
        processChunk(cursor);
      } else {
        resolve(btoa(spark.end(true)));
      }
    };

    processChunk(0);
  });
}