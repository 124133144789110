import * as React from 'react';
import styled from 'styled-components';
import Header from './header';

interface MainContainerProps{
  children: React.ReactNode;
  selected: string;
}

interface MainContainerState {
  margin: number;
}

class MainContainer extends React.Component<MainContainerProps, MainContainerState> {
  constructor (props: MainContainerProps) {
    super(props);

    this.state = {
      margin: 0,
    };
  }

  render(): React.ReactNode {
    return (
      <>
        <Header
          selected={this.props.selected}
          updateMargin={(newMargin: number): void => {
            if (this.state.margin <= 0) {
              this.setState({ margin:  newMargin });
            }
          }}
        />
        <Container marginTop={this.state.margin}>
          {this.props.children}
        </Container>
      </>
    );
  }
}

const Container = styled.div<{ marginTop: number }>`
  margin: 1.875rem;
  margin-top: calc(${(props): string => `${props.marginTop}px`} + 1.5rem);
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 768px) {
    margin: 4rem 1.5rem;
  }
`;

export default MainContainer;