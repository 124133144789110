import * as React from 'react';
import { Typography, Radio, StyledButton } from 'components';
import styled from 'styled-components';
import COLOR, { transparent } from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  VEHICLE_PREFERENCE_FTL_CAPACITY,
  VEHICLE_PREFERENCE_INFO,
} from 'constants/vehiclePreference';
import { DEFAULT_FTL_VEHICLE, SERVICE_TYPE } from 'constants/serviceType';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import FTLVehicleModal from '../modal/ftlVehicleModal';
import { getServicePricing } from 'utils/taskHelper';

interface MarineServiceTypeFormProps {
  onSelectedService: (value: string, price: number) => void;
  selectedServiceType: string;
  selectedVehiclePreference: string;
  totalCargo: number;
  onVehicleClick: (vehicle: string, price: number) => void;
  disabled?: boolean;
}

interface MarineServiceTypeFormState {
  showSelectTruckModal: boolean;
}

class MarineServiceTypeForm extends React.Component<
  MarineServiceTypeFormProps,
  MarineServiceTypeFormState
> {
  scrollRef: React.RefObject<HTMLDivElement>;
  constructor(props: MarineServiceTypeFormProps) {
    super(props);
    this.state = {
      showSelectTruckModal: false,
    };
    this.scrollRef = React.createRef();
  }

  renderSelectTruckModal = (selectedVehiclePreference: string): React.ReactNode => (
    <>
      {this.state.showSelectTruckModal ? (
        <FTLVehicleModal
          closeModal={(): void => this.setState({ showSelectTruckModal: false })}
          selectedVehiclePreference={selectedVehiclePreference}
          onVehicleClick={(vehicle: string, price: number): void => {
            this.props.onVehicleClick(vehicle, price);
            this.setState({ showSelectTruckModal: false });
          }}
          servicePricing={SERVICE_TYPE[1].service_pricing}
          disabled={this.props.disabled}
        />
      ) : (
        false
      )}
    </>
  );

  render(): React.ReactNode {
    let ftlSelectedVehicle = this.props.selectedVehiclePreference;
    // keeping the ftl truck selection to DEFAULT_FTL_VEHICLE when selecting the ltl option
    if (ftlSelectedVehicle === 'ltl' || !this.props.selectedServiceType) {
      ftlSelectedVehicle = DEFAULT_FTL_VEHICLE;
    }
    const selectedServiceType =
      this.props.selectedServiceType === 'ftl' ? 'truck' : this.props.selectedServiceType;
    return (
      <Container>
        <Title>Service Type</Title>
        <LabelDescription>
          <Span>Select between LTL or FTL.</Span>
        </LabelDescription>
        <div>
          <ServiceTypeRadio>
            {SERVICE_TYPE.map((service, i) => {
              const selectedService = getServicePricing(
                service.service_type,
                ftlSelectedVehicle
              );
              const isTruckOrFtl =
                service.service_type === 'truck' || service.service_type === 'ftl';

              return (
                <Radio
                  key={i}
                  options={[
                    {
                      text: (
                        <>
                          <RowSpaceBetween>
                            <CardRow>
                              <ServiceTypeIcon icon={service.icon} />
                              <Typography
                                as="h1"
                                color="gray_700"
                                weight="semibold"
                                size="md"
                                customStyle={{
                                  textTransform: 'uppercase',
                                  marginLeft: '0.2rem',
                                  marginBottom: '0.3rem',
                                }}
                              >
                                {service.service_type}
                              </Typography>
                            </CardRow>
                            {service.eco_friendly ? (
                              <EcoFriendlyContainer>
                                <EcoFriendlyText>Eco-friendly</EcoFriendlyText>
                              </EcoFriendlyContainer>
                            ) : (
                              false
                            )}
                          </RowSpaceBetween>
                          <CardRow>
                            <ServiceTypeDescription>
                              {service.service_description}
                            </ServiceTypeDescription>
                          </CardRow>
                          <div>
                            {isTruckOrFtl ? (
                              <div>
                                <TruckSizeText>
                                  Truck size <RedText>*</RedText>
                                </TruckSizeText>
                              </div>
                            ) : (
                              false
                            )}
                            <Typography
                              as="div"
                              customStyle={{
                                backgroundColor: transparent('neutral', 0.2),
                                borderRadius: 15,
                                padding: '1rem',
                              }}
                            >
                              <Typography
                                as="div"
                                color="primary_600"
                                weight="semibold"
                                size="sm"
                              >
                                {isTruckOrFtl ? (
                                  <RowSpaceBetween>
                                    <Typography
                                      as="div"
                                      color="primary_600"
                                      weight="semibold"
                                      size="sm"
                                    >
                                      <Typography
                                        as="span"
                                        color="gray_700"
                                        weight="semibold"
                                        size="md"
                                        customStyle={{ marginRight: 'auto' }}
                                      >
                                        {
                                          VEHICLE_PREFERENCE_INFO[ftlSelectedVehicle]
                                            .description
                                        }
                                      </Typography>
                                      <Typography
                                        as="h1"
                                        color="gray_600"
                                        weight="normal"
                                        size="sm"
                                        customStyle={{ marginRight: 'auto' }}
                                      >
                                        {
                                          VEHICLE_PREFERENCE_FTL_CAPACITY[
                                            ftlSelectedVehicle
                                          ].capacity
                                        }
                                      </Typography>
                                    </Typography>
                                    <ButtonIcon
                                      disabled={this.props.disabled}
                                      buttonStyle="encourage"
                                      buttonType="neutral"
                                      icon={<FontAwesomeIcon icon={faPencilAlt} />}
                                      onClick={(): void => {
                                        this.setState({ showSelectTruckModal: true });
                                      }}
                                    />
                                  </RowSpaceBetween>
                                ) : (
                                  false
                                )}
                                <div>
                                  <Typography
                                    as="h1"
                                    color="primary_600"
                                    weight="semibold"
                                    size="sm"
                                    customStyle={{ marginRight: 'auto' }}
                                  >
                                    {selectedService.currency} {selectedService.price}{' '}
                                    {selectedService.uom_description}
                                  </Typography>
                                  <Typography
                                    as="h1"
                                    color="gray_600"
                                    weight="normal"
                                    size="sm"
                                    customStyle={{ marginRight: 'auto' }}
                                  >
                                    {isTruckOrFtl && selectedService.additional_cost}
                                    {service.service_type === 'ltl' && (
                                      <>
                                        <div
                                          onClick={(): void => {
                                            if (!this.props.disabled) {
                                              this.scrollRef.current.scrollIntoView({
                                                behavior: 'smooth',
                                                block: 'start',
                                              });
                                            }
                                          }}
                                        >
                                          {`${this.props.totalCargo} lifts = ${
                                            selectedService.currency
                                          } ${
                                            this.props.totalCargo * selectedService.price
                                          }`}
                                          <Typography
                                            as="span"
                                            color="primary_600"
                                            weight="normal"
                                            size="sm"
                                          >
                                            *
                                          </Typography>
                                        </div>
                                      </>
                                    )}

                                    {service.service_type === 'boat' && (
                                      <>
                                        <Typography
                                          as="h1"
                                          color="gray_600"
                                          weight="normal"
                                          size="sm"
                                          customStyle={{ marginRight: 'auto' }}
                                        >
                                          {VEHICLE_PREFERENCE_INFO['boat'].capacity}
                                        </Typography>
                                        {selectedService.additional_cost}
                                      </>
                                    )}
                                  </Typography>
                                </div>
                              </Typography>
                            </Typography>
                          </div>
                        </>
                      ),
                      value: service.service_type,
                      disabled: this.props.disabled,
                    },
                  ]}
                  value={selectedServiceType}
                  onChange={(value: string): void =>
                    this.props.onSelectedService(value, selectedService.price)
                  }
                />
              );
            })}
          </ServiceTypeRadio>
          <div ref={this.scrollRef}>
            <Typography
              as="div"
              color="gray_400"
              weight="normal"
              size="xs"
              customStyle={{ marginTop: '0.5rem' }}
            >
              *Rates shown here may be different than the actual charges for your
              shipment.
            </Typography>
          </div>
        </div>
        {this.renderSelectTruckModal(ftlSelectedVehicle)}
      </Container>
    );
  }
}

const Container = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ServiceTypeRadio = styled.div`
  display: grid;
  gap: 0.3rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const LabelDescription = styled.div`
  margin-bottom: 0.5rem;
`;

const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Span = styled.span`
  font-size: 0.8rem;
  color: gray;
`;

const ServiceTypeIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${COLOR.darkGray};
`;

const CardRow = styled.div`
  display: flex;
`;

const EcoFriendlyContainer = styled.div`
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 1rem;
  align-self: center;
  background-color: ${(props): string => props.theme.colors.success_50};
`;

const EcoFriendlyText = styled.div`
  color: ${(props): string => props.theme.colors.success_600};
  font-size: 0.75rem;
  font-weight: 600;
`;

const ServiceTypeDescription = styled.div`
  color: ${COLOR.midDarkGrey};
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

const TruckSizeText = styled.div`
  font-size: 0.875rem;
  margin-bottom: 0.2rem;
`;

const RedText = styled.span`
  color: ${COLOR.red};
`;

const ButtonIcon = styled(StyledButton)`
  padding: 8px;
  width: 40px;
  height: 40px;
`;

export default MarineServiceTypeForm;
