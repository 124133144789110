import {
  ReceiveRunsheetGenerationJobDetailAction,
  ReceiveRunsheetGenerationJobsAction,
  RECEIVE_RUNSHEET_GENERATION_JOBS,
  RECEIVE_RUNSHEET_GENERATION_JOB_DETAIL,
} from './runsheetGenerationTypes';
import { RunsheetGenerationJob } from 'models/runsheetGeneration';

export function receiveRunsheetGenerationJobs(runsheetGenerationJobs: RunsheetGenerationJob[]): ReceiveRunsheetGenerationJobsAction {
  return {
    type: RECEIVE_RUNSHEET_GENERATION_JOBS,
    runsheetGenerationJobs,
  };
}

export function receiveRunsheetGenerationJob(runsheetGenerationJob: RunsheetGenerationJob): ReceiveRunsheetGenerationJobDetailAction {
  return {
    type: RECEIVE_RUNSHEET_GENERATION_JOB_DETAIL,
    runsheetGenerationJob,
  };
}