import * as React from 'react';
import styled, { css } from 'styled-components';
import COLOR from 'constants/color';
import TextInput from '../textInput';

interface RadioItemProps {
  children?: React.ReactNode;
  onChange: (v: string) => void;
  selectedValue: string;
  value: string;
  withTextInput: boolean;
  textInputPlaceholder?: string;
  textInputRequired: boolean;
  disabled: boolean;
}

interface RadioItemState {
  textInput: string;
}

class RadioItem extends React.Component<RadioItemProps, RadioItemState> {
  constructor(props: RadioItemProps) {
    super(props);
    this.state = {
      textInput: '',
    };
  }

  onInputChange = (): void => {
    if (this.props.withTextInput) {
      this.props.onChange(`${this.props.value} - ${this.state.textInput}`);
    } else {
      this.props.onChange(this.props.value);
    }
  };

  render(): React.ReactNode {
    const {
      children,
      selectedValue,
      value,
      withTextInput,
      textInputRequired,
      disabled,
      textInputPlaceholder,
    } = this.props;
    const { textInput } = this.state;
    const selected = withTextInput
      ? selectedValue.indexOf(`${value} - `) === 0
      : selectedValue === value;

    return (
      <Container
        onClick={(e): void => {
          if (!disabled) {
            e.stopPropagation();
            this.onInputChange();
          }
        }}
        disabled={disabled}
      >
        <OuterDiv disabled={disabled} selected={selected}>
          {selected ? <InnerDiv disabled={disabled} /> : false}
        </OuterDiv>
        <Content disabled={disabled}>
          {children}
          {selected && withTextInput && (
            <TextInput
              containerStyle={css`
                margin-top: calc(1em - 0.8rem);
              `}
              height="small"
              width="auto"
              placeholder={textInputPlaceholder}
              isRequired={textInputRequired}
              onTextChange={(value): void => {
                this.setState(() => ({ textInput: value }), this.onInputChange);
              }}
              value={textInput}
            />
          )}
        </Content>
      </Container>
    );
  }
}

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
  padding: calc(1em - 0.8rem);
`;

const Content = styled.div<{ disabled: boolean }>`
  color: ${(props): string => (props.disabled ? COLOR.midDarkGrey : COLOR.black)};
  width: calc(100% - 1em);
  overflow-wrap: anywhere;
`;

const OuterDiv = styled.div<{ disabled: boolean; selected?: boolean }>`
  position: relative;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  background-color: ${(props) =>
    props.disabled
      ? COLOR.midWhiteGrey
      : props.selected
      ? props.theme.colors.primary_50
      : COLOR.white};
  border: 1px solid
    ${(props) =>
      props.disabled
        ? COLOR.midDarkGrey
        : props.selected
        ? COLOR.blue
        : COLOR.midLightGrey};
  margin-right: calc(1em - 0.5rem);
`;

const InnerDiv = styled.div<{ disabled: boolean }>`
  border-radius: 50%;
  background-color: ${(props): string => (props.disabled ? COLOR.grey : COLOR.blue)};
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%);
`;

export default RadioItem;
