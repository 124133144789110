import COLOR from './color';

interface Status {
  [key: string]: string;
}

interface StatusColor {
  [key: string]: string;
}

export const TASK_STATUS = {
  CREATED: 'created',
  UPDATED: 'updated',
  PENDING_BROADCAST: 'pending_broadcast',
  BROADCASTING: 'broadcasting',
  CANCELLED: 'cancelled',
  ACCEPTED: 'accepted',
  PENDING_PICKUP: 'pending_pickup',
  UNSUCCESSFUL_PICKUP: 'unsuccessful_pickup',
  PICKUP_ARRIVING: 'pickup_arriving',
  PENDING_DELIVERY: 'pending_delivery',
  DELIVERY_ARRIVING: 'delivery_arriving',
  UNSUCCESSFUL_DELIVERY: 'unsuccessful_delivery',
  COMPLETE: 'complete',
  EXPIRED: 'expired',
} as const;


const STATUS: Status = {
  [TASK_STATUS.CREATED]: 'Order Placed',
  [TASK_STATUS.UPDATED]: 'Order Edited',
  [TASK_STATUS.PENDING_BROADCAST]: 'Pending Broadcast',
  [TASK_STATUS.BROADCASTING]: 'Broadcasting',
  [TASK_STATUS.CANCELLED]: 'Cancelled',
  [TASK_STATUS.ACCEPTED]: 'Accepted',
  [TASK_STATUS.PENDING_PICKUP]: 'Pending Pickup',
  [TASK_STATUS.UNSUCCESSFUL_PICKUP]: 'Unsuccessful Pickup',
  [TASK_STATUS.PICKUP_ARRIVING]: 'Pickup Arriving',
  [TASK_STATUS.PENDING_DELIVERY]: 'Pending Delivery',
  [TASK_STATUS.DELIVERY_ARRIVING]: 'Delivery Arriving',
  [TASK_STATUS.UNSUCCESSFUL_DELIVERY]: 'Unsuccessful Delivery',
  [TASK_STATUS.COMPLETE]: 'Complete',
  [TASK_STATUS.EXPIRED]: 'Expired',
};

export const STATUS_DESCRIPTION: Status = {
  [TASK_STATUS.CREATED]: 'Created',
  [TASK_STATUS.UPDATED]: 'Updated',
  [TASK_STATUS.PENDING_BROADCAST]: 'Pending Broadcast',
  [TASK_STATUS.BROADCASTING]: 'Broadcasting',
  [TASK_STATUS.CANCELLED]: 'Cancelled',
  [TASK_STATUS.ACCEPTED]: 'Accepted',
  [TASK_STATUS.PENDING_PICKUP]: 'Pending Pickup',
  [TASK_STATUS.UNSUCCESSFUL_PICKUP]: 'Unsuccessful Pickup',
  [TASK_STATUS.PICKUP_ARRIVING]: 'Pickup Arriving',
  [TASK_STATUS.PENDING_DELIVERY]: 'Pending Delivery',
  [TASK_STATUS.DELIVERY_ARRIVING]: 'Delivery Arriving',
  [TASK_STATUS.UNSUCCESSFUL_DELIVERY]: 'Unsuccessful Delivery',
  [TASK_STATUS.COMPLETE]: 'Complete',
  [TASK_STATUS.EXPIRED]: 'Expired',
};

export const STATUS_COLOR: StatusColor = {
  [TASK_STATUS.CREATED]: COLOR.grayishBlue,
  [TASK_STATUS.UPDATED]: COLOR.grayishBlue,
  [TASK_STATUS.PENDING_BROADCAST]: COLOR.blue,
  [TASK_STATUS.BROADCASTING]: COLOR.blue,
  [TASK_STATUS.ACCEPTED]: COLOR.blue,
  [TASK_STATUS.CANCELLED]: COLOR.red,
  [TASK_STATUS.PENDING_PICKUP]: COLOR.yellow,
  [TASK_STATUS.UNSUCCESSFUL_PICKUP]: COLOR.red,
  [TASK_STATUS.PICKUP_ARRIVING]: COLOR.yellow,
  [TASK_STATUS.PENDING_DELIVERY]: COLOR.yellow,
  [TASK_STATUS.DELIVERY_ARRIVING]: COLOR.yellow,
  [TASK_STATUS.UNSUCCESSFUL_DELIVERY]: COLOR.red,
  [TASK_STATUS.COMPLETE]: COLOR.cyan,
  [TASK_STATUS.EXPIRED]: COLOR.rouge,
};

export const TaskEditableStatuses: string[] = [
  TASK_STATUS.PENDING_BROADCAST,
  TASK_STATUS.BROADCASTING,
  TASK_STATUS.ACCEPTED,
  TASK_STATUS.PENDING_PICKUP,
  TASK_STATUS.PICKUP_ARRIVING,
  TASK_STATUS.PENDING_DELIVERY,
  TASK_STATUS.DELIVERY_ARRIVING,
];

export const TaskEditableStatusesMarine: string[] = [
  TASK_STATUS.PENDING_BROADCAST,
  TASK_STATUS.BROADCASTING,
  TASK_STATUS.ACCEPTED,
  TASK_STATUS.PENDING_PICKUP,
  TASK_STATUS.PICKUP_ARRIVING,
  TASK_STATUS.PENDING_DELIVERY,
  TASK_STATUS.DELIVERY_ARRIVING,
  TASK_STATUS.UPDATED,
  TASK_STATUS.COMPLETE, // for editing final price
];

export const ActiveOrderStatusStrings: string[] = [
  TASK_STATUS.CREATED,
  TASK_STATUS.PENDING_BROADCAST,
  TASK_STATUS.BROADCASTING,
  TASK_STATUS.ACCEPTED,
  TASK_STATUS.PENDING_PICKUP,
  TASK_STATUS.PICKUP_ARRIVING,
  TASK_STATUS.PENDING_DELIVERY,
  TASK_STATUS.DELIVERY_ARRIVING,
];

export const PastOrderStatusStrings: string[] = [
  TASK_STATUS.EXPIRED,
  TASK_STATUS.COMPLETE,
  TASK_STATUS.CANCELLED,
  TASK_STATUS.UNSUCCESSFUL_PICKUP,
  TASK_STATUS.UNSUCCESSFUL_DELIVERY,
];

export const TaskEditableStatusesMarineMerchant: string[] = [
  TASK_STATUS.CREATED,
  TASK_STATUS.PENDING_BROADCAST,
  TASK_STATUS.BROADCASTING,
  TASK_STATUS.ACCEPTED, // for accepted status, editable until At least 3 hours before pickup start time
];

export default STATUS;