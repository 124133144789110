import { ContractPricing, ContractPricingBasic } from 'models/contractPricing';

export interface ContractPricingState {
  contractPricings: ContractPricingBasic[];
  contractPricing: ContractPricing;
}

export const RECEIVE_CONTRACT_PRICINGS = 'RECEIVE_CONTRACT_PRICINGS';
export const RECEIVE_CONTRACT_PRICING = 'RECEIVE_CONTRACT_PRICING';

export interface ReceiveContractPricings {
  type: typeof RECEIVE_CONTRACT_PRICINGS;
  contractPricings: ContractPricingBasic[];
}

export interface ReceiveContractPricing {
  type: typeof RECEIVE_CONTRACT_PRICING;
  contractPricing: ContractPricing;
}

export type ContractPricingActionTypes = ReceiveContractPricings | ReceiveContractPricing;