import * as React from 'react';
import {
  StyledButton, Alert, TextInput, BeforeLoginContainer,
} from 'components';
import styled from 'styled-components';
import AuthClient from 'httpClients/authClient';
import * as H from 'history';
import COLOR from 'constants/color';
import { Link } from 'react-router-dom';
import gmailLogo from 'assets/images/gmail.png';
import outlookLogo from 'assets/images/outlook.png';

interface ForgotPasswordProps<S = H.LocationState> {
  history: H.History<S>;
}

interface ForgotPasswordState {
  form: {
    email: string;
  };
  error: string | null;
  isSuccess: boolean;
}

class ForgotPassword extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = {
      form: {
        email: '',
      },
      error: null,
      isSuccess: false,
    };
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  resetPassword = async (): Promise<void> => {
    const { form } = this.state;

    this.setState({ error: null });
    try {
      const client = new AuthClient();
      await client.forgotPassword({
        user: {
          email: form.email,
        },
      });
      this.setState({ isSuccess: true });
    } catch (e) {
      this.setState({ error: e });
    }
  }

  renderBeforeReset = (): React.ReactNode => (
    <>
      <Description>
        To reset your password, enter the email address you use to sign in.
      </Description>
      <Div>
        <TextInput
          fieldName="Email"
          isRequired
          name="email"
          onTextChange={(value: string): void => this.handleChange('email', value)}
          type="email"
          value={this.state.form.email}
          required
        />
      </Div>
      {this.state.error && (
        <Div>
          <Alert status='error'>{this.state.error}</Alert>
        </Div>
      )}
      <Div>
        <StyledButton
          buttonStyle="encourage"
          buttonType="primary"
          disabled={this.state.form.email.trim() === ''}
          onClick={this.resetPassword}
          isFullWidth={true}
          size='lg'
        >
          Reset Password
        </StyledButton>
      </Div>
    </>
  )

  renderAfterReset = (): React.ReactNode => (
    <>
      <Description>
        {'Check your email. We’ve sent instructions to '}
        <HighLightedEmail>
          {this.state.form.email}.
        </HighLightedEmail>
      </Description>
      <Div>
        {'Wrong email? '}
        <BackLink
          onClick={(): void => { this.setState({ isSuccess: false }); }}
        >
          Change your email
        </BackLink>
      </Div>
      <Div>
        <Alert status='success'>
          Check your email. If you have an account with us, we’ve sent instructions to reset your password.
        </Alert>
      </Div>
      <MailsContainer>
        <MailContainer
          onClick={(): void => { window.open('https://mail.google.com/'); }}
        >
          <img src={gmailLogo} />
          <MailText>
            Open Gmail
          </MailText>
        </MailContainer>
        <MailContainer
          onClick={(): void => { window.open('https://outlook.office365.com/mail'); }}
        >
          <img src={outlookLogo} />
          <MailText>
            Open Outlook
          </MailText>
        </MailContainer>
      </MailsContainer>
      <Div>
        <Link to="/login">
          <StyledButton
            buttonStyle="encourage"
            buttonType="primary"
            isFullWidth={true}
            size='lg'
          >
            Return to login
          </StyledButton>
        </Link>
      </Div>
    </>
  );

  render(): React.ReactNode {
    const { isSuccess } = this.state;

    return (
      <BeforeLoginContainer title="Password Reset">
        {
          isSuccess ? this.renderAfterReset() : this.renderBeforeReset()
        }
      </BeforeLoginContainer>
    );
  }
}

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const Div = styled.div`
  margin-top: 1.875rem;
`;

const MailsContainer = styled(Div)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const MailContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-right: 1rem;
`;

const MailText = styled.div`
  margin-left: 0.375rem;
`;

const HighLightedEmail = styled.span`
  font-weight: 600;
`;

const BackLink = styled.span`
  color: ${COLOR.blue};
  cursor: pointer;
  text-decoration: underline;
`;

export default ForgotPassword;
