import * as React from 'react';
import {
  MainContainer,
  Breadcrumb,
  Heading,
  PageTitle,
  Separator,
  StyledButton,
  Alert,
  TextInput,
  CenterModal,
} from 'components';
import { RouteComponentProps } from 'react-router-dom';
import { User } from 'models/auth';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faExclamationCircle, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import SettingSidebarMenu from '../../sidebarMenu';
import AuthClient from 'httpClients/authClient';

interface ProfileProps {
  user: User;
}

interface Form {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

const getDefaultForm = (): Form => {
  return {
    current_password: '',
    new_password: '',
    confirm_password: '',
  };
};

type Props = RouteComponentProps & ProfileProps;

const ChangePasswordEdit = (props: Props): JSX.Element => {
  const [currentPasswordShown, setCurrentPasswordShown] = React.useState(false);
  const [newPasswordShown, setNewPasswordShown] = React.useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = React.useState(false);
  const [isFetching, setFetching] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState('');
  const [form, setForm] = React.useState(getDefaultForm());
  const pattern = new RegExp(/[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);

  const isPasswordValid = (): boolean => {
    return pattern.test(form.confirm_password);
  };

  const isNotEmpty = (): boolean => {
    return (form.current_password.length > 2) && (form.current_password.trim() != '') &&
      (form.new_password.length >= 8) && (form.new_password.trim() != '') &&
      (form.confirm_password.length >= 8) && (form.confirm_password.trim() != '');
  };

  const isConfirmPasswordValid = (): boolean => {
    return form.new_password === form.confirm_password;
  };

  const handleChange = (fieldName: string, value: string): void => {
    setForm(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const save = async (form: Form): Promise<void> => {
    setError('');
    try {
      setFetching(true);
      setSuccess(false);
      const client = new AuthClient();
      await client.updatePassword({
        user: {
          current_password: form.current_password,
          password: form.new_password,
        },
      });
      setSuccess(true);
    } catch (e) {
      if ('current_password' in e.errors) {
        setError('Incorrect password');
      } else {
        setError(e);
      }
    }
    setFetching(false);
  };

  return (
    <MainContainer selected="setting">
      <Breadcrumb
        items={[
          { text: 'Setting', onClick: (): void => { props.history.push('/setting'); } },
          { text: 'Change Password' },
        ]}
      />
      <Heading>
        <PageTitle>Setting</PageTitle>
      </Heading>
      <SettingSidebarMenu selected="/setting/personal-profile/change-password">
        <Heading>
          <PageTitle>
            Change Password
          </PageTitle>
          {props.user && (
            <EditButtonContainer>
              <StyledButton
                buttonStyle="encourage"
                buttonType="neutral"
                onClick={(): void => { props.history.push('/setting/personal-profile/change-password'); }}
              >
                <Icon icon={faTimes} />
                Cancel
              </StyledButton>
              <StyledButton
                buttonStyle="encourage"
                buttonType="primary"
                disabled={
                  isFetching ||
                  !isNotEmpty() ||
                  !isPasswordValid() ||
                  !isConfirmPasswordValid()
                }
                onClick={(): void => { save({ ...form }); }}
              >
                <Icon icon={faCheck} color={COLOR.white} />
                Save
              </StyledButton>
            </EditButtonContainer>
          )}
        </Heading>
        <CustomSeparator />
        {props.user && (
          <FieldColumn>
            <TextInput
              fieldName="Current Password"
              icon={currentPasswordShown ? faEye : faEyeSlash}
              iconOnClick={(): void => {
                setCurrentPasswordShown(!currentPasswordShown);
              }}
              isRequired
              name="current_password"
              onTextChange={(value: string): void => handleChange('current_password', value)}
              type={currentPasswordShown ? 'text' : 'password'}
              value={form.current_password}
              width="large"
            />
            {error ? (
              <InlineErrorMessage>
                <Icon icon={faExclamationCircle} />
                {error}
              </InlineErrorMessage>
            ) : false}
            <TextInput
              fieldName="New Password"
              icon={newPasswordShown ? faEye : faEyeSlash}
              iconOnClick={(): void => {
                setNewPasswordShown(!newPasswordShown);
              }}
              isRequired
              name="new_password"
              onTextChange={(value: string): void => handleChange('new_password', value)}
              type={newPasswordShown ? 'text' : 'password'}
              value={form.new_password}
              width="large"
              extraLabel={<PasswordInfo>Minimum 8 characters and at least 1 special character.</PasswordInfo>}
            />
            <TextInput
              fieldName="Confirm Password"
              icon={confirmPasswordShown ? faEye : faEyeSlash}
              iconOnClick={(): void => {
                setConfirmPasswordShown(!confirmPasswordShown);
              }}
              isRequired
              name="confirm_password"
              onTextChange={(value: string): void => handleChange('confirm_password', value)}
              type={confirmPasswordShown ? 'text' : 'password'}
              value={form.confirm_password}
              width="large"
            />
            {form.confirm_password.length >= 8 ? (
              <>
                {isPasswordValid() ? false : (
                  <InlineErrorMessage>
                    <Icon icon={faExclamationCircle} />
                    Minimum 8 characters and at least 1 special character.
                  </InlineErrorMessage>
                )}
              </>
            ) : false}
            {form.confirm_password.length > 2 ? (
              <>
                {isConfirmPasswordValid() ? false : (
                  <InlineErrorMessage>
                    <Icon icon={faExclamationCircle} />
                    Password do not match
                  </InlineErrorMessage>
                )}
              </>
            ) : false}
            {success ? (
              <CenterModal
                rightButtonText="Ok"
                rightButtonOnClick={(): void => { props.history.push('/setting/personal-profile/change-password'); }}
                title="Change Password Success"
              >
                <Alert status='success'>
                  <AlertMessage>Your password has been updated.</AlertMessage>
                </Alert>
              </CenterModal>
            ) : false}
          </FieldColumn>
        )}
      </SettingSidebarMenu>
    </MainContainer>
  );
};

const EditButtonContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldColumn = styled(Column)`
  flex: 1;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  };
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.5rem;
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
`;

const InlineErrorMessage = styled.div`
  margin-top: -1.3rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const AlertMessage = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

const PasswordInfo = styled.div`
  margin-top: 0.2rem;
  font-size: 0.7rem;
  color: ${COLOR.grey};
`;

function mapStateToProps(state: RootState): ProfileProps {
  return {
    user: state.auth.currentUser.user,
  };
}

export default connect(mapStateToProps)(ChangePasswordEdit);