import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as H from 'history';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  faPlusCircle,
  faTrash,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Table,
  Pagination,
  StyledButton,
  PaginationState,
  Breadcrumb,
  Separator,
  TableCellRenderEvent,
  Heading,
  CenterModal,
  PageTitle,
  SysAdminMainContainer,
} from 'components';
import emptyPlaceholder from 'assets/images/active_order_empty_state.png';
import COLOR from 'constants/color';
import GeoServiceClient from 'httpClients/geoServiceClient';
import SettingSidebarMenu from '../sidebarMenu';
import { GeoData, SavedGeoData, SysCreateGeoData } from 'models/geoService';
import AddressContactModal, {
  AddressContactData,
} from 'pages/sys/task/modal/addressContactModal';
import LocateMapModal from 'pages/order/modal/locateMapModal';
import { Address, Contact } from 'models/task';
import { Organization } from 'models/organization';
import { formatAddress } from 'utils/formatter';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';

interface IconProps {
  color: string;
}

interface SavedAddressProps {
  organization: Organization;
  models: SavedGeoData[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface BaseForm extends Record<string, string | Address | Contact | boolean> {
  saved_address_name: string;
  saved_address: Address;
  saved_contact: Contact;
  is_default_pickup: boolean;
  is_default_delivery: boolean;
  org_id: string;
}

interface ErrorInfo extends Record<string, string> {
  general: string;
}

interface SavedAddressState extends PaginationState<SavedGeoData> {
  showAddressContactModal: boolean;
  showPickupLocationModal: boolean;
  showRemoveMembersModal: boolean;
  selectAddressID: string;
  baseForm: BaseForm;
  error: ErrorInfo;
}

type Props = RouteComponentProps<{ id: string }> & SavedAddressProps & HistoryProps;

class SysAdminSavedAddressIndex extends Pagination<
  SavedGeoData,
  Props,
  SavedAddressState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      baseForm: this.getDefaultBaseForm(),
      error: this.getDefaultError(),
      basePath: `/sys/organizations/${this.props.match.params.id}/saved-address`,
      showAddressContactModal: false,
      showPickupLocationModal: false,
      renderContainer: false,
      renderSortBy: false,
      filters: this.defaultFilter(),
      pluralModelName: 'geoDatas',
    };
    this.rules = [{ name: 'page' }];
  }

  getDefaultBaseForm(): BaseForm {
    return {
      saved_address_name: '',
      saved_address: {
        street_address: '',
        city: 'Singapore',
        country: 'Singapore',
        state: 'Singapore',
        zip_code: '',
        latitude: 0,
        longitude: 0,
      },
      org_id: this.props.match.params.id,
      saved_contact: {},
      is_default_pickup: false,
      is_default_delivery: false,
      showRemoveMembersModal: false,
      selectAddressID: '',
    };
  }

  getDefaultError(): ErrorInfo {
    return {
      general: '',
    };
  }

  defaultFilter = (): Record<string, string> => ({
    page: '1',
  });

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const orgClient = new OrganizationManagementClient();
    await orgClient.sysAdminGetOrganization(this.props.match.params.id);

    const client = new GeoServiceClient();
    const searchParams = new URLSearchParams(this.state.filters);
    searchParams.append('org_id', this.props.match.params.id);
    await client.getSysSavedAddress(searchParams);

    this.setState({ isFetching: false });
  };

  showAddressContactModal = (): void => {
    this.setState({ showAddressContactModal: true });
  };

  closeAddressContactModal = (): void => {
    this.setState({ showAddressContactModal: false });
  };

  onSubmit = async (): Promise<void> => {
    const { baseForm } = this.state;

    const savedGeoForm: SysCreateGeoData = {
      street_address: baseForm.saved_address.street_address,
      name_address: baseForm.saved_address_name,
      unit_number: baseForm.saved_address.unit_number,
      building_name: baseForm.saved_address.building_name,
      city: baseForm.saved_address.city,
      state: baseForm.saved_address.state,
      country: baseForm.saved_address.country,
      zip_code: baseForm.saved_address.zip_code,
      lat: baseForm.saved_address.latitude,
      lng: baseForm.saved_address.longitude,
      contact_name: baseForm.saved_contact.name,
      contact_phone: baseForm.saved_contact.phone,
      contact_email: baseForm.saved_contact.email,
      is_default_pickup: baseForm.is_default_pickup,
      is_default_delivery: baseForm.is_default_delivery,
      org_id: baseForm.org_id,
    };
    const client = new GeoServiceClient();
    try {
      await client.sysCreateSavedAddress(savedGeoForm);
      await this.fetchData();
      this.setState({ baseForm: this.getDefaultBaseForm() });
    } catch (error) {
      this.setState((prevState) => ({
        error: {
          ...prevState.error,
          general: error,
        },
      }));
    }
  };

  selectPickupAddress = (streetAddress: string, geoData: GeoData): void => {
    this.setState((prevState) => ({
      baseForm: {
        ...prevState.baseForm,
        saved_address: {
          ...prevState.baseForm.saved_address,
          street_address: streetAddress,
          building_name: geoData.building_name,
          latitude: geoData.lat,
          longitude: geoData.lng,
          zip_code: geoData.zip_code,
        },
      },
    }));
  };

  onSelectPickupAddress = (geoData: GeoData): void => {
    this.selectPickupAddress(geoData.street_address, geoData);
  };

  renderLocateMapModal = (): React.ReactNode => {
    const { baseForm, showPickupLocationModal } = this.state;

    return (
      <>
        {showPickupLocationModal && (
          <LocateMapModal
            defaultAddress={{
              building_name: baseForm.saved_address.building_name
                ? baseForm.saved_address.building_name
                : '',
              street_address: baseForm.saved_address.street_address
                ? baseForm.saved_address.street_address
                : '',
              lat: baseForm.saved_address.latitude ? baseForm.saved_address.latitude : 0,
              lng: baseForm.saved_address.longitude
                ? baseForm.saved_address.longitude
                : 0,
              zip_code: baseForm.saved_address.zip_code
                ? baseForm.saved_address.zip_code
                : '',
            }}
            closeModal={(): void => {
              this.setState({
                showPickupLocationModal: false,
                showAddressContactModal: true,
              });
            }}
            onSelectAddress={this.onSelectPickupAddress}
          />
        )}
      </>
    );
  };

  renderAddressContactModal = (): React.ReactNode => (
    <>
      {this.state.showAddressContactModal && (
        <AddressContactModal
          title="Add Address"
          type="ops"
          isHideSavedAddress
          closeModal={this.closeAddressContactModal}
          defaultForm={{
            address: this.state.baseForm.saved_address,
            contact: this.state.baseForm.saved_contact,
            saved_address_name: this.state.baseForm.saved_address_name,
            is_default_pickup: this.state.baseForm.is_default_pickup,
            is_default_delivery: this.state.baseForm.is_default_delivery,
            org_id: this.state.baseForm.org_id,
          }}
          onConfirm={async (data: AddressContactData): Promise<void> => {
            this.setState(
              (prevState) => ({
                baseForm: {
                  ...prevState.baseForm,
                  saved_address: data.address,
                  saved_contact: data.contact,
                  saved_address_name: data.saved_address_name,
                  is_default_pickup: data.is_default_pickup,
                  is_default_delivery: data.is_default_delivery,
                },
              }),
              () => {
                this.onSubmit();
              }
            );
          }}
        />
      )}
    </>
  );

  renderError = (): React.ReactNode => {
    return this.state.error.general && this.state.error.general !== '' ? (
      <InlineErrorMessage>
        <ErrorIcon icon={faExclamationCircle} />
        {this.state.error.general}
      </InlineErrorMessage>
    ) : (
      false
    );
  };

  renderPage = (content: JSX.Element): React.ReactNode => {
    return (
      <SysAdminMainContainer selected="organizations">
        <Breadcrumb
          items={[
            {
              text: 'Organizations',
              onClick: (): void => {
                this.props.history.push('/sys/organizations');
              },
            },
            {
              text: this.props.match.params.id,
              onClick: (): void => {
                this.props.history.push(
                  '/sys/organizations/' + this.props.match.params.id + '/company-details'
                );
              },
            },
            { text: 'Saved Addresses' },
          ]}
        />
        <Heading>
          <PageTitle>Setting</PageTitle>
        </Heading>
        <SettingSidebarMenu
          selected={`/sys/organizations/${this.props.match.params.id}/saved-address`}
        >
          <Heading>
            <PageTitle>Saved Addresses</PageTitle>
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={this.showAddressContactModal}
            >
              <Icon icon={faPlusCircle} color={COLOR.black} />
              Add Address
            </StyledButton>
          </Heading>
          <CustomSeparator />
          {this.renderError()}
          {this.renderFilterAndPagination()}
          {content}
          {this.renderAddressContactModal()}
          {this.renderLocateMapModal()}
        </SettingSidebarMenu>
      </SysAdminMainContainer>
    );
  };

  renderHeader = (): React.ReactNode => {
    return false;
  };

  renderEmptyState = (): React.ReactNode => {
    return (
      <>
        {this.renderPage(
          <EmptyContainer>
            <div>
              <Placeholder src={emptyPlaceholder} />
            </div>
            <Subtitle>No saved address yet</Subtitle>
            <Description>Add address.</Description>
          </EmptyContainer>
        )}
      </>
    );
  };

  confirmRemoveAddress = (selectAddressID: string): void => {
    this.setState({
      showRemoveMembersModal: true,
      selectAddressID,
    });
  };

  closeRemoveAddress = (): void => {
    this.setState({
      showRemoveMembersModal: false,
      selectAddressID: '',
    });
  };

  renderCell = (
    e: TableCellRenderEvent<{
      id: string;
      name_address: string;
      street_address: string;
      is_default_pickup_or_delivery: string;
      action: string;
    }>
  ): React.ReactNode => {
    if (e.key === 'is_default_pickup_or_delivery') {
      if (e.value === 'pickup') {
        return (
          <DefaultSavedAddressContainer>
            <DefaultSavedAddress>Pickup</DefaultSavedAddress>
          </DefaultSavedAddressContainer>
        );
      } else if (e.value === 'delivery') {
        return (
          <DefaultSavedAddressContainer>
            <DefaultSavedAddress>Delivery</DefaultSavedAddress>
          </DefaultSavedAddressContainer>
        );
      }
      return <></>;
    } else if (e.key === 'action') {
      return (
        <RemoveButton
          buttonStyle="discourage"
          buttonType="neutral"
          onClick={(): void => {
            this.confirmRemoveAddress(e.value.toString());
          }}
        >
          <TrashIcon icon={faTrash} />
          Remove
        </RemoveButton>
      );
    }

    return <>{e.value}</>;
  };

  deleteAddress = async (): Promise<void> => {
    const { selectAddressID } = this.state;
    const client = new GeoServiceClient();
    try {
      await client.sysDeleteSavedAddress(this.props.match.params.id, selectAddressID);
    } catch (e) {
      // e
    }

    this.fetchData();

    this.setState({
      showRemoveMembersModal: false,
      selectAddressID: '',
    });
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;

    const columns = {
      name_address: 'Name',
      street_address: 'Postal Code / Street Address',
      is_default_pickup_or_delivery: '',
      action: '',
    };

    const data = models.map((geoData) => ({
      id: geoData.id,
      name_address: geoData.name_address,
      street_address: formatAddress(geoData),
      is_default_pickup_or_delivery: geoData.is_default_pickup
        ? 'pickup'
        : geoData.is_default_delivery
        ? 'delivery'
        : '',
      action: geoData.id,
    }));

    return (
      <>
        {this.renderPage(
          <Table
            columns={columns}
            data={data}
            cellRenderer={this.renderCell}
            rowOnClick={(id: string): void => {
              this.props.history.push(
                `/sys/organizations/${this.props.match.params.id}/saved-address/${id}`
              );
            }}
          />
        )}
        {this.state.showRemoveMembersModal ? (
          <CenterModal
            leftButtonOnClick={this.closeRemoveAddress}
            leftButtonText="Cancel"
            rightButtonText="Remove"
            rightButtonOnClick={this.deleteAddress}
            rightButtonStyle="discourage"
            rightButtonType="neutral"
            title="Remove saved address?"
          >
            <ModalTextContainer>
              <ModalText>The saved address details will be lost.</ModalText>
            </ModalTextContainer>
          </CenterModal>
        ) : (
          false
        )}
      </>
    );
  };
}

const Placeholder = styled.img`
  width: 13.5rem;
  margin-bottom: 1rem;
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-bottom: 5rem;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

const Subtitle = styled.div`
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const Icon = styled(FontAwesomeIcon)<IconProps>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.375rem;
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
  margin-bottom: 0.5rem;
`;

const DefaultSavedAddressContainer = styled.div`
  text-align: left;
  padding-left: 20%;
`;

const DefaultSavedAddress = styled.span`
  background-color: ${COLOR.whiteGrey};
  border-radius: 0.375rem;
  width: fit-content;
  padding: 0.2rem 0.25rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
`;

const RemoveButton = styled(StyledButton)`
  margin-right: 0.5rem;
  border-width: 0;
  visibility: hidden;
  tr:hover & {
    visibility: visible;
    background-color: ${COLOR.white};
  }
`;

const ModalTextContainer = styled.div`
  margin-bottom: 2rem;
`;

const ModalText = styled.div`
  line-height: 1.25rem;
  margin-bottom: 1rem;
`;

const TrashIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`;

const InlineErrorMessage = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  margin-right: 0.35rem;
`;

const mapStateToProps = (state: RootState): SavedAddressProps => ({
  organization: state.organization.organization,
  models: state.geoService.savedGeoDatas,
});

export default connect(mapStateToProps)(SysAdminSavedAddressIndex);
