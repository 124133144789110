import * as React from 'react';
import BadgeGroup, { BadgeType } from 'components/badge/badgeGroup';
import { STATUS_DESCRIPTION } from 'constants/taskStatus';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

interface StatusBadgeProps {
  status: string;
  children?: React.ReactNode;
}

const StatusBadge = (props: StatusBadgeProps): JSX.Element => {
  let badgeType: BadgeType = 'primary';
  const taskStatus = props.status;
  if (['created', 'updated'].includes(taskStatus)) {
    badgeType = 'grey';
  }
  if (
    [
      'pending_pickup',
      'pickup_arriving',
      'pending_delivery',
      'delivery_arriving',
    ].includes(taskStatus)
  ) {
    badgeType = 'warning';
  }
  if (
    ['unsuccessful_pickup', 'unsuccessful_delivery', 'cancelled', 'expired'].includes(
      taskStatus
    )
  ) {
    badgeType = 'error';
  }
  if (['complete'].includes(taskStatus)) {
    badgeType = 'success';
  }

  return (
    <BadgeGroup type={badgeType} iconLeft={faCircle} iconSize="xs">
      {props.children || STATUS_DESCRIPTION[taskStatus] || taskStatus}
    </BadgeGroup>
  );
};

export default StatusBadge;
