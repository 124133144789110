import * as React from 'react';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { Table, StyledButton, SysAdminPagination, Dropdown, PaginationState } from 'components';
import styled, { css } from 'styled-components';

import OrganizationClient from 'httpClients/organizationManagementClient';
import { Organization } from 'models/organization';
import * as H from 'history';
import { ROLE_TYPE } from 'constants/role';
import Checkbox from 'components/input/checkbox';

interface OrganizationIndexProps {
  models: Organization[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface OrganizationIndexState extends PaginationState<Organization> {
  allOrganizations: Organization[];
}

type Props = HistoryProps & OrganizationIndexProps;

class OrganizationIndex extends SysAdminPagination<Organization, Props, OrganizationIndexState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,
      basePath: '/sys/organizations',
      filters: this.getDefaultFilter(),
      pluralModelName: 'organizations',
      selectedTab: 'organizations',
      allOrganizations: [],
    };

    this.rules = [{ name: 'page' }];
  }

  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
    is_show_role: 'true',
    is_qa: 'false',
  })

  componentDidMount(): void {
    super.componentDidMount();
    this.fetchAllOrganizations();
  }

  fetchAllOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(new URLSearchParams({ page: pageNum.toString() }));
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      this.setState({ allOrganizations: organizations });
    } catch (e) {
      //
    }
  }

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new OrganizationClient();
    await client.sysAdminGetOrganizations(new URLSearchParams(this.state.filters));

    this.setState({ isFetching: false });
  }

  renderActionPanel = (): React.ReactNode => (
    <TopButtonContainer>
      <Button
        buttonStyle="encourage"
        buttonType="primary"
        onClick={(): void => { this.props.history.push('/sys/organizations/new'); }}
        fontWeight={400}
      >
        Create Organization
      </Button>
      <Button
        buttonStyle="encourage"
        buttonType="primary"
        onClick={(): void => { this.props.history.push('/sys/organizations/pending'); }}
        fontWeight={400}
      >
        Pending Organization
      </Button>
    </TopButtonContainer>
  )

  renderFilter = (): React.ReactNode => (
    <FilterContainer>
      <Checkbox
        onClick={(e): void => {
          e.stopPropagation();
          const isQA = (this.state.filters.is_qa || '') === 'true';
          this.onFilterChange('is_qa', (!isQA).toString());
        }}
        selected={(this.state.filters.is_qa || '') === 'true'}
      >
        Test Organisations
      </Checkbox>
      <Dropdown
        containerStyle={css`
            margin: 0 0.5rem;
          `}
        label="Organization"
        includeAll
        onChange={(value: string): void => {
          const isDelete = value === '';
          this.onFilterChange('org_id', value, isDelete);
        }}
        options={(this.state.allOrganizations || []).map((org: Organization) => ({
          name: org.business_name,
          value: org.id,
        }))}
        value={this.state.filters.org_id || ''}
        withSearch
        searchPlaceholder="Find Organisation..."
        sorted
      />
    </FilterContainer>
  )

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      id: 'ID',
      business_name: 'Business Name',
      business_type: 'Business Type',
      squad_code: 'Squad Code',
    };
    const data = models ? models.map(organization => ({
      id: organization.id,
      business_name: organization.business_name,
      business_type: ROLE_TYPE[organization.business_type],
      squad_code: organization.squad_code,
    })) : [];

    return (
      <Table
        columns={columns}
        data={data}
        rowOnClick={(id: string): void => { this.props.history.push('/sys/organizations/' + id + '/company-details'); }}
      />
    );
  }
}

const Button = styled(StyledButton)`
  margin-right: 0.5rem;
  flex: none;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
`;

const TopButtonContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

const mapStateToProps = (state: RootState): OrganizationIndexProps => ({
  models: state.organization.organizations,
});

export default connect(mapStateToProps)(OrganizationIndex);