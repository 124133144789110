import * as React from 'react';
import { CenterModal, Alert } from 'components';
import { TextInput, Checkbox } from 'components';
import { formatError } from 'utils/formatter';
import VesselClient from 'httpClients/vesselClient';
import { CreateVesselPayload, Vessel } from 'models/vessel';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Row, InlineErrorMessage,
  AlertMessage, ErrorIcon, SaveVesselCheckbox,
} from '../styles';

const STATE = {
  beforeAdd: 'beforeAdd',
  processing: 'processing',
  afterAdd: 'afterAdd',
};

interface AddVesselModalProps {
  closeModal: () => void;
  addVesselSuccess: (vessel: Vessel) => void;
  hasCheckboxSaveVessel?: boolean;
  saveVesselOnSuccess?: boolean;
}

interface AddVesselModalState {
  name: string;
  imo: string;
  error: string;
  pageState: string;
  isCheckedSaveVessel: boolean;
  addedVessel: Vessel;
}

class AddVesselModal extends React.Component<AddVesselModalProps, AddVesselModalState>{
  constructor(props: AddVesselModalProps) {
    super(props);

    this.state = {
      name: '',
      imo: '',
      error: '',
      pageState: STATE.beforeAdd,
      isCheckedSaveVessel: false,
      addedVessel: {
        id: '',
        name: '',
        imo: '',
        user: null,
        created_at: null,
        updated_at: null,
      },
    };
  }

  validateInput = (): boolean => {
    const { name, imo, pageState } = this.state;
    if (name === '' || imo === '' || pageState === STATE.processing || (imo.length || '') !== 7) {
      return false;
    }
    return true;
  }

  handleAddVessel = async (): Promise<void> => {
    const { name, imo, isCheckedSaveVessel } = this.state;
    const { saveVesselOnSuccess } = this.props;

    if (saveVesselOnSuccess || isCheckedSaveVessel) {
      const vesselClient = new VesselClient();
      this.setState({ pageState: STATE.processing });
      try {
        const vesselCreateForm: CreateVesselPayload = {
          name: name,
          imo: imo,
        };
        const addedVessel = await vesselClient.createVessel(vesselCreateForm);
        this.setState({ addedVessel, pageState: STATE.afterAdd });
      } catch (e) {
        this.setState({ error: formatError(e, false), pageState: STATE.beforeAdd });
        return;
      }
    } else {
      const addedVessel: Vessel = {
        id: '',
        name,
        imo,
        user: null,
        created_at: null,
        updated_at: null,
      };
      // vessel data are not saved, just return the vessel name and imo
      this.props.addVesselSuccess(addedVessel);
      this.props.closeModal();
    }
  }

  renderAfterAdd = (): React.ReactNode => {
    const { addedVessel } = this.state;
    return (
      <CenterModal
        rightButtonOnClick={(): void => {
          this.props.addVesselSuccess(addedVessel);
          this.props.closeModal();
        }}
        rightButtonText={'OK'}
        title="Added"
      >
        <AlertMessage status="success">
          Successfully added.
        </AlertMessage>
      </CenterModal>
    );
  };

  renderBeforeAdd = (): React.ReactNode => {
    const { name, imo, error } = this.state;
    return (
      <CenterModal
        leftButtonOnClick={this.props.closeModal}
        leftButtonText="Cancel"
        rightButtonOnClick={this.handleAddVessel}
        rightButtonText="Add"
        rightButtonDisabled={!this.validateInput()}
        title="Add Vessel"
      >
        <Row>
          <Alert status="info">
            Important: Please ensure that this information is accurate
          </Alert>
        </Row>
        <Row>
          <TextInput
            fieldName="Vessel Name"
            name="vesselName"
            placeholder="BEAGLE"
            isRequired
            onTextChange={(value): void => { this.setState({ name: value }); }}
            type="text"
            value={name}
          />
        </Row>
        <TextInput
          fieldName="IMO No."
          name="imoNumber"
          placeholder="1234567"
          isRequired
          onTextChange={(value): void => { this.setState({ imo: value }); }}
          type="number"
          value={imo}
        />
        {this.props.hasCheckboxSaveVessel ? (
          <SaveVesselCheckbox>
            <Checkbox
              onClick={(): void => {
                this.setState(prevState => ({
                  isCheckedSaveVessel: !prevState.isCheckedSaveVessel,
                }));
              }}
              selected={this.state.isCheckedSaveVessel}
            >
              Save this vessel
            </Checkbox>
          </SaveVesselCheckbox>
        ) : false}
        {(error !== '') && (
          <InlineErrorMessage>
            <ErrorIcon icon={faExclamationCircle} />
            {
              this.state.error
            }
          </InlineErrorMessage>
        )}
        {(imo && imo.length !== 7) && (
          <InlineErrorMessage>
            <ErrorIcon icon={faExclamationCircle} />
            Enter a 7-digit number.
          </InlineErrorMessage>
        )}
      </CenterModal>
    );
  }

  render(): React.ReactNode {
    let content: React.ReactNode;
    const { pageState } = this.state;
    if (pageState === STATE.beforeAdd || pageState === STATE.processing) {
      content = this.renderBeforeAdd();
    } else if (pageState === STATE.afterAdd) {
      content = this.renderAfterAdd();
    }
    return (
      content
    );
  }
}

export default AddVesselModal;