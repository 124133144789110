import * as React from 'react';
import { Card, Divider } from '../styles';
import { Task } from 'models/task';
import { Typography } from 'components';
interface Props {
  task: Task;
  ops?: boolean;
}

const AdditionalInfoCard = ({ task, ops }: Props): JSX.Element => {
  return (
    <>
      {task?.invoice_number || task?.group_tag ? (
        <Card>
          <Typography
            as="h2"
            size="lg"
            color="gray_900"
            customStyle={{ lineHeight: '24px' }}
          >
            Additional Info
          </Typography>
          <Divider />
          <div>
            <Typography
              as="h4"
              size="sm"
              color="gray_900"
              weight="semibold"
              customStyle={{
                marginBottom: '0.35rem',
              }}
            >
              Invoice No.
            </Typography>
            <Typography as="p" size="sm" color="gray_600">
              {task?.invoice_number ? task.invoice_number : '-'}
            </Typography>
          </div>
          {ops ? (
            <div>
              <Typography
                as="h4"
                size="sm"
                color="gray_900"
                weight="semibold"
                customStyle={{
                  marginBottom: '0.35rem',
                }}
              >
                Group Tag
              </Typography>
              <Typography as="p" size="sm" color="gray_600">
                {task?.group_tag ? task.group_tag : '-'}
              </Typography>
            </div>
          ) : null}
        </Card>
      ) : null}
    </>
  );
};

export default AdditionalInfoCard;
