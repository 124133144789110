import styled from 'styled-components';
import COLOR from 'constants/color';

export default styled.hr`
  margin-bottom: 0rem;
  border-top: 1px solid ${COLOR.neutral};
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  margin-top: 0rem;
`;