import { VesselState, VesselActionTypes, RECEIVE_VESSELS, RECEIVE_VESSEL } from './vesselTypes';

const initialState: VesselState = {
  vessels: [],
  vessel: null,
};

function vesselReducer(
  state: VesselState = initialState, action: VesselActionTypes
): VesselState {
  switch (action.type) {
    case RECEIVE_VESSELS:
      return { ...state, vessels: action.vessels };
    case RECEIVE_VESSEL:
      return { ...state, vessel: action.vessel };
    default:
      return state;
  }
}

export default vesselReducer;
