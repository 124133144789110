import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import {
  GetRunsheetReadyToBroadcastsResponse,
  RunsheetReadyToBroadcast,
} from 'models/runsheetReadyToBroadcast';
import { receiveRunsheetReadyToBroadcast, receiveRunsheetReadyToBroadcastAllSuccess } from 'reduxActions/runsheetReadyToBroadcast/runsheetReadyToBroadcastActions';
import { getBearerToken } from 'utils/userHelper';
import { PER_PAGE } from 'constants/paginationMeta';
import { StartRunsheetToBroadcastRequest } from 'models/broadcast/startRunsheetToBroadcast';

class RunsheetRTBClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.RUNSHEET_TO_BROADCAST_SERVICE_PROTOCOL;
    this.host = process.env.RUNSHEET_TO_BROADCAST_SERVICE_HOST;
    this.port = process.env.RUNSHEET_TO_BROADCAST_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  runsheetRTBUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/job?${params}`;
  }

  runsheetRTBdetailUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/job/${id}`;
  }

  startBroadcastUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/runsheet/${id}/broadcast`;
  }

  runsheetRTBByRunsheetIdUrl(id: string, params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/job/runsheet/${id}?${params}`;
  }

  async startBroadcast(id: string, startBroadcastRequest: StartRunsheetToBroadcastRequest): Promise<void> {
    const request = this.makeRequest('PUT', `${this.startBroadcastUrl(id)}`, startBroadcastRequest); // TODO: Submit time

    try {
      await axios(request);
    } catch (error) {
      throw error.message;
    }
  }

  async getRunsheetReadyToBroadcasts(params = new URLSearchParams()): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', `${this.runsheetRTBUrl(params)}`);

    try {
      const response: AxiosResponse<GetRunsheetReadyToBroadcastsResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheetReadyToBroadcastAllSuccess(response.data.jobs));
    } catch (error) {
      throw error.message;
    }
  }

  async getRunsheetReadyToBroadcast(id: string): Promise<RunsheetReadyToBroadcast> {
    const request = this.makeRequest('GET', this.runsheetRTBdetailUrl(id));

    try {
      const response: AxiosResponse<RunsheetReadyToBroadcast> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheetReadyToBroadcast(response.data));
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  async getRunsheetRTBByRunsheetId(id: string, params = new URLSearchParams()): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', `${this.runsheetRTBByRunsheetIdUrl(id, params)}`);

    try {
      const response: AxiosResponse<GetRunsheetReadyToBroadcastsResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheetReadyToBroadcastAllSuccess(response.data.jobs));
    } catch (error) {
      throw error.message;
    }
  }

}

export default RunsheetRTBClient;
