import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  Separator,
  PageTitle,
  SysAdminMainContainer,
  Breadcrumb,
  Heading,
  StyledButton,
  TextInput,
  CenterModal,
  Alert,
} from 'components';
import * as moment from 'moment';
import ConfigSidebarMenu from '../../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import {
  faPlusCircle,
  faTimes,
  faTrash,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomDataMapper, CustomGroupMapperRequest } from 'models/runsheetReadyToTransfer';
import RunsheetReadyToTransferClient from 'httpClients/runsheetRTTClient';

type Props = RouteComponentProps<{ id: string }>;

interface Form {
  id: string;
  group_code: string;
  group_id: string;
  data: CustomDataMapper[];
  updated_by: string;
  updated_at: Date;
}

const getDefaultForm = (): Form => {
  return {
    id: '',
    group_code: '',
    group_id: '',
    data: [{
      field: '',
      id: '',
    }],
    updated_by: '',
    updated_at: new Date(),
  };
};

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

const saveCustomGroup = async (props: Props, form: CustomGroupMapperRequest, setSuccess: React.Dispatch<React.SetStateAction<boolean>>): Promise<void> => {
  const client = new RunsheetReadyToTransferClient();
  await client.updateCustomGroup(props.match.params.id, form);
  setSuccess(true);
};

const deleteCustomGroup = async (props: Props, setDeleted: React.Dispatch<React.SetStateAction<boolean>>): Promise<void> => {
  const client = new RunsheetReadyToTransferClient();
  await client.deleteCustomGroup(props.match.params.id);
  setDeleted(true);
};

const SysAdminConfigTransferCustomGroupEdit = (props: Props): JSX.Element => {
  const [form, setForm] = React.useState(getDefaultForm());
  const [success, setSuccess] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  React.useEffect(() => {
    (async function (): Promise<void> {
      const client = new RunsheetReadyToTransferClient();
      const form = await client.getCustomGroupDetail(props.match.params.id);
      setForm(form);
    })();
  }, []);

  const handleChange = (fieldName: string, value: string): void => {
    setForm(prevState => {
      if (fieldName.indexOf('.') !== -1) {
        const updatedDatas = [...prevState.data];
        const fields: string[] = fieldName.split('.');
        if (fields[0].indexOf('[') >= 0) {
          const arrayInfo: string[] = fields[0].split('[');
          const itemIdx = arrayInfo[1].slice(0, -1);
          updatedDatas[parseInt(itemIdx)] = {
            ...updatedDatas[parseInt(itemIdx)],
            [fields[1]]: value,
          };
          return {
            ...prevState,
            data: updatedDatas,
          };
        } else {
          return {
            ...prevState,
            [fields[0]]: {
              ...[fields[0]],
              [fields[1]]: value,
            },
          };
        }
      } else {
        return {
          ...prevState,
          [fieldName]: value,
        };
      }
    });
  };

  return (
    <SysAdminMainContainer selected="custom_group_mapper">
      <Breadcrumb
        items={[
          { text: 'Configuration', onClick: (): void => { props.history.push('/sys/config'); } },
          { text: 'Custom Group Mapper' },
        ]}
      />
      <Heading>
        <PageTitle>Configuration</PageTitle>
      </Heading>
      <ConfigSidebarMenu selected="/sys/config/runsheet-ready-to-transfer/custom-group">
        <HeadingContainer>
          <CustomPageTitle>
            Custom Group <CustomGroupBadge>{form.group_code}</CustomGroupBadge>
          </CustomPageTitle>
          <RemoveButtonContainer>
            <StyledButton
              type="button"
              buttonType="neutral"
              buttonStyle="encourage"
              onClick={(): void => {
                setDeleteModal(true);
              }}
            >
              <Icon color={COLOR.red} icon={faTrash} />
              Delete
            </StyledButton>
          </RemoveButtonContainer>
        </HeadingContainer>
        <CustomSeparator />
        <br />
        <TextInput
          autoComplete="none"
          containerStyle={inlineTextInputStyle}
          fieldName="Group Code"
          isRequired
          onTextChange={(value: string): void => { handleChange('group_code', value); }}
          width="large"
          placeholder="Group Code"
          value={form.group_code}
        />
        <TextInput
          autoComplete="none"
          containerStyle={inlineTextInputStyle}
          fieldName="Group Mapping ID"
          isRequired
          onTextChange={(value: string): void => { handleChange('group_id', value); }}
          width="medium"
          placeholder="Group Mapping ID"
          value={form.group_id}
        />
        <CustomSeparator />
        <CustomDataLabel>
          Custom Data
        </CustomDataLabel>
        <ItemList>
          {form.data.map((item, j) => (
            <li key={j}>
              <span>
                <TextInput
                  autoComplete="none"
                  containerStyle={inlineTextInputStyle}
                  fieldName={j === 0 ? 'Field Code' : undefined}
                  onTextChange={(value: string): void => { handleChange(`data[${j}].field`, value); }}
                  width="half"
                  placeholder={`custom_field_${j + 1}`}
                  value={item.field}
                />
                <TextInput
                  autoComplete="none"
                  containerStyle={inlineTextInputStyle}
                  fieldName={j === 0 ? 'Mapping ID' : undefined}
                  onTextChange={(value: string): void => { handleChange(`data[${j}].id`, value); }}
                  type="text"
                  width="medium"
                  placeholder={`id_${j + 1}`}
                  min={1}
                  value={item.id}
                />
                {form.data.length > 1 && (
                  <RemoveItemButton
                    buttonType="neutral"
                    buttonStyle="discourage"
                    onClick={(): void => {
                      setForm(prevState => {
                        const updatedDatas = [...prevState.data];
                        updatedDatas.splice(j, 1);
                        prevState = {
                          ...prevState,
                          data: updatedDatas,
                        };
                        return {
                          ...prevState,
                        };
                      });
                    }}
                  >
                    <TrashIcon icon={faTrash} />
                    Remove
                  </RemoveItemButton>
                )}
              </span>
            </li>
          ))}
        </ItemList>
        <Button
          buttonType="neutral"
          buttonStyle="encourage"
          onClick={(): void => {
            setForm(prevState => ({
              ...prevState,
              data: prevState.data.concat({
                field: '',
                id: '',
              }),
            }));
          }}
        >
          <Icon color={COLOR.blue} icon={faPlusCircle} />
          Add Custom Data
        </Button>
        <CustomSeparator />
        <InfoContainer>
          <InfoDescription>
            Last updated by: <br />
            <b>{form.updated_by}</b> at {moment(form.updated_at).utc().format('DD MMM YYYY HH:mm:ss A')}
          </InfoDescription>
        </InfoContainer>
        <CustomSeparator />
        <ActionButtonContainer>
          <Button
            buttonStyle="discourage"
            buttonType="primary"
            onClick={(): void => { props.history.push('/sys/config/runsheet-ready-to-transfer/custom-group'); }}
          >
            <Icon icon={faTimes} />
            Cancel
          </Button>
          <StyledButton
            buttonStyle="encourage"
            buttonType="primary"
            onClick={(): void => { saveCustomGroup(props, { ...form }, setSuccess); }}
          >
            <Icon icon={faCheck} color={COLOR.white} />
            Save
          </StyledButton>
        </ActionButtonContainer>
        {success ? (
          <CenterModal
            rightButtonText="OK"
            rightButtonOnClick={(): void => { props.history.push('/sys/config/runsheet-ready-to-transfer/custom-group'); }}
            title="Custom Group"
          >
            <Alert status='success'>
              <AlertMessage>Saved</AlertMessage>
            </Alert>
          </CenterModal>
        ) : false}
        {deleteModal ? (<CenterModal
          title={`Delete ${form.group_code}?`}
          leftButtonText="Cancel"
          leftButtonOnClick={(): void => {
            setDeleteModal(false);
          }}
          rightButtonText="Delete"
          rightButtonStyle="discourage"
          rightButtonOnClick={(): void => {
            deleteCustomGroup(props, setDeleted);
          }}
        >
          The custom group configuration details will be lost.
        </CenterModal>) : false}
        {deleted ? (
          <CenterModal
            rightButtonText="OK"
            rightButtonOnClick={(): void => { props.history.push('/sys/config/runsheet-ready-to-transfer/custom-group'); }}
            title="Custom Group"
          >
            <Alert status='error'>
              <AlertMessage>Deleted</AlertMessage>
            </Alert>
          </CenterModal>
        ) : false}
      </ConfigSidebarMenu>
    </SysAdminMainContainer>
  );
};

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
`;

const InfoContainer = styled.div`
  margin-top: 1.5rem;
  background-color: ${COLOR.midWhiteGrey};
  display: flex;
  border-radius: 13px;
  align-items: center;
`;

const InfoDescription = styled.div`
  color: ${COLOR.darkGray};
  padding-left: 1.78rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const CustomDataLabel = styled.div`
  font-size: 0.875rem;
  margin-top: 1.5rem;
  margin-bottom: 0.3rem;
`;

const ItemList = styled.ol`
  padding-inline-start: 1.5em;
  & > li {
    margin-bottom: 1rem;
  }
`;

const RemoveItemButton = styled(StyledButton)`
  border-width: 0;
  visibility: hidden;
  span:hover & {
    visibility: visible;
    background-color: ${COLOR.white};
  }
`;

const TrashIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`;

const Button = styled(StyledButton)`
  margin-right: 1rem;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;
`;

const AlertMessage = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

const ActionButtonContainer = styled.div`
  float: right;
  padding: 20px 0;
`;

const HeadingContainer = styled.section`
  width: 100%;
  display: flex;
  margin-bottom: -10px;
`;

const RemoveButtonContainer = styled.div`
  float: right;
  visibility: hidden;
  section:hover & {
    visibility: visible;
  }
`;

const CustomPageTitle = styled(PageTitle)`
  display: flex;
`;

const CustomGroupBadge = styled.div`
  font-size: 1.2rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.3rem;
  margin-bottom: 0.5rem;
  border-radius: 6px;
  color: ${COLOR.black};
  background-color: ${COLOR.yellow};
`;

export default SysAdminConfigTransferCustomGroupEdit;