import * as React from 'react';
import { CustomCard, Title } from '../styles';
import {
  FieldView,
} from 'components';

const DriverPerformanceCard = (): JSX.Element => {
  return (
    <CustomCard>
      <Title>
        Performance
      </Title>
      <FieldView fieldName="Fulfilment rate" />
      <FieldView fieldName="Total Successful Deliveries" />
      <FieldView fieldName="Merchant Satisfaction" />
    </CustomCard>
  );
};

export default DriverPerformanceCard;