import * as React from 'react';
import { BeforeLoginContainer, TextInput, StyledButton } from 'components';
import styled from 'styled-components';
import * as H from 'history';
import COLOR from 'constants/color';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

interface SetUserPassProps<S = H.LocationState> {
  history: H.History<S>;
}

interface SetUserPassState {
  form: {
    name: string;
    password: string;
  };
  passwordShown: boolean;
}

class SetUserPass extends React.Component<SetUserPassProps, SetUserPassState> {
  constructor(props: SetUserPassProps) {
    super(props);
    this.state = {
      form: {
        name: '',
        password: '',
      },
      passwordShown: false,
    };
  }

  componentDidMount(): void {
    const tempToken = localStorage.getItem('tempBearerToken');
    if (!tempToken) {
      this.props.history.push('/register');
    }
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  handleSetUserPass = (): void => {
    const { form } = this.state;
    this.props.history.push(
      '/register/set-organization',
      {
        'registerName': form.name,
        'registerPassword': form.password,
      }
    );
  }

  render(): React.ReactNode {
    const { form, passwordShown } = this.state;
    const pattern = new RegExp(/[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);

    return (
      <BeforeLoginContainer title="Setup your name and password">
        <LabelInfo>Use the name that your team recognises.</LabelInfo>
        <InputFieldContainer>
          <TextInput
            fieldName="Name"
            isRequired
            name="name"
            onTextChange={(value): void => this.handleChange('name', value)}
            type="text"
            value={form.name}
          />
        </InputFieldContainer>
        <InputFieldContainer>
          <TextInput
            fieldName="Password"
            icon={passwordShown ? faEye : faEyeSlash}
            iconOnClick={(): void => {
              this.setState(prevState => ({ passwordShown: !prevState.passwordShown }));
            }}
            isRequired
            name="password"
            onTextChange={(value): void => this.handleChange('password', value)}
            type={passwordShown ? 'text' : 'password'}
            value={form.password}
          />
        </InputFieldContainer>
        <LabelAdditionalInfo>Minimum 8 characters and at least 1 special character.</LabelAdditionalInfo>
        <ActionButton
          buttonStyle="encourage"
          buttonType="primary"
          size="lg"
          isFullWidth={true}
          onClick={this.handleSetUserPass}
          disabled={form.password && form.password.length < 8 || !pattern.test(form.password)}
        >
          Continue
        </ActionButton>
      </BeforeLoginContainer>
    );
  }
}

const LabelInfo = styled.div`
  color: ${COLOR.darkGray};
  margin-bottom: 1rem;
`;

const InputFieldContainer = styled.div`
  margin-top: 1rem;
`;

const LabelAdditionalInfo = styled.div`
  margin-top: 0.25rem;
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
`;

const ActionButton = styled(StyledButton)`
  margin-top: 1.5rem;
`;

export default SetUserPass;
