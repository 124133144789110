import * as React from 'react';
import * as moment from 'moment';
import { Stack, PickupDeliveryDetailWrapper, AddressViewWrapper } from './styles';

import {
  AddressView,
  Typography,
  FileInformationCard,
  VerticalTimeline,
  WhatsAppLink,
} from 'components';
import { Attachment, Task } from 'models/task';
import ReasonOfFailure from './reasonOfFailure';
import { TimelineInfo } from 'components';

const reorganizeTaskData = (
  task: Task
): {
  pickupAttachments: Attachment[];
  deliveryAttachments: Attachment[];
  pickupFields: {
    name: string;
    value?: React.ReactNode;
  }[];
  deliveryFields: {
    name: string;
    value?: React.ReactNode;
    secondValue?: React.ReactNode;
  }[];
} => {
  const pickupAttachments: Attachment[] = [];
  const deliveryAttachments: Attachment[] = [];
  const pickupFields: {
    name: string;
    value?: React.ReactNode;
  }[] = [];
  const deliveryFields: {
    name: string;
    value?: React.ReactNode;
    secondValue?: React.ReactNode;
  }[] = [];

  if (task && task?.statuses) {
    task.statuses.forEach((history) => {
      if (history.status === 'pending_delivery') {
        if (history.remarks) {
          pickupFields.push({ name: 'Remarks', value: history.remarks });
        }

        if (history.actual_contact_name) {
          pickupFields.push({
            name: 'Released From (Name)',
            value: history.actual_contact_name,
          });
        }

        if ((history.attachments || []).length > 0) {
          pickupAttachments.push(...history.attachments);
        }
        return;
      }

      if (history.status === 'unsuccessful_pickup') {
        if ((history.attachments || []).length > 0) {
          pickupAttachments.push(...history.attachments);
        }
        return;
      }

      if (history.status === 'unsuccessful_delivery') {
        if ((history.attachments || []).length > 0) {
          deliveryAttachments.push(...history.attachments);
        }
        return;
      }

      if (history.status === 'complete') {
        if (history.remarks) {
          deliveryFields.push({ name: 'Remarks', value: history.remarks });
        }

        if (history.actual_contact_name) {
          deliveryFields.push({
            name: 'Released To (Name)',
            value: history.actual_contact_name,
          });
        }

        if (history.custom_info) {
          const inPersonInfo = history.custom_info.find(
            (info) => info.question === 'In Person?'
          );
          if (
            inPersonInfo &&
            inPersonInfo.answer &&
            inPersonInfo.answer.includes('No -')
          ) {
            deliveryFields.push({
              name: 'In Person?',
              value: 'No',
              secondValue: inPersonInfo.answer.slice(5).trim(), // The person name after exclude these 5 character: 'No - '
            });
          } else {
            inPersonInfo &&
              deliveryFields.push({
                name: 'In Person?',
                value: inPersonInfo.answer,
              });
          }
        }

        if (history.attachments) {
          if (history.attachments.length > 0) {
            deliveryAttachments.push(...history.attachments);
          }
        }
      }
    });
  }

  if (task?.attachments?.length > 0) {
    deliveryAttachments.push(...task.attachments);
  }

  return {
    pickupAttachments,
    deliveryAttachments,
    pickupFields,
    deliveryFields,
  };
};

interface PickupAndDeliveryDetailProps {
  task: Task;
  ops?: boolean;
}

const PickupAndDeliveryDetail = ({ task }: PickupAndDeliveryDetailProps): JSX.Element => {
  const { pickupAttachments, deliveryAttachments, pickupFields, deliveryFields } =
    reorganizeTaskData(task);
  const fromContactName = task.from_contact.name || '';
  const fromContactPhone = task.from_contact.phone;
  const fromContactJoin = [fromContactName, fromContactPhone].filter(Boolean).join(', ');
  const fromCompanyName = task.from_contact.company_name || '';
  const fromVehicleName = task.from_contact.vehicle_name || '';
  const fromContactArr = [fromCompanyName, fromVehicleName, fromContactJoin].filter(Boolean);
  const fromContact = fromContactArr.join(', ');
  const toContactName = task.to_contact.name || '';
  const toContactPhone = task.to_contact.phone;
  const toContactJoin = [toContactName, toContactPhone].filter(Boolean).join(', ');
  const toCompanyName = task.to_contact.company_name || '';
  const toVehicleName = task.to_contact.vehicle_name || '';
  const toContactArr = [toCompanyName, toVehicleName, toContactJoin].filter(Boolean);
  const toContact = toContactArr.join(', ');

  return (
    <>
      <Stack gap="0.5rem">
        <VerticalTimeline
          type="pickup"
          currentStatus={task.current_status}
          hasNextLocation={true}
          style={{
            marginLeft: '-3px',
          }}
        />
        <PickupDeliveryDetailWrapper gap="1rem" direction="column">
          <Stack direction="column" gap="0.35rem">
            <TimelineInfo type="pickup" currentStatus={task.current_status} />
            <ReasonOfFailure
              taskStatuses={task.statuses}
              currentStatus={task.current_status}
              cancelType="unsuccessful_pickup"
            />
          </Stack>
          <Stack gap="1rem" direction="column">
            <AddressViewWrapper direction="column" gap="0px">
              <AddressView
                address={task.from_address}
                berthNo={task.from_contact.berth_no}
              />
              <Typography
                as="p"
                color="gray_600"
                customStyle={{
                  marginTop: '0.25rem',
                }}
              >
                Est. Pickup{' '}
                {moment(task.from_time_window.start_time_utc).format(
                  'DD MMM YYYY, HH:mm'
                )}
              </Typography>
            </AddressViewWrapper>
            {fromContactArr.length > 0 ? (
              <Stack gap="0rem" direction="column">
                <Typography
                  as="p"
                  size="sm"
                  color="gray_900"
                  customStyle={{
                    marginBottom: '0.35rem',
                  }}
                  weight="semibold"
                >
                  Contact
                </Typography>
                <Typography
                  as="p"
                  color="gray_600"
                  customStyle={{
                    marginBottom: '0.5rem',
                  }}
                >
                  {fromContact}
                </Typography>
                {fromContactPhone && (
                  <div>
                    <WhatsAppLink phoneNumber={task.from_contact.phone} />
                  </div>
                )}
              </Stack>
            ) : null}
            {task.pickup_note_to_driver ? (
              <Stack gap="0rem" direction="column">
                <Typography
                  as="div"
                  size="sm"
                  color="gray_900"
                  weight="semibold"
                  customStyle={{
                    marginBottom: '0.35rem',
                  }}
                >
                  Instructions
                </Typography>
                <Typography as="p" color="gray_600">
                  {task.pickup_note_to_driver}
                </Typography>
              </Stack>
            ) : null}
            {pickupAttachments.length ? (
              <Stack gap="0rem" direction="column">
                <Typography
                  as="div"
                  size="sm"
                  color="gray_900"
                  weight="semibold"
                  customStyle={{
                    marginBottom: '0.35rem',
                  }}
                >
                  Attachments
                </Typography>
                <Stack direction="row" gap="0.5rem" wrap={true}>
                  {pickupAttachments.map((attachment) => (
                    <FileInformationCard
                      key={attachment.id}
                      name={attachment.name}
                      src={attachment.url}
                      size={attachment.size}
                    />
                  ))}
                </Stack>
              </Stack>
            ) : null}
            {pickupFields.map((field, i) => (
              <Stack key={i} gap="0rem" direction="column">
                <Typography
                  as="div"
                  size="sm"
                  color="gray_900"
                  weight="semibold"
                  customStyle={{ marginBottom: '0.35rem' }}
                >
                  {field.name}
                </Typography>
                <Typography as="p" color="gray_600">
                  {field.value}
                </Typography>
              </Stack>
            ))}
            {task.org_transporter || task.driver ? (
              <Stack gap="0rem" direction="column">
                <Typography
                  as="div"
                  size="sm"
                  color="gray_900"
                  weight="semibold"
                  customStyle={{ marginBottom: '0.35rem' }}
                >
                  Transport-Partner
                </Typography>
                {task.org_transporter ? (
                  <>
                    <Typography
                      as="p"
                      weight="semibold"
                      color="gray_600"
                      customStyle={{ marginBottom: '0.25rem' }}
                    >
                      {task.org_transporter.name}
                    </Typography>
                    <Typography as="p" weight="normal" color="gray_600">
                      {task.org_transporter.org_driver.name
                        ? task.org_transporter.org_driver.name
                        : '-'}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography as="p" color="gray_600">
                      {task.driver.name}
                    </Typography>
                  </>
                )}
              </Stack>
            ) : null}
          </Stack>
        </PickupDeliveryDetailWrapper>
      </Stack>

      <Stack gap="0.5rem">
        <VerticalTimeline
          type="delivery"
          currentStatus={task.current_status}
          hasNextLocation={false}
          style={{
            marginLeft: '-3px',
          }}
        />
        <PickupDeliveryDetailWrapper gap="1rem" direction="column">
          <Stack direction="column" gap="0.35rem">
            <TimelineInfo type="delivery" currentStatus={task.current_status} />
            <ReasonOfFailure
              taskStatuses={task.statuses}
              currentStatus={task.current_status}
              cancelType="unsuccessful_delivery"
            />
          </Stack>
          <Stack gap="1rem" direction="column">
            <AddressViewWrapper direction="column" gap="0px">
              <AddressView address={task.to_address} berthNo={task.to_contact.berth_no} />
              <Typography
                as="p"
                color="gray_600"
                customStyle={{
                  marginTop: '0.25rem',
                }}
              >
                Est. Delivery{' '}
                {moment(task.to_time_window.start_time_utc).format('DD MMM YYYY, HH:mm')}
              </Typography>
            </AddressViewWrapper>
            {toContactArr.length > 0 ? (
              <Stack gap="0rem" direction="column">
                <Typography
                  as="p"
                  size="sm"
                  color="gray_900"
                  customStyle={{
                    marginBottom: '0.35rem',
                  }}
                  weight="semibold"
                >
                  Contact
                </Typography>
                <Typography
                  as="p"
                  color="gray_600"
                  customStyle={{
                    marginBottom: '0.5rem',
                  }}
                >
                  {toContact}
                </Typography>
                {toContactPhone && (
                  <div>
                    <WhatsAppLink phoneNumber={task.to_contact.phone} />
                  </div>
                )}
              </Stack>
            ) : null}
            {task.delivery_note_to_driver ? (
              <Stack gap="0rem" direction="column">
                <Typography
                  as="div"
                  size="sm"
                  color="gray_900"
                  weight="semibold"
                  customStyle={{
                    marginBottom: '0.35rem',
                  }}
                >
                  Instructions
                </Typography>
                <Typography as="p" color="gray_600">
                  {task.delivery_note_to_driver}
                </Typography>
              </Stack>
            ) : null}
            {deliveryAttachments.length ? (
              <Stack gap="0rem" direction="column">
                <Typography
                  as="div"
                  size="sm"
                  color="gray_900"
                  weight="semibold"
                  customStyle={{
                    marginBottom: '0.35rem',
                  }}
                >
                  Attachments
                </Typography>
                <Stack direction="row" gap="0.5rem" wrap={true}>
                  {deliveryAttachments.map((attachment) => (
                    <FileInformationCard
                      key={attachment.id}
                      name={attachment.name}
                      src={attachment.url}
                      size={attachment.size}
                    />
                  ))}
                </Stack>
              </Stack>
            ) : null}
            {deliveryFields.map((field, i) => (
              <Stack key={i} gap="0rem" direction="column">
                <Typography
                  as="div"
                  size="sm"
                  color="gray_900"
                  weight="semibold"
                  customStyle={{ marginBottom: '0.35rem' }}
                >
                  {field.name}
                </Typography>
                <Typography as="p" color="gray_600">
                  {field.value}
                </Typography>
                {field.secondValue && (
                  <Typography as="p" color="gray_600">
                    {field.secondValue}
                  </Typography>
                )}
              </Stack>
            ))}
            {/* {task.to_contact?.lighter_company_name ||
            task.to_contact?.lighter_boat_name ? (
              <Stack gap="0rem" direction="column">
                <Typography
                  as="div"
                  size="sm"
                  color="gray_900"
                  weight="semibold"
                  customStyle={{ marginBottom: '0.25rem' }}
                >
                  Transport-Partner
                </Typography>

                {task.to_contact?.lighter_company_name && (
                  <Typography
                    as="p"
                    weight="semibold"
                    color="gray_600"
                    customStyle={{ marginBottom: '0.25rem' }}
                  >
                    {task.to_contact?.lighter_company_name}
                  </Typography>
                )}
                {task.to_contact?.lighter_boat_name && (
                  <Typography as="p" weight="normal" color="gray_600">
                    {task.to_contact.lighter_boat_name
                      ? task.to_contact.lighter_boat_name
                      : '-'}
                  </Typography>
                )}
              </Stack>
            ) : null} */}
          </Stack>
        </PickupDeliveryDetailWrapper>
      </Stack>
    </>
  );
};

export default PickupAndDeliveryDetail;
