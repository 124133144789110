import * as React from 'react';
import RadioItem from './item';

interface Props {
  options: {
    text: string | React.ReactNode;
    value: string;
    withTextInput?: boolean;
    textInputRequired?: boolean;
    textInputPlaceholder?: string;
    disabled?: boolean;
  }[];
  value: string;
  onChange: (v: string) => void;
}

const Radio = (props: Props): JSX.Element => {
  const propsOnChange = props.onChange;

  const onChange = (selectedValue: string): void => {
    if (selectedValue !== props.value) {
      propsOnChange(selectedValue);
    }
  };

  return (
    <div>
      {(props.options || []).map(option => (
        <RadioItem
          key={option.value}
          onChange={onChange}
          selectedValue={props.value}
          value={option.value}
          withTextInput={option.withTextInput}
          textInputRequired={option.textInputRequired}
          textInputPlaceholder={option.textInputPlaceholder}
          disabled={option.disabled}
        >
          {option.text}
        </RadioItem>
      ))}
    </div>
  );
};

export default Radio;
