import * as React from 'react';
import {
  Alert, Breadcrumb, Card, Dropdown, Heading, PageTitle, SysAdminMainContainer,
  StyledButton, TextInput, Radio, CenterModal, Separator as BasicSeparator,
} from 'components';
import styled, { css } from 'styled-components';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { Organization, DefaultPriceForm, SquadDriver } from 'models/organization';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps } from 'react-router-dom';
import { formatDuration } from 'utils/formatter';
import SettingSidebarMenu from '../sidebarMenu';
import BROADCAST_PREFERENCES, { BROADCAST_PREFERENCE_DESCRIPTION, BROADCAST_MINUTES } from 'constants/broadcastPreference';
import { ONE_SGD, ALLOWED_MIN_SGD, ALLOWED_MIN_PRICE, DEFAULT_SQUAD_PRICE, DEFAULT_MIN_PRICE, DEFAULT_MAX_PRICE } from 'constants/priceDetails';

interface SysAdminDefaultPriceProps {
  organization: Organization;
  squadDrivers: SquadDriver[];
}

interface Form {
  min_price: number;
  max_price: number;
  increment: number;
  squad_price: number;
  broadcast_preference: string;
}

type Props = RouteComponentProps<{ id: string }> & SysAdminDefaultPriceProps;

const inlineDropdownStyle = css`
  display: inline-block;
  margin-left: 0;
  margin-top: 0.25rem;
`;

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

const save = async (props: Props, form: DefaultPriceForm, setSuccess: React.Dispatch<React.SetStateAction<boolean>>): Promise<void> => {
  const client = new OrganizationManagementClient();
  await client.sysAdminUpdateDefaultPrice(props.match.params.id, form);
  setSuccess(true);
};

const priceValid = (form: Form): boolean => {
  if (form.broadcast_preference === BROADCAST_PREFERENCES.all) {
    return form.min_price >= ALLOWED_MIN_PRICE && form.max_price >= form.min_price;
  } else if (form.broadcast_preference === BROADCAST_PREFERENCES.squad) {
    return form.squad_price >= ALLOWED_MIN_PRICE;
  }
};

const renderHeading = (props: Props, form: Form, setSuccess: React.Dispatch<React.SetStateAction<boolean>>): JSX.Element => {
  return (
    <Heading>
      <PageTitle>
        Driver-Partner & Price
      </PageTitle>
      <Button
        buttonType='neutral'
        buttonStyle="encourage"
        onClick={(): void => { props.history.push(`/sys/organizations/${props.match.params.id}/default-price`); }}
        icon={<FontAwesomeIcon icon={faTimes} />}
      >
        Cancel
      </Button>
      <StyledButton
        buttonStyle="encourage"
        buttonType="primary"
        disabled={!(priceValid(form))}
        onClick={(): void => { save(props, { ...form, version_rev: props.organization.version_rev }, setSuccess); }}
        icon={<FontAwesomeIcon icon={faCheck} style={{color: COLOR.white}} />}
      >
        Save
      </StyledButton>
    </Heading>
  );
};

const getDefaultForm = (props: Props): Form => {
  const org = props.organization;
  const squadDrivers = props.squadDrivers;
  let increment = ONE_SGD;
  if (org?.price_table && org?.price_table.length > 1) {
    increment = org?.price_table[1].price - org?.price_table[0].price;
    if (increment <= ONE_SGD) {
      increment = ONE_SGD;
    } else if (increment <= ONE_SGD * 2) {
      increment = ONE_SGD * 2;
    } else if (increment <= ONE_SGD * 3) {
      increment = ONE_SGD * 3;
    }
  }

  const squadDriversLength = squadDrivers.length;
  const defaultBroadcastPreference = squadDriversLength > 0 ?
    BROADCAST_PREFERENCES.squad : BROADCAST_PREFERENCES.all;

  return {
    min_price: org?.min_price || DEFAULT_MIN_PRICE,
    max_price: org?.max_price || DEFAULT_MAX_PRICE,
    increment: increment,
    squad_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
    broadcast_preference: defaultBroadcastPreference,
  };
};

const renderMaxAcceptanceTime = (minPrice: number, maxPrice: number, increment: number): React.ReactNode => {
  if (minPrice < maxPrice) {
    const minutes = (Math.ceil((maxPrice - minPrice) / increment) + 1) * BROADCAST_MINUTES;

    return (
      <article>
        Max order acceptance time: {formatDuration(minutes * 60)}
      </article>
    );
  }

  return false;
};

const OrganizationEdit = (props: Props): JSX.Element => {
  React.useEffect(() => {
    (async function (): Promise<void> {
      const client = new OrganizationManagementClient();
      await client.sysAdminGetOrganization(props.match.params.id);
      await client.sysAdminGetSquadDrivers(props.match.params.id, new URLSearchParams());
    })();
  }, []);

  const [form, setForm] = React.useState(getDefaultForm(props));
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    setForm(getDefaultForm(props));
  }, [props.organization, props.squadDrivers]);

  const handleChange = (fieldName: string, value: string): void => {
    setForm(prevState => ({
      ...prevState,
      [fieldName]: fieldName === 'increment' ?
        parseInt(value) : Math.floor(parseFloat(value) * 100) * 1000,
    }));
  };

  const squadDriversLength = props.squadDrivers.length;
  const emptySquads = squadDriversLength === 0;
  const broadcastPreferences = [{
    text: (
      <>
        <RadioText>
          {BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.squad].label}
        </RadioText>
        <AdditionalInfo
          disabled={emptySquads}
        >
          {emptySquads ? 'Broadcast orders to your Squad only at a fixed price.' :
            'Only your Squad will receive your orders.'}
        </AdditionalInfo>
      </>
    ),
    value: BROADCAST_PREFERENCES.squad,
    disabled: emptySquads,
  },
  {
    text: (
      <>
        <RadioText>
          {BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.all].label}
        </RadioText>
        <AdditionalInfo>
          All Driver-Partners will receive orders at the same time. Note that 20% Commission fee will be deducted for non-squad Driver-Partners.
        </AdditionalInfo>
      </>
    ),
    value: BROADCAST_PREFERENCES.all,
  }];

  return (
    <SysAdminMainContainer selected="organizations">
      <Breadcrumb
        items={[
          { text: 'Organizations', onClick: (): void => { props.history.push('/sys/organizations'); } },
          {
            text: props.match.params.id,
            onClick: (): void => {
              props.history.push('/sys/organizations/' + props.match.params.id + '/company-details');
            },
          },
          { text: 'Default Price' },
        ]}
      />
      <Heading>
        <PageTitle>Organisations Info</PageTitle>
      </Heading>
      <SettingSidebarMenu
        selected={`/sys/organizations/${props.match.params.id}/default-price`}
      >
        {renderHeading(props, form, setSuccess)}
        <Separator />
        <Container>
          <CustomCard>
            <Description>
              Set your Driver-Partner preference.
            </Description>
            <Radio
              options={broadcastPreferences}
              value={form.broadcast_preference}
              onChange={(v: string): void => {
                setForm({
                  ...getDefaultForm(props),
                  broadcast_preference: v,
                });
              }}
            />
            <Separator />
            {props.organization && props.squadDrivers && (
              <>
                <ContentTitle>Set your price</ContentTitle>
                {form.broadcast_preference === BROADCAST_PREFERENCES.all ? (
                  <AllDriversPrice>
                    <Description>
                      Alert drivers starting from a base price per location that increases to your max budget until a driver is found.
                    </Description>
                    <PriceInfo>
                      <CardRow>
                        <TextInput
                          fieldName="Minimum Price (SGD)"
                          isRequired
                          type="number"
                          width="small"
                          onTextChange={(value): void => handleChange('min_price', value)}
                          value={form.min_price / ONE_SGD}
                        />
                        <Arrow icon={faArrowRight} />
                        <TextInput
                          fieldName="Maximum Price (SGD)"
                          isRequired
                          type="number"
                          width="small"
                          onTextChange={(value): void => handleChange('max_price', value)}
                          value={form.max_price / ONE_SGD}
                        />
                      </CardRow>
                    </PriceInfo>
                    <AlertInfo>
                      {
                        form.max_price < form.min_price && (
                          <Alert status="error">
                            Set a Maximum Price that is higher than the Minimum Price. Alternatively, set a lower Minimum Price.
                          </Alert>
                        )
                      }
                      {
                        form.max_price === form.min_price && (
                          <Alert status="info">
                            There will be no increment, your orders will be broadcasted at this same price throughout.
                          </Alert>
                        )
                      }
                    </AlertInfo>
                    <div>
                      <div>
                        Price increment
                      </div>
                      <Dropdown
                        containerStyle={inlineDropdownStyle}
                        disabled={form.max_price <= form.min_price}
                        onChange={(value: string): void => handleChange('increment', value)}
                        options={[ONE_SGD, ONE_SGD * 2, ONE_SGD * 3].map((value: number) => (
                          {
                            name: `SGD ${(value / ONE_SGD).toFixed(2)}`,
                            value: value.toString(),
                          }
                        ))}
                        value={form.increment.toString()}
                      />
                      <span>At {BROADCAST_MINUTES} minutes intervals</span>
                    </div>
                    <SmallDescription>
                      {renderMaxAcceptanceTime(form.min_price, form.max_price, form.increment)}
                    </SmallDescription>
                    {
                      form.min_price < ALLOWED_MIN_PRICE && (
                        <ErrorAlert status="error">
                          Enter a minimum price from SGD {ALLOWED_MIN_SGD}.00.
                        </ErrorAlert>
                      )
                    }
                  </AllDriversPrice>
                ) : (
                  <>
                    <Description>
                      Alert drivers at a fixed price per location until a driver is found.
                    </Description>
                    <div>
                      <TextInput
                        containerStyle={inlineTextInputStyle}
                        fieldName="Price (SGD)"
                        isRequired
                        placeholder="5.00"
                        onTextChange={(value: string): void => { handleChange('squad_price', value); }}
                        type="number"
                        width="medium"
                        value={(form.squad_price / ONE_SGD)}
                      />
                    </div>
                    {
                      form.squad_price < ALLOWED_MIN_PRICE && (
                        <ErrorAlert status="error">
                          Enter a minimum price from SGD {ALLOWED_MIN_SGD}.00.
                        </ErrorAlert>
                      )
                    }
                  </>
                )}
              </>
            )}
          </CustomCard>
          {success ? (
            <CenterModal
              rightButtonText="OK"
              rightButtonOnClick={(): void => { props.history.push(`/sys/organizations/${props.match.params.id}/default-price`); }}
              title="Organization Settings"
            >
              <Alert status='success'>
                <AlertMessage>Saved</AlertMessage>
              </Alert>
            </CenterModal>
          ) : false}
        </Container>
      </SettingSidebarMenu>
    </SysAdminMainContainer >
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const CustomCard = styled(Card)`
  flex: 1;
  margin: 0rem;
  padding: 0rem;
`;

const CardRow = styled.div`
  display: flex;

  & > * {
    margin-right: 1rem;
  }
`;

const Arrow = styled(FontAwesomeIcon)`
  align-self: center;
  margin-top: 1.5rem;
`;

const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Button = styled(StyledButton)`
  margin-right: 1rem;
`;

const Separator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
  margin-bottom: 1.5rem;
`;

const RadioText = styled.div`
  color: ${COLOR.black};
`;

const AdditionalInfo = styled.div<{ disabled?: boolean }>`
  color: ${(props): string => props.disabled ? COLOR.midLightGrey : COLOR.midDarkGrey};
`;

const ContentTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 0.375rem;
  color: ${COLOR.darkGray};
`;

const PriceInfo = styled.div`
  margin-bottom: 1.5rem;
`;

const AlertInfo = styled.div`
  margin-bottom: 1rem;
`;

const AllDriversPrice = styled.div`
  margin-bottom: 7rem;
`;

const Description = styled.div`
  margin-bottom: 1.5rem;
`;

const SmallDescription = styled.div`
  margin-top: 0.313rem;
  font-size: 0.75rem;
`;

export const AlertMessage = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

function mapStateToProps(state: RootState): SysAdminDefaultPriceProps {
  return {
    organization: state.organization.organization,
    squadDrivers: state.organization.squadDrivers,
  };
}

export default connect(mapStateToProps)(OrganizationEdit);