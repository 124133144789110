import * as React from 'react';
import * as moment from 'moment';
import { SidebarMenu } from 'components';
import { connect } from 'react-redux';
import { Separator } from 'components';
import { Vessel } from 'models/vessel';
import { RootState } from 'reduxActions/store';
import { SidebarMenuContainer, SidebarInfoContainer, SidebarName, SidebarCreatedDate, SidebarUpdatedBy } from './styles';

interface MainMenu {
  title: string;
  submenu: Submenu[];
}

interface Submenu {
  title: string;
  path: string;
}

interface StateProps {
  vessel: Vessel;
}

interface SidebarMenuProps extends StateProps {
  header?: React.ReactNode;
  mainMenu?: MainMenu[];
  footer?: React.ReactNode;
  selected?: string;
  children?: React.ReactNode;
}

const VesselSidebarMenu = (props: SidebarMenuProps): JSX.Element => {
  const id = props.vessel && props.vessel.id || '';
  return (
    <SidebarMenu
      {...props}
      header={(
        <>
          <SidebarMenuContainer>
            <SidebarInfoContainer>
              <SidebarName>
                {props.vessel && props.vessel.name}
              </SidebarName>
              <SidebarCreatedDate>
                Created on {props.vessel && moment(props.vessel.created_at).format('DD MMM YYYY')}
              </SidebarCreatedDate>
              <SidebarUpdatedBy>
                {props.vessel && props.vessel.user && props.vessel.user.name && `Updated by ${props.vessel.user.name}`}
              </SidebarUpdatedBy>
            </SidebarInfoContainer>
          </SidebarMenuContainer>
          <Separator />
        </>
      )}
      mainMenu={[
        {
          title: 'Vessel',
          submenu: [
            {
              title: 'Vessel details',
              path: `/sys/vessels/${id}/details`,
            },
          ],
        },
      ]}
    />
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  vessel: state.vessel.vessel,
});

export default connect(mapStateToProps)(VesselSidebarMenu);