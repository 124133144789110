import * as React from 'react';
import { connect } from 'react-redux';
import TaskClient from 'httpClients/taskClient';
import {
  Breadcrumb,
  CenterModal,
  MainContainer,
  Radio,
  Typography,
  Collapsible,
  DropdownMenu,
  Alert,
} from 'components';
import { RouteComponentProps } from 'react-router-dom';
import { Task } from 'models/task';
import { RootState } from 'reduxActions/store';
import {
  faChevronLeft,
  faShip,
  faTimesCircle,
  faShare,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import BroadcastInfoCard from './cards/broadcastInfoCard';
import IdentityInfoCard from './cards/identityInfoCard';
import OrderActivityCard from './cards/orderActivityCard';
import AdditionalInfoCard from './cards/additionalInfoCard';
import MarineInfoCard from './cards/marineInfoCard';
import {
  Heading,
  Prompt,
  Divider,
  Stack,
  ColumnItem,
  IconGrayish,
  CardWrapper,
  OrderInfoContainer,
  PickupDeliveryContainer,
  IdAndStatusWrapper,
  VesselWrapper,
  CollapsibleText,
  ActionButton,
  IsTestLabel,
  DropdownButton,
  DropdownButtonIcon,
} from './styles';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import { Organization } from 'models/organization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PickupAndDeliveryDetail from './pickupAndDeliveryDetail';
import {
  TaskEditableStatusesMarineMerchant,
  ActiveOrderStatusStrings, TASK_STATUS,
} from 'constants/taskStatus';
import ReasonOfFailure from './reasonOfFailure';
import { css } from 'styled-components';
import SharePodReceiptModal from '../sys/task/modal/sharePodReceiptModal';
import StatusBadge from 'components/statusBadge';
import { sendTaskCancelNotification, getLastUpdatedTask } from 'utils/taskHelper';
import BadgeGroup, { BadgeChip } from 'components/badge/badgeGroup';
import DateHelper from 'utils/dateHelper';
import * as moment from 'moment';

const cancelReasons = [
  {
    text: 'My customer cancelled the order',
    value: 'My customer cancelled the order',
  },
  {
    text: 'Wrong information',
    value: 'Wrong information',
  },
  {
    text: 'I found a better offer',
    value: 'I found a better offer',
  },
  {
    text: 'Others',
    value: 'Others',
    withTextInput: true,
    textInputRequired: true,
  },
];

interface StateProps {
  task: Task;
  organization: Organization;
}

interface LocationState {
  from: string;
}

interface OrderState {
  cancelReason: string;
  showCancelModal: boolean;
  isMobile: boolean;
  showPODShareModal: boolean;
}

type Props = RouteComponentProps<{ id: string }, object, LocationState> & StateProps;

class OrderDetail extends React.Component<Props, OrderState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cancelReason: cancelReasons[0].value,
      showCancelModal: false,
      isMobile: false,
      showPODShareModal: false,
    };
  }

  componentDidMount(): void {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    this.setState({ isMobile: mediaQuery.matches });
    mediaQuery.addEventListener('change', this.handleMediaQueryChange);
    const { id } = this.props.match.params;
    this.fetchTask(id);
  }

  componentWillUnmount(): void {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    mediaQuery.removeEventListener('change', this.handleMediaQueryChange);
  }

  isCannotEdit = (): boolean => {
    const { task } = this.props;
    let cannotEdit = true;
    if (task) {
      // validate Accepted status, should be At least 3 hours before pickup start time
      if (task.broadcast_preference === BROADCAST_PREFERENCES.marine) {
        if (TaskEditableStatusesMarineMerchant.includes(task.current_status)) {
          cannotEdit = false;
          if (task.current_status === TASK_STATUS.ACCEPTED) {
            // check for current time, must be at least 3 hours before pickup time
            const timeToCheck =
              task.from_time_window && task.from_time_window.start_time_utc;
            cannotEdit = DateHelper.isMoreThan3HoursFromStart(timeToCheck);
          }
        }
      } else {
        // only pending_broadcast allowed for editing merchant standard order
        if (['pending_broadcast'].includes(task.current_status)) {
          cannotEdit = false;
        }
      }
    }
    return cannotEdit;
  };

  isShowNoLongerEditableBadge = (): boolean => {
    const { task } = this.props;
    const StatusesList: string[] = [
      'expired',
      'cancelled',
      'unsuccessful_pickup',
      'unsuccessful_delivery',
    ];

    return this.isCannotEdit() && !StatusesList.includes(task.current_status);
  };

  handleMediaQueryChange = (mediaQuery: MediaQueryListEvent): void => {
    this.setState({ isMobile: mediaQuery.matches });
  };

  fetchTask = async (id: string): Promise<void> => {
    const client = new TaskClient();
    await client.getTask(id);
  };

  fetchOrganization = async (): Promise<void> => {
    const client = new OrganizationManagementClient();
    await client.getOrganization();
  };

  cancel = async (): Promise<void> => {
    const client = new TaskClient();
    const versionRev = this.props.task && this.props.task.version_rev;

    await client.cancelTask(this.props.match.params.id, {
      reason: this.state.cancelReason,
      version_rev: versionRev,
    });
    if (process.env.SEGMENT_KEY) {
      await this.fetchOrganization();
      const orgName = this.props.organization.business_name;

      window.analytics.track('Order Cancelled', {
        task_id: this.props.task.id,
        org_id: this.props.task.org_id,
        org_name: orgName,
        reason: this.state.cancelReason,
      });
    }

    sendTaskCancelNotification(this.props.task);

    this.setState({ showCancelModal: false });
    this.fetchTask(this.props.match.params.id);
  };

  renderCancelModal = (): React.ReactNode => {
    const { cancelReason } = this.state;
    const mandatoryTextInputOption = cancelReasons.find(
      (reason) =>
        reason.withTextInput &&
        reason.textInputRequired &&
        cancelReason.indexOf(`${reason.value} - `) === 0
    );

    return (
      <CenterModal
        leftButtonOnClick={(): void => {
          this.setState({ showCancelModal: false });
        }}
        leftButtonText="Back"
        rightButtonDisabled={
          mandatoryTextInputOption
            ? cancelReason.trim() === `${mandatoryTextInputOption.value} -`
            : cancelReason.trim().length === 0
        }
        rightButtonStyle="discourage"
        rightButtonType="secondary"
        rightButtonOnClick={this.cancel}
        rightButtonText="Yes, cancel"
        title="Cancel Order"
      >
        <Prompt>Reason for cancellation:</Prompt>
        <div>
          <Radio
            options={cancelReasons}
            value={cancelReason}
            onChange={(v: string): void => {
              this.setState({ cancelReason: v });
            }}
          />
        </div>
      </CenterModal>
    );
  };

  renderHeading = (isActiveOrders: boolean): React.ReactNode => {
    const { task } = this.props;
    const orderListQueryParams =
      (this.props.location.state && this.props.location.state.from) || '';
    const cannotCancel = task
      ? task.current_status !== TASK_STATUS.PENDING_BROADCAST &&
      task.current_status !== TASK_STATUS.BROADCASTING &&
      task.current_status !== TASK_STATUS.ACCEPTED
      : true;
    const selectedTab = isActiveOrders ? 'active-orders' : 'past-orders';
    const cannotEdit = this.isCannotEdit();

    return (
      <Heading>
        <Typography
          as="h1"
          size="display_sm"
          color="gray_900"
          weight="semibold"
          customStyle={{ marginRight: 'auto' }}
        >
          Order Info
        </Typography>
        <ActionButton
          buttonStyle="encourage"
          buttonType="neutral"
          onClick={(): void => {
            this.props.history.push(`/${selectedTab}?${orderListQueryParams}`);
          }}
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
        >
          Back
        </ActionButton>
        {task && task.current_status === TASK_STATUS.COMPLETE ? (
          <ActionButton
            onClick={(): void => {
              this.setState({ showPODShareModal: true });
            }}
            buttonStyle="encourage"
            buttonType="neutral"
            icon={<FontAwesomeIcon icon={faShare} />}
          >
            Share
          </ActionButton>
        ) : (
          false
        )}
        {isActiveOrders ? (
          <ActionButton
            buttonStyle="discourage"
            buttonType="neutral"
            disabled={cannotCancel}
            onClick={(): void => {
              this.setState({ showCancelModal: true });
            }}
            icon={<FontAwesomeIcon icon={faTimesCircle} />}
          >
            Cancel Order
          </ActionButton>
        ) : (
          false
        )}
        <DropdownMenu disabled={cannotEdit}>
          <DropdownButton
            disabled={cannotEdit}
            onClick={(): void => {
              this.props.history.push(`/orders/${task && task.id}/edit`);
            }}
            buttonStyle="encourage"
            buttonType="neutral"
          >
            <DropdownButtonIcon icon={faPen} fixedWidth />
            Edit Order
          </DropdownButton>
        </DropdownMenu>
      </Heading>
    );
  };

  renderPODModal = (): React.ReactNode => {
    const taskId = this.props.task.id;
    return (
      <SharePodReceiptModal
        taskId={taskId}
        isShowBackButton={false}
        handleCloseModal={() => {
          this.setState({
            showPODShareModal: false,
          });
        }}
        handleBackButtonModal={() => {
          this.setState({
            showPODShareModal: false,
          });
        }}
      />
    );
  };

  render(): React.ReactNode {
    const { task } = this.props;
    const orderListQueryParams =
      (this.props.location.state && this.props.location.state.from) || '';
    const isActiveOrders =
      (task && ActiveOrderStatusStrings.includes(task.current_status)) || false;
    const selectedTab = isActiveOrders ? 'active-orders' : 'past-orders';
    const breadCrumbText = isActiveOrders ? 'Active Orders' : 'Past Orders';
    const isMarine = task && task.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const lastUpdated = getLastUpdatedTask(task);
    // ETA and ETD (formerly ETB and ETU)
    const etaTime =
      task &&
      task.to_contact &&
      task.to_contact.etb_time_window &&
      task.to_contact.etb_time_window.start_time_utc;
    const etdTime =
      task &&
      task.to_contact &&
      task.to_contact.etu_time_window &&
      task.to_contact.etu_time_window.start_time_utc;

    return (
      <MainContainer selected={selectedTab}>
        <Breadcrumb
          customStyle={{
            marginBottom: 0,
          }}
          items={[
            {
              text: breadCrumbText,
              onClick: (): void => {
                this.props.history.push(`/${selectedTab}?${orderListQueryParams}`);
              },
            },
            { text: this.props.task && this.props.task.id.split('-')[0] },
          ]}
        />
        {this.renderHeading(isActiveOrders)}
        <Divider marginBottom="1rem" />
        {this.props.task && this.props.match.params.id === this.props.task.id && (
          <>
            <OrderInfoContainer
              direction="column"
              gap="1.5rem"
              style={{
                paddingBottom: '1rem',
              }}
            >
              {!this.state.isMobile && (
                <ColumnItem order={1}>
                  <Stack direction="column" gap="1rem">
                    {/* Identity Info Card */}
                    {<IdentityInfoCard task={task} />}
                    {/* Broadcast Info */}
                    {!isMarine && <BroadcastInfoCard task={task} />}
                  </Stack>
                </ColumnItem>
              )}

              <PickupDeliveryContainer
                direction="column"
                gap="0.35rem"
                mdScreenStyle={css`
                  padding: 1rem;
                `}
              >
                {this.isShowNoLongerEditableBadge() ? (
                  <Alert
                    status="warning"
                    display="row"
                    title="Your order is on its way to you and is no longer editable"
                    subTitle="Contact our team to make urgent changes."
                    containerStyle={css`
                      margin-bottom: 2rem;
                    `}
                    buttonText="Contact"
                    onButtonClick={() => {
                      window.open('mailto:help@gotsurge.co');
                    }}
                  />
                ) : (
                  false
                )}
                {lastUpdated ? (
                  <BadgeGroup
                    type="grey"
                    customStyle={css`
                      margin-bottom: 0.5rem;
                    `}
                  >
                    <BadgeChip>Edited</BadgeChip>
                    View Changes {DateHelper.formatSimpleDateTime(lastUpdated.set_at_utc)}
                  </BadgeGroup>
                ) : (
                  false
                )}
                <Stack direction="column" gap="0.35rem">
                  <IdAndStatusWrapper gap="0.5rem">
                    <Typography as="span" size="sm" color="gray_400">
                      {task.tracking_id || task.id.split('-')[0]}
                    </Typography>
                    {task.is_qa ? <IsTestLabel>Test</IsTestLabel> : false}
                    <StatusBadge status={task.current_status} />
                  </IdAndStatusWrapper>
                  <ReasonOfFailure
                    taskStatuses={task.statuses}
                    currentStatus={task.current_status}
                    cancelType="cancelled"
                  />
                </Stack>

                {task.to_contact.vessel_name && (
                  <>
                    <VesselWrapper gap="0.5rem">
                      <IconGrayish icon={faShip} fontSize="19px" />
                      <Typography as="h4" color="gray_700" weight="semibold">
                        {task.to_contact.vessel_name}
                      </Typography>
                    </VesselWrapper>
                    {task.to_contact.vessel_imo || etaTime || etdTime ? (
                      <Typography as="p" color="gray_400">
                        IMO{task.to_contact.vessel_imo}
                        {((task.to_contact.vessel_imo && etaTime) || etdTime) && ' | '}
                        {etaTime && `ETA, ${moment(etaTime).format('DD MMM, HH:mm')}`}
                        {((etdTime && task.to_contact.vessel_imo) || etaTime) && ' | '}
                        {etdTime && `ETD, ${moment(etdTime).format('DD MMM, HH:mm')}`}
                      </Typography>
                    ) : null}
                    {/*
                        TODO..
                        <Typography as="p" size="xs" color="primary_600">
                          <Link to={'/'}>See Vessel Arrival</Link>
                        </Typography>
                      */}
                  </>
                )}

                {this.state.isMobile && (
                  <Collapsible
                    title={({ open }): JSX.Element => (
                      <div>
                        <CollapsibleText>{open ? 'Hide' : 'See More'}</CollapsibleText>
                        {open ? null : (
                          <Divider
                            style={{
                              marginTop: '16px',
                              marginBottom: '16px',
                            }}
                          />
                        )}
                      </div>
                    )}
                  >
                    <Stack
                      direction="column"
                      gap="1rem"
                      style={{
                        marginBottom: '1rem',
                      }}
                    >
                      <IdentityInfoCard task={task} />
                      {!isMarine && <BroadcastInfoCard task={task} />}
                    </Stack>
                  </Collapsible>
                )}

                {!this.state.isMobile && (
                  <Divider
                    style={{
                      marginTop: '16px',
                      marginBottom: '16px',
                    }}
                  />
                )}

                <PickupAndDeliveryDetail task={task} ops />

                {!this.state.isMobile && <Divider marginTopBottom="1.25rem" />}

                {/*Order Activity */}
                <Stack direction="column">
                  {task && <OrderActivityCard task={task} />}
                </Stack>
              </PickupDeliveryContainer>

              <ColumnItem order={2}>
                <CardWrapper>
                  <Stack direction="column" gap="1rem">
                    {task && <AdditionalInfoCard task={task} ops />}
                    {task &&
                      task.broadcast_preference === BROADCAST_PREFERENCES.marine && (
                        <MarineInfoCard task={task} />
                      )}
                  </Stack>
                </CardWrapper>
              </ColumnItem>
            </OrderInfoContainer>
          </>
        )}
        {this.state.showCancelModal && isActiveOrders && this.renderCancelModal()}
        {this.state.showPODShareModal && this.renderPODModal()}
      </MainContainer>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  task: state.task.task,
  organization: state.organization.organization,
});

export default connect(mapStateToProps)(OrderDetail);
