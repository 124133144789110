import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AuthClient from 'httpClients/authClient';
import { saveBearerToken } from 'utils/userHelper';

function SamlAuth(): JSX.Element {
  const locationObj = useLocation();
  const params = new URLSearchParams(locationObj.search);
  const history = useHistory();

  const verifyUser = async (): Promise<void> => {
    try {
      const client = new AuthClient();
      await client.saveUserInfo();
      history.replace('/');
    } catch (_) {
      history.replace('/users/saml/no-access');
    }
  };

  React.useEffect(() => {
    // TODO: prevent token or UUID from being stolen
    if (params.get('uuid') && params.get('token')) {
      saveBearerToken(params.get('token'));
      verifyUser();
    }
  }, []);

  return null;
}

export default SamlAuth;