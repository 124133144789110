import * as React from 'react';
import { BeforeLoginContainer, TextInput, StyledButton, Alert } from 'components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AuthClient from 'httpClients/authClient';
import * as H from 'history';
import COLOR from 'constants/color';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

const STATE = {
  beforeAccept: 'beforeAccept',
  acceptSuccess: 'acceptSuccess',
  acceptFailed: 'acceptFailed',
};

interface UserAcceptInvitationProps<S = H.LocationState> {
  history: H.History<S>;
}

interface UserAcceptInvitationState {
  form: {
    name: string;
    password: string;
  };
  passwordShown: boolean;
  pageState: string;
}

class UserAcceptInvitation extends React.Component<UserAcceptInvitationProps, UserAcceptInvitationState> {
  constructor(props: UserAcceptInvitationProps) {
    super(props);
    this.state = {
      form: {
        name: '',
        password: '',
      },
      passwordShown: false,
      pageState: STATE.beforeAccept,
    };
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  getUrlParams(): URLSearchParams {
    const { location } = this.props.history;
    if (!location.search) {
      return new URLSearchParams();
    }
    return new URLSearchParams(location.search);
  }

  getInvitationToken(): string {
    const queryParams = this.getUrlParams();
    return queryParams.get('invitation_token') || '';
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const { form } = this.state;
    this.setState({ pageState: STATE.beforeAccept });
    try {
      const client = new AuthClient();
      await client.AcceptInvitation({
        user: {
          name: form.name,
          password: form.password,
          invitation_token: this.getInvitationToken(),
        },
      });
      this.setState({ pageState: STATE.acceptSuccess });
    } catch (e) {
      this.setState({ pageState: STATE.acceptFailed });
    }
  }

  renderUserInvitationForm(): React.ReactNode {
    const { form, passwordShown } = this.state;
    const pattern = new RegExp(/[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);

    return (
      <BeforeLoginContainer title="Set your name and password">
        <LabelInfo>Use the name that your team recognises.</LabelInfo>
        <form onSubmit={this.handleSubmit}>
          <InputFieldContainer>
            <InputField
              fieldName="Name"
              isRequired
              name="name"
              onTextChange={(value): void => this.handleChange('name', value)}
              type="text"
              value={form.name}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <InputField
              fieldName="Password"
              icon={passwordShown ? faEye : faEyeSlash}
              iconOnClick={(): void => {
                this.setState(prevState => ({ passwordShown: !prevState.passwordShown }));
              }}
              isRequired
              name="password"
              onTextChange={(value): void => this.handleChange('password', value)}
              type={passwordShown ? 'text' : 'password'}
              value={form.password}
            />
          </InputFieldContainer>
          <LabelAdditionalInfo>Minimum 8 characters and at least 1 special character.</LabelAdditionalInfo>
          <ActionButton
            buttonStyle="encourage"
            buttonType="primary"
            size="lg"
            isFullWidth={true}
            type="submit"
            disabled={form.password && form.password.length < 8 || !pattern.test(form.password)}
          >
            Done
          </ActionButton>
        </form>
      </BeforeLoginContainer>
    );
  }

  renderInvitationAccepted = (): React.ReactNode => {
    return (
      <BeforeLoginContainer title="Set your name and password">
        <LabelInfo>{`Welcome, ${this.state.form.name}. We're glad you're here. `}</LabelInfo>
        <MessageContainer status='success'>
          Your account is successfully created!
        </MessageContainer>
        <Link to="/login">
          <ActionButton
            buttonStyle="encourage"
            buttonType="primary"
            isFullWidth={true}
          >
            Go to Login
          </ActionButton>
        </Link>
      </BeforeLoginContainer>
    );
  }

  renderInvitationExpired = (): React.ReactNode => (
    <BeforeLoginContainer title="Set your name and password">
      <MessageContainer status='error'>
        {'This invitation has expired. Contact your admin to invite you again.'}
      </MessageContainer>
    </BeforeLoginContainer>
  )

  render(): React.ReactNode {
    let content: React.ReactNode;
    if (this.state.pageState === STATE.beforeAccept) {
      content = this.renderUserInvitationForm();
    } else if (this.state.pageState === STATE.acceptSuccess) {
      content = this.renderInvitationAccepted();
    } else if (this.state.pageState === STATE.acceptFailed) {
      content = this.renderInvitationExpired();
    }
    return (
      content
    );
  }
}

const LabelInfo = styled.div`
  color: ${COLOR.darkGray};
  margin-bottom: 1rem;
`;

const InputFieldContainer = styled.div`
  margin-top: 1rem;
`;

const InputField = styled(TextInput)`
  margin-bottom: 0.2rem !important;
`;

const LabelAdditionalInfo = styled.div`
  margin-top: 0.2rem;
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
`;

const ActionButton = styled(StyledButton)`
  margin-top: 1.5rem;
`;

const MessageContainer = styled(Alert)`
  margin-top: 1rem;
`;

export default UserAcceptInvitation;
