import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faChevronRight, faAngleLeft, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import COLOR, { transparent } from 'constants/color';

interface NavigationArrowButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  type: 'left' | 'right';
}

interface NavigationArrowButtonState {
  hover: boolean;
}

class NavigationArrowButton extends React.Component<NavigationArrowButtonProps, NavigationArrowButtonState> {
  constructor(props: NavigationArrowButtonProps) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  render(): React.ReactNode {
    let icon = faAngleRight;
    if (this.props.type === 'left') {
      if (this.state.hover) {
        icon = faChevronLeft;
      } else {
        icon = faAngleLeft;
      }
    } else {
      if (this.state.hover) {
        icon = faChevronRight;
      }
    }

    return(
      <ArrowContainer
        disabled={this.props.disabled}
        onClick={
          !this.props.disabled && this.props.onClick ?
            (): void => { this.props.onClick(); } : undefined
        }
        onMouseEnter={(): void => { this.setState({ hover: true }); }}
        onMouseLeave={(): void => { this.setState({ hover: false }); }}
      >
        <ArrowIcon
          disabled={this.props.disabled}
          icon={icon}
        />
      </ArrowContainer>
    );
  }
}

const ArrowContainer = styled.div<{ disabled: boolean }>`
  background-color: ${(props): string => props.disabled ? COLOR.neutral : COLOR.white};
  box-shadow: 0px 2px 4px ${transparent('neutral', 0.8)};
  border-radius: 20px;
  border: 1px solid ${COLOR.neutral};
  cursor: ${(props): string => props.disabled ? 'default' : 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  margin-left: 0.25rem;
  width: 1.5rem;
  :hover{
    ${(props): string => props.disabled ? '' : `
        background-color: ${COLOR.whiteGrey};
        padding-left: 0.531rem;
        padding-right: 0.469rem;
        width: 0.5rem !important;
      `
    }
  }
`;

const ArrowIcon = styled(FontAwesomeIcon)<{ disabled: boolean }>`
  color: ${(props): string => props.disabled ? COLOR.midDarkGrey : COLOR.darkGray};
  font-size: 0.75rem;
`;

export default NavigationArrowButton;