import {
  GeoServiceState,
  GeoServiceActionTypes,
  RECEIVE_GEODATAS,
  RECEIVE_SAVED_GEODATAS,
  RECEIVE_SAVED_GEODATA,
  RECEIVE_MARINE_LOCATION_DATAS,
  RECEIVE_MARINE_LOCATION_DETAIL,
} from './geoServiceTypes';

const initialState: GeoServiceState = {
  geoDatas: [],
  savedGeoDatas: [],
  savedGeoData: {
    id: '',
    building_name: '',
    street_address: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    lat: 0,
    lng: 0,
    source: '',
    source_reference: '',
  },
  marineLocationDatas: [],
  marineLocationDetail: {
    id: '',
    name_address: '',
    lat: 0,
    lng: 0,
    country: '',
    city: '',
    state: '',
    created_at: '',
    updated_at: '',
  },
};

function geoServiceReducer(
  state: GeoServiceState = initialState,
  action: GeoServiceActionTypes
): GeoServiceState {
  switch (action.type) {
    case RECEIVE_GEODATAS:
      return { ...state, geoDatas: action.geoDatas };
    case RECEIVE_SAVED_GEODATAS:
      return { ...state, savedGeoDatas: action.savedGeoDatas };
    case RECEIVE_SAVED_GEODATA:
      return { ...state, savedGeoData: action.savedGeoData };
    case RECEIVE_MARINE_LOCATION_DATAS:
      return { ...state, marineLocationDatas: action.marineLocationDatas };
    case RECEIVE_MARINE_LOCATION_DETAIL:
      return { ...state, marineLocationDetail: action.marineLocationDetail };
    default:
      return state;
  }
}

export default geoServiceReducer;
