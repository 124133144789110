import * as React from 'react';
import {
  CenterModal,
  TextInput,
  Searchbox,
  DashedLine,
  Typography,
  LocationTypeForm,
  DisplayField,
  SavedAddressButton,
  CounterInputButton,
  Checkbox,
  InputDimensions,
} from 'components';
import { css } from 'styled-components';
import COLOR from 'constants/color';
import { GeoData, SavedGeoData } from 'models/geoService';
import {
  faCircle,
  faSearch,
  faExclamationCircle,
  faInfoCircle,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Address, TimeWindow } from 'models/task';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import * as moment from 'moment';
import AddressContactModal, {
  AddressContactData,
  ShowSection,
} from 'pages/sys/task/modal/addressContactModal';
import { MerchantBaseForm, MerchantDeliveryForm } from 'models/task';
import { Organization } from 'models/organization';
import {
  OrderCardRow,
  TitleDescription,
  CircleIcon,
  AlphabetContainer,
  OrderHeading,
  OrderTitle,
  Content,
  Button,
  RedText,
  DateAndTimeContainer,
  DatePickerWrapper,
  AddressSeparator,
  FormErrorAlert,
  InlineErrorMessage,
  ErrorIcon,
  SelectContactButton,
  SelectContactText,
  SubTitleContainer,
  SubTitle,
  Calendar,
  InstructionsLabel,
  RemoveDeliveryLocationButton,
  TrashIcon,
  RecommendedBadge,
  ItemTitle,
  ItemDescription,
  ReferenceIDLabel,
  ReferenceIDInfoButton,
  Icon,
  ItemList,
  Li,
  ItemNumber,
  RemoveItemSummaryWrapper,
  RemoveItemButton,
  ItemFieldName,
  ItemFieldsWrapper,
  CheckBoxItemWrapper,
  CheckBoxFullWidthContainer,
  ItemDetailsContainer,
} from 'pages/order/styles';
import VehicleForm from 'pages/sys/task/partialForm/vehicleForm';
import DateHelper from 'utils/dateHelper';

interface DeliveryFormProps {
  i: number;
  baseForm: MerchantBaseForm;
  deliveryForm: MerchantDeliveryForm;
  deliveryFormsLength: number;
  organization: Organization;
  geoDatas: GeoData[];
  savedGeoDatas: SavedGeoData[];
  updateBaseForm: (fieldName: string, value: string | boolean | Date) => void;
  updateDeliveryForm: (
    fieldName: string,
    value: string | Date | boolean | number,
    deliveryFormIndex: number
  ) => void;
  marineVehicleFormChange: (fieldName: string, value: string | boolean) => void;
  chooseSavedAddress: (
    type: 'pickup' | 'delivery',
    index: number,
    data: SavedGeoData
  ) => void;
  removeItemClick: (j: number) => void;
  addItemClick: () => void;
  onRemoveDeliveryDetails: () => void;
  onSelectAddress: (data: AddressContactData) => void;
  isEdit?: boolean;
  isEditableField?: (fieldName: string) => boolean;
  ops?: boolean;
  page?: 'new' | 'edit' | '';
}

interface DeliveryFormState {
  toLocationSelected: boolean;
  showRemoveDeliveryDetailsModal: boolean;
  showAddressContactModal: boolean;
  showOnly: ShowSection;
  showReferenceIDModal: boolean;
}

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

class DeliveryForm extends React.Component<DeliveryFormProps, DeliveryFormState> {
  static defaultProps = {
    ops: false,
    page: '',
  };

  constructor(props: DeliveryFormProps) {
    super(props);
    this.state = {
      toLocationSelected: props.isEdit,
      showRemoveDeliveryDetailsModal: false,
      showAddressContactModal: false,
      showOnly: false,
      showReferenceIDModal: false,
    };
  }

  isRangeValid = (timeWindow: TimeWindow): boolean => {
    let isRangeValid = true;
    const startDate = moment(timeWindow.start_time_utc);
    const endDate = moment(timeWindow.end_time_utc);
    if (startDate !== endDate) {
      isRangeValid = endDate.isSameOrAfter(startDate);
    }

    return isRangeValid;
  };

  isLocationValid = (geoData: GeoData): boolean => {
    return (
      geoData.street_address !== '' &&
      geoData.lat !== 0 &&
      geoData.lng !== 0 &&
      geoData.lat !== undefined &&
      geoData.lng !== undefined
    );
  };

  isAddressLocationValid = (address: Address): boolean =>
    this.isLocationValid({
      street_address: address.street_address,
      zip_code: address.zip_code,
      lat: address.latitude,
      lng: address.longitude,
    });

  renderTimeRangeError = (timeWindow: TimeWindow): React.ReactNode => {
    const isRangeInvalid =
      Object.values(timeWindow).every((v) => v !== '') && !this.isRangeValid(timeWindow);

    return (
      <>
        {isRangeInvalid && (
          <FormErrorAlert status="error" noBackground>
            The start time must be earlier than the end time
          </FormErrorAlert>
        )}
      </>
    );
  };

  renderInstructionsLabel = (): React.ReactNode => {
    return (
      <InstructionsLabel>
        <>Instructions to Driver</>
        <RecommendedBadge>Recommended</RecommendedBadge>
      </InstructionsLabel>
    );
  };

  renderReferenceID = (prefixID: string, trackingID: string): React.ReactNode => {
    const isReferenceIDFilled = trackingID.length >= 1;
    const hasMinLength = prefixID.length + trackingID.length >= 11;

    return (
      <>
        {isReferenceIDFilled && !hasMinLength && (
          <FormErrorAlert status="error" noBackground>
            Reference ID, including the prefix, must have at least 11 characters.
          </FormErrorAlert>
        )}
      </>
    );
  };

  renderReferenceIDModal = (): React.ReactNode => (
    <CenterModal
      title="Reference ID Prefix"
      rightButtonText="OK"
      rightButtonOnClick={(): void => {
        this.setState({ showReferenceIDModal: false });
      }}
    >
      To customise the prefix, contact GotSurge.
    </CenterModal>
  );

  renderReferenceIDLabel = (): React.ReactNode => {
    return (
      <ReferenceIDLabel>
        <>Reference ID</>
        <ReferenceIDInfoButton
          buttonType="neutral"
          buttonStyle="discourage"
          onClick={(): void => {
            this.setState({ showReferenceIDModal: true });
          }}
        >
          <Icon color={COLOR.grey} fontSize={'1rem'} icon={faInfoCircle} />
        </ReferenceIDInfoButton>
      </ReferenceIDLabel>
    );
  };

  renderDeliveryAddressNotFound = (): React.ReactNode => (
    <>
      {(this.props.deliveryForm.to_address.street_address ||
        this.props.deliveryForm.to_address.building_name) &&
      this.props.isEditableField &&
      this.props.isEditableField('to_address.street_address') &&
      !this.isAddressLocationValid(this.props.deliveryForm.to_address) ? (
        <InlineErrorMessage>
          <ErrorIcon icon={faExclamationCircle} />
          Address not found
        </InlineErrorMessage>
      ) : (
        false
      )}
    </>
  );

  renderDeliveryLocation = (): React.ReactNode => {
    const {
      deliveryForm,
      baseForm,
      savedGeoDatas,
      updateDeliveryForm,
      chooseSavedAddress,
      i,
      isEditableField,
    } = this.props;
    const isBroadcastPreferenceMarine =
      baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const latLngText =
      deliveryForm.to_address.latitude || deliveryForm.to_address.longitude
        ? `${deliveryForm.to_address.latitude}, ${deliveryForm.to_address.longitude}`
        : '';
    const locationText =
      deliveryForm.to_location_type === 'land' || !isBroadcastPreferenceMarine
        ? [
            deliveryForm.to_address.street_address,
            deliveryForm.to_address.unit_number && deliveryForm.to_address.unit_number,
            deliveryForm.to_contact.berth_no && deliveryForm.to_contact.berth_no,
          ]
            .filter(Boolean)
            .join(', ')
        : latLngText;
    const isToContactEmpty = Object.entries(deliveryForm.to_contact)
      .filter(
        ([key]) =>
          key !== 'berth_no' &&
          key !== 'etb_time_window' &&
          key !== 'etu_time_window' &&
          key !== 'vessel_id' &&
          key !== 'vessel_imo' &&
          key !== 'vessel_name' &&
          (this.props.ops || key !== 'email')
      )
      .every(([, value]) => value === '');
    const { toLocationSelected } = this.state;

    return (
      <>
        {isBroadcastPreferenceMarine ? (
          <>
            <LocationTypeForm
              value={toLocationSelected && deliveryForm.to_location_type}
              onChange={(v) => {
                updateDeliveryForm('to_location_type', v, i);
                this.setState({
                  showAddressContactModal: true,
                  toLocationSelected: false,
                  showOnly: v === 'land' ? 'address_details' : 'sea_address',
                });
              }}
              disabled={isEditableField && !isEditableField('to_location_type')}
            />
            {deliveryForm.to_location_type && toLocationSelected ? (
              <>
                <DisplayField
                  label="Location"
                  width="half"
                  title={
                    deliveryForm.to_address.name_address ||
                    deliveryForm.to_address.building_name
                  }
                  text={locationText}
                  onButtonClick={() => {
                    this.setState({
                      showAddressContactModal: true,
                      showOnly:
                        deliveryForm.to_location_type === 'land'
                          ? 'address_details'
                          : 'sea_address',
                    });
                  }}
                  disabled={isEditableField && !isEditableField('delivery_location')}
                />
                {this.renderDeliveryAddressNotFound()}
                {deliveryForm.to_location_type === 'land' &&
                isEditableField &&
                isEditableField('delivery_location') ? (
                  <>
                    {i === 0 ? (
                      <SavedAddressButton
                        savedGeoDatas={savedGeoDatas}
                        onChange={(v: SavedGeoData) =>
                          chooseSavedAddress('delivery', i, v)
                        }
                      />
                    ) : (
                      false
                    )}
                  </>
                ) : (
                  false
                )}
                {isToContactEmpty ? (
                  <div>
                    <Typography
                      as="p"
                      color="gray_900"
                      size="sm"
                      customStyle={{ marginTop: '1rem', marginBottom: '0.25rem' }}
                    >
                      Contact Details
                    </Typography>
                    <SelectContactButton
                      buttonStyle="encourage"
                      buttonType="neutral"
                      onClick={(): void => {
                        this.setState({
                          showAddressContactModal: true,
                          showOnly: 'contact_details',
                        });
                      }}
                      disabled={
                        isEditableField && !isEditableField('delivery_contact_details')
                      }
                      type="button"
                    >
                      <SelectContactText>Enter contact details...</SelectContactText>
                    </SelectContactButton>
                  </div>
                ) : (
                  <DisplayField
                    label="Contact Details"
                    width="half"
                    title={[
                      deliveryForm.to_contact.company_name,
                      deliveryForm.to_contact.vehicle_name,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                    text={[
                      deliveryForm.to_contact.name,
                      deliveryForm.to_contact.phone,
                      this.props.ops ? deliveryForm.to_contact.email : '',
                    ]
                      .filter(Boolean)
                      .join(', ')}
                    onButtonClick={() => {
                      this.setState({
                        showAddressContactModal: true,
                        showOnly: 'contact_details',
                      });
                    }}
                    containerStyle={css`
                      margin-top: 0.5rem;
                    `}
                    disabled={
                      isEditableField && !isEditableField('delivery_contact_details')
                    }
                  />
                )}
              </>
            ) : (
              false
            )}
          </>
        ) : (
          <>
            <Searchbox
              hideDropdown
              readOnly
              trailingIcon={faSearch}
              containerStyle={inlineTextInputStyle}
              fieldName="Postal Code or Street Address"
              isRequired
              disabled={isEditableField && !isEditableField('to_address.street_address')}
              handleSetFocus={(focused: boolean): void => {
                if (focused) {
                  this.setState({ showAddressContactModal: true, showOnly: false });
                }
              }}
              placeholder="Postal Code / Street Address"
              width="half"
              value={
                deliveryForm.to_address.name_address ||
                deliveryForm.to_address.building_name
              }
              options={(this.props.geoDatas || []).map((geoData) => ({
                value: geoData.street_address,
                text: geoData.street_address,
                additionalValue: {
                  street_address: geoData.street_address,
                  building_name: geoData.building_name,
                  zip_code: geoData.zip_code,
                  lat: geoData.lat,
                  lng: geoData.lng,
                },
              }))}
              bottomLabel={
                deliveryForm.to_address.street_address && (
                  <SubTitleContainer>
                    <SubTitle>{locationText}</SubTitle>
                    {Object.values(deliveryForm.to_contact).some((value) => value) && (
                      <SubTitle>
                        Contact:{' '}
                        {[
                          deliveryForm.to_contact.company_name,
                          deliveryForm.to_contact.vehicle_name,
                          deliveryForm.to_contact.name,
                          deliveryForm.to_contact.phone,
                        ]
                          .filter(Boolean)
                          .join(', ')}
                      </SubTitle>
                    )}
                  </SubTitleContainer>
                )
              }
            />
            {this.renderDeliveryAddressNotFound()}
            {isEditableField && isEditableField('from_address.street_address') && (
              <SavedAddressButton
                savedGeoDatas={savedGeoDatas}
                onChange={(v: SavedGeoData) => chooseSavedAddress('delivery', i, v)}
              />
            )}
          </>
        )}
      </>
    );
  };

  renderMarineVehicleForm = (): React.ReactNode => {
    const { baseForm, deliveryForm, marineVehicleFormChange, isEditableField } =
      this.props;
    const { toLocationSelected } = this.state;
    return (
      <VehicleForm
        displayHeader={
          <>
            <Typography
              weight="normal"
              color="gray_700"
              as="h4"
              size="sm"
              customStyle={{ marginBottom: '0.2rem', letterSpacing: '0.32px' }}
            >
              Vehicle Requirements <RedText>*</RedText>
            </Typography>
            <Typography weight="normal" color="gray_400" as="p" size="xs">
              Select the most appropriate vehicle to fit your cargo.
            </Typography>
          </>
        }
        form={{
          broadcast_preference:
            baseForm.broadcast_preference as keyof typeof BROADCAST_PREFERENCES,
          vehicle_preference: deliveryForm.vehicle_preference,
        }}
        onFormChange={marineVehicleFormChange}
        disabled={
          baseForm.from_location_type === '' ||
          deliveryForm.to_location_type === '' ||
          !toLocationSelected ||
          (isEditableField && !isEditableField('select_vehicle'))
        }
      />
    );
  };

  renderAddressContactModal = (): React.ReactNode => {
    const {
      baseForm: { broadcast_preference },
      deliveryForm,
      onSelectAddress,
      ops,
    } = this.props;
    const { showAddressContactModal } = this.state;
    return (
      <>
        {showAddressContactModal && (
          <AddressContactModal
            broadcastPreferences={broadcast_preference}
            title="Send To"
            type={ops ? 'ops' : 'merchant'}
            defaultForm={{
              address: deliveryForm.to_address,
              contact: deliveryForm.to_contact,
            }}
            closeModal={(): void => this.setState({ showAddressContactModal: false })}
            onConfirm={(data: AddressContactData): void => {
              onSelectAddress(data);
              this.setState((prevState) => ({
                ...prevState,
                toLocationSelected: true,
              }));
            }}
            showOnly={this.state.showOnly}
          />
        )}
      </>
    );
  };

  renderRemoveDeliveryDetailsWarningModal = (): React.ReactNode => (
    <CenterModal
      title="Remove Delivery Details?"
      leftButtonText="Back"
      leftButtonOnClick={(): void => {
        this.setState({ showRemoveDeliveryDetailsModal: false });
      }}
      rightButtonOnClick={(): void => {
        this.props.onRemoveDeliveryDetails();
        this.setState({ showRemoveDeliveryDetailsModal: false });
      }}
      rightButtonText="Remove"
    >
      The delivery details will be lost.
    </CenterModal>
  );

  render(): React.ReactNode {
    const {
      baseForm,
      deliveryForm,
      organization,
      deliveryFormsLength,
      i,
      updateDeliveryForm,
      removeItemClick,
      addItemClick,
      isEditableField,
    } = this.props;
    const { showReferenceIDModal, showRemoveDeliveryDetailsModal } = this.state;
    const showDashedLine = i !== deliveryFormsLength - 1;
    const prefixID = (organization && organization.prefix_id) || 'GOTSURGE-';
    const isMarine = baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const instructionToDriver = isMarine
      ? 'E.g. Meet at the loading bay'
      : 'E.g. Meet at the lobby';
    return (
      <section key={i}>
        <OrderCardRow>
          <CircleIcon color={COLOR.yellow} icon={faCircle} />
          <AlphabetContainer>{i + 1}.</AlphabetContainer>
          <OrderHeading>
            <OrderTitle>Send To</OrderTitle>
            {!isMarine && (
              <TitleDescription>Enter the customers details</TitleDescription>
            )}
          </OrderHeading>
          {deliveryFormsLength > 1 && (
            <RemoveDeliveryLocationButton
              buttonType="neutral"
              buttonStyle="discourage"
              onClick={(): void => {
                this.setState({ showRemoveDeliveryDetailsModal: true });
              }}
              type="button"
            >
              <TrashIcon icon={faTrash} />
              Remove
            </RemoveDeliveryLocationButton>
          )}
        </OrderCardRow>
        <OrderCardRow>
          {showDashedLine && <DashedLine />}
          <Content>
            <div>{this.renderDeliveryLocation()}</div>
            <div>
              <DateAndTimeContainer>
                <DatePickerWrapper
                  minDate={moment().toDate()}
                  dateFormat="dd MMM, HH:mm"
                  timeFormat="HH:mm"
                  onChange={(value: Date): void => {
                    if (value instanceof Date) {
                      updateDeliveryForm('to_time_window.start_time_utc', value, i);
                      updateDeliveryForm(
                        'to_time_window.end_time_utc',
                        moment(value).add(1, 'hour').toDate(),
                        i
                      );
                    }
                  }}
                  onSelect={(value: Date): void =>
                    updateDeliveryForm('to_time_window.start_time_utc', value, i)
                  }
                  selected={new Date(deliveryForm.to_time_window.start_time_utc)}
                  showTimeSelect
                  customInput={
                    <TextInput
                      autoComplete="none"
                      inputName="name"
                      containerStyle={inlineTextInputStyle}
                      isRequired
                      fieldName="Delivery Date & Time"
                      width="full"
                    />
                  }
                  calendarContainer={Calendar}
                  disabled={
                    isEditableField && !isEditableField('to_time_window.start_time_utc')
                  }
                />
                <TextInput
                  autoComplete="none"
                  containerStyle={inlineTextInputStyle}
                  fieldName={this.renderInstructionsLabel()}
                  onTextChange={(value: string): void => {
                    updateDeliveryForm('delivery_note_to_driver', value, i);
                  }}
                  placeholder={instructionToDriver}
                  width="half"
                  value={deliveryForm.delivery_note_to_driver}
                  disabled={
                    isEditableField && !isEditableField('delivery_note_to_driver')
                  }
                />
              </DateAndTimeContainer>
              {this.renderTimeRangeError(deliveryForm.to_time_window)}
              {deliveryForm.to_time_window.start_time_utc &&
                DateHelper.compareDateTime(
                  baseForm.from_time_window.start_time_utc,
                  deliveryForm.to_time_window.start_time_utc
                ) > 0 && (
                  <FormErrorAlert status="error" noBackground display="row">
                    The delivery time must be later than pickup time
                  </FormErrorAlert>
                )}
            </div>

            {baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine && (
              <>
                <div>
                  <ItemTitle>Additional Info</ItemTitle>
                  <TextInput
                    autoComplete="none"
                    inputName="tracking-id"
                    containerStyle={inlineTextInputStyle}
                    fieldName={this.renderReferenceIDLabel()}
                    leftLabel={prefixID}
                    onTextChange={(value: string): void => {
                      updateDeliveryForm('tracking_id', value, i);
                    }}
                    placeholder="4297"
                    width="medium"
                    value={deliveryForm.tracking_id}
                    disabled={isEditableField && !isEditableField('tracking_id')}
                  />
                  {showReferenceIDModal && this.renderReferenceIDModal()}
                  {this.renderReferenceID(prefixID, deliveryForm.tracking_id)}
                </div>
                <div id="item-summary">
                  <ItemTitle>
                    Item Details
                    <ItemDescription>
                      Add item information and the amount of each item.
                    </ItemDescription>
                  </ItemTitle>
                  <ItemList>
                    {deliveryForm.items.map((item, j) => {
                      const itemDescription = deliveryForm.items[j].name || '';
                      const isMinQty = deliveryForm.items[j].quantity <= 1;
                      const canRemoveItemByQty = isMinQty && itemDescription !== '';
                      const canRemoveItem = itemDescription !== '' || j !== 0;
                      return (
                        <Li key={j}>
                          <span className="list-item-wrapper">
                            <ItemFieldsWrapper>
                              <ItemNumber
                                className="item-number"
                                index={j}
                                marginTop="33px"
                                marginRight="10px"
                                marginLeft={j + 1 >= 10 ? '-10px' : '0px'}
                                mobileMarginTop={'35px'}
                              >
                                {j + 1}.
                              </ItemNumber>
                              <ItemDetailsContainer>
                                <div>
                                  <ItemFieldName onlyShowOnMobile={j > 0}>
                                    Description
                                  </ItemFieldName>
                                  <TextInput
                                    autoComplete="none"
                                    containerStyle={css`
                                      min-width: 280px;
                                    `}
                                    fieldName=""
                                    onTextChange={(value: string): void => {
                                      updateDeliveryForm(`items[${j}].name`, value, i);
                                    }}
                                    placeholder="E.g Parcel, Box"
                                    value={item.name}
                                    disabled={
                                      isEditableField && !isEditableField('items')
                                    }
                                  />
                                </div>

                                <div>
                                  <ItemFieldName onlyShowOnMobile={j > 0}>
                                    Qty
                                  </ItemFieldName>
                                  <CounterInputButton
                                    value={item.quantity}
                                    incrementValue={1}
                                    decrementValue={1}
                                    defaultEmptyValue={item.weight ? 1 : ''}
                                    onValueChange={(value): void => {
                                      if (canRemoveItem && Number(value) < 1) {
                                        removeItemClick(j);
                                      } else {
                                        updateDeliveryForm(
                                          `items[${j}].quantity`,
                                          value,
                                          i
                                        );
                                      }
                                    }}
                                    isAllFieldsDisabled={
                                      isEditableField && !isEditableField('items')
                                    }
                                    isDiscourageMinusButton={canRemoveItemByQty}
                                    isMinusButtonDisabled={
                                      isMinQty && !canRemoveItemByQty
                                    }
                                  />
                                </div>

                                <div>
                                  <ItemFieldName onlyShowOnMobile={j > 0}>
                                    Weight (kg)
                                  </ItemFieldName>

                                  <CounterInputButton
                                    value={item.weight}
                                    incrementValue={1}
                                    decrementValue={1}
                                    isZeroNumberAllowed={true}
                                    isFloatNumberAllowed={true}
                                    onValueChange={(value: string): void => {
                                      updateDeliveryForm(`items[${j}].weight`, value, i);
                                    }}
                                    isMinusButtonDisabled={
                                      deliveryForm.items[j].weight <= 1
                                    }
                                    isAllFieldsDisabled={
                                      isEditableField && !isEditableField('items')
                                    }
                                  />
                                </div>

                                <div>
                                  <InputDimensions
                                    isShowTotalVolume={this.props.ops}
                                    totalVolumeStyles={
                                      this.props.ops &&
                                      css`
                                        @media (min-width: 1261px) {
                                          position: absolute;
                                          bottom: -23px;
                                        }
                                      `
                                    }
                                    inputLabel={
                                      <ItemFieldName onlyShowOnMobile={j > 0}>
                                        Dimensions (L x W x H) per item (cm)
                                      </ItemFieldName>
                                    }
                                    isFloatNumberAllowed={true}
                                    lengthValue={item.dimension.length}
                                    widthValue={item.dimension.width}
                                    heightValue={item.dimension.height}
                                    onLengthChange={(value) =>
                                      updateDeliveryForm(
                                        `items[${j}].dimension.length`,
                                        value,
                                        i
                                      )
                                    }
                                    onWidthChange={(value) =>
                                      updateDeliveryForm(
                                        `items[${j}].dimension.width`,
                                        value,
                                        i
                                      )
                                    }
                                    onHeightChange={(value) =>
                                      updateDeliveryForm(
                                        `items[${j}].dimension.height`,
                                        value,
                                        i
                                      )
                                    }
                                    disabled={
                                      isEditableField && !isEditableField('items')
                                    }
                                  />
                                </div>

                                <CheckBoxFullWidthContainer>
                                  <CheckBoxItemWrapper index={j}>
                                    <Checkbox
                                      onClick={(): void => {
                                        updateDeliveryForm(
                                          `items[${j}].has_hazard_mat`,
                                          !deliveryForm.items[j].has_hazard_mat,
                                          i
                                        );
                                      }}
                                      selected={deliveryForm.items[j].has_hazard_mat}
                                      disabled={
                                        isEditableField && !isEditableField('items')
                                      }
                                    >
                                      <span style={{ fontSize: '14px' }}>
                                        Hazardous Material
                                      </span>
                                    </Checkbox>
                                  </CheckBoxItemWrapper>
                                </CheckBoxFullWidthContainer>

                                {this.props.page === 'new' && canRemoveItem ? (
                                  <RemoveItemSummaryWrapper index={j}>
                                    <RemoveItemButton
                                      buttonType="link"
                                      buttonStyle="discourage"
                                      fontWeight={400}
                                      icon={<TrashIcon icon={faTrash} />}
                                      onClick={() => removeItemClick(j)}
                                    >
                                      Remove
                                    </RemoveItemButton>
                                  </RemoveItemSummaryWrapper>
                                ) : (
                                  <>
                                    {isEditableField && isEditableField('items') && (
                                      <RemoveItemSummaryWrapper index={j}>
                                        <RemoveItemButton
                                          buttonType="link"
                                          buttonStyle="discourage"
                                          fontWeight={400}
                                          icon={<TrashIcon icon={faTrash} />}
                                          onClick={() => removeItemClick(j)}
                                        >
                                          Remove
                                        </RemoveItemButton>
                                      </RemoveItemSummaryWrapper>
                                    )}
                                  </>
                                )}
                              </ItemDetailsContainer>
                            </ItemFieldsWrapper>
                          </span>
                        </Li>
                      );
                    })}
                  </ItemList>
                  <Button
                    buttonType="neutral"
                    buttonStyle="encourage"
                    onClick={addItemClick}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    type="button"
                    disabled={isEditableField && !isEditableField('items')}
                  >
                    Add Item
                  </Button>
                </div>
              </>
            )}
            {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
              <>
                {this.renderMarineVehicleForm()}
                <div style={{ marginTop: '0.75rem' }}>
                  <TextInput
                    autoComplete="none"
                    inputName="tracking-id"
                    containerStyle={inlineTextInputStyle}
                    fieldName={this.renderReferenceIDLabel()}
                    leftLabel={prefixID}
                    onTextChange={(value: string): void => {
                      updateDeliveryForm('tracking_id', value, i);
                    }}
                    placeholder="4297"
                    width="medium"
                    value={deliveryForm.tracking_id}
                    disabled={isEditableField && !isEditableField('tracking_id')}
                  />
                  {this.props.ops && (
                    <>
                      <TextInput
                        autoComplete="none"
                        inputName="invoice-number"
                        containerStyle={inlineTextInputStyle}
                        fieldName="Invoice No."
                        disabled={!isEditableField('invoice_number')}
                        onTextChange={(value: string): void => {
                          updateDeliveryForm('invoice_number', value, i);
                        }}
                        placeholder="GOT934298"
                        width="small"
                        value={deliveryForm.invoice_number}
                      />
                      <TextInput
                        autoComplete="none"
                        inputName="group-tag"
                        containerStyle={inlineTextInputStyle}
                        fieldName="Group Tag"
                        disabled={!isEditableField('group_tag')}
                        onTextChange={(value: string): void => {
                          updateDeliveryForm('group_tag', value, i);
                        }}
                        width="small"
                        value={deliveryForm.group_tag}
                      />
                    </>
                  )}
                  {showReferenceIDModal && this.renderReferenceIDModal()}
                  {this.renderReferenceID(prefixID, deliveryForm.tracking_id)}
                </div>
              </>
            )}
          </Content>
        </OrderCardRow>
        {showDashedLine && (
          <OrderCardRow>
            <DashedLine />
            <AddressSeparator />
          </OrderCardRow>
        )}
        {this.renderAddressContactModal()}
        {showRemoveDeliveryDetailsModal && this.renderRemoveDeliveryDetailsWarningModal()}
      </section>
    );
  }
}

export default DeliveryForm;
