import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { FlattenSimpleInterpolation, css, keyframes } from 'styled-components';
import { StyledButton, Alert, Separator as BasicSeparator } from 'components';
import COLOR, { transparent } from 'constants/color';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';

interface IconProps {
  color: string;
}

interface ColumnProps {
  columnStyle?: FlattenSimpleInterpolation;
  flex: string;
}

interface RemoveCargoDetailsIconProps {
  color?: string;
  fontSize?: string;
}

export const attachmentLabelStyle = css`
  padding-bottom: 0;
  padding-left: 0;
  letter-spacing: 0.32px;
`;

export const noMarginLeft = css`
  margin-left: 0;
`;

export const CustomCard = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${COLOR.neutral};
  padding: 1rem;
  background-color: ${COLOR.white};
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const CardRow = styled.div`
  display: flex;

  & > * {
    margin-right: 1rem;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.25rem 0 1.25rem 0;
`;

export const Icon = styled(FontAwesomeIcon)<IconProps>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.375rem;
`;

export const BulletPoint = styled(FontAwesomeIcon)`
  font-size: 6px;
  margin-left: 4px;
  margin-right: 4px;
`;

export const Description = styled.div`
  font-size: 0.875rem;
`;

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props): string => props.flex};

  ${(props): FlattenSimpleInterpolation => props.columnStyle}
`;

export const Container = styled.div`
  display: flex;
  margin-top: 1rem;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  color: ${COLOR.darkGray};
`;

export const CancelButton = styled(StyledButton)`
  margin-left: 0.5rem;
`;

export const Prompt = styled.p`
  margin-bottom: 1.5rem;
`;

export const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

export const Row = styled.div`
  margin-bottom: 1.5rem;
`;

export const Field = styled.div`
  margin-bottom: 1rem;
`;

export const SquadIconContainer = styled.span`
  display: inline-flex;
  justify-content: center;
  margin-right: 0.3rem;
  background-color: ${COLOR.cyan};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-radius: 20px;
`;

export const SquadIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  color: ${COLOR.darkGray};
`;

export const ActionButton = styled(StyledButton)`
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
`;

// Define the animation keyframes
const fadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CardContainer = styled.div`
  animation: ${fadeAnimation} 0.3s ease-in-out;
  display: flex;
  margin-top: 1rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledLink = styled(Link)`
  color: ${COLOR.blue};
  cursor: pointer;
  text-decoration: none;
`;

export const ActionButtons = styled.div`
  margin-bottom: 1.5rem;
`;

export const FieldInfo = styled.div`
  font-size: 0.8rem;
  color: ${COLOR.midDarkGrey};
`;

export const CustomLink = styled.a`
  font-size: 0.8rem;
  text-decoration: underline;
  color: ${COLOR.blue};
  cursor: pointer;
`;

export const ActionButtonsContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  height: 2.2rem;
`;

export const DropdownButton = styled(StyledButton)`
  border-width: 0;
  background-color: ${COLOR.white};
  padding: 0 0;
  height: 2.75rem;

  :hover {
    background-color: ${COLOR.white};
    color: ${(props): string => (props.disabled ? COLOR.grey : COLOR.midDarkGrey)};
    border: none;
  }

  :focus {
    box-shadow: none;
  }

  :disabled {
    border: none;
  }
`;

export const DropdownButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 0.375rem;
`;

export const AlertMessage = styled(Alert)`
  margin-bottom: 0.4rem;
`;

export const Required = styled.span`
  color: ${COLOR.red};
  margin-left: 0.2rem;
`;

export const BoldText = styled.div`
  font-weight: 600;
`;

interface StackProps {
  gap?: string;
  direction?: string;
  wrap?: boolean;
  mdScreenStyle?: FlattenSimpleInterpolation;
}

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-wrap: ${(props): string => (props.wrap ? 'wrap' : 'nowrap')};
  flex-direction: ${(props): string => (props.direction ? props.direction : 'row')};
  gap: ${(props): string => props.gap && props.gap};
  @media (min-width: 768px) {
    ${(props): FlattenSimpleInterpolation => props.mdScreenStyle}
  }
`;

interface ColumnItemProps {
  mdScreenStyle?: FlattenSimpleInterpolation;
  order?: number;
}

export const ColumnItem = styled.div<ColumnItemProps>`
  order: ${(props): number => props.order && props.order};
  @media (min-width: 768px) {
    ${(props): FlattenSimpleInterpolation => props.mdScreenStyle}
    order: 0;
  }
`;

export const Card = styled.div`
  border-radius: 0.75rem;
  box-shadow: 0px 1px 1px 2px rgba(222, 222, 222, 0.06),
    0px 2px 6px 0px ${(props): string => props.theme.colors.gray_200};

  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
`;

export const CardWrapper = styled.div`
  @media (min-width: 767px) {
    max-width: 220px;
    min-width: 220px;
  }
`;

export const BorderIconWrapper = styled(StyledButton)`
  width: 36px;
  height: 36px;
  border-radius: 0.5rem;
  border: 1px solid ${(props): string => props.theme.colors.gray_300};
`;

interface DividerProps {
  isHidden?: boolean;
  marginTopBottom?: string;
  marginBottom?: string;
}

export const Divider = styled.hr<DividerProps>`
  margin: 0;
  height: 1px;
  width: 100%;
  border: none;
  background-color: ${(props): string => props.theme.colors.gray_200};
  visibility: ${(props): string => props.isHidden && 'hidden'};
  margin: ${(props): string =>
    props.marginTopBottom && `${props.marginTopBottom} 0 ${props.marginTopBottom} 0`};
  margin-bottom: ${(props): string => props.marginBottom && props.marginBottom};
`;

interface IconGrayish {
  isGray?: boolean;
  fontSize?: string;
}

export const IconGrayish = styled(FontAwesomeIcon)<IconGrayish>`
  color: ${(props): string =>
    props.isGray ? props.theme.colors.gray_500 : props.theme.colors.gray_700};
  font-size: ${(props): string => props.fontSize && props.fontSize};
`;

export const SuperScript = styled.span<{ lineHeight?: string }>`
  line-height: 131%;
  line-height: ${({ lineHeight }): string => (lineHeight ? lineHeight : '131%')};
`;

export const IsTestLabel = styled.span`
  border-radius: 1rem;
  background-color: ${(props): string => props.theme.colors.pink_50};
  color: ${(props): string => props.theme.colors.pink_700};
  padding: 0.125rem 0.5rem;
`;

export const LabelGray = styled.span`
  background-color: ${(props): string => props.theme.colors.gray_100};
  color: ${(props): string => props.theme.colors.gray_700};
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  display: inline-block;
`;

export const OrderInfoContainer = styled(Stack)`
  padding-bottom: 1rem;
  gap: 1rem;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 0;
  }
`;

export const PickupDeliveryContainer = styled(Stack)`
  @media (min-width: 768px) {
    flex-basis: 100%;
    padding: 1rem;
  }
`;

export const PickupDeliveryDetailWrapper = styled(Stack)`
  padding-bottom: 2rem;
`;

export const AddressViewWrapper = styled(Stack)`
  font-size: ${(props): string => props.theme.fontSizes.md};
`;

export const IdAndStatusWrapper = styled(Stack)`
  align-items: center;
`;

export const VesselWrapper = styled(Stack)`
  margin-bottom: 0.35rem;
`;

export const CollapsibleText = styled.span`
  font-size: ${(props): string => props.theme.fontSizes.sm};
  color: ${(props): string => props.theme.colors.primary_600};
  text-decoration: underline;
  align-self: flex-start;
  cursor: pointer;
`;

export const OrderCard = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${(props): string => props.theme.colors.gray_200};
  background-color: ${(props): string => props.theme.colors.base_white};
  box-shadow: 0px 2px 6px 0px ${(props): string => props.theme.colors.gray_200},
    0px 1px 1px 2px rgba(222, 222, 222, 0.06);
  padding: 1.875rem 2.125rem;
  margin-bottom: 1.75rem;

  @media screen and (max-width: 600px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`;

export const OrderTitle = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

export const OrderIcon = styled(FontAwesomeIcon)<{ color?: string; fontSize?: string }>`
  color: ${(props): string => props.color};
  font-size: ${(props): string => (props.fontSize ? props.fontSize : '0.75rem')};
  margin-right: 0.5rem;
  align-self: center;
`;

export const PriceInfoCard = styled(OrderCard)`
  margin-bottom: 6rem;
`;

export const TrashIcon = styled(FontAwesomeIcon)`
  margin-right: 0rem;
`;

export const RemoveCargoDetailsIcon = styled(
  FontAwesomeIcon
)<RemoveCargoDetailsIconProps>`
  color: ${(props): string => props.color};
  font-size: ${(props): string => (props.fontSize ? props.fontSize : '1rem')};
`;

export const RemoveCargoDetailsSubTitle = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const RemoveItemButton = styled(StyledButton)`
  font-size: ${(props): string => props.theme.fontSizes.xs};
  border-width: 0;
  background-color: ${COLOR.white};
`;

export const RemoveDeliveryLocationButton = styled(StyledButton)`
  border-width: 0;
  visibility: hidden;
  section:hover & {
    visibility: visible;
    background-color: ${COLOR.white};
  }

  @media (max-width: 768px) {
    visibility: visible;
    background-color: ${COLOR.white};
  }
`;

export const CircleIcon = styled(FontAwesomeIcon)<{ color: string }>`
  color: ${(props): string => props.color};
  font-size: 0.5rem;
  margin-right: 0.5rem;
  align-self: center;
`;

export const Separator = styled(BasicSeparator)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const AddressSeparator = styled(Separator)`
  margin-left: 1.5rem;
  width: 100%;
`;

export const OrderCardRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AlphabetContainer = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 1.5rem;
  margin-right: 0.75rem;
  color: ${COLOR.darkGray};

  /* @media (max-device-width: 700px), (max-width: 700px) {
    margin-left: 0.5rem;
    margin-right: 0.25rem;
  } */

  @media (max-width: 769px) {
    margin-left: 0.75rem;
  }
`;

export const Content = styled.div`
  flex: 1;
  margin-left: 3rem;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-right: 0rem;
  display: flex;
  flex-direction: column;
  width: 90%;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  /* @media (max-device-width: 700px), (max-width: 700px) {
    margin-left: 0;
    padding-left: 1rem;
  } */

  @media (max-width: 769px) {
    margin-left: 0;
  }
`;

export const PriceContent = styled(Content)`
  margin-left: 0;

  @media (max-device-width: 700px), (max-width: 700px) {
    padding-left: 0rem;
  }
`;

export const AdditionalServiceContent = styled(Content)`
  padding-top: 0;
  padding-bottom: 1rem;
  margin-left: 0;
  padding-left: 1.7rem;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`;

export const TitleDescription = styled.div`
  color: ${COLOR.midDarkGrey};
`;

export const OrderHeading = styled.div`
  margin-right: auto;
`;

export const ItemTitle = styled.div`
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const FormErrorAlert = styled(Alert)`
  margin-top: 0.5rem;
`;

export const AttachmentFormErrorAlert = styled(Alert)`
  margin-top: 0.5rem;
  max-width: 14rem;
`;

export const RedText = styled.span`
  color: ${COLOR.red};
`;

export const Button = styled(StyledButton)`
  margin-right: 1rem;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

export const InstructionsLabel = styled.div`
  display: flex;
  align-items: end;
`;

export const ReferenceIDLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const ReferenceIDInfoButton = styled(StyledButton)`
  padding: 0 0.3rem;
  border-width: 0;
  div:hover & {
    background-color: ${COLOR.white};
  }
`;

export const RecommendedBadge = styled.div`
  font-weight: 600;
  font-size: 0.8rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.3rem;
  border-radius: 6px;
  color: ${COLOR.black};
  background-color: ${COLOR.lightGrey};
`;

export const CheckboxRememberPrice = styled.div`
  margin-top: 1.5rem;
  margin-left: 1.5rem;

  @media (max-device-width: 700px), (max-width: 700px) {
    margin-left: 0rem;
  }
`;

export const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  margin-right: 0.35rem;
`;

export const InlineErrorMessage = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

export const RecommendPriceContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const RecommendPriceAlert = styled(Alert)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const RecommendPriceText = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`;

export const RecommendPriceHelp = styled.div`
  color: ${COLOR.midDarkGrey};
  font-size: 0.875rem;
`;

export const RecommendPriceButton = styled(Button)`
  margin-left: 1rem;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const TextBold = styled.div`
  font-weight: 600;
`;

export const ActionButtonContainer = styled.div`
  padding: 1rem 3rem;

  @media (max-width: 768px) {
    padding: 1rem 1rem 1rem 2rem;
    display: flex;
    justify-content: space-between;
  }
`;

export const ActionContainer = styled.section`
  background-color: ${COLOR.whiteGrey};
  box-shadow: 0px 8px 18px ${transparent('shadow', 80)};
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 14;

  @media (max-width: 768px) {
    padding-bottom: 3rem;
    height: 1.5rem;
  }
`;

export const ItemList = styled.ol`
  /* padding-inline-start: 1.5em; */
  padding-left: 0rem;
  @media screen and (max-width: 768px) {
    padding-left: 1.5rem;
  }
  & > li {
    margin-bottom: 1rem;
  }
`;

export const ConfirmOrderModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const ConfirmOrderModalText = styled.div`
  margin-left: 0.5rem;
`;

export const ErrorModalText = styled.div`
  padding-top: 0.5rem;
`;

export const AdditionalServiceCard = styled(OrderCard)`
  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const DateAndTimeContainer = styled.div<{ alignItems?: string }>`
  display: flex;
  margin-bottom: 1px;
  align-items: ${(props): string => props.alignItems ?? 'flex-end'};
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DatePickerWrapper = styled(({ className, ...props }) => (
  <DatePicker {...props} wrapperClassName={className} />
))<{ width?: string; marginRight?: string }>`
  display: flex;
  align-items: flex-end;
  margin-right: ${(props): string => props.marginRight || '0px'};
  width: ${(props): string => props.width || '11rem'};
  /* @media (max-width: 768px) {
    width: 100%;
  } */
`;

export const LabelDescription = styled.div`
  margin-bottom: 0.5rem;
`;

export const Span = styled.span`
  font-size: 0.8rem;
  color: gray;
`;

export const FileSectionContainer = styled.div`
  flex-direction: column;
`;

export const SectionContainer = styled.div`
  margin-bottom: 1rem;
  flex-wrap: wrap;
  display: flex;
`;

export const EnterManualEmptyPlaceholderButton = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
  width: 7rem;
  margin-top: 0.1rem;
`;

export const EnterManualButton = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
  width: 7rem;
  margin-top: 0.5rem;
`;

export const ImoText = styled.div`
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
`;

export const ImoInfoAlert = styled(Alert)`
  max-width: 24rem;
`;

export const EmptyVesselPlaceholder = styled.div`
  background-color: ${COLOR.white};
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLOR.blue};
  color: ${COLOR.black};
  outline: none;
  overflow-y: auto;
  max-height: 15rem;
  padding: 0.8rem;
  z-index: 1;
`;

export const VesselCardRow = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    display: flex;
  }
`;

export const ItemFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const ItemFieldContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-end;
  position: relative;
  gap: 0 10px;
  @media (max-width: 768px) {
    align-items: inherit;
    flex-direction: column;
    gap: 14px;
  }
`;

export const ItemDetailsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-end;
  position: relative;
  gap: 16px 16px;
  max-width: 978px;
  margin-bottom: 10px;

  @media (min-width: 920px) and (max-width: 1260px) {
    max-width: 632px;
  }

  @media (min-width: 768px) and (max-width: 919px) {
    max-width: 500px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ItemNumber = styled.div<{
  index: number;
  marginTop?: string;
  marginRight?: string;
  marginLeft?: string;
  mobileMarginTop?: string;
}>`
  display: inline-flex;
  margin-right: ${(props): string => props.marginRight || '0.5rem'};
  margin-left: ${(props): string => props.marginLeft || '0'};
  margin-bottom: 1rem;
  margin-top: ${(props): string =>
    props.marginTop && props.index === 0
      ? props.marginTop
      : props.marginTop && props.index > 0
      ? '10px'
      : props.index > 0
      ? '12px'
      : '32px'};
  @media screen and (max-width: 639px) {
    position: absolute;
    left: -25px;
    ${(props): string =>
      props.mobileMarginTop ? `margin-top: ${props.mobileMarginTop};` : ''}
  }
`;

export const Li = styled.li`
  list-style: none;

  @media (max-width: 600px) {
    .list-item-wrapper {
      position: relative;
    }
  }
`;

export const ItemFieldName = styled.p<{ onlyShowOnMobile?: boolean }>`
  font-size: 0.875rem;
  margin: 0 0 6px 0;

  ${(props): string =>
    props.onlyShowOnMobile
      ? `
        display: none;
        @media (max-width: 768px) {
          display: block;
        }
      `
      : ''}
`;

interface ItemFieldProps {
  flex: number | string;
  flexDirection?: string;
  marginBottom?: string;
  withMarginLeft?: string;
  withMarginRight?: string;
  maxWidth?: string;
}

export const ItemField = styled.div<ItemFieldProps>`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: ${(props): string =>
    props.flexDirection ? props.flexDirection : 'flex-start'};
  margin-bottom: ${(props): string => (props.marginBottom ? props.marginBottom : '0')};
  max-width: ${(props): string => (props.maxWidth ? props.maxWidth : '100%')};

  @media screen and (min-width: 768px) {
    /* align-self: flex-end; */
    margin-bottom: 0;
    margin-left: ${(props): string =>
      props.withMarginLeft ? props.withMarginLeft : '0'};
    margin-right: ${(props): string =>
      props.withMarginRight ? props.withMarginRight : '0'};
    flex: ${(props): number | string => props.flex};
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const CheckBoxWrapper = styled.div<{ index: number }>`
  white-space: nowrap;
  @media (min-width: 768px) {
    margin-left: 1.5rem;
  }
`;

export const CheckBoxFullWidthContainer = styled.div`
  width: 100%;
`;
export const CheckBoxItemWrapper = styled.div<{ index: number }>`
  white-space: nowrap;
`;

interface RemoveCargoDetailItemWrapperProps {
  index: number;
  isTwoRow: boolean;
}

export const RemoveCargoDetailItemWrapper = styled.div<RemoveCargoDetailItemWrapperProps>`
  @media (min-width: 768px) {
    margin-left: 0.75rem;
    align-self: ${(props): string => (props.isTwoRow ? 'self-start' : 'center')};
    position: absolute;
    right: 16px;
    top: 34px;
    ${(props): FlattenSimpleInterpolation => {
      if (!props.isTwoRow && props.index > 0) {
        return css`
          /* margin-top: 0; */
          top: 13px;
        `;
      } else if (props.isTwoRow && props.index > 0) {
        return css`
          /* margin-top: 6px; */
          top: 12px;
        `;
      } else {
        return css`
          /* margin-top: 21px; */
          top: 34px;
        `;
      }
    }}
  }
`;

export const RemoveItemSummaryWrapper = styled.div<{ index: number }>`
  display: inline-flex;
  margin-bottom: 10px;
  margin-top: 8px;
  @media (min-width: 769px) {
    margin-top: 0;
    position: absolute;
    right: -5.2rem;
    top: ${(props): string => (props.index === 0 ? '2.2rem' : '0.8rem')};
  }
  @media (min-width: 600px) and (max-width: 768px) {
    margin-top: 0;
    position: absolute;
    right: -5.2rem;
    top: 2.2rem;
  }
`;

export const ItemDescription = styled.p`
  color: ${(props): string => props.theme.colors.gray_400};
  font-size: ${(props): string => props.theme.fontSizes.xs};
  margin-top: 0.45rem;
  font-weight: 400;
`;

export const TotalCargoContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 680px;
  align-items: center;
`;

export const TotalCargo = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
`;

export const Lifts = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

export const LiftInfoIcon = styled(FontAwesomeIcon)`
  color: ${(props): string => props.theme.colors.primary_600};
`;

export const SubTitle = styled.div`
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  margin-top: 0.2rem;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 0;
`;

export const CustomLinkButton = styled.a`
  display: inline-block;
`;

export const inlineDropdownStyle = css`
  display: inline-block;
  margin-left: 0;
  margin-right: 1rem;
  margin-top: 0.25rem;
`;

export const SelectContactButton = styled(StyledButton)`
  display: flex;
  flex-direction: row;
  height: fit-content;
  padding: 1rem;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

export const SelectContactText = styled.div`
  width: 100%;
  text-align: center;
`;

export const Calendar = styled.div`
  position: inherit;
`;

export const PickupCardRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BottomMargin = styled.div`
  margin-bottom: 6rem;
`;

export const DeliveryLocationPanel = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
