import { PriceAdjustmentActionTypes, PRICE_ADJUSTMENT_LOGS, PRICE_ADJUSTMENTS, PRICE_ADJUSTMENT } from './priceAdjustmentTypes';
import { PriceAdjustment, PriceAdjustmentLog } from 'models/priceAdjustment';

export function receivePriceAdjustmentAllSuccess(priceAdjustments: PriceAdjustment[]): PriceAdjustmentActionTypes {
  return {
    type: PRICE_ADJUSTMENTS,
    priceAdjustments,
  };
}

export function receivePriceAdjustment(priceAdjustment: PriceAdjustment): PriceAdjustmentActionTypes {
  return {
    type: PRICE_ADJUSTMENT,
    priceAdjustment,
  };
}

export function receivePriceAdjustmentLogAllSuccess(priceAdjustmentLogs: PriceAdjustmentLog[]): PriceAdjustmentActionTypes {
  return {
    type: PRICE_ADJUSTMENT_LOGS,
    priceAdjustmentLogs,
  };
}