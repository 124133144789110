import { RunsheetReadyToBroadcastState, RunsheetReadyToBroadcastActionTypes, RECEIVE_RUNSHEET_READY_TO_BROADCASTS, RECEIVE_RUNSHEET_READY_TO_BROADCAST } from './runsheetReadyToBroadcastTypes';

const initialState: RunsheetReadyToBroadcastState = {
  runsheetReadyToBroadcasts: [],
  runsheetReadyToBroadcast: null,
};

function runsheetReadyToBroadcastReducer(
  state: RunsheetReadyToBroadcastState = initialState, action: RunsheetReadyToBroadcastActionTypes
): RunsheetReadyToBroadcastState {
  switch (action.type) {
    case RECEIVE_RUNSHEET_READY_TO_BROADCASTS:
      return { ...state, runsheetReadyToBroadcasts: action.runsheetReadyToBroadcasts };
    case RECEIVE_RUNSHEET_READY_TO_BROADCAST:
      return { ...state, runsheetReadyToBroadcast: action.runsheetReadyToBroadcast };
    default:
      return state;
  }
}

export default runsheetReadyToBroadcastReducer;
