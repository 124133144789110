import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { Card, StyledButton, Alert } from 'components';
import COLOR from 'constants/color';
import { Link } from 'react-router-dom';

interface ColumnProps {
  columnStyle?: FlattenSimpleInterpolation;
  flex: string;
}

interface IconProps {
  disabled?: boolean;
  color: string;
}

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  color: ${COLOR.darkGray};
  margin-bottom: 1.5rem;
`;

export const Icon = styled(FontAwesomeIcon) <IconProps>`
  color: ${(props): string => props.disabled ? COLOR.grey : props.color};
  align-self: center;
  margin-right: 0.375rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const ActionButton = styled(StyledButton)`
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
`;

export const TimestampField = styled.div`
  & > :not(:last-child) {
    margin-bottom: 0.2rem;
  };
`;

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props): string => props.flex};

  ${(props): FlattenSimpleInterpolation => props.columnStyle}
`;

export const CardContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CustomCard = styled(Card)`
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: ${COLOR.white};
  margin-right: 0;
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  };
`;

export const CardRow = styled.div`
  display: flex;
`;

export const AddOrderButton = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const OrderField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  &:last-child {
    padding-bottom: 0.8rem;
    margin-bottom: 0;
    border-bottom: 1px solid ${COLOR.midWhiteGrey};
  }
`;

export const RemoveButton = styled(StyledButton)`
  margin-right: 0.5rem;
  border-width: 0;
  background-color: ${COLOR.white};

  :hover {
    background-color: ${COLOR.white};
  }
`;

export const StyledLink = styled(Link)`
  color: ${COLOR.blue};
  cursor: pointer;
  text-decoration: none;
`;

export const RunsheetId = styled.div`
  font-size: 0.75rem;
  color: ${COLOR.midDarkGrey};
`;

export const FieldInfo = styled.div`
  font-size: 0.8rem;
  color: ${COLOR.midDarkGrey};
`;

export const ModalTextContainer = styled.div`
  margin-bottom: 2rem;
`;

export const ModalText = styled.div`
  line-height: 1.25rem;
  margin-bottom: 1rem;
`;

export const Prompt = styled.p`
  margin-bottom: 1.5rem;
`;

export const ErrorMessage = styled.div`
  color: ${COLOR.red};
  margin: 0.625rem 0;
`;

export const CustomLink = styled.a`
  font-size: 1rem;
  text-decoration: none;
  color: ${COLOR.blue};
  cursor: pointer;
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${COLOR.cyan};
  margin-right: 0.3rem;
`;

export const Button = styled(StyledButton)`
  margin-right: 0.5rem;
  flex: none;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const TopButtonContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

export const OrderHeading = styled.div`
  font-size: 1rem;
`;

export const OrderText = styled.div`
  color: ${COLOR.darkGray};
`;

export const Row = styled.div`
  margin-bottom: 1.5rem;
`;

export const Field = styled.div`
  margin-bottom: 1rem;
`;

export const Label = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
  margin-top: -0.5rem;
  display: flex;
`;

export const LabelButton = styled.div<{ disabled?: boolean }>`
  color: ${(props): string => props.disabled ? COLOR.grey : COLOR.blue};
  cursor: ${(props): string => props.disabled ? 'default' : 'pointer'};
  font-size: 1rem;
  margin-left: 0.3rem;
  text-decoration: underline;
`;

export const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

export const DropdownButton = styled(StyledButton)`
  border-width: 0;
  background-color: ${COLOR.white};
  padding: 0 0;
  height: 2.75rem;
  border: none;

  :hover {
    background-color: ${COLOR.white};
    color: ${(props): string => props.disabled ? COLOR.grey : COLOR.midDarkGrey};
    border: none;
  }

  :focus {
    box-shadow: none;
  }

  :disabled {
    border: none;
  }
`;

export const DropdownButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 0.375rem;
`;

export const ActionButtonsContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  height: 2.2rem;
`;

export const AlertMessage = styled(Alert)`
  margin-bottom: 0.4rem;
`;
