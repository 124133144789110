import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CenterModal, TextInput, ProgressBar, Alert } from 'components';
import { Task } from 'models/task';
import TaskClient from 'httpClients/taskClient';
import COLOR from 'constants/color';

const STATE = {
  beforeUpdate: 'beforeUpdate',
  updating: 'updating',
  afterUpdate: 'afterUpdate',
};

interface UpdatePriceModalProps {
  closeModal: () => void;
  orders: Task[];
}

interface UpdatePriceModalState {
  completedCount: number;
  currency: string;
  error: {
    id: string;
    message: string;
  }[];
  pageState: string;
  price: number;
}

class UpdatePriceModal extends React.Component<UpdatePriceModalProps, UpdatePriceModalState> {
  constructor(props: UpdatePriceModalProps) {
    super(props);

    this.state = {
      completedCount: 0,
      currency: 'SGD',
      error: [],
      pageState: STATE.beforeUpdate,
      price: 0,
    };
  }

  updatePrice = async (): Promise<void> => {
    this.setState({ pageState: STATE.updating });

    const client = new TaskClient();

    for(let i = 0; i < this.props.orders.length; i++) {
      const order = this.props.orders[i];

      try{
        await client.sysAdminUpdateTaskPrice(order.id, {
          price: this.state.price,
          currency: this.state.currency,
          version_rev: order.version_rev,
        });

        this.setState(prevState => ({ completedCount: prevState.completedCount + 1 }));
      } catch (e) {
        this.setState(prevState => {
          const newErrors = [ ...prevState.error ];
          newErrors.push({
            id: order.id.split('-')[0],
            message: e,
          });

          return { error: newErrors };
        });
      }
    }

    this.setState({ pageState: STATE.afterUpdate });
  }

  renderBeforeUpdate = (): React.ReactNode => (
    <CenterModal
      leftButtonOnClick={this.props.closeModal}
      leftButtonText="Cancel"
      rightButtonOnClick={(): void => { this.updatePrice(); }}
      rightButtonText="Update Price"
      title="Update Price"
    >
      <Input
        fieldName="New Currency"
        onTextChange={(value): void => { this.setState({ currency: value }); }}
        width="half"
        value={this.state.currency}
      />
      <Input
        fieldName="New Price"
        onTextChange={(value): void => { this.setState({ price: parseInt(value) }); }}
        width="half"
        type="number"
        value={this.state.price}
      />
      <Description>
        <div>
          The new currency and price will replace existing prices.
        </div>
        <div>
          Price of assigned Orders cannot be changed.
        </div>
        <div>
          Review your selection before clicking Update.
        </div>
      </Description>
    </CenterModal>
  )

  renderUpdating = (): React.ReactNode => (
    <CenterModal
      title="Update Price"
    >
      <ProgressBar
        completeCount={this.state.completedCount}
        totalCount={this.props.orders.length || 1}
      />
      <ProgressDescription>
        Updated {this.state.completedCount} of {this.props.orders.length} Orders
      </ProgressDescription>
    </CenterModal>
  )

  renderAfterUpdate = (): React.ReactNode => (
    <CenterModal
      rightButtonOnClick={this.props.closeModal}
      rightButtonText={
        <>
          <Icon icon={faCheck} />
          OK
        </>
      }
      title="Update Price"
    >
      {
        this.state.error.length > 0 ? (
          <>
            <Alert status="error">
              {this.props.orders.length - this.state.completedCount} of {this.props.orders.length} Orders cannot be updated.
            </Alert>
            {
              this.state.error.map(error => (
                <Description key={error.id}>
                  {error.id} - {error.message}
                </Description>
              ))
            }
          </>
        ) : (
          <Alert status="success">
            Price updated for {this.state.completedCount} Orders.
          </Alert>
        )
      }
    </CenterModal>
  )

  render(): React.ReactNode {
    let content: React.ReactNode = this.renderBeforeUpdate();
    if (this.state.pageState === STATE.updating) {
      content = this.renderUpdating();
    } else if (this.state.pageState === STATE.afterUpdate) {
      content = this.renderAfterUpdate();
    }

    return (
      content
    );
  }
}

const Description = styled.div`
  font-size: 0.75rem;
  margin-top: 1.5rem;
`;

const ProgressDescription = styled.div`
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;

const Input = styled(TextInput)`
  margin-bottom: 0.25rem;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${COLOR.darkGray};
  font-size: 0.75rem;
  margin-right: 0.125rem;
`;

export default UpdatePriceModal;