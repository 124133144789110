import * as React from 'react';
import { Button, TextField, Message } from 'components';
import styled from 'styled-components';
import * as H from 'history';
import AuthClient from 'httpClients/authClient';
import { UserInvitationForm } from 'models/auth';
import { formatError } from 'utils/formatter';

interface UserInvitationProps<S = H.LocationState> {
  history: H.History<S>;
}

interface UserInvitationState {
  form: {
    email: string;
  };
  error: string | null;
  successMessage: string | null;
}

class UserInvitation extends React.Component<UserInvitationProps, UserInvitationState> {
  constructor(props: UserInvitationProps) {
    super(props);
    this.state = {
      form: {
        email: '',
      },
      error: null,
      successMessage: null,
    };
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const { form } = this.state;

    this.setState({ error: null, successMessage: null });

    try {
      const client = new AuthClient();
      const userInvitationForm: UserInvitationForm = {
        user: {
          email: form.email,
        },
      };

      const response = await client.inviteOperator(userInvitationForm);

      this.setState({ successMessage: response.status });
    } catch (e) {
      this.setState({ error: formatError(e) });
    }
  }

  render(): React.ReactNode {
    const { form, error, successMessage } = this.state;

    return (
      <Container>
        <h1>Invite User</h1>
        {error !== null ? (
          <Message className='error'>{error}</Message>
        ) : false}
        {successMessage !== null ? (
          <Message>{successMessage}</Message>
        ) : false}
        <form onSubmit={this.handleSubmit}>
          <>
            <Label>Email</Label>
            <TextField
              type="text"
              name="email"
              value={form.email}
              onChange={(e): void => this.handleChange('email', e.target.value)}
            />
          </>
          <>
            <Button type="submit">Invite</Button>
          </>
        </form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 25rem;
`;

const Label = styled.label`
  width: 18rem;
  float: left;
  margin-top: 1rem;
`;

export default UserInvitation;