import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import * as HttpHelper from 'utils/httpHelper';
import { getBearerToken } from 'utils/userHelper';
import { RecommendationPayload, RecommendationResponse } from 'models/priceRetrieval';

class PriceRetrievalClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.PRICE_RETRIEVAL_PROTOCOL;
    this.host = process.env.PRICE_RETRIEVAL_HOST;
    this.port = process.env.PRICE_RETRIEVAL_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  recommendationUrl(): string {
    return `${this.getBaseUrl()}/recommendation`;
  }

  async recommendation(payload: RecommendationPayload): Promise<RecommendationResponse> {
    const request = this.makeRequest('POST', this.recommendationUrl(), payload);

    try {
      const response: AxiosResponse<RecommendationResponse> = await axios(request);

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }
}

export default PriceRetrievalClient;