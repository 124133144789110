import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  SysAdminMainContainer,
  Message,
  Breadcrumb,
  StyledButton,
  TextInput,
  Separator,
  Radio,
  FleetTypesCheckbox,
} from 'components';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ROLES, { ROLE_DESCRIPTION } from 'constants/role';
import OrganizationSignUpClient from 'httpClients/organizationSignUpClient';
import * as H from 'history';
import { VehicleType } from 'constants/vehiclePreference';

const organisationNames = [{
  text: ROLE_DESCRIPTION[ROLES.OrgAdmin],
  value: ROLES.OrgAdmin,
}, {
  text: ROLE_DESCRIPTION[ROLES.OrgTransporter],
  value: ROLES.OrgTransporter,
}];

interface OrganizationNewProps {
  history: H.History;
}

interface OrganizationNewState {
  form: {
    business_name: string;
    fleet_types: VehicleType[];
    role_name: string;
  };
  error: { business_name: { message: string } } | string | null;
}

class OrganizationNew extends React.Component<OrganizationNewProps, OrganizationNewState> {
  constructor(props: OrganizationNewProps) {
    super(props);

    this.state = {
      form: {
        business_name: '',
        fleet_types: [],
        role_name: '',
      },
      error: null,
    };
  }

  handleChange = (field: string, value: string | VehicleType[]): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  handleCreateOrg = async (): Promise<void> => {
    this.setState({ error: null });
    try {
      const client = new OrganizationSignUpClient();
      await client.sysSignUp({
        business_name: this.state.form.business_name,
        fleet_types: this.state.form.fleet_types,
        role_name: this.state.form.role_name,
      });
      this.props.history.push('/sys/organizations');
    } catch (e) {
      this.setState({ error: e });
    }
  }

  renderHeading = (): React.ReactNode => {

    return (
      <Header>
        <Title>
          Create Organisation
        </Title>
        <ActionButton
          buttonStyle='encourage'
          buttonType='neutral'
          onClick={(): void => { this.props.history.push('/sys/organizations'); }}
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          Back
        </ActionButton>
      </Header>
    );
  }

  render(): React.ReactNode {
    const { form, error } = this.state;

    return (
      <SysAdminMainContainer selected="organizations">
        <Breadcrumb
          items={[
            { text: 'Organizations', onClick: (): void => { this.props.history.push('/sys/organizations'); } },
            { text: 'Create Organisation' },
          ]}
        />
        {this.renderHeading()}
        <CardContainer>
          <Card>
            <TextInput
              fieldName="Business Name"
              isRequired
              name="name"
              onTextChange={(value): void => this.handleChange('business_name', value)}
              placeholder="Name of company"
              type="text"
              value={form && form.business_name}
              width="large"
              height="small"
            />
            <Label>
              What do you primarily want to use GotSurge for?
            </Label>
            <Radio
              options={organisationNames}
              value={form && form.role_name}
              onChange={(v: string): void => {
                this.handleChange('role_name', v);
                this.handleChange('fleet_types', []);
              }}
            />
            <FleetTypesCheckbox
              containerStyle={css`
                margin-top: 0.3rem;
                margin-left: 1.8rem;
              `}
              fieldName="Types of fleet"
              isRequired
              value={form.fleet_types}
              disabled={!form.role_name || form.role_name === ROLES.OrgAdmin}
              onChange={(fleets: VehicleType[]) => this.handleChange('fleet_types', fleets)}
            />
            {error && (
              <Message className='error'>
                {typeof error === 'object' ? error.business_name?.message : error}
              </Message>
            )}
            <CustomSeparator />
            <StyledButton
              type="button"
              buttonType="primary"
              buttonStyle="encourage"
              disabled={
                form.business_name === '' ||
                form.role_name === '' ||
                form.role_name === ROLES.OrgTransporter && form.fleet_types.length === 0
              }
              onClick={(): void => { this.handleCreateOrg(); }}
            >
              <Icon color={COLOR.white} icon={faCheck} />
              Create Organisation
            </StyledButton>
          </Card>
        </CardContainer>
      </SysAdminMainContainer>
    );
  }
}

export const ActionButton = styled(StyledButton)`
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  color: ${COLOR.darkGray};

  @media (max-width: 769px) {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const Icon = styled(FontAwesomeIcon) <{ color: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.375rem;
`;

export const CardContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Card = styled.div`
  flex: 1;
  border-radius: 0.5rem;
  background-color: ${COLOR.white};
  padding: 1.875rem 2.125rem;
  margin-bottom: 1.75rem;
  justify-content: left;
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Label = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

export default OrganizationNew;