import * as Styled from './styles';
import { useState } from 'react';
import * as React from 'react';
import { faTimes, faUser, faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useParams } from 'react-router-dom';

import { logoutUser } from 'utils/userHelper';
import { User } from '../../models/auth/user';
import merchantSmallLogo from 'assets/images/merchant_small.png';
import sysAdminSmallLogo from 'assets/images/ops_small.png';
import merchantLogo from 'assets/images/merchant_logo.png';
import sysAdminLogo from 'assets/images/ops_logo.png';
import NotificationPanel from '../notification/notificationPanel';
import { useHistory } from 'react-router-dom';

interface Props {
  user?: User;
  open?: boolean;
  role?: string;
  selected?: string;
  navList?: { name: string; path: string; text: string }[];
  registeredPath?: { path: string; text: string }[];
}

const onLogout = (): void => {
  logoutUser();
};

function NavItem(props: Props): JSX.Element {
  return (
    <>
      <Styled.NavMenu open={props.open}>
        <Styled.NavContainer>
          {props.role === 'sysAdmin' ? (
            <Styled.MobileLogoContainer>
              <Styled.Logo src={sysAdminLogo} open={props.open} />
            </Styled.MobileLogoContainer>
          ) : (
            <Styled.MobileLogoContainer>
              <Styled.Logo src={merchantLogo} open={props.open} />
            </Styled.MobileLogoContainer>
          )}
          {props.navList.map((link, index) => (
            <Styled.LinkButton
              key={index}
              selected={props.selected === link.name}
              to={link.path}
            >
              <Styled.LinkText>{link.text}</Styled.LinkText>
            </Styled.LinkButton>
          ))}
          <Styled.MobileSideBarFooter>
            {props.role === 'sysAdmin' && <NotificationPanel />}
            {props.role !== 'sysAdmin' && (
              <Link
                to="/setting"
                style={{
                  textDecoration: 'none',
                  textAlign: 'left',
                  width: '100%',
                  marginLeft: '15px',
                }}
              >
                <Styled.MobileSettingContainer>
                  <Styled.SettingIcon icon={faCog} />
                  <Styled.MobileSettingText>Setting</Styled.MobileSettingText>
                </Styled.MobileSettingContainer>
              </Link>
            )}
            <Styled.LogoutPanel>
              <Styled.ProfileIcon icon={faUser} />
              <Styled.LogoutUserText>
                <Styled.LogoutUserName>
                  {props.user && props.user?.name}
                </Styled.LogoutUserName>
                <Styled.LogoutUserEmail>
                  {props.user && props.user?.email}
                </Styled.LogoutUserEmail>
              </Styled.LogoutUserText>
              <Styled.LogoutButton onClick={onLogout}>
                <Styled.LogoutIcon icon={faSignOutAlt} />
              </Styled.LogoutButton>
            </Styled.LogoutPanel>
          </Styled.MobileSideBarFooter>
        </Styled.NavContainer>
        <Styled.NavRightContainer></Styled.NavRightContainer>
      </Styled.NavMenu>
    </>
  );
}

export default function Navbar(props: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const [showDropdown, setDropdown] = React.useState(false);
  const { id } = useParams<{ id: string }>();
  const pathLocation = useLocation();
  const history = useHistory();
  const headerPath = props.registeredPath.find(
    (registeredPath) => {
      if (id) {
        if (pathLocation.pathname.split('/').splice(-1)[0] === id) {
          return registeredPath.path + '/' + id === pathLocation.pathname;
        } else if (pathLocation.pathname.indexOf(id) > 0) {
          const pathSplit = registeredPath.path.split(':value');
          return pathSplit[0] + id + pathSplit[1] === pathLocation.pathname;
        }
        return registeredPath.path + '/' + id === pathLocation.pathname;
      }
      return registeredPath.path === pathLocation.pathname;
    }
  );
  return (
    <Styled.NavHeaderContainer>
      <Styled.LogoContainer onClick={(): void => setOpen(!open)}>
        <Styled.SmallLogo
          src={props.role !== 'sysAdmin' ? merchantSmallLogo : sysAdminSmallLogo}
        />
      </Styled.LogoContainer>
      {open && (
        <Styled.CloseNavContainer onClick={(): void => setOpen(!open)}>
          <Styled.CloseNavIcon icon={faTimes} />
        </Styled.CloseNavContainer>
      )}
      <NavItem
        user={props.user}
        open={open}
        role={props.role}
        navList={props.navList}
        selected={props.selected}
      />
      <Styled.NavHeader>{headerPath && headerPath.text}</Styled.NavHeader>
      <Styled.DesktopRightPanelSection>
        {props.role === 'sysAdmin' && <NotificationPanel />}
        <div
          onBlur={(): void => {
            setTimeout(() => {
              setDropdown(false);
            }, 100);
          }}
          onClick={(): void => {
            setDropdown(true);
          }}
          tabIndex={1}
        >
          <Styled.ProfileIcon hoverable icon={faUser} />
          {showDropdown ? (
            <Styled.DropdownMainContainer>
              <Styled.DropdownContainer>
                <Styled.ProfileContainer>
                  <Styled.ProfileIcon icon={faUser} />
                  <Styled.DetailContainer>
                    <Styled.NameText>{props.user.name}</Styled.NameText>
                    <Styled.EmailText>{props.user.email}</Styled.EmailText>
                  </Styled.DetailContainer>
                </Styled.ProfileContainer>
                {props.role !== 'sysAdmin' && (
                  <Styled.SettingContainer
                    onClick={(): void => {
                      history.push('/setting');
                    }}
                  >
                    <Styled.SettingIcon icon={faCog} />
                    Setting
                  </Styled.SettingContainer>
                )}
                <Styled.ActionButtonList>
                  <Styled.ActionButton
                    onClick={(): void => {
                      onLogout();
                    }}
                  >
                    <Styled.ActionIcon icon={faSignOutAlt} />
                    Logout
                  </Styled.ActionButton>
                </Styled.ActionButtonList>
              </Styled.DropdownContainer>
            </Styled.DropdownMainContainer>
          ) : (
            false
          )}
        </div>
      </Styled.DesktopRightPanelSection>
    </Styled.NavHeaderContainer>
  );
}
