import * as React from 'react';
import {
  StyledButton, Alert, TextInput, BeforeLoginContainer,
} from 'components';
import styled from 'styled-components';
import * as H from 'history';
import COLOR from 'constants/color';
import { Link, RouteComponentProps } from 'react-router-dom';
import OrganizationSignUpClient from 'httpClients/organizationSignUpClient';
import AuthClient from 'httpClients/authClient';

interface SetOrganizationPageProps<S = H.LocationState> {
  history: H.History<S>;
}

interface LocationState {
  registerName: string;
  registerPassword: string;
}

interface SetOrganizationPageState {
  form: {
    businessName: string;
  };
  error: string | null;
  isSuccess: boolean;
}

type Props = RouteComponentProps<object, object, LocationState> & SetOrganizationPageProps;

class SetOrganizationPage extends React.Component<Props, SetOrganizationPageState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        businessName: '',
      },
      error: null,
      isSuccess: false,
    };
  }

  componentDidMount(): void {
    const tempToken = localStorage.getItem('tempBearerToken');
    if (!tempToken) {
      this.props.history.push('/register');
    } else if (!(
      this.props.location.state &&
      this.props.location.state.registerName &&
      this.props.location.state.registerPassword
    )) {
      this.props.history.push('/register/set-name-password');
    }
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  isBusinessNameValid = (): boolean => {
    const { businessName } = this.state.form;
    const pattern = new RegExp(/^[a-zA-Z0-9 _-]*$/);

    return pattern.test(businessName);
  }

  signUp = async (): Promise<void> => {
    const authClient = new AuthClient();
    const signUpClient = new OrganizationSignUpClient();

    try {
      // Check organization name first
      await signUpClient.signUp(this.state.form.businessName);
      await authClient.completeSelfRegistration({
        name: this.props.location.state.registerName,
        password: this.props.location.state.registerPassword,
      });

      if (process.env.SEGMENT_KEY) {
        window.analytics.track('Merchant Self Registration', {
          'org_name': this.state.form.businessName,
          'user_name': this.props.location.state.registerName,
        });
      }

      this.setState({ isSuccess: true }, () => { localStorage.removeItem('tempBearerToken'); });
    } catch (error) {
      this.setState({ error });
    }
  }

  renderBeforeSuccess = (): React.ReactNode => (
    <>
      <Description>
        Let us know the name of your organisation. You can change this later.
      </Description>
      <Div>
        <TextInput
          fieldName="Business name"
          isRequired
          name="businessName"
          onTextChange={(value: string): void => this.handleChange('businessName', value)}
          type="text"
          value={this.state.form.businessName}
          required
        />
        {!this.isBusinessNameValid() && (
          <Info status="info">
            Business name can only contain alphanumeric, space, dash, and underscore characters.
          </Info>
        )}
      </Div>
      {this.state.error && (
        <Div>
          <Alert status='error'>{this.state.error}</Alert>
        </Div>
      )}
      <Div>
        <StyledButton
          buttonStyle="encourage"
          buttonType="primary"
          onClick={this.signUp}
          disabled={this.state.form.businessName.trim() === '' || !this.isBusinessNameValid()}
          size="lg"
          isFullWidth={true}
        >
          Continue
        </StyledButton>
      </Div>
    </>
  )

  renderAfterSuccess = (): React.ReactNode => (
    <>
      <Description>
        Great! Welcome, <BoldText>{this.state.form.businessName}</BoldText>.
        Look out for an email for the approval of your business account creation and instructions.
        You can change this name later.
      </Description>
      <Div>
        <Alert status='success'>
          We’ll review your account within 2 working days.
        </Alert>
      </Div>
      <Div>
        <Link to="/login">
          <StyledButton
            buttonStyle="encourage"
            buttonType="primary"
            size="lg"
            isFullWidth={true}
          >
            Go to login
          </StyledButton>
        </Link>
      </Div>
    </>
  )

  render(): React.ReactNode {
    const { isSuccess } = this.state;

    return (
      <BeforeLoginContainer title="What’s the name of your organisation?">
        {isSuccess ? this.renderAfterSuccess() : this.renderBeforeSuccess()}
      </BeforeLoginContainer>
    );
  }
}

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const Div = styled.div`
  margin-top: 1.875rem;
`;

const BoldText = styled.span`
  font-weight: 600;
`;

const Info = styled(Alert)`
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1.5rem;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

export default SetOrganizationPage;
