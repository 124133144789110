import * as React from 'react';
import TaskImportClient from 'httpClients/taskImportClient';
import { RouteComponentProps } from 'react-router-dom';
import { SysAdminMainContainer, Button, Message } from 'components';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import styled from 'styled-components';
import * as H from 'history';
import { FileImportResult, TaskImportJobResultError } from 'models/taskImport';
import { TaskImportHash } from 'reduxActions/taskImport/taskImportTypes';
import STATUS from 'constants/taskImportStatus';

interface TaskImportJobDetailState {
  error: string | null;
  successMessage: string | null;
}

interface StateProps {
  data: TaskImportHash;
}

interface TaskImportJobDetailProps {
  history: H.History;
}

interface Field {
  title: string;
  value: number | string;
}

type Props = RouteComponentProps<{ id: string }> & TaskImportJobDetailProps & StateProps;

class TaskImportJobDetail extends React.Component<Props, TaskImportJobDetailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      successMessage: null,
    };
  }

  componentDidMount(): void {
    const { id } = this.props.match.params;
    this.fetchTaskImportJob(id);
    this.fetchTaskImportJobResult(id);
  }

  fetchTaskImportJob = async (id: string): Promise<void> => {
    this.setState({ error: null });
    try {
      const client = new TaskImportClient();
      await client.sysAdminGetTaskImportJob(id);
    } catch (e) {
      this.setState({ error: e });
    }
  }

  fetchTaskImportJobResult = async (id: string): Promise<void> => {
    try {
      const client = new TaskImportClient();
      await client.sysAdminGetTaskImportJobResult(id);
    } catch (e) {
      //ignore the error message
    }
  }

  handleStartImport = async (): Promise<void> => {
    const { id } = this.props.match.params;

    this.setState({ error: null });
    try {
      const client = new TaskImportClient();
      await client.sysAdminStart(id);
      this.setState({ successMessage: 'Task import started' });
      setTimeout(() => {
        this.props.history.push('/sys/imports');
      }, 300);
    } catch (e) {
      this.setState({ error: e });
    }
  }

  render(): React.ReactNode {
    const { error, successMessage } = this.state;
    const { id } = this.props.match.params;
    const job = this.props.data[id];
    const jobFields: Field[] = job && [
      {
        title: 'Order Import Job ID',
        value: job.id,
      },
      {
        title: 'Current Status',
        value: STATUS[job.current_status],
      },
      {
        title: 'Organization ID',
        value: job.org_id,
      },
      {
        title: 'Is QA?',
        value: job.is_qa ? 'Yes' : 'No',
      },
    ];
    const result = job && job.result;
    const resultFields: Field[] = result && [
      {
        title: 'Job Result ID',
        value: result.id,
      },
      {
        title: 'Total Orders counted',
        value: result.total_tasks_counted,
      },
      {
        title: 'Total Orders imported',
        value: result.total_tasks_imported,
      },
      {
        title: 'Total Orders rejected',
        value: result.total_tasks_rejected,
      },
      {
        title: 'Total Items counted',
        value: result.total_items_counted,
      },
      {
        title: 'Total Items imported',
        value: result.total_items_imported,
      },
      {
        title: 'Total Items rejected',
        value: result.total_items_rejected,
      },
    ];

    return (
      <SysAdminMainContainer selected="imports">
        <PageHeader>
          <Title>Order Import Job Detail and Result</Title>
          {error && (
            <Message className='error'>
              {error}
            </Message>
          )}
          {successMessage !== null ? (
            <Message>
              {successMessage}
            </Message>
          ) : false}
          <Button
            onClick={(): void => this.props.history.goBack()}
          >
            Back To Order Import List
          </Button>
        </PageHeader>
        {job ? (
          jobFields && jobFields.map((field: Field) => (
            <>
              <StyledDt>{field.title}</StyledDt>
              <dd>{field.value}</dd>
            </>
          ))
        ) : false}
        {
          job && job.upload_record ? (
            <>
              <StyledDt>Upload Record Id</StyledDt>
              <dd>{job.upload_record.id}</dd>
              {
                job.upload_record.files && job.upload_record.files.length > 0 ? (
                  <>
                    <StyledDt>
                      {job.upload_record.files.length > 1
                        ? 'Files' : 'File'}
                    </StyledDt>
                    {
                      job.upload_record.files.map((file, index) => (
                        <StyledDl key={index}>
                          <dt>File Name</dt>
                          <dd>{file.file_name}</dd>
                          <dt>File Size</dt>
                          <dd>{file.file_size}</dd>
                          <dt>Url</dt>
                          <dd>{file.url}</dd>
                          <dt>Checksum</dt>
                          <dd>{file.checksum}</dd>
                          <dt>Checksum Method</dt>
                          <dd>{file.checksum_method}</dd>
                        </StyledDl>
                      ))
                    }
                  </>
                ) : false
              }
            </>
          ) : false
        }
        {result ? (
          <dl>
            {
              resultFields && resultFields.map((field: Field) => (
                <>
                  <StyledDt>{field.title}</StyledDt>
                  <dd>{field.value}</dd>
                </>
              ))
            }
            {
              result.file_import_results
                && result.file_import_results.length > 0 ? (
                  <>
                    <StyledDt>
                      {result.file_import_results.length > 1
                        ? 'Files Result' : 'File Result'}
                    </StyledDt>
                    {
                      result.file_import_results.map((result: FileImportResult,
                        index: number) => (
                          <StyledDl key={index}>
                            <dt>File Name</dt>
                            <dd>{result.file_name}</dd>
                            <dt>File was processed:
                              {result.file_was_processed ? 'Yes' : 'No'}
                            </dt>
                            <dt>Total counted: {result.total_counted}</dt>
                            <dt>Total rejected: {result.total_rejected}</dt>
                          </StyledDl>
                      ))
                    }
                  </>
              ) : false
            }
            {
              result.errors && result.errors.length > 0 ? (
                <>
                  <StyledDt>
                    {result.errors.length > 1 ? 'Errors' : 'Error'}
                  </StyledDt>
                  {
                    result.errors.map((error: TaskImportJobResultError, index: number) => (
                      <dd key={index}>{JSON.stringify(error)}</dd>
                    ))
                  }
                </>
              ) : false
            }
          </dl>
        ) : false}
        {
          job && STATUS[job.current_status] == STATUS.new ? (
            <Button onClick={this.handleStartImport}>Start Import</Button>
          ) : false
        }
      </SysAdminMainContainer>
    );
  }
}

const StyledDl = styled.dl`
  position: relative;
  left: 1rem;
`;

const StyledDt = styled.dt`
  font-weight: bold;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  data: state.taskImport.taskImportHash,
});

export default connect(mapStateToProps)(TaskImportJobDetail);