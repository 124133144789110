import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CenterModal, TextInput, ProgressBar, Alert } from 'components';
import { Task } from 'models/task';
import TaskClient from 'httpClients/taskClient';
import COLOR from 'constants/color';

const STATE = {
  beforeUpdate: 'beforeUpdate',
  updating: 'updating',
  afterUpdate: 'afterUpdate',
};

interface UpdateAdminFeeModalProps {
  closeModal: () => void;
  orders: Task[];
}

interface UpdateAdminFeeModalState {
  completedCount: number;
  error: {
    id: string;
    message: string;
  }[];
  pageState: string;
  adminFee: number;
}

class UpdateAdminFeeModal extends React.Component<UpdateAdminFeeModalProps, UpdateAdminFeeModalState> {
  constructor(props: UpdateAdminFeeModalProps) {
    super(props);

    this.state = {
      completedCount: 0,
      error: [],
      pageState: STATE.beforeUpdate,
      adminFee: 0,
    };
  }

  UpdateAdminFee = async (): Promise<void> => {
    this.setState({ pageState: STATE.updating });

    const client = new TaskClient();

    for(let i = 0; i < this.props.orders.length; i++) {
      const order = this.props.orders[i];

      try{
        await client.sysAdminUpdateTaskAdminFee(order.id, {
          admin_fee: this.state.adminFee,
          version_rev: order.version_rev,
        });

        this.setState(prevState => ({ completedCount: prevState.completedCount + 1 }));
      } catch (e) {
        this.setState(prevState => {
          const newErrors = [ ...prevState.error ];
          newErrors.push({
            id: order.id.split('-')[0],
            message: e,
          });

          return { error: newErrors };
        });
      }
    }

    this.setState({ pageState: STATE.afterUpdate });
  }

  renderBeforeUpdate = (): React.ReactNode => (
    <CenterModal
      leftButtonOnClick={this.props.closeModal}
      leftButtonText="Cancel"
      rightButtonOnClick={(): void => { this.UpdateAdminFee(); }}
      rightButtonText="Update Admin Fee"
      title="Update Admin Fee"
    >
      <Input
        fieldName="New Admin Fee"
        onTextChange={(value): void => { this.setState({ adminFee: parseInt(value) }); }}
        width="half"
        type="number"
        value={this.state.adminFee}
      />
      <Description>
        <div>
          The new admin fee will replace existing admin fees.
        </div>
        <div>
          Admin fee of assigned Orders cannot be changed.
        </div>
        <div>
          Review your selection before clicking Update.
        </div>
      </Description>
    </CenterModal>
  )

  renderUpdating = (): React.ReactNode => (
    <CenterModal
      title="Update Admin Fee"
    >
      <ProgressBar
        completeCount={this.state.completedCount}
        totalCount={this.props.orders.length || 1}
      />
      <ProgressDescription>
        Updated {this.state.completedCount} of {this.props.orders.length} Orders
      </ProgressDescription>
    </CenterModal>
  )

  renderAfterUpdate = (): React.ReactNode => (
    <CenterModal
      rightButtonOnClick={this.props.closeModal}
      rightButtonText={
        <>
          <Icon icon={faCheck} />
          OK
        </>
      }
      title="Update Admin Fee"
    >
      {
        this.state.error.length > 0 ? (
          <>
            <Alert status="error">
              {this.props.orders.length - this.state.completedCount} of {this.props.orders.length} Orders cannot be updated.
            </Alert>
            {
              this.state.error.map(error => (
                <Description key={error.id}>
                  {error.id} - {error.message}
                </Description>
              ))
            }
          </>
        ) : (
          <Alert status="success">
            Admin Fee updated for {this.state.completedCount} Orders.
          </Alert>
        )
      }
    </CenterModal>
  )

  render(): React.ReactNode {
    let content: React.ReactNode = this.renderBeforeUpdate();
    if (this.state.pageState === STATE.updating) {
      content = this.renderUpdating();
    } else if (this.state.pageState === STATE.afterUpdate) {
      content = this.renderAfterUpdate();
    }

    return (
      content
    );
  }
}

const Description = styled.div`
  font-size: 0.75rem;
  margin-top: 1.5rem;
`;

const ProgressDescription = styled.div`
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;

const Input = styled(TextInput)`
  margin-bottom: 0.25rem;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${COLOR.darkGray};
  font-size: 0.75rem;
  margin-right: 0.125rem;
`;

export default UpdateAdminFeeModal;