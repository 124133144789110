import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import * as H from 'history';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import { TextInput, BeforeLoginContainer, Alert, StyledButton } from 'components';
import AuthClient from 'httpClients/authClient';
import COLOR from 'constants/color';
import { RootState } from 'reduxActions/store';
import configureStore from 'reduxActions/store';
import { setAfterLoginPage } from 'reduxActions/auth/authActions';
import { CurrentUser } from 'models/auth';
import theme from 'theme';

interface LoginProps<S = H.LocationState> {
  history: H.History<S>;
}

interface StateProps {
  promptMessage: string;
  afterLoginPage: string;
  currentUser: CurrentUser;
}

interface LoginState {
  form: {
    email: string;
    password: string;
    rememberMe: boolean;
  };
  isSubmitClicked: boolean;
  passwordShown: boolean;
  error: string | null;
  isLoading: boolean;
  showAlert: boolean;
  alertMeta: {
    type: 'error' | 'success' | 'info';
    title: string;
    message: string;
  };
}

type Props = LoginProps & StateProps & RouteComponentProps;

class Login extends React.Component<Props, LoginState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        email: '',
        password: '',
        rememberMe: false,
      },
      passwordShown: false,
      isSubmitClicked: false,
      error: null,
      isLoading: false,
      showAlert: false,
      alertMeta: {
        type: 'info',
        title: '',
        message: '',
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const verified = params.get('verified');
    if (verified === 'true') {
      this.setState({
        showAlert: verified === 'true',
        alertMeta: {
          type: 'success',
          title: 'You\'ve verified your account!',
          message: 'Please login.',
        },
      });
    }
  }

  handleChange(field: string, value: string): void {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  async authLogin(maxRetries: number): Promise<void> {
    const { afterLoginPage, history } = this.props;
    const { form } = this.state;
    const client = new AuthClient();
    try {
      await client.signIn({
        user: {
          email: form.email,
          password: form.password,
        },
      });
      if (afterLoginPage) {
        history.push(afterLoginPage);
        const store = configureStore();
        store.dispatch(setAfterLoginPage(''));
      } else {
        history.push('/');
      }
    } catch (e) {
      if (
        e.includes('Invalid Email or password') ||
        e.includes(
          'You have a pending invitation. Check your email and accept the invitation to log in.'
        )
      ) {
        this.setState({
          error: e,
          isSubmitClicked: false,
        });
      } else if (maxRetries > 0) {
        setTimeout(() => {
          this.setState(() => ({
            error: 'This is taking a little longer than expected. Hang in there!',
            isSubmitClicked: false,
          }));
          this.authLogin(maxRetries - 1);
        }, 5 * 1000);
      } else {
        this.setState({
          error: 'Please wait a few minutes before you try again.',
          isSubmitClicked: false,
        });
      }
    }
  }

  async handleSubmit(): Promise<void> {
    const { form } = this.state;
    if (!this.state.isSubmitClicked) {
      if (form.email.trim().length === 0 || form.password.trim().length === 0) {
        this.setState({ error: 'Enter your email and password to log in.' });
        return;
      }

      this.setState({ error: null, isSubmitClicked: true });
      await this.authLogin(3);
    }
  }

  handleKeyDown(keyValue: string): void {
    if (keyValue === 'Enter' && !this.state.isSubmitClicked) {
      this.handleSubmit();
    }
  }

  onCheckboxChange = (): void => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        rememberMe: !prevState.form.rememberMe,
      },
    }));
  };

  renderTitle = (): React.ReactNode => {
    const { promptMessage } = this.props;
    return (
      <>
        {promptMessage && (
          <AlertInfo status="info">
            <AlertMessage>{promptMessage}</AlertMessage>
          </AlertInfo>
        )}
        <Title>Welcome back</Title>
        <SubTitle>Welcome back! Enter your credentials.</SubTitle>
        {this.state.showAlert && (
          <AlertInfo status={this.state.alertMeta.type}>
            <AlertTitle color={theme.colors.success_700}>
              {this.state.alertMeta.title}
            </AlertTitle>
            <AlertMessage color={theme.colors.success_700}>
              {this.state.alertMeta.message}
            </AlertMessage>
          </AlertInfo>
        )}
      </>
    );
  };

  renderLoginForm = (): React.ReactNode => {
    const { form, error, passwordShown } = this.state;
    return (
      <FormDiv>
        <Div>
          <TextInput
            fieldName="Email"
            isRequired
            name="email"
            onKeyDown={(e): void => this.handleKeyDown(e.key)}
            onTextChange={(value): void => this.handleChange('email', value)}
            type="text"
            value={form.email}
          />
        </Div>
        <Div>
          <TextInput
            fieldName="Password"
            icon={passwordShown ? faEye : faEyeSlash}
            iconOnClick={(): void => {
              this.setState((prevState) => ({
                passwordShown: !prevState.passwordShown,
              }));
            }}
            isRequired
            name="password"
            onKeyDown={(e): void => this.handleKeyDown(e.key)}
            onTextChange={(value): void => this.handleChange('password', value)}
            type={passwordShown ? 'text' : 'password'}
            value={form.password}
          />
        </Div>
        {/* <CheckboxContainer>
        <LabelCheckBox>
          <InputCheckbox
            type="checkbox"
            checked={this.state.form.rememberMe}
            onChange={this.onCheckboxChange}
          />
            Remember me
        </LabelCheckBox>
      </CheckboxContainer>  */}
        {error && <ErrorMessage status="error">{error}</ErrorMessage>}
        <LoginButtonContainer>
          <StyledButton
            buttonStyle="encourage"
            buttonType="primary"
            isFullWidth={true}
            size="lg"
            isLoading={this.state.isSubmitClicked}
            disabled={this.state.isSubmitClicked}
            onClick={(): void => {
              this.handleSubmit();
            }}
          >
            Log in
          </StyledButton>
        </LoginButtonContainer>
        <Register>
          Don’t have an account? <StyledLink to="/register">Register</StyledLink>
        </Register>
        <ForgotPassword>
          <StyledLink to="/forgot-password">Forgot Password</StyledLink>
        </ForgotPassword>
        <FooterDescription>
          {'Stuck? '}
          <CustomLink target="_blank" href="https://help.gotsurge.co">
            Get Help
          </CustomLink>
        </FooterDescription>
      </FormDiv>
    );
  };

  render(): React.ReactNode {
    return (
      <BeforeLoginContainer title={this.renderTitle()}>
        {this.renderLoginForm()}
      </BeforeLoginContainer>
    );
  }
}

const FormDiv = styled.div`
  margin-top: 2.25rem;
`;

const Register = styled.div`
  margin-top: 2rem;
  font-size: 0.875rem;
  color: ${COLOR.midDarkGrey};
`;

const ForgotPassword = styled.div`
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

// const CheckboxContainer = styled.div`
//   margin-bottom: 1.625rem;
//   font-size: 1rem;
// `;

// const LabelCheckBox = styled.label`
//   cursor: pointer;
// `;

// const InputCheckbox = styled.input`
//   marginRight: 1rem;
//   transform: scale(1.8);
//   boxShadow: 0px 4px 16px #455B633Al
// `;

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${COLOR.blue};
`;

const CustomLink = styled.a`
  text-decoration: underline;
  color: ${COLOR.blue};
`;

const Div = styled.div`
  margin-bottom: 1.25rem;
`;

const ErrorMessage = styled(Alert)`
  margin-bottom: 1rem;
`;

const LoginButtonContainer = styled.div`
  margin-top: 1.875rem;
`;

const AlertInfo = styled(Alert)`
  margin-top: 1.5rem;
`;

interface AlertTextProps {
  color?: string;
}
const AlertTitle = styled.div<AlertTextProps>`
  color: ${(props) => props.color || COLOR.darkGray};
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const AlertMessage = styled.div<AlertTextProps>`
  color: ${(props) => props.color || COLOR.darkGray};
  font-size: 0.875rem;
  font-weight: 400;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  margin-right: auto;
  margin-top: 2rem;
  color: ${COLOR.black};
`;

export const FooterDescription = styled.div`
  font-size: 0.75rem;
  margin-top: 3rem;
  color: ${COLOR.midDarkGrey};
`;

export const SubTitle = styled.div`
  font-size: 0.875rem;
  margin-top: 0.5rem;
  font-weight: 400;
  color: ${COLOR.midDarkGrey};
`;

const mapStateToProps = (state: RootState): StateProps => ({
  promptMessage: state.auth.promptMessage,
  afterLoginPage: state.auth.afterLoginPage,
  currentUser: state.auth.currentUser,
});

export default withRouter(connect(mapStateToProps)(Login));
