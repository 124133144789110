import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import { DriverProfile } from 'models/driverProfile';
import { RouteComponentProps } from 'react-router-dom';
import driverProfileClient from 'httpClients/driverProfileClient';
import { FieldView, SysAdminMainContainer, TextInput, Dropdown } from 'components';
import {
  Breadcrumb,
  CenterModal,
  StyledButton,
} from 'components';
import {
  CustomCard,
  Container,
  Image,
  Heading,
  InfoCard,
  MainColumn,
  PersonalInfoColumn,
  Row,
  Title,
  VehicleInfoTitle,
  Label,
  Required,
} from './styles';
import COLOR from 'constants/color';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import DriverInfoCard from './cards/driverInfoCard';
import DriverPerformanceCard from './cards/driverPerformanceCard';
import { DriverProfileEditForm } from 'models/driverProfile';
import * as H from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const accountTypeStrings = [
  {
    value: 'freelance',
    name: 'Freelance',
  }, {
    value: 'professional',
    name: 'Professional',
  },
];

interface StateProps {
  driverProfile: DriverProfile;
}

interface DriverProfileEditState {
  form: DriverProfileEditForm;
  error: string | null;
  isChanged: boolean;
}

interface EditDriverProfileProps {
  driverProfile: DriverProfile;
  history: H.History;
}

type Props = RouteComponentProps<{ id: string }, object> & StateProps;

class DriverEdit extends React.Component<Props, DriverProfileEditState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: this.getDefaultForm(),
      error: null,
      isChanged: false,
    };
  }

  async componentDidMount(): Promise<void> {
    const { id } = this.props.match.params;
    this.fetchDriverProfile(id);
  }

  componentDidUpdate(prevProps: EditDriverProfileProps): void {
    if (prevProps.driverProfile !== this.props.driverProfile) {
      this.setState({
        form: this.getDefaultForm(),
      });
    }
  }

  fetchDriverProfile = async (id: string): Promise<void> => {
    try {
      const client = new driverProfileClient();
      await client.getDriverProfile(id);
    } catch (e) {
      this.setState(({
        error: e,
      }));
    }
  }

  getDefaultForm = (): DriverProfileEditForm => {
    const driverProfile = this.props.driverProfile;
    return {
      name: driverProfile && driverProfile.name ? driverProfile.name : '',
      full_name: driverProfile && driverProfile.full_name ? driverProfile.full_name : '',
      type: driverProfile && driverProfile.type ? driverProfile.type : accountTypeStrings[0]['value'],
      display_picture: {
        name: driverProfile && driverProfile.display_picture
          && driverProfile.display_picture.name ? driverProfile.display_picture.name : '',
        url: driverProfile && driverProfile.display_picture
          && driverProfile.display_picture.url ? driverProfile.display_picture.url : '',
      },
      vehicle_plate_number: driverProfile && driverProfile.vehicle_plate_number ? driverProfile.vehicle_plate_number : '',
      partial_id_chars: driverProfile && driverProfile.partial_id_chars ? driverProfile.partial_id_chars : '',
      phone_number: driverProfile && driverProfile.phone_number ? driverProfile.phone_number : '',
      // bank_name: '',
      // bank_account_no: '',
      version_rev: driverProfile && driverProfile.version_rev,
    };
  }

  handleChange = (fieldName: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [fieldName]: value,
      },
      isChanged: true,
    }));
  }

  handleSaveProfile = async (): Promise<void> => {
    const { isChanged } = this.state;
    const { ...form } = this.state.form;
    const driverId = this.props.driverProfile.id;

    if (!this.validatePhoneNumber()) {
      this.setState({ error: 'Mobile number must be in this format \'+6581234567\'' });
      return;
    }

    try {
      const client = new driverProfileClient();
      // If there is no changes, dont call update API
      if (this.props.driverProfile && isChanged) {
        await client.updateDriverProfile(driverId, form);
      }

      this.props.history.push(`/sys/drivers/${driverId}`);
    } catch (e) {
      let errorMessage = '';
      if (e.code === 'version-conflict') {
        errorMessage = 'There are changes to this profile.';
      } else {
        errorMessage = e.message ? e.message : 'Unknown error';
        if (e.trace_id) {
          errorMessage += ` (trace_id: ${e.trace_id}`;
        }
      }
      this.setState({ error: errorMessage });
    }
  }

  validatePhoneNumber = (): boolean => {
    const pattern = new RegExp(/^([+\d]*)?\d$/);

    return pattern.test(this.state.form.phone_number);
  }

  renderHeading = (): React.ReactNode => {
    return (
      <Heading>
        <Title>
          Driver Profile
        </Title>
        <StyledButton
          buttonType="neutral"
          buttonStyle="encourage"
          onClick={this.handleSaveProfile}
          icon={<FontAwesomeIcon icon={faCheck} style={{color: COLOR.blue}} />}
        >
          Save Changes
        </StyledButton>
      </Heading>
    );
  }

  renderPersonalInfoEdit = (): React.ReactNode => {
    const { form } = this.state;
    const driverProfile = this.props.driverProfile;

    return (
      <CustomCard>
        <Title>Personal Info</Title>
        <Row>
          <PersonalInfoColumn>
            <FieldView
              fieldName="Profile Photo"
            >
              <Image
                src={form && form.display_picture
                  && form.display_picture.url
                  ? form.display_picture.url : null}
                alt={form && form.display_picture
                  && form.display_picture.name}
              />
            </FieldView>
            <TextInput
              fieldName="Display name"
              isRequired
              name="display_name"
              onTextChange={(value): void => this.handleChange('name', value)}
              type="text"
              value={form && form.name}
              width="half"
              height="small"
            />
            <FieldView
              fieldName="Availability"
            >
              {driverProfile && driverProfile.is_available ? 'Available' : '-'}
            </FieldView>
            <Label>Account Type<Required>*</Required></Label>
            <Dropdown
              onChange={(value: string): void => { this.handleChange('type', value); }}
              value={form && form.type ? form.type : accountTypeStrings[0]['value']}
              options={accountTypeStrings}
            />
          </PersonalInfoColumn>
          <PersonalInfoColumn>
            <TextInput
              fieldName="Full Name as per NRIC / FIN"
              isRequired
              name="name"
              onTextChange={(value): void => this.handleChange('full_name', value)}
              type="text"
              value={form && form.full_name}
              width="half"
              height="small"
            />
            <TextInput
              fieldName="Mobile No."
              isRequired
              name="name"
              onTextChange={(value): void => this.handleChange('phone_number', value)}
              type="text"
              value={form && form.phone_number}
              width="half"
              height="small"
            />
            <FieldView
              fieldName="Email"
            >
              {driverProfile && driverProfile.email}
            </FieldView>
            <TextInput
              fieldName="Last 4 characters of NRIC / FIN"
              isRequired
              name="partial_id_chars"
              onTextChange={(value): void => this.handleChange('partial_id_chars', value)}
              type="text"
              value={form && form.partial_id_chars}
              width="half"
              height="small"
            />
          </PersonalInfoColumn>
        </Row>
      </CustomCard>
    );
  }

  renderVehicleInfoEdit = (): React.ReactNode => {
    const { form } = this.state;

    return (
      <InfoCard>
        <VehicleInfoTitle>
          <Title>
            Vehicle Info
          </Title>
        </VehicleInfoTitle>
        <TextInput
          fieldName="Vehicle Plate No"
          isRequired
          name="vehicle_plate_number"
          onTextChange={(value): void => this.handleChange('vehicle_plate_number', value)}
          type="text"
          value={form && form.vehicle_plate_number}
          width="half"
          height="small"
        />
      </InfoCard>
    );
  }

  renderPaymentInfoEdit = (): React.ReactNode => {
    return (
      <InfoCard>
        <Title>
          Payment Info
        </Title>
        <FieldView fieldName="Bank Name" />
        <FieldView fieldName="Bank Account No" />
      </InfoCard>
    );
  }

  render(): React.ReactNode {
    const driverProfileList = this.props.location.state
      && this.props.driverProfile.id || '';
    const driverId = this.props.driverProfile && this.props.driverProfile.id || '';
    const { error } = this.state;

    return (
      <SysAdminMainContainer selected="drivers">
        <Breadcrumb
          items={[
            { text: 'Drivers', onClick: (): void => { this.props.history.push(`/sys/drivers?${driverProfileList}`); } },
            { text: driverId, onClick: (): void => { this.props.history.push(`/sys/drivers/${driverId}`); } },
            { text: 'Edit' },
          ]}
        />
        {this.renderHeading()}
        <Container>
          <MainColumn
            flex="2"
          >
            {this.renderPersonalInfoEdit()}
            <Row>
              {this.renderVehicleInfoEdit()}
              {this.renderPaymentInfoEdit()}
            </Row>
          </MainColumn>
          <MainColumn
            flex="1"
          >
            <DriverInfoCard driverProfile={this.props.driverProfile} />
            <DriverPerformanceCard />
          </MainColumn>
        </Container>
        {error && (
          <CenterModal
            rightButtonText="Refresh"
            rightButtonOnClick={(): void => { window.location.reload(); }}
          >
            {error}
          </CenterModal>
        )}
      </SysAdminMainContainer>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    driverProfile: state.driverProfile.profile,
  };
}

export default connect(mapStateToProps)(DriverEdit);