import * as React from 'react';
import { format } from 'date-fns';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';
import { Receiver, Endorse, EpodStatus } from 'models/task/epod';
import { Contact } from 'models/task';
import Divider from './divider';

interface EndorseSectionProps {
  epodCreatedAt?: Date;
  epodStatus: EpodStatus;
  receiver: Receiver | null;
  endorse: Endorse;
  toContact: Contact;
  epodRemarks: string | null;
}
const EndorseSection = ({
  epodStatus,
  receiver,
  endorse,
  toContact,
  epodRemarks,
}: EndorseSectionProps) => {
  const isEndorsed = epodStatus !== 'to_be_acknowledged' && endorse !== null;
  if (!isEndorsed) {
    return null;
  }
  const endorsedDate = format(new Date(endorse.endorse_at), 'dd MMM yyyy, HH:mm');

  return (
    <View
      break
      style={{
        flexDirection: 'column',
        marginBottom: 16,
        width: '100%',
      }}
    >
      <Text style={[styles.titleSmall, { marginBottom: 8 }]}>
        Complete - Endorsed on {endorsedDate}
      </Text>
      <Divider />
      {!receiver ? (
        <Text style={[styles.textMedium, { marginTop: 6 }]}>
          Automatically marked complete
        </Text>
      ) : (
        <>
          <Text style={[styles.textMedium, { marginTop: 6 }]}>{receiver.full_name}</Text>
          <Text style={[styles.textMedium, { marginTop: 4 }]}>
            {receiver.rank} {toContact.vessel_name ? `of ${toContact.vessel_name}` : null}
          </Text>
          <Text style={[styles.textMedium, { marginTop: 4 }]}>{receiver.email}</Text>
          {epodRemarks && (
            <>
              <Text style={[styles.titleSmall, { marginTop: 12 }]}>Remarks</Text>
              <Text style={[styles.textMedium]}>{epodRemarks}</Text>
            </>
          )}
        </>
      )}
    </View>
  );
};

export default EndorseSection;
