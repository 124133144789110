import * as React from 'react';
import styled from 'styled-components';
import COLOR, { transparent } from 'constants/color';
import merchantLogo from 'assets/images/merchant_logo.png';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${COLOR.white} !important;
  }
`;

export const HEIGHTS = {
  auto: 'auto',
  full: '100%',
};

interface ContainerProps {
  children: React.ReactNode;
  height?: keyof typeof HEIGHTS;
}

interface ContainerState {
  margin: number;
}

class Container extends React.Component<ContainerProps, ContainerState> {
  constructor(props: ContainerProps) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      <>
        <GlobalStyle />
        <Header>
          <ButtonContainer>
            <LogoContainer>
              <Logo src={merchantLogo} />
            </LogoContainer>
          </ButtonContainer>
        </Header>
        <MainContainer height={this.props.height}>
          {this.props.children}
        </MainContainer>
      </>
    );
  }
}

const MainContainer = styled.div<{ height?: keyof typeof HEIGHTS }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${(props): string => (props.height === 'auto') ? '2.3rem' : '0'};
  height: ${(props): string => props.height ? HEIGHTS[props.height] : HEIGHTS.auto};
`;

const Logo = styled.img`
  width: 12rem;
  height: 2.3rem;
`;

const ButtonContainer = styled.div`
  background-color: ${COLOR.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  box-shadow: 0px 4px 6px ${transparent('black', 0.05)};
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  z-index: 9999;
`;

export default Container;