import { PriceAdjustment, PriceAdjustmentLog } from 'models/priceAdjustment';

export interface PriceAdjustmentState {
  priceAdjustmentLogs: PriceAdjustmentLog[];
  priceAdjustments: PriceAdjustment[];
  priceAdjustment: PriceAdjustment | null;
}

export const PRICE_ADJUSTMENT_LOGS = 'PRICE_ADJUSTMENT_LOGS';
export const PRICE_ADJUSTMENTS = 'PRICE_ADJUSTMENTS';
export const PRICE_ADJUSTMENT = 'PRICE_ADJUSTMENT';

interface ReceivePriceAdjustmentLogsAction {
  type: typeof PRICE_ADJUSTMENT_LOGS;
  priceAdjustmentLogs: PriceAdjustmentLog[];
}

interface ReceivePriceAdjustmentsAction {
  type: typeof PRICE_ADJUSTMENTS;
  priceAdjustments: PriceAdjustment[];
}

interface ReceivePriceAdjustmentAction {
  type: typeof PRICE_ADJUSTMENT;
  priceAdjustment: PriceAdjustment;
}

export type PriceAdjustmentActionTypes = ReceivePriceAdjustmentLogsAction | ReceivePriceAdjustmentsAction | ReceivePriceAdjustmentAction;
