import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import {
  receiveRunsheetGenerationJobs,
  receiveRunsheetGenerationJob,
} from 'reduxActions/runsheetGeneration/runsheetGenerationActions';
import { AddTaskToRunsheetGenerationRequest } from 'models/task';
import {
  RunsheetGenerationJob,
  AddTaskToRunsheetGenerationResponse,
  MetaRunsheetGenerationJobs,
} from 'models/runsheetGeneration';
import { getBearerToken } from 'utils/userHelper';
import { PER_PAGE } from 'constants/paginationMeta';

class RunsheetGenerationClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.RUNSHEET_GENERATION_SERVICE_PROTOCOL;
    this.host = process.env.RUNSHEET_GENERATION_SERVICE_HOST;
    this.port = process.env.RUNSHEET_GENERATION_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  runsheetGenerationUrl(): string {
    return `${this.getBaseUrl()}/runsheet-generation`;
  }

  getRunsheetGenerationsUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/runsheet-generation?${params}`;
  }

  runsheetGenerationWithIdUrl(id: string): string {
    return `${this.getBaseUrl()}/runsheet-generation/${id}`;
  }

  addTaskToRunsheetGenerationUrl(id: string): string {
    return `${this.getBaseUrl()}/runsheet-generation/${id}/task`;
  }

  deleteTaskFromRunsheetGenerationUrl(id: string, taskId: string): string {
    return `${this.getBaseUrl()}/runsheet-generation/${id}/task/${taskId}`;
  }

  startJobUrl(id: string): string {
    return `${this.getBaseUrl()}/runsheet-generation/${id}/start`;
  }

  async getJobs(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.getRunsheetGenerationsUrl(params));

    try {
      const response: AxiosResponse<MetaRunsheetGenerationJobs> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheetGenerationJobs(response.data.runsheet_generation_jobs));
    } catch (error) {
      throw error.message;
    }
  }

  async createRunsheetGenerationJob(name: string, strategy: string): Promise<void> {
    const request = this.makeRequest('POST', this.runsheetGenerationUrl(), {
      name,
      strategy,
      strategy_config: {},//not set up yet
    });

    try {
      await axios(request);
    } catch (error) {
      throw error.message;
    }
  }

  async editRunsheetGenerationJob(id: string, name: string, strategy: string): Promise<void> {
    const request = this.makeRequest('PUT', this.runsheetGenerationWithIdUrl(id), {
      name,
      strategy,
      strategy_config: {}, // not set up yet
    });

    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async getRunsheetGeneration(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.runsheetGenerationWithIdUrl(id));

    try {
      const response: AxiosResponse<RunsheetGenerationJob> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheetGenerationJob(response.data));
    } catch (error) {
      throw error.message;
    }
  }

  async addTaskToRunsheetGeneration(id: string, addTaskRequest: AddTaskToRunsheetGenerationRequest): Promise<AddTaskToRunsheetGenerationResponse> {
    const request = this.makeRequest('POST', this.addTaskToRunsheetGenerationUrl(id), addTaskRequest);
    try {
      const response: AxiosResponse<AddTaskToRunsheetGenerationResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  async deleteTaskFromRunsheetGeneration(id: string, taskId: string, versionRev: string): Promise<void> {
    const request = this.makeRequest('DELETE', this.deleteTaskFromRunsheetGenerationUrl(id, taskId), {
      version_rev: versionRev,
    });

    try {
      await axios(request);
    } catch (error) {
      throw error.message;
    }
  }

  async startJob(id: string, versionRev: string): Promise<void> {
    const request = this.makeRequest('PUT', this.startJobUrl(id), { version_rev: versionRev });
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.error;
      }
    }
  }
}

export default RunsheetGenerationClient;
