import * as React from 'react';
import { Button, TextField, Message, Dropdown } from 'components';
import styled from 'styled-components';
import * as H from 'history';
import AuthClient from 'httpClients/authClient';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import RoleClient from 'httpClients/roleClient';
import { SysUserInvitationForm } from 'models/auth';
import { Organization } from 'models/organization';
import { Role } from 'models/role';
import { formatError } from 'utils/formatter';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import configureStore from 'reduxActions/store';
import { receiveOrganizations } from 'reduxActions/organization/organizationActions';

interface StateProps {
  organizations: Organization[];
  roles: Role[];
}

interface HistoryProps<S = H.LocationState> {
  history: H.History<S>;
}

interface SysAdminUserInvitationState {
  form: {
    email: string;
    orgId: string;
    roleId: string;
  };
  error: string | null;
  successMessage: string | null;
}

type Props = HistoryProps & StateProps;

class SysAdminUserInvitation extends React.Component<Props, SysAdminUserInvitationState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        email: '',
        orgId: '',
        roleId: '',
      },
      error: null,
      successMessage: null,
    };
  }

  async componentDidMount(): Promise<void> {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(new URLSearchParams({ page: pageNum.toString() }));
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch (e) {
      //
    }
  }

  fetchRoles = async (orgId: string): Promise<void> => {
    const roleClient = new RoleClient();
    await roleClient.getRoles(orgId);
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const { form } = this.state;

    this.setState({ error: null, successMessage: null });

    try {
      const client = new AuthClient();
      const sysUserInvitationForm: SysUserInvitationForm = {
        user: {
          email: form.email,
          org_id: form.orgId,
          role_id: form.roleId,
        },
      };

      const response = await client.sysInviteOperator(sysUserInvitationForm);

      this.setState({ successMessage: response.status });
    } catch (e) {
      this.setState({ error: formatError(e) });
    }
  }

  render(): React.ReactNode {
    const { form, error, successMessage } = this.state;
    const { organizations, roles } = this.props;

    return (
      <Container>
        <h1>Invite User</h1>
        {error !== null ? (
          <Message className='error'>{error}</Message>
        ) : false}
        {successMessage !== null ? (
          <Message>{successMessage}</Message>
        ) : false}
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Label>Email</Label>
            <TextField
              type="text"
              name="email"
              value={form.email}
              onChange={(e): void => this.handleChange('email', e.target.value)}
            />
          </Row>
          <Row>
            <Dropdown
              label="User Organization "
              onChange={(value: string): void => {
                this.handleChange('orgId', value);
                this.handleChange('roleId', '');
                this.fetchRoles(value);
              }}
              value={form.orgId}
              options={organizations.map(org => (
                {
                  value: org.id,
                  name: org.business_name,
                }
              ))}
            />
          </Row>
          <Row>
            <Dropdown
              label="User Role "
              onChange={(value: string): void => {
                this.handleChange('roleId', value);
              }}
              value={form.roleId}
              options={roles.map(role => (
                {
                  value: role.id,
                  name: role.role_name,
                }
              ))}
            />
          </Row>
          <>
            <Button type="submit">Invite</Button>
          </>
        </form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 25rem;
`;

const Row = styled.div`
  padding-bottom: 1rem;
`;

const Label = styled.label`
  width: 18rem;
  float: left;
  margin-top: 1rem;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  organizations: state.organization.organizations,
  roles: state.role.roles,
});

export default connect(mapStateToProps)(SysAdminUserInvitation);
