import styled from 'styled-components';
import COLOR from 'constants/color';

interface ButtonProps {
  disabled?: boolean;
  color?: string;
}

// This component is deprecated
// This component should be removed when all pages are using StyledButton
export default styled.button<ButtonProps>`
  border-radius: 3px;
  border: 2px solid ${(props): string => props.disabled ? COLOR.neutral : props.color || COLOR.red2};
  margin: 0.5em 0.5em;
  padding: 0.25em 1em;
  background: ${(props): string => props.disabled ? COLOR.neutral : props.color || COLOR.red2};
  color: ${(props): string => props.disabled ? COLOR.grey : COLOR.white};
  cursor: ${(props): string => props.disabled ? 'not-allowed' : 'pointer'};
`;