import { Typography } from 'components';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import COLOR, { transparent } from 'constants/color';
import theme from 'theme';

interface EpodStatusColorValue {
  backgroundColor: string;
  textColor: string;
  circleColor: string;
}

interface EpodStatusColorObject {
  to_be_acknowledged: EpodStatusColorValue;
  partially_complete: EpodStatusColorValue;
  declined: EpodStatusColorValue;
  complete: EpodStatusColorValue;
}

export const EpodStatusColor: EpodStatusColorObject = {
  to_be_acknowledged: {
    backgroundColor: theme.colors.warning_50,
    textColor: theme.colors.warning_700,
    circleColor: theme.colors.warning_500,
  },
  partially_complete: {
    backgroundColor: theme.colors.error_50,
    textColor: theme.colors.error_700,
    circleColor: theme.colors.error_500,
  },
  declined: {
    backgroundColor: theme.colors.error_50,
    textColor: theme.colors.error_700,
    circleColor: theme.colors.error_500,
  },
  complete: {
    backgroundColor: theme.colors.success_50,
    textColor: theme.colors.success_700,
    circleColor: theme.colors.success_500,
  },
};

export const Container = styled.div`
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const ReceiptContainer = styled(Container)`
  padding-bottom: 5rem;
`;

export const BottomWrapper = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ReceiptCard = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.75rem;
  box-shadow: 0px 1px 1px 2px rgba(222, 222, 222, 0.06),
    0px 2px 6px 0px ${(props): string => props.theme.colors.gray_200};
  @media (min-width: 767px) {
    padding: 2rem;
  }
`;

export const ReceiptTitle = styled(Typography)`
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: ${(props): string => props.theme.fontSizes.display_sm};
  @media (max-width: 768px) {
    font-size: ${(props): string => props.theme.fontSizes.md};
  }
`;

interface ReceiptStatusProps {
  backgroundColor: string;
  textColor: string;
  circleColor: string;
}

export const ReceiptStatusChip = styled.span<ReceiptStatusProps>`
  display: inline-flex;
  align-items: center;
  padding: 0.125rem 0.5rem 0.125rem 0.375rem;
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.textColor};
  border-radius: 1rem;
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 1.125rem;

  &::before {
    content: '';
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: ${(props): string => props.circleColor};
    margin-right: 6px;
  }
`;

export const OrderIdAndViewPODWrapper = styled.div`
  line-height: 1.6;
`;

export const SectionTitleLarge = styled(Typography)`
  font-size: ${(props): string => props.theme.fontSizes.lg};
  margin: 0;
`;

interface StackProps {
  gap?: string;
  direction?: string;
  wrap?: boolean;
  mdScreenStyle?: FlattenSimpleInterpolation;
}

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-wrap: ${(props): string => (props.wrap ? 'wrap' : 'nowrap')};
  flex-direction: ${(props): string => (props.direction ? props.direction : 'row')};
  gap: ${(props): string => props.gap && props.gap};
  @media (min-width: 768px) {
    ${(props): FlattenSimpleInterpolation => props.mdScreenStyle}
  }
`;

export const CareofAndReceiverSection = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex: 0 0 100% !important;
  }
`;

export const LeftSection = styled(CareofAndReceiverSection)`
  flex-direction: column;
  flex: 0 0 45%;
  gap: 1rem;
`;

export const RightSection = styled(CareofAndReceiverSection)`
  flex-direction: column;
  flex: 0 0 55%;
  gap: 1rem;
`;

export const LinkText = styled.a`
  display: block;
  color: ${(props): string => props.theme.colors.primary_600};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

export const InlineLinkText = styled(LinkText)`
  display: inline;
`;

export const InputErrorMessage = styled.span`
  display: block;
  color: ${COLOR.red};
  font-size: ${(props): string => props.theme.fontSizes.xs};
  margin-top: 1px;
`;

export const RadioWrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  // override the style of the radio group
  > div {
    > div {
      font-size: ${(props): string => props.theme.fontSizes.sm};
      margin-bottom: 1rem;
    }
  }
  div {
    color: ${(props): string => props.theme.colors.gray_700};
  }
`;

export const RedText = styled.span`
  color: ${COLOR.red};
`;

export const ActionBottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 4rem;
  border-top: 1px solid ${(props): string => props.theme.colors.gray_200};
  background-color: ${COLOR.whiteGrey};
  box-shadow: 0px 8px 18px ${transparent('shadow', 80)};
`;

interface DividerProps {
  isHidden?: boolean;
  marginTopBottom?: string;
  marginBottom?: string;
}

export const Divider = styled.hr<DividerProps>`
  margin: 0;
  height: 1px;
  width: 100%;
  border: none;
  background-color: ${(props): string => props.theme.colors.gray_200};
  visibility: ${(props): string => props.isHidden && 'hidden'};
  margin: ${(props): string =>
    props.marginTopBottom && `${props.marginTopBottom} 0 ${props.marginTopBottom} 0`};
  margin-bottom: ${(props): string => props.marginBottom && props.marginBottom};
`;
