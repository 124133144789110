import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';

interface Action<T> {
  type: string;
  callback: (eventData: T) => void;
}

interface PusherError {
  type: string;
  error: string;
  status: string;
}

let pusher: Pusher | null = null;
let channels: PusherTypes.Channel[] = [];
const actions: Action<unknown>[] = [];

function addAction<T>(action: Action<T>): void {
  if (channels.length > 0 && pusher !== null) {
    pusher.bind(action.type, action.callback);
  }
}


function updateActions(): void {
  actions.forEach((action) => {
    addAction(action);
  });
}

class PusherHelper {
  static init(key: string, cluster: string): void {
    try {
      pusher = new Pusher(key, { cluster });

      pusher.connection.bind('connected', () => {
        // eslint-disable-next-line no-console
        console.log({
          'event': 'Connected to pusher',
        });
      });

      pusher.connection.bind('error', (error: string) => {
        // eslint-disable-next-line no-console
        console.log({
          'event': 'Pusher connection error',
          'error': error,
        });
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log({
        'event': 'Error in pusherHelper init function',
        'error': e,
      });
      // Retry the connection
      this.init(key, cluster);
    }
  }

  static subscribe(channelNames: string[]): void {
    if (channels.length > 0) {
      channels.forEach((channel: PusherTypes.Channel) => channel.unsubscribe());
    }

    // eslint-disable-next-line no-console
    console.log({
      event: 'Subscribe to pusher channels',
      channels: channelNames,
    });

    channelNames.forEach((channelName) => {
      if (pusher !== null) {
        const channel = pusher.subscribe(channelName);

        channel.bind('pusher:subscription_succeeded', () => {
          // eslint-disable-next-line no-console
          console.log({
            'event': `Successful Pusher subscription to channel ${channelName}`,
            'channel': channelName,
          });
        });

        channel.bind('pusher:subscription_error', (err: PusherError) => {
          // eslint-disable-next-line no-console
          console.log({
            'event': `Failed Pusher subscription to channel ${channelName}`,
            'channel': channelName,
            'error': err.error,
            'status': err.status,
            'type': err.type,
          });
        });

        channels.push(channel);
      }
    });
    updateActions();
  }

  static unsubscribe(): void {
    if (channels.length > 0) {
      channels.forEach(channel => channel.unsubscribe());
      channels = [];
    }
  }

  static bindChannel<T>(action: Action<T>): void {
    actions.push(action);
    addAction(action);
  }
}

export default PusherHelper;
