import { TaskImportJob, TaskImportJobResult } from 'models/taskImport';

export interface TaskImportHash {
  [index: string]: TaskImportJob;
}

export interface TaskImportState {
  taskImportHash: TaskImportHash;
  taskImportIds: string[];
}

export const RECEIVE_TASK_IMPORT_JOBS = 'RECEIVE_TASK_IMPORT_JOBS';
export const RECEIVE_TASK_IMPORT_JOB = 'RECEIVE_TASK_IMPORT_JOB';
export const RECEIVE_TASK_IMPORT_JOB_RESULT = 'RECEIVE_TASK_IMPORT_JOB_RESULT';
export const UPDATE_IN_PROGRESS_STATUS = 'UPDATE_IN_PROGRESS_STATUS';

export interface ReceiveTaskImportJobs {
  type: typeof RECEIVE_TASK_IMPORT_JOBS;
  taskImportJobs: TaskImportJob[];
}

export interface ReceiveTaskImportJob {
  type: typeof RECEIVE_TASK_IMPORT_JOB;
  taskImportJob: TaskImportJob;
}

export interface ReceiveTaskImportJobResult {
  type: typeof RECEIVE_TASK_IMPORT_JOB_RESULT;
  taskImportJobResult: TaskImportJobResult;
}

export interface UpdateInProgressStatus {
  type: typeof UPDATE_IN_PROGRESS_STATUS;
  id: string;
}

export type TaskImportActionTypes = ReceiveTaskImportJobs | ReceiveTaskImportJob
  | ReceiveTaskImportJobResult | UpdateInProgressStatus;
