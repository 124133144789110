import * as React from 'react';
import styled from 'styled-components';
import { PER_PAGE } from 'constants/paginationMeta';
import { capitalizeEachWord } from 'utils/formatter';
import {
  QueryValidation,
  PaginationProps,
  PaginationState,
  BasicModel,
} from './paginationTypes';
import MainContainer from './mainContainer';
import Breadcrumb from './breadcrumb';
import Dropdown from '../input/dropdown';
import StyledButton from '../button/styledButton';
import NavigationArrowButton from '../button/navigationArrowButton';
import COLOR, { transparent } from 'constants/color';

class Pagination<
  Model extends BasicModel,
  Props extends PaginationProps<Model>,
  State extends PaginationState<Model> = PaginationState<Model>
> extends React.Component<Props, State> {
  rules: QueryValidation[];

  constructor(props: Props) {
    super(props);

    this.state = ({
      basePath: '',
      breadcrumb: [],
      filters: this.getDefaultFilter(),
      isFetching: false,
      multipleSelectButtons: [],
      pluralModelName: '',
      selected: {},
      selectedTab: '',
      renderContainer: true,
      renderSortBy: true,
      isContentNoBorder: false,
    } as PaginationState<Model>) as State;

    this.rules = [
      {
        name: 'page',
      },
      {
        name: 'order',
      },
    ];
  }

  componentDidMount(): void {
    // Update state with the existing query params
    const params = new URLSearchParams(this.props.location.search).entries();
    const queryFilters: Record<string, string> = {};

    for (const param of params) {
      queryFilters[param[0]] = param[1];
    }

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          ...queryFilters,
        },
      }),
      this.updateQueryString
    );
  }

  async componentDidUpdate(prevProp: Props, prevState: State): Promise<void> {
    if (this.isQueryParamsInvalid()) {
      this.setState({ filters: this.getDefaultFilter() }, (): void => {
        this.props.history.push(this.state.basePath);
      });
    } else if (
      this.props.history.location.search === '' &&
      this.props.history.location.pathname === this.state.basePath
    ) {
      // Update filters if the user click the header tab
      this.updateFilters(this.getDefaultFilter());
    } else if (this.state.filters !== prevState.filters) {
      await this.fetchData();
    } else {
      const queryStringParams = new URLSearchParams(this.props.location.search).entries();
      const queryFilters: Record<string, string> = {};
      let isDiff = false;
      let count = 0;

      for (const param of queryStringParams) {
        count++;
        queryFilters[param[0]] = param[1];

        if (this.state.filters[param[0]] !== param[1]) {
          isDiff = true;
        }
      }

      // Update filters if the user change the url query string
      if (isDiff || count !== Object.keys(this.state.filters).length) {
        this.updateFilters(queryFilters);
      }
    }
  }

  updateFilters = (newFilters: Record<string, string>): void => {
    this.setState({ filters: newFilters }, this.updateQueryString);
  };

  // Override this function if you have more default filters
  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
    order: 'desc',
  });

  isQueryParamsInvalid = (): boolean => {
    const { filters } = this.state;

    return !this.rules.every((rule) => {
      const selectedParam = filters[rule.name];

      if (selectedParam) {
        switch (rule.name) {
          case 'page': {
            const page = parseInt(selectedParam);
            return !isNaN(page) && page >= 1;
          }
          case 'order':
            return selectedParam === 'desc' || selectedParam === 'asc';
          default:
            return rule.validate(selectedParam);
        }
      } else {
        return true;
      }
    });
  };

  fetchData = async (): Promise<void> => {
    // Overwrite this function to fetch data
    // Update isFetching before and after fetch data and when handling error
  };

  onFilterChange = (fieldName: string, value: string, isDelete = false): void => {
    this.setState((prevState) => {
      const newFilters: Record<string, string> = { ...prevState.filters };

      if (isDelete) {
        delete newFilters[fieldName];
      } else {
        newFilters[fieldName] = value;
      }

      if (fieldName !== 'page') {
        newFilters.page = '1';
      }

      return {
        filters: newFilters,
      };
    }, this.updateQueryString);
  };

  updateQueryString = (): void => {
    const queryParams = new URLSearchParams(this.state.filters);

    // If user moved to other pages, don't push to router history
    if (this.props.history.location.pathname === this.state.basePath) {
      const currentPageNum = new URLSearchParams(this.props.location.search).get('page');
      if (this.props.location.search === '' || this.state.filters.pagination === '1') {
        this.props.history.replace(`${this.state.basePath}?${queryParams}`);
      } else if (currentPageNum !== this.state.filters.pagination) {
        this.props.history.push(`${this.state.basePath}?${queryParams}`);
      }
    }
  };

  toggleSelectAll = (): void => {
    const count = Object.keys(this.state.selected).length;

    if (count < this.props.models.length) {
      this.setState({
        selected: this.props.models.reduce<{ [key: string]: Model }>((acc, model): {
          [key: string]: Model;
        } => {
          acc[model.id] = model;
          return acc;
        }, {}),
      });
    } else {
      this.setState({ selected: {} });
    }
  };

  toggleSelect = (index: number): void => {
    const model = this.props.models[index];

    this.setState((prevState) => {
      const newSelected: { [key: string]: Model } = { ...prevState.selected };

      if (newSelected[model.id]) {
        delete newSelected[model.id];
      } else {
        newSelected[model.id] = model;
      }

      return { selected: newSelected };
    });
  };

  renderFilterAndPagination = (): React.ReactNode => {
    const page = parseInt(this.state.filters.page);

    const numberOfModel = this.props.models.length;

    const numStart = PER_PAGE * (page - 1) + 1;
    const numEnd = PER_PAGE * (page - 1) + numberOfModel;

    return (
      <FilterMainContainer>
        <FilterContainer>
          {this.renderFilter()}
          {this.state.renderSortBy ? (
            <Dropdown
              label="Sort by"
              options={[
                {
                  value: 'desc',
                  name: 'Newest First',
                },
                {
                  value: 'asc',
                  name: 'Oldest First',
                },
              ]}
              onChange={(value: string): void => {
                this.onFilterChange('order', value);
              }}
              value={this.state.filters.order}
            />
          ) : (
            false
          )}
        </FilterContainer>
        <PaginationContainer>
          {!this.state.isFetching && numberOfModel > 0 && `${numStart} - ${numEnd}`}
          <NavigationArrowButton
            disabled={page <= 1}
            onClick={(): void => {
              if (page > 1) {
                this.onFilterChange('page', `${page - 1}`);
              }
            }}
            type="left"
          />
          <NavigationArrowButton
            disabled={numberOfModel < PER_PAGE}
            onClick={(): void => {
              if (numberOfModel >= PER_PAGE) {
                this.onFilterChange('page', `${page + 1}`);
              }
            }}
            type="right"
          />
        </PaginationContainer>
      </FilterMainContainer>
    );
  };

  renderActionPanel = (): React.ReactNode => {
    // Overwrite this function to add more component in header
    return false;
  };

  renderFilter = (): React.ReactNode => {
    // Overwrite this function to render filter component
    return false;
  };

  renderContent = (): React.ReactNode => {
    // Overwrite this function to render main content
    return false;
  };

  renderEmptyState = (): React.ReactNode => {
    return false;
  };

  renderBreadcrumb = (): React.ReactNode => {
    return <Breadcrumb items={this.state.breadcrumb} />;
  };

  renderMultipleSelectActions = (): React.ReactNode => {
    const ids = Object.keys(this.state.selected);
    const isAllSelected = ids.length === this.props.models.length;

    return (
      <MultipleSelectActionContainer>
        <div>
          {`${isAllSelected ? 'All ' : ''}${ids.length} ${
            this.state.pluralModelName
          } on this page are selected.`}
        </div>
        <SelectLink onClick={this.toggleSelectAll}>
          {isAllSelected
            ? `Deselect all ${this.props.models.length} ${this.state.pluralModelName}`
            : `Select all ${this.props.models.length} ${this.state.pluralModelName}`}
        </SelectLink>
        <div>
          {this.state.multipleSelectButtons.map((button) => (
            <StyledButton key={button.text} onClick={button.onClick}>
              {button.text}
            </StyledButton>
          ))}
        </div>
      </MultipleSelectActionContainer>
    );
  };

  renderHeader = (): React.ReactNode => (
    <TableHeader>
      <Title>{capitalizeEachWord(this.state.pluralModelName)}</Title>
      {this.props.models.length > 0 && this.renderActionPanel()}
    </TableHeader>
  );

  render(): React.ReactNode {
    const selectedCount = Object.keys(this.state.selected).length;

    return (
      <>
        {this.state.renderContainer ? (
          <MainContainer selected={this.state.selectedTab}>
            {this.renderBreadcrumb()}
            {this.renderHeader()}
            {this.props.models.length > 0 && this.renderFilterAndPagination()}
            <ContentContainer isContentNoBorder={this.state.isContentNoBorder}>
              {this.props.models.length === 0
                ? this.renderEmptyState()
                : this.renderContent()}
            </ContentContainer>
            {selectedCount > 0 ? this.renderMultipleSelectActions() : false}
          </MainContainer>
        ) : (
          <>
            {this.props.models.length === 0
              ? this.renderEmptyState()
              : this.renderContent()}
            {selectedCount > 0 ? this.renderMultipleSelectActions() : false}
          </>
        )}
      </>
    );
  }
}

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: 0.75rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ContentContainer = styled.div<{ isContentNoBorder?: boolean }>`
  border-radius: ${({ isContentNoBorder }): string => !isContentNoBorder && '0.5rem'};
  border: ${({ isContentNoBorder }): string =>
    !isContentNoBorder && `1px solid ${COLOR.neutral}`};
  padding: ${({ isContentNoBorder }): string => !isContentNoBorder && '0 1rem'};
  background-color: ${COLOR.white};
  flex: 1;
`;

const TableHeader = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

const FilterContainer = styled.div`
  display: flex;
`;

const FilterMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.75rem;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const MultipleSelectActionContainer = styled.div`
  background-color: ${COLOR.whiteGrey};
  border-radius: 8px;
  bottom: 3rem;
  box-shadow: 0px 4px 16px ${transparent('shadow', 80)};
  left: 1.5rem;
  padding: 0.5rem 1rem;
  position: fixed;
  width: calc(100% - 5rem);
  z-index: 14;
`;

const SelectLink = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  margin-bottom: 0.375rem;
`;

export default Pagination;
