import * as React from 'react';
import { StyledButton } from 'components';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

interface Option {
  text: string;
  value: string;
}

interface Props {
  title?: string | React.ReactNode;
  label?: string | React.ReactNode;
  options: Option[];
  value: string;
  onChange: (v: string) => void;
  containerStyle?: FlattenSimpleInterpolation;
  disabled?: boolean;
}

const RadioButton = (props: Props): JSX.Element => {
  return (
    <>
      {props.label ? (
        <Label>
          {props.label} <RedText>*</RedText>
        </Label>
      ) : (
        false
      )}
      <Container containerStyle={props.containerStyle}>
        {props.title ? (
          <Title>
            {props.title} <RedText>*</RedText>
          </Title>
        ) : (
          false
        )}
        <RadioButtonsContainer>
          {(props.options || []).map((option) => (
            <Radio
              key={option.value}
              buttonStyle="encourage"
              buttonType="neutral"
              active={props.value === option.value}
              onClick={(): void => props.onChange(option.value)}
              type="button"
              disabled={props.disabled && props.value !== option.value}
            >
              {option.text}
            </Radio>
          ))}
        </RadioButtonsContainer>
      </Container>
    </>
  );
};

export default RadioButton;

const Title = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-right: auto;
  color: ${(props): string => props.theme.colors.gray_900};
`;

const Container = styled.div<{ containerStyle?: FlattenSimpleInterpolation }>`
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Radio = styled(StyledButton)<{ active: boolean }>`
  padding: 10px 16px;
  box-shadow: none;

  &:disabled {
    background-color: ${(props): string => props.theme.colors.gray_50};
  }

  ${(props) => {
    const activeStyles = css`
      background-color: ${props.theme.colors.gray_50};
      border: 1px solid ${props.theme.colors.primary_600};

      &:hover {
        background-color: ${props.theme.colors.gray_50};
        border: 1px solid ${props.theme.colors.primary_600};
      }
    `;

    return props.active ? activeStyles : null;
  }}
`;

const Label = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-right: auto;
  color: ${(props): string => props.theme.colors.gray_900};
  margin-bottom: 0.25rem;
`;

const RedText = styled.span`
  color: ${(props): string => props.theme.colors.error_500};
`;
