import { Task } from 'models/task';

export interface TaskState {
  tasks: Task[];
  task: Task;
}

export const RECEIVE_TASKS = 'RECEIVE_TASKS';
export const RECEIVE_TASK = 'RECEIVE_TASK';

interface ReceiveTasksAction {
  type: typeof RECEIVE_TASKS;
  tasks: Task[];
}

interface ReceiveTaskAction {
  type: typeof RECEIVE_TASK;
  task: Task;
}

export type TaskActionTypes = ReceiveTasksAction | ReceiveTaskAction;
