import * as React from 'react';
import {
  Separator,
  PageTitle,
  StyledButton,
  TextInput,
  CenterModal,
  Alert,
  MainContainer,
  Breadcrumb,
  Heading,
} from 'components';
import { Organization, CompanyDetailsForm } from 'models/organization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import COLOR from 'constants/color';
import styled from 'styled-components';
import defaultUserCompanyLogo from 'assets/images/default_user_company_logo.png';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import SettingSidebarMenu from '../../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import { CurrentUser } from 'models/auth';

interface CompanyDetailsProps {
  organization: Organization;
  currentUser: CurrentUser;
}

type Props = RouteComponentProps & CompanyDetailsProps;

interface Form {
  business_name: string;
  version_rev: string;
}

const getDefaultForm = (org: Organization): Form => {
  return {
    business_name: org && org.business_name ? org.business_name : '',
    version_rev: org && org.version_rev ? org.version_rev : '',
  };
};

const CompanyDetailsEdit = (props: Props): JSX.Element => {
  React.useEffect(() => {
    (async function (): Promise<void> {
      const client = new OrganizationManagementClient();
      await client.getOrganization();
    })();
  }, []);

  const { organization, currentUser } = props;
  const [form, setForm] = React.useState(getDefaultForm(organization));
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  const pattern = new RegExp(/\w*[a-zA-Z0-9]\w*/);
  const merchant = currentUser && currentUser.user.roles.includes('OrgAdmin') && 'Merchant';
  const transporter = currentUser && currentUser.user.roles.includes('OrgTransporter') && 'Transporter';
  const accountType = [merchant, transporter].filter(Boolean).join(' & ');

  React.useEffect(() => {
    setForm(getDefaultForm(organization));
  }, [organization]);

  const handleChange = (fieldName: string, value: string): void => {
    setForm(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
    setError('');
  };

  const save = async (form: CompanyDetailsForm): Promise<void> => {
    setError('');
    try {
      setFetching(true);
      const client = new OrganizationManagementClient();
      await client.updateCompanyDetails(form);
      setSuccess(true);
    } catch (e) {
      setError(e);
    }
    setFetching(false);
  };

  return (
    <MainContainer selected="setting">
      <Breadcrumb
        items={[
          { text: 'Setting', onClick: (): void => { props.history.push('/setting'); } },
          { text: 'Company details' },
        ]}
      />
      <Heading>
        <PageTitle>Setting</PageTitle>
      </Heading>
      <SettingSidebarMenu selected="/setting/company/company-details">
        <Heading>
          <PageTitle>
            Company Details
          </PageTitle>
          {organization && (
            <EditButtonContainer>
              <Button
                buttonStyle="encourage"
                buttonType="neutral"
                onClick={(): void => { props.history.push('/setting/company/company-details'); }}
                disabled={fetching}
              >
                <Icon icon={faTimes} />
                Cancel
              </Button>
              <StyledButton
                buttonStyle="encourage"
                buttonType="primary"
                disabled={
                  (form.business_name && form.business_name.length < 2) ||
                  !pattern.test(form.business_name) ||
                  (form.business_name && form.business_name.trim() === '') ||
                  fetching
                }
                onClick={(): void => { save({ ...form, version_rev: organization.version_rev }); }}
              >
                <Icon icon={faCheck} color={COLOR.white} />
                Save
              </StyledButton>
            </EditButtonContainer>
          )}
        </Heading>
        <CustomSeparator />
        {organization && (
          <FormContainer>
            <CompanyImageContainer>
              <CompanyImage src={defaultUserCompanyLogo} />
            </CompanyImageContainer>
            <FormInput>
              <TextInputContainer>
                <TextInput
                  fieldName="Company Name"
                  isRequired
                  name="business_name"
                  onTextChange={(value: string): void => handleChange('business_name', value)}
                  type="text"
                  width="auto"
                  value={form && form.business_name && form.business_name}
                  required
                />
                <TextInput
                  fieldName="Account Type"
                  name="account_type"
                  readOnly
                  type="text"
                  width="auto"
                  disabled
                  value={accountType}
                  required
                  isRequired
                />
                <div>
                  <FormHeading>Reference ID Prefix</FormHeading>
                  <NoteText>Enter your reference ID Prefix</NoteText>
                  <TextInput
                    readOnly
                    type="text"
                    width="auto"
                    disabled
                    value={(organization.prefix_id || 'GOTSURGE-')}
                    required
                    isRequired
                  />
                  <NoteText>Note: To customise the prefix, contact GotSurge</NoteText>
                </div>
                {error ? (
                  <ErrorMessage status='error'>
                    <AlertMessage>{error}</AlertMessage>
                  </ErrorMessage>
                ) : false}
              </TextInputContainer>
            </FormInput>
          </FormContainer>
        )}
        {success ? (
          <CenterModal
            rightButtonText="Ok"
            rightButtonOnClick={(): void => { props.history.push('/setting/company/company-details'); }}
            title="Company Details"
          >
            <Alert status='success'>
              <AlertMessage>Saved.</AlertMessage>
            </Alert>
          </CenterModal>
        ) : false}
      </SettingSidebarMenu>
    </MainContainer>
  );
};

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.5rem;
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
`;

const EditButtonContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyImageContainer = styled.div`
  display: flex;
  margin-top: 1.875rem;
`;

const CompanyImage = styled.img`
  width: 5.625rem;
  height: 5.625rem;
`;

const FormInput = styled.div`
  margin-top: 1rem;
`;

const AlertMessage = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

const ErrorMessage = styled(Alert)`
  margin-top: 1rem;
`;

const TextInputContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  @media only screen and (min-width: 768px) and (min-device-width: 768px){
    width: 100%;
  }

  @media only screen and (min-width: 992px){
    width: 50%;
  }

  @media only screen and (min-width: 1200px) {
    width: 35%;
  }
`;

const Button = styled(StyledButton)`
  margin-right: 1rem;
`;

const FormHeading = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

const NoteText = styled.div`
  line-height: 1.5rem;
  font-size: 0.75rem;
  color: ${COLOR.midDarkGrey};
`;

function mapStateToProps(state: RootState): CompanyDetailsProps {
  return {
    organization: state.organization.organization,
    currentUser: state.auth.currentUser,
  };
}

export default connect(mapStateToProps)(CompanyDetailsEdit);