import { UserWithTimestamp, CurrentUser } from 'models/auth';

export interface AuthState {
  currentUser: CurrentUser;
  users: UserWithTimestamp[];
  promptMessage: string;
  afterLoginPage: string;
}

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const RECEIVE_USER = 'RECEIVE_USER';
export const SET_AFTER_LOGIN_PAGE = 'SET_AFTER_LOGIN_PAGE';

export interface SigninSuccessAction {
  type: typeof SIGNIN_SUCCESS;
  payload: CurrentUser;
}

export interface SignoutSuccessAction {
  type: typeof SIGNOUT_SUCCESS;
  promptMessage: string;
  afterLoginPage: string;
}

export interface ReceiveUsersAction {
  type: typeof RECEIVE_USERS;
  users: UserWithTimestamp[];
}

export interface ReceiveUserAction {
  type: typeof RECEIVE_USER;
  user: UserWithTimestamp;
}

export interface SetAfterLoginPageAction {
  type: typeof SET_AFTER_LOGIN_PAGE;
  afterLoginPage: string;
}

export type AuthActionTypes = SigninSuccessAction | SignoutSuccessAction |
  ReceiveUsersAction | ReceiveUserAction | SetAfterLoginPageAction;
