import { TaskExport } from 'models/taskExport';
import {
  PlanningActionTypes, RECEIVE_TASK_EXPORT_JOB, RECEIVE_TASK_EXPORT_JOBS,
  TaskExportHash, TaskExportState,
} from './taskExportTypes';

const initialState: TaskExportState = {
  taskExportHash: {},
};

function combineJob(state: TaskExportState, job: TaskExport | TaskExport[]): TaskExportHash {
  const combinedJobs = { ...state.taskExportHash };

  if (job instanceof Array) {
    job.forEach(elem => {
      combinedJobs[elem.id] = {
        ...state.taskExportHash[elem.id],
        ...elem,
      };
    });
  } else {
    combinedJobs[job.id] = {
      ...state.taskExportHash[job.id],
      ...job,
    };
  }

  return combinedJobs;
}

function taskExportReducer(
  state: TaskExportState = initialState, action: PlanningActionTypes
): TaskExportState {
  switch (action.type) {
    case RECEIVE_TASK_EXPORT_JOBS:
      return {
        ...state,
        taskExportHash: combineJob(state, action.jobs),
      };
    case RECEIVE_TASK_EXPORT_JOB:
      return {
        ...state,
        taskExportHash: combineJob(state, action.job),
      };
    default:
      return state;
  }
}

export default taskExportReducer;
