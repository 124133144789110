import * as React from 'react';
import { CenterModal, ButtonWithIcon, Typography } from 'components';
import styled, { css } from 'styled-components';
import { VehiclePreferenceInfo } from 'constants/vehiclePreference';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import BadgeGroup from 'components/badge/badgeGroup';

interface VehicleModalProps {
  vehiclesPreferenceInfo: VehiclePreferenceInfo;
  closeModal: () => void;
  onVehicleClick: (vehicle: string) => void;
  selectedVehicle: string;
  disabled?: boolean;
  broadcastPreference: keyof typeof BROADCAST_PREFERENCES;
  displayColumnTabletAndDesktop?: 1 | 2;
  vehiclesExceedingDimensions?: string[];
}

class VehicleModal extends React.Component<VehicleModalProps> {
  static defaultProps = {
    displayColumnTabletAndDesktop: 1,
    isShowExceededDimensions: false,
  };

  render(): React.ReactNode {
    const { vehiclesPreferenceInfo, vehiclesExceedingDimensions } = this.props;
    const isMarine = this.props.broadcastPreference === BROADCAST_PREFERENCES.marine;
    const vehicleList = [];
    for (const [key, value] of Object.entries(vehiclesPreferenceInfo)) {
      value.isShown &&
        value.broadcastAvailability.includes(this.props.broadcastPreference) &&
        vehicleList.push({
          key: key,
          description: value.description,
          dimension: value.dimension,
          capacity: value.capacity,
          disabled: value.disabled,
        });
    }
    return (
      <CenterModal
        title="Select your vehicle"
        closeButtonOnClick={this.props.closeModal}
        width={this.props.displayColumnTabletAndDesktop === 2 ? 'medium' : 'small'}
        customWidthModal={!isMarine ? '43.6rem' : ''}
        position="bottom"
        hasScrollInside={this.props.displayColumnTabletAndDesktop === 2 ? true : false}
      >
        <VehiclesContainer displayColumn={this.props.displayColumnTabletAndDesktop}>
          {vehicleList.map((vehicle) => (
            <ButtonWithIcon
              key={vehicle.key}
              icon={<VehicleIcon src={vehiclesPreferenceInfo[vehicle.key].icon} />}
              isActive={this.props.selectedVehicle === vehicle.key}
              warningMode={
                !vehicle.disabled && vehiclesExceedingDimensions?.includes(vehicle.key)
              }
              disabled={
                vehicle.disabled ||
                (this.props.disabled && this.props.selectedVehicle !== vehicle.key)
              }
              mainText={
                <ButtonMainTextWrapper>
                  <Typography
                    as="h3"
                    color="gray_700"
                    weight="semibold"
                    size="md"
                    customStyle={{ marginRight: 'auto' }}
                  >
                    {vehicle.description}
                  </Typography>
                  {!vehicle.disabled &&
                    vehiclesExceedingDimensions?.includes(vehicle.key) && (
                      <BadgeGroup
                        customStyle={css`
                          padding: 0.25rem 0.5rem 0.25rem 0.5rem;
                        `}
                        type="warning"
                      >
                        Dimensions Exceeded
                      </BadgeGroup>
                    )}
                </ButtonMainTextWrapper>
              }
              description={
                <>
                  <Typography
                    as="p"
                    color="gray_600"
                    weight="normal"
                    size="sm"
                    customStyle={{ margin: '0.25rem auto 0.25rem 0', textAlign: 'left' }}
                  >
                    {vehicle.dimension}
                  </Typography>
                  <Typography
                    as="p"
                    color="gray_600"
                    weight="normal"
                    size="sm"
                    customStyle={{ marginRight: 'auto', textAlign: 'left' }}
                  >
                    {vehicle.capacity}
                  </Typography>
                </>
              }
              onClick={(): void => {
                this.props.onVehicleClick(vehicle.key);
                this.props.closeModal();
              }}
            />
          ))}
        </VehiclesContainer>
      </CenterModal>
    );
  }
}

const VehiclesContainer = styled.div<{ displayColumn: 1 | 2 }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;
  width: 100%;

  @media (min-width: 650px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 918px) {
    grid-template-columns: ${({ displayColumn }) =>
      displayColumn === 1 ? '1fr' : '1fr 1fr'};
  }
`;

const VehicleIcon = styled.img`
  width: 62px;
  height: 28px;
`;

const ButtonMainTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    & > div {
      order: -1;
      margin-left: -2px;
    }
  }
`;

export default VehicleModal;
