import { BroadcastRecord } from 'models/broadcastRecord';

export interface BroadcastRecordState {
  pairRecord: BroadcastRecord | null;
}

export const RECEIVE_PAIR_RECORD = 'RECEIVE_PAIR_RECORD';

export interface ReceivePairRecord {
  type: typeof RECEIVE_PAIR_RECORD;
  pairRecord: BroadcastRecord;
}

export type BroadcastRecordActionTypes = ReceivePairRecord;