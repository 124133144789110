import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as H from 'history';
import styled from 'styled-components';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { SysAdminPagination, PaginationState, Table, CenterModal, Alert, Heading, StyledButton } from 'components';
import { Organization } from 'models/organization';
import { groupArrayKeyByValue } from 'utils/formatter';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import driverProfileClient from 'httpClients/driverProfileClient';
import { DriverProfile } from 'models/driverProfile';

interface StateProps {
  models: Organization[];
  driverProfile: DriverProfile;
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type AddDriverSquadProps = RouteComponentProps<{ id: string }, object> & HistoryProps & StateProps;

interface AddDriverSquadState extends PaginationState<Organization> {
  showDoneModal: boolean;
  newOrgs: string[];
  errors: {
    name: string;
    message: string;
  }[];
}

class AddDriverSquad extends SysAdminPagination<Organization, AddDriverSquadProps, AddDriverSquadState> {
  constructor(props: AddDriverSquadProps) {
    super(props);
    this.state = {
      ...this.state,
      basePath: `/sys/drivers/${props.match.params.id}/add-squad`,
      multipleSelectButtons: [{
        onClick: this.addOrganizations,
        text: 'Add Driver to selected Squad(s)',
      }],
      pluralModelName: 'organizations',
      selectedTab: 'drivers',
      showDoneModal: false,
      newOrgs: [],
      errors: [],
    };
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    const client = new driverProfileClient();
    await client.getDriverProfile(this.props.match.params.id);
  }

  addOrganizations = async (): Promise<void> => {
    this.setState({ newOrgs: [], errors: [] });
    const client = new OrganizationManagementClient();
    await Promise.all(Object.values(this.state.selected).map(async (org): Promise<void> => {
      try {
        await client.sysAdminAddSquad({ driver_id: this.props.match.params.id, org_id: org.id });
        this.setState(prevState => ({ newOrgs: prevState.newOrgs.concat(org.business_name) }));
      } catch (e) {
        this.setState(prevState => ({
          errors: prevState.errors.concat({
            name: org.business_name,
            message: e.message,
          }),
        }));
      }
    }));
    this.setState({ showDoneModal: true });
  }

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new OrganizationManagementClient();
    await client.sysAdminGetOrganizations(new URLSearchParams(this.state.filters));

    this.setState({ isFetching: false });
  }

  renderDoneModal = (): React.ReactNode => {
    const { errors, newOrgs } = this.state;
    const formattedErrors = groupArrayKeyByValue(errors, 'name', 'message').map((grouped) => (
      `${Array.isArray(grouped.name) ? grouped.name.join(', ') : grouped.name} 
      ${(typeof grouped.message === 'string') ? grouped.message.trim() : grouped.message}`
    ));

    return (
      <CenterModal
        rightButtonOnClick={(): void => {
          this.setState({ showDoneModal: false });
        }}
        rightButtonText="OK"
        title="Add Organizations"
      >
        {newOrgs.length > 0 && (
          <Alert status="success">
            <LabelInfoBold>
              {newOrgs.length} has been added:
            </LabelInfoBold>
            <List>
              {newOrgs.map((org, index) => (<li key={index}>{org}</li>))}
            </List>
          </Alert>
        )}
        {formattedErrors.length > 0 && (
          <List>
            {formattedErrors.map((error, index) => <li key={index}>{error}</li>)}
          </List>
        )}
      </CenterModal>
    );
  }

  renderActionPanel = (): React.ReactNode => {
    const { id } = this.props.match.params;
    const { history } = this.props;
    return (
      <>
        <StyledButton
          buttonStyle="encourage"
          buttonType="neutral"
          onClick={(): void => { history.push(`/sys/drivers/${id}/squads`); }}
        >
          Back
        </StyledButton>
      </>
    );
  }

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      id: 'Organization ID',
      business_name: 'Business Name',
      squad_code: 'Squad Code',
    };
    const data = models.map(org => ({
      id: org.id,
      business_name: org.business_name,
      squad_code: org.squad_code,
    }));

    return this.props.driverProfile ? (
      <>
        <Heading>
          Add Squads for {this.props.driverProfile?.name}
        </Heading>
        <Table
          columns={columns}
          data={data}
          multipleSelect
          onToggleCheckbox={this.toggleSelect}
          onToggleCheckboxAll={this.toggleSelectAll}
          selected={this.state.selected}
        />
        {this.state.showDoneModal && this.renderDoneModal()}
      </>
    ) : (
      <Heading>
        Driver with given ID is not found
      </Heading>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  models: state.organization.organizations,
  driverProfile: state.driverProfile.profile,
});

export default connect(mapStateToProps)(AddDriverSquad);

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
`;

const LabelInfoBold = styled.div`
  font-weight: 600;
`;
