import * as React from 'react';
import { StyledButton, TextInput, BeforeLoginContainer, Alert } from 'components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AuthClient from 'httpClients/authClient';
import * as H from 'history';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

interface RequestCodeProps<S = H.LocationState> {
  history: H.History<S>;
}

interface RequestCodeState {
  form: {
    email: string;
    phone_number: string;
  };
  error: string | null;
}

class RequestCode extends React.Component<RequestCodeProps, RequestCodeState> {
  constructor(props: RequestCodeProps) {
    super(props);
    this.state = {
      form: {
        email: '',
        phone_number: '',
      },
      error: null,
    };
  }

  componentDidMount(): void {
    const tempToken = localStorage.getItem('tempBearerToken');
    if (tempToken) {
      this.props.history.push('/register/set-name-password');
    }
  }

  isPhoneNumberValid = (): boolean => {
    const pattern = new RegExp(/^([+\d]*)?\d$/);

    return pattern.test(this.state.form.phone_number);
  }

  handleChange(fieldName: string, value: string): void {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [fieldName]: value,
      },
    }));
  }

  async handleRegister(): Promise<void> {
    const { form } = this.state;
    this.setState({ error: null });
    try {
      const client = new AuthClient();
      const response = await client.requestVerificationCode({
        user: {
          email: form.email,
          phone_number: form.phone_number,
        },
      });
      this.props.history.push(
        '/register/verify-code',
        {
          'registerEmail': form.email,
          'registerToken': response.bearer_token,
        }
      );
    } catch (e) {
      if (e.code === 'user-exists') {
        this.setState({ error: 'This email already registered.' });
      } else {
        this.setState({ error: e.message });
      }
    }
  }

  handleKeyDown(keyValue: string): void {
    if (keyValue === 'Enter') {
      this.handleRegister();
    }
  }

  render(): React.ReactNode {
    const { form, error } = this.state;
    return (
      <BeforeLoginContainer title="Register">
        <LabelInfo>We suggest using the <b>email address you use at work.</b></LabelInfo>
        <InputField
          fieldName="Email"
          placeholder="name@work-email.com"
          isRequired
          name="email"
          onKeyDown={(e): void => this.handleKeyDown(e.key)}
          onTextChange={(value): void => this.handleChange('email', value)}
          type="text"
          value={form.email}
        />
        <InputField
          fieldName="Mobile Number"
          placeholder="80008000"
          isRequired
          name="phone_number"
          onKeyDown={(e): void => this.handleKeyDown(e.key)}
          onTextChange={(value): void => this.handleChange('phone_number', value)}
          type="text"
          value={form.phone_number}
        />
        {
          this.isPhoneNumberValid() ? false : (
            <InlineErrorMessage>
              <Icon icon={faExclamationCircle} />
              Mobile number must be xxxxxxxx
            </InlineErrorMessage>
          )
        }
        <LabelInfo>
          {'By creating an account, you have read and agree to our '}
          <CustomLink target='_blank' href='https://gotsurge.co/privacy-policy/'>
            Privacy Policy.
          </CustomLink>
        </LabelInfo>
        {error && (
          <ErrorMessage status='error'>{error}</ErrorMessage>
        )}
        <StyledButton
          buttonStyle="encourage"
          buttonType="primary"
          disabled={
            form.email.trim() === '' ||
            !this.isPhoneNumberValid()
          }
          onClick={(): void => { this.handleRegister(); }}
          size="lg"
          isFullWidth={true}
        >
          Register
        </StyledButton>
        <CenterText>
          Have an account? <StyledLink to='/login'>Log In</StyledLink>
        </CenterText>
        <CenterText>
          If your team has an existing organisation account, ask an admin to invite you.
        </CenterText>
      </BeforeLoginContainer>
    );
  }
}

const LabelInfo = styled.div`
  color: ${COLOR.darkGray};
  margin-bottom: 1.875rem;
`;

const CenterText = styled.div`
  margin-top: 1.875rem;
  text-align: center;
`;

const CustomLink = styled.a`
  font-size: 1rem;
  text-decoration: underline;
  color: ${COLOR.blue};
`;

const StyledLink = styled(Link)`
  font-size: 1rem;
  text-decoration: underline;
  color: ${COLOR.blue};
`;

const InputField = styled(TextInput)`
  margin-bottom: 1.5rem;
`;

const ErrorMessage = styled(Alert)`
  margin-bottom: 1rem;
`;

const InlineErrorMessage = styled.div`
  margin-top: -1.3rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`;

export default RequestCode;