import * as React from 'react';
import styled from 'styled-components';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';

import COLOR, { transparent } from 'constants/color';
import { CurrentUser } from 'models/auth';
import Menu from '../navbar/Navbar';
import { MerchantRegisteredPath } from 'constants/registeredPath';
interface HeaderStateProps {
  currentUser: CurrentUser;
}

interface HeaderProps extends HeaderStateProps {
  dispatch: Dispatch;
  selected: string;
  updateMargin: (height: number) => void;
}

const Header = (props: HeaderProps): JSX.Element => {
  const merchantList = [
    {
      name: 'active-orders',
      path: '/active-orders',
      text: 'Active Orders',
    },
    {
      name: 'past-orders',
      path: '/past-orders',
      text: 'Past Orders',
    },
  ];

  return (
    <MainContainer
      ref={(ref: HTMLDivElement): void => {
        if (ref != null) {
          props.updateMargin(ref.offsetHeight);
        }
      }}
    >
      <ButtonContainer>
        <Menu user={props.currentUser.user}
          navList={merchantList}
          registeredPath={MerchantRegisteredPath}
          role={'merchant'}
          selected={props.selected} />
      </ButtonContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  box-shadow: 0px 4px 6px ${transparent('black', 0.05)};
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  z-index: 9999;
`;

const ButtonContainer = styled.div`
  background-color: ${COLOR.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

function mapStateToProps(state: RootState): HeaderStateProps {
  return {
    currentUser: state.auth.currentUser,
  };
}

export default connect(mapStateToProps)(Header);