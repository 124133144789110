import { PriceAdjustmentState, PriceAdjustmentActionTypes, PRICE_ADJUSTMENT_LOGS, PRICE_ADJUSTMENTS, PRICE_ADJUSTMENT } from './priceAdjustmentTypes';

const initialState: PriceAdjustmentState = {
  priceAdjustmentLogs: [],
  priceAdjustments: [],
  priceAdjustment: null,
};

function priceAdjustmentReducer(
  state: PriceAdjustmentState = initialState, action: PriceAdjustmentActionTypes
): PriceAdjustmentState {
  switch (action.type) {
    case PRICE_ADJUSTMENTS:
      return { ...state, priceAdjustments: action.priceAdjustments };
    case PRICE_ADJUSTMENT_LOGS:
      return { ...state, priceAdjustmentLogs: action.priceAdjustmentLogs };
    case PRICE_ADJUSTMENT:
      return { ...state, priceAdjustment: action.priceAdjustment };
    default:
      return state;
  }
}

export default priceAdjustmentReducer;
