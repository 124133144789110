import {
  PlanningState, PlanningActionTypes, RECEIVE_PLANNINGS,
  PlanningHash, RECEIVE_PLANNING, RECEIVE_PLANNING_STATUS,
  RECEIVE_PLANNING_RESULT,
} from './planningTypes';
import { Planning } from 'models/planning';

const initialState: PlanningState = {
  planningHash: {},
  planningIds: [],
};

function combinePlanning(state: PlanningState, plan: Planning | Planning[]): PlanningHash {
  const combinedPlannings = { ...state.planningHash };

  if (plan instanceof Array) {
    plan.forEach(elem => {
      combinedPlannings[elem.id] = {
        ...state.planningHash[elem.id],
        ...elem,
      };
    });
  } else {
    combinedPlannings[plan.id] = {
      ...state.planningHash[plan.id],
      ...plan,
    };
  }

  return combinedPlannings;
}

function planningReducer(
  state: PlanningState = initialState, action: PlanningActionTypes
): PlanningState {
  switch (action.type) {
    case RECEIVE_PLANNINGS:
      return {
        ...state,
        planningIds: action.plannings.map(data => data.id),
        planningHash: combinePlanning(state, action.plannings),
      };
    case RECEIVE_PLANNING:
      return {
        ...state,
        planningHash: combinePlanning(state, action.planning),
      };
    case RECEIVE_PLANNING_STATUS:
      return {
        ...state,
        planningHash: combinePlanning(state, {
          ...state.planningHash[action.planningStatus.planning_job_id],
          status: action.planningStatus,
        }),
      };
    case RECEIVE_PLANNING_RESULT:
      return {
        ...state,
        planningHash: combinePlanning(state, {
          ...state.planningHash[action.planningResult.planning_job_id],
          result: action.planningResult,
        }),
      };
    default:
      return state;
  }
}

export default planningReducer;