import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import {
  PartnerAuthorization,
  UserAuthenticationPayload,
  UserAuthenticationResponse,
} from 'models/partnership';
import { receivePartnerAuthorization } from 'reduxActions/partnership/partnershipActions';
import { PartnerSigninResponse } from 'models/auth';
import { saveBearerToken, saveUser } from 'utils/userHelper';
import { receiveSigninSuccess } from 'reduxActions/auth/authActions';

class PartnershipClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.PARTNERSHIP_SERVICE_PROTOCOL;
    this.host = process.env.PARTNERSHIP_SERVICE_HOST;
    this.port = process.env.PARTNERSHIP_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(
    method: Method,
    url: string,
    body: T | null = null,
    customBearerToken: string = null
  ): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (customBearerToken) {
      options.headers.Authorization = `Bearer ${customBearerToken}`;
    }

    return options;
  }

  getPartnerAuthorizationUrl(partnerClientKey: string): string {
    return `${this.getBaseUrl()}/partner/${partnerClientKey}`;
  }

  async getPartnerAuthorization(partnerClientKey: string): Promise<void> {
    const request = this.makeRequest(
      'GET',
      this.getPartnerAuthorizationUrl(partnerClientKey)
    );

    try {
      const response: AxiosResponse<PartnerAuthorization> = await axios(request);
      const store = configureStore();
      store.dispatch(receivePartnerAuthorization(response.data));
    } catch (error) {
      throw error.message;
    }
  }

  userAuthenticationUrl(): string {
    return `${this.getBaseUrl()}/user`;
  }

  async userAuthentication(
    bearerToken: string,
    payload: UserAuthenticationPayload
  ): Promise<UserAuthenticationResponse> {
    const request = this.makeRequest(
      'POST',
      this.userAuthenticationUrl(),
      payload,
      bearerToken
    );

    try {
      const response = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  exchangeTokenURL(): string {
    return `${this.getBaseUrl()}/platform-partners/exchange-token`;
  }

  async exchangeToken(form: { session_id: string }) {
    const request = this.makeRequest('POST', this.exchangeTokenURL(), form);

    try {
      const response: AxiosResponse<PartnerSigninResponse> = await axios(request);
      const roles = response.data.user.roles;
      if (
        roles.includes('SysAdmin') ||
        roles.includes('OrgAdmin') ||
        roles.includes('OrgTransporter')
      ) {
        saveBearerToken(response.data.bearer_token);
        saveUser(JSON.stringify(response.data.user));

        if (process.env.SEGMENT_KEY) {
          window.analytics.identify(
            response.data.user.id,
            {
              email: response.data.user.email,
              name: response.data.user.name,
            },
            {
              Intercom: {
                user_hash: response.data.user.user_hash,
              },
            }
          );
        }

        const store = configureStore();
        store.dispatch(receiveSigninSuccess(response.data));
      } else {
        throw Error('Your account is not allowed to access the web app');
      }
    } catch (error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }
}

export default PartnershipClient;
