import * as React from 'react';
import TaskImportClient from 'httpClients/taskImportClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import {
  Table, Button, TableCellRenderEvent,
  Dropdown, SysAdminPagination,
} from 'components';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { Link } from 'react-router-dom';
import { TaskImportJob } from 'models/taskImport';
import * as H from 'history';
import { Organization } from 'models/organization';
import STATUS from 'constants/taskImportStatus';
import configureStore from 'reduxActions/store';
import { updateInProgressStatus } from 'reduxActions/taskImport/taskImportActions';
import { receiveOrganizations } from 'reduxActions/organization/organizationActions';
import { BROADCAST_PREFERENCE_DESCRIPTION } from 'constants/broadcastPreference';
import { css } from 'styled-components';

interface StateProps {
  models: TaskImportJob[];
  organizations: Organization[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type Props = StateProps & HistoryProps;

class TaskImportIndex extends SysAdminPagination<TaskImportJob, Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,
      basePath: '/sys/imports',
      pluralModelName: 'task imports',
      selectedTab: 'imports',
    };

    this.rules = [...this.rules, {
      name: 'org_id',
      validate: (param: string): boolean => {
        const { organizations } = this.props;
        const orgIds = organizations.map(data => data.id);

        return organizations.length === 0 || orgIds.includes(param);
      },
    }];
  }

  componentDidMount = (): void => {
    super.componentDidMount();
    this.fetchOrganizations();
  }

  fetchOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(new URLSearchParams({ page: pageNum.toString() }));
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch (e) {
      //
    }
  }

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new TaskImportClient();
    await client.sysAdminGetAllTaskImportJobs(new URLSearchParams(this.state.filters));

    this.setState({ isFetching: false });
  }

  onStartClick = async (id: string): Promise<void> => {
    const client = new TaskImportClient();
    await client.sysAdminStart(id);

    const store = configureStore();
    store.dispatch(updateInProgressStatus(id));
    setTimeout(() => {
      this.props.history.push('/sys/imports');
    }, 1000);
  }

  renderFilter = (): React.ReactNode => (
    <>
      <Dropdown
        containerStyle={css`
          margin: 0 0.5rem;
        `}
        label="Sort by"
        options={[
          {
            value: 'desc',
            name: 'Newest First',
          }, {
            value: 'asc',
            name: 'Oldest First',
          },
        ]}
        onChange={(value: string): void => { this.onFilterChange('order', value); }}
        value={this.state.filters.order}
      />
      <Dropdown
        containerStyle={css`
          margin: 0 0.5rem;
        `}
        includeAll
        label="Organization"
        options={this.props.organizations.map(org => (
          {
            value: org.id,
            name: org.business_name,
          }
        ))}
        onChange={(value: string): void => {
          const isDelete = value === '';
          this.onFilterChange('org_id', value, isDelete);
        }}
        value={this.state.filters.org_id || ''}
        withSearch
        searchPlaceholder="Find Organisation..."
        sorted
      />
    </>
  )

  renderActionPanel = (): React.ReactNode => (
    <>
      <Link to="/sys/tasks/imports/new">
        <Button>Import Order</Button>
      </Link>
    </>
  )

  renderCell = (e: TableCellRenderEvent<{ action: string; status: string }>): React.ReactNode => {
    if (e.key === 'broadcast_preference') {
      return e.value ? BROADCAST_PREFERENCE_DESCRIPTION[e.value.toString()].label : BROADCAST_PREFERENCE_DESCRIPTION['all'].label;
    } else if (e.key === 'action') {
      return (
        <div>
          <Link to={`/sys/imports/${e.value.toString()}`}>
            <Button>Detail</Button>
          </Link>
          {
            e.data.status === STATUS.new ?
              <Button
                type='button'
                onClick={(): void => { this.onStartClick(e.value.toString()); }}
              >
                Start
              </Button>
              : false
          }
        </div>
      );
    }

    return <>{e.value}</>;
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      id: 'ID',
      broadcast_preference: 'Broadcast Service Type',
      status: 'Status',
      action: 'Action',
    };
    const data = models.map(job => ({
      id: job.id,
      broadcast_preference: job.broadcast_preference,
      status: STATUS[job.current_status],
      action: job.id,
    }));

    return (
      <Table
        cellRenderer={this.renderCell}
        columns={columns}
        data={data}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  models: state.taskImport.taskImportIds
    .map(id => state.taskImport.taskImportHash[id]),
  organizations: state.organization.organizations,
});

export default connect(mapStateToProps)(TaskImportIndex);