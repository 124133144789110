import * as React from 'react';
import { CenterModal, Typography, MapMarker, TextInput } from 'components';
import styled, { css } from 'styled-components';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Position } from 'models/geoService/geoData';

interface LocationModalProps {
  title?: string;
  initialLocationName?: string;
  initialPosition?: Position;
  onClose: () => void;
  onSubmit: (locationName: string, position: Position) => void;
}

const LocationDetailModal = (props: LocationModalProps): React.ReactElement => {
  const title = props.title || <>What&apos;s your exact location?</>;
  const [locationName, setLocationName] = React.useState(props.initialLocationName || '');
  const [position, setPosition] = React.useState<Position>({
    lat: props.initialPosition?.lat || 0,
    lng: props.initialPosition?.lng || 0,
  });

  const handleLatLngChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;

    const parts = input.split(', ').map((str) => str.trim());
    if (parts.length === 2) {
      const [latStr, lngStr] = parts;
      const lat = parseFloat(latStr);
      const lng = parseFloat(lngStr);

      setPosition({
        lat,
        lng,
      });
    } else {
      setPosition({
        ...position,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleNumericInput = (event: React.FormEvent<HTMLInputElement>): void => {
    const input = event.currentTarget.value;
    // check value contains string ', '
    if (input.includes(', ')) {
      return;
    }
    const numericInput = input.replace(/[^0-9.]/g, ''); // Allow numbers, periods, and hyphens, disallow commas
    event.currentTarget.value = numericInput;
  };

  return (
    <>
      <CenterModal
        title={
          <Typography as="h2" size="lg" color="gray_900">
            {title}
          </Typography>
        }
        leftButtonText={
          <>
            <FontAwesomeIcon icon={faTimes} />
            Cancel
          </>
        }
        leftButtonOnClick={(): void => props.onClose()}
        leftButtonType="neutral"
        rightButtonText={
          <>
            <FontAwesomeIcon icon={faCheck} />
            OK
          </>
        }
        rightButtonType="primary"
        rightButtonOnClick={(): void => {
          if (typeof position.lat === 'string') {
            position.lat = parseFloat(position.lat);
          }
          if (typeof position.lng === 'string') {
            position.lng = parseFloat(position.lng);
          }
          props.onSubmit(locationName, position);
        }}
        rightButtonDisabled={!locationName || !position.lat || !position.lng}
        buttonGroupJustifyContent="flex-end"
      >
        <div>
          <InputWrapper marginTop="8px" marginBottom="8px">
            <ItemFieldName>
              Location Name <RedText>*</RedText>
            </ItemFieldName>
            <TextInput
              width="full"
              height="large"
              autoComplete="none"
              onTextChange={(value: string): void => {
                setLocationName(value.toUpperCase());
              }}
              type="text"
              placeholder="Enter location name"
              value={locationName || ''}
            />
          </InputWrapper>
          <InputWrapper
            flexDirection="row"
            marginBottom="8px"
            gap="12px"
            marginTop="18px"
          >
            <div>
              <ItemFieldName>
                Latitude <RedText>*</RedText>
              </ItemFieldName>
              <TextInput
                name="lat"
                containerStyle={css`
                  margin-top: 8px;
                `}
                width="full"
                height="large"
                autoComplete="none"
                onTextChange={(value: string, e): void => {
                  handleLatLngChange(e);
                }}
                type="text"
                inputMode="decimal"
                placeholder="00.000000"
                value={position.lat || ''}
                onInput={handleNumericInput}
              />
            </div>
            <div
              style={{
                alignSelf: 'flex-end',
                marginLeft: '-9px',
              }}
            >
              ,
            </div>
            <div>
              <ItemFieldName>
                Longitude <RedText>*</RedText>
              </ItemFieldName>
              <TextInput
                name="lng"
                containerStyle={css`
                  margin-top: 8px;
                `}
                width="full"
                height="large"
                autoComplete="none"
                onTextChange={(value: string, e): void => {
                  handleLatLngChange(e);
                }}
                type="text"
                inputMode="decimal"
                placeholder="00.000000"
                value={position.lng || ''}
                onInput={handleNumericInput}
              />
            </div>
          </InputWrapper>
        </div>
        <MapMarkerWrapper>
          <MapMarker
            onPositionChange={({ lat, lng }): void => {
              setPosition({
                lat,
                lng,
              });
            }}
            position={{
              lat:
                typeof position.lat === 'number'
                  ? position.lat
                  : parseFloat(position.lat),
              lng:
                typeof position.lng === 'number'
                  ? position.lng
                  : parseFloat(position.lng),
            }}
          />
        </MapMarkerWrapper>
      </CenterModal>
    </>
  );
};

const ItemFieldName = styled.p`
  font-size: 0.875rem;
  margin: 0 0 6px 0;
`;

const RedText = styled.span`
  color: ${COLOR.red};
`;

const MapMarkerWrapper = styled.div`
  margin: 18px 0;
`;

const InputWrapper = styled.div<{
  marginTop: string;
  marginBottom: string;
  flexDirection?: string;
  gap?: string;
}>`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  gap: ${(props) => props.gap || '0'};
`;

export default LocationDetailModal;
