import * as React from 'react';
import styled from 'styled-components';
import COLOR from 'constants/color';
import { Link } from 'react-router-dom';

interface CardColumnProps {
  flex: string;
}

interface MainMenu {
  title: string;
  submenu: Submenu[];
}

interface Submenu {
  title: string;
  path: string;
}

interface SidebarMenuProps {
  header?: React.ReactNode;
  mainMenu: MainMenu[];
  footer?: React.ReactNode;
  selected?: string;
  children?: React.ReactNode;
}

class SidebarMenu extends React.Component<SidebarMenuProps> {
  constructor(props: SidebarMenuProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { header, mainMenu, children, footer, selected } = this.props;
    return (
      <Container>
        <CardColumn
          flex="1"
        >
          {header}
          <MenuContainer>
            {(mainMenu).map((menu, key) => (
              <div key={key}>
                <SubMenuTitle>
                  {menu.title}
                </SubMenuTitle>
                <SubMenuContainer>
                  {(menu.submenu).map((submenu, skey) => {
                    return (
                      <LinkContainer
                        key={skey}
                      >
                        <TabMenuLink
                          to={submenu.path}
                          selected={selected === submenu.path}
                        >
                          {submenu.title}
                        </TabMenuLink>
                      </LinkContainer>
                    );
                  })}
                </SubMenuContainer>
              </div>
            ))}
          </MenuContainer>
          {footer}
        </CardColumn>
        <CardColumn
          flex="3"
        >
          {children}
        </CardColumn>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
`;

const Card = styled.div`
  border-radius: 0.5rem;
  background-color: ${COLOR.white};
  padding: 1.5rem 2rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;

  :last-child {
    margin-right: 0px;
    overflow: scroll;
  }

  @media (max-width: 768px) {
    width: -webkit-fill-available;
    margin-right: 0;
  }
`;

const CardColumn = styled(Card) <CardColumnProps>`
  flex: ${(props): string => props.flex};
  border-radius: 0.5rem;
  border: 1px solid ${COLOR.neutral};

  @media (max-width: 768px) {
    flex: none;
  }
`;

const LinkContainer = styled.div`
  margin-top: 0.5rem;
`;

const TabMenuLink = styled(Link) <{ selected?: boolean }>`
  color: ${(props): string => `${props.selected ? COLOR.blue : COLOR.darkGray}`};
  font-size: 1rem;
  border: none;
  text-decoration: none;

  :hover {
    color: ${(props): string => props.selected ? COLOR.blue : COLOR.midLightGrey};
  }
`;

const MenuContainer = styled.div`
  margin-top: 1.5rem;
`;

const SubMenuTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  margin-top: 1rem;
`;

const SubMenuContainer = styled.div`
  margin-left: 0.9375rem;
`;

export default SidebarMenu;