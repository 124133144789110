import {
  PartnershipState,
  PartnershipActionTypes,
  RECEIVE_PARTNER_AUTHORIZATION,
} from './partnershipTypes';

const initialState: PartnershipState = {
  partnerAuthorization: null,
};

function geoServiceReducer(
  state: PartnershipState = initialState,
  action: PartnershipActionTypes
): PartnershipState {
  switch (action.type) {
    case RECEIVE_PARTNER_AUTHORIZATION:
      return { ...state, partnerAuthorization: action.partnerAuthorization };
    default:
      return state;
  }
}

export default geoServiceReducer;