import { Organization, PendingOrganization, Squad, SquadDriver } from 'models/organization';
import {
  ReceiveOrganizationAction,
  ReceiveOrganizationsAction,
  ReceivePendingOrganizationsAction,
  ReceiveSquadsAction,
  ReceiveSquadDriversAction,
  RECEIVE_ORGANIZATION,
  RECEIVE_ORGANIZATIONS,
  RECEIVE_PENDING_ORGANIZATIONS,
  RECEIVE_SQUADS,
  RECEIVE_SQUAD_DRIVERS,
} from './organizationTypes';

export function receiveOrganization(organization: Organization): ReceiveOrganizationAction {
  return {
    type: RECEIVE_ORGANIZATION,
    organization,
  };
}

export function receiveOrganizations(organizations: Organization[]): ReceiveOrganizationsAction {
  return {
    type: RECEIVE_ORGANIZATIONS,
    organizations,
  };
}

export function receivePendingOrganizations(organizations: PendingOrganization[]): ReceivePendingOrganizationsAction {
  return {
    type: RECEIVE_PENDING_ORGANIZATIONS,
    organizations,
  };
}

export function receiveSquads(squads: Squad[]): ReceiveSquadsAction {
  return ({
    type: RECEIVE_SQUADS,
    squads,
  });
}

export function receiveSquadDrivers(squadDrivers: SquadDriver[]): ReceiveSquadDriversAction {
  return ({
    type: RECEIVE_SQUAD_DRIVERS,
    squadDrivers,
  });
}