import { faPallet, faTruckPickup, faShip } from '@fortawesome/free-solid-svg-icons';
import { ServiceType } from 'models/task';

export const SERVICE_TYPE: ServiceType[] = [
  {
    service_type: 'ltl',
    icon: faPallet,
    service_description: 'Book only the truck space you need',
    eco_friendly: true,
    service_pricing: [
      {
        vehicle_preference: 'ltl',
        price: 70,
        currency: 'SGD',
        uom: 'lift',
        uom_description: 'per lift',
        additional_cost: '',
        is_default: true,
      },
    ],
  },
  {
    service_type: 'truck',
    icon: faTruckPickup,
    service_description: 'Charter a full truck',
    eco_friendly: false,
    service_pricing: [
      {
        vehicle_preference: 'lorry_14ft',
        price: 120,
        currency: 'SGD',
        uom: 'time',
        uom_description: 'for 1ˢᵗ 3hrs',
        additional_cost: '+ SGD 40 for subsq. hrs',
        is_default: false,
      },
      {
        vehicle_preference: 'lorry_24ft',
        price: 180,
        currency: 'SGD',
        uom: 'time',
        uom_description: 'for 1ˢᵗ 3hrs',
        additional_cost: '+ SGD 50 for subsq. hrs',
        is_default: true,
      },
      {
        vehicle_preference: 'lorry_40ft',
        price: 320,
        currency: 'SGD',
        uom: 'time',
        uom_description: 'for 1ˢᵗ 3hrs',
        additional_cost: '+ SGD 80 for subsq. hrs',
        is_default: false,
      },
    ],
  },
  {
    service_type: 'boat',
    icon: faShip,
    service_description: 'Charter a full boat',
    eco_friendly: false,
    service_pricing: [
      {
        vehicle_preference: 'boat',
        price: 500,
        currency: 'SGD',
        uom: 'time',
        uom_description: '',
        additional_cost: '',
        is_default: true,
      },
    ],
  },
];

export const DEFAULT_SERVICE_TYPE = 'ftl';
export const DEFAULT_FTL_VEHICLE = 'lorry_24ft';
