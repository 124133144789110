import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  Message,
  SysAdminMainContainer,
  FileInformationCard,
  SelectFileButton,
  PageTitle,
  Breadcrumb,
  SearchBar,
  Button,
  Dropdown,
  Checkbox,
  TextInput,
} from 'components';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { Organization } from 'models/organization';
import { FileData, SysAdminCreateImportAfterUploadForm } from 'models/taskImport';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import { formatError } from 'utils/formatter';
import configureStore from 'reduxActions/store';
import { receiveOrganizations } from 'reduxActions/organization/organizationActions';
import TaskImportClient from 'httpClients/taskImportClient';
import * as H from 'history';
import COLOR, { transparent } from 'constants/color';
import { computeChecksumMd5 } from 'utils/computeChecksum';
import { BROADCAST_PREFERENCE_DESCRIPTION } from 'constants/broadcastPreference';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import MarineServiceTypeForm from '../task/partialForm/marineServiceTypeForm';
import VehicleForm from '../task/partialForm/vehicleForm';
import { DEFAULT_FTL_VEHICLE, DEFAULT_SERVICE_TYPE } from 'constants/serviceType';
import {
  DEFAULT_SQUAD_PRICE,
  DEFAULT_MIN_PRICE,
  DEFAULT_MAX_PRICE,
} from 'constants/priceDetails';
import { getServicePricing } from 'utils/taskHelper';

interface StateProps {
  organizations: Organization[];
}

interface NewTaskImportProps {
  history: H.History;
}

interface FileWithData {
  file: File;
  fileData: FileData;
}

interface NewTaskImportState {
  form: SysAdminCreateImportAfterUploadForm;
  error: string | null;
  isImporting: boolean;
  itemFiles: FileWithData[];
  orderFiles: FileWithData[];
  successMessage: string | null;
}

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

type Props = NewTaskImportProps & StateProps;

class NewTaskImport extends React.Component<Props, NewTaskImportState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      isImporting: false,
      itemFiles: [],
      orderFiles: [],
      form: this.getDefaultForm(),
      successMessage: null,
    };
  }

  getDefaultForm(): SysAdminCreateImportAfterUploadForm {
    return {
      is_qa: false,
      broadcast_preference: 'all',
      vehicle_preference: '',
      service_type: DEFAULT_SERVICE_TYPE,
      files: [],
      org_id: null,
      need_cargo_net: false,
      cargo_net_quantity: 0,
      has_officer_contact: false,
      officer_contact: {
        name: '',
        phone: '',
      },
    };
  }

  async componentDidMount(): Promise<void> {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(new URLSearchParams({ page: pageNum.toString() }));
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch (e) {
      //
    }
  }

  getPresignedUrl = async (fileName: string): Promise<FileData> => {
    const client = new TaskImportClient();
    return await client.sysAdminGetPresignedUrl(fileName);
  }

  onFileChange = async (e: React.ChangeEvent<HTMLInputElement>, fieldName: string): Promise<void> => {
    const file = e.target.files[0];
    if (file) {
      const fileData = await this.getPresignedUrl(file.name);
      const newFileData: FileData = {
        file_name: file.name,
        file_size: file.size,
        s3_upload_bucket: fileData.s3_upload_bucket,
        s3_upload_key: fileData.s3_upload_key,
        url: fileData.url,
        checksum: await computeChecksumMd5(file),
        checksum_method: 'MD5',
      };
      const fileWithData: FileWithData = {
        file: file,
        fileData: newFileData,
      };

      if (fieldName === 'order') {
        this.setState((prevState) => ({ orderFiles: prevState.orderFiles.concat(fileWithData) }));
      } else if (fieldName === 'item') {
        this.setState((prevState) => ({ itemFiles: prevState.itemFiles.concat(fileWithData) }));
      }
    }
  }

  handleChange = (fieldName: string, value: string): void => {
    if (fieldName.indexOf('.') !== -1) {
      const fields = fieldName.split('.');

      this.setState(prevState => ({
        form: {
          ...prevState.form,
          [fields[0]]: {
            ...prevState.form[fields[0]] as object,
            [fields[1]]: value,
          },
        },
      }));
    } else {
      switch (fieldName) {
        case 'broadcast_preference': {
          const org = this.props.organizations.find(
            (option) => option.id === this.state.form.org_id
          );
          const minPrice = org?.min_price || DEFAULT_MIN_PRICE;
          const maxPrice = org?.max_price || DEFAULT_MAX_PRICE;
          let vehiclePreference = '';
          let minMaxPrice = {
            min_price: minPrice,
            max_price: maxPrice,
          };
          if (value === BROADCAST_PREFERENCES.squad) {
            minMaxPrice = {
              min_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
              max_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
            };
          } else if (value === BROADCAST_PREFERENCES.marine) {
            const servicePricing = getServicePricing(DEFAULT_SERVICE_TYPE, DEFAULT_FTL_VEHICLE);
            const priceValue = Math.floor(servicePricing.price * 100) * 1000;
            vehiclePreference = DEFAULT_FTL_VEHICLE;
            minMaxPrice = {
              min_price: priceValue,
              max_price: priceValue,
            };
          }
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              [fieldName]: value,
              ...minMaxPrice,
              service_type: DEFAULT_SERVICE_TYPE,
              vehicle_preference: vehiclePreference,
            },
          }));
          break;
        }
        case 'service_type': {
          let vehiclePreference = DEFAULT_FTL_VEHICLE;
          if (value === 'ltl') {
            vehiclePreference = 'ltl';
          }
          this.setState((prevState) => ({
            form: {
              ...prevState.form,
              increment: 0,
              service_type: value,
              vehicle_preference: vehiclePreference,
            },
          }));
          break;
        }
        case 'is_qa': {
          const isQa = value === 'Yes' ? true : false;
          this.setState(prevState => ({
            form: {
              ...prevState.form,
              is_qa: isQa,
            },
          }));
          break;
        }
        case 'cargo_net_quantity': {
          this.setState(prevState => ({
            form: {
              ...prevState.form,
              cargo_net_quantity: parseInt(value),
            },
          }));
          break;
        }
        default: {
          this.setState(prevState => ({
            form: {
              ...prevState.form,
              [fieldName]: value,
            },
          }));
        }
      }
    }
  }

  uploadToS3 = async (filesWithData: FileWithData[]): Promise<void> => {
    try {
      const client = new TaskImportClient();
      for (const fileWithData of filesWithData) {
        await client.uploadToS3(fileWithData.fileData.url, fileWithData.file);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  createImport = async (filesWithData: FileWithData[]): Promise<void> => {
    const { form } = this.state;
    try {
      const client = new TaskImportClient();
      const fileDatas: FileData[] = filesWithData.map(file => file.fileData);
      const fileWithData: SysAdminCreateImportAfterUploadForm = {
        ...form,
        files: fileDatas,
      };

      await client.sysAdminCreateImportAfterUpload(fileWithData);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  handleSubmit = async (): Promise<void> => {
    this.setState({ isImporting: true, error: null });

    try {
      const { orderFiles, itemFiles } = this.state;
      const files = orderFiles.concat(itemFiles);
      await this.uploadToS3(files);
      await this.createImport(files);

      this.setState({ successMessage: 'Task import created' });
      setTimeout(() => {
        this.props.history.push('/sys/imports');
      }, 300);
    } catch (e) {
      this.setState({ error: formatError(e) });
    }

    this.setState({ isImporting: false });
  }

  downloadTaskImportTemplate = (): void => {
    const client = new TaskImportClient();
    client.sysDownloadTaskImportTemplate();
  }

  downloadItemImportTemplate = (): void => {
    const client = new TaskImportClient();
    client.downloadItemImportTemplate();
  }

  onFileRemove = (index: number, fieldName: string): void => {
    if (fieldName === 'order') {
      this.setState((prevState) => ({
        orderFiles: prevState.orderFiles.filter((_, i) => i !== index),
      }));
    } else if (fieldName === 'item') {
      this.setState((prevState) => ({
        itemFiles: prevState.itemFiles.filter((_, i) => i !== index),
      }));
    }
  };

  renderOrderTemplateDescription = (): React.ReactNode => {
    return (
      <TemplateContainer>
        <Span>Download template for</Span>
        <LinkButton onClick={this.downloadTaskImportTemplate}>
          Orders
        </LinkButton>
        <Span>to get started. </Span>
        <Text>Accept only .csv.</Text>
      </TemplateContainer>
    );
  }

  renderAdditionalService = (): React.ReactNode => {
    if (this.state.form.broadcast_preference !== 'marine' || this.state.orderFiles.length === 0) {
      return <></>;
    }
    return (
      <BoxContainer>
        <SectionContainer>
          <AdditionalServiceContainer>
            <Title>
              Any Additional Service / Requirement?
            </Title>
            <Checkbox
              onClick={(): void => {
                this.setState(prevState => ({
                  form: {
                    ...prevState.form,
                    need_cargo_net: !prevState.form.need_cargo_net,
                  },
                }));
              }}
              selected={this.state.form.need_cargo_net}
            >
              Do you need cargo net?
            </Checkbox>
            {this.state.form.need_cargo_net &&
              <AdditionalTextContainer>
                <TextInput
                  fieldName="No. of Cargo Nets"
                  type="number"
                  width="medium"
                  placeholder="4"
                  onTextChange={(value): void => this.handleChange('cargo_net_quantity', value)}
                  value={this.state.form.cargo_net_quantity}
                />
              </AdditionalTextContainer>
            }
            <Checkbox
              onClick={(): void => {
                this.setState(prevState => ({
                  form: {
                    ...prevState.form,
                    has_officer_contact: !prevState.form.has_officer_contact,
                  },
                }));
              }}
              selected={this.state.form.has_officer_contact}
            >
              Is there an additional Boarding Officer coming along?
            </Checkbox>
            {this.state.form.has_officer_contact &&
              <AdditionalTextContainer>
                <TextInput
                  fieldName="Name"
                  type="text"
                  width="medium"
                  placeholder="Tony"
                  containerStyle={inlineTextInputStyle}
                  onTextChange={(value: string): void => this.handleChange('officer_contact.name', value)}
                  value={this.state.form.officer_contact.name}
                />
                <TextInput
                  fieldName="Contact No."
                  type="text"
                  width="medium"
                  placeholder="8100 8989"
                  containerStyle={inlineTextInputStyle}
                  onTextChange={(value: string): void => this.handleChange('officer_contact.phone', value)}
                  value={this.state.form.officer_contact.phone}
                />
              </AdditionalTextContainer>
            }
          </AdditionalServiceContainer>
        </SectionContainer>
      </BoxContainer>
    );
  }

  renderItemTemplateDescription = (): React.ReactNode => {
    return (
      <TemplateContainer>
        <Span>Download template for corresponding</Span>
        <LinkButton onClick={this.downloadItemImportTemplate}>
          Items
        </LinkButton>
        <Span>to get started. </Span>
        <Text>Accept only .csv.</Text>
      </TemplateContainer>
    );
  }

  renderFiles = (filesWithData: FileWithData[], type: string): React.ReactNode => {
    return (
      <FileSectionContainer>
        {filesWithData.map((fileWithData, index) => (
          <FileInformationCard key={index}
            name={fileWithData.file.name}
            size={fileWithData.file.size}
            onDeleteFile={(): void => {
              this.onFileRemove(index, type === 'order'
                ? 'order' : 'item');
            }}
          />
        ))}
      </FileSectionContainer>
    );
  }

  render(): React.ReactNode {
    const { orderFiles, itemFiles, form } = this.state;
    const { organizations } = this.props;
    const { successMessage, error } = this.state;

    return (
      <SysAdminMainContainer selected="tasks">
        <Breadcrumb
          items={[
            { text: 'Orders', onClick: (): void => { this.props.history.push('/sys/tasks'); } },
            { text: 'Import Orders' },
          ]}
        />
        <PageTitle>
          Import Orders
        </PageTitle>
        <BoxContainer>
          <SectionContainer>
            <SearchContainer>
              <SearchBar
                label="Organisation / Merchant"
                onChange={(v: string): void => { this.handleChange('org_id', v); }}
                options={organizations.map(org => {
                  return {
                    value: org.id,
                    text: org.business_name,
                  };
                })}
                placeholder="Search Company Name..."
                required
              />
            </SearchContainer>
            <LabelBox>
              <Label>Broadcast Service Type</Label>
              <Dropdown
                containerStyle={css`
                  margin: 0 0.5rem;
                `}
                onChange={(value: string): void => this.handleChange('broadcast_preference', value)}
                value={this.state.form.broadcast_preference}
                options={Object.entries(BROADCAST_PREFERENCE_DESCRIPTION).map(([key, value]) => ({
                  name: value.label,
                  value: key,
                }))}
                width="small"
              />
            </LabelBox>
            <LabelBox>
              <VehicleRequirements>
                {this.state.form.broadcast_preference ===
                BROADCAST_PREFERENCES.marine ? (
                  <MarineServiceTypeForm
                    onSelectedService={(value: string, price: number): void => {
                      this.handleChange('service_type', value);
                      this.handleChange('min_price', price.toString());
                      this.handleChange('max_price', price.toString());
                    }}
                    selectedServiceType={this.state.form.service_type}
                    selectedVehiclePreference={
                      this.state.form.vehicle_preference || DEFAULT_FTL_VEHICLE
                    }
                    totalCargo={1}
                    onVehicleClick={(vehicle: string, price: number): void => {
                      this.handleChange('vehicle_preference', vehicle);
                      this.handleChange('min_price', price.toString());
                      this.handleChange('max_price', price.toString());
                    }}
                  />
                ) : (
                  <VehicleForm
                    form={{
                      vehicle_preference: this.state.form.vehicle_preference,
                      broadcast_preference: this.state.form.broadcast_preference as keyof typeof BROADCAST_PREFERENCES,
                    }}
                    onFormChange={(fieldName: string, value: string): void => {
                      this.handleChange(fieldName, value);
                    }}
                  />
                )}
              </VehicleRequirements>
            </LabelBox>
            <SelectFileButton
              buttonText="Select CSV..."
              disabled={!this.state.form.org_id}
              label="Upload Orders"
              onFileChange={(e): void => { this.onFileChange(e, 'order'); }}
              required
            >
              {this.renderOrderTemplateDescription()}
            </SelectFileButton>
            {this.renderFiles(orderFiles, 'order')}

            <SelectFileButton
              buttonText="Select CSV..."
              disabled={this.state.orderFiles.length === 0}
              label="Upload Items"
              onFileChange={(e): void => { this.onFileChange(e, 'item'); }}
            >
              {this.renderItemTemplateDescription()}
            </SelectFileButton>
            {this.renderFiles(itemFiles, 'item')}
          </SectionContainer>
        </BoxContainer>
        {this.renderAdditionalService()}
        <BoxContainer>
          <SectionContainer>
            <Label>
              Test?
            </Label>
            <Dropdown
              containerStyle={css`
                margin: 0 0.5rem;
              `}
              onChange={(v: string): void => { this.handleChange('is_qa', v); }}
              value={this.state.form.is_qa ? 'Yes' : 'No'}
              width="verySmall"
              options={['Yes', 'No'].map((value: string) => (
                {
                  name: value,
                  value: value,
                }
              ))}
            />

            {orderFiles.length > 0 && form.vehicle_preference != '' &&
              <ButtonContainer>
                <CenterContainer>
                  {error && (
                    <Message className="error">{error}</Message>
                  )}
                  {successMessage !== null ? (
                    <Message>{successMessage}</Message>
                  ) : false}
                </CenterContainer>
                <ImportButton
                  disabled={this.state.isImporting}
                  color={COLOR.blue}
                  onClick={this.handleSubmit}
                >
                  {this.state.isImporting ? 'Importing...' : 'Import'}
                </ImportButton>
              </ButtonContainer>
            }
          </SectionContainer>
        </BoxContainer>
      </SysAdminMainContainer>
    );
  }
}

const SectionContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;

const FileSectionContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`;

const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const LinkButton = styled.button`
  border: none;
  background: ${COLOR.white};
  color: ${COLOR.blue};
  padding: 0.2rem;
  font-size: 0.5rem;
`;

const TemplateContainer = styled.div`
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Span = styled.span`
  font-size: 0.6rem;
  color: gray;
`;

const Text = styled.div`
  font-size: 0.6rem;
  color: gray;
`;

const ImportButton = styled(Button)`
  padding: 0.5rem 4rem;
  border-radius: 14px;
  border: none;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border-top: 1px solid ${COLOR.neutral};
  margin-top: 10px;
`;

const BoxContainer = styled.div`
  box-shadow: ${transparent('shadow', 0.2)} 0px 2px 8px 0px;
  padding: 0.75rem;
  margin: 1rem;
  border-radius: 10px;
  background-color: ${COLOR.white};
`;

const Label = styled.div`
  color: ${COLOR.black};
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.8175rem;
  font-weight: 500;
  font-size: 16px;
`;

const LabelBox = styled.div`
  margin-top: 1.5rem;
`;

const AdditionalServiceContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  color: ${COLOR.darkGray};
`;

const AdditionalTextContainer = styled.div`
  margin-left: 1.7rem;
`;

const VehicleRequirements = styled.div`
  margin-left: 0.5rem;
`;

const SearchContainer = styled.div`
  margin: 0.25rem 0.5rem 1rem 0;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  organizations: state.organization.organizations.filter(org => org.id !== 'FreelanceDriver'),
});

export default connect(mapStateToProps)(NewTaskImport);