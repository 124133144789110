import * as React from 'react';

function SamlNoAccess(): JSX.Element {
  return (
    <div>
      <h2>You do not have access to the GotSurge web. Please contact the Administrator.</h2>
    </div>
  );
}

export default SamlNoAccess;