import * as React from 'react';
import { connect } from 'react-redux';
import {
  Table, TableCellRenderEvent, Dropdown,
  Message, SysAdminPagination,
  PaginationState, CenterModal, TextInput,
} from 'components';
import { RootState } from 'reduxActions/store';
import * as H from 'history';
import { faPlus, faTrash, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Vessel } from 'models/vessel';
import VesselClient from 'httpClients/vesselClient';
import COLOR from 'constants/color';
import {
  ErrorIcon,
  FilterContainer, RemoveButton, SortDropdownContainer,
  TopButtonContainer, Button,
} from './styles';
import AddVesselModal from './modal/addVesselModal';
import { formatError } from 'utils/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface UserIndexProps {
  models: Vessel[];
}

interface VesselsIndexState extends PaginationState<Vessel> {
  error: string | null;
  term: string;
  showAddVesselModal: boolean;
  deleteVesselModal: {
    id: string;
    name: string;
  };
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type Props = UserIndexProps & HistoryProps;

class VesselsIndex extends SysAdminPagination<Vessel, Props, VesselsIndexState> {
  searchTimeout: number | null | NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      basePath: '/sys/vessels',
      breadcrumb: [{ text: 'Vessels' }],
      filters: this.getDefaultFilter(),
      pluralModelName: 'vessels',
      selectedTab: 'vessels',
      error: null,
      term: '',
      showAddVesselModal: false,
      deleteVesselModal: {
        id: '',
        name: '',
      },
    };

    this.rules = [{ name: 'page' }];
  }

  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
    order: 'asc',
    term: '',
  })

  componentDidMount(): void {
    super.componentDidMount();
  }

  fetchData = async (): Promise<void> => {
    this.setState({ error: null, isFetching: true });
    try {
      const client = new VesselClient();
      await client.getVessels(new URLSearchParams(this.state.filters));
    } catch (error) {
      this.setState({
        error,
        isFetching: false,
      });
    }

    this.setState({ isFetching: false });
  }

  deleteVessel = async (): Promise<void> => {
    const vesselClient = new VesselClient();
    try {
      await vesselClient.deleteVessel(this.state.deleteVesselModal.id);
      this.closeDeleteVesselModal();
      this.fetchData();
    } catch (e) {
      this.setState({ error: formatError(e, false) });
      return;
    }
  }

  search = (): void => {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      const value = this.state.term && this.state.term.toUpperCase();
      const isDelete = value === '';
      if (value.length >= 3 || value === '') {
        this.onFilterChange('term', value, isDelete);
      }
    }, 700);
  }

  showAddVesselModal = (): void => {
    this.setState({ showAddVesselModal: true });
  }

  closeAddVesselModal = (): void => {
    this.setState({ showAddVesselModal: false });
  }

  closeAddVesselModalFetch = (): void => {
    this.fetchData();
    this.closeAddVesselModal();
  }

  showDeleteVesselModal = (id: string, name: string): void => {
    this.setState({
      deleteVesselModal: {
        id,
        name,
      },
    });
  }

  closeDeleteVesselModal = (): void => {
    this.setState({
      deleteVesselModal: {
        id: '',
        name: '',
      },
    });
  }

  renderCell = (e: TableCellRenderEvent<{ id: string; name: string; imo: string; updatedBy: string; action: string }>): React.ReactNode => {
    if (e.key === 'action') {
      return (
        <div>
          <RemoveButton
            onClick={(evt: React.MouseEvent): void => { evt.stopPropagation(); this.showDeleteVesselModal(e.data.id, e.data.name); }}
            buttonStyle="discourage"
            buttonType="neutral"
          >
            <ErrorIcon icon={faTrash} />
            Delete
          </RemoveButton>
        </div >
      );
    }

    return <>{e.value}</>;
  };

  renderActionPanel = (): React.ReactNode => (
    <TopButtonContainer>
      <Button
        buttonStyle="encourage"
        buttonType="primary"
        onClick={(): void => { this.showAddVesselModal(); }}
        icon={<FontAwesomeIcon icon={faPlus} />}
        fontWeight={400}
      >
        Add Vessel
      </Button>
      {this.state.showAddVesselModal && this.renderAddVesselModal()}
      {this.state.deleteVesselModal.id && this.renderDeleteModal()}
    </TopButtonContainer>
  )

  renderFilter = (): React.ReactNode => (
    <FilterContainer>
      <TextInput
        icon={(this.state.term || '').length === 0 ? faSearch : faTimes}
        iconOnClick={(): void => {
          this.setState({ term: '' });
          this.search();
        }}
        iconStyle={{
          top: '-1.6rem',
          right: '1rem',
          color: `${COLOR.midDarkGrey}`,
        }}
        height="small"
        width="full"
        onTextChange={(value): void => {
          this.setState({ term: value });
          this.search();
        }}
        placeholder="Search vessel name or IMO"
        value={this.state.term}
      />
      <SortDropdownContainer>
        <Dropdown
          label="Sort by"
          options={[
            {
              value: 'asc',
              name: 'A-Z',
            },
            {
              value: 'desc',
              name: 'Z-A',
            },
          ]}
          onChange={(value: string): void => { this.onFilterChange('order', value); }}
          value={this.state.filters.order}
        />
      </SortDropdownContainer>
    </FilterContainer>
  )

  renderAddVesselModal = (): React.ReactNode => {
    return (
      <AddVesselModal
        saveVesselOnSuccess
        closeModal={this.closeAddVesselModal}
        addVesselSuccess={this.closeAddVesselModalFetch}
      />
    );
  }

  renderDeleteModal(): React.ReactNode {
    return (
      <CenterModal
        leftButtonOnClick={this.closeDeleteVesselModal}
        leftButtonText="Cancel"
        rightButtonStyle="discourage"
        rightButtonType="secondary"
        rightButtonOnClick={(): Promise<void> => this.deleteVessel()}
        rightButtonText="Delete"
        title="Delete Vessel?"
      >
        Deleting {this.state.deleteVesselModal.name} is irreversible and will not affect existing data.
      </CenterModal>
    );
  }

  renderContent = (): React.ReactNode => {
    const { error } = this.state;
    const { models } = this.props;

    const columns = {
      'name': 'Vessel Name',
      'imo': 'IMO No.',
      'updatedBy': 'Updated By',
      'action': '',
    };

    const data = models.map(vessel => ({
      id: vessel.id,
      name: vessel.name,
      imo: vessel.imo,
      updatedBy: vessel.user.name,
      action: vessel.id,
    }));

    return (
      <>
        {error && (
          <Message className='error'>{error}</Message>
        )}
        <Table
          columns={columns}
          data={data}
          rowOnClick={(id: string): void => { this.props.history.push('/sys/vessels/' + id + '/details'); }}
          cellRenderer={this.renderCell}
        />
      </>
    );
  }
}

function mapStateToProps(state: RootState): UserIndexProps {
  return {
    models: state.vessel.vessels,
  };
}

export default connect(mapStateToProps)(VesselsIndex);