import * as React from 'react';
import {
  CustomCard,
  Content,
  Title,
  InfoTable,
  InfoTableRow,
  InfoTableEntry,
  Description,
  LogInfoBox,
  DetailLog,
} from '../styles';
import { PriceAdjustment, PriceAdjustmentLog } from 'models/priceAdjustment';
import { StatusLabel, Separator } from 'components';
import DateHelper from 'utils/dateHelper';
import COLOR from 'constants/color';
import { AdjustmentTaskLog } from 'models/priceAdjustment/priceAdjustmentLog';
import JOB_STATUS, { JOB_STATUS_COLOR } from 'constants/jobStatus';

interface Props {
  priceAdjustments: PriceAdjustment[];
  priceAdjustmentLogs: PriceAdjustmentLog[];
}
interface LogStatusColor {
  [key: string]: string;
}

interface LogStatus {
  [key: string]: string;
}

const LOG_STATUS: LogStatus = {
  'warn': 'Warn',
  'error': 'Error',
  'info': 'Info',
};

export const LOG_STATUS_COLOR: LogStatusColor = {
  'warn': COLOR.orange,
  'error': COLOR.red,
  'info': COLOR.lime,
};

function renderActivityEntry(history: {
  created_at?: Date;
  updated_at?: Date;
  status: string;
  runsheet_id: string;
  current_attempt?: number;
  last_attempt_index: number;
}): React.ReactNode {
  return (
    <InfoTableRow>
      <InfoTableEntry
        size="big"
      >
        {history.created_at && DateHelper.formatDateTime(history.created_at)}
      </InfoTableEntry>
      <InfoTableEntry
        size="big"
      >
        <StatusLabel
          iconColor={JOB_STATUS_COLOR[history.status]}
          noBackground
        >
          {JOB_STATUS[history.status]}
        </StatusLabel>
      </InfoTableEntry>
      <InfoTableEntry
        size="big"
      >
        {history.current_attempt}/
        {history.last_attempt_index}
      </InfoTableEntry>
    </InfoTableRow>
  );
}

function renderDetailLog(details: object | []): React.ReactNode {
  return (
    <LogInfoBox>
      <DetailLog>{JSON.stringify(details, null, 1)}</DetailLog>
    </LogInfoBox>
  );
}

function renderLogActivityEntry(history: {
  created_at?: Date;
  details: {
    tasks?: AdjustmentTaskLog[];
    message?: string;
  };
  message?: string;
  level: string;
  runsheet_id: string;
}): React.ReactNode {
  return (
    <InfoTableRow>
      <InfoTableEntry
        size="big"
      >
        {history.created_at && DateHelper.formatDateTime(history.created_at)}
      </InfoTableEntry>
      <InfoTableEntry
        size="big"
      >
        <StatusLabel
          iconColor={LOG_STATUS_COLOR[history.level]}
          noBackground
        >
          {LOG_STATUS[history.level]}
        </StatusLabel>
        <Description>
          {history.message}
          {history.details && (renderDetailLog(history.details))}
        </Description>
      </InfoTableEntry>
    </InfoTableRow>
  );
}

const PriceAdjustmentCard = ({ priceAdjustments, priceAdjustmentLogs }: Props): JSX.Element => {
  return (
    <CustomCard>
      <Content>
        <Title>Price Adjustment</Title>
        <InfoTable>
          {priceAdjustments.length === 0 && 'No price adjustment'}
          <tbody>
            {priceAdjustments && priceAdjustments.slice().reverse().map(history => renderActivityEntry(history))}
          </tbody>
        </InfoTable>
        <Separator />
        <InfoTable>
          <tbody>
            {priceAdjustmentLogs && priceAdjustmentLogs.slice().reverse().map(priceAdjustmentLogHistory => renderLogActivityEntry(priceAdjustmentLogHistory))}
          </tbody>
        </InfoTable>
      </Content>
    </CustomCard>
  );
};

export default PriceAdjustmentCard;