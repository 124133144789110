import * as React from 'react';
import styled from 'styled-components';
import COLOR, { transparent } from 'constants/color';
import { StyledButton } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const WIDTHS = {
  small: '26rem',
  medium: '38rem',
  large: '50rem',
  full: 'calc(100vw - 10%)',
};

const SMALL_MODAL_POSITION = {
  top: 'top',
  bottom: 'bottom',
};

interface CenterModalProps {
  children: React.ReactNode;
  closeButtonOnClick?: () => void;
  backButtonOnClick?: () => void;
  leftButtonOnClick?: () => void;
  leftButtonText?: React.ReactNode | string;
  leftButtonStyle?: 'encourage' | 'discourage' | 'caution';
  leftButtonType?: 'primary' | 'secondary' | 'neutral'; // remove secondary; deprecated to use neutral instead;
  rightButtonOnClick?: () => void;
  rightButtonText?: React.ReactNode | string;
  rightButtonStyle?: 'encourage' | 'discourage' | 'caution';
  rightButtonType?: 'primary' | 'secondary' | 'neutral'; // remove secondary; deprecated to use neutral instead;
  rightButtonDisabled?: boolean;
  title?: string | React.ReactNode;
  fullBottomButtonOnClick?: () => void;
  fullBottomButtonText?: React.ReactNode | string;
  fullBottomButtonStyle?: 'encourage' | 'discourage' | 'caution';
  fullBottomButtonType?: 'primary' | 'secondary' | 'neutral'; // remove secondary; deprecated to use neutral instead;
  fullBottomButtonDisabled?: boolean;
  width?: keyof typeof WIDTHS;
  customWidthModal?: string;
  onTitleClick?: () => void;
  hasScrollInside?: boolean;
  position?: keyof typeof SMALL_MODAL_POSITION;
  bottomFloatingContent?: React.ReactNode;
  isShowBackButton?: boolean;
  buttonGroupJustifyContent?: 'flex-start' | 'flex-end' | 'space-between';
}

const CenterModal = (props: CenterModalProps): JSX.Element => {
  const isMultipleButton = props.leftButtonText && props.rightButtonText ? true : false;
  return (
    <ModalContainer>
      <Modal
        width={props.width}
        position={props.position}
        customWidthModal={props.customWidthModal}
        hasScrollInside={props.hasScrollInside}
      >
        {props.isShowBackButton && typeof props.backButtonOnClick === 'function' && (
          <ModalBackAndCloseContainer>
            <span onClick={props.backButtonOnClick}>
              <Icon icon={faAngleLeft} />
            </span>
            <span onClick={props.closeButtonOnClick}>
              <Icon icon={faTimes} />
            </span>
          </ModalBackAndCloseContainer>
        )}
        {props.title || props.closeButtonOnClick ? (
          <TitleContainer>
            {props.title ? <Title>{props.title}</Title> : false}
            {props.closeButtonOnClick && !props.isShowBackButton ? (
              <span onClick={props.closeButtonOnClick}>
                <Icon icon={faTimes} />
              </span>
            ) : (
              false
            )}
          </TitleContainer>
        ) : (
          false
        )}
        <div>{props.children}</div>
        {props.leftButtonText || props.rightButtonText || props.fullBottomButtonText ? (
          <ButtonGroup
            width={props.width}
            isMultipleButton={isMultipleButton}
            buttonGroupJustifyContent={props.buttonGroupJustifyContent}
          >
            {props.leftButtonText ? (
              <StyledButton
                onClick={props.leftButtonOnClick}
                buttonStyle={props.leftButtonStyle || 'encourage'}
                buttonType={props.leftButtonType || 'neutral'}
                isFullWidth={props.width === 'small' && isMultipleButton}
              >
                {props.leftButtonText}
              </StyledButton>
            ) : (
              false
            )}
            {props.rightButtonText ? (
              <StyledButton
                onClick={props.rightButtonOnClick}
                buttonStyle={props.rightButtonStyle || 'encourage'}
                buttonType={props.rightButtonType || 'neutral'}
                disabled={props.rightButtonDisabled}
                isFullWidth={props.width === 'small' && isMultipleButton}
              >
                {props.rightButtonText}
              </StyledButton>
            ) : (
              false
            )}
            {props.fullBottomButtonText ? (
              <StyledButton
                type="button"
                onClick={props.fullBottomButtonOnClick}
                buttonStyle={props.fullBottomButtonStyle || 'encourage'}
                buttonType={props.fullBottomButtonType || 'neutral'}
                disabled={props.fullBottomButtonDisabled}
                isFullWidth
              >
                {props.fullBottomButtonText}
              </StyledButton>
            ) : (
              false
            )}
          </ButtonGroup>
        ) : (
          false
        )}
      </Modal>
      {props.bottomFloatingContent ? (
        <BottomFloatingContent>{props.bottomFloatingContent}</BottomFloatingContent>
      ) : (
        false
      )}
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  align-items: center;
  background-color: ${transparent('shadow', 0.5)};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  overflow-y: auto;

  @media (max-width: 768px) {
    align-items: start;
  }
`;

const Modal = styled.div<CenterModalProps>`
  background-color: ${COLOR.white};
  border-radius: 0.5rem;
  box-shadow: 0px 4px 16px ${transparent('shadow', 0.8)};
  padding: 1.5rem;
  max-height: 90vh;
  overflow-y: ${(props): string => (props.hasScrollInside ? 'auto' : '')};
  width: ${(props): string =>
    props.customWidthModal
      ? props.customWidthModal
      : props.width
      ? WIDTHS[props.width]
      : WIDTHS.small};

  @media (max-width: 768px) {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    width: 82%;
    max-height: 80vh;
    overflow-y: auto;

    ${(props): string =>
      props.position === 'bottom'
        ? 'position:fixed;top:auto;right:auto;left:auto;bottom:3rem;'
        : ''}
  }

  @media (max-height: 600px) and (min-width: 768px) {
    margin-top: 15%;
  }

  @media (max-height: 450px) and (min-width: 768px) {
    margin-top: 25%;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${COLOR.grey};
  font-size: 1.3rem;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  width: 100%;
`;

const ButtonGroup = styled.div<{
  width?: keyof typeof WIDTHS;
  isMultipleButton: boolean;
  buttonGroupJustifyContent?: 'flex-start' | 'flex-end' | 'space-between';
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props): string =>
    props.buttonGroupJustifyContent ||
    (props.isMultipleButton ? 'space-between' : 'flex-end')};
  margin-top: 1.5rem;
  gap: 0.5rem;

  @media (max-width: 768px) {
    ${(props): string =>
      props.width === 'small' ? 'flex-direction: column-reverse' : ''}
  }
`;

const BottomFloatingContent = styled.div<CenterModalProps>`
  width: ${(props): string => (props.width ? WIDTHS[props.width] : WIDTHS.small)};
  @media (max-width: 768px) {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    width: 82%;

    ${(props): string =>
      props.position === 'bottom'
        ? 'position:fixed;top:auto;right:auto;left:auto;bottom:3rem;'
        : ''}
  }
  background-color: ${COLOR.white};
  border-radius: 0.5rem;
  box-shadow: 0px 4px 16px ${transparent('shadow', 0.8)};
  padding: 1.5rem;
  margin-top: 0.75rem;
`;

const ModalBackAndCloseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export default CenterModal;
