import * as React from 'react';
import styled from 'styled-components';
import whatsAppLogo from 'assets/images/whatsapp.svg';
import { StyledButton } from 'components';
import { removeNonDigits } from '../utils/formatter';

interface WhatsAppLinkProps {
  phoneNumber: string;
}

class WhatsAppLink extends React.Component<WhatsAppLinkProps> {
  handleWhatsAppClick = (): void => {
    const { phoneNumber } = this.props;
    const cleanedPhoneNumber = removeNonDigits(phoneNumber);
    window.open(`https://wa.me/${cleanedPhoneNumber}`, '_blank', 'noopener noreferrer');
  };

  render(): JSX.Element {
    return (
      <BorderIconWrapper
        buttonStyle="encourage"
        buttonType="neutral"
        onClick={this.handleWhatsAppClick}
      >
        <img src={whatsAppLogo} alt="WhatsApp Logo" width={20} height={20} />
      </BorderIconWrapper>
    );
  }
}

export const BorderIconWrapper = styled(StyledButton)`
  width: 36px;
  height: 36px;
  border-radius: 0.5rem;
  border: 1px solid ${(props): string => props.theme.colors.gray_300};
`;

export default WhatsAppLink;
