import * as React from 'react';
import styled from 'styled-components';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, TextField, SysAdminMainContainer } from 'components';
import RunsheetClient from 'httpClients/runsheetClient';
import { Runsheet } from 'models/runsheet';
import * as H from 'history';

interface StateProps {
  runsheet: Runsheet;
}

interface RunsheetEditProps {
  history: H.History;
  runsheet: Runsheet;
}

interface RunsheetEditState {
  form: {
    name: string;
  };
  error: string | null;
}

interface LocationState {
  from: string;
}

type Props = RouteComponentProps<{ id: string }, object, LocationState> & RunsheetEditProps;

class RunsheetEdit extends React.Component<Props, RunsheetEditState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: {
        name: '',
      },
      error: '',
    };
  }

  componentDidMount(): void {
    const { id } = this.props.match.params;
    this.fetchRunsheet(id);
  }

  componentDidUpdate(prevProps: RunsheetEditProps): void {
    if(prevProps.runsheet !== this.props.runsheet){
      const { runsheet } = this.props;
      if (runsheet) {
        this.setState({
          form: {
            name: runsheet.name,
          },
        });
      }
    }
  }

  fetchRunsheet = async (id: string): Promise<void> => {
    this.setState({ error: null });
    try {
      const client = new RunsheetClient();
      await client.getRunsheet(id);
    } catch (e) {
      this.setState({ error: e });
    }
  }

  goBack = (): void => {
    const { history } = this.props;
    const { location } = history;
    const { id } = this.props.match.params;

    if (location && location.state && location.state.from && location.state.from === `/sys/runsheets/${id}`) {
      history.goBack();
    } else {
      history.push(`/sys/runsheets/${id}`);
    }
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const { id } = this.props.match.params;
    const { runsheet } = this.props;

    this.setState({ error: null });
    try {
      const client = new RunsheetClient();
      await client.editRunsheet({
        name:this.state.form.name,
        version_rev: runsheet.version_rev,
      }, id);
      this.goBack();
    } catch (e) {
      this.setState({ error: e });
    }
  }

  render(): React.ReactNode {
    const { form, error } = this.state;

    return (
      <SysAdminMainContainer selected="runsheets">
        <Container>
          <h1>Edit Runsheet</h1>
          {error && (
           <ErrorMessage>{error}</ErrorMessage>
          )}
          <form onSubmit={this.handleSubmit}>
            <>
              <label>Name</label>
              <TextField
                type="text"
                name="name"
                value={form.name}
                onChange={(e): void => this.handleChange('name', e.target.value)}
              />
            </>
            <>
              <Button type="submit">Edit</Button>
            </>
          </form>
        </Container>
      </SysAdminMainContainer>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 25rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 0.625rem 0;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  runsheet: state.runsheet.runsheet,
});

export default connect(mapStateToProps)(RunsheetEdit);
