import * as React from 'react';
import { Task } from 'models/task';
import styled from 'styled-components';
import { Checkbox, HorizontalTimeline, AddressView } from 'components';
import { Typography } from './typography/typography';
import { VEHICLE_PREFERENCE_DESCRIPTION } from 'constants/vehiclePreference';
import { BROADCAST_PREFERENCE_DESCRIPTION } from 'constants/broadcastPreference';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faShip } from '@fortawesome/free-solid-svg-icons';
import DateHelper from 'utils/dateHelper';
import BadgeGroup, { BadgeChip } from './badge/badgeGroup';
import { getLastUpdatedTask } from 'utils/taskHelper';
import StatusBadge from 'components/statusBadge';
import { formatNumberWithSpaces } from 'utils/formatter';

interface OrderCardProps {
  task: Task;
  onToggleCheckbox?: () => void;
  row: number;
  selected?: string[];
  isCheckboxEnable?: boolean;
  isOps: boolean;
}

const renderServiceInfo = (
  serviceType: string,
  totalLifts: number,
  broadcastPreference: string,
  vehicleDescription: string
): JSX.Element => {
  const renderVehicleDescription =
    serviceType === 'FTL' || serviceType === 'BOAT' || serviceType === 'TRUCK' ? (
      <>
        {vehicleDescription} <BulletPoint icon={faCircle} /> {totalLifts}{' '}
        {totalLifts > 1 ? 'Lifts' : 'Lift'}
      </>
    ) : (
      <>
        {totalLifts} {totalLifts > 1 ? 'Lifts' : 'Lift'}
      </>
    );
  return (
    <>
      {serviceType === 'LTL' && (
        <>
          {serviceType} <BulletPoint icon={faCircle} />{' '}
        </>
      )}{' '}
      {renderVehicleDescription}
      <Typography
        as="span"
        size="xl"
        color="gray_400"
        customStyle={{
          margin: '0 4px',
        }}
      >
        |
      </Typography>
      <Typography as="span" size="md" color="gray_400">
        {BROADCAST_PREFERENCE_DESCRIPTION[broadcastPreference] &&
          BROADCAST_PREFERENCE_DESCRIPTION[broadcastPreference].label}
      </Typography>
    </>
  );
};

const renderServiceDetail = (
  serviceType: string,
  vehicleDescription: string,
  totalLifts: number,
  isMarine: boolean,
  broadcastPreference: string,
  shouldShowTotalWeight: boolean,
  totalItemWeight: string
): JSX.Element => {
  serviceType = (serviceType || '').toUpperCase();
  if (
    (serviceType === 'FTL' ||
      serviceType === 'LTL' ||
      serviceType === 'BOAT' ||
      serviceType === 'TRUCK') &&
    isMarine
  ) {
    return renderServiceInfo(
      serviceType,
      totalLifts,
      broadcastPreference,
      vehicleDescription
    );
  }
  return (
    <>
      {vehicleDescription}{' '}
      {shouldShowTotalWeight ? (
        <>
          <BulletPoint icon={faCircle} /> {totalItemWeight} kg
        </>
      ) : null}
      <Typography
        as="span"
        size="xl"
        color="gray_400"
        customStyle={{
          margin: '0 4px',
        }}
      >
        |
      </Typography>
      <Typography as="span" size="md" color="gray_400">
        {BROADCAST_PREFERENCE_DESCRIPTION[broadcastPreference] &&
          BROADCAST_PREFERENCE_DESCRIPTION[broadcastPreference].label}
      </Typography>
    </>
  );
};

const OrderCard = ({
  task,
  onToggleCheckbox,
  selected,
  isOps,
  isCheckboxEnable = true,
}: OrderCardProps): JSX.Element => {
  const isMarine = task.broadcast_preference === BROADCAST_PREFERENCES.marine;
  const vehicleDescription = VEHICLE_PREFERENCE_DESCRIPTION[task.vehicle_preference];
  const userRoles = (task.user && task?.user.roles) || [];
  let createdIn = '';
  if (userRoles.length > 0) {
    createdIn = userRoles.includes('SysAdmin') ? 'Ops' : 'Merchant';
  }
  const lastUpdated = getLastUpdatedTask(task);
  const shouldShowTotalWeight =
    !isMarine &&
    task.item_summary.total_item_weight > 0 &&
    task.item_summary.total_weight_unit === 'kg';
  const totalItemWeight = formatNumberWithSpaces(task.item_summary.total_item_weight);

  return (
    <OrderCardContainer>
      <CardSummaryWrapper>
        {isCheckboxEnable && (
          <div>
            <Checkbox
              onClick={(e): void => {
                e.stopPropagation();
                onToggleCheckbox();
              }}
              selected={selected.includes(task.id)}
            />
          </div>
        )}
        <CardSummary>
          {lastUpdated ? (
            <BadgeGroup type="grey">
              <BadgeChip>Edited</BadgeChip>
              View Changes {DateHelper.formatSimpleDateTime(lastUpdated.set_at_utc)}
            </BadgeGroup>
          ) : (
            false
          )}
          <div
            style={{
              display: 'flex',
              gap: '0.55rem',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              as="span"
              size="sm"
              customStyle={{
                lineHeight: '1.5',
                maxWidth: '7rem',
                wordWrap: 'break-word',
              }}
            >
              {task.tracking_id || task.id.split('-')[0]}
            </Typography>

            {task.is_qa ? <IsTestLabel>Test</IsTestLabel> : false}

            <StatusBadge status={task.current_status} />
          </div>
          {task.to_contact.vessel_name && (
            <div>
              <Typography
                as="h4"
                color="gray_700"
                weight="semibold"
                customStyle={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                }}
              >
                <FontAwesomeIcon icon={faShip} />
                <span
                  style={{
                    display: 'inline-block',
                    marginLeft: '0.375rem',
                    maxWidth: '14.625rem',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {task.to_contact.vessel_name}
                </span>
              </Typography>
            </div>
          )}
          <div>
            <Typography
              as="p"
              size="md"
              color="gray_700"
              customStyle={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {renderServiceDetail(
                task.service_type,
                vehicleDescription,
                task.item_summary.total_item_quantity ?? 0,
                isMarine,
                task.broadcast_preference,
                shouldShowTotalWeight,
                totalItemWeight
              )}
            </Typography>
          </div>

          {isOps && (
            <>
              <Typography as="p" size="sm" color="gray_400">
                {task.org_name}
              </Typography>
              <Typography as="p" size="sm" color="gray_400">
                {createdIn}
              </Typography>
            </>
          )}
        </CardSummary>
      </CardSummaryWrapper>
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <TimelineDescriptionContainer>
          <HorizontalTimeline
            type="pickup"
            currentStatus={task.current_status}
            hasNextLocation={true}
            style={{
              marginLeft: '-3px',
            }}
          />
          <BuildingNameWrapper>
            <AddressView address={task.from_address} noWrap />
            <Typography
              as="p"
              mdSize="md"
              color="gray_600"
              customStyle={{
                marginTop: '0.35rem',
              }}
            >
              {task && task.from_time_window ? (
                <>
                  Est.{' '}
                  {DateHelper.formatSimpleDateTime(task.from_time_window.start_time_utc)}
                </>
              ) : (
                '-'
              )}
            </Typography>
          </BuildingNameWrapper>

          <div>
            {task.org_transporter || task.driver ? (
              <>
                <Typography
                  as="div"
                  size="sm"
                  color="gray_700"
                  weight="semibold"
                  customStyle={{ marginBottom: '0.25rem' }}
                >
                  Transport-Partner
                </Typography>
                {task.org_transporter ? (
                  <>
                    {isOps && (
                      <>
                        <Typography
                          as="p"
                          color="gray_600"
                          customStyle={{ marginBottom: '0.25rem' }}
                        >
                          {task.org_transporter.name}
                        </Typography>
                      </>
                    )}
                    <Typography as="p" color="gray_600">
                      {task.org_transporter.org_driver.name
                        ? task.org_transporter.org_driver.name
                        : '-'}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography as="p" color="gray_600">
                      {task.driver.name}
                    </Typography>
                  </>
                )}
              </>
            ) : null}
          </div>
        </TimelineDescriptionContainer>

        <TimelineDescriptionContainer>
          <HorizontalTimeline
            type="delivery"
            currentStatus={task.current_status}
            hasNextLocation={false}
            style={{
              marginLeft: '-3px',
            }}
          />
          <BuildingNameWrapper>
            <AddressView address={task.to_address} noWrap />
            <Typography
              as="p"
              mdSize="md"
              color="gray_600"
              customStyle={{
                marginTop: '0.35rem',
              }}
            >
              {task && task.to_time_window ? (
                <>
                  Est.{' '}
                  {DateHelper.formatSimpleDateTime(task.to_time_window.start_time_utc)}
                </>
              ) : (
                '-'
              )}
            </Typography>
          </BuildingNameWrapper>
          <div>
            {/* {task.to_contact?.lighter_company_name ||
              task.to_contact?.lighter_boat_name ? (
              <>
                <Typography
                  as="div"
                  size="sm"
                  color="gray_700"
                  weight="semibold"
                  customStyle={{ marginBottom: '0.25rem' }}
                >
                  Transport-Partner
                </Typography>

                {task.to_contact?.lighter_company_name && (
                  <Typography
                    as="p"
                    color="gray_600"
                    customStyle={{ marginBottom: '0.25rem' }}
                  >
                    {task.to_contact?.lighter_company_name}
                  </Typography>
                )}
                {task.to_contact?.lighter_boat_name && (
                  <Typography as="p" color="gray_600">
                    {task.to_contact.lighter_boat_name
                      ? task.to_contact.lighter_boat_name
                      : '-'}
                  </Typography>
                )}
              </>
            ) : null} */}
          </div>
        </TimelineDescriptionContainer>
      </div>
    </OrderCardContainer>
  );
};

const OrderCardContainer = styled.div`
  padding: 1rem;
  border-radius: 0.75rem;
  background: var(--base-white, #fff);

  box-shadow: 0px 1px 1px 2px rgba(222, 222, 222, 0.06),
    0px 2px 6px 0px ${(props): string => props.theme.colors.gray_200};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    max-width: calc(100% - 34px);
  }
`;

const CardSummaryWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 1rem;
  overflow: hidden;
  border-bottom: 1px solid #dedede;
  padding-bottom: 1rem;

  @media (min-width: 768px) {
    border-right: 0.0625rem solid #dedede;
    border-bottom: none;
    padding-bottom: 0rem;
    min-width: 21rem;
    max-width: 21rem;
    padding-right: 1rem;
  }
`;

const CardSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.23rem;

  @media (min-width: 768px) {
    gap: 0.56rem;
    width: 100%;
  }
`;

const TimelineDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  width: 50%;
  gap: 0.75rem;
`;

const BuildingNameWrapper = styled.div`
  max-width: 94%;
  @media (min-width: 768px) {
    max-width: 90%;
  }
`;

export const BulletPoint = styled(FontAwesomeIcon)`
  font-size: 6px;
  margin-left: 4px;
  margin-right: 4px;
`;

export const IsTestLabel = styled.span`
  border-radius: 1rem;
  background-color: ${(props): string => props.theme.colors.pink_50};
  color: ${(props): string => props.theme.colors.pink_700};
  padding: 0.125rem 0.5rem;
`;

export default OrderCard;
