import * as React from 'react';
import { connect } from 'react-redux';
import TaskClient from 'httpClients/taskClient';
import {
  Breadcrumb,
  MainContainer,
  TextInput,
  Typography,
  Searchbox,
  Checkbox,
  CenterModal,
  StyledButton,
  Alert,
} from 'components';
import { RouteComponentProps } from 'react-router-dom';
import * as H from 'history';
import 'react-datepicker/dist/react-datepicker.css';
import {
  TaskEditableStatusesMarineMerchant,
  ActiveOrderStatusStrings,
} from 'constants/taskStatus';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import DateHelper from 'utils/dateHelper';
import * as moment from 'moment';
import {
  faChevronLeft,
  faCheck,
  faSearch,
  faTimes,
  faTrash,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  TimeWindow,
  Task,
  MerchantBaseForm,
  MerchantDeliveryForm,
  FileWithData,
  Item,
  Attachment,
  AttachmentForm,
  MerchantOrderEditForm,
} from 'models/task';
import COLOR from 'constants/color';
import { RootState } from 'reduxActions/store';
import { Organization } from 'models/organization';
import {
  ONE_SGD,
  ALLOWED_MIN_PRICE,
  DEFAULT_SQUAD_PRICE,
  DEFAULT_MIN_PRICE,
  DEFAULT_MAX_PRICE,
} from 'constants/priceDetails';
import { ALLOWED_FILE_TYPE } from 'constants/attachmenfFile';
import { GeoData, SavedGeoData } from 'models/geoService';
import configureStore from 'reduxActions/store';
import { receiveVesselAllSuccess } from 'reduxActions/vessel/vesselActions';
import TaskImportClient from 'httpClients/taskImportClient';
import { computeChecksumMd5 } from 'utils/computeChecksum';
import VesselClient from 'httpClients/vesselClient';
import RunsheetClient from 'httpClients/runsheetClient';
import NotificationPusherClient from 'httpClients/notificationPusherClient';
import { Vessel } from 'models/vessel';
import { FileData } from 'models/taskImport';
import { AddressContactData } from '../sys/task/modal/addressContactModal';
import VehicleForm from '../sys/task/partialForm/vehicleForm';
import {
  getServicePricing,
  isSendEditNotification,
  isItemExceedingVehicleDimensions,
} from 'utils/taskHelper';
import { formatError } from 'utils/formatter';
import { DEFAULT_FTL_VEHICLE, DEFAULT_SERVICE_TYPE } from 'constants/serviceType';
import {
  ActionButtonContainer,
  OrderCard,
  OrderTitle,
  OrderIcon,
  AdditionalServiceContent,
  RedText,
  Button,
  ActionContainer,
  AdditionalServiceCard,
  DateAndTimeContainer,
  DatePickerWrapper,
  EnterManualEmptyPlaceholderButton,
  EnterManualButton,
  ImoText,
  ImoInfoAlert,
  EmptyVesselPlaceholder,
  VesselCardRow,
  inlineTextInputStyle,
  Calendar,
  ConfirmOrderModalText,
  ConfirmOrderModalButtons,
  BottomMargin,
  ErrorModalText,
  RemoveCargoDetailsIcon,
  RemoveCargoDetailsSubTitle,
} from './styles';
import PickupForm from './cards/pickupForm';
import DeliveryForm from './cards/deliveryForm';
import GeneralInfoForm from './partialForm/generalInfoForm';
import FilesUpload from './cards/filesUpload';
import CargoDetails from './cards/cargoDetails';
import { StatusType } from 'models/task/status';
import { VEHICLE_PREFERENCE_INFO } from 'constants/vehiclePreference';

interface LocationState {
  from: string;
}

interface StateProps {
  task: Task;
  organization: Organization;
  vessels: Vessel[];
  geoDatas: GeoData[];
  savedGeoDatas: SavedGeoData[];
}

interface OrderDeclaration {
  correctAndComplete: boolean;
}

interface OrderEditState {
  baseForm: MerchantBaseForm;
  deliveryForms: MerchantDeliveryForm[];
  isSaving: boolean;
  isFetchingPrice: boolean;
  isFetchingVessel: boolean;
  isItemsChanged: boolean;
  orderDeclaration: OrderDeclaration;
  error: string | null;
  isSuccessful: boolean;
  showRecommendPriceSuccess: boolean;
  showRecommendPriceInfo: boolean;
  showRecommendPriceError: boolean;
  showSwitchViewModal: boolean;
  showConfirmOrderModal: boolean;
  showRemoveCargoDetailsModal: {
    deliveryFormIndex: number | boolean;
    cargoDetailsIndex: number;
  };
  showRemoveCargoDetailsSuccessModal: boolean;
  showSwitchServiceWarningModal: string;
  showPickupAddVesselModal: boolean;
  showDeliveryAddVesselModal: boolean | number;
  hasCargoNet: boolean;
  hasOfficerContact: boolean;
  isFetchSavedAddress: boolean;
  updatedFieldNames: string[];
  showRemoveModal: {
    deliveryFormIndex: number | boolean;
    itemIndex: number;
    actionType: 'cargo_details' | 'items' | '';
  };
  showRemoveSuccessModal: string;
  totalWeight: number;
}

const TaskNotEditableFields = [
  'general_info_form',
  'from_address.street_address',
  'from_address.unit_number',
  'to_address.street_address',
  'to_address.unit_number',
  'from_time_window.start_time_utc',
  'from_time_window.end_time_utc',
  'to_time_window.start_time_utc',
  'to_time_window.end_time_utc',
  'tracking_id',
  'invoice_number',
  'group_tag',
  'items',
  'has_cargo_net',
  'cargo_net_quantity',
  'has_officer_contact',
  'has_officer_contact.name',
  'has_officer_contact.phone',
  'price',
  'select_vehicle',
  'service_type',
  'cargo_details',
];

function getNotEditableFieldsByStatus(
  status: StatusType,
  broadcastPreference: string
): string[] {
  if (BROADCAST_PREFERENCES.marine !== broadcastPreference) {
    return TaskNotEditableFields;
  }
  // Marine usecase
  const marineNotEditableFields = [
    'general_info_form',
    'cargo_net_quantity',
    'has_cargo_net',
    'has_officer_contact',
    'has_officer_contact.name',
    'has_officer_contact.phone',
  ];

  if (
    status === 'pending_broadcast' ||
    status === 'broadcasting' ||
    status === 'accepted'
  ) {
    return marineNotEditableFields;
  } else if (status === 'pending_pickup' || status === 'pickup_arriving') {
    return [
      ...marineNotEditableFields,
      'service_type',
      'select_vehicle',
      'from_location_type',
      'pickup_location',
      'pickup_contact_details',
      'from_address.street_address',
      'from_address.unit_number',
      'from_time_window.start_time_utc',
      'from_time_window.end_time_utc',
      'to_location_type',
      'to_contact.vessel_name',
      'tracking_id',
      'attachments',
      'cargo_details',
      'from_contact.etb_time_window.start_time_utc',
      'from_contact.etb_time_window.end_time_utc',
      'from_contact.etu_time_window.start_time_utc',
      'from_contact.etu_time_window.end_time_utc',
      'from_contact.berth_no',
    ];
  } else if (status === 'pending_delivery' || status === 'delivery_arriving') {
    return [
      ...marineNotEditableFields,
      'service_type',
      'select_vehicle',
      'from_location_type',
      'pickup_location',
      'pickup_contact_details',
      'from_address.street_address',
      'from_address.unit_number',
      'from_time_window.start_time_utc',
      'from_time_window.end_time_utc',
      'to_location_type',
      'to_contact.vessel_name',
      'tracking_id',
      'attachments',
      'cargo_details',
      'from_contact.etb_time_window.start_time_utc',
      'from_contact.etb_time_window.end_time_utc',
      'from_contact.etu_time_window.start_time_utc',
      'from_contact.etu_time_window.end_time_utc',
      'from_contact.berth_no',
    ];
  } else {
    return TaskNotEditableFields;
  }
}

interface OrderEditProps<S = H.LocationState> extends StateProps {
  history: H.History<S>;
}

type Props = RouteComponentProps<{ id: string }, object, LocationState> & OrderEditProps;

const CURRENT_DATE = new Date();
class OrderEdit extends React.Component<Props, OrderEditState> {
  searchPickupTimeout: number | null | NodeJS.Timeout;
  searchDeliveryTimeout: number | null | NodeJS.Timeout;
  searchVesselTimeout: number | null | NodeJS.Timeout;
  constructor(props: Props) {
    super(props);
    this.state = {
      baseForm: this.getDefaultBaseForm(),
      deliveryForms: [this.getDefaultDeliveryForm()],
      isSaving: false,
      isFetchingPrice: false,
      isFetchingVessel: false,
      isItemsChanged: false,
      orderDeclaration: {
        correctAndComplete: false,
      },
      error: null,
      isSuccessful: false,
      showRecommendPriceSuccess: false,
      showRecommendPriceInfo: false,
      showRecommendPriceError: false,
      showSwitchViewModal: false,
      showConfirmOrderModal: false,
      showRemoveCargoDetailsModal: { deliveryFormIndex: false, cargoDetailsIndex: 0 },
      showRemoveCargoDetailsSuccessModal: false,
      showSwitchServiceWarningModal: '',
      showPickupAddVesselModal: false,
      showDeliveryAddVesselModal: false,
      hasCargoNet: false,
      hasOfficerContact: false,
      isFetchSavedAddress: false,
      updatedFieldNames: [],
      showRemoveModal: { deliveryFormIndex: false, itemIndex: 0, actionType: '' },
      showRemoveSuccessModal: '',
      totalWeight: 0,
    };
  }

  componentDidMount(): void {
    const { id } = this.props.match.params;
    this.fetchTask(id);
  }

  componentDidUpdate(prevProps: Props, prevState: OrderEditState): void {
    const { task } = this.props;
    if (prevProps.task !== task) {
      const hasCargoNet = task.cargo_net_quantity && true;
      const hasOfficerContact = task.officer_contact.name && true;
      this.setState({
        baseForm: this.getDefaultBaseForm(),
        deliveryForms: [this.getDefaultDeliveryForm()],
        hasCargoNet,
        hasOfficerContact,
      });
    }

    if (
      this.state.baseForm &&
      this.state.baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine &&
      prevState.totalWeight !== this.state.totalWeight &&
      this.state.baseForm.vehicle_preference in VEHICLE_PREFERENCE_INFO &&
      this.state.totalWeight >
        VEHICLE_PREFERENCE_INFO[this.state.baseForm.vehicle_preference].max_weight
    ) {
      this.setState((prevState) => ({
        ...prevState,
        baseForm: {
          ...prevState.baseForm,
          vehicle_preference: '',
        },
      }));
    }
  }

  getDefaultBaseForm(): MerchantBaseForm {
    const task = this.props.task;
    if (task) {
      return {
        org_id: task.org_id,
        is_qa: task.is_qa,
        from_address: task.from_address,
        from_time_window: task.from_time_window,
        from_contact: task.from_contact,
        min_price: task.min_price,
        max_price: task.max_price,
        increment: ONE_SGD,
        admin_fee: task.admin_fee,
        broadcast_preference: task.broadcast_preference,
        vehicle_preference: task.vehicle_preference,
        service_type: task.service_type,
        pickup_note_to_driver: task.pickup_note_to_driver,
        cargo_net_quantity: task.cargo_net_quantity,
        officer_contact: task.officer_contact,
        client: task.client,
        task_creation_group: task.task_creation_group,
        version_rev: task.version_rev,
        price: task.price,
        from_location_type: task.from_location_type || 'land', // handle previous data without location_type
      };
    }
  }

  getDefaultDeliveryForm(): MerchantDeliveryForm {
    const task = this.props.task;
    if (task) {
      const attachment_files: FileWithData[] = task.attachments.map(
        (attachment: Attachment) => ({
          file: {
            name: attachment.name,
            size: attachment.size,
            type: '',
          } as File,
          fileData: {
            file_name: attachment.name,
            file_size: attachment.size,
            file_type: '',
            url: attachment.url,
            s3_upload_bucket: '',
            s3_upload_key: '',
            checksum: '',
            checksum_method: '',
          },
          prviewUrl: attachment.url,
          action: 'view',
          id: attachment.id,
        })
      );
      const items: {
        name: string;
        quantity: number;
        has_hazard_mat: boolean;
        dimension: { length: number; width: number; height: number };
      }[] = task.items.map((item: Item) => ({
        name: item.name,
        quantity: item.quantity,
        has_hazard_mat: item.has_hazard_mat ?? false,
        weight: item.weight ?? 0,
        dimension: {
          length: item.dimension.length,
          width: item.dimension.width,
          height: item.dimension.height,
        },
      }));
      return {
        attachment_files: attachment_files,
        task_creation_group: task.task_creation_group,
        to_location_type: task.to_location_type || 'land', // handle previous data without location_type
        to_address: task.to_address,
        to_time_window: task.to_time_window,
        distance_in_meters: task.distance_in_meters,
        to_contact: task.to_contact,
        tracking_id: task.tracking_id,
        invoice_number: task.invoice_number,
        group_tag: task.group_tag,
        items: items,
        delivery_note_to_driver: task.delivery_note_to_driver,
        cargo_details: task.cargo_details ?? [
          {
            id: '',
            name: '',
            quantity: 1,
            quantity_unit: '',
            remarks: '',
            has_hazard_mat: false,
            sku: '',
            volume_unit: '',
            weight_unit: '',
          },
        ],
        vehicle_preference: task.vehicle_preference,
      };
    }
  }

  getDefaultPickupTimeWindow(): TimeWindow {
    return {
      start_timezone: 'Asia/Singapore',
      start_time_utc: CURRENT_DATE,
      end_timezone: 'Asia/Singapore',
      end_time_utc: this.addHoursToDateTime(1, CURRENT_DATE),
    };
  }

  getDefaultDeliveryTimeWindow(): TimeWindow {
    return {
      start_timezone: 'Asia/Singapore',
      start_time_utc: this.addHoursToDateTime(1, CURRENT_DATE),
      end_timezone: 'Asia/Singapore',
      end_time_utc: this.addHoursToDateTime(2, CURRENT_DATE),
    };
  }

  addHoursToDateTime(hours: number, dateTime: Date): Date {
    return new Date(dateTime.getTime() + hours * 60 * 60 * 1000);
  }

  fetchTask = async (id: string): Promise<void> => {
    const client = new TaskClient();
    await client.getTask(id);
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (this.allowUpdate()) {
      this.setState({ error: null, isSaving: true });
      const { baseForm, deliveryForms } = this.state;
      const { id } = this.props.match.params;

      try {
        const client = new TaskClient();
        await Promise.all(
          deliveryForms.map(
            async (deliveryForm: MerchantDeliveryForm, deliveryIndex: number) => {
              let minPrice = baseForm.min_price;
              let maxPrice = baseForm.max_price;
              if (
                baseForm.service_type === 'ltl' &&
                baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine
              ) {
                const selectedService = getServicePricing(baseForm.service_type, 'ltl');
                const priceValue = Math.floor(selectedService.price * 100) * 1000;
                minPrice = priceValue * this.totalCargoLifts(deliveryIndex);
                maxPrice = priceValue * this.totalCargoLifts(deliveryIndex);
              }
              const form: MerchantOrderEditForm = {
                org_id: baseForm.org_id,
                is_qa: baseForm.is_qa,
                broadcast_preference: baseForm.broadcast_preference,
                vehicle_preference: baseForm.vehicle_preference,
                service_type: baseForm.service_type,
                from_address: baseForm.from_address,
                from_contact: baseForm.from_contact,
                from_time_window: baseForm.from_time_window,
                pickup_note_to_driver: baseForm.pickup_note_to_driver,
                to_address: deliveryForm.to_address,
                to_contact: deliveryForm.to_contact,
                to_time_window: deliveryForm.to_time_window,
                delivery_note_to_driver: deliveryForm.delivery_note_to_driver,
                min_price: minPrice,
                max_price: maxPrice,
                increment: baseForm.increment,
                admin_fee: baseForm.admin_fee,
                version_rev: baseForm.version_rev,
                currency: 'SGD',
                distance_in_meters: deliveryForm.distance_in_meters,
                client_time_utc: new Date(),
                client_timezone: 'Asia/Singapore',
                items: deliveryForm.items,
                tracking_id: deliveryForm.tracking_id,
                invoice_number: deliveryForm.invoice_number,
                group_tag: deliveryForm.group_tag,
                client: baseForm.client,
                task_creation_group: baseForm.task_creation_group,
              };
              if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
                form.from_contact.vessel_name = baseForm.from_contact.vessel_name;
                form.from_contact.lighter_boat_name =
                  baseForm.from_contact.lighter_boat_name;
                form.from_contact.lighter_company_name =
                  baseForm.from_contact.lighter_company_name;
                form.cargo_details = deliveryForm.cargo_details;

                if (
                  baseForm.from_contact.etb_time_window &&
                  baseForm.from_contact.etb_time_window.start_time_utc &&
                  baseForm.from_contact.etb_time_window.end_time_utc
                ) {
                  form.from_contact.etb_time_window =
                    baseForm.from_contact.etb_time_window;
                  form.from_contact.etb_time_window.start_timezone = 'Asia/Singapore';
                  form.from_contact.etb_time_window.end_timezone = 'Asia/Singapore';
                } else {
                  form.from_contact.etb_time_window = null;
                }

                if (
                  baseForm.from_contact.etu_time_window &&
                  baseForm.from_contact.etu_time_window.start_time_utc &&
                  baseForm.from_contact.etu_time_window.end_time_utc
                ) {
                  form.from_contact.etu_time_window =
                    baseForm.from_contact.etu_time_window;
                  form.from_contact.etu_time_window.start_timezone = 'Asia/Singapore';
                  form.from_contact.etu_time_window.end_timezone = 'Asia/Singapore';
                } else {
                  form.from_contact.etu_time_window = null;
                }

                form.to_contact.vessel_name = deliveryForm.to_contact.vessel_name;
                form.to_contact.lighter_boat_name =
                  deliveryForm.to_contact.lighter_boat_name;
                form.to_contact.lighter_company_name =
                  deliveryForm.to_contact.lighter_company_name;

                if (
                  deliveryForm.to_contact.etb_time_window &&
                  deliveryForm.to_contact.etb_time_window.start_time_utc &&
                  deliveryForm.to_contact.etb_time_window.end_time_utc
                ) {
                  form.to_contact.etb_time_window =
                    deliveryForm.to_contact.etb_time_window;
                  form.to_contact.etb_time_window.start_timezone = 'Asia/Singapore';
                  form.to_contact.etb_time_window.end_timezone = 'Asia/Singapore';
                } else {
                  form.to_contact.etb_time_window = null;
                }

                if (
                  deliveryForm.to_contact.etu_time_window &&
                  deliveryForm.to_contact.etu_time_window.start_time_utc &&
                  deliveryForm.to_contact.etu_time_window.end_time_utc
                ) {
                  form.to_contact.etu_time_window =
                    deliveryForm.to_contact.etu_time_window;
                  form.to_contact.etu_time_window.start_timezone = 'Asia/Singapore';
                  form.to_contact.etu_time_window.end_timezone = 'Asia/Singapore';
                } else {
                  form.to_contact.etu_time_window = null;
                }

                form.to_contact.berth_no = deliveryForm.to_contact.berth_no;

                form.cargo_net_quantity = baseForm.cargo_net_quantity;
                form.officer_contact = baseForm.officer_contact;
                form.price = baseForm.price;
                form.min_price = baseForm.price;
                form.max_price = baseForm.price;
                // from and to location type
                form.from_location_type = baseForm.from_location_type;
                form.to_location_type = deliveryForm.to_location_type;
              } else {
                if (!this.props.task.runsheet) {
                  // Handle if user remove the task from runsheet and edit task
                  form.price = baseForm.price;
                }
              }
              let totalItemCount = 0;
              for (let i = form.items.length - 1; i >= 0; i--) {
                if (form.items[i].name.trim().length === 0) {
                  form.items.splice(i, 1);
                } else {
                  // add default value 1 when quantity is not filled or negative
                  if (!form.items[i].quantity || form.items[i].quantity <= 0) {
                    form.items[i].quantity = 1;
                  }
                  if (form.items[i].quantity >= 1) {
                    totalItemCount++;
                  }
                }
              }
              form.total_item_count = totalItemCount;

              // archive removed file attachments
              const filesToArchived = deliveryForm.attachment_files.filter(
                (attachment) => attachment.action === 'remove'
              );
              if (filesToArchived.length > 0) {
                form['archived_attachments'] = filesToArchived.map((attachment) => {
                  return {
                    attachment_id: attachment.id,
                    associated_task_status: '', // will be use if edit from task.statuses['task_status'].attachments
                  };
                });
              }

              // attachment update
              // upload new file attachments
              const filesToUpload = deliveryForm.attachment_files.filter(
                (attachment) => attachment.action === 'add'
              );
              if (
                deliveryForm.attachment_files.length > 0 &&
                baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine &&
                filesToUpload.length > 0
              ) {
                const taskAttachment: AttachmentForm[] = [];
                for (let i = 0; i < filesToUpload.length; i++) {
                  if (!filesToUpload[i].fileError) {
                    filesToUpload[i].fileData.url = await this.getPresignedUrl(
                      id,
                      filesToUpload[i].file.name
                    );
                    taskAttachment.push({
                      name: filesToUpload[i].file.name,
                      url: filesToUpload[i].fileData.url,
                    });
                  }
                }
                await this.uploadToS3(
                  filesToUpload.filter((attachment) => attachment.fileData.url !== '')
                );
                const responseAddAttachment = await client.addAttachment(id, {
                  attachments: taskAttachment,
                  version_rev: form.version_rev as string,
                });
                if (responseAddAttachment && responseAddAttachment.version_rev) {
                  form.version_rev = responseAddAttachment.version_rev;
                }
              }

              const task = await client.updateTask(id, form);

              // notification update
              if (task) {
                const npClient = new NotificationPusherClient();
                if (task && task.runsheet && task.broadcast_preference === 'marine') {
                  let orgId = (task.org_transporter && task.org_transporter.id) || '';
                  let sendAsRunsheet = false;
                  const runsheetData = {
                    category: 'task-management',
                    last_broadcast_expired_at: '',
                    runsheet_id: task.runsheet.id,
                  };
                  const taskData = {
                    category: 'task-management',
                    task_id: task.id,
                    task_current_status: task.current_status,
                  };
                  if (!orgId) {
                    const runsheetClient = new RunsheetClient();
                    const updatedRunsheet = await runsheetClient.getRunsheetData(
                      task.runsheet.id
                    );
                    if (updatedRunsheet) {
                      orgId =
                        updatedRunsheet.org_transporter &&
                        updatedRunsheet.org_transporter.id;
                      sendAsRunsheet = true;
                    }
                  }
                  if (orgId) {
                    // publish event to transport-partner within the organiziation
                    npClient.pushEvents({
                      events: [
                        {
                          id: task.id,
                          channel: `gotsurge-orgid-${orgId}`,
                          name: 'task-management',
                          data: {
                            task_id: sendAsRunsheet ? '' : task.id,
                            runsheet_id: sendAsRunsheet ? task.runsheet.id : '',
                          },
                        },
                      ],
                    });
                    // marine check if updated field should send notification
                    if (isSendEditNotification(task, this.state.updatedFieldNames)) {
                      // send notification to transport-partner within the organiziation
                      npClient.pushNotification({
                        push_messages: [
                          {
                            id: task.id,
                            title: 'Order Edited',
                            body: 'Order has been edited.',
                            data: sendAsRunsheet ? runsheetData : taskData,
                            sound: 'default',
                          },
                        ],
                        application_key: 'gotsurge-mobile-transport',
                        org_id: orgId,
                      });
                    }
                  }
                }
                if (task && task.driver && task.driver.id) {
                  // publish event to pro-driver and freelance driver
                  npClient.pushEvents({
                    events: [
                      {
                        id: task.id,
                        channel: `gotsurge-${task.driver.id}`,
                        name: 'task-management',
                        data: {
                          task_id: task.id,
                        },
                      },
                    ],
                  });
                  // standard & squad check if updated field should send notification
                  if (isSendEditNotification(task, this.state.updatedFieldNames)) {
                    // sending notification to pro-driver and freelance driver
                    npClient.pushNotification({
                      push_messages: [
                        {
                          id: task.id,
                          user_id: task.driver.id,
                          title: 'Order Edited',
                          body: 'Order has been edited.',
                          data: {
                            category: 'task-management',
                            task_id: task.id,
                          },
                          sound: 'default',
                        },
                      ],
                      application_key: 'gotsurge-mobile-driver',
                    });
                  }
                }
              }
            }
          )
        );
        this.setState({ showConfirmOrderModal: false, isSuccessful: true });
      } catch (e) {
        this.setState({
          error: formatError(e),
          isSaving: false,
          showConfirmOrderModal: false,
          isSuccessful: false,
        });
      }
    }
  };

  getPresignedUrl = async (id: string, fileName: string): Promise<string> => {
    const client = new TaskClient();
    return await client.getSignedAttachmentLink(id, fileName);
  };

  onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    deliveryFormIndex: number
  ): Promise<void> => {
    const file = e.target.files[0];
    let blobURL = null;
    if (file.type.includes('image') || file.type.includes('pdf')) {
      blobURL = URL.createObjectURL(file);
    }
    if (file) {
      const newFileData: FileData = {
        file_name: file.name,
        file_size: file.size,
        s3_upload_bucket: '',
        s3_upload_key: '',
        url: '',
        checksum: await computeChecksumMd5(file),
        checksum_method: 'MD5',
      };
      const fileWithData: FileWithData = {
        file: file,
        fileData: newFileData,
        prviewUrl: blobURL,
        action: 'add',
      };
      if (file.size > 20971520) {
        fileWithData.fileError = 'File size should not be more than 20MB';
      }
      if (!ALLOWED_FILE_TYPE.includes(file.type)) {
        fileWithData.fileError = 'Only accepts png, jpg, pdf, doc, docx, xlsx, csv.';
      }
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          ['attachment_files']:
            updatedDeliveryForms[deliveryFormIndex].attachment_files.concat(fileWithData),
        };
        return {
          deliveryForms: updatedDeliveryForms,
        };
      });
    }
  };

  onFileRemove = (attachmentIndex: number, previewUrl: string): void => {
    const deliveryFormIndex = 0;
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      const deliveryForm = { ...updatedDeliveryForms[deliveryFormIndex] };
      const attachmentFiles = [...(deliveryForm.attachment_files || [])];
      const index =
        attachmentIndex ||
        attachmentFiles.findIndex((file) => file.prviewUrl === previewUrl);
      // Check if the file exists at the given index
      if (attachmentFiles[index]) {
        if (attachmentFiles[index].action === 'view') {
          // Set the action to 'remove' from task
          attachmentFiles[index] = {
            ...attachmentFiles[index],
            action: 'remove',
          };
        } else if (attachmentFiles[index].action === 'add') {
          // Remove the file from the attachment files array
          attachmentFiles.splice(index, 1);
        }
      }

      // Update the delivery form with the modified attachment files array
      deliveryForm.attachment_files = attachmentFiles;

      // Update the delivery forms array with the modified delivery form
      updatedDeliveryForms[deliveryFormIndex] = deliveryForm;
      // Return the new state
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  uploadToS3 = async (filesWithData: FileWithData[]): Promise<void> => {
    try {
      const client = new TaskImportClient();
      for (const fileWithData of filesWithData) {
        await client.uploadToS3(fileWithData.fileData.url, fileWithData.file);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  updateBaseForm = (fieldName: string, value: string | boolean | Date): void => {
    if (typeof value === 'string') {
      switch (fieldName) {
        case 'broadcast_preference': {
          const org = this.props.organization;
          const minPrice = org?.min_price || DEFAULT_MIN_PRICE;
          const maxPrice = org?.max_price || DEFAULT_MAX_PRICE;
          let minMaxPrice = {
            min_price: minPrice,
            max_price: maxPrice,
          };
          let vehiclePreference = '';
          if (value === BROADCAST_PREFERENCES.squad) {
            minMaxPrice = {
              min_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
              max_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
            };
          } else if (value === BROADCAST_PREFERENCES.marine) {
            const servicePricing = getServicePricing(
              DEFAULT_SERVICE_TYPE,
              DEFAULT_FTL_VEHICLE
            );
            const priceValue = Math.floor(servicePricing.price * 100) * 1000;
            minMaxPrice = {
              min_price: priceValue,
              max_price: priceValue,
            };
            vehiclePreference = DEFAULT_FTL_VEHICLE;
          } else {
            vehiclePreference = '';
          }
          this.setState(
            (prevState) => ({
              baseForm: {
                ...prevState.baseForm,
                [fieldName]: value,
                ...minMaxPrice,
                service_type: DEFAULT_SERVICE_TYPE,
                vehicle_preference: vehiclePreference,
              },
              showRecommendPriceInfo: false,
              showRecommendPriceSuccess: false,
            }),
            () => {}
          );
          return;
        }
        case 'max_price':
        case 'min_price': {
          const priceValue = Math.floor(parseFloat(value) * 100) * 1000;
          let minMaxPrice = {
            [fieldName]: priceValue,
          };
          if (this.state.baseForm.broadcast_preference === BROADCAST_PREFERENCES.squad) {
            minMaxPrice = {
              [fieldName]: priceValue,
              max_price: priceValue,
            };
          }
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              ...minMaxPrice,
            },
          }));
          return;
        }
        case 'increment': {
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              increment: parseInt(value),
            },
          }));
          return;
        }
        case 'cargo_net_quantity': {
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              cargo_net_quantity: parseInt(value),
            },
          }));
          return;
        }
        case 'service_type': {
          let vehiclePreference = '';
          if (value === 'ltl') {
            vehiclePreference = 'ltl';
          }
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              service_type: value,
              vehicle_preference: vehiclePreference,
            },
          }));
          return;
        }
        case 'from_location_type': {
          let prevBaseForm = this.state.baseForm;
          if (value === this.props.task.from_location_type) {
            prevBaseForm = {
              ...this.state.baseForm,
              from_address: this.getDefaultBaseForm().from_address,
              from_contact: this.getDefaultBaseForm().from_contact,
            };
          } else {
            prevBaseForm = {
              ...this.state.baseForm,
              from_address: {
                building_name: '',
                name_address: '',
                street_address: '',
                city: 'Singapore',
                country: 'Singapore',
                state: 'Singapore',
                zip_code: '',
              },
              from_contact: {},
            };
          }
          this.setState(
            (prevState) => ({
              ...prevState,
              baseForm: {
                ...prevState.baseForm,
                ...prevBaseForm,
                from_location_type: value,
              },
              fromLocationSelected: false,
            }),
            () => {}
          );
          return;
        }
      }
    }
    this.setState((prevState) => {
      if (fieldName.indexOf('.') !== -1) {
        const fields: string[] = fieldName.split('.');
        return {
          baseForm: {
            ...prevState.baseForm,
            [fields[0]]: {
              ...prevState.baseForm[fields[0]],
              [fields[1]]: value,
            },
          },
        };
      } else {
        return {
          baseForm: {
            ...prevState.baseForm,
            [fieldName]: value,
          },
        };
      }
    });
  };

  shouldShowWarningDataWillBeLost = (): boolean => {
    // TODO:

    return true;
  };

  updateDeliveryForm = (
    fieldName: string,
    value: string | Date | boolean | number,
    deliveryFormIndex: number
  ): void => {
    let isItemsChanged = false;
    this.setState(
      (prevState) => {
        let shouldRecalculateTotalWeight = false;
        const updatedDeliveryForms = [...prevState.deliveryForms];
        if (fieldName.indexOf('.') !== -1) {
          const fields: string[] = fieldName.split('.');
          if (fields[0].indexOf('[') >= 0) {
            const arrayInfo: string[] = fields[0].split('[');
            const itemIdx = arrayInfo[1].slice(0, -1);
            const newArray = [
              ...prevState.deliveryForms[deliveryFormIndex][arrayInfo[0]],
            ];
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              [arrayInfo[0]]: newArray,
            };

            if (
              arrayInfo[0] === 'items' &&
              value !== '' &&
              (fields[1] === 'weight' || fields[1] === 'quantity')
            ) {
              shouldRecalculateTotalWeight = true;
            }

            const val =
              (arrayInfo[0] === 'items' || arrayInfo[0] === 'cargo_details') &&
              fields[1] === 'quantity' &&
              typeof value === 'string'
                ? Math.abs(parseInt(value))
                : value;
            newArray[parseInt(itemIdx)][fields[1]] = val;
          } else if (fields[0] in prevState.deliveryForms[deliveryFormIndex]) {
            let fieldValue = value;
            if (fields.length === 3) {
              fieldValue = {
                ...updatedDeliveryForms[deliveryFormIndex][fields[0]][fields[1]],
                [fields[2]]: value || null,
              };
            }
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              [fields[0]]: {
                ...updatedDeliveryForms[deliveryFormIndex][fields[0]],
                [fields[1]]: fieldValue,
              },
            };
          }
          isItemsChanged = true;
        } else {
          if (fieldName === 'to_location_type' && typeof value === 'string') {
            if (value === this.props.task.to_location_type) {
              updatedDeliveryForms[deliveryFormIndex] = {
                ...updatedDeliveryForms[deliveryFormIndex],
                to_address: this.getDefaultDeliveryForm().to_address,
                to_contact: {
                  ...updatedDeliveryForms[deliveryFormIndex].to_contact,
                  ...this.getDefaultDeliveryForm().to_contact,
                },
                to_location_type: value,
              };
            } else {
              updatedDeliveryForms[deliveryFormIndex] = {
                ...updatedDeliveryForms[deliveryFormIndex],
                to_address: {
                  building_name: '',
                  name_address: '',
                  street_address: '',
                  city: 'Singapore',
                  country: 'Singapore',
                  state: 'Singapore',
                  zip_code: '',
                },
                to_contact: {
                  ...updatedDeliveryForms[deliveryFormIndex].to_contact,
                  ...{},
                },
                to_location_type: value,
              };
            }
          } else {
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              [fieldName]: value,
            };
          }
        }
        return {
          isItemsChanged,
          deliveryForms: updatedDeliveryForms,
          totalWeight: shouldRecalculateTotalWeight
            ? this.calculateTotalItemsWeight(updatedDeliveryForms)
            : prevState.totalWeight,
        };
      },
      () => {}
    );
  };

  clearVessels = (): void => {
    const store = configureStore();
    store.dispatch(receiveVesselAllSuccess([]));
  };

  fetchVessels = async (searchKey: string): Promise<void> => {
    this.clearVessels();
    if (searchKey && searchKey.length >= 3) {
      this.setState({ isFetchingVessel: true });
      const client = new VesselClient();
      await client.getVessels(
        new URLSearchParams({
          term: searchKey.toUpperCase(),
          is_party_search: 'true',
        })
      );
      this.setState({ isFetchingVessel: false });
    }
  };

  chooseSavedAddress = async (
    type: 'pickup' | 'delivery',
    index: number,
    data: SavedGeoData
  ): Promise<void> => {
    if (type === 'pickup') {
      this.setState((prevState) => ({
        baseForm: {
          ...prevState.baseForm,
          from_address: {
            ...prevState.baseForm.from_address,
            name_address: data.name_address,
            building_name: data.building_name,
            street_address: data.street_address,
            latitude: data.lat || 0,
            longitude: data.lng || 0,
            zip_code: data.zip_code || '',
            unit_number: data.unit_number,
          },
          from_contact: {
            ...prevState.baseForm.to_contact,
            name: data.contact_name,
            phone: data.contact_phone,
            email: data.contact_email,
          },
        },
      }));
    } else {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        updatedDeliveryForms[index] = {
          ...updatedDeliveryForms[index],
          to_address: {
            ...updatedDeliveryForms[index].to_address,
            name_address: data.name_address,
            building_name: data.building_name,
            street_address: data.street_address,
            latitude: data.lat || 0,
            longitude: data.lng || 0,
            zip_code: data.zip_code || '',
            unit_number: data.unit_number,
          },
          to_contact: {
            ...updatedDeliveryForms[index].to_contact,
            name: data.contact_name,
            phone: data.contact_phone,
            email: data.contact_email,
          },
        };
        return {
          deliveryForms: updatedDeliveryForms,
        };
      });
    }
  };

  searchDeliveryVessels = (index: number): void => {
    clearTimeout(this.searchVesselTimeout);

    this.searchVesselTimeout = setTimeout(() => {
      this.fetchVessels(this.state.deliveryForms[index].to_contact.vessel_name);
    }, 700);
  };

  selectDeliveryVessels = (index: number, id: string, vessel: Vessel): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[index] = {
        ...updatedDeliveryForms[index],
        to_contact: {
          ...updatedDeliveryForms[index].to_contact,
          vessel_id: id,
          vessel_imo: vessel.imo,
          vessel_name: vessel.name,
        },
      };
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  clearDeliveryVessels = (index: number, clearName: boolean): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      const toContact = updatedDeliveryForms[index].to_contact;
      delete toContact.vessel_id;
      delete toContact.vessel_imo;
      if (clearName) {
        toContact.vessel_name = '';
      }
      updatedDeliveryForms[index] = {
        ...updatedDeliveryForms[index],
        to_contact: toContact,
      };
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  areCargoDetailsRequiredFieldsEmpty = (deliveryFormIndex: number): boolean => {
    const cargoDetails = this.state.deliveryForms[deliveryFormIndex].cargo_details;

    return cargoDetails.reduce((acc, detail) => {
      const isNameEmpty = detail.name.trim() === '';
      const isQuantityEmpty = isNaN(detail.quantity) || detail.quantity <= 0;
      return acc || isNameEmpty || isQuantityEmpty;
    }, false);
  };

  totalCargoLifts = (deliveryFormIndex: number): number => {
    const cargoDetails = this.state.deliveryForms[deliveryFormIndex].cargo_details;

    return cargoDetails?.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);
  };

  allowConfirmOrder = (): boolean => {
    const { baseForm, deliveryForms } = this.state;
    const { from_time_window: fromTimeWindow } = baseForm;

    let allowedMinPrice = ALLOWED_MIN_PRICE;
    if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      allowedMinPrice = 0;
    }

    let isPriceIncrementFilled = (baseForm.increment || 0) !== 0;
    if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      isPriceIncrementFilled = true;
    }

    const isBaseFormFulfilled =
      baseForm.min_price >= allowedMinPrice &&
      baseForm.max_price >= baseForm.min_price &&
      baseForm.from_address.street_address.trim().length > 0 &&
      baseForm.vehicle_preference !== '' &&
      DateHelper.isRangeValid(fromTimeWindow) &&
      baseForm.price > allowedMinPrice;

    let areDeliveryFormsFulfilled = true;
    for (let i = 0; i < deliveryForms.length; i++) {
      const { to_time_window: toTimeWindow } = deliveryForms[i];
      const checkCargoDetailsRequiredFields =
        baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine
          ? true
          : !this.areCargoDetailsRequiredFieldsEmpty(i);
      const isFormFulfilled =
        deliveryForms[i].to_address.street_address.trim().length > 0 &&
        DateHelper.isRangeValid(toTimeWindow) &&
        DateHelper.compareDateTime(
          baseForm.from_time_window.start_time_utc,
          deliveryForms[i].to_time_window.start_time_utc
        ) <= 0 &&
        DateHelper.compareDateTime(
          baseForm.from_time_window.end_time_utc,
          deliveryForms[i].to_time_window.end_time_utc
        ) <= 0 &&
        checkCargoDetailsRequiredFields;

      if (!isFormFulfilled) {
        areDeliveryFormsFulfilled = false;
        break;
      }
    }

    const isOrganizationSelected = this.state.baseForm.org_id.length !== 0;

    return (
      baseForm &&
      deliveryForms &&
      isBaseFormFulfilled &&
      areDeliveryFormsFulfilled &&
      !this.state.isSaving &&
      isOrganizationSelected &&
      isPriceIncrementFilled
    );
  };

  allowUpdate = (): boolean => {
    return this.state.orderDeclaration.correctAndComplete && this.allowConfirmOrder();
  };

  isFormChanged(): boolean {
    const { baseForm, deliveryForms } = this.state;
    const deliveryForm = deliveryForms[0];

    // get the base form value to compare to the current state
    const defaultPickup = this.getDefaultBaseForm();
    const defaultDelivery = this.getDefaultDeliveryForm();

    // compare the changes
    const isVehicleChanged =
      JSON.stringify(defaultPickup.vehicle_preference) !==
      JSON.stringify(baseForm.vehicle_preference);
    const isPickupAddressChanged =
      JSON.stringify(defaultPickup.from_address) !==
      JSON.stringify(baseForm.from_address);
    const isPickupContactChanged =
      JSON.stringify(defaultPickup.from_contact) !==
      JSON.stringify(baseForm.from_contact);
    const isFromTimeWindowChanged =
      JSON.stringify(defaultPickup.from_time_window) !==
      JSON.stringify(baseForm.from_time_window);
    const isPickupInstructionsChanged =
      JSON.stringify(defaultPickup.pickup_note_to_driver) !==
      JSON.stringify(baseForm.pickup_note_to_driver);
    const isDeliveryAddressChanged =
      JSON.stringify(defaultDelivery.to_address) !==
      JSON.stringify(deliveryForm.to_address);
    const isDeliveryContactChanged =
      JSON.stringify(defaultDelivery.to_contact) !==
      JSON.stringify(deliveryForm.to_contact);
    const isToTimeWindowChanged =
      JSON.stringify(defaultDelivery.to_time_window) !==
      JSON.stringify(deliveryForm.to_time_window);
    const isDeliveryInstructionsChanged =
      JSON.stringify(defaultDelivery.delivery_note_to_driver) !==
      JSON.stringify(deliveryForm.delivery_note_to_driver);
    const referenceIDChanged =
      JSON.stringify(defaultDelivery.tracking_id) !==
      JSON.stringify(deliveryForm.tracking_id);
    const attachmentChanged =
      JSON.stringify(defaultDelivery.attachment_files) !==
      JSON.stringify(deliveryForm.attachment_files);
    const cargoNetChanged =
      JSON.stringify(baseForm.cargo_net_quantity) !==
      JSON.stringify(defaultPickup.cargo_net_quantity);
    const officerChanged =
      JSON.stringify(baseForm.officer_contact) !==
      JSON.stringify(defaultPickup.officer_contact);

    const result =
      isVehicleChanged ||
      isPickupAddressChanged ||
      isPickupContactChanged ||
      isFromTimeWindowChanged ||
      isPickupInstructionsChanged ||
      isDeliveryAddressChanged ||
      isDeliveryContactChanged ||
      isToTimeWindowChanged ||
      isDeliveryInstructionsChanged ||
      referenceIDChanged ||
      attachmentChanged ||
      cargoNetChanged ||
      officerChanged ||
      this.state.isItemsChanged;

    return result;
  }

  isEditableField = (fieldName: string): boolean => {
    const { task } = this.props;
    let isEditableField = false;
    const taskEditableStatuses =
      task && task.broadcast_preference === BROADCAST_PREFERENCES.marine
        ? TaskEditableStatusesMarineMerchant
        : ['pending_broadcast'];
    if (task && taskEditableStatuses.includes(task.current_status)) {
      isEditableField = true;
    }
    // if (task && TaskNotEditableFields.includes(fieldName)) {
    //   isEditableField = false;
    // }
    if (
      task &&
      getNotEditableFieldsByStatus(
        task.current_status as StatusType,
        task.broadcast_preference
      ).includes(fieldName)
    ) {
      isEditableField = false;
    }
    if (task && task.runsheet === null && task.current_status === 'pending_broadcast') {
      isEditableField = true;
    }

    if (
      task.broadcast_preference === BROADCAST_PREFERENCES.marine &&
      fieldName === 'price' &&
      (task.current_status as StatusType) === 'complete'
    ) {
      isEditableField = true;
    }
    // validate Accepted status, should be At least 3 hours before pickup start time
    if (isEditableField && task.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      if (TaskEditableStatusesMarineMerchant.includes(task.current_status)) {
        if (task.current_status === 'accepted') {
          // check for current time, must be at least 3 hours before pickup time
          const timeToCheck =
            task.from_time_window && task.from_time_window.start_time_utc;
          isEditableField = !DateHelper.isMoreThan3HoursFromStart(timeToCheck);
        }
      }
    }
    return isEditableField;
  };

  removeCargoDetails = (deliveryFormIndex: number, cargoDetailsIndex: number): void => {
    if (typeof deliveryFormIndex === 'number') {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        const updatedItems = [
          ...prevState.deliveryForms[deliveryFormIndex].cargo_details,
        ];
        if (
          cargoDetailsIndex === 0 &&
          this.state.deliveryForms[deliveryFormIndex].cargo_details.length === 1
        ) {
          updatedItems[cargoDetailsIndex] = {
            id: '',
            name: '',
            quantity: 1,
            quantity_unit: '',
            remarks: '',
            has_hazard_mat: false,
          };
        } else {
          updatedItems.splice(cargoDetailsIndex, 1);
        }
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          cargo_details: updatedItems,
        };
        return {
          deliveryForms: updatedDeliveryForms,
        };
      });
    }
  };

  renderDeliveryVesselBottomLabel = (index: number): React.ReactNode => {
    const toContact =
      this.state.deliveryForms[index] && this.state.deliveryForms[index].to_contact;

    return (
      <>
        {toContact.vessel_imo ? (
          <>
            <ImoText>{`IMO ${toContact.vessel_imo}`}</ImoText>
            <ImoInfoAlert status="info">
              Important: Please ensure that this information is accurate.
            </ImoInfoAlert>
          </>
        ) : (
          false
        )}
        <EnterManualButton
          onClick={(): void => {
            this.setState({ showDeliveryAddVesselModal: index });
          }}
        >
          Enter manually
        </EnterManualButton>
      </>
    );
  };

  renderDeliveryVesselEmptyPlaceholder = (index: number): React.ReactNode => {
    const { deliveryForms, showDeliveryAddVesselModal } = this.state;
    return (
      <>
        {deliveryForms[index].to_contact &&
        deliveryForms[index].to_contact.vessel_name &&
        deliveryForms[index].to_contact.vessel_name.length >= 3 &&
        !showDeliveryAddVesselModal ? (
          <EmptyVesselPlaceholder>
            {this.state.isFetchingVessel ? (
              <div>Loading...</div>
            ) : (
              <>
                <div>Vessel name not found.</div>
                <EnterManualEmptyPlaceholderButton
                  onClick={(e: React.MouseEvent): void => {
                    e.stopPropagation();
                    this.setState({ showDeliveryAddVesselModal: index });
                  }}
                >
                  Enter manually
                </EnterManualEmptyPlaceholderButton>
              </>
            )}
          </EmptyVesselPlaceholder>
        ) : (
          false
        )}
      </>
    );
  };

  renderGeneralInfoForm = (): React.ReactNode => {
    const { baseForm, deliveryForms } = this.state;
    const i = 0;
    const startETB =
      (deliveryForms[i].to_contact?.etb_time_window?.start_time_utc &&
        new Date(deliveryForms[i].to_contact?.etb_time_window?.start_time_utc)) ||
      null;
    const startETU =
      (deliveryForms[i].to_contact?.etu_time_window?.start_time_utc &&
        new Date(deliveryForms[i].to_contact?.etu_time_window?.start_time_utc)) ||
      null;
    return (
      <GeneralInfoForm
        disableFullEdit
        form={{
          delivery_date: baseForm.delivery_date,
          broadcast_preference: baseForm.broadcast_preference,
        }}
        onFormChange={(fieldName: string, value: string | Date): void => {
          if (
            fieldName === 'broadcast_preference' &&
            typeof value === 'string' &&
            baseForm.broadcast_preference !== value &&
            this.shouldShowWarningDataWillBeLost()
          ) {
            this.setState({ showSwitchServiceWarningModal: value });
          } else {
            // prevent updateBaseForm if broadcast_preference value is not changed
            if (
              fieldName === 'broadcast_preference' &&
              typeof value === 'string' &&
              baseForm.broadcast_preference === value
            ) {
              return;
            }
            this.updateBaseForm(fieldName, value);
          }
        }}
        hasNoSquad={true}
        vesselForm={
          <>
            {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
              <>
                <OrderTitle>Mother Vessel</OrderTitle>
                <VesselCardRow>
                  <Searchbox
                    key={`to_contact_vessel_name${i}`}
                    inputName="to_contact_vessel_name"
                    trailingIcon={
                      this.state.deliveryForms[i].to_contact.vessel_name
                        ? faTimes
                        : faSearch
                    }
                    containerStyle={inlineTextInputStyle}
                    fieldName="Vessel Name"
                    disabled={!this.isEditableField('to_contact.vessel_name')}
                    bottomLabel={
                      this.isEditableField('to_contact.vessel_name') &&
                      this.renderDeliveryVesselBottomLabel(i)
                    }
                    trailingIconOnClick={(): void => {
                      if (this.isEditableField('to_contact.vessel_name')) {
                        this.clearDeliveryVessels(i, true);
                        this.searchDeliveryVessels(i);
                      }
                    }}
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm('to_contact.vessel_name', value, i);
                      this.searchDeliveryVessels(i);
                      this.clearDeliveryVessels(i, false);
                    }}
                    handleSetFocus={(focused: boolean): void => {
                      if (focused) {
                        this.clearVessels();
                        this.searchDeliveryVessels(i);
                      } else {
                        const toContact = this.state.deliveryForms[i].to_contact;
                        if (toContact.vessel_name && !toContact.vessel_imo) {
                          this.updateDeliveryForm('to_contact.vessel_name', '', i);
                        }
                      }
                    }}
                    handleSelectChange={(value: string, vessel: Vessel): void => {
                      this.selectDeliveryVessels(i, value, vessel);
                    }}
                    placeholder="Search vessel name or IMO"
                    width="large"
                    value={this.state.deliveryForms[i].to_contact.vessel_name}
                    options={(this.props.vessels || []).map((vessel) => ({
                      value: vessel.id,
                      text: vessel.name,
                      additionalText: `IMO ${vessel.imo}`,
                      additionalValue: {
                        id: vessel.id,
                        imo: vessel.imo,
                        name: vessel.name,
                      },
                    }))}
                    emptyPlaceholder={this.renderDeliveryVesselEmptyPlaceholder(i)}
                  />
                  <DateAndTimeContainer alignItems="flex-start">
                    {/* Formerly Estimated Time of Berthing (ETB) */}
                    <DatePickerWrapper
                      marginRight="10px"
                      width="13.5rem"
                      minDate={moment().toDate()}
                      dateFormat="dd MMM, hh:mm a"
                      onChange={(value: Date): void => {
                        this.updateDeliveryForm(
                          'to_contact.etb_time_window.start_time_utc',
                          value,
                          i
                        );
                        this.updateDeliveryForm(
                          'to_contact.etb_time_window.end_time_utc',
                          moment(value).add(1, 'hour').toDate(),
                          i
                        );
                      }}
                      onSelect={(value: Date): void => {
                        this.updateDeliveryForm(
                          'to_contact.etb_time_window.start_time_utc',
                          value,
                          i
                        );
                        this.updateDeliveryForm(
                          'to_contact.etb_time_window.end_time_utc',
                          moment(value).add(1, 'hour').toDate(),
                          i
                        );
                      }}
                      selected={startETB}
                      showTimeSelect
                      placeholderText="10 Jan, 10:00 AM"
                      customInput={
                        <TextInput
                          autoComplete="none"
                          inputName="name"
                          containerStyle={inlineTextInputStyle}
                          fieldName="Est. Time of Arrival (ETA)"
                          width="full"
                          noWrapLabel
                        />
                      }
                      calendarContainer={Calendar}
                      disabled={!this.isEditableField('to_contact.vessel_name')}
                    />
                    {/* Formerly Estimated Time of Unberthing (ETU) */}
                    <DatePickerWrapper
                      width="13.5rem"
                      minDate={moment().toDate()}
                      dateFormat="dd MMM, hh:mm a"
                      onChange={(value: Date): void => {
                        this.updateDeliveryForm(
                          'to_contact.etu_time_window.start_time_utc',
                          value,
                          i
                        );
                        this.updateDeliveryForm(
                          'to_contact.etu_time_window.end_time_utc',
                          moment(value).add(1, 'hour').toDate(),
                          i
                        );
                      }}
                      onSelect={(value: Date): void => {
                        this.updateDeliveryForm(
                          'to_contact.etu_time_window.start_time_utc',
                          value,
                          i
                        );
                        this.updateDeliveryForm(
                          'to_contact.etu_time_window.end_time_utc',
                          moment(value).add(1, 'hour').toDate(),
                          i
                        );
                      }}
                      selected={startETU}
                      showTimeSelect
                      placeholderText="10 Jan, 11:30 AM"
                      customInput={
                        <TextInput
                          autoComplete="none"
                          inputName="name"
                          containerStyle={inlineTextInputStyle}
                          fieldName="Est. Time of Departure (ETD)"
                          width="full"
                          noWrapLabel
                        />
                      }
                      calendarContainer={Calendar}
                      disabled={!this.isEditableField('to_contact.vessel_name')}
                    />
                  </DateAndTimeContainer>
                </VesselCardRow>
              </>
            )}
          </>
        }
      />
    );
  };

  renderOrderForm = (): React.ReactNode => {
    return (
      <OrderCard>
        <PickupForm
          isEdit
          baseForm={this.state.baseForm}
          chooseSavedAddress={this.chooseSavedAddress}
          savedGeoDatas={this.props.savedGeoDatas}
          updateBaseForm={this.updateBaseForm}
          onSelectAddress={(data: AddressContactData): void => {
            this.setState((prevState) => ({
              ...prevState,
              baseForm: {
                ...prevState.baseForm,
                from_address: data.address,
                from_contact: data.contact,
              },
              fromLocationSelected: true,
            }));
          }}
          isEditableField={this.isEditableField}
        />
        {this.state.deliveryForms.map((_, i) => (
          <DeliveryForm
            isEdit
            key={i}
            i={i}
            baseForm={this.state.baseForm}
            deliveryForm={this.state.deliveryForms[i]}
            updateBaseForm={this.updateBaseForm}
            updateDeliveryForm={this.updateDeliveryForm}
            deliveryFormsLength={this.state.deliveryForms.length}
            organization={this.props.organization}
            geoDatas={this.props.geoDatas}
            savedGeoDatas={this.props.savedGeoDatas}
            addItemClick={(): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[i] = {
                  ...updatedDeliveryForms[i],
                  items: updatedDeliveryForms[i].items.concat({
                    name: '',
                    quantity: undefined,
                    has_hazard_mat: false,
                    dimension: {
                      length: null,
                      width: null,
                      height: null,
                    },
                  }),
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
            removeItemClick={(j: number): void => {
              this.setState({
                showRemoveModal: {
                  deliveryFormIndex: i,
                  itemIndex: j,
                  actionType: 'items',
                },
              });
            }}
            chooseSavedAddress={(
              type: 'pickup' | 'delivery',
              i: number,
              v: SavedGeoData
            ) => this.chooseSavedAddress(type, i, v)}
            marineVehicleFormChange={(
              fieldName: string,
              value: string | boolean
            ): void => {
              // handle pricing based on vehicle
              if (typeof value === 'string') {
                const serviceType = value === 'boat' ? 'boat' : 'truck';
                const servicePricing = getServicePricing(serviceType, value);
                const priceValue =
                  Math.floor(parseFloat(servicePricing.price.toString()) * 100) * 1000;
                this.setState((prevState) => {
                  const updatedDeliveryForms = [...prevState.deliveryForms];
                  updatedDeliveryForms[i] = {
                    ...updatedDeliveryForms[i],
                    [fieldName]: value,
                    min_price: priceValue,
                    max_price: priceValue,
                    service_type: serviceType,
                  };
                  return {
                    deliveryForms: updatedDeliveryForms,
                    baseForm: { ...prevState.baseForm, vehicle_preference: value },
                  };
                });
              }
            }}
            onSelectAddress={(data: AddressContactData): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[i] = {
                  ...updatedDeliveryForms[i],
                  to_address: data.address,
                  to_contact: data.contact,
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                  // toLocationSelected: [...this.state.toLocationSelected, i],
                };
              });
            }}
            onRemoveDeliveryDetails={(): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms.splice(i, 1);
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
            isEditableField={this.isEditableField}
          />
        ))}
      </OrderCard>
    );
  };

  renderFilesUpload = (deliveryFormIndex: number = 0): React.ReactNode => {
    const { deliveryForms } = this.state;
    return (
      <FilesUpload
        deliveryForm={deliveryForms[deliveryFormIndex]}
        deliveryFormIndex={deliveryFormIndex}
        onFileChange={async (e): Promise<void> => {
          await this.onFileChange(e, deliveryFormIndex);
        }}
        onFileRemove={this.onFileRemove}
        isEditableField={this.isEditableField}
      />
    );
  };

  renderCargoDetails = (deliveryFormIndex: number = 0): React.ReactNode => {
    const { deliveryForms } = this.state;
    return (
      <CargoDetails
        deliveryForm={deliveryForms[deliveryFormIndex]}
        updateDeliveryForm={(
          fieldName: string,
          value: string | Date | boolean | number
        ) => {
          this.updateDeliveryForm(fieldName, value, deliveryFormIndex);
        }}
        onRemoveCargoDetails={(cargoDetailsIndex: number) => {
          this.removeCargoDetails(deliveryFormIndex, cargoDetailsIndex);
        }}
        onAddItemClick={(): void => {
          this.setState((prevState) => {
            const updatedDeliveryForms = [...prevState.deliveryForms];
            updatedDeliveryForms[deliveryFormIndex] = {
              ...updatedDeliveryForms[deliveryFormIndex],
              cargo_details: updatedDeliveryForms[deliveryFormIndex].cargo_details.concat(
                {
                  id: '',
                  name: '',
                  quantity: 1,
                  quantity_unit: '',
                  remarks: '',
                  has_hazard_mat: false,
                  dimension: {
                    length: null,
                    width: null,
                    height: null,
                  },
                }
              ),
            };
            return {
              deliveryForms: updatedDeliveryForms,
            };
          });
        }}
        isEditableField={this.isEditableField}
      />
    );
  };

  renderServiceAndVehicleSelect = (): React.ReactNode => {
    return (
      <AdditionalServiceCard>
        {this.renderVehicleForm(
          this.state.baseForm.broadcast_preference as keyof typeof BROADCAST_PREFERENCES
        )}
      </AdditionalServiceCard>
    );
  };

  renderActionButtons(): React.ReactNode {
    const { id } = this.props.match.params;
    const { task } = this.props;
    let canEdit = task.current_status === 'pending_broadcast' ? true : false;
    if (task && task.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      canEdit = true;
    }
    const taskEditableStatuses =
      task && task.broadcast_preference === BROADCAST_PREFERENCES.marine
        ? TaskEditableStatusesMarineMerchant
        : ['pending_broadcast'];
    if (task && taskEditableStatuses.includes(task.current_status)) {
      canEdit = true;
    }

    return (
      <ActionContainer>
        <ActionButtonContainer>
          <Button
            type="button"
            buttonStyle="encourage"
            buttonType="neutral"
            onClick={(): void => {
              this.props.history.push(`/orders/${id}`);
            }}
          >
            <OrderIcon color={COLOR.darkGray} icon={faChevronLeft} />
            Back
          </Button>
          <Button
            type="button"
            buttonType="primary"
            buttonStyle="encourage"
            disabled={!this.allowConfirmOrder() || !canEdit || !this.isFormChanged()}
            onClick={(): void => {
              this.allowConfirmOrder()
                ? this.setState({ showConfirmOrderModal: true })
                : this.setState({
                    error: 'There was an error processing your order.',
                  });
            }}
          >
            <OrderIcon color={COLOR.white} icon={faCheck} />
            Update
          </Button>
        </ActionButtonContainer>
      </ActionContainer>
    );
  }

  renderConfirmOrderModal = (): React.ReactNode => {
    return (
      <>
        {this.state.showConfirmOrderModal && (
          <CenterModal title="Confirm Update Order?">
            <Checkbox
              onClick={(): void => {
                this.setState((prevState) => ({
                  orderDeclaration: {
                    ...prevState.orderDeclaration,
                    correctAndComplete: !prevState.orderDeclaration.correctAndComplete,
                  },
                }));
              }}
              selected={this.state.orderDeclaration.correctAndComplete}
            >
              <ConfirmOrderModalText>
                I confirm that the description and specifics of my delivery details are
                correct and complete.
              </ConfirmOrderModalText>
            </Checkbox>
            <ConfirmOrderModalButtons>
              <StyledButton
                buttonType="neutral"
                buttonStyle="encourage"
                onClick={(): void => {
                  this.setState({ showConfirmOrderModal: false });
                }}
              >
                Back
              </StyledButton>
              <StyledButton
                type="submit"
                buttonType="primary"
                buttonStyle="encourage"
                disabled={!this.allowUpdate()}
              >
                Confirm
              </StyledButton>
            </ConfirmOrderModalButtons>
          </CenterModal>
        )}
      </>
    );
  };

  renderOrderPlacedModal = (): React.ReactNode => {
    const { id } = this.props.match.params;
    return (
      <>
        {this.state.isSuccessful && (
          <CenterModal
            title="Order Updated"
            rightButtonText="OK"
            rightButtonOnClick={(): void => {
              this.props.history.push('/orders/' + id);
            }}
          >
            <Alert status="success">You have successfully updated your order.</Alert>
          </CenterModal>
        )}
      </>
    );
  };

  renderErrorModal = (): React.ReactNode => {
    return (
      <>
        {this.state.error && (
          <CenterModal
            title="Unable to place orders"
            rightButtonText="OK"
            rightButtonOnClick={(): void => {
              this.setState({ error: null, isSaving: false });
            }}
          >
            <Alert status="error">
              {this.state.error !== 'Network Error'
                ? this.state.error
                : 'There seems to be an error with the network.'}
            </Alert>
            <ErrorModalText>
              {this.state.error !== 'Network Error'
                ? 'Please check that your order information is correct, then try again.'
                : 'Please wait a few seconds then try again. If the problem continues, let us know.'}
            </ErrorModalText>
          </CenterModal>
        )}
      </>
    );
  };

  isMotorcycleExceedingDimensions = (): string[] => {
    const { deliveryForms } = this.state;
    const items = deliveryForms.flatMap((deliveryForm) => deliveryForm.items);
    const result = isItemExceedingVehicleDimensions(items, ['motorcycle']);
    const vehiclesExceed = Object.keys(result).filter(
      (key) => result[key as keyof typeof result]
    );
    return vehiclesExceed;
  };

  getTotalItemsWeight = (): number => {
    return parseFloat(this.state.totalWeight.toFixed(2));
  };

  calculateTotalItemsWeight = (deliveryForms: MerchantDeliveryForm[]): number => {
    const items = deliveryForms.flatMap((deliveryForm) => deliveryForm.items);
    return items.reduce((total, item) => {
      const weight = item.weight ? parseFloat(item.weight.toString()) : 0;
      const quantity = item.quantity || 0;
      return total + weight * quantity;
    }, 0);
  };

  renderVehicleForm = (
    broadcastPreference: keyof typeof BROADCAST_PREFERENCES
  ): React.ReactNode => {
    const { baseForm } = this.state;
    return (
      <VehicleForm
        displayHeader={
          broadcastPreference === 'marine' ? (
            <>
              <Typography
                weight="normal"
                color="gray_700"
                as="h4"
                size="sm"
                customStyle={{ marginBottom: '0.2rem', letterSpacing: '0.32px' }}
              >
                Vehicle Requirements <RedText>*</RedText>
              </Typography>
              <Typography weight="normal" color="gray_400" as="p" size="xs">
                Select the most appropriate vehicle to fit your cargo.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                weight="semibold"
                color="gray_700"
                as="h2"
                customStyle={{ marginBottom: '0.2rem', letterSpacing: '0.32px' }}
              >
                Vehicle Requirements <RedText>*</RedText>
              </Typography>
              <Typography weight="normal" color="gray_400" as="p">
                Select the most appropriate vehicle to fit all your items.
              </Typography>
            </>
          )
        }
        form={{
          broadcast_preference:
            baseForm.broadcast_preference as keyof typeof BROADCAST_PREFERENCES,
          vehicle_preference: baseForm.vehicle_preference,
        }}
        onFormChange={(fieldName: string, value: string | boolean): void => {
          this.updateBaseForm(fieldName, value);
        }}
        disabled={!this.isEditableField('select_vehicle')}
        vehiclesExceedingDimensions={this.isMotorcycleExceedingDimensions()}
        enableTotalWeightValidation={
          this.state.baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine
        }
        totalWeight={this.getTotalItemsWeight()}
      />
    );
  };

  renderAdditionalService = (): React.ReactNode => {
    const { baseForm, hasCargoNet, hasOfficerContact } = this.state;
    return (
      <AdditionalServiceCard>
        <OrderTitle>Any Additional Service / Requirements?</OrderTitle>
        <Checkbox
          disabled={!this.isEditableField('has_cargo_net')}
          onClick={(): void => {
            this.setState((prevState) => ({
              hasCargoNet: !prevState.hasCargoNet,
            }));
          }}
          selected={hasCargoNet}
        >
          Do you need cargo net?
        </Checkbox>
        {hasCargoNet && (
          <AdditionalServiceContent>
            <TextInput
              disabled={!this.isEditableField('has_cargo_net')}
              containerStyle={inlineTextInputStyle}
              fieldName="No. of Cargo Nets"
              placeholder="4"
              onTextChange={(value: string): void => {
                this.updateBaseForm('cargo_net_quantity', value);
              }}
              type="number"
              width="medium"
              min={1}
              value={baseForm.cargo_net_quantity}
            />
          </AdditionalServiceContent>
        )}
        <Checkbox
          disabled={!this.isEditableField('has_officer_contact')}
          onClick={(): void => {
            this.setState((prevState) => ({
              hasOfficerContact: !prevState.hasOfficerContact,
            }));
          }}
          selected={hasOfficerContact}
        >
          Is there an additional Boarding Officer coming along?
        </Checkbox>
        {hasOfficerContact && (
          <AdditionalServiceContent>
            <TextInput
              disabled={!this.isEditableField('has_officer_contact.name')}
              containerStyle={inlineTextInputStyle}
              fieldName="Name"
              placeholder="Tony"
              onTextChange={(value: string): void => {
                this.updateBaseForm('officer_contact.name', value);
              }}
              type="text"
              width="medium"
              value={baseForm.officer_contact.name}
            />
            <TextInput
              disabled={!this.isEditableField('has_officer_contact.phone')}
              containerStyle={inlineTextInputStyle}
              fieldName="Contact No."
              placeholder="8100 8989"
              onTextChange={(value: string): void => {
                this.updateBaseForm('officer_contact.phone', value);
              }}
              type="text"
              width="medium"
              value={baseForm.officer_contact.phone}
            />
          </AdditionalServiceContent>
        )}
      </AdditionalServiceCard>
    );
  };

  getPopupRemovalPreference = () => {
    return {
      items: {
        title: 'Remove item details?',
        content:
          'Are you sure you want to remove item details? This action cannot be undone.',
        action: this.removeItem,
      },
      cargo_details: {
        title: 'Remove cargo details?',
        content:
          'Are you sure you want to remove cargo details? This action cannot be undone.',
        action: () => {},
      },
    };
  };

  removeItem = () => {
    const { deliveryFormIndex, itemIndex, actionType } = this.state.showRemoveModal;
    if (typeof deliveryFormIndex === 'number' && actionType === 'items') {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        const updatedItems = [...prevState.deliveryForms[deliveryFormIndex].items];
        if (
          itemIndex === 0 &&
          this.state.deliveryForms[deliveryFormIndex].items.length === 1
        ) {
          updatedItems[itemIndex] = {
            name: '',
            quantity: undefined,
            weight: null,
            weight_unit: '',
            has_hazard_mat: false,
            dimension: {
              length: null,
              width: null,
              height: null,
            },
          };
        } else {
          updatedItems.splice(itemIndex, 1);
        }
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          items: updatedItems,
        };
        return {
          deliveryForms: updatedDeliveryForms,
          showRemoveModal: {
            deliveryFormIndex: false,
            itemIndex: 0,
            actionType: '',
          },
          showRemoveSuccessModal: 'Item details removed',
        };
      });
    }
  };

  renderRemoveCargoDetailsOrItemsModal = (): React.ReactNode => {
    const { actionType } = this.state.showRemoveModal;
    if (actionType === '') {
      return null;
    }
    const popupPreference = this.getPopupRemovalPreference();
    return (
      <CenterModal
        title={<RemoveCargoDetailsIcon icon={faTrash} color={COLOR.red} />}
        leftButtonText="Cancel"
        leftButtonOnClick={(): void => {
          this.setState({
            showRemoveModal: {
              deliveryFormIndex: false,
              itemIndex: 0,
              actionType: '',
            },
          });
        }}
        rightButtonOnClick={(): void => popupPreference[actionType].action()}
        rightButtonText="Remove"
        rightButtonStyle="discourage"
        rightButtonType="primary"
        width="small"
        position="bottom"
      >
        <RemoveCargoDetailsSubTitle>
          {popupPreference[actionType].title}
        </RemoveCargoDetailsSubTitle>
        <Typography as="div" color="gray_600" size="sm">
          {popupPreference[actionType].content}
        </Typography>
      </CenterModal>
    );
  };

  renderRemoveSuccessModal = (): React.ReactNode => (
    <>
      {this.state.showRemoveSuccessModal ? (
        <CenterModal
          title={
            <RemoveCargoDetailsIcon
              icon={faCheckCircle}
              color={COLOR.cyan}
              fontSize="1.3"
            />
          }
          fullBottomButtonText="OK"
          fullBottomButtonOnClick={(): void => {
            this.setState({
              showRemoveSuccessModal: '',
            });
          }}
          width="small"
          position="bottom"
        >
          <RemoveCargoDetailsSubTitle>
            {this.state.showRemoveSuccessModal}
          </RemoveCargoDetailsSubTitle>
        </CenterModal>
      ) : (
        false
      )}
    </>
  );

  render(): React.ReactNode {
    const { id } = this.props.match.params;
    const { baseForm } = this.state;
    if (baseForm) {
      const { task } = this.props;
      const orderListQueryParams =
        (this.props.location.state && this.props.location.state.from) || '';
      const isActiveOrders =
        (task && ActiveOrderStatusStrings.includes(task.current_status)) || false;
      const selectedTab = isActiveOrders ? 'active-orders' : 'past-orders';
      const breadCrumbText = isActiveOrders ? 'Active Orders' : 'Past Orders';
      return (
        <MainContainer selected={selectedTab}>
          <Breadcrumb
            customStyle={{
              marginBottom: 0,
            }}
            items={[
              {
                text: breadCrumbText,
                onClick: (): void => {
                  this.props.history.push(`/${selectedTab}?${orderListQueryParams}`);
                },
              },
              {
                text: this.props.task && this.props.task.id.split('-')[0],
                onClick: (): void => {
                  this.props.history.push(`/orders/${id}`);
                },
              },
              { text: 'Edit Order' },
            ]}
          />
          <form
            onSubmit={this.handleSubmit}
            onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>): void => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            {this.renderGeneralInfoForm()}
            {this.renderOrderForm()}
            {(baseForm.broadcast_preference === BROADCAST_PREFERENCES.all ||
              baseForm.broadcast_preference === BROADCAST_PREFERENCES.squad) && (
              <>
                {this.renderServiceAndVehicleSelect()}
                <BottomMargin />
              </>
            )}
            {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
              <>
                <OrderCard>
                  {this.renderFilesUpload()}
                  {this.renderCargoDetails()}
                </OrderCard>
                {this.renderAdditionalService()}
              </>
            )}
            {this.renderActionButtons()}
            {this.renderConfirmOrderModal()}
            {this.renderOrderPlacedModal()}
            {this.renderErrorModal()}
            <BottomMargin />
          </form>
          {typeof this.state.showRemoveModal.deliveryFormIndex === 'number' &&
            this.renderRemoveCargoDetailsOrItemsModal()}
          {this.renderRemoveSuccessModal()}
        </MainContainer>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  task: state.task.task,
  organization: state.organization.organization,
  vessels: state.vessel.vessels,
  geoDatas: state.geoService.geoDatas,
  savedGeoDatas: state.geoService.savedGeoDatas,
});

export default connect(mapStateToProps)(OrderEdit);
