import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import COLOR, { transparent } from 'constants/color';

interface Nav {
  open: boolean;
}

interface LinkButtonProps {
  selected?: boolean;
}

export const NavHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 769px) {
    justify-content: flex-start;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  width: 3.5rem;
  margin-left: 1rem;
  min-height: 3rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 769px) {
    width: 3rem;
    margin-left: 1rem;
  }
`;

export const SettingContainer = styled.div`
  margin-top: 1rem;
  background-color: ${COLOR.white};
  border-color: ${COLOR.blue};
  border-radius: 0.5em;
  border-width: 1px;
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125em;
  height: auto;
  padding: 0.5rem 0.75rem;
  border-style: solid;
  text-decoration: none;
  text-align: center;
`;

export const CloseNavContainer = styled.div`
  position: absolute;
  right: 0.75rem;
  z-index: 10;
`;

export const SmallLogo = styled.img`
  width: 2rem;
  height: 2rem;

  @media (max-width: 768px) {
    margin-left: -1rem;
  }
`;

export const MobileSettingContainer = styled.div`
  align-items: center;
  width: -webkit-fill-available;
  margin-bottom: 15px;
`;

export const MobileSettingText = styled.span`
  margin-left: 15px;
`;

export const MobileSideBarFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  height: 100px;
  width: -webkit-fill-available;
  margin-top: auto;
  bottom: 0;
  padding: 0 20px 1.5em 20px;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const LogoutPanel = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #CCC;
`;

export const LogoutUserPhoto = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  align-items: flex-start;
  display: flex;
  resize-mode: contain;
`;

export const LogoutUserText = styled.div`
  display: flex;
  flex: 1;
  padding: 0 20px;
  flex-direction: column;
`;

export const LogoutUserName = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  color: ${COLOR.black};
`;

export const LogoutUserEmail = styled.div`
  font-size: 0.875rem;
  color: ${COLOR.black};
`;

export const LogoutButton = styled.div`
  margin-right: 0.656rem;
`;

export const LogoutIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.black};
`;

export const DesktopRightPanelSection = styled.div`
  align-items: center;
  display: flex;
  margin-right: 1.5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ProfileIcon = styled(FontAwesomeIcon) <{ hoverable?: boolean }>`
  background-color: ${COLOR.cyan};
  color: ${COLOR.white};
  height: 1rem;
  width: 1rem;
  padding: 0.5rem;
  border-radius: 20px;

  ${(props): string => props.hoverable ? `
    :hover{
      cursor: pointer;
    }
  ` : ''}
`;

export const DropdownMainContainer = styled.div`
  height: 0;
  position: relative;
  width: 0;
`;

export const DropdownContainer = styled.div`
  background-color: ${COLOR.white};
  border: 1px solid ${transparent('shadow', 0.26)};
  border-radius: 4px;
  min-width: 18rem;
  padding: 1.875rem;
  position: absolute;
  top: 0.125rem;
  right: -1.875rem;
  z-index: 15;
`;

export const ProfileContainer = styled.div`
  display: flex;
`;

export const DetailContainer = styled.div`
  margin-left: 0.75rem;
`;

export const NameText = styled.div`
  color: ${COLOR.darkGray};
  font-weight: 600;
`;

export const EmailText = styled.div`
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
`;

export const SettingIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`;

export const ActionButtonList = styled.div`
  margin-top: 1.5rem
`;

export const ActionButton = styled.div`
  border-top: 1px solid ${COLOR.whiteGrey};
  padding: 0.75rem 0;

  :hover{
    cursor: pointer;
  }
`;

export const ActionIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`;

export const MobileLogoContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    min-height: 3rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    padding-left: 0.75rem;
  }
`;

export const Logo = styled.img<Nav>`
  width: 12rem;
  height: 2.3rem;

  @media (max-width: 768px) {
    display: ${(props): string => props.open ? 'block' : 'none'};
    margin-left: 12px;
  }
`;

export const NavHeader = styled.div`
  display: flex;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const NavMenu = styled.div<Nav>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;

  a {
    text-decoration: none;
    text-transform: none;
    color: ${COLOR.black};
    cursor: pointer;

    &:hover {
      color: ${COLOR.blue};
    }
  }

  @media (max-width: 768px) {
    flex-direction: row;
    position: fixed;
    transform: ${(props): string => props.open ? 'translateX(0%)' : 'translateX(-100%)'};
    height: 100%;
    transition: transform 0.3s ease-in-out;
    z-index: 9;
    justify-content: normal;
  }
`;

export const NavContainer = styled.div`
  display:flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 769px) {
    height: 100%;
    background-color: ${COLOR.white};
    width: 85%;
    display:flex;
    flex-direction:column;
  }
`;

export const NavRightContainer = styled.div`
  width: 15%;
  background-color: ${COLOR.blackTransparent};

  @media (min-width: 769px) {
    display: none;
  }
`;

export const LinkButton = styled(Link) <LinkButtonProps>`
  padding: 0.75rem 1rem;
  margin: 1rem 0;
  border-radius: 0.4rem;
  display: flex;
  text-align: center;
  border: none;
  font-weight: 600;
  background: ${(props): string => props.selected ? COLOR.whiteGrey : COLOR.white};
  text-decoration: none;
  color: ${COLOR.black};
  height: fit-content;

  @media (max-width: 769px) {
    margin: 0.5rem 1.5rem 0.5rem 1rem;
  }
`;

export const LinkText = styled.div`
  font-size: 0.875rem
`;

export const CloseNavIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 0.75rem;
  top: 1rem;
  font-size: 1.2rem;
  color: ${COLOR.white};

  @media (min-width: 769px) {
    display: none;
  }
`;