import {
  RECEIVE_DRIVER_PROFILES,
  RECEIVE_DRIVER_PROFILE,
  RECEIVE_DRIVER_PROFILES_WITH_SQUAD,
  DriverProfileActionTypes,
  DriverProfileState,
} from './driverProfileTypes';

const initialState: DriverProfileState = {
  profiles: [],
  profile: null,
  profilesWithSquad: [],
};

function driverProfileReducer(
  state = initialState,
  action: DriverProfileActionTypes
): DriverProfileState {
  switch(action.type) {
    case RECEIVE_DRIVER_PROFILES:
      return {
        ...state,
        profiles: action.profiles,
      };
    case RECEIVE_DRIVER_PROFILE:
      return {
        ...state,
        profile: action.profile,
      };
    case RECEIVE_DRIVER_PROFILES_WITH_SQUAD:
      return {
        ...state,
        profilesWithSquad: action.profiles,
      };
    default:
      return state;
  }
}

export default driverProfileReducer;