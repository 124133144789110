import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';

const WIDTHS = {
  auto: 'auto',
  verySmall: '8%',
  small: '13%',
  medium: '23%',
  large: '40%',
  half: '57%',
  full: 'calc(100% - 0.5rem)',
};

const HEIGHTS = {
  small: '2.3rem',
  large: '2.5rem',
};

interface StyledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputStyle?: FlattenSimpleInterpolation;
  isRequired?: boolean;
  width?: keyof typeof WIDTHS;
  height?: keyof typeof HEIGHTS;
  icon?: IconDefinition;
  disabled?: boolean;
  hideNativeInputNumberButton?: boolean;
}

export default styled.input<StyledInputProps>`
  background-color: ${(props): string => props.disabled ? COLOR.neutral : props.color || COLOR.white};
  border-color: ${(props): string => props.isRequired && props.value === ''
    ? COLOR.red : (props.disabled ? COLOR.grey : COLOR.neutral)};
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  font-size: 1rem;
  height: ${(props): string => props.height ? HEIGHTS[props.height] : HEIGHTS.large};
  padding: 0 0.75rem;
  padding-right: ${(props): string => props.icon ? '2.3rem' : '1rem'};
  width: 100%;
  text-overflow: ellipsis;
  color: ${COLOR.black};
  ${(props): FlattenSimpleInterpolation => props.inputStyle};

  &:focus {
    outline: none;
    border-color: ${COLOR.blue};
  }

  &::placeholder {
    color: ${COLOR.grey};
  }

  ::-ms-reveal {
    display: none;
  }
  
  ${(props): FlattenSimpleInterpolation => props.hideNativeInputNumberButton ? css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  ` : null}
`;

/* eslint no-undef: "OFF" */
export { HEIGHTS, WIDTHS, StyledInputProps };