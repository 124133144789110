import * as React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faCheck,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import COLOR, { transparent } from 'constants/color';
import { WIDTHS, HEIGHTS } from './basicTextInput';
import CenterModal from '../modal/centerModal';

const PADDING_HEIGHTS = {
  small: '0.5rem 0.75rem',
  large: '0.75rem',
};

interface Option {
  name: string;
  value: string;
  description?: string;
}

interface DropdownProps {
  containerStyle?: FlattenSimpleInterpolation;
  disabled?: boolean;
  includeAll?: boolean;
  label?: string;
  onChange: (value: string) => void;
  options: Option[];
  value: string;
  width?: keyof typeof WIDTHS;
  height?: keyof typeof HEIGHTS;
  minWidth?: keyof typeof WIDTHS;
  withSearch?: boolean;
  searchPlaceholder?: string;
  sorted?: boolean;
  withoutDefinedLabel?: boolean;
  isShowingModalListOnMobile?: boolean;
  isItemTitleBold?: boolean;
  modalTitle?: string;
}

interface DropdownState {
  showDropdown: boolean;
  searchText: string;
  isMobile: boolean;
}

class Dropdown extends React.Component<DropdownProps, DropdownState> {
  buttonRef: React.RefObject<HTMLButtonElement>;
  dropdownRef: React.RefObject<HTMLDivElement>;

  static defaultProps = {
    isShowingModalListOnMobile: false,
  };

  constructor(props: DropdownProps) {
    super(props);

    this.state = {
      showDropdown: false,
      searchText: '',
      isMobile: false,
    };

    this.buttonRef = React.createRef();
    this.dropdownRef = React.createRef();
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleOutsideClick);
    window.addEventListener('resize', this.handleWindowResize);

    this.handleWindowResize();
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleOutsideClick);
    this.closeModalEvent('remove');
  }

  componentDidUpdate(): void {
    this.closeModalEvent('add');
  }

  handleOutsideClick = (event: MouseEvent): void => {
    if (
      this.dropdownRef &&
      !this.dropdownRef.current.contains(event.target as Node)
    ) {
      this.setState({ showDropdown: false });
    }
  };

  handleWindowResize = (): void => {
    const isMobile = window.innerWidth <= 768;
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
  };

  onSelect = (value: string): void => {
    this.setState({ showDropdown: false });
    this.props.onChange(value);
  };

  onTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ searchText: event.target.value });
  };

  onIconClick = (): void => {
    if (this.state.searchText !== '') {
      this.setState({
        searchText: '',
      });
    }
  };

  onIconCloseModalClick = (): void => {
    this.setState({ showDropdown: false });
  };

  closeModalEvent = (type: 'add' | 'remove'): void => {
    if (this.props.isShowingModalListOnMobile) {
      const closeModalDom = document.getElementById('close-modal');
      if (closeModalDom && type === 'add') {
        closeModalDom.addEventListener('click', this.onIconCloseModalClick);
      } else if (closeModalDom && type === 'remove') {
        closeModalDom.removeEventListener('click', this.onIconCloseModalClick);
      }
    }
  };

  renderModalTitle = (title: string): React.ReactNode => (
    <ModalTitleWrapper>
      <ModalTitle>{title}</ModalTitle>
      <div id="close-modal">
        <IconClose icon={faTimes} />
      </div>
    </ModalTitleWrapper>
  );

  render(): React.ReactNode {
    let selectedText = '';

    if (this.props.value === '') {
      if (this.props.includeAll) {
        selectedText = 'All';
      }
    } else {
      const selected = this.props.options.find(
        (option) => option.value === this.props.value
      );
      if (selected) {
        selectedText = selected.name;
      }
    }

    let width = 'auto';
    if (this.buttonRef.current) {
      width = this.buttonRef.current.offsetWidth.toString();
    }
    const options = this.props.options.filter((item) => item.value !== '');
    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(this.state.searchText.toLowerCase())
    );
    if (this.props.sorted) {
      filteredOptions.sort((a, b) =>
        a.name === 'All' ? -1 : a.name.localeCompare(b.name)
      );
    }
    if (this.props.includeAll) {
      filteredOptions.unshift({
        value: '',
        name: 'All',
      });
    }
    return (
      <Container
        containerStyle={this.props.containerStyle}
        width={this.props.width}
      >
        <div
          onClick={(): void => {
            if (!this.props.disabled) {
              this.setState({ showDropdown: true });
            }
          }}
          ref={this.dropdownRef}
        >
          <DropdownButton
            type="button"
            width={this.props.width}
            height={this.props.height}
            minWidth={this.props.minWidth}
            disabled={this.props.disabled}
            onClick={(): void => {
              if (!this.props.disabled) {
                this.setState({ showDropdown: true });
              }
            }}
            ref={this.buttonRef}
          >
            {this.props.withoutDefinedLabel ? (
              <SelectedText>
                {selectedText ? selectedText : this.props.label}
              </SelectedText>
            ) : (
              <SelectedText>
                {this.props.label ? this.props.label + ': ' : ''}
                {selectedText}
              </SelectedText>
            )}
            <FontAwesomeIcon icon={faAngleDown} />
          </DropdownButton>
          {this.state.showDropdown ? (
            this.state.isMobile && this.props.isShowingModalListOnMobile ? (
              <CenterModal title={this.renderModalTitle(this.props.modalTitle)}>
                <DropdownItems
                  withSearch={this.props.withSearch}
                  withModal={true}
                >
                  {filteredOptions.map((option) => (
                    <DropdownItem
                      withBorderRadius={true}
                      key={option.value}
                      onMouseDown={(): void => {
                        this.onSelect(option.value);
                      }}
                      selected={option.value === this.props.value}
                    >
                      <DropdownItemTitle
                        isItemTitleBold={this.props.isItemTitleBold}
                      >
                        {option.name}
                      </DropdownItemTitle>

                      {option.description && (
                        <DropdownItemDescription>
                          {option.description}
                        </DropdownItemDescription>
                      )}

                      {option.value === this.props.value && (
                        <IconChecked icon={faCheck} />
                      )}
                    </DropdownItem>
                  ))}
                </DropdownItems>
              </CenterModal>
            ) : (
              <DropdownListContainer>
                <DropdownList width={width} withSearch={this.props.withSearch}>
                  {this.props.withSearch ? (
                    <DropdownSearch>
                      <Input
                        onChange={this.onTextChange}
                        placeholder={
                          this.props.searchPlaceholder
                            ? this.props.searchPlaceholder
                            : 'Search'
                        }
                        value={this.state.searchText}
                      />
                      <DropdownSearchIconWrapper onClick={this.onIconClick}>
                        <Icon
                          icon={
                            this.state.searchText === '' ? faSearch : faTimes
                          }
                        />
                      </DropdownSearchIconWrapper>
                    </DropdownSearch>
                  ) : (
                    false
                  )}
                  <DropdownItems withSearch={this.props.withSearch}>
                    {filteredOptions.map((option) => (
                      <DropdownItem
                        key={option.value}
                        onMouseDown={(): void => {
                          this.onSelect(option.value);
                        }}
                        selected={option.value === this.props.value}
                      >
                        <DropdownItemTitle
                          isItemTitleBold={this.props.isItemTitleBold}
                        >
                          {option.name}
                        </DropdownItemTitle>

                        {option.description && (
                          <DropdownItemDescription>
                            {option.description}
                          </DropdownItemDescription>
                        )}
                      </DropdownItem>
                    ))}
                  </DropdownItems>
                </DropdownList>
              </DropdownListContainer>
            )
          ) : null}
        </div>
      </Container>
    );
  }
}
interface ContainerProps {
  containerStyle: FlattenSimpleInterpolation;
  width: keyof typeof WIDTHS;
}

const Container = styled.div<ContainerProps>`
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
  width: ${(props): string => (props.width ? WIDTHS[props.width] : 'auto')};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface DropdownButtonProps {
  disabled: boolean;
  minWidth: keyof typeof WIDTHS;
  width: keyof typeof WIDTHS;
  height: keyof typeof HEIGHTS;
}

const DropdownButton = styled.button<DropdownButtonProps>`
  width: ${(props): string => (props.width ? '100%' : 'auto')};
  min-width: ${(props): string =>
    props.minWidth ? WIDTHS[props.minWidth] : 'auto'};
  height: ${(props): string => (props.height ? HEIGHTS[props.height] : 'auto')};
  background-color: ${(props): string =>
    props.disabled ? COLOR.neutral : COLOR.white};
  border: 1px solid ${COLOR.neutral};
  border-radius: 4px;
  color: ${transparent('black', 0.75)};
  cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  padding: ${(props): string =>
    props.height ? PADDING_HEIGHTS[props.height] : '0.5rem 0.75rem'};

  :hover {
    background-color: ${(props): string =>
      props.disabled ? COLOR.neutral : COLOR.whiteGrey};
  }
`;

const SelectedText = styled.div`
  width: 100%;
  margin-right: 0.5rem;
  text-align: left;
`;

const DropdownListContainer = styled.div`
  height: 0;
  position: relative;
  width: 100%;
`;

const DropdownList = styled.div<{ width: string; withSearch: boolean }>`
  background-color: ${COLOR.white};
  border: 1px solid ${transparent('shadow', 0.26)};
  border-radius: 4px;
  position: absolute;
  top: 0.125rem;
  min-width: 13rem;
  z-index: 15;
  max-height: ${(props): string => (props.withSearch ? '14rem' : '12rem')};
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 2px 4px -2px ${(props): string => props.theme.colors.blue_100},
    0px 4px 8px -2px ${(props): string => props.theme.colors.blue_100};
`;

const DropdownSearch = styled.div`
  height: 2rem;
  margin-right: 0.75rem;
  padding-top: 0.5rem;
  position: relative;
`;

const DropdownSearchIconWrapper = styled.span`
  position: absolute;
  right: -14px;
  top: 4px;
`;

const DropdownItems = styled.div<{ withSearch: boolean; withModal?: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: ${(props): string =>
    props.withSearch ? '12rem' : props.withModal ? '26.2rem' : '10rem'};
  overflow-y: auto;
`;

interface DropdownItemProps {
  selected: boolean;
  withBorderRadius?: boolean;
}

const DropdownItem = styled.div<DropdownItemProps>`
  display: flex;
  flex-direction: column;
  color: ${(props): string =>
    props.selected ? COLOR.neutral : transparent('black', 0.75)};
  cursor: ${(props): string => (props.selected ? 'default' : 'pointer')};
  padding: 0.625rem 0 0.625rem 0.75rem;
  position: relative;
  border-radius: ${(props): string =>
    props.withBorderRadius ? '0.375rem' : '0px'};

  background-color: ${(props): string =>
    props.selected ? props.theme.colors.gray_50 : 'inherit'};

  :last-child {
    border-bottom: 0px;
  }

  :hover {
    background-color: ${(props): string => props.theme.colors.gray_50};
  }
`;

const DropdownItemTitle = styled.p<{ isItemTitleBold?: boolean }>`
  font-size: 1rem;
  margin: 0 0 2px 0;
  font-weight: ${(props): string => (props.isItemTitleBold ? '600' : '400')};
  color: ${(props): string => props.theme.colors.gray_900};
`;

const DropdownItemDescription = styled.span`
  font-size: 0.75rem;
  color: ${(props): string => props.theme.colors.gray_600};
`;

const Input = styled.input`
  border: 0;
  background-color: ${COLOR.white};
  width: 95%;
  font-size: 1rem;
  padding-left: 0.75rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-top: 0.2rem;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${COLOR.midLightGrey};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
  color: ${COLOR.grey};
  padding-right: 0.25rem;
  padding-left: 0.25rem;
`;

const IconChecked = styled(FontAwesomeIcon)`
  position: absolute;
  right: 16px;
  top: calc(50% - 8px);
  color: ${(props): string => props.theme.colors.primary_600};
`;

const IconClose = styled(FontAwesomeIcon)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${(props): string => props.theme.colors.gray_500};
`;

const ModalTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalTitle = styled.p`
  margin: 0;
`;

export default Dropdown;
