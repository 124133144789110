import * as React from 'react';
import * as H from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import COLOR from 'constants/color';
import {
  faExclamationCircle,
  faSearch,
  faTimes,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import styled, { css } from 'styled-components';
import configureStore, { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';

import {
  Separator as BasicSeparator,
  Heading,
  PaginationState,
  Breadcrumb,
  PageTitle,
  Checkbox,
  Separator,
  TextInput,
  StyledButton,
  Searchbox,
  SysAdminMainContainer,
  Radio,
} from 'components';
import SettingSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import { GeoData, SavedGeoData } from 'models/geoService';
import GeoServiceClient from 'httpClients/geoServiceClient';
import { Contact } from 'models/task';
import { receiveGeoDatas } from 'reduxActions/geoService/geoServiceActions';
import LocateMapModal from 'pages/order/modal/locateMapModal';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { Organization } from 'models/organization';

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

interface SavedAddressDetailProps {
  models: SavedGeoData;
  geoDatas: GeoData[];
  organization: Organization;
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface SavedAddressState extends PaginationState<SavedGeoData> {
  form: SavedGeoData;
  contact: Contact;
  showPickupLocationModal: boolean;
  error: {
    name_address: string;
  };
}

type Props = RouteComponentProps<{ id: string; addressId: string }> &
  SavedAddressDetailProps &
  HistoryProps;

class SysAdminSavedAddressEdit extends React.Component<Props, SavedAddressState> {
  searchPickupTimeout: number | null | NodeJS.Timeout;
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      form: this.getDefaultForm(),
      showPickupLocationModal: false,
      error: {
        name_address: '',
      },
    };
  }

  getDefaultForm(): SavedGeoData {
    const savedAddress = this.props.models;
    if (savedAddress) {
      return {
        id: savedAddress && savedAddress.id,
        name_address:
          savedAddress &&
          savedAddress.name_address &&
          savedAddress.name_address.toUpperCase(),
        building_name: savedAddress && savedAddress.building_name,
        unit_number: savedAddress && savedAddress.unit_number,
        street_address: savedAddress && savedAddress.street_address,
        city: savedAddress && savedAddress.city,
        state: savedAddress && savedAddress.state,
        country: savedAddress && savedAddress.country,
        zip_code: savedAddress && savedAddress.zip_code,
        lat: savedAddress && savedAddress.lat,
        lng: savedAddress && savedAddress.lng,
        source: savedAddress && savedAddress.source,
        source_reference: savedAddress && savedAddress.source_reference,
        contact_name: savedAddress && savedAddress.contact_name,
        contact_phone: savedAddress && savedAddress.contact_phone,
        contact_email: savedAddress && savedAddress.contact_email,
        is_default_enabled:
          savedAddress &&
          (savedAddress.is_default_pickup || savedAddress.is_default_delivery),
        is_default_pickup: savedAddress && savedAddress.is_default_pickup,
        is_default_delivery: savedAddress && savedAddress.is_default_delivery,
      };
    }
  }

  componentDidMount(): void {
    this.fetchData();
  }

  componentDidUpdate(prevProps: SavedAddressDetailProps): void {
    if (prevProps.models !== this.props.models) {
      this.setState({
        form: this.getDefaultForm(),
      });
    }
  }

  defaultFilter = (): Record<string, string> => ({
    page: '1',
  });

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const orgClient = new OrganizationManagementClient();
    await orgClient.sysAdminGetOrganization(this.props.match.params.id);

    const savedAddressClient = new GeoServiceClient();
    await savedAddressClient.getSysSavedAddressDetail(this.props.match.params.addressId);

    this.setState({ isFetching: false });
  };

  onSelectAddress = (geoData: GeoData): void => {
    this.selectAddress(geoData.street_address, geoData);
  };

  selectAddress = (streetAddress: string, geoData: GeoData): void => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        street_address: streetAddress,
        building_name: geoData.building_name,
        city: geoData.city,
        state: geoData.state,
        country: geoData.country,
        zip_code: geoData.zip_code,
        lat: geoData.lat,
        lng: geoData.lng,
        source: geoData.source,
        source_reference: geoData.source_reference,
      },
    }));
  };

  fetchAddresses = async (searchKey: string): Promise<void> => {
    this.clearAddresses();
    if (searchKey && searchKey.length >= 3) {
      const client = new GeoServiceClient();
      await client.getGeoCode(searchKey);
    }
  };

  isLocationValid = (geoData: GeoData): boolean => {
    return (
      geoData.street_address !== '' &&
      geoData.lat !== 0 &&
      geoData.lng !== 0 &&
      geoData.lat !== undefined &&
      geoData.lng !== undefined
    );
  };

  isAddressLocationValid = (address: SavedGeoData): boolean =>
    this.isLocationValid({
      street_address: address.street_address,
      zip_code: address.zip_code,
      lat: address.lat,
      lng: address.lng,
    });

  clearAddresses = (): void => {
    const store = configureStore();
    store.dispatch(receiveGeoDatas([]));
  };

  clearAddress = (): void => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        building_name: '',
        street_address: '',
        city: '',
        state: '',
        country: '',
        zip_code: '',
        lat: 0,
        lng: 0,
      },
    }));
  };

  searchAddress = (): void => {
    clearTimeout(this.searchPickupTimeout);

    this.searchPickupTimeout = setTimeout(() => {
      this.fetchAddresses(this.state.form.building_name);
    }, 700);
  };

  renderContent = (): React.ReactNode => {
    return (
      <>
        <PersonalInfoColumn>
          <Header>Address Details</Header>
          <div>
            <TextInput
              autoComplete="on"
              inputName="name_address"
              containerStyle={inlineTextInputStyle}
              fieldName="Name of Address"
              onTextChange={(value: string): void => {
                this.setState((prevState) => ({
                  form: {
                    ...prevState.form,
                    name_address: value.toUpperCase(),
                  },
                }));
              }}
              isRequired
              placeholder="E.g. Home, work, warehouse"
              width="half"
              value={this.state.form.name_address}
            />
            {this.state.error.name_address && this.state.error.name_address !== '' ? (
              <InlineErrorMessage>
                <ErrorIcon icon={faExclamationCircle} />
                {this.state.error.name_address}
              </InlineErrorMessage>
            ) : (
              false
            )}
            <CheckBoxField>
              <Checkbox
                onClick={(): void => {
                  this.setState((prevState) => ({
                    form: {
                      ...prevState.form,
                      is_default_enabled: !prevState.form.is_default_enabled,
                      is_default_pickup: prevState.form.is_default_enabled === false,
                      is_default_delivery: false,
                    },
                  }));
                }}
                selected={this.state.form.is_default_enabled}
              >
                Set your address as default
              </Checkbox>
            </CheckBoxField>
            <IsDefaultRadioContainer>
              <Radio
                options={[
                  {
                    text: 'Pickup',
                    value: 'pickup',
                    disabled: !this.state.form.is_default_enabled,
                  },
                  {
                    text: 'Delivery',
                    value: 'delivery',
                    disabled: !this.state.form.is_default_enabled,
                  },
                ]}
                value={
                  this.state.form.is_default_pickup
                    ? 'pickup'
                    : this.state.form.is_default_delivery
                    ? 'delivery'
                    : ''
                }
                onChange={(value: string): void => {
                  this.setState((prevState) => ({
                    form: {
                      ...prevState.form,
                      is_default_pickup: value === 'pickup',
                      is_default_delivery: value === 'delivery',
                    },
                  }));
                }}
              />
            </IsDefaultRadioContainer>
          </div>
          <Field>
            <Searchbox
              autoComplete="off"
              key="street-address"
              inputName="street-address"
              trailingIcon={faSearch}
              containerStyle={inlineTextInputStyle}
              fieldName="Postal Code / Street Address"
              isRequired
              onTextChange={(value: string): void => {
                this.setState((prevState) => ({
                  form: {
                    ...prevState.form,
                    building_name: value,
                  },
                }));
                this.searchAddress();
                if (value === '') {
                  this.clearAddress();
                }
              }}
              handleSetFocus={(focused: boolean): void => {
                if (focused) {
                  this.clearAddresses();
                  this.searchAddress();
                }
              }}
              handleSelectChange={(value: string, geoData: GeoData): void => {
                this.selectAddress(value, geoData);
              }}
              placeholder="880 Jurong West Street 88"
              width="full"
              value={this.state.form?.building_name}
              options={(this.props.geoDatas || []).map((geoData) => ({
                title: geoData.building_name,
                value: geoData.street_address,
                text: geoData.street_address,
                additionalValue: {
                  building_name: geoData.building_name,
                  street_address: geoData.street_address,
                  zip_code: geoData.zip_code,
                  lat: geoData.lat,
                  lng: geoData.lng,
                },
              }))}
            />
            <SubTitle title={this.state.form.street_address}>
              {this.state.form.street_address}
            </SubTitle>
            <div>
              {(this.state.form.street_address || this.state.form.building_name) &&
              !this.isAddressLocationValid(this.state.form) ? (
                <InlineErrorMessage>
                  <ErrorIcon icon={faExclamationCircle} />
                  Address not found
                </InlineErrorMessage>
              ) : (
                false
              )}
              <LocateMapButton
                onClick={(): void => this.setState({ showPickupLocationModal: true })}
              >
                Locate on a map
              </LocateMapButton>
            </div>
          </Field>
          <Field>
            <TextInput
              autoComplete="none"
              fieldName="Unit No."
              containerStyle={inlineTextInputStyle}
              onTextChange={(value: string): void => {
                this.setState((prevState) => ({
                  form: {
                    ...prevState.form,
                    unit_number: value,
                  },
                }));
              }}
              placeholder="#01-01"
              width="half"
              value={this.state.form.unit_number}
            />
          </Field>
        </PersonalInfoColumn>
        <Separator />
        <PersonalInfoColumn>
          <Header>Contact Details</Header>
          <Field>
            <TextInput
              autoComplete="on"
              inputName="name"
              containerStyle={inlineTextInputStyle}
              fieldName="Name"
              onTextChange={(value: string): void => {
                this.setState((prevState) => ({
                  form: {
                    ...prevState.form,
                    contact_name: value,
                  },
                }));
              }}
              placeholder="Amy Amin"
              width="half"
              value={this.state.form.contact_name}
            />
          </Field>
          <Field>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Contact No."
              placeholder="8900 89000"
              onTextChange={(value: string): void => {
                this.setState((prevState) => ({
                  form: {
                    ...prevState.form,
                    contact_phone: value,
                  },
                }));
              }}
              type="text"
              width="half"
              value={this.state.form.contact_phone}
            />
          </Field>
          <Field>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Email"
              placeholder="amyamin@example.com"
              onTextChange={(value: string): void => {
                this.setState((prevState) => ({
                  form: {
                    ...prevState.form,
                    contact_email: value,
                  },
                }));
              }}
              type="text"
              width="half"
              value={this.state.form.contact_email}
            />
          </Field>
        </PersonalInfoColumn>
      </>
    );
  };

  onConfirmValidation = async (): Promise<boolean> => {
    const client = new GeoServiceClient();

    try {
      const savedAddress = await client.getSysSavedAddressDetailByName(
        this.state.form.name_address,
        this.props.match.params.id
      );
      if (savedAddress.id === this.state.form.id) {
        return true;
      }
      this.setState((prevState) => ({
        error: {
          ...prevState.error,
          name_address: 'Name has been taken.',
        },
      }));
    } catch (error) {
      // continue since it not duplicated
      return true;
    }
    return false;
  };

  handleSaveAddress = async (): Promise<void> => {
    const { form } = this.state;
    const orgID = this.props.match.params.id;

    const isValidate = await this.onConfirmValidation();
    if (!isValidate) {
      return;
    }

    const savedGeoForm: SavedGeoData = {
      id: form.id,
      street_address: form.street_address,
      name_address: form.name_address,
      unit_number: form.unit_number,
      building_name: form.building_name,
      city: form.city,
      state: form.state,
      country: form.country,
      zip_code: form.zip_code,
      lat: form.lat,
      lng: form.lng,
      contact_name: form.contact_name,
      contact_phone: form.contact_phone,
      contact_email: form.contact_email,
      is_default_pickup: form.is_default_pickup,
      is_default_delivery: form.is_default_delivery,
      org_id: orgID,
    };
    const client = new GeoServiceClient();
    try {
      await client.sysUpdateSavedAddress(savedGeoForm);

      this.props.history.push(
        `/sys/organizations/${this.props.match.params.id}/saved-address/${form.id}`
      );
    } catch (error) {
      // this.setState({ error });
      // TODO: CHECK ERROR IMPLEMENTATION
    }
  };

  selectPickupAddress = (streetAddress: string, geoData: GeoData): void => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        street_address: streetAddress,
        building_name: geoData.building_name,
        lat: geoData.lat,
        lng: geoData.lng,
        zip_code: geoData.zip_code,
      },
    }));
  };

  onSelectPickupAddress = (geoData: GeoData): void => {
    this.selectPickupAddress(geoData.street_address, geoData);
  };

  renderLocateMapModal = (): React.ReactNode => {
    const { form, showPickupLocationModal } = this.state;

    return (
      <>
        {showPickupLocationModal && (
          <LocateMapModal
            defaultAddress={{
              building_name: form.building_name ? form.building_name : '',
              street_address: form.street_address ? form.street_address : '',
              lat: form.lat ? form.lat : 0,
              lng: form.lng ? form.lng : 0,
              zip_code: form.zip_code ? form.zip_code : '',
            }}
            closeModal={(): void => {
              this.setState({ showPickupLocationModal: false });
            }}
            onSelectAddress={this.onSelectPickupAddress}
          />
        )}
      </>
    );
  };

  render(): React.ReactNode {
    const { form } = this.state;
    const { id, addressId } = this.props.match.params;
    return (
      <SysAdminMainContainer selected="setting">
        <Breadcrumb
          items={[
            {
              text: 'Organizations',
              onClick: (): void => {
                this.props.history.push('/sys/organizations');
              },
            },
            {
              text: id,
              onClick: (): void => {
                this.props.history.push('/sys/organizations/' + id + '/company-details');
              },
            },
            { text: 'Saved Addresses' },
          ]}
        />
        <Heading>
          <PageTitle>Setting</PageTitle>
        </Heading>
        <SettingSidebarMenu selected={`/sys/organizations/${id}/saved-address`}>
          <Heading>
            <PageTitle>{form && form.name_address}</PageTitle>
            <ActionButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={(): void => {
                this.props.history.push(
                  `/sys/organizations/${id}/saved-address/${addressId}`
                );
              }}
            >
              <Icon icon={faTimes} color={COLOR.black} />
              Cancel
            </ActionButton>
            <ActionButton
              buttonType="primary"
              buttonStyle="encourage"
              disabled={form.name_address === '' || form.street_address === ''}
              onClick={this.handleSaveAddress}
            >
              <Icon icon={faCheck} />
              Save
            </ActionButton>
          </Heading>
          <CustomSeparator />
          {this.renderContent()}
          {this.renderLocateMapModal()}
        </SettingSidebarMenu>
      </SysAdminMainContainer>
    );
  }
}

const CustomSeparator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
`;

const Icon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;
`;

const Header = styled.div`
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const InlineErrorMessage = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const LocateMapButton = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
  width: fit-content;
  margin-top: 0.3rem;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  margin-right: 0.35rem;
`;

const SubTitle = styled.div`
  color: ${COLOR.darkGray};
  margin-top: 0.3rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const CustomCard = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${COLOR.neutral};
  padding: 1rem;
  background-color: ${COLOR.white};
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PersonalInfoColumn = styled(Column)`
  flex: 1;
  margin-bottom: 1rem;

  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Field = styled.div`
  margin-bottom: 1rem;
`;

const ActionButton = styled(StyledButton)`
  margin-left: 1rem;
`;

const CheckBoxField = styled.div`
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`;

const IsDefaultRadioContainer = styled.div`
  margin-top: 0.3rem;
  margin-left: 2rem;
  width: fit-content;
`;

function mapStateToProps(state: RootState): SavedAddressDetailProps {
  return {
    models: state.geoService.savedGeoData,
    geoDatas: state.geoService.geoDatas,
    organization: state.organization.organization,
  };
}

export default connect(mapStateToProps)(SysAdminSavedAddressEdit);
