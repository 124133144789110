import { RunsheetReadyToTransfer, RunsheetReadyToTransferLog } from 'models/runsheetReadyToTransfer';

export interface RunsheetReadyToTransferState {
  runsheetReadyToTransferLogs: RunsheetReadyToTransferLog[];
  runsheetReadyToTransfers: RunsheetReadyToTransfer[];
  runsheetReadyToTransfer: RunsheetReadyToTransfer | null;
}

export const RECEIVE_RUNSHEET_READY_TO_TRANSFER_LOGS = 'RECEIVE_RUNSHEET_READY_TO_TRANSFER_LOGS';
export const RECEIVE_RUNSHEET_READY_TO_TRANSFERS = 'RECEIVE_RUNSHEET_READY_TO_TRANSFERS';
export const RECEIVE_RUNSHEET_READY_TO_TRANSFER = 'RECEIVE_RUNSHEET_READY_TO_TRANSFER';

interface ReceivePriceAdjustmentLogsAction {
  type: typeof RECEIVE_RUNSHEET_READY_TO_TRANSFER_LOGS;
  runsheetReadyToTransferLogs: RunsheetReadyToTransferLog[];
}

interface ReceiveRunsheetReadyToTransfersAction {
  type: typeof RECEIVE_RUNSHEET_READY_TO_TRANSFERS;
  runsheetReadyToTransfers: RunsheetReadyToTransfer[];
}

interface ReceiveRunsheetReadyToTransferAction {
  type: typeof RECEIVE_RUNSHEET_READY_TO_TRANSFER;
  runsheetReadyToTransfer: RunsheetReadyToTransfer;
}

export type RunsheetReadyToTransferActionTypes = ReceivePriceAdjustmentLogsAction | ReceiveRunsheetReadyToTransfersAction | ReceiveRunsheetReadyToTransferAction;
