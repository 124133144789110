import * as React from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { StyledButton } from 'components';
import BROADCAST_PREFERENCES, {
  BROADCAST_PREFERENCE_DESCRIPTION,
} from 'constants/broadcastPreference';

interface Option {
  value: string;
  label: string | React.ReactNode;
  description?: string | React.ReactNode;
  disabled?: boolean;
}

interface RadioCardProps {
  options: Option[];
  broadcast_preference: string;
  onChange: (v: string) => void;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  containerStyle?: FlattenSimpleInterpolation;
  buttonContainerStyle?: FlattenSimpleInterpolation;
  radioStyle?: FlattenSimpleInterpolation;
}

class RadioCard extends React.Component<RadioCardProps> {
  render(): React.ReactNode {
    const {
      onChange,
      broadcast_preference,
      isRequired,
      label,
      containerStyle,
      options,
      disabled,
      buttonContainerStyle,
      radioStyle,
    } = this.props;
    const serviceList = [];
    for (const service in BROADCAST_PREFERENCES) {
      serviceList.push({
        value: service,
        label: BROADCAST_PREFERENCE_DESCRIPTION[service].label,
        description: BROADCAST_PREFERENCE_DESCRIPTION[service].description,
      });
    }
    return (
      <Container containerStyle={containerStyle}>
        {label ? (
          <Label>
            {label} {isRequired && <RedText>*</RedText>}
          </Label>
        ) : (
          false
        )}
        <RadioButtonsContainer buttonContainerStyle={buttonContainerStyle}>
          {options.map((v: Option, i: number) => (
            <RadioButton
              radioStyle={radioStyle}
              key={i}
              buttonStyle="encourage"
              buttonType="neutral"
              active={v.value === broadcast_preference}
              onClick={(): void => {
                !(v.disabled || disabled) && onChange(v.value);
              }}
              disabled={(v.disabled || disabled) && v.value !== broadcast_preference}
              type="button"
            >
              {v.label ? <LabelText>{v.label}</LabelText> : false}
              {v.description ? (
                <LabelDescription>{v.description}</LabelDescription>
              ) : (
                false
              )}
            </RadioButton>
          ))}
        </RadioButtonsContainer>
      </Container>
    );
  }
}

const Container = styled.div<{ containerStyle?: FlattenSimpleInterpolation }>`
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
`;

const RedText = styled.span`
  color: ${(props): string => props.theme.colors.error_500};
`;

const RadioButtonsContainer = styled.div<{ buttonContainerStyle?: FlattenSimpleInterpolation }>`
  ${(props): FlattenSimpleInterpolation => props.buttonContainerStyle};
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  gap: 0.625rem;
`;

const RadioButton = styled(StyledButton)<{ active: boolean, radioStyle: FlattenSimpleInterpolation }>`
  ${(props): FlattenSimpleInterpolation => props.radioStyle};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  padding: 16px 16px;
  box-shadow: none;
  justify-content: flex-start;

  &:disabled {
    background-color: ${(props): string => props.theme.colors.gray_50};
  }

  ${(props) => {
    const activeStyles = css`
      background-color: ${props.theme.colors.gray_50};
      border: 1.5px solid ${props.theme.colors.primary_600};

      &:hover {
        background-color: ${props.theme.colors.gray_50};
        border: 1.5px solid ${props.theme.colors.primary_600};
      }
    `;

    return props.active ? activeStyles : null;
  }}
`;

const Label = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${(props): string => props.theme.colors.gray_900};
`;

const LabelText = styled.div`
  font-size: 1rem;
`;

const LabelDescription = styled.div`
  font-size: 0.85rem;
  font-weight: 400;
  color: ${(props): string => props.theme.colors.gray_300};
`;

export default RadioCard;
