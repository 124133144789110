import {
  RECEIVE_CONTRACT_PRICINGS, RECEIVE_CONTRACT_PRICING, ReceiveContractPricings,
  ReceiveContractPricing,
} from './contractPricingTypes';
import { ContractPricingBasic, ContractPricing } from 'models/contractPricing';

export function receiveContractPricings(contractPricings: ContractPricingBasic[]): ReceiveContractPricings {
  return {
    type: RECEIVE_CONTRACT_PRICINGS,
    contractPricings,
  };
}

export function receiveContractPricing(contractPricing: ContractPricing): ReceiveContractPricing {
  return {
    type: RECEIVE_CONTRACT_PRICING,
    contractPricing,
  };
}
