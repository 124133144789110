import { TaskExport } from 'models/taskExport';
import { PlanningActionTypes, RECEIVE_TASK_EXPORT_JOB, RECEIVE_TASK_EXPORT_JOBS } from './taskExportTypes';

export function receiveTaskExportJobs(jobs: TaskExport[]): PlanningActionTypes {
  return {
    type: RECEIVE_TASK_EXPORT_JOBS,
    jobs,
  };
}

export function receiveTaskExportJob(job: TaskExport): PlanningActionTypes {
  return {
    type: RECEIVE_TASK_EXPORT_JOB,
    job,
  };
}
