import * as React from 'react';
import {
  StyledButton,
  CenterModal,
  Alert,
} from 'components';
import { TextInput } from 'components';
import styled from 'styled-components';
import COLOR from 'constants/color';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserInvitationForm } from 'models/auth';
import AuthClient from 'httpClients/authClient';
import { formatError, groupArrayKeyByValue } from 'utils/formatter';

const STATE = {
  beforeSend: 'beforeSend',
  sending: 'sending',
  afterSend: 'afterSend',
};

interface IconProps {
  color: string;
}

interface InvitationModalProps {
  closeModal: () => void;
}

interface InvitationModalState {
  sentCount: number;
  emails: Array<string>;
  errors: {
    email: string;
    message: string;
  }[];
  sentEmails: Array<string>;
  isProcessing: boolean;
  pageState: string;
}

class InvitationModal extends React.Component<InvitationModalProps, InvitationModalState>{
  constructor(props: InvitationModalProps) {
    super(props);

    this.state = {
      sentCount: 0,
      emails: [''],
      errors: [],
      sentEmails: [],
      isProcessing: false,
      pageState: STATE.beforeSend,
    };
  }

  validateInput = (): boolean => {
    const { emails } = this.state;
    for (let i = 0; i < emails.length; i++) {
      if (i > 0 && emails[i] === '') {
        return true;
      }
      if (!this.validateEmail(emails[i])) {
        return false;
      }
    }
    return true;
  }

  validateEmail = (email: string): boolean => {
    const pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return pattern.test(email);
  }

  sendInvitation = async (): Promise<void> => {
    this.setState({ pageState: STATE.sending, sentCount: 0 });

    const client = new AuthClient();
    await Promise.all(this.state.emails.map(async (email): Promise<void> => {
      if (email.trim() !== '') {
        try {
          const userInvitationForm: UserInvitationForm = {
            user: {
              email: email,
            },
          };
          await client.inviteOperator(userInvitationForm);
        } catch (e) {
          this.setState(prevState => {
            const newErrors = [...prevState.errors];
            newErrors.push({
              email: email,
              message: this.replaceMessage(formatError(e, false)),
            });
            return { errors: newErrors };
          });
          return;
        }
        this.setState(prevState => {
          const newEmails = [...prevState.sentEmails];
          newEmails.push(email);
          return { sentCount: prevState.sentCount + 1, sentEmails: newEmails };
        });
      }
    }));

    this.setState({ pageState: STATE.afterSend });
  }

  handleEmailChange = (index: number, value: string): void => {
    const newEmails = this.state.emails;
    newEmails[index] = value;
    this.setState({ emails: newEmails });
  }

  replaceMessage = (prevMessage: string): string => {
    let newMessage = prevMessage;
    if (prevMessage === 'has already been taken') {
      newMessage = 'are already member(s) on your team.';
    }
    return newMessage;
  }

  addEmailField = (): void => {
    this.setState({ emails: [...this.state.emails, ''] });
  }

  renderBeforeSend = (): React.ReactNode => {
    const { emails, pageState } = this.state;
    return (
      <CenterModal
        leftButtonOnClick={this.props.closeModal}
        leftButtonText="Cancel"
        rightButtonOnClick={this.sendInvitation}
        rightButtonText="OK"
        rightButtonDisabled={!this.validateInput()}
        title="Invite Members"
      >
        <InvitationContainer>
          <LabelDescription>
            <Label>Add up to 5 emails at a time.</Label>
          </LabelDescription>
          <LabelEmail>
            <Label>Email <Required>*</Required></Label>
          </LabelEmail>
          <EmailInputContainer>
            {emails.map((email, index) => (
              <EmailInput
                key={index}
                isRequired={index === 0}
                placeholder="name@example.com"
                onTextChange={(value): void => { this.handleEmailChange(index, value); }}
                type="text"
                value={email}
                width="full"
                height="large"
              />
            ))}
          </EmailInputContainer>
          <AddEmailButton
            buttonStyle="encourage"
            buttonType="neutral"
            hidden={emails.length >= 5}
            onClick={this.addEmailField}
            disabled={pageState === STATE.sending}
          >
            <Icon icon={faPlusCircle} color={COLOR.black} />
            Add Email
          </AddEmailButton>
        </InvitationContainer>
      </CenterModal>
    );
  };

  renderSending = (): React.ReactNode => (
    <CenterModal
      title="Invite Members"
    >
      <LabelInfo>
        <Label>Sending invitation(s) ...</Label>
      </LabelInfo>
    </CenterModal>
  );

  renderAfterSend = (): React.ReactNode => {
    const { sentCount, sentEmails } = this.state;
    const errors = groupArrayKeyByValue(this.state.errors, 'email', 'message').map((grouped) => (
      `${Array.isArray(grouped.email) ? grouped.email.join(', ') : grouped.email} 
      ${(typeof grouped.message === 'string') ? grouped.message.trim() : grouped.message}`
    ));
    return (
      <CenterModal
        rightButtonOnClick={this.props.closeModal}
        rightButtonText={'OK'}
        title="Invite members"
      >
        {(sentCount > 0) && (
          <AlertMessage status="success">
            <LabelInfoBold>
              {`${sentCount} ${sentCount === 1 ? 'has' : 'have'} joined your organisation:`}
            </LabelInfoBold>
            {sentEmails.map((email, index) => (
              <LabelInfo key={index}>
                {email}
              </LabelInfo>
            ))}
          </AlertMessage>
        )}
        <ErrorsContainer>
          {
            errors.map((error, index) => (
              <LabelError key={index}>
                {error}
              </LabelError>
            ))
          }
        </ErrorsContainer>
      </CenterModal>
    );
  };

  render(): React.ReactNode {
    let content: React.ReactNode;
    if (this.state.pageState === STATE.beforeSend) {
      content = this.renderBeforeSend();
    } else if (this.state.pageState === STATE.sending) {
      content = this.renderSending();
    } else if (this.state.pageState === STATE.afterSend) {
      content = this.renderAfterSend();
    }
    return (
      content
    );
  }
}

const ErrorsContainer = styled.div`
  margin-top: 1rem;
  line-height: 1.5rem;
`;

const AlertMessage = styled(Alert)`
  margin-bottom: 0.4rem;
`;

const Icon = styled(FontAwesomeIcon) <IconProps>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.375rem;
`;

const AddEmailButton = styled(StyledButton)`
  margin-bottom: 1.2rem;
`;

const InvitationContainer = styled.div`
  border-bottom: 0.1rem solid ${COLOR.neutral};
`;

const Label = styled.label`
  font-size: 0.875rem;
`;

const LabelDescription = styled.div`
  width: 100%;
  margin-bottom: 1.4rem;
`;

const LabelEmail = styled.div`
  width: 100%;
  margin-bottom: 0.4rem;
`;

const Required = styled.span`
  color: ${COLOR.red};
`;

const EmailInputContainer = styled.div`
  margin-bottom: 1rem;
`;

const EmailInput = styled(TextInput)`
  margin-bottom: 0.5rem;
`;

const LabelInfo = styled.div`
  margin-top: 0.5rem;
  width: 100%;
`;

const LabelInfoBold = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 600;
`;

const LabelError = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3rem;
`;

export default InvitationModal;