import {
  OrgDriverState,
  OrgDriverActionTypes,
  RECEIVE_ORGANIZATION_DRIVER,
  RECEIVE_ORGANIZATION_DRIVERS,
} from './orgDriverTypes';

const initialState: OrgDriverState = {
  orgDrivers: [],
  orgDriver: null,
};

function orgDriverReducer(
  state: OrgDriverState = initialState,
  action: OrgDriverActionTypes
): OrgDriverState {
  switch (action.type) {
    case RECEIVE_ORGANIZATION_DRIVER:
      return { ...state, orgDriver: action.orgDriver };
    case RECEIVE_ORGANIZATION_DRIVERS:
      return { ...state, orgDrivers: action.orgDrivers };
    default:
      return state;
  }
}

export default orgDriverReducer;
