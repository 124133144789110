import * as React from 'react';
import {
  PageTitle, SysAdminMainContainer, Breadcrumb, Heading, StyledButton,
} from 'components';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import VesselSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import VesselClient from 'httpClients/vesselClient';
import { Vessel } from 'models/vessel';
import { Separator, FormContainer, FormInput, FormHeading, FormContent } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CompanyDetailsProps {
  vessel: Vessel;
}

type Props = RouteComponentProps<{ id: string }> & CompanyDetailsProps;

const SysAdminCompanyDetailsIndex = (props: Props): JSX.Element => {
  React.useEffect(() => {
    (async function (): Promise<void> {
      const vesselClient = new VesselClient();
      await vesselClient.getVessel(props.match.params.id);
    })();
  }, []);

  const { vessel } = props;

  return (
    <SysAdminMainContainer selected="vessels">
      <Breadcrumb
        items={[
          { text: 'Vessels', onClick: (): void => { props.history.push('/sys/vessels'); } },
          { text: props.vessel && props.vessel.name },
        ]}
      />
      <Heading>
        <PageTitle>Vessels Info</PageTitle>
      </Heading>
      <VesselSidebarMenu
        selected={`/sys/vessels/${props.match.params.id}/details`}
      >
        <Heading>
          <PageTitle>
            Vessel Details
          </PageTitle>
          {vessel && (
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={(): void => {
                props.history.push(`/sys/vessels/${props.match.params.id}/details/edit`);
              }}
              icon={<FontAwesomeIcon icon={faEdit} style={{color: COLOR.blue}} />}
            >
              Edit
            </StyledButton>
          )}
        </Heading>
        <Separator />
        {vessel && (
          <FormContainer>
            <FormInput>
              <FormHeading>
                Vessel Name
              </FormHeading>
              <FormContent>
                {vessel.name}
              </FormContent>
            </FormInput>
            <FormInput>
              <FormHeading>
                IMO No.
              </FormHeading>
              <FormContent>
                {vessel.imo}
              </FormContent>
            </FormInput>
          </FormContainer>
        )}
      </VesselSidebarMenu>
    </SysAdminMainContainer>
  );
};

function mapStateToProps(state: RootState): CompanyDetailsProps {
  return {
    vessel: state.vessel.vessel,
  };
}

export default connect(mapStateToProps)(SysAdminCompanyDetailsIndex);