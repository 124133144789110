import * as React from 'react';
import { StyledButton, CenterModal, Searchbox, Separator } from 'components';
import styled, { css } from 'styled-components';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Icon as LeafletIcon } from 'leaflet';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faSearch,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import GeoServiceClient from 'httpClients/geoServiceClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { GeoData, GeoDataContact } from 'models/geoService';
import configureStore from 'reduxActions/store';
import { receiveGeoDatas } from 'reduxActions/geoService/geoServiceActions';

const DEFAULT_ZOOM = 14;
const FIND_ZOOM = 17;
const DEFAULT_LAT = 1.29027;
const DEFAULT_LNG = 103.851959;
const MAP_URL = 'https://www.onemap.gov.sg/maps/tiles/Default/{z}/{x}/{y}.png';
const MAP_ATTRIBUTION =
  'OneMap | Map data &copy; contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>';

interface Position {
  lat: number;
  lng: number;
}

interface StateProps {
  geoDatas: GeoData[];
}

interface LocateMapModalProps {
  defaultAddress?: GeoData;
  closeModal: () => void;
  onSelectAddress?: (geoData: GeoData) => void;
}

type Props = LocateMapModalProps & StateProps;

interface LocateMapModalState {
  selectedAddress: GeoData;
  mapPosition: Position;
  isMapReady: boolean;
}

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

class LocateMapModal extends React.Component<Props, LocateMapModalState> {
  searchTimeout: number | null | NodeJS.Timeout;
  filteredAnchorages: GeoDataContact[];
  anchorages: GeoDataContact[];
  constructor(props: Props) {
    super(props);
    const defaultGeoData = this.getDefaultGeoData();

    this.state = {
      isMapReady: false,
      selectedAddress: defaultGeoData,
      mapPosition: {
        lat: defaultGeoData.lat,
        lng: defaultGeoData.lng,
      },
    };
    this.searchTimeout = null;
    this.filteredAnchorages = [];
  }

  refmarker = React.createRef<L.Marker>();
  refMap = React.createRef<L.Map>();

  componentDidMount(): void {
    this.loadAnchorages();
    if (this.state.isMapReady) {
      if (this.isLocationValid(this.props.defaultAddress)) {
        this.findLocation(this.props.defaultAddress);
      } else {
        this.clearSelectedPosition();
        this.fetchAddresses();
      }
    }
  }

  loadAnchorages = async () => {
    const anchoragesAddress = await import('assets/anchorages.json');
    const geoDataAnchorages = anchoragesAddress;
    this.anchorages = geoDataAnchorages.geo_datas;
  };

  getDefaultGeoData = (): GeoData => {
    const { defaultAddress } = this.props;
    return {
      street_address:
        defaultAddress && defaultAddress.street_address
          ? defaultAddress.street_address
          : '',
      building_name:
        defaultAddress && defaultAddress.building_name
          ? defaultAddress.building_name
          : '',
      zip_code: defaultAddress && defaultAddress.zip_code ? defaultAddress.zip_code : '',
      lat: defaultAddress && defaultAddress.lat ? defaultAddress.lat : DEFAULT_LAT,
      lng: defaultAddress && defaultAddress.lng ? defaultAddress.lng : DEFAULT_LNG,
    };
  };

  markerEventHandlers = () => {
    return {
      dragend: () => {
        this.updatePosition();
      },
    };
  };

  updatePosition = (): void => {
    const marker = this.refmarker.current;
    if (marker != null) {
      const { lat, lng } = marker.getLatLng();
      this.setState((prevState) => ({
        selectedAddress: {
          ...prevState.selectedAddress,
          lat,
          lng,
        },
      }));
    }
  };

  searchAddress = (): void => {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.fetchAddresses();
      const filteredAnchorages = this.anchorages.filter((anchorage) => {
        return anchorage.name_address
          .toLowerCase()
          .includes(this.state.selectedAddress.building_name.toLowerCase());
      });
      this.filteredAnchorages = filteredAnchorages;
    }, 700);
  };

  clearSelectedAddress = (): void => {
    this.setState((prevState) => ({
      selectedAddress: {
        ...prevState.selectedAddress,
        street_address: '',
        building_name: '',
        name_address: '',
        latitude: 0,
        longitude: 0,
        zip_code: '',
        unit_number: '',
      },
    }));
  };

  fetchAddresses = async (): Promise<void> => {
    this.clearAddresses();
    const streetAddress = this.state.selectedAddress.building_name;
    if (streetAddress.length >= 3) {
      const client = new GeoServiceClient();
      await client.getGeoCode(streetAddress);
    }
  };

  clearAddresses = (): void => {
    const store = configureStore();
    store.dispatch(receiveGeoDatas([]));
  };

  findLocation = (geoData: GeoData): void => {
    const map = this.refMap.current;
    map?.flyTo(
      {
        lat: geoData.lat,
        lng: geoData.lng,
      },
      FIND_ZOOM
    );

    this.refmarker?.current?.openPopup();

    this.setState({
      selectedAddress: geoData,
      mapPosition: {
        lat: geoData.lat,
        lng: geoData.lng,
      },
    });
  };

  clearSelectedPosition = (): void => {
    // this is to reset the selected marker
    this.setState((prevState) => ({
      selectedAddress: {
        ...prevState.selectedAddress,
        lat: DEFAULT_LAT,
        lng: DEFAULT_LNG,
      },
    }));
  };

  isLocationValid = (geoData: GeoData): boolean => {
    return (
      geoData.street_address !== '' &&
      geoData.lat !== DEFAULT_LAT &&
      geoData.lng !== DEFAULT_LNG &&
      geoData.lat !== 0 &&
      geoData.lng !== 0
    );
  };

  render(): React.ReactNode {
    return (
      <>
        <CenterModal title="What's your exact location?">
          <Row>
            <Searchbox
              autoComplete="off"
              inputName="search"
              trailingIcon={
                (this.state.selectedAddress?.building_name || '') === ''
                  ? faSearch
                  : faTimes
              }
              trailingIconOnClick={(): void => {
                (this.state.selectedAddress?.building_name || '') !== '' &&
                  this.clearSelectedAddress();
              }}
              containerStyle={inlineTextInputStyle}
              fieldName="Postal Code or Street Address"
              isRequired
              onTextChange={(val: string): void => {
                this.setState((prevState) => ({
                  selectedAddress: {
                    ...prevState.selectedAddress,
                    building_name: val,
                  },
                }));
                this.searchAddress();
                this.clearSelectedPosition();
              }}
              placeholder="'640880' or '880 Jurong West Street 88'"
              width="full"
              value={this.state.selectedAddress.building_name}
              options={([...this.props.geoDatas, ...this.filteredAnchorages] || []).map((geoData) => ({
                title: geoData.building_name,
                value: geoData.street_address,
                text: geoData.street_address,
                additionalValue: {
                  building_name: geoData.building_name,
                  street_address: geoData.street_address,
                  zip_code: geoData.zip_code,
                  lat: geoData.lat,
                  lng: geoData.lng,
                },
              }))}
              handleSelectChange={(value: string, geoData: GeoData): void => {
                this.setState((prevState) => ({
                  selectedAddress: {
                    ...prevState.selectedAddress,
                    building_name: value,
                  },
                }));
                this.clearSelectedPosition();
                this.findLocation(geoData);
              }}
            />
          </Row>
          <SubTitleContainer>
            <SubTitle title={this.state.selectedAddress.street_address}>
              {this.state.selectedAddress.street_address}
            </SubTitle>
          </SubTitleContainer>
          <ErrorContainer>
            {!this.isLocationValid(this.state.selectedAddress) ? (
              <InlineErrorMessage>
                <ErrorIcon icon={faExclamationCircle} />
                Search a nearby location and adjust the map below
              </InlineErrorMessage>
            ) : (
              false
            )}
          </ErrorContainer>
          <MapContainer
            ref={this.refMap}
            style={{ width: '100%', height: '300px' }}
            center={[this.state.mapPosition.lat, this.state.mapPosition.lng]}
            zoom={
              this.isLocationValid(this.props.defaultAddress) ? FIND_ZOOM : DEFAULT_ZOOM
            }
            maxZoom={18}
            minZoom={11}
            scrollWheelZoom={false}
            useFlyTo={true}
            maxBounds={[
              [1.56073, 104.1147],
              [1.16, 103.502],
            ]}
            whenReady={() => {
              this.setState({ isMapReady: true });
            }}
          >
            <TileLayer url={MAP_URL} attribution={MAP_ATTRIBUTION} />
            {this.isLocationValid(this.state.selectedAddress) ? (
              <Marker
                draggable={true}
                position={[
                  this.state.selectedAddress.lat,
                  this.state.selectedAddress.lng,
                ]}
                ref={this.refmarker}
                eventHandlers={this.markerEventHandlers()}
                icon={
                  new LeafletIcon({
                    iconUrl: markerIconPng,
                    iconSize: [25, 41],
                    iconAnchor: [12, 10],
                  })
                }
              >
                <Popup minWidth={90}>
                  <span>Drag to pinpoint exact location</span>
                </Popup>
              </Marker>
            ) : (
              false
            )}
          </MapContainer>
          <CustomSeparator />
          <ActionButtonContainer>
            <ActionButton
              type="button"
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={this.props.closeModal}
            >
              <Icon icon={faTimes} />
              Cancel
            </ActionButton>
            <ActionButton
              buttonStyle="encourage"
              buttonType="primary"
              disabled={!this.isLocationValid(this.state.selectedAddress)}
              type="button"
              onClick={(): void => {
                this.props.closeModal();
                this.props.onSelectAddress(this.state.selectedAddress);
              }}
              icon={<FontAwesomeIcon icon={faCheck} />}
            >
              Ok
            </ActionButton>
          </ActionButtonContainer>
        </CenterModal>
      </>
    );
  }
}

const CustomSeparator = styled(Separator)`
  margin-top: 1rem;
  margin-right: 1rem;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  margin-right: 0.35rem;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${COLOR.black};
  margin-right: 0.25rem;
`;

const InlineErrorMessage = styled.div`
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const ActionButtonContainer = styled.div`
  margin-top: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

export const ActionButton = styled(StyledButton)`
  margin-left: 0.5rem;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 1rem;
`;

const SubTitleContainer = styled.div`
  display: flex;
  margin-top: 0.3rem;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 0;
`;

const SubTitle = styled.div`
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  geoDatas: state.geoService.geoDatas,
});

export default connect(mapStateToProps)(LocateMapModal);
