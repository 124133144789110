import * as React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import COLOR, { transparent } from 'constants/color';

interface DropdownMenuProps {
  containerStyle?: FlattenSimpleInterpolation;
  children: React.ReactNode;
  label?: string;
  disabled?: boolean;
}

interface DropdownMenuState {
  showDropdown: boolean;
}

class DropdownMenu extends React.Component<DropdownMenuProps, DropdownMenuState> {
  dropdownRef: React.RefObject<HTMLDivElement>;

  constructor(props: DropdownMenuProps) {
    super(props);

    this.state = {
      showDropdown: false,
    };

    this.dropdownRef = React.createRef();
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = (event: MouseEvent): void => {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target as Node)) {
      this.setState({ showDropdown: false });
    }
  };

  render(): React.ReactNode {
    return (
      <Container
        containerStyle={this.props.containerStyle}
        disabled={this.props.disabled}
        ref={this.dropdownRef}
        onClick={(): void => {
          if (!this.props.disabled) {
            this.setState({ showDropdown: true });
          }
        }}
      >
        <DropdownMenuContainer disabled={this.props.disabled}>
          {this.props.label ? <Label>{this.props.label}</Label> : false}
          <FontAwesomeIcon icon={faEllipsisV} />
          {this.state.showDropdown ? (
            <DropdownListContainer>
              <DropdownList>{this.props.children}</DropdownList>
            </DropdownListContainer>
          ) : (
            false
          )}
        </DropdownMenuContainer>
      </Container>
    );
  }
}

const Label = styled.div`
  margin-right: 0.5rem;
`;

const Container = styled.div<{
  containerStyle: FlattenSimpleInterpolation;
  disabled?: boolean;
}>`
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
  cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
`;

const DropdownMenuContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  background-color: ${COLOR.white};
  border: 1px solid ${COLOR.neutral};
  border-radius: 1rem;
  color: ${transparent('black', 0.75)};
  cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  :hover {
    background-color: ${(props): string =>
      props.disabled ? COLOR.white : COLOR.whiteGrey};
  }
  opacity: ${(props): string => (props.disabled ? '0.5' : '1')};
`;

const DropdownListContainer = styled.div`
  border: 1px solid ${transparent('shadow', 0.26)};
  border-radius: 4px;
  background-color: ${COLOR.white};
  position: absolute;
  right: 0;
  top: 2rem;
  width: auto;
  margin-top: 0.5rem;
`;

const DropdownList = styled.div`
  background-color: ${COLOR.white};
  border-radius: 4px;
  margin: 0.2rem 1rem;
  min-width: 13rem;
  z-index: 15;
  max-width: auto;
  text-align: left;
`;

export default DropdownMenu;
