import * as React from 'react';
import styled from 'styled-components';
import { Button, TextField, SysAdminMainContainer } from 'components';
import * as H from 'history';
import RunsheetGenerationClient from 'httpClients/runsheetGenerationClient';

interface RunsheetGenerationNewProps {
  history: H.History;
}

interface RunsheetGenerationNewState {
  form: {
    name: string;
    strategy: 'random' | 'exact-address-match';
  };
  error: string | null;
}

class RunsheetGenerationNew extends React.Component<RunsheetGenerationNewProps, RunsheetGenerationNewState> {
  constructor(props: RunsheetGenerationNewProps) {
    super(props);

    this.state = {
      form: {
        name: '',
        strategy: 'random',
      },
      error: '',
    };
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    this.setState({ error: null });
    try {
      const client = new RunsheetGenerationClient();
      await client.createRunsheetGenerationJob(this.state.form.name, this.state.form.strategy);
      this.props.history.push('/sys/runsheet-generations');
    } catch (e) {
      this.setState({ error: e });
    }
  }

  render(): React.ReactNode {
    const { form, error } = this.state;

    return (
      <SysAdminMainContainer selected="runsheet-generations">
        <Container>
          <h1>Create Runsheet Generation Job</h1>
          {error && (
           <ErrorMessage>{error}</ErrorMessage>
          )}
          <form onSubmit={this.handleSubmit}>
            <>
              <label>Name</label>
              <TextField
                type="text"
                name="name"
                value={form.name}
                onChange={(e): void => this.handleChange('name', e.target.value)}
              />
              <Label>Strategy</Label>
              <DropDown
                name="strategy"
                onChange={(e): void => this.handleChange('strategy', e.target.value)}
                value={form.strategy}
              >
                <option key={1} value='random'>Random</option>
                <option key={2} value='exact-address-match'>Exact Address Match</option>
              </DropDown>
            </>
            <>
              <Button type="submit">Create</Button>
            </>
          </form>
        </Container>
      </SysAdminMainContainer>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 25rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 0.625rem 0;
`;

const DropDown = styled.select`
  width: 20.5rem;
  padding: 0.25rem;
  margin: 0.25rem 0.5rem 1rem 0.5rem;
`;

const Label = styled.label`
  width: 18rem;
  float: left;
  margin-top: 1rem;
`;


export default RunsheetGenerationNew;
