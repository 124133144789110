import * as React from 'react';
import styled from 'styled-components';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faCheck } from '@fortawesome/free-solid-svg-icons';

interface CheckboxProps {
  children?: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
  selected?: boolean;
  isPartialCheck?: boolean;
  disabled?: boolean;
}

const Checkbox = (props: CheckboxProps): JSX.Element => (
  <Container disabled={props.disabled} onClick={props.disabled ? null : props.onClick}>
    <CheckboxContainer disabled={props.disabled} selected={props.selected}>
      {props.selected ? (
        <Icon
          color={props.disabled ? COLOR.grey : COLOR.blue}
          fontSize={'0.5rem'}
          icon={props.isPartialCheck ? faMinus : faCheck}
        />
      ) : (
        false
      )}
    </CheckboxContainer>
    {props.children ? <TextContainer>{props.children}</TextContainer> : false}
  </Container>
);

const Container = styled.div<{ disabled?: boolean }>`
  align-items: center;
  display: flex;
  cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
`;

const CheckboxContainer = styled.div<{ disabled?: boolean; selected?: boolean }>`
  background-color: ${(props) =>
    props.disabled ? COLOR.neutral : props.selected ? props.theme.colors.primary_50 : COLOR.white};
  border: 1px solid
    ${(props) =>
      props.disabled
        ? COLOR.midDarkGrey
        : props.selected
        ? COLOR.blue
        : COLOR.midLightGrey};
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const TextContainer = styled.div`
  margin-left: 0.5rem;
`;

const Icon = styled(FontAwesomeIcon)<{ color: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  font-size: 0.85rem;
`;

export default Checkbox;
