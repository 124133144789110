import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  SysAdminMainContainer,
  Dropdown,
  OrderCard,
  Breadcrumb,
  Typography,
  StyledButton,
  NavigationArrowButton,
} from 'components';
import { connect } from 'react-redux';
import RunsheetClient from 'httpClients/runsheetClient';
import TaskClient from 'httpClients/taskClient';
import { RootState } from 'reduxActions/store';
import { Runsheet } from 'models/runsheet';
import { Task, AddTaskToRunsheetRequest } from 'models/task';
import { Organization } from 'models/organization';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import styled, { css } from 'styled-components';
import { PER_PAGE } from 'constants/paginationMeta';
import configureStore from 'reduxActions/store';
import { receiveTaskAllSuccess } from 'reduxActions/task/taskActions';
import { receiveOrganizations } from 'reduxActions/organization/organizationActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

interface RunsheetAddTaskState {
  error: string | null;
  success: string | null;
  runsheetId: string | null;
  checkedTasks: { [id: string]: Task };
  version_rev: string | null;
  filters: Record<string, string>;
}

interface StateProps {
  runsheet: Runsheet;
  tasks: Task[];
  organizations: Organization[];
}

interface LocationState {
  from: string;
}

type Props = StateProps & RouteComponentProps<{ id: string }, object, LocationState>;

class RunsheetAddTask extends React.Component<Props, RunsheetAddTaskState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      runsheetId: null,
      checkedTasks: null,
      version_rev: null,
      filters: this.getDefaultFilter(),
    };
  }

  componentDidMount(): void {
    const { id } = this.props.match.params;
    this.setState({ runsheetId: id });
    this.fetchOrganizations();
    this.fetchTasks();
    this.fetchRunsheet(id);
  }

  componentDidUpdate(prevProps: Props, prevState: RunsheetAddTaskState): void {
    if (prevState.filters !== this.state.filters) {
      this.fetchTasks();
    }
  }

  fetchTasks = async (): Promise<void> => {
    const queryParams = new URLSearchParams(this.state.filters);

    const client = new TaskClient();
    const response = await client.sysAdminGetNoRunsheetTasks(queryParams);

    const store = configureStore();
    store.dispatch(receiveTaskAllSuccess(response.tasks));
  };

  fetchRunsheet = async (id: string): Promise<void> => {
    const client = new RunsheetClient();
    await client.getRunsheet(id);
  };

  fetchOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(
          new URLSearchParams({ page: pageNum.toString() })
        );
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch (e) {
      //
    }
  };

  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
    order: 'desc',
    is_qa: 'false',
    broadcast_preference: '',
  });

  onFilterChange = (fieldName: string, value: string, isDelete = false): void => {
    this.onClickDeselectAll();
    this.setState((prevState) => {
      const newFilters: Record<string, string> = { ...prevState.filters };

      if (isDelete) {
        delete newFilters[fieldName];
      } else {
        newFilters[fieldName] = value;
      }

      newFilters.page = '1';

      return {
        filters: newFilters,
      };
    });
  };

  checkboxChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const target = e.target;
    const targetTask: Task = this.props.tasks.find((task) => task.id === target.value);

    if (target.checked) {
      this.setState((prevState) => ({
        checkedTasks: {
          ...prevState.checkedTasks,
          [target.value]: targetTask,
        },
      }));
    } else {
      this.setState((prevState) => {
        const prevCheckedTasks = { ...prevState.checkedTasks };
        delete prevCheckedTasks[target.value];
        return { checkedTasks: prevCheckedTasks };
      });
    }
  };

  toggleSelect = (id: string, taskIndex: number): void => {
    this.setState((prevState) => {
      let previousCheckedTasks = prevState.checkedTasks;
      if (previousCheckedTasks && previousCheckedTasks[id]) {
        delete previousCheckedTasks[id];
      } else {
        previousCheckedTasks = {
          ...previousCheckedTasks,
          [id]: this.props.tasks[taskIndex],
        };
      }

      return { checkedTasks: previousCheckedTasks };
    });
  };

  goBack = (): void => {
    const { history } = this.props;
    const { location } = history;
    const { id } = this.props.match.params;

    if (
      location &&
      location.state &&
      location.state.from &&
      location.state.from === `/sys/runsheets/${id}`
    ) {
      history.goBack();
    } else {
      history.push(`/sys/runsheets/${id}`);
    }
  };

  handlePageChangeClick = (page: number): void => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        page: page.toString(),
      },
    }));
  };

  onClickDeselectAll = (): void => {
    this.setState({ checkedTasks: null });
  };

  onClickSelectAll = (): void => {
    const selectedAllTask: { [id: string]: Task } = {};

    this.props.tasks.forEach((task) => {
      selectedAllTask[task.id] = task;
    });

    this.setState((prevState) => ({
      checkedTasks: {
        ...prevState.checkedTasks,
        ...selectedAllTask,
      },
    }));
  };

  handleAddTaskToRunsheet = async (): Promise<void> => {
    const { runsheetId, checkedTasks } = this.state;
    const { runsheet } = this.props;

    this.setState({ error: null, success: null });
    try {
      const client = new RunsheetClient();
      const addTaskRequest: AddTaskToRunsheetRequest = {
        tasks: Object.values(checkedTasks),
        version_rev: runsheet.version_rev,
      };

      const response = await client.addTaskToRunsheet(runsheetId, addTaskRequest);
      // TODO: to display success assignment count
      this.setState({ success: response.message });
      setTimeout(() => {
        this.goBack();
      }, 1000);
    } catch (e) {
      if (e.details) {
        this.setState({ error: JSON.stringify(e.details) });
      } else {
        this.setState({ error: e.message || 'unknown-error' });
      }
    }
  };

  renderFilter = (): React.ReactNode => {
    const organizationList = this.props.organizations.map((org) => ({
      value: org.id,
      name: org.business_name,
    }));
    return (
      <FilterContainer>
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem 0.75rem;
          `}
          label="Sort by"
          options={[
            {
              value: 'desc',
              name: 'Newest First',
            },
            {
              value: 'asc',
              name: 'Oldest First',
            },
          ]}
          onChange={(value: string): void => {
            this.onFilterChange('order', value);
          }}
          value={this.state.filters.order}
        />
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem 0.75rem;
          `}
          label="Organization"
          options={organizationList}
          includeAll
          onChange={(value: string): void => {
            const isDelete = value === '';
            this.onFilterChange('org_id', value, isDelete);
          }}
          value={this.state.filters.org_id || ''}
          withSearch
          searchPlaceholder="Find Organisation..."
          sorted
        />
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem 0.75rem;
          `}
          label="IsQA"
          options={[
            {
              value: '',
              name: 'All',
            },
            {
              value: 'true',
              name: 'QA',
            },
            {
              value: 'false',
              name: 'Not QA',
            },
          ]}
          includeAll
          onChange={(value: string): void => {
            const isDelete = value === '';
            this.onFilterChange('is_qa', value, isDelete);
          }}
          value={this.state.filters.is_qa || ''}
        />
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem 0.75rem;
          `}
          label="BroadcastPreference"
          options={[
            {
              value: '',
              name: 'Show All',
            },
            {
              value: 'all',
              name: 'All Driver-Partner Only',
            },
            {
              value: 'squad',
              name: 'Squad Only',
            },
          ]}
          includeAll
          onChange={(value: string): void => {
            const isDelete = value === '';
            this.onFilterChange('broadcast_preference', value, isDelete);
          }}
          value={this.state.filters.broadcast_preference || ''}
        />
      </FilterContainer>
    );
  };

  renderListItem = (task: Task, index: number): React.ReactNode => (
    <OrderCard
      key={task.id}
      row={index}
      task={task}
      isOps={true}
      onToggleCheckbox={(): void => this.toggleSelect(task.id, index)}
      selected={Object.keys(this.state.checkedTasks ?? [])}
    />
  );

  render(): React.ReactNode {
    const { checkedTasks, error, success, filters } = this.state;
    const runsheetId = this.props.match.params.id;
    const isTasksSelectedEmpty = !checkedTasks || Object.keys(checkedTasks).length === 0;
    const page = parseInt(filters.page);

    const numberOfTasks = this.props.tasks.length;
    const numStart = PER_PAGE * (page - 1) + 1;
    const numEnd = PER_PAGE * (page - 1) + numberOfTasks;

    return (
      <SysAdminMainContainer selected="runsheets">
        <Breadcrumb
          customStyle={{
            marginBottom: 0,
          }}
          items={[
            {
              text: 'Runsheet',
              onClick: (): void => {
                this.props.history.push('/sys/runsheets');
              },
            },
            {
              text: runsheetId.split('-')[0],
              onClick: (): void => {
                this.props.history.push(`/sys/runsheets/${runsheetId}`);
              },
            },
            {
              text: 'Add task',
            },
          ]}
        />
        <PageHeader>
          <Typography as="h1" size="display_sm" color="gray_900" weight="semibold">
            Add Orders to Runsheet
          </Typography>
          <div>
            <ActionButton
              onClick={this.goBack}
              buttonStyle="encourage"
              buttonType="neutral"
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
            >
              Back
            </ActionButton>
          </div>
        </PageHeader>
        <div>
          <Typography
            as="h3"
            color="gray_900"
            size="lg"
            customStyle={{
              margin: '2rem 0 1rem',
            }}
          >
            Order Lists
          </Typography>
          {this.renderFilter()}

          <p>You can select multiple orders</p>
          <p>
            Number of Orders Selected :{' '}
            {checkedTasks ? Object.keys(checkedTasks).length : '0'}
          </p>
          <ControlsContainer>
            <ControlsButton>
              <StyledButton
                buttonStyle="encourage"
                buttonType="neutral"
                onClick={this.onClickDeselectAll}
                disabled={isTasksSelectedEmpty}
              >
                Deselect All Orders
              </StyledButton>
              <StyledButton
                buttonStyle="encourage"
                buttonType="neutral"
                onClick={this.onClickSelectAll}
              >
                Select All Orders in this page
              </StyledButton>
            </ControlsButton>
            <ControlsPagination>
              <p>
                Orders {numberOfTasks > 0 && `${numStart} - ${numEnd}`}
                {numberOfTasks === 0 && 'No order to be added'}
              </p>
              <PaginationWrapper>
                <NavigationArrowButton
                  disabled={page <= 1}
                  onClick={(): void => {
                    if (page > 1) {
                      this.handlePageChangeClick(page - 1);
                    }
                  }}
                  type="left"
                />
                <NavigationArrowButton
                  disabled={numberOfTasks < PER_PAGE}
                  onClick={(): void => {
                    if (numberOfTasks >= PER_PAGE) {
                      this.handlePageChangeClick(page + 1);
                    }
                  }}
                  type="right"
                />
              </PaginationWrapper>
            </ControlsPagination>
          </ControlsContainer>

          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <SuccessMessage>{success}</SuccessMessage>}
          <StyledList>
            {this.props.tasks.length !== 0
              ? this.props.tasks.map((task, index) => this.renderListItem(task, index))
              : 'There is no task data'}
          </StyledList>
          <StyledButton
            buttonStyle="encourage"
            buttonType="primary"
            onClick={this.handleAddTaskToRunsheet}
            disabled={isTasksSelectedEmpty}
            style={{
              marginBottom: '1rem',
            }}
          >
            Add Orders to Runsheet
          </StyledButton>
        </div>
      </SysAdminMainContainer>
    );
  }
}

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 0.625rem 0;
`;

const SuccessMessage = styled.div`
  color: #2ecc71;
  margin: 0.625rem 0;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ActionButton = styled(StyledButton)`
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: -1rem;
  }
`;

const ControlsButton = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
`;

const ControlsPagination = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const PaginationWrapper = styled(ControlsPagination)``;

const mapStateToProps = (state: RootState): StateProps => ({
  tasks: state.task.tasks,
  runsheet: state.runsheet.runsheet,
  organizations: state.organization.organizations,
});

export default connect(mapStateToProps)(RunsheetAddTask);
