import * as React from 'react';
import { SysAdminMainContainer, Breadcrumb, Heading, PageTitle } from 'components';
import * as H from 'history';
import ConfigFileSidebarMenu from './sidebarMenu';

interface ConfigFilesProps {
  history: H.History;
}

class ConfigFiles extends React.Component<ConfigFilesProps> {
  constructor(props: ConfigFilesProps) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      <SysAdminMainContainer selected="configFile">
        <Breadcrumb
          items={[
            { text: 'Configuration', onClick: (): void => { this.props.history.push('/sys/config'); } },
          ]}
        />
        <Heading>
          <PageTitle>Configuration</PageTitle>
        </Heading>
        <ConfigFileSidebarMenu />
      </SysAdminMainContainer >
    );
  }
}

export default ConfigFiles;