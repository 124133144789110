import * as React from 'react';

import {
  PageTitle,
  SysAdminMainContainer,
  Breadcrumb,
  Heading,
  StyledButton,
  Separator,
  Typography,
} from 'components';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import { RouteComponentProps } from 'react-router-dom';
import GeoServiceClient from 'httpClients/geoServiceClient';
import { MarineLocation } from 'models/marineLocation';
import styled from 'styled-components';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

interface MarineLocationPageProps {
  marineLocation: MarineLocation;
}

type Props = RouteComponentProps<{ id: string }> & MarineLocationPageProps;

const SysMarineLocationDetail = (props: Props): React.ReactNode => {
  const { id } = props.match.params;
  const { marineLocation } = props;

  React.useEffect(() => {
    (async function (): Promise<void> {
      const geoServiceClient = new GeoServiceClient();
      await geoServiceClient.getSysMarineLocationDetail(id);
    })();
  }, []);

  if (!marineLocation) {
    return null;
  }

  return (
    <SysAdminMainContainer selected="marine-locations">
      <Breadcrumb
        items={[
          {
            text: 'Marine Locations',
            onClick: () => {
              props.history.push('/sys/marine-locations');
            },
          },
          { text: marineLocation && marineLocation.name_address },
        ]}
      />

      <Heading>
        <PageTitle>Marine Location Detail</PageTitle>
      </Heading>

      <SeparatorWrapper>
        <Separator />
      </SeparatorWrapper>

      <CardMarineLocation>
        <FlexBetween>
          <Typography as="h4" color="gray_700">
            {marineLocation.name_address}
          </Typography>
          <StyledButton
            buttonStyle="encourage"
            buttonType="neutral"
            onClick={(): void => {
              props.history.push(
                `/sys/marine-locations/${props.match.params.id}/details/edit`
              );
            }}
            icon={<FontAwesomeIcon icon={faEdit} style={{ color: COLOR.darkGray }} />}
          >
            Edit
          </StyledButton>
        </FlexBetween>
        <SeparatorWrapper>
          <Separator />
        </SeparatorWrapper>
        <FlexCol gap="1.5rem">
          <Typography as="h5" color="gray_700">
            Location Details
          </Typography>
          <FlexCol gap="0.2rem">
            <Typography as="p" size="sm" color="gray_700" weight="semibold">
              Location Name
            </Typography>
            <Typography as="p" size="md" color="gray_500">
              {marineLocation.name_address}
            </Typography>
          </FlexCol>
          <FlexCol gap="0.2rem">
            <Typography as="p" size="sm" color="gray_700" weight="semibold">
              Latitude, Longitude
            </Typography>
            <Typography as="p" size="md" color="gray_500">
              {marineLocation.lat}, {marineLocation.lng}
            </Typography>
          </FlexCol>
        </FlexCol>
      </CardMarineLocation>
    </SysAdminMainContainer>
  );
};

const SeparatorWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

const CardMarineLocation = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${COLOR.neutral};
  padding: 1rem;
  background-color: ${COLOR.white};
  margin-bottom: 1.5rem;
  width: -webkit-fill-available;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexCol = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(props): string => props.gap || '1rem'};
`;

function mapStateToProps(state: RootState): MarineLocationPageProps {
  return {
    marineLocation: state.geoService.marineLocationDetail,
  };
}

export default connect(mapStateToProps)(SysMarineLocationDetail);
