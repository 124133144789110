import * as React from 'react';
import { connect } from 'react-redux';
import configureStore, { RootState } from 'reduxActions/store';
import {
  Alert,
  Breadcrumb,
  CenterModal,
  Checkbox,
  CounterInputButton,
  Dropdown,
  FileInformationCard,
  InputDimensions,
  Searchbox,
  SelectFileButton,
  StyledButton,
  SysAdminMainContainer,
  TextInput,
  Typography,
} from 'components';
import {
  Address,
  Attachment,
  AttachmentForm,
  Contact,
  Item,
  MerchantBaseForm,
  MerchantDeliveryForm,
  OfficerContact,
  SysAdminTaskEditForm,
  Task,
  TaskClientDetail,
  TaskCreationGroup,
  TimeWindow,
} from 'models/task';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import DatePicker from 'react-datepicker';
//TODO: to put rules in css loader in future
import 'react-datepicker/dist/react-datepicker.css';
import TaskClient from 'httpClients/taskClient';
import * as H from 'history';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { receiveOrganizations } from 'reduxActions/organization/organizationActions';
import BROADCAST_PREFERENCES, { BROADCAST_MINUTES } from 'constants/broadcastPreference';
import { Organization, SquadDriver } from 'models/organization';
import { GeoData, GeoDistanceRequest, SavedGeoData } from 'models/geoService';
import GeneralInfoForm from './partialForm/generalInfoForm';
import { RouteComponentProps } from 'react-router-dom';
import {
  ALLOWED_MIN_PRICE,
  ALLOWED_MIN_SGD,
  DEFAULT_MAX_PRICE,
  DEFAULT_MIN_PRICE,
  DEFAULT_SQUAD_PRICE,
  ONE_SGD,
} from 'constants/priceDetails';
import COLOR, { transparent } from 'constants/color';
import { receiveVesselAllSuccess } from 'reduxActions/vessel/vesselActions';
import {
  faArrowRight,
  faBinoculars,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faCircleNotch,
  faInfoCircle,
  faPlus,
  faSearch,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import GeoServiceClient from 'httpClients/geoServiceClient';
import { RecommendationPayload, RecommendationResponse } from 'models/priceRetrieval';
import PriceRetrievalClient from 'httpClients/priceRetrievalClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment';
import LocateMapModal from '../../order/modal/locateMapModal';
import { formatDuration, formatError } from 'utils/formatter';
import { TASK_STATUS, TaskEditableStatuses } from 'constants/taskStatus';
import VesselClient from 'httpClients/vesselClient';
import AddVesselModal from '../vessels/modal/addVesselModal';
import { Vessel } from 'models/vessel';
import AddressContactModal, { AddressContactData } from './modal/addressContactModal';
import { CargoDetail } from 'models/task/item';
import { CARGO_TYPES } from 'constants/cargo';
import VehicleForm from './partialForm/vehicleForm';
import { DEFAULT_FTL_VEHICLE } from 'constants/serviceType';
import { getServicePricing, isItemExceedingVehicleDimensions } from 'utils/taskHelper';
import NotificationPusherClient from 'httpClients/notificationPusherClient';
import RunsheetClient from 'httpClients/runsheetClient';
import { StatusType } from 'models/task/status';
import { FileData } from 'models/taskImport';
import { computeChecksumMd5 } from 'utils/computeChecksum';
import { ALLOWED_FILE_TYPE } from 'constants/attachmenfFile';
import TaskImportClient from 'httpClients/taskImportClient';
import PickupForm from 'pages/order/cards/pickupForm';
import DeliveryForms from 'pages/order/cards/deliveryForm';
import { ErrorModalText, OrderCard } from 'pages/order/styles';
import { VEHICLE_PREFERENCE_INFO } from 'constants/vehiclePreference';

interface SysAdminTaskEditProps {
  organizations: Organization[];
  organization: Organization;
  squadDrivers: SquadDriver[];
  geoDatas: GeoData[];
  savedGeoDatas: SavedGeoData[];
  vessels: Vessel[];
  task: Task;
}

interface SysAdminEditTaskProps<S = H.LocationState> extends SysAdminTaskEditProps {
  history: H.History<S>;
}

interface LocationState {
  deliveryDate: Date;
  vehiclePreference: string;
  broadcastPreference: string;
  minPrice: number;
  maxPrice: number;
  increment: number;
}

interface FileWithData {
  file: File;
  fileData: FileData;
  fileError?: string;
  prviewUrl?: string;
  action: 'add' | 'remove' | 'view';
  id?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DeliveryForm extends Record<string, any> {
  to_address: Address;
  delivery_note_to_driver: string;
  distance_in_meters: number;
  to_contact: Contact;
  to_time_window: TimeWindow;
  tracking_id?: string;
  invoice_number?: string;
  group_tag: string;
  items: Item[];
  cargo_details?: CargoDetail[];
  attachment_files: FileWithData[];
}

interface OrderDeclaration {
  correctAndComplete: boolean;
}

interface SysAdminTaskEditState {
  baseForm: BaseForm;
  deliveryForms: DeliveryForm[];
  isSaving: boolean;
  isFetchingPrice: boolean;
  isFetchingVessel: boolean;
  isItemsChanged: boolean;
  orderDeclaration: OrderDeclaration;
  error: string | null;
  isSuccessful: boolean;
  showPickupLocationModal: boolean;
  showDeliveryLocationModal: boolean | number;
  showRecommendPriceSuccess: boolean;
  showRecommendPriceInfo: boolean;
  showRecommendPriceError: boolean;
  showSwitchViewModal: boolean;
  showConfirmOrderModal: boolean;
  showRemoveDeliveryDetailsModal: boolean | number;
  showRemoveModal: {
    deliveryFormIndex: number | boolean;
    itemIndex: number;
    actionType: 'cargo_details' | 'items' | '';
  };
  showRemoveSuccessModal: string;
  showPickupAddVesselModal: boolean;
  showDeliveryAddVesselModal: boolean | number;
  showAddressContactModal: boolean | number;
  hasCargoNet: boolean;
  hasOfficerContact: boolean;
  isFetchSavedAddress: boolean;
  updatedFieldNames: string[];
  fromLocationSelected: boolean;
  totalWeight: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface BaseForm extends Record<string, any> {
  org_id: string;
  is_qa: boolean;
  broadcast_preference: string;
  vehicle_preference: string;
  service_type: string;
  from_address: Address;
  from_contact: Contact;
  from_time_window: TimeWindow;
  pickup_note_to_driver: string;
  max_price: number;
  min_price: number;
  increment: number;
  admin_fee: number;
  cargo_net_quantity: number;
  officer_contact: OfficerContact;
  client: TaskClientDetail;
  task_creation_group: TaskCreationGroup;
  version_rev: string;
  price: number;
}

interface RemoveCargoDetailsIconProps {
  color?: string;
  fontSize?: string;
}

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Calendar = styled.div`
  position: inherit;
`;

const RECOMMENDATION_ERROR =
  'Price recommendation is unavailable at the moment. Please try again in a few minutes.';

const TaskNotEditableFields = [
  'general_info_form',
  'from_address.street_address',
  'from_address.unit_number',
  'to_address.street_address',
  'to_address.unit_number',
  'from_time_window.start_time_utc',
  'from_time_window.end_time_utc',
  'to_time_window.start_time_utc',
  'to_time_window.end_time_utc',
  'tracking_id',
  'invoice_number',
  'group_tag',
  'items',
  'has_cargo_net',
  'cargo_net_quantity',
  'has_officer_contact',
  'has_officer_contact.name',
  'has_officer_contact.phone',
  'price',
  'select_vehicle',
  'service_type',
  'cargo_details',
];

function getNotEditableFieldsByStatus(
  status: StatusType,
  broadcastPreference: string
): string[] {
  if (BROADCAST_PREFERENCES.marine !== broadcastPreference) {
    return TaskNotEditableFields;
  }
  // Marine use case
  const marineNotEditableFields = [
    'general_info_form',
    'cargo_net_quantity',
    'has_cargo_net',
    'has_officer_contact',
    'has_officer_contact.name',
    'has_officer_contact.phone',
  ];

  if (
    status === TASK_STATUS.PENDING_BROADCAST ||
    status === TASK_STATUS.BROADCASTING ||
    status === TASK_STATUS.ACCEPTED
  ) {
    return marineNotEditableFields;
  } else if (
    status === TASK_STATUS.PENDING_PICKUP ||
    status === TASK_STATUS.PICKUP_ARRIVING
  ) {
    return [
      ...marineNotEditableFields,
      'service_type',
      'select_vehicle',
      'from_location_type',
      'pickup_location',
      'from_address.street_address',
      'from_address.unit_number',
      'from_time_window.start_time_utc',
      'from_time_window.end_time_utc',
      'from_contact.etb_time_window.start_time_utc',
      'from_contact.etb_time_window.end_time_utc',
      'from_contact.etu_time_window.start_time_utc',
      'from_contact.etu_time_window.end_time_utc',
      'from_contact.berth_no',
      'to_location_type',
      'to_contact.vessel_name',
      'tracking_id',
      'cargo_details',
    ];
  } else if (
    status === TASK_STATUS.PENDING_DELIVERY ||
    status === TASK_STATUS.DELIVERY_ARRIVING
  ) {
    return [
      ...marineNotEditableFields,
      'service_type',
      'select_vehicle',
      'from_location_type',
      'pickup_location',
      'pickup_contact_details',
      'pickup_note_to_driver',
      'from_address.street_address',
      'from_address.unit_number',
      'from_time_window.start_time_utc',
      'from_time_window.end_time_utc',
      'from_contact.etb_time_window.start_time_utc',
      'from_contact.etb_time_window.end_time_utc',
      'from_contact.etu_time_window.start_time_utc',
      'from_contact.etu_time_window.end_time_utc',
      'from_contact.berth_no',
      'to_location_type',
      'to_contact.vessel_name',
      'tracking_id',
      'cargo_details',
    ];
  } else {
    return TaskNotEditableFields;
  }
}

type Props = RouteComponentProps<{ id: string }, object, LocationState> &
  SysAdminEditTaskProps;

class SysAdminTaskEdit extends React.Component<Props, SysAdminTaskEditState> {
  searchPickupTimeout: number | null | NodeJS.Timeout;
  searchDeliveryTimeout: number | null | NodeJS.Timeout;
  searchVesselTimeout: number | null | NodeJS.Timeout;

  componentDidMount(): void {
    const { id } = this.props.match.params;
    this.fetchTask(id);
    this.fetchOrganizations();
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      baseForm: this.getDefaultBaseForm(),
      deliveryForms: [this.getDefaultDeliveryForm()],
      isSaving: false,
      isFetchingPrice: false,
      isFetchingVessel: false,
      isItemsChanged: false,
      orderDeclaration: {
        correctAndComplete: false,
      },
      error: null,
      isSuccessful: false,
      showPickupLocationModal: false,
      showDeliveryLocationModal: false,
      showRecommendPriceSuccess: false,
      showRecommendPriceInfo: false,
      showRecommendPriceError: false,
      showSwitchViewModal: false,
      showConfirmOrderModal: false,
      showRemoveDeliveryDetailsModal: false,
      showRemoveModal: { deliveryFormIndex: false, itemIndex: 0, actionType: '' },
      showRemoveSuccessModal: '',
      showPickupAddVesselModal: false,
      showDeliveryAddVesselModal: false,
      showAddressContactModal: false,
      hasCargoNet: false,
      hasOfficerContact: false,
      isFetchSavedAddress: false,
      updatedFieldNames: [],
      fromLocationSelected: false,
      totalWeight: 0,
    };
  }

  fetchTask = async (id: string): Promise<void> => {
    const client = new TaskClient();
    await client.sysAdminGetTask(id);
  };

  fetchSavedGeoData = async (org_id: string): Promise<void> => {
    const geoClient = new GeoServiceClient();
    await geoClient.getSysSavedAddress(new URLSearchParams({ org_id }));
  };

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<SysAdminTaskEditState>
  ): Promise<void> {
    if (prevProps.task !== this.props.task) {
      this.setState(
        {
          baseForm: this.getDefaultBaseForm(),
          deliveryForms: [this.getDefaultDeliveryForm()],
        },
        () => {
          this.fetchSavedGeoData(this.state.baseForm.org_id);
        }
      );
    }
    if (
      prevProps.squadDrivers !== this.props.squadDrivers ||
      (this.state.baseForm &&
        prevState.baseForm &&
        prevState.baseForm.org_id !== this.state.baseForm.org_id)
    ) {
      this.getDefaultPreferenceAndPrice();
    }

    if (
      this.state.baseForm &&
      this.state.baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine &&
      prevState.totalWeight !== this.state.totalWeight &&
      this.state.baseForm.vehicle_preference in VEHICLE_PREFERENCE_INFO &&
      this.state.totalWeight >
        VEHICLE_PREFERENCE_INFO[this.state.baseForm.vehicle_preference].max_weight
    ) {
      this.setState((prevState) => ({
        ...prevState,
        baseForm: {
          ...prevState.baseForm,
          vehicle_preference: '',
        },
      }));
    }
  }

  fetchOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(
          new URLSearchParams({ page: pageNum.toString() })
        );
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      // sort organization names
      organizations.sort((a, b) => a.business_name.localeCompare(b.business_name));

      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch (e) {
      //
    }
  };

  getDefaultPreferenceAndPrice(): void {
    const { organization: org, location } = this.props;
    let increment = ONE_SGD;
    if (location.state?.increment) {
      increment = location.state?.increment;
    } else {
      if (org?.price_table && org?.price_table.length > 1) {
        increment = org?.price_table[1].price - org?.price_table[0].price;
        if (increment <= ONE_SGD) {
          increment = ONE_SGD;
        } else if (increment <= ONE_SGD * 2) {
          increment = ONE_SGD * 2;
        } else if (increment <= ONE_SGD * 3) {
          increment = ONE_SGD * 3;
        }
      }
    }
    const propsService = location.state?.broadcastPreference;
    const squadsLength = this.props.squadDrivers.length;
    let defaultBroadcastPreference = org?.broadcast_preference || '';
    if (defaultBroadcastPreference === '') {
      defaultBroadcastPreference =
        squadsLength > 0 ? BROADCAST_PREFERENCES.squad : BROADCAST_PREFERENCES.all;
    }
    if (squadsLength > 0 && propsService) {
      defaultBroadcastPreference = propsService;
    }
    let defaultMinPrice = location.state?.minPrice || org?.min_price || DEFAULT_MIN_PRICE;
    let defaultMaxPrice = location.state?.maxPrice || org?.max_price || DEFAULT_MAX_PRICE;
    if (defaultBroadcastPreference === BROADCAST_PREFERENCES.squad) {
      defaultMinPrice = org?.squad_price || DEFAULT_SQUAD_PRICE;
      defaultMaxPrice = defaultMinPrice;
    }

    this.setState((prevState) => ({
      baseForm: {
        ...prevState.baseForm,
        broadcast_preference: defaultBroadcastPreference,
        min_price: defaultMinPrice,
        max_price: defaultMaxPrice,
        increment,
      },
    }));
  }

  getDefaultBaseForm(): BaseForm {
    const task = this.props.task;
    if (task) {
      return {
        org_id: task.org_id,
        is_qa: task.is_qa,
        from_address: task.from_address,
        from_time_window: task.from_time_window,
        from_contact: task.from_contact,
        min_price: task.min_price,
        max_price: task.max_price,
        increment: ONE_SGD,
        admin_fee: task.admin_fee,
        broadcast_preference: task.broadcast_preference,
        vehicle_preference: task.vehicle_preference,
        service_type: task.service_type,
        pickup_note_to_driver: task.pickup_note_to_driver,
        cargo_net_quantity: task.cargo_net_quantity,
        officer_contact: task.officer_contact,
        client: task.client,
        task_creation_group: task.task_creation_group,
        version_rev: task.version_rev,
        price: task.price,
        from_location_type: task.from_location_type || 'land', // handle previous data without location_type
      };
    }
  }

  getDefaultDeliveryForm(): DeliveryForm {
    const task = this.props.task;
    if (task) {
      const attachment_files: FileWithData[] = task.attachments.map(
        (attachment: Attachment) => ({
          file: {
            name: attachment.name,
            size: attachment.size,
            type: '',
          } as File,
          fileData: {
            file_name: attachment.name,
            file_size: attachment.size,
            file_type: '',
            url: attachment.url,
            s3_upload_bucket: '',
            s3_upload_key: '',
            checksum: '',
            checksum_method: '',
          },
          prviewUrl: attachment.url,
          action: 'view',
          id: attachment.id,
        })
      );
      return {
        attachment_files: attachment_files,
        task_creation_group: task.task_creation_group,
        to_location_type: task.to_location_type || 'land', // handle previous data without location_type
        to_address: task.to_address,
        to_time_window: task.to_time_window,
        distance_in_meters: task.distance_in_meters,
        to_contact: task.to_contact,
        tracking_id: task.tracking_id,
        invoice_number: task.invoice_number,
        group_tag: task.group_tag,
        items: task.items,
        delivery_note_to_driver: task.delivery_note_to_driver,
        cargo_details: task.cargo_details ?? [
          {
            id: '',
            name: '',
            quantity: 1,
            quantity_unit: '',
            remarks: '',
            has_hazard_mat: false,
            sku: '',
            volume_unit: '',
            weight_unit: '',
            dimension: {
              length: null,
              width: null,
              height: null,
            },
          },
        ],
        vehicle_preference: task.vehicle_preference,
      };
    }
  }

  setUpdatedFields = (fieldName: string) => {
    let newFieldName = fieldName;
    const isCargoDetails = fieldName.indexOf('[');
    if (isCargoDetails !== -1) {
      newFieldName = fieldName.substring(0, fieldName.indexOf('['));
    }
    const { updatedFieldNames } = this.state;
    if (!updatedFieldNames.includes(newFieldName)) {
      this.setState({ updatedFieldNames: [...updatedFieldNames, newFieldName] });
    }
  };

  isSendEditNotification = (task: Task) => {
    // send notification if there are changes in the specified fields [GOT-2104]
    const { updatedFieldNames } = this.state;
    let sendFields: string[] = [];
    if (task.current_status === TASK_STATUS.ACCEPTED) {
      sendFields = [
        'to_contact.vessel_name',
        'to_contact.lighter_company_name',
        'to_contact.lighter_boat_name',
        'from_street_address',
        'from_contact.etb_time_window.start_time_utc',
        'from_contact.etb_time_window.end_time_utc',
        'from_contact.etu_time_window.start_time_utc',
        'from_contact.etu_time_window.end_time_utc',
        'from_contact.berth_no',
        'from_contact.name',
        'from_contact.phone',
        'from_contact.email',
        'pickup_note_to_driver',
        'to_street_address',
        'to_contact.etb_time_window.start_time_utc',
        'to_contact.etb_time_window.end_time_utc',
        'to_contact.etu_time_window.start_time_utc',
        'to_contact.etu_time_window.end_time_utc',
        'to_contact.berth_no',
        'to_contact.name',
        'to_contact.phone',
        'to_contact.email',
        'to_time_window.start_time_utc',
        'delivery_note_to_driver',
        'cargo_details',
        'max_price',
        'min_price',
        'service_type',
        'from_time_window.start_time_utc',
        'from_time_window.end_time_utc',
        'to_time_window.start_time_utc',
        'to_time_window.end_time_utc',
      ];
    }
    if (
      task.current_status === TASK_STATUS.PENDING_PICKUP ||
      task.current_status === TASK_STATUS.PICKUP_ARRIVING ||
      task.current_status === TASK_STATUS.PENDING_DELIVERY ||
      task.current_status === TASK_STATUS.DELIVERY_ARRIVING
    ) {
      sendFields = [
        'to_street_address',
        'to_contact.etb_time_window.start_time_utc',
        'to_contact.etb_time_window.end_time_utc',
        'to_contact.etu_time_window.start_time_utc',
        'to_contact.etu_time_window.end_time_utc',
        'to_contact.berth_no',
        'to_contact.name',
        'to_contact.phone',
        'to_contact.email',
        'to_time_window.start_time_utc',
        'delivery_note_to_driver',
        'to_time_window.start_time_utc',
        'to_time_window.end_time_utc',
      ];
    }
    let count = 0;
    for (const str of updatedFieldNames) {
      if (sendFields.includes(str)) {
        count++;
      }
    }
    // simplify 'count ? true : false' to '!!count' is because they are equivalent
    return !!count;
  };

  updateBaseForm = (fieldName: string, value: string | boolean | Date): void => {
    this.setUpdatedFields(fieldName);
    if (typeof value === 'string') {
      switch (fieldName) {
        case 'broadcast_preference': {
          const org = this.props.organization;
          const minPrice = org?.min_price || DEFAULT_MIN_PRICE;
          const maxPrice = org?.max_price || DEFAULT_MAX_PRICE;
          let minMaxPrice = {
            min_price: minPrice,
            max_price: maxPrice,
          };
          if (value === BROADCAST_PREFERENCES.squad) {
            minMaxPrice = {
              min_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
              max_price: org?.squad_price || DEFAULT_SQUAD_PRICE,
            };
          } else if (value === BROADCAST_PREFERENCES.marine) {
            minMaxPrice = {
              min_price: 0,
              max_price: 0,
            };
          }
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              [fieldName]: value,
              ...minMaxPrice,
              service_type: 'ftl',
              vehicle_preference: DEFAULT_FTL_VEHICLE,
            },
            showRecommendPriceInfo: false,
            showRecommendPriceSuccess: false,
          }));
          return;
        }
        case 'max_price':
        case 'min_price': {
          const priceValue = Math.floor(parseFloat(value) * 100) * 1000;
          let minMaxPrice = {
            [fieldName]: priceValue,
          };
          if (this.state.baseForm.broadcast_preference === BROADCAST_PREFERENCES.squad) {
            minMaxPrice = {
              [fieldName]: priceValue,
              max_price: priceValue,
            };
          }
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              ...minMaxPrice,
            },
          }));
          return;
        }
        case 'price': {
          // total price can be edited only in marine
          if (this.state.baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
            const priceValue = Math.floor(parseFloat(value) * 100) * 1000;
            this.setState((prevState) => ({
              baseForm: {
                ...prevState.baseForm,
                price: priceValue,
              },
            }));
          }
          return;
        }
        case 'increment': {
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              increment: Math.floor(parseInt(value) * 100) * 1000,
            },
          }));
          return;
        }
        case 'admin_fee': {
          const priceValue = Math.floor(parseFloat(value) * 100) * 1000;
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              [fieldName]: priceValue,
            },
          }));
          return;
        }
        case 'cargo_net_quantity': {
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              cargo_net_quantity: parseInt(value),
            },
          }));
          return;
        }
        case 'service_type': {
          let vehiclePreference = DEFAULT_FTL_VEHICLE;
          if (value === 'ltl') {
            vehiclePreference = 'ltl';
          } else if (value === 'boat') {
            vehiclePreference = 'boat';
          }
          this.setState((prevState) => ({
            baseForm: {
              ...prevState.baseForm,
              increment: 0,
              service_type: value,
              vehicle_preference: vehiclePreference,
            },
          }));
          return;
        }
        case 'from_location_type': {
          let prevBaseForm = this.state.baseForm;
          if (value === this.props.task.from_location_type) {
            prevBaseForm = {
              ...this.state.baseForm,
              from_address: this.getDefaultBaseForm().from_address,
              from_contact: this.getDefaultBaseForm().from_contact,
            };
          } else {
            prevBaseForm = {
              ...this.state.baseForm,
              from_address: {
                building_name: '',
                name_address: '',
                street_address: '',
                city: 'Singapore',
                country: 'Singapore',
                state: 'Singapore',
                zip_code: '',
              },
              from_contact: {},
            };
          }
          this.setState(
            (prevState) => ({
              ...prevState,
              baseForm: {
                ...prevState.baseForm,
                ...prevBaseForm,
                from_location_type: value,
              },
              fromLocationSelected: false,
            }),
            () => {}
          );
          return;
        }
      }
    }
    this.setState((prevState) => {
      if (fieldName.indexOf('.') !== -1) {
        const fields: string[] = fieldName.split('.');
        let fieldValue = value;
        if (fields.length === 3) {
          fieldValue = {
            ...prevState.baseForm[fields[0]][fields[1]],
            [fields[2]]: value || null,
          };
        }
        return {
          baseForm: {
            ...prevState.baseForm,
            [fields[0]]: {
              ...prevState.baseForm[fields[0]],
              [fields[1]]: fieldValue,
            },
          },
        };
      } else {
        return {
          baseForm: {
            ...prevState.baseForm,
            [fieldName]: value,
          },
        };
      }
    });
  };

  updateDeliveryForm = (
    fieldName: string,
    value: string | Date | number | boolean,
    deliveryFormIndex: number
  ): void => {
    this.setUpdatedFields(fieldName);
    let isItemsChanged = false;
    this.setState((prevState) => {
      let shouldRecalculateTotalWeight = false;
      const updatedDeliveryForms = [...prevState.deliveryForms];
      if (fieldName.indexOf('.') !== -1) {
        const fields: string[] = fieldName.split('.');
        if (fields[0].indexOf('[') >= 0) {
          const arrayInfo: string[] = fields[0].split('[');
          const itemIdx = arrayInfo[1].slice(0, -1);
          const newArray = [...prevState.deliveryForms[deliveryFormIndex][arrayInfo[0]]];
          updatedDeliveryForms[deliveryFormIndex] = {
            ...updatedDeliveryForms[deliveryFormIndex],
            [arrayInfo[0]]: newArray,
          };
          if (
            arrayInfo[0] === 'items' &&
            value !== '' &&
            (fields[1] === 'weight' || fields[1] === 'quantity')
          ) {
            shouldRecalculateTotalWeight = true;
          }
          if (
            (arrayInfo[0] === 'items' || arrayInfo[0] === 'cargo_details') &&
            value === '' &&
            (fields[1] === 'weight' || fields[1] === 'volume')
          ) {
            delete newArray[parseInt(itemIdx)][fields[1]];
            if (fields[1] === 'weight') {
              newArray[parseInt(itemIdx)]['weight_unit'] = '';
            }
          } else {
            if (fields.length === 2) {
              newArray[parseInt(itemIdx)][fields[1]] = value;
              if (fields[1] === 'weight' && value !== '') {
                // set to 1 if quantity is not set
                if (!newArray[parseInt(itemIdx)]['quantity']) {
                  newArray[parseInt(itemIdx)]['quantity'] = 1;
                }
                newArray[parseInt(itemIdx)]['weight_unit'] = 'kg';
              }
            } else if (fields.length === 3) {
              newArray[parseInt(itemIdx)][fields[1]] = {
                ...newArray[parseInt(itemIdx)][fields[1]],
                [fields[2]]: value,
              };
            }
          }
          isItemsChanged = true;
        } else if (fields[0] in prevState.deliveryForms[deliveryFormIndex]) {
          let fieldValue = value;
          if (fields.length === 3) {
            fieldValue = {
              ...updatedDeliveryForms[deliveryFormIndex][fields[0]][fields[1]],
              [fields[2]]: value || null,
            };
          }
          updatedDeliveryForms[deliveryFormIndex] = {
            ...updatedDeliveryForms[deliveryFormIndex],
            [fields[0]]: {
              ...updatedDeliveryForms[deliveryFormIndex][fields[0]],
              [fields[1]]: fieldValue,
            },
          };
        }
      } else {
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          [fieldName]: value,
        };
      }
      return {
        isItemsChanged,
        deliveryForms: updatedDeliveryForms,
        totalWeight: shouldRecalculateTotalWeight
          ? this.calculateTotalItemsWeight(updatedDeliveryForms)
          : prevState.totalWeight,
      };
    });
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (this.allowUpdate()) {
      this.setState({ error: null, isSaving: true });
      const { baseForm, deliveryForms } = this.state;
      const { id } = this.props.match.params;

      try {
        const client = new TaskClient();
        await Promise.all(
          deliveryForms.map(async (deliveryForm: DeliveryForm, deliveryIndex: number) => {
            let minPrice = baseForm.min_price;
            let maxPrice = baseForm.max_price;
            if (
              baseForm.service_type === 'ltl' &&
              baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine
            ) {
              const selectedService = getServicePricing(baseForm.service_type, 'ltl');
              const priceValue = Math.floor(selectedService.price * 100) * 1000;
              minPrice = priceValue * this.totalCargoLifts(deliveryIndex);
              maxPrice = priceValue * this.totalCargoLifts(deliveryIndex);
            }
            const form: SysAdminTaskEditForm = {
              org_id: baseForm.org_id,
              is_qa: baseForm.is_qa,
              broadcast_preference: baseForm.broadcast_preference,
              vehicle_preference: baseForm.vehicle_preference,
              service_type: baseForm.service_type,
              from_address: baseForm.from_address,
              from_contact: baseForm.from_contact,
              from_time_window: baseForm.from_time_window,
              pickup_note_to_driver: baseForm.pickup_note_to_driver,
              to_address: deliveryForm.to_address,
              to_contact: deliveryForm.to_contact,
              to_time_window: deliveryForm.to_time_window,
              delivery_note_to_driver: deliveryForm.delivery_note_to_driver,
              min_price: minPrice,
              max_price: maxPrice,
              increment: baseForm.increment,
              admin_fee: baseForm.admin_fee,
              version_rev: baseForm.version_rev,
              currency: 'SGD',
              distance_in_meters: deliveryForm.distance_in_meters,
              client_time_utc: new Date(),
              client_timezone: 'Asia/Singapore',
              items: deliveryForm.items,
              tracking_id: deliveryForm.tracking_id,
              invoice_number: deliveryForm.invoice_number,
              group_tag: deliveryForm.group_tag,
              client: baseForm.client,
              task_creation_group: baseForm.task_creation_group,
            };
            if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
              form.from_contact.vessel_name = baseForm.from_contact.vessel_name;
              form.from_contact.lighter_boat_name =
                baseForm.from_contact.lighter_boat_name;
              form.from_contact.lighter_company_name =
                baseForm.from_contact.lighter_company_name;
              form.cargo_details = deliveryForm.cargo_details;

              if (
                baseForm.from_contact.etb_time_window &&
                baseForm.from_contact.etb_time_window.start_time_utc &&
                baseForm.from_contact.etb_time_window.end_time_utc
              ) {
                form.from_contact.etb_time_window = baseForm.from_contact.etb_time_window;
                form.from_contact.etb_time_window.start_timezone = 'Asia/Singapore';
                form.from_contact.etb_time_window.end_timezone = 'Asia/Singapore';
              } else {
                form.from_contact.etb_time_window = null;
              }

              if (
                baseForm.from_contact.etu_time_window &&
                baseForm.from_contact.etu_time_window.start_time_utc &&
                baseForm.from_contact.etu_time_window.end_time_utc
              ) {
                form.from_contact.etu_time_window = baseForm.from_contact.etu_time_window;
                form.from_contact.etu_time_window.start_timezone = 'Asia/Singapore';
                form.from_contact.etu_time_window.end_timezone = 'Asia/Singapore';
              } else {
                form.from_contact.etu_time_window = null;
              }

              form.to_contact.vessel_name = deliveryForm.to_contact.vessel_name;
              form.to_contact.lighter_boat_name =
                deliveryForm.to_contact.lighter_boat_name;
              form.to_contact.lighter_company_name =
                deliveryForm.to_contact.lighter_company_name;

              if (
                deliveryForm.to_contact.etb_time_window &&
                deliveryForm.to_contact.etb_time_window.start_time_utc &&
                deliveryForm.to_contact.etb_time_window.end_time_utc
              ) {
                form.to_contact.etb_time_window = deliveryForm.to_contact.etb_time_window;
                form.to_contact.etb_time_window.start_timezone = 'Asia/Singapore';
                form.to_contact.etb_time_window.end_timezone = 'Asia/Singapore';
              } else {
                form.to_contact.etb_time_window = null;
              }

              if (
                deliveryForm.to_contact.etu_time_window &&
                deliveryForm.to_contact.etu_time_window.start_time_utc &&
                deliveryForm.to_contact.etu_time_window.end_time_utc
              ) {
                form.to_contact.etu_time_window = deliveryForm.to_contact.etu_time_window;
                form.to_contact.etu_time_window.start_timezone = 'Asia/Singapore';
                form.to_contact.etu_time_window.end_timezone = 'Asia/Singapore';
              } else {
                form.to_contact.etu_time_window = null;
              }

              form.to_contact.berth_no = deliveryForm.to_contact.berth_no;

              form.cargo_net_quantity = baseForm.cargo_net_quantity;
              form.officer_contact = baseForm.officer_contact;
              form.price = baseForm.price;
              form.min_price = baseForm.price;
              form.max_price = baseForm.price;
            } else {
              if (!this.props.task.runsheet) {
                // Handle if user remove the task from runsheet and edit task
                form.price = baseForm.price;
              }
            }
            let totalItemCount = 0;
            for (let i = form.items.length - 1; i >= 0; i--) {
              if (form.items[i].name.trim().length === 0) {
                form.items.splice(i, 1);
              } else {
                // add default value 1 when quantity is not filled or negative
                if (!form.items[i].quantity || form.items[i].quantity <= 0) {
                  form.items[i].quantity = 1;
                }
                if (form.items[i].quantity >= 1) {
                  totalItemCount++;
                }
              }
            }
            form.total_item_count = totalItemCount;

            // archive removed file attachments
            const filesToArchived = deliveryForm.attachment_files.filter(
              (attachment) => attachment.action === 'remove'
            );
            if (filesToArchived.length > 0) {
              form['archived_attachments'] = filesToArchived.map((attachment) => {
                return {
                  attachment_id: attachment.id,
                  associated_task_status: '', // will be use if edit from task.statuses['task_status'].attachments
                };
              });
            }

            // attachment update
            // upload new file attachments
            const filesToUpload = deliveryForm.attachment_files.filter(
              (attachment) => attachment.action === 'add'
            );
            if (
              deliveryForm.attachment_files.length > 0 &&
              baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine &&
              filesToUpload.length > 0
            ) {
              const taskAttachment: AttachmentForm[] = [];
              for (let i = 0; i < filesToUpload.length; i++) {
                if (!filesToUpload[i].fileError) {
                  filesToUpload[i].fileData.url = await this.getPresignedUrl(
                    id,
                    filesToUpload[i].file.name
                  );
                  taskAttachment.push({
                    name: filesToUpload[i].file.name,
                    url: filesToUpload[i].fileData.url,
                  });
                }
              }
              await this.uploadToS3(
                filesToUpload.filter((attachment) => attachment.fileData.url !== '')
              );
              const responseAddAttachment = await client.addAttachment(id, {
                attachments: taskAttachment,
                version_rev: form.version_rev as string,
              });
              if (responseAddAttachment && responseAddAttachment.version_rev) {
                form.version_rev = responseAddAttachment.version_rev;
              }
            }

            const task = await client.sysAdminUpdateTask(id, form);

            // notification update
            if (task) {
              const npClient = new NotificationPusherClient();
              if (task && task.runsheet && task.broadcast_preference === 'marine') {
                let orgId = (task.org_transporter && task.org_transporter.id) || '';
                let sendAsRunsheet = false;
                const runsheetData = {
                  category: 'task-management',
                  last_broadcast_expired_at: '',
                  runsheet_id: task.runsheet.id,
                };
                const taskData = {
                  category: 'task-management',
                  task_id: task.id,
                  task_current_status: task.current_status,
                };
                if (!orgId) {
                  const runsheetClient = new RunsheetClient();
                  const updatedRunsheet = await runsheetClient.getRunsheetData(
                    task.runsheet.id
                  );
                  if (updatedRunsheet) {
                    orgId =
                      updatedRunsheet.org_transporter &&
                      updatedRunsheet.org_transporter.id;
                    sendAsRunsheet = true;
                  }
                }
                if (orgId) {
                  // publish event to transport-partner within the organization
                  npClient.pushEvents({
                    events: [
                      {
                        id: task.id,
                        channel: `gotsurge-orgid-${orgId}`,
                        name: 'task-management',
                        data: {
                          task_id: sendAsRunsheet ? '' : task.id,
                          runsheet_id: sendAsRunsheet ? task.runsheet.id : '',
                        },
                      },
                    ],
                  });
                  // marine check if updated field should send notification
                  if (this.isSendEditNotification(task)) {
                    // send notification to transport-partner within the organiziation
                    npClient.pushNotification({
                      push_messages: [
                        {
                          id: task.id,
                          title: 'Order Edited',
                          body: 'Order has been edited.',
                          data: sendAsRunsheet ? runsheetData : taskData,
                          sound: 'default',
                        },
                      ],
                      application_key: 'gotsurge-mobile-transport',
                      org_id: orgId,
                    });
                  }
                }
              }
              if (task && task.driver && task.driver.id) {
                // publish event to pro-driver and freelance driver
                npClient.pushEvents({
                  events: [
                    {
                      id: task.id,
                      channel: `gotsurge-${task.driver.id}`,
                      name: 'task-management',
                      data: {
                        task_id: task.id,
                      },
                    },
                  ],
                });
                // standard & squad check if updated field should send notification
                if (this.isSendEditNotification(task)) {
                  // sending notification to pro-driver and freelance driver
                  npClient.pushNotification({
                    push_messages: [
                      {
                        id: task.id,
                        user_id: task.driver.id,
                        title: 'Order Edited',
                        body: 'Order has been edited.',
                        data: {
                          category: 'task-management',
                          task_id: task.id,
                        },
                        sound: 'default',
                      },
                    ],
                    application_key: 'gotsurge-mobile-driver',
                  });
                }
              }
            }
          })
        );
        this.setState({ showConfirmOrderModal: false, isSuccessful: true });
      } catch (e) {
        this.setState({
          error: formatError(e),
          isSaving: false,
          showConfirmOrderModal: false,
          isSuccessful: false,
        });
      }
    }
  };

  areCargoDetailsRequiredFieldsEmpty = (deliveryFormIndex: number): boolean => {
    const cargoDetails = this.state.deliveryForms[deliveryFormIndex].cargo_details;

    return cargoDetails?.reduce((acc, detail) => {
      const isNameEmpty = detail.name.trim() === '';
      const isQuantityEmpty = isNaN(detail.quantity) || detail.quantity <= 0;
      return acc || isNameEmpty || isQuantityEmpty;
    }, false);
  };

  totalCargoLifts = (deliveryFormIndex: number): number => {
    const cargoDetails = this.state.deliveryForms[deliveryFormIndex].cargo_details;

    return cargoDetails?.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);
  };

  allowConfirmOrder = (): boolean => {
    const { baseForm, deliveryForms } = this.state;
    const { from_time_window: fromTimeWindow } = baseForm;

    let allowedMinPrice = ALLOWED_MIN_PRICE;
    if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      allowedMinPrice = 0;
    }

    let isPriceIncrementFilled = (baseForm.increment || 0) !== 0;
    if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      isPriceIncrementFilled = true;
    }

    const isBaseFormFulfilled =
      baseForm.min_price >= allowedMinPrice &&
      baseForm.max_price >= baseForm.min_price &&
      baseForm.from_address.street_address.trim().length > 0 &&
      baseForm.vehicle_preference !== '' &&
      this.isRangeValid(fromTimeWindow) &&
      baseForm.price > allowedMinPrice;

    let areDeliveryFormsFulfilled = true;
    for (let i = 0; i < deliveryForms.length; i++) {
      const { to_time_window: toTimeWindow } = deliveryForms[i];
      const checkCargoDetailsRequiredFields =
        baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine
          ? true
          : !this.areCargoDetailsRequiredFieldsEmpty(i);
      const isFormFulfilled =
        deliveryForms[i].to_address.street_address.trim().length > 0 &&
        this.isRangeValid(toTimeWindow) &&
        this.compareDateTime(
          baseForm.from_time_window.start_time_utc,
          deliveryForms[i].to_time_window.start_time_utc
        ) <= 0 &&
        this.compareDateTime(
          baseForm.from_time_window.end_time_utc,
          deliveryForms[i].to_time_window.end_time_utc
        ) <= 0 &&
        checkCargoDetailsRequiredFields;

      if (!isFormFulfilled) {
        areDeliveryFormsFulfilled = false;
        break;
      }
    }

    const isOrganizationSelected = this.state.baseForm.org_id.length !== 0;

    return (
      isBaseFormFulfilled &&
      areDeliveryFormsFulfilled &&
      !this.state.isSaving &&
      isOrganizationSelected &&
      isPriceIncrementFilled
    );
  };

  allowUpdate = (): boolean => {
    return this.state.orderDeclaration.correctAndComplete && this.allowConfirmOrder();
  };

  isFormChanged(): boolean {
    const baseFormChanged =
      JSON.stringify(this.state.baseForm) !== JSON.stringify(this.getDefaultBaseForm());
    const deliveryFormChanged =
      JSON.stringify(this.state.deliveryForms[0]) !==
      JSON.stringify(this.getDefaultDeliveryForm());
    const itemsChanged = this.state.isItemsChanged;

    return baseFormChanged || deliveryFormChanged || itemsChanged;
  }

  isRangeValid = (timeWindow: TimeWindow): boolean => {
    let isRangeValid = true;
    const startDate = moment(timeWindow.start_time_utc);
    const endDate = moment(timeWindow.end_time_utc);
    if (startDate !== endDate) {
      isRangeValid = endDate.isSameOrAfter(startDate);
    }

    return isRangeValid;
  };

  isLocationValid = (geoData: GeoData): boolean => {
    return (
      geoData.street_address !== '' &&
      geoData.lat !== 0 &&
      geoData.lng !== 0 &&
      geoData.lat !== undefined &&
      geoData.lng !== undefined
    );
  };

  isAddressLocationValid = (address: Address): boolean =>
    this.isLocationValid({
      street_address: address.street_address,
      zip_code: address.zip_code,
      lat: address.latitude,
      lng: address.longitude,
    });

  isEditableField = (fieldName: string): boolean => {
    const { task } = this.props;
    let isEditableField = false;
    // all bc preference can be edited up to pending_delivery
    if (task && TaskEditableStatuses.includes(task.current_status)) {
      isEditableField = true;
    }
    // if (task && TaskNotEditableFields.includes(fieldName)) {
    //   isEditableField = false;
    // }
    if (
      task &&
      getNotEditableFieldsByStatus(
        task.current_status as StatusType,
        task.broadcast_preference
      ).includes(fieldName)
    ) {
      isEditableField = false;
    }
    if (task && task.runsheet === null && task.current_status === 'pending_broadcast') {
      isEditableField = true;
    }

    if (
      task.broadcast_preference === BROADCAST_PREFERENCES.marine &&
      fieldName === 'price' &&
      (task.current_status as StatusType) === 'complete'
    ) {
      isEditableField = true;
    }
    return isEditableField;
  };

  recommendPrice = async (): Promise<void> => {
    const { baseForm, deliveryForms } = this.state;
    const isPickupLocationValid = this.isAddressLocationValid(baseForm.from_address);
    let areDeliveryLocationsValid = true;
    for (let i = 0; i < deliveryForms.length; i++) {
      if (!this.isAddressLocationValid(deliveryForms[i].to_address)) {
        areDeliveryLocationsValid = false;
        break;
      }
    }
    if (!isPickupLocationValid || !areDeliveryLocationsValid) {
      this.setState({ showRecommendPriceInfo: true });
      return;
    }

    this.setState({ showRecommendPriceSuccess: false, isFetchingPrice: true });
    const stops = [
      {
        location: {
          lat: baseForm.from_address.latitude,
          lng: baseForm.from_address.longitude,
        },
        distance_in_meters: 0,
        addresses: {
          en_SG: {
            display_string: baseForm.from_address.street_address,
            market: 'SG_SIN',
          },
        },
      },
    ];
    deliveryForms.forEach((deliveryForm) => {
      stops.push({
        location: {
          lat: deliveryForm.to_address.latitude,
          lng: deliveryForm.to_address.longitude,
        },
        distance_in_meters: deliveryForm.distance_in_meters,
        addresses: {
          en_SG: {
            display_string: deliveryForm.to_address.street_address,
            market: 'SG_SIN',
          },
        },
      });
    });
    const payload: RecommendationPayload = {
      service_type: baseForm.vehicle_preference,
      stops: stops,
    };
    try {
      const client = new PriceRetrievalClient();
      const resp: RecommendationResponse = await client.recommendation(payload);
      const averageFee = resp.average_fee;
      const recommendedPrice =
        averageFee < ALLOWED_MIN_PRICE ? ALLOWED_MIN_PRICE : averageFee;

      if (baseForm.broadcast_preference === BROADCAST_PREFERENCES.all) {
        // price range
        const twoSGD = ONE_SGD * 2;
        let minPrice = recommendedPrice - twoSGD;
        let maxPrice = recommendedPrice + twoSGD;

        if (recommendedPrice - twoSGD < twoSGD) {
          // if below 2 SGD price range will be 2 SGD -> 4 SGD
          minPrice = twoSGD;
          maxPrice = twoSGD * 2;
        }
        this.updateBaseForm('min_price', Math.round(minPrice / ONE_SGD).toString());
        this.updateBaseForm('max_price', Math.round(maxPrice / ONE_SGD).toString());
      } else {
        // single price
        this.updateBaseForm(
          'min_price',
          Math.round(recommendedPrice / ONE_SGD).toString()
        );
      }
      this.setState({ showRecommendPriceSuccess: true, isFetchingPrice: false });
    } catch (e) {
      this.setState({ showRecommendPriceError: true });
      setTimeout(() => {
        this.setState({ showRecommendPriceError: false, isFetchingPrice: false });
      }, 5000);
    }
  };

  selectPickupAddress = (streetAddress: string, geoData: GeoData): void => {
    this.setState((prevState) => ({
      baseForm: {
        ...prevState.baseForm,
        from_address: {
          ...prevState.baseForm.from_address,
          street_address: streetAddress,
          building_name: geoData.building_name,
          latitude: geoData.lat,
          longitude: geoData.lng,
          zip_code: geoData.zip_code,
        },
      },
      showRecommendPriceSuccess: false,
      showRecommendPriceInfo: false,
    }));
  };

  getDistance = async (geoData: GeoData): Promise<number> => {
    const { baseForm } = this.state;
    if (
      baseForm.from_address.latitude === undefined ||
      baseForm.from_address.longitude === undefined
    ) {
      // if base address is not valid, set distance to 0
      return 0;
    }
    try {
      const geoClient = new GeoServiceClient();
      const geoDistanceRequest: GeoDistanceRequest = {
        from_address: {
          street_address: baseForm.from_address.street_address,
          zip_code: baseForm.from_address.zip_code,
          lat: baseForm.from_address.latitude,
          lng: baseForm.from_address.longitude,
        },
        to_address: {
          street_address: geoData.street_address,
          zip_code: geoData.zip_code,
          lat: geoData.lat,
          lng: geoData.lng,
        },
      };

      const geoResponse = await geoClient.geoDistance(geoDistanceRequest);
      return geoResponse.distance_in_meters;
    } catch (e) {
      // e
    }
  };

  selectDeliveryAddress = async (
    i: number,
    streetAddress: string,
    geoData: GeoData
  ): Promise<void> => {
    const distance = (await this.getDistance(geoData)) || 0;
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[i] = {
        ...prevState.deliveryForms[i],
        to_address: {
          ...prevState.deliveryForms[i].to_address,
          street_address: streetAddress,
          building_name: geoData.building_name,
          latitude: geoData.lat,
          longitude: geoData.lng,
          zip_code: geoData.zip_code,
        },
        distance_in_meters: distance,
      };
      return {
        deliveryForms: updatedDeliveryForms,
        showRecommendPriceSuccess: false,
        showRecommendPriceInfo: false,
      };
    });
  };

  onSelectPickupAddress = (geoData: GeoData): void => {
    this.selectPickupAddress(geoData.street_address, geoData);
  };

  onSelectDeliveryAddress = (i: number, geoData: GeoData): void => {
    this.selectDeliveryAddress(i, geoData.street_address, geoData);
  };

  fetchVessels = async (searchKey: string): Promise<void> => {
    this.clearVessels();
    if (searchKey && searchKey.length >= 3) {
      this.setState({ isFetchingVessel: true });
      const client = new VesselClient();
      await client.getVessels(
        new URLSearchParams({ term: searchKey.toUpperCase(), is_party_search: 'true' })
      );
      this.setState({ isFetchingVessel: false });
    }
  };

  clearVessels = (): void => {
    const store = configureStore();
    store.dispatch(receiveVesselAllSuccess([]));
  };

  searchDeliveryVessels = (index: number): void => {
    clearTimeout(this.searchVesselTimeout);
    this.searchVesselTimeout = setTimeout(() => {
      this.fetchVessels(this.state.deliveryForms[index].to_contact.vessel_name);
    }, 700);
  };

  selectDeliveryVessels = (index: number, id: string, vessel: Vessel): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      updatedDeliveryForms[index] = {
        ...updatedDeliveryForms[index],
        to_contact: {
          ...updatedDeliveryForms[index].to_contact,
          vessel_id: id,
          vessel_imo: vessel.imo,
          vessel_name: vessel.name,
        },
      };
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  clearDeliveryVessels = (index: number, clearName: boolean): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      const toContact = updatedDeliveryForms[index].to_contact;
      delete toContact.vessel_id;
      delete toContact.vessel_imo;
      if (clearName) {
        toContact.vessel_name = '';
      }
      updatedDeliveryForms[index] = {
        ...updatedDeliveryForms[index],
        to_contact: toContact,
      };
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  compareDateTime = (timeRange1: Date, timeRange2: Date): number => {
    const timeWindow1 = new Date(timeRange1).getTime();
    const timeWindow2 = new Date(timeRange2).getTime();
    if (timeWindow1 < timeWindow2) {
      return -1;
    } else if (timeWindow1 === timeWindow2) {
      return 0;
    }
    return 1;
  };

  renderGeneralInfoForm = (): React.ReactNode => {
    const { baseForm, deliveryForms } = this.state;
    const i = 0;
    const deliveryETBStart =
      (deliveryForms[i].to_contact?.etb_time_window?.start_time_utc &&
        new Date(deliveryForms[i].to_contact?.etb_time_window?.start_time_utc)) ||
      null;
    const deliveryETUStart =
      (deliveryForms[i].to_contact?.etu_time_window?.start_time_utc &&
        new Date(deliveryForms[i].to_contact?.etu_time_window?.start_time_utc)) ||
      null;
    return (
      <GeneralInfoFormContainer>
        <GeneralInfoForm
          form={{
            is_qa: baseForm.is_qa,
            broadcast_preference: baseForm.broadcast_preference,
          }}
          disabled={!this.isEditableField('general_info_form')}
          disableFullEdit={true}
          orgId={baseForm.org_id}
          onFormChange={(fieldName: string, value: string | boolean): void => {
            this.updateBaseForm(fieldName, value);
          }}
          hasNoSquad={this.props.squadDrivers.length === 0}
          organizations={this.props.organizations}
          vesselForm={
            <>
              {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
                <>
                  <Title>Mother Vessel</Title>
                  <VesselCardRow>
                    <Searchbox
                      key={`to_contact_vessel_name${i}`}
                      inputName="to_contact_vessel_name"
                      trailingIcon={
                        deliveryForms[i].to_contact.vessel_name ? faTimes : faSearch
                      }
                      containerStyle={inlineTextInputStyle}
                      fieldName="Vessel Name"
                      disabled={!this.isEditableField('to_contact.vessel_name')}
                      bottomLabel={
                        this.isEditableField('to_contact.vessel_name') &&
                        this.renderDeliveryVesselBottomLabel(i)
                      }
                      trailingIconOnClick={(): void => {
                        if (this.isEditableField('to_contact.vessel_name')) {
                          this.clearDeliveryVessels(i, true);
                          this.searchDeliveryVessels(i);
                        }
                      }}
                      onTextChange={(value: string): void => {
                        this.updateDeliveryForm('to_contact.vessel_name', value, i);
                        this.searchDeliveryVessels(i);
                        this.clearDeliveryVessels(i, false);
                      }}
                      handleSetFocus={(focused: boolean): void => {
                        if (focused) {
                          this.clearVessels();
                          this.searchDeliveryVessels(i);
                        } else {
                          const toContact = deliveryForms[i].to_contact;
                          if (toContact.vessel_name && !toContact.vessel_imo) {
                            this.updateDeliveryForm('to_contact.vessel_name', '', i);
                          }
                        }
                      }}
                      handleSelectChange={(value: string, vessel: Vessel): void => {
                        this.selectDeliveryVessels(i, value, vessel);
                      }}
                      placeholder="Search vessel name or IMO"
                      width="large"
                      value={deliveryForms[i].to_contact.vessel_name}
                      options={(this.props.vessels || []).map((vessel) => ({
                        value: vessel.id,
                        text: vessel.name,
                        additionalText: `IMO ${vessel.imo}`,
                        additionalValue: {
                          id: vessel.id,
                          imo: vessel.imo,
                          name: vessel.name,
                        },
                      }))}
                      emptyPlaceholder={this.renderDeliveryVesselEmptyPlaceholder(i)}
                    />
                    <DateAndTimeContainer alignItems="flex-start">
                      {/* Formerly Estimated Time of Berthing (ETB) */}
                      <DatePickerWrapper
                        marginRight="10px"
                        width="13.5rem"
                        minDate={moment().toDate()}
                        dateFormat="dd MMM, hh:mm a"
                        onChange={(value: Date): void => {
                          this.updateDeliveryForm(
                            'to_contact.etb_time_window.start_time_utc',
                            value,
                            i
                          );
                          this.updateDeliveryForm(
                            'to_contact.etb_time_window.end_time_utc',
                            moment(value).add(1, 'hour').toDate(),
                            i
                          );
                        }}
                        onSelect={(value: Date): void => {
                          this.updateDeliveryForm(
                            'to_contact.etb_time_window.start_time_utc',
                            value,
                            i
                          );
                          this.updateDeliveryForm(
                            'to_contact.etb_time_window.end_time_utc',
                            moment(value).add(1, 'hour').toDate(),
                            i
                          );
                        }}
                        selected={deliveryETBStart}
                        disabled={
                          !this.isEditableField(
                            'to_contact.etb_time_window.start_time_utc'
                          )
                        }
                        showTimeSelect
                        placeholderText="10 Jan, 10:00 AM"
                        customInput={
                          <TextInput
                            autoComplete="none"
                            inputName="name"
                            containerStyle={inlineTextInputStyle}
                            fieldName="Est. Time of Arrival (ETA)"
                            width="full"
                            noWrapLabel
                          />
                        }
                        calendarContainer={Calendar}
                      />
                      {/* Formerly Estimated Time of Unberthing (ETU) */}
                      <DatePickerWrapper
                        width="13.5rem"
                        minDate={moment().toDate()}
                        dateFormat="dd MMM, hh:mm a"
                        onChange={(value: Date): void => {
                          this.updateDeliveryForm(
                            'to_contact.etu_time_window.start_time_utc',
                            value,
                            i
                          );
                          this.updateDeliveryForm(
                            'to_contact.etu_time_window.end_time_utc',
                            moment(value).add(1, 'hour').toDate(),
                            i
                          );
                        }}
                        onSelect={(value: Date): void => {
                          this.updateDeliveryForm(
                            'to_contact.etu_time_window.start_time_utc',
                            value,
                            i
                          );
                          this.updateDeliveryForm(
                            'to_contact.etu_time_window.end_time_utc',
                            moment(value).add(1, 'hour').toDate(),
                            i
                          );
                        }}
                        selected={deliveryETUStart}
                        disabled={
                          !this.isEditableField(
                            'to_contact.etu_time_window.start_time_utc'
                          )
                        }
                        showTimeSelect
                        placeholderText="10 Jan, 11:30 AM"
                        customInput={
                          <TextInput
                            autoComplete="none"
                            inputName="name"
                            containerStyle={inlineTextInputStyle}
                            fieldName="Est. Time of Departure (ETD)"
                            width="full"
                            noWrapLabel
                          />
                        }
                        calendarContainer={Calendar}
                      />
                    </DateAndTimeContainer>
                    {/* <TextInput
                      autoComplete="none"
                      containerStyle={inlineTextInputStyle}
                      fieldName="Lighter Company Name"
                      disabled={!this.isEditableField('to_contact.lighter_company_name')}
                      onTextChange={(value: string): void => {
                        this.updateDeliveryForm(
                          'to_contact.lighter_company_name',
                          value,
                          i
                        );
                      }}
                      placeholder="Aquaholic Pte Ltd"
                      width="medium"
                      value={deliveryForms[i].to_contact.lighter_company_name}
                    />
                    <TextInput
                      autoComplete="none"
                      containerStyle={inlineTextInputStyle}
                      fieldName="Lighter Boat Name"
                      disabled={!this.isEditableField('to_contact.lighter_boat_name')}
                      onTextChange={(value: string): void => {
                        this.updateDeliveryForm('to_contact.lighter_boat_name', value, i);
                      }}
                      placeholder="Seas the Day"
                      width="medium"
                      value={deliveryForms[i].to_contact.lighter_boat_name}
                    /> */}
                  </VesselCardRow>
                </>
              )}
            </>
          }
        />
      </GeneralInfoFormContainer>
    );
  };

  isMotorcycleExceedingDimensions = (): string[] => {
    const { deliveryForms } = this.state;
    const items = deliveryForms.flatMap((deliveryForm) => deliveryForm.items);
    const result = isItemExceedingVehicleDimensions(items, ['motorcycle']);
    return Object.keys(result).filter((key) => result[key as keyof typeof result]);
  };

  getTotalItemsWeight = (): number => {
    return parseFloat(this.state.totalWeight.toFixed(2));
  };

  calculateTotalItemsWeight = (deliveryForms: DeliveryForm[]): number => {
    const items = deliveryForms.flatMap((deliveryForm) => deliveryForm.items);
    return items.reduce((total, item) => {
      const weight = item.weight ? parseFloat(item.weight.toString()) : 0;
      const quantity = item.quantity || 0;
      return total + weight * quantity;
    }, 0);
  };

  renderVehicleForm = (
    broadcastPreference: keyof typeof BROADCAST_PREFERENCES
  ): React.ReactNode => {
    const { baseForm } = this.state;
    return (
      <VehicleForm
        displayHeader={
          broadcastPreference === 'marine' ? (
            <>
              <Typography
                weight="normal"
                color="gray_700"
                as="h4"
                size="sm"
                customStyle={{ marginBottom: '0.2rem', letterSpacing: '0.32px' }}
              >
                Vehicle Requirements <RedText>*</RedText>
              </Typography>
              <Typography weight="normal" color="gray_400" as="p" size="xs">
                Select the most appropriate vehicle to fit your cargo.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                weight="semibold"
                color="gray_700"
                as="h2"
                customStyle={{ marginBottom: '0.2rem', letterSpacing: '0.32px' }}
              >
                Vehicle Requirements <RedText>*</RedText>
              </Typography>
              <Typography weight="normal" color="gray_400" as="p">
                Select the most appropriate vehicle to fit all your items.
              </Typography>
            </>
          )
        }
        form={{
          broadcast_preference:
            baseForm.broadcast_preference as keyof typeof BROADCAST_PREFERENCES,
          vehicle_preference: baseForm.vehicle_preference,
        }}
        onFormChange={(fieldName: string, value: string | boolean): void => {
          this.updateBaseForm(fieldName, value);
        }}
        disabled={!this.isEditableField('select_vehicle')}
        vehiclesExceedingDimensions={this.isMotorcycleExceedingDimensions()}
        enableTotalWeightValidation={
          this.state.baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine
        }
        totalWeight={this.getTotalItemsWeight()}
      />
    );
  };

  renderPriceForm = (): React.ReactNode => {
    const { baseForm } = this.state;
    const priceContent: React.ReactNode =
      baseForm.broadcast_preference === BROADCAST_PREFERENCES.all
        ? this.renderMinMaxPrice()
        : this.renderSinglePrice();

    return (
      <PriceInfoCard>
        <Title>
          Set your price <RedText>*</RedText>
        </Title>
        {priceContent}
        {baseForm.min_price < ALLOWED_MIN_PRICE || this.state.error !== null ? (
          <ErrorAlert status="error">
            {this.state.error !== null
              ? this.state.error
              : `Enter a minimum price from SGD ${ALLOWED_MIN_SGD}.00.`}
          </ErrorAlert>
        ) : (
          false
        )}
        <PriceContent>
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Admin Fee SGD"
              placeholder="1.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('admin_fee', value);
              }}
              type="number"
              width="medium"
              min={0}
              value={baseForm.admin_fee / ONE_SGD || ''}
              step="any"
            />
          </div>
        </PriceContent>
      </PriceInfoCard>
    );
  };

  renderAdditionalService = (): React.ReactNode => {
    const { baseForm, hasCargoNet, hasOfficerContact } = this.state;
    return (
      <AdditionalServiceCard>
        <OrderTitle>Any Additional Service / Requirements?</OrderTitle>
        <Checkbox
          disabled={!this.isEditableField('has_cargo_net')}
          onClick={(): void => {
            this.setState((prevState) => ({
              hasCargoNet: !prevState.hasCargoNet,
            }));
          }}
          selected={hasCargoNet}
        >
          Do you need cargo net?
        </Checkbox>
        {hasCargoNet && (
          <AdditionalServiceContent>
            <TextInput
              disabled={!this.isEditableField('has_cargo_net')}
              containerStyle={inlineTextInputStyle}
              fieldName="No. of Cargo Nets"
              placeholder="4"
              onTextChange={(value: string): void => {
                this.updateBaseForm('cargo_net_quantity', value);
              }}
              type="number"
              width="medium"
              min={1}
              value={baseForm.cargo_net_quantity}
            />
          </AdditionalServiceContent>
        )}
        <Checkbox
          disabled={!this.isEditableField('has_officer_contact')}
          onClick={(): void => {
            this.setState((prevState) => ({
              hasOfficerContact: !prevState.hasOfficerContact,
            }));
          }}
          selected={hasOfficerContact}
        >
          Is there an additional Boarding Officer coming along?
        </Checkbox>
        {hasOfficerContact && (
          <AdditionalServiceContent>
            <TextInput
              disabled={!this.isEditableField('has_officer_contact.name')}
              containerStyle={inlineTextInputStyle}
              fieldName="Name"
              placeholder="Tony"
              onTextChange={(value: string): void => {
                this.updateBaseForm('officer_contact.name', value);
              }}
              type="text"
              width="medium"
              value={baseForm.officer_contact.name}
            />
            <TextInput
              disabled={!this.isEditableField('has_officer_contact.phone')}
              containerStyle={inlineTextInputStyle}
              fieldName="Contact No."
              placeholder="8100 8989"
              onTextChange={(value: string): void => {
                this.updateBaseForm('officer_contact.phone', value);
              }}
              type="text"
              width="medium"
              value={baseForm.officer_contact.phone}
            />
          </AdditionalServiceContent>
        )}
      </AdditionalServiceCard>
    );
  };

  renderLocateMapModal = (): React.ReactNode => {
    const {
      baseForm,
      deliveryForms,
      showPickupLocationModal,
      showDeliveryLocationModal: deliveryModalIdx,
    } = this.state;

    return (
      <>
        {showPickupLocationModal && (
          <LocateMapModal
            defaultAddress={{
              building_name: baseForm.from_address.building_name
                ? baseForm.from_address.building_name
                : '',
              street_address: baseForm.from_address.street_address
                ? baseForm.from_address.street_address
                : '',
              lat: baseForm.from_address.latitude ? baseForm.from_address.latitude : 0,
              lng: baseForm.from_address.longitude ? baseForm.from_address.longitude : 0,
              zip_code: baseForm.from_address.zip_code
                ? baseForm.from_address.zip_code
                : '',
            }}
            closeModal={(): void => {
              this.setState({
                showPickupLocationModal: false,
                showAddressContactModal: true,
              });
            }}
            onSelectAddress={this.onSelectPickupAddress}
          />
        )}
        {typeof deliveryModalIdx === 'number' && (
          <LocateMapModal
            defaultAddress={{
              building_name: deliveryForms[deliveryModalIdx].to_address.building_name
                ? deliveryForms[deliveryModalIdx].to_address.building_name
                : '',
              street_address: deliveryForms[deliveryModalIdx].to_address.street_address
                ? deliveryForms[deliveryModalIdx].to_address.street_address
                : '',
              lat: deliveryForms[deliveryModalIdx].to_address.latitude
                ? deliveryForms[deliveryModalIdx].to_address.latitude
                : 0,
              lng: deliveryForms[deliveryModalIdx].to_address.longitude
                ? deliveryForms[deliveryModalIdx].to_address.longitude
                : 0,
              zip_code: deliveryForms[deliveryModalIdx].to_address.zip_code
                ? deliveryForms[deliveryModalIdx].to_address.zip_code
                : '',
            }}
            closeModal={(): void => {
              this.setState({ showDeliveryLocationModal: false });
            }}
            onSelectAddress={(geoData: GeoData): void => {
              this.onSelectDeliveryAddress(deliveryModalIdx, geoData);
            }}
          />
        )}
      </>
    );
  };

  getPopupRemovalPreference = () => {
    return {
      items: {
        title: 'Remove item details?',
        content:
          'Are you sure you want to remove item details? This action cannot be undone.',
        action: this.removeItem,
      },
      cargo_details: {
        title: 'Remove cargo details?',
        content:
          'Are you sure you want to remove cargo details? This action cannot be undone.',
        action: this.removeCargoDetails,
      },
    };
  };

  removeItem = () => {
    const { deliveryFormIndex, itemIndex, actionType } = this.state.showRemoveModal;
    if (typeof deliveryFormIndex === 'number' && actionType === 'items') {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        const updatedItems = [...prevState.deliveryForms[deliveryFormIndex].items];
        if (
          itemIndex === 0 &&
          this.state.deliveryForms[deliveryFormIndex].items.length === 1
        ) {
          updatedItems[itemIndex] = {
            name: '',
            quantity: undefined,
            quantity_unit: '',
            sku: '',
            volume: null,
            volume_unit: '',
            weight: null,
            weight_unit: '',
            has_hazard_mat: false,
            dimension: {
              length: null,
              width: null,
              height: null,
            },
          };
        } else {
          updatedItems.splice(itemIndex, 1);
        }
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          items: updatedItems,
        };
        return {
          deliveryForms: updatedDeliveryForms,
          showRemoveModal: {
            deliveryFormIndex: false,
            itemIndex: 0,
            actionType: '',
          },
          showRemoveSuccessModal: 'Item details removed',
        };
      });
    }
  };

  renderRemoveCargoDetailsOrItemsModal = (): React.ReactNode => {
    const { actionType } = this.state.showRemoveModal;
    if (actionType === '') {
      return null;
    }
    const popupPreference = this.getPopupRemovalPreference();
    return (
      <CenterModal
        title={<RemoveCargoDetailsIcon icon={faTrash} color={COLOR.red} />}
        leftButtonText="Cancel"
        leftButtonOnClick={(): void => {
          this.setState({
            showRemoveModal: {
              deliveryFormIndex: false,
              itemIndex: 0,
              actionType: '',
            },
          });
        }}
        rightButtonOnClick={(): void => popupPreference[actionType].action()}
        rightButtonText="Remove"
        rightButtonStyle="discourage"
        rightButtonType="primary"
        width="small"
        position="bottom"
      >
        <RemoveCargoDetailsSubTitle>
          {popupPreference[actionType].title}
        </RemoveCargoDetailsSubTitle>
        <Typography as="div" color="gray_600" size="sm">
          {popupPreference[actionType].content}
        </Typography>
      </CenterModal>
    );
  };

  renderRemoveSuccessModal = (): React.ReactNode => (
    <>
      {this.state.showRemoveSuccessModal ? (
        <CenterModal
          title={
            <RemoveCargoDetailsIcon
              icon={faCheckCircle}
              color={COLOR.cyan}
              fontSize="1.3"
            />
          }
          fullBottomButtonText="OK"
          fullBottomButtonOnClick={(): void => {
            this.setState({
              showRemoveSuccessModal: '',
            });
          }}
          width="small"
          position="bottom"
        >
          <RemoveCargoDetailsSubTitle>
            {this.state.showRemoveSuccessModal}
          </RemoveCargoDetailsSubTitle>
        </CenterModal>
      ) : (
        false
      )}
    </>
  );

  renderRemoveDeliveryDetailsWarningModal = (): React.ReactNode => (
    <CenterModal
      title="Remove Delivery Details?"
      leftButtonText="Back"
      leftButtonOnClick={(): void => {
        this.setState({ showRemoveDeliveryDetailsModal: false });
      }}
      rightButtonOnClick={(): void => {
        this.setState((prevState) => {
          const updatedDeliveryForms = [...prevState.deliveryForms];
          if (typeof this.state.showRemoveDeliveryDetailsModal === 'number') {
            updatedDeliveryForms.splice(this.state.showRemoveDeliveryDetailsModal, 1);
          }
          return {
            deliveryForms: updatedDeliveryForms,
            showRemoveDeliveryDetailsModal: false,
          };
        });
      }}
      rightButtonText="Remove"
    >
      The delivery details will be lost.
    </CenterModal>
  );

  renderAddressContactModal = (): React.ReactNode => {
    const { showAddressContactModal: index } = this.state;
    return (
      <>
        {this.state.showAddressContactModal ? (
          <AddressContactModal
            title="Collect From"
            type="ops"
            defaultForm={{
              address: this.state.baseForm.from_address,
              contact: this.state.baseForm.from_contact,
            }}
            closeModal={(): void => this.setState({ showAddressContactModal: false })}
            onConfirm={(data: AddressContactData): void => {
              this.setUpdatedFields('from_street_address');
              this.setState((prevState) => ({
                baseForm: {
                  ...prevState.baseForm,
                  from_address: data.address,
                  from_contact: data.contact,
                },
              }));
            }}
          />
        ) : (
          false
        )}
        {typeof index === 'number' && (
          <AddressContactModal
            title="Send To"
            type="ops"
            defaultForm={{
              address: this.state.deliveryForms[index].to_address,
              contact: this.state.deliveryForms[index].to_contact,
            }}
            closeModal={(): void => this.setState({ showAddressContactModal: false })}
            onConfirm={(data: AddressContactData): void => {
              this.setUpdatedFields('to_street_address');
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[index] = {
                  ...updatedDeliveryForms[index],
                  to_address: data.address,
                  to_contact: data.contact,
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
          />
        )}
      </>
    );
  };

  renderDeliveryVesselBottomLabel = (index: number): React.ReactNode => {
    const { to_contact } = this.state.deliveryForms[index];

    return (
      <>
        {to_contact.vessel_imo ? (
          <>
            <ImoText>{`IMO ${to_contact.vessel_imo}`}</ImoText>
            <ImoInfoAlert status="info">
              Important: Please ensure that this information is accurate.
            </ImoInfoAlert>
          </>
        ) : (
          false
        )}
        <EnterManualButton
          onClick={(): void => {
            this.setState({ showDeliveryAddVesselModal: index });
          }}
        >
          Enter manually
        </EnterManualButton>
      </>
    );
  };

  chooseSavedAddress = async (
    type: 'pickup' | 'delivery',
    index: number,
    data: SavedGeoData
  ): Promise<void> => {
    if (type === 'pickup') {
      this.setState((prevState) => ({
        baseForm: {
          ...prevState.baseForm,
          from_address: {
            ...prevState.baseForm.from_address,
            name_address: data.name_address,
            building_name: data.building_name,
            street_address: data.street_address,
            latitude: data.lat || 0,
            longitude: data.lng || 0,
            zip_code: data.zip_code || '',
            unit_number: data.unit_number,
          },
          from_contact: {
            ...prevState.baseForm.to_contact,
            name: data.contact_name,
            phone: data.contact_phone,
            email: data.contact_email,
          },
        },
      }));
    } else {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        updatedDeliveryForms[index] = {
          ...updatedDeliveryForms[index],
          to_address: {
            ...updatedDeliveryForms[index].to_address,
            name_address: data.name_address,
            building_name: data.building_name,
            street_address: data.street_address,
            latitude: data.lat || 0,
            longitude: data.lng || 0,
            zip_code: data.zip_code || '',
            unit_number: data.unit_number,
          },
          to_contact: {
            ...updatedDeliveryForms[index].to_contact,
            name: data.contact_name,
            phone: data.contact_phone,
            email: data.contact_email,
          },
        };
        return {
          deliveryForms: updatedDeliveryForms,
        };
      });
    }
  };

  baseFormData = (): MerchantBaseForm => {
    const { baseForm } = this.state;
    return {
      ...baseForm,
      broadcast_preference: baseForm.broadcast_preference,
      vehicle_preference: baseForm.vehicle_preference,
      from_address: baseForm.from_address,
      from_contact: baseForm.from_contact,
      from_time_window: baseForm.from_time_window,
      pickup_note_to_driver: baseForm.pickup_note_to_driver,
      max_price: baseForm.max_price,
      min_price: baseForm.min_price,
      increment: baseForm.increment,
      cargo_net_quantity: baseForm.cargo_net_quantity,
      officer_contact: baseForm.officer_contact,
      from_location_type: baseForm.from_location_type,
    };
  };

  deliveryFormData = (i: number): MerchantDeliveryForm => {
    const { deliveryForms } = this.state;
    const deliveryForm = deliveryForms[i];
    return {
      ...deliveryForm,
      to_address: deliveryForm.to_address,
      delivery_note_to_driver: deliveryForm.delivery_note_to_driver,
      distance_in_meters: deliveryForm.distance_in_meters,
      to_contact: deliveryForm.to_contact,
      to_time_window: deliveryForm.to_time_window,
      tracking_id: deliveryForm.tracking_id,
      items: deliveryForm.items.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        has_hazard_mat: item.has_hazard_mat,
        weight: item.weight,
        weight_unit: item.weight_unit,
        dimension: {
          length: item.dimension.length,
          width: item.dimension.width,
          height: item.dimension.height,
        },
      })),
      attachment_files: deliveryForm.attachment_files,
      cargo_details: deliveryForm.cargo_details,
      task_creation_group: deliveryForm.task_creation_group,
      to_location_type: deliveryForm.to_location_type,
      vehicle_preference: deliveryForm.vehicle_preference,
    };
  };

  renderOrderForm = (): React.ReactNode => {
    const baseFormData = this.baseFormData();
    return (
      <OrderCard>
        <PickupForm
          ops
          isEdit
          baseForm={baseFormData}
          chooseSavedAddress={this.chooseSavedAddress}
          savedGeoDatas={this.props.savedGeoDatas}
          updateBaseForm={this.updateBaseForm}
          onSelectAddress={(data: AddressContactData): void => {
            this.setState((prevState) => ({
              ...prevState,
              baseForm: {
                ...prevState.baseForm,
                from_address: data.address,
                from_contact: data.contact,
              },
              fromLocationSelected: true,
            }));
          }}
          isEditableField={this.isEditableField}
        />
        {this.state.deliveryForms.map((_, i) => (
          <DeliveryForms
            ops
            isEdit
            key={i}
            i={i}
            baseForm={baseFormData}
            deliveryForm={this.deliveryFormData(i)}
            updateBaseForm={this.updateBaseForm}
            updateDeliveryForm={this.updateDeliveryForm}
            deliveryFormsLength={this.state.deliveryForms.length}
            organization={this.props.organization}
            geoDatas={this.props.geoDatas}
            savedGeoDatas={this.props.savedGeoDatas}
            addItemClick={(): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[i] = {
                  ...updatedDeliveryForms[i],
                  items: updatedDeliveryForms[i].items.concat({
                    name: '',
                    quantity: undefined,
                    has_hazard_mat: false,
                    dimension: {
                      length: null,
                      width: null,
                      height: null,
                    },
                  }),
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
            removeItemClick={(j: number): void => {
              this.setState({
                showRemoveModal: {
                  deliveryFormIndex: i,
                  itemIndex: j,
                  actionType: 'items',
                },
              });
            }}
            chooseSavedAddress={(
              type: 'pickup' | 'delivery',
              i: number,
              v: SavedGeoData
            ) => this.chooseSavedAddress(type, i, v)}
            marineVehicleFormChange={(
              fieldName: string,
              value: string | boolean
            ): void => {
              // handle pricing based on vehicle
              if (typeof value === 'string') {
                const serviceType = value === 'boat' ? 'boat' : 'truck';
                const servicePricing = getServicePricing(serviceType, value);
                const priceValue =
                  Math.floor(parseFloat(servicePricing.price.toString()) * 100) * 1000;
                this.setState((prevState) => {
                  const updatedDeliveryForms = [...prevState.deliveryForms];
                  updatedDeliveryForms[i] = {
                    ...updatedDeliveryForms[i],
                    [fieldName]: value,
                    min_price: priceValue,
                    max_price: priceValue,
                    service_type: serviceType,
                  };
                  return {
                    deliveryForms: updatedDeliveryForms,
                    baseForm: { ...prevState.baseForm, vehicle_preference: value },
                  };
                });
              }
            }}
            onSelectAddress={(data: AddressContactData): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[i] = {
                  ...updatedDeliveryForms[i],
                  to_address: data.address,
                  to_contact: data.contact,
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                  // toLocationSelected: [...this.state.toLocationSelected, i],
                };
              });
            }}
            onRemoveDeliveryDetails={(): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms.splice(i, 1);
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
            isEditableField={this.isEditableField}
          />
        ))}
      </OrderCard>
    );
  };

  renderServiceAndVehicleSelect = (): React.ReactNode => {
    return (
      <AdditionalServiceCard>
        {this.renderVehicleForm(
          this.state.baseForm.broadcast_preference as keyof typeof BROADCAST_PREFERENCES
        )}
      </AdditionalServiceCard>
    );
  };

  renderCargoDetails = (i: number = 0): React.ReactNode => {
    const disableCargoDetails = !this.isEditableField('cargo_details');
    return (
      <div id="cargo-details">
        <ItemTitle>
          Cargo Details
          <ItemDescription>
            Add cargo information and the amount of each cargo.
          </ItemDescription>
        </ItemTitle>

        {this.state.deliveryForms[i].cargo_details?.map((item, k) => {
          const cargoDescription =
            this.state.deliveryForms[i].cargo_details[k].name || '';
          const isMinQty = this.state.deliveryForms[i].cargo_details[k].quantity <= 1;
          const canRemoveCargoByQty = isMinQty && cargoDescription !== '';
          const canRemoveCargo =
            (cargoDescription !== '' || k !== 0) && !disableCargoDetails;
          return (
            <span key={k}>
              <ItemDetailsContainer>
                <OrderNumber>{k + 1}.</OrderNumber>

                <DescriptionWrapper>
                  <ItemFieldName>
                    Description <RedText>*</RedText>
                  </ItemFieldName>

                  <Dropdown
                    width="full"
                    height="large"
                    label="Select Cargo"
                    isItemTitleBold={true}
                    isShowingModalListOnMobile={true}
                    withoutDefinedLabel={true}
                    options={CARGO_TYPES}
                    onChange={(value: string): void => {
                      this.updateDeliveryForm(`cargo_details[${k}].name`, value, i);
                    }}
                    value={this.state.deliveryForms[i].cargo_details[k].name}
                    disabled={disableCargoDetails}
                  />

                  {this.state.deliveryForms[i].cargo_details[k].name === 'others' && (
                    <>
                      <TextInput
                        containerStyle={css`
                          margin-top: 8px;
                        `}
                        width="full"
                        height="large"
                        autoComplete="none"
                        onTextChange={(value: string): void => {
                          this.updateDeliveryForm(
                            `cargo_details[${k}].remarks`,
                            value,
                            i
                          );
                        }}
                        type="text"
                        placeholder="Enter cargo type"
                        value={item.remarks || ''}
                        disabled={disableCargoDetails}
                      />
                    </>
                  )}
                </DescriptionWrapper>

                <QuantityWrapper>
                  <ItemFieldName>
                    Qty <RedText>*</RedText>
                  </ItemFieldName>

                  <CounterInputButton
                    value={item.quantity || 1}
                    incrementValue={1}
                    decrementValue={1}
                    defaultEmptyValue={1}
                    onValueChange={(val): void => {
                      if (canRemoveCargoByQty && val !== '' && Number(val) < 1) {
                        this.setState({
                          showRemoveModal: {
                            deliveryFormIndex: i,
                            itemIndex: k,
                            actionType: 'cargo_details',
                          },
                        });
                      } else {
                        this.updateDeliveryForm(`cargo_details[${k}].quantity`, val, i);
                      }
                    }}
                    isDiscourageMinusButton={canRemoveCargoByQty && !disableCargoDetails}
                    isMinusButtonDisabled={isMinQty && !canRemoveCargoByQty}
                    isAllFieldsDisabled={disableCargoDetails}
                  />
                </QuantityWrapper>

                <QuantityUnitWrapper>
                  <ItemFieldName>Unit</ItemFieldName>
                  <TextInput
                    containerStyle={css`
                      margin-top: 8px;
                    `}
                    width="full"
                    height="large"
                    autoComplete="none"
                    placeholder="Pallet"
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm(
                        `cargo_details[${k}].quantity_unit`,
                        value,
                        i
                      );
                    }}
                    type="text"
                    value={item.quantity_unit || ''}
                    disabled={disableCargoDetails}
                  />
                </QuantityUnitWrapper>

                <SkuWrapper>
                  <ItemFieldName>SKU</ItemFieldName>
                  <TextInput
                    containerStyle={css`
                      margin-top: 8px;
                    `}
                    width="full"
                    height="large"
                    autoComplete="none"
                    placeholder="A123456ABC"
                    onTextChange={(value: string): void => {
                      this.updateDeliveryForm(`cargo_details[${k}].sku`, value, i);
                    }}
                    type="text"
                    value={item.sku || ''}
                    disabled={disableCargoDetails}
                  />
                </SkuWrapper>

                <WeightWrapper>
                  <ItemFieldName>Weight</ItemFieldName>
                  <CounterInputButton
                    value={item.weight}
                    incrementValue={1}
                    decrementValue={1}
                    isZeroNumberAllowed={true}
                    isFloatNumberAllowed={true}
                    onValueChange={(value: string): void => {
                      this.updateDeliveryForm(`cargo_details[${k}].weight`, value, i);
                    }}
                    isMinusButtonDisabled={
                      this.state.deliveryForms[i].cargo_details[k].weight <= 1
                    }
                    isAllFieldsDisabled={disableCargoDetails}
                  />
                </WeightWrapper>

                <WeightUnitWrapper>
                  <ItemFieldName>Unit</ItemFieldName>
                  <TextInput
                    containerStyle={css`
                      margin-top: 8px;
                    `}
                    placeholder="kg"
                    width="full"
                    height="large"
                    autoComplete="none"
                    type="text"
                    value={item.weight_unit || ''}
                    disabled={disableCargoDetails}
                  />
                </WeightUnitWrapper>

                <DimensionWrapper>
                  <InputDimensions
                    inputLabel={
                      <ItemFieldName>Dimensions (L x W x H) per item (cm)</ItemFieldName>
                    }
                    isFloatNumberAllowed={true}
                    lengthValue={item.dimension.length}
                    widthValue={item.dimension.width}
                    heightValue={item.dimension.height}
                    onLengthChange={(value) =>
                      this.updateDeliveryForm(
                        `cargo_details[${k}].dimension.length`,
                        value,
                        i
                      )
                    }
                    onWidthChange={(value) =>
                      this.updateDeliveryForm(
                        `cargo_details[${k}].dimension.width`,
                        value,
                        i
                      )
                    }
                    onHeightChange={(value) =>
                      this.updateDeliveryForm(
                        `cargo_details[${k}].dimension.height`,
                        value,
                        i
                      )
                    }
                    disabled={disableCargoDetails}
                  />
                </DimensionWrapper>

                <CargoHazardousMaterial>
                  <CheckBoxWrapper index={k}>
                    <Checkbox
                      onClick={(): void => {
                        this.updateDeliveryForm(
                          `cargo_details[${k}].has_hazard_mat`,
                          !this.state.deliveryForms[i].cargo_details[k].has_hazard_mat,
                          i
                        );
                      }}
                      selected={
                        this.state.deliveryForms[i].cargo_details[k].has_hazard_mat
                      }
                      disabled={disableCargoDetails}
                    >
                      <span style={{ fontSize: '14px' }}>Hazardous Material</span>
                    </Checkbox>
                  </CheckBoxWrapper>
                </CargoHazardousMaterial>

                {canRemoveCargo ? (
                  <RemoveItemWrapper>
                    <RemoveItemButton
                      size="xs"
                      buttonType="link"
                      buttonStyle="discourage"
                      fontWeight={400}
                      icon={<TrashIcon icon={faTrash} />}
                      onClick={(): void => {
                        this.setState({
                          showRemoveModal: {
                            deliveryFormIndex: i,
                            itemIndex: k,
                            actionType: 'cargo_details',
                          },
                        });
                      }}
                    >
                      Remove
                    </RemoveItemButton>
                  </RemoveItemWrapper>
                ) : (
                  false
                )}
              </ItemDetailsContainer>
            </span>
          );
        })}
        {!disableCargoDetails && (
          <Button
            buttonType="neutral"
            buttonStyle="encourage"
            disabled={this.areCargoDetailsRequiredFieldsEmpty(i)}
            onClick={(): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[i] = {
                  ...updatedDeliveryForms[i],
                  cargo_details: updatedDeliveryForms[i].cargo_details.concat({
                    id: '',
                    name: '',
                    quantity: 1,
                    quantity_unit: '',
                    remarks: '',
                    has_hazard_mat: false,
                    sku: '',
                    volume_unit: '',
                    weight_unit: '',
                    dimension: {
                      length: null,
                      width: null,
                      height: null,
                    },
                  }),
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
            icon={<FontAwesomeIcon icon={faPlus} />}
          >
            Add Item
          </Button>
        )}
        <TotalCargoContainer>
          <TotalCargo>Total Cargo</TotalCargo>
          <Lifts>
            {this.totalCargoLifts(i)} Lifts <LiftInfoIcon icon={faInfoCircle} />
          </Lifts>
        </TotalCargoContainer>
      </div>
    );
  };

  removeCargoDetails = (): void => {
    const { deliveryFormIndex, itemIndex, actionType } = this.state.showRemoveModal;
    if (typeof deliveryFormIndex === 'number' && actionType === 'cargo_details') {
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        const updatedItems = [
          ...prevState.deliveryForms[deliveryFormIndex].cargo_details,
        ];
        if (
          itemIndex === 0 &&
          this.state.deliveryForms[deliveryFormIndex].cargo_details.length === 1
        ) {
          updatedItems[itemIndex] = {
            id: '',
            name: '',
            quantity: 1,
            quantity_unit: '',
            remarks: '',
            has_hazard_mat: false,
            sku: '',
            volume_unit: '',
            weight_unit: '',
            dimension: {
              length: null,
              width: null,
              height: null,
            },
          };
        } else {
          updatedItems.splice(itemIndex, 1);
        }
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          cargo_details: updatedItems,
        };
        return {
          deliveryForms: updatedDeliveryForms,
          showRemoveModal: {
            deliveryFormIndex: false,
            itemIndex: 0,
            actionType: '',
          },
          showRemoveSuccessModal: 'Cargo details removed',
        };
      });
    }
  };

  renderDeliveryVesselEmptyPlaceholder = (index: number): React.ReactNode => {
    const { deliveryForms, showDeliveryAddVesselModal } = this.state;
    return (
      <>
        {deliveryForms[index].to_contact &&
        deliveryForms[index].to_contact.vessel_name &&
        deliveryForms[index].to_contact.vessel_name.length >= 3 &&
        !showDeliveryAddVesselModal ? (
          <EmptyVesselPlaceholder>
            {this.state.isFetchingVessel ? (
              <div>Loading...</div>
            ) : (
              <>
                <div>Vessel name not found.</div>
                <EnterManualEmptyPlaceholderButton
                  onClick={(e: React.MouseEvent): void => {
                    e.stopPropagation();
                    this.setState({ showDeliveryAddVesselModal: index });
                  }}
                >
                  Enter manually
                </EnterManualEmptyPlaceholderButton>
              </>
            )}
          </EmptyVesselPlaceholder>
        ) : (
          false
        )}
      </>
    );
  };

  renderAddVesselModal = (): React.ReactNode => {
    const { showDeliveryAddVesselModal: index } = this.state;
    return (
      <>
        {this.state.showPickupAddVesselModal ? (
          <AddVesselModal
            closeModal={(): void => {
              this.setState({ showPickupAddVesselModal: false });
            }}
            addVesselSuccess={(vessel: Vessel): void => {
              this.setState((prevState) => ({
                baseForm: {
                  ...prevState.baseForm,
                  from_contact: {
                    ...prevState.baseForm.from_contact,
                    vessel_name: vessel.name.toUpperCase(),
                    vessel_imo: vessel.imo,
                  },
                },
              }));
            }}
          />
        ) : (
          false
        )}
        {typeof index === 'number' && (
          <AddVesselModal
            closeModal={(): void => {
              this.setState({ showDeliveryAddVesselModal: false });
            }}
            addVesselSuccess={(vessel: Vessel): void => {
              this.setState((prevState) => {
                const updatedDeliveryForms = [...prevState.deliveryForms];
                updatedDeliveryForms[index] = {
                  ...updatedDeliveryForms[index],
                  to_contact: {
                    ...updatedDeliveryForms[index].to_contact,
                    vessel_id: vessel.id,
                    vessel_imo: vessel.imo,
                    vessel_name: vessel.name.toUpperCase(),
                  },
                };
                return {
                  deliveryForms: updatedDeliveryForms,
                };
              });
            }}
          />
        )}
      </>
    );
  };

  renderMaxAcceptanceTime = (
    minPrice: number,
    maxPrice: number,
    increment: number
  ): React.ReactNode => {
    if (minPrice < maxPrice) {
      const minutes =
        (Math.ceil((maxPrice - minPrice) / increment) + 1) * BROADCAST_MINUTES;

      return <article>Max order acceptance time: {formatDuration(minutes * 60)}</article>;
    }

    return false;
  };

  renderRecommendPrice = (): React.ReactNode => {
    return (
      <>
        <RecommendPriceContainer>
          <RecommendPriceText>
            <>Not sure what price to set?</>
            <RecommendPriceHelp>
              To get a price recommendation, addresses must be validated
            </RecommendPriceHelp>
          </RecommendPriceText>
          <RecommendPriceButton
            type="button"
            buttonType="neutral"
            buttonStyle="encourage"
            disabled={this.state.showRecommendPriceInfo || this.state.isFetchingPrice}
            onClick={this.recommendPrice}
          >
            <Icon
              color={
                this.state.showRecommendPriceInfo || this.state.isFetchingPrice
                  ? COLOR.grey
                  : COLOR.blue
              }
              icon={
                this.state.showRecommendPriceInfo || this.state.isFetchingPrice
                  ? faCircleNotch
                  : faBinoculars
              }
            />
            Recommend Price
          </RecommendPriceButton>
        </RecommendPriceContainer>
        {this.state.showRecommendPriceSuccess ? (
          <RecommendPriceAlert status="success">
            <TextBold>
              We recommend prices based on current market conditions to increase
              likelihood of order acceptance. You can change the suggested prices if they
              do not fit your budget.
            </TextBold>
          </RecommendPriceAlert>
        ) : (
          false
        )}
        {this.state.showRecommendPriceInfo ? (
          <RecommendPriceAlert status="info">
            <TextBold>
              Valid addresses is required to get a price recommendation. Please fill in
              the correct address to get a price recommendation
            </TextBold>
          </RecommendPriceAlert>
        ) : (
          false
        )}
        {this.state.showRecommendPriceError ? (
          <ErrorAlert status="error">
            <TextBold>{RECOMMENDATION_ERROR}</TextBold>
          </ErrorAlert>
        ) : (
          false
        )}
      </>
    );
  };

  renderMinMaxPrice(): React.ReactNode {
    const { baseForm } = this.state;
    return (
      <>
        <div>
          Alert drivers starting from a base price per location that increases to your max
          budget until a driver is found.
        </div>
        <PriceContent>
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Minimum Price SGD"
              isRequired
              placeholder="5.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('min_price', value);
              }}
              type="number"
              width="medium"
              value={baseForm.min_price / ONE_SGD || ''}
            />
            <Icon icon={faArrowRight} />
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Maximum Price SGD"
              isRequired
              placeholder="15.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('max_price', value);
              }}
              type="number"
              width="medium"
              value={baseForm.max_price / ONE_SGD || ''}
            />
          </div>
          {baseForm.max_price < baseForm.min_price && (
            <Alert status="error">
              Set a Maximum Price that is higher than the Minimum Price. Alternatively,
              set a lower Minimum Price.
            </Alert>
          )}
          {baseForm.max_price === baseForm.min_price && (
            <Alert status="info">
              There will be no increment, your orders will be broadcasted at this same
              price throughout.
            </Alert>
          )}
          {this.renderRecommendPrice()}
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Price Increment SGD"
              isRequired
              placeholder="1.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('increment', value);
              }}
              type="number"
              width="medium"
              value={baseForm.increment / ONE_SGD || ''}
              min={1}
              step="any"
              max={baseForm.max_price / ONE_SGD}
            />
            <span>every {BROADCAST_MINUTES} minutes</span>
          </div>
          {this.renderMaxAcceptanceTime(
            baseForm.min_price,
            baseForm.max_price,
            baseForm.increment
          )}
        </PriceContent>
      </>
    );
  }

  renderSinglePrice(): React.ReactNode {
    return (
      <>
        <div>Alert drivers at a fixed price per location until a driver is found.</div>
        <PriceContent>
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Price SGD"
              isRequired
              placeholder="5.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('min_price', value);
              }}
              type="number"
              width="medium"
              value={this.state.baseForm.min_price / ONE_SGD || ''}
              min={1}
            />
          </div>
        </PriceContent>
        {this.renderRecommendPrice()}
      </>
    );
  }

  renderFinalPrice(): React.ReactNode {
    return (
      <FinalPriceWrapper>
        <TextInput
          containerStyle={inlineTextInputStyle}
          fieldName="Final Price"
          placeholder="5.00"
          disabled={!this.isEditableField('price')}
          onTextChange={(value: string): void => {
            this.updateBaseForm('price', value);
          }}
          name="price"
          type="number"
          width="medium"
          value={this.state.baseForm.price / ONE_SGD || ''}
          step="any"
          min={1}
        />
      </FinalPriceWrapper>
    );
  }

  renderConfirmOrderModal = (): React.ReactNode => {
    return (
      <>
        {this.state.showConfirmOrderModal && (
          <CenterModal title="Confirm Update Order?">
            <Checkbox
              onClick={(): void => {
                this.setState((prevState) => ({
                  orderDeclaration: {
                    ...prevState.orderDeclaration,
                    correctAndComplete: !prevState.orderDeclaration.correctAndComplete,
                  },
                }));
              }}
              selected={this.state.orderDeclaration.correctAndComplete}
            >
              <ConfirmOrderModalText>
                I confirm that the description and specifics of my delivery details are
                correct and complete.
              </ConfirmOrderModalText>
            </Checkbox>
            <ConfirmOrderModalButtons>
              <StyledButton
                buttonType="neutral"
                buttonStyle="encourage"
                onClick={(): void => {
                  this.setState({ showConfirmOrderModal: false });
                }}
              >
                Back
              </StyledButton>
              <StyledButton
                type="submit"
                buttonType="primary"
                buttonStyle="encourage"
                disabled={!this.allowUpdate()}
              >
                Confirm
              </StyledButton>
            </ConfirmOrderModalButtons>
          </CenterModal>
        )}
      </>
    );
  };

  renderOrderPlacedModal = (): React.ReactNode => {
    const { id } = this.props.match.params;
    return (
      <>
        {this.state.isSuccessful && (
          <CenterModal
            title="Order Updated"
            rightButtonText="OK"
            rightButtonOnClick={(): void => {
              this.props.history.push('/sys/tasks/' + id);
            }}
          >
            <Alert status="success">You have successfully updated your order.</Alert>
          </CenterModal>
        )}
      </>
    );
  };

  renderSwitchViewWarningModal = (): React.ReactNode => (
    <CenterModal
      title="Switch view?"
      leftButtonText="Cancel"
      leftButtonOnClick={(): void => {
        this.setState({ showSwitchViewModal: false });
      }}
      rightButtonText="OK"
      rightButtonOnClick={(): void => {
        this.props.history.push('/sys/tasks/imports/new', {
          orgId: this.state.baseForm.org_id,
          vehiclePreference: this.state.baseForm.vehicle_preference,
          broadcastPreference: this.state.baseForm.broadcast_preference,
        });
      }}
    >
      The added order details will be lost.
    </CenterModal>
  );

  renderActionButtons(): React.ReactNode {
    const task = this.props.task;
    let canEdit = task.current_status === 'pending_broadcast';
    if (task && task.broadcast_preference === BROADCAST_PREFERENCES.marine) {
      canEdit = true;
    }
    if (task && TaskEditableStatuses.includes(task.current_status)) {
      canEdit = true;
    }
    return (
      <ActionContainer>
        <ActionButtonContainer>
          <Button
            type="button"
            buttonType="neutral"
            buttonStyle="encourage"
            onClick={(): void => {
              this.props.history.push('/sys/tasks');
            }}
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
          >
            Back
          </Button>
          <Button
            type="button"
            buttonType="primary"
            buttonStyle="encourage"
            disabled={!this.allowConfirmOrder() || !canEdit || !this.isFormChanged()}
            onClick={(): void => {
              this.setState({ showConfirmOrderModal: true });
            }}
            icon={<FontAwesomeIcon icon={faCheck} />}
          >
            Update
          </Button>
        </ActionButtonContainer>
      </ActionContainer>
    );
  }

  onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    deliveryFormIndex: number
  ): Promise<void> => {
    const file = e.target.files[0];
    let blobURL = null;
    if (file.type.includes('image') || file.type.includes('pdf')) {
      blobURL = URL.createObjectURL(file);
    }
    if (file) {
      const newFileData: FileData = {
        file_name: file.name,
        file_size: file.size,
        s3_upload_bucket: '',
        s3_upload_key: '',
        url: '',
        checksum: await computeChecksumMd5(file),
        checksum_method: 'MD5',
      };
      const fileWithData: FileWithData = {
        file: file,
        fileData: newFileData,
        prviewUrl: blobURL,
        action: 'add',
      };
      if (file.size > 20971520) {
        fileWithData.fileError = 'File size should not be more than 20MB';
      }
      if (!ALLOWED_FILE_TYPE.includes(file.type)) {
        fileWithData.fileError = 'Only accepts png, jpg, pdf, doc, docx, xlsx, csv.';
      }
      this.setState((prevState) => {
        const updatedDeliveryForms = [...prevState.deliveryForms];
        updatedDeliveryForms[deliveryFormIndex] = {
          ...updatedDeliveryForms[deliveryFormIndex],
          ['attachment_files']:
            updatedDeliveryForms[deliveryFormIndex].attachment_files.concat(fileWithData),
        };
        return {
          deliveryForms: updatedDeliveryForms,
        };
      });
    }
  };

  onFileRemove = (deliveryFormIndex: number, previewUrl: string): void => {
    this.setState((prevState) => {
      const updatedDeliveryForms = [...prevState.deliveryForms];
      const deliveryForm = { ...updatedDeliveryForms[deliveryFormIndex] };
      const attachmentFiles = [...deliveryForm.attachment_files];
      const index = attachmentFiles.findIndex((file) => file.prviewUrl === previewUrl);
      // Check if the file exists at the given index
      if (attachmentFiles[index]) {
        if (attachmentFiles[index].action === 'view') {
          // Set the action to 'remove' from task
          attachmentFiles[index] = {
            ...attachmentFiles[index],
            action: 'remove',
          };
        } else if (attachmentFiles[index].action === 'add') {
          // Remove the file from the attachment files array
          attachmentFiles.splice(index, 1);
        }
      }

      // Update the delivery form with the modified attachment files array
      deliveryForm.attachment_files = attachmentFiles;

      // Update the delivery forms array with the modified delivery form
      updatedDeliveryForms[deliveryFormIndex] = deliveryForm;
      // Return the new state
      return {
        deliveryForms: updatedDeliveryForms,
      };
    });
  };

  renderUploadAttachmentDescription = (): React.ReactNode => {
    return (
      <LabelDescription>
        <Span>Attach documents e.g. DSA/DO/Permits/Packing Lists/Invoice etc.</Span>
        <br />
        <Span>
          Upload files up to 20MB in pdf (recommended), jpg, jpeg, png, docx, xlsx or csv.
        </Span>
      </LabelDescription>
    );
  };

  getPresignedUrl = async (id: string, fileName: string): Promise<string> => {
    const client = new TaskClient();
    return await client.getSignedAttachmentLink(id, fileName);
  };

  uploadToS3 = async (filesWithData: FileWithData[]): Promise<void> => {
    try {
      const client = new TaskImportClient();
      for (const fileWithData of filesWithData) {
        await client.uploadToS3(fileWithData.fileData.url, fileWithData.file);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  renderFiles = (deliveryFormIndex: number): React.ReactNode => {
    const filteredFiles = this.state.deliveryForms[
      deliveryFormIndex
    ].attachment_files.filter((attachment) => attachment.action !== 'remove');
    return (
      <SectionContainer>
        {filteredFiles.map((fileWithData, attachmentIndex) => (
          <FileSectionContainer
            key={`${deliveryFormIndex}'-'${attachmentIndex}-${fileWithData.prviewUrl}`}
          >
            <FileInformationCard
              key={`${deliveryFormIndex}'-'${attachmentIndex}-${fileWithData.prviewUrl}`}
              name={fileWithData.file.name}
              size={fileWithData.file.size}
              src={fileWithData.prviewUrl}
              fileType={fileWithData.file.type}
              onDeleteFile={(): void => {
                this.onFileRemove(deliveryFormIndex, fileWithData.prviewUrl);
              }}
            />
            {fileWithData && fileWithData.fileError && (
              <AttachmentFormErrorAlert status="error" noBackground>
                {fileWithData.fileError}
              </AttachmentFormErrorAlert>
            )}
          </FileSectionContainer>
        ))}
      </SectionContainer>
    );
  };

  renderFilesUpload = (deliveryFormIndex: number = 0): React.ReactNode => {
    return (
      <div style={{ marginTop: '-1.5rem', marginBottom: '2rem' }}>
        <SelectFileButton
          buttonText="Upload"
          disabled={
            this.state.deliveryForms[deliveryFormIndex].attachment_files.length >= 5
          }
          buttonStyle={noMarginLeft}
          label="Attachments"
          labelStyle={attachmentLabelStyle as FlattenSimpleInterpolation}
          onFileChange={async (e): Promise<void> => {
            await this.onFileChange(e, deliveryFormIndex);
          }}
          acceptedFormat={'text/csv, .png, .jpg, .pdf, .doc, .docx, .xlsx, .csv'}
        >
          {this.renderUploadAttachmentDescription()}
        </SelectFileButton>
        {this.renderFiles(deliveryFormIndex)}
      </div>
    );
  };

  renderErrorModal = (): React.ReactNode => {
    return (
      <>
        {this.state.error && (
          <CenterModal
            title="Unable to place orders"
            rightButtonText="OK"
            rightButtonOnClick={(): void => {
              this.setState({ error: null, isSaving: false });
            }}
          >
            <Alert status="error">
              {this.state.error !== 'Network Error'
                ? this.state.error
                : 'There seems to be an error with the network.'}
            </Alert>
            <ErrorModalText>
              {this.state.error !== 'Network Error'
                ? 'Please check that your order information is correct, then try again.'
                : 'Please wait a few seconds then try again. If the problem continues, let us know.'}
            </ErrorModalText>
          </CenterModal>
        )}
      </>
    );
  };

  render(): React.ReactNode {
    const { baseForm } = this.state;
    if (baseForm) {
      const { task } = this.props;
      const orderId = (task && task.id) || '';
      return (
        <SysAdminMainContainer selected="tasks">
          <Breadcrumb
            key={0}
            items={[
              {
                text: 'Orders',
                onClick: (): void => {
                  this.props.history.push('/sys/tasks');
                },
              },
              {
                text: orderId.split('-')[0],
                onClick: (): void => {
                  this.props.history.push(`/sys/tasks/${orderId}`);
                },
              },
              { text: 'Edit' },
            ]}
          />
          <form
            onSubmit={this.handleSubmit}
            onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>): void => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            {this.renderGeneralInfoForm()}
            {this.renderOrderForm()}
            {(baseForm.broadcast_preference === BROADCAST_PREFERENCES.all ||
              baseForm.broadcast_preference === BROADCAST_PREFERENCES.squad) && (
              <>
                {this.renderServiceAndVehicleSelect()}
                {this.isEditableField('price') && this.renderPriceForm()}
              </>
            )}
            {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
              <>
                <OrderCard>
                  {this.renderFilesUpload()}
                  {this.renderCargoDetails()}
                  {this.renderFinalPrice()}
                </OrderCard>
                {this.renderAdditionalService()}
              </>
            )}
            {this.renderLocateMapModal()}
            {this.renderConfirmOrderModal()}
            {this.renderOrderPlacedModal()}
            {this.renderErrorModal()}
            <BottomMargin />
            {this.renderActionButtons()}
          </form>
          {this.state.showSwitchViewModal && this.renderSwitchViewWarningModal()}
          {typeof this.state.showRemoveDeliveryDetailsModal === 'number' &&
            this.renderRemoveDeliveryDetailsWarningModal()}
          {typeof this.state.showRemoveModal.deliveryFormIndex === 'number' &&
            this.renderRemoveCargoDetailsOrItemsModal()}
          {this.renderRemoveSuccessModal()}
          {this.renderAddVesselModal()}
          {this.renderAddressContactModal()}
        </SysAdminMainContainer>
      );
    } else {
      return null;
    }
  }
}

const DateAndTimeContainer = styled.div<{ alignItems?: string }>`
  display: flex;
  margin-bottom: 1px;
  align-items: ${(props): string => props.alignItems ?? 'flex-end'};
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const DatePickerWrapper = styled(({ className, ...props }) => (
  <DatePicker {...props} wrapperClassName={className} />
))<{ width?: string; marginRight?: string }>`
  display: flex;
  align-items: flex-end;
  margin-right: ${(props): string => props.marginRight || '0px'};
  width: ${(props): string => props.width || '11rem'};
`;

const Card = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${COLOR.neutral};
  background-color: ${COLOR.white};
  padding: 1.875rem 2.125rem;
  margin-bottom: 1.75rem;
`;

const PriceInfoCard = styled(Card)`
  margin-bottom: 6rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const Icon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  align-self: center;
`;

const TrashIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`;

const RemoveCargoDetailsIcon = styled(FontAwesomeIcon)<RemoveCargoDetailsIconProps>`
  color: ${(props): string => props.color};
  font-size: ${(props): string => (props.fontSize ? props.fontSize : '1rem')};
`;

const RemoveCargoDetailsSubTitle = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
`;

const RemoveItemButton = styled(StyledButton)`
  border-width: 0;
  background-color: ${COLOR.white};
`;

const VesselCardRow = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    display: flex;
  }
`;

const Content = styled.div`
  flex: 1;
  margin-left: 3rem;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-right: 0;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media (max-width: 769px) {
    margin-left: 0;
  }
`;

const PriceContent = styled(Content)`
  margin-left: 0;
`;

const AdditionalServiceContent = styled(Content)`
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 1rem;
  margin-left: 0;
  padding-left: 1.7rem;
  margin-top: 1rem;
`;

const ItemTitle = styled.div`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const RedText = styled.span`
  color: ${COLOR.red};
`;

const Button = styled(StyledButton)`
  margin-right: 1rem;
`;

const RecommendPriceContainer = styled.div`
  margin-top: 1.5rem;
  margin-left: 1rem;
  display: flex;
`;

const RecommendPriceAlert = styled(Alert)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const RecommendPriceText = styled.div`
  display: flex;
  flex-direction: column;
`;

const RecommendPriceHelp = styled.div`
  color: ${COLOR.midDarkGrey};
  font-size: 0.875rem;
`;

const RecommendPriceButton = styled(Button)`
  margin-left: 1rem;
`;

const TextBold = styled.div`
  font-weight: 600;
`;

const ActionButtonContainer = styled.div`
  padding: 1rem 3rem;

  @media (max-width: 768px) {
    padding: 1rem 1rem 1rem 2rem;
    display: flex;
    justify-content: space-between;
  }
`;

const ActionContainer = styled.section`
  background-color: ${COLOR.whiteGrey};
  box-shadow: 0 8px 18px ${transparent('shadow', 80)};
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 14;

  @media (max-width: 768px) {
    padding-bottom: 3rem;
    height: 2.5rem;
  }
`;

const GeneralInfoFormContainer = styled.div`
  margin-top: 1rem;
`;

const ConfirmOrderModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

const ConfirmOrderModalText = styled.div`
  margin-left: 0.5rem;
`;

const AdditionalServiceCard = styled(OrderCard)`
  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const FileSectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BottomMargin = styled.div`
  margin-bottom: 6rem;
`;

const EnterManualEmptyPlaceholderButton = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
  width: 7rem;
  margin-top: 0.1rem;
`;

const EnterManualButton = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
  width: 7rem;
  margin-top: 0.5rem;
`;

const ImoText = styled.div`
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
`;

const ImoInfoAlert = styled(Alert)`
  max-width: 24rem;
`;

const EmptyVesselPlaceholder = styled.div`
  background-color: ${COLOR.white};
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLOR.blue};
  color: ${COLOR.black};
  outline: none;
  overflow-y: auto;
  max-height: 15rem;
  padding: 0.8rem;
  z-index: 1;
`;

const ItemDescription = styled.p`
  color: ${(props): string => props.theme.colors.gray_400};
  font-size: ${(props): string => props.theme.fontSizes.xs};
  margin-top: 0.45rem;
  font-weight: 400;
`;

const ItemDetailsContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 18px;
  grid-column-gap: 16px;
  margin-bottom: 1.5rem;
  max-width: 530px;

  @media (min-width: 590px) and (max-width: 785px) {
    grid-template-columns: 35% 1fr;
  }

  @media (min-width: 786px) {
    padding-left: 23px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const OrderNumber = styled.span`
  position: absolute;
  left: -24px;
  top: 32px;

  @media (min-width: 786px) {
    left: 0;
  }
`;

const DescriptionWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: end;

  @media (min-width: 786px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const QuantityWrapper = styled.div`
  @media (min-width: 786px) {
    grid-column: 1;
  }
`;

const SkuWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: end;
  max-width: 50%;

  @media (min-width: 786px) {
    grid-column-start: 3;
    grid-column-end: 4;
    max-width: 100%;
  }
`;

const DimensionWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: end;
  @media (min-width: 786px) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

const CargoHazardousMaterial = styled.div`
  @media (min-width: 786px) {
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 2;
  }
`;

const RemoveItemWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  align-self: end;
  @media (min-width: 786px) {
    position: absolute;
    top: 2rem;
    right: -16rem;
  }
`;

const QuantityUnitWrapper = styled.div``;

const WeightWrapper = styled.div``;

const WeightUnitWrapper = styled.div`
  visibility: hidden;
`;

const TotalCargoContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 680px;
  align-items: center;
`;

const TotalCargo = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
`;

const Lifts = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

const LiftInfoIcon = styled(FontAwesomeIcon)`
  color: ${(props): string => props.theme.colors.primary_600};
`;

const ItemFieldName = styled.p`
  font-size: 0.875rem;
  margin: 0 0 6px 0;
`;

const CheckBoxWrapper = styled.div<{ index: number }>`
  white-space: nowrap;
`;

const FinalPriceWrapper = styled.div`
  margin-top: 1rem;
`;

const SectionContainer = styled.div`
  margin-bottom: 1rem;
  flex-wrap: wrap;
  display: flex;
`;

const AttachmentFormErrorAlert = styled(Alert)`
  margin-top: 0.5rem;
  max-width: 14rem;
`;

const LabelDescription = styled.div`
  margin-bottom: 0.5rem;
`;

const Span = styled.span`
  font-size: 0.8rem;
  color: gray;
`;

const noMarginLeft = css`
  margin-left: 0;
`;

const attachmentLabelStyle = css`
  padding-bottom: 0;
  padding-left: 0;
  letter-spacing: 0.32px;
`;

export const OrderTitle = styled.div`
  margin-bottom: 1rem !important;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const mapStateToProps = (state: RootState): SysAdminTaskEditProps => ({
  organizations: state.organization.organizations,
  organization: state.organization.organization,
  squadDrivers: state.organization.squadDrivers,
  geoDatas: state.geoService.geoDatas,
  savedGeoDatas: state.geoService.savedGeoDatas,
  vessels: state.vessel.vessels,
  task: state.task.task,
});

export default connect(mapStateToProps)(SysAdminTaskEdit);
