import { UploadRecord } from './uploadRecord';
import { TaskImportJobResult } from './taskImportJobResult';

export enum TaskImportStatus {
  new = 'new',
  queued = 'queued',
  inProgress = 'in_progress',
  failed = 'failed',
  done = 'done',
}

export interface TaskImportJob {
  id: string;
  upload_record: UploadRecord;
  org_id: string;
  broadcast_preference: string;
  is_qa: boolean;
  current_status: TaskImportStatus;
  last_executed_at_client_time_utc?: Date;
  last_executed_at_client_timezone?: string;
  last_executed_at_server_time?: Date;
  result?: TaskImportJobResult;
}

export interface MetaTasksImportJob {
  meta: {
    page: number;
    per_page: number;
  };
  task_import_jobs: TaskImportJob[];
}