import * as React from 'react';
import {
  LabelDescription,
  Span,
  noMarginLeft,
  attachmentLabelStyle,
  SectionContainer,
  FileSectionContainer,
  AttachmentFormErrorAlert,
} from 'pages/order/styles';
import { SelectFileButton, FileInformationCard } from 'components';
import { MerchantDeliveryForm } from 'models/task';
import { FlattenSimpleInterpolation } from 'styled-components';

interface FilesUploadCardProps {
  deliveryForm: MerchantDeliveryForm;
  deliveryFormIndex: number;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFileRemove: (attachmentFormIndex: number, previewUrl: string) => void;
  isEditableField?: (fieldName: string) => boolean;
}

class FilesUpload extends React.Component<FilesUploadCardProps> {
  renderUploadAttachmentDescription = (): React.ReactNode => {
    return (
      <LabelDescription>
        <Span>Attach documents e.g. DSA/DO/Permits/Packing Lists/Invoice etc.</Span>
        <br />
        <Span>
          Upload files up to 20MB in pdf (recommended), jpg, jpeg, png, docx, xlsx or csv.
        </Span>
      </LabelDescription>
    );
  };

  renderFiles = (): React.ReactNode => {
    const { deliveryForm, onFileRemove, deliveryFormIndex, isEditableField } = this.props;
    const filteredFiles = deliveryForm.attachment_files.filter(
      (attachment) => attachment.action !== 'remove'
    );
    return (
      <SectionContainer>
        {filteredFiles.map((fileWithData, attachmentIndex) => (
          <FileSectionContainer key={`${deliveryFormIndex}'-'${attachmentIndex}`}>
            <FileInformationCard
              key={`${deliveryFormIndex}'-'${attachmentIndex}`}
              name={fileWithData.file.name}
              size={fileWithData.file.size}
              src={fileWithData.prviewUrl}
              fileType={fileWithData.file.type}
              onDeleteFile={
                isEditableField && !isEditableField('attachments')
                  ? undefined // or omit onDeleteFile prop altogether
                  : () => {
                      onFileRemove(attachmentIndex, fileWithData.prviewUrl);
                    }
              }
            />
            {fileWithData && fileWithData.fileError && (
              <AttachmentFormErrorAlert status="error" noBackground>
                {fileWithData.fileError}
              </AttachmentFormErrorAlert>
            )}
          </FileSectionContainer>
        ))}
      </SectionContainer>
    );
  };

  render(): React.ReactNode {
    const { deliveryForm, onFileChange, isEditableField } = this.props;

    return (
      <div style={{ marginTop: '-1.5rem', marginBottom: '2rem' }}>
        <SelectFileButton
          buttonText="Upload"
          disabled={
            deliveryForm.attachment_files.length >= 5 ||
            (isEditableField && !isEditableField('attachments'))
          }
          buttonStyle={noMarginLeft}
          label="Attachments"
          labelStyle={attachmentLabelStyle as FlattenSimpleInterpolation}
          onFileChange={onFileChange}
          acceptedFormat={'text/csv, .png, .jpg, .pdf, .doc, .docx, .xlsx, .csv'}
        >
          {this.renderUploadAttachmentDescription()}
        </SelectFileButton>
        {this.renderFiles()}
      </div>
    );
  }
}

export default FilesUpload;
