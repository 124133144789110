import styled from 'styled-components';

// This component is deprecated
// This component should be removed when all pages are using TextInput component
export default styled.input`
  background: none;
  border: 1px solid #dddddd;
  border-radius: 0;
  font-size: 16px;
  font-family: inherit;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  width: 100%;
  height: 100%;
  cursor: auto;
  -webkit-appearance: none;
  transition: all 0.2s;

  &:focus {
    outline: none;
    &::placeholder {
      opacity: 1;
    }
  }
`;
