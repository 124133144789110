import COLOR from './color';

interface Status {
  [key: string]: string;
}

const STATUS: Status = {
  'verified': 'Verified',
  'unverified': 'Not Verified',
  'deleted': 'Deleted',
};

export const ACCOUNT_STATUS_COLOR: Status = {
  'verified': COLOR.blue,
  'unverified': COLOR.red,
  'deleted': COLOR.red,
};

export const VEHICLE_STATUS_COLOR: Status = {
  'verified': COLOR.blue,
  'unverified': COLOR.yellow,
  'deleted': COLOR.red,
};

export default STATUS;