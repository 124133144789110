import axios, { AxiosRequestConfig, Method, AxiosResponse, ResponseType as AxiosResponseType } from 'axios';
import { TaskImportAPIResponse } from 'models/task';
import {
  GetTaskImportJobResponse,
  MetaTasksImportJob,
  TaskImportJobResult,
  FileData,
  GetPresignedUrlResponse,
  SysAdminCreateImportAfterUploadForm,
  CreateImportAfterUploadForm,
} from 'models/taskImport';
import * as HttpHelper from 'utils/httpHelper';
import { getBearerToken } from 'utils/userHelper';
import configureStore from 'reduxActions/store';
import {
  receiveTaskImportJobs,
  receiveTaskImportJob,
  receiveTaskImportJobResult,
} from 'reduxActions/taskImport/taskImportActions';
import { PER_PAGE } from 'constants/paginationMeta';

class TaskImportClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.TASK_IMPORT_SERVICE_PROTOCOL;
    this.host = process.env.TASK_IMPORT_SERVICE_HOST;
    this.port = process.env.TASK_IMPORT_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  importUrl(): string {
    return `${this.getBaseUrl()}/import`;
  }

  getImportJobUrl(id: string): string {
    return `${this.getBaseUrl()}/import/${id}`;
  }

  getImportJobsUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/import?${params}`;
  }

  startImportUrl(id: string): string {
    return `${this.getBaseUrl()}/import/${id}/start`;
  }

  sysAdminStartImportUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/import/${id}/start`;
  }

  downloadTaskImportTemplateUrl(): string {
    return `${this.getBaseUrl()}/task/template`;
  }

  sysDownloadTaskImportTemplateUrl(): string {
    return `${this.getBaseUrl()}/sys/task/template`;
  }

  downloadItemImportTemplateUrl(): string {
    return `${this.getBaseUrl()}/item/template`;
  }

  resultUrl(id: string): string {
    return `${this.getBaseUrl()}/result/${id}`;
  }

  sysAdminImportUrl(): string {
    return `${this.getBaseUrl()}/sys/import`;
  }

  sysAdminGetImportJobsUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/import?${params}`;
  }

  sysAdminGetImportJobUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/import/${id}`;
  }

  sysAdminResultUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/result/${id}`;
  }

  sysAdminPresignedUrl(fileName: string): string {
    return `${this.getBaseUrl()}/sys/presigned-url?filename=${fileName}`;
  }

  presignedUrl(fileName: string): string {
    return `${this.getBaseUrl()}/presigned-url?filename=${fileName}`;
  }

  sysAdminImportAfterUploadUrl(): string {
    return `${this.getBaseUrl()}/sys/import-after-upload`;
  }

  importAfterUploadUrl(): string {
    return `${this.getBaseUrl()}/import-after-upload`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, responseType: AxiosResponseType = 'json', includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
      responseType,
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  async create(files: FormData): Promise<TaskImportAPIResponse> {
    const request = this.makeRequest('POST', this.importUrl(), files);
    try {

      const response: AxiosResponse<TaskImportAPIResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.Error || error.response.data.error;
      }
    }
  }

  async sysAdminCreate(files: FormData): Promise<TaskImportAPIResponse> {
    const request = this.makeRequest('POST', this.sysAdminImportUrl(), files);
    try {

      const response: AxiosResponse<TaskImportAPIResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.Error || error.response.data.error;
      }
    }
  }

  async start(id: string): Promise<void> {
    const request = this.makeRequest('POST', this.startImportUrl(id));
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.Error;
      }
    }
  }

  async sysAdminStart(id: string): Promise<void> {
    const request = this.makeRequest('POST', this.sysAdminStartImportUrl(id));
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.Error;
      }
    }
  }

  async downloadTaskImportTemplate(): Promise<void> {
    const response = await axios(this.makeRequest('GET', this.downloadTaskImportTemplateUrl(), null, 'blob', false));
    HttpHelper.saveBlob(response.data, 'sample.orders.csv');
  }

  async downloadItemImportTemplate(): Promise<void> {
    const response = await axios(this.makeRequest('GET', this.downloadItemImportTemplateUrl(), null, 'blob', false));
    HttpHelper.saveBlob(response.data, 'sample.items.csv');
  }

  async sysDownloadTaskImportTemplate(): Promise<void> {
    const response = await axios(this.makeRequest('GET', this.sysDownloadTaskImportTemplateUrl(), null, 'blob', false));
    HttpHelper.saveBlob(response.data, 'sample.orders.csv');
  }

  async getTaskImportJob(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.getImportJobUrl(id));
    try {
      const response: AxiosResponse<GetTaskImportJobResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskImportJob(response.data));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    }
  }

  async getTaskImportJobs(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.getImportJobsUrl(params), null);

    try {
      const response: AxiosResponse<MetaTasksImportJob> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskImportJobs(response.data.task_import_jobs));
    } catch (error) {
      if (error.response) {
        throw error.response.data.error.message;
      } else {
        throw error.message;
      }
    }
  }

  async getTaskImportJobResult(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.resultUrl(id));
    try {
      const response: AxiosResponse<TaskImportJobResult> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskImportJobResult(response.data));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    }
  }

  async sysAdminGetAllTaskImportJobs(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.sysAdminGetImportJobsUrl(params), null);

    try {
      const response: AxiosResponse<MetaTasksImportJob> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskImportJobs(response.data.task_import_jobs));
    } catch (error) {
      if (error.response) {
        throw error.response.data.Error;
      }
    }
  }

  async sysAdminGetTaskImportJob(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.sysAdminGetImportJobUrl(id));
    try {
      const response: AxiosResponse<GetTaskImportJobResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskImportJob(response.data));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    }
  }

  async sysAdminGetTaskImportJobResult(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.sysAdminResultUrl(id));
    try {
      const response: AxiosResponse<TaskImportJobResult> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskImportJobResult(response.data));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    }
  }

  async sysAdminGetPresignedUrl(fileName: string): Promise<FileData> {
    const request = this.makeRequest('GET', this.sysAdminPresignedUrl(fileName));
    try {
      const response: AxiosResponse<GetPresignedUrlResponse> = await axios(request);
      return response.data.file;
    } catch (error) {
      if (error.response) {
        throw error.response.data.Error || error.response.data.error;
      }
    }
  }

  async getPresignedUrl(fileName: string): Promise<FileData> {
    const request = this.makeRequest('GET', this.presignedUrl(fileName));
    try {
      const response: AxiosResponse<GetPresignedUrlResponse> = await axios(request);
      return response.data.file;
    } catch (error) {
      if (error.response) {
        throw error.response.data.Error || error.response.data.error;
      }
    }
  }

  async sysAdminCreateImportAfterUpload(form: SysAdminCreateImportAfterUploadForm): Promise<TaskImportAPIResponse> {
    const request = this.makeRequest('POST', this.sysAdminImportAfterUploadUrl(), form);
    try {
      const response: AxiosResponse<TaskImportAPIResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.Error || error.response.data.error;
      }
    }
  }

  async createImportAfterUpload(form: CreateImportAfterUploadForm): Promise<TaskImportAPIResponse> {
    const request = this.makeRequest('POST', this.importAfterUploadUrl(), form);
    try {
      const response: AxiosResponse<TaskImportAPIResponse> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.Error || error.response.data.error;
      }
    }
  }

  async uploadToS3(url: string, file: File): Promise<void>{
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    try {
      await axios.put(url, file, options);
    } catch (error) {
      throw ('Error uploading file to S3 (403 forbidden. Invalid Bucket Name, Access Key Id or Access Id).');
    }
  }
}

export default TaskImportClient;
