import * as React from 'react';
import styled from 'styled-components';

const NotFound = (): JSX.Element => (
  <Container>
    <HeaderText>{'Oops! You\'ve hit a dead end.'}</HeaderText>
    <DescriptionText>
      {'If you\'re sure that you have the correct URL, then you may not have access to this page.'}
    </DescriptionText>
  </Container>
);

const Container = styled.div`
  background-color: 'black';
  margin-left: 2rem;
`;

const HeaderText = styled.div`
  font-size: 3rem;
  font-weight: 'bold';
`;

const DescriptionText = styled.div`
  font-size: 1rem;
  font-weight: 'bold';
`;

export default NotFound;