import * as React from 'react';
import styled from 'styled-components';
import { Button, TextField, SysAdminMainContainer } from 'components';
import RunsheetGenerationClient from 'httpClients/runsheetGenerationClient';
import { RouteComponentProps } from 'react-router-dom';
import { RunsheetGenerationJob } from 'models/runsheetGeneration';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';

interface RunsheetGenerationEditProps {
  runsheetGenerationJob: RunsheetGenerationJob;
}

interface RunsheetGenerationEditState {
  form: {
    name: string;
    strategy: 'random' | 'exact-address-match';
  };
  error: string | null;
}

type Props = RouteComponentProps<{ id: string }> & RunsheetGenerationEditProps;

class RunsheetGenerationEdit extends React.Component<Props, RunsheetGenerationEditState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: {
        name: '',
        strategy: 'random',
      },
      error: '',
    };
  }

  componentDidMount(): void {
    const { id } = this.props.match.params;
    this.fetchRunsheetGeneration(id);
  }

  componentDidUpdate(prevProps: RunsheetGenerationEditProps): void {
    const { runsheetGenerationJob } = this.props;
    if (prevProps.runsheetGenerationJob !== runsheetGenerationJob) {
      if (runsheetGenerationJob) {
        this.setState({
          form: {
            name: runsheetGenerationJob.name,
            strategy: runsheetGenerationJob.strategy,
          },
        });
      }
    }
  }

  fetchRunsheetGeneration = async (id: string): Promise<void> => {
    this.setState({ error: null });
    try {
      const client = new RunsheetGenerationClient();
      await client.getRunsheetGeneration(id);
    } catch (e) {
      this.setState({ error: e });
    }
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const { id } = this.props.match.params;
    const { form } = this.state;

    this.setState({ error: null });
    try {
      const client = new RunsheetGenerationClient();
      await client.editRunsheetGenerationJob(id, form.name, form.strategy);
      this.props.history.push('/sys/runsheet-generations');
    } catch (e) {
      this.setState({ error: e });
    }
  }

  render(): React.ReactNode {
    const { form, error } = this.state;

    return (
      <SysAdminMainContainer selected="runsheet-generations">
        <Container>
          <h1>Edit Runsheet Generation Job</h1>
          {error && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
          <form onSubmit={this.handleSubmit}>
            <>
              <label>Name</label>
              <TextField
                type="text"
                name="name"
                value={form.name}
                onChange={(e): void => this.handleChange('name', e.target.value)}
              />
              <Label>Strategy</Label>
              <DropDown
                name="strategy"
                onChange={(e): void => this.handleChange('strategy', e.target.value)}
                value={form.strategy}
              >
                <option key={1} value='random'>Random</option>
                <option key={2} value='exact-address-match'>Exact Address Match</option>
              </DropDown>
            </>
            <>
              <Button type="submit">Save</Button>
            </>
          </form>
        </Container>
      </SysAdminMainContainer>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 25rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 0.625rem 0;
`;

const DropDown = styled.select`
  width: 20.5rem;
  padding: 0.25rem;
  margin: 0.25rem 0.5rem 1rem 0.5rem;
`;

const Label = styled.label`
  width: 18rem;
  float: left;
  margin-top: 1rem;
`;

const mapStateToProps = (state: RootState): RunsheetGenerationEditProps => ({
  runsheetGenerationJob: state.runsheetGeneration.runsheetGenerationJob,
});

export default connect(mapStateToProps)(RunsheetGenerationEdit);
