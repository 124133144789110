import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { FieldView, Button, SysAdminMainContainer, StatusLabel } from 'components';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import styled from 'styled-components';
import { RunsheetReadyToTransfer, RunsheetReadyToTransferLog } from 'models/runsheetReadyToTransfer';
import * as moment from 'moment';
import RunsheetRTTClient from 'httpClients/runsheetRTTClient';
import { Card, Content, Container, Column, Title, InfoTable } from './styles';
import JOB_STATUS, { JOB_STATUS_COLOR } from 'constants/jobStatus';
import TransferLogCard from './cards/transferLogCard';

interface RunsheetReadyToTransferDetailState {
  error: null | string;
  showStopTransferingModal: boolean;
}

interface StateProps {
  runsheetReadyToTransfer: RunsheetReadyToTransfer;
  runsheetReadyToTransferLogs: RunsheetReadyToTransferLog[];
}

type Props = RouteComponentProps<{ id: string }> & StateProps;

class RunsheetReadyToTransferDetail extends React.Component<Props, RunsheetReadyToTransferDetailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      showStopTransferingModal: false,
    };
  }

  async componentDidMount(): Promise<void> {
    const { id } = this.props.match.params;
    const runsheetRTT = await this.fetchRunsheetReadyToTransfer(id);

    if (runsheetRTT !== undefined) {
      await this.fetchRunsheetReadyToTransferByJob(id);
    }
  }

  fetchRunsheetReadyToTransfer = async (id: string): Promise<RunsheetReadyToTransfer> => {
    this.setState({ error: null });
    try {
      const client = new RunsheetRTTClient();
      const runsheetRTT = await client.getRunsheetReadyToTransfer(id);
      return runsheetRTT;
    } catch (e) {
      this.setState({ error: e });
    }
  }

  fetchRunsheetReadyToTransferByJob = async (JobID: string): Promise<void> => {
    this.setState({ error: null });
    try {
      const client = new RunsheetRTTClient();
      await client.getRunsheetReadyToTransferLog(JobID);
    } catch (e) {
      this.setState({ error: e });
    }
  }

  render(): React.ReactNode {
    const { runsheetReadyToTransfer, runsheetReadyToTransferLogs } = this.props;
    const { error } = this.state;

    return (
      <SysAdminMainContainer selected="runsheet-ready-to-transfers">
        <PageHeader>
          <Title>Transfer Detail</Title>
          <Link to="/sys/runsheet-ready-to-transfers"><Button>Back</Button></Link>
        </PageHeader>
        {error && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
        {runsheetReadyToTransfer !== null ? (
          <Container>
            <Column flex="1">
              <Card>
                <InfoTable>
                  <Content>
                    <Title>Transfer Worker</Title>
                    <FieldView
                      fieldName="Status"
                    >
                      <StatusLabel
                        iconColor={JOB_STATUS_COLOR[runsheetReadyToTransfer.status]}
                        noBackground
                      >
                        {JOB_STATUS[runsheetReadyToTransfer.status]}
                      </StatusLabel>
                    </FieldView>
                    <FieldView
                      fieldName="Runsheet ID">
                      <Link to={'/sys/runsheets/' + runsheetReadyToTransfer.runsheet_id.toString()}>
                        <strong>{runsheetReadyToTransfer.runsheet_id.toString()}</strong>
                      </Link>
                    </FieldView>
                    <FieldView
                      fieldName="Organization ID">
                      {runsheetReadyToTransfer.org_id}
                    </FieldView>
                    <FieldView
                      fieldName="Date Created">
                      {moment(runsheetReadyToTransfer.created_at).format('DD MMM YYYY, hh:mm a')}
                    </FieldView>
                    <FieldView
                      fieldName="Date Updated">
                      {moment(runsheetReadyToTransfer.updated_at).format('DD MMM YYYY, hh:mm a')}
                    </FieldView>
                  </Content>
                </InfoTable>
              </Card>
            </Column>
            <Column flex="2">
              {runsheetReadyToTransferLogs && <TransferLogCard runsheetReadyToTransferLogs={runsheetReadyToTransferLogs} />}
            </Column>
          </Container>
        ) : null}
      </SysAdminMainContainer>
    );
  }
}

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 0.625rem 0;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  runsheetReadyToTransfer: state.runsheetReadyToTransfer.runsheetReadyToTransfer,
  runsheetReadyToTransferLogs: state.runsheetReadyToTransfer.runsheetReadyToTransferLogs,
});

export default connect(mapStateToProps)(RunsheetReadyToTransferDetail);
