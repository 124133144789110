import * as React from 'react';
import * as H from 'history';
import {
  Separator as BasicSeparator,
  Heading,
  PaginationState,
  SysAdminMainContainer,
  Breadcrumb,
  PageTitle,
  FieldView,
} from 'components';
import { CustomCard, PersonalInfoColumn, Row, ActionButton } from './styles';
import { Organization, OrgDriver } from 'models/organization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import COLOR from 'constants/color';
import { faEdit, faUser, faSync } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import SettingSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import OrganizationDriverClient from 'httpClients/organizationDriverClient';
import AuthClient from 'httpClients/authClient';

interface SysAdminOrgDriverDetailProps {
  organization: Organization;
  orgDriver: OrgDriver;
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface SysAdminOrgDriverState extends PaginationState<OrgDriver> {
  form: OrgDriver;
  isDriverAppCreated: boolean;
}

type Props = RouteComponentProps<{ id: string; driverId: string }> &
  SysAdminOrgDriverDetailProps &
  HistoryProps;

class SysAdminOrgDriverDetail extends React.Component<Props, SysAdminOrgDriverState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      form: this.getDefaultOrgDriverForm(),
      isDriverAppCreated: false,
    };
  }

  getDefaultOrgDriverForm(): OrgDriver {
    const orgDriver = this.props.orgDriver;
    if (orgDriver) {
      return {
        id: orgDriver && orgDriver.id,
        org_id: orgDriver && orgDriver.org_id,
        name: orgDriver && orgDriver.name,
        email:
          (orgDriver && orgDriver.email) ||
          `${orgDriver.id.split('-')[0]}@gotsurge-noemail.com`,
        full_name: (orgDriver && orgDriver.full_name) || orgDriver.name,
        partial_id_chars: orgDriver && orgDriver.partial_id_chars,
        phone_number: orgDriver && orgDriver.phone_number,
        driver_id: orgDriver && orgDriver.driver_id ? orgDriver.driver_id : '',
      };
    }
  }

  componentDidMount(): void {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.orgDriver !== this.props.orgDriver) {
      this.setState({
        form: this.getDefaultOrgDriverForm(),
      });
    }
  }

  defaultFilter = (): Record<string, string> => ({
    page: '1',
  });

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new OrganizationManagementClient();
    const orgDriverClient = new OrganizationDriverClient();
    await client.sysAdminGetOrganization(this.props.match.params.id);
    await orgDriverClient.sysAdminGetOrganizationDriver(this.props.match.params.driverId);

    this.setState({ isFetching: false });
  };

  handleCreateProfile = async (): Promise<void> => {
    const { ...form } = this.state.form;
    const driverId = this.props.orgDriver.id;

    try {
      const client = new AuthClient();
      await client.sysAddOrgDriver({
        user: {
          org_driver_id: form.id,
          name: form.name,
          partial_id_chars: form.partial_id_chars,
          email: form.email,
          phone_number: form.phone_number,
          org_id: form.org_id,
          password: form.id.split('-')[0],
          vehicle_type: 'lorry_24ft', // TODO: Change later with UI
        },
      });

      this.setState({ isDriverAppCreated: true });

      this.props.history.push(
        `/sys/organizations/${this.props.match.params.id}/driver/${driverId}`
      );
    } catch (e) {
      console.error(e.message);
    }
  };

  renderContent = (): React.ReactNode => {
    const { form } = this.state;

    return (
      <CustomCard>
        <Row>
          <PersonalInfoColumn>
            <FieldView fieldName="Name">{form && form.name}</FieldView>
            <FieldView fieldName="Full Name as per NRIC / FIN">
              {form && form.full_name}
            </FieldView>
            <FieldView fieldName="Mobile No.">{form && form.phone_number}</FieldView>
            <FieldView fieldName="Email">{form && form.email}</FieldView>
            <FieldView fieldName="Last 4 characters of NRIC / FIN">
              {form && form.partial_id_chars}
            </FieldView>
          </PersonalInfoColumn>
        </Row>
      </CustomCard>
    );
  };

  render(): React.ReactNode {
    const { form, isDriverAppCreated } = this.state;
    return (
      <SysAdminMainContainer selected="organizations">
        <Breadcrumb
          items={[
            {
              text: 'Organizations',
              onClick: (): void => {
                this.props.history.push('/sys/organizations');
              },
            },
            {
              text: this.props.match.params.id,
              onClick: (): void => {
                this.props.history.push(
                  '/sys/organizations/' + this.props.match.params.id + '/company-details'
                );
              },
            },
            { text: 'Driver' },
          ]}
        />
        <Heading>
          <PageTitle>Organisations Info</PageTitle>
        </Heading>
        <SettingSidebarMenu
          selected={`/sys/organizations/${this.props.match.params.id}/driver`}
        >
          <Heading>
            <PageTitle>{form && form.name}</PageTitle>
            {!isDriverAppCreated && form && form.driver_id === '' && (
              <ActionButton
                buttonStyle="encourage"
                buttonType="neutral"
                onClick={(): void => {
                  this.props.history.push(
                    `/sys/organizations/${this.props.match.params.id}/driver/${this.props.match.params.driverId}/edit`
                  );
                }}
              >
                <Icon icon={faEdit} color={COLOR.black} />
                Edit
              </ActionButton>
            )}
            {(isDriverAppCreated || (form && form.driver_id !== '')) && (
              <ActionButton
                buttonType="primary"
                buttonStyle="caution"
                onClick={(): void => {
                  this.props.history.push(`/sys/drivers/${form.driver_id}`);
                }}
              >
                <Icon icon={faUser} color={COLOR.white} />
                View Driver-Partner App Detail
              </ActionButton>
            )}
            {!isDriverAppCreated &&
              form &&
              form.email &&
              // form.phone_number &&
              form.driver_id === '' && (
                <ActionButton
                  buttonType="primary"
                  buttonStyle="encourage"
                  onClick={this.handleCreateProfile}
                >
                  <Icon icon={faSync} color={COLOR.white} />
                  Create Driver-Partner App
                </ActionButton>
              )}
          </Heading>
          <CustomSeparator />
          {this.renderContent()}
        </SettingSidebarMenu>
      </SysAdminMainContainer>
    );
  }
}

const CustomSeparator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
  margin-bottom: 1.6875rem;
`;

const Icon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;
`;

function mapStateToProps(state: RootState): SysAdminOrgDriverDetailProps {
  return {
    organization: state.organization.organization,
    orgDriver: state.orgDriver.orgDriver,
  };
}

export default connect(mapStateToProps)(SysAdminOrgDriverDetail);
