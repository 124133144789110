import {
  RoleState,
  RoleActionTypes,
  RECEIVE_ROLES,
} from './roleTypes';

const initialState: RoleState = {
  roles: [],
};

function roleReducer(state: RoleState = initialState, action: RoleActionTypes): RoleState {
  switch(action.type) {
    case RECEIVE_ROLES:
      return { ...state, roles: action.roles };
    default:
      return state;
  }
}

export default roleReducer;