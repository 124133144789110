import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { RootState } from 'reduxActions/store';
import { CurrentUser } from 'models/auth';
import { connect } from 'react-redux';

interface StateProps {
  currentUser: CurrentUser;
}

type LoginRouteProps = StateProps & RouteProps;

// This Route is only accessible when the user is not login
const LoginRoute = (props: LoginRouteProps): JSX.Element => {
  const { component: Component, currentUser, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode =>
        currentUser ? (
          // TODO: Should redirect to /sys/ for SysAdmin
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

function mapStateToProps(state: RootState): StateProps {
  return {
    currentUser: state.auth.currentUser,
  };
}

export default connect(mapStateToProps)(LoginRoute);