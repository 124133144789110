import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';

interface StatusLabelProps {
  children: React.ReactNode;
  iconColor: string;
  noBackground?: boolean;
}

const StatusLabel = (props: StatusLabelProps): JSX.Element => (
  <Container>
    <Label noBackground={props.noBackground}>
      <Icon color={props.iconColor} icon={faCircle} />
      {props.children}
    </Label>
  </Container>
);

const Container = styled.div`
  display: flex;
`;

const Label = styled.div<{ noBackground?: boolean }>`
  align-items: center;
  background-color: ${(props): string => props.noBackground ? 'inherit' : COLOR.lightGrey};
  border-radius: 5px;
  display: flex;
  padding: ${(props): string => props.noBackground ? '0' : '0.375rem 0.5rem'};
`;

const Icon = styled(FontAwesomeIcon)<{ color: string }>`
  color: ${(props): string => props.color};
  font-size: 0.5rem;
  margin-right: 0.5rem;
`;

export default StatusLabel;