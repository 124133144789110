import * as React from 'react';
import { View, Text, Image, Link } from '@react-pdf/renderer';
import { styles } from './styles';
import { Attachment } from 'models/task';

function filterAttachmentsByExtension(attachments: Attachment[], excludeType: string[]) {
  return attachments.filter((attachment) => {
    const url = new URL(attachment.url);
    const pathname = url.pathname;
    const extension = pathname.split('.').pop();
    return !excludeType.includes(extension);
  });
}

interface ImageAttachmentProps {
  url: string;
}

const ImageAttachment = ({ url }: ImageAttachmentProps) => {
  return (
    <Link src={url}>
      <Image
        src={url}
        style={{
          width: '56px',
          height: '75px',
          marginRight: 6,
          marginBottom: 6,
          objectFit: 'cover',
        }}
      />
    </Link>
  );
};

interface AttachmentSectionProps {
  attachments: Attachment[];
}

const AttachmentSection = ({ attachments }: AttachmentSectionProps) => {
  const filteredAttachments = filterAttachmentsByExtension(attachments, [
    'text/csv',
    'pdf',
    'doc',
    'docx',
    'xlsx',
    'csv',
  ]);

  return (
    <View
      style={{
        flexDirection: 'column',
        marginRight: 4,
        marginTop: 8,
        marginBottom: 20,
      }}
    >
      <Text style={styles.titleSmall}>Attachments</Text>
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {filteredAttachments.length > 0 ? (
          <>
            {filteredAttachments.map((attachment) => (
              <ImageAttachment key={attachment.id} url={attachment.url} />
            ))}
          </>
        ) : (
          <Text style={styles.textMedium}>{'-'}</Text>
        )}
      </View>
    </View>
  );
};

export default AttachmentSection;
