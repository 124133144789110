import * as React from 'react';
import {
  CenterModal,
  Separator,
  StyledButton,
} from 'components';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import COLOR, { transparent } from 'constants/color';
import whatsAppLogo from 'assets/images/whatsapp@2x.png';
import telegramLogo from 'assets/images/telegram@2x.png';
import { shareSocialMedia } from 'utils/httpHelper';

interface ShareModalProps {
  closeModal: () => void;
  squadCode?: string;
}

const ShareModal = (props: ShareModalProps): JSX.Element => {
  const { squadCode, closeModal } = props;
  const [copySuccess, setCopySuccess] = React.useState(false);
  const templatePreview = React.useRef(null);

  const copyTemplate = (): void => {
    navigator.clipboard.writeText(templatePreview.current.innerText);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  return (
    <CenterModal
      rightButtonText="Close"
      rightButtonOnClick={closeModal}
      width="large"
    >
      <BoldText>
        Use our template to share your Squad code below.
      </BoldText>
      <TemplatePreview ref={templatePreview}>
        Hi!
        <br /><br />
        {'We\'d like to invite you to our driver-partner Squad on GotSurge.'}<br />
        We use GotSurge to broadcast delivery orders to freelance drivers digitally and securely.
        <br /><br />
        There will be no commission fees when you complete orders for your Squad.
        <br /><br />
        Download GotSurge Driver Partner from the app stores:<br />
        iPhone: <CustomLink target="_blank" href="https://i.gotsurge.co/dp-ios">i.gotsurge.co/dp-ios</CustomLink><br />
        Android: <CustomLink target="_blank" href="https://i.gotsurge.co/dp-android">i.gotsurge.co/dp-android</CustomLink>
        <br /><br />
        Remember to fill in this Squad code <b>{squadCode}</b> after you sign up.
        <br /><br />
        More on <CustomLink target="_blank" href="https://gotsurge.co">gotsurge.co/</CustomLink>
      </TemplatePreview>
      <ActionButtonContainer>
        <StyledButton
          buttonStyle="encourage"
          buttonType="neutral"
          onClick={copyTemplate}
        >
          <Icon icon={faCopy} />
          Copy text
        </StyledButton>
        <ActionButton
          onClick={(): void => shareSocialMedia('telegram', templatePreview.current.innerText)}
        >
          <TelegramImg src={telegramLogo} />
          <ActionButtonText>
            Open Telegram
          </ActionButtonText>
        </ActionButton>
        <ActionButton
          onClick={(): void => shareSocialMedia('whatsapp', templatePreview.current.innerText)}
        >
          <WhatsappImg src={whatsAppLogo} />
          <ActionButtonText>
            Open WhatsApp
          </ActionButtonText>
        </ActionButton>
      </ActionButtonContainer>
      <Separator />
      {copySuccess && (
        <ToastBackground
          onClick={(): void => {
            setCopySuccess(false);
          }}
        >
          <Toast>
            Copied!
          </Toast>
        </ToastBackground>
      )}
    </CenterModal>
  );
};

const BoldText = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;

const TemplatePreview = styled.div`
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLOR.midLightGrey};
  padding: 0.5rem;
  color: ${COLOR.midDarkGrey};
  margin-top: 0.6875rem;
  margin-bottom: 1.25rem;
`;

const CustomLink = styled.a`
  font-size: 1rem;
  text-decoration: underline;
  color: ${COLOR.blue};
  cursor: pointer;
`;

const Toast = styled.div`
  background-color: ${COLOR.white};
  border-radius: 0.5rem;
  box-shadow: 0px 4px 16px ${transparent('shadow', 0.8)};
  padding: 1rem;
  cursor: pointer;
`;

const ToastBackground = styled.div`
  align-items: center;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 40%;
  left: 45%;
  z-index: 10000;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${COLOR.blue};
  align-self: center;
  margin-right: 0.5rem;
`;

const ActionButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 1rem;
`;

const ActionButtonText = styled.div`
  margin-left: 0.375rem;
`;

const WhatsappImg = styled.img`
  width: 1rem;
`;

const TelegramImg = styled.img`
  width: 1rem;
`;

export default ShareModal;