import * as React from 'react';
import { CenterModal, RadioCard } from 'components';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import BROADCAST_PREFERENCES, {
  BROADCAST_PREFERENCE_DESCRIPTION,
} from 'constants/broadcastPreference';

interface Props {
  form: {
    delivery_date?: Date;
    broadcast_preference?: string;
  };
  disableFullEdit?: boolean;
  hasNoSquad?: boolean;
  onFormChange: (fieldName: string, value: string | Date) => void;
  vesselForm?: React.ReactNode;
}

const GeneralInfoForm = (props: Props): JSX.Element => {
  const broadcastPreference = props.form && props.form.broadcast_preference;
  if (!(props.form && props.form.delivery_date)) {
    props.onFormChange('delivery_date', new Date());
  }
  const [showNoSquadModal, setNoSquadModal] = React.useState(false);
  const history = useHistory();
  const disableFullEdit = props.disableFullEdit || false;

  const serviceList = [];
  for (const service in BROADCAST_PREFERENCES) {
    serviceList.push({
      value: service,
      label: BROADCAST_PREFERENCE_DESCRIPTION[service].label,
      description: BROADCAST_PREFERENCE_DESCRIPTION[service].description,
    });
  }

  return (
    <Card>
      <BroadcastPreferencesContainer>
        <RadioCard
          radioStyle={css`
            width: 20.5rem;
          `}
          label="Select a Service"
          isRequired
          options={[
            {
              value: BROADCAST_PREFERENCES.marine,
              label: BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.marine].label,
              description:
                BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.marine]
                  .description,
            },
            {
              value: BROADCAST_PREFERENCES.all,
              label:
                BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.all].label,
              description:
                BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.all].description,
            },
          ]}
          disabled={disableFullEdit}
          broadcast_preference={broadcastPreference}
          onChange={(value: string) => props.onFormChange('broadcast_preference', value)}
        />
      </BroadcastPreferencesContainer>
      {props.vesselForm ? (
        <VesselFormContainer>{props.vesselForm}</VesselFormContainer>
      ) : (
        false
      )}
      {showNoSquadModal && (
        <CenterModal
          leftButtonOnClick={(): void => {
            setNoSquadModal(false);
          }}
          leftButtonText="Back"
          rightButtonStyle="encourage"
          rightButtonType="secondary"
          rightButtonOnClick={(): void => {
            history.push('/setting/company/squad');
          }}
          rightButtonText="Invite member"
          title="You have no squad member yet"
        >
          Add your squad member or select another service.
        </CenterModal>
      )}
    </Card>
  );
};

export default GeneralInfoForm;

const Card = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${(props): string => props.theme.colors.gray_200};
  background-color: ${(props): string => props.theme.colors.base_white};
  box-shadow: 0px 2px 6px 0px ${(props): string => props.theme.colors.gray_200},
    0px 1px 1px 2px rgba(222, 222, 222, 0.06);
  padding: 1.875rem 2.125rem;
  margin-bottom: 1.75rem;
`;

const VesselFormContainer = styled.section`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const BroadcastPreferencesContainer = styled.section`
  margin-top: 1rem;
`;
