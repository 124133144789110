import * as React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import COLOR from 'constants/color';

const WIDTHS = {
  small: 'auto',
  medium: '7.25rem',
  large: '14rem',
  full: '100%',
};

const HEIGHTS = {
  small: 'auto',
  large: '2.75rem',
};

const BUTTON_STYLES = {
  primary: {
    encourage: {
      backgroundColor: COLOR.blue,
      borderColor: '',
      color: COLOR.white,
      hoverBackgroundColor: COLOR.blueCyan,
    },
    neutral: {
      backgroundColor: COLOR.yellow,
      borderColor: '',
      color: COLOR.darkGray,
      hoverBackgroundColor: COLOR.orange,
    },
    discourage: {
      backgroundColor: COLOR.red,
      borderColor: '',
      color: COLOR.white,
      hoverBackgroundColor: COLOR.rouge,
    },
  },
  secondary: {
    encourage: {
      backgroundColor: COLOR.white,
      borderColor: COLOR.blue,
      color: COLOR.blue,
      hoverBackgroundColor: COLOR.midWhiteGrey,
    },
    neutral: {
      backgroundColor: COLOR.white,
      borderColor: COLOR.neutral,
      color: COLOR.black,
      hoverBackgroundColor: COLOR.whiteGrey,
    },
    discourage: {
      backgroundColor: COLOR.white,
      borderColor: COLOR.red,
      color: COLOR.red,
      hoverBackgroundColor: COLOR.lightRed,
    },
  },
};

interface StyledButtonProps {
  bold?: boolean;
  isActive?: boolean;
  hasFullWidth?: boolean;
  buttonStyle?: keyof typeof BUTTON_STYLES.primary;
  height?: keyof typeof HEIGHTS;
  style?: FlattenSimpleInterpolation;
  width?: keyof typeof WIDTHS;
  buttonType?: keyof typeof BUTTON_STYLES;
  type?: string;
  onClick?: () => void;
}

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & StyledButtonProps;

const StyledButton = (props: ButtonProps): JSX.Element => (
  <Button
    {...props}
    type={props.type === undefined ? 'button' : props.type}
    onClick={props.isActive ? undefined : props.onClick}
  />
);

// TODO: Update the component to be more general and follow XD design
const Button = styled.button<ButtonProps>`
  ${(props): string => {
    const selectedStyle = props.buttonStyle && props.buttonType
      ? BUTTON_STYLES[props.buttonType][props.buttonStyle]
      : BUTTON_STYLES.secondary.neutral;
    let bgColor = selectedStyle.backgroundColor;
    let cursor = 'pointer';
    if (props.disabled) {
      bgColor = COLOR.neutral;
      cursor = 'not-allowed';
    }
    if (props.isActive) {
      bgColor = COLOR.whiteGrey;
    }

    return (
      `
        background-color: ${bgColor};
        border-color: ${props.isActive ? COLOR.blue : selectedStyle.borderColor};
        border-radius: 0.5rem;
        padding: 0.5rem 0.875rem;
        border-width: ${props.buttonType === 'primary' ? '0' : '1px'};
        color: ${props.disabled ? COLOR.grey : selectedStyle.color};
        cursor: ${cursor};
        font-size: 1rem;
        font-weight: ${props.bold ? '600' : '400'};
        line-height: 1.125em;
        height: ${props.height ? HEIGHTS[props.height] : HEIGHTS.small};
        width: ${props.hasFullWidth ? '100%' : (props.width ? WIDTHS[props.width] : WIDTHS.small)};
        ${props.style === undefined ? '' : props.style};
        border-style: ${props.disabled ? 'none' : 'solid'};
      
        :hover {
          ${!props.disabled && !props.isActive && `background-color: ${selectedStyle.hoverBackgroundColor}`};
          ${!props.disabled && !props.isActive && `border-color: ${COLOR.blue}`};
        }
      `
    );
  }}
`;

export default StyledButton;
