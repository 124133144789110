import * as React from 'react';
import styled from 'styled-components';
import { TableCellRenderEvent, ValueTypes } from './tableType';
import COLOR, { transparent } from 'constants/color';
import NavigationArrowButton from './button/navigationArrowButton';
import Checkbox from './input/checkbox';

interface TableProps<T> {
  columns: TableColumns;
  cellRenderer?: (e: TableCellRenderEvent<T> | object) => React.ReactNode;
  data: Array<T>;
  multipleSelect?: boolean;
  hideNavigationButton?: boolean;
  onToggleCheckbox?: (index: number) => void;
  onToggleCheckboxAll?: () => void;
  selected?: {
    [key: string]: ValueTypes;
  };
  renderHeader?: () => React.ReactNode;
  rowOnClick?: (id: string) => void;
  rowOnNavigationButtonClick?: (id: string) => void;
  isRenderColumnHeader: boolean;
  isRowHasBorderBottom: boolean;
  containerPaddingRight?: string;
}

interface TableColumns {
  [key: string]: string;
}

class Table<T extends { [index: string]: ValueTypes }> extends React.Component<
  TableProps<T>
> {
  static defaultProps = {
    isRenderColumnHeader: true,
    isRowHasBorderBottom: true,
    containerPaddingRight: '',
  };

  rowOnClick = (id: ValueTypes): void => {
    if (typeof id === 'string') {
      this.props.rowOnClick(id);
    }
  };
  rowOnNavigationButtonClick = (id: ValueTypes): void => {
    if (typeof id === 'string') {
      this.props.rowOnNavigationButtonClick(id);
    }
  };

  defaultCellRenderer = (value: ValueTypes): React.ReactNode => {
    if (React.isValidElement(value)) {
      return value;
    }

    switch (typeof value) {
      case 'boolean':
      case 'number':
      case 'string':
        return value.toString();
      case 'object':
        if (Array.isArray(value)) {
          return value.join(', ');
        }
        if (value) {
          return value.toString();
        }
        break;
    }

    return '';
  };

  renderColumnHeader = (columns: TableColumns, keys: Array<string>): JSX.Element => {
    return (
      <thead>
        <HeaderRow>
          {this.props.multipleSelect ? (
            <SelectTh>
              <Checkbox
                onClick={(): void => {
                  this.props.onToggleCheckboxAll();
                }}
                selected={
                  this.props.data.length > 0 &&
                  Object.keys(this.props.selected).length === this.props.data.length
                }
              />
            </SelectTh>
          ) : (
            false
          )}
          {keys.map((key) => (
            <StyledTh key={key} totalColumns={keys.length}>
              {columns[key]}
            </StyledTh>
          ))}
        </HeaderRow>
      </thead>
    );
  };

  renderRow = (keys: Array<string>, rowIndex: number): JSX.Element => {
    const { data = [], cellRenderer } = this.props;
    const row = data[rowIndex];

    const handleRowClick = (e: React.MouseEvent): void => {
      const target = e.target as HTMLElement;
      if (target.tagName !== 'BUTTON') {
        this.props.rowOnClick ? this.rowOnClick(row.id) : undefined;
      }
    };

    return (
      <Row
        key={rowIndex}
        onClick={handleRowClick}
        isRowHasBorderBottom={this.props.isRowHasBorderBottom}
      >
        {this.props.multipleSelect ? (
          <SelectTd
            onClick={(e: React.MouseEvent): void => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              onClick={(): void => {
                this.props.onToggleCheckbox(rowIndex);
              }}
              selected={
                this.props.selected[row.id as string] !== undefined &&
                this.props.selected[row.id as string] !== null
              }
            />
          </SelectTd>
        ) : (
          false
        )}
        {keys.map((key, cell) => (
          <StyledTd key={key} containerPaddingRight={this.props.containerPaddingRight}>
            {cellRenderer !== undefined
              ? cellRenderer({
                  column: cell,
                  key,
                  data: row,
                  row: rowIndex,
                  value: this.defaultCellRenderer(row[key]),
                  render: cellRenderer,
                })
              : this.defaultCellRenderer(row[key])}
          </StyledTd>
        ))}
        {!this.props.hideNavigationButton ? (
          <StyledTd>
            <NavigationArrowButton
              type="right"
              onClick={
                this.props.rowOnNavigationButtonClick
                  ? (): void => {
                      this.rowOnNavigationButtonClick(row.id);
                    }
                  : undefined
              }
            />
          </StyledTd>
        ) : (
          false
        )}
      </Row>
    );
  };

  renderBody = (keys: Array<string>): JSX.Element => {
    const { data } = this.props;

    return <tbody>{data && data.map((_, index) => this.renderRow(keys, index))}</tbody>;
  };

  render(): React.ReactNode {
    const { columns } = this.props;
    const keys = Object.keys(columns);

    return (
      <TableContainer>
        {this.props.renderHeader ? (
          <TableHeader>{this.props.renderHeader()}</TableHeader>
        ) : (
          false
        )}
        <StyledTable>
          {this.props?.isRenderColumnHeader
            ? this.renderColumnHeader(columns, keys)
            : null}
          {this.renderBody(keys)}
        </StyledTable>
      </TableContainer>
    );
  }
}

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const TableContainer = styled.div`
  overflow: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const HeaderRow = styled.tr`
  border-bottom: 1px solid ${COLOR.neutral};
  height: 1.125rem;
`;

const SelectTh = styled.th`
  font-size: 0.75rem;
  font-weight: 600;
  margin-right: 1rem;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-right: 1rem;
  text-align: left;
  vertical-align: middle;
  width: 1rem;
`;

const StyledTh = styled.th<{ totalColumns: number }>`
  font-size: 0.75rem;
  font-weight: 600;
  text-align: left;
  vertical-align: middle;
  width: ${(props): string => `${100 / props.totalColumns}%`};
  min-width: 6rem;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-right: 1rem;
`;

const Row = styled.tr<{ isRowHasBorderBottom?: boolean }>`
  border-bottom: ${({ isRowHasBorderBottom }): string =>
    isRowHasBorderBottom && `1px solid ${COLOR.neutral}`};
  cursor: ${(props): string => (props.onClick ? 'pointer' : 'default')};
  height: 3rem;
  vertical-align: middle;
`;

const SelectTd = styled.td`
  padding-bottom: 0.75rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  text-align: left;
  cursor: default;
`;

const StyledTd = styled.td<{ containerPaddingRight?: string }>`
  color: ${transparent('black', 0.75)};
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  padding-right: ${(props): string =>
    props.containerPaddingRight ? props.containerPaddingRight : '1rem'};
  text-align: left;
  min-width: 5rem;
`;

export default Table;
