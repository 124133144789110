import * as React from 'react';
import RunsheetClient from 'httpClients/runsheetClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { STATUS_DESCRIPTION, STATUS_COLOR } from 'constants/runsheetStatus';
import {
  Table, TableCellRenderEvent,
  SysAdminPagination, Dropdown, DatePicker, StatusLabel,
} from 'components';
import * as H from 'history';
import * as moment from 'moment';
import { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';

import { Runsheet } from 'models/runsheet';
import DateHelper from 'utils/dateHelper';
import COLOR from 'constants/color';
import BROADCAST_PREFERENCES, { BROADCAST_PREFERENCE_DESCRIPTION } from 'constants/broadcastPreference';
import RunsheetRTBClient from 'httpClients/runsheetRTBClient';
import { VEHICLE_PREFERENCE_DESCRIPTION } from 'constants/vehiclePreference';
import { formatPrice } from 'utils/formatter';
import { CheckIcon, FilterContainer, Button, TopButtonContainer } from './styles';

interface StateProps {
  models: Runsheet[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type RunsheetIndexProps = StateProps & HistoryProps;

class RunsheetIndex extends SysAdminPagination<Runsheet, RunsheetIndexProps> {
  constructor(props: RunsheetIndexProps) {
    super(props);

    this.state = {
      ...this.state,
      basePath: '/sys/runsheets',
      pluralModelName: 'runsheets',
      selectedTab: 'runsheets',
      filters: this.getDefaultFilter(),
    };
  }

  getDefaultFilter = (): Record<string, string> => {
    const currentDate = new Date();

    return ({
      page: '1',
      order: 'desc',
      runsheet_date: DateHelper.formatDate(currentDate),
    });
  }

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });
    const filters = { ...this.state.filters };
    const runsheetDate = DateHelper.formatString(this.state.filters.runsheet_date);

    const startDate = new Date(runsheetDate).setHours(0, 0, 0, 0);
    const endDate = new Date(runsheetDate).setHours(23, 59, 59, 999);

    filters.range_start_created_at = moment(startDate).utc().format();
    filters.range_end_created_at = moment(endDate).utc().format();
    delete filters.runsheet_date;

    const client = new RunsheetClient();
    await client.getRunsheets(new URLSearchParams(filters));

    this.setState({ isFetching: false });
  }

  handleStartBroadcast = async (id: string, versionRev: string): Promise<void> => {
    const client = new RunsheetRTBClient();
    await client.startBroadcast(id, {
      version_rev: versionRev,
    });
    setTimeout(() => {
      this.fetchData();
    }, 500);
  }

  renderFilter = (): React.ReactNode => (
    <FilterContainer>
      <Dropdown
        containerStyle={css`
          margin: 0 0.5rem;
        `}
        label="Sort by"
        options={[
          {
            value: 'desc',
            name: 'Newest First',
          }, {
            value: 'asc',
            name: 'Oldest First',
          },
        ]}
        onChange={(value: string): void => { this.onFilterChange('order', value); }}
        value={this.state.filters.order}
      />
      <DatePicker
        label='Date Created'
        enablePreviousDate
        containerStyle={css`
          margin-left: 0.375rem;
          background-color: ${COLOR.white}
        `}
        value={DateHelper.formatString(this.state.filters.runsheet_date)}
        onChange={(v: Date): void => { this.onFilterChange('runsheet_date', DateHelper.formatDate(v)); }}
      />
    </FilterContainer>
  )

  renderActionPanel = (): React.ReactNode => (
    <TopButtonContainer>
      <Button
        buttonStyle="encourage"
        buttonType="primary"
        icon={<FontAwesomeIcon icon={faPlus} />}
        fontWeight={400}
        onClick={(): void => { this.props.history.push('/sys/runsheets/new'); }}
      >
        Create Runsheet
      </Button>

      <Button
        buttonStyle="encourage"
        buttonType="primary"
        icon={<FontAwesomeIcon icon={faSearch} />}
        fontWeight={400}
        onClick={(): void => { this.props.history.push('/sys/runsheet-ready-to-broadcasts'); }}
      >
        Broadcasts
      </Button>
    </TopButtonContainer>
  )

  renderCell = (e: TableCellRenderEvent<{
    maxBroadcastAttempt: number;
    incrementAttempt: number;
    createdAt: string;
    id: string;
    name: string;
    totalTasks: number;
    currentStatus: string;
    broadcastPreference: string;
    lastBroadcastExpiredAt: string;
    driverName: string;
    versionRev: string;
    orgTransporter: string;
  }>): React.ReactNode => {
    if (e.key === 'driverName') {
      return (
        <div>
          {e.data.orgTransporter && (
            <div>
              {e.data.orgTransporter}
            </div>
          )}
          <div>
            {e.data.driverName}
          </div>
        </div>
      );
    }
    if (e.key === 'currentStatus') {
      return (
        <StatusLabel iconColor={e.data.currentStatus ? STATUS_COLOR[e.data.currentStatus] : ''}>
          {e.data.currentStatus && STATUS_DESCRIPTION[e.data.currentStatus]}
        </StatusLabel>
      );
    }
    if (e.key === 'priceIncrementAttempt' && e.data.currentStatus !== 'pending_broadcast' && e.data.maxBroadcastAttempt !== 0) {
      return (
        <>
          ({!e.data.incrementAttempt ? 0 : e.data.incrementAttempt}/{e.data.maxBroadcastAttempt})
        </>
      );
    }

    return <>{e.value}</>;
  };

  renderPrice = (runsheet: Runsheet): React.ReactNode => {
    let price: React.ReactNode;
    const currentStatus = runsheet && runsheet.current_status;
    const broadcastPreference = runsheet && runsheet.broadcast_preference;
    const currency = runsheet && runsheet.currency || '';
    const totalPrice = runsheet && runsheet.total_price || 0;
    const totalDriverPrice = runsheet && runsheet.total_driver_price || 0;

    if (broadcastPreference === BROADCAST_PREFERENCES.marine) {
      price = 'N/A';
    } else if (currentStatus === 'broadcasting') {
      price = `Broadcasting at ${formatPrice(currency, totalPrice)}`;
    } else if (currentStatus === 'expired') {
      price = `Last Broadcasted at ${formatPrice(currency, totalPrice)}`;
    } else if (currentStatus !== 'pending_broadcast'
      && currentStatus !== 'cancelled') {
      price = (
        <>
          <CheckIcon icon={faCheckCircle} />
          {formatPrice(currency, totalDriverPrice)}
        </>
      );
    }

    return price;
  }

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      createdAt: 'Created At',
      displayId: 'ID',
      name: 'Name',
      vehiclePreference: 'Vehicle Preference',
      totalTasks: 'Total Orders',
      currentStatus: 'Status',
      priceIncrementAttempt: 'Increment Attempt',
      broadcastPreferenceDescription: 'Broadcast Preference',
      lastBroadcastExpiredAt: 'Broadcast Expire',
      runsheetDriverPrice: 'Runsheet Price (Driver)',
      driverName: 'Driver Name',
    };

    const data = models ? models.map(runsheet => {
      const orgTransporter = runsheet.org_transporter &&
        runsheet.org_transporter.name;
      const orgTransporterDriver = runsheet.org_transporter &&
        runsheet.org_transporter.org_driver &&
        runsheet.org_transporter.org_driver.name;
      let driverName = orgTransporter ? orgTransporterDriver : (runsheet.driver && runsheet.driver.name);
      if (runsheet.broadcast_preference === 'marine') {
        const firstDriver = runsheet && runsheet.tasks && runsheet.tasks[0] && runsheet.tasks[0].driver && runsheet.tasks[0].driver.id;
        const hasMultipleDriver = !(runsheet.tasks || []).every((task) => {
          if (task.driver !== null) {
            return task.driver && task.driver.id === firstDriver;
          } else {
            return true;
          }
        });
        if (hasMultipleDriver) {
          driverName = 'Multiple';
        }
      }

      return (
        {
          createdAt: moment(runsheet.created_at).format('DD MMM YYYY HH:mm:ss A'),
          displayId: runsheet.id.split('-')[0],
          name: runsheet.name,
          vehiclePreference: VEHICLE_PREFERENCE_DESCRIPTION[runsheet.vehicle_preference],
          totalTasks: runsheet.total_tasks,
          currentStatus: runsheet.current_status,
          incrementAttempt: runsheet.increment_attempt,
          maxBroadcastAttempt: runsheet.max_broadcast_times - 1,
          broadcastPreferenceDescription:
          BROADCAST_PREFERENCE_DESCRIPTION[runsheet.broadcast_preference] &&
          BROADCAST_PREFERENCE_DESCRIPTION[runsheet.broadcast_preference].label,
          lastBroadcastExpiredAt:
            runsheet.current_status == 'broadcasting'
              ? moment(runsheet.last_broadcast_expired_at).format(
                  'DD MMM YYYY HH:mm:ss A'
                )
              : null,
          orgTransporter: orgTransporter,
          driverName: driverName,
          runsheetDriverPrice: this.renderPrice(runsheet),
          broadcastPreference: runsheet.broadcast_preference,
          versionRev: runsheet.version_rev,
          id: runsheet.id,
        });
    }) : [];

    return (
      <>
        <Table
          columns={columns}
          data={data}
          cellRenderer={this.renderCell}
          rowOnClick={(id: string): void => {
            this.props.history.push({
              pathname: '/sys/runsheets/' + id,
              state: { from: this.props.location.pathname },
            });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  models: state.runsheet.runsheets,
});

export default connect(mapStateToProps)(RunsheetIndex);
