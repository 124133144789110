import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { SysAdminMainContainer, Button } from 'components';
import AuthClient from 'httpClients/authClient';
import RoleClient from 'httpClients/roleClient';
import { Role } from 'models/role';
import { User } from 'models/auth';
import { RootState } from 'reduxActions/store';

type RouteProps = RouteComponentProps<{ id: string }>;

interface UserRoleAddProps {
  roles: Role[];
  user: User;
}

interface UserRoleAddState {
  selectedRoleId: string;
  user: User | null;
  error: string | null;
}

type Props = RouteProps & UserRoleAddProps;

class UserRoleAdd extends React.Component<Props, UserRoleAddState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      selectedRoleId: '',
      user: null,
    };
  }

  async componentDidMount(): Promise<void> {
    const { id } = this.props.match.params;

    const authClient = new AuthClient();
    await authClient.sysGetUser(id);

    if (this.props.user !== null) {
      await this.fetchRoles();
    }
  }

  async componentDidUpdate(prevProps: Props): Promise<void> {
    if (prevProps.user !== this.props.user && this.props.user !== null) {
      await this.fetchRoles();
    }
  }

  fetchRoles = async (): Promise<void> => {
    const roleClient = new RoleClient();
    await roleClient.getRoles(this.props.user.org_id);
  }

  addRole = async (): Promise<void> => {
    const client = new RoleClient();
    const { id } = this.props.match.params;

    try {
      await client.addRoleToUser(id, this.state.selectedRoleId);

      this.props.history.push('/sys/users');
    } catch(error) {
      this.setState({ error });
    }
  }

  render(): React.ReactNode {
    return (
      <SysAdminMainContainer selected="users">
        <Header>
          <Title>{`Add Role to User ${(this.props.user || {}).name}`}</Title>
          <Link to="/sys/users"><Button>Back</Button></Link>
        </Header>
        <div>
          <h3>{`Assigned Roles to ${(this.props.user || {}).name}`}</h3>
          {
            this.props.user !== null && (this.props.user.roles || []).length > 0 ?
              (this.props.user.roles || []).map(role => (
                <li key={role}>
                  {role}
                </li>
              ))
              : (
                <div>No Role Assigned yet</div>
              )
          }
          <h3>List roles</h3>
          <p>Select Role to assign to user</p>
          {
            this.state.error !== null
              ? <ErrorMessage>{this.state.error}</ErrorMessage>
              : null
          }
          <div>
            <select
              onChange={(e): void => { this.setState({ selectedRoleId: e.target.value }); }}
              value={this.state.selectedRoleId}
            >
              {(this.props.roles || []).length !== 0
                ? this.props.roles.map((role) => (
                <option key={role.id} value={role.id}>{role.role_name}</option>
              )) : false}
              <option value='' disabled hidden>Choose Roles</option>
            </select>
          </div>
          <Button onClick={this.addRole}>Add Roles to User</Button>
        </div>
      </SysAdminMainContainer>
    );
  }
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 0.625rem 0;
`;

function mapStateToProps(state: RootState, ownProps: RouteProps): UserRoleAddProps {
  const { id } = ownProps.match.params;

  return({
    roles: state.role.roles,
    user: state.auth.users.find(user => user.id === id) || null,
  });
}

export default connect(mapStateToProps)(UserRoleAdd);