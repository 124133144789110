import * as React from 'react';
import {
  PriceInfoCard,
  OrderTitle,
  RedText,
  ErrorAlert,
  RecommendPriceContainer,
  RecommendPriceText,
  RecommendPriceHelp,
  RecommendPriceButton,
  OrderIcon,
  TextBold,
  RecommendPriceAlert,
  PriceContent,
  inlineTextInputStyle,
  inlineDropdownStyle,
  CheckboxRememberPrice,
} from 'pages/order/styles';
import { TextInput, Checkbox, Dropdown, Alert } from 'components';
import { MerchantBaseForm } from 'models/task';
import {
  faArrowRight,
  faBinoculars,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import { ONE_SGD, ALLOWED_MIN_SGD, ALLOWED_MIN_PRICE } from 'constants/priceDetails';
import BROADCAST_PREFERENCES, { BROADCAST_MINUTES } from 'constants/broadcastPreference';
import { formatDuration } from 'utils/formatter';

interface PriceFormProps {
  baseForm: MerchantBaseForm;
  isFetchingPrice: boolean;
  onRecommendPrice: () => void;
  updateBaseForm: (fieldName: string, value: string | boolean | Date) => void;
  showRecommendPriceSuccess: boolean;
  showRecommendPriceInfo: boolean;
  showRecommendPriceError: boolean;
  rememberMySetting: boolean;
  onCheckboxRemember: () => void;
}

const RECOMMENDATION_ERROR =
  'Price recommendation is unavailable at the moment. Please try again in a few minutes.';

class PriceForm extends React.Component<PriceFormProps> {
  canRecommendPrice = (): boolean => {
    const { baseForm, showRecommendPriceInfo, isFetchingPrice } = this.props;
    return (
      showRecommendPriceInfo || baseForm.vehicle_preference === '' || isFetchingPrice
    );
  };

  renderRecommendPrice = (): React.ReactNode => {
    const {
      baseForm,
      onRecommendPrice,
      showRecommendPriceSuccess,
      showRecommendPriceInfo,
      showRecommendPriceError,
    } = this.props;
    return (
      <>
        <RecommendPriceContainer>
          <RecommendPriceText>
            <>Not sure what price to set?</>
            <RecommendPriceHelp>
              To get a price recommendation, addresses must be validated
            </RecommendPriceHelp>
          </RecommendPriceText>
          <RecommendPriceButton
            type="button"
            buttonType="neutral"
            buttonStyle="encourage"
            disabled={this.canRecommendPrice()}
            onClick={onRecommendPrice}
          >
            <OrderIcon
              color={this.canRecommendPrice() ? COLOR.grey : COLOR.blue}
              icon={
                this.canRecommendPrice() && baseForm.vehicle_preference !== ''
                  ? faCircleNotch
                  : faBinoculars
              }
            />
            Recommend Price
          </RecommendPriceButton>
        </RecommendPriceContainer>
        {showRecommendPriceSuccess ? (
          <RecommendPriceAlert status="success">
            <TextBold>
              We recommend prices based on current market conditions to increase
              likelihood of order acceptance. You can change the suggested prices if they
              do not fit your budget.
            </TextBold>
          </RecommendPriceAlert>
        ) : (
          false
        )}
        {showRecommendPriceInfo ? (
          <RecommendPriceAlert status="info">
            <TextBold>
              Valid addresses is required to get a price recommendation. Please fill in
              the correct address to get a price recommendation
            </TextBold>
          </RecommendPriceAlert>
        ) : (
          false
        )}
        {showRecommendPriceError ? (
          <ErrorAlert status="error">
            <TextBold>{RECOMMENDATION_ERROR}</TextBold>
          </ErrorAlert>
        ) : (
          false
        )}
      </>
    );
  };

  renderMinMaxPrice(): React.ReactNode {
    const { baseForm, updateBaseForm } = this.props;
    return (
      <>
        <div>
          Alert drivers starting from a base price per location that increases to your max
          budget until a driver is found.
        </div>
        <PriceContent>
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Minimum Price SGD"
              isRequired
              placeholder="5.00"
              onTextChange={(value: string): void => {
                updateBaseForm('min_price', value);
              }}
              type="number"
              width="medium"
              value={baseForm.min_price / ONE_SGD || ''}
            />
            <OrderIcon icon={faArrowRight} />
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Maximum Price SGD"
              isRequired
              placeholder="15.00"
              onTextChange={(value: string): void => {
                updateBaseForm('max_price', value);
              }}
              type="number"
              width="medium"
              value={baseForm.max_price / ONE_SGD || ''}
            />
          </div>
          {baseForm.max_price < baseForm.min_price && (
            <Alert status="error">
              Set a Maximum Price that is higher than the Minimum Price. Alternatively,
              set a lower Minimum Price.
            </Alert>
          )}
          {baseForm.max_price === baseForm.min_price && (
            <Alert status="info">
              There will be no increment, your orders will be broadcasted at this same
              price throughout.
            </Alert>
          )}
          {this.renderRecommendPrice()}
          <div>
            {/* <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Price Increment SGD"
              isRequired
              placeholder="1.00"
              onTextChange={(value: string): void => {
                this.updateBaseForm('increment', value);
              }}
              type="number"
              width="medium"
              value={baseForm.increment / ONE_SGD || ''}
              min={1}
              step="any"
              max={baseForm.max_price / ONE_SGD}
            /> */}
            <div>Price increment:</div>
            <Dropdown
              containerStyle={inlineDropdownStyle}
              disabled={baseForm.max_price <= baseForm.min_price}
              onChange={(value: string): void => updateBaseForm('increment', value)}
              width="small"
              height="large"
              options={[ONE_SGD, ONE_SGD * 2, ONE_SGD * 3].map((value: number) => ({
                name: `SGD ${(value / ONE_SGD).toFixed(2)}`,
                value: value.toString(),
              }))}
              value={baseForm.increment.toString()}
            />
            <span>every {BROADCAST_MINUTES} minutes</span>
          </div>
          {this.renderMaxAcceptanceTime(
            baseForm.min_price,
            baseForm.max_price,
            baseForm.increment
          )}
        </PriceContent>
      </>
    );
  }

  renderSinglePrice(): React.ReactNode {
    const { baseForm, updateBaseForm } = this.props;
    return (
      <>
        <div>Alert drivers at a fixed price per location until a driver is found.</div>
        <PriceContent>
          <div>
            <TextInput
              containerStyle={inlineTextInputStyle}
              fieldName="Price (SGD)"
              isRequired
              placeholder="5.00"
              onTextChange={(value: string): void => {
                updateBaseForm('min_price', value);
              }}
              type="number"
              width="medium"
              value={baseForm.min_price / ONE_SGD}
            />
          </div>
        </PriceContent>
        {this.renderRecommendPrice()}
      </>
    );
  }

  renderMaxAcceptanceTime = (
    minPrice: number,
    maxPrice: number,
    increment: number
  ): React.ReactNode => {
    if (minPrice < maxPrice) {
      const minutes =
        (Math.ceil((maxPrice - minPrice) / increment) + 1) * BROADCAST_MINUTES;

      return <article>Max order acceptance time: {formatDuration(minutes * 60)}</article>;
    }

    return false;
  };

  render(): React.ReactNode {
    const { baseForm, rememberMySetting, onCheckboxRemember } = this.props;
    const priceContent: React.ReactNode =
      baseForm.broadcast_preference === BROADCAST_PREFERENCES.all
        ? this.renderMinMaxPrice()
        : this.renderSinglePrice();

    return (
      <PriceInfoCard>
        <OrderTitle>
          Set your price <RedText>*</RedText>
        </OrderTitle>
        {priceContent}
        {baseForm.min_price < ALLOWED_MIN_PRICE ? (
          <ErrorAlert status="error">
            {`Enter a minimum price from SGD ${ALLOWED_MIN_SGD}.00.`}
          </ErrorAlert>
        ) : (
          false
        )}
        <CheckboxRememberPrice>
          <Checkbox onClick={onCheckboxRemember} selected={rememberMySetting}>
            Remember my price setting
          </Checkbox>
        </CheckboxRememberPrice>
      </PriceInfoCard>
    );
  }
}

export default PriceForm;
