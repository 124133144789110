import styled from 'styled-components';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, StyledButton } from 'components';

interface MainColumnProps {
  flex: string;
}

interface IconProps {
  color: string;
}

export const ActionButton = styled(StyledButton)`
  margin-left: 0.5rem;
`;

export const CustomCard = styled(Card)`
  border-radius: 0.5rem;
  border: 1px solid ${COLOR.neutral};
  padding: 1rem;
  background-color: ${COLOR.white};
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  };

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  margin-right: 2rem;
`;

export const Icon = styled(FontAwesomeIcon) <IconProps>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.375rem;
`;

export const InfoCard = styled(Card)`
  flex: 1;
  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  };
`;

export const Image = styled.img`
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3rem;
  border: 1px solid ${COLOR.black};
`;

export const MainColumn = styled(Column) <MainColumnProps>`
  flex: ${(props): string => props.flex};
`;

export const PersonalInfoColumn = styled(Column)`
  flex: 1;
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  };
`;

export const Row = styled.div`
  display: flex;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  color: ${COLOR.darkGray};
`;

export const VehicleInfoTitle = styled.div`
  display: flex;
  text-align: center;
`;

export const ErrorMessage = styled.div`
  color: ${COLOR.red};
`;

export const Label = styled.label`
  float: left;
  margin-bottom: 0px !important;
  font-size: 0.875rem;
`;

export const Required = styled.span`
  color: ${COLOR.red};
`;

export const AlertMessage = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

export const AlertDescription = styled.div`
  margin-top: 1.5rem;
  font-size: 1rem;
  color: ${COLOR.black};
`;