import * as React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';

interface OrderInfoProps {
  orgName: string;
  orderId: string;
  trackingId: string;
}

const OrderInfo = ({ orgName, orderId, trackingId }: OrderInfoProps) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        marginBottom: 16,
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          flexGrow: 9,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <Text style={[styles.textMediumBold, { marginRight: 6 }]}>Order ID:</Text>
          <Text style={styles.textMedium}>{trackingId || orderId.split('-')[0]}</Text>
        </View>
      </View>
      <View
        style={{
          position: 'relative',
          flexDirection: 'column',
          flexGrow: 1,
          marginLeft: 'auto',
          minWidth: '24%',
        }}
      >
        <Text style={[styles.textSmallBold, { marginBottom: 4 }]}>
          Deliver From / Company
        </Text>
        <View
          style={{
            position: 'absolute',
            top: 12,
            lineHeight: 1.2,
          }}
        >
          <Text style={styles.textSmall}>{orgName}</Text>
        </View>
      </View>
    </View>
  );
};

export default OrderInfo;
