import * as React from 'react';
import styled from 'styled-components';
import COLOR, { transparent } from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faFileCsv,
  faLink,
  faFile,
  IconDefinition,
  faImage,
  faFileExcel,
  faFileAlt,
  faFileWord,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';

interface FileInformationCardProps {
  name: string;
  onDeleteFile?: () => void;
  size?: number;
  link?: string;
  src?: string;
  fileType?: string;
}

type Props = FileInformationCardProps;

function formatBytes(bytes: number, decimals = 2): number | string {
  if (bytes === 0) return '0 b';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function formatIcon(fileName: string, fileLink: string): IconDefinition {
  const parts = fileName.split('.');
  const fileExtension = parts[parts.length - 1];
  const lowercasedExtension = fileExtension.toLowerCase();
  if (fileLink) return faLink;
  if (['png', 'jpg', 'jpeg', 'gif'].includes(lowercasedExtension)) {
    return faImage;
  }
  if (lowercasedExtension === 'pdf') {
    return faFilePdf;
  }
  if (['doc', 'docx'].includes(lowercasedExtension)) {
    return faFileWord;
  }
  if (['xls', 'xlsx'].includes(lowercasedExtension)) {
    return faFileExcel;
  }
  if (['csv'].includes(lowercasedExtension)) {
    return faFileCsv;
  }
  if (['xml'].includes(lowercasedExtension)) {
    return faFileAlt;
  }
  return faFile;
}

const FileInformationCard = (props: Props): JSX.Element => {
  const isNotImageExtension = ['text/csv', 'pdf', 'doc', 'docx', 'xlsx', 'csv'];
  let isImage =
    props.src && !isNotImageExtension.includes(props.src.split('.').pop().split('?')[0]);
  if (props.fileType) {
    isImage = props.fileType.includes('image');
  }
  return (
    <FileContainer clickable={!!props.src || !!props.link}>
      <FileInformationContainer
        onClick={
          props.src || props.link
            ? (): void => {
                window.open(props.link ? props.link : props.src, '_blank');
              }
            : undefined
        }
      >
        {isImage ? (
          <Thumbnail src={props.src} />
        ) : (
          <Icon icon={formatIcon(props.name, props.link)} />
        )}
        <FileTextContainer
          isImage={isImage}
          isShowDeleteIcon={typeof props.onDeleteFile === 'function'}
        >
          <FileNameText title={props.name}>{props.name}</FileNameText>
          {!isNaN(props.size) && !props.link && (
            <FileSizeText>{formatBytes(props.size)}</FileSizeText>
          )}
        </FileTextContainer>
      </FileInformationContainer>
      {props.onDeleteFile && (
        <DeleteIconWrapper onClick={props.onDeleteFile}>
          <Icon clickable icon={faTimes} />
        </DeleteIconWrapper>
      )}
    </FileContainer>
  );
};

const Thumbnail = styled.img`
  height: 1.5rem;
  max-width: 2rem;
`;

const Icon = styled(FontAwesomeIcon)<{ clickable?: boolean }>`
  color: ${COLOR.grey};
  cursor: ${(props): string => (props.clickable ? 'pointer' : 'initial')};
`;

const FileContainer = styled.div<{ clickable: boolean }>`
  position: relative;
  display: flex;
  box-shadow: ${transparent('grey', 0.2)} 0px 0px 0px 2px;
  border-radius: 0.75rem;
  width: 15rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
  margin-top: 0.5rem;
  cursor: ${(props): string => (props.clickable ? 'pointer' : 'initial')};
`;

const FileInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.75rem 1rem;
  width: 100%;
  flex-grow: 1;
  justify-content: c center;
  gap: 0.625rem;
`;

const FileTextContainer = styled.div<{ isImage: boolean; isShowDeleteIcon: boolean }>`
  padding-left: 0.25rem;
  overflow: hidden;
  width: ${(props): string =>
    props.isImage ? '60%' : props.isShowDeleteIcon ? '70%' : '80%'};
`;

const FileNameText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(props): string => props.theme.fontSizes.sm};
  color: ${(props): string => props.theme.colors.gray_700};
  line-height: 1.2;
  margin-bottom: 0.25rem;
`;

const FileSizeText = styled.div`
  font-size: ${(props): string => props.theme.fontSizes.sm};
  color: ${(props): string => props.theme.colors.gray_600};
`;

const DeleteIconWrapper = styled.span`
  position: absolute;
  right: 12px;
  top: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default FileInformationCard;
