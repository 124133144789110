import * as React from 'react';
import {
  TextInput,
  Searchbox,
  DashedLine,
  Typography,
  LocationTypeForm,
  DisplayField,
  SavedAddressButton,
} from 'components';
import { css } from 'styled-components';
import COLOR from 'constants/color';
import { GeoData, SavedGeoData } from 'models/geoService';
import {
  faCircle,
  faSearch,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Address, TimeWindow } from 'models/task';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import * as moment from 'moment';
import AddressContactModal, {
  AddressContactData,
  ShowSection,
} from 'pages/sys/task/modal/addressContactModal';
import {
  CardTitle,
  TitleDescription,
  Separator,
  CircleIcon,
  AlphabetContainer,
  OrderHeading,
  OrderTitle,
  Content,
  DateAndTimeContainer,
  DatePickerWrapper,
  AddressSeparator,
  FormErrorAlert,
  InlineErrorMessage,
  ErrorIcon,
  SelectContactButton,
  SelectContactText,
  SubTitleContainer,
  SubTitle,
  Calendar,
  InstructionsLabel,
  RecommendedBadge,
  PickupCardRow,
  inlineTextInputStyle,
} from 'pages/order/styles';
import { MerchantBaseForm } from 'models/task';
import DateHelper from 'utils/dateHelper';

interface PickupFormProps {
  baseForm: MerchantBaseForm;
  updateBaseForm: (fieldName: string, value: string | boolean | Date) => void;
  savedGeoDatas: SavedGeoData[];
  chooseSavedAddress: (
    type: 'pickup' | 'delivery',
    index: number,
    data: SavedGeoData
  ) => Promise<void>;
  onSelectAddress: (data: AddressContactData) => void;
  isEdit?: boolean;
  isEditableField?: (fieldName: string) => boolean;
  ops?: boolean;
}

interface PickupFormState {
  fromLocationSelected: boolean;
  showAddressContactModal: boolean;
  showOnly: ShowSection;
}

class PickupForm extends React.Component<PickupFormProps, PickupFormState> {
  static defaultProps = {
    ops: false,
  };
  constructor(props: PickupFormProps) {
    super(props);
    this.state = {
      showAddressContactModal: false,
      showOnly: false,
      fromLocationSelected: props.isEdit,
    };
  }

  isRangeValid = (timeWindow: TimeWindow): boolean => {
    let isRangeValid = true;
    const startDate = moment(timeWindow.start_time_utc);
    const endDate = moment(timeWindow.end_time_utc);
    if (startDate !== endDate) {
      isRangeValid = endDate.isSameOrAfter(startDate);
    }

    return isRangeValid;
  };

  isLocationValid = (geoData: GeoData): boolean => {
    return (
      geoData.street_address !== '' &&
      geoData.lat !== 0 &&
      geoData.lng !== 0 &&
      geoData.lat !== undefined &&
      geoData.lng !== undefined
    );
  };

  isAddressLocationValid = (address: Address): boolean =>
    this.isLocationValid({
      street_address: address.street_address,
      zip_code: address.zip_code,
      lat: address.latitude,
      lng: address.longitude,
    });

  renderTimeRangeError = (timeWindow: TimeWindow): React.ReactNode => {
    const isRangeInvalid =
      Object.values(timeWindow).every((v) => v !== '') && !this.isRangeValid(timeWindow);

    return (
      <>
        {isRangeInvalid && (
          <FormErrorAlert status="error" noBackground display="row">
            The start time must be earlier than the end time
          </FormErrorAlert>
        )}
      </>
    );
  };

  renderInstructionsLabel = (): React.ReactNode => {
    return (
      <InstructionsLabel>
        <>Instructions to Driver</>
        <RecommendedBadge>Recommended</RecommendedBadge>
      </InstructionsLabel>
    );
  };

  renderPickupAddressNotFound = (): React.ReactNode => {
    const { baseForm, isEditableField } = this.props;

    return (
      <div>
        {(baseForm.from_address.street_address || baseForm.from_address.building_name) &&
        isEditableField &&
        isEditableField('from_address.street_address') &&
        !this.isAddressLocationValid(baseForm.from_address) ? (
          <InlineErrorMessage>
            <ErrorIcon icon={faExclamationCircle} />
            Address not found
          </InlineErrorMessage>
        ) : (
          false
        )}
      </div>
    );
  };

  renderAddressContactModal = (): React.ReactNode => {
    const {
      baseForm: { broadcast_preference },
      baseForm,
      onSelectAddress,
      ops,
    } = this.props;
    const { showAddressContactModal } = this.state;
    return (
      <>
        {showAddressContactModal ? (
          <AddressContactModal
            broadcastPreferences={broadcast_preference}
            title="Collect From"
            type={ops ? 'ops' : 'merchant'}
            defaultForm={{
              address: baseForm.from_address,
              contact: baseForm.from_contact,
            }}
            closeModal={(): void => this.setState({ showAddressContactModal: false })}
            onConfirm={(data: AddressContactData): void => {
              onSelectAddress(data);
              this.setState((prevState) => ({
                ...prevState,
                fromLocationSelected: true,
              }));
            }}
            showOnly={this.state.showOnly}
          />
        ) : (
          false
        )}
      </>
    );
  };

  renderPickupLocation = (): React.ReactNode => {
    const { baseForm, updateBaseForm, chooseSavedAddress, isEditableField } = this.props;
    const { savedGeoDatas } = this.props;
    const isBroadcastPreferenceMarine =
      baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const latLngText =
      baseForm.from_address.latitude || baseForm.from_address.longitude
        ? `${baseForm.from_address.latitude}, ${baseForm.from_address.longitude}`
        : '';
    const locationTitle =
      baseForm.from_address.name_address || baseForm.from_address.building_name;
    const locationText =
      baseForm.from_location_type === 'land' || !isBroadcastPreferenceMarine
        ? [
            baseForm.from_address.street_address,
            baseForm.from_address.unit_number && baseForm.from_address.unit_number,
            baseForm.from_contact.berth_no && baseForm.from_contact.berth_no,
          ]
            .filter(Boolean)
            .join(', ')
        : latLngText;
    const isFromContactEmpty = Object.entries(baseForm.from_contact)
      .filter(([key]) => key !== 'berth_no' && (this.props.ops || key !== 'email'))
      .every(([, value]) => value === '');

    return (
      <>
        {isBroadcastPreferenceMarine ? (
          <>
            <LocationTypeForm
              value={this.state.fromLocationSelected && baseForm.from_location_type}
              onChange={(v) => {
                updateBaseForm('from_location_type', v);
                this.setState({
                  showAddressContactModal: true,
                  fromLocationSelected: false,
                  showOnly: v === 'land' ? 'address_details' : 'sea_address',
                });
              }}
              disabled={isEditableField && !isEditableField('from_location_type')}
            />
            {baseForm.from_location_type && this.state.fromLocationSelected ? (
              <>
                <DisplayField
                  label="Location"
                  width="half"
                  title={locationTitle}
                  text={locationText}
                  onButtonClick={() => {
                    this.setState({
                      showAddressContactModal: true,
                      showOnly:
                        baseForm.from_location_type === 'land'
                          ? 'address_details'
                          : 'sea_address',
                    });
                  }}
                  disabled={isEditableField && !isEditableField('pickup_location')}
                />
                {this.renderPickupAddressNotFound()}
                {baseForm.from_location_type === 'land' &&
                  isEditableField &&
                  isEditableField('pickup_location') && (
                    <>
                      <SavedAddressButton
                        savedGeoDatas={savedGeoDatas}
                        onChange={(v: SavedGeoData) => chooseSavedAddress('pickup', 0, v)}
                      />
                    </>
                  )}
                {isFromContactEmpty ? (
                  <div>
                    <Typography
                      as="p"
                      color="gray_900"
                      size="sm"
                      customStyle={{ marginTop: '1rem', marginBottom: '0.25rem' }}
                    >
                      Contact Details
                    </Typography>
                    <SelectContactButton
                      buttonStyle="encourage"
                      buttonType="neutral"
                      onClick={(): void => {
                        this.setState({
                          showAddressContactModal: true,
                          showOnly: 'contact_details',
                        });
                      }}
                      type="button"
                      disabled={
                        isEditableField && !isEditableField('pickup_contact_details')
                      }
                    >
                      <SelectContactText>Enter contact details...</SelectContactText>
                    </SelectContactButton>
                  </div>
                ) : (
                  <>
                    <DisplayField
                      label="Contact Details"
                      width="half"
                      title={[
                        baseForm.from_contact.company_name,
                        baseForm.from_contact.vehicle_name,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                      text={[
                        baseForm.from_contact.name,
                        baseForm.from_contact.phone,
                        this.props.ops ? baseForm.from_contact.email : '',
                      ]
                        .filter(Boolean)
                        .join(', ')}
                      onButtonClick={() => {
                        this.setState({
                          showAddressContactModal: true,
                          showOnly: 'contact_details',
                        });
                      }}
                      containerStyle={css`
                        margin-top: 0.5rem;
                      `}
                      disabled={
                        isEditableField && !isEditableField('pickup_contact_details')
                      }
                    />
                  </>
                )}
              </>
            ) : (
              false
            )}
          </>
        ) : (
          <>
            <Searchbox
              hideDropdown
              readOnly
              inputName="street-address"
              trailingIcon={faSearch}
              containerStyle={inlineTextInputStyle}
              fieldName="Postal Code or Street Address"
              isRequired
              disabled={
                isEditableField && !isEditableField('from_address.street_address')
              }
              handleSetFocus={(focused: boolean): void => {
                if (focused) {
                  this.setState({ showAddressContactModal: true, showOnly: false });
                }
              }}
              placeholder="Postal Code / Street Address"
              width="half"
              value={
                baseForm.from_address.name_address || baseForm.from_address.building_name
              }
              bottomLabel={
                baseForm.from_address.street_address && (
                  <SubTitleContainer>
                    <SubTitle>
                      {[
                        baseForm.from_address.street_address,
                        baseForm.from_address.unit_number &&
                          baseForm.from_address.unit_number,
                        baseForm.from_contact.berth_no && baseForm.from_contact.berth_no,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </SubTitle>
                    {Object.values(baseForm.from_contact).some((value) => value) && (
                      <SubTitle>
                        Contact:{' '}
                        {[
                          baseForm.from_contact.company_name,
                          baseForm.from_contact.vehicle_name,
                          baseForm.from_contact.name,
                          baseForm.from_contact.phone,
                        ]
                          .filter(Boolean)
                          .join(', ')}
                      </SubTitle>
                    )}
                  </SubTitleContainer>
                )
              }
            />
            {this.renderPickupAddressNotFound()}
            {isEditableField && isEditableField('from_address.street_address') && (
              <SavedAddressButton
                savedGeoDatas={savedGeoDatas}
                onChange={(v: SavedGeoData) => chooseSavedAddress('pickup', 0, v)}
              />
            )}
          </>
        )}
      </>
    );
  };

  render(): React.ReactNode {
    const { updateBaseForm, baseForm, isEditableField } = this.props;
    const isMarine = baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const instructionToDriver = isMarine
      ? 'E.g. Meet at the loading bay'
      : 'E.g. Meet at the lobby';
    return (
      <>
        <PickupCardRow>
          <CardTitle>
            <TitleDescription>Fill in Pickup & Delivery Details</TitleDescription>
          </CardTitle>
        </PickupCardRow>
        <Separator />
        <PickupCardRow>
          <CircleIcon color={COLOR.blue} icon={faCircle} />
          <AlphabetContainer>A.</AlphabetContainer>
          <OrderHeading>
            <OrderTitle>Collect From</OrderTitle>
          </OrderHeading>
        </PickupCardRow>
        <PickupCardRow>
          {baseForm.broadcast_preference !== BROADCAST_PREFERENCES.marine && (
            <DashedLine />
          )}
          {baseForm.broadcast_preference === BROADCAST_PREFERENCES.marine && (
            <DashedLine />
          )}
          <Content>
            <div>{this.renderPickupLocation()}</div>
            <div>
              <DateAndTimeContainer>
                <DatePickerWrapper
                  minDate={moment().toDate()}
                  dateFormat="dd MMM, HH:mm"
                  timeFormat="HH:mm"
                  onChange={(value: Date): void => {
                    if (value instanceof Date) {
                      updateBaseForm('from_time_window.start_time_utc', value);
                      updateBaseForm(
                        'from_time_window.end_time_utc',
                        moment(value).add(1, 'hour').toDate()
                      );
                    }
                  }}
                  onSelect={(value: Date): void =>
                    updateBaseForm('from_time_window.start_time_utc', value)
                  }
                  selected={new Date(baseForm.from_time_window.start_time_utc)}
                  showTimeSelect
                  customInput={
                    <TextInput
                      autoComplete="none"
                      inputName="name"
                      containerStyle={inlineTextInputStyle}
                      isRequired
                      fieldName="Pickup Date & Time"
                      width="full"
                    />
                  }
                  calendarContainer={Calendar}
                  disabled={
                    isEditableField && !isEditableField('from_time_window.start_time_utc')
                  }
                />
                <TextInput
                  autoComplete="none"
                  containerStyle={inlineTextInputStyle}
                  fieldName={this.renderInstructionsLabel()}
                  onTextChange={(value: string): void => {
                    updateBaseForm('pickup_note_to_driver', value);
                  }}
                  placeholder={instructionToDriver}
                  width="half"
                  value={baseForm.pickup_note_to_driver}
                  disabled={isEditableField && !isEditableField('pickup_note_to_driver')}
                />
              </DateAndTimeContainer>
              {this.renderTimeRangeError(baseForm.from_time_window)}
              {baseForm.from_time_window.end_time_utc &&
                isEditableField &&
                isEditableField('from_time_window.start_time_utc') &&
                DateHelper.compareDateTime(
                  baseForm.from_time_window.end_time_utc,
                  new Date()
                ) < 0 && (
                  <FormErrorAlert status="error" noBackground display="row">
                    Set pickup time at least 1 hour before now
                  </FormErrorAlert>
                )}
            </div>
          </Content>
        </PickupCardRow>
        <PickupCardRow>
          <DashedLine />
          <AddressSeparator />
        </PickupCardRow>
        {this.renderAddressContactModal()}
      </>
    );
  }
}

export default PickupForm;
