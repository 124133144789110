const COLOR = {
  blackTransparent: 'rgba(52, 64, 84, .6)',
  black: 'rgba(0, 6, 41, 1)',
  blue: 'rgba(0, 24, 178, 1)',
  blueCyan: 'rgba(5, 71, 187, 1)',
  cyan: 'rgba(18, 205, 212, 1)',
  darkGray: 'rgba(58, 63, 89, 1)',
  grayishBlue: 'rgba(170, 171, 179, 1)',
  grey: 'rgba(170, 171, 180, 1)',
  lightGrey: 'rgba(238, 238, 238, 1)',
  lightRed: 'rgba(251,231,238,1)',
  midDarkGrey: 'rgba(116, 119, 136, 1)',
  midLightGrey: 'rgba(205, 205, 205, 1)',
  midWhiteGrey: 'rgba(235, 237, 249, 1)',
  neutral: 'rgba(222, 222, 222, 1)',
  orange: 'rgba(244, 148, 24, 1)',
  red: 'rgba(217, 22, 87, 1)',
  rouge: 'rgba(171, 64, 116, 1)',
  // red2 will be removed once old button is deprecated
  red2: '#db7093',
  shadow: 'rgba(69, 91, 99, 1)',
  white: 'rgba(255, 255, 255, 1)',
  whiteGrey: 'rgba(245, 247, 249, 1)',
  yellow: 'rgba(255, 196, 0, 1)',
  lime: 'rgba(50,205,50, 1)',
};

type ColorTypeList = keyof typeof COLOR;

export const transparent =  (colorName: ColorTypeList, opacity: number): string => {
  const selectedColor = COLOR[colorName];
  const lastIndex = selectedColor.lastIndexOf('1');

  return (selectedColor.slice(0, lastIndex) + opacity + selectedColor.slice(lastIndex + 1));
};

export default COLOR;