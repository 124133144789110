import {
  ContractPricingState, ContractPricingActionTypes,
  RECEIVE_CONTRACT_PRICINGS, RECEIVE_CONTRACT_PRICING,
} from './contractPricingTypes';

const initialState: ContractPricingState = {
  contractPricings: [],
  contractPricing: null,
};

function contractPricingReducer(
  state: ContractPricingState = initialState, action: ContractPricingActionTypes
): ContractPricingState {
  switch (action.type) {
    case RECEIVE_CONTRACT_PRICINGS:
      return {
        ...state,
        contractPricings: action.contractPricings,
      };
    case RECEIVE_CONTRACT_PRICING:
      return {
        ...state,
        contractPricing: action.contractPricing,
      };
    default:
      return state;
  }
}

export default contractPricingReducer;
