import * as React from 'react';
import * as H from 'history';
import {
  Separator as BasicSeparator,
  PageTitle,
  SysAdminPagination,
  PaginationState,
  StyledButton,
  SysAdminMainContainer,
  Breadcrumb,
  Table,
  Heading,
  NavigationArrowButton,
  TableCellRenderEvent,
} from 'components';
import { Organization, OrgDriver } from 'models/organization';
import emptyPlaceholder from 'assets/images/team_empty_state.png';
import AddDriverModal from './modal/addDriverModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import COLOR from 'constants/color';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import SettingSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import OrganizationDriverClient from 'httpClients/organizationDriverClient';
import AuthClient from 'httpClients/authClient';

interface SysAdminOrgDriverProps {
  organization: Organization;
  models: OrgDriver[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface OrgDriverState extends PaginationState<OrgDriver> {
  showAddDriverModal: boolean;
  selectedMemberId: string;
}

type Props = RouteComponentProps<{ id: string }> & SysAdminOrgDriverProps & HistoryProps;

class SysAdminOrgDriverIndex extends SysAdminPagination<
  OrgDriver,
  Props,
  OrgDriverState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      basePath: `/sys/organizations/${this.props.match.params.id}/driver`,
      showAddDriverModal: false,
      selectedMemberId: '',
      renderContainer: false,
      renderSortBy: false,
      filters: this.getDefaultFilter(),
      pluralModelName: 'users',
    };
    this.rules = [{ name: 'page' }];
  }

  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
    order: 'asc',
  });

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new OrganizationManagementClient();
    const orgDriverClient = new OrganizationDriverClient();
    await client.sysAdminGetOrganization(this.props.match.params.id);
    await orgDriverClient.sysAdminGetOrganizationDrivers(
      this.props.match.params.id,
      new URLSearchParams(this.state.filters)
    );

    this.setState({ isFetching: false });
  };

  handleConvertDriver = async (org_driver: OrgDriver): Promise<void> => {
    this.setState({ isFetching: true });
    const authClient = new AuthClient();
    try {
      await authClient.sysAddOrgDriver({
        user: {
          org_driver_id: org_driver.id,
          name: org_driver.name,
          partial_id_chars: org_driver.partial_id_chars,
          email: `${org_driver.id.split('-')[0]}@gotsurge-noemail.com`,
          phone_number: '',
          org_id: org_driver.org_id,
          password: org_driver.id.split('-')[0],
          vehicle_type: 'lorry_24ft', // TODO: Change later with UI
        },
      });
      this.fetchData();
    } catch (e) {
      console.error(e);
      return;
    }
    this.setState({ isFetching: false });
  };

  showAddDriverModal = (): void => {
    this.setState({ showAddDriverModal: true });
  };

  closeAddDriverModal = (): void => {
    this.setState({ showAddDriverModal: false });
  };

  closeAddDriverModalFetch = (): void => {
    this.fetchData();
    this.setState({ showAddDriverModal: false });
  };

  renderCell = (e: TableCellRenderEvent<{ action: OrgDriver }>): React.ReactNode => {
    if (e.key === 'action') {
      return (
        <ActionContainer>
          {e.data.action.driver_id === '' ? (
            <ConvertButton
              size="sm"
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={(): void => {
                this.handleConvertDriver(e.data.action);
              }}
              disabled={this.state.isFetching}
            >
              Convert
            </ConvertButton>
          ) : (
            <NavigationArrowButton
              type="right"
              onClick={() =>
                this.props.history.push('/sys/drivers/' + e.data.action.driver_id)
              }
            />
          )}
        </ActionContainer>
      );
    }

    return <>{e.value}</>;
  };

  renderContent = (): JSX.Element => {
    const { models } = this.props;

    const columns = {
      name: 'Name',
      driver_id: 'Driver ID',
      action: '',
    };

    const data = models.map((item) => ({
      id: item.id,
      name: item.name,
      driver_id: item.driver_id ? item.id.split('-')[0] : '',
      action: item,
    }));

    return (
      <>
        {this.renderPage(
          <Table
            cellRenderer={this.renderCell}
            columns={columns}
            data={data}
            hideNavigationButton
          />
        )}
      </>
    );
  };

  renderEmptyState = (): React.ReactNode => (
    <>
      {this.renderPage(
        <EmptyContainer>
          <Placeholder src={emptyPlaceholder} />
          <Subtitle>No drivers yet.</Subtitle>
          <Description>Add drivers</Description>
        </EmptyContainer>
      )}
    </>
  );

  renderAddDriverModal = (): React.ReactNode => {
    return (
      <>
        {this.state.showAddDriverModal && (
          <AddDriverModal
            orgId={this.props.match.params.id}
            closeModal={this.closeAddDriverModal}
            addDriverSuccess={this.closeAddDriverModalFetch}
          />
        )}
      </>
    );
  };

  renderPage = (content: JSX.Element): React.ReactNode => {
    return (
      <SysAdminMainContainer selected="organizations">
        <Breadcrumb
          items={[
            {
              text: 'Organizations',
              onClick: (): void => {
                this.props.history.push('/sys/organizations');
              },
            },
            {
              text: this.props.match.params.id,
              onClick: (): void => {
                this.props.history.push(
                  '/sys/organizations/' + this.props.match.params.id + '/company-details'
                );
              },
            },
            { text: 'Driver' },
          ]}
        />
        <Heading>
          <PageTitle>Organisations Info</PageTitle>
        </Heading>
        <SettingSidebarMenu
          selected={`/sys/organizations/${this.props.match.params.id}/driver`}
        >
          <Heading>
            <PageTitle>Driver</PageTitle>
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={this.showAddDriverModal}
            >
              <Icon icon={faPlusCircle} color={COLOR.black} />
              Add driver
            </StyledButton>
          </Heading>
          <CustomSeparator />
          <DriverOrgDriverLabel>
            {this.renderFilterAndPagination()}
            {this.renderAddDriverModal()}
          </DriverOrgDriverLabel>
          {content}
        </SettingSidebarMenu>
      </SysAdminMainContainer>
    );
  };
}

const CustomSeparator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
`;

const DriverOrgDriverLabel = styled.div`
  font-size: 0.875rem;
  margin-top: 1.5rem;
  margin-bottom: 0.3rem;
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  & > * {
    margin: 0.5rem;
  }
`;

const Placeholder = styled.img`
  width: 13.5rem;
`;

const Subtitle = styled.div`
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const ActionContainer = styled.div`
  float: right;
  margin-right: 1rem;
  display: flex;
  align-items: center;
`;

const ConvertButton = styled(StyledButton)`
  margin-left: 1rem;
`;

const Icon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;
`;

function mapStateToProps(state: RootState): SysAdminOrgDriverProps {
  return {
    organization: state.organization.organization,
    models: state.orgDriver.orgDrivers,
  };
}

export default connect(mapStateToProps)(SysAdminOrgDriverIndex);
