import * as React from 'react';
import styled from 'styled-components';
import COLOR from 'constants/color';

interface DashedLineState {
  height: number;
  iconHeight: number;
}

class DashedLine extends React.Component<Record<string, string>, DashedLineState> {
  constructor(props: Record<string, string>) {
    super(props);

    this.state = {
      height: 0,
      iconHeight: 0,
    };
  }

  render(): React.ReactNode {
    const oneDot = (
      <Dot
        key="0"
        ref={(ref: HTMLDivElement): void => {
          if(ref != null && this.state.iconHeight === 0) {
            const style = window.getComputedStyle(ref);
            this.setState({
              iconHeight: ref.offsetHeight + parseFloat(style.marginTop) });
          }
        }}
      />
    );

    const dash = [oneDot];

    if (this.state.height !== 0 && this.state.iconHeight !== 0) {
      const n = Math.floor(this.state.height / this.state.iconHeight);

      for (let i = 1; i < n; i++) {
        dash.push(
          <Dot key={i} />
        );
      }
    }

    return (
      <DashedLineContainer
        ref={(ref: HTMLDivElement): void => {
            if(ref != null && this.state.height === 0) {
              this.setState({ height: ref.offsetHeight });
          }
        }}
      >
        {dash}
      </DashedLineContainer>
    );
  }
}

const DashedLineContainer = styled.div`
  margin-bottom: -0.325rem;
  margin-left: 0.156rem;
  margin-top: -0.75rem;
`;

const Dot = styled.div`
  background-color: ${COLOR.grey};
  border-radius: 27px;
  height: 0.188rem;
  width: 0.188rem;
  margin: 0.375rem 0;
`;

export default DashedLine;