import * as React from 'react';
import Checkbox from './checkbox';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import COLOR, { transparent } from 'constants/color';

interface Option {
  name: string;
  value: string;
}

interface DropdownChecboxProps {
  containerStyle?: FlattenSimpleInterpolation;
  disabled?: boolean;
  onChangeSelected: (selectedValue: string) => void;
  onClearSelected?: () => void;
  options: Option[];
  label?: string;
  selectedValues: string[];
  withSearch?: boolean;
  sorted?: boolean;
}

interface DropdownCheckboxState {
  showDropdown: boolean;
  searchText: string;
}

class DropdownCheckbox extends React.Component<DropdownChecboxProps, DropdownCheckboxState> {
  dropdownRef: React.RefObject<HTMLDivElement>;

  constructor(props: DropdownChecboxProps) {
    super(props);

    this.state = {
      showDropdown: false,
      searchText: '',
    };

    this.dropdownRef = React.createRef();
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleOutsideClick = (event: MouseEvent): void => {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target as Node)) {
      this.setState({ showDropdown: false });
    }
  }

  onTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ searchText: event.target.value });
  }

  onIconClick = (): void => {
    if (this.state.searchText !== '') {
      this.setState({
        searchText: '',
      });
    }
  };

  render(): React.ReactNode {
    const { selectedValues } = this.props;
    const selectedText = selectedValues.length > 0 ? `${selectedValues.length} selected` : 'All';
    const filteredOptions = this.props.options
      .filter(option => option.name.toLowerCase().includes(this.state.searchText.toLowerCase()));
    if (this.props.sorted) {
      filteredOptions.sort((a, b) => a.name.localeCompare(b.name));
    }

    return (
      <Container containerStyle={this.props.containerStyle}>
        <div
          ref={this.dropdownRef}
          onClick={(): void => {
            if (!this.props.disabled) {
              this.setState({ showDropdown: true });
            }
          }}
        >
          <DropdownButton
            disabled={this.props.disabled}
          >
            <SelectedText>
              {this.props.label ? this.props.label + ': ' : ''}{selectedText}
            </SelectedText>
            <FontAwesomeIcon icon={faAngleDown} />
          </DropdownButton>
          {
            this.state.showDropdown ? (
              <DropdownListContainer>
                <DropdownList withSearch={this.props.withSearch}>
                  {this.props.withSearch ? (
                    <DropdownSearch>
                      <Input
                        onChange={this.onTextChange}
                        placeholder="Search"
                        value={this.state.searchText}
                      />
                      <span onClick={this.onIconClick}>
                        <Icon
                          icon={this.state.searchText === '' ? faSearch : faTimes}
                        />
                      </span>
                    </DropdownSearch>
                  ) : false}
                  <DropdownItems withSearch={this.props.withSearch}>
                    {
                      selectedValues.length ? (
                        <ClearSelectionButton
                          onClick={this.props.onClearSelected}
                        >
                          Clear Selection
                        </ClearSelectionButton>
                      ) : false
                    }
                    {
                      filteredOptions.map(option => (
                        <DropdownItem
                          key={option.value}
                        >
                          <Checkbox
                            onClick={(e: React.MouseEvent): void => {
                              e.stopPropagation();
                              this.props.onChangeSelected(option.value);
                            }}
                            selected={selectedValues.includes(option.value)}
                          >
                            <DropdownItemText>
                              {option.name}
                            </DropdownItemText>
                          </Checkbox>
                        </DropdownItem>
                      ))
                    }
                  </DropdownItems>
                </DropdownList>
              </DropdownListContainer>
            ) : false
          }
        </div>
      </Container>
    );
  }
}

const SelectedText = styled.div`
  margin-right: 0.5rem;
`;

const Container = styled.div<{ containerStyle: FlattenSimpleInterpolation }>`
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
`;

const DropdownButton = styled.button`
  background-color: ${(props): string => props.disabled ? COLOR.neutral : COLOR.white};
  border: 1px solid ${COLOR.neutral};
  border-radius: 4px;
  color: ${transparent('black', 0.75)};
  cursor: ${(props): string => props.disabled ? 'default' : 'pointer'};
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  margin: 0 0.5rem;
  padding: 0.5rem 0.75rem;
  :hover {
    background-color: ${(props): string => props.disabled ? COLOR.neutral : COLOR.whiteGrey};
  }
`;

const DropdownListContainer = styled.div`
  height: 0;
  position: relative;
  width: 0;
`;

const DropdownList = styled.div<{ withSearch: boolean }>`
  background-color: ${COLOR.white};
  border: 1px solid ${transparent('shadow', 0.26)};
  border-radius: 4px;
  margin: 0 0.5rem;
  padding-left: 0.75rem;
  position: absolute;
  top: 0.125rem;
  min-width: 13rem;
  z-index: 15;
  max-height: ${(props): string => props.withSearch ? '14rem' : '12rem'};
  max-width: auto;
`;

const DropdownSearch = styled.div`
  height: 2rem;
  margin-right: 0.75rem;
`;

const DropdownItems = styled.div<{ withSearch: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: ${(props): string => props.withSearch ? '12rem' : '10rem'};
  overflow-y: scroll;
`;

const DropdownItem = styled.div`
  border-bottom: 1px solid ${COLOR.whiteGrey};
  padding: 0.75rem 0;

  :last-child {
    border-bottom: 0px;
  }

  display: flex;
`;

const DropdownItemText = styled.div`
  white-space: nowrap;
  font-size: 1rem;
`;

const ClearSelectionButton = styled.button`
  font-size: 0.75rem;
  cursor: pointer;
  border: none;
  background: ${transparent('black', 0)};
  color: ${COLOR.blue};
  margin-top: 0.6875rem;
  margin-bottom: 0.75rem;
  padding: 0;
  text-align: left;
`;

const Input = styled.input`
  border: 1px solid ${COLOR.blue};
  border-radius: 5px;
  background-color: ${COLOR.white};
  width: 95%;
  font-size: 0.75rem;
  padding-left: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-top: 0.2rem;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
  color: ${COLOR.grey};
  padding-right: 0.25rem;
  padding-left: 0.25rem;
`;

export default DropdownCheckbox;