import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import { GetContractPricingResponse, MetaContractPricing } from 'models/contractPricing';
import { getBearerToken } from 'utils/userHelper';
import { PER_PAGE } from 'constants/paginationMeta';
import { receiveContractPricings, receiveContractPricing } from 'reduxActions/contractPricing/contractPricingAction';

class ContractPricingClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.CONTRACT_PRICING_SERVICE_PROTOCOL;
    this.host = process.env.CONTRACT_PRICING_SERVICE_HOST;
    this.port = process.env.CONTRACT_PRICING_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();

      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  sysContractPricingUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/contract-pricing?${params}`;
  }

  sysGetContractPricingUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/contract-pricing/${id}`;
  }

  sysDeactivateContractPricingUrl(id: string): string {
    return `${this.sysGetContractPricingUrl(id)}/deactivate`;
  }

  sysActivateContractPricingUrl(id: string): string {
    return `${this.sysGetContractPricingUrl(id)}/activate`;
  }

  async sysGetContractPricings(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.sysContractPricingUrl(params), null);

    try {
      const response: AxiosResponse<MetaContractPricing> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveContractPricings(response.data.contract_pricings));
    } catch (error) {
      throw error.message;
    }
  }

  async sysGetContractPricing(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.sysGetContractPricingUrl(id));
    try {
      const response: AxiosResponse<GetContractPricingResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveContractPricing(response.data.contract_pricing));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysDeactivateContractPricing(id: string, versionRev: string): Promise<void> {
    const request = this.makeRequest('PUT', this.sysDeactivateContractPricingUrl(id), {
      version_rev: versionRev,
    });

    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysActivateContractPricing(id: string, versionRev: string): Promise<void> {
    const request = this.makeRequest('PUT', this.sysActivateContractPricingUrl(id), {
      version_rev: versionRev,
    });

    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }
}

export default ContractPricingClient;
