import * as React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';
import { Contact, Status } from 'models/task';
import Divider from './divider';

interface ReleasedToProps {
  toContact: Contact;
  status: Status;
}

const ReleasedTo = ({ toContact, status }: ReleasedToProps) => {
  const lighterInfoComplete =
    toContact?.lighter_boat_name && toContact?.lighter_company_name ? ', ' : '';
  return (
    <View
      break
      style={{
        flexDirection: 'column',
        marginBottom: 16,
        width: '100%',
      }}
    >
      <Text style={[styles.titleSmall, { marginBottom: 8 }]}>Released To</Text>
      <Divider />
      <Text style={[styles.textMedium, { marginTop: 6, marginBottom: 12 }]}>
        {status?.actual_contact_name ?? '-'}
      </Text>
      {toContact?.lighter_boat_name || toContact?.lighter_company_name ? (
        <>
          <Text style={styles.titleSmall}>Lighter</Text>
          <Text style={styles.textMedium}>
            {`${toContact?.lighter_boat_name ?? ''}${lighterInfoComplete}${toContact?.lighter_company_name ?? ''}`}
          </Text>
        </>
      ) : null}
    </View>
  );
};

export default ReleasedTo;
