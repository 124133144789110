import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import {
  GetRunsheetReadyToTransfersResponse,
  GetRunsheetReadyToTransferLogsResponse,
  RunsheetReadyToTransfer,
  CustomGroupMapper,
  CustomGroupMapperRequest,
} from 'models/runsheetReadyToTransfer';
import { receiveRunsheetReadyToTransferLogAllSuccess, receiveRunsheetReadyToTransferAllSuccess, receiveRunsheetReadyToTransfer } from 'reduxActions/runsheetReadyToTransfer/runsheetReadyToTransferActions';
import { PER_PAGE } from 'constants/paginationMeta';
import { getBearerToken } from 'utils/userHelper';

class RunsheetReadyToTransferClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.RUNSHEET_TO_TRANSFER_SERVICE_PROTOCOL;
    this.host = process.env.RUNSHEET_TO_TRANSFER_SERVICE_HOST;
    this.port = process.env.RUNSHEET_TO_TRANSFER_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  runsheetRTTUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/job?${params}`;
  }

  runsheetRTTDetailUrl(JobID: string): string {
    return `${this.getBaseUrl()}/sys/job/${JobID}`;
  }

  runsheetRTTLogUrl(JobID: string): string {
    return `${this.getBaseUrl()}/sys/job/${JobID}/log`;
  }

  customGroupUrl(): string {
    return `${this.getBaseUrl()}/sys/custom-group`;
  }

  customGroupDetailUrl(CustomGroupID: string): string {
    return `${this.getBaseUrl()}/sys/custom-group/${CustomGroupID}`;
  }

  runsheetRTTByRunsheetIdUrl(id: string, params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/job/runsheet/${id}?${params}`;
  }

  async getRunsheetReadyToTransfers(params = new URLSearchParams()): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', `${this.runsheetRTTUrl(params)}`);

    try {
      const response: AxiosResponse<GetRunsheetReadyToTransfersResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheetReadyToTransferAllSuccess(response.data.jobs));
    } catch (error) {
      throw error.message;
    }
  }

  async getRunsheetReadyToTransfer(id: string): Promise<RunsheetReadyToTransfer> {
    const request = this.makeRequest('GET', this.runsheetRTTDetailUrl(id));

    try {
      const response: AxiosResponse<RunsheetReadyToTransfer> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheetReadyToTransfer(response.data));
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  async getRunsheetReadyToTransferLog(JobID: string): Promise<void> {
    const request = this.makeRequest('GET', this.runsheetRTTLogUrl(JobID));

    try {
      const response: AxiosResponse<GetRunsheetReadyToTransferLogsResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheetReadyToTransferLogAllSuccess(response.data.logs));
    } catch (error) {
      throw error.message;
    }
  }

  async getCustomGroup(): Promise<CustomGroupMapper[]> {
    const request = this.makeRequest('GET', this.customGroupUrl(), {});

    try {
      const response: AxiosResponse<{ custom_groups: CustomGroupMapper[] }> = await axios(request);
      return response.data.custom_groups;
    } catch (error) {
      throw error.message;
    }
  }

  async getCustomGroupDetail(id: string): Promise<CustomGroupMapper> {
    const request = this.makeRequest('GET', this.customGroupDetailUrl(id), {});

    try {
      const response: AxiosResponse<CustomGroupMapper> = await axios(request);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  async updateCustomGroup(id: string, form: CustomGroupMapperRequest): Promise<CustomGroupMapper> {
    const request = this.makeRequest('PUT', this.customGroupDetailUrl(id), form);
    try {
      const response: AxiosResponse<CustomGroupMapper> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  async deleteCustomGroup(id: string): Promise<void> {
    const request = this.makeRequest('DELETE', this.customGroupDetailUrl(id));
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  async addCustomGroup(form: CustomGroupMapperRequest): Promise<string> {
    const request = this.makeRequest('POST', this.customGroupUrl(), form);
    try {
      const response: AxiosResponse<CustomGroupMapper> = await axios(request);
      return response.data.id;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  async getRunsheetRTTByRunsheetId(id: string, params = new URLSearchParams()): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', `${this.runsheetRTTByRunsheetIdUrl(id, params)}`);

    try {
      const response: AxiosResponse<GetRunsheetReadyToTransfersResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveRunsheetReadyToTransferAllSuccess(response.data.jobs));
    } catch (error) {
      throw error.message;
    }
  }
}

export default RunsheetReadyToTransferClient;
