import * as React from 'react';
import styled from 'styled-components';
import {
  Separator,
  PageTitle,
  SysAdminMainContainer,
  Breadcrumb,
  Heading,
  StyledButton,
  Table,
} from 'components';
import * as moment from 'moment';
import {
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import ConfigSidebarMenu from '../../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import mappingLogo from 'assets/images/surge_squad_logo.png';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomGroupMapper } from 'models/runsheetReadyToTransfer';
import RunsheetReadyToTransferClient from 'httpClients/runsheetRTTClient';

type Props = RouteComponentProps;

const SysAdminConfigTransferCustomGroupIndex = (props: Props): JSX.Element => {
  const [customGroups, setCustomGroups] = React.useState<CustomGroupMapper[]>([]);
  React.useEffect(() => {
    (async function (): Promise<void> {
      const client = new RunsheetReadyToTransferClient();
      const customGroups = await client.getCustomGroup();
      setCustomGroups(customGroups);
    })();
  }, []);

  const columns = {
    'group_code': 'Code',
    'updated_by': 'Updated By',
    'updated_at': 'Last Updated At',
  };

  const data = customGroups.map(item => ({
    id: item.id,
    group_code: item.group_code,
    updated_by: item.updated_by,
    updated_at: moment(item.updated_at).utc().format('DD MMM YYYY HH:mm:ss A'),
  }));

  return (
    <SysAdminMainContainer selected="custom_group_mapper">
      <Breadcrumb
        items={[
          { text: 'Configuration', onClick: (): void => { props.history.push('/sys/config'); } },
          { text: 'Custom Group Mapper' },
        ]}
      />
      <Heading>
        <PageTitle>Configuration</PageTitle>
      </Heading>
      <ConfigSidebarMenu selected="/sys/config/runsheet-ready-to-transfer/custom-group">
        <Heading>
          <PageTitle>
            Custom Group
          </PageTitle>
          <RemoveButtonContainer>
            <StyledButton
              type="button"
              buttonType="neutral"
              buttonStyle="encourage"
              onClick={(): void => {
                props.history.push('/sys/config/runsheet-ready-to-transfer/custom-group/new');
              }}
            >
              <Icon color={COLOR.blue} icon={faPlusCircle} />
              Add New Config
            </StyledButton>
          </RemoveButtonContainer>
        </Heading>
        <CustomSeparator />
        <InfoContainer>
          <InfoImg src={mappingLogo} />
          <InfoDescription>
            Mapping your custom field here!<br />
            For new config creation, you need to ask <b>Tech Team</b><br/>to get the correct Group Code & Field Code.
          </InfoDescription>
        </InfoContainer>
        <CustomSeparator />
        <CustomGroupLabel>
          Custom Group Configuration List
        </CustomGroupLabel>
        {customGroups.length > 0 ?
          (
            <Table
              columns={columns}
              data={data}
              rowOnClick={(id: string): void => { props.history.push('/sys/config/runsheet-ready-to-transfer/custom-group/' + id); }}
            />
          ) : (
            <EmptyPlaceholder>
              No config files yet.
            </EmptyPlaceholder>
          )}
      </ConfigSidebarMenu>
    </SysAdminMainContainer>
  );
};

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
`;

const InfoContainer = styled.div`
  margin-top: 1.5rem;
  background-color: ${COLOR.midWhiteGrey};
  display: flex;
  border-radius: 13px;
  align-items: center;
`;

const InfoImg = styled.img`
  width: 3.875rem;
  padding-left: 1.4rem;
  padding-top: 1.08rem;
  padding-bottom: 0.89rem;
`;

const InfoDescription = styled.div`
  color: ${COLOR.darkGray};
  padding-left: 1.78rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const CustomGroupLabel = styled.div`
  font-size: 0.875rem;
  margin-top: 1.5rem;
  margin-bottom: 0.3rem;
`;

const EmptyPlaceholder = styled.div`
  font-size: 1rem;
  margin-top: 0.3125rem;
`;

const RemoveButtonContainer = styled.div`
  float: right;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;
`;

export default SysAdminConfigTransferCustomGroupIndex;