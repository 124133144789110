import { RunsheetReadyToTransferState, RunsheetReadyToTransferActionTypes, RECEIVE_RUNSHEET_READY_TO_TRANSFERS, RECEIVE_RUNSHEET_READY_TO_TRANSFER, RECEIVE_RUNSHEET_READY_TO_TRANSFER_LOGS } from './runsheetReadyToTransferTypes';

const initialState: RunsheetReadyToTransferState = {
  runsheetReadyToTransferLogs: [],
  runsheetReadyToTransfers: [],
  runsheetReadyToTransfer: null,
};

function runsheetReadyToTransferReducer(
  state: RunsheetReadyToTransferState = initialState, action: RunsheetReadyToTransferActionTypes
): RunsheetReadyToTransferState {
  switch (action.type) {
    case RECEIVE_RUNSHEET_READY_TO_TRANSFER_LOGS:
      return { ...state, runsheetReadyToTransferLogs: action.runsheetReadyToTransferLogs };
    case RECEIVE_RUNSHEET_READY_TO_TRANSFERS:
      return { ...state, runsheetReadyToTransfers: action.runsheetReadyToTransfers };
    case RECEIVE_RUNSHEET_READY_TO_TRANSFER:
      return { ...state, runsheetReadyToTransfer: action.runsheetReadyToTransfer };
    default:
      return state;
  }
}

export default runsheetReadyToTransferReducer;
