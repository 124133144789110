import { TaskImportJob, TaskImportJobResult } from 'models/taskImport';
import {
  ReceiveTaskImportJobs,
  ReceiveTaskImportJob,
  ReceiveTaskImportJobResult,
  UpdateInProgressStatus,
  RECEIVE_TASK_IMPORT_JOBS,
  RECEIVE_TASK_IMPORT_JOB,
  RECEIVE_TASK_IMPORT_JOB_RESULT,
  UPDATE_IN_PROGRESS_STATUS,
} from './taskImportTypes';

export function receiveTaskImportJobs(
  taskImportJobs: TaskImportJob[]
): ReceiveTaskImportJobs {
  return {
    type: RECEIVE_TASK_IMPORT_JOBS,
    taskImportJobs,
  };
}

export function receiveTaskImportJob(
  taskImportJob: TaskImportJob
): ReceiveTaskImportJob {
  return {
    type: RECEIVE_TASK_IMPORT_JOB,
    taskImportJob,
  };
}

export function receiveTaskImportJobResult(
  taskImportJobResult: TaskImportJobResult
): ReceiveTaskImportJobResult {
  return {
    type: RECEIVE_TASK_IMPORT_JOB_RESULT,
    taskImportJobResult,
  };
}

export function updateInProgressStatus(id: string): UpdateInProgressStatus {
  return {
    type: UPDATE_IN_PROGRESS_STATUS,
    id,
  };
}