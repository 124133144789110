export const CARGO_TYPES = [
  {
    name: 'Standard Pallet',
    value: 'standard_pallet',
  },
  {
    name: 'Life Raft',
    value: 'life_raft',
  },
  {
    name: 'Cold Bin',
    value: 'cold_bin',
  },
  {
    name: 'Cage',
    value: 'cage',
  },
  {
    name: 'Drum',
    value: 'drum',
  },
  {
    name: 'Long Item (8 - 12ft)',
    value: 'long_item_8_12_ft',
    description: 'Tubes, furniture, large machines, equipment',
  },
  {
    name: 'Extra Long Item (>12ft)',
    value: 'extra_long_item_above_12_ft',
    description: 'Pipes, rods, metal bars, beam, industrial rolls',
  },
  {
    name: 'Others',
    value: 'others',
  },
];


interface CargoTypeDescription {
  [key: string]: string;
}

export const CARGO_TYPES_DESCRIPTION: CargoTypeDescription = {
  standard_pallet: 'Standard Pallet',
  life_raft: 'Life Raft',
  cold_bin: 'Cold Bin',
  cage: 'Cage',
  drum: 'Drum',
  long_item_8_12_ft: 'Long Item (8 - 12ft)',
  extra_long_item_above_12_ft: 'Extra Long Item (>12ft)',
  others: 'Others',
};