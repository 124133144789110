import * as React from 'react';
import * as moment from 'moment';
import { SidebarMenu } from 'components';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Separator } from 'components';
import { Organization } from 'models/organization';
import { RootState } from 'reduxActions/store';
import defaultUserCompanyLogo from 'assets/images/default_user_company_logo.png';

interface MainMenu {
  title: string;
  submenu: Submenu[];
}

interface Submenu {
  title: string;
  path: string;
}

interface StateProps {
  organization: Organization;
}

interface SidebarMenuProps extends StateProps {
  header?: React.ReactNode;
  mainMenu?: MainMenu[];
  footer?: React.ReactNode;
  selected?: string;
  children?: React.ReactNode;
}

const OrganizationSidebarMenu = (props: SidebarMenuProps): JSX.Element => {
  const orgId = (props.organization && props.organization.id) || '';
  return (
    <SidebarMenu
      {...props}
      header={
        <>
          <CompanyContainer>
            <CompanyImageContainer>
              <CompanyImage src={defaultUserCompanyLogo} />
            </CompanyImageContainer>
            <CompanyInfoContainer>
              <CompanyName>
                {props.organization && props.organization.business_name}
              </CompanyName>
              <CreatedDate>
                Created on{' '}
                {props.organization &&
                  moment(props.organization.created_at).format('DD MMM YYYY')}
              </CreatedDate>
            </CompanyInfoContainer>
          </CompanyContainer>
          <Separator />
        </>
      }
      mainMenu={[
        {
          title: 'Company',
          submenu: [
            {
              title: 'Company details',
              path: `/sys/organizations/${orgId}/company-details`,
            },
            {
              title: 'Driver-Partners pref and price',
              path: `/sys/organizations/${orgId}/default-price`,
            },
            {
              title: 'Team',
              path: `/sys/organizations/${orgId}/team`,
            },
            {
              title: 'Squad',
              path: `/sys/organizations/${orgId}/squad`,
            },
            {
              title: 'Driver',
              path: `/sys/organizations/${orgId}/driver`,
            },
            {
              title: 'Saved Addresses',
              path: `/sys/organizations/${orgId}/saved-address`,
            },
          ],
        },
      ]}
    />
  );
};

const CompanyContainer = styled.div`
  display: flex;
  margin-top: 0.4rem;
  margin-bottom: 1.5rem;
`;

const CompanyInfoContainer = styled.div`
  margin-left: 0.6875rem;
`;

const CompanyImageContainer = styled.div`
  display: flex;
`;

const CompanyImage = styled.img`
  height: 2.875rem;
  width: 2.875rem;
`;

const CompanyName = styled.div`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.3rem;
`;

const CreatedDate = styled.div`
  font-size: 1rem;
  margin-bottom: 0.3rem;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  organization: state.organization.organization,
});

export default connect(mapStateToProps)(OrganizationSidebarMenu);
