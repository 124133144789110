import * as React from 'react';
import * as H from 'history';
import {
  Separator as BasicSeparator,
  Heading,
  PaginationState,
  SysAdminMainContainer,
  Breadcrumb,
  PageTitle,
  CenterModal,
  TextInput,
} from 'components';
import { CustomCard, PersonalInfoColumn, Row, ActionButton } from './styles';
import { Organization, OrgDriver } from 'models/organization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import COLOR from 'constants/color';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import SettingSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import OrganizationDriverClient from 'httpClients/organizationDriverClient';

interface SysAdminOrgDriverDetailProps {
  organization: Organization;
  orgDriver: OrgDriver;
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface SysAdminOrgDriverState extends PaginationState<OrgDriver> {
  form: OrgDriver;
  error: string | null;
  isChanged: boolean;
}

type Props = RouteComponentProps<{ id: string; driverId: string }> &
  SysAdminOrgDriverDetailProps &
  HistoryProps;

class SysAdminOrgDriverDetail extends React.Component<Props, SysAdminOrgDriverState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      form: this.getDefaultOrgDriverForm(),
      error: null,
      isChanged: false,
    };
  }

  getDefaultOrgDriverForm(): OrgDriver {
    const orgDriver = this.props.orgDriver;
    return {
      id: orgDriver && orgDriver.id ? orgDriver.id : '',
      org_id: orgDriver && orgDriver.org_id ? orgDriver.org_id : '',
      name: orgDriver && orgDriver.name ? orgDriver.name : '',
      email: orgDriver && orgDriver.email ? orgDriver.email : '',
      full_name: orgDriver && orgDriver.full_name ? orgDriver.full_name : '',
      partial_id_chars:
        orgDriver && orgDriver.partial_id_chars ? orgDriver.partial_id_chars : '',
      phone_number: orgDriver && orgDriver.phone_number ? orgDriver.phone_number : '',
      driver_id: orgDriver && orgDriver.driver_id ? orgDriver.driver_id : '',
    };
  }

  componentDidMount(): void {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.orgDriver !== this.props.orgDriver) {
      this.setState({
        form: this.getDefaultOrgDriverForm(),
      });
    }
  }

  defaultFilter = (): Record<string, string> => ({
    page: '1',
  });

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new OrganizationManagementClient();
    const orgDriverClient = new OrganizationDriverClient();
    await client.sysAdminGetOrganization(this.props.match.params.id);
    await orgDriverClient.sysAdminGetOrganizationDriver(this.props.match.params.driverId);

    this.setState({ isFetching: false });
  };

  handleChange = (fieldName: string, value: string): void => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [fieldName]: value,
      },
      isChanged: true,
    }));
  };

  renderContent = (): React.ReactNode => {
    const { form } = this.state;

    return (
      <CustomCard>
        <Row>
          <PersonalInfoColumn>
            <TextInput
              fieldName="Name"
              isRequired
              name="name"
              onTextChange={(value): void => this.handleChange('name', value)}
              type="text"
              value={form && form.name}
              width="half"
              height="small"
            />
            <TextInput
              fieldName="Full Name as per NRIC / FIN"
              name="full_name"
              onTextChange={(value): void => this.handleChange('full_name', value)}
              type="text"
              value={form && form.full_name}
              width="half"
              height="small"
            />
            <TextInput
              fieldName="Mobile No."
              name="phone_number"
              onTextChange={(value): void => this.handleChange('phone_number', value)}
              type="text"
              value={form && form.phone_number}
              width="half"
              height="small"
            />
            <TextInput
              fieldName="Email"
              name="email"
              onTextChange={(value): void => this.handleChange('email', value)}
              type="text"
              value={form && form.email}
              width="half"
              height="small"
            />
            <TextInput
              fieldName="Last 4 characters of NRIC / FIN"
              name="partial_id_chars"
              onTextChange={(value): void => this.handleChange('partial_id_chars', value)}
              type="text"
              value={form && form.partial_id_chars}
              width="half"
              height="small"
            />
          </PersonalInfoColumn>
        </Row>
      </CustomCard>
    );
  };

  validatePhoneNumber = (): boolean => {
    const pattern = new RegExp(/^([+\d]*)?\d$/);

    return pattern.test(this.state.form.phone_number);
  };

  handleSaveProfile = async (): Promise<void> => {
    const { isChanged } = this.state;
    const { ...form } = this.state.form;
    const driverId = this.props.orgDriver.id;

    if (form && form.phone_number && !this.validatePhoneNumber()) {
      this.setState({ error: 'Mobile number must be in this format \'+6581234567\'' });
      return;
    }

    try {
      const client = new OrganizationDriverClient();
      // If there is no changes, dont call update API
      if (isChanged) {
        await client.sysAdminUpdateOrgDriver(driverId, form);
      }

      this.props.history.push(
        `/sys/organizations/${this.props.match.params.id}/driver/${driverId}`
      );
    } catch (e) {
      const errorMessage = e.message ? e.message : 'Unknown error';
      this.setState({ error: errorMessage });
    }
  };

  render(): React.ReactNode {
    const { error, form } = this.state;
    return (
      <SysAdminMainContainer selected="organizations">
        <Breadcrumb
          items={[
            {
              text: 'Organizations',
              onClick: (): void => {
                this.props.history.push('/sys/organizations');
              },
            },
            {
              text: this.props.match.params.id,
              onClick: (): void => {
                this.props.history.push(
                  '/sys/organizations/' + this.props.match.params.id + '/company-details'
                );
              },
            },
            { text: 'Driver' },
          ]}
        />
        <Heading>
          <PageTitle>Organisations Info</PageTitle>
        </Heading>
        <SettingSidebarMenu
          selected={`/sys/organizations/${this.props.match.params.id}/driver`}
        >
          <Heading>
            <PageTitle>{form && form.name}</PageTitle>
            <ActionButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={(): void => {
                this.props.history.push(
                  `/sys/organizations/${this.props.match.params.id}/driver/${this.props.match.params.driverId}`
                );
              }}
            >
              <Icon icon={faTimes} color={COLOR.black} />
              Cancel
            </ActionButton>
            <ActionButton
              buttonType="primary"
              buttonStyle="encourage"
              onClick={this.handleSaveProfile}
            >
              <Icon icon={faCheck} />
              Save
            </ActionButton>
          </Heading>
          <CustomSeparator />
          {this.renderContent()}
          {error && (
            <CenterModal
              rightButtonText="Refresh"
              rightButtonOnClick={(): void => {
                window.location.reload();
              }}
            >
              {error}
            </CenterModal>
          )}
        </SettingSidebarMenu>
      </SysAdminMainContainer>
    );
  }
}

const CustomSeparator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
  margin-bottom: 1.6875rem;
`;

const Icon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props): string => props.color};
  font-size: 0.75rem;
  margin-right: 0.5rem;
  align-self: center;
`;

function mapStateToProps(state: RootState): SysAdminOrgDriverDetailProps {
  return {
    organization: state.organization.organization,
    orgDriver: state.orgDriver.orgDriver,
  };
}

export default connect(mapStateToProps)(SysAdminOrgDriverDetail);
