import * as React from 'react';
import { Link } from 'react-router-dom';
import { StyledButton, TextInput, Alert, BeforeLoginContainer } from 'components';
import styled from 'styled-components';
import AuthClient from 'httpClients/authClient';
import * as H from 'history';
import COLOR from 'constants/color';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

interface ResetPasswordProps<S = H.LocationState> {
  history: H.History<S>;
}

interface ResetPasswordState {
  form: {
    password: string;
    retypePassword: string;
  };
  passwordShown: boolean;
  retypePasswordShown: boolean;
  error: string | null;
  isSuccess: boolean;
}

class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
  constructor(props: ResetPasswordProps) {
    super(props);
    this.state = {
      form: {
        password: '',
        retypePassword: '',
      },
      passwordShown: false,
      retypePasswordShown: false,
      error: null,
      isSuccess: false,
    };
  }

  getUrlParams(): URLSearchParams {
    const { location } = this.props.history;
    if (!location.search) {
      return new URLSearchParams();
    }
    return new URLSearchParams(location.search);
  }

  getResetPasswordToken(): string {
    const queryParams = this.getUrlParams();
    return queryParams.get('reset_password_token') || '';
  }

  handleChange = (field: string, value: string): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  changePassword = async (): Promise<void> => {
    const { form } = this.state;

    this.setState({ error: null });
    try {
      const client = new AuthClient();
       await client.resetPassword({
        user: {
          password: form.password,
          reset_password_token: this.getResetPasswordToken(),
        },
      });
      this.setState({ isSuccess: true });
    } catch (e) {
      this.setState({ error: e });
    }
  }

  renderBeforeSuccess = (): React.ReactNode => {
    const { form, error, passwordShown, retypePasswordShown } = this.state;
    const pattern = new RegExp(/[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);

    return (
      <>
        <Div>
          <Description>
            Enter new password for your account.
          </Description>
        </Div>
        <Div>
          <TextInput
            fieldName="Password"
            icon={passwordShown ? faEye : faEyeSlash}
            iconOnClick={(): void => { this.setState(prevState => ({
              passwordShown: !prevState.passwordShown }));
            }}
            isRequired
            name="password"
            onTextChange={(value: string): void => this.handleChange('password', value)}
            type={passwordShown ? 'text' : 'password'}
            value={form.password}
            required
          />
          <InputDescription>
            Minimum 8 characters and at least 1 special character.
          </InputDescription>
        </Div>
        <Div>
          <TextInput
            fieldName="Retype Password"
            icon={retypePasswordShown ? faEye : faEyeSlash}
            iconOnClick={(): void => {
              this.setState(prevState => ({ retypePasswordShown: !prevState.retypePasswordShown }));
            }}
            isRequired
            name="retypePassword"
            onTextChange={(value: string): void => this.handleChange('retypePassword', value)}
            type={retypePasswordShown ? 'text' : 'password'}
            value={form.retypePassword}
            required
          />
        </Div>
        {error !== null && (
          <Div>
            <Alert status='error'>{error}</Alert>
          </Div>
        )}
        <StyledButton
          buttonStyle="encourage"
          buttonType="primary"
          disabled={form.password !== form.retypePassword || form.password.length < 8 || !pattern.test(form.password)}
          onClick={this.changePassword}
          size="lg"
          isFullWidth={true}
        >
          Change Password
        </StyledButton>
      </>
    );
  }

  renderAfterSuccess = (): React.ReactNode => (
    <>
      <Div>
        <Alert status='success'>
          Password change is successful
        </Alert>
      </Div>
      <Link to="/login">
        <StyledButton
          buttonStyle="encourage"
          buttonType="primary"
          size="lg"
          isFullWidth={true}
        >
          Return to login
        </StyledButton>
      </Link>
    </>
  )

  render(): React.ReactNode {
    return (
      <BeforeLoginContainer
        title={this.state.isSuccess ? 'Changed' : 'New password'}
      >
        {this.state.isSuccess ? this.renderAfterSuccess() : this.renderBeforeSuccess()}
      </BeforeLoginContainer>
    );
  }
}

const Div = styled.div`
  margin-bottom: 1.875rem;
`;

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const InputDescription = styled.div`
  color: ${COLOR.midDarkGrey};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

export default ResetPassword;
