interface CustomWindow extends Window {
  MSStream: unknown;
}

function checkIsIOS(): boolean {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as unknown as CustomWindow).MSStream;
}

export function redirectToStoreDriverPartner(isNewTab = false): void {
  const iosLink = 'https://i.gotsurge.co/dp-ios';
  const androidLink = 'https://i.gotsurge.co/dp-android';
  if (!isNewTab) {
    window.location.replace(checkIsIOS() ? iosLink : androidLink);
  } else {
    window.open(checkIsIOS() ? iosLink : androidLink, '_blank');
  }
}

export function redirectToStoreTransportPartner(isNewTab = false): void {
  const iosLink = 'https://apps.apple.com/id/app/gotsurge-transport-partner/id6448738798';
  const androidLink = 'https://play.google.com/store/apps/details?id=com.gotsurge.transport';
  if (!isNewTab) {
    window.location.replace(checkIsIOS() ? iosLink : androidLink);
  } else {
    window.open(checkIsIOS() ? iosLink : androidLink, '_blank');
  }
}

export function deepLinkDriverPartner(pageLocation = ''): void {
  let appLink = 'com.gotsurge.gotsurgemobileapps';
  if (process.env.ENVIRONMENT !== 'production' && process.env.ENVIRONMENT !== '') {
    appLink = `com.gotsurge.gotsurgemobileapps.${process.env.ENVIRONMENT}`;
  }
  window.open(`${appLink}://${pageLocation}`, '_self');
}

export function deepLinkTransportPartner(pageLocation = ''): void {
  let appLink = 'com.gotsurge.transport';
  if (process.env.ENVIRONMENT !== 'production' && process.env.ENVIRONMENT !== '') {
    appLink = `com.gotsurge.transport.${process.env.ENVIRONMENT}`;
  }
  window.open(`${appLink}://${pageLocation}`, '_self');
}