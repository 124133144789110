import * as React from 'react';
import { CenterModal, StyledButton } from 'components';
import styled from 'styled-components';
import COLOR, { transparent } from 'constants/color';
import { faCopy, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import whatsAppLogo from 'assets/images/whatsapp@2x.png';
import telegramLogo from 'assets/images/telegram@2x.png';
import { shareSocialMedia } from 'utils/httpHelper';

interface ShareOrderDetailModalProps {
  closeModal: () => void;
  title: string;
  header?: React.ReactNode;
  children: string | React.ReactNode;
  shareUrl?: string;
  showSharePOD?: boolean;
  handlePODClick?: () => void;
}

interface ShareOrderDetailModalState {
  showCopySuccess: boolean;
}

class ShareOrderDetailModal extends React.Component<
  ShareOrderDetailModalProps,
  ShareOrderDetailModalState
> {
  shareContent: React.RefObject<HTMLDivElement>;
  constructor(props: ShareOrderDetailModalProps) {
    super(props);

    this.state = {
      showCopySuccess: false,
    };
    this.shareContent = React.createRef();
  }

  copyTemplate = (): void => {
    navigator.clipboard.writeText(this.shareContent.current.innerText);
    this.setState({ showCopySuccess: true });
    setTimeout(() => {
      this.setState({ showCopySuccess: false });
    }, 3000);
  };

  render(): React.ReactNode {
    return (
      <CenterModal
        title={this.props.title}
        width="small"
        closeButtonOnClick={this.props.closeModal}
        bottomFloatingContent={
          this.props.showSharePOD ? (
            <SharePOD
              onClick={() => {
                this.props.handlePODClick();
              }}
            >
              Share Proof of Delivery Receipt
              <div>
                <Icon icon={faAngleRight} />
              </div>
            </SharePOD>
          ) : (
            false
          )
        }
      >
        {this.props.header}
        <TemplateContainer>
          <TemplatePreview ref={this.shareContent}>{this.props.children}</TemplatePreview>
          {this.state.showCopySuccess ? (
            <ToastBackground
              onClick={(): void => {
                this.setState({ showCopySuccess: false });
              }}
            >
              <Toast>Copied!</Toast>
            </ToastBackground>
          ) : (
            false
          )}
        </TemplateContainer>
        <ActionButtonContainer>
          <StyledButton
            size="md"
            buttonStyle="encourage"
            buttonType="neutral"
            fontWeight={400}
            onClick={this.copyTemplate}
            icon={<FontAwesomeIcon icon={faCopy} />}
          >
            Copy text
          </StyledButton>
          <ActionButton
            onClick={(): void =>
              shareSocialMedia(
                'telegram',
                this.shareContent.current.innerText,
                this.props.shareUrl
              )
            }
          >
            <TelegramImg src={telegramLogo} />
            <ActionButtonText>Open Telegram</ActionButtonText>
          </ActionButton>
          <ActionButton
            onClick={(): void =>
              shareSocialMedia(
                'whatsapp',
                this.shareContent.current.innerText,
                this.props.shareUrl
              )
            }
          >
            <WhatsappImg src={whatsAppLogo} />
            <ActionButtonText>Open WhatsApp</ActionButtonText>
          </ActionButton>
        </ActionButtonContainer>
      </CenterModal>
    );
  }
}

const Toast = styled.div`
  background-color: ${COLOR.white};
  border-radius: 0.5rem;
  box-shadow: 0px 4px 16px ${transparent('shadow', 0.8)};
  padding: 1rem;
  cursor: pointer;
`;

const ToastBackground = styled.div`
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TemplateContainer = styled.div`
  position: relative;
`;

const TemplatePreview = styled.div`
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLOR.midLightGrey};
  padding: 0.5rem;
  color: ${COLOR.midDarkGrey};
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;

  overflow-y: auto;
  max-height: 15rem;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

const ActionButtonText = styled.div`
  font-size: 14px;
  margin-left: 0.375rem;
`;

const WhatsappImg = styled.img`
  width: 1.2rem;
`;

const TelegramImg = styled.img`
  width: 1.2rem;
`;

const SharePOD = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${COLOR.grey};
  font-size: 1.3rem;
`;

export default ShareOrderDetailModal;
