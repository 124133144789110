import * as React from 'react';
import {
  Separator as BasicSeparator,
  PageTitle,
  StyledButton,
  SysAdminMainContainer,
  Breadcrumb,
  Heading,
  FieldView,
  Card,
  Radio,
  Alert,
} from 'components';
import { Organization, SquadDriver } from 'models/organization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import COLOR from 'constants/color';
import styled from 'styled-components';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import SettingSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import { faEdit, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { formatDuration, formatPrice } from 'utils/formatter';
import BROADCAST_PREFERENCES, { BROADCAST_PREFERENCE_DESCRIPTION, BROADCAST_MINUTES } from 'constants/broadcastPreference';
import { ONE_SGD, DEFAULT_SQUAD_PRICE, DEFAULT_MIN_PRICE, DEFAULT_MAX_PRICE } from 'constants/priceDetails';

interface SysAdminDefaultPriceProps {
  organization: Organization;
  squadDrivers: SquadDriver[];
}

type Props = RouteComponentProps<{ id: string }> & SysAdminDefaultPriceProps;

const renderSquadPrice = (props: Props): JSX.Element => {
  return (
    <>
      <ContentTitle>Set your price</ContentTitle>
      <Description>
        Alert drivers at a fixed price per location until a driver is found.
      </Description>
      {props.organization && (
        <>
          <PriceInfo>
            <CardRow>
              <FieldView
                fieldName="Price"
              >
                {formatPrice('SGD', props.organization.squad_price || DEFAULT_SQUAD_PRICE)}
              </FieldView>
            </CardRow>
          </PriceInfo>
        </>
      )}
    </>
  );
};

const renderAllDriversPrice = (props: Props): JSX.Element => {
  let increment = 0;
  if (props.organization?.price_table && props.organization.price_table.length > 1) {
    increment = props.organization.price_table[1].price - props.organization.price_table[0].price;
    if (increment <= ONE_SGD) {
      increment = ONE_SGD;
    } else if (increment <= ONE_SGD * 2) {
      increment = ONE_SGD * 2;
    } else if (increment <= ONE_SGD * 3) {
      increment = ONE_SGD * 3;
    }
  }

  let totalSeconds = 0;
  props.organization?.price_table.forEach(record => {
    totalSeconds += record.time_delta;
  });
  const minPrice = props.organization && props.organization.min_price || DEFAULT_MIN_PRICE;
  const maxPrice = props.organization && props.organization.max_price || DEFAULT_MAX_PRICE;

  return (
    <>
      <ContentTitle>Set your price</ContentTitle>
      <Description>
        Alert drivers starting from a base price per location that increases to your max budget until a driver is found.
      </Description>
      {props.organization && (
        <>
          <PriceInfo>
            <CardRow>
              <FieldView
                fieldName="Minimum Price"
              >
                {formatPrice('SGD', minPrice)}
              </FieldView>
              <Arrow icon={faArrowRight} />
              <FieldView
                fieldName="Maximum Price"
              >
                {formatPrice('SGD', maxPrice)}
              </FieldView>
            </CardRow>
          </PriceInfo>
          {minPrice === maxPrice ? (
            <Alert status="info">
              There will be no increment, your orders will be broadcasted at this same price throughout.
            </Alert>
          ) : (
            <>
              <div>
                Price increment: + <b>{formatPrice('SGD', increment)} every {BROADCAST_MINUTES} minutes</b>
              </div>
              <SmallDescription>
                Max order acceptance time: {formatDuration(totalSeconds)}
              </SmallDescription>
            </>
          )}
        </>
      )}
    </>
  );
};

const SysAdminDefaultPriceIndex = (props: Props): JSX.Element => {
  const [fetching, setFetching] = React.useState(false);
  React.useEffect(() => {
    setFetching(true);
    (async function (): Promise<void> {
      const client = new OrganizationManagementClient();
      await client.sysAdminGetOrganization(props.match.params.id);
      await client.sysAdminGetSquadDrivers(props.match.params.id, new URLSearchParams());
      setFetching(false);
    })();
  }, []);

  const { organization } = props;
  const [broadcastPreference, setBroadcastPreference] = React.useState(BROADCAST_PREFERENCES.all);
  const squadDriversLength = props.squadDrivers.length;
  const emptySquads = squadDriversLength === 0;

  React.useEffect(() => {
    if (squadDriversLength > 0) {
      setBroadcastPreference(BROADCAST_PREFERENCES.squad);
    } else {
      setBroadcastPreference(BROADCAST_PREFERENCES.all);
    }
  }, [props.squadDrivers]);

  const broadcastPreferences = [{
    text: (
      <>
        <RadioText>
          {BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.squad].label}
        </RadioText>
        <AdditionalInfo
          disabled
        >
          {emptySquads ? 'Broadcast orders to your Squad only at a fixed price.' :
            'Only your Squad will receive your orders.'}
        </AdditionalInfo>
      </>
    ),
    value: BROADCAST_PREFERENCES.squad,
    disabled: emptySquads,
  },
  {
    text: (
      <>
        <RadioText>
          {BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.all].label}
        </RadioText>
        <AdditionalInfo
          disabled
        >
          All Driver-Partners will receive orders at the same time. Note that 20% Commission fee will be deducted for non-squad Driver-Partners.
        </AdditionalInfo>
      </>
    ),
    value: BROADCAST_PREFERENCES.all,
    disabled: false,
  }];

  const priceContent: JSX.Element = broadcastPreference === BROADCAST_PREFERENCES.all ?
    renderAllDriversPrice(props) : renderSquadPrice(props);

  return (
    <SysAdminMainContainer selected="organizations">
      <Breadcrumb
        items={[
          { text: 'Organizations', onClick: (): void => { props.history.push('/sys/organizations'); } },
          {
            text: props.match.params.id,
            onClick: (): void => {
              props.history.push('/sys/organizations/' + props.match.params.id + '/company-details');
            },
          },
          { text: 'Default Price' },
        ]}
      />
      <Heading>
        <PageTitle>Organisations Info</PageTitle>
      </Heading>
      <SettingSidebarMenu
        selected={`/sys/organizations/${props.match.params.id}/default-price`}
      >
        <Heading>
          <PageTitle>
            Driver-Partner & Price
          </PageTitle>
          {organization && (
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              disabled={fetching}
              onClick={(): void => {
                props.history.push(`/sys/organizations/${props.match.params.id}/default-price/edit`);
              }}
            >
              <Icon icon={faEdit} color={COLOR.black} />
              Edit
            </StyledButton>
          )}
        </Heading>
        <Separator />
        {organization && (
          <ContentContainer>
            <CustomCard>
              <FieldInfo>
                <FieldView
                  fieldName="Squad Code"
                >
                  {props.organization?.squad_code}
                </FieldView>
              </FieldInfo>
              <Separator />
              <Description>
                Set your Driver-Partner preference.
              </Description>
              <Radio
                options={broadcastPreferences}
                value={broadcastPreference}
                onChange={(v: string): void => {
                  setBroadcastPreference(v);
                }}
              />
              <Separator />
              {props.organization && props.squadDrivers && priceContent}
            </CustomCard>
          </ContentContainer>
        )}
      </SettingSidebarMenu>
    </SysAdminMainContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomCard = styled(Card)`
  flex: 1;
  margin: 0rem;
  padding: 0rem;
`;

const CardRow = styled.div`
  display: flex;

  & > * {
    margin-right: 1rem;
  }
`;

const Arrow = styled(FontAwesomeIcon)`
  align-self: center;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.5rem;
`;

const FieldInfo = styled.div`
  margin-bottom: 1.5rem;
`;

const Separator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
  margin-bottom: 1.5rem;
`;

const RadioText = styled.div`
  color: ${COLOR.black};
`;

const ContentTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 0.375rem;
  color: ${COLOR.darkGray};
`;

const PriceInfo = styled.div`
  margin-bottom: 1.5rem;
`;

const Description = styled.div`
  margin-bottom: 1.5rem;
`;

const SmallDescription = styled.div`
  margin-top: 0.313rem;
  font-size: 0.75rem;
`;

const AdditionalInfo = styled.div<{ disabled?: boolean }>`
  color: ${(props): string => props.disabled ? COLOR.midLightGrey : COLOR.midDarkGrey};
`;

function mapStateToProps(state: RootState): SysAdminDefaultPriceProps {
  return {
    organization: state.organization.organization,
    squadDrivers: state.organization.squadDrivers,
  };
}

export default connect(mapStateToProps)(SysAdminDefaultPriceIndex);