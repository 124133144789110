import * as React from 'react';
import { SidebarMenu } from 'components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Separator } from 'components';
import { Organization } from 'models/organization';
import { RootState } from 'reduxActions/store';
import COLOR from 'constants/color';

interface MainMenu {
  title: string;
  submenu: Submenu[];
}

interface Submenu {
  title: string;
  path: string;
}

interface StateProps {
  organization: Organization;
}

interface SidebarMenuProps extends StateProps {
  header?: React.ReactNode;
  mainMenu?: MainMenu[];
  footer?: React.ReactNode;
  selected?: string;
  children?: React.ReactNode;
}

const OrganizationSidebarMenu = (props: SidebarMenuProps): JSX.Element => {
  return (
    <SidebarMenu
      {...props}
      header={(
        <>
          <ConfigHeaderContainer>
            <StyledLink
              to='/sys/config'
            >
              GotSurge Configuration
            </StyledLink>
          </ConfigHeaderContainer>
          <Separator />
        </>
      )}
      mainMenu={[
        {
          title: 'Transfer',
          submenu: [
            {
              title: 'Custom Group Mapper',
              path: '/sys/config/runsheet-ready-to-transfer/custom-group',
            },
          ],
        },
      ]}
    />
  );
};

const ConfigHeaderContainer = styled.div`
  display: flex;
  margin-top: 0.40rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
`;

const StyledLink = styled(Link)`
  font-size: 1rem;
  text-decoration: none;
  color: ${COLOR.black};
`;

const mapStateToProps = (state: RootState): StateProps => ({
  organization: state.organization.organization,
});

export default connect(mapStateToProps)(OrganizationSidebarMenu);