import { GeoData, SavedGeoData } from 'models/geoService';
import { MarineLocation } from 'models/marineLocation';

export interface GeoServiceState {
  geoDatas: GeoData[];
  savedGeoDatas: SavedGeoData[];
  savedGeoData: SavedGeoData;
  marineLocationDatas: MarineLocation[];
  marineLocationDetail: MarineLocation;
}

export const RECEIVE_GEODATAS = 'RECEIVE_GEODATAS';
export const RECEIVE_SAVED_GEODATAS = 'RECEIVE_SAVED_GEODATAS';
export const RECEIVE_SAVED_GEODATA = 'RECEIVE_SAVED_GEODATA';
export const RECEIVE_MARINE_LOCATION_DATAS = 'RECEIVE_MARINE_LOCATION_DATAS';
export const RECEIVE_MARINE_LOCATION_DETAIL = 'RECEIVE_MARINE_LOCATION_DETAIL';

export interface ReceiveGeodatas {
  type: typeof RECEIVE_GEODATAS;
  geoDatas: GeoData[];
}

export interface ReceiveSavedGeodatas {
  type: typeof RECEIVE_SAVED_GEODATAS;
  savedGeoDatas: SavedGeoData[];
}

export interface ReceiveSavedGeodata {
  type: typeof RECEIVE_SAVED_GEODATA;
  savedGeoData: SavedGeoData;
}

export interface ReceiveMarineLocationDatas {
  type: typeof RECEIVE_MARINE_LOCATION_DATAS;
  marineLocationDatas: MarineLocation[];
}

export interface ReceiveMarineLocationDetail {
  type: typeof RECEIVE_MARINE_LOCATION_DETAIL;
  marineLocationDetail: MarineLocation;
}

export type GeoServiceActionTypes =
  | ReceiveGeodatas
  | ReceiveSavedGeodatas
  | ReceiveSavedGeodata
  | ReceiveMarineLocationDatas
  | ReceiveMarineLocationDetail;
