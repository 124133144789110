import * as React from 'react';
import styled from 'styled-components';
import { Separator, PageTitle, MainContainer, Breadcrumb, Heading } from 'components';
import SettingSidebarMenu from '../../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps;

const WalletIndex = (props: Props): JSX.Element => {
  return (
    <MainContainer selected="setting">
      <Breadcrumb
        items={[
          { text: 'Setting', onClick: (): void => { props.history.push('/setting'); } },
          { text: 'Wallet' },
        ]}
      />
      <Heading>
        <PageTitle>Setting</PageTitle>
      </Heading>
      <SettingSidebarMenu
        selected="/setting/company/wallet"
      >
        <PageHeading>
          <Title>
            Wallet
          </Title>
        </PageHeading>
        <HeaderSeparator />
      </SettingSidebarMenu>
    </MainContainer>
  );
};

const Title = styled(PageTitle)`
  margin-top: 0.5rem;
`;

const PageHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderSeparator = styled(Separator)`
  margin-top: 1.65rem;
`;

export default WalletIndex;