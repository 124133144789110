import * as React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { TextInput, Typography } from 'components';
import { isValueEmptyOrZero, formatNumberWithSpaces } from 'utils/formatter';

interface DimensionsProps {
  lengthValue: number | null;
  widthValue: number | null;
  heightValue: number | null;
  onLengthChange: (value: number) => void;
  onWidthChange: (value: number) => void;
  onHeightChange: (value: number) => void;
  isShowTotalVolume?: boolean;
  inputLabel?: string | React.ReactNode;
  disabled?: boolean;
  maxLength?: number;
  isFloatNumberAllowed?: boolean;
  isZeroNumberAllowed?: boolean;
  defaultEmptyValue?: number | string;
  totalVolumeStyles?: FlattenSimpleInterpolation;
}

const maxDigitAfterDecimalPoint = 2;
export default function InputDimensions({
  lengthValue,
  widthValue,
  heightValue,
  onLengthChange,
  onWidthChange,
  onHeightChange,
  inputLabel,
  disabled,
  totalVolumeStyles,
  isShowTotalVolume = true,
  maxLength = 10,
  isFloatNumberAllowed = false,
  isZeroNumberAllowed = false,
  defaultEmptyValue = '',
}: DimensionsProps) {
  const regexValidation = isFloatNumberAllowed ? /^-?(?:\d*\.\d*|\d+)$/ : /^-?\d+$/;
  const isAllDimensionsSet =
    typeof lengthValue === 'number' &&
    lengthValue > 0 &&
    typeof widthValue === 'number' &&
    widthValue > 0 &&
    typeof heightValue === 'number' &&
    heightValue > 0;

  const handleTextChange = (
    value: string,
    e: React.ChangeEvent<HTMLInputElement>,
    onValueChange: (value: number | string) => void
  ): void => {
    let inputValue = value;

    if (maxLength && inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
    }

    if (regexValidation.test(inputValue) || inputValue === '') {
      if (
        inputValue !== '' &&
        Math.abs(Number(value) % 1) !== 0 &&
        value.toString().split('.')[1].length > maxDigitAfterDecimalPoint
      ) {
        e.stopPropagation();
        return;
      }
      const updatedValue =
        inputValue.endsWith('.') || inputValue === '' ? null : Number(inputValue);
      onValueChange(updatedValue);
    } else {
      e.preventDefault();
    }
  };

  const renderValueValidation = (value: null | number) => {
    if (value === null) {
      return '';
    }
    if (value === 0 && !isZeroNumberAllowed) {
      return '';
    }
    return value;
  };

  const handleInputOnBlur = (
    e: React.ChangeEvent<HTMLInputElement>,
    onValueChange: (value: number | string) => void
  ) => {
    if (!isZeroNumberAllowed && e.target.value === '0' && defaultEmptyValue === '') {
      onValueChange('');
    } else if (defaultEmptyValue && isValueEmptyOrZero(e.target.value)) {
      onValueChange(defaultEmptyValue);
    }
  };

  const totalVolume = isAllDimensionsSet ? lengthValue * widthValue * heightValue : 0;
  return (
    <DimensionsContainer>
      {typeof inputLabel === 'string' ? (
        <Typography as="p" color="gray_900" size="sm">
          {inputLabel}
        </Typography>
      ) : (
        inputLabel
      )}
      <DimensionsInputs>
        <TextInput
          autoComplete="none"
          containerStyle={containerInputStyle}
          inputStyle={inputStyle}
          type="text"
          width="small"
          placeholder="L"
          value={renderValueValidation(lengthValue)}
          inputMode="numeric"
          onTextChange={(value, e) => handleTextChange(value, e, onLengthChange)}
          onBlur={(e) => handleInputOnBlur(e, onLengthChange)}
          disabled={disabled}
        />
        <Typography as="span" size="xs">
          x
        </Typography>
        <TextInput
          autoComplete="none"
          containerStyle={containerInputStyle}
          inputStyle={inputStyle}
          type="text"
          width="small"
          placeholder="W"
          value={renderValueValidation(widthValue)}
          inputMode="numeric"
          onTextChange={(value, e) => handleTextChange(value, e, onWidthChange)}
          onBlur={(e) => handleInputOnBlur(e, onWidthChange)}
          disabled={disabled}
        />
        <Typography as="span" size="xs">
          x
        </Typography>
        <TextInput
          autoComplete="none"
          containerStyle={containerInputStyle}
          inputStyle={inputStyle}
          type="text"
          width="small"
          placeholder="H"
          value={renderValueValidation(heightValue)}
          inputMode="numeric"
          onTextChange={(value, e) => handleTextChange(value, e, onHeightChange)}
          onBlur={(e) => handleInputOnBlur(e, onHeightChange)}
          disabled={disabled}
        />
      </DimensionsInputs>
      {isShowTotalVolume && isAllDimensionsSet && (
        <TotalVolume totalVolumeStyles={totalVolumeStyles}>
          <Typography as="p" size="sm">
            Volume = {formatNumberWithSpaces(totalVolume.toFixed(2))}cm³
          </Typography>
        </TotalVolume>
      )}
    </DimensionsContainer>
  );
}

const DimensionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const DimensionsInputs = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 600px;
`;

const containerInputStyle = css`
  max-width: 92px;
  @media (min-width: 768px) {
    width: 92px !important;
  }
`;

const inputStyle = css`
  text-align: center;
  padding: 0.75rem;
`;

const TotalVolume = styled.div<{ totalVolumeStyles?: FlattenSimpleInterpolation }>`
  margin-top: 4px;
  ${({ totalVolumeStyles }) => totalVolumeStyles}
`;
