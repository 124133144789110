import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import {
  GetPriceAdjustmentsResponse,
  GetPriceAdjustmentLogsResponse,
} from 'models/priceAdjustment';
import { receivePriceAdjustmentLogAllSuccess, receivePriceAdjustmentAllSuccess } from 'reduxActions/priceAdjustment/priceAdjustmentActions';
import { getBearerToken } from 'utils/userHelper';

class PriceAdjustmentClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.PRICE_ADJUSTMENT_SERVICE_PROTOCOL;
    this.host = process.env.PRICE_ADJUSTMENT_SERVICE_HOST;
    this.port = process.env.PRICE_ADJUSTMENT_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  priceAdjustmentdetailUrl(runsheetID: string): string {
    return `${this.getBaseUrl()}/sys/job/runsheet/${runsheetID}`;
  }

  async getPriceAdjustmentByRunsheet(runsheetID: string): Promise<void> {
    const request = this.makeRequest('GET', this.priceAdjustmentdetailUrl(runsheetID));

    try {
      const response: AxiosResponse<GetPriceAdjustmentsResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receivePriceAdjustmentAllSuccess(response.data.jobs));
    } catch (error) {
      throw error.message;
    }
  }

  priceAdjustmentLogdetailUrl(runsheetID: string): string {
    return `${this.getBaseUrl()}/sys/log/runsheet/${runsheetID}`;
  }

  async getPriceAdjustmentLogByRunsheet(runsheetID: string): Promise<void> {
    const request = this.makeRequest('GET', this.priceAdjustmentLogdetailUrl(runsheetID));

    try {
      const response: AxiosResponse<GetPriceAdjustmentLogsResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receivePriceAdjustmentLogAllSuccess(response.data.logs));
    } catch (error) {
      throw error.message;
    }
  }
}

export default PriceAdjustmentClient;
