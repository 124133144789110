import * as React from 'react';
import {
  Separator,
  PageTitle,
  StyledButton,
  MainContainer,
  Breadcrumb,
  Heading,
} from 'components';
import { Organization } from 'models/organization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import COLOR from 'constants/color';
import styled from 'styled-components';
import defaultUserCompanyLogo from 'assets/images/default_user_company_logo.png';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import SettingSidebarMenu from '../../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import { fetchCurrentUser } from 'utils/userHelper';
import { CurrentUser } from 'models/auth';

interface CompanyDetailsProps {
  organization: Organization;
  currentUser: CurrentUser;
}

type Props = RouteComponentProps & CompanyDetailsProps;

const CompanyDetailsIndex = (props: Props): JSX.Element => {
  const [fetching, setFetching] = React.useState(false);
  React.useEffect(() => {
    setFetching(true);
    (async function (): Promise<void> {
      // fetching the current user
      fetchCurrentUser();
      const client = new OrganizationManagementClient();
      await client.getOrganization();
      setFetching(false);
    })();
  }, []);

  const { organization, currentUser } = props;
  const merchant = currentUser && currentUser.user.roles.includes('OrgAdmin') && 'Merchant';
  const transporter = currentUser && currentUser.user.roles.includes('OrgTransporter') && 'Transporter';
  const accountType = [merchant, transporter].filter(Boolean).join(' & ');

  return (
    <MainContainer selected="setting">
      <Breadcrumb
        items={[
          { text: 'Setting', onClick: (): void => { props.history.push('/setting'); } },
          { text: 'Company Details' },
        ]}
      />
      <Heading>
        <PageTitle>Setting</PageTitle>
      </Heading>
      <SettingSidebarMenu selected="/setting/company/company-details">
        <Heading>
          <PageTitle>
            Company Details
          </PageTitle>
          {organization && (
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              disabled={fetching}
              onClick={(): void => { props.history.push('/setting/company/company-details/edit'); }}
            >
              <Icon icon={faEdit} color={COLOR.black} />
              Edit
            </StyledButton>
          )}
        </Heading>
        <CustomSeparator />
        {organization && (
          <FormContainer>
            <CompanyImageContainer>
              <CompanyImage src={defaultUserCompanyLogo} />
            </CompanyImageContainer>
            <FormInput>
              <FormHeading>
                Company Name
              </FormHeading>
              <Content>
                {organization.business_name}
              </Content>
            </FormInput>
            <FormInput>
              <FormHeading>
                Account Type
              </FormHeading>
              <Content>
                {accountType}
              </Content>
            </FormInput>
            <FormInput>
              <FormHeading>
                Reference ID Prefix
              </FormHeading>
              <Content>
                {(organization.prefix_id || 'GOTSURGE-') + '[Your ID]'}
              </Content>
              <NoteText>Note: To customise the prefix, contact GotSurge</NoteText>
            </FormInput>
          </FormContainer>
        )}
      </SettingSidebarMenu>
    </MainContainer>
  );
};

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.5rem;
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyImageContainer = styled.div`
  display: flex;
  margin-top: 1.875rem;
`;

const CompanyImage = styled.img`
  width: 5.625rem;
  height: 5.625rem;
`;

const FormInput = styled.div`
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

const Content = styled.div`
  margin-top: 0.25rem;
  color: ${COLOR.darkGray};
`;

const NoteText = styled.div`
  line-height: 1.5rem;
  font-size: 0.75rem;
  color: ${COLOR.midDarkGrey};
`;

function mapStateToProps(state: RootState): CompanyDetailsProps {
  return {
    organization: state.organization.organization,
    currentUser: state.auth.currentUser,
  };
}

export default connect(mapStateToProps)(CompanyDetailsIndex);