import * as React from 'react';
import Input, { StyledInputProps } from './input/basicTextInput';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const leftSideStyle = css`
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 1rem;
  max-width: calc(1rem + 1.2em);
`;

const colonStyle = css`
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
  max-width: 0.3em;
`;

const periodStyle = css`
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
  padding-left: 0.2rem;
  max-width: calc(0.2rem + 1.5em);
`;

const midSideStyle = css`
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
  max-width: 1.2em;
`;

const rightSideStyle = css`
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 0.2rem;
  padding-right: 2.5rem;
  max-width: calc(2.7rem + 1.5em);
`;

const highlightedStyle = css`
  border-color: ${COLOR.blue};
`;

const inlineInputStyle = css`
  width: auto;
  display: inline-block;
  padding: 0rem;
`;

const centeredText = css`
  text-align: center;
`;

interface TimeRange {
  startHour: string;
  startMinute: string;
  startPeriod: string;
  endHour: string;
  endMinute: string;
  endPeriod: string;
}

interface TimeRangeInputProps extends StyledInputProps {
  fieldName?: string | React.ReactNode;
  isRequired?: boolean;
  values: TimeRange;
  onValuesChange: (v: TimeRange) => void;
}

interface ContainerProps {
  containerStyle?: FlattenSimpleInterpolation;
}

const TimeRangeInput = (props: TimeRangeInputProps & ContainerProps): JSX.Element => {
  const { fieldName, isRequired } = props;
  const [highlighted, setHighlighted] = React.useState(false);
  const additionalStyle: FlattenSimpleInterpolation = highlighted ? highlightedStyle : null;
  const startTimestampHour = React.useRef(null);
  const startTimestampMinute = React.useRef(null);
  const startTimestampPeriod = React.useRef(null);
  const endTimestampHour = React.useRef(null);
  const endTimestampMinute = React.useRef(null);
  const endTimestampPeriod = React.useRef(null);
  const isCompleted = Object.values(props.values).every(v => v !== '');
  const fields = [
    {
      ref: startTimestampHour,
      onChange: (v: string): void => {
        props.onValuesChange({
          ...props.values,
          startHour: v,
        });
      },
    },
    {
      ref: startTimestampMinute,
      onChange: (v: string): void => {
        props.onValuesChange({
          ...props.values,
          startMinute: v,
        });
      },
    },
    {
      ref: startTimestampPeriod,
      onChange: (v: string): void => {
        props.onValuesChange({
          ...props.values,
          startPeriod: v,
        });
      },
    },
    {
      ref: endTimestampHour,
      onChange: (v: string): void => {
        props.onValuesChange({
          ...props.values,
          endHour: v,
        });
      },
    },
    {
      ref: endTimestampMinute,
      onChange: (v: string): void => {
        props.onValuesChange({
          ...props.values,
          endMinute: v,
        });
      },
    },
    {
      ref: endTimestampPeriod,
      onChange: (v: string): void => {
        props.onValuesChange({
          ...props.values,
          endPeriod: v,
        });
      },
    },
  ];

  fields.forEach((field, i) => {
    React.useEffect(() => {
      function handleKeyDown(e: KeyboardEvent): void {
        if (e.target instanceof HTMLInputElement) {
          const currVal = field.ref.current.value;
          if (i !== fields.length - 1
            && (e.code === 'Semicolon' || e.code === 'Minus' || e.code === 'Space' || (e.code === 'ArrowRight'
              && e.target.selectionStart === currVal.length))
          ) {
            e.preventDefault();
            if (i !== 2 && i !== 5 && currVal.trim().length === 1 && Number(currVal) < 10) {
              field.onChange('0' + currVal.trim());
            }
            fields[i + 1].ref.current.focus();
            fields[i + 1].ref.current.setSelectionRange(0, 0);
            return;
          }

          if (i !== 0
            && e.target.selectionStart === 0
            && ((e.code === 'Backspace' && e.target.selectionStart === e.target.selectionEnd) || e.code === 'ArrowLeft')
          ) {
            e.preventDefault();
            if (i !== 2 && i !== 5 && currVal.trim().length === 1 && Number(currVal) < 10) {
              field.onChange('0' + currVal.trim());
            }
            fields[i - 1].ref.current.focus();
            fields[i - 1].ref.current.setSelectionRange(fields[i - 1].ref.current.value.length, fields[i - 1].ref.current.value.length);
            return;
          }

          if (i !== fields.length - 1
            && e.target.selectionStart === e.target.selectionEnd
            && currVal.length === 2
            && (e.code.startsWith('Key') || e.code.startsWith('Digit'))
          ) {
            fields[i + 1].ref.current.focus();
            return;
          }
        }
      }

      field.ref.current.addEventListener('keydown', handleKeyDown);
      return (): void => {
        field.ref.current.removeEventListener('keydown', handleKeyDown);
      };
    }, [fields[i].ref, props.values]);
  });

  const renderTimestampInput = (type: 'start' | 'end'): JSX.Element => {
    const isStart = type === 'start';
    const val = props.values;

    return (
      <>
        <StyledInput
          ref={isStart ? startTimestampHour : endTimestampHour}
          size={2}
          placeholder="12"
          maxLength={2}
          inputStyle={[(isStart ? leftSideStyle : midSideStyle), additionalStyle]}
          isCompleted={isCompleted}
          isRequired={props.isRequired}
          onFocus={(): void => { setHighlighted(true); }}
          onBlur={(): void => {
            setHighlighted(false);
            const v = isStart ? val.startHour : val.endHour;
            if (v.trim().length === 1 && Number(v) < 10) {
              (isStart ? fields[0] : fields[3]).onChange('0' + v.trim());
            }
          }}
          value={isStart ? val.startHour : val.endHour}
          onChange={(e): void => {
            (isStart ? fields[0] : fields[3]).onChange(e.target.value);
          }}
        />
        <StyledInput
          disabled
          value=":"
          size={1}
          isCompleted={isCompleted}
          inputStyle={[colonStyle, additionalStyle]}
          isRequired={props.isRequired}
        />
        <StyledInput
          ref={isStart ? startTimestampMinute : endTimestampMinute}
          size={2}
          maxLength={2}
          placeholder="00"
          isCompleted={isCompleted}
          inputStyle={[midSideStyle, additionalStyle]}
          isRequired={props.isRequired}
          onFocus={(): void => { setHighlighted(true); }}
          onBlur={(): void => {
            setHighlighted(false);
            const v = isStart ? val.startMinute : val.endMinute;
            if (v.trim().length === 1 && Number(v) < 10) {
              (isStart ? fields[1] : fields[4]).onChange('0' + v.trim());
            }
          }}
          value={isStart ? val.startMinute : val.endMinute}
          onChange={(e): void => {
            (isStart ? fields[1] : fields[4]).onChange(e.target.value);
          }}
        />
        <StyledInput
          ref={isStart ? startTimestampPeriod : endTimestampPeriod}
          size={2}
          maxLength={2}
          placeholder={isStart ? 'am' : 'pm'}
          isCompleted={isCompleted}
          inputStyle={[(isStart ? periodStyle : rightSideStyle), additionalStyle]}
          isRequired={props.isRequired}
          onFocus={(): void => { setHighlighted(true); }}
          onBlur={(): void => { setHighlighted(false); }}
          value={isStart ? val.startPeriod : val.endPeriod}
          onChange={(e): void => {
            (isStart ? fields[2] : fields[5]).onChange(e.target.value.toLowerCase());
          }}
        />
      </>
    );
  };

  return (
    <Container containerStyle={props.containerStyle}>
      <LabelContainer>
        {
          fieldName && (
            <InputLabel>
              {fieldName} {isRequired && <RequiredSpan>*</RequiredSpan>}
            </InputLabel>
          )
        }
      </LabelContainer>
      {renderTimestampInput('start')}
      <StyledInput
        disabled
        width="auto"
        value="-"
        size={1}
        isCompleted={isCompleted}
        inputStyle={[midSideStyle, additionalStyle]}
        isRequired={props.isRequired}
      />
      {renderTimestampInput('end')}
      <IconContainer>
        <Icon icon={faClock} />
      </IconContainer>
    </Container>
  );
};

const IconContainer = styled.div`
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: -2rem;
  right: 1rem;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InputLabel = styled.label`
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.32px;
  margin-bottom: 0.25rem;
`;

const RequiredSpan = styled.span`
  color: ${COLOR.red};
`;

const StyledInput = styled(Input) <{ inputStyle: FlattenSimpleInterpolation; isRequired: boolean; isCompleted: boolean }>`
  ${inlineInputStyle}
  border-color: ${(props): string => props.isRequired && !props.isCompleted
    ? COLOR.red : COLOR.neutral};
  ${(props): FlattenSimpleInterpolation => props.disabled ? [props.inputStyle, centeredText] : props.inputStyle};
`;

const Container = styled.div<ContainerProps>`
  margin: 0;
  display: inline-block;
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
`;

export default TimeRangeInput;