import * as React from 'react';
import { StyledLink, CardWrapper, Card, Stack, Divider } from '../styles';
import { Task } from 'models/task';
import { Typography } from 'components';
import { formatPrice } from 'utils/formatter';
import DateHelper from 'utils/dateHelper';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import styled from 'styled-components';

interface Props {
  task: Task;
  ops?: boolean;
}

const BroadcastResponse = (task: Task, ops: boolean): React.ReactNode => {
  const deliveryHistory =
    task && task.statuses.find((history) => history.status === 'complete');

  let response: React.ReactNode;
  let incrementText: string | null = null;
  const fulfillmentDateContent = deliveryHistory ? (
    <>
      <Typography
        as="p"
        size="sm"
        color="gray_900"
        weight="semibold"
        customStyle={{
          marginBottom: '0.35rem',
        }}
      >
        Fulfilment Date
      </Typography>
      <Typography
        as="p"
        color="gray_600"
        customStyle={{
          marginBottom: '1rem',
        }}
      >
        {DateHelper.formatDateTime(deliveryHistory.set_at_utc)}
      </Typography>
    </>
  ) : null;

  if (task && task.price_table && task.price_table.length > 1) {
    const incrementValue = task.price_table[1].price - task.price_table[0].price;

    const diffIncrement = task.price_table.find((priceRecord, index) => {
      if (index !== task.price_table.length - 1) {
        return task.price_table[index + 1].price - priceRecord.price !== incrementValue;
      }
      return false;
    });

    if (diffIncrement) {
      incrementText = 'Varies';
    } else {
      incrementText = formatPrice(task.currency, incrementValue);
    }
  }

  if (task && task.broadcast_preference === BROADCAST_PREFERENCES.all) {
    response = (
      <>
        <Typography
          as="p"
          size="sm"
          color="gray_900"
          weight="semibold"
          customStyle={{
            marginBottom: '0.35rem',
          }}
        >
          Minimum Price
        </Typography>
        <Typography
          as="p"
          color="gray_600"
          customStyle={{
            marginBottom: '1rem',
          }}
        >
          {task && task.min_price && formatPrice(task.currency, task.min_price)}
        </Typography>
        <Typography
          as="p"
          size="sm"
          color="gray_900"
          weight="semibold"
          customStyle={{
            marginBottom: '0.35rem',
          }}
        >
          Maximum Price
        </Typography>
        <Typography
          as="p"
          color="gray_600"
          customStyle={{
            marginBottom: '1rem',
          }}
        >
          {task && task.max_price ? formatPrice(task.currency, task.max_price) : '-'}
        </Typography>
        <Typography
          as="p"
          size="sm"
          color="gray_900"
          weight="semibold"
          customStyle={{
            marginBottom: '0.35rem',
          }}
        >
          Price Increment
        </Typography>
        <Typography
          as="p"
          color="gray_600"
          customStyle={{
            marginBottom: '1rem',
          }}
        >
          {incrementText}
        </Typography>
        {fulfillmentDateContent}
        <Typography
          as="p"
          size="sm"
          color="gray_900"
          weight="semibold"
          customStyle={{
            marginBottom: '0.35rem',
          }}
        >
          Accepted Price
        </Typography>
        <Typography
          as="p"
          color="gray_600"
          customStyle={{
            marginBottom: '1rem',
          }}
        >
          {task && task.driver
            ? `${formatPrice(task.currency, task.driver_price)} ${
                task.is_squad ? '(Squad)' : '(Non-Squad)'
              }`
            : '-'}
        </Typography>
        {ops && task ? (
          <>
            <Typography
              as="p"
              size="sm"
              color="gray_900"
              weight="semibold"
              customStyle={{
                marginBottom: '0.35rem',
              }}
            >
              Admin Fee
            </Typography>
            <Typography
              as="p"
              color="gray_600"
              customStyle={{
                marginBottom: '1rem',
              }}
            >
              {task.admin_fee && formatPrice(task.currency, task.admin_fee)}
            </Typography>
          </>
        ) : (
          false
        )}
        <Typography
          as="p"
          size="sm"
          color="gray_900"
          weight="semibold"
          customStyle={{
            marginBottom: '0.35rem',
          }}
        >
          Total Price
        </Typography>
        <Typography
          as="p"
          color="gray_600"
          customStyle={{
            marginBottom: '1rem',
          }}
        >
          {task?.total_price && task?.driver
            ? formatPrice(task.currency, task.total_price)
            : '-'}
        </Typography>
      </>
    );
  } else if (task && task.broadcast_preference === BROADCAST_PREFERENCES.squad) {
    response = (
      <>
        {fulfillmentDateContent}
        {ops && task ? (
          <>
            <Typography
              as="p"
              size="sm"
              color="gray_900"
              weight="semibold"
              customStyle={{
                marginBottom: '0.35rem',
              }}
            >
              Admin Fee
            </Typography>
            <Typography
              as="p"
              color="gray_600"
              customStyle={{
                marginBottom: '1rem',
              }}
            >
              {task.admin_fee && formatPrice(task.currency, task.admin_fee)}
            </Typography>
          </>
        ) : (
          false
        )}
        <Typography
          as="p"
          size="sm"
          color="gray_900"
          weight="semibold"
          customStyle={{
            marginBottom: '0.35rem',
          }}
        >
          Total Price
        </Typography>

        <Typography
          as="p"
          color="gray_600"
          customStyle={{
            marginBottom: '1rem',
          }}
        >
          {task?.total_price && task?.driver
            ? formatPrice(task.currency, task.total_price)
            : '-'}
        </Typography>
      </>
    );
  } else {
    response = fulfillmentDateContent;
  }

  return response;
};

const BroadcastInfoCard = ({ task, ops }: Props): JSX.Element => {
  return (
    <CardWrapper>
      <Card>
        <Stack direction="column" gap="0.35rem">
          <Typography as="h3" size="lg" color="gray_900">
            Broadcast Info
          </Typography>
          {ops ? (
            <>
              {task && task.runsheet && task.runsheet.id && (
                <Typography as="p" size="xs">
                  <StyledLink to={`/sys/runsheets/${task.runsheet.id}`}>
                    View broadcast log
                  </StyledLink>
                </Typography>
              )}
            </>
          ) : (
            false
          )}
        </Stack>
        <Divider />
        <BroadcastResponseContainer>
          {BroadcastResponse(task, ops)}
        </BroadcastResponseContainer>
      </Card>
    </CardWrapper>
  );
};

const BroadcastResponseContainer = styled.div`
  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export default BroadcastInfoCard;
