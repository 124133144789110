import * as React from 'react';
import {
  RedText,
  ItemTitle,
  ItemDescription,
  CustomLinkButton,
  ItemList,
  Li,
  ItemFieldContainer,
  ItemNumber,
  ItemField,
  ItemFieldName,
  CheckBoxWrapper,
  RemoveCargoDetailItemWrapper,
  RemoveItemButton,
  TrashIcon,
  Button,
  TotalCargoContainer,
  TotalCargo,
  Lifts,
  LiftInfoIcon,
  RemoveCargoDetailsIcon,
  RemoveCargoDetailsSubTitle,
} from 'pages/order/styles';
import {
  CenterModal,
  TextInput,
  Checkbox,
  Dropdown,
  CounterInputButton,
} from 'components';
import { MerchantDeliveryForm } from 'models/task';
import COLOR from 'constants/color';
import { css } from 'styled-components';
import { CARGO_TYPES } from 'constants/cargo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faTrash,
  faPlus,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

interface CargoDetailsProps {
  deliveryForm: MerchantDeliveryForm;
  updateDeliveryForm: (
    fieldName: string,
    value: string | Date | boolean | number
  ) => void;
  onAddItemClick: () => void;
  onRemoveCargoDetails: (cargoDetailsIndex: number) => void;
  isEditableField?: (fieldName: string) => boolean;
}

interface CargoDetailState {
  showRemoveCargoDetailsModal: boolean | number;
  showRemoveCargoDetailsSuccessModal: boolean;
}

class CargoDetails extends React.Component<CargoDetailsProps, CargoDetailState> {
  constructor(props: CargoDetailsProps) {
    super(props);
    this.state = {
      showRemoveCargoDetailsModal: false,
      showRemoveCargoDetailsSuccessModal: false,
    };
  }

  totalCargoLifts = (): number => {
    const { deliveryForm } = this.props;
    const cargoDetails = deliveryForm.cargo_details;

    return cargoDetails.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);
  };

  areCargoDetailsRequiredFieldsEmpty = (): boolean => {
    const { deliveryForm } = this.props;
    const cargoDetails = deliveryForm.cargo_details;

    return cargoDetails.reduce((acc, detail) => {
      const isNameEmpty = detail.name.trim() === '';
      const isQuantityEmpty = isNaN(detail.quantity) || detail.quantity <= 0;
      return acc || isNameEmpty || isQuantityEmpty;
    }, false);
  };

  renderRemoveCargoDetailSuccessModal = (): React.ReactNode => (
    <>
      {this.state.showRemoveCargoDetailsSuccessModal ? (
        <CenterModal
          title={
            <RemoveCargoDetailsIcon
              icon={faCheckCircle}
              color={COLOR.cyan}
              fontSize="1.3"
            />
          }
          fullBottomButtonText="OK"
          fullBottomButtonOnClick={(): void => {
            this.setState({
              showRemoveCargoDetailsSuccessModal: false,
            });
          }}
          width="small"
          position="bottom"
        >
          <RemoveCargoDetailsSubTitle>Cargo details removed</RemoveCargoDetailsSubTitle>
        </CenterModal>
      ) : (
        false
      )}
    </>
  );

  renderRemoveCargoDetailsModal = (): React.ReactNode => {
    const { onRemoveCargoDetails } = this.props;
    const { showRemoveCargoDetailsModal } = this.state;
    return (
      <CenterModal
        title={<RemoveCargoDetailsIcon icon={faTrash} color={COLOR.red} />}
        leftButtonText="Cancel"
        leftButtonOnClick={(): void => {
          this.setState({
            showRemoveCargoDetailsModal: false,
          });
        }}
        rightButtonOnClick={(): void => {
          if (typeof showRemoveCargoDetailsModal === 'number') {
            onRemoveCargoDetails(showRemoveCargoDetailsModal);
            this.setState({
              showRemoveCargoDetailsModal: false,
              showRemoveCargoDetailsSuccessModal: true,
            });
          }
        }}
        rightButtonText="Remove"
        rightButtonStyle="discourage"
        rightButtonType="primary"
        width="small"
        position="bottom"
      >
        <RemoveCargoDetailsSubTitle>Remove cargo details?</RemoveCargoDetailsSubTitle>
        Are you sure you want to remove cargo details? This action cannot be undone.
      </CenterModal>
    );
  };

  render(): React.ReactNode {
    const { deliveryForm, updateDeliveryForm, onAddItemClick, isEditableField } =
      this.props;

    return (
      <div id="cargo-details">
        <ItemTitle style={{ marginBottom: '22px' }}>
          Cargo Details
          <ItemDescription>
            Add cargo information and the amount of each cargo.
            <br />
            Please indicate the cargo includes dangerous goods to prevent delays and
            ensure safety.{' '}
            <CustomLinkButton
              target="_blank"
              href="https://help.gotsurge.co/en/article/undeclared-dangerous-goods"
            >
              See more info
            </CustomLinkButton>
          </ItemDescription>
        </ItemTitle>
        <ItemList>
          {deliveryForm.cargo_details.map((item, k) => {
            const cargoDescription = deliveryForm.cargo_details[k].name || '';
            const isMinQty = deliveryForm.cargo_details[k].quantity <= 1;
            const canRemoveCargoByQty = isMinQty && cargoDescription !== '';
            const canRemoveCargo = cargoDescription !== '' || k !== 0;
            return (
              <Li key={k}>
                <span>
                  <ItemFieldContainer
                    style={{
                      maxWidth: '600px',
                      alignItems: 'flex-start',
                      flexWrap: 'wrap',
                    }}
                  >
                    <ItemNumber index={k}>{k + 1}.</ItemNumber>
                    <ItemField
                      flex="0 0 284px"
                      flexDirection="column"
                      withMarginLeft="-8px"
                    >
                      {k === 0 && (
                        <ItemFieldName>
                          Description <RedText>*</RedText>
                        </ItemFieldName>
                      )}
                      <Dropdown
                        width="full"
                        height="large"
                        label="Select Cargo"
                        isItemTitleBold={true}
                        isShowingModalListOnMobile={true}
                        modalTitle="Select Cargo Types"
                        withoutDefinedLabel={true}
                        options={CARGO_TYPES}
                        onChange={(value: string): void => {
                          updateDeliveryForm(`cargo_details[${k}].name`, value);
                        }}
                        value={deliveryForm.cargo_details[k].name}
                        disabled={isEditableField && !isEditableField('cargo_details')}
                      />
                      {deliveryForm.cargo_details[k].name === 'others' && (
                        <>
                          <TextInput
                            containerStyle={css`
                              margin-top: 8px;
                            `}
                            width="full"
                            height="large"
                            autoComplete="none"
                            onTextChange={(value: string): void => {
                              updateDeliveryForm(`cargo_details[${k}].remarks`, value);
                            }}
                            type="text"
                            placeholder="Enter cargo type"
                            value={item.remarks || ''}
                            disabled={isEditableField && !isEditableField('cargo_details')}
                          />
                        </>
                      )}
                    </ItemField>
                    <ItemField flex={1} flexDirection="column" maxWidth="180px">
                      {k === 0 && (
                        <ItemFieldName>
                          Qty <RedText>*</RedText>
                        </ItemFieldName>
                      )}

                      <CounterInputButton
                        isAllFieldsDisabled={isEditableField && !isEditableField('cargo_details')}
                        value={item.quantity || 1}
                        incrementValue={1}
                        decrementValue={1}
                        defaultEmptyValue={1}
                        onValueChange={(val): void => {
                          if (canRemoveCargoByQty && Number(val) < 1) {
                            this.setState({
                              showRemoveCargoDetailsModal: k,
                            });
                          } else {
                            updateDeliveryForm(
                              `cargo_details[${k}].quantity`,
                              Number(val)
                            );
                          }
                        }}
                        isDiscourageMinusButton={canRemoveCargoByQty}
                        isMinusButtonDisabled={isMinQty && !canRemoveCargoByQty}
                      />
                    </ItemField>
                    <ItemField
                      flex={1}
                      style={{ marginTop: '0.5rem', flexBasis: '100%' }}
                      marginBottom="10px"
                      maxWidth="150px"
                    >
                      <CheckBoxWrapper index={k}>
                        <Checkbox
                          onClick={(): void => {
                            updateDeliveryForm(
                              `cargo_details[${k}].has_hazard_mat`,
                              !deliveryForm.cargo_details[k].has_hazard_mat
                            );
                          }}
                          selected={deliveryForm.cargo_details[k].has_hazard_mat}
                          disabled={isEditableField && !isEditableField('cargo_details')}
                        >
                          <span style={{ fontSize: '14px' }}>Hazardous Material</span>
                        </Checkbox>
                      </CheckBoxWrapper>
                    </ItemField>

                    {canRemoveCargo && (isEditableField && isEditableField('cargo_details')) ? (
                      <RemoveCargoDetailItemWrapper
                        index={k}
                        isTwoRow={deliveryForm.cargo_details[k].name === 'others'}
                      >
                        <RemoveItemButton
                          size="xs"
                          buttonType="link"
                          buttonStyle="discourage"
                          fontWeight={400}
                          icon={<TrashIcon icon={faTrash} />}
                          onClick={(): void => {
                            this.setState({
                              showRemoveCargoDetailsModal: k,
                            });
                          }}
                          type="button"
                        >
                          Remove
                        </RemoveItemButton>
                      </RemoveCargoDetailItemWrapper>
                    ) : (
                      false
                    )}
                  </ItemFieldContainer>
                </span>
              </Li>
            );
          })}
        </ItemList>
        <div style={{ marginTop: '6px' }}>
          <Button
            type="button"
            buttonType="neutral"
            buttonStyle="encourage"
            disabled={this.areCargoDetailsRequiredFieldsEmpty() || isEditableField && !isEditableField('cargo_details')}
            onClick={onAddItemClick}
            icon={<FontAwesomeIcon icon={faPlus} />}
          >
            Add Item
          </Button>
        </div>
        <TotalCargoContainer>
          <TotalCargo>Total Cargo</TotalCargo>
          <Lifts>
            {this.totalCargoLifts()} Lifts <LiftInfoIcon icon={faInfoCircle} />
          </Lifts>
        </TotalCargoContainer>
        {typeof this.state.showRemoveCargoDetailsModal === 'number' &&
          this.renderRemoveCargoDetailsModal()}
        {this.renderRemoveCargoDetailSuccessModal()}
      </div>
    );
  }
}

export default CargoDetails;
