import * as React from 'react';
import * as H from 'history';
import TaskClient from 'httpClients/taskClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { Table, Pagination, TableCellRenderEvent, OrderCard } from 'components';
import { Task } from 'models/task';
import styled from 'styled-components';
import emptyPlaceholder from 'assets/images/past_order_empty_state.png';
import COLOR from 'constants/color';
import { PastOrderStatusStrings } from 'constants/taskStatus';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { Organization } from 'models/organization';

interface StateProps {
  models: Task[];
  organization: Organization;
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type PastOrderListProps = HistoryProps & StateProps;

class PastOrderList extends Pagination<Task, PastOrderListProps> {
  constructor(props: PastOrderListProps) {
    super(props);

    this.state = {
      ...this.state,
      basePath: '/past-orders',
      breadcrumb: [{ text: 'Past Orders' }],
      pluralModelName: 'past orders',
      selectedTab: 'past-orders',
      isContentNoBorder: true,
    };
  }

  getPastOrderFilters = (): URLSearchParams => {
    const pastOrderFilters = new URLSearchParams(this.state.filters);
    PastOrderStatusStrings.map((status) => {
      pastOrderFilters.append('current_status', status);
    });
    const isQA = this.props.organization && this.props.organization.is_qa;
    if (!isQA) {
      pastOrderFilters.append('is_qa', 'false');
    }

    return pastOrderFilters;
  };

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const orgClient = new OrganizationManagementClient();
    await orgClient.getOrganization();
    const client = new TaskClient();
    await client.getTasks(this.getPastOrderFilters());

    this.setState({ isFetching: false });
  };

  renderEmptyState = (): React.ReactNode => (
    <EmptyContainer>
      <Placeholder src={emptyPlaceholder} />
      <Subtitle>No past orders yet</Subtitle>
      <Description>Add new orders at Active Orders</Description>
    </EmptyContainer>
  );

  renderCell = (e: TableCellRenderEvent<Task>): React.ReactNode => {
    return <OrderCard row={e.row} task={e.data} isOps={false} isCheckboxEnable={false} />;
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      orderCard: '',
    };

    const data = models.map((task) => {
      return {
        ...task,
      };
    });

    return (
      <Table
        columns={columns}
        data={data}
        rowOnClick={(id: string): void => {
          this.props.history.push('/orders/' + id);
        }}
        cellRenderer={this.renderCell}
        isRenderColumnHeader={false}
        hideNavigationButton={true}
        isRowHasBorderBottom={false}
        containerPaddingRight="0.125rem"
      />
    );
  };
}

const Placeholder = styled.img`
  width: 13.5rem;
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;

  & > * {
    margin: 0.5rem;
  }
`;

const Subtitle = styled.div`
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const mapStateToProps = (state: RootState): StateProps => ({
  models: state.task.tasks,
  organization: state.organization.organization,
});

export default connect(mapStateToProps)(PastOrderList);
