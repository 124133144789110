import * as moment from 'moment';
import { TimeWindow } from 'models/task';

class DateHelper {
  static formatDateTime(date: Date): string {
    const formattedDate = moment(date).format('DD MMMM YYYY LT');

    return formattedDate;
  }

  static formatDateTime24h(date: Date): string {
    const formattedDate = moment(date).format('DD MMMM YYYY, HH:mm');

    return formattedDate;
  }

  static formatDate(date: Date): string {
    const formattedDate = moment(date).format('DD MMMM YYYY');

    return formattedDate;
  }

  static formatString(date: string, dateFormat = 'DD MMMM YYYY'): Date {
    const formattedString = moment(date, dateFormat).toDate();

    return formattedString;
  }

  static formatTimeRange(startDate: Date, endDate: Date): string {
    return moment(startDate).format('LT') + ' - ' + moment(endDate).format('LT');
  }

  static formatDateTimeRange(startDate: Date, endDate: Date): string {
    const isSameDay = moment(startDate).isSame(moment(endDate), 'day');
    if (isSameDay) {
      return (
        moment(startDate).format('DD MMM YYYY') +
        ', ' +
        moment(startDate).format('LT') +
        ' - ' +
        moment(endDate).format('LT')
      );
    }
    return (
      moment(startDate).format('DD MMM YYYY, LT') +
      ' - ' +
      moment(endDate).format('DD MMM YYYY, LT')
    );
  }

  static formatSimpleDateTime(date: Date): string {
    if (moment(date).isSame(moment(), 'year')) {
      return moment(date).format('DD MMM, HH:mm');
    }
    return moment(date).format('DD MMM YYYY, HH:mm');
  }

  static formatDateSimpleMonthTime(date: Date): string {
    const formattedDate = moment(date).format('DD MMMM YYYY, HH:mm');

    return formattedDate;
  }

  static isRangeValid(timeWindow: TimeWindow): boolean {
    let isRangeValid = true;
    const startDate = moment(timeWindow.start_time_utc);
    const endDate = moment(timeWindow.end_time_utc);
    if (startDate !== endDate) {
      isRangeValid = endDate.isSameOrAfter(startDate);
    }

    return isRangeValid;
  }

  static compareDateTime(dateTime1: Date, dateTime2: Date): number {
    const timeWindow1 = dateTime1 && new Date(dateTime1).getTime();
    const timeWindow2 = dateTime2 && new Date(dateTime2).getTime();
    if (timeWindow1 < timeWindow2) {
      return -1;
    } else if (timeWindow1 === timeWindow2) {
      return 0;
    }
    return 1;
  }

  static subtractHoursFromDateTime = (hours: number, dateTime: Date): Date => {
    const newDate = new Date(dateTime);
    return new Date(newDate.getTime() - hours * 60 * 60 * 1000);
  };

  static isMoreThan3HoursFromStart = (startUtc: Date): boolean => {
    if (!startUtc || !(startUtc instanceof Date)) {
      startUtc = new Date(startUtc);
    }

    const currentUtc = new Date(); // Current UTC time

    const subtractedTime = DateHelper.subtractHoursFromDateTime(3, startUtc);

    return currentUtc > subtractedTime;
  };
}

export default DateHelper;
