import { SERVICE_TYPE } from 'constants/serviceType';
import { ServicePricing, Status, Task, Item } from 'models/task';
import NotificationPusherClient from 'httpClients/notificationPusherClient';
import { VEHICLE_DIMENSION } from 'constants/vehiclePreference';

export function getServicePricing(
  serviceType: string,
  vehiclePreference: string
): ServicePricing | null {
  let selectedServiceType = serviceType;
  if (selectedServiceType === 'ftl') {
    // previous name of truck is ftl
    selectedServiceType = 'truck';
  }
  const getServiceType = SERVICE_TYPE.find((obj) => {
    return obj.service_type === selectedServiceType;
  });
  let getServicePricing = getServiceType.service_pricing.find((obj) => {
    return obj.is_default === true;
  });
  if (serviceType === 'ftl' || serviceType === 'truck') {
    getServicePricing = getServiceType.service_pricing.find((obj) => {
      return obj.vehicle_preference === vehiclePreference;
    });
  }
  return getServicePricing || null;
}

export function getLastUpdatedTask(task: Task): Status | null {
  if (!task) return null;
  if (task.current_status === 'complete') return null;
  if ('statuses' in task === false && !Array.isArray(task.statuses)) return null;
  const updateStatuses = task.statuses.filter((status) => status.status === 'updated');
  const lastUpdated = updateStatuses.reduce(
    (latestStatus, status) =>
      latestStatus
        ? status.set_at_utc && status.set_at_utc > latestStatus.set_at_utc
          ? status
          : latestStatus
        : status,
    null
  );
  return lastUpdated || null;
}

export async function sendTaskCancelNotification(task: Task): Promise<void> {
  const driverId = task.driver && task.driver.id;
  // should send notification to runsheet that has been assigned to a driver
  if (!driverId) {
    return;
  }
  const taskId = task.id;
  const npClient = new NotificationPusherClient();
  const orgId = task.org_transporter && task.org_transporter.id;
  if (task.broadcast_preference === 'marine') {
    // sending notification to transport-partner
    npClient.pushNotification({
      push_messages: [
        {
          id: taskId,
          title: 'Order Cancelled',
          body: 'Order has been cancelled.',
          data: {
            category: 'task-management-cancel',
            task_id: taskId,
          },
          sound: 'default',
        },
      ],
      application_key: 'gotsurge-mobile-transport',
      org_id: orgId,
    });
    // sending notification to pro-driver
    npClient.pushNotification({
      push_messages: [
        {
          id: taskId,
          user_id: driverId,
          title: 'Order Cancelled',
          body: 'Order has been cancelled.',
          data: {
            category: 'task-management-cancel',
            task_id: taskId,
          },
          sound: 'default',
        },
      ],
      application_key: 'gotsurge-mobile-driver',
    });
  } else {
    // sending notification to mobile-driver (standerd & squad)
    npClient.pushNotification({
      push_messages: [
        {
          id: taskId,
          user_id: driverId,
          title: 'Order Cancelled',
          body: 'Order has been cancelled.',
          data: {
            category: 'task-management-cancel',
            task_id: taskId,
          },
          sound: 'default',
        },
      ],
      application_key: 'gotsurge-mobile-driver',
    });
  }
}

export function isSendEditNotification(task: Task, updatedFieldNames: string[]): boolean {
  // send notification if there are changes in the specified fields [GOT-2104]
  let sendFields: string[] = [];
  if (task.current_status === 'accepted') {
    sendFields = [
      'to_contact.vessel_name',
      'to_contact.lighter_company_name',
      'to_contact.lighter_boat_name',
      'from_street_address',
      'from_contact.etb_time_window.start_time_utc',
      'from_contact.etb_time_window.end_time_utc',
      'from_contact.etu_time_window.start_time_utc',
      'from_contact.etu_time_window.end_time_utc',
      'from_contact.berth_no',
      'from_contact.name',
      'from_contact.phone',
      'from_contact.email',
      'pickup_note_to_driver',
      'to_street_address',
      'to_contact.etb_time_window.start_time_utc',
      'to_contact.etb_time_window.end_time_utc',
      'to_contact.etu_time_window.start_time_utc',
      'to_contact.etu_time_window.end_time_utc',
      'to_contact.berth_no',
      'to_contact.name',
      'to_contact.phone',
      'to_contact.email',
      'to_time_window.start_time_utc',
      'delivery_note_to_driver',
      'cargo_details',
      'max_price',
      'min_price',
      'service_type',
      'from_time_window.start_time_utc',
      'from_time_window.end_time_utc',
      'to_time_window.start_time_utc',
      'to_time_window.end_time_utc',
    ];
  }
  if (
    task.current_status === 'pending_pickup' ||
    task.current_status === 'pickup_arriving' ||
    task.current_status === 'pending_delivery' ||
    task.current_status === 'delivery_arriving'
  ) {
    sendFields = [
      'to_street_address',
      'to_contact.etb_time_window.start_time_utc',
      'to_contact.etb_time_window.end_time_utc',
      'to_contact.etu_time_window.start_time_utc',
      'to_contact.etu_time_window.end_time_utc',
      'to_contact.berth_no',
      'to_contact.name',
      'to_contact.phone',
      'to_contact.email',
      'to_time_window.start_time_utc',
      'delivery_note_to_driver',
      'to_time_window.start_time_utc',
      'to_time_window.end_time_utc',
    ];
  }
  let count = 0;
  for (const str of updatedFieldNames) {
    if (sendFields.includes(str)) {
      count++;
    }
  }
  return count ? true : false;
}

type VehicleType = keyof typeof VEHICLE_DIMENSION;
export function isItemExceedingVehicleDimensions<T extends VehicleType>(
  items: Item[],
  vehicles: readonly T[]
): Record<T, boolean> {
  const result = {} as Record<T, boolean>;

  for (const vehicle of vehicles) {
    result[vehicle] = false;
    const vehicleDimension = VEHICLE_DIMENSION[vehicle];
    for (const { dimension } of items) {
      if (
        dimension.width > vehicleDimension.width ||
        dimension.height > vehicleDimension.height ||
        dimension.length > vehicleDimension.length
      ) {
        result[vehicle] = true;
        break;
      }
    }
  }

  return result;
}
