import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import * as HttpHelper from 'utils/httpHelper';
import configureStore from 'reduxActions/store';
import { MetaVessels } from 'models/vessel';
import { getBearerToken } from 'utils/userHelper';
import { PER_PAGE } from 'constants/paginationMeta';
import { CreateVesselPayload, CreateVesselResponse, UpdateVesselPayload, Vessel } from 'models/vessel';
import { receiveVesselAllSuccess, receiveVesselSuccess } from 'reduxActions/vessel/vesselActions';

class VesselClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.VESSEL_SERVICE_PROTOCOL;
    this.host = process.env.VESSEL_SERVICE_HOST;
    this.port = process.env.VESSEL_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  vesselUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/vessel?${params}`;
  }

  getVesselUrl(id: string): string {
    return `${this.getBaseUrl()}/vessel/${id}`;
  }

  async getVessels(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', this.vesselUrl(params));

    try {
      const response: AxiosResponse<MetaVessels> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveVesselAllSuccess(response.data.vessels));
    } catch (error) {
      throw error.message;
    }
  }

  async getVessel(id: string): Promise<Vessel> {
    const request = this.makeRequest('GET', this.getVesselUrl(id));
    try {
      const response: AxiosResponse<Vessel> = await axios(request);
      const store = configureStore();

      store.dispatch(receiveVesselSuccess(response.data));
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }

  async createVessel(form: CreateVesselPayload): Promise<CreateVesselResponse> {
    const request = this.makeRequest('POST', this.vesselUrl(), form);

    try {
      const response: AxiosResponse<CreateVesselResponse> = await axios(request);

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async updateVessel(id: string, form: UpdateVesselPayload): Promise<void> {
    const request = this.makeRequest('PUT', this.getVesselUrl(id), form);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async deleteVessel(id: string): Promise<void> {
    const request = this.makeRequest('DELETE', this.getVesselUrl(id));
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error;
      }
    }
  }

}

export default VesselClient;