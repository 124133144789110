import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { RootState } from 'reduxActions/store';
import { CurrentUser } from 'models/auth';
import { connect } from 'react-redux';

interface MerchantRouteProps {
  currentUser: CurrentUser;
}

type Props = MerchantRouteProps & RouteProps;

const MerchantRoute = (props: Props): JSX.Element => {
  const { component: Component, currentUser, ...rest } = props;

  const isSysAdmin = currentUser && currentUser.user.roles.includes('SysAdmin')
    && currentUser.user.org_id === 'VersaFleet';
  const isMerchant = currentUser && currentUser.user.roles.includes('OrgAdmin');
  const isTransportPartner = currentUser && currentUser.user.roles.includes('OrgTransporter');

  let redirectPath: string;
  if (!currentUser) {
    redirectPath = '/login';
  } else if (isSysAdmin) {
    redirectPath = '/sys/tasks';
  } else if (isTransportPartner) {
    redirectPath = '/transport-partner';
  }

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode =>
        isMerchant ?
          <Component {...props} />
          : (
            <Redirect
              to={{
                pathname: redirectPath,
                state: { from: props.location },
              }}
            />
          )
      }
    />
  );
};

function mapStateToProps(state: RootState): MerchantRouteProps {
  return {
    currentUser: state.auth.currentUser,
  };
}

export default connect(mapStateToProps)(MerchantRoute);
