import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Button, SysAdminMainContainer, Dropdown } from 'components';
import { connect } from 'react-redux';
import TaskClient from 'httpClients/taskClient';
import { RootState } from 'reduxActions/store';
import { Task, AddTaskToRunsheetGenerationRequest } from 'models/task';
import { Organization } from 'models/organization';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import styled, { css } from 'styled-components';
import RunsheetGenerationClient from 'httpClients/runsheetGenerationClient';
import { RunsheetGenerationJob } from 'models/runsheetGeneration';
import STATUS from 'constants/taskStatus';
import { PER_PAGE } from 'constants/paginationMeta';
import { transparent } from 'constants/color';
import configureStore from 'reduxActions/store';
import { receiveTaskAllSuccess } from 'reduxActions/task/taskActions';
import { BROADCAST_PREFERENCE_DESCRIPTION } from 'constants/broadcastPreference';
import { receiveOrganizations } from 'reduxActions/organization/organizationActions';

interface RunsheetGenerationTaskAddState {
  error: string | null;
  success: string | null;
  runsheetGenerationId: string | null;
  checkedTasks: { [id: string]: Task };
  version_rev: string | null;
  filters: Record<string, string>;
}

interface RunsheetGenerationTaskAddProps {
  runsheetGenerationJob: RunsheetGenerationJob;
  tasks: Task[];
  organizations: Organization[];
}

type Props = RunsheetGenerationTaskAddProps & RouteComponentProps<{ id: string }>;

class RunsheetGenerationAddTask extends React.Component<Props, RunsheetGenerationTaskAddState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      runsheetGenerationId: null,
      checkedTasks: null,
      version_rev: null,
      filters: this.getDefaultFilter(),
    };
  }

  componentDidMount(): void {
    const { id } = this.props.match.params;
    this.setState({ runsheetGenerationId: id });
    this.fetchOrganizations();
    this.fetchTasks();
    this.fetchRunsheet(id);
  }

  componentDidUpdate(prevProps: Props, prevState: RunsheetGenerationTaskAddState): void {
    if (prevState.filters !== this.state.filters) {
      this.fetchTasks();
    }
  }

  fetchTasks = async (): Promise<void> => {
    const queryParams = new URLSearchParams(this.state.filters);

    const client = new TaskClient();
    const response = await client.sysAdminGetNoRunsheetTasks(queryParams);

    const store = configureStore();
    store.dispatch(receiveTaskAllSuccess(response.tasks));
  }

  fetchRunsheet = async (id: string): Promise<void> => {
    const client = new RunsheetGenerationClient();
    await client.getRunsheetGeneration(id);
  }

  fetchOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(new URLSearchParams({ page: pageNum.toString() }));
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch (e) {
      //
    }
  }

  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
    order: 'desc',
    is_qa: 'false',
  })

  onFilterChange = (fieldName: string, value: string, isDelete = false): void => {
    this.onClickDeselectAll();
    this.setState((prevState) => {
      const newFilters: Record<string, string> = { ...prevState.filters };

      if (isDelete) {
        delete newFilters[fieldName];
      } else {
        newFilters[fieldName] = value;
      }

      newFilters.page = '1';

      return {
        filters: newFilters,
      };
    });
  }

  checkboxChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const target = e.target;
    const targetTask: Task = this.props.tasks.find(task => task.id === target.value);

    if (target.checked) {
      this.setState(prevState => ({
        checkedTasks: {
          ...prevState.checkedTasks,
          [target.value]: targetTask,
        },
      }));
    } else {
      this.setState(prevState => {
        const prevCheckedTasks = { ...prevState.checkedTasks };
        delete prevCheckedTasks[target.value];
        return ({ checkedTasks: prevCheckedTasks });
      });
    }
  }

  handlePageChangeClick = (page: number): void => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        page: (page).toString(),
      },
    }));
  };

  onClickDeselectAll = (): void => {
    this.setState({ checkedTasks: null });
  }

  onClickSelectAll = (): void => {
    const selectedAllTask: { [id: string]: Task } = {};

    this.props.tasks.forEach(task => {
      selectedAllTask[task.id] = task;
    });

    this.setState(prevState => ({
      checkedTasks: {
        ...prevState.checkedTasks,
        ...selectedAllTask,
      },
    }));
  }

  handleAddTaskToRunsheetGeneration = async (): Promise<void> => {
    const { runsheetGenerationId, checkedTasks } = this.state;
    const { runsheetGenerationJob } = this.props;

    this.setState({ error: null, success: null });
    try {
      const client = new RunsheetGenerationClient();
      const addTaskRequest: AddTaskToRunsheetGenerationRequest = {
        tasks: Object.values(checkedTasks),
        version_rev: runsheetGenerationJob.version_rev,
      };

      const response = await client.addTaskToRunsheetGeneration(runsheetGenerationId, addTaskRequest);
      // TODO: to display success assignment count
      this.setState({ success: response.message });
      setTimeout(() => {
        this.props.history.push(`/sys/runsheet-generations/${runsheetGenerationId}`);
      }, 1000);
    } catch (e) {
      if (e.details) {
        this.setState({ error: JSON.stringify(e.details) });
      } else {
        this.setState({ error: e.message || e.error || 'unknown-error' });
      }
    }
  }

  renderFilter = (): React.ReactNode => {
    const organizationList = this.props.organizations.map(org => (
      {
        value: org.id,
        name: org.business_name,
      }
    ));
    return (
      <FilterContainer>
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem;
          `}
          label="Sort by"
          options={[
            {
              value: 'desc',
              name: 'Newest First',
            },
            {
              value: 'asc',
              name: 'Oldest First',
            },
          ]}
          onChange={(value: string): void => { this.onFilterChange('order', value); }}
          value={this.state.filters.order}
        />
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem;
          `}
          label="Organization"
          options={organizationList}
          includeAll
          onChange={(value: string): void => {
            const isDelete = value === '';
            this.onFilterChange('org_id', value, isDelete);
          }}
          value={this.state.filters.org_id || ''}
          withSearch
          searchPlaceholder="Find Organisation..."
          sorted
        />
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem;
          `}
          label="IsQA"
          options={[
            {
              value: '',
              name: 'All',
            },
            {
              value: 'true',
              name: 'QA',
            },
            {
              value: 'false',
              name: 'Not QA',
            },
          ]}
          includeAll
          onChange={(value: string): void => {
            const isDelete = value === '';
            this.onFilterChange('is_qa', value, isDelete);
          }}
          value={this.state.filters.is_qa || ''}
        />
        <Dropdown
          containerStyle={css`
            margin: 0 0.5rem;
          `}
          label="BroadcastPreference"
          options={[
            {
              value: '',
              name: 'Show All',
            },
            {
              value: 'all',
              name: 'All Driver-Partner Only',
            },
            {
              value: 'squad',
              name: 'Squad Only',
            },
          ]}
          includeAll
          onChange={(value: string): void => {
            const isDelete = value === '';
            this.onFilterChange('broadcast_preference', value, isDelete);
          }}
          value={this.state.filters.broadcast_preference || ''}
        />
      </FilterContainer>
    );
  }

  renderListItem = (task: Task, index: number): React.ReactNode => (
    <StyledListItem key={index}>
      <input
        key={task.id}
        name={task.id}
        onChange={this.checkboxChanged}
        type="checkbox"
        checked={
          this.state.checkedTasks && task.id in this.state.checkedTasks ? true : false
        }
        value={task.id}
      />
      {`Delivery Address: ${task.to_address && task.to_address.street_address}
        | Postal code: ${task.to_address && task.to_address.zip_code || '-'}
        | Runsheet ID: ${task.runsheet ? task.runsheet.id : '-'}
        | Status: ${task.current_status && STATUS[task.current_status]}
        | Broadcast Times: ${task.price_table ? task.price_table.length : 0}x
        to ${task.broadcast_preference ? BROADCAST_PREFERENCE_DESCRIPTION[task.broadcast_preference].label : BROADCAST_PREFERENCE_DESCRIPTION['all'].label}
        ${task.is_qa ? '| QA' : ''}
      `}
    </StyledListItem>
  );

  render(): React.ReactNode {
    const { runsheetGenerationId, checkedTasks, error, success, filters } = this.state;
    const isTasksSelectedEmpty = !checkedTasks || Object.keys(checkedTasks).length === 0;
    const page = parseInt(filters.page);

    const numberOfTasks = this.props.tasks.length;
    const numStart = PER_PAGE * (page - 1) + 1;
    const numEnd = PER_PAGE * (page - 1) + numberOfTasks;

    return (
      <SysAdminMainContainer selected="runsheet-generations">
        <PageHeader>
          <Title>Add Tasks to Runsheet Generation</Title>
          <div>
            {page > 1 && (
              <Button onClick={(): void => {
                this.handlePageChangeClick(page - 1);
              }}>
                Previous
              </Button>
            )}
            {numberOfTasks > 0 && numberOfTasks === PER_PAGE && (
              <Button onClick={(): void => {
                this.handlePageChangeClick(page + 1);
              }}>
                Next
              </Button>
            )}
            <Link to={`/sys/runsheet-generations/${runsheetGenerationId}`}>
              <Button>Back</Button>
            </Link>
          </div>
        </PageHeader>
        <div>
          <h3>List Orders</h3>
          {this.renderFilter()}
          <p>
            Orders {numberOfTasks > 0 && `${numStart} - ${numEnd}`}
            {numberOfTasks === 0 && 'No order to be added'}
          </p>
          <p>You can select multiple orders</p>
          <p>Number of Orders Selected : {checkedTasks ? Object.keys(checkedTasks).length : '0'}</p>
          <Button
            onClick={this.onClickDeselectAll}
            disabled={isTasksSelectedEmpty}
          >
            Deselect All Orders
          </Button>
          <Button
            onClick={this.onClickSelectAll}
          >
            Select All Orders in this page
          </Button>
          {error && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
          {success && (
            <SuccessMessage>{success}</SuccessMessage>
          )}
          <StyledList>
            {this.props.tasks.length !== 0
              ? this.props.tasks.map((task, index) => this.renderListItem(task, index))
              : 'There is no task data'}
          </StyledList>
          <Button
            onClick={this.handleAddTaskToRunsheetGeneration}
            disabled={isTasksSelectedEmpty}
          >
            Add Orders to Runsheet Generation
          </Button>
        </div>
      </SysAdminMainContainer>
    );
  }
}

const StyledList = styled.ul`
  flex: auto;
  flex-direction: column;
  margin: 0;
  position: relative;
  overflow-x: hidden;
  list-style: none;
`;

const StyledListItem = styled('li')`
  background-color: none;
  color: #333333;
  border: 1 solid ${transparent('black', 0.125)};
  padding: 0.625rem;
  position: relative;
  text-decoration: none;
  &:last-child {
    margin-bottom: 0;
  }
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 0.625rem 0;
`;

const SuccessMessage = styled.div`
  color: #2ecc71;
  margin: 0.625rem 0;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const mapStateToProps = (state: RootState): RunsheetGenerationTaskAddProps => ({
  tasks: state.task.tasks,
  runsheetGenerationJob: state.runsheetGeneration.runsheetGenerationJob,
  organizations: state.organization.organizations,
});

export default connect(mapStateToProps)(RunsheetGenerationAddTask);
