import {
  OrganizationState,
  OrganizationActionTypes,
  RECEIVE_ORGANIZATION,
  RECEIVE_ORGANIZATIONS,
  RECEIVE_PENDING_ORGANIZATIONS,
  RECEIVE_SQUADS,
  RECEIVE_SQUAD_DRIVERS,
} from './organizationTypes';

const initialState: OrganizationState = {
  organizations: [],
  organization: null,
  pendingOrganizations: [],
  squads: [],
  squadDrivers: [],
};

function organizationReducer(
  state: OrganizationState = initialState,
  action: OrganizationActionTypes
): OrganizationState {
  switch (action.type) {
    case RECEIVE_ORGANIZATION:
      return { ...state, organization: action.organization };
    case RECEIVE_ORGANIZATIONS:
      return { ...state, organizations: action.organizations };
    case RECEIVE_PENDING_ORGANIZATIONS:
      return { ...state, pendingOrganizations: action.organizations };
    case RECEIVE_SQUADS:
      return { ...state, squads: action.squads };
    case RECEIVE_SQUAD_DRIVERS:
      return { ...state, squadDrivers: action.squadDrivers };
    default:
      return state;
  }
}

export default organizationReducer;
