import * as React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon as LeafletIcon } from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import { Position } from 'models/geoService/geoData';

// const DEFAULT_ZOOM = 14;
const FIND_ZOOM = 17;
const DEFAULT_LAT = 1.29027;
const DEFAULT_LNG = 103.851959;
const MAP_URL = 'https://www.onemap.gov.sg/maps/tiles/Default/{z}/{x}/{y}.png';
const MAP_ATTRIBUTION =
  'OneMap | Map data &copy; contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>';

interface MapMarkerProps {
  position?: Position;
  onPositionChange?: (position: Position) => void;
}

interface MapMarkerState {
  isMapReady: boolean;
  mapPosition: Position;
}

class MapMarker extends React.Component<MapMarkerProps, MapMarkerState> {
  constructor(props: MapMarkerProps) {
    super(props);
    this.state = {
      isMapReady: false,
      mapPosition: {
        lat: this.props.position?.lat || DEFAULT_LAT,
        lng: this.props.position?.lng || DEFAULT_LNG,
      },
    };
  }

  refmarker = React.createRef<L.Marker>();
  refMap = React.createRef<L.Map>();

  componentDidUpdate(prevProps: MapMarkerProps): void {
    if (
      this.props.position &&
      (prevProps.position?.lat !== this.props.position.lat ||
        prevProps.position?.lng !== this.props.position.lng)
    ) {
      if (this.isValidLatLng(this.props.position.lat, this.props.position.lng)) {
        const map = this.refMap.current;
        map?.flyTo(
          {
            lat: this.props.position.lat,
            lng: this.props.position.lng,
          },
          FIND_ZOOM
        );

        this.setState({
          mapPosition: {
            lat: this.props.position.lat || DEFAULT_LAT,
            lng: this.props.position.lng || DEFAULT_LNG,
          },
        });
      }
    }
  }

  markerEventHandlers = () => {
    return {
      dragend: () => {
        this.updatePosition();
      },
    };
  };

  updatePosition = (): void => {
    const marker = this.refmarker.current;
    if (marker != null) {
      const { lat, lng } = marker.getLatLng();
      this.setState((prevState) => ({
        mapPosition: {
          ...prevState.mapPosition,
          lat,
          lng,
        },
      }));

      if (this.props.onPositionChange) {
        this.props.onPositionChange({ lat, lng });
      }
    }
  };

  isValidLatLng = (lat: number, lng: number): boolean => {
    if (
      lat === null ||
      lat === 0 ||
      isNaN(lat) ||
      lng === null ||
      lng === 0 ||
      isNaN(lng)
    ) {
      return false;
    }
    return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
  };

  render(): React.ReactNode {
    return (
      <>
        <MapContainer
          ref={this.refMap}
          style={{ width: '100%', height: '300px' }}
          center={[this.state.mapPosition.lat, this.state.mapPosition.lng]}
          zoom={FIND_ZOOM}
          maxZoom={18}
          minZoom={11}
          scrollWheelZoom={false}
          useFlyTo={true}
          maxBounds={[
            [1.56073, 104.1147],
            [1.16, 103.502],
          ]}
          whenReady={() => {
            this.setState({ isMapReady: true });
          }}
        >
          <TileLayer url={MAP_URL} attribution={MAP_ATTRIBUTION} />
          <Marker
            draggable={true}
            position={[this.state.mapPosition.lat, this.state.mapPosition.lng]}
            ref={this.refmarker}
            eventHandlers={this.markerEventHandlers()}
            icon={
              new LeafletIcon({
                iconUrl: markerIconPng,
                iconSize: [25, 41],
                iconAnchor: [12, 10],
              })
            }
          >
            <Popup minWidth={90}>
              <span>Drag to pinpoint exact location</span>
            </Popup>
          </Marker>
        </MapContainer>
      </>
    );
  }
}

export default MapMarker;
