import * as React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';

interface HeaderTitleProps {
  title: string;
}
const HeaderTitle = ({ title }: HeaderTitleProps) => (
  <View>
    <Text style={styles.titleLarge}>{title}</Text>
  </View>
);

export default HeaderTitle;
