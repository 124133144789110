import styled from 'styled-components';

interface ColumnProps {
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
}

function getWidth (portion: number): number {
  if (portion) {
    return portion / 12 * 100;
  }

  return 100;
}

// Bootstrap-like responsive container
const Column = styled.div<ColumnProps>`
  float: left;
  width: ${(props): number => getWidth(props.xs)}%;
  
  @media only screen and (min-width: 768px) and (min-device-width: 768px){
    width: ${(props): number => getWidth(props.sm)}%;
  }

  @media only screen and (min-width: 992px){
    width: ${(props): number => getWidth(props.md)}%;
  }

  @media only screen and (min-width: 1200px) {
    width: ${(props): number => getWidth(props.lg)}%;
  }
`;

export default Column;