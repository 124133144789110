import COLOR from './color';

interface JobStatusColor {
  [key: string]: string;
}

interface JobStatus {
  [key: string]: string;
}

const JOB_STATUS: JobStatus = {
  'failed': 'Failed',
  'created': 'Created',
  'in_progress': 'In Progress',
  'done': 'Done',
  'cancelled': 'Stopped',
};

export const JOB_STATUS_COLOR: JobStatusColor = {
  'cancelled': COLOR.red,
  'failed': COLOR.red,
  'created': COLOR.cyan,
  'in_progress': COLOR.blue,
  'done': COLOR.lime,
};

export default JOB_STATUS;