import { TaskImportJob, TaskImportStatus } from 'models/taskImport';
import {
  TaskImportState,
  TaskImportHash,
  TaskImportActionTypes,
  RECEIVE_TASK_IMPORT_JOBS,
  RECEIVE_TASK_IMPORT_JOB,
  RECEIVE_TASK_IMPORT_JOB_RESULT,
  UPDATE_IN_PROGRESS_STATUS,
} from './taskImportTypes';

const initialState: TaskImportState = {
  taskImportHash: {},
  taskImportIds: [],
};

function combineJob(
  state: TaskImportState, job: TaskImportJob | TaskImportJob[]
): TaskImportHash {
  const combinedJobs = { ...state.taskImportHash };

  if (job instanceof Array) {
    job.forEach(elem => {
      combinedJobs[elem.id] = {
        ...state.taskImportHash[elem.id],
        ...elem,
      };
    });
  } else {
    combinedJobs[job.id] = {
      ...state.taskImportHash[job.id],
      ...job,
    };
  }

  return combinedJobs;
}

function taskImportReducer(
  state: TaskImportState = initialState,
  action: TaskImportActionTypes
): TaskImportState {
  switch (action.type) {
    case RECEIVE_TASK_IMPORT_JOBS:
      return {
        ...state,
        taskImportIds: action.taskImportJobs.map(data => data.id),
        taskImportHash: combineJob(state, action.taskImportJobs),
      };
    case RECEIVE_TASK_IMPORT_JOB:
      return { ...state, taskImportHash: combineJob(state, action.taskImportJob) };
    case RECEIVE_TASK_IMPORT_JOB_RESULT:
      return {
        ...state,
        taskImportHash: combineJob(state, {
          ...state.taskImportHash[action.taskImportJobResult.task_import_job_id],
          result: action.taskImportJobResult,
        }),
      };
    case UPDATE_IN_PROGRESS_STATUS:
      return {
        ...state,
        taskImportHash: combineJob(state, {
          ...state.taskImportHash[action.id],
          current_status: TaskImportStatus['inProgress'],
        }),
      };
    default:
      return state;
  }
}

export default taskImportReducer;
