import * as React from 'react';
import * as H from 'history';
import * as moment from 'moment';
import styled from 'styled-components';
import { Button, SysAdminMainContainer, Message } from 'components';
import { Link } from 'react-router-dom';
import { TaskExportForm } from 'models/taskExport';
import { Organization } from 'models/organization';
import TaskExportClient from 'httpClients/taskExportClient';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import DatePicker from 'react-datepicker';
// TODO: put in the css loader
import 'react-datepicker/dist/react-datepicker.css';
import configureStore from 'reduxActions/store';
import { receiveOrganizations } from 'reduxActions/organization/organizationActions';

interface TaskExportNewProps {
  history: H.History;
}

interface StateProps {
  organizations: Organization[];
}

interface TaskExportNewState {
  form: TaskExportForm;
  error: string | null;
}

type Props = TaskExportNewProps & StateProps;

class TaskExportNew extends React.Component<Props, TaskExportNewState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: this.getDefaultForm(),
      error: null,
    };
  }

  componentDidMount(): void {
    this.fetchOrganizations();
  }

  getDefaultForm(): TaskExportForm {
    const date = new Date();

    return {
      org_id: '',
      org_name: '',
      name: '',
      type: 'basic',
      creation_start_date: date,
      creation_end_date: date,
    };
  }

  _parseToUTC = (date: Date): Date => moment.parseZone(date).utc(true).toDate();

  fetchOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(new URLSearchParams({ page: pageNum.toString() }));
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch (e) {
      //
    }
  }

  handleChange = (field: string, value: string | Date): void => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  handleChangeOrg = (orgId: string): void => {
    let businessName = '';
    if (orgId !== 'all') {
      const getOrg = this.props.organizations.find(org => org.id === orgId);
      businessName = getOrg.business_name;
    } else {
      businessName = 'all';
    }

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        org_id: orgId,
        org_name: businessName,
      },
    }));
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const form: TaskExportForm = {
      ...this.state.form,
      creation_start_date: this._parseToUTC(this.state.form.creation_start_date),
      creation_end_date: this._parseToUTC(this.state.form.creation_end_date),
    };

    try {
      const client = new TaskExportClient();
      const job = await client.createJob(form);
      this.setState({ error: null });
      this.props.history.push(`/sys/tasks/export/${job}`);
    } catch (error) {
      this.setState({ error });
    }
  }

  render(): React.ReactNode {
    const { form, error } = this.state;

    return (
      <SysAdminMainContainer selected="tasks">
        <>
          <div>
            <h1>Create Task Export Job</h1>
            <Link to="/sys/tasks/export">
              <Button>Back</Button>
            </Link>
          </div>

          {error && (
            <Message className='error'>{error}</Message>
          )}

          <Form onSubmit={this.handleSubmit}>
            <FormGroup key="form_org_id">
              <Label>
                Organization <Required>*</Required>
              </Label>
              <DropDown
                name="org_id"
                onChange={(e): void => {
                  this.handleChangeOrg(e.target.value);
                }}
                value={form.org_id}
                required
              >
                <option value="">-- Select --</option>
                <option value="all">All</option>
                {this.props.organizations.map((org: Organization, index: number) =>
                  <option key={index} value={org.id}>{org.business_name}</option>
                )}
              </DropDown>
            </FormGroup>
            <FormGroup key="form_name">
              <Label>
                Name <Required>*</Required>
              </Label>
              <TextField
                required
                type="text"
                name="name"
                value={form.name}
                onChange={(e): void => this.handleChange('name', e.target.value)}
              />
            </FormGroup>
            <FormGroup key="form_type">
              <Label>
                Export Worker Type <Required>*</Required>
              </Label>
              <DropDown
                name="type"
                onChange={(e): void => this.handleChange('type', e.target.value)}
                value={form.type}
              >
                <option value="basic">Basic</option>
                <option value="fedex">FedEx</option>
              </DropDown>
            </FormGroup>
            <FormGroup>
              <h3>Export Range (UTC)</h3>
            </FormGroup>
            <FormGroup key="form_creation_start_date">
              <Label>From</Label>
              <DatePicker
                dateFormat="yyyy-MM-dd, h:mm aa"
                selected={form.creation_start_date}
                onChange={(value): void => {
                  if (value instanceof Date) {
                    this.handleChange('creation_start_date', value);
                  }
                }}
                selectsStart
                startDate={form.creation_start_date}
                endDate={form.creation_end_date}
                showTimeSelect
              />
            </FormGroup>
            <FormGroup key="form_creation_end_date">
              <Label>To</Label>
              <DatePicker
                dateFormat="yyyy-MM-dd, h:mm aa"
                selected={form.creation_end_date}
                onChange={(value): void => {
                  if (value instanceof Date) {
                    this.handleChange('creation_end_date', value);
                  }
                }}
                selectsEnd
                startDate={form.creation_start_date}
                endDate={form.creation_end_date}
                minDate={form.creation_start_date}
                showTimeSelect
              />
            </FormGroup>
            <FormGroup>
              <Button type="submit">Create</Button>
            </FormGroup>
          </Form>
        </>
      </SysAdminMainContainer>
    );
  }
}

const Form = styled.form`
  margin-top: 1.5rem;
`;

const FormGroup = styled.div`
  margin: 0.5rem 0;
`;

const Label = styled.label`
  width: 10rem;
  float: left;
`;

const DropDown = styled.select`
  width: 20.5rem;
  padding: 0.25rem;
`;

const TextField = styled.input`
  width: 20.5rem;
  padding: 0.25rem;
`;

const Required = styled.span`
  color: red;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  organizations: state.organization.organizations,
});

export default connect(mapStateToProps)(TaskExportNew);
