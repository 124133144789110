import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import {
  GetTaskExportJobResponse, GetTaskExportJobsResponse,
  TaskExportForm, TaskExport,
} from 'models/taskExport';
import configureStore from 'reduxActions/store';
import { receiveTaskExportJob, receiveTaskExportJobs } from 'reduxActions/taskExport/taskExportActions';
import { getBearerToken } from 'utils/userHelper';
import * as HttpHelper from 'utils/httpHelper';

class TaskExportClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.TASK_EXPORT_SERVICE_PROTOCOL;
    this.host = process.env.TASK_EXPORT_SERVICE_HOST;
    this.port = process.env.TASK_EXPORT_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  jobUrl(): string {
    return `${this.getBaseUrl()}/sys/export`;
  }

  retryJobUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/retry-export/${id}`;
  }

  getJobUrl(id: string): string {
    return `${this.jobUrl()}/${id}`;
  }

  startJobUrl(id: string): string {
    return `${this.getJobUrl(id)}/start`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  async getJobs(): Promise<void> {
    const request = this.makeRequest('GET', this.jobUrl());

    try {
      const response: AxiosResponse<GetTaskExportJobsResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskExportJobs(response.data.jobs));
    } catch (error) {
      throw error.message;
    }
  }

  async getJob(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.getJobUrl(id));

    try {
      const response: AxiosResponse<GetTaskExportJobResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskExportJob(response.data.job));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async createJob(form: TaskExportForm): Promise<string> {
    const request = this.makeRequest('POST', this.jobUrl(), form);

    try {
      const response: AxiosResponse<GetTaskExportJobResponse> = await axios(request);
      return response.data.job.id;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async retryCreateJob(id: string): Promise<TaskExport> {
    const request = this.makeRequest('POST', this.retryJobUrl(id));

    try {
      const response: AxiosResponse<GetTaskExportJobResponse> = await axios(request);
      return response.data.job;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async startJob(id: string, versionRev: string): Promise<void> {
    const request = this.makeRequest('POST', this.startJobUrl(id), {
      version_rev: versionRev,
    });

    try {
      const response: AxiosResponse<GetTaskExportJobResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskExportJob(response.data.job));
    } catch (error) {
      if (error.response) {
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }
}

export default TaskExportClient;
