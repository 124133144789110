import * as React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';

interface AdditionalRemarksProps {
  deliveryNote: string;
}
const DeliveryNote = ({ deliveryNote }: AdditionalRemarksProps) => {
  if (!deliveryNote) {
    return null;
  }
  return (
    <View
      style={{
        flexDirection: 'column',
        marginBottom: 20,
      }}
    >
      <Text style={styles.titleSmall}>Instructions to Driver</Text>
      <Text style={styles.textMedium}>{deliveryNote}</Text>
    </View>
  );
};

export default DeliveryNote;
