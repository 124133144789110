import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import COLOR, { transparent } from 'constants/color';
import { StyledButton, TextInput, Alert } from 'components';
import * as H from 'history';
import AuthClient from 'httpClients/authClient';
import PartnershipClient from 'httpClients/partnershipClient';
import { PartnerAuthorization } from 'models/partnership';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import Container, { HEIGHTS } from './container';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

type PageState = 'login' | 'consent' | 'error';

const ERRORS = {
  variable_missing: 'variable_missing',
  client_unauthorized: 'client_unauthorized',
  unauthorized: 'unauthorized',
};

interface AuthProps {
  partnerAuthorization: PartnerAuthorization;
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface AuthState {
  form: {
    email: string;
    password: string;
    rememberMe: boolean;
  };
  bearerToken: string;
  refreshToken: string;
  error: string | null;
  isFetching: boolean;
  passwordShown: boolean;
  pageState: PageState;
  redirectCountdown: number;
  queryParams: Record<string, string>;
}

type Props = AuthProps & HistoryProps;

class Auth extends React.Component<Props, AuthState> {
  timer: ReturnType<typeof setInterval> | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      form: {
        email: '',
        password: '',
        rememberMe: false,
      },
      bearerToken: '',
      refreshToken: '',
      error: null,
      isFetching: false,
      passwordShown: false,
      pageState: 'login',
      redirectCountdown: 0,
      queryParams: {},
    };

    this.timer = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const params = new URLSearchParams(this.props.location.search).entries();
    const queryParams: Record<string, string> = {};

    for (const param of params) {
      queryParams[param[0]] = param[1];
    }
    this.setState({ queryParams });
    if (!queryParams.client_key || !queryParams.redirect_url) {
      this.setState({ pageState: 'error' });
    }
    await this.fetchPartnerAuthorization(queryParams.client_key);
  }

  componentDidUpdate(prevProps: AuthProps, prevState: AuthState): void {
    const { pageState, queryParams } = this.state;
    if (prevState.pageState !== pageState && pageState === 'error') {
      if (queryParams.redirect_url) {
        const redirectParams = new URLSearchParams();
        if (!queryParams.client_key) {
          redirectParams.append('error', ERRORS.variable_missing);
        } else {
          redirectParams.append('error', ERRORS.unauthorized);
        }
        this.startCountdownRedirect(queryParams.redirect_url, redirectParams);
      }
    }
  }

  componentWillUnmount(): void {
    this.removeCountdownRedirect();
  }

  startCountdownRedirect(url: string, redirectParams = new URLSearchParams()): void {
    this.setState({ redirectCountdown: 5 });
    this.timer = setInterval(() => {
      const { redirectCountdown } = this.state;
      this.setState(prevState => ({
        redirectCountdown: prevState.redirectCountdown - 1,
      }));
      if (redirectCountdown <= 0) {
        this.removeCountdownRedirect();
        this.redirect(url, redirectParams);
      }
    }, 1000);
  }

  removeCountdownRedirect(): void {
    if (this.timer) {
      this.setState({ redirectCountdown: 0 });
      clearInterval(this.timer);
    }
  }

  redirect(url: string, redirectParams = new URLSearchParams()): void {
    const { queryParams } = this.state;
    if (queryParams.state) {
      redirectParams.append('state', queryParams.state);
    }
    const fmtUrl = !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    location.assign(`${fmtUrl}${redirectParams ? `?${redirectParams}` : ''}`);
  }

  async handleLogin(): Promise<void> {
    const { form } = this.state;
    if (!this.state.isFetching) {
      if (form.email.trim().length === 0 || form.password.trim().length === 0) {
        this.setState({ error: 'Enter your email and password to log in.' });
        return;
      }

      this.setState({ error: null, isFetching: true, bearerToken: '', refreshToken: '' });
      await this.authLogin(3);
    }
  }

  handleChange(field: string, value: string): void {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  }

  handleKeyDown(keyValue: string): void {
    if (keyValue === 'Enter') {
      this.handleLogin();
    }
  }

  async authLogin(maxRetries: number): Promise<void> {
    const { form } = this.state;
    const client = new AuthClient();
    const { partnerAuthorization } = this.props;
    try {
      const response = await client.partnershipSignIn({
        user: {
          email: form.email,
          password: form.password,
          azp: {
            key: partnerAuthorization.client_key,
            name: partnerAuthorization.name,
          },
        },
      });
      const roles = response.user.roles;
      const bearerToken = response.bearer_token;
      const refreshToken = response.refresh_token;
      if (roles.includes('OrgAdmin')) {
        this.setState({ pageState: 'consent', isFetching: false, bearerToken, refreshToken });
      } else if (roles.includes('FreelanceDriver')) {
        this.setState({ error: 'Your account is not allowed to access the web app', isFetching: false, bearerToken: '', refreshToken: '' });
      } else {
        this.setState({ error: 'Invalid Email or password', isFetching: false, bearerToken: '', refreshToken: '' });
      }
    } catch (e) {
      if (e.includes('Invalid Email or password')) {
        this.setState({
          error: e,
          isFetching: false,
        });
      } else if (e.includes('You have a pending invitation. Check your email and accept the invitation to log in.')) {
        this.setState({
          error: e,
          isFetching: false,
        });
      } else if (maxRetries > 0) {
        setTimeout(
          () => {
            this.setState({
              error: 'This is taking a little longer than expected. Hang in there!',
            });
            this.authLogin(maxRetries - 1);
          },
          (5 * 1000),
        );
      } else {
        this.setState({
          error: 'Please wait a few minutes before you try again.',
          isFetching: false,
        });
      }
    }
  }

  async fetchPartnerAuthorization(partnerClientKey: string): Promise<void> {
    const client = new PartnershipClient();
    try {
      this.setState({ isFetching: true });
      await client.getPartnerAuthorization(partnerClientKey);
    } catch (e) {
      this.setState({ pageState: 'error', isFetching: false });
    }
    this.setState({ isFetching: false });
  }

  authenticateUser = async (): Promise<void> => {
    const client = new PartnershipClient();
    const { partnerAuthorization } = this.props;
    const { bearerToken, refreshToken, queryParams } = this.state;
    try {
      this.setState({ isFetching: true });
      const response = await client.userAuthentication(bearerToken, {
        client_auth: {
          key: partnerAuthorization.client_key,
          name: partnerAuthorization.name,
        },
        refresh_token: refreshToken,
      });

      const redirectParams = new URLSearchParams();
      redirectParams.append('code', response.auth_code);
      this.redirect(queryParams.redirect_url, redirectParams);
    } catch (e) {
      this.setState({ isFetching: false });
    }
    this.setState({ isFetching: true });
  }

  renderLogin = (): React.ReactNode => {
    const { form, error, passwordShown } = this.state;
    return (
      <LoginBox>
        <FormContainer>
          <Title>Log in</Title>
          <Form>
            <TextInput
              fieldName="Email"
              isRequired
              name="email"
              onKeyDown={(e): void => this.handleKeyDown(e.key)}
              onTextChange={(value): void => this.handleChange('email', value)}
              type="text"
              value={form.email}
              width="full"
            />
          </Form>
          <Form>
            <TextInput
              fieldName="Password"
              icon={passwordShown ? faEye : faEyeSlash}
              iconOnClick={(): void => {
                this.setState(prevState => ({ passwordShown: !prevState.passwordShown }));
              }}
              isRequired
              name="password"
              onKeyDown={(e): void => this.handleKeyDown(e.key)}
              onTextChange={(value): void => this.handleChange('password', value)}
              type={passwordShown ? 'text' : 'password'}
              value={form.password}
            />
          </Form>
          {error && (
            <ErrorMessage status='error'>{error}</ErrorMessage>
          )}
          <LoginButtonContainer>
            <StyledButton
              buttonStyle="encourage"
              buttonType="primary"
              disabled={this.state.isFetching}
              onClick={(): void => { this.handleLogin(); }}
              isFullWidth={true}
              size='lg'
            >
              Log in
            </StyledButton>
          </LoginButtonContainer>
          <Register>
            No account yet? <StyledLink target='_blank' to='/register'>Register</StyledLink>
          </Register>
          <ForgotPassword>
            <StyledLink target='_blank' to='/forgot-password'>Forgot Password</StyledLink>
          </ForgotPassword>
        </FormContainer>
      </LoginBox>
    );
  }

  renderConsent = (): React.ReactNode => {
    const { isFetching, queryParams } = this.state;
    const { partnerAuthorization } = this.props;
    return (
      <ConsentBox>
        <ConsentTitle>
          {partnerAuthorization.name} wants to access your GotSurge account
        </ConsentTitle>
        <GreyText>
          {partnerAuthorization.name} is requesting permission to access GotSurge account
        </GreyText>
        <SubTitle>
          {partnerAuthorization.name} will have access the following in your GotSurge account:
        </SubTitle>
        <SubTitle>
          {partnerAuthorization.name} will be able to view
        </SubTitle>
        <ListItems>
          <ul>
            <li>Content and info about you</li>
            <li>Content and info about your orders</li>
            <li>Content and info about your order statuses</li>
          </ul>
        </ListItems>
        <SubTitle>
          {partnerAuthorization.name} will be able to
        </SubTitle>
        <ListItems>
          <ul>
            <li>Perform actions as you</li>
            <li>Perform actions in orders</li>
          </ul>
        </ListItems >
        <ActionButtons>
          <StyledButton
            onClick={(): void => {
              const redirectParams = new URLSearchParams();
              redirectParams.append('error', ERRORS.client_unauthorized);
              this.redirect(queryParams.redirect_url, redirectParams);
            }}
            buttonStyle="encourage"
            buttonType="neutral"
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={this.authenticateUser}
            buttonStyle="encourage"
            buttonType="primary"
            disabled={isFetching}
          >
            {isFetching ? <Icon color={COLOR.grey} icon={faCircleNotch} /> : false}
            Allow Access
          </StyledButton>
        </ActionButtons>
      </ConsentBox >
    );
  }

  renderError = (): React.ReactNode => {
    const { queryParams, redirectCountdown } = this.state;
    return (
      <ErrorBox>
        <Title>Unable to authorise</Title>
        <ErrorMessage status='error'>
          {'It appears you\'re unauthorised to access this page.'}
        </ErrorMessage>
        {queryParams.redirect_url ? (
          <>
            <ActionButtons>
              <RedirectText>
                {`You'll be redirected back in ${redirectCountdown} seconds.`}
              </RedirectText>
              <StyledButton
                onClick={(): void => {
                  const redirectParams = new URLSearchParams();
                  if (!queryParams.client_key) {
                    redirectParams.append('error', ERRORS.variable_missing);
                  } else {
                    redirectParams.append('error', ERRORS.unauthorized);
                  }
                  this.redirect(queryParams.redirect_url, redirectParams);
                }}
                buttonStyle="encourage"
                buttonType="neutral"
              >
                OK
              </StyledButton>
            </ActionButtons>
          </>
        ) : false}
      </ErrorBox>
    );
  }

  render(): React.ReactNode {
    const { pageState } = this.state;
    let content: React.ReactNode;
    let height: keyof typeof HEIGHTS = 'auto';

    if (pageState === 'login') {
      content = this.renderLogin();
    } else if (pageState === 'consent') {
      content = this.renderConsent();
    } else if (pageState === 'error') {
      content = this.renderError();
      height = 'full';
    }
    return (
      <Container height={height}>
        {content}
      </Container>
    );
  }
}

const LoginBox = styled.div`
  box-shadow: 0px 0px 10px ${transparent('black', 0.15)};
  background-color: ${COLOR.white};
  border-radius: 8px;
  height: fit-content;
  margin-top: 4rem;
  width: 24.188rem;
`;

const ConsentBox = styled.div`
  box-shadow: 0px 0px 10px ${transparent('black', 0.25)};
  background-color: ${COLOR.white};
  border-radius: 8px;
  padding-left: 2.875rem;
  padding-right: 2.875rem;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  height: fit-content;
  margin-top: 4rem;
  width: 35.5rem;
`;

const ErrorBox = styled.div`
  box-shadow: 0px 0px 10px ${transparent('black', 0.25)};
  background-color: ${COLOR.white};
  border-radius: 8px;
  padding: 1.5rem;
  height: fit-content;
`;

const FormContainer = styled.div`
  padding: 1.875rem;
`;

const StyledLink = styled(Link)`
  font-size: 1rem;
  text-decoration: underline;
  color: ${COLOR.blue};
`;

const ForgotPassword = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const Form = styled.div`
  margin-bottom: 1.25rem;
`;

const Title = styled.div`
  color: ${COLOR.black};
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.75rem;
`;

const ConsentTitle = styled.div`
  color: ${COLOR.black};
  font-size: 1.5rem;
  font-weight: bold;
`;

const GreyText = styled.div`
  color: ${COLOR.midDarkGrey};
  margin-bottom: 1rem;
`;

const SubTitle = styled.div`
  color: ${COLOR.darkGray};
  margin-bottom: 0.625rem;
`;

const LoginButtonContainer = styled.div`
  margin-top: 1.875rem;
`;

const Register = styled.div`
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;
`;

const ListItems = styled.div`
  color: ${COLOR.midDarkGrey};
  margin-bottom: 1rem;

  ul {
    margin: 0;
    padding-left: 1rem;
  }
`;

const ErrorMessage = styled(Alert)`
  margin-bottom: 1rem;
`;

const RedirectText = styled.div`
  color: ${COLOR.midDarkGrey};
  font-size: 0.875rem;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.875rem;
  gap: 0.5rem;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string; fontSize?: string }>`
  color: ${(props): string => props.color};
  font-size: ${(props): string => props.fontSize ? props.fontSize : '0.75rem'};
  margin-right: 0.5rem;
  align-self: center;
`;

const mapStateToProps = (state: RootState): AuthProps => ({
  partnerAuthorization: state.partnership.partnerAuthorization,
});

export default connect(mapStateToProps)(Auth);