import * as React from 'react';
import { DriverProfile } from 'models/driverProfile';
import { CustomCard } from '../styles';
import {
  StatusLabel,
  FieldView,
} from 'components';
import STATUS, { ACCOUNT_STATUS_COLOR } from 'constants/driverProfileStatus';
import DateHelper from 'utils/dateHelper';

interface Props {
  driverProfile: DriverProfile;
}

const DriverInfoCard = ({ driverProfile }: Props): JSX.Element => {
  let accountStatus = driverProfile && driverProfile.driver_verified_at
    ? 'verified' : 'unverified';
  if (driverProfile && driverProfile.deleted_at != null) {
    accountStatus = 'deleted';
  }

  return (
    <CustomCard>
      <FieldView
        fieldName="Driver ID"
      >
        {driverProfile && driverProfile.id.split('-')[0]}
      </FieldView>
      <FieldView
        fieldName="Account Status"
      >
        <StatusLabel
          iconColor={ACCOUNT_STATUS_COLOR[accountStatus]}
        >
          {STATUS[accountStatus]}
        </StatusLabel>
      </FieldView>
      <FieldView
        fieldName="Is QA?"
      >
        {driverProfile && driverProfile.is_qa ? 'Yes' : 'No'}
      </FieldView>
      <FieldView
        fieldName="Last Active"
      >
        {driverProfile && driverProfile.last_active_at && `${DateHelper.formatDateTime(driverProfile.last_active_at)}`}
      </FieldView>
      <FieldView
        fieldName="Joined Since"
      >
        {driverProfile && driverProfile.created_at && `${DateHelper.formatDateTime(driverProfile.created_at)}`}
      </FieldView>
      <FieldView
        fieldName="Last Location (Latitude, Longitude)"
      >
        {driverProfile && driverProfile.latitude ? driverProfile.latitude : '-'} ,
        {driverProfile && driverProfile.longitude ? driverProfile.longitude : '-'}
      </FieldView>
    </CustomCard>
  );
};

export default DriverInfoCard;