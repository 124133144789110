import * as React from 'react';
import {
  PageTitle, SysAdminMainContainer, Breadcrumb, Heading, StyledButton,
  TextInput, CenterModal,
} from 'components';
import { faExclamationCircle, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import VesselSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import VesselClient from 'httpClients/vesselClient';
import { Vessel, UpdateVesselPayload } from 'models/vessel';
import {
  InlineErrorMessage, ErrorIcon, AlertMessage,
  FormContainer, TextInputContainer, Separator, FormInput, CancelButton,
} from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CompanyDetailsProps {
  vessel: Vessel;
}

type Props = RouteComponentProps<{ id: string }> & CompanyDetailsProps;

const getDefaultForm = (vessel: Vessel): UpdateVesselPayload => {
  return {
    name: vessel && vessel.name,
    imo: vessel && vessel.imo,
  };
};

const SysAdminCompanyDetailsEdit = (props: Props): JSX.Element => {
  React.useEffect(() => {
    (async function (): Promise<void> {
      const vesselClient = new VesselClient();
      await vesselClient.getVessel(props.match.params.id);
    })();
  }, []);
  const { vessel } = props;
  const [form, setForm] = React.useState(getDefaultForm(vessel));
  const [error, setError] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    setForm(getDefaultForm(vessel));
  }, [vessel]);

  const handleChange = (fieldName: string, value: string): void => {
    setForm(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
    setError('');
  };

  const save = async (form: UpdateVesselPayload): Promise<void> => {
    setError('');
    try {
      setFetching(true);
      const client = new VesselClient();
      await client.updateVessel(props.match.params.id, form);
      setSuccess(true);
    } catch (e) {
      setError(e);
    }
    setFetching(false);
  };

  const validateInput = (): boolean => {
    if (form.name === '' || form.imo === '' || fetching || (form.imo || '').length !== 7) {
      return false;
    }
    return true;
  };

  return (
    <SysAdminMainContainer selected="vessels">
      <Breadcrumb
        items={[
          { text: 'Vessels', onClick: (): void => { props.history.push('/sys/vessels'); } },
          { text: props.vessel && props.vessel.name },
        ]}
      />
      <Heading>
        <PageTitle>Vessels Info</PageTitle>
      </Heading>
      <VesselSidebarMenu
        selected={`/sys/vessels/${props.match.params.id}/details`}
      >
        <Heading>
          <PageTitle>
            Vessel Details
          </PageTitle>
          {vessel && (
            <>
              <CancelButton
                buttonStyle="encourage"
                buttonType='neutral'
                onClick={(): void => {
                  props.history.push(`/sys/vessels/${props.match.params.id}/details`);
                }}
                icon={<FontAwesomeIcon icon={faTimes} />}
              >
                Cancel
              </CancelButton>
              <StyledButton
                buttonStyle="encourage"
                buttonType="primary"
                disabled={!validateInput()}
                onClick={(): void => { save({ ...form }); }}
                icon={<FontAwesomeIcon icon={faCheck} style={{color: COLOR.white}} />}
              >
                Save
              </StyledButton>
            </>
          )}
        </Heading>
        <Separator />
        {vessel && (
          <FormContainer>
            <TextInputContainer>
              <FormInput>
                <TextInput
                  fieldName="Vessel Name"
                  isRequired
                  name="name"
                  onTextChange={(value: string): void => handleChange('name', value)}
                  type="text"
                  width="auto"
                  value={form && form.name}
                  required
                />
              </FormInput>
              <FormInput>
                <TextInput
                  fieldName="IMO No."
                  isRequired
                  name="imo"
                  onTextChange={(value: string): void => handleChange('imo', value)}
                  type="number"
                  width="auto"
                  value={form && form.imo}
                  required
                />
              </FormInput>
            </TextInputContainer>
            {(error !== '') && (
              <InlineErrorMessage>
                <ErrorIcon icon={faExclamationCircle} />
                {error}
              </InlineErrorMessage>
            )}
            {(form.imo && (form.imo || '').length !== 7) && (
              <InlineErrorMessage>
                <ErrorIcon icon={faExclamationCircle} />
                Enter a 7-digit number.
              </InlineErrorMessage>
            )}
          </FormContainer>
        )}
        {success ? (
          <CenterModal
            rightButtonText="Ok"
            rightButtonOnClick={(): void => { props.history.push(`/sys/vessels/${props.match.params.id}/details`); }}
            title="Vessel details"
          >
            <AlertMessage status="success">Saved</AlertMessage>
          </CenterModal>
        ) : false}
      </VesselSidebarMenu>
    </SysAdminMainContainer>
  );
};

function mapStateToProps(state: RootState): CompanyDetailsProps {
  return {
    vessel: state.vessel.vessel,
  };
}

export default connect(mapStateToProps)(SysAdminCompanyDetailsEdit);