import * as React from 'react';
import styled from 'styled-components';

interface HeadingProps{
  children: React.ReactNode;
}

const Heading = (props: HeadingProps): JSX.Element => {
  return (
    <Container>
      {props.children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
`;

export default Heading;