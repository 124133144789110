import * as React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { StyledButton } from 'components';
import { SavedGeoData } from 'models/geoService';

interface SavedAddressButtonProps {
  savedGeoDatas: SavedGeoData[];
  onChange: (v: SavedGeoData) => void;
  containerStyle?: FlattenSimpleInterpolation;
}

class SavedAddressButton extends React.Component<SavedAddressButtonProps> {

  render(): React.ReactNode {
    const { savedGeoDatas, onChange, containerStyle } = this.props;

    return savedGeoDatas.length > 0 && (
      <SmallButtonContainer containerStyle={containerStyle}>
        {savedGeoDatas.map((item, k) => (
          <SmallButton
            key={k}
            type="button"
            buttonType="neutral"
            buttonStyle="encourage"
            size="xs"
            onClick={(): void => {
              onChange(item);
            }}
          >
            {item.name_address}
          </SmallButton>
        ))}
      </SmallButtonContainer>
    );
  }
}

const SmallButton = styled(StyledButton)`
  margin-right: 0.3rem;
  white-space: nowrap;
`;

const SmallButtonContainer = styled.div<{ containerStyle?: FlattenSimpleInterpolation }>`
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
  display: flex;
  overflow-x: auto;
  margin-top: 0.3rem;
`;

export default SavedAddressButton;