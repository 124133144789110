import * as React from 'react';
import { connect } from 'react-redux';
import TaskClient from 'httpClients/taskClient';
import {
  CenterModal,
  Radio,
  SysAdminMainContainer,
  Breadcrumb,
  DropdownMenu,
  Separator,
  TextInput,
  Typography,
  Collapsible,
} from 'components';
import styled, { css } from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { Task } from 'models/task';
import { RootState } from 'reduxActions/store';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { Organization } from 'models/organization';
import StatusBadge from 'components/statusBadge';

import {
  Prompt,
  ActionButton,
  ActionButtonsContainer,
  DropdownButton,
  DropdownButtonIcon,
  Row,
  Field,
  inlineTextInputStyle,
  AlertMessage,
  Required,
  PickupDeliveryContainer,
  IdAndStatusWrapper,
  VesselWrapper,
  CollapsibleText,
  IsTestLabel,
} from '../../order/styles';
import {
  faThumbsUp,
  faThumbsDown,
  faTimes,
  faTrash,
  faArrowLeft,
  faShare,
  faPen,
  faShip,
} from '@fortawesome/free-solid-svg-icons';
import { VEHICLE_PREFERENCE_DESCRIPTION } from 'constants/vehiclePreference';
import DateHelper from 'utils/dateHelper';

// import MainInfoCard from '../../order/cards/mainInfoCard';
import OrderActivityCard from '../../order/cards/orderActivityCard';
// import TimestampInfoCard from '../../order/cards/timestampInfoCard';
import IdentityInfoCard from '../../order/cards/identityInfoCard';
import AdditionalInfoCard from '../../order/cards/additionalInfoCard';
import BroadcastInfoCard from '../../order/cards/broadcastInfoCard';
import MarineInfoCard from '../../order/cards/marineInfoCard';
import ShareOrderDetailModal from './modal/shareOrderDetailModal';
import STATUS, {
  TASK_STATUS,
  TaskEditableStatuses,
  TaskEditableStatusesMarine,
} from 'constants/taskStatus';
import { STATUS_DESCRIPTION } from 'constants/taskStatus';
import { formatAddress } from 'utils/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Stack,
  ColumnItem,
  CardWrapper,
  IconGrayish,
  Divider,
  SuperScript,
  OrderInfoContainer,
} from '../../../pages/order/styles';
import PickupAndDeliveryDetail from '../../../pages/order/pickupAndDeliveryDetail';
import BROADCAST_PREFERENCES from '../../../constants/broadcastPreference';
import ReasonOfFailure from '../../../pages/order/reasonOfFailure';
import SharePodReceiptModal from './modal/sharePodReceiptModal';
import { getLastUpdatedTask, sendTaskCancelNotification } from 'utils/taskHelper';
import BadgeGroup, { BadgeChip } from 'components/badge/badgeGroup';
import * as moment from 'moment';

const cancelReasons = [
  {
    text: 'Merchant requested to cancel order',
    value: 'Merchant requested to cancel order',
  },
  {
    text: 'Driver requested to cancel order',
    value: 'Driver requested to cancel order',
  },
  {
    text: 'This is a test order',
    value: 'This is a test order',
  },
  {
    text: 'I am bored',
    value: 'I am bored',
  },
  {
    text: 'Others',
    value: 'Others',
    withTextInput: true,
    textInputRequired: true,
  },
];

interface StateProps {
  task: Task;
  organization: Organization;
}

interface LocationState {
  from: string;
}

interface SysAdminTaskDetailState {
  cancelReason: string;
  showCancelModal: boolean;
  showDeleteModal: boolean;
  showConfirmPickupModal: boolean;
  showPickupConfirmedModal: boolean;
  confirmPickupModal: {
    remarks: string;
    releasedFrom: string;
  };
  showUnsuccessfulPickupModal: boolean;
  showPickupUnsuccessfulModal: boolean;
  unsuccessfulPickupModal: {
    reason: string;
  };
  showConfirmDeliveryModal: boolean;
  showDeliveryConfirmedModal: boolean;
  showStartPickupSuccessModal: boolean;
  confirmDeliveryModal: {
    remarks: string;
    releasedTo: string;
    inPerson: string;
  };
  showUnsuccessfulDeliveryModal: boolean;
  showDeliveryUnsuccessfulModal: boolean;
  unsuccessfulDeliveryModal: {
    reason: string;
  };
  showShareOderDetailModal: boolean;
  showPODShareModal: boolean;
  error: string;
  isMobile: boolean;
}

type Props = RouteComponentProps<{ id: string }, object, LocationState> & StateProps;

class SysAdminTaskDetail extends React.Component<Props, SysAdminTaskDetailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cancelReason: cancelReasons[0].value,
      showCancelModal: false,
      showDeleteModal: false,
      showConfirmPickupModal: false,
      showPickupConfirmedModal: false,
      confirmPickupModal: {
        remarks: '',
        releasedFrom: '',
      },
      showUnsuccessfulPickupModal: false,
      showPickupUnsuccessfulModal: false,
      unsuccessfulPickupModal: {
        reason: '',
      },
      showConfirmDeliveryModal: false,
      showDeliveryConfirmedModal: false,
      showStartPickupSuccessModal: false,
      confirmDeliveryModal: {
        remarks: '',
        releasedTo: '',
        inPerson: '',
      },
      showUnsuccessfulDeliveryModal: false,
      showDeliveryUnsuccessfulModal: false,
      unsuccessfulDeliveryModal: {
        reason: '',
      },
      showShareOderDetailModal: false,
      showPODShareModal: false,
      error: '',
      isMobile: false,
    };
  }

  async componentDidMount(): Promise<void> {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    this.setState({ isMobile: mediaQuery.matches });
    mediaQuery.addEventListener('change', this.handleMediaQueryChange);
    this.fetchData();
  }

  componentWillUnmount(): void {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    mediaQuery.removeEventListener('change', this.handleMediaQueryChange);
  }

  handleMediaQueryChange = (mediaQuery: MediaQueryListEvent): void => {
    this.setState({ isMobile: mediaQuery.matches });
  };

  fetchData = async (): Promise<void> => {
    const { id } = this.props.match.params;
    await this.fetchTask(id);
    this.fetchOrganization();
  };

  fetchTask = async (id: string): Promise<void> => {
    const client = new TaskClient();
    await client.sysAdminGetTask(id);
  };

  fetchOrganization = async (): Promise<void> => {
    const { task } = this.props;
    const orgClient = new OrganizationManagementClient();
    await orgClient.sysAdminGetOrganization(task.org_id);
  };

  cancel = async (): Promise<void> => {
    const { id, version_rev: versionRev } = this.props.task;

    const client = new TaskClient();

    await client.sysAdminCancelTask(id, {
      reason: this.state.cancelReason,
      version_rev: versionRev,
    });

    sendTaskCancelNotification(this.props.task);

    this.setState({ showCancelModal: false });
    this.fetchTask(id);
  };

  startPickup = async (): Promise<void> => {
    const { task } = this.props;
    const taskId = task && task.id;

    try {
      const client = new TaskClient();
      await client.sysStartPickup(taskId, {
        version_rev: task.version_rev,
        org_transporter_id: task.org_transporter && task.org_transporter.id,
      });
      this.fetchData();
      this.setState({ showStartPickupSuccessModal: true });
    } catch (e) {
      this.setState({ error: e });
    }
  };

  confirmPickup = async (): Promise<void> => {
    const { task } = this.props;
    const { confirmPickupModal } = this.state;
    const taskId = task && task.id;

    try {
      const client = new TaskClient();
      await client.sysConfirmPickup(taskId, {
        version_rev: task.version_rev,
        org_transporter_id: task.org_transporter && task.org_transporter.id,
        actual_contact_name: confirmPickupModal.releasedFrom,
        remarks: confirmPickupModal.remarks,
      });
      this.fetchData();
      this.setState({ showConfirmPickupModal: false, showPickupConfirmedModal: true });
    } catch (e) {
      this.setState({ error: e });
    }
  };

  unsuccessfulPickup = async (): Promise<void> => {
    const { task } = this.props;
    const { unsuccessfulPickupModal } = this.state;
    const taskId = task && task.id;

    try {
      const client = new TaskClient();
      await client.sysUnsuccessfulPickup(taskId, {
        version_rev: task.version_rev,
        org_transporter_id: task.org_transporter && task.org_transporter.id,
        custom_info: [
          {
            question: 'Reason',
            answer: unsuccessfulPickupModal.reason,
          },
        ],
      });
      this.fetchData();
      this.setState({
        showUnsuccessfulPickupModal: false,
        showPickupUnsuccessfulModal: true,
      });
    } catch (e) {
      this.setState({ error: e });
    }
  };

  confirmDelivery = async (): Promise<void> => {
    const { task } = this.props;
    const { confirmDeliveryModal } = this.state;
    const taskId = task && task.id;

    try {
      const client = new TaskClient();
      await client.sysConfirmDelivery(taskId, {
        version_rev: task.version_rev,
        org_transporter_id: task.org_transporter && task.org_transporter.id,
        actual_contact_name: confirmDeliveryModal.releasedTo,
        remarks: confirmDeliveryModal.remarks,
        custom_info: [
          {
            question: 'In Person?',
            answer: confirmDeliveryModal.inPerson,
          },
        ],
      });
      this.fetchData();
      this.setState({
        showConfirmDeliveryModal: false,
        showDeliveryConfirmedModal: true,
      });
    } catch (e) {
      this.setState({ error: e });
    }
  };

  unsuccessfulDelivery = async (): Promise<void> => {
    const { task } = this.props;
    const { unsuccessfulDeliveryModal } = this.state;
    const taskId = task && task.id;

    try {
      const client = new TaskClient();
      await client.sysUnsuccessfulDelivery(taskId, {
        version_rev: task.version_rev,
        org_transporter_id: task.org_transporter && task.org_transporter.id,
        custom_info: [
          {
            question: 'Reason',
            answer: unsuccessfulDeliveryModal.reason,
          },
        ],
      });
      this.fetchData();
      this.setState({
        showUnsuccessfulDeliveryModal: false,
        showDeliveryUnsuccessfulModal: true,
      });
    } catch (e) {
      this.setState({ error: e });
    }
  };

  onDeleteClick = async (id: string): Promise<void> => {
    const client = new TaskClient();
    await client.deleteTask(id);
    this.setState({ showDeleteModal: false });
    this.props.history.push('/sys/tasks');
  };

  renderCancelModal = (): React.ReactNode => {
    const { cancelReason } = this.state;
    const mandatoryTextInputOption = cancelReasons.find(
      (reason) =>
        reason.withTextInput &&
        reason.textInputRequired &&
        cancelReason.indexOf(`${reason.value} - `) === 0
    );

    return (
      <CenterModal
        leftButtonOnClick={(): void => {
          this.setState({ showCancelModal: false });
        }}
        leftButtonText="Back"
        rightButtonDisabled={
          mandatoryTextInputOption
            ? cancelReason.trim() === `${mandatoryTextInputOption.value} -`
            : cancelReason.trim().length === 0
        }
        rightButtonStyle="discourage"
        rightButtonType="secondary"
        rightButtonOnClick={this.cancel}
        rightButtonText="Yes, cancel"
        title="Cancel Order"
      >
        <Disclaimer>
          (Please ensure that you have evident permission from the order creator).
        </Disclaimer>
        <Prompt>What is the reason for cancellation?</Prompt>
        <div>
          <Radio
            options={cancelReasons}
            value={cancelReason}
            onChange={(value: string): void => {
              this.setState({ cancelReason: value });
            }}
          />
        </div>
      </CenterModal>
    );
  };

  renderDeleteModal(): React.ReactNode {
    return (
      <CenterModal
        leftButtonOnClick={(): void => {
          this.setState({ showDeleteModal: false });
        }}
        leftButtonText="No"
        rightButtonStyle="discourage"
        rightButtonType="secondary"
        rightButtonOnClick={(): Promise<void> => this.onDeleteClick(this.props.task.id)}
        rightButtonText="Yes, delete"
        title="Delete order?"
      >
        <Prompt>This action cannot be undone.</Prompt>
      </CenterModal>
    );
  }

  renderConfirmPickupModal = (): React.ReactNode => {
    return (
      <CenterModal
        leftButtonOnClick={(): void => {
          this.setState({ showConfirmPickupModal: false });
        }}
        leftButtonText="Cancel"
        rightButtonText="Confirm"
        rightButtonStyle="encourage"
        rightButtonDisabled={!this.state.confirmPickupModal.releasedFrom}
        rightButtonOnClick={(): Promise<void> => this.confirmPickup()}
        title="Confirm Pickup"
      >
        <Row>
          <Field>
            <TextInput
              autoComplete="none"
              inputName="remarks"
              containerStyle={inlineTextInputStyle}
              fieldName="Remarks"
              onTextChange={(value: string): void => {
                this.setState((prevState) => ({
                  confirmPickupModal: {
                    ...prevState.confirmPickupModal,
                    remarks: value,
                  },
                }));
              }}
              placeholder="Eg: on behalf receiver"
              width="full"
              value={this.state.confirmPickupModal.remarks}
            />
          </Field>
          <Field>
            <TextInput
              isRequired
              autoComplete="none"
              inputName="released-from-name"
              containerStyle={inlineTextInputStyle}
              fieldName="Released From (Name)"
              onTextChange={(value: string): void => {
                this.setState((prevState) => ({
                  confirmPickupModal: {
                    ...prevState.confirmPickupModal,
                    releasedFrom: value,
                  },
                }));
              }}
              width="full"
              value={this.state.confirmPickupModal.releasedFrom}
            />
          </Field>
        </Row>
      </CenterModal>
    );
  };

  renderPickupConfirmedModal = (): React.ReactNode => {
    return (
      <CenterModal
        rightButtonOnClick={(): void => {
          this.setState({
            showPickupConfirmedModal: false,
          });
        }}
        rightButtonText="OK"
        title="Pickup Confirmed"
      >
        <AlertMessage status="success">Order is picked up!</AlertMessage>
      </CenterModal>
    );
  };

  renderUnsuccessfulPickupModal = (): React.ReactNode => {
    return (
      <CenterModal
        leftButtonOnClick={(): void => {
          this.setState({ showUnsuccessfulPickupModal: false });
        }}
        leftButtonText="Cancel"
        rightButtonText="Update"
        rightButtonStyle="discourage"
        rightButtonDisabled={!this.state.unsuccessfulPickupModal.reason}
        rightButtonOnClick={(): Promise<void> => this.unsuccessfulPickup()}
        title="Pickup Unsuccessful"
      >
        <Row>
          <Field>
            <TextInput
              isRequired
              autoComplete="none"
              inputName="pickup-unsuccessful-reason"
              containerStyle={inlineTextInputStyle}
              fieldName="Reason"
              onTextChange={(value: string): void => {
                this.setState({
                  unsuccessfulPickupModal: {
                    reason: value,
                  },
                });
              }}
              placeholder="Wrong information"
              width="full"
              value={this.state.unsuccessfulPickupModal.reason}
            />
          </Field>
        </Row>
      </CenterModal>
    );
  };

  renderPickupUnsuccessfulModal = (): React.ReactNode => {
    return (
      <CenterModal
        rightButtonOnClick={(): void => {
          this.setState({
            showPickupUnsuccessfulModal: false,
          });
        }}
        rightButtonText="OK"
        title="Updated"
      >
        <AlertMessage status="success">Order unsuccessful pickup!</AlertMessage>
      </CenterModal>
    );
  };

  renderConfirmDeliveryModal(): React.ReactNode {
    const { confirmDeliveryModal } = this.state;
    const notInPersonSelectedButEmpty =
      confirmDeliveryModal.inPerson.replace(/-|\s/g, '') === 'No';
    const canConfirm =
      confirmDeliveryModal.releasedTo &&
      confirmDeliveryModal.inPerson &&
      !notInPersonSelectedButEmpty;
    return (
      <CenterModal
        leftButtonOnClick={(): void => {
          this.setState({ showConfirmDeliveryModal: false });
        }}
        leftButtonText="Cancel"
        rightButtonText="Confirm"
        rightButtonStyle="encourage"
        rightButtonDisabled={!canConfirm}
        rightButtonOnClick={(): Promise<void> => this.confirmDelivery()}
        title="Confirm Delivery"
      >
        <Row>
          <Field>
            <TextInput
              autoComplete="none"
              inputName="remarks"
              containerStyle={inlineTextInputStyle}
              fieldName="Remarks"
              onTextChange={(value: string): void => {
                this.setState((prevState) => ({
                  confirmDeliveryModal: {
                    ...prevState.confirmDeliveryModal,
                    remarks: value,
                  },
                }));
              }}
              placeholder="Eg: on behalf receiver"
              width="full"
              value={this.state.confirmDeliveryModal.remarks}
            />
          </Field>
          <Field>
            <TextInput
              isRequired
              autoComplete="none"
              inputName="released-to-name"
              containerStyle={inlineTextInputStyle}
              fieldName="Released to (Name)"
              onTextChange={(value: string): void => {
                this.setState((prevState) => ({
                  confirmDeliveryModal: {
                    ...prevState.confirmDeliveryModal,
                    releasedTo: value,
                  },
                }));
              }}
              width="full"
              value={this.state.confirmDeliveryModal.releasedTo}
            />
          </Field>
          <Field>
            In Person?<Required>*</Required>
            <Radio
              options={[
                {
                  text: 'Yes',
                  value: 'Yes',
                },
                {
                  text: (
                    <>
                      No
                      {confirmDeliveryModal.inPerson.includes('No') ? (
                        <div>
                          Please state the location<Required>*</Required>
                        </div>
                      ) : (
                        false
                      )}
                    </>
                  ),
                  value: 'No',
                  withTextInput: true,
                  textInputPlaceholder: 'Security',
                  textInputRequired: true,
                },
              ]}
              value={this.state.confirmDeliveryModal.inPerson}
              onChange={(value: string): void => {
                this.setState((prevState) => ({
                  confirmDeliveryModal: {
                    ...prevState.confirmDeliveryModal,
                    inPerson: value,
                  },
                }));
              }}
            />
          </Field>
        </Row>
      </CenterModal>
    );
  }

  renderDeliveryConfirmedModal = (): React.ReactNode => {
    return (
      <CenterModal
        rightButtonOnClick={(): void => {
          this.setState({
            showDeliveryConfirmedModal: false,
          });
        }}
        rightButtonText="OK"
        title="Delivery Confirmed"
      >
        <AlertMessage status="success">Order completed!</AlertMessage>
      </CenterModal>
    );
  };

  renderStartPickupSuccessModal = (): React.ReactNode => {
    return (
      <CenterModal
        rightButtonOnClick={(): void => {
          this.setState({
            showStartPickupSuccessModal: false,
          });
        }}
        rightButtonText="OK"
        title="Status Updated"
      >
        <AlertMessage status="success">
          Status has been successfully changed to pending pickup.
        </AlertMessage>
      </CenterModal>
    );
  };

  renderUnsuccessfulDeliveryModal = (): React.ReactNode => {
    return (
      <CenterModal
        leftButtonOnClick={(): void => {
          this.setState({ showUnsuccessfulDeliveryModal: false });
        }}
        leftButtonText="Cancel"
        rightButtonText="Update"
        rightButtonStyle="discourage"
        rightButtonDisabled={!this.state.unsuccessfulDeliveryModal.reason}
        rightButtonOnClick={(): Promise<void> => this.unsuccessfulDelivery()}
        title="Delivery Unsuccessful"
      >
        <Row>
          <Field>
            <TextInput
              isRequired
              autoComplete="none"
              inputName="delivery-unsuccessful-reason"
              containerStyle={inlineTextInputStyle}
              fieldName="Reason"
              onTextChange={(value: string): void => {
                this.setState({
                  unsuccessfulDeliveryModal: {
                    reason: value,
                  },
                });
              }}
              placeholder="Wrong information"
              width="full"
              value={this.state.unsuccessfulDeliveryModal.reason}
            />
          </Field>
        </Row>
      </CenterModal>
    );
  };

  renderDeliveryUnsuccessfulModal = (): React.ReactNode => {
    return (
      <CenterModal
        rightButtonOnClick={(): void => {
          this.setState({
            showDeliveryUnsuccessfulModal: false,
          });
        }}
        rightButtonText="OK"
        title="Updated"
      >
        <AlertMessage status="success">Order unsuccessful delivery!</AlertMessage>
      </CenterModal>
    );
  };

  renderShareOderDetailModal = (): React.ReactNode => {
    const { task, organization } = this.props;
    let shareContent: React.ReactNode = null;
    let vehicleInfo =
      task &&
      task.vehicle_preference &&
      `1 x ${VEHICLE_PREFERENCE_DESCRIPTION[task.vehicle_preference]}`;
    if (task.vehicle_preference === 'ltl') {
      const cargoLifts = task.cargo_details.reduce((acc, item) => {
        return acc + item.quantity;
      }, 0);
      vehicleInfo = task && task.vehicle_preference && `${cargoLifts} Lifts`;
    }
    const vesselInfo = `Vessel: ${(task && task.to_contact && task.to_contact.vessel_name) || 'TBD'
      }`;
    const pickupInfo = `Pickup: ${task &&
      task.from_time_window &&
      DateHelper.formatSimpleDateTime(task.from_time_window.start_time_utc)
      } (${formatAddress(task.from_address)})`;
    const deliveryInfo = `Delivery: ${task &&
      task.to_time_window &&
      DateHelper.formatSimpleDateTime(task.to_time_window.start_time_utc)
      } (${formatAddress(task.to_address)})`;
    const lighterCompanyInfo = `Lighter Company: ${(task && task.to_contact && task && task.to_contact.lighter_company_name) || 'TBD'
      }`;
    const lighterBoatInfo = `Lighter Boat: ${(task && task.to_contact && task && task.to_contact.lighter_boat_name) || 'TBD'
      }`;
    const shareUrl = `${window.location.origin}/${task.tracking_id}`;
    const driverDetails = `Driver Details: ${(task &&
        task.org_transporter &&
        task.org_transporter.org_driver &&
        task.org_transporter.org_driver.name) ||
      'TBD'
      }${(task &&
        task.org_transporter &&
        task.org_transporter.org_driver &&
        task.org_transporter.org_driver.phone_number &&
        ', ' + task.org_transporter.org_driver.phone_number) ||
      ''
      }`;
    const taskStatus = `Status: ${STATUS_DESCRIPTION[task.current_status]}`;

    if (
      task.current_status === TASK_STATUS.PENDING_BROADCAST ||
      task.current_status === TASK_STATUS.BROADCASTING
    ) {
      shareContent = (
        <>
          Hi, 1 new order from {organization && organization.business_name}
          <br />
          <br />
          {vehicleInfo}
          <br />
          <br />
          {vesselInfo}
          <br />
          {pickupInfo}
          <br />
          {deliveryInfo}
          <br />
          {lighterCompanyInfo}
          <br />
          {lighterBoatInfo}
          <br />
          <br />
          <a href={shareUrl}>{shareUrl}</a>
          <br />
          <br />
          Able to support?
        </>
      );
    } else if (
      task.current_status === TASK_STATUS.PENDING_PICKUP ||
      task.current_status === TASK_STATUS.ACCEPTED
    ) {
      shareContent = (
        <>
          Order Update — {STATUS[task.current_status]}
          <br />
          <br />
          {vehicleInfo}
          <br />
          <br />
          {vesselInfo}
          <br />
          {pickupInfo}
          <br />
          {deliveryInfo}
          <br />
          {driverDetails}
          <br />
          <br />
          <a href={shareUrl}>{shareUrl}</a>
        </>
      );
    } else if (task.current_status === TASK_STATUS.PICKUP_ARRIVING) {
      shareContent = (
        <>
          Order Update — Driver has arrived at pickup
          <br />
          <br />
          {vehicleInfo}
          <br />
          <br />
          {vesselInfo}
          <br />
          {pickupInfo}
          <br />
          {deliveryInfo}
          <br />
          {driverDetails}
          <br />
          <br />
          <a href={shareUrl}>{shareUrl}</a>
        </>
      );
    } else if (task.current_status === TASK_STATUS.UNSUCCESSFUL_PICKUP) {
      shareContent = (
        <>
          Order Update — Unsuccessful Pickup
          <br />
          <br />
          {vehicleInfo}
          <br />
          <br />
          {vesselInfo}
          <br />
          {pickupInfo}
          <br />
          {deliveryInfo}
          <br />
          {driverDetails}
          <br />
          <br />
          <a href={shareUrl}>{shareUrl}</a>
          <br />
          <br />
        </>
      );
    } else if (task.current_status === 'pending_delivery') {
      shareContent = (
        <>
          Order Update — Pending Delivery
          <br />
          <br />
          {vehicleInfo}
          <br />
          <br />
          {vesselInfo}
          <br />
          {pickupInfo}
          <br />
          {deliveryInfo}
          <br />
          {driverDetails}
          <br />
          <br />
          <a href={shareUrl}>{shareUrl}</a>
        </>
      );
    } else if (task.current_status === 'delivery_arriving') {
      shareContent = (
        <>
          Order Update — Driver has arrived at delivery point
          <br />
          <br />
          {vehicleInfo}
          <br />
          <br />
          {vesselInfo}
          <br />
          {taskStatus}
          <br />
          {pickupInfo}
          <br />
          {deliveryInfo}
          <br />
          {driverDetails}
          <br />
          <br />
          <a href={shareUrl}>{shareUrl}</a>
        </>
      );
    } else if (task.current_status === 'unsuccessful_delivery') {
      shareContent = (
        <>
          Order Update — Unsuccessful Delivery
          <br />
          <br />
          {vehicleInfo}
          <br />
          <br />
          {vesselInfo}
          <br />
          {pickupInfo}
          <br />
          {deliveryInfo}
          <br />
          {driverDetails}
          <br />
          <br />
          <a href={shareUrl}>{shareUrl}</a>
        </>
      );
    } else if (task.current_status === 'complete') {
      shareContent = (
        <>
          Order Update — Complete
          <br />
          <br />
          {vehicleInfo}
          <br />
          <br />
          {vesselInfo}
          <br />
          {pickupInfo}
          <br />
          {deliveryInfo}
          <br />
          {driverDetails}
          <br />
          <br />
          <a href={shareUrl}>{shareUrl}</a>
        </>
      );
    } else if (task.current_status === 'cancelled') {
      shareContent = (
        <>
          Order Update — Cancelled
          <br />
          <br />
          {vehicleInfo}
          <br />
          <br />
          {vesselInfo}
          <br />
          {pickupInfo}
          <br />
          {deliveryInfo}
          <br />
          {driverDetails}
          <br />
          <br />
          <a href={shareUrl}>{shareUrl}</a>
        </>
      );
    }
    return (
      <>
        {this.state.showShareOderDetailModal && (
          <ShareOrderDetailModal
            title="Share Order Details"
            shareUrl={shareUrl}
            header={
              <>
                Share the order details using the provided template
                {task.current_status === 'pending_broadcast' ||
                  task.current_status === 'broadcasting'
                  ? ' to transporters'
                  : ' with the merchant'}
                .
              </>
            }
            closeModal={(): void => {
              this.setState({
                showShareOderDetailModal: false,
              });
            }}
            showSharePOD={task.current_status === 'complete'}
            handlePODClick={(): void => {
              this.setState({
                showShareOderDetailModal: false,
                showPODShareModal: true,
              });
            }}
          >
            {shareContent}
          </ShareOrderDetailModal>
        )}
      </>
    );
  };

  renderPODModal = (isShowBackButton: boolean): React.ReactNode => {
    const taskId = this.props.task.id;
    return (
      <SharePodReceiptModal
        taskId={taskId}
        isShowBackButton={isShowBackButton}
        handleCloseModal={() => {
          this.setState({
            showPODShareModal: false,
            showShareOderDetailModal: false,
          });
        }}
        handleBackButtonModal={() => {
          this.setState({
            showPODShareModal: false,
            showShareOderDetailModal: true,
          });
        }}
      />
    );
  };

  renderError = (): React.ReactNode => {
    return (
      <CenterModal
        rightButtonText="Refresh"
        rightButtonOnClick={(): void => {
          window.location.reload();
        }}
      >
        <AlertMessage status="error">{this.state.error}</AlertMessage>
      </CenterModal>
    );
  };

  renderHeading = (): React.ReactNode => {
    const { task } = this.props;
    const taskEditableStatuses =
      task && task.broadcast_preference === BROADCAST_PREFERENCES.marine
        ? TaskEditableStatusesMarine
        : TaskEditableStatuses;
    const orderListQueryParams =
      (this.props.location.state && this.props.location.state.from) || '';
    const cannotCancel = this.props.task
      ? this.props.task.current_status !== 'pending_broadcast' &&
      this.props.task.current_status !== 'broadcasting' &&
      this.props.task.current_status !== 'accepted'
      : true;
    let cannotEdit = this.props.task
      ? this.props.task.current_status !== 'pending_broadcast' ||
      this.props.task.edit_locked
      : true;
    if (task && taskEditableStatuses.includes(task.current_status)) {
      cannotEdit = false;
    }
    const cannotDelete = task && task.runsheet !== null;
    const canStartPickup = task && task.current_status === 'accepted';
    const canPickup =
      task &&
      task.broadcast_preference === BROADCAST_PREFERENCES.marine &&
      task.current_status === 'pending_pickup';
    const canDelivery =
      task &&
      task.broadcast_preference === BROADCAST_PREFERENCES.marine &&
      (task.current_status === 'pending_delivery' ||
        task.current_status === 'unsuccessful_delivery');

    return (
      <Header>
        <Typography as="h1" size="display_sm" color="gray_900" weight="semibold">
          Order Info
        </Typography>
        <ActionButtonsContainer>
          <ActionButton
            onClick={(): void => {
              this.props.history.push(`/sys/tasks?${orderListQueryParams}`);
            }}
            buttonStyle="encourage"
            buttonType="neutral"
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
          >
            Back
          </ActionButton>
          {task && task.broadcast_preference === BROADCAST_PREFERENCES.marine ? (
            <ActionButton
              onClick={(): void => {
                if (task.broadcast_preference === BROADCAST_PREFERENCES.marine) {
                  this.setState({
                    showShareOderDetailModal: true,
                  });
                }
              }}
              buttonStyle="encourage"
              buttonType="neutral"
              icon={<FontAwesomeIcon icon={faShare} />}
            >
              Share
            </ActionButton>
          ) : (
            false
          )}
          {task &&
            task.broadcast_preference !== BROADCAST_PREFERENCES.marine &&
            task.current_status === 'complete' ? (
            <ActionButton
              onClick={(): void => {
                this.setState({
                  showPODShareModal: true,
                });
              }}
              buttonStyle="encourage"
              buttonType="neutral"
              icon={<FontAwesomeIcon icon={faShare} />}
            >
              Share
            </ActionButton>
          ) : (
            false
          )}
          <DropdownMenu disabled={cannotEdit}>
            <DropdownButton
              disabled={cannotEdit}
              onClick={(): void => {
                this.props.history.push(`/sys/tasks/${task && task.id}/edit`);
              }}
              buttonStyle="encourage"
              buttonType="neutral"
            >
              <DropdownButtonIcon icon={faPen} fixedWidth />
              Edit Order
            </DropdownButton>
            <Separator />
            <DropdownButton
              disabled={cannotCancel}
              onClick={(): void => {
                this.setState({ showCancelModal: true });
              }}
              buttonStyle="encourage"
              buttonType="neutral"
            >
              <DropdownButtonIcon icon={faTimes} fixedWidth />
              Cancel Order
            </DropdownButton>
            <Separator />
            <DropdownButton
              disabled={cannotDelete}
              onClick={(): void => {
                this.setState({ showDeleteModal: true });
              }}
              buttonStyle="encourage"
              buttonType="neutral"
            >
              <DropdownButtonIcon icon={faTrash} fixedWidth />
              Delete Order
            </DropdownButton>
            {canStartPickup && (
              <>
                <Separator />
                <DropdownButton
                  onClick={(): void => {
                    this.startPickup();
                  }}
                  buttonType="neutral"
                  buttonStyle="encourage"
                >
                  <DropdownButtonIcon icon={faThumbsUp} fixedWidth />
                  Start Pickup
                </DropdownButton>
                <Separator />
              </>
            )}
            {canPickup && (
              <>
                <Separator />
                <DropdownButton
                  onClick={(): void => {
                    this.setState((prevState) => ({
                      showConfirmPickupModal: true,
                      confirmPickupModal: {
                        ...prevState.confirmPickupModal,
                        remarks: '',
                        releasedFrom: task.from_contact && task.from_contact.name,
                      },
                    }));
                  }}
                  buttonType="neutral"
                  buttonStyle="encourage"
                >
                  <DropdownButtonIcon icon={faThumbsUp} fixedWidth />
                  Confirm Pickup
                </DropdownButton>
                <Separator />
                <DropdownButton
                  onClick={(): void => {
                    this.setState({
                      showUnsuccessfulPickupModal: true,
                      unsuccessfulPickupModal: {
                        reason: '',
                      },
                    });
                  }}
                  buttonType="neutral"
                  buttonStyle="encourage"
                >
                  <DropdownButtonIcon icon={faThumbsDown} fixedWidth />
                  Pickup Unsuccessful
                </DropdownButton>
              </>
            )}
            {canDelivery && (
              <>
                <DropdownButton
                  disabled={task.current_status === 'unsuccessful_delivery'}
                  onClick={(): void => {
                    this.setState((prevState) => ({
                      showConfirmDeliveryModal: true,
                      confirmDeliveryModal: {
                        ...prevState.confirmDeliveryModal,
                        remarks: '',
                        releasedTo: task.to_contact && task.to_contact.name,
                      },
                    }));
                  }}
                  buttonType="neutral"
                  buttonStyle="encourage"
                >
                  <DropdownButtonIcon icon={faThumbsUp} fixedWidth />
                  Confirm Delivery
                </DropdownButton>
                <Separator />
                <DropdownButton
                  disabled={task.current_status === 'unsuccessful_delivery'}
                  onClick={(): void => {
                    this.setState({
                      showUnsuccessfulDeliveryModal: true,
                      unsuccessfulDeliveryModal: {
                        reason: '',
                      },
                    });
                  }}
                  buttonType="neutral"
                  buttonStyle="encourage"
                >
                  <DropdownButtonIcon icon={faThumbsDown} fixedWidth />
                  Delivery Unsuccessful
                </DropdownButton>
              </>
            )}
          </DropdownMenu>
        </ActionButtonsContainer>
      </Header>
    );
  };

  endsWithNumber = (str: string): boolean => {
    return !isNaN(parseInt(str.charAt(str.length - 1)));
  };

  renderSuperscriptText = (
    volume: number,
    volumeUnit: string,
    lineHeight?: string
  ): React.ReactNode => {
    if (!volume) {
      return '-';
    }

    let volumeNumber = volumeUnit;
    let superScript = '';
    if (this.endsWithNumber(volumeUnit)) {
      volumeNumber = volumeUnit.substring(0, volumeUnit.length - 1);
      superScript = volumeUnit.charAt(volumeUnit.length - 1);
    }

    return (
      <>
        {superScript ? (
          <SuperScript lineHeight={lineHeight}>
            {volume} {volumeNumber}
            {superScript ? <sup>{superScript}</sup> : ''}
          </SuperScript>
        ) : (
          <>
            {volume} {volumeUnit}
          </>
        )}
      </>
    );
  };

  render(): React.ReactNode {
    const { task, organization } = this.props;
    const orderListQueryParams =
      (this.props.location.state && this.props.location.state.from) || '';
    const orderId = (task && task.id) || '';
    const isMarine = task && task.broadcast_preference === BROADCAST_PREFERENCES.marine;
    const lastUpdated = getLastUpdatedTask(task);
    // ETA and ETD (formerly ETB and ETU)
    const etaTime = task && task.to_contact
      && task.to_contact.etb_time_window && task.to_contact.etb_time_window.start_time_utc;
    const etdTime = task && task.to_contact
      && task.to_contact.etu_time_window && task.to_contact.etu_time_window.start_time_utc;

    return (
      <SysAdminMainContainer selected="tasks">
        <Breadcrumb
          customStyle={{
            marginBottom: 0,
          }}
          items={[
            {
              text: 'Orders',
              onClick: (): void => {
                this.props.history.push(`/sys/tasks?${orderListQueryParams}`);
              },
            },
            { text: orderId.split('-')[0] },
          ]}
        />
        {this.renderHeading()}

        <Divider marginBottom="1rem" />

        {task !== null ? (
          <>
            <OrderInfoContainer direction="column" gap="1.5rem">
              {!this.state.isMobile && (
                <ColumnItem order={1}>
                  <Stack direction="column" gap="1rem">
                    {/* Identity Info Card */}
                    <IdentityInfoCard task={task} organization={organization} ops />
                    {/* Broadcast Info */}
                    {!isMarine && <BroadcastInfoCard task={task} ops />}
                  </Stack>
                </ColumnItem>
              )}

              <PickupDeliveryContainer
                direction="column"
                gap="0.35rem"
                mdScreenStyle={css`
                  padding: 1rem;
                `}
              >
                {lastUpdated ? (
                  <BadgeGroup
                    type="grey"
                    customStyle={css`
                      margin-bottom: 0.5rem;
                    `}
                  >
                    <BadgeChip>Edited</BadgeChip>
                    View Changes {DateHelper.formatSimpleDateTime(lastUpdated.set_at_utc)}
                  </BadgeGroup>
                ) : (
                  false
                )}
                <Stack direction="column" gap="0.35rem">
                  <IdAndStatusWrapper gap="0.5rem">
                    <Typography as="span" size="sm" color="gray_400">
                      {task.tracking_id || task.id.split('-')[0]}
                    </Typography>
                    {task.is_qa ? <IsTestLabel>Test</IsTestLabel> : false}
                    <StatusBadge status={task.current_status} />
                  </IdAndStatusWrapper>
                  <ReasonOfFailure
                    taskStatuses={task.statuses}
                    currentStatus={task.current_status}
                    cancelType="cancelled"
                  />
                </Stack>

                {task.to_contact.vessel_name && (
                  <>
                    <VesselWrapper gap="0.5rem">
                      <IconGrayish icon={faShip} fontSize="19px" />
                      <Typography as="h4" color="gray_700" weight="semibold">
                        {task.to_contact.vessel_name}
                      </Typography>
                    </VesselWrapper>
                    {task.to_contact.vessel_imo || etaTime || etdTime ? (
                      <Typography as="p" color="gray_400">
                        IMO{task.to_contact.vessel_imo}
                        {(task.to_contact.vessel_imo && etaTime || etdTime) && ' | '}
                        {etaTime && `ETA, ${moment(etaTime).format(
                          'DD MMM, HH:mm'
                        )}`}
                        {(etdTime && task.to_contact.vessel_imo || etaTime) && ' | '}
                        {etdTime && `ETD, ${moment(etdTime).format(
                          'DD MMM, HH:mm'
                        )}`}
                      </Typography>
                    ) : null}
                    {/*
                        TODO..
                        <Typography as="p" size="xs" color="primary_600">
                          <Link to={'/'}>See Vessel Arrival</Link>
                        </Typography>
                      */}
                  </>
                )}

                {this.state.isMobile && (
                  <Collapsible
                    title={({ open }): JSX.Element => (
                      <div>
                        <CollapsibleText>{open ? 'Hide' : 'See More'}</CollapsibleText>
                        {open ? null : (
                          <Divider
                            style={{
                              marginTop: '16px',
                              marginBottom: '16px',
                            }}
                          />
                        )}
                      </div>
                    )}
                  >
                    <Stack
                      direction="column"
                      gap="1rem"
                      style={{
                        marginBottom: '1rem',
                      }}
                    >
                      <IdentityInfoCard task={task} organization={organization} ops />
                      {!isMarine && <BroadcastInfoCard task={task} ops />}
                    </Stack>
                  </Collapsible>
                )}

                {!this.state.isMobile && (
                  <Divider
                    style={{
                      marginTop: '16px',
                      marginBottom: '16px',
                    }}
                  />
                )}

                <PickupAndDeliveryDetail task={task} ops />

                {!this.state.isMobile && <Divider marginTopBottom="1.25rem" />}

                {/*Order Activity */}
                <Stack direction="column">
                  {task && <OrderActivityCard task={task} />}
                </Stack>
              </PickupDeliveryContainer>

              <ColumnItem order={2}>
                <CardWrapper>
                  <Stack direction="column" gap="1rem">
                    {task && <AdditionalInfoCard task={task} ops />}
                    {task &&
                      task.broadcast_preference === BROADCAST_PREFERENCES.marine && (
                        <MarineInfoCard task={task} />
                      )}
                  </Stack>
                </CardWrapper>
              </ColumnItem>
            </OrderInfoContainer>
          </>
        ) : (
          false
        )}

        {this.state.showCancelModal && this.renderCancelModal()}
        {this.state.showDeleteModal && this.renderDeleteModal()}
        {/* success start to pickup */}
        {this.state.showStartPickupSuccessModal && this.renderStartPickupSuccessModal()}
        {/* confirm pickup */}
        {this.state.showConfirmPickupModal && this.renderConfirmPickupModal()}
        {this.state.showPickupConfirmedModal && this.renderPickupConfirmedModal()}
        {/* unsuccessful pickup */}
        {this.state.showUnsuccessfulPickupModal && this.renderUnsuccessfulPickupModal()}
        {this.state.showPickupUnsuccessfulModal && this.renderPickupUnsuccessfulModal()}
        {/* confirm delivery */}
        {this.state.showConfirmDeliveryModal && this.renderConfirmDeliveryModal()}
        {this.state.showDeliveryConfirmedModal && this.renderDeliveryConfirmedModal()}
        {/* unsuccessful delivery */}
        {this.state.showUnsuccessfulDeliveryModal &&
          this.renderUnsuccessfulDeliveryModal()}
        {this.state.showDeliveryUnsuccessfulModal &&
          this.renderDeliveryUnsuccessfulModal()}
        {/* render share order detail modal */}
        {this.state.showShareOderDetailModal && this.renderShareOderDetailModal()}
        {this.state.showPODShareModal && this.renderPODModal(isMarine)}
        {this.state.error && this.renderError()}
      </SysAdminMainContainer>
    );
  }
}

const Disclaimer = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.25rem 0 1.25rem 0;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  task: state.task.task,
  organization: state.organization.organization,
});

export default connect(mapStateToProps)(SysAdminTaskDetail);
