import * as React from 'react';
import styled from 'styled-components';
import COLOR from 'constants/color';

interface FieldViewProps {
  fieldName: string;
  children?: React.ReactNode;
}

const FieldView = (props: FieldViewProps): JSX.Element => {
  return (
    <FieldViewContainer>
      <Heading>{props.fieldName}</Heading>
      <Content>{props.children || '-'}</Content>
    </FieldViewContainer>
  );
};

const Heading = styled.div`
  font-size: 0.875rem;
`;

const FieldViewContainer = styled.div`
  margin-bottom: 1rem;
`;

const Content = styled.div`
  color: ${COLOR.darkGray};
`;

export default FieldView;
