import * as React from 'react';
import {
  Card,
  Content,
  Title,
  InfoTable,
  InfoTableRow,
  InfoTableEntry,
  Description,
  LogInfoBox,
  DetailLog,
} from '../styles';
import { RunsheetReadyToTransferLog } from 'models/runsheetReadyToTransfer';
import { StatusLabel, Separator } from 'components';
import DateHelper from 'utils/dateHelper';
import COLOR from 'constants/color';
import { AdjustmentTaskLog } from 'models/runsheetReadyToTransfer/runsheetReadyToTransferLog';

interface Props {
  runsheetReadyToTransferLogs: RunsheetReadyToTransferLog[];
}
interface LogStatusColor {
  [key: string]: string;
}

interface LogStatus {
  [key: string]: string;
}

const LOG_STATUS: LogStatus = {
  'warn': 'Warn',
  'error': 'Error',
  'info': 'Info',
};

export const LOG_STATUS_COLOR: LogStatusColor = {
  'warn': COLOR.orange,
  'error': COLOR.red,
  'info': COLOR.lime,
};

function renderDetailLog(details: object | []): React.ReactNode {
  return (
    <LogInfoBox>
      <pre><DetailLog>{JSON.stringify(details, null, 1)}</DetailLog></pre>
    </LogInfoBox>
  );
}

function renderLogActivityEntry(history: {
  created_at?: Date;
  details: {
    tasks?: AdjustmentTaskLog[];
    message?: string;
    request?: string[];
  };
  message?: string;
  level: string;
  runsheet_id: string;
}): React.ReactNode {
  return (
    <InfoTableRow>
    <InfoTableEntry
      size="big"
    >
      {history.created_at && DateHelper.formatDateTime(history.created_at)}
    </InfoTableEntry>
    <InfoTableEntry
      size="big"
    >
      <StatusLabel
        iconColor={LOG_STATUS_COLOR[history.level]}
        noBackground
      >
        {LOG_STATUS[history.level]}
      </StatusLabel>
      <Description>
        {history.message}
        {history.details && (renderDetailLog(history.details))}
      </Description>
    </InfoTableEntry>
  </InfoTableRow>
  );
}

const TransferLogCard = ({ runsheetReadyToTransferLogs }: Props): JSX.Element => {
  return (
    <Card>
      <Content>
        <Title>Transfer Log</Title>
        <Separator />
        <InfoTable>
          <tbody>
            {runsheetReadyToTransferLogs && runsheetReadyToTransferLogs.map(history => renderLogActivityEntry(history))}
          </tbody>
        </InfoTable>
      </Content>
    </Card>
  );
};

export default TransferLogCard;