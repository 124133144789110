import * as React from 'react';

import {
  PageTitle,
  SysAdminMainContainer,
  Breadcrumb,
  Heading,
  StyledButton,
  Separator,
  Typography,
  TextInput,
  LocationDetailModal,
  CenterModal,
  Alert,
} from 'components';
import { InlineErrorMessage, ErrorIcon } from './styles';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import { RouteComponentProps } from 'react-router-dom';
import GeoServiceClient from 'httpClients/geoServiceClient';
import { MarineLocation } from 'models/marineLocation';
import styled, { css } from 'styled-components';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Position } from 'models/geoService/geoData';

interface MarineLocationPageProps {
  marineLocation: MarineLocation;
}

type Props = RouteComponentProps<{ id: string }> & MarineLocationPageProps;

const SysMarineLocationEdit = (props: Props): React.ReactNode => {
  const { id } = props.match.params;
  const { marineLocation } = props;
  const [showLocateOnMap, setShowLocateOnMap] = React.useState(false);
  const [locationName, setLocationName] = React.useState('');
  const [position, setPosition] = React.useState<Position>({
    lat: 0,
    lng: 0,
  });
  const [isUpdateSuccessful, setIsUpdateSuccessful] = React.useState(false);
  const [fieldsError, setFieldsError] = React.useState({
    name_address: '',
  });

  const handleSubmitLocationUpdate = async (): Promise<void> => {
    const geoServiceClient = new GeoServiceClient();
    try {
      let latitude = position.lat;
      let longitude = position.lng;
      if (typeof latitude === 'string') {
        latitude = parseFloat(latitude);
      }
      if (typeof longitude === 'string') {
        longitude = parseFloat(longitude);
      }
      await geoServiceClient.updateSysMarineLocation({
        id,
        name_address: locationName.trim(),
        lat: latitude,
        lng: longitude,
        country: 'SINGAPORE',
        city: 'SINGAPORE',
        state: 'SINGAPORE',
      });

      setIsUpdateSuccessful(true);
    } catch (error) {
      if (error.message === 'name address already exists') {
        setFieldsError({ name_address: 'Location name already exists' });
      }
    }
  };

  const handleNumericInput = (event: React.FormEvent<HTMLInputElement>): void => {
    const input = event.currentTarget.value;
    // check value contains string ', '
    if (input.includes(', ')) {
      return;
    }
    const numericInput = input.replace(/[^0-9.]/g, ''); // Allow numbers, periods, and hyphens, disallow commas
    event.currentTarget.value = numericInput;
  };

  const handleLatLngChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;

    const parts = input.split(', ').map((str) => str.trim());
    if (parts.length === 2) {
      const [latStr, lngStr] = parts;
      const lat = parseFloat(latStr);
      const lng = parseFloat(lngStr);

      setPosition({
        lat,
        lng,
      });
    } else {
      setPosition({
        ...position,
        [event.target.name]: event.target.value,
      });
    }
  };

  React.useEffect(() => {
    if (marineLocation) {
      setLocationName(marineLocation.name_address);
      setPosition({
        lat: marineLocation.lat,
        lng: marineLocation.lng,
      });
    }
  }, [marineLocation]);

  React.useEffect(() => {
    (async function (): Promise<void> {
      const geoServiceClient = new GeoServiceClient();
      await geoServiceClient.getSysMarineLocationDetail(id);
    })();
  }, []);

  const renderUpdateSuccessModal = (): React.ReactNode => {
    return (
      <>
        {isUpdateSuccessful && (
          <CenterModal
            title="Marine Location Details"
            rightButtonText="OK"
            rightButtonOnClick={(): void => {
              props.history.push(
                '/sys/marine-locations/' + props.match.params.id + '/details'
              );
            }}
          >
            <Alert status="success">Saved</Alert>
          </CenterModal>
        )}
      </>
    );
  };

  if (!marineLocation) {
    return null;
  }

  return (
    <>
      <SysAdminMainContainer selected="marine-locations">
        <Breadcrumb
          items={[
            {
              text: 'Marine Locations',
              onClick: () => {
                props.history.push('/sys/marine-locations');
              },
            },
            { text: marineLocation && marineLocation.name_address },
          ]}
        />

        <Heading>
          <PageTitle>Marine Location Detail</PageTitle>
        </Heading>

        <SeparatorWrapper>
          <Separator />
        </SeparatorWrapper>

        <CardMarineLocation>
          <FlexBetween>
            <Typography as="h4" color="gray_700">
              {marineLocation.name_address}
            </Typography>
            <FlexColRow gap="1rem">
              <StyledButton
                buttonStyle="encourage"
                buttonType="neutral"
                onClick={(): void => {
                  props.history.push(
                    `/sys/marine-locations/${props.match.params.id}/details`
                  );
                }}
                icon={
                  <FontAwesomeIcon icon={faTimes} style={{ color: COLOR.darkGray }} />
                }
              >
                Cancel
              </StyledButton>
              <StyledButton
                buttonStyle="encourage"
                buttonType="primary"
                disabled={!locationName || !position.lat || !position.lng}
                onClick={handleSubmitLocationUpdate}
                icon={<FontAwesomeIcon icon={faCheck} style={{ color: COLOR.white }} />}
              >
                Save
              </StyledButton>
            </FlexColRow>
          </FlexBetween>
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          <FlexCol gap="1.5rem">
            <Typography as="h5" color="gray_700">
              Location Details
            </Typography>
            <FlexCol gap="0.2rem">
              <Typography as="p" size="sm" color="gray_700" weight="semibold">
                Location Name <RedText>*</RedText>
              </Typography>
              <TextInput
                containerStyle={InputStyle}
                width="half"
                height="large"
                autoComplete="none"
                onTextChange={(value) => {
                  setLocationName(value.toUpperCase());
                }}
                type="text"
                placeholder="Enter location name"
                value={locationName}
              />
              {fieldsError.name_address && (
                <InlineErrorMessage>
                  <ErrorIcon icon={faExclamationCircle} />
                  {fieldsError.name_address}
                </InlineErrorMessage>
              )}
            </FlexCol>
            <FlexColRow gap="0.75rem">
              <div>
                <Typography as="p" size="sm" color="gray_700" weight="semibold">
                  Latitude <RedText>*</RedText>
                </Typography>
                <TextInput
                  name="lat"
                  containerStyle={InputStyle}
                  width="full"
                  height="large"
                  autoComplete="none"
                  onTextChange={(value, e) => {
                    handleLatLngChange(e);
                  }}
                  type="text"
                  inputMode="decimal"
                  placeholder="00.000000"
                  value={position.lat || ''}
                  onInput={handleNumericInput}
                />
              </div>
              <Comma>,</Comma>
              <div>
                <Typography as="p" size="sm" color="gray_700" weight="semibold">
                  Longitude <RedText>*</RedText>
                </Typography>
                <TextInput
                  name="lng"
                  containerStyle={InputStyle}
                  width="full"
                  height="large"
                  autoComplete="none"
                  onTextChange={(value, e) => {
                    handleLatLngChange(e);
                  }}
                  type="text"
                  inputMode="decimal"
                  placeholder="00.000000"
                  value={position.lng || ''}
                  onInput={handleNumericInput}
                />
              </div>
            </FlexColRow>
            <Typography
              as="p"
              size="sm"
              color="primary_800"
              customStyle={{
                textDecoration: 'underline',
                cursor: 'pointer',
                marginTop: '-18px',
              }}
            >
              <span
                onClick={() => {
                  setShowLocateOnMap(true);
                }}
              >
                Locate on a map
              </span>
            </Typography>
          </FlexCol>
        </CardMarineLocation>
      </SysAdminMainContainer>
      {showLocateOnMap && (
        <LocationDetailModal
          initialLocationName={locationName}
          initialPosition={position}
          onClose={(): void => {
            setShowLocateOnMap(false);
          }}
          onSubmit={(locationName, { lat, lng }): void => {
            setLocationName(locationName);
            setPosition({
              lat,
              lng,
            });
            setShowLocateOnMap(false);
          }}
        />
      )}

      {renderUpdateSuccessModal()}
    </>
  );
};

const SeparatorWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

const CardMarineLocation = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${COLOR.neutral};
  padding: 1rem;
  background-color: ${COLOR.white};
  margin-bottom: 1.5rem;
  width: -webkit-fill-available;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexCol = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(props): string => props.gap || '1rem'};
`;

const FlexColRow = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: ${(props): string => props.gap || '1rem'};
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Comma = styled.div`
  margin-left: -7px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const InputStyle = css`
  margin-top: 0.25rem;
`;

const RedText = styled.span`
  color: ${COLOR.red};
`;

function mapStateToProps(state: RootState): MarineLocationPageProps {
  return {
    marineLocation: state.geoService.marineLocationDetail,
  };
}

export default connect(mapStateToProps)(SysMarineLocationEdit);
