import * as React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';
import { Contact } from 'models/task';

interface CareOfProps {
  toContact: Contact;
}
const CareOf = ({ toContact }: CareOfProps) => {
  const isAllValueEmpty =
    !toContact.name &&
    !toContact.phone &&
    !toContact.email &&
    !toContact.company_name &&
    !toContact.vehicle_name;

  const companyAndVehicleComplete =
    toContact?.company_name && toContact?.vehicle_name ? ', ' : '';
  return (
    <View
      style={{
        flexDirection: 'column',
        marginBottom: 16,
      }}
    >
      <Text style={styles.titleSmall}>Care-of</Text>
      {isAllValueEmpty ? (
        <Text style={[styles.textMedium, styles.spaceSmallBottom]}>-</Text>
      ) : (
        <>
          <Text style={[styles.textMedium, styles.spaceSmallBottom]}>
            {toContact?.company_name || toContact?.vehicle_name ? (
              <>
                {`${toContact?.company_name ?? ''}${companyAndVehicleComplete}${
                  toContact?.vehicle_name ?? ''
                }`}
              </>
            ) : null}
          </Text>
          {toContact.name && (
            <Text style={[styles.textMedium, styles.spaceSmallBottom]}>
              {toContact.name}
            </Text>
          )}
          {toContact.phone && (
            <Text style={[styles.textMedium, styles.spaceSmallBottom]}>
              {toContact.phone}
            </Text>
          )}
          {toContact.email && (
            <Text style={[styles.textMedium, styles.spaceSmallBottom]}>
              {toContact.email}
            </Text>
          )}
        </>
      )}
    </View>
  );
};

export default CareOf;
