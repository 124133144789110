import * as React from 'react';
import { Breadcrumb, Table, SysAdminMainContainer, StyledButton, CenterModal, Alert } from 'components';
import { RouteComponentProps } from 'react-router-dom';
import { SquadWithOrgDetail } from 'models/organization';
import styled from 'styled-components';
import COLOR, { transparent } from 'constants/color';
import { groupArrayKeyByValue } from 'utils/formatter';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const STATE = {
  loading: 'loading',
  done: 'done',
};

type Props = RouteComponentProps<{ id: string }, object>;

const renderLoadingModal = (): React.ReactNode => (
  <CenterModal
    title="Remove Squads"
  >
    Removing squad(s) ...
  </CenterModal>
);

const renderDoneModal = (
  removedSquads: SquadWithOrgDetail[],
  errors: { name: string; message: string }[],
  rightButtonOnClick: () => void,
): React.ReactNode => {
  const formattedErrors = groupArrayKeyByValue(errors, 'name', 'message').map((grouped) => (
    `${Array.isArray(grouped.name) ? grouped.name.join(', ') : grouped.name} 
    ${(typeof grouped.message === 'string') ? grouped.message.trim() : grouped.message}`
  ));

  return (
    <CenterModal
      rightButtonOnClick={rightButtonOnClick}
      rightButtonText="OK"
      title="Squad membership removed"
    >
      {removedSquads.length > 0 && (
        <Alert status="success">
          <LabelInfoBold>
            {'Driver\'s Squad membership has been withdrawn from the following Squads:'}
          </LabelInfoBold>
          <List>
            {removedSquads.map((squad, index) => (<li key={index}>{squad.organization.name}</li>))}
          </List>
        </Alert>
      )}
      {formattedErrors.length > 0 && (
        <List>
          {formattedErrors.map((error, index) => <li key={index}>{error}</li>)}
        </List>
      )}
    </CenterModal>
  );
};

const SquadList = (props: Props): JSX.Element => {
  const [squads, setSquads] = React.useState<SquadWithOrgDetail[]>([]);
  const [modalState, setModalState] = React.useState('');
  const [errors, setErrors] = React.useState([]);
  const [selected, setSelected] = React.useState({});
  const [removedSquads, setRemovedSquads] = React.useState<SquadWithOrgDetail[]>([]);
  const [showRemoveSquadModal, setRemoveSquadModal] = React.useState(false);
  React.useEffect(() => {
    if (modalState == '') {
      (async function (): Promise<void> {
        const client = new OrganizationManagementClient();
        const squads = await client.sysAdminGetSquads(props.match.params.id);
        setSquads(squads);
      })();
    }
  }, [modalState]);

  const columns = {
    organisations: 'Organisations',
  };
  const data = squads.map(squad => ({
    id: squad.id,
    organisations: squad.organization.name,
  }));
  const { id } = props.match.params;

  const renderBreadcrumb = (): React.ReactNode => {
    return (
      <Breadcrumb
        items={[
          { text: 'Drivers', onClick: (): void => { props.history.push('/sys/drivers'); } },
          { text: id, onClick: (): void => { props.history.push(`/sys/drivers/${id}`); } },
          { text: 'Squads' },
        ]}
      />
    );
  };

  const renderHeader = (): React.ReactNode => {
    return (
      <TableHeader>
        <Title>Squads</Title>
        <Subtitle>
          {squads.length === 0 && 'No squads joined yet.'}
        </Subtitle>
        <StyledButton
          buttonStyle="encourage"
          buttonType="primary"
          onClick={(): void => { props.history.push(`/sys/drivers/${id}/add-squad`); }}
        >
          <Icon icon={faPlus} />
          Add
        </StyledButton>
      </TableHeader>
    );
  };

  const toggleSelectAll = (): void => {
    const count = Object.keys(selected).length;

    if (count < squads.length) {
      setSelected(
        squads.reduce<{ [key: string]: SquadWithOrgDetail }>((acc, model): { [key: string]: SquadWithOrgDetail } => {
          acc[model.id] = model;
          return acc;
        }, {}),
      );
    } else {
      setSelected({});
    }
  };

  const toggleSelect = (index: number): void => {
    setSelected(prevState => {
      const model = squads[index];
      const newSelected: { [key: string]: SquadWithOrgDetail } = { ...prevState };

      if (newSelected[model.id]) {
        delete newSelected[model.id];
      } else {
        newSelected[model.id] = model;
      }
      return newSelected;
    });
  };

  const removeSquads = async (): Promise<void> => {
    setRemoveSquadModal(false);
    setModalState(STATE.loading);
    setRemovedSquads([]);
    setErrors([]);
    const client = new OrganizationManagementClient();
    await Promise.all(Object.values(selected).map(async (squad: SquadWithOrgDetail): Promise<void> => {
      try {
        await client.sysAdminRemoveSquad(squad.id);
        setRemovedSquads(prevState => prevState.concat(squad));
      } catch (e) {
        setErrors(prevState => prevState.concat({ name: squad.organization.name, message: e.message }));
      }
    }));
    setModalState(STATE.done);
  };

  const renderMultipleSelectActions = (): React.ReactNode => {
    const ids = Object.keys(selected);
    const isAllSelected = ids.length === squads.length;

    return (
      <MultipleSelectActionContainer>
        <div>
          {`${isAllSelected ? 'All ' : ''}${ids.length} squads on this page are selected.`}
        </div>
        <SelectLink
          onClick={toggleSelectAll}
        >
          {
            isAllSelected ? (
              `Deselect all ${squads.length} squads`
            ) : (
              `Select all ${squads.length} squads`
            )
          }
        </SelectLink>
        <div>
          <StyledButton
            buttonStyle="encourage"
            buttonType="primary"
            onClick={(): void => { setRemoveSquadModal(true); }}
          >
            Remove
          </StyledButton>
        </div>
      </MultipleSelectActionContainer>
    );
  };

  return (
    <SysAdminMainContainer selected="drivers">
      {renderBreadcrumb()}
      {renderHeader()}
      <ContentContainer>
        <Table
          columns={columns}
          data={data}
          multipleSelect
          onToggleCheckbox={toggleSelect}
          onToggleCheckboxAll={toggleSelectAll}
          selected={selected}
        />
      </ContentContainer>
      {Object.keys(selected).length > 0 ? renderMultipleSelectActions() : false}
      {modalState === STATE.loading && renderLoadingModal()}
      {modalState === STATE.done && renderDoneModal(
        removedSquads,
        errors,
        (): void => {
          setModalState('');
          setSelected({});
        },
      )}
      {
        showRemoveSquadModal ? (
          <CenterModal
            leftButtonOnClick={(): void => { setRemoveSquadModal(false); }}
            leftButtonText="Cancel"
            rightButtonText="Remove"
            rightButtonOnClick={removeSquads}
            rightButtonStyle="discourage"
            rightButtonType="secondary"
            title="Remove Driver from selected Squad(s)?"
          >
            <ModalTextContainer>
              <ModalText>
                The Driver will be withdrawn as members of Squad of the selected organisations
                and will not be entitled to the 20%-commission-fee-waive to future broadcasts.
              </ModalText>
              <ModalText>
                Previous driver activity as Squad member the organisations will not be changed.
              </ModalText>
            </ModalTextContainer>
          </CenterModal>
        ) : false
      }
    </SysAdminMainContainer>
  );
};

export default SquadList;

const Icon = styled(FontAwesomeIcon)`
  align-self: center;
  margin-right: 0.375rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
`;

const Title = styled.div`
  font-size: 1.5rem;
`;

const Subtitle = styled.div`
  margin: auto;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const ContentContainer = styled.div`
  border-radius: 0.5rem;
  padding-left: 1rem;
  background-color: ${COLOR.white};
`;

const MultipleSelectActionContainer = styled.div`
  background-color: ${COLOR.whiteGrey};
  border-radius: 8px;
  bottom: 3rem;
  box-shadow: 0px 4px 16px ${transparent('shadow', 80)};
  left: 1.5rem;
  padding: 0.5rem 1rem;
  position: fixed;
  width: calc(100% - 5rem);
  z-index: 14;
`;

const SelectLink = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  margin-bottom: 0.375rem;
`;

const LabelInfoBold = styled.div`
  font-weight: 600;
`;

const ModalTextContainer = styled.div`
  margin-bottom: 2rem;
`;

const ModalText = styled.div`
  line-height: 1.25rem;
  margin-bottom: 1rem;
`;
