import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import {
  TaskAPIResponse,
  TasksAPIResponse,
  Task,
  TaskForm,
  MerchantCreateTasksForm,
  MerchantCreateTaskForm,
  MerchantOrderEditForm,
  TaskDraftForm,
  AddLineItemsForm,
  UpdateLineItemsForm,
  MetaTasks,
  TaskPriceEditForm,
  TaskAdminFeeEditForm,
  SysAdminTaskEditForm,
  AddAttachmentsForm,
  GetPresignedUrlResponse,
  SysConfirmPickupPayload,
  SysConfirmDeliveryPayload,
  SysUnsuccessfulPickupPayload,
  SysUnsuccessfulDeliveryPayload,
  SysStartPickupPayload,
} from 'models/task';
import { EndorseEpodPayload, Epod } from 'models/task/epod';
import configureStore from 'reduxActions/store';
import * as HttpHelper from 'utils/httpHelper';
import { receiveTaskAllSuccess, receiveTaskSuccess } from 'reduxActions/task/taskActions';
import { getBearerToken, checkAuthorization } from 'utils/userHelper';
import { PER_PAGE } from 'constants/paginationMeta';

class TaskClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.TASK_MANAGEMENT_SERVICE_PROTOCOL;
    this.host = process.env.TASK_MANAGEMENT_SERVICE_HOST;
    this.port = process.env.TASK_MANAGEMENT_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  taskUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/task?${params}`;
  }

  tasksUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/tasks?${params}`;
  }

  sysAdminTaskUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/task?${params}`;
  }

  getTaskUrl(id: string): string {
    return `${this.getBaseUrl()}/task/${id}`;
  }

  getTaskByTrackingIDUrl(trackingID: string): string {
    return `${this.getBaseUrl()}/task-tracking/${trackingID}`;
  }

  getTaskDraftUrl(id: string): string {
    return `${this.getBaseUrl()}/task/${id}/update-draft`;
  }

  sysAdminGetTaskUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/task/${id}`;
  }

  sysAdminUpdateTaskPriceUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/task/${id}/price`;
  }

  sysAdminUpdateTaskAdminFeeUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/task/${id}/admin-fee`;
  }

  addLineItemUrl(id: string): string {
    return `${this.getBaseUrl()}/task/${id}/item`;
  }

  deleteLineItemUrl(taskId: string, itemId: string): string {
    return `${this.getBaseUrl()}/task/${taskId}/item/${itemId}`;
  }

  updateLineItemUrl(id: string, itemId: string): string {
    return `${this.getBaseUrl()}/task/${id}/item/${itemId}`;
  }

  sysAdminGetNoRunsheetTasksUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/no-runsheet-task?${params}`;
  }

  cancelTaskUrl(id: string): string {
    return `${this.getBaseUrl()}/task/${id}/cancel`;
  }

  sysAdminCancelTaskUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/task/${id}/cancel`;
  }

  sysAdminBroadcastTaskUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/broadcast?${params}`;
  }

  broadcastTaskUrl(): string {
    return `${this.getBaseUrl()}/broadcast`;
  }

  getSignedAttachmentLinkUrl(id: string, filename: string): string {
    return `${this.getBaseUrl()}/task/${id}/attachment/presigned_url?filename=${filename}`;
  }

  addAttachmentUrl(id: string): string {
    return `${this.getBaseUrl()}/task/${id}/attachment`;
  }

  sysStartPickupUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/task/${id}/start-pickup`;
  }

  sysConfirmPickupUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/task/${id}/arrive-complete-pickup`;
  }

  sysUnsuccessfulPickupUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/task/${id}/arrive-fail-pickup`;
  }

  sysConfirmDeliveryUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/task/${id}/arrive-complete-delivery`;
  }

  sysUnsuccessfulDeliveryUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/task/${id}/arrive-fail-delivery`;
  }

  taskEpodUrl(taskId: string): string {
    return `${this.getBaseUrl()}/task/${taskId}/epod`;
  }

  makeRequest<T>(
    method: Method,
    url: string,
    body: T | null = null,
    includeBearerToken = true
  ): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  async create(form: MerchantCreateTaskForm): Promise<TaskAPIResponse> {
    const request = this.makeRequest('POST', this.taskUrl(), form);

    try {
      const response: AxiosResponse<TaskAPIResponse> = await axios(request);

      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async createTasks(forms: MerchantCreateTasksForm): Promise<TasksAPIResponse> {
    const request = this.makeRequest('POST', this.tasksUrl(), forms);

    try {
      const response: AxiosResponse<TasksAPIResponse> = await axios(request);

      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysAdminCreate(form: TaskForm): Promise<TaskAPIResponse> {
    const request = this.makeRequest('POST', this.sysAdminTaskUrl(), form);
    try {
      const response: AxiosResponse<TaskAPIResponse> = await axios(request);

      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  async getTasks(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', this.taskUrl(params));

    try {
      const response: AxiosResponse<MetaTasks> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskAllSuccess(response.data.tasks));
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
      }
      throw error.message;
    }
  }

  async sysAdminGetTasks(params: URLSearchParams): Promise<void> {
    params.append('per_page', PER_PAGE.toString());
    const request = this.makeRequest('GET', this.sysAdminTaskUrl(params));

    try {
      const response: AxiosResponse<MetaTasks> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveTaskAllSuccess(response.data.tasks));
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
      }
      throw error.message;
    }
  }

  async getTask(id: string): Promise<Task> {
    const request = this.makeRequest('GET', this.getTaskUrl(id));
    try {
      const response: AxiosResponse<Task> = await axios(request);
      const store = configureStore();

      store.dispatch(receiveTaskSuccess(response.data));
      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
      }
      throw error.message;
    }
  }

  async getTaskByTrackingID(trackingID: string): Promise<Task> {
    const request = this.makeRequest('GET', this.getTaskByTrackingIDUrl(trackingID));
    try {
      const response: AxiosResponse<Task> = await axios(request);
      const store = configureStore();

      store.dispatch(receiveTaskSuccess(response.data));
      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
      }
      throw error.message;
    }
  }

  async sysAdminGetTask(id: string): Promise<Task> {
    const request = this.makeRequest('GET', this.sysAdminGetTaskUrl(id));
    try {
      const response: AxiosResponse<Task> = await axios(request);
      const store = configureStore();

      store.dispatch(receiveTaskSuccess(response.data));
      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
      }
      throw error.message;
    }
  }

  async addLineItem(id: string, items: AddLineItemsForm): Promise<void> {
    const request = this.makeRequest('POST', this.addLineItemUrl(id), items);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysAdminGetNoRunsheetTasks(
    queryParams = new URLSearchParams()
  ): Promise<MetaTasks> {
    queryParams.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest(
      'GET',
      this.sysAdminGetNoRunsheetTasksUrl(queryParams)
    );
    try {
      const response: AxiosResponse<MetaTasks> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
      }
      throw error.message;
    }
  }

  async deleteLineItem(
    taskId: string,
    itemId: string,
    versionRev: string
  ): Promise<void> {
    const request = this.makeRequest('DELETE', this.deleteLineItemUrl(taskId, itemId), {
      version_rev: versionRev,
    });
    try {
      const response: AxiosResponse<Task> = await axios(request);
      const store = configureStore();

      store.dispatch(receiveTaskSuccess(response.data));
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.error || error.response.data.message;
      } else {
        throw error.message;
      }
    }
  }

  async updateTask(id: string, form: MerchantOrderEditForm): Promise<Task> {
    const request = this.makeRequest('PUT', this.getTaskUrl(id), form);
    try {
      const response: AxiosResponse<Task> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async updateDraftTask(id: string, form: TaskDraftForm): Promise<void> {
    const request = this.makeRequest('PUT', this.getTaskDraftUrl(id), form);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysAdminUpdateTask(id: string, form: SysAdminTaskEditForm): Promise<Task> {
    const request = this.makeRequest('PUT', this.sysAdminGetTaskUrl(id), form);
    try {
      const response: AxiosResponse<Task> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysAdminUpdateTaskPrice(id: string, form: TaskPriceEditForm): Promise<void> {
    const request = this.makeRequest('PUT', this.sysAdminUpdateTaskPriceUrl(id), form);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysAdminUpdateTaskAdminFee(
    id: string,
    form: TaskAdminFeeEditForm
  ): Promise<void> {
    const request = this.makeRequest('PUT', this.sysAdminUpdateTaskAdminFeeUrl(id), form);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async deleteTask(id: string): Promise<void> {
    const request = this.makeRequest('DELETE', this.getTaskUrl(id));
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
      }
      throw error.message;
    }
  }

  async updateLineItem(
    id: string,
    itemId: string,
    itemForm: UpdateLineItemsForm
  ): Promise<void> {
    const request = this.makeRequest('PUT', this.updateLineItemUrl(id, itemId), itemForm);
    // TO DO: to pass in the bearer token
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async cancelTask(
    id: string,
    form: { reason: string; version_rev: string }
  ): Promise<void> {
    const request = this.makeRequest('PUT', this.cancelTaskUrl(id), form);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysAdminCancelTask(
    id: string,
    form: { reason: string; version_rev: string }
  ): Promise<void> {
    const request = this.makeRequest('PUT', this.sysAdminCancelTaskUrl(id), form);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysAdminBroadcastTask(queryParams = new URLSearchParams()): Promise<void> {
    const request = this.makeRequest('PUT', this.sysAdminBroadcastTaskUrl(queryParams));
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data;
      } else {
        throw error.message;
      }
    }
  }

  async broadcastTask(): Promise<void> {
    const request = this.makeRequest('PUT', this.broadcastTaskUrl());
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data;
      } else {
        throw error.message;
      }
    }
  }

  async getSignedAttachmentLink(id: string, filename: string): Promise<string> {
    const request = await this.makeRequest(
      'GET',
      this.getSignedAttachmentLinkUrl(id, filename)
    );
    try {
      const response: AxiosResponse<GetPresignedUrlResponse> = await axios(request);
      return response.data.signed_url;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async addAttachment(id: string, attachment: AddAttachmentsForm): Promise<Task> {
    const request = this.makeRequest('POST', this.addAttachmentUrl(id), attachment);
    try {
      const response: AxiosResponse<Task> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysStartPickup(id: string, payload: SysStartPickupPayload): Promise<void> {
    const request = this.makeRequest('PUT', this.sysStartPickupUrl(id), payload);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysConfirmPickup(id: string, payload: SysConfirmPickupPayload): Promise<void> {
    const request = this.makeRequest('PUT', this.sysConfirmPickupUrl(id), payload);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysUnsuccessfulPickup(
    id: string,
    payload: SysUnsuccessfulPickupPayload
  ): Promise<void> {
    const request = this.makeRequest('PUT', this.sysUnsuccessfulPickupUrl(id), payload);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysConfirmDelivery(
    id: string,
    payload: SysConfirmDeliveryPayload
  ): Promise<void> {
    const request = this.makeRequest('PUT', this.sysConfirmDeliveryUrl(id), payload);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async sysUnsuccessfulDelivery(
    id: string,
    payload: SysUnsuccessfulDeliveryPayload
  ): Promise<void> {
    const request = this.makeRequest('PUT', this.sysUnsuccessfulDeliveryUrl(id), payload);
    try {
      await axios(request);
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async getEpodByTaskId(taskId: string): Promise<Epod> {
    const request = this.makeRequest('GET', this.taskEpodUrl(taskId));
    try {
      const response: AxiosResponse<Epod> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async endorseEpod(taskId: string, payload: EndorseEpodPayload): Promise<Epod> {
    const request = this.makeRequest('PUT', this.taskEpodUrl(taskId), payload);
    try {
      const response: AxiosResponse<Epod> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }

  async generateEpod(taskId: string): Promise<Epod> {
    const request = this.makeRequest('POST', this.taskEpodUrl(taskId));
    try {
      const response: AxiosResponse<Epod> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response) {
        checkAuthorization(error.response.status);
        throw error.response.data.message || error.response.data.error;
      } else {
        throw error.message;
      }
    }
  }
}

export default TaskClient;
