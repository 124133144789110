import * as React from 'react';
import { CenterModal, Typography, ButtonWithIcon } from 'components';
import styled from 'styled-components';
import { VEHICLE_PREFERENCE_DESCRIPTION, VEHICLE_PREFERENCE_FTL_CAPACITY, VEHICLE_PREFERENCE_INFO } from 'constants/vehiclePreference';
import { ServicePricing } from 'models/task';

interface FtlVehicleModalProps {
  closeModal: () => void;
  onVehicleClick: (vehicle: string, price: number) => void;
  selectedVehiclePreference: string;
  servicePricing: ServicePricing[];
  disabled?: boolean;
}

class FtlVehicleModal extends React.Component<FtlVehicleModalProps> {
  render(): React.ReactNode {
    return (
      <CenterModal
        title="Select your truck"
        closeButtonOnClick={this.props.closeModal}
        width="small"
        position="bottom"
      >
        {this.props.servicePricing.map((price: ServicePricing, i: number) => <VehicleButtonContainer key={i}>
          <ButtonWithIcon
            icon={<VehiclePreferenceIcon src={VEHICLE_PREFERENCE_INFO[price.vehicle_preference].icon} />}
            isActive={price.vehicle_preference === this.props.selectedVehiclePreference}
            mainText={<Typography
              as="span"
              color="gray_700"
              weight="semibold"
              size="md"
              customStyle={{ marginRight: 'auto' }}
            >
              {VEHICLE_PREFERENCE_DESCRIPTION[price.vehicle_preference]}
            </Typography>}
            description={<TruckDescription>
              <Typography
                as="h1"
                color="gray_600"
                weight="normal"
                size="sm"
                customStyle={{ marginRight: 'auto' }}
              >
                {VEHICLE_PREFERENCE_FTL_CAPACITY[price.vehicle_preference].capacity}
              </Typography>
              <Typography
                as="h1"
                color="primary_600"
                weight="semibold"
                size="sm"
                customStyle={{ marginRight: 'auto' }}
              >
                {price.currency} {price.price} {price.uom_description}
              </Typography>
              <Typography
                as="h1"
                color="gray_600"
                weight="normal"
                size="sm"
                customStyle={{ marginRight: 'auto' }}
              >
                {price.additional_cost}
              </Typography>
            </TruckDescription>}
            onClick={(): void => {
              this.props.onVehicleClick(price.vehicle_preference, price.price);
              this.props.closeModal();
            }}
            disabled={this.props.disabled}
          />
        </VehicleButtonContainer>)}
      </CenterModal>
    );
  }
}

const VehiclePreferenceIcon = styled.img`
  width: 5rem;
  height: 2rem;
`;

const VehicleButtonContainer = styled.div`
  margin-top: 1rem;
`;

const TruckDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export default FtlVehicleModal;
