import * as React from 'react';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import {
  Table,
  StyledButton,
  SysAdminPagination,
  TableCellRenderEvent,
} from 'components';
import OrganizationSignUpClient from 'httpClients/organizationSignUpClient';
import { PendingOrganization } from 'models/organization';
import ROLES, { ROLE_NAME } from 'constants/role';
import * as H from 'history';

interface PendingOrganizationIndexProps {
  models: PendingOrganization[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type Props = HistoryProps & PendingOrganizationIndexProps;

class PendingOrganizationIndex extends SysAdminPagination<
  PendingOrganization,
  Props
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,
      basePath: '/sys/organizations/pending',
      filters: this.getDefaultFilter(),
      pluralModelName: 'pending organizations',
      selectedTab: 'organizations',
    };

    this.rules = [{ name: 'page' }];
  }

  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
  });

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new OrganizationSignUpClient();
    await client.sysGetPendingOrganizations(
      new URLSearchParams(this.state.filters)
    );

    this.setState({ isFetching: false });
  };

  approve = async (id: string, versionRev: string): Promise<void> => {
    const client = new OrganizationSignUpClient();
    await client.sysApprove(id, versionRev);

    this.fetchData();
  };

  renderCell = (
    e: TableCellRenderEvent<{ action: string }>
  ): React.ReactNode => {
    if (e.key === 'action') {
      const selectedOrg = this.props.models.find(
        (org) => org.id === e.value.toString()
      );

      if (selectedOrg.status === 'pending') {
        return (
          <div>
            <StyledButton
              onClick={(): void => {
                this.approve(selectedOrg.id, selectedOrg.version_rev);
              }}
              buttonStyle="encourage"
              buttonType="neutral"
              size="sm"
            >
              Approve
            </StyledButton>
          </div>
        );
      }
      return false;
    }

    return <>{e.value}</>;
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;
    const columns = {
      id: 'ID',
      business_name: 'Business Name',
      business_role: 'Business Type',
      requester: 'Requester',
      status: 'Status',
      updatedAt: 'Updated At',
      action: 'Action',
    };
    const data = models
      ? models.map((organization) => ({
          id: organization.id,
          business_name: organization.business_name,
          business_role:
            organization.business_role === ROLES.OrgTransporter
              ? ROLE_NAME[ROLES.OrgTransporter]
              : ROLE_NAME[ROLES.OrgAdmin],
          requester: organization.requester.email,
          status: organization.status,
          updatedAt: organization.updated_at,
          action: organization.id,
        }))
      : [];

    return (
      <Table
        columns={columns}
        cellRenderer={this.renderCell}
        data={data}
        hideNavigationButton
      />
    );
  };
}

const mapStateToProps = (state: RootState): PendingOrganizationIndexProps => ({
  models: state.organization.pendingOrganizations,
});

export default connect(mapStateToProps)(PendingOrganizationIndex);
