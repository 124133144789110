import * as React from 'react';
import * as H from 'history';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { PageTitle } from 'components';
import {
  Table,
  Pagination,
  StyledButton,
  PaginationState,
  MainContainer,
  Breadcrumb,
  Separator,
  Heading,
} from 'components';
import { User } from 'models/auth';
import styled from 'styled-components';
import emptyPlaceholder from 'assets/images/team_empty_state.png';
import COLOR from 'constants/color';
import AuthClient from 'httpClients/authClient';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvitationModal from './modal/invitationModal';
import SettingSidebarMenu from '../../sidebarMenu';
import { fetchCurrentUser } from 'utils/userHelper';

interface IconProps {
  color: string;
}

interface TeamProps {
  models: User[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface TeamState extends PaginationState<User> {
  showInvitationModal: boolean;
}

type Props = TeamProps & HistoryProps;

class TeamIndex extends Pagination<User, Props, TeamState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      basePath: '/setting/company/team',
      showInvitationModal: false,
      renderContainer: false,
      renderSortBy: false,
      filters: this.defaultFilter(),
      pluralModelName: 'users',
    };
    this.rules = [{ name: 'page' }];
  }

  componentDidMount(): void {
    // fetching the current user
    fetchCurrentUser();
  }

  defaultFilter = (): Record<string, string> => ({
    page: '1',
  });

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const client = new AuthClient();
    await client.getUsers(new URLSearchParams(this.state.filters));

    this.setState({ isFetching: false });
  };

  showInvitationModal = (): void => {
    this.setState({ showInvitationModal: true });
  };

  closeInvitationModal = (): void => {
    this.setState({ showInvitationModal: false });
  };

  renderInvitationModal = (): React.ReactNode => (
    <>
      {this.state.showInvitationModal && (
        <InvitationModal closeModal={this.closeInvitationModal} />
      )}
    </>
  );

  renderPage = (content: JSX.Element): React.ReactNode => {
    return (
      <MainContainer selected="setting">
        <Breadcrumb
          items={[
            {
              text: 'Setting',
              onClick: (): void => {
                this.props.history.push('/setting');
              },
            },
            { text: 'Team Members' },
          ]}
        />
        <Heading>
          <PageTitle>Setting</PageTitle>
        </Heading>
        <SettingSidebarMenu selected="/setting/company/team">
          <Heading>
            <PageTitle>Team</PageTitle>
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={this.showInvitationModal}
            >
              <Icon icon={faPlusCircle} color={COLOR.black} />
              Invite members
            </StyledButton>
          </Heading>
          <CustomSeparator />
          {this.renderFilterAndPagination()}
          {content}
          {this.renderInvitationModal()}
        </SettingSidebarMenu>
      </MainContainer>
    );
  };

  renderHeader = (): React.ReactNode => {
    return false;
  };

  renderEmptyState = (): React.ReactNode => {
    return (
      <>
        {this.renderPage(
          <EmptyContainer>
            <Placeholder src={emptyPlaceholder} />
            <Subtitle>No members yet</Subtitle>
            <Description>Invite members</Description>
          </EmptyContainer>
        )}
      </>
    );
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;

    const columns = {
      name: 'Name',
      email: 'Email',
    };

    const data = models.map((user) => ({
      id: user.id,
      name: user.name,
      email: user.email,
    }));

    return <>{this.renderPage(<Table columns={columns} data={data} />)}</>;
  };
}

const Placeholder = styled.img`
  width: 13.5rem;
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-bottom: 5rem;

  & > * {
    margin: 0.5rem;
  }
`;

const Subtitle = styled.div`
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const Icon = styled(FontAwesomeIcon)<IconProps>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.375rem;
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
  margin-bottom: 0.5rem;
`;

const mapStateToProps = (state: RootState): TeamProps => ({
  models: state.auth.users,
});

export default connect(mapStateToProps)(TeamIndex);
