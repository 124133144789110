import * as React from 'react';
import styled from 'styled-components';
import COLOR from 'constants/color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

export interface BreadcrumbItem {
  text: string;
  onClick?: () => void;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  customStyle?: React.CSSProperties;
}

const Breadcrumb = (props: BreadcrumbProps): JSX.Element => (
  <>
    {props.items && props.items.length > 1 && (
      <BreadcrumbContainer style={props?.customStyle}>
        {props.items.map((item, index) => (
          <BreadcrumbItem key={item.text}>
            {item.onClick ? (
              <Link onClick={item.onClick}>{item.text}</Link>
            ) : (
              <CurrentActive>{item.text}</CurrentActive>
            )}
            {index !== props.items.length - 1 ? <ArrowIcon icon={faAngleRight} /> : false}
          </BreadcrumbItem>
        ))}
      </BreadcrumbContainer>
    )}
  </>
);

const BreadcrumbContainer = styled.div`
  color: ${COLOR.darkGray};
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  white-space: pre;
  align-items: center;
  margin-bottom: 1rem !important;

  @media (max-width: 768px) {
    display: none;
  }
`;

const BreadcrumbItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1rem;
`;

const Link = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  color: ${COLOR.darkGray};
  cursor: pointer;
  :hover {
    border-bottom: 1px solid ${COLOR.yellow};
    color: ${COLOR.black};
  }
`;

const CurrentActive = styled.div`
  background-color: ${COLOR.whiteGrey};
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
`;

export default Breadcrumb;
