import {
  NotificationState,
  NotificationActionTypes,
  RECEIVE_META_NOTIFICATION,
} from './notificationTypes';

const initialState: NotificationState = {
  metaNotification: null,
};

function notificationReducer(
  state: NotificationState = initialState,
  action: NotificationActionTypes
): NotificationState {
  switch (action.type) {
    case RECEIVE_META_NOTIFICATION:
      return { ...state, metaNotification: action.metaNotification };
    default:
      return state;
  }
}

export default notificationReducer;