import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import * as HttpHelper from 'utils/httpHelper';
import {
  DefaultPriceForm,
  CompanyDetailsForm,
  MetaOrganizations,
  Organization,
  GetSquadsResponse,
  SquadWithOrgDetail,
  SquadDriver,
  DefaultServiceForm,
} from 'models/organization';
import { getBearerToken } from 'utils/userHelper';
import configureStore from 'reduxActions/store';
import {
  receiveOrganization,
  receiveOrganizations,
  receiveSquads,
  receiveSquadDrivers,
} from 'reduxActions/organization/organizationActions';
import { PER_PAGE } from 'constants/paginationMeta';

class OrganizationManagementClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.ORGANIZATION_MANAGEMENT_SERVICE_PROTOCOL;
    this.host = process.env.ORGANIZATION_MANAGEMENT_SERVICE_HOST;
    this.port = process.env.ORGANIZATION_MANAGEMENT_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(method: Method, url: string, body: T | null = null, includeBearerToken = true): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  getSysOrganizationUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/organization?${params}`;
  }

  getOrganizationUrl(): string {
    return `${this.getBaseUrl()}/organization`;
  }

  getSysOrganizationDetailUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/organization/${id}`;
  }

  squadUrl(params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/squad?${params}`;
  }

  updateDefaultServiceUrl(): string {
    return `${this.getBaseUrl()}/default-broadcast-preference`;
  }

  updateDefaultPriceUrl(): string {
    return `${this.getBaseUrl()}/default-price`;
  }

  sysUpdateDefaultPriceUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/organization/${id}/default-price`;
  }

  sysUpdateCompanyDetailsUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/organization/${id}`;
  }

  updateCompanyDetailsUrl(): string {
    return `${this.getBaseUrl()}/organization`;
  }

  getSysSquadsUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/driver/${id}/squad`;
  }

  getSysSquadDriversUrl(id: string, params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/squad/${id}/driver?${params}`;
  }

  sysRemoveSquadUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/squad/${id}`;
  }

  removeSquadUrl(id: string): string {
    return `${this.getBaseUrl()}/squad/${id}`;
  }

  sysAddSquadUrl(): string {
    return `${this.getBaseUrl()}/sys/squad`;
  }

  async getOrganization(): Promise<void> {
    const request = this.makeRequest('GET', this.getOrganizationUrl());

    try {
      const response: AxiosResponse<{ organization: Organization }> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveOrganization(response.data.organization));
    } catch (error) {
      throw error.message;
    }
  }

  async sysAdminGetOrganization(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.getSysOrganizationDetailUrl(id));

    try {
      const response: AxiosResponse<{ organization: Organization }> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveOrganization(response.data.organization));
    } catch (error) {
      throw error.message;
    }
  }

  async sysAdminGetOrganizations(params = new URLSearchParams()): Promise<void> {
    params.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.getSysOrganizationUrl(params));

    try {
      const response: AxiosResponse<MetaOrganizations> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveOrganizations(response.data.organizations));
    } catch (error) {
      throw error.message;
    }
  }

  // This is the correct replacement for sysAdminGetOrganizations once the redux usage is fixed
  async sysAdminGetOrganizationList(params = new URLSearchParams()): Promise<MetaOrganizations> {
    params.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest('GET', this.getSysOrganizationUrl(params));

    try {
      const response: AxiosResponse<MetaOrganizations> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error;
    }
  }

  async getSquads(params: URLSearchParams): Promise<void> {
    const request = this.makeRequest('GET', this.squadUrl(params));

    try {
      const response: AxiosResponse<GetSquadsResponse> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveSquads(response.data.squads));
    } catch (error) {
      throw error.message;
    }
  }

  async updateDefaultPrice(form: DefaultPriceForm): Promise<void> {
    const request = this.makeRequest('PUT', this.updateDefaultPriceUrl(), form);

    try {
      await axios(request);
    } catch (error) {
      throw error.message;
    }
  }

  async updateDefaultService(form: DefaultServiceForm): Promise<Organization> {
    const request = this.makeRequest('PUT', this.updateDefaultServiceUrl(), form);

    try {
      const response: AxiosResponse<{ organization: Organization }> = await axios(request);
      return response.data.organization;
    } catch (error) {
      throw error.message;
    }
  }

  async sysAdminUpdateDefaultPrice(id: string, form: DefaultPriceForm): Promise<void> {
    const request = this.makeRequest('PUT', this.sysUpdateDefaultPriceUrl(id), form);

    try {
      await axios(request);
    } catch (error) {
      throw error.message;
    }
  }

  async sysUpdateCompanyDetails(id: string, form: CompanyDetailsForm): Promise<void> {
    const request = this.makeRequest('PUT', this.sysUpdateCompanyDetailsUrl(id), form);

    try {
      await axios(request);
    } catch (error) {
      if (error.response && error.response.data) {
        const data = error.response.data;
        if (data.code === 'invalid-request' && data.details.business_name) {
          const type = data.details.business_name.type;
          if (type === 'alphanumc') {
            throw 'Allowed characters are : Alphanumeric (A-Z, a-z, 0-9), space ( ), dash (-) and underscore (_)';
          }
          if (type === 'iexcludes' || type === 'ine') {
            throw 'This name cannot be used, please choose another name';
          }
        }
        throw error.response.data.message || error.response.data.code;
      }
      throw error.message;
    }
  }

  async updateCompanyDetails(form: CompanyDetailsForm): Promise<void> {
    const request = this.makeRequest('PUT', this.updateCompanyDetailsUrl(), form);

    try {
      await axios(request);
    } catch (error) {
      if (error.response && error.response.data) {
        const data = error.response.data;
        if (data.code === 'invalid-request' && data.details.business_name) {
          const type = data.details.business_name.type;
          if (type === 'alphanumc') {
            throw 'Allowed characters are : Alphanumeric (A-Z, a-z, 0-9), space ( ), dash (-) and underscore (_)';
          }
          if (type === 'iexcludes' || type === 'ine') {
            throw 'This name cannot be used, please choose another name';
          }
        }
        throw error.response.data.message || error.response.data.code;
      }
      throw error.message;
    }
  }

  async sysAdminGetSquads(id: string): Promise<SquadWithOrgDetail[]> {
    const request = this.makeRequest('GET', this.getSysSquadsUrl(id), {});

    try {
      const response: AxiosResponse<{ squads: SquadWithOrgDetail[] }> = await axios(request);
      return response.data.squads;
    } catch (error) {
      throw error.message;
    }
  }

  async sysAdminGetSquadDrivers(id: string, params: URLSearchParams): Promise<void> {
    const request = this.makeRequest('GET', this.getSysSquadDriversUrl(id, params), {});

    try {
      const response: AxiosResponse<{ squads: SquadDriver[] }> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveSquadDrivers(response.data.squads));
    } catch (error) {
      throw error.message;
    }
  }

  async sysAdminRemoveSquad(id: string): Promise<void> {
    try {
      const request = this.makeRequest('DELETE', this.sysRemoveSquadUrl(id), null);
      await axios(request);
    } catch(error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error;
    }
  }

  async removeSquad(id: string): Promise<void> {
    try {
      const request = this.makeRequest('DELETE', this.removeSquadUrl(id), null);
      await axios(request);
    } catch(error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error;
    }
  }

  async sysAdminAddSquad(form: { driver_id: string; org_id: string }): Promise<void> {
    try {
      const request = this.makeRequest('POST', this.sysAddSquadUrl(), form);
      await axios(request);
    } catch(error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error;
    }
  }
}

export default OrganizationManagementClient;
