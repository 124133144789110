import { OrgDriver } from 'models/organization';
import {
  ReceiveOrgDriverAction,
  ReceiveOrgDriversAction,
  RECEIVE_ORGANIZATION_DRIVER,
  RECEIVE_ORGANIZATION_DRIVERS,
} from './orgDriverTypes';

export function receiveOrgDriver(orgDriver: OrgDriver): ReceiveOrgDriverAction {
  return {
    type: RECEIVE_ORGANIZATION_DRIVER,
    orgDriver,
  };
}

export function receiveOrgDrivers(orgDrivers: OrgDriver[]): ReceiveOrgDriversAction {
  return {
    type: RECEIVE_ORGANIZATION_DRIVERS,
    orgDrivers,
  };
}