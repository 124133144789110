import * as React from 'react';
import {
  MainContainer,
  Breadcrumb,
  Heading,
  PageTitle,
  Separator,
  StyledButton,
  FieldView,
} from 'components';
import { RouteComponentProps } from 'react-router-dom';
import { User } from 'models/auth';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import SettingSidebarMenu from '../../sidebarMenu';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { fetchCurrentUser } from 'utils/userHelper';

interface ProfileProps {
  user: User;
}

type Props = RouteComponentProps & ProfileProps;

const ProfileIndex = (props: Props): JSX.Element => {
  React.useEffect(() => {
    // fetching the current user
    fetchCurrentUser();
  }, []);

  return (
    <MainContainer selected="setting">
      <Breadcrumb
        items={[
          { text: 'Setting', onClick: (): void => { props.history.push('/setting'); } },
          { text: 'Profile' },
        ]}
      />
      <Heading>
        <PageTitle>Setting</PageTitle>
      </Heading>
      <SettingSidebarMenu selected="/setting/personal-profile/profile">
        <Heading>
          <PageTitle>
            Profile
          </PageTitle>
          {props.user && (
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={(): void => { props.history.push('/setting/personal-profile/profile/edit'); }}
              icon={<FontAwesomeIcon icon={faEdit} />}
            >
              Edit
            </StyledButton>
          )}
        </Heading>
        <CustomSeparator />
        {props.user && (
          <FieldColumn>
            <ProfileIconContainer>
              <ProfileIcon
                icon={faUser}
              />
            </ProfileIconContainer>
            <FieldView
              fieldName="Name"
            >
              {props.user.name}
            </FieldView>
            <FieldView
              fieldName="Email"
            >
              {props.user.email}
            </FieldView>
            <FieldView
              fieldName="Mobile No."
            >
              {props.user.phone_number}
            </FieldView>
            {/* <FieldView
              fieldName="Time Zone"
            /> */}
          </FieldColumn>
        )}
      </SettingSidebarMenu>
    </MainContainer>
  );
};

const ProfileIcon = styled(FontAwesomeIcon)`
  background-color: ${COLOR.cyan};
  color: ${COLOR.white};
  height: 4.25rem;
  width: 4.25rem !important;
  padding: 1.5rem;
  border-radius: 50%;
`;

const ProfileIconContainer = styled.div`
  height: 6.25rem;
  width: 6.25rem;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem !important;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldColumn = styled(Column)`
  flex: 1;
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  };
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
`;

function mapStateToProps(state: RootState): ProfileProps {
  return {
    user: state.auth.currentUser.user,
  };
}

export default connect(mapStateToProps)(ProfileIndex);