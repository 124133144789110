import * as React from 'react';
import styled, {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
  css,
} from 'styled-components';

const timelineInfoColorStyles = {
  defaultColor: css`
    color: ${(props): string => props.theme.colors.gray_700};
  `,
  defaultBgColor: css`
    background-color: ${(props): string => props.theme.colors.gray_100};
  `,
  pickupTypeToPickupColor: css`
    color: ${(props): string => props.theme.colors.warning_700};
  `,
  pickupTypeFailPickupColor: css`
    color: ${(props): string => props.theme.colors.error_700};
  `,
  pickupTypeToDeliverColor: css`
    color: ${(props): string => props.theme.colors.success_700};
  `,
  deliverTypeToDeliverColor: css`
    color: ${(props): string => props.theme.colors.warning_700};
  `,
  deliveryTypeFailDeliverColor: css`
    color: ${(props): string => props.theme.colors.error_700};
  `,
  deliveryTypeCompleteColor: css`
    color: ${(props): string => props.theme.colors.success_700};
  `,
  pickupTypeToPickupBgColor: css`
    background-color: ${(props): string => props.theme.colors.warning_50};
  `,
  pickupTypeFailPickupBgColor: css`
    background-color: ${(props): string => props.theme.colors.error_50};
  `,
  pickupTypeToDeliverBgColor: css`
    background-color: ${(props): string => props.theme.colors.success_50};
  `,
  deliverTypeToDeliverBgColor: css`
    background-color: ${(props): string => props.theme.colors.warning_50};
  `,
  deliveryTypeFailDeliverBgColor: css`
    background-color: ${(props): string => props.theme.colors.error_50};
  `,
  deliveryTypeCompleteBgColor: css`
    background-color: ${(props): string => props.theme.colors.success_50};
  `,
};

interface InfoTimelineProps {
  type: 'pickup' | 'delivery';
  currentStatus: string;
}

const TimelineInfo = (props: InfoTimelineProps): JSX.Element => {
  const { type, currentStatus } = props;
  let timelineColor = timelineInfoColorStyles.defaultColor;
  let timelineBgColor = timelineInfoColorStyles.defaultBgColor;
  let timelineInfoText = 'To Pickup';

  if (['pending_pickup', 'pickup_arriving'].includes(currentStatus)) {
    if (type === 'pickup') {
      timelineColor = timelineInfoColorStyles.pickupTypeToPickupColor;
      timelineBgColor = timelineInfoColorStyles.pickupTypeToPickupBgColor;
      timelineInfoText = 'To Pickup';
    } else if (type === 'delivery') {
      timelineInfoText = 'To Deliver';
    }
  } else if (['pending_delivery', 'delivery_arriving'].includes(currentStatus)) {
    if (type === 'delivery') {
      timelineColor = timelineInfoColorStyles.deliverTypeToDeliverColor;
      timelineBgColor = timelineInfoColorStyles.deliverTypeToDeliverBgColor;
      timelineInfoText = 'To Deliver';
    } else if (type === 'pickup') {
      timelineColor = timelineInfoColorStyles.pickupTypeToDeliverColor;
      timelineBgColor = timelineInfoColorStyles.pickupTypeToDeliverBgColor;
      timelineInfoText = 'Pickup Successful';
    }
  } else if (currentStatus === 'unsuccessful_pickup') {
    if (type === 'delivery') {
      timelineColor = timelineInfoColorStyles.defaultColor;
      timelineBgColor = timelineInfoColorStyles.defaultBgColor;
      timelineInfoText = 'To Deliver';
    } else if (type === 'pickup') {
      timelineColor = timelineInfoColorStyles.pickupTypeFailPickupColor;
      timelineBgColor = timelineInfoColorStyles.pickupTypeFailPickupBgColor;
      timelineInfoText = 'Pickup Unsuccessful';
    }
  } else if (currentStatus === 'unsuccessful_delivery') {
    if (type === 'delivery') {
      timelineColor = timelineInfoColorStyles.deliveryTypeFailDeliverColor;
      timelineBgColor = timelineInfoColorStyles.deliveryTypeFailDeliverBgColor;
      timelineInfoText = 'Delivery Unsuccessful';
    } else if (type === 'pickup') {
      timelineColor = timelineInfoColorStyles.pickupTypeToDeliverColor;
      timelineBgColor = timelineInfoColorStyles.pickupTypeToDeliverBgColor;
      timelineInfoText = 'Pickup Successful';
    }
  } else if (currentStatus === 'complete') {
    if (type === 'delivery') {
      timelineColor = timelineInfoColorStyles.deliveryTypeCompleteColor;
      timelineBgColor = timelineInfoColorStyles.deliveryTypeCompleteBgColor;
      timelineInfoText = 'Delivery Successful';
    } else if (type === 'pickup') {
      timelineColor = timelineInfoColorStyles.pickupTypeToDeliverColor;
      timelineBgColor = timelineInfoColorStyles.pickupTypeToDeliverBgColor;
      timelineInfoText = 'Pickup Successful';
    }
  } else if (type === 'delivery') {
    timelineInfoText = 'To Deliver';
  }

  return (
    <div>
      <TimelineInfoContainer>
        <TimelineInfoBadge timelineInfoBadgeColor={timelineBgColor}>
          <TimelineInfoText timelineInfoTextColor={timelineColor}>
            {timelineInfoText}
          </TimelineInfoText>
        </TimelineInfoBadge>
      </TimelineInfoContainer>
    </div>
  );
};

const TimelineInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface TimelineInfoBadgeProps {
  timelineInfoBadgeColor: FlattenInterpolation<ThemeProps<DefaultTheme>>;
}

const TimelineInfoBadge = styled.div<TimelineInfoBadgeProps>`
  display: inline-flex;
  gap: 0.25rem;
  flex-wrap: nowrap;
  padding: 0.25rem 0.625rem;
  border-radius: 1rem;
  ${(props): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    props.timelineInfoBadgeColor};
`;

interface TimelineInfoTextProps {
  timelineInfoTextColor: FlattenInterpolation<ThemeProps<DefaultTheme>>;
}

const TimelineInfoText = styled.span<TimelineInfoTextProps>`
  display: inline-block;
  font-size: ${(props): string => props.theme.fontSizes.xs};
  font-weight: ${(props): number => props.theme.fontWeights.semibold};
  ${(props): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    props.timelineInfoTextColor};
`;

export default TimelineInfo;
