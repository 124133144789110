import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {
  StyledButton, Alert,
  Separator as BasicSeparator,
} from 'components';
import COLOR from 'constants/color';

interface IconProps {
  color: string;
}

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const Icon = styled(FontAwesomeIcon) <IconProps>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.375rem;
`;

export const ActionIcon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.5rem;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  color: ${COLOR.darkGray};
`;

export const Row = styled.div`
  margin-bottom: 1rem;
`;

export const Button = styled(StyledButton)`
  margin-right: 0.5rem;
  flex: none;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const TopButtonContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

export const InlineErrorMessage = styled.div`
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

export const AlertMessage = styled(Alert)`
  margin-bottom: 0.4rem;
`;

export const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  margin-right: 0.35rem;
`;

export const RemoveButton = styled(StyledButton)`
  margin-right: 0.5rem;
  border-width: 0;
  visibility: hidden;
  tr:hover & {
    visibility: visible;
    background-color: ${COLOR.white};
  }
`;

export const CancelButton = styled(StyledButton)`
  margin-right: 1rem;
`;

export const SidebarMenuContainer = styled.div`
  display: flex;
  margin-top: 0.40rem;
  margin-bottom: 1.5rem;
`;

export const SidebarInfoContainer = styled.div`
  margin-left: 0.6875rem;
`;

export const SidebarName = styled.div`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.3rem;
`;

export const SidebarCreatedDate = styled.div`
  font-size: 1rem;
  margin-bottom: 0.3rem;
`;

export const SidebarUpdatedBy = styled.div`
  font-size: 1rem;
`;

export const Separator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormInput = styled.div`
  margin-top: 1rem;
`;

export const FormHeading = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

export const FormContent = styled.div`
  margin-top: 0.25rem;
  color: ${COLOR.darkGray};
`;

export const TextInputContainer = styled.div`
  @media only screen and (min-width: 768px) and (min-device-width: 768px){
    width: 100%;
  }

  @media only screen and (min-width: 992px){
    width: 50%;
  }

  @media only screen and (min-width: 1200px) {
    width: 30%;
  }
`;

export const SaveVesselCheckbox = styled.div`
  margin-top: 1rem;
`;

export const SortDropdownContainer = styled.div`
  width: 13rem;
`;
