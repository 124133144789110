import {
  RunsheetGenerationState,
  RunsheetGenerationActionTypes,
  RECEIVE_RUNSHEET_GENERATION_JOBS,
  RECEIVE_RUNSHEET_GENERATION_JOB_DETAIL,
} from './runsheetGenerationTypes';
import { RunsheetGenerationJob } from 'models/runsheetGeneration';

const initialState: RunsheetGenerationState = {
  runsheetGenerationJobHash: {},
  runsheetGenerationIds: [],
  runsheetGenerationJob: null,
};

function combineJob(state: RunsheetGenerationState, job: RunsheetGenerationJob | RunsheetGenerationJob[]):
  typeof state.runsheetGenerationJobHash {
  const combinedJobs = { ...state.runsheetGenerationJobHash };

  if (job instanceof Array) {
    job.forEach(elem => {
      combinedJobs[elem.id] = {
        ...state.runsheetGenerationJobHash[elem.id],
        ...elem,
      };
    });
  } else {
    combinedJobs[job.id] = {
      ...state.runsheetGenerationJobHash[job.id],
      ...job,
    };
  }

  return combinedJobs;
}

function runsheetGenerationReducer(
  state: RunsheetGenerationState = initialState, action: RunsheetGenerationActionTypes
): RunsheetGenerationState {
  switch (action.type) {
    case RECEIVE_RUNSHEET_GENERATION_JOBS:
      return {
        ...state,
        runsheetGenerationIds: action.runsheetGenerationJobs.map(data => data.id),
        runsheetGenerationJobHash: combineJob(state, action.runsheetGenerationJobs),
    };
    case RECEIVE_RUNSHEET_GENERATION_JOB_DETAIL:
      return {
        ...state,
        runsheetGenerationJob: action.runsheetGenerationJob,
    };
    default:
      return state;
  }
}

export default runsheetGenerationReducer;