import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Table, TableCellRenderEvent, Dropdown,
  StyledButton, Message, SysAdminPagination,
  PaginationState, TextInput,
} from 'components';
import { RootState } from 'reduxActions/store';
import styled, { css } from 'styled-components';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

import { UserWithTimestamp } from 'models/auth';
import { Organization } from 'models/organization';
import AuthClient from 'httpClients/authClient';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import * as H from 'history';
import configureStore from 'reduxActions/store';
import { receiveOrganizations } from 'reduxActions/organization/organizationActions';
import COLOR from 'constants/color';

interface UserIndexProps {
  models: UserWithTimestamp[];
  organizations: Organization[];
}

interface UserIndexState extends PaginationState<UserWithTimestamp> {
  error: string | null;
  search: string;
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

type Props = UserIndexProps & HistoryProps;

class UserIndex extends SysAdminPagination<UserWithTimestamp, Props, UserIndexState> {
  searchTimeout: number | null | NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      basePath: '/sys/users',
      filters: this.getDefaultFilter(),
      pluralModelName: 'users',
      selectedTab: 'users',
      error: null,
      search: '',
    };

    this.rules = [{ name: 'page' }, {
      name: 'org_id',
      validate: (param: string): boolean => {
        const { organizations } = this.props;
        const orgIds = organizations.map(data => data.id);

        return organizations.length === 0 || orgIds.includes(param);
      },
    }];
  }

  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
  })

  componentDidMount(): void {
    super.componentDidMount();
    this.fetchOrganizations();
  }

  fetchOrganizations = async (): Promise<void> => {
    let pageNum = 1;
    let allFetched = false;
    const organizations: Organization[] = [];
    const client = new OrganizationManagementClient();
    try {
      while (!allFetched) {
        const results = await client.sysAdminGetOrganizationList(new URLSearchParams({ page: pageNum.toString() }));
        pageNum += 1;
        if (results.organizations.length === 0) {
          allFetched = true;
        } else {
          organizations.push(...results.organizations);
        }
      }
      const store = configureStore();
      store.dispatch(receiveOrganizations(organizations));
    } catch (e) {
      //
    }
  }

  fetchData = async (): Promise<void> => {
    this.setState({ error: null, isFetching: true });
    try {
      const client = new AuthClient();
      await client.sysGetUsers(new URLSearchParams(this.state.filters));
    } catch (error) {
      this.setState({
        error,
        isFetching: false,
      });
    }

    this.setState({ isFetching: false });
  }

  renderActionPanel = (): React.ReactNode => (
    <TopButtonContainer>
      <Button
        buttonStyle="encourage"
        buttonType="primary"
        onClick={(): void => { this.props.history.push('/sys/invitation'); }}
        fontWeight={400}
      >
        Invite User
      </Button>
    </TopButtonContainer>
  )

  searchUsers = (): void => {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      const value = this.state.search;
      const isDelete = value === '';
      this.onFilterChange('search', value, isDelete);
    }, 700);
  }

  renderCell = (e: TableCellRenderEvent<{ action: string }>): React.ReactNode => {
    if (e.key === 'action') {
      return (
        <div>
          <Link
            to={`/sys/users/${e.value.toString()}/role`}
          >
            <Button
              buttonStyle='encourage'
              buttonType='neutral'
              fontWeight={400}
            >Add Role to User</Button>
          </Link>
        </div>
      );
    }

    return <>{e.value}</>;
  };

  renderFilter = (): React.ReactNode => (
    <FilterContainer>
      <TextInput
        icon={(this.state.search || '').length === 0 ? faSearch : faTimes}
        iconOnClick={(): void => {
          this.setState({ search: '' });
          this.searchUsers();
        }}
        // iconStyle={css`
        //   top: -1.6rem;
        //   right: 1rem;
        //   color: ${COLOR.midDarkGrey};
        // `}
        iconStyle={{
          top: '-1.6rem',
          right: '1rem',
          color: `${COLOR.midDarkGrey}`,
        }}
        height="small"
        width="auto"
        onTextChange={(value): void => {
          this.setState({ search: value });
          this.searchUsers();
        }}
        placeholder="Search..."
        value={this.state.search}
      />
      <Dropdown
        containerStyle={css`
          margin: 0 0.5rem;
        `}
        label="Organization"
        includeAll
        onChange={(value: string): void => {
          const isDelete = value === '';
          this.onFilterChange('org_id', value, isDelete);
        }}
        options={this.props.organizations.map((org: Organization) => ({
          name: org.business_name,
          value: org.id,
        }))}
        value={this.state.filters.org_id || ''}
        withSearch
        sorted
      />
    </FilterContainer>
  )

  renderContent = (): React.ReactNode => {
    const { error } = this.state;

    const columns = {
      'id': 'User ID',
      'name': 'Name',
      'email': 'Email',
      'createdAt': 'Created At',
      'action': 'Actions',
    };

    // TODO: This might need to change since auth service is not syncing the role data
    const data = this.props.models.map(user => ({
      id: user.id,
      name: user.name,
      email: user.email.includes('+deleted-') ? user.email.split('+deleted-')[0] + ' (deleted)' : user.email,
      createdAt: user.created_at,
      action: user.id,
    }));

    return (
      <>
        {error && (
          <Message className='error'>{error}</Message>
        )}
        <Table
          cellRenderer={this.renderCell}
          columns={columns}
          data={data}
          hideNavigationButton
        />
      </>
    );
  }
}

const Button = styled(StyledButton)`
  margin-right: 0.5rem;
  flex: none;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
`;

const TopButtonContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

function mapStateToProps(state: RootState): UserIndexProps {
  return {
    models: state.auth.users,
    organizations: state.organization.organizations,
  };
}

export default connect(mapStateToProps)(UserIndex);