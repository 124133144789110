import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Alert, StyledButton } from 'components';
import COLOR from 'constants/color';

const DEEP_LINK_URL = process.env.DEEP_LINK_URL;

function ConfirmEmail(): JSX.Element {
  const locationObj = useLocation();
  const params = new URLSearchParams(locationObj.search);

  return (
    <Layout>
      <Title>
        Driver Partner <br /> email confirmation
      </Title>
      {params.get('success') === 'true' ? (
        <>
          <Alert status='success'>
            Your account is confirmed.
          </Alert>
          <CustomLinkButton target="_blank" href={DEEP_LINK_URL}>Open GotSurge Driver Partner app</CustomLinkButton>
        </>
      ) : (
        <Alert status='error'>
          <Summary>
            {'Can\'t confirm your account.'}
          </Summary>
          <Paragraph>
            You may have confirmed your account before or the link has expired.
          </Paragraph>
          <Paragraph>
            Try logging in to <CustomLink target='_blank' href={DEEP_LINK_URL}>GotSurge Driver Partner app</CustomLink> or <CustomLink target='_blank' href="https://help.gotsurge.co">get help</CustomLink>.
          </Paragraph>
        </Alert>
      )}
    </Layout>
  );
}

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;
  color: ${COLOR.blue};
  br {
    display: none;
  }

  @media only screen and (max-device-width: 700px), (max-width: 700px) {
    font-size: 7vw;
    br {
      display: initial;
    }
  }

  @media only screen and (max-device-width: 499px), (max-width: 499px) {
    font-size: 9vw;
    br {
      display: initial;
    }
  }
`;

const CustomLink = styled.a`
  text-decoration: none;
  color: ${COLOR.blue};
`;

const CustomLinkButton = styled.a`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: ${COLOR.blue};
  border-radius: 2em;
  border-width: 0px;
  color: ${COLOR.white};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125em;
  height: auto;
  padding: 0.5rem 0rem;
  width: 100%;
  border-style: solid;
`;

const Summary = styled.p`
  margin: 0;
  margin-bottom: 2rem;
`;

const Paragraph = styled.p`
  margin: 0;
`;

const Layout = styled.div`
  padding: 10vh 4rem 4rem;
  margin-left: auto;
  margin-right: auto;

  & > * {
    margin-bottom: 2rem;
  }
`;

export const Button = styled(StyledButton)`
  @media only screen and (max-device-width: 700px), (max-width: 700px) {
    font-size: 3.5vw;
    padding: 1.5rem 2.25rem;
  }
`;

export default ConfirmEmail;