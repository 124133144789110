import { DriverProfile, DriverProfileWithSquad } from 'models/driverProfile';

export interface DriverProfileState {
  profiles: DriverProfile[];
  profile: DriverProfile | null;
  profilesWithSquad: DriverProfileWithSquad[];
}

export const RECEIVE_DRIVER_PROFILES = 'RECEIVE_DRIVER_PROFILES';
export const RECEIVE_DRIVER_PROFILE = 'RECEIVE_DRIVER_PROFILE';
export const RECEIVE_DRIVER_PROFILES_WITH_SQUAD = 'RECEIVE_DRIVER_PROFILES_WITH_SQUAD';

export interface ReceiveDriverProfiles {
  type: typeof RECEIVE_DRIVER_PROFILES;
  profiles: DriverProfile[];
}

export interface ReceiveDriverProfile {
  type: typeof RECEIVE_DRIVER_PROFILE;
  profile: DriverProfile;
}

export interface ReceiveDriverProfileWithSquad {
  type: typeof RECEIVE_DRIVER_PROFILES_WITH_SQUAD;
  profiles: DriverProfileWithSquad[];
}

export type DriverProfileActionTypes = ReceiveDriverProfiles | ReceiveDriverProfile | ReceiveDriverProfileWithSquad;