import * as React from 'react';
import styled from 'styled-components';

interface HorizontalBarProps {
  color: string;
}

const HorizontalBar = (props: HorizontalBarProps): JSX.Element => {
  return (
    <Container>
      <DarkPart {...props} />
      <LightPart {...props} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 6px;
`;

const DarkPart = styled.div<HorizontalBarProps>`
  border-radius: 120px;
  background-color: ${(props): string => props.color};
  width: 44%;
`;

const LightPart = styled.div<HorizontalBarProps>`
  border-radius: 120px;
  background-color: ${(props): string => props.color};
  width: 56%;
  opacity: 0.19;
`;

export default HorizontalBar;