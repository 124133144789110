import * as React from 'react';
import { CenterModal, Alert } from 'components';
import { TextInput } from 'components';
import styled from 'styled-components';
import COLOR from 'constants/color';
import { formatError } from 'utils/formatter';
import OrganizationDriverClient from 'httpClients/organizationDriverClient';
import AuthClient from 'httpClients/authClient';
import { SysOrgDriverCreateForm } from 'models/organization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const STATE = {
  beforeAdd: 'beforeAdd',
  afterAdd: 'afterAdd',
};

interface AddDriverModalProps {
  orgId: string;
  closeModal: () => void;
  addDriverSuccess: () => void;
}

interface AddDriverModalState {
  name: string;
  error: string;
  pageState: string;
}

class AddDriverModal extends React.Component<AddDriverModalProps, AddDriverModalState> {
  constructor(props: AddDriverModalProps) {
    super(props);

    this.state = {
      name: '',
      error: '',
      pageState: STATE.beforeAdd,
    };
  }

  validateInput = (): boolean => {
    const { name } = this.state;
    if (name == '') {
      return false;
    }
    return true;
  };

  validateEmail = (email: string): boolean => {
    const pattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return pattern.test(email);
  };

  handleAddDriver = async (): Promise<void> => {
    const { name } = this.state;

    const orgDriverClient = new OrganizationDriverClient();
    const authClient = new AuthClient();
    if (name.trim() !== '') {
      try {
        const sysOrgDriverCreateForm: SysOrgDriverCreateForm = {
          name: name,
        };
        const orgDriver = await orgDriverClient.sysAdminAddOrgDriver(
          this.props.orgId,
          sysOrgDriverCreateForm
        );

        await authClient.sysAddOrgDriver({
          user: {
            org_driver_id: orgDriver.id,
            name: orgDriver.name,
            partial_id_chars: orgDriver.partial_id_chars,
            email: `${orgDriver.id.split('-')[0]}@gotsurge-noemail.com`,
            phone_number: '',
            org_id: orgDriver.org_id,
            password: orgDriver.id.split('-')[0],
            vehicle_type: 'lorry_24ft', // TODO: Change later with UI
          },
        });
      } catch (e) {
        this.setState({ error: formatError(e, false) });
        return;
      }
    }

    this.setState({ pageState: STATE.afterAdd });
  };

  renderAfterAdd = (): React.ReactNode => {
    return (
      <CenterModal
        rightButtonOnClick={this.props.addDriverSuccess}
        rightButtonText={'OK'}
        title="Driver Added"
      >
        <AlertMessage status="success">Successfully added!</AlertMessage>
      </CenterModal>
    );
  };

  renderBeforeAdd = (): React.ReactNode => {
    const { name, error } = this.state;
    return (
      <CenterModal
        leftButtonOnClick={this.props.closeModal}
        leftButtonText="Cancel"
        rightButtonOnClick={this.handleAddDriver}
        rightButtonText="OK"
        rightButtonDisabled={!this.validateInput()}
        title="Add driver"
      >
        <AddDriverContainer>
          <LabelEmail>
            <Label>
              Name <Required>*</Required>
            </Label>
          </LabelEmail>
          <NameInputContainer>
            <NameInput
              key={0}
              isRequired
              placeholder="Ashley"
              onTextChange={(value): void => {
                this.setState({ name: value });
              }}
              type="text"
              value={name}
              width="full"
              height="large"
            />
          </NameInputContainer>
          {error !== '' && (
            <InlineErrorMessage>
              <ErrorIcon icon={faExclamationCircle} />
              {this.state.error}
            </InlineErrorMessage>
          )}
        </AddDriverContainer>
      </CenterModal>
    );
  };

  render(): React.ReactNode {
    let content: React.ReactNode;
    if (this.state.pageState === STATE.beforeAdd) {
      content = this.renderBeforeAdd();
    } else if (this.state.pageState === STATE.afterAdd) {
      content = this.renderAfterAdd();
    }
    return content;
  }
}

const AddDriverContainer = styled.div`
  border-bottom: 0.1rem solid ${COLOR.neutral};
`;

const Label = styled.label`
  font-size: 0.875rem;
`;

const LabelEmail = styled.div`
  width: 100%;
  margin-bottom: 0.4rem;
`;

const Required = styled.span`
  color: ${COLOR.red};
`;

const NameInputContainer = styled.div`
  margin-bottom: 1rem;
`;

const NameInput = styled(TextInput)`
  margin-bottom: 0.5rem;
`;

const InlineErrorMessage = styled.div`
  margin-top: -1.2rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const AlertMessage = styled(Alert)`
  margin-bottom: 0.4rem;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  margin-right: 0.35rem;
`;

export default AddDriverModal;
