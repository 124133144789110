import * as React from 'react';
import styled from 'styled-components';
import COLOR from 'constants/color';

interface CardProps {
  children: React.ReactNode;
}

const CardContainer = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${COLOR.neutral};
  padding: 1rem;
  background-color: ${COLOR.white};
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: -webkit-fill-available;

  @media (max-width: 768px) {
    width: -webkit-fill-available;
    margin-right: 0;
  }
`;

const Card = ({ children }: CardProps) => {
  return <CardContainer>{children}</CardContainer>;
};

export default Card;
