import * as React from 'react';
import {
  Separator as BasicSeparator,
  PageTitle,
  SysAdminMainContainer,
  Breadcrumb,
  Heading,
  TextInput,
  Alert,
  StyledButton,
  CenterModal,
  Radio,
  Checkbox,
  FleetTypesCheckbox,
} from 'components';
import { Organization, CompanyDetailsForm } from 'models/organization';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import RoleClient from 'httpClients/roleClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import styled, { css } from 'styled-components';
import defaultUserCompanyLogo from 'assets/images/default_user_company_logo.png';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import SettingSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import { Role } from 'models/role';
import ROLES, { ROLE_DESCRIPTION } from 'constants/role';
import { CategoryAndVehicleType } from 'constants/vehiclePreference';
import NotificationPusherClient from 'httpClients/notificationPusherClient';

const organisationNames = [
  {
    text: ROLE_DESCRIPTION[ROLES.OrgAdmin],
    value: ROLES.OrgAdmin,
  },
  {
    text: ROLE_DESCRIPTION[ROLES.OrgTransporter],
    value: ROLES.OrgTransporter,
  },
];

interface CompanyDetailsProps {
  organization: Organization;
  roles: Role[];
}

type Props = RouteComponentProps<{ id: string }> & CompanyDetailsProps;

interface Form {
  business_name: string;
  prefix_id: string;
  version_rev: string;
  is_qa: boolean;
  fleet_types: CategoryAndVehicleType[];
}

const getDefaultForm = (org: Organization): Form => {
  return {
    business_name: org && org.business_name ? org.business_name : '',
    prefix_id: org && org.prefix_id ? org.prefix_id : 'GOTSURGE-',
    version_rev: org && org.version_rev ? org.version_rev : '',
    is_qa: org && org.is_qa ? org.is_qa : false,
    fleet_types: org && org.fleet_types ? org.fleet_types : [],
  };
};

const SysAdminCompanyDetailsEdit = (props: Props): JSX.Element => {
  React.useEffect(() => {
    (async function (): Promise<void> {
      const client = new OrganizationManagementClient();
      await client.sysAdminGetOrganization(props.match.params.id);
      const roleClient = new RoleClient();
      await roleClient.getRoles(props.match.params.id);
    })();
  }, []);

  const { organization, roles } = props;
  const isAdminRoles = roles.find((role) => role.role_name === ROLES.OrgAdmin);
  const isTransporterRoles = roles.find(
    (role) => role.role_name === ROLES.OrgTransporter
  );
  const role = isAdminRoles ? ROLES.OrgAdmin : ROLES.OrgTransporter;
  const [form, setForm] = React.useState(getDefaultForm(organization));
  const [selectedRole, setSelectedRole] = React.useState(role);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  const pattern = new RegExp(/\w*[a-zA-Z0-9]\w*/);
  const onChangeRole = (role: string) => {
    if (role === ROLES.OrgAdmin) {
      handleChange('fleet_types', []);
    } else if (role === ROLES.OrgTransporter) {
      handleChange('fleet_types', organization.fleet_types || []);
    }
    setSelectedRole(role);
  };

  React.useEffect(() => {
    setForm(getDefaultForm(organization));
  }, [organization]);

  const handleChange = (
    fieldName: string,
    value: string | boolean | CategoryAndVehicleType[]
  ): void => {
    if (fieldName === 'fleet_types' && Array.isArray(value)) {
      const filteredValue = (value as CategoryAndVehicleType[]).filter(
        (item) => item !== 'sea' && item !== 'land'
      );
      value = filteredValue;
    }
    setForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    setError('');
  };

  const save = async (props: Props, form: CompanyDetailsForm): Promise<void> => {
    setError('');
    try {
      setFetching(true);
      const client = new OrganizationManagementClient();
      if (selectedRole === ROLES.OrgAdmin && 'fleet_types' in form) {
        // delete fleet_types
        delete form.fleet_types;
      }
      await client.sysUpdateCompanyDetails(props.match.params.id, form);
      if (isAdminRoles || isTransporterRoles) {
        const roleClient = new RoleClient();
        await roleClient.updateOrganizationRole(props.match.params.id, {
          role_name: selectedRole,
        });
      }
      if (isTransporterRoles) {
        // publish event to transport-partner within the organization
        const npClient = new NotificationPusherClient();
        npClient.pushEvents({
          events: [
            {
              id: props.match.params.id,
              channel: `gotsurge-orgid-${props.match.params.id}`,
              name: 'organization-management',
              data: {
                org_id: props.match.params.id,
              },
            },
          ],
        });
      }
      setSuccess(true);
    } catch (e) {
      setError(e);
    }
    setFetching(false);
  };

  return (
    <SysAdminMainContainer selected="organizations">
      <Breadcrumb
        items={[
          {
            text: 'Organizations',
            onClick: (): void => {
              props.history.push('/sys/organizations');
            },
          },
          {
            text: props.match.params.id,
            onClick: (): void => {
              props.history.push(
                '/sys/organizations/' + props.match.params.id + '/company-details'
              );
            },
          },
          { text: 'Company Details' },
        ]}
      />
      <Heading>
        <PageTitle>Organisations Info</PageTitle>
      </Heading>
      <SettingSidebarMenu
        selected={`/sys/organizations/${props.match.params.id}/company-details`}
      >
        <Heading>
          <PageTitle>Company Details</PageTitle>
          {organization && (
            <>
              <EditButtonContainer>
                <StyledButton
                  buttonStyle="encourage"
                  buttonType="neutral"
                  onClick={(): void => {
                    props.history.push(
                      `/sys/organizations/${props.match.params.id}/company-details`
                    );
                  }}
                  disabled={fetching}
                >
                  <Icon icon={faTimes} />
                  Cancel
                </StyledButton>
                <StyledButton
                  buttonStyle="encourage"
                  buttonType="primary"
                  disabled={
                    (form.business_name && form.business_name.length < 2) ||
                    !pattern.test(form.business_name) ||
                    (form.business_name && form.business_name.trim() === '') ||
                    form.prefix_id.trim() === '' ||
                    ((isAdminRoles || isTransporterRoles) &&
                      selectedRole === ROLES.OrgTransporter &&
                      form.fleet_types.length === 0) ||
                    fetching
                  }
                  onClick={(): void => {
                    save(props, { ...form, version_rev: organization.version_rev });
                  }}
                >
                  <Icon icon={faCheck} color={COLOR.white} />
                  Save
                </StyledButton>
              </EditButtonContainer>
            </>
          )}
        </Heading>
        <Separator />
        {error ? (
          <ErrorMessage status="error">
            <AlertMessage>{error}</AlertMessage>
          </ErrorMessage>
        ) : (
          false
        )}
        {organization && (
          <FormContainer>
            <CompanyImageContainer>
              <CompanyImage src={defaultUserCompanyLogo} />
            </CompanyImageContainer>
            <FormInput>
              <TextInputContainer>
                <TextInput
                  fieldName="Company Name"
                  isRequired
                  name="business_name"
                  onTextChange={(value: string): void =>
                    handleChange('business_name', value)
                  }
                  type="text"
                  width="auto"
                  value={form && form.business_name}
                  required
                />
              </TextInputContainer>
            </FormInput>
            <CheckboxIsQA>
              <Checkbox
                onClick={(): void => handleChange('is_qa', !form.is_qa)}
                selected={form && form.is_qa}
                disabled={organization.id === 'VersaFleet'}
              >
                Test
              </Checkbox>
            </CheckboxIsQA>
            {isAdminRoles || isTransporterRoles ? (
              <FormInput>
                <Label>What do you primarily want to use GotSurge for?</Label>
                <Radio
                  options={organisationNames}
                  value={selectedRole}
                  onChange={(v: string): void => {
                    onChangeRole(v);
                  }}
                />
                <FleetTypesCheckbox
                  containerStyle={css`
                    margin-top: 0.5rem;
                    margin-left: 1.8rem;
                  `}
                  fieldName="Types of fleet"
                  isRequired
                  value={form.fleet_types}
                  onChange={(fleets: CategoryAndVehicleType[]) =>
                    handleChange('fleet_types', fleets)
                  }
                  disabled={selectedRole === ROLES.OrgAdmin}
                />
              </FormInput>
            ) : (
              false
            )}
            <FormInput>
              <TextInputContainer>
                <TextInput
                  fieldName="Reference ID Prefix"
                  fieldDescription="Enter your reference ID Prefix"
                  name="prefix_id"
                  onTextChange={(value: string): void => handleChange('prefix_id', value)}
                  type="text"
                  width="auto"
                  value={form && form.prefix_id && form.prefix_id}
                  required
                  disabled={organization.id === 'VersaFleet'}
                />
              </TextInputContainer>
            </FormInput>
          </FormContainer>
        )}
        {success ? (
          <CenterModal
            rightButtonText="OK"
            rightButtonOnClick={(): void => {
              props.history.push(
                `/sys/organizations/${props.match.params.id}/company-details`
              );
            }}
            title="Organization Settings"
          >
            <Alert status="success">
              <AlertMessage>Saved</AlertMessage>
            </Alert>
          </CenterModal>
        ) : (
          false
        )}
      </SettingSidebarMenu>
    </SysAdminMainContainer>
  );
};

const Separator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyImageContainer = styled.div`
  display: flex;
  margin-top: 1.875rem;
`;

const CompanyImage = styled.img`
  width: 5.625rem;
  height: 5.625rem;
`;

const FormInput = styled.div`
  margin-top: 1rem;
`;

const TextInputContainer = styled.div`
  @media only screen and (min-width: 768px) and (min-device-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 992px) {
    width: 50%;
  }

  @media only screen and (min-width: 1200px) {
    width: 30%;
  }
`;

const AlertMessage = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

const ErrorMessage = styled(Alert)`
  margin-top: 1rem;
`;

const EditButtonContainer = styled.div`
  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

const Icon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.5rem;
`;

const Label = styled.div`
  margin-bottom: 0.5rem;
`;

const CheckboxIsQA = styled.div`
  margin: 0.6rem;
`;

function mapStateToProps(state: RootState): CompanyDetailsProps {
  return {
    organization: state.organization.organization,
    roles: state.role.roles,
  };
}

export default connect(mapStateToProps)(SysAdminCompanyDetailsEdit);
