import * as React from 'react';
import styled from 'styled-components';

interface CollapsibleProps {
  children: React.ReactNode;
  title: (state: { open: boolean }) => JSX.Element;
}

const Collapsible = ({ children, title }: CollapsibleProps): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const toggle = (): void => {
    setOpen(!open);
  };
  const Title = React.cloneElement(title({ open }), { onClick: toggle });

  return (
    <>
      {Title}
      {open && <CollapsibleContent>{children}</CollapsibleContent>}
    </>
  );
};

const CollapsibleContent = styled.div`
  margin-top: 1rem;
`;

export default Collapsible;
