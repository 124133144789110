import * as React from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { WIDTHS } from './input/basicTextInput';
import { StyledButton } from 'components';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import COLOR from 'constants/color';

interface DisplayFieldProps {
  label?: string;
  title: string;
  text: string;
  isRequired?: boolean;
  onButtonClick?: () => void;
  width?: keyof typeof WIDTHS;
  containerStyle?: FlattenSimpleInterpolation;
  disabled?: boolean;
}

class DisplayField extends React.Component<DisplayFieldProps> {
  render(): React.ReactNode {
    return (
      <Container containerStyle={this.props.containerStyle}>
        {this.props.label ? (
          <Label>
            {this.props.label} {this.props.isRequired && <RedText>*</RedText>}
          </Label>
        ) : (
          false
        )}
        <FieldContainer width={this.props.width}>
          <TextContainer>
            <Title>{this.props.title}</Title>
            <SubTitle>{this.props.text}</SubTitle>
          </TextContainer>
          <ButtonContainer>
            <Button
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={(): void => {
                this.props.onButtonClick && this.props.onButtonClick();
              }}
              type="button"
              disabled={this.props.disabled}
            >
              <FontAwesomeIcon icon={faPencilAlt} style={{ color: COLOR.black }} />
            </Button>
          </ButtonContainer>
        </FieldContainer>
      </Container>
    );
  }
}

const Container = styled.div<{ containerStyle?: FlattenSimpleInterpolation }>`
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
`;

const Label = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-right: auto;
  color: ${(props): string => props.theme.colors.gray_900};
  margin-bottom: 0.25rem;
`;

const RedText = styled.span`
  color: ${(props): string => props.theme.colors.error_500};
`;

const FieldContainer = styled.div<{ width?: keyof typeof WIDTHS }>`
  /* width: ${(props): string => (props.width ? WIDTHS[props.width] : WIDTHS.full)}; */
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 0.75rem;
  ${(props) => {
    return css`
      background-color: ${props.theme.colors.gray_50};
    `;
  }}

  @media (min-width: 768px) {
    width: ${(props): string => (props.width ? WIDTHS[props.width] : WIDTHS.full)};
  }
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div``;

const SubTitle = styled.div`
  font-size: 1rem;
  color: ${COLOR.midDarkGrey};
`;

const ButtonContainer = styled.div`
  font-size: 1rem;
  color: ${COLOR.black};
`;

const Button = styled(StyledButton)`
  padding: 7px 7px;
  box-shadow: none;

  :disabled {
    opacity: 0.4;
  }
`;

export default DisplayField;
