import COLOR from './color';

interface Status {
  [key: string]: string;
}

interface StatusColor {
  [key: string]: string;
}

const STATUS: Status = {
  'pending_broadcast': 'Pending Broadcast',
  'broadcasting': 'Broadcasting',
  'accepted': 'Accepted',
  'started': 'Started',
  'done': 'Done',
  'expired': 'Expired',
  'cancelled': 'Cancelled',
};

export const STATUS_DESCRIPTION: Status = {
  'pending_broadcast': 'Pending Broadcast',
  'broadcasting': 'Broadcasting',
  'accepted': 'Accepted',
  'started': 'Started',
  'done': 'Done',
  'expired': 'Expired',
  'cancelled': 'Cancelled',
};

export const STATUS_COLOR: StatusColor = {
  'pending_broadcast': COLOR.red,
  'broadcasting': COLOR.blue,
  'started': COLOR.yellow,
  'accepted': COLOR.yellow,
  'done': COLOR.cyan,
  'expired': COLOR.rouge,
  'cancelled': COLOR.red,
};

export default STATUS;