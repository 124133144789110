import {
  RECEIVE_PAIR_RECORD,
  BroadcastRecordActionTypes,
  BroadcastRecordState,
} from './broadcastRecordTypes';

const initialState: BroadcastRecordState = {
  pairRecord: null,
};

function broadcastRecordReducer(
  state = initialState,
  action: BroadcastRecordActionTypes
): BroadcastRecordState {
  switch(action.type) {
    case RECEIVE_PAIR_RECORD:
      return {
        ...state,
        pairRecord: action.pairRecord,
      };
    default:
      return state;
  }
}

export default broadcastRecordReducer;