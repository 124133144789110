import { RunsheetReadyToTransferActionTypes, RECEIVE_RUNSHEET_READY_TO_TRANSFERS, RECEIVE_RUNSHEET_READY_TO_TRANSFER, RECEIVE_RUNSHEET_READY_TO_TRANSFER_LOGS } from './runsheetReadyToTransferTypes';
import { RunsheetReadyToTransfer, RunsheetReadyToTransferLog } from 'models/runsheetReadyToTransfer';


export function receiveRunsheetReadyToTransferLogAllSuccess(runsheetReadyToTransferLogs: RunsheetReadyToTransferLog[]): RunsheetReadyToTransferActionTypes {
  return {
    type: RECEIVE_RUNSHEET_READY_TO_TRANSFER_LOGS,
    runsheetReadyToTransferLogs,
  };
}

export function receiveRunsheetReadyToTransferAllSuccess(runsheetReadyToTransfers: RunsheetReadyToTransfer[]): RunsheetReadyToTransferActionTypes {
  return {
    type: RECEIVE_RUNSHEET_READY_TO_TRANSFERS,
    runsheetReadyToTransfers,
  };
}

export function receiveRunsheetReadyToTransfer(runsheetReadyToTransfer: RunsheetReadyToTransfer): RunsheetReadyToTransferActionTypes {
  return {
    type: RECEIVE_RUNSHEET_READY_TO_TRANSFER,
    runsheetReadyToTransfer,
  };
}