import * as React from 'react';
import styled, { css } from 'styled-components';
import COLOR from 'constants/color';
import {
  faExclamationCircle,
  faSearch,
  faCheck,
  faTimes,
  faBookmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

import {
  CenterModal,
  TextInput,
  Separator,
  Searchbox,
  Checkbox,
  Radio,
  LocationDetailModal,
  SavedAddressButton,
} from 'components';
import configureStore, { RootState } from 'reduxActions/store';
import { receiveGeoDatas, receiveMarineLocationDatas } from 'reduxActions/geoService/geoServiceActions';
import { GeoData, GeoDataContact, SavedGeoData } from 'models/geoService';
import { Address, Contact } from 'models/task';
import GeoServiceClient from 'httpClients/geoServiceClient';
import LocateMapModal from '../../../order/modal/locateMapModal';
import BROADCAST_PREFERENCES from 'constants/broadcastPreference';
import { MarineLocation } from 'models/marineLocation';

export type ShowSection = 'address_details' | 'contact_details' | 'sea_address' | false;

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

interface StateProps {
  geoDatas: GeoData[];
  savedGeoDatas: SavedGeoData[];
  marineLocationDatas: MarineLocation[];
}

export interface AddressContactData {
  address: Address;
  contact: Contact;
  saved_address_name?: string;
  is_default_enabled?: boolean;
  is_default_pickup?: boolean;
  is_default_delivery?: boolean;
  org_id?: string;
}

interface AddressContactModalProps {
  title?: string;
  closeModal: () => void;
  onConfirm: (data: AddressContactData) => void;
  onComponentAddressDetail?: React.ReactNode;
  header?: React.ReactNode;
  shareUrl?: string;
  defaultForm: AddressContactData;
  isHideSavedAddress?: boolean;
  type: 'ops' | 'merchant';
  broadcastPreferences?: string;
  showOnly?: ShowSection;
}

interface AddressContactModalState {
  address: Address;
  contact: Contact;
  saved_address_name?: string;
  is_default_enabled?: boolean;
  is_default_pickup?: boolean;
  is_default_delivery?: boolean;
  error: {
    saved_address_name: string;
  };
  showLocateMapModal: boolean;
}

type Props = AddressContactModalProps & StateProps;

class AddressContactModal extends React.Component<Props, AddressContactModalState> {
  searchPickupTimeout: number | null | NodeJS.Timeout;
  shareContent: React.RefObject<HTMLDivElement>;
  filteredAnchorages: GeoDataContact[];
  anchorages: GeoDataContact[];
  constructor(props: Props) {
    super(props);

    this.state = {
      address: this.props.defaultForm.address,
      contact: this.props.defaultForm.contact,
      saved_address_name: this.props.defaultForm.saved_address_name,
      is_default_enabled:
        this.props.defaultForm.is_default_pickup ||
        this.props.defaultForm.is_default_delivery,
      is_default_pickup: this.props.defaultForm.is_default_pickup,
      is_default_delivery: this.props.defaultForm.is_default_delivery,
      error: {
        saved_address_name: '',
      },
      showLocateMapModal: false,
    };
    this.shareContent = React.createRef();
    this.filteredAnchorages = [];
  }

  componentDidMount() {
    this.loadAnchorages();
    this.fetchMarineLocations();
  }

  loadAnchorages = async () => {
    if (this.props.broadcastPreferences === BROADCAST_PREFERENCES.marine) {
      const anchoragesAddress = await import('assets/anchorages.json');
      const geoDataAnchorages = anchoragesAddress;
      this.anchorages = geoDataAnchorages.geo_datas;
    }
  };

  onSelectAddress = (geoData: GeoDataContact): void => {
    this.selectAddress(geoData.street_address, geoData);
  };

  selectAddress = (streetAddress: string, geoData: GeoDataContact): void => {
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        street_address: streetAddress,
        building_name: geoData.building_name,
        name_address: geoData.name_address,
        latitude: geoData.lat,
        longitude: geoData.lng,
        zip_code: geoData.zip_code,
      },
    }));
    // Set Address from saved address
    if (geoData.name_address && geoData.name_address !== '') {
      const isUnitNumber = geoData.unit_number && geoData.unit_number !== '';
      this.setState((prevState) => ({
        address: {
          ...prevState.address,
          unit_number: isUnitNumber ? geoData.unit_number : '',
        },
      }));
      const isContact = geoData.contact_name && geoData.contact_name !== '';
      this.setState((prevState) => ({
        contact: {
          ...prevState.contact,
          name: isContact ? geoData.contact_name : '',
          phone: isContact ? geoData.contact_phone : '',
          email: isContact ? geoData.contact_email : '',
          street_address: isContact ? streetAddress : '',
        },
      }));
    }
  };

  selectMarineAddress = (streetAddress: string, geoData: GeoDataContact): void => {
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        street_address: streetAddress,
        building_name: geoData.building_name,
        name_address: geoData.name_address,
        latitude: geoData.lat,
        longitude: geoData.lng,
        zip_code: geoData.zip_code,
      },
    }));
  };

  fetchAddresses = async (searchKey: string): Promise<void> => {
    this.clearAddresses();
    const client = new GeoServiceClient();
    if (!this.props.isHideSavedAddress) {
      try {
        this.props.type === 'merchant'
          ? await client.getSavedAddress(new URLSearchParams())
          : await client.getSysSavedAddress(
              new URLSearchParams({
                org_id: this.props.defaultForm.org_id,
              })
            );
      } catch (error) {
        // continue to if no saved address
      }
    }
    if (searchKey && searchKey.length >= 3) {
      await client.getGeoCode(searchKey);
    }
  };

  fetchMarineLocations = async (searchKey: string = ''): Promise<void> => {
    try {
      const client = new GeoServiceClient();
      if (searchKey && searchKey.length >= 3) {
        await client.getMarineLocation(
          new URLSearchParams({
            term: searchKey,
          })
        );
      }
    } catch (error) {
      throw error.message;
    }
  };

  isLocationValid = (geoData: GeoData): boolean => {
    return (
      geoData.street_address !== '' &&
      geoData.lat !== 0 &&
      geoData.lng !== 0 &&
      geoData.lat !== undefined &&
      geoData.lng !== undefined
    );
  };

  isAddressLocationValid = (address: Address): boolean =>
    this.isLocationValid({
      street_address: address.street_address,
      zip_code: address.zip_code,
      lat: address.latitude,
      lng: address.longitude,
    });

  clearAddresses = (): void => {
    const store = configureStore();
    store.dispatch(receiveGeoDatas([]));
  };

  clearMarineLocations = (): void => {
    const store = configureStore();
    store.dispatch(receiveMarineLocationDatas([]));
  };

  clearAddressAndContact = (): void => {
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        street_address: '',
        building_name: '',
        name_address: '',
        latitude: 0,
        longitude: 0,
        zip_code: '',
        unit_number: '',
      },
      contact: {
        ...prevState.contact,
        name: '',
        phone: '',
        email: '',
        street_address: '',
        company_name: '',
        vehicle_name: '',
      },
    }));
  };

  searchAddress = (): void => {
    clearTimeout(this.searchPickupTimeout);

    this.searchPickupTimeout = setTimeout(() => {
      this.fetchAddresses(this.state.address.building_name);
      if (this.props.broadcastPreferences === BROADCAST_PREFERENCES.marine) {
        const filteredAnchorages = this.anchorages.filter((anchorage) => {
          return anchorage.name_address
            .toLowerCase()
            .includes(this.state.address.building_name.toLowerCase());
        });
        this.filteredAnchorages = filteredAnchorages;
      }
    }, 700);
  };

  searchMarineLocations = (): void => {
    clearTimeout(this.searchPickupTimeout);

    this.searchPickupTimeout = setTimeout(() => {
      this.fetchMarineLocations(this.state.address.building_name);
    }, 700);
  };

  onConfirmValidation = async (): Promise<boolean> => {
    const client = new GeoServiceClient();

    try {
      this.props.type === 'merchant'
        ? await client.getSavedAddressDetailByName(this.state.saved_address_name)
        : await client.getSysSavedAddressDetailByName(
            this.state.saved_address_name,
            this.props.defaultForm.org_id
          );
      this.setState((prevState) => ({
        error: {
          ...prevState.error,
          saved_address_name: 'Name has been taken.',
        },
      }));
    } catch (error) {
      // continue since it not duplicated
      return true;
    }
    return false;
  };

  chooseSavedAddress = async (data: SavedGeoData): Promise<void> => {
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        name_address: data.name_address,
        building_name: data.building_name,
        street_address: data.street_address,
        latitude: data.lat || 0,
        longitude: data.lng || 0,
        zip_code: data.zip_code || '',
        unit_number: data.unit_number,
      },
      contact: {
        ...prevState.contact,
        name: data.contact_name,
        phone: data.contact_phone,
        email: data.contact_email,
      },
    }));
  };

  renderLocateMapModal = (): React.ReactNode => {
    const { showLocateMapModal, address } = this.state;

    return (
      <>
        {showLocateMapModal && (
          <>
            {this.props.showOnly === 'sea_address' ? (
              <LocationDetailModal
                initialLocationName={this.state.address.building_name}
                initialPosition={{
                  lat: this.state.address.latitude,
                  lng: this.state.address.longitude,
                }}
                onClose={(): void => {
                  this.setState({ showLocateMapModal: false });
                }}
                onSubmit={(locationName, { lat, lng }): void => {
                  this.setState((prevState) => ({
                    ...prevState,
                    address: {
                      ...prevState.address,
                      street_address: locationName,
                      building_name: locationName,
                      name_address: locationName,
                      latitude: lat,
                      longitude: lng,
                    },
                    showLocateMapModal: false,
                  }));
                }}
              />
            ) : (
              <LocateMapModal
                defaultAddress={{
                  name_address: address.name_address ? address.name_address : '',
                  building_name: address.building_name ? address.building_name : '',
                  street_address: address.street_address ? address.street_address : '',
                  lat: address.latitude ? address.latitude : 0,
                  lng: address.longitude ? address.longitude : 0,
                  zip_code: address.zip_code ? address.zip_code : '',
                }}
                closeModal={(): void => {
                  this.setState({
                    showLocateMapModal: false,
                  });
                }}
                onSelectAddress={this.onSelectAddress}
              />
            )}
          </>
        )}
      </>
    );
  };

  render(): React.ReactNode {
    const isMarine = this.props.broadcastPreferences === BROADCAST_PREFERENCES.marine;
    const buildingName = this.state.address?.building_name?.toLowerCase() || '';
    const savedAddressLists = (
      this.props.isHideSavedAddress ? [] : this.props.savedGeoDatas ?? []
    )
      .reduce((results, geoData) => {
        if (
          geoData.name_address.toLowerCase().includes(buildingName) ||
          geoData.building_name.toLowerCase().includes(buildingName) ||
          geoData.street_address.toLowerCase().includes(buildingName)
        ) {
          results.push({
            leftIcon: faBookmark,
            title: geoData.name_address,
            value: geoData.street_address,
            text: geoData.street_address,
            additionalValue: {
              name_address: geoData.name_address,
              building_name: geoData.building_name,
              street_address: geoData.street_address,
              zip_code: geoData.zip_code,
              lat: geoData.lat,
              lng: geoData.lng,
              unit_number: geoData.unit_number,
              contact_name: geoData.contact_name,
              contact_phone: geoData.contact_phone,
              contact_email: geoData.contact_email,
            },
          });
        }
        return results;
      }, [])
      .sort((a, b) => a.title.localeCompare(b.title, 'en', { sensitivity: 'base' }));
    const searchAddressLists = (
      [...this.props.geoDatas, ...this.filteredAnchorages] || []
    ).map((geoData) => ({
      title: geoData.building_name,
      value: geoData.street_address,
      text: geoData.street_address,
      additionalValue: {
        building_name: geoData.building_name,
        street_address: geoData.street_address,
        zip_code: geoData.zip_code,
        lat: geoData.lat,
        lng: geoData.lng,
      },
    }));
    const marineLocations = ([...this.props.marineLocationDatas] || []).map(
      (geoData) => ({
        title: geoData.name_address,
        value: geoData.name_address,
        text: `${geoData.lat}, ${geoData.lng}`,
        additionalValue: {
          street_address: geoData.name_address,
          building_name: geoData.name_address,
          id: geoData.id,
          city: geoData.city,
          country: geoData.country,
          lat: geoData.lat,
          lng: geoData.lng,
        },
      })
    );
    const { showOnly, savedGeoDatas } = this.props;
    const showAddress =
      (!showOnly || showOnly === 'address_details') && showOnly !== 'sea_address';
    const showContact =
      (!showOnly || showOnly === 'contact_details') && showOnly !== 'sea_address';
    const showSeaAddress = showOnly === 'sea_address';
    let isButtonDisabled = false;
    if (
      this.props.defaultForm.saved_address_name === undefined
        ? !this.state.address.building_name
        : !this.state.address.building_name || !this.state.saved_address_name
    ) {
      isButtonDisabled = true;
    }
    if (showOnly === 'contact_details') {
      isButtonDisabled = false;
    }
    if (showOnly === 'sea_address') {
      isButtonDisabled = !this.state.address.building_name;
    }

    return (
      <>
        <CenterModal
          closeButtonOnClick={this.props.closeModal}
          title={this.props.title}
          width="small"
          hasScrollInside={showOnly ? false : true}
          fullBottomButtonText={
            <ConfirmText>
              {' '}
              <ConfirmIcon icon={faCheck} />
              Confirm
            </ConfirmText>
          }
          fullBottomButtonType="primary"
          fullBottomButtonStyle="encourage"
          fullBottomButtonOnClick={async (): Promise<void> => {
            const isValidate =
              this.state.saved_address_name !== undefined
                ? await this.onConfirmValidation()
                : true;
            if (isValidate) {
              this.props.onConfirm({
                address: this.state.address,
                contact: this.state.contact,
                saved_address_name: this.state.saved_address_name,
                is_default_pickup: this.state.is_default_pickup,
                is_default_delivery: this.state.is_default_delivery,
              });
              this.props.closeModal();
            }
          }}
          fullBottomButtonDisabled={isButtonDisabled}
        >
          {showAddress ? (
            <>
              <Header>
                {showOnly === 'address_details' ? 'Location Details' : ' Address Details'}
              </Header>
              {this.props.defaultForm.saved_address_name !== undefined && (
                <Field>
                  <TextInput
                    autoComplete="none"
                    fieldName="Name of Address"
                    onTextChange={(value: string): void => {
                      this.setState({ saved_address_name: value.toUpperCase() });
                    }}
                    isRequired
                    placeholder="E.g. Home, work, warehouse"
                    width="full"
                    value={this.state.saved_address_name}
                  />
                  <div>
                    {this.state.error.saved_address_name &&
                    this.state.error.saved_address_name !== '' ? (
                      <InlineErrorMessage>
                        <ErrorIcon icon={faExclamationCircle} />
                        {this.state.error.saved_address_name}
                      </InlineErrorMessage>
                    ) : (
                      false
                    )}
                  </div>
                  <CheckBoxField>
                    <Checkbox
                      onClick={(): void => {
                        this.setState((prevState) => ({
                          is_default_enabled: !prevState.is_default_enabled,
                          is_default_pickup: prevState.is_default_enabled === false,
                          is_default_delivery: false,
                        }));
                      }}
                      selected={this.state.is_default_enabled}
                    >
                      Set your address as default
                    </Checkbox>
                  </CheckBoxField>
                  <IsDefaultRadioContainer>
                    <Radio
                      options={[
                        {
                          text: 'Pickup',
                          value: 'pickup',
                          disabled: !this.state.is_default_enabled,
                        },
                        {
                          text: 'Delivery',
                          value: 'delivery',
                          disabled: !this.state.is_default_enabled,
                        },
                      ]}
                      value={
                        this.state.is_default_pickup
                          ? 'pickup'
                          : this.state.is_default_delivery
                          ? 'delivery'
                          : ''
                      }
                      onChange={(value: string): void => {
                        this.setState({
                          is_default_pickup: value === 'pickup',
                          is_default_delivery: value === 'delivery',
                        });
                      }}
                    />
                  </IsDefaultRadioContainer>
                </Field>
              )}
              <Field>
                <Searchbox
                  autoComplete="off"
                  key="street-address"
                  inputName="street-address"
                  trailingIcon={
                    (this.state.address?.building_name || '') === '' ? faSearch : faTimes
                  }
                  trailingIconOnClick={(): void => {
                    (this.state.address?.building_name || '') !== '' &&
                      this.clearAddressAndContact();
                  }}
                  containerStyle={inlineTextInputStyle}
                  fieldName="Postal Code / Street Address"
                  isRequired
                  onTextChange={(value: string): void => {
                    this.setState((prevState) => ({
                      address: {
                        ...prevState.address,
                        building_name: value,
                        name_address: value,
                      },
                    }));
                    this.searchAddress();
                    if (value === '') {
                      this.clearAddressAndContact();
                    }
                  }}
                  handleSetFocus={(focused: boolean): void => {
                    if (focused) {
                      this.clearAddresses();
                      this.searchAddress();
                    }
                  }}
                  handleSelectChange={(value: string, geoData: GeoDataContact): void => {
                    this.selectAddress(value, geoData);
                  }}
                  placeholder="880 Jurong West Street 88"
                  width="full"
                  value={
                    this.state.address?.name_address || this.state.address?.building_name
                  }
                  options={searchAddressLists}
                  expandableOptions={savedAddressLists}
                  expandableOptionsMaxLength={3}
                  expandableOptionsTitle="Saved addresses"
                />
                <SubTitleContainer>
                  <SubTitle title={this.state.address.street_address}>
                    {this.state.address.street_address}
                  </SubTitle>
                </SubTitleContainer>
                <div>
                  {(this.state.address.street_address ||
                    this.state.address.building_name) &&
                  !this.isAddressLocationValid(this.state.address) ? (
                    <InlineErrorMessage>
                      <ErrorIcon icon={faExclamationCircle} />
                      Address not found
                    </InlineErrorMessage>
                  ) : (
                    false
                  )}
                  <SavedAddressButton
                    savedGeoDatas={savedGeoDatas}
                    onChange={(v: SavedGeoData) => {
                      this.chooseSavedAddress(v);
                    }}
                  />
                  <LocateMapButton
                    onClick={() => {
                      this.setState({ showLocateMapModal: true });
                    }}
                  >
                    Locate on a map
                  </LocateMapButton>
                </div>
              </Field>
              <Field>
                <TextInput
                  autoComplete="none"
                  fieldName="Unit No."
                  containerStyle={inlineTextInputStyle}
                  onTextChange={(value: string): void => {
                    this.setState((prevState) => ({
                      address: {
                        ...prevState.address,
                        unit_number: value,
                      },
                    }));
                  }}
                  placeholder="#01-01"
                  width="full"
                  value={this.state.address.unit_number}
                />
              </Field>
              {isMarine && (
                <Field>
                  <TextInput
                    autoComplete="none"
                    fieldName="Berth No."
                    containerStyle={inlineTextInputStyle}
                    onTextChange={(value: string): void => {
                      this.setState((prevState) => ({
                        contact: {
                          ...prevState.contact,
                          berth_no: value,
                        },
                      }));
                    }}
                    placeholder="JP 04"
                    width="full"
                    value={this.state.contact.berth_no}
                  />
                </Field>
              )}
              <Separator />
            </>
          ) : (
            false
          )}
          {showContact ? (
            <>
              <Header>Contact Details</Header>
              {isMarine && (
                <>
                  <Field>
                    <TextInput
                      autoComplete="on"
                      inputName="Company"
                      containerStyle={inlineTextInputStyle}
                      fieldName="Company"
                      fieldDescription="E.g. Land Transport Co. / Lighter Company Co."
                      onTextChange={(value: string): void => {
                        this.setState((prevState) => ({
                          contact: {
                            ...prevState.contact,
                            company_name: value,
                          },
                        }));
                      }}
                      placeholder="Company Name"
                      width="full"
                      value={this.state.contact.company_name ?? ''}
                    />
                  </Field>
                  <Field>
                    <TextInput
                      autoComplete="on"
                      inputName="Vehicle"
                      containerStyle={inlineTextInputStyle}
                      fieldName="Vehicle"
                      fieldDescription="E.g. Lighter Boat Name / Vehicle Plate No."
                      onTextChange={(value: string): void => {
                        this.setState((prevState) => ({
                          contact: {
                            ...prevState.contact,
                            vehicle_name: value,
                          },
                        }));
                      }}
                      placeholder="Seas the Day / GBX 1234 T"
                      width="full"
                      value={this.state.contact.vehicle_name ?? ''}
                    />
                  </Field>
                </>
              )}
              <Field>
                <TextInput
                  autoComplete="on"
                  inputName="name"
                  containerStyle={inlineTextInputStyle}
                  fieldName="Name"
                  onTextChange={(value: string): void => {
                    this.setState((prevState) => ({
                      contact: {
                        ...prevState.contact,
                        name: value,
                      },
                    }));
                  }}
                  placeholder="Eg. Amy Amin"
                  width="full"
                  value={this.state.contact.name}
                />
              </Field>
              <Field>
                <TextInput
                  containerStyle={inlineTextInputStyle}
                  fieldName="Contact No."
                  placeholder="E.g. 8100 8989"
                  onTextChange={(value: string): void => {
                    this.setState((prevState) => ({
                      contact: {
                        ...prevState.contact,
                        phone: value,
                      },
                    }));
                  }}
                  type="text"
                  width="full"
                  value={this.state.contact.phone}
                />
              </Field>
              {this.props.type === 'ops' ? (
                <Field>
                  <TextInput
                    containerStyle={inlineTextInputStyle}
                    fieldName="Email"
                    placeholder="E.g. amyamin@example.com"
                    onTextChange={(value: string): void => {
                      this.setState((prevState) => ({
                        contact: {
                          ...prevState.contact,
                          email: value,
                        },
                      }));
                    }}
                    type="text"
                    width="full"
                    value={this.state.contact.email}
                  />
                </Field>
              ) : (
                false
              )}
              <Separator />
            </>
          ) : (
            false
          )}
          {showSeaAddress ? (
            <>
              <Header>Location Details</Header>
              <Field>
                <Searchbox
                  autoComplete="off"
                  key="street-address"
                  inputName="street-address"
                  trailingIcon={
                    (this.state.address?.building_name || '') === '' ? faSearch : faTimes
                  }
                  trailingIconOnClick={(): void => {
                    (this.state.address?.building_name || '') !== '' &&
                      this.clearAddressAndContact();
                  }}
                  containerStyle={inlineTextInputStyle}
                  fieldName="Location Name"
                  isRequired
                  onTextChange={(value: string): void => {
                    this.setState((prevState) => ({
                      address: {
                        ...prevState.address,
                        building_name: value,
                        name_address: value,
                      },
                    }));
                    this.searchMarineLocations();
                    if (value === '') {
                      this.clearMarineLocations();
                    }
                  }}
                  handleSetFocus={(focused: boolean): void => {
                    if (focused) {
                      this.clearMarineLocations();
                      this.searchMarineLocations();
                    }
                  }}
                  handleSelectChange={(value: string, geoData: GeoDataContact): void => {
                    this.selectMarineAddress(value, geoData);
                  }}
                  placeholder="Enter location Name"
                  width="full"
                  value={
                    this.state.address?.name_address ||
                    this.state.address?.building_name
                  }
                  options={marineLocations}
                />
                {this.state.address.latitude || this.state.address.longitude ? (
                  <SubTitleContainer>
                    <SubTitle title={this.state.address.street_address}>
                      {`${this.state.address.latitude}, ${this.state.address.longitude}`}
                    </SubTitle>
                  </SubTitleContainer>
                ) : (
                  false
                )}
                <div>
                  {(this.state.address.street_address ||
                    this.state.address.building_name) &&
                  !this.isAddressLocationValid(this.state.address) ? (
                    <InlineErrorMessage>
                      <ErrorIcon icon={faExclamationCircle} />
                      Address not found
                    </InlineErrorMessage>
                  ) : (
                    false
                  )}
                  <LocateMapButton
                    onClick={() => {
                      this.setState({ showLocateMapModal: true });
                    }}
                  >
                    Locate on a map
                  </LocateMapButton>
                </div>
              </Field>
              <Separator />
            </>
          ) : (
            false
          )}
        </CenterModal>
        {this.renderLocateMapModal()}
      </>
    );
  }
}

const Header = styled.div`
  font-size: 1rem;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  margin-bottom: 1rem;
  font-weight: 600;
`;

const InlineErrorMessage = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const LocateMapButton = styled.div`
  color: ${COLOR.blue};
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
  width: fit-content;
  margin-top: 0.3rem;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  margin-right: 0.35rem;
`;

const ConfirmIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.white};
  align-self: center;
  margin-right: 0.375rem;
`;

const Field = styled.div`
  margin-bottom: 1rem;
`;

const ConfirmText = styled.div`
  font-size: 1rem;
  font-weight: 400;
`;

const SubTitle = styled.div`
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const SubTitleContainer = styled.div`
  display: flex;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 0;
`;

const CheckBoxField = styled.div`
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`;

const IsDefaultRadioContainer = styled.div`
  margin-top: 0.3rem;
  margin-left: 2rem;
  width: fit-content;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  geoDatas: state.geoService.geoDatas,
  savedGeoDatas: state.geoService.savedGeoDatas,
  marineLocationDatas: state.geoService.marineLocationDatas,
});

export default connect(mapStateToProps)(AddressContactModal);
