import { PlanState, PlanActionTypes, RECEIVE_PLAN, RECEIVE_PLANS } from './planTypes';

const initialState: PlanState = {
  plans: [],
  plan: null,
};

function planReducer(
  state: PlanState = initialState, action: PlanActionTypes
): PlanState {
  switch (action.type) {
    case RECEIVE_PLAN:
      return {
        ...state,
        plan: action.plan,
      };
    case RECEIVE_PLANS:
      return {
        ...state,
        plans: action.plans,
      };
    default:
      return state;
  }
}

export default planReducer;
