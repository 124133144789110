import { Organization, PendingOrganization, Squad, SquadDriver } from 'models/organization';

export interface OrganizationState {
  organizations: Organization[];
  organization: Organization;
  pendingOrganizations: PendingOrganization[];
  squads: Squad[];
  squadDrivers: SquadDriver[];
}

export const RECEIVE_ORGANIZATION = 'RECEIVE_ORGANIZATION';
export const RECEIVE_ORGANIZATIONS = 'RECEIVE_ORGANIZATIONS';
export const RECEIVE_PENDING_ORGANIZATIONS = 'RECEIVE_PENDING_ORGANIZATIONS';
export const RECEIVE_SQUADS = 'RECEIVE_SQUADS';
export const RECEIVE_SQUAD_DRIVERS = 'RECEIVE_SQUAD_DRIVERS';

export interface ReceiveOrganizationAction {
  type: typeof RECEIVE_ORGANIZATION;
  organization: Organization;
}

export interface ReceiveOrganizationsAction {
  type: typeof RECEIVE_ORGANIZATIONS;
  organizations: Organization[];
}

export interface ReceivePendingOrganizationsAction {
  type: typeof RECEIVE_PENDING_ORGANIZATIONS;
  organizations: PendingOrganization[];
}

export interface ReceiveSquadsAction {
  type: typeof RECEIVE_SQUADS;
  squads: Squad[];
}

export interface ReceiveSquadDriversAction {
  type: typeof RECEIVE_SQUAD_DRIVERS;
  squadDrivers: SquadDriver[];
}

export type OrganizationActionTypes = ReceiveOrganizationAction
  | ReceiveOrganizationsAction | ReceivePendingOrganizationsAction | ReceiveSquadsAction | ReceiveSquadDriversAction;
