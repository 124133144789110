import * as React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { CenterModal, TextInput, Alert } from 'components';
import { TaskExportHash } from 'reduxActions/taskExport/taskExportTypes';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import { TaskExportForm } from 'models/taskExport';
import TaskExportClient from 'httpClients/taskExportClient';
import DatePicker from 'react-datepicker';
import COLOR from 'constants/color';
import { formatError } from 'utils/formatter';
import * as moment from 'moment';

const STATE = {
  beforeStart: 'beforeStart',
  starting: 'starting',
  afterStart: 'afterStart',
};

const Calendar = styled.div`
  position: absolute:
`;

const inlineTextInputStyle = css`
  display: inline-block;
  margin-right: 1rem;
`;

const CURRENT_DATE = moment().seconds(0).milliseconds(0).toDate();

interface ExportOrdersModalStateProps {
  data: TaskExportHash;
}

interface ExportOrdersModalProps {
  closeModal: () => void;
}

interface ExportOrdersModalState {
  error: string | null;
  pageState: string;
  creationStartDate: Date;
  creationEndDate: Date;
}

type Props = ExportOrdersModalProps & ExportOrdersModalStateProps;

class ExportOrdersModal extends React.Component<Props, ExportOrdersModalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      pageState: STATE.beforeStart,
      creationStartDate: this.addDaysToDateTime(-7, CURRENT_DATE),
      creationEndDate: CURRENT_DATE,
    };
  }

  addDaysToDateTime(days: number, dateTime: Date): Date {
    const newDate = new Date(dateTime);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  isRangeValid = (): boolean => {
    const { creationStartDate, creationEndDate } = this.state;
    let isRangeValid = true;
    const startDate = moment(creationStartDate);
    const endDate = moment(creationEndDate);
    if (startDate !== endDate) {
      isRangeValid = endDate.isSameOrAfter(startDate);
    }

    return isRangeValid;
  }

  isDaysValid = (): boolean => {
    const { creationStartDate, creationEndDate } = this.state;
    const startDate = moment(moment(creationStartDate).format('YYYY-MM-DD HH:mm:ss'));
    const endDate = moment(moment(creationEndDate).format('YYYY-MM-DD HH:mm:ss'));
    const dayDiff = moment.duration(endDate.diff(startDate)).asDays();

    return Math.abs(dayDiff) <= 40;
  }

  isFutureDate = (): boolean => {
    const { creationEndDate } = this.state;
    const currentDate = moment(moment().seconds(0).milliseconds(0).toDate());
    const endDate = moment(creationEndDate);

    return !endDate.isSameOrBefore(currentDate);
  }

  ExportOrders = async (): Promise<void> => {
    this.setState({ pageState: STATE.starting, error: null });

    const form: TaskExportForm = {
      org_id: 'all',
      org_name: 'all',
      name: 'all-basic-' + moment().format('YMMDD-HHmmss'),
      type: 'basic',
      creation_start_date: this.state.creationStartDate,
      creation_end_date: this.state.creationEndDate,
    };

    try {
      const client = new TaskExportClient();
      const jobId = await client.createJob(form);
      try {
        await client.getJob(jobId);
      } catch (error) {
        this.setState({ pageState: STATE.beforeStart, error: 'Failed to get job, error: ' + formatError(error) });
      }
      try {
        const job = this.props.data[jobId];
        await client.startJob(jobId, job.version_rev);
      } catch (error) {
        this.setState({ pageState: STATE.beforeStart, error: 'Failed to start job, error: ' + formatError(error) });
      }
    } catch (error) {
      this.setState({ pageState: STATE.beforeStart, error: 'Failed to create job, error: ' + formatError(error) });
    }
    this.setState({ pageState: STATE.afterStart, error: null });
  }

  canStartExport = (): boolean => {
    return this.state.pageState !== STATE.starting && this.isRangeValid() && this.isDaysValid() && !this.isFutureDate();
  }

  renderBeforeStart(): React.ReactNode {
    const { error } = this.state;
    return (
      <CenterModal
        leftButtonOnClick={this.props.closeModal}
        rightButtonOnClick={(): void => { this.ExportOrders(); }}
        title="Export Orders"
        leftButtonText="Cancel"
        rightButtonText="Export"
        rightButtonStyle="encourage"
        rightButtonDisabled={!this.canStartExport()}
      >
        <SmallDescription>
          Export up to 40 days of orders at once.
        </SmallDescription>
        <DatePickerWrapper
          dateFormat="dd MMM yyyy, hh:mm a"
          onChange={(value: Date): void => {
            if (value instanceof Date) {
              const selectedDate = moment(value).seconds(0).milliseconds(0).toDate();
              this.setState({ creationStartDate: selectedDate });
            }
          }}
          onSelect={(value: Date): void => {
            if (value instanceof Date) {
              const selectedDate = moment(value).seconds(0).milliseconds(0).toDate();
              this.setState({ creationStartDate: selectedDate });
            }
          }}
          selected={this.state.creationStartDate}
          selectsStart
          showTimeSelect
          customInput={
            <TextInput
              autoComplete="none"
              inputName="name"
              containerStyle={inlineTextInputStyle}
              fieldName="Created From"
              isRequired
              width="full"
            />
          }
          startDate={this.state.creationStartDate}
          endDate={this.state.creationEndDate}
          maxDate={this.state.creationEndDate}
          calendarContainer={Calendar}
        />
        {!this.isRangeValid() ? (
          <InlineErrorMessage>
            <ErrorIcon icon={faExclamationCircle} />
            Created From Date & Time must be earlier than Created To Date & Time.
          </InlineErrorMessage>
        ) : false}
        <DatePickerWrapper
          dateFormat="dd MMM yyyy, hh:mm a"
          onChange={(value: Date): void => {
            if (value instanceof Date) {
              const selectedDate = moment(value).seconds(0).milliseconds(0).toDate();
              this.setState({ creationEndDate: selectedDate });
            }
          }}
          onSelect={(value: Date): void => {
            const selectedDate = moment(value).seconds(0).milliseconds(0).toDate();
            this.setState({ creationEndDate: selectedDate });
          }}
          selected={this.state.creationEndDate}
          selectsEnd
          showTimeSelect
          customInput={
            <TextInput
              autoComplete="none"
              inputName="name"
              containerStyle={inlineTextInputStyle}
              fieldName="Created To"
              isRequired
              width="full"
            />
          }
          startDate={this.state.creationStartDate}
          endDate={this.state.creationEndDate}
          minDate={this.state.creationStartDate}
          calendarContainer={Calendar}
        />
        {error ? (
          <InlineErrorMessage>
            <ErrorIcon icon={faExclamationCircle} />
            {error}
          </InlineErrorMessage>
        ) : false}
        {!this.isRangeValid() ? (
          <InlineErrorMessage>
            <ErrorIcon icon={faExclamationCircle} />
            Created To Date & Time must be later than Created From Date & Time.
          </InlineErrorMessage>
        ) : false}
        {!this.isDaysValid() ? (
          <InlineErrorMessage>
            <ErrorIcon icon={faExclamationCircle} />
            Select up to 40 days.
          </InlineErrorMessage>
        ) : false}
        {this.isFutureDate() ? (
          <InlineErrorMessage>
            <ErrorIcon icon={faExclamationCircle} />
            The selected date is in the future. Pick a date in the past or present.
          </InlineErrorMessage>
        ) : false}
      </CenterModal>
    );
  }

  renderAfterStart(): React.ReactNode {
    return (
      <CenterModal
        rightButtonText="OK"
        rightButtonOnClick={this.props.closeModal}
        title="Exporting Orders…"
      >
        <AlertMessage status='success'>
          The export will be created in the background.
          You will be notified once it’s complete.
        </AlertMessage>
      </CenterModal>
    );
  }

  render(): React.ReactNode {
    let content: React.ReactNode;
    const { pageState } = this.state;
    if (pageState === STATE.beforeStart || pageState === STATE.starting) {
      content = this.renderBeforeStart();
    } else if (pageState === STATE.afterStart) {
      content = this.renderAfterStart();
    }
    return content;
  }
}

const SmallDescription = styled.div`
  color: ${COLOR.darkGray};
  font-size: 0.75rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const DatePickerWrapper = styled(({ className, ...props }) => (
  <DatePicker {...props} wrapperClassName={className} />
))`
  display: flex;
  align-items: flex-end;
  width: 60%;
  margin-bottom: 1rem;
`;

const AlertMessage = styled(Alert)`
  margin-bottom: 0.4rem;
`;

const InlineErrorMessage = styled.div`
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  color: ${COLOR.red};
  font-size: 0.875rem;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${COLOR.red};
  margin-right: 0.35rem;
`;

function mapStateToProps(state: RootState): ExportOrdersModalStateProps {
  return {
    data: state.taskExport.taskExportHash,
  };
}

export default connect(mapStateToProps)(ExportOrdersModal);
