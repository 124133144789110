import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { TaskExportHash } from 'reduxActions/taskExport/taskExportTypes';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import TaskExportClient from 'httpClients/taskExportClient';
import { Button, SysAdminMainContainer, Message } from 'components';
import styled from 'styled-components';

interface TaskExportDetailProps {
  data: TaskExportHash;
}

interface TaskExportDetailState {
  showSuccessMessage: boolean;
  error: string | null;
}

type Props = RouteComponentProps<{ id: string }> & TaskExportDetailProps;

class TaskExportDetail extends React.Component<Props, TaskExportDetailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      showSuccessMessage: false,
    };
  }

  componentDidMount(): void {
    this.fetchJob();
  }

  fetchJob = async (): Promise<void> => {
    const { id } = this.props.match.params;

    try {
      const client = new TaskExportClient();
      await client.getJob(id);
      this.setState({ error: null });
    } catch (error) {
      this.setState({ error });
    }
  }

  startJob = async (): Promise<void> => {
    const { id } = this.props.match.params;
    const job = this.props.data[id];

    try {
      const client = new TaskExportClient();
      await client.startJob(id, job.version_rev);
      this.setState({ error: null, showSuccessMessage: true });
    } catch (error) {
      this.setState({ error, showSuccessMessage: false });
    }
  }

  render(): React.ReactNode {
    const { id } = this.props.match.params;
    const { error, showSuccessMessage } = this.state;
    const job = this.props.data[id];
    const isStarted = job && job.status !== 'new';

    return (
      <SysAdminMainContainer selected="tasks">
        <div>
          <h2>Task Export Job Detail</h2>
          <Link to="/sys/tasks/export">
            <Button>Back</Button>
          </Link>
        </div>
        {error && (
          <Message className="error">{error}</Message>
        )}
        {showSuccessMessage && (
          <Message className="success">Request successful. Task Export Job execution is running now.</Message>
        )}
        {job && (
          <React.Fragment>
            {job.result.error != '' && (
              <Message className="error">
                <strong>Job Error</strong>: {job.result.error}
              </Message>
            )}

            <Content>
              <dl>
                <dt>ID:</dt>
                <dd>{job.id}</dd>

                <dt>Org ID:</dt>
                <dd>{job.org_id}</dd>

                <dt>Org Name:</dt>
                <dd>{job.org_name}</dd>

                <dt>Name:</dt>
                <dd>{job.name}</dd>

                <dt>Export Type:</dt>
                <dd>{job.type}</dd>

                <dt>Export Range:</dt>
                <dd>From: {new Date(job.creation_start_date).toUTCString()}</dd>
                <dd>To: {new Date(job.creation_end_date).toUTCString()}</dd>

                <dt>Status:</dt>
                <dd>{job.status}</dd>
              </dl>
            </Content>

            <div>
              <Button disabled={isStarted} onClick={this.startJob}>Start</Button>
            </div>

            <Content>
              <dl>
                <dt>Exported file:</dt>
                <dd>
                  {job.result.download_url != '' ? (
                    <a href={job.result.download_url}>Download</a>
                  ) : (
                    <i>Not available</i>
                  )}
                </dd>
              </dl>
            </Content>
          </React.Fragment>
        )}
      </SysAdminMainContainer>
    );
  }
}

const Content = styled.div`
  dl {
    display: grid;
    grid-template-columns: 8rem auto;
    row-gap: 0.5rem;
  }

  dt {
    grid-column: 1;
  }

  dd {
    grid-column: 2;
  }
`;

function mapStateToProps(state: RootState): TaskExportDetailProps {
  return {
    data: state.taskExport.taskExportHash,
  };
}

export default connect(mapStateToProps)(TaskExportDetail);
