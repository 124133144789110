import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import styled from 'styled-components';

import COLOR from 'constants/color';
import { Alert, StyledButton, BeforeLoginContainer } from 'components';
import { Task } from 'models/task';
import { CurrentUser } from 'models/auth';
import { logoutUser } from 'utils/userHelper';
import * as RedirectHelper from 'utils/redirectHelper';

interface StateProps {
  currentUser: CurrentUser;
  task: Task;
}

interface TransportPartnerRedirectProps {
  finalUrl: string;
}

type Props = TransportPartnerRedirectProps & StateProps;

class DriverPartnerRedirect extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  reLogin = (): void => {
    logoutUser();
    // Redirect to the login page
    window.open('/login', '_self');
  };

  renderHasMobileApp = (): React.ReactNode => {
    return (
      <RedirectContainer>
        <AlertInfo status="info">
          <AlertTitle>
            Redirecting to Driver-Partner
          </AlertTitle>
          <AlertMessage>
            Don’t have the app yet? Download
          </AlertMessage>
          <StyledRedirectLink
            onClick={(): void => {
              RedirectHelper.redirectToStoreDriverPartner(true);
            }}>
            GotSurge Driver-Partner
          </StyledRedirectLink>
        </AlertInfo>
        <StyledButton
          buttonStyle="encourage"
          buttonType="primary"
          size="lg"
          isFullWidth={true}
          onClick={(): void => { RedirectHelper.deepLinkDriverPartner(this.props.finalUrl); }}
        >
          Open app
        </StyledButton>
        <FooterDescription>
          {'Not working? '}
          <StyledLink
            onClick={(): void => {
              window.open('https://help.gotsurge.co', '_blank');
            }}>
            Get Help
          </StyledLink>
        </FooterDescription>
      </RedirectContainer>
    );
  }

  render(): React.ReactNode {
    const { currentUser } = this.props;

    return (
      <BeforeLoginContainer title={''}>
        {currentUser && currentUser.user && currentUser.user.name && (
          <>
            <Title>
              {`Welcome Back, ${currentUser.user.name}`}
            </Title>
            <SubTitle>
              {'Not you? '}
              <StyledLink
                onClick={(): void => {
                  this.reLogin();
                }}>
                Logout
              </StyledLink>
            </SubTitle>
          </>
        )}
        {this.renderHasMobileApp()}
      </BeforeLoginContainer>
    );
  }
}

const StyledLink = styled.span`
  color: ${COLOR.blue};
  text-decoration: underline;
`;

const RedirectContainer = styled.div`
  margin-top: 2rem;
`;

const StyledRedirectLink = styled.span`
  color: ${COLOR.blue};
  text-decoration: underline;
`;

const AlertMessage = styled.div`
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

const AlertTitle = styled.div`
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
`;

const AlertInfo = styled(Alert)`
  margin-bottom: 2rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  margin-top: 2rem;
  color: ${COLOR.black};
`;

const FooterDescription = styled.div`
  font-size: 0.75rem;
  margin-top: 3rem;
  color: ${COLOR.midDarkGrey};
`;

const SubTitle = styled.div`
  font-size: 0.875rem;
  margin-top: 0.5rem;
  color: ${COLOR.midDarkGrey};
`;

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.auth.currentUser,
  task: state.task.task,
});

export default connect(mapStateToProps)(DriverPartnerRedirect);