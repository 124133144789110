import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { RootState } from 'reduxActions/store';
import { CurrentUser } from 'models/auth';
import { connect } from 'react-redux';

interface StateProps {
  currentUser: CurrentUser;
}

type PrivateRouteProps = StateProps & RouteProps;

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const { component: Component, currentUser, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode =>
        currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

function mapStateToProps(state: RootState): StateProps {
  return {
    currentUser: state.auth.currentUser,
  };
}

export default connect(mapStateToProps)(PrivateRoute);