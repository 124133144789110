import { RECEIVE_VESSELS, RECEIVE_VESSEL, VesselActionTypes } from './vesselTypes';
import { Vessel } from 'models/vessel';

export function receiveVesselAllSuccess(vessels: Vessel[]): VesselActionTypes {
  return {
    type: RECEIVE_VESSELS,
    vessels,
  };
}

export function receiveVesselSuccess(vessel: Vessel): VesselActionTypes {
  return {
    type: RECEIVE_VESSEL,
    vessel,
  };
}
