import * as React from 'react';
import styled from 'styled-components';
import COLOR, { transparent } from 'constants/color';

interface ProgressBar {
  completeCount: number;
  totalCount: number;
}

const ProgressBar = (props: ProgressBar): JSX.Element => (
  <ProgressBarContainer>
    <Progress flex={props.completeCount} />
    <RemainingProgress flex={props.totalCount - props.completeCount} />
  </ProgressBarContainer>
);

const ProgressBarContainer = styled.div`
  border-radius: 120px;
  display: flex;
  flex-direction: row;
  height: 0.375rem;
`;

const Progress = styled.div<{ flex: number }>`
  background-color: ${COLOR.cyan};
  flex: ${(props): string => props.flex.toString()};
`;

const RemainingProgress = styled.div<{ flex: number }>`
  background-color: ${transparent('cyan', 0.19)};
  flex: ${(props): string => props.flex.toString()};
`;

export default ProgressBar;