import { RunsheetState, RunsheetActionTypes, RECEIVE_RUNSHEETS, RECEIVE_RUNSHEET } from './runsheetTypes';

const initialState: RunsheetState = {
  runsheets: [],
  runsheet: null,
};

function runsheetReducer(
  state: RunsheetState = initialState, action: RunsheetActionTypes
): RunsheetState {
  switch (action.type) {
    case RECEIVE_RUNSHEETS:
      return { ...state, runsheets: action.runsheets };
    case RECEIVE_RUNSHEET:
      return { ...state, runsheet: action.runsheet };
    default:
      return state;
  }
}

export default runsheetReducer;
