import * as React from 'react';
import { Status } from 'models/task';
import { Typography } from 'components';

interface ReasonCancel {
  type: CancellationType;
  reason: string;
}

type CancellationType = 'cancelled' | 'unsuccessful_pickup' | 'unsuccessful_delivery';

const getReasonCancelled = (
  statuses: Status[],
  cancelType: string
): ReasonCancel | null => {
  const data = statuses.find((status) => status.status === cancelType);
  const reasonInfo = data?.custom_info?.find((info) => info.question === 'Reason');

  switch (cancelType) {
    case 'unsuccessful_pickup':
      return {
        type: 'unsuccessful_pickup',
        reason: reasonInfo ? reasonInfo.answer : '-',
      };
    case 'unsuccessful_delivery':
      return {
        type: 'unsuccessful_delivery',
        reason: reasonInfo ? reasonInfo.answer : '-',
      };
    case 'cancelled':
      return {
        type: 'cancelled',
        reason: data.reason ? data.reason : '-',
      };
    default:
      return null;
  }
};

interface ReasonOfFailure {
  taskStatuses: Status[];
  currentStatus: string;
  cancelType: CancellationType;
  style?: React.CSSProperties;
}

const ReasonOfFailure = ({
  taskStatuses,
  currentStatus,
  cancelType,
  style,
}: ReasonOfFailure): JSX.Element => {
  const reasonCancel = getReasonCancelled(taskStatuses, currentStatus);

  return (
    <>
      {currentStatus === cancelType ? (
        <Typography as="p" size="xs" color="gray_600" weight="normal" customStyle={style}>
          Reason: {reasonCancel.reason}
        </Typography>
      ) : null}
    </>
  );
};

export default ReasonOfFailure;
