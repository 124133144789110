import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, SysAdminMainContainer } from 'components';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Item, Task } from 'models/task';
import { RootState } from 'reduxActions/store';
import TaskClient from 'httpClients/taskClient';
import * as H from 'history';
import { Message } from 'components';

interface StateProps {
  item: Item;
  task: Task;
}

interface SysAdminEditLineItemProps {
  item: Item;
  task: Task;
  history: H.History;
}

interface SysAdminEditLineItemState {
  item: Item;
  error: string | null;
  successMessage: string | null;
}

type Props = SysAdminEditLineItemProps & RouteComponentProps<{ id: string; itemId: string }>;

class SysAdminEditLineItems extends React.Component<Props, SysAdminEditLineItemState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      item: {},
      error: null,
      successMessage: '',
    };
  }

  componentDidMount(): void {
    const { id } = this.props.match.params;
    this.fetchTask(id);
  }

  componentDidUpdate(prevProps: SysAdminEditLineItemProps): void {
    if(prevProps.item !== this.props.item){
      const { item } = this.props;
      if (item) {
        this.setState({
          item: {
            name: item.name,
            sku: item.sku,
            quantity: item.quantity,
            quantity_unit: item.quantity_unit,
            volume: item.volume,
            volume_unit: item.volume_unit,
            weight: item.weight,
            weight_unit: item.weight_unit,
          },
        });
      }
    }
  }

  fetchTask = async (id: string): Promise<void> => {
    const client = new TaskClient();
    await client.sysAdminGetTask(id);
  }

  handleChange = (name: string, value: string, type: string): void => {
    let formattedValue: string | number = value;

    if (type === 'number') {
      if (name === 'quantity') {
        formattedValue = parseInt(value);//quantity type is int in api
      } else {
        formattedValue = parseFloat(value);//weight and volume are float
      }
    }

    this.setState(prevState => {
      return {
        item : {
          ...prevState.item,
          [name]: formattedValue,
        },
      };
    });
  }

  checkNameValidation = (): boolean => {
    const { item } = this.state;
    const checkEmptyName = item.name === undefined || item.name === '';
    if (checkEmptyName) {
      this.setState({
        error: 'Name should not be empty.',
      });
      return false;
    } else {
      return true;
    }
  }

  handleSubmit = async (): Promise<void> => {
    if (this.checkNameValidation()) {
      const { id, itemId } = this.props.match.params;
      const { item } = this.state;

      this.setState({ error: null, successMessage: null });
      try {
        const client = new TaskClient();
        await client.updateLineItem(id, itemId, {
          item: item,
          version_rev: this.props.task.version_rev,
        });
        this.setState({ successMessage: 'Item updated successfully' });
        this.props.history.push(`/sys/tasks/${id}`);
      } catch (e) {
        this.setState({ error: e });
      }
    }
  }

  renderItemForm = (item: Item): React.ReactNode => {
    const itemsFields = [
      {
        label: 'Name',
        type: 'text',
        name: 'name',
        value: item.name,
        required: '*',
      },
      {
        label: 'Sku',
        type: 'text',
        name: 'sku',
        value: item.sku,
        required: '',
      },
      {
        label: 'Quantity',
        type: 'number',
        name: 'quantity',
        value: item.quantity,
        required: '',
      },
      {
        label: 'Quantity Unit',
        type: 'text',
        name: 'quantity_unit',
        value: item.quantity_unit,
        required: '',
      },
      {
        label: 'Volume',
        type: 'number',
        name: 'volume',
        value: item.volume,
        required: '',
      },
      {
        label: 'Volume Unit',
        type: 'text',
        name: 'volume_unit',
        value: item.volume_unit,
        required: '',
      },
      {
        label: 'Weight',
        type: 'number',
        name: 'weight',
        value: item.weight,
        required: '',
      },
      {
        label: 'Weight Unit',
        type: 'text',
        name: 'weight_unit',
        value: item.weight_unit,
        required: '',
      },
    ];

    return (
      <Section>
        {
          itemsFields.map((field) => (
            <div key={field.name}>
              <Label>{field.label}<Required>{field.required}</Required></Label>
              <TextField
                name={field.name}
                type={field.type}
                value={field.value || ''}
                onChange={(e): void => this.handleChange(e.target.name, e.target.value, e.target.type)}
              />
            </div>
          ))
        }
      </Section>
    );
  }

  render(): React.ReactNode {
    const { successMessage } = this.state;
    return (
      <SysAdminMainContainer selected="tasks">
        <Header>
          <Title>Edit Line Item</Title>
          <Link to={`/sys/tasks/${this.props.match.params.id}`}>
            <Button>Back To Task Detail</Button>
          </Link>
        </Header>
        <Container>
          {
            this.state.error !== null
              ? <ErrorMessage>{this.state.error}</ErrorMessage>
              : false
          }
          {successMessage !== null ? (
            <Message>{successMessage}</Message>
          ) : false}
          <div>
            {this.renderItemForm(this.state.item)}
            <Button onClick={this.handleSubmit}>Submit</Button>
          </div>
        </Container>
      </SysAdminMainContainer>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 40rem;
`;

const TextField = styled.input`
  width: 20.5rem;
  padding: 0.25rem;
  margin: 0.5rem;
`;

const Label = styled.label`
  margin: 0.5rem;
  width: 16rem;
  float: left;
`;

const Section = styled.div`
  border-style: ridge;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
`;

const Required = styled.span`
  color: red;
`;

const mapStateToProps = (state: RootState, ownProp: Props): StateProps => ({
  item: state.task.task ? state.task.task.items.find(
    item => item.id === ownProp.match.params.itemId
  ) : {},
  task: state.task.task,
});

export default connect(mapStateToProps)(SysAdminEditLineItems);
