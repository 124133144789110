import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Button, SysAdminMainContainer } from 'components';
import DateHelper from 'utils/dateHelper';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import styled from 'styled-components';
import { RunsheetGenerationJob } from 'models/runsheetGeneration';
import * as H from 'history';
import RunsheetGenerationClient from 'httpClients/runsheetGenerationClient';

interface RunsheetGenerationDetailState {
  error: null | string;
  showSuccessMessage: boolean;
}

interface StateProps {
  runsheetGenerationJob: RunsheetGenerationJob;
}

interface RunsheetGenerationDetailProps {
  history: H.History;
  runsheetGenerationJob: RunsheetGenerationJob;
}

type Props = RouteComponentProps<{ id: string }> & RunsheetGenerationDetailProps;

class RunsheetGenerationDetail extends React.Component<Props, RunsheetGenerationDetailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      showSuccessMessage: false,
    };
  }

  componentDidMount(): void {
    const { id } = this.props.match.params;
    this.fetchRunsheetGeneration(id);
  }

  onDeleteClick = async(id: string): Promise<void> => {
    const { match, runsheetGenerationJob } = this.props;
    const client = new RunsheetGenerationClient();
    await client.deleteTaskFromRunsheetGeneration(match.params.id, id, runsheetGenerationJob.version_rev);
    this.fetchRunsheetGeneration(match.params.id);
  }

  fetchRunsheetGeneration = async (id: string): Promise<void> => {
    this.setState({ error: null });
    try {
      const client = new RunsheetGenerationClient();
      await client.getRunsheetGeneration(id);
    } catch (e) {
      this.setState({ error: e });
    }
  }

  startJob = async (): Promise<void> => {
    const { runsheetGenerationJob } = this.props;
    const { id } = this.props.match.params;

    try {
      const client = new RunsheetGenerationClient();
      await client.startJob(id, runsheetGenerationJob.version_rev);
      this.setState({ error: null, showSuccessMessage: true });
    } catch (error) {
      this.setState({ error });
    }
  }

  render(): React.ReactNode {
    const { runsheetGenerationJob } = this.props;
    const { error, showSuccessMessage } = this.state;
    const { url } = this.props.match;

    return (
      <SysAdminMainContainer selected="runsheet-generations">
        <PageHeader>
          <Title>Runsheet Generation Detail</Title>
        </PageHeader>
        <Link to="/sys/runsheet-generations">
          <Button>Back</Button>
        </Link>
        {error && <h3>{error}</h3>}
        {!error && <Button onClick={this.startJob}>Start</Button>}
        {showSuccessMessage && (
          <div>
            Request successful. Runsheet generation job is running now.
          </div>
        )}
        {runsheetGenerationJob !== null ? (
          <dl>
            <StyledDt>Name</StyledDt>
            <dd>{runsheetGenerationJob.name}</dd>
            <StyledDt>Strategy</StyledDt>
            <dd>{runsheetGenerationJob.strategy}</dd>
            <StyledDt>Orders</StyledDt>
            <Link to={`${url}/add-task`}>
              <Button>Add Order</Button>
            </Link>
            {
              runsheetGenerationJob.tasks !== null
                ? runsheetGenerationJob.tasks.map((task, index) => (
                    <ContainerTask key={index}>
                      <strong>ID: {task.id}</strong>
                      <dl>
                        <dt>Total Items</dt>
                        <dd>{task.total_item_count}</dd>
                        <dt>Total Item Weight</dt>
                        <dd>{task.total_item_weight} {task.total_weight_unit}</dd>
                        <dt>Total Item Volume</dt>
                        <dd>{task.total_item_volume} {task.total_volume_unit}</dd>
                        <dt>From Address</dt>
                        <dd>
                          {
                            `${task.from_address.street_address}, ${task.from_address.building_name},
                            ${task.from_address.state}, ${task.from_address.country} ${task.from_address.zip_code}`
                          }
                        </dd>
                        <dd>
                          {
                            `${DateHelper.formatDateTime(task.from_time_window.start_time_utc)} - ${DateHelper.formatDateTime(task.from_time_window.end_time_utc)}`
                          }
                        </dd>
                        <dt>To Address</dt>
                        <dd>
                          {
                            `${task.to_address.street_address}, ${task.to_address.building_name},
                            ${task.to_address.state}, ${task.to_address.country} ${task.to_address.zip_code}`
                          }
                        </dd>
                        <dd>
                          {
                            `${DateHelper.formatDateTime(task.to_time_window.start_time_utc)} - ${DateHelper.formatDateTime(task.to_time_window.end_time_utc)}`
                          }
                        </dd>
                      </dl>
                      <Button
                        onClick={(): void => { this.onDeleteClick(task.id); }}
                      >
                        Delete
                      </Button>
                    </ContainerTask>
                  ))
                : null
            }
          </dl>
        ) : null}
      </SysAdminMainContainer>
    );
  }
}

const StyledDt = styled.dt`
  font-weight: bold;
`;

const ContainerTask = styled.div`
  margin-left: 1.25rem;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  runsheetGenerationJob: state.runsheetGeneration.runsheetGenerationJob,
});

export default connect(mapStateToProps)(RunsheetGenerationDetail);
