class TranslationHelper {
  static replaceTag(str: string, tagObjectMap: { [index: string]: string }): string[] {
    if (str === '') {
      return [''];
    }

    const stri = str;
    const strArray = stri.split(/(#{[^}]*})/g);

    for (let i = strArray.length - 1; i >= 0; i -= 1) {
      const strElement = strArray[i];
      Object.keys(tagObjectMap).forEach((key: string) => {
        if (strElement === `#{${key}}`) {
          strArray[i] = tagObjectMap[key];
        }
      });
    }
    return strArray;
  }
}

export default TranslationHelper;
