import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'reduxActions/store';
import styled from 'styled-components';
import * as moment from 'moment';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FieldView, SysAdminMainContainer, StatusLabel, Breadcrumb } from 'components';

import RunsheetRTBClient from 'httpClients/runsheetRTBClient';
import COLOR from 'constants/color';
import {
  Card,
  Content,
  CardContainer,
  Column,
  Title,
  InfoTable,
  ActionButtonsContainer,
  ActionButton,
  Icon,
} from './styles';
import PriceAdjustmentCard from './cards/priceAdjustmentCard';
import { RunsheetReadyToBroadcast } from 'models/runsheetReadyToBroadcast';
import { PriceAdjustment, PriceAdjustmentLog } from 'models/priceAdjustment';
import PriceAdjustmentClient from 'httpClients/priceAdjustmentClient';
import JOB_STATUS, { JOB_STATUS_COLOR } from 'constants/jobStatus';

interface RunsheetReadyToBroadcastDetailState {
  error: null | string;
  showStopBroadcastingModal: boolean;
}

interface StateProps {
  runsheetReadyToBroadcast: RunsheetReadyToBroadcast;
  priceAdjustments: PriceAdjustment[];
  priceAdjustmentLogs: PriceAdjustmentLog[];
}

type Props = RouteComponentProps<{ id: string }> & StateProps;

class RunsheetReadyToBroadcastDetail extends React.Component<Props, RunsheetReadyToBroadcastDetailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      showStopBroadcastingModal: false,
    };
  }

  async componentDidMount(): Promise<void> {
    const { id } = this.props.match.params;
    const runsheetRTB = await this.fetchRunsheetReadyToBroadcast(id);

    if (runsheetRTB !== undefined) {
      await this.fetchPriceAdjustmentByRunsheet(runsheetRTB.runsheet_id);
    }
  }

  fetchRunsheetReadyToBroadcast = async (id: string): Promise<RunsheetReadyToBroadcast> => {
    this.setState({ error: null });
    try {
      const client = new RunsheetRTBClient();
      const runsheetRTB = await client.getRunsheetReadyToBroadcast(id);
      return runsheetRTB;
    } catch (e) {
      this.setState({ error: e });
    }
  }

  fetchPriceAdjustmentByRunsheet = async (runsheetID: string): Promise<void> => {
    this.setState({ error: null });
    try {
      const client = new PriceAdjustmentClient();
      await client.getPriceAdjustmentByRunsheet(runsheetID);
      await client.getPriceAdjustmentLogByRunsheet(runsheetID);
    } catch (e) {
      this.setState({ error: e });
    }
  }

  render(): React.ReactNode {
    const { runsheetReadyToBroadcast, priceAdjustments, priceAdjustmentLogs, history } = this.props;
    const { error } = this.state;
    const { id } = this.props.match.params;

    return (
      <SysAdminMainContainer selected="runsheets">
        <Breadcrumb
          items={[
            { text: 'Broadcast', onClick: (): void => { this.props.history.push('/sys/runsheet-ready-to-broadcasts'); } },
            { text: id.split('-')[0] },
          ]}
        />
        <PageHeader>
          <Title>Broadcast Detail</Title>
          <ActionButtonsContainer>
            <ActionButton
              onClick={(): void => { history.goBack(); }}
              buttonStyle='encourage'
              buttonType='neutral'
            >
              <Icon icon={faArrowLeft} color={COLOR.black} />
              Back
            </ActionButton>
          </ActionButtonsContainer>
        </PageHeader>
        {error && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
        {runsheetReadyToBroadcast !== null ? (
          <CardContainer>
            <Column flex="1">
              <Card>
                <InfoTable>
                  <Content>
                    <Title>Broadcast Worker</Title>
                    <FieldView
                      fieldName="Status"
                    >
                      <StatusLabel
                        iconColor={JOB_STATUS_COLOR[runsheetReadyToBroadcast.status]}
                        noBackground
                      >
                        {JOB_STATUS[runsheetReadyToBroadcast.status]}
                      </StatusLabel>
                    </FieldView>
                    <FieldView
                      fieldName="Runsheet ID">
                      <Link to={'/sys/runsheets/' + runsheetReadyToBroadcast.runsheet_id.toString()}>
                        <strong>{runsheetReadyToBroadcast.runsheet_id.toString()}</strong>
                      </Link>
                    </FieldView>
                    <FieldView
                      fieldName="Total Driver Broadcasted">
                      {runsheetReadyToBroadcast.drivers.length}
                    </FieldView>
                    <FieldView
                      fieldName="Date Created">
                      {moment(runsheetReadyToBroadcast.created_at).format('DD MMM YYYY, hh:mm a')}
                    </FieldView>
                    <FieldView
                      fieldName="Date Updated">
                      {moment(runsheetReadyToBroadcast.updated_at).format('DD MMM YYYY, hh:mm a')}
                    </FieldView>
                  </Content>
                </InfoTable>
              </Card>
            </Column>
            <Column flex="2">
              {priceAdjustments && <PriceAdjustmentCard priceAdjustments={priceAdjustments} priceAdjustmentLogs={priceAdjustmentLogs} />}
            </Column>
          </CardContainer>
        ) : null}
      </SysAdminMainContainer>
    );
  }
}

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const ErrorMessage = styled.div`
  color: red;
  margin: 0.625rem 0;
`;

const mapStateToProps = (state: RootState): StateProps => ({
  runsheetReadyToBroadcast: state.runsheetReadyToBroadcast.runsheetReadyToBroadcast,
  priceAdjustments: state.priceAdjustment.priceAdjustments,
  priceAdjustmentLogs: state.priceAdjustment.priceAdjustmentLogs,
});

export default connect(mapStateToProps)(RunsheetReadyToBroadcastDetail);
