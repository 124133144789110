import * as React from 'react';
import * as H from 'history';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { PageTitle } from 'components';
import { getUser } from 'utils/userHelper';
import {
  Table,
  SysAdminPagination,
  StyledButton,
  PaginationState,
  SysAdminMainContainer,
  Breadcrumb,
  Separator as BasicSeparator,
  Heading,
  TableCellRenderEvent,
  CenterModal,
  Alert,
} from 'components';
import { User } from 'models/auth';
import styled from 'styled-components';
import emptyPlaceholder from 'assets/images/team_empty_state.png';
import COLOR from 'constants/color';
import AuthClient from 'httpClients/authClient';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvitationModal from './modal/invitationModal';
import SettingSidebarMenu from '../sidebarMenu';
import { RouteComponentProps } from 'react-router-dom';
import { OrgDriver, Organization } from 'models/organization';
import { Role } from 'models/role';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import RoleClient from 'httpClients/roleClient';
import { formatError } from 'utils/formatter';
import ROLES from 'constants/role';
import OrganizationDriverClient from 'httpClients/organizationDriverClient';

interface IconProps {
  color: string;
}

interface SysAdminTeamProps {
  models: User[];
  organization: Organization;
  roles: Role[];
  org_drives: OrgDriver[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface TeamState extends PaginationState<User> {
  showInvitationModal: boolean;
  showDeleteTeamModal: boolean;
  selectedEmail: string;
  isProcessing: boolean;
  error: string;
}

type Props = RouteComponentProps<{ id: string }> & HistoryProps & SysAdminTeamProps;

class SysAdminTeamIndex extends SysAdminPagination<User, Props, TeamState> {
  buttonRefs: React.RefObject<HTMLButtonElement>[] = [];
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      basePath: `/sys/organizations/${this.props.match.params.id}/team`,
      showInvitationModal: false,
      showDeleteTeamModal: false,
      selectedEmail: '',
      isProcessing: false,
      error: '',
      renderContainer: false,
      renderSortBy: false,
      filters: this.defaultFilter(),
      pluralModelName: 'users',
    };
    this.rules = [{ name: 'page' }];
  }

  componentDidMount(): void {
    this.fetchOrgDriver();
    this.fetchOrganization();
    this.fetchRoles();
  }

  defaultFilter = (): Record<string, string> => ({
    page: '1',
  });

  fetchOrganization = async (): Promise<void> => {
    const client = new OrganizationManagementClient();
    await client.sysAdminGetOrganization(this.props.match.params.id);
  };

  fetchOrgDriver = async (): Promise<void> => {
    const client = new OrganizationDriverClient();
    await client.sysAdminGetOrganizationDrivers(
      this.props.match.params.id,
      new URLSearchParams(this.state.filters)
    );
  };

  fetchRoles = async (): Promise<void> => {
    const roleClient = new RoleClient();
    await roleClient.getRoles(this.props.match.params.id);
  };

  fetchData = async (): Promise<void> => {
    this.setState({ isFetching: true });

    const currentFilters = new URLSearchParams(this.state.filters);
    currentFilters.append('org_id', this.props.match.params.id);

    const client = new AuthClient();
    await client.sysGetUsers(currentFilters);

    this.setState({ isFetching: false });
  };

  copyInvitationUrl = async (email: string, userIndex: number): Promise<void> => {
    const client = new AuthClient();
    try {
      const response = await client.sysGetInvitationLink(email);
      if (response.invitation_url) {
        navigator.clipboard.writeText(response.invitation_url);
        this.buttonRefs[userIndex].current.textContent = 'Copied!';
        setTimeout(() => {
          this.buttonRefs[userIndex].current.textContent = 'Share setup link';
        }, 2000);
      }
    } catch (error) {
      if (error && error.error === 'User has already accepted the invitation') {
        this.fetchData();
      }
    }
  };

  showInvitationModal = (): void => {
    this.setState({ showInvitationModal: true });
  };

  closeInvitationModal = (): void => {
    this.setState({ showInvitationModal: false });
  };

  closeInvitationModalFetch = (): void => {
    this.fetchData();
    this.setState({ showInvitationModal: false });
  };

  showDeleteTeamModal = (selectedEmail: string): void => {
    this.setState({ showDeleteTeamModal: true, selectedEmail });
  };

  closeDeleteTeamModal = (): void => {
    this.setState({ showDeleteTeamModal: false, selectedEmail: '' });
  };

  deleteTeam = async (): Promise<void> => {
    const { selectedEmail } = this.state;
    const client = new AuthClient();
    this.setState({ isProcessing: true, error: '' });
    try {
      await client.sysDeleteAccount({
        user: {
          email: selectedEmail,
        },
      });
    } catch (e) {
      this.closeDeleteTeamModal();
      this.setState({ error: formatError(e), isProcessing: false });
    }
    await this.fetchData();
    this.setState({ isProcessing: false });
    this.closeDeleteTeamModal();
  };

  renderInvitationModal = (): React.ReactNode => {
    const role = this.props.roles.find(
      (role) =>
        role.role_name === ROLES.OrgAdmin || role.role_name === ROLES.OrgTransporter
    );
    const roleId = (role && role.id) || '';

    return (
      <>
        {this.state.showInvitationModal && (
          <InvitationModal
            orgId={this.props.match.params.id}
            roleId={roleId}
            closeModal={this.closeInvitationModal}
            invitationSent={this.closeInvitationModalFetch}
          />
        )}
      </>
    );
  };

  renderErrorModal = (): React.ReactNode => {
    const { error } = this.state;
    return (
      <>
        {error && (
          <CenterModal
            leftButtonOnClick={(): void => {
              this.setState({ error: '' });
            }}
            leftButtonText="Close"
            title="Delete team failed"
          >
            <ModalTextContainer>
              <ModalText>
                <Alert status="error">{error}</Alert>
              </ModalText>
            </ModalTextContainer>
          </CenterModal>
        )}
      </>
    );
  };

  renderCell = (
    e: TableCellRenderEvent<{ id: string; name: string; email: string; action: string }>
  ): React.ReactNode => {
    const { email } = getUser();
    if (e.key === 'action' && e.data.email !== email) {
      return (
        <CellContainer>
          <RemoveButton
            buttonStyle="discourage"
            buttonType="neutral"
            onClick={(): void => {
              this.showDeleteTeamModal(e.data.email);
            }}
            disabled={this.state.isProcessing}
          >
            <TrashIcon icon={faTrash} />
            Delete
          </RemoveButton>
          {this.renderShareSetupLink(e.data.email)}
        </CellContainer>
      );
    }

    return <>{e.value}</>;
  };

  renderPage = (content: JSX.Element): React.ReactNode => {
    return (
      <SysAdminMainContainer selected="organizations">
        <Breadcrumb
          items={[
            {
              text: 'Organizations',
              onClick: (): void => {
                this.props.history.push('/sys/organizations');
              },
            },
            {
              text: this.props.match.params.id,
              onClick: (): void => {
                this.props.history.push(
                  '/sys/organizations/' + this.props.match.params.id + '/company-details'
                );
              },
            },
            { text: 'Team Members' },
          ]}
        />
        <Heading>
          <PageTitle>Organisations Info</PageTitle>
        </Heading>
        <SettingSidebarMenu
          selected={`/sys/organizations/${this.props.match.params.id}/team`}
        >
          <Heading>
            <PageTitle>Team</PageTitle>
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={this.showInvitationModal}
            >
              <Icon icon={faPlusCircle} color={COLOR.black} />
              Invite members
            </StyledButton>
          </Heading>
          <Separator />
          {this.renderFilterAndPagination()}
          {content}
          {this.renderInvitationModal()}
          {this.renderErrorModal()}
          {this.state.showDeleteTeamModal ? (
            <CenterModal
              leftButtonOnClick={this.closeDeleteTeamModal}
              leftButtonText="Cancel"
              rightButtonText="Delete"
              rightButtonOnClick={this.deleteTeam}
              rightButtonDisabled={this.state.isProcessing}
              title="Delete team?"
            >
              <ModalTextContainer>
                <ModalText>The team member will not be able to log in again.</ModalText>
              </ModalTextContainer>
            </CenterModal>
          ) : (
            false
          )}
        </SettingSidebarMenu>
      </SysAdminMainContainer>
    );
  };

  renderHeader = (): React.ReactNode => {
    return false;
  };

  renderEmptyState = (): React.ReactNode => {
    return (
      <>
        {this.renderPage(
          <EmptyContainer>
            <Placeholder src={emptyPlaceholder} />
            <Subtitle>No members yet</Subtitle>
            <Description>Invite members</Description>
          </EmptyContainer>
        )}
      </>
    );
  };

  renderContent = (): React.ReactNode => {
    const { models } = this.props;

    const columns = {
      name: 'Name',
      email: 'Email',
      action: '',
    };

    const data = models
      .filter(
        (user) =>
          !this.props.org_drives.some((org_driver) => org_driver.driver_id === user.id)
      )
      .map((user) => ({
        id: user.id,
        name: user.name,
        email: user.email,
      }));

    return (
      <>
        {this.renderPage(
          <Table
            columns={columns}
            data={data}
            cellRenderer={this.renderCell}
            hideNavigationButton
          />
        )}
      </>
    );
  };

  renderShareSetupLink = (email: string): React.ReactNode => {
    const { models } = this.props;
    const userIndex = models.findIndex(
      (user) => user.email === email && user.invitation_token
    );
    if (userIndex === -1) {
      return null;
    }
    this.buttonRefs[userIndex] = React.createRef();

    return (
      <StyledButton
        ref={this.buttonRefs[userIndex]}
        buttonStyle="encourage"
        buttonType="neutral"
        size="sm"
        fontWeight={600}
        style={{ whiteSpace: 'nowrap', minWidth: '143.3px' }}
        onClick={() => {
          this.copyInvitationUrl(email, userIndex);
        }}
      >
        Share setup link
      </StyledButton>
    );
  };
}

const Placeholder = styled.img`
  width: 13.5rem;
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-bottom: 5rem;

  & > * {
    margin: 0.5rem;
  }
`;

const Subtitle = styled.div`
  font-weight: 600;
  color: ${COLOR.darkGray};
`;

const Description = styled.div`
  color: ${COLOR.darkGray};
`;

const Icon = styled(FontAwesomeIcon)<IconProps>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.375rem;
`;

const Separator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
  margin-bottom: 0.5rem;
`;

const ModalTextContainer = styled.div`
  margin-bottom: 2rem;
`;

const ModalText = styled.div`
  line-height: 1.25rem;
  margin-bottom: 1rem;
`;

const CellContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const RemoveButton = styled(StyledButton)`
  margin-right: 0.5rem;
  border-width: 0;
  visibility: hidden;
  tr:hover & {
    visibility: visible;
    background-color: ${COLOR.white};
  }
`;

const TrashIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`;

const mapStateToProps = (state: RootState): SysAdminTeamProps => ({
  models: state.auth.users,
  organization: state.organization.organization,
  roles: state.role.roles,
  org_drives: state.orgDriver.orgDrivers,
});

export default connect(mapStateToProps)(SysAdminTeamIndex);
