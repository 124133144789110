import * as React from 'react';
import { SysAdminMainContainer } from 'components';
import * as H from 'history';

interface HomeProps {
  history: H.History;
}

class SysAdminDashboard extends React.Component<HomeProps> {
  render(): React.ReactNode {
    return (
      <SysAdminMainContainer selected="dashboard">
      </SysAdminMainContainer>
    );
  }
}

export default SysAdminDashboard;