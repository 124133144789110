const BROADCAST_PREFERENCES = {
  all: 'all',
  squad: 'squad',
  marine: 'marine',
};

interface BroadcastPreferenceDescription {
  [key: string]: {
    label: string;
    description: string;
  };
}

export const BROADCAST_PREFERENCE_DESCRIPTION: BroadcastPreferenceDescription = {
  all: {
    label: 'Standard',
    description: 'Inland delivery services',
  },
  squad: {
    label: 'Squad',
    description: 'Preferred Driver-Partners',
  },
  marine: {
    label: 'Marine',
    description: 'Delivery & offland services for ship supplies',
  },
};

export const BROADCAST_MINUTES = 30;

export default BROADCAST_PREFERENCES;