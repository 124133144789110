import * as React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from './styles';
import { Contact } from 'models/task';

interface VesselDetailProps {
  toContact: Contact;
}

const VesselDetail = ({ toContact }: VesselDetailProps) => {
  return (
    <View
      style={{
        flexDirection: 'column',
        marginBottom: 16,
      }}
    >
      <Text style={styles.titleSmall}>Vessel & IMO</Text>
      {toContact.vessel_name ? (
        <Text style={[styles.textMedium, styles.spaceSmallBottom]}>
          {toContact.vessel_name}{' '}
          {toContact.vessel_imo ? `| ${toContact.vessel_imo}` : ''}
        </Text>
      ) : (
        '-'
      )}
    </View>
  );
};

export default VesselDetail;
