import styled from 'styled-components';
import COLOR from 'constants/color';

export default styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-right: auto;
  color: ${COLOR.darkGray};

  @media (max-width: 768px) {
    display: none;
  }
`;
