import * as React from 'react';
import {
  MainContainer,
  Breadcrumb,
  Heading,
  PageTitle,
  Separator,
  StyledButton,
  TextInput,
} from 'components';
import { RouteComponentProps } from 'react-router-dom';
import { User } from 'models/auth';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import COLOR from 'constants/color';
import SettingSidebarMenu from '../../sidebarMenu';
import { fetchCurrentUser } from 'utils/userHelper';

interface ProfileProps {
  user: User;
}

type Props = RouteComponentProps & ProfileProps;

const ChangePasswordIndex = (props: Props): JSX.Element => {
  React.useEffect(() => {
    // fetching the current user
    fetchCurrentUser();
  }, []);

  return (
    <MainContainer selected="setting">
      <Breadcrumb
        items={[
          { text: 'Setting', onClick: (): void => { props.history.push('/setting'); } },
          { text: 'Change Password' },
        ]}
      />
      <Heading>
        <PageTitle>Setting</PageTitle>
      </Heading>
      <SettingSidebarMenu selected="/setting/personal-profile/change-password">
        <Heading>
          <PageTitle>
            Change Password
          </PageTitle>
          {props.user && (
            <StyledButton
              buttonStyle="encourage"
              buttonType="neutral"
              onClick={(): void => { props.history.push('/setting/personal-profile/change-password/edit'); }}
              icon={<FontAwesomeIcon icon={faEdit} />}
            >
              Edit
            </StyledButton>
          )}
        </Heading>
        <CustomSeparator />
        {props.user && (
          <FieldColumn>
            <TextInputField
              fieldName="Current Password"
              icon={faEyeSlash}
              name="current_password"
              type="password"
              value=""
              width="large"
              disabled
            />
            <TextInputField
              fieldName="New Password"
              icon={faEyeSlash}
              name="new_password"
              type="password"
              value=""
              width="large"
              disabled
              extraLabel={<PasswordInfo>Minimum 8 characters and at least 1 special character.</PasswordInfo>}
            />
            <TextInputField
              fieldName="Confirm Password"
              icon={faEyeSlash}
              name="confirm_password"
              type="password"
              value=""
              width="large"
              disabled
            />
          </FieldColumn>
        )}
      </SettingSidebarMenu>
    </MainContainer>
  );
};

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldColumn = styled(Column)`
  flex: 1;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  & > :not(:last-child) {
    margin-bottom: 1.5rem;
  };
`;

const CustomSeparator = styled(Separator)`
  margin-top: 1.6875rem;
`;

const PasswordInfo = styled.div`
  margin-top: 0.2rem;
  font-size: 0.7rem;
  color: ${COLOR.grey};
`;

const TextInputField = styled(TextInput)`
  background-color: ${COLOR.whiteGrey};
`;

function mapStateToProps(state: RootState): ProfileProps {
  return {
    user: state.auth.currentUser.user,
  };
}

export default connect(mapStateToProps)(ChangePasswordIndex);