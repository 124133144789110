import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import * as HttpHelper from 'utils/httpHelper';
import {
  SysOrgDriverUpdateForm,
  MetaOrgDrivers,
  OrgDriver,
  SysOrgDriverCreateForm,
} from 'models/organization';
import { getBearerToken } from 'utils/userHelper';
import configureStore from 'reduxActions/store';
import {
  receiveOrgDriver,
  receiveOrgDrivers,
} from 'reduxActions/orgDriver/orgDriverActions';
import { PER_PAGE } from 'constants/paginationMeta';

class OrganizationDriverClient {
  protocol: string;
  host: string;
  port: string | null;

  constructor() {
    this.protocol = process.env.ORGANIZATION_MANAGEMENT_SERVICE_PROTOCOL;
    this.host = process.env.ORGANIZATION_MANAGEMENT_SERVICE_HOST;
    this.port = process.env.ORGANIZATION_MANAGEMENT_SERVICE_PORT || null;
  }

  getBaseUrl(): string {
    if (this.port !== null) {
      return `${this.protocol}://${this.host}:${this.port}`;
    }
    return `${this.protocol}://${this.host}`;
  }

  makeRequest<T>(
    method: Method,
    url: string,
    body: T | null = null,
    includeBearerToken = true
  ): AxiosRequestConfig {
    const correlationId = HttpHelper.generateCorrelationId();

    const options: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-correlation-id': correlationId,
      },
    };

    if (includeBearerToken) {
      const bearerToken = getBearerToken();
      options.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
  }

  sysUpdateOrganizationDriverUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/org-driver/${id}`;
  }

  getSysOrganizationDriversUrl(id: string, params = new URLSearchParams()): string {
    return `${this.getBaseUrl()}/sys/organization/${id}/org-driver?${params}`;
  }

  sysAddOrgDriverUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/organization/${id}/org-driver`;
  }

  getSysOrganizationDriverUrl(id: string): string {
    return `${this.getBaseUrl()}/sys/org-driver/${id}`;
  }

  async sysAdminAddOrgDriver(
    orgId: string,
    form: SysOrgDriverCreateForm
  ): Promise<OrgDriver> {
    try {
      const request = this.makeRequest('POST', this.sysAddOrgDriverUrl(orgId), form);
      const response: AxiosResponse<OrgDriver> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        throw error.response.data.message;
      } else if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error;
    }
  }

  async sysAdminGetOrganizationDriver(id: string): Promise<void> {
    const request = this.makeRequest('GET', this.getSysOrganizationDriverUrl(id));

    try {
      const response: AxiosResponse<OrgDriver> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveOrgDriver(response.data));
    } catch (error) {
      throw error.message;
    }
  }

  async sysAdminGetOrganizationDrivers(
    orgId: string,
    params = new URLSearchParams()
  ): Promise<void> {
    params.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest(
      'GET',
      this.getSysOrganizationDriversUrl(orgId, params)
    );

    try {
      const response: AxiosResponse<MetaOrgDrivers> = await axios(request);
      const store = configureStore();
      store.dispatch(receiveOrgDrivers(response.data.org_drivers));
    } catch (error) {
      throw error.message;
    }
  }

  async sysAdminGetOrganizationDriverList(
    orgId: string,
    params = new URLSearchParams()
  ): Promise<MetaOrgDrivers> {
    params.append('per_page', PER_PAGE.toString());

    const request = this.makeRequest(
      'GET',
      this.getSysOrganizationDriversUrl(orgId, params)
    );

    try {
      const response: AxiosResponse<MetaOrgDrivers> = await axios(request);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error;
    }
  }

  async sysAdminUpdateOrgDriver(id: string, form: SysOrgDriverUpdateForm): Promise<void> {
    const request = this.makeRequest(
      'PUT',
      this.sysUpdateOrganizationDriverUrl(id),
      form
    );

    try {
      await axios(request);
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw error.message;
    }
  }
}

export default OrganizationDriverClient;
