import * as React from 'react';
import { SidebarMenu } from 'components';
import styled from 'styled-components';
import { getUser } from 'utils/userHelper';
import COLOR from 'constants/color';
import { Separator } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

interface MainMenu {
  title: string;
  submenu: Submenu[];
}

interface Submenu {
  title: string;
  path: string;
}

const SidebarMenuList = [
  {
    title: 'Personal Profile',
    submenu: [
      {
        title: 'Profile',
        path: '/setting/personal-profile/profile',
      },
      {
        title: 'Change Password',
        path: '/setting/personal-profile/change-password',
      },
      // {
      //   title: 'Notifications',
      //   path: '/setting/personal-profile/notifications',
      // },
    ],
  },
  {
    title: 'Company',
    submenu: [
      {
        title: 'Company details',
        path: '/setting/company/company-details',
      },
      {
        title: 'Team',
        path: '/setting/company/team',
      },
      {
        title: 'Saved Addresses',
        path: '/setting/company/saved-address',
      },
    ],
  },
];

interface SidebarMenuProps {
  header?: React.ReactNode;
  mainMenu?: MainMenu[];
  footer?: React.ReactNode;
  selected?: string;
  children?: React.ReactNode;
}

const SettingSidebarMenu = (props: SidebarMenuProps): JSX.Element => {
  const { email, name } = getUser();

  return (
    <SidebarMenu
      {...props}
      header={(
        <>
          <ProfileContainer>
            <ProfileIconContainer>
              <ProfileIcon
                icon={faUser}
              />
            </ProfileIconContainer>
            <ProfileInfoContainer>
              <ProfileName>
                {name}
              </ProfileName>
              <ProfileEmail>
                {email}
              </ProfileEmail>
            </ProfileInfoContainer>
          </ProfileContainer>
          <Separator />
        </>
      )}
      mainMenu={SidebarMenuList}
    />
  );
};

const ProfileIcon = styled(FontAwesomeIcon) <{ hoverable?: boolean }>`
  background-color: ${COLOR.cyan};
  color: ${COLOR.white};
  height: 1.7rem;
  width: 1.7rem !important;
  padding: 0.5rem;
  border-radius: 25px;
`;

const ProfileContainer = styled.div`
  display: flex;
  margin-top: 0.40rem;
  margin-bottom: 1.5rem;
`;

const ProfileIconContainer = styled.div`
  height: 1.875rem;
  width: 1.875rem;
  margin-right: 0.6875rem;
`;

const ProfileInfoContainer = styled.div`
  margin-left: 0.6875rem;
`;

const ProfileName = styled.div`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.3rem;
`;

const ProfileEmail = styled.div`
  color: ${COLOR.darkGray};
  font-size: 0.875rem;
`;

export default SettingSidebarMenu;