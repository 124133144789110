import * as React from 'react';
import {
  VEHICLE_PREFERENCE_BY_CATEGORY,
  VehicleType,
  VEHICLE_PREFERENCE_INFO,
} from 'constants/vehiclePreference';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import COLOR from 'constants/color';

interface Props {
  value: VehicleType[];
  containerStyle?: FlattenSimpleInterpolation;
  fieldName?: string | React.ReactNode;
}

export const FleetTypesLabel = (props: Props): JSX.Element => {
  const fleetTypes = props.value || [];

  const getLabelText = (): string => {
    // Separate fleet types into land and sea categories
    const landFleetTypes = fleetTypes.filter((type) =>
      VEHICLE_PREFERENCE_BY_CATEGORY.land.includes(type as string)
    );
    const seaFleetTypes = fleetTypes.filter((type) =>
      VEHICLE_PREFERENCE_BY_CATEGORY.sea.includes(type as string)
    );
    const isLand = landFleetTypes.length > 0;
    const isSea = seaFleetTypes.length > 0;

    if (isLand && isSea) {
      return 'Land & Sea';
    }
    if (isLand) {
      return 'Land only';
    }
    if (isSea) {
      return 'Sea only';
    }
    return '';
  };

  return (
    <Container containerStyle={props.containerStyle}>
      {props.fieldName && <InputLabel>{props.fieldName}</InputLabel>}
      <VehicleInfoLabel>{getLabelText()}</VehicleInfoLabel>
      <Ul>
        {Object.keys(VEHICLE_PREFERENCE_INFO)
          .filter((key) => fleetTypes.includes(key as VehicleType))
          .map((key, i) => (
            <Li key={i}>
              {VEHICLE_PREFERENCE_INFO[key] && VEHICLE_PREFERENCE_INFO[key].description}
            </Li>
          ))}
      </Ul>
    </Container>
  );
};

const Container = styled.div<{ containerStyle: FlattenSimpleInterpolation }>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  ${(props): FlattenSimpleInterpolation => props.containerStyle};
`;

const InputLabel = styled.label`
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.32px;
  margin-bottom: 0.3rem;
`;

const VehicleInfoLabel = styled.div`
  color: ${COLOR.darkGray};
`;

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style-position: inside;
  margin-top: 0.5rem;
`;

const Li = styled.li`
  padding: 0;
  margin-left: 1rem;
  color: ${COLOR.darkGray};
`;
