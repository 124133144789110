import * as React from 'react';
import {
  Breadcrumb, Card, Heading, Separator as BasicSeparator, PageTitle,
  MainContainer, FieldView, StyledButton, Alert,
} from 'components';
import styled from 'styled-components';
import OrganizationManagementClient from 'httpClients/organizationManagementClient';
import { RootState } from 'reduxActions/store';
import { connect } from 'react-redux';
import { Organization, Squad } from 'models/organization';
import COLOR from 'constants/color';
import { formatDuration, formatPrice } from 'utils/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps } from 'react-router-dom';
import SettingSidebarMenu from '../../sidebarMenu';
import BROADCAST_PREFERENCES, { BROADCAST_PREFERENCE_DESCRIPTION, BROADCAST_MINUTES } from 'constants/broadcastPreference';
import { ONE_SGD, DEFAULT_SQUAD_PRICE, DEFAULT_MIN_PRICE, DEFAULT_MAX_PRICE } from 'constants/priceDetails';
import { fetchCurrentUser } from 'utils/userHelper';

interface DefaultPriceProps {
  organization: Organization;
  squads: Squad[];
}

type Props = RouteComponentProps & DefaultPriceProps;

const renderHeading = (props: Props): JSX.Element => {
  return (
    <Heading>
      <PageTitle>
        Service & Price
      </PageTitle>
      <StyledButton
        buttonStyle="encourage"
        buttonType="neutral"
        onClick={(): void => { props.history.push('/setting/company/default-price/edit'); }}
      >
        <Icon icon={faEdit} color={COLOR.black} />
        Edit
      </StyledButton>
    </Heading>
  );
};

const DefaultPriceIndex = (props: Props): JSX.Element => {
  React.useEffect(() => {
    (async function (): Promise<void> {
      const client = new OrganizationManagementClient();
      await client.getOrganization();
      await client.getSquads(new URLSearchParams());
    })();
  }, []);

  const [broadcastPreference, setBroadcastPreference] = React.useState(BROADCAST_PREFERENCES.all);
  const squadsLength = props.squads.length;

  React.useEffect(() => {
    // fetching the current user
    fetchCurrentUser();
    let defaultBroadcastPreference = props.organization?.broadcast_preference || '';
    // changed to standard if default broadcast preference is squad but have no squad
    if (defaultBroadcastPreference === BROADCAST_PREFERENCES.squad && squadsLength === 0) {
      defaultBroadcastPreference = BROADCAST_PREFERENCES.all;
    }
    if (defaultBroadcastPreference === '') {
      defaultBroadcastPreference = squadsLength > 0 ?
        BROADCAST_PREFERENCES.squad : BROADCAST_PREFERENCES.all;
    }
    setBroadcastPreference(defaultBroadcastPreference);
  }, [props.squads]);

  const renderSquadPrice = (props: Props): JSX.Element => {
    return (
      <>
        <ServiceTitle>
          {BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.squad].label}
          {broadcastPreference == BROADCAST_PREFERENCES.squad && (
            <DefaultServiceBadge>
              Default
            </DefaultServiceBadge>
          )}
        </ServiceTitle>
        <Description>
          Broadcast your Orders to your Squad
        </Description>
        <PriceContainer>
          <ContentTitle>Set your price</ContentTitle>
          <Description>
            Alert drivers at a fixed price per location until a driver is found.
          </Description>
          {props.organization && (
            <>
              <PriceInfo>
                <CardRow>
                  <FieldView
                    fieldName="Price"
                  >
                    {formatPrice('SGD', props.organization.squad_price || DEFAULT_SQUAD_PRICE)}
                  </FieldView>
                </CardRow>
              </PriceInfo>
            </>
          )}
        </PriceContainer>
      </>
    );
  };

  const renderAllDriversPrice = (props: Props): JSX.Element => {
    let increment = 0;
    if (props.organization?.price_table && props.organization.price_table.length > 1) {
      increment = props.organization.price_table[1].price - props.organization.price_table[0].price;
      if (increment <= ONE_SGD) {
        increment = ONE_SGD;
      } else if (increment <= ONE_SGD * 2) {
        increment = ONE_SGD * 2;
      } else if (increment <= ONE_SGD * 3) {
        increment = ONE_SGD * 3;
      }
    }

    let totalSeconds = 0;
    props.organization?.price_table.forEach(record => {
      totalSeconds += record.time_delta;
    });
    const minPrice = props.organization && props.organization.min_price || DEFAULT_MIN_PRICE;
    const maxPrice = props.organization && props.organization.max_price || DEFAULT_MAX_PRICE;

    return (
      <>
        <ServiceTitle>
          {BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.all].label}
          {broadcastPreference == BROADCAST_PREFERENCES.all && (
            <DefaultServiceBadge>
              Default
            </DefaultServiceBadge>
          )}
        </ServiceTitle>
        <Description>
          Broadcast your Orders to all GotSurge Driver-Partners
        </Description>
        <PriceContainer>
          <ContentTitle>Set your price</ContentTitle>
          <Description>
            Alert drivers starting from a base price per location that increases to your max budget until a driver is found.
          </Description>
          {props.organization && (
            <>
              <PriceInfo>
                <CardRow>
                  <FieldView
                    fieldName="Minimum Price"
                  >
                    {formatPrice('SGD', minPrice)}
                  </FieldView>
                  <Arrow icon={faArrowRight} />
                  <FieldView
                    fieldName="Maximum Price"
                  >
                    {formatPrice('SGD', maxPrice)}
                  </FieldView>
                </CardRow>
              </PriceInfo>
              {minPrice === maxPrice ? (
                <Alert status="info">
                  There will be no increment, your orders will be broadcasted at this same price throughout.
                </Alert>
              ) : (
                <>
                  <div>
                    Price increment: + {formatPrice('SGD', increment)} every {BROADCAST_MINUTES} minutes
                  </div>
                  <SmallDescription>
                    Max order acceptance time: {formatDuration(totalSeconds)}
                  </SmallDescription>
                </>
              )}
            </>
          )}
        </PriceContainer>
      </>
    );
  };

  const renderMarinePrice = (): JSX.Element => {
    return (
      <>
        <ServiceTitle>
          {BROADCAST_PREFERENCE_DESCRIPTION[BROADCAST_PREFERENCES.marine].label}
          {broadcastPreference == BROADCAST_PREFERENCES.marine && (
            <DefaultServiceBadge>
              Default
            </DefaultServiceBadge>
          )}
        </ServiceTitle>
        <Description>
          Book for a Transporter to and from Marine ports
        </Description>
        <PriceContainer>
          <ContentTitle>Set your price</ContentTitle>
          <Description>
            Note: Default price is not applicable for marine.
          </Description>
        </PriceContainer>
      </>
    );
  };

  return (
    <MainContainer selected="setting">
      <Breadcrumb
        items={[
          { text: 'Setting', onClick: (): void => { props.history.push('/setting'); } },
          { text: 'Service & Price' },
        ]}
      />
      <Heading>
        <PageTitle>Setting</PageTitle>
      </Heading>
      <SettingSidebarMenu selected="/setting/company/default-price">
        {renderHeading(props)}
        <Separator />
        <Container>
          <CustomCard>
            <CustomCardDescription>
              Set your preferred service.
            </CustomCardDescription>
            {props.organization && props.squads && renderSquadPrice(props)}
            {props.organization && props.squads && renderAllDriversPrice(props)}
            {props.organization && props.squads && renderMarinePrice()}
          </CustomCard>
        </Container>
      </SettingSidebarMenu>
    </MainContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
`;

const CustomCard = styled(Card)`
  flex: 1;
  margin: 0rem;
  padding-left: 0 !important;
`;

const CardRow = styled.div`
  display: flex;

  & > * {
    margin-right: 1rem;
  }
`;

const Arrow = styled(FontAwesomeIcon)`
  align-self: center;
  margin: 0 2rem 0 1rem;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(props): string => props.color};
  align-self: center;
  margin-right: 0.5rem;
`;

const Separator = styled(BasicSeparator)`
  margin-top: 1.6875rem;
`;

const ServiceTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 600;
  margin-right: auto;
  margin-top: 1.5rem;
  margin-bottom: 0.375rem;
  color: ${COLOR.darkGray};
`;

const ContentTitle = styled.div`
  font-size: 1rem;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 0.375rem;
  color: ${COLOR.darkGray};
`;

const PriceInfo = styled.div`
  margin-bottom: 1.5rem;
`;

const CustomCardDescription = styled.div`
  margin-bottom: 1.5rem;
  font-size: 1rem;
`;

const Description = styled.div`
  margin-bottom: 1rem;
  font-size: 0.8125rem;
  color: #747788;
`;

const SmallDescription = styled.div`
  margin-top: 0.313rem;
  font-size: 0.75rem;
`;

const DefaultServiceBadge = styled.div`
  font-size: 0.75rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.3rem;
  border-radius: 6px;
  color: ${COLOR.black};
  background-color: ${COLOR.yellow};
`;

const PriceContainer = styled.div`
  padding-left: 1rem;
`;

function mapStateToProps(state: RootState): DefaultPriceProps {
  return {
    organization: state.organization.organization,
    squads: state.organization.squads,
  };
}

export default connect(mapStateToProps)(DefaultPriceIndex);