import * as React from 'react';
import styled, { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export type BadgeType = 'primary' | 'grey' | 'error' | 'warning' | 'success';

interface BadgeGroupContextProps {
  type: BadgeType;
  fontSize: keyof DefaultTheme['fontSizes'];
  fontWeight: keyof DefaultTheme['fontWeights'];
  iconSize: keyof DefaultTheme['iconSizes'];
}

export const BadgeGroupContext = React.createContext<BadgeGroupContextProps | null>(null);

interface BadgeGroupProps {
  children: React.ReactNode;
  type: BadgeType;
  fontSize?: keyof DefaultTheme['fontSizes'];
  fontWeight?: keyof DefaultTheme['fontWeights'];
  iconSize?: keyof DefaultTheme['iconSizes'];
  iconLeft?: IconDefinition;
  iconRight?: IconDefinition;
  customStyle?: FlattenSimpleInterpolation;
}

export default function BadgeGroup({
  type,
  children,
  fontSize = 'xs',
  fontWeight = 'normal',
  iconSize = 'md',
  iconLeft,
  iconRight,
  customStyle,
}: BadgeGroupProps): JSX.Element {
  return (
    <BadgeGroupContext.Provider value={{ type, fontSize, fontWeight, iconSize }}>
      <BadgeWrapper
        type={type}
        fontSize={fontSize}
        fontWeight={fontWeight}
        iconLeft={iconLeft}
        customStyle={customStyle}
      >
        {iconLeft && <Icon icon={iconLeft} type={type} iconSize={iconSize} />}
        {children}
        {iconRight && <Icon icon={iconRight} type={type} iconSize={iconSize} />}
      </BadgeWrapper>
    </BadgeGroupContext.Provider>
  );
}

const BadgeWrapper = styled.div<{
  type: BadgeType;
  fontSize: keyof DefaultTheme['fontSizes'];
  fontWeight?: keyof DefaultTheme['fontWeights'];
  iconLeft?: IconDefinition;
  customStyle?: FlattenSimpleInterpolation;
}>`
  display: inline-flex;
  padding: ${(props) =>
    props.iconLeft ? '0.25rem 0.625rem' : '0.25rem 0.625rem 0.25rem 0.25rem'};
  align-items: center;
  gap: 0.5rem;
  border-radius: 1rem;
  font-size: ${(props): string => props.theme.fontSizes[props.fontSize]};
  font-weight: ${(props): number => props.theme.fontWeights[props.fontWeight]};

  ${(props): ReturnType<typeof css> => {
    switch (props.type) {
      case 'primary':
        return css`
          color: ${props.theme.colors.primary_700};
          background-color: ${props.theme.colors.primary_50};
        `;
      case 'grey':
        return css`
          color: ${props.theme.colors.gray_700};
          background-color: ${props.theme.colors.gray_50};
        `;
      case 'error':
        return css`
          color: ${props.theme.colors.error_700};
          background-color: ${props.theme.colors.error_50};
        `;
      case 'warning':
        return css`
          color: ${props.theme.colors.warning_700};
          background-color: ${props.theme.colors.warning_50};
        `;
      case 'success':
        return css`
          color: ${props.theme.colors.success_700};
          background-color: ${props.theme.colors.success_50};
        `;
    }
  }}

  ${(props) => props.customStyle}
`;

const BadgeChipStyled = styled.div<{
  type: BadgeType;
  fontSize: keyof DefaultTheme['fontSizes'];
  fontWeight?: keyof DefaultTheme['fontWeights'];
}>`
  color: #fff;
  display: inline-flex;
  padding: 0.3rem 0.5rem;
  align-items: center;
  border-radius: 1rem;
  font-size: ${(props): string => props.theme.fontSizes[props.fontSize]};
  font-weight: ${(props): number => props.theme.fontWeights[props.fontWeight]};

  ${(props): ReturnType<typeof css> => {
    switch (props.type) {
      case 'primary':
        return css`
          color: #fff;
          background-color: ${props.theme.colors.primary_700};
        `;
      case 'grey':
        return css`
          color: ${props.theme.colors.gray_700};
          background-color: ${props.theme.colors.gray_200};
        `;
      case 'error':
        return css`
          color: #fff;
          background-color: ${props.theme.colors.error_600};
        `;
      case 'warning':
        return css`
          color: #fff;
          background-color: ${props.theme.colors.warning_600};
        `;
      case 'success':
        return css`
          color: #fff;
          background-color: ${props.theme.colors.success_600};
        `;
    }
  }}
`;

export const BadgeChip = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const context = React.useContext(BadgeGroupContext);
  if (!context) {
    throw new Error('BadgeChip must be used within a BadgeGroup');
  }
  const { type, fontSize, fontWeight } = context;
  return (
    <BadgeChipStyled type={type} fontSize={fontSize} fontWeight={fontWeight} >
      {children}
    </BadgeChipStyled>
  );
};

const Icon = styled(FontAwesomeIcon)<{
  type: BadgeType;
  iconSize: keyof DefaultTheme['iconSizes'];
}>`
  font-size: ${(props): string => props.theme.iconSizes[props.iconSize]};
  color: ${(props): string => props.color};
  align-self: center;

  ${(props): ReturnType<typeof css> => {
    switch (props.type) {
      case 'primary':
        return css`
          color: ${props.theme.colors.primary_500};
        `;
      case 'grey':
        return css`
          color: ${props.theme.colors.gray_500};
        `;
      case 'error':
        return css`
          color: ${props.theme.colors.error_500};
        `;
      case 'warning':
        return css`
          color: ${props.theme.colors.warning_500};
        `;
      case 'success':
        return css`
          color: ${props.theme.colors.success_500};
        `;
    }
  }}
`;
