import * as React from 'react';
import { Radio, StyledButton, TextInput, Typography } from 'components';
import { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import EpodDocument from 'components/pdf/epodDocument';
import {
  ActionBottomContainer,
  BottomWrapper,
  CareofAndReceiverSection,
  Divider,
  InlineLinkText,
  LeftSection,
  LinkText,
  OrderIdAndViewPODWrapper,
  RadioWrapper,
  ReceiptCard,
  ReceiptContainer,
  ReceiptStatusChip,
  RedText,
  RightSection,
  ReceiptTitle,
  SectionTitleLarge,
  Stack,
  EpodStatusColor,
  InputErrorMessage,
} from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { EndorseEpodPayload, Epod, EpodStatus } from 'models/task/epod';
import TaskClient from 'httpClients/taskClient';
import { formatAddress } from 'utils/formatter';
import { format } from 'date-fns';
import { EPOD_STATUS_TEXT } from 'constants/epodStatus';
import { Attachment } from 'models/task';
import COLOR from 'constants/color';

interface InputLabelProps {
  text: string;
  required: boolean;
}

const renderInputLabel = ({ required, text }: InputLabelProps): React.ReactNode => {
  return (
    <Typography as="p" size="sm" color="gray_700" weight="semibold">
      {text} {required ? <RedText>*</RedText> : null}
    </Typography>
  );
};

const epodStatuses = [
  {
    text: 'Complete -  All goods received and accepted',
    value: 'complete',
  },
  {
    text: 'Partially Complete - Some goods received and accepted, but not all',
    value: 'partially_complete',
  },
  {
    text: 'Declined - No goods received and accepted',
    value: 'declined',
  },
];

function isMobileBrowser() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

interface EndorseFormProps {
  vessel_imo: string;
  rank: string;
  full_name: string;
  email: string;
  status: string;
  remakrs: string;
}

export default function EpodReceipt() {
  const history = useHistory();
  const { taskId } = useParams<{ taskId: string }>();
  const [selectedStatus, setSelectedStatus] = React.useState<string>('');
  const [epod, setEpod] = React.useState<Epod | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [endorseForm, setEndorseForm] = React.useState<EndorseFormProps>({
    vessel_imo: '',
    rank: '',
    full_name: '',
    email: '',
    status: '',
    remakrs: '',
  });

  const [formError, setFormError] = React.useState<EndorseFormProps>({
    vessel_imo: '',
    rank: '',
    full_name: '',
    email: '',
    status: '',
    remakrs: '',
  });

  const fetchTask = async (id: string): Promise<void> => {
    const client = new TaskClient();
    try {
      const response = await client.getEpodByTaskId(id);
      setEpod(response);
    } catch (error) {
      // generate epod if epod not found but task status is complete
      const errorMessage = (error as Error).toString().toLowerCase();
      if (errorMessage === 'epod not found') {
        history.push('/');
      }
    }
  };

  const isFormFullyFilled = (): boolean => {
    const vesselImo = task.to_contact?.vessel_name ? !!endorseForm.vessel_imo : true;
    return (
      vesselImo &&
      endorseForm.rank !== '' &&
      endorseForm.full_name !== '' &&
      endorseForm.email !== '' &&
      endorseForm.status !== ''
    );
  };

  const formValidate = (): boolean => {
    // check if all fields are filled
    let isFormInvalid = false;
    // Update formError state directly
    if (task.to_contact?.vessel_name && !endorseForm.vessel_imo) {
      setFormError((prevState) => ({
        ...prevState,
        vessel_imo: 'Vessel IMO is required',
      }));
      isFormInvalid = true;
    }
    if (!endorseForm.rank) {
      setFormError((prevState) => ({ ...prevState, rank: 'Title / Rank is required' }));
      isFormInvalid = true;
    }
    if (!endorseForm.full_name) {
      setFormError((prevState) => ({ ...prevState, full_name: 'Full Name is required' }));
      isFormInvalid = true;
    }
    if (!endorseForm.email) {
      setFormError((prevState) => ({ ...prevState, email: 'Email is required' }));
      isFormInvalid = true;
    }

    if (endorseForm.email !== '' && !/\S+@\S+\.\S+/.test(endorseForm.email)) {
      setFormError((prevState) => ({ ...prevState, email: 'Email format is invalid' }));
      isFormInvalid = true;
    }

    if (endorseForm.status === '' || endorseForm.status === null) {
      setFormError((prevState) => ({ ...prevState, status: 'Status is required' }));
      isFormInvalid = true;
    }

    if (isFormInvalid) {
      return true;
    } else {
      setFormError({
        vessel_imo: '',
        rank: '',
        full_name: '',
        email: '',
        status: '',
        remakrs: '',
      });
      return false;
    }
  };

  const handleSubmitEndorse = async (): Promise<void> => {
    const isFormInvalid = formValidate();
    if (isFormInvalid) {
      return;
    }
    setIsSubmitting(true);
    const client = new TaskClient();
    const payload: EndorseEpodPayload = {
      version_rev: epod.version_rev,
      status: endorseForm.status as EpodStatus,
      endorse: {
        ip_address: '',
        endorse_at: new Date(),
      },
      receiver: {
        vessel_imo: endorseForm.vessel_imo,
        rank: endorseForm.rank,
        full_name: endorseForm.full_name,
        email: endorseForm.email,
      },
      remarks: endorseForm.remakrs,
    };

    try {
      await client.endorseEpod(task.id, payload);
      window.location.href = `/epod/${taskId}`;
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    fetchTask(taskId);
  }, []);

  if (!epod) {
    return null;
  }
  const isEndorsed = epod.status !== 'to_be_acknowledged' && epod.endorse !== null;
  const isEndorsedBySystem =
    epod.endorse !== null && epod.endorse.endorse_at && epod.receiver === null;
  const { task } = epod;
  const companyAndVehicleComplete =
    task.to_contact?.company_name && task.to_contact?.vehicle_name ? ', ' : '';
  const taskCompleteStatus = task.statuses.find((status) => status.status === 'complete');
  const epodStatus = epod.status;
  const deliveryAttachments: Attachment[] = [];
  if (task && task?.statuses) {
    task.statuses.forEach((history) => {
      if (history.status === 'unsuccessful_delivery') {
        if ((history.attachments || []).length > 0) {
          deliveryAttachments.push(...history.attachments);
        }
        return;
      }

      if (history.status === 'complete') {
        if (history.attachments) {
          if (history.attachments.length > 0) {
            deliveryAttachments.push(...history.attachments);
          }
        }
      }
    });
  }

  if (task?.attachments?.length > 0) {
    deliveryAttachments.push(...task.attachments);
  }

  const renderEpodStatement = (): React.ReactNode => {
    if (isEndorsedBySystem) {
      return (
        <Typography as="p" size="sm" color="gray_700">
          This Proof of delivery has been automatically marked complete on{' '}
          {format(new Date(epod.endorse.endorse_at), 'dd MMM yyyy, HH:mm')}.
        </Typography>
      );
    }

    if (isEndorsed) {
      return (
        <Typography as="p" size="sm" color="gray_700">
          <Typography as="span" size="sm" color="gray_700" weight="semibold">
            {epod.receiver.rank} {epod.receiver.full_name}{' '}
          </Typography>
          of{' '}
          {task.to_contact?.vessel_name && (
            <Typography as="span" size="sm" color="gray_700" weight="semibold">
              {task.to_contact?.vessel_name ?? ''}{' '}
            </Typography>
          )}
          has endorsed and acknowledged that the receipt of goods described in the POD is{' '}
          <Typography as="span" size="sm" color="gray_700" weight="semibold">
            {epodStatuses.find((status) => status.value === epod.status)?.text}
            {':'}
          </Typography>
        </Typography>
      );
    }

    return (
      <Typography as="p" size="sm" color="gray_700">
        Your endorsement is required to confirm that the receipt of goods described in the
        POD have been delivered to:
      </Typography>
    );
  };

  const renderReceiverForm = (): React.ReactNode => {
    return (
      <>
        {task.to_contact?.vessel_name && (
          <Stack gap="0.25rem" direction="column">
            <TextInput
              inputStyle={css`
                border-color: ${formError.vessel_imo ? COLOR.red : COLOR.neutral};
              `}
              value={endorseForm.vessel_imo}
              autoComplete="none"
              placeholder="E.g. 9087890"
              fieldName={renderInputLabel({ required: true, text: 'Vessel IMO' })}
              onTextChange={(value: string): void => {
                setEndorseForm({ ...endorseForm, vessel_imo: value });
              }}
            />
            {formError.vessel_imo && (
              <InputErrorMessage>{formError.vessel_imo}</InputErrorMessage>
            )}
          </Stack>
        )}

        <Stack gap="0.25rem" direction="column">
          <TextInput
            inputStyle={css`
              border-color: ${formError.rank ? COLOR.red : COLOR.neutral};
            `}
            value={endorseForm.rank}
            autoComplete="none"
            placeholder="E.g. Chief Officer"
            fieldName={renderInputLabel({ required: true, text: 'Title / Rank' })}
            onTextChange={(value: string): void => {
              setEndorseForm({ ...endorseForm, rank: value });
            }}
          />
          {formError.rank && <InputErrorMessage>{formError.rank}</InputErrorMessage>}
        </Stack>

        <Stack gap="0.25rem" direction="column">
          <TextInput
            inputStyle={css`
              border-color: ${formError.full_name ? COLOR.red : COLOR.neutral};
            `}
            value={endorseForm.full_name}
            autoComplete="none"
            placeholder="E.g. James Wong"
            fieldName={renderInputLabel({ required: true, text: 'Full Name' })}
            onTextChange={(value: string): void => {
              setEndorseForm({ ...endorseForm, full_name: value });
            }}
          />
          {formError.full_name && (
            <InputErrorMessage>{formError.full_name}</InputErrorMessage>
          )}
        </Stack>

        <Stack gap="0.25rem" direction="column">
          <TextInput
            inputStyle={css`
              border-color: ${formError.email ? COLOR.red : COLOR.neutral};
            `}
            value={endorseForm.email}
            autoComplete="none"
            placeholder="E.g. james@mail.com"
            fieldName={renderInputLabel({ required: true, text: 'Email' })}
            onTextChange={(value: string): void => {
              setEndorseForm({ ...endorseForm, email: value });
            }}
          />

          {formError.email && <InputErrorMessage>{formError.email}</InputErrorMessage>}
        </Stack>

        {task.to_contact?.vessel_name && (
          <Stack gap="0.25rem" direction="column">
            <Typography as="p" size="sm" color="gray_700" weight="semibold">
              Vessel
            </Typography>
            <Typography as="p" size="md" color="gray_600">
              {task.to_contact?.vessel_name ?? '-'}
            </Typography>
          </Stack>
        )}

        <Stack gap="0.25rem" direction="column">
          <Typography as="p" size="sm" color="gray_700" weight="semibold">
            Location
          </Typography>
          <Typography as="p" size="md" color="gray_600">
            {task.to_address?.country.toUpperCase() ?? 'SINGAPORE'}
          </Typography>
        </Stack>
      </>
    );
  };

  const renderReceiverDetail = (): React.ReactNode => {
    return (
      <>
        {task.to_contact?.vessel_name && (
          <Stack gap="0.25rem" direction="column">
            <Typography as="p" size="sm" color="gray_700" weight="semibold">
              Vessel IMO
            </Typography>
            <Typography as="p" size="md" color="gray_600">
              {epod.receiver?.vessel_imo ?? '-'}
            </Typography>
          </Stack>
        )}
        <Stack gap="0.25rem" direction="column">
          <Typography as="p" size="sm" color="gray_700" weight="semibold">
            Title / Rank
          </Typography>
          <Typography as="p" size="md" color="gray_600">
            {epod.receiver?.rank ?? '-'}
          </Typography>
        </Stack>
        <Stack gap="0.25rem" direction="column">
          <Typography as="p" size="sm" color="gray_700" weight="semibold">
            Full Name
          </Typography>
          <Typography as="p" size="md" color="gray_600">
            {epod.receiver?.full_name ?? '-'}
          </Typography>
        </Stack>
        {task.to_contact?.vessel_name && (
          <Stack gap="0.25rem" direction="column">
            <Typography as="p" size="sm" color="gray_700" weight="semibold">
              Vessel
            </Typography>
            <Typography as="p" size="md" color="gray_600">
              {task.to_contact?.vessel_name ?? '-'}
            </Typography>
          </Stack>
        )}
        <Stack gap="0.25rem" direction="column">
          <Typography as="p" size="sm" color="gray_700" weight="semibold">
            Location
          </Typography>
          <Typography as="p" size="md" color="gray_600">
            {task.to_address?.country.toUpperCase() ?? 'SINGAPORE'}
          </Typography>
        </Stack>
        <Stack gap="0.25rem" direction="column">
          <Typography as="p" size="sm" color="gray_700" weight="semibold">
            Endorsed on
          </Typography>
          <Typography as="p" size="md" color="gray_600">
            {format(new Date(epod.endorse.endorse_at), 'dd MMM yyyy, HH:mm')}
          </Typography>
          {epod.endorse?.ip_address && (
            <Typography as="p" size="md" color="gray_600">
              IP: {epod.endorse?.ip_address ?? '-'}
            </Typography>
          )}
        </Stack>
      </>
    );
  };

  const filename = (epodCreatedAt: Date, taskId: string) => {
    const id = taskId.split('-')[0];
    const dateEpodCreatedFormatted = format(new Date(epod.created_at), 'ddMMyy');
    return `EPOD-${dateEpodCreatedFormatted}-${id}`;
  };

  return (
    <>
      <ReceiptContainer>
        <Typography
          as="p"
          size="sm"
          color="gray_700"
          customStyle={{ marginTop: '2rem', marginBottom: '1rem' }}
        >
          Receipt ID: {epod.id}
        </Typography>

        {epod && isEndorsed && (
          <div style={{ marginBottom: '1rem' }}>
            <PDFDownloadLink
              document={<EpodDocument data={epod} />}
              fileName={filename(epod.created_at, taskId)}
            >
              {() => (
                <>
                  <StyledButton
                    size={'md'}
                    buttonStyle="encourage"
                    buttonType="primary"
                    fontWeight={600}
                    icon={<FontAwesomeIcon icon={faFileDownload} />}
                  >
                    Download Receipt
                  </StyledButton>
                </>
              )}
            </PDFDownloadLink>
          </div>
        )}

        <ReceiptCard>
          <Stack
            direction="column"
            gap="1.25rem"
            style={{ marginBottom: '1.25rem' }}
            mdScreenStyle={css`
              gap: 1.5rem;
              margin-bottom: 1.5rem !important;
            `}
          >
            <Stack
              direction="column"
              gap="1.25rem"
              mdScreenStyle={css`
                flex-direction: row;
                justify-content: space-between;
              `}
            >
              <div>
                <ReceiptTitle as="h1" color="gray_900">
                  Proof of Delivery Receipt
                </ReceiptTitle>
                <ReceiptStatusChip {...EpodStatusColor[epodStatus]}>
                  {EPOD_STATUS_TEXT[epodStatus]}
                </ReceiptStatusChip>
              </div>

              <OrderIdAndViewPODWrapper>
                <Typography as="p" size="sm" color="gray_700">
                  Order ID:{' '}
                  {task.tracking_id
                    ? task.tracking_id.toLowerCase()
                    : task.id.split('-')[0]}
                </Typography>
                <Typography as="p" size="sm" color="gray_700">
                  {isMobileBrowser() ? (
                    <PDFDownloadLink
                      document={<EpodDocument data={epod} />}
                      fileName={filename(epod.created_at, taskId)}
                    >
                      <LinkText as={'span'}>View POD</LinkText>
                    </PDFDownloadLink>
                  ) : (
                    <LinkText href={`/epod/${taskId}/pdf`} target="_blank">
                      View POD
                    </LinkText>
                  )}
                </Typography>
              </OrderIdAndViewPODWrapper>
            </Stack>
            {renderEpodStatement()}
          </Stack>

          <CareofAndReceiverSection>
            <LeftSection>
              <SectionTitleLarge as="h2" color="gray_700">
                Care-of
              </SectionTitleLarge>

              {/* Location */}
              <Stack gap="0.25rem" direction="column">
                <Typography as="p" size="sm" color="gray_700" weight="semibold">
                  Location
                </Typography>
                <Typography as="p" size="md" color="gray_600">
                  {formatAddress(task.to_address, true)}
                </Typography>
              </Stack>

              {/* Lighter Boat & Company */}
              <Stack gap="0.25rem" direction="column">
                <Typography as="p" size="sm" color="gray_700" weight="semibold">
                  Company & Vehicle Name
                </Typography>
                <Typography as="p" size="md" color="gray_600">
                  {task.to_contact?.company_name || task.to_contact?.vehicle_name ? (
                    <>
                      {`${
                        task.to_contact?.company_name ?? ''
                      }${companyAndVehicleComplete}${
                        task.to_contact?.vehicle_name ?? ''
                      }`}
                    </>
                  ) : (
                    <Typography as="p" size="md" color="gray_600">
                      -
                    </Typography>
                  )}
                </Typography>
              </Stack>

              {/* Delivery Completed at */}
              <Stack gap="0.25rem" direction="column">
                <Typography as="p" size="sm" color="gray_700" weight="semibold">
                  Delivery Completed at
                </Typography>
                <Typography as="p" size="md" color="gray_600">
                  {format(new Date(taskCompleteStatus?.set_at_utc), 'dd MMM yyyy, HH:mm')}
                </Typography>
              </Stack>

              {/* Evidence of Delivery  */}
              <Stack gap="0.25rem" direction="column">
                <Typography as="p" size="sm" color="gray_700" weight="semibold">
                  Evidence of Delivery
                </Typography>
                {deliveryAttachments.length > 0 ? (
                  deliveryAttachments.map((attachment) => (
                    <LinkText key={attachment.id} href={attachment.url} target="_blank">
                      {attachment.name}
                    </LinkText>
                  ))
                ) : (
                  <Typography as="p" size="md" color="gray_600">
                    -
                  </Typography>
                )}
              </Stack>
            </LeftSection>

            <RightSection
              style={{
                maxWidth: '440px',
              }}
            >
              <SectionTitleLarge as="h2" color="gray_700">
                Receiver
              </SectionTitleLarge>
              {isEndorsed ? renderReceiverDetail() : renderReceiverForm()}
            </RightSection>
          </CareofAndReceiverSection>

          {!isEndorsed ? (
            <>
              <Divider marginTopBottom="1.5rem" />
              <Typography as="p" size="sm" color="gray_700">
                By clicking Endorse, you acknowledge that the receipt of goods which are
                described in the POD are:
              </Typography>
              <RadioWrapper className="rd-wrap">
                <Radio
                  options={epodStatuses}
                  value={selectedStatus}
                  onChange={(v: string): void => {
                    setSelectedStatus(v);
                    setEndorseForm({ ...endorseForm, status: v });
                  }}
                />
                {formError.status && (
                  <InputErrorMessage>{formError.status}</InputErrorMessage>
                )}
              </RadioWrapper>
              <Stack gap="0.25rem" direction="column">
                <TextInput
                  value={endorseForm.remakrs}
                  autoComplete="none"
                  placeholder="E.g. All received"
                  fieldName={renderInputLabel({
                    required: false,
                    text: 'Remarks',
                  })}
                  onTextChange={(value: string): void => {
                    setEndorseForm({ ...endorseForm, remakrs: value });
                  }}
                />
              </Stack>
            </>
          ) : (
            <>
              {/* Endorsement Remarks */}
              <Stack gap="0.25rem" direction="column" style={{ marginTop: '1rem' }}>
                <Typography as="p" size="sm" color="gray_700" weight="semibold">
                  Remarks
                </Typography>
                <Typography as="p" size="md" color="gray_600">
                  {epod.remarks && epod.remarks !== '' ? epod.remarks : '-'}
                </Typography>
              </Stack>
            </>
          )}
        </ReceiptCard>
        <Stack gap="1rem" direction="column">
          <Typography
            as="p"
            size="sm"
            color="gray_600"
            customStyle={{ lineHeight: '1.5' }}
          >
            This is computer-generated content in accordance with the scope of GST under
            the{' '}
            <InlineLinkText
              href="https://www.iras.gov.sg/taxes/goods-services-tax-(gst)/specific-business-sectors/marine-and-shipping"
              target="_blank"
            >
              GST Act for Marine and Shipping by the Inland Revenue Authority of Singapore
              (IRAS)
            </InlineLinkText>
            . No signature is required.
          </Typography>
          {!isEndorsedBySystem && (
            <Typography
              as="p"
              size="sm"
              color="gray_600"
              customStyle={{ lineHeight: '1.5' }}
            >
              Please be advised that your Proof of Delivery will be automatically marked
              as complete by the system after 7 days.
            </Typography>
          )}
        </Stack>
      </ReceiptContainer>
      <ActionBottomContainer>
        <BottomWrapper>
          <StyledButton
            icon={<FontAwesomeIcon icon={faCheck} />}
            disabled={!isFormFullyFilled()}
            buttonStyle="encourage"
            buttonType="primary"
            onClick={(): void => {
              handleSubmitEndorse();
            }}
            fontWeight={600}
            isLoading={isSubmitting}
          >
            <Stack gap="0.5rem" direction="row">
              Endorse
            </Stack>
          </StyledButton>
        </BottomWrapper>
      </ActionBottomContainer>
    </>
  );
}
