import * as React from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableCellRenderEvent,
  Dropdown,
  Message,
  SysAdminPagination,
  PaginationState,
  CenterModal,
  TextInput,
  Typography,
  LocationDetailModal,
} from 'components';
import {
  ErrorIcon,
  FilterContainer,
  RemoveButton,
  SortDropdownContainer,
  TopButtonContainer,
  Button,
  RemoveIcon,
  InlineErrorMessage,
} from './styles';
import { RootState } from 'reduxActions/store';
import * as H from 'history';
import {
  faPlus,
  faTrash,
  faSearch,
  faTimes,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { formatError } from 'utils/formatter';

import { MarineLocation } from 'models/marineLocation';
import GeoServiceClient from 'httpClients/geoServiceClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import COLOR from 'constants/color';
import styled, { css } from 'styled-components';

interface MarineLocationPageProps {
  models: MarineLocation[];
}

interface HistoryProps<S = H.LocationState> {
  location: H.Location<S>;
  history: H.History<S>;
}

interface MarineLocationIndexState extends PaginationState<MarineLocation> {
  error: string | null;
  term: string;
  showAddMarineLocationModal: boolean;
  deleteMarineLocationModal: {
    id: string;
    name: string;
  };
  showLocateOnMap: boolean;
  addMarineLocationData: {
    lat: number;
    lng: number;
    name_address: string;
    country: string;
    city: string;
    state: string;
  };
  fieldsError: {
    name_address: string;
  };
}

type Props = MarineLocationPageProps & HistoryProps;

class MarineLocationIndex extends SysAdminPagination<
  MarineLocation,
  Props,
  MarineLocationIndexState
> {
  searchTimeout: number | null | NodeJS.Timeout;
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      basePath: '/sys/marine-locations',
      breadcrumb: [{ text: 'Marine Locations' }],
      filters: this.getDefaultFilter(),
      pluralModelName: 'Marine Locations',
      selectedTab: 'marine-locations',
      error: null,
      term: '',
      showAddMarineLocationModal: false,
      deleteMarineLocationModal: {
        id: '',
        name: '',
      },
      showLocateOnMap: false,
      addMarineLocationData: {
        name_address: '',
        lat: 0,
        lng: 0,
        country: 'SINGAPORE',
        city: 'SINGAPORE',
        state: 'SINGAPORE',
      },
      fieldsError: {
        name_address: '',
      },
    };

    this.rules = [{ name: 'page' }];
  }

  componentDidMount(): void {
    super.componentDidMount();
  }

  getDefaultFilter = (): Record<string, string> => ({
    page: '1',
    order: 'asc',
    term: '',
  });

  fetchData = async (): Promise<void> => {
    this.setState({ error: null, isFetching: true });
    try {
      const client = new GeoServiceClient();
      await client.getSysMarineLocation(new URLSearchParams(this.state.filters));
    } catch (error) {
      this.setState({
        error,
        isFetching: false,
      });
    }

    this.setState({ isFetching: false });
  };

  showAddMarineLocationModal = (): void => {
    this.setState({ showAddMarineLocationModal: true });
  };

  closeAddMarineLocationModal = (): void => {
    this.setState((prevState) => ({
      showAddMarineLocationModal: false,
      addMarineLocationData: {
        ...prevState.addMarineLocationData,
        name_address: '',
        lat: null,
        lng: null,
      },
      fieldsError: {
        name_address: '',
      },
    }));
  };

  closeAddMarineLocationModalFetch = (): void => {
    this.fetchData();
    this.closeAddMarineLocationModal();
  };

  deleteMarineLocation = async (): Promise<void> => {
    const vesselClient = new GeoServiceClient();
    try {
      await vesselClient.deleteSysMarineLocation(this.state.deleteMarineLocationModal.id);
      this.closeDeleteMarineLocationModal();
      this.fetchData();
    } catch (e) {
      this.setState({ error: formatError(e, false) });
      return;
    }
  };

  renderDeleteMarineLocationModal = (): React.ReactNode => {
    return (
      <CenterModal
        closeButtonOnClick={() => this.closeDeleteMarineLocationModal()}
        title={<RemoveIcon icon={faTrash} color={COLOR.red} />}
        leftButtonText="Cancel"
        leftButtonOnClick={(): void => {
          this.closeDeleteMarineLocationModal();
        }}
        rightButtonOnClick={this.deleteMarineLocation}
        rightButtonText="Remove"
        rightButtonStyle="discourage"
        rightButtonType="primary"
        width="small"
        position="bottom"
      >
        <Typography
          as="h2"
          color="gray_900"
          size="lg"
          customStyle={{ marginBottom: '10px', marginTop: '4px' }}
        >
          Remove Marine Locations
        </Typography>

        <Typography
          as="div"
          color="gray_600"
          size="sm"
          customStyle={{ lineHeight: '1.5' }}
        >
          Removing {this.state.deleteMarineLocationModal.name} is irreversible and will
          not affect existing data.
        </Typography>
      </CenterModal>
    );
  };

  showDeleteMarineLocationModal = (id: string, name: string): void => {
    this.setState({
      deleteMarineLocationModal: { id, name },
    });
  };

  closeDeleteMarineLocationModal = (): void => {
    this.setState({
      deleteMarineLocationModal: {
        id: '',
        name: '',
      },
    });
  };

  search = (): void => {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      const value = this.state.term && this.state.term.toUpperCase();
      const isDelete = value === '';
      if (value.length >= 3 || value === '') {
        this.onFilterChange('term', value, isDelete);
      }
    }, 700);
  };

  renderCell = (e: TableCellRenderEvent<MarineLocation>): React.ReactNode => {
    if (e.key === 'id') {
      return (
        <div>
          <RemoveButton
            onClick={(evt: React.MouseEvent): void => {
              evt.stopPropagation();
              this.showDeleteMarineLocationModal(e.data.id, e.data.name_address);
            }}
            buttonStyle="discourage"
            buttonType="neutral"
          >
            <ErrorIcon icon={faTrash} />
            Delete
          </RemoveButton>
        </div>
      );
    }
    return <>{e.value}</>;
  };

  renderContent = (): React.ReactNode => {
    const { error } = this.state;
    const { models } = this.props;

    const columns = {
      name_address: 'Location Name',
      latlng: 'Latitude, Longitude',
      id: '',
    };

    const data = models.map((marineLocation) => ({
      name_address: marineLocation.name_address,
      latlng: `${marineLocation.lat}, ${marineLocation.lng}`,
      id: marineLocation.id,
    }));

    return (
      <>
        {error && <Message className="error">{error}</Message>}
        <Table
          columns={columns}
          data={data}
          rowOnClick={(id: string): void => {
            this.props.history.push('/sys/marine-locations/' + id + '/details');
          }}
          cellRenderer={this.renderCell}
        />
      </>
    );
  };

  renderFilter = (): React.ReactNode => (
    <FilterContainer>
      <TextInput
        icon={(this.state.term || '').length === 0 ? faSearch : faTimes}
        iconOnClick={(): void => {
          this.setState({ term: '' });
          this.search();
        }}
        iconStyle={{
          top: '-1.6rem',
          right: '1rem',
          color: `${COLOR.midDarkGrey}`,
        }}
        height="small"
        width="full"
        onTextChange={(value): void => {
          this.setState({ term: value });
          this.search();
        }}
        placeholder="Search location name"
        value={this.state.term}
      />
      <SortDropdownContainer>
        <Dropdown
          label="Sort by"
          options={[
            {
              value: 'asc',
              name: 'A-Z',
            },
            {
              value: 'desc',
              name: 'Z-A',
            },
          ]}
          onChange={(value: string): void => {
            this.onFilterChange('order', value);
          }}
          value={this.state.filters.order}
        />
      </SortDropdownContainer>
    </FilterContainer>
  );

  handleLatLngChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;

    const parts = input.split(', ').map((str) => str.trim());
    if (parts.length === 2) {
      const [latStr, lngStr] = parts;
      const lat = parseFloat(latStr);
      const lng = parseFloat(lngStr);

      this.setState((prevState) => ({
        addMarineLocationData: {
          ...prevState.addMarineLocationData,
          lat,
          lng,
        },
      }));
    } else {
      this.setState((prevState) => ({
        addMarineLocationData: {
          ...prevState.addMarineLocationData,
          [event.target.name]: event.target.value,
        },
      }));
    }
  };

  handleNumericInput = (event: React.FormEvent<HTMLInputElement>): void => {
    const input = event.currentTarget.value;
    // check value contains string ', '
    if (input.includes(', ')) {
      return;
    }
    const numericInput = input.replace(/[^0-9.]/g, ''); // Allow numbers, periods, and hyphens, disallow commas
    event.currentTarget.value = numericInput;
  };

  handleMarineLocationNameChange = (value: string): void => {
    const uppercasedLocationName = value.toUpperCase();
    this.setState((prevState) => ({
      addMarineLocationData: {
        ...prevState.addMarineLocationData,
        name_address: uppercasedLocationName,
      },
    }));
  };

  allowAddMarineLocation = (): boolean => {
    const { addMarineLocationData } = this.state;
    const valid =
      addMarineLocationData.name_address &&
      addMarineLocationData.name_address.length >= 3 &&
      addMarineLocationData.lat &&
      addMarineLocationData.lat !== 0 &&
      addMarineLocationData.lng &&
      addMarineLocationData.lng !== 0;

    return !valid;
  };

  handleSubmitMarineLocation = async (): Promise<void> => {
    const { addMarineLocationData } = this.state;
    const payload = {
      ...addMarineLocationData,
      name_address: addMarineLocationData.name_address.trim(),
    };
    if (typeof payload.lat === 'string') {
      payload.lat = parseFloat(payload.lat);
    }
    if (typeof payload.lng === 'string') {
      payload.lng = parseFloat(payload.lng);
    }
    try {
      const client = new GeoServiceClient();
      await client.postSysMarineLocation(payload);
      this.closeAddMarineLocationModalFetch();
    } catch (error) {
      if (error.message === 'name address already exists') {
        this.setState({ fieldsError: { name_address: 'Location name already exists' } });
      } else {
        this.setState({ error: formatError(error, false) });
      }
    }
  };

  renderActionPanel = (): React.ReactNode => (
    <TopButtonContainer>
      <Button
        buttonStyle="encourage"
        buttonType="primary"
        onClick={(): void => {
          this.showAddMarineLocationModal();
        }}
        icon={<FontAwesomeIcon icon={faPlus} />}
        fontWeight={400}
      >
        Add Location
      </Button>
      {this.state.showAddMarineLocationModal && this.renderAddMarineLocationModal()}
      {this.state.deleteMarineLocationModal.id && this.renderDeleteMarineLocationModal()}
    </TopButtonContainer>
  );

  renderAddMarineLocationModal = (): React.ReactNode => {
    const { showLocateOnMap, addMarineLocationData } = this.state;
    if (showLocateOnMap) {
      return this.renderLocateOnMapModal();
    }
    return (
      <>
        <CenterModal
          closeButtonOnClick={() => this.closeAddMarineLocationModal()}
          title={
            <Typography as="h2" size="lg" color="gray_900">
              Add Location
            </Typography>
          }
          leftButtonText="Cancel"
          leftButtonType="neutral"
          leftButtonOnClick={(): void => this.closeAddMarineLocationModal()}
          rightButtonText="Add"
          rightButtonType="primary"
          rightButtonOnClick={() => this.handleSubmitMarineLocation()}
          rightButtonDisabled={this.allowAddMarineLocation()}
          width="small"
        >
          <div>
            <InputWrapper marginTop="8px" marginBottom="8px">
              <ItemFieldName>
                Location Name <RedText>*</RedText>
              </ItemFieldName>
              <TextInput
                width="full"
                height="large"
                autoComplete="none"
                onTextChange={this.handleMarineLocationNameChange}
                type="text"
                placeholder="Enter location name"
                value={addMarineLocationData.name_address || ''}
              />
              {this.state.fieldsError.name_address && (
                <InlineErrorMessage>
                  <ErrorIcon icon={faExclamationCircle} />
                  {this.state.fieldsError.name_address}
                </InlineErrorMessage>
              )}
            </InputWrapper>
            <InputWrapper
              flexDirection="row"
              marginBottom="8px"
              gap="12px"
              marginTop="18px"
            >
              <div>
                <ItemFieldName>
                  Latitude <RedText>*</RedText>
                </ItemFieldName>
                <TextInput
                  name="lat"
                  containerStyle={css`
                    margin-top: 8px;
                  `}
                  width="full"
                  height="large"
                  autoComplete="none"
                  onTextChange={(value: string, e): void => {
                    this.handleLatLngChange(e);
                  }}
                  type="text"
                  inputMode="decimal"
                  placeholder="00.000000"
                  onInput={this.handleNumericInput}
                  value={addMarineLocationData.lat || ''}
                />
              </div>
              <div
                style={{
                  alignSelf: 'flex-end',
                  marginLeft: '-9px',
                }}
              >
                ,
              </div>
              <div>
                <ItemFieldName>
                  Longitude <RedText>*</RedText>
                </ItemFieldName>
                <TextInput
                  name="lng"
                  containerStyle={css`
                    margin-top: 8px;
                  `}
                  width="full"
                  height="large"
                  autoComplete="none"
                  onTextChange={(value: string, e): void => {
                    this.handleLatLngChange(e);
                  }}
                  type="text"
                  inputMode="decimal"
                  placeholder="00.000000"
                  value={addMarineLocationData.lng || ''}
                  onInput={this.handleNumericInput}
                />
              </div>
            </InputWrapper>
            <Typography
              as="p"
              size="sm"
              color="primary_800"
              customStyle={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              <span
                onClick={() => {
                  this.setState({ showLocateOnMap: true });
                }}
              >
                Locate on a map
              </span>
            </Typography>
          </div>
        </CenterModal>
      </>
    );
  };

  renderLocateOnMapModal = (): React.ReactNode => {
    const { addMarineLocationData } = this.state;
    return (
      <LocationDetailModal
        onClose={(): void => this.setState({ showLocateOnMap: false })}
        onSubmit={(locationName: string, { lat, lng }): void => {
          this.setState({
            addMarineLocationData: {
              ...addMarineLocationData,
              name_address: locationName,
              lat: lat,
              lng: lng,
            },
            showLocateOnMap: false,
          });
        }}
        initialLocationName={addMarineLocationData.name_address}
        initialPosition={{
          lat: addMarineLocationData.lat,
          lng: addMarineLocationData.lng,
        }}
      />
    );
  };
}

const ItemFieldName = styled.p`
  font-size: 0.875rem;
  margin: 0 0 6px 0;
`;

const RedText = styled.span`
  color: ${COLOR.red};
`;

const InputWrapper = styled.div<{
  marginTop: string;
  marginBottom: string;
  flexDirection?: string;
  gap?: string;
}>`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  gap: ${(props) => props.gap || '0'};
`;

function mapStateToProps(state: RootState): MarineLocationPageProps {
  return {
    models: state.geoService.marineLocationDatas,
  };
}

export default connect(mapStateToProps)(MarineLocationIndex);
